import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useState } from 'react';
import { ADMINISTRATION_INVESTOR_API, INVESTMENT_ENTITY_API } from 'src/constants/apiEndpoints';
import { EXPORT_CSV_FORMAT } from 'src/constants/date';
import { ITeam, ITeamContact } from 'src/modules/applications/type';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { InvestmentEntityQueryBody } from 'src/modules/investment-entity/types';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  archiveInvestmentEntity,
  archiveInvestor,
  ceaseInvestor,
  createInvestor,
  createInvestorDetail,
  createInvestorIssue,
  createInvestorRedeem,
  createOffmarketTransfer,
  createRecordPayment,
  deleteInvestor,
  downloadInvestorProfileCSV,
  exportInvestorProfileCSV,
  generateStatement,
  getInvestmentEntityRoles,
  getInvestorById,
  getInvestorDetailIssue,
  getInvestorDetailRedeem,
  getInvestorEntities,
  getInvestorFilter,
  getInvestorFunds,
  getInvestorList,
  getInvestorMasterData,
  getInvestorPoppulateData,
  getInvestorUnitClassesByFund,
  getOffmarketTransfer,
  getRecordPayment,
  getUnitClassesByMultiFunds,
  investmentEntityExportCSV,
  investorExportCSV,
  previewStatement,
  unarchiveInvestmentEntity,
  unarchiveInvestor,
  unceaseInvestor,
  updateCurrentUnitPrice,
  upsertInvestorDetail,
  upsertInvestorInvestmentDetail,
  upsertInvestorTeam,
  upsertInvestorTeamContact,
} from './services';
import {
  ICreateInvestorDetail,
  IFund,
  IGenerateStatement,
  IInvestmentDetailForm,
  IInvestmentEntity,
  IInvestorById,
  IInvestorDetailsForm,
  IIssueDetail,
  IIssueForm,
  IOffmarketTransfer,
  IPreviewStatement,
  IRecordPayment,
  IRedeemDetail,
  IRedemptionForm,
  IUnitClass,
  InvestorQueryBody,
} from './types';

export const useGetInvestorList = <T>() => {
  const [queryBody, setQueryBody] = useState<InvestorQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<T>>(
    [ADMINISTRATION_INVESTOR_API.getInvestorList.api, queryBody],
    () => getInvestorList(queryBody),
  );

  const setParams = (newParams: InvestorQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useArchiveInvestor = () => {
  const resp = async (id: string) => {
    await archiveInvestor(id);
  };
  return useMutation(resp);
};

export const useUnarchiveInvestor = () => {
  const resp = async (id: string) => {
    await unarchiveInvestor(id);
  };
  return useMutation(resp);
};

export const useCeaseInvestor = () => {
  const resp = async ({ id, reason }: { id: string; reason: string }) => {
    await ceaseInvestor(id, reason);
  };
  return useMutation(resp);
};

export const useUnceaseInvestor = () => {
  const resp = async (id: string) => {
    await unceaseInvestor(id);
  };
  return useMutation(resp);
};

export const useUpsertInvestorDetails = () => {
  const resp = async ({ id, data }: { id: string; data: IInvestorDetailsForm }) => {
    return await upsertInvestorDetail(id, data);
  };
  return useMutation(resp);
};

export const useCreateInvestorDetail = () => {
  const resp = async ({ data }: { data: ICreateInvestorDetail }) => {
    return await createInvestorDetail(data);
  };
  return useMutation(resp);
};

export const useUpsertInvestorInvestmentDetail = () => {
  const resp = async ({ id, data }: { id: string; data: IInvestmentDetailForm }) => {
    return await upsertInvestorInvestmentDetail(id, data);
  };
  return useMutation(resp);
};

export const useGetInvestorFilters = () => {
  return useQuery([ADMINISTRATION_INVESTOR_API.getInvestorFiltered.api], getInvestorFilter);
};

export const useGetInvestorById = (id: string, step: number, enabled = true) => {
  return useQuery<IInvestorById>(
    [ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id), id, step],
    () => getInvestorById(id, step),
    {
      enabled: !!id && enabled,
    },
  );
};

export const useGetInvestorFunds = (enabled = true) => {
  return useQuery<IFund[]>([ADMINISTRATION_INVESTOR_API.getFunds.api], () => getInvestorFunds(), {
    enabled,
  });
};

export const useGetInvestorMasterData = (enabled = true) => {
  return useQuery(
    [ADMINISTRATION_INVESTOR_API.getInvestorMasterData.api],
    () => getInvestorMasterData(),
    {
      enabled,
    },
  );
};

export const useGetInvestorUnitClassesByFund = (fundId: string, enabled = true) => {
  return useQuery<IUnitClass[]>(
    [ADMINISTRATION_INVESTOR_API.getUnitClassesByFund.api(fundId), fundId],
    () => getInvestorUnitClassesByFund(fundId),
    {
      enabled: !!fundId && enabled,
    },
  );
};

export const useGetInvestorUnitClassesByMultiFunds = (fundIds: string[], enabled = true) => {
  return useQuery<IUnitClass[]>(
    [ADMINISTRATION_INVESTOR_API.getUnitClassesByMultiFunds.api],
    () => getUnitClassesByMultiFunds(fundIds),
    {
      enabled,
    },
  );
};

export const useGetInvestorEntities = (enabled = true) => {
  return useQuery<IInvestmentEntity[]>(
    [ADMINISTRATION_INVESTOR_API.getInvestorEntities.api],
    () => getInvestorEntities(),
    {
      enabled,
    },
  );
};

export const useGetInvestorPoppulateData = (
  fundId: string,
  unitClassId: string,
  investmentEntityId: string,
  enabled = true,
) => {
  return useQuery(
    [
      ADMINISTRATION_INVESTOR_API.getInvestorPoppulateData.api,
      fundId,
      unitClassId,
      investmentEntityId,
    ],
    () => getInvestorPoppulateData(fundId, unitClassId, investmentEntityId),
    {
      enabled: !!fundId && !!unitClassId && !!investmentEntityId && enabled,
    },
  );
};

export const useArchiveInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await archiveInvestmentEntity(id);
    await queryClient.invalidateQueries([INVESTMENT_ENTITY_API.getInvestmentEntityList.api]);
  };
  return useMutation(resp);
};

export const useUnarchiveInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await unarchiveInvestmentEntity(id);
    await queryClient.invalidateQueries([INVESTMENT_ENTITY_API.getInvestmentEntityList.api]);
  };
  return useMutation(resp);
};

export const useGetInvestmentEntityRoles = () => {
  return useQuery(
    [ADMINISTRATION_INVESTOR_API.getInvestmentEntityRoles.api],
    getInvestmentEntityRoles,
  );
};

export const useUpsertInvestorTeamContact = () => {
  const resp = async ({ id, data }: { id: string; data: ITeamContact }) => {
    return await upsertInvestorTeamContact(id, data);
  };
  return useMutation(resp);
};

export const useUpsertInvestorTeam = () => {
  const resp = async ({ id, data }: { id: string; data: ITeam }) => {
    return await upsertInvestorTeam(id, data);
  };
  return useMutation(resp);
};

export const useCreateInvestor = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await createInvestor(id);
    await queryClient.invalidateQueries([ADMINISTRATION_INVESTOR_API.getInvestorList.api]);
  };
  return useMutation(resp);
};

export const useGetInvestorDetailsIssue = (id: string, enabled = true) => {
  return useQuery<IIssueDetail>(
    [ADMINISTRATION_INVESTOR_API.getInvestorDetailIssue.api(id), id],
    () => getInvestorDetailIssue(id),
    {
      enabled: enabled && !!id,
    },
  );
};

export const useCreateInvestorIssue = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IIssueForm }) => {
    await createInvestorIssue(id, data);
    await queryClient.invalidateQueries([ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id)]);
  };
  return useMutation(resp);
};

export const useGetInvestorDetailRedeem = (id: string, enabled = true) => {
  return useQuery<IRedeemDetail>(
    [ADMINISTRATION_INVESTOR_API.getInvestorDetailRedeem.api(id), id],
    () => getInvestorDetailRedeem(id),
    {
      enabled: enabled && !!id,
    },
  );
};

export const useCreateInvestorRedeem = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IRedemptionForm }) => {
    await createInvestorRedeem(id, data);
    await queryClient.invalidateQueries([ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id)]);
  };
  return useMutation(resp);
};

export const useCreateOffmarketTransfer = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IOffmarketTransfer }) => {
    await createOffmarketTransfer(id, data);
    await queryClient.invalidateQueries([ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id)]);
  };
  return useMutation(resp);
};

export const useGetOffmarketTransfer = (id: string) => {
  return useQuery([ADMINISTRATION_INVESTOR_API.offmarketTransfer.api(id), id], () =>
    getOffmarketTransfer(id),
  );
};

export const useCreateRecordPayment = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IRecordPayment }) => {
    await createRecordPayment(id, data);
    await queryClient.invalidateQueries([ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id)]);
  };
  return useMutation(resp);
};

export const useGetRecordPayment = (id: string) => {
  return useQuery([ADMINISTRATION_INVESTOR_API.recordPayment.api(id), id], () =>
    getRecordPayment(id),
  );
};

export const usePreviewStatement = (options?: any) => {
  const resp = async ({ id, data }: { id: string; data: IPreviewStatement }) => {
    return await previewStatement(id, data);
  };
  return useMutation(resp, options);
};

export const useGenerateStatement = () => {
  const resp = async ({ id, data }: { id: string; data: IGenerateStatement }) => {
    return await generateStatement(id, data);
  };
  return useMutation(resp);
};

export const useDeleteInvestor = () => {
  const resp = async (id: string) => {
    await deleteInvestor(id);
  };
  return useMutation(resp);
};

export const useUpdateCurrentUnitPrice = () => {
  const resp = async ({
    unitClassId,
    data,
  }: {
    unitClassId: string;
    data: { unitPrice: number };
  }) => {
    await updateCurrentUnitPrice(unitClassId, data);
  };
  return useMutation(resp);
};

export const useInvestorExportCSV = () => {
  const resp = async (params: InvestorQueryBody) => {
    const data = await investorExportCSV(params);
    downloadFileFromArrayBuffer(data, `Investor_List_CSV_${dayjs().format(EXPORT_CSV_FORMAT)}.csv`);
  };
  return useMutation(resp);
};

export const useInvestmentEntityExportCSV = () => {
  const resp = async (params: InvestmentEntityQueryBody) => {
    const data = await investmentEntityExportCSV(params);
    downloadFileFromArrayBuffer(
      data,
      `InvestmentEntity_List_CSV_${dayjs().format(EXPORT_CSV_FORMAT)}.csv`,
    );
  };
  return useMutation(resp);
};

export const useExportInvestorProfileCSV = () => {
  return useMutation(exportInvestorProfileCSV);
};

export const useDownloadInvestorProfileCSV = () => {
  const resp = async (documentId: string) => {
    const data = await downloadInvestorProfileCSV(documentId);
    downloadFileFromArrayBuffer(data, `Investor Details_${dayjs().format(EXPORT_CSV_FORMAT)}.zip`);
  };
  return useMutation(resp, {
    retry: true,
    retryDelay: 5000,
  });
};
