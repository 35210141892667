/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IConnectIDFundcorrLogoProps {}

export const ConnectIDFundcorrLogo: React.FunctionComponent<IConnectIDFundcorrLogoProps> = () => {
  return (
    <svg
      width='54px'
      height='54px'
      viewBox='0 0 54 54'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <linearGradient
          x1='92.0953576%'
          y1='-6.44435352%'
          x2='-19.2220828%'
          y2='84.2880524%'
          id='linearGradient-1'
        >
          <stop stopColor='#44B588' offset='0%'></stop>
          <stop stopColor='#39A27F' offset='20%'></stop>
          <stop stopColor='#1F7267' offset='60%'></stop>
          <stop stopColor='#023E4E' offset='100%'></stop>
        </linearGradient>
      </defs>
      <g id='KYC-Verification' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_KYC-Verification_08.2'
          transform='translate(-613, -140)'
        >
          <g id='Group-5' transform='translate(430, 80)'>
            <g id='Group-14' transform='translate(80, 60)'>
              <g id='Group-100' transform='translate(103, 0)'>
                <rect
                  id='Rectangle'
                  fill='url(#linearGradient-1)'
                  x='0'
                  y='0'
                  width='54'
                  height='54'
                  rx='8'
                ></rect>
                <g
                  id='Group-Copy-2'
                  transform='translate(11.25, 9)'
                  fill='#FFFFFF'
                  fillRule='nonzero'
                >
                  <path
                    d='M17.9419026,0 L29.4474802,0 C29.4474802,0 29.4474802,0 29.4474802,0 C29.4474802,3.52782324 26.5803228,6.38543372 23.0407135,6.38543372 L20.210504,6.38543372 C12.9834938,6.38543372 7.11616648,12.2258592 7.11616648,19.4361702 L7.11616648,28.9075286 C7.11616648,32.8256956 3.93125708,36 0,36 L0,36 L0,17.8821604 C0,8.00572831 8.03247452,0.00736497545 17.934513,0.00736497545 L17.9419026,0 Z'
                    id='Path'
                  ></path>
                  <path
                    d='M24.7846546,12.1006547 L32.625,12.1006547 C32.625,15.6284779 29.7578426,18.4860884 26.2182333,18.4860884 L26.2182333,18.4860884 C22.9520385,18.4860884 20.2991789,21.1301146 20.2991789,24.3854337 L20.2991789,29.1505728 C20.2991789,32.9288052 17.2251133,35.992635 13.4342582,35.992635 L13.4342582,35.992635 L13.4342582,23.413257 C13.4342582,17.1677578 18.5182899,12.1006547 24.7846546,12.1006547 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
