import { Box } from '@mui/material';
import React, { ComponentType, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import * as StorageService from 'src/helpers/storage';
import { useAuthenticated } from 'src/modules/auth/hooks';

export default function WithPrivateRoute(Wrapped: ComponentType) {
  const { isAuthenticated } = useAuthenticated();
  const { search, pathname } = window.location;
  const searchParams = new URLSearchParams(search);
  const offerCode = searchParams.get('offerCode');
  const code = searchParams.get('code');
  const email = searchParams.get('email');
  const isInviteOfferPath = pathname === ROUTES_PATH.INVESTOR_OFFER_DETAILS;

  /**
   * Save offer code in local storage when user haven't logged in yet
   * Then redirect user to offer details page after user logged in
   * Cover the redirection works with both code param and offerCode param
   */
  useEffect(() => {
    if (offerCode && !isAuthenticated) {
      StorageService.setValue(STORAGE_KEYS.OFFER_CODE, offerCode);
    }
  }, [offerCode, isAuthenticated]);

  useEffect(() => {
    if (code && isInviteOfferPath && !isAuthenticated) {
      StorageService.setValue(STORAGE_KEYS.OFFER_CODE, code);
    }
  }, [isInviteOfferPath, code, isAuthenticated]);

  return (props: any) =>
    isAuthenticated ? (
      <React.Suspense
        fallback={
          <Box className='flex justify-center items-center h-full'>
            <Box>Loading...</Box>
          </Box>
        }
      >
        <Wrapped {...props} />
      </React.Suspense>
    ) : (
      <Navigate to={`${ROUTES_PATH.LOGIN}${email ? `?email=${email}` : ''}`} />
    );
}
