import { yupResolver } from '@hookform/resolvers/yup';
import _, { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  COMMUNICATION_MESSAGE_TYPE,
  CommunicationCategoryEnum,
  CommunicationDeliveryType,
  communicationDefaultValues,
} from 'src/constants/communications';
import { detectPortalType } from 'src/helpers/common';
import { showToast } from 'src/helpers/toast';
import { IAlertInfo } from 'src/modules/common/type';
import {
  useCreateCommunicationMessage,
  useGetCommunicationReplyMessage,
  useUpdateCommunicationMessage,
} from 'src/modules/communication/hooks';
import {
  ICategoryInvestor,
  ICategoryKYCBO,
  IClientRecipientBody,
  IClientRecipientDetail,
  ICommunicationDetail,
  IFundCategoryInvestor,
  IInternalRecipientBody,
  IInternalRecipientDetail,
  IInvestorRecipientBody,
  IKycBoRecipientBody,
  IUpsertCommunicationForm,
} from 'src/modules/communication/type';
import { handleErrorFromServer } from 'src/utils/common';
import { messageSchema } from 'src/validations/communication';
import MessageButtons from './MessageButtons';
import MessageContent from './MessageContent';

const { isInvestor, isFunds } = detectPortalType();

interface IReplyMessageProps {
  kycBoData?: ICategoryKYCBO;
  repliedId?: string;
  clientData?: IClientRecipientDetail[];
  internalData?: IInternalRecipientDetail[];
  categoryInvestorData: ICategoryInvestor[] | IFundCategoryInvestor[];
  forwardedId?: string;
  categories?: any;
  onRefresh?: () => void;
  onClose?: () => void;
  userMessageId?: string;
}

type ReplyMessageHandler = {
  open: () => void;
};

const ReplyMessage = React.forwardRef<ReplyMessageHandler, IReplyMessageProps>(
  (
    {
      repliedId,
      kycBoData,
      clientData,
      categories,
      internalData,
      categoryInvestorData,
      onRefresh,
      onClose,
      userMessageId,
    },
    ref,
  ): JSX.Element => {
    const alertRef = useRef<IBasicModalElement>(null);
    const upsertDrawerRef = useRef<ICustomDrawerElement>(null);

    const { data: replyMessageDetail } = useGetCommunicationReplyMessage(
      repliedId || '',
      userMessageId,
    );

    const { mutate: updateCommunicationMessage } = useUpdateCommunicationMessage();
    const { mutate: createCommunicationMessage } = useCreateCommunicationMessage();

    const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
      title: '',
      description: '',
    });

    useImperativeHandle(ref, () => ({
      open: () => upsertDrawerRef.current?.open(),
      close: () => upsertDrawerRef.current?.close(),
    }));

    const form = useForm<IUpsertCommunicationForm>({
      defaultValues: {
        ...communicationDefaultValues,
        messageType: COMMUNICATION_MESSAGE_TYPE.Reply,
      },
      resolver: yupResolver(messageSchema),
    });
    const recipientsError =
      form.formState.errors?.clientRecipients?.message ||
      form.formState.errors?.internalRecipients?.userIds?.message ||
      form.formState.errors?.investorRecipients?.message;

    const watchCategory = form.watch('category');

    useEffect(() => {
      if (!isEmpty(replyMessageDetail) && !isEmpty(replyMessageDetail)) {
        const { id, subject, attachments, content, repliedMessage } = replyMessageDetail;
        const {
          category,
          investorRecipients,
          clientRecipients,
          internalRecipients,
          kycboRecipients,
          investorRelationOrder,
        } = repliedMessage as ICommunicationDetail;

        const _internalRecipients: IInternalRecipientBody = {
          userIds: [],
        };
        let _clientRecipients: IClientRecipientBody[] = [];
        let _investorRecipients: IInvestorRecipientBody[] = [];
        const _kycboRecipients: IKycBoRecipientBody = {
          uboIds: [],
          contactIds: [],
        };

        if (category === CommunicationCategoryEnum.Internal && !isEmpty(internalRecipients)) {
          _internalRecipients.userIds = _.map(internalRecipients.recipients, 'id');
        }

        if (category === CommunicationCategoryEnum.ClientManagement && !isEmpty(clientRecipients)) {
          _clientRecipients = clientRecipients.map((client: IClientRecipientDetail) => ({
            clientId: client.clientId,
            adminIds: _.map(client.recipients, 'id'),
          }));
        }

        if (
          category === CommunicationCategoryEnum.InvestorRelation &&
          !isEmpty(investorRecipients)
        ) {
          if (isFunds) {
            _investorRecipients = investorRecipients.map((investor: any) => ({
              investorId: investor?.investorId,
              contactIds: _.map(investor.communicationList, 'id'),
            }));
          }
          if (isInvestor) {
            _investorRecipients = investorRecipients.map((investor: any) => ({
              ...investor,
              investorIds: _.map(investor.investors, 'id'),
            }));
          }
        }

        if (category === CommunicationCategoryEnum.KYCBO && !isEmpty(kycboRecipients)) {
          _kycboRecipients.uboIds = _.map(kycboRecipients.ubOs, 'id');
          _kycboRecipients.contactIds = _.map(kycboRecipients.communicationList, 'id');
          if (
            !_.isEmpty(kycboRecipients.primaryContact) &&
            _kycboRecipients.contactIds.findIndex(
              (f: any) => f.id === kycboRecipients.primaryContact.id,
            ) === -1
          ) {
            _kycboRecipients.contactIds = _kycboRecipients.contactIds.concat(
              kycboRecipients.primaryContact?.id,
            );
          }
        }

        form.reset((formState) => ({
          ...formState,
          id,
          category,
          attachments,
          subject,
          clientRecipients: _clientRecipients,
          internalRecipients: _internalRecipients,
          investorRecipients: _investorRecipients,
          kycboRecipients: _kycboRecipients,
          content,
          repliedId,
          investorRelationOrder,
        }));
      }
    }, [replyMessageDetail]);

    const handleCloseUpsertModal = () => {
      const isDirtyAlt = !!Object.keys(form.formState.dirtyFields).length;
      if (isDirtyAlt) {
        handleSaveMessage();
      } else {
        handleClose();
      }
    };

    const handleShowAlertModal = (alertInfo: IAlertInfo) => {
      setAlertInfo(alertInfo);
      alertRef.current?.open();
    };

    const getEmailContentExcludeFooter = (content: string) => {
      return content.split('<footer>')?.[0];
    };

    const handleSendEmail = (deliveryType: number) => {
      const formData = cloneDeep(form.getValues());
      if (watchCategory === CommunicationCategoryEnum.InvestorRelation) {
        // Only get the content exclude footer for investor relation case
        formData.emailContent = getEmailContentExcludeFooter(formData.content);
      }
      createCommunicationMessage(
        {
          data: {
            ...formData,
            deliveryType,
            externalEmails: (formData?.externalEmails || []).map((it) => it.email),
            userMessageId,
          },
        },
        {
          onSuccess: () => {
            onRefresh?.();
            showToast('Message sent', 'success');
          },
          onError: handleErrorFromServer,
          onSettled: () => handleClose(),
        },
      );
    };

    const handleSaveMessage = () => {
      const formData = cloneDeep(form.getValues());
      if (watchCategory === CommunicationCategoryEnum.InvestorRelation) {
        // Only get the content exclude footer for investor relation case
        formData.emailContent = getEmailContentExcludeFooter(formData.content);
      }
      updateCommunicationMessage(
        {
          data: {
            ...formData,
            externalEmails: (formData?.externalEmails || []).map((it) => it.email),
            userMessageId,
          },
        },
        {
          onSuccess: () => {
            onRefresh?.();
            showToast('Draft saved!');
          },
          onError: handleErrorFromServer,
          onSettled: () => handleClose(),
        },
      );
    };

    const handleClose = () => {
      upsertDrawerRef.current?.close();
      form.reset();
      onClose?.();
    };

    const renderReplyMessageButtons = () => {
      return (
        <MessageButtons
          category={watchCategory}
          onError={() =>
            handleShowAlertModal({
              title: '',
              isError: true,
              description: 'File size exceeds the limit. Please choose a file smaller than 2MB.',
            })
          }
          onSendViaPortal={form.handleSubmit(() =>
            handleSendEmail(CommunicationDeliveryType.Portal),
          )}
          onSendViaPortalAndEmail={form.handleSubmit(() =>
            handleSendEmail(CommunicationDeliveryType.PortalAndEmail),
          )}
        />
      );
    };

    return (
      <>
        <FormProvider {...form}>
          <CustomDrawer
            ref={upsertDrawerRef}
            title='Replying'
            onClose={handleCloseUpsertModal}
            buttonBoxProps={{
              className: 'flex',
            }}
            ButtonComponents={renderReplyMessageButtons()}
          >
            <MessageContent
              kycBoData={kycBoData}
              messageDetail={{
                ...(replyMessageDetail as ICommunicationDetail),
                ..._.pick(replyMessageDetail?.repliedMessage, [
                  'sender',
                  'category',
                  'subject',
                  'investorRecipients',
                  'clientRecipients',
                  'internalRecipients',
                  'kycboRecipients',
                  'investorRelationOrder',
                  'content',
                ]),
              }}
              categories={categories}
              clientData={clientData}
              internalData={internalData}
              categoryInvestorData={categoryInvestorData}
              isCategoryDisabled
              isRecipientDisabled
              isSubjectDisabled
              recipientsError={recipientsError}
              messageType={COMMUNICATION_MESSAGE_TYPE.Reply}
            />
          </CustomDrawer>
        </FormProvider>
        <BasicModal ref={alertRef}>
          <ConfirmationAlert
            isError={alertInfo?.isError}
            title={alertInfo.title}
            description={alertInfo.description}
            buttonAction={{
              label: 'OK',
              onAction: () => {
                alertRef?.current?.close();
              },
            }}
          />
        </BasicModal>
      </>
    );
  },
);

export default ReplyMessage;
