/* eslint-disable no-restricted-imports */
import { AccordionDetails, Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Accordion, AccordionSummary } from 'src/components/atoms/CustomAccordion';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import PartnershipDetailsForm from 'src/components/pages/applications/components/InvestmentEntity/Partnership/PartnershipDetailsForm';
import { investmentEntityPartnershipDefaultValue } from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { INVESTMENT_ENTITY_STEP } from '../../InvestmentEntityForm';
import AddNew from '../AddNew';

interface IPartnershipProps extends IInvestmentEntityProps {}

const Partnership: FC<IPartnershipProps> = (props) => {
  const { id = '' } = props;
  const { reset, watch } = useFormContext<IInvestmentEntityForm>();
  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });
  const allowEditAbn = watch('investmentEntityDetails.allowEditAbn');

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntityPartnershipDefaultValue, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                Partnership Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <PartnershipDetailsForm {...props} disabledAbn={!allowEditAbn} hideUBODeclaration />
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default Partnership;
