/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IKYCIconProps {}

export const KYCIcon: React.FunctionComponent<IKYCIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='34' height='45.37' viewBox='0 0 34 45.37'>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' fillRule='nonzero' transform='translate(-448 -310)'>
          <g transform='translate(290 54)'>
            <g transform='translate(0 213)'>
              <g transform='translate(80 32)'>
                <g transform='translate(60)'>
                  <path d='M18 13v41.37a2 2 0 002 2h30a2 2 0 002-2V22.558a2 2 0 00-.586-1.414l-9.557-9.558A2 2 0 0040.442 11H20a2 2 0 00-2 2zm29.283 14.833a1.086 1.086 0 00-1.537.001l-2.096 2.103a.987.987 0 01-1.399-.002.988.988 0 00-1.4-.003l-.068.068a1.088 1.088 0 000 1.538l1.398 1.398a1.083 1.083 0 001.538 0l3.564-3.564a1.088 1.088 0 000-1.539zM26.09 14.475a2.616 2.616 0 110 5.23 2.616 2.616 0 01-.001-5.23zm-4.094 9.452a4.22 4.22 0 118.44 0 .936.936 0 01-.936.935h-6.568a.936.936 0 01-.936-.935zm.71 3.89H34.92a.71.71 0 110 1.419H22.706a.71.71 0 110-1.419zm0 5.343H34.92a.71.71 0 110 1.418H22.706a.71.71 0 110-1.418zm25.59 15.234h-26.05a.71.71 0 110-1.42h26.05a.71.71 0 010 1.42zm0-4.389H22.245a.71.71 0 010-1.42h26.05a.71.71 0 010 1.42zm.001-4.086H22.244a.709.709 0 110-1.417h26.053a.709.709 0 010 1.417zm-8.26-18.902l-.01-2.74a1.95 1.95 0 013.329-1.387l2.78 2.78a1.954 1.954 0 01-1.38 3.337l-2.717.002a2 2 0 01-2.002-1.992z'></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
