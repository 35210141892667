import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import IndividualsIcon from 'src/assets/icons/IndividualsIcon';
import OtherIcon from 'src/assets/icons/OtherIcon';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';
import UploadCertified from 'src/components/pages/applications/components/InvestmentEntity/shared/UploadCertified';
import {
  DEFAULT_UBOS_POSITION,
  INVESTMENT_ENTITY_TYPE,
  PARTNERSHIP_TYPE,
  PARTNERS_DESCRIPTION,
  PARTNER_TYPE,
  UBOS_META,
  abnAcnNotAllowedStatus,
  individualDefaultValue,
  otherPartnerDefaultValue,
  partnershipTypeOptions,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { showToast } from 'src/helpers/toast';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, ISkyc } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import PartnerItem from './PartnerItem';

interface IPartnershipDetailsFormProps extends IInvestmentEntityProps {
  keyName?: string;
  hideUBODeclaration?: boolean;
  disabledAbn?: boolean;
}

const PartnershipDetailsForm: FC<IPartnershipDetailsFormProps> = (props) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    isInvestmentEntitySetting = false,
    hideUbo,
    hideAbnSearch,
    isReviewing,
    hideUBODeclaration,
    disabledAbn,
  } = props;
  const { countryOptions } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;

  const partnerTypeModalRef = useRef<IBasicModalElement>(null);
  const { watch, control, clearErrors, setValue, setError, getFieldState } =
    useFormContext<IInvestmentEntityForm>();

  const {
    fields: partners,
    append: addPartner,
    remove: removePartner,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.partnership.partners',
    keyName: 'dataId',
  });

  const {
    fields: ubOs,
    append: addUbO,
    remove: removeUBO,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.partnership.ubOs',
    keyName: 'dataId',
  });

  const { error: partnersError } = getFieldState('investmentEntityDetails.partnership.partners');
  const { error: ubOsError } = getFieldState('investmentEntityDetails.partnership.ubOs');
  const watchPartnershipType = watch('investmentEntityDetails.partnership.type');
  const watchIsRegulated = watch('investmentEntityDetails.partnership.partnershipRegulated');
  const watchPartnerType = watch('investmentEntityDetails.partnership.partnerType');

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Partnership,
  });

  const handleRemovePartner = (index: number) => {
    if (index === 0) {
      const partnersTemp = [...partners];
      if (partnersTemp[1]) {
        partnersTemp[1].beVerified = true;

        if (partnersTemp[1]?.partnerType === PARTNER_TYPE.Individuals) {
          addUbO(individualDefaultValue());
        }
      }
      setValue('investmentEntityDetails.partnership.partners', partnersTemp);
    }
    removePartner(index);
  };

  const handleAddPartner = () => {
    if (!Number.isInteger(watchPartnerType)) return;
    const beVerified = !partners?.length;

    if (watchPartnerType === PARTNER_TYPE.Individuals) {
      addPartner({
        ...individualDefaultValue(beVerified),
        partnerType: PARTNER_TYPE.Individuals,
        position: DEFAULT_UBOS_POSITION.PARTNER,
        beVerified,
      });
    } else {
      addPartner({
        ...individualDefaultValue(),
        ...otherPartnerDefaultValue,
        partnerType: PARTNER_TYPE.Other,
        beVerified,
      });
    }
    handleClosePartnerTypeModal();
  };

  const handleAddUBO = () => {
    addUbO(individualDefaultValue());
  };

  const handleOpenPartnerTypeModal = () => {
    partnerTypeModalRef.current?.open();
  };

  const handleClosePartnerTypeModal = () => {
    partnerTypeModalRef.current?.close();
  };

  const getPartnersDescription = () => {
    if (typeof watchIsRegulated !== 'boolean') return '';

    return watchIsRegulated ? PARTNERS_DESCRIPTION.REGULATED : PARTNERS_DESCRIPTION.UNREGULATED;
  };

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    if (reason === 'selectOption') {
      clearErrors('investmentEntityDetails.partnership.abn');

      const isEntityTypeMatch = handleCheckEntityTypeMatch(
        skyc as OptionType & ISkyc,
        'partnership',
      );
      const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

      if (isEntityTypeMatch) {
        setValue('investmentEntityDetails.partnership.abn', skyc.value);
        setValue('investmentEntityDetails.partnership.partnershipName', skyc.label);
        if (isNotAllowed) {
          setError('investmentEntityDetails.partnership.abn', {
            message: 'ABN is invalid',
            type: 'invalidAbn',
          });
        }
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const options = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  return (
    <>
      <Grid container rowSpacing='18px' columnSpacing='20px'>
        {!isExistingInvestmentEntity && !isInvestmentEntitySetting && !hideAbnSearch && (
          <Grid item xs={12}>
            <FormAutocomplete
              name=''
              dataKey='id'
              options={options || []}
              searchIcon={<SearchIcon />}
              isLoading={isSearchSkycLoading}
              placeholder='Search by Business Name, ABN'
              hidePopupIcon
              renderOption={(props, option) => (
                <li {...props}>
                  <Box className='flex flex-col'>
                    <Typography variant='body3' fontWeight='medium'>
                      {option.label}
                    </Typography>
                    <Typography variant='caption1' color='neutral.ne500' paddingTop='4px'>
                      {`ABN: ${option.value}`}
                    </Typography>
                  </Box>
                </li>
              )}
              renderTags={(selected) => (
                <Typography variant='body2'>
                  <span className='line-clamp-1'>
                    {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
                  </span>
                </Typography>
              )}
              filterOptions={(options) => options}
              getOptionLabel={() => ''}
              onInputChange={handleSearchSkycInputChange}
              onChange={handleSkycChange}
              onFocus={() => clearErrors('investmentEntityDetails.partnership.abn')}
              disabled={disabled}
              blurOnSelect
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.partnership.partnershipName'
            label='Partnership Name'
            placeholder='Enter partnership name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.partnership.abn'
            label='ABN'
            placeholder='Enter ABN'
            maxLength={14}
            formatValue={(value) => formatValueWithSpaces(value)}
            disabled={disabled || disabledAbn}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.partnership.countryOfEstablishment'
            options={countryOptions}
            label='Country of Establishment'
            placeholder='Select country of establishment'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.partnership.countryOfTaxResident'
            options={countryOptions}
            label='Country of Tax Residence'
            placeholder='Select country of tax residence'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.partnership.businessName'
            label='Business Name'
            placeholder='Enter business name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.partnership.principalPlaceOfBusiness'
            label='Principal Place of Business'
            placeholder='Enter principal place of business'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.partnership.type'
            options={partnershipTypeOptions}
            label='Type'
            placeholder='Select type'
            disabled={disabled}
          />
        </Grid>
        {watchPartnershipType === PARTNERSHIP_TYPE.Other && (
          <Grid item xs={6} alignSelf='flex-end'>
            <FormInput
              name='investmentEntityDetails.partnership.otherType'
              label={<Typography />}
              placeholder='Enter other type'
              disabled={disabled}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormRadioGroup
            name='investmentEntityDetails.partnership.partnershipRegulated'
            options={TrueFalseRadioOptions}
            label='Is the Partnership regulated by a professional association?'
            optionGap={10}
            isBooleanValue
            disabled={disabled}
            showPickerOnly={isReviewing}
          />
        </Grid>
        {watchIsRegulated && (
          <>
            <Grid item xs={6}>
              <FormInput
                name='investmentEntityDetails.partnership.nameOfAssociation'
                label='Name of Association'
                placeholder='Enter name of association'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='investmentEntityDetails.partnership.membershipDetails'
                label='Membership Details'
                placeholder='Enter membership details'
                disabled={disabled}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Divider sx={{ mt: 3 }} />
      <UploadCertified
        {...props}
        title='Upload Certified Partnership Agreement'
        fileTableTitle={
          <Typography variant='body3' fontWeight={500}>
            Files Uploaded
          </Typography>
        }
        keyName='investmentEntityDetails.partnership'
      />
      <Divider sx={{ my: 3 }} />
      <FormArrayLayout
        {...props}
        title='Partners'
        onAddItem={handleOpenPartnerTypeModal}
        disabled={disabled}
        helperText={<CustomHelperText variant='error' message={partnersError?.message} />}
        description={getPartnersDescription()}
      >
        <Grid container rowSpacing='16px' mb='16px'>
          {(partners || []).map((partner, index) => (
            <Grid key={partner.dataId} item xs={12}>
              <PartnerItem
                {...props}
                keyName={`investmentEntityDetails.partnership.partners.${index}`}
                onDelete={() => handleRemovePartner(index)}
                canDelete={!disabled}
                beVerified={partner?.beVerified}
                partnerType={partner?.partnerType || 0}
              />
            </Grid>
          ))}
        </Grid>
      </FormArrayLayout>
      <Divider sx={{ my: 3 }} />
      {!hideUbo && (
        <FormArrayLayout
          {...props}
          title='Ultimate Beneficial Owners'
          tooltip={UBOS_META.PARTNERSHIP.TOOLTIP}
          description={UBOS_META.PARTNERSHIP.DESCRIPTION}
          disabled={disabled}
          onAddItem={handleAddUBO}
          helperText={<CustomHelperText variant='error' message={ubOsError?.message} />}
        >
          <Grid container rowSpacing='16px' mb='16px'>
            {(partners || []).map(
              (partner, index) =>
                partner?.refId && (
                  <Grid key={partner.dataId} item xs={12}>
                    <IndividualDetail
                      {...props}
                      keyName={`investmentEntityDetails.partnership.partners.${index}`}
                      disabledDetail
                      hasPosition
                      hideTfn
                      autocompleteAddress
                      hideDeclaration={hideUBODeclaration}
                    />
                  </Grid>
                ),
            )}
            {ubOs.map((ubOs, index) => (
              <Grid key={ubOs.dataId} item xs={12}>
                <IndividualDetail
                  {...props}
                  keyName={`investmentEntityDetails.partnership.ubOs.${index}`}
                  handleDelete={() => removeUBO(index)}
                  canDelete
                  hasPosition
                  hideTfn
                  autocompleteAddress
                  hideDeclaration={hideUBODeclaration}
                />
              </Grid>
            ))}
          </Grid>
        </FormArrayLayout>
      )}
      <BasicModal ref={partnerTypeModalRef}>
        <Box className='px-10 py-12 flex flex-col min-w-[485px]'>
          <Typography variant='subtitle1' fontWeight='bold' mb={3}>
            Select Partner Type
          </Typography>
          <FormRadioGroup
            name='investmentEntityDetails.partnership.partnerType'
            label=''
            options={[
              {
                icon: <IndividualsIcon />,
                value: PARTNER_TYPE.Individuals,
                label: 'Individual',
              },
              {
                icon: <OtherIcon />,
                value: PARTNER_TYPE.Other,
                label: 'Other',
              },
            ]}
            isEnumValue
            optionGap={2}
            showPickerOnly={isReviewing}
          />
          <CustomButton
            sx={{ mt: 3, alignSelf: 'flex-end' }}
            disabled={!Number.isInteger(watchPartnerType)}
            onClick={handleAddPartner}
          >
            Next
          </CustomButton>
        </Box>
      </BasicModal>
    </>
  );
};

export default PartnershipDetailsForm;
