import { Menu, MenuItem, MenuProps, Typography, styled } from '@mui/material';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';

export interface IMenuItem {
  icon?: ReactNode;
  title: ReactNode;
  onClick: (params?: any) => void;
  hidden?: boolean;
}

type ICustomMenuProps = Omit<MenuProps, 'open'> & {
  menuItems: IMenuItem[];
};

export interface ICustomMenuElement {
  open: (anchorEl: HTMLElement | null) => void;
  close: () => void;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: theme.palette.neutral.ne200,
  },
}));

const StyledMenu = styled(Menu)(() => ({
  transform: 'translateY(6px)',
  '& .MuiMenu-list': {
    padding: 4,
    margin: 0,
  },
}));

export const CustomMenu = forwardRef<ICustomMenuElement, ICustomMenuProps>(
  ({ menuItems, ...rest }, ref): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    useImperativeHandle(ref, () => ({
      open(anchorEl) {
        setAnchorEl(anchorEl);
      },
      close() {
        handleClose();
      },
    }));

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} {...rest}>
        {menuItems?.map(({ title, icon, onClick, hidden }: IMenuItem, index: number) => (
          <StyledMenuItem
            key={index}
            onClick={() => {
              onClick();
              handleClose();
            }}
            sx={{
              display: hidden ? 'none' : 'flex',
            }}
          >
            {icon}
            <Typography component='span' variant='body3' sx={{ ml: 1 }} color='neutral.ne800'>
              {title}
            </Typography>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    );
  },
);
