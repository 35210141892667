import { Box, FormControl, FormControlLabel, SwitchProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import CustomHelperText from './CustomHelperText';
import { CustomSwitch } from './CustomSwitch';

interface IFormSwitchProps extends SwitchProps {
  name: string;
  label?: ReactNode;
  rules?: Record<string, unknown>;
  handleChange?: (_: boolean) => void;
}

const FormSwitch: React.FC<IFormSwitchProps> = ({
  name,
  label = null,
  defaultValue = false,
  rules = {},
  handleChange,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormControlLabel
            control={
              <CustomSwitch
                {...rest}
                checked={value}
                onChange={(e, checked) => {
                  onChange(e, checked);
                  handleChange && handleChange(checked);
                }}
              />
            }
            label={label}
            sx={{ mr: 0 }}
          />
          {invalid && (
            <Box ml='-10px' mt={0.5}>
              <CustomHelperText variant={'error'} message={error?.message} />
            </Box>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormSwitch;
