/* eslint-disable no-empty-pattern */
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import FormInput from 'src/components/atoms/FormInput';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';
import { formatValueWithSpaces } from 'src/utils/common';

interface ISoleTraderProps {
  viewOnly?: boolean;
  isEditMode?: boolean;
  isViewMode?: boolean;
}

const SoleTrader: React.FC<ISoleTraderProps> = (props) => {
  const additionalDetail = (
    <>
      <Grid item xs={12}>
        <FormInput
          name='investorDetails.entityDetails.soleTrader.abn'
          label='ABN'
          placeholder='Enter ABN'
          formatValue={(value) => formatValueWithSpaces(value)}
          maxLength={14}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name='investorDetails.entityDetails.soleTrader.businessName'
          label='Business Name (if any)'
          placeholder='Enter your business name'
          optional='Optional'
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name='investorDetails.entityDetails.soleTrader.principalPlaceOfBusiness'
          label='Principal Place of Business'
          placeholder='Enter principal place of business'
          disabled
        />
      </Grid>
    </>
  );

  return (
    <Box>
      <Box pt={2} className='flex flex-col gap-4'>
        <IndividualDetail
          {...props}
          keyName={`investorDetails.entityDetails.soleTrader`}
          additionalDetail={additionalDetail}
        />
      </Box>
    </Box>
  );
};

export default SoleTrader;
