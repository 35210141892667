const IndividualsIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-494.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1' transform='translate(0.000000, 43.000000)'>
                  <g id='individual' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g mask='url(#mask-2)'>
                      <g transform='translate(8.000000, 9.000000)'>
                        <path
                          d='M34,39.0176506 C34,41.2179561 32.2517901,43 30.0932598,43 L5.90674021,43 C3.74503382,43 2,41.2182644 2,39.0176506 C2,34.5125128 3.79025527,30.4350409 6.68533564,27.4839305 C9.67161513,24.439856 13.710819,22.5826344 18.0000756,22.7079171 C28,23 34,30.0056792 34,39.0176506 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M36,43 C37.3333333,53 25.3333333,53.3333333 9.09494702e-13,44 C9.09494702e-13,40.6666667 1,37 3,33 C3.16394638,32.6721072 4.3398813,30.2007569 5,30 C7.30281909,29.299661 14.6361524,27.9663276 27,26 C31.6666667,27.3333333 34.6666667,33 36,43 Z'
                          id='Path-2'
                          fill='#3C6D91'
                          fillRule='evenodd'
                        ></path>
                        <path
                          d='M35,37 C35.1077009,37.4765553 35.4410342,39.8098887 36,44 C33.3399962,44 31.6733295,44 31,44 C29.9900057,44 0,47 0,44 C0,42 0.333333333,40 1,38 C6.8999028,38.6666667 12.5665695,39 18,39 C26.1501458,39 29.6345419,30.8654818 26.0555437,24.9315022 C33,28 34.8384486,36.285167 35,37 Z'
                          id='Path'
                          fill='#000000'
                          fillRule='nonzero'
                          opacity='0.12'
                        ></path>
                        <path
                          d='M21.4111948,20.6620894 L21.4111948,23.9304804 C21.4111948,25.8508142 19.8839499,27.4076167 18.0000756,27.4076167 C16.1162014,27.4076167 14.5889565,25.8508142 14.5889565,23.9304804 L14.5889565,20.6620894 L21.4111948,20.6620894 Z'
                          id='Path'
                          fill='#FFCDBE'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M21.5170644,22.8298651 L18.0011343,27.2830478 L20.005095,28.8343002 C20.9249507,29.5464086 22.2537657,29.2240404 22.7604274,28.1659759 L24.5240641,24.4831776 C24.7441216,24.0234445 24.5465992,23.4693597 24.0881837,23.2612311 L22.5688034,22.5710147 C22.2011334,22.4040493 21.7693366,22.510272 21.5170644,22.8298651 L21.5170644,22.8298651 Z'
                          id='Path'
                          fill='#E8F1F8'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M14.4829356,22.8298651 L17.9988657,27.2830478 L15.994905,28.8343002 C15.0750493,29.5464086 13.7462343,29.2240404 13.2395726,28.1659759 L11.4759359,24.4831776 C11.2558784,24.0234445 11.4534008,23.4693597 11.9118163,23.2612311 L13.4311966,22.5710147 C13.7988666,22.4040493 14.2306634,22.510272 14.4829356,22.8298651 L14.4829356,22.8298651 Z'
                          id='Path'
                          fill='#E8F1F8'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M26.3763287,6.76510681 L26.3763287,13.3532272 L9.62669616,13.3532272 L9.62669616,6.76510681 C9.62669616,3.02881175 12.5980027,0 16.2633601,0 L19.7396647,0 C23.4050222,0 26.3763287,3.02881175 26.3763287,6.76510681 Z'
                          id='Path'
                          fill='#2C3033'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M26.9575529,13.6876206 C26.7750034,14.9334634 26.025749,15.8641839 25.2952486,15.7448568 C25.2811831,15.7448568 25.262429,15.7400776 25.253052,15.7352983 L25.253052,16.9524656 C25.253052,18.9905849 23.4361781,20.713582 21.6288325,21.9307493 C21.6053899,21.9403078 21.5913244,21.9546455 21.568033,21.964204 C20.486348,22.6849459 19.2455561,23.0477065 17.9999244,23.0477065 C16.7544439,23.0477065 15.5135007,22.6849459 14.4318157,21.964204 C14.4083732,21.9546455 14.3943076,21.9403078 14.3710163,21.9307493 C12.5635194,20.713582 10.7467967,18.9905849 10.7467967,16.9524656 L10.7467967,15.7352983 C10.7374197,15.7400776 10.7186657,15.7448568 10.7046001,15.7448568 C9.97409975,15.8641839 9.22499657,14.9334634 9.04229586,13.6876206 C8.85505787,12.4417778 9.30923854,11.3345332 10.0444274,11.2247646 C10.2926161,11.1865306 10.5361162,11.2582194 10.7608623,11.4251848 C10.9248089,9.39662405 12.605716,7.79757918 14.637959,7.79757918 L21.362041,7.79757918 C23.394284,7.79757918 25.0751911,9.39662405 25.2391377,11.4251848 C25.4638838,11.2580652 25.7073839,11.1865306 25.9555726,11.2247646 C26.6906102,11.3345332 27.1449421,12.4419319 26.9575529,13.6876206 L26.9575529,13.6876206 Z'
                          id='Path'
                          fill='#FFDECF'
                          fillRule='nonzero'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default IndividualsIcon;
