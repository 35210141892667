/* eslint-disable @typescript-eslint/no-empty-function */
import { cloneDeep, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import * as storage from 'src/helpers/storage';
import { useAuthenticated } from 'src/modules/auth/hooks';
import { useGetBranding } from 'src/modules/organisation-settings/hooks';
import { BrandingResponse } from 'src/modules/organisation-settings/type';
import { useFundPortalContext } from './FundPortalProvider';

export const BrandingDefault = {
  backgroundNavigation: '#002A37',
  backgroundActive: '#002A37',
  text: '#FFFFFF',
  primaryBackground: '#002A37',
  primaryText: '#FFFFFF',
  contactLawyerBackground: '#33B27F',
  contactLawyerText: '#FFFFFF',
  hyperlinkText: '#002A37',
  defaultToStandard: false,
};

export const BrandingContext = React.createContext<{
  branding: BrandingResponse | undefined;
  readOnly: boolean;
  onRefresh: () => void;
  setReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  branding: {
    ...BrandingDefault,
    logoLink: '',
    id: '',
  },
  readOnly: false,
  onRefresh: () => {},
  setReadOnly: () => {},
});

interface IBrandingContextProvider {
  children: React.ReactNode;
}

const BrandingContextProvider: React.FC<IBrandingContextProvider> = ({ children }) => {
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const { isAuthenticated } = useAuthenticated();
  const { isFAUser, selectedClients } = useFundPortalContext();
  /**
   * FM user case: Only enable to fetch branding when selected clients are not empty
   * Always allow to fetch with other cases
   */
  const { data: branding, refetch } = useGetBranding(
    isAuthenticated && !isFAUser ? !isEmpty(selectedClients) : true,
  );
  const preBranding = React.useRef<BrandingResponse | undefined>(
    JSON.parse(storage.getValue(STORAGE_KEYS.BRANDING_KEY) || 'null'),
  );
  const [standardBranding, setStandardBranding] = useState<BrandingResponse>();

  const onRefresh = () => refetch();

  React.useEffect(() => {
    if (branding) {
      /**
       * Handle for the first time user log in to portal
       * Set default value for the color param that was null returned from the API for the first time
       */
      let cloneBranding = cloneDeep(branding);
      Object.keys(cloneBranding || {}).forEach((key) => {
        if (!get(cloneBranding, key)) {
          cloneBranding = { ...cloneBranding, [key]: get(BrandingDefault, key) };
        } else if (get(cloneBranding, key) === 'white') {
          cloneBranding = { ...cloneBranding, [key]: '#FFFFFF' };
        }
      });
      setStandardBranding(cloneBranding);
    }
  }, [branding]);

  useEffect(() => {
    if (standardBranding) {
      preBranding.current = standardBranding;
      storage.setValue(STORAGE_KEYS.BRANDING_KEY, JSON.stringify(standardBranding));
    }
  }, [standardBranding]);

  return (
    <BrandingContext.Provider
      value={{
        branding: standardBranding || preBranding.current,
        readOnly,
        setReadOnly,
        onRefresh,
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};

export default BrandingContextProvider;
