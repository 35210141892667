/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ChevronDownIcon } from 'src/assets/icons/ChevronDownIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import RoundedCloseIcon from 'src/assets/icons/RoundedCloseIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import FormSelect from 'src/components/atoms/FormSelect';
import { useGetExisting } from 'src/modules/funds-management/hooks';
import { INewExisting, IServiceProviderType } from 'src/modules/funds-management/type';
import { ACTION_MODE, SECTIONS } from './TrusteeAndServiceProviders';

interface ITrusteeAndServiceProvidersSectionProps {
  title: string;
  name: string;
  isOptional?: boolean;
  capacities: IServiceProviderType[];
  serviceProviderType: number;
  isViewMode: boolean;
  onAddNew: () => void;
  onClickProvider: (mode: string, existingItem: INewExisting, section: string) => void;
}

const MAX_SERVICE_PROVIDERS = 10;

const TrusteeAndServiceProvidersSection: React.FC<ITrusteeAndServiceProvidersSectionProps> = ({
  title,
  name,
  isOptional = false,
  capacities = [],
  serviceProviderType,
  isViewMode = false,
  onAddNew,
  onClickProvider,
}) => {
  const { watch, setValue } = useFormContext();
  const watchExisting = watch(`${name}.existing`);
  const watchCapacity = watch(`${name}.capacity`);
  const watchNewExisting = watch(`${name}.newExisting`);
  const watchClientId = watch('clientId');

  const { data: existingData = [] } = useGetExisting({
    clientId: watchClientId,
    capacityIds: watchCapacity,
    serviceProviderType,
  });
  const isServiceProvidersSection = name === SECTIONS.serviceProviders.name;

  const disabledAddNew = React.useMemo(() => {
    if (isServiceProvidersSection) {
      return (
        [...(watchExisting || []), ...(watchNewExisting || [])].length >= MAX_SERVICE_PROVIDERS
      );
    }

    return watchExisting || watchNewExisting?.length;
  }, [watchExisting, watchNewExisting]);

  const handleResetExisting = () => {
    if (isViewMode) return;
    setValue(`${name}.existing`, '');
    setValue(`${name}.newExisting`, []);
    setValue(`${name}.isAddNew`, false);
  };

  const handleRemoveExisting = (existingItem: INewExisting, isAddNew?: boolean) => {
    if (isViewMode) return;
    const existing = [...(watchExisting || [])].filter((item) => item !== existingItem.id);
    const newExisting = [...(watchNewExisting || [])].filter((item) => item.id !== existingItem.id);

    isAddNew
      ? setValue(`${name}.newExisting`, newExisting)
      : setValue(`${name}.existing`, existing);
  };

  const renderExistingItem = (existingItem: INewExisting, isAddNew?: boolean) => (
    <Box
      className='p-4 mt-3 mb-6 rounded-[10px] flex justify-between items-center'
      bgcolor='neutral.ne200'
      key={existingItem.id}
    >
      <Typography variant='body2' fontWeight={600}>
        {`${existingItem.capacityNames?.join(', ')} - ${existingItem.displayName}`}
      </Typography>
      <Box>
        <CustomButton
          color='primary'
          variant='text'
          sx={{ p: 0, mr: 1, color: 'GrayText' }}
          onClick={() =>
            onClickProvider(isAddNew ? ACTION_MODE.edit : ACTION_MODE.view, existingItem, name)
          }
        >
          {isAddNew ? 'Edit' : 'View'}
        </CustomButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={
            isServiceProvidersSection
              ? () => handleRemoveExisting(existingItem, isAddNew)
              : handleResetExisting
          }
        >
          <RoundedCloseIcon />
        </IconButton>
      </Box>
    </Box>
  );

  const renderSelectedExisting = () => {
    let selectedExisting = [];
    const hasSelectedExisting =
      watchExisting instanceof Array ? !!watchExisting?.length : !!watchExisting;
    const hasNewExisting =
      watchNewExisting instanceof Array ? !!watchNewExisting?.length : !!watchNewExisting;

    selectedExisting = (existingData || []).filter((existing) => {
      if (watchExisting instanceof Array) {
        return watchExisting.includes(existing.id);
      }

      return existing.id === watchExisting;
    });

    return (
      <>
        {hasSelectedExisting && (
          <>
            <Typography color='neutral.ne800' variant='body2'>
              Selected:
            </Typography>
            {selectedExisting.map((item) => {
              return renderExistingItem(item);
            })}
          </>
        )}
        {hasNewExisting && (
          <>
            <Typography color='neutral.ne800' variant='body2'>
              {`New ${title}`}
            </Typography>
            {(watchNewExisting || []).map((item: INewExisting) => {
              return renderExistingItem(item, true);
            })}
          </>
        )}
      </>
    );
  };

  const capacityOptions = (capacities || []).map(({ id, name }) => ({
    id,
    label: name,
    value: id,
  }));

  const existingOptions = (existingData || []).map(({ id, displayName }) => ({
    id,
    label: displayName,
    value: id,
  }));

  return (
    <Box className='w-full'>
      <Box className='section-header pb-2.5 flex justify-between items-center'>
        <Box className='flex items-center'>
          <ChevronDownIcon />
          <Typography variant='body2' fontWeight={600} paddingLeft={0.5}>
            {title}
          </Typography>
        </Box>
        {isOptional && (
          <Typography color='neutral.ne800' variant='body2' fontWeight='medium'>
            Optional
          </Typography>
        )}
      </Box>
      <Box
        className='section-body border-t p-6'
        bgcolor='neutral.ne100'
        borderColor='neutral.ne300'
      >
        <Box className='pb-6 border-b' borderColor='neutral.ne300'>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {isServiceProvidersSection ? (
                <FormMultiSelect
                  name={`${name}.capacity`}
                  label='Capacity'
                  options={capacityOptions}
                  placeholder='Select capacity'
                  readOnly={isViewMode}
                />
              ) : (
                <FormSelect
                  name={`${name}.capacity`}
                  label='Capacity'
                  options={capacityOptions}
                  placeholder='Select capacity'
                  readOnly={isViewMode}
                  disabled={watchNewExisting?.length || watchExisting}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isServiceProvidersSection ? (
                <FormMultiSelect
                  name={`${name}.existing`}
                  label='Select Existing'
                  options={existingOptions}
                  readOnly={!watchCapacity?.length || isViewMode}
                  placeholder='Select existing'
                />
              ) : (
                <FormSelect
                  name={`${name}.existing`}
                  label='Select Existing'
                  options={existingOptions}
                  readOnly={isViewMode}
                  disabled={watchNewExisting?.length || watchExisting}
                  placeholder='Select existing'
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className='pt-6'>
          {renderSelectedExisting()}
          <CustomButton
            startIcon={<PlusPrimaryIcon disabled={disabledAddNew || isViewMode} />}
            variant='text'
            sx={{ px: 1, color: 'GrayText' }}
            onClick={onAddNew}
            disabled={disabledAddNew || isViewMode}
          >
            Add new
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TrusteeAndServiceProvidersSection;
