import { FC, SVGProps } from 'react';

export const CheckCircleIcon: FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <>
      <svg
        width='60px'
        height='60px'
        viewBox='0 0 60 60'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='00_Forgot-Password_10' transform='translate(-691.000000, -275.000000)'>
            <g id='Modal' transform='translate(478.000000, 215.000000)'>
              <g id='Icon' transform='translate(213.000000, 60.000000)'>
                <g id='01.Design-Token/02.Background/03.Soild/50' fill='#E3F4EC'>
                  <rect id='Background' x='0' y='0' width='60' height='60' rx='30' />
                </g>
                <g
                  id='02.Icons/Default/-Communication-/-mail_outline'
                  transform='translate(12.000000, 12.000000)'
                >
                  <g
                    id='-Round-/-Action-/-check_circle_outline'
                    transform='translate(-0.000000, 0.000000)'
                  >
                    <polygon id='Path' points='0 0 35 0 35 35 0 35' />
                    <path
                      d='M17.5,2.91666667 C9.45,2.91666667 2.91666667,9.45 2.91666667,17.5 C2.91666667,25.55 9.45,32.0833333 17.5,32.0833333 C25.55,32.0833333 32.0833333,25.55 32.0833333,17.5 C32.0833333,9.45 25.55,2.91666667 17.5,2.91666667 Z M17.5,29.1666667 C11.06875,29.1666667 5.83333333,23.93125 5.83333333,17.5 C5.83333333,11.06875 11.06875,5.83333333 17.5,5.83333333 C23.93125,5.83333333 29.1666667,11.06875 29.1666667,17.5 C29.1666667,23.93125 23.93125,29.1666667 17.5,29.1666667 Z M23.1583333,12.0895833 L14.5833333,20.6645833 L11.8416667,17.9229167 C11.2729167,17.3541667 10.3541667,17.3541667 9.78541667,17.9229167 C9.21666667,18.4916667 9.21666667,19.4104167 9.78541667,19.9791667 L13.5625,23.75625 C14.13125,24.325 15.05,24.325 15.61875,23.75625 L25.2291667,14.1458333 C25.7979167,13.5770833 25.7979167,12.6583333 25.2291667,12.0895833 C24.6604167,11.5208333 23.7270833,11.5208333 23.1583333,12.0895833 Z'
                      id='🔹Icon-Color'
                      fill='#33B27F'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
