import { Box, Divider } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FINANCIAL_STEP,
  INVESTMENT_APPLICATION_STEP,
} from 'src/components/pages/applications/components/InvestmentApplicationForm';
import TaxDetailsSection from 'src/components/pages/applications/components/TaxDetails/components/TaxDetailsSection';
import TaxInformationSection from 'src/components/pages/applications/components/TaxDetails/components/TaxInformationSection';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { useGetKycBoTaxDetails } from 'src/modules/kyc-back-office/hooks';

interface ITaxInformationProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const TaxInformation: FC<ITaxInformationProps> = (props) => {
  const { id = '' } = props;
  const { reset } = useFormContext();

  const { data: applicationTax } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.TAX_DETAILS,
  });

  const { data: taxDetails } = useGetKycBoTaxDetails(id);
  const {
    directors,
    taxNoTINReasons,
    isForeignTaxResident,
    isAnyIndividualContacts,
    allowForeignInvestors,
    individualTaxPersons,
    foreignControllingPersons,
  } = taxDetails || {};

  useEffect(() => {
    if (!isEmpty(applicationTax?.financialTax)) {
      reset({
        ...applicationTax?.financialTax,
      });
    }
  }, [applicationTax?.financialTax]);

  return (
    <>
      <Box
        sx={{
          '.MuiAccordion-root': {
            background: 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'neutral.ne300',
          },
        }}
      >
        <TaxDetailsSection
          {...props}
          isForeignTaxResident={isForeignTaxResident}
          isAnyIndividualContacts={isAnyIndividualContacts}
          allowForeignInvestors={allowForeignInvestors}
          individualTaxPersons={individualTaxPersons}
        />
      </Box>
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      <Box
        sx={{
          '.tax-information-title': {
            mb: 2,
          },
          '.tax-information-container': {
            p: '0 !important',
            border: 'none !important',
          },
          '.MuiAccordion-root': {
            background: 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'neutral.ne300',
          },
        }}
      >
        <TaxInformationSection
          {...props}
          directors={directors}
          taxNoTINReasons={taxNoTINReasons}
          isForeignTaxResident={isForeignTaxResident}
          isAnyIndividualContacts={isAnyIndividualContacts}
          allowForeignInvestors={allowForeignInvestors}
          foreignControllingPersons={foreignControllingPersons}
          allowExpanded
        />
      </Box>
    </>
  );
};

export default TaxInformation;
