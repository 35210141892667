import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomTextField } from 'src/components/atoms/FormInput';
import { MAX_UNIT_PRICE } from 'src/constants/unit-class';
import { useUpdateCurrentUnitPrice } from 'src/modules/administration-investor/hooks';
import { currencyStringToNumber, handleErrorFromServer } from 'src/utils/common';

interface IUpdateUnitPriceModalProps {
  onClose: () => void;
  unitPriceRounding?: number;
  onConfirmUpdateUnitPrice: (price: number) => void;
  unitClassId?: string;
  currentUnitPriceValue?: string | number | null;
}

const UpdateUnitPriceModal: React.FunctionComponent<IUpdateUnitPriceModalProps> = ({
  onClose,
  unitPriceRounding,
  onConfirmUpdateUnitPrice,
  unitClassId = '',
  currentUnitPriceValue = '',
}) => {
  const confirmModalRef = useRef<IConfirmModalElement>(null);

  const [price, setPrice] = useState<string | number>('');
  const { mutate: updateCurrentUnitPrice, isLoading: updatingCurrentUnitPrice } =
    useUpdateCurrentUnitPrice();

  useEffect(() => {
    if (currentUnitPriceValue !== null) setPrice(currentUnitPriceValue);
  }, [currentUnitPriceValue]);

  const handleConfirm = () => {
    const numberPrice = currencyStringToNumber(price);

    updateCurrentUnitPrice(
      {
        unitClassId,
        data: { unitPrice: numberPrice },
      },
      {
        onSuccess: () => {
          onConfirmUpdateUnitPrice(numberPrice);
          onClose();
          confirmModalRef.current?.close();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleUpdateCurrentUnitPriceConfirmation = () => {
    confirmModalRef.current?.open();
  };

  return (
    <Box className='p-10 rounded-[22px] w-[600px]'>
      <Typography variant='h5'>Update Unit Price?</Typography>
      <Typography variant='body2' my={3}>
        Would you like to record a new current unit price for this unit class?
      </Typography>
      <Box className='flex flex-col'>
        <Typography variant='body3' mb={0.5}>
          Current Unit Price
        </Typography>
        <NumericFormat
          fullWidth
          isAllowed={(values) => {
            const { floatValue = 0 } = values;

            return floatValue <= MAX_UNIT_PRICE;
          }}
          value={price}
          customInput={CustomTextField}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
            '.MuiInputBase-adornedEnd.Mui-disabled': {
              paddingRight: '14px',
            },
          }}
          decimalScale={unitPriceRounding}
          thousandSeparator
          allowNegative={false}
          onChange={(e) => {
            setPrice(e.target.value);
          }}
          placeholder='Enter current unit price'
          fixedDecimalScale
        />
      </Box>
      <Box className='flex items-center justify-end mt-8'>
        <CustomButton
          variant='text'
          sx={{
            color: 'neutral.ne800',
            width: 125,
          }}
          disabled={updatingCurrentUnitPrice}
          onClick={onClose}
        >
          No
        </CustomButton>
        <CustomButton
          sx={{
            width: 125,
          }}
          isLoading={updatingCurrentUnitPrice}
          disabled={currencyStringToNumber(price) <= 0}
          onClick={handleUpdateCurrentUnitPriceConfirmation}
        >
          Yes
        </CustomButton>
      </Box>
      <ConfirmModal
        title='Update Unit Price?'
        content='Are you sure you want to update new Unit Price?'
        ref={confirmModalRef}
        ButtonsComponent={
          <>
            <CustomButton variant='text' onClick={() => confirmModalRef.current?.close()}>
              <Typography color='primary' variant='body2' fontWeight={500}>
                No
              </Typography>
            </CustomButton>
            <CustomButton isLoading={updatingCurrentUnitPrice} onClick={handleConfirm}>
              <Typography color='white' variant='body2' fontWeight={500}>
                Yes
              </Typography>
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default UpdateUnitPriceModal;
