import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

interface CustomAccordionSummaryProps extends AccordionSummaryProps {
  hideIcon?: boolean;
}

interface CustomAccordionProps extends AccordionProps {
  canCollapse?: boolean;
}

export const Accordion = styled((props: CustomAccordionProps) => {
  const { canCollapse = true, ...rest } = props;
  return <MuiAccordion disableGutters elevation={0} square disabled={!canCollapse} {...props} />;
})<{ canCollapse?: boolean }>(({ theme, canCollapse }) => ({
  backgroundColor: theme.palette.neutral.ne100,
  borderRadius: 10,
  padding: 0,
  borderColor: theme.palette.neutral.ne300,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  ...(canCollapse
    ? {}
    : {
        '&.Mui-disabled': {
          backgroundColor: theme.palette.base.white,
        },
        '.MuiAccordionSummary-root.Mui-disabled': {
          opacity: 1,
        },
      }),
}));

export const AccordionSummary = styled((props: CustomAccordionSummaryProps) => {
  const { hideIcon, ...rest } = props;
  return (
    <MuiAccordionSummary
      expandIcon={!hideIcon ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} /> : undefined}
      {...rest}
    />
  );
})(({ theme }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
