import * as React from 'react';

interface IEmploymentIconProps {}

const EmploymentIcon: React.FunctionComponent<IEmploymentIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_00'
          transform='translate(-819.000000, -586.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-4' transform='translate(214.000000, 270.000000)'>
                <g id='Employment-Income' transform='translate(303.000000, 43.000000)'>
                  <g id='broker' transform='translate(12.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                    <g id='employer' mask='url(#mask-2)'>
                      <g transform='translate(7.000000, 7.000000)'>
                        <g id='Group' transform='translate(0.000000, 28.500590)'>
                          <path
                            d='M3.16288411,5.15900177 L10.4390008,1.02632773 C11.8045759,0.250666475 13.193152,-0.299226207 14.6940223,0.178068541 C16.3674946,0.710086732 19.1000472,1.85249588 21.8868301,2.34528186 C22.9212263,2.67408083 23.5660994,3.6894436 23.2847563,4.71901429 C23.0033197,5.74876831 22.1157193,6.27968654 20.8634669,6.08975861 L21.8376488,6.24265426 C22.9539515,6.41773261 23.9791846,6.26786187 24.9561715,5.71008608 L31.9517867,1.71554978 C37.3896832,-1.38929518 38.1883647,3.38731886 36.3803452,4.47857947 L23.6851255,12.1422447 C22.5174911,12.8469579 21.2399,13.0114033 19.9153717,12.6633548 L10.7070668,10.244432 L7.29691595,12.1746021 C6.64129033,12.5457499 5.79772867,12.3241978 5.41914508,11.6813578 L2.66153434,6.99888273 C2.28332475,6.35668434 2.50838049,5.53079119 3.16288411,5.15900177 L3.16288411,5.15900177 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          />
                          <path
                            d='M23.1186994,5.1461688 C22.7298307,5.89121426 21.9286248,6.25136234 20.8633734,6.08975861 C19.3550231,5.69871974 17.8483558,5.29778115 16.3412209,4.90197575 C15.9375792,4.79592044 15.6980309,4.38920701 15.8061175,3.99340161 C15.9142976,3.59768787 16.3292529,3.36284456 16.7328946,3.46880821 L23.1186994,5.1461688 Z'
                            id='Path'
                            fill='#F1CBBC'
                            fillRule='nonzero'
                          />
                          <path
                            d='M3.16288411,5.15900177 L10.4390008,1.02632773 C11.8045759,0.250666475 13.193152,-0.299226207 14.6940223,0.178068541 C15.5892898,0.46268544 16.7878729,0.922014033 18.1355893,1.36310148 L4.04076046,9.34099107 L2.66153434,6.99888273 C2.28323125,6.35668434 2.50838049,5.53079119 3.16288411,5.15900177 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          />
                          <path
                            d='M3.88835462,2.90819083 L9.6588325,12.903973 C9.93783805,13.3873176 9.76280508,14.0065999 9.26977685,14.2801255 L7.30701401,15.3691862 C6.81398578,15.6427117 6.18238979,15.4711166 5.90329075,14.987772 L0.132812865,4.99189819 C-0.146192676,4.50855361 0.0289337914,3.88936291 0.521868515,3.61574569 L2.48463136,2.52668503 C2.97765959,2.25325114 3.60934908,2.42484626 3.88835462,2.90819083 L3.88835462,2.90819083 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          />
                          <path
                            d='M6.92338139,9.77593702 L7.94478103,11.545145 L8.95477361,13.2946452 C9.23321815,13.7769815 9.05940069,14.3947056 8.56870998,14.6690561 L9.26987036,14.2800338 C9.76289858,14.0065083 9.93793155,13.3872259 9.65892601,12.9038813 L8.64547391,11.148423 L6.61062216,7.62357328 L4.5734329,4.09487372 L3.88835462,2.90819083 C3.64665578,2.48946942 3.14035048,2.30476634 2.68874299,2.44070414 L3.87489054,4.49526233 L5.90974229,8.020112 L6.92319439,9.77557037 L6.92338139,9.77593702 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          />
                          <path
                            d='M2.56924933,4.40194832 C2.18627121,4.45685509 1.92129075,4.80572849 1.97720406,5.18109281 C2.03321087,5.55654879 2.38898033,5.81641639 2.77195845,5.76150962 C3.15493657,5.70660285 3.42001053,5.35772944 3.36400372,4.98236513 C3.30799691,4.60690915 2.95222745,4.34704155 2.56924933,4.40194832 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          />
                        </g>
                        <g id='Group' transform='translate(3.696492, 0.000000)'>
                          <path
                            d='M19.6543696,5.35556423 L21.8315291,5.35565589 L21.8315291,2.41103971 C21.8315291,1.08502745 20.724857,9.16640586e-05 19.3722785,9.16640586e-05 L12.0303375,9.16640586e-05 C10.677759,9.16640586e-05 9.57099339,1.08502745 9.57099339,2.41103971 L9.57099339,5.35574756 L11.7480594,5.35556423 L11.7480594,2.41103971 C11.7480594,2.26309392 11.8794276,2.13448925 12.0303375,2.13448925 L19.3722785,2.13448925 C19.5231884,2.13448925 19.6543696,2.26318558 19.6543696,2.41103971 L19.6543696,5.35556423 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          />
                          <path
                            d='M19.6543696,5.35556423 L21.8315291,5.35574756 L21.8315291,5.35565589 L20.5663736,5.35556423 L20.5663736,2.41103971 C20.5663736,2.26318558 20.4351924,2.13448925 20.2842826,2.13448925 L19.3722785,2.13448925 C19.5231884,2.13448925 19.6543696,2.26318558 19.6543696,2.41103971 L19.6543696,5.35556423 Z M12.9423415,0 L12.0303375,0 C10.677759,0 9.57099339,1.08493579 9.57099339,2.41094804 L9.57099339,5.35565589 L10.4829974,5.35556423 L10.4829974,2.41103971 C10.4829974,1.08502745 11.589763,0 12.9423415,0 L12.9423415,0 Z'
                            id='Shape'
                            fill='#B5B5B5'
                            fillRule='nonzero'
                          />
                          <path
                            d='M1.39960923,12.1509875 L1.39960923,24.18217 C1.39960923,25.4212848 2.43372495,26.4351809 3.69775843,26.4351809 L27.7044836,26.4351809 C28.9684236,26.4351809 30.0026328,25.4213764 30.0026328,24.18217 L30.0026328,12.1514458 L1.39960923,12.1509875 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                            opacity='0.967703683'
                          />
                          <path
                            d='M1.39960923,12.1510791 L1.39960923,24.1822617 C1.39960923,25.4213764 2.43372495,26.4352726 3.69775843,26.4352726 L5.52176651,26.4352726 C4.25773303,26.4352726 3.22361731,25.4213764 3.22361731,24.1822617 L3.22361731,12.1510791 L1.39960923,12.1510791 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          />
                          <path
                            d='M29.2870733,14.5578106 C29.5398052,14.4010651 29.7803821,14.2342365 30.0025393,14.0553999 C30.8292709,13.3901022 31.4021485,12.5593509 31.4021485,11.4688236 L31.4021485,5.97649656 C31.4021485,5.34071465 30.8731226,4.82207941 30.224603,4.82207941 C20.3796531,4.82207941 11.0224954,4.82207941 1.17745201,4.82207941 C0.528932423,4.82207941 0,5.34062299 0,5.97649656 L0,11.4687319 C0,12.5591675 0.572877666,13.3899189 1.39951573,14.0552166 C1.62185996,14.2341449 1.86243679,14.4010651 2.11507519,14.5578106 C6.89603711,17.5232346 24.5409871,17.5015102 29.2870733,14.5578106 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          />
                          <path
                            d='M3.22361731,4.82207941 L1.17754551,4.82207941 C0.529025923,4.82207941 9.35005167e-05,5.34071465 9.35005167e-05,5.97649656 L9.35005167e-05,11.4687319 C9.35005167e-05,12.5591675 0.572971166,13.3899189 1.39960923,14.0552166 C1.62185996,14.2341449 1.86253029,14.4010651 2.11516869,14.5578106 C2.43063943,14.7535134 2.80351949,14.9358332 3.22361731,15.1055034 L3.22361731,4.82207941 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          />
                          <path
                            d='M7.50210746,4.82207941 L23.9003216,4.82207941 L23.9003216,8.5759059 C23.9003216,9.37200824 23.2360004,10.0232814 22.4239484,10.0232814 L8.97848061,10.0232814 C8.16642863,10.0232814 7.50210746,9.37200824 7.50210746,8.5759059 L7.50210746,4.82207941 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          />
                          <path
                            d='M12.8071398,17.1379705 C12.8071398,18.7048759 14.1027764,19.9750648 15.7011678,19.9750648 C17.2994656,19.9750648 18.5951023,18.7048759 18.5951023,17.1379705 C18.5951023,15.5761067 17.3045146,14.3008763 15.7011678,14.3008763 C14.0978209,14.3008763 12.8071398,15.5759233 12.8071398,17.1379705 L12.8071398,17.1379705 Z'
                            id='Path'
                            fill='#CCCCCC'
                            fillRule='nonzero'
                          />
                          <path
                            d='M12.8071398,17.1379705 C12.8071398,18.7048759 14.1027764,19.9750648 15.7011678,19.9750648 C15.8563786,19.9750648 16.0085975,19.9629651 16.1571698,19.9398658 C14.7755126,19.7254635 13.7191438,18.5527136 13.7191438,17.1379705 C13.7191438,15.727169 14.7719596,14.5505692 16.1571698,14.3359836 C16.008691,14.3129759 15.8563786,14.3008763 15.7011678,14.3008763 C14.0978209,14.3008763 12.8071398,15.5759233 12.8071398,17.1379705 L12.8071398,17.1379705 Z'
                            id='Path'
                            fill='#B5B5B5'
                            fillRule='nonzero'
                          />
                        </g>
                        <g id='Group-8' transform='translate(26.337449, 12.910075)'>
                          <ellipse
                            id='Oval'
                            fill='#F4A93C'
                            fillRule='nonzero'
                            cx='7.83134234'
                            cy='7.67753401'
                            rx='7.8312091'
                            ry='7.67739336'
                          />
                          <path
                            d='M0.000133248006,7.67753401 C0,10.5326168 1.6172033,13.1515649 4.19766132,14.4750981 C7.99033851,15.0961319 11.6812388,12.9236287 12.9049166,9.34991495 C14.1285944,5.77620117 12.5223746,1.86045838 9.11566064,0.112230591 C6.84408609,-0.273824158 4.5147971,0.346925974 2.75520023,1.80727858 C0.995603363,3.26763119 -0.0133404549,5.41740031 0.000133248006,7.67753401 L0.000133248006,7.67753401 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          />
                          <path
                            d='M10.963826,6.14205533 L10.963826,5.374316 C10.963826,4.10228382 9.91198046,3.07109799 8.61446325,3.07109799 L8.61446325,2.30335866 L7.04822143,2.30335866 L7.04822143,3.07109799 C5.75070423,3.07109799 4.69885871,4.10228382 4.69885871,5.374316 L4.69885871,6.14205533 C4.69885871,7.41408752 5.75070423,8.44527334 7.04822143,8.44527334 L7.04822143,10.7484913 C6.6157157,10.7484913 6.26510052,10.4047627 6.26510052,9.98075201 L4.69885871,9.98075201 C4.69885871,11.2527842 5.75070423,12.28397 7.04822143,12.28397 L7.04822143,13.0517094 L8.61446325,13.0517094 L8.61446325,12.28397 C9.91198046,12.28397 10.963826,11.2527842 10.963826,9.98075201 L10.963826,9.21301268 C10.963826,7.9409805 9.91198046,6.90979467 8.61446325,6.90979467 L8.61446325,4.60657666 C9.04696899,4.60657666 9.39758416,4.95030527 9.39758416,5.374316 L9.39758416,6.14205533 L10.963826,6.14205533 Z M9.39758416,9.21301268 L9.39758416,9.98075201 C9.39758416,10.4047627 9.04696899,10.7484913 8.61446325,10.7484913 L8.61446325,8.44527334 C9.04696899,8.44527334 9.39758416,8.78900195 9.39758416,9.21301268 Z M7.04822143,6.90979467 C6.6157157,6.90979467 6.26510052,6.56606606 6.26510052,6.14205533 L6.26510052,5.374316 C6.26510052,4.95030527 6.6157157,4.60657666 7.04822143,4.60657666 L7.04822143,6.90979467 Z'
                            id='Shape'
                            fill='#FCE797'
                            fillRule='nonzero'
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmploymentIcon;
