/* eslint-disable no-empty-pattern */
import { Box, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { differenceWith, isEmpty } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { UseFormTrigger, useFieldArray, useFormContext } from 'react-hook-form';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomEditor from 'src/components/atoms/CustomEditor';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  APPLICATION_SIGNATORY_STATUS,
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  SignatoryMethodType,
} from 'src/constants/applications';
import { DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import {
  useGetApplicationById,
  useGetSignatoryContact,
  useLetterFile,
  useMarkSignedApplicationSignatory,
} from 'src/modules/applications/hooks';
import {
  ELetterType,
  IAcceptanceForm,
  IAcceptanceSignatory,
  IExecutionMethod,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { ISignatoryContact } from 'src/modules/funds-management/type';
import { handleErrorFromServer } from 'src/utils/common';
import SelectSignatory from './SelectSignatory';
import Signatory from './Signatory';

interface IAcceptanceApplicationProps extends IUpsertInvestmentApplicationForm {
  fundContacts?: ISignatoryContact[];
  fundId?: string;
  applicationReview?: {
    enabled?: boolean;
    disabled?: boolean;
    addNewList?: ISignatoryContact[];
    removeList?: string[];
    trigger?: UseFormTrigger<any>;
  };
  setApplicationReviewAcceptance?: any;
  executionMethods?: IExecutionMethod[];
}

const Acceptance: FC<IAcceptanceApplicationProps> = ({
  isViewMode,
  isDisabledEdit,
  id = '',
  applicationReview,
  currentStep,
  offerId = '',
  setApplicationReviewAcceptance,
  isDraft,
  isCreateMode,
  executionMethods,
  isCreateFundApplicationForm,
}) => {
  const isApplicationReview = applicationReview?.enabled;

  const { reset, control, getValues, setValue, formState, watch, trigger } =
    useFormContext<IAcceptanceForm>();

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
  });
  const { data: existingContacts } = useGetSignatoryContact(
    id,
    currentStep === INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
  );
  const { mutate: markSigned, isLoading: isLoadingMarkSigned } =
    useMarkSignedApplicationSignatory();

  const { mutate: getLetterFile, isLoading: getLetterLoading } = useLetterFile({
    onSuccess: (data: Blob) => {
      window.open(URL.createObjectURL(data), '_blank');
    },
  });

  const alertModalRef = useRef<IBasicModalElement & { description: string }>({
    open: () => null,
    close: () => null,
    description: '',
  });

  const downloadLetterFile = (type: ELetterType) => () => {
    getLetterFile({
      appId: id,
      type,
    });
  };

  const { entityType, trusteeType, otherType } = applicationDetail || {};

  // Individuals, Sole Trader, Individual Trust, and Individual SMSF
  const inCase1 =
    entityType === INVESTMENT_ENTITY_TYPE.Individuals || // Individual
    entityType === INVESTMENT_ENTITY_TYPE.SoleTrader || // Sole Trader
    (entityType === INVESTMENT_ENTITY_TYPE.Trust &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual) || //  Individual Trust
    (entityType === INVESTMENT_ENTITY_TYPE.SMSF &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual); // Individual SMSF

  // Company, Corporate Trust, Corporate SMSF
  const inCase2 =
    entityType === INVESTMENT_ENTITY_TYPE.Company || // Company
    (entityType === INVESTMENT_ENTITY_TYPE.Trust &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) || //  Corporate Trust
    (entityType === INVESTMENT_ENTITY_TYPE.SMSF &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate); // Corporate SMSF

  // Partnership, Associations, and Government Bodies
  const inCase3 =
    entityType === INVESTMENT_ENTITY_TYPE.Partnership || // Partnership
    (entityType === INVESTMENT_ENTITY_TYPE.Other &&
      otherType === INVESTMENT_ENTITY_OTHER.Association) || //  Association
    (entityType === INVESTMENT_ENTITY_TYPE.Other &&
      otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody); // GovernmentBody

  useEffect(() => {
    setValue('validateCase2', inCase3);
  }, [inCase3]);

  useEffect(() => {
    setValue('validateCase1', inCase2 && existingContacts?.length > 2);
  }, [inCase2, existingContacts]);

  const { remove, append } = useFieldArray({
    control,
    name: 'signatories',
    keyName: 'key',
  });

  const signatories = watch('signatories');

  useEffect(() => {
    let newSignatories: any = [];

    // For Company, Corporate Trust, Corporate SMSF If there are 1 or 2 directors existing then default to select and not allow to un-select
    const isDefaultSelectedSignatories =
      inCase2 && existingContacts?.length && existingContacts.length <= 2;

    if (!isEmpty(applicationDetail?.acceptance?.signatories)) {
      newSignatories = (applicationDetail?.acceptance?.signatories || []).map((e) => {
        const fullSelectedUserInfo =
          (existingContacts || []).find((f: IAcceptanceSignatory) => f.id === e.contactId) || {};
        return {
          ...e,
          ...fullSelectedUserInfo, // Need to sync isCurrentUser value, signatories didnt return this one
          isReadDeclaration: !isEmpty(e.signatoryFile),
          email: e.email,
          notRemoved: e.notRemoved || isDefaultSelectedSignatories,
        };
      });
    }

    if ((isCreateMode || isDraft) && (inCase1 || isDefaultSelectedSignatories)) {
      (existingContacts || []).forEach((e: IAcceptanceSignatory) => {
        const isSelectedItem = (applicationDetail?.acceptance?.signatories || []).findIndex(
          (f) => f.contactId === e.id,
        );
        if (isSelectedItem === -1) {
          newSignatories.push({
            ...e,
            contactId: e.id,
            notRemoved: e.notRemoved || isDefaultSelectedSignatories,
          });
        }
      });
    }

    reset((formState) => ({
      ...formState,
      signatories: newSignatories,
    }));
  }, [inCase1, inCase2, existingContacts, applicationDetail]);

  useEffect(() => {
    const selectedList = applicationDetail?.acceptance?.signatories || [];
    const currentSelectedList = getValues()?.signatories || [];

    if (!inCase1 && isApplicationReview) {
      const addNewList = differenceWith(
        currentSelectedList,
        selectedList,
        (a, b) => a.contactId === b.contactId,
      );
      const removeList = differenceWith(
        selectedList,
        currentSelectedList,
        (a, b) => a.contactId === b.contactId,
      ).map((it) => it.contactId);
      setApplicationReviewAcceptance((prev: any) => ({
        ...prev,
        addNewList,
        removeList,
        currentSelectedList,
        selectedList,
        trigger,
      }));
    } else if (inCase1 && isApplicationReview) {
      setApplicationReviewAcceptance((prev: any) => ({
        ...prev,
        currentSelectedList,
        selectedList,
      }));
    }
  }, [JSON.stringify(getValues()?.signatories)]);

  const handleSelectSignatory = (item: IAcceptanceSignatory, selectedIndex: number) => {
    if (selectedIndex !== -1) {
      remove(selectedIndex);
    } else {
      if (item.id) {
        item.contactId = item.id;
      }
      if (typeof signatories[0].method === 'number') {
        item.method = signatories[0].method;
      }
      append(item);
    }
  };

  const handleMarkSigned = (index: number) => {
    const data: IAcceptanceSignatory = getValues()?.signatories?.[index];
    markSigned(
      {
        appId: id,
        contactId: data.contactId,
        receivedType: data.receivedType as number,
        signedDate: dayjs(data.signedDate).format(DATE_PICKER_FORMAT_SERVER),
      },
      {
        onSuccess: () => {
          alertModalRef.current.description = 'The signatory is marked as signed.';
          alertModalRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  /**
   *
   * @param method
   * If users choose 1 signatory method for a selected user, the system auto-selects signatory method for all selected users. 1 method for all
   */
  const handleChangeSignatoryMethod = (method: SignatoryMethodType) => {
    const signatoriesWithNewMethod = (signatories || []).map((item) => ({
      ...item,
      method,
      isSendMail: method === SignatoryMethodType.Manual,
      signature: '',
    }));

    setValue('signatories', signatoriesWithNewMethod, {
      shouldDirty: true,
    });
  };

  /**
   * After user submits the application and returns to edit
   * if 1 of the signatories is signed, user shall not be able to select another method
   */
  const isDisabledAllSignatoryMethod =
    (isDisabledEdit || isApplicationReview) &&
    signatories?.some((f) => f.status === APPLICATION_SIGNATORY_STATUS.Signed);

  // Sign now method is only applicable if there is only 1 signatory that has the same email address with the current user (editor)
  const isEnableSignNowMethod =
    signatories?.length === 1 && signatories?.some((f) => f.isCurrentUser);

  return (
    <Box className='pt-4'>
      <Box width='full'>
        <Typography mb={2} fontWeight={500} variant='body2'>
          Declaration
        </Typography>
        <CustomEditor
          name='declaration'
          initValue={applicationDetail?.acceptance?.declaration}
          readOnly
          toolbar={false}
          height={300}
        />
      </Box>
      {!isDraft && !isCreateMode && !applicationReview && (
        <>
          <Typography my={2} fontWeight={500} variant='body2'>
            Download
          </Typography>
          <Box
            sx={{
              cursor: getLetterLoading ? 'wait' : 'default',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Link
              sx={{ display: 'flex', justifyContent: 'center' }}
              underline='hover'
              onClick={downloadLetterFile(ELetterType.deposit)}
            >
              <DownloadIcon />
              <Typography marginLeft={1}>Download Deposit Instruction Letter</Typography>
            </Link>
            <Link
              sx={{ display: 'flex', justifyContent: 'center' }}
              underline='hover'
              onClick={downloadLetterFile(ELetterType.applicationForm)}
            >
              <DownloadIcon />
              <Typography marginLeft={1}>Download Application Form</Typography>
            </Link>
          </Box>
        </>
      )}
      {!inCase1 && (
        <SelectSignatory
          items={existingContacts}
          selectedItems={signatories}
          onSelect={handleSelectSignatory}
          isApplicationReview={isApplicationReview}
          disabled={isViewMode && !isApplicationReview}
          errorMessage={formState.errors.signatories?.message || ''}
        />
      )}
      {signatories.length > 0 && (
        <Box pt={4}>
          <Typography mb={2} fontWeight={500} variant='body2'>
            Signatory List
          </Typography>
          <Box className='flex flex-col gap-4'>
            {signatories?.map((item, index) => (
              <Signatory
                key={item.id}
                user={item}
                isViewMode={isViewMode}
                applicationReview={applicationReview}
                declaration={applicationDetail?.acceptance?.declaration || ''}
                keyName={`signatories.${index}`}
                ordered={index}
                onChangeMethod={handleChangeSignatoryMethod}
                isAlreadySelectedManualOrDocuSignBefore={
                  inCase1 ||
                  !!applicationDetail?.acceptance?.signatories?.find(
                    (it) =>
                      it.contactId === item.contactId &&
                      [SignatoryMethodType.Manual, SignatoryMethodType.DocuSign].includes(
                        it.method,
                      ),
                  )
                }
                id={id}
                offerId={offerId}
                isLoadingMarkSigned={isLoadingMarkSigned}
                handleMarkSigned={handleMarkSigned}
                isDisabledAllSignatoryMethod={isDisabledAllSignatoryMethod}
                isEnableSignNowMethod={isEnableSignNowMethod}
                executionMethods={executionMethods}
                isCreateFundApplicationForm={isCreateFundApplicationForm}
              />
            ))}
          </Box>
        </Box>
      )}
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title={'You did it'}
          description={alertModalRef.current?.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertModalRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default Acceptance;
