import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import HorizontalTabs, {
  IHorizontalTabsElement,
  ITab,
} from 'src/components/molecules/HorizontalTabs';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import FinancialDetails from 'src/components/pages/applications/components/FinancialDetails';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import {
  IFinancialTaxDetailsForm,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { FINANCIAL_STEP, INVESTMENT_APPLICATION_STEP } from './InvestmentApplicationForm';
import TaxDetails from './TaxDetails';
export interface IFinancialAndTaxDetailsProps extends IUpsertInvestmentApplicationForm {
  activeTab?: number;
  setActiveTab?: Dispatch<SetStateAction<number>>;
}

const FinancialAndTaxDetails: FC<IFinancialAndTaxDetailsProps> = (props) => {
  const {
    id = '',
    investmentEntityId,
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    isReviewing,
    isCreateFundApplicationForm,
    activeTab,
    setActiveTab,
  } = props;
  const horizontalTabsRef = useRef<IHorizontalTabsElement>(null);
  const {
    reset,
    formState: { errors },
  } = useFormContext<IFinancialTaxDetailsForm>();
  const { financialDetails: financialDetailsErrors, ...taxDetailsErrors } = errors || {};
  const { data: applicationFinancial } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.FINANCIAL_DETAILS,
  });
  const { data: applicationTax } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.TAX_DETAILS,
  });

  const financialDetails = applicationFinancial?.financialDetails;
  const taxDetails = applicationTax?.financialTax;

  useEffect(() => {
    if (!isEmpty(financialDetails) && !isEmpty(taxDetails)) {
      reset({
        ...taxDetails,
        financialDetails,
      });
    }
  }, [financialDetails, taxDetails]);

  useEffect(() => {
    if (Number.isInteger(Number(activeTab))) {
      horizontalTabsRef.current?.setTab(Number(activeTab));
    }
  }, [activeTab]);

  const handleTabChange = (tab: number) => setActiveTab && tab !== activeTab && setActiveTab(tab);

  const Tabs: ITab[] = [
    {
      label: 'Financial Details',
      content: (
        <FinancialDetails
          {...props}
          keyName='financialDetails.'
          isWithoutFinancialAndTaxDetails={false}
        />
      ),
      isError: !isEmpty(financialDetailsErrors),
    },
    {
      label: 'Tax Details',
      content: <TaxDetails {...props} />,
      isError: !isEmpty(taxDetailsErrors),
    },
  ];

  return (
    <>
      {isExistingInvestmentEntity && !isReviewing && !isCreateFundApplicationForm && (
        <Box mb={4} textAlign='right'>
          <UpdateDetailsButton
            entityId={investmentEntityId}
            disabled={isViewMode || isDisabledEdit}
          />
        </Box>
      )}
      <HorizontalTabs
        ref={horizontalTabsRef}
        tabs={Tabs}
        destroyInactivePanel={false}
        onTabChange={handleTabChange}
      />
    </>
  );
};

export default FinancialAndTaxDetails;
