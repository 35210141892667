import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Controller, useController, useFormContext } from 'react-hook-form';
import CustomHelperText from './CustomHelperText';

interface IFormCheckboxProps extends CheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  defaultChecked?: boolean;
  rules?: Record<string, unknown>;
  disabled?: boolean;
  labelSx?: SxProps<Theme>;
}

const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  name,
  defaultChecked = false,
  rules = {},
  disabled = false,
  onChange: handleChange,
  label,
  labelSx,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultChecked,
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      rules={rules}
      render={({ field: { ref, onChange, value } }) => (
        <FormControl>
          <FormControlLabel
            sx={{ m: 0, alignItems: 'flex-start' }}
            control={
              <Checkbox
                {...rest}
                className='p-0'
                inputRef={ref}
                checked={value}
                disabled={disabled}
                onChange={(e, checked) => {
                  onChange(e, checked);
                  handleChange && handleChange(e, checked);
                }}
              />
            }
            label={
              <Typography
                component='span'
                variant='body2'
                paddingLeft={label ? 1 : 0}
                color='base.black'
                sx={labelSx}
              >
                {label}
              </Typography>
            }
          />
          {invalid && <CustomHelperText variant={'error'} message={error?.message} />}
        </FormControl>
      )}
    />
  );
};

export default FormCheckbox;
