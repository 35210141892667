/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';

interface IIndividualsProps {
  viewOnly?: boolean;
  isEditMode?: boolean;
  isViewMode?: boolean;
}

const Individuals: React.FC<IIndividualsProps> = (props) => {
  const { watch } = useFormContext();
  const individuals = watch('investorDetails.entityDetails.individuals') || [];

  return (
    <Box>
      <Box className='flex items-center pt-4'>
        <Typography variant='body2' fontWeight={500} mr={1.5} whiteSpace={'nowrap'}>
          Number of individuals
        </Typography>
        <Box width={'160px'}>
          <FormInput name='' value={individuals?.length} disabled />
        </Box>
      </Box>
      {!!individuals.length && (
        <Box mt={3} className='flex flex-col gap-4'>
          {individuals.map((individual: any, index: number) => (
            <IndividualDetail
              key={individual.dataId}
              {...props}
              keyName={`investorDetails.entityDetails.individuals.${index}`}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Individuals;
