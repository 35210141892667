import { get, isEmpty } from 'lodash';
import entityTypesJsonData from 'src/assets/jsonData/entityType.json';
import { OptionType } from 'src/components/atoms/FormAutocomplete';
import { AbnAcnTypeCode } from 'src/constants/applications';
import { SPECIAL_ABN } from 'src/constants/investments';
import { ISkyc } from 'src/modules/applications/type';
import { trimAll } from './common';

type KeyNameType = 'company' | 'governmentBody' | 'trust' | 'association' | 'partnership';

export const handleCheckEntityTypeMatch = (skyc: OptionType & ISkyc, keyName: KeyNameType) => {
  if (skyc?.status.type === AbnAcnTypeCode.ACN || !skyc?.entityType) return true;

  return get(entityTypesJsonData, keyName)
    .map((item) => item.entityDescription.toLocaleLowerCase())
    .includes(skyc.entityType.toLocaleLowerCase());
};

export const filterSKYCDataResponse = (data: ISkyc[]) => {
  if (isEmpty(data)) return [];
  return data.filter((skyc) => {
    if (skyc.status.type === AbnAcnTypeCode.ACN) {
      // Find abn type with same abn value
      const abnSKYC = data.find(
        (item) =>
          item.bn === skyc.bn && item.cn === skyc.cn && item.status.type === AbnAcnTypeCode.ABN,
      );
      // Remove item if duplicate
      return isEmpty(abnSKYC);
    }

    return true;
  });
};

export const checkAllowEditAbn = (abn?: string | null) => {
  if (!abn) return true;
  if (SPECIAL_ABN.includes(trimAll(abn))) return true;

  return false;
};
