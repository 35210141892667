import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { IDirector } from 'src/modules/applications/type';
import { ITaxDetailsProps } from '..';
import DirectorSelectionBox from './DirectorSelectionBox';

interface IDeclarationProps extends ITaxDetailsProps {
  directors?: IDirector[];
}

const Declaration: React.FC<IDeclarationProps> = ({ directors, isViewMode, isDisabledEdit }) => {
  const { watch, setValue } = useFormContext();
  const selectedUser = watch('declarationDirectorId');

  const handleSelect = (item: IDirector) => {
    setValue('declarationDirectorId', item.id);
  };

  return (
    <DirectorSelectionBox
      directors={directors}
      onSelect={handleSelect}
      selected={selectedUser}
      disabled={isViewMode || isDisabledEdit}
    />
  );
};

export default Declaration;
