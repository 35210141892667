import { Box, BoxProps, Tab, Tabs, styled } from '@mui/material';
import { isNumber } from 'lodash';
import * as React from 'react';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  paddingTop: 24,
  width: '225px',
  '.MuiTabs-indicator': {
    left: 0,
  },
  '.MuiTab-root': {
    alignItems: 'flex-start',
    fontWeight: 'bold',
    color: theme.palette.base.black,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className='w-full tab-panel'
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface ITab {
  label: string;
  component: React.ReactNode;
}

interface VerticalTabsProps extends Omit<BoxProps, 'onChange'> {
  tabs: ITab[];
  onChange?: (value: number) => void;
  tabActived?: number;
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  tabs,
  onChange,
  tabActived,
  ...containerProps
}) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    isNumber(tabActived) && setValue(tabActived);
  }, [tabActived]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <Box {...containerProps} className='flex-grow flex'>
      <CustomTabs
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{ borderRight: 2, borderColor: 'divider' }}
        className='custom-vertical-tab'
      >
        {tabs.map((tab: ITab, index: number) => (
          <Tab
            key={`tab-${index}`}
            label={tab.label}
            className='min-w-[300px]'
            {...a11yProps(index)}
          />
        ))}
      </CustomTabs>
      {tabs.map((tab: ITab, index: number) => (
        <TabPanel key={`tabPabel-${index}`} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default VerticalTabs;
