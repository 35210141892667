import { Box, Typography } from '@mui/material';
import { ConnectIDVerifiedIcon } from 'src/assets/icons/ConnectIDVerifiedIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import HorizontalTabs from 'src/components/molecules/HorizontalTabs';
import StatusBadge from 'src/components/molecules/StatusBadge';
import { IKYCMethod, IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import { utcToLocalTimezone } from 'src/utils/time';
import ConnectIDVerifiedDetails from './ConnectIDVerifiedDetails';
import RequestToVerify from './RequestToVerify';
import VerifyNow from './VerifyNow';

export default function IndividualItem(props: IVerifyIndividualItem) {
  const isVerifiedConnectID =
    props?.individual?.verificationMethod === IKYCMethod.CONNECTION_ID &&
    props?.individual?.overallStatus === IOverallStatus.VERIFIED;

  return (
    <>
      <Accordion
        sx={{
          border: (theme) => `1px solid ${theme.palette.neutral.ne300} !important`,
          '.MuiAccordionSummary-root': { px: 2.5, py: 1 },
          background: 'white',
        }}
        defaultExpanded={
          ![IOverallStatus.PENDING, IOverallStatus.VERIFIED].includes(
            props?.individual?.overallStatus || IOverallStatus.REQUIRED,
          )
        }
        disabled={
          props?.individual?.overallStatus === IOverallStatus.VERIFIED &&
          props?.individual?.verificationMethod !== IKYCMethod.CONNECTION_ID
        }
      >
        <AccordionSummary className='flex justify-between'>
          <Typography mr='10px'>
            {[
              `${props?.individual?.firstName} ${props?.individual?.lastName}`,
              props?.individual?.position,
            ]
              .filter(Boolean)
              .join(' - ')}
          </Typography>
          {isVerifiedConnectID && <ConnectIDVerifiedIcon />}
          <Box ml='auto' className='flex items-center'>
            {isVerifiedConnectID && props.individual?.kycVerifiedDate && (
              <Typography variant='body2' color='neutral.ne800' mr='10px'>
                {utcToLocalTimezone(props.individual?.kycVerifiedDate)}
              </Typography>
            )}
            <StatusBadge status={props?.individual?.overallStatusName || 'Not Required'} />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            margin: '0 20px 20px',
            paddingTop: '20px',
            borderTop: (theme) => `1px solid ${theme.palette.neutral.ne300}`,
          }}
        >
          {isVerifiedConnectID ? (
            <ConnectIDVerifiedDetails individual={props?.individual} />
          ) : (
            <HorizontalTabs
              tabs={[
                {
                  label: 'Verify Now',
                  content: <VerifyNow {...props} />,
                },
                {
                  label: 'Request Verification',
                  content: <RequestToVerify {...props} />,
                },
              ]}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
