import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from 'src/assets/icons/CloseIcon';
import NotificationFilledIcon from 'src/assets/icons/NotificationFilledIcon';
import FeatureBadge from 'src/components/molecules/FeatureBadge';
import {
  NOTIFICATION_POPUP_TYPE,
  NOTIFICATION_REDIRECT,
  NOTIFICATION_STATE,
} from 'src/constants/notifications';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { detectPortalType } from 'src/helpers/common';
import { useDeleteNotification, useUpdateNotificationState } from 'src/modules/notifications/hooks';
import { INotificationItem } from 'src/modules/notifications/type';
import { NotificationContext } from 'src/providers/NotificationContextProvider';
import { handleErrorFromServer } from 'src/utils/common';
import { getRelativeTimeFromNow } from 'src/utils/time';

interface INotificationItemProps {
  notification: INotificationItem;
}

const NotificationItem: FC<INotificationItemProps> = ({ notification }) => {
  const theme = useTheme();
  const isUnread = notification.state === NOTIFICATION_STATE.NEW;
  const { setTotalUnread, setTotal, setNotifications } = useContext(NotificationContext);

  const { mutate: deleteNotification } = useDeleteNotification();
  const { mutate: updateNotificationState } = useUpdateNotificationState();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const { isFunds } = detectPortalType();

  const handleDeleteNotification = () => {
    deleteNotification(notification.id, {
      onSuccess: handleDeleteSuccess,
      onError: handleErrorFromServer,
    });
  };

  const handleDeleteSuccess = () => {
    setNotifications((prev) => prev.filter((it) => it.id !== notification.id));
    setTotal((prev) => prev - 1);
    if (isUnread) {
      setTotalUnread((prev) => prev - 1);
    }
  };

  const handleNotificationClick = () => {
    if (isUnread) {
      setTotalUnread((prev) => prev - 1);
    }
    updateNotificationState(notification.id, {
      onSuccess: () => {
        handleRedirect(notification);
      },
      onError: handleErrorFromServer,
    });
  };

  const handleRedirect = (notification: INotificationItem) => {
    const notificationRedirectPath = NOTIFICATION_REDIRECT.path(isFunds);

    if (notification?.popupType === NOTIFICATION_POPUP_TYPE.DuplicateApplication) {
      handleDuplicateApplicationRedirect(notification);
    } else {
      navigate(`${notificationRedirectPath[notification.type]}`);
    }
  };

  const handleDuplicateApplicationRedirect = (notification: INotificationItem) => {
    const notificationRedirectPath = NOTIFICATION_REDIRECT.path(isFunds);

    if (notification?.popupObject) {
      const duplicatedInfo = JSON.parse(notification.popupObject);
      if (pathname !== ROUTES_PATH.APPLICATIONS) {
        navigate({
          pathname: `${notificationRedirectPath[notification.type]}`,
          search: createSearchParams(duplicatedInfo).toString(),
        });
      } else {
        window.location.href = `${notificationRedirectPath[notification.type]}?${createSearchParams(
          duplicatedInfo,
        ).toString()}`;
      }
    }
  };

  return (
    <Box className='flex items-center justify-between' onClick={handleNotificationClick}>
      <Box className='p-3.5 rounded-lg flex items-center justify-center' bgcolor='neutral.ne100'>
        <NotificationFilledIcon color={isUnread ? theme.palette.secondary.main : ''} />
      </Box>
      <Box className='content mx-4 flex-1'>
        <Typography variant='body3' fontWeight={500}>
          {notification.title}
        </Typography>
        <Box className='flex items-center mt-2'>
          <Typography variant='caption1' color='neutral.ne500'>
            {getRelativeTimeFromNow(notification.createdDate)}
          </Typography>
          <Box className='w-1 h-1 mx-2.5 rounded-full' bgcolor='neutral.ne500' />
          <FeatureBadge label={notification.typeName} />
        </Box>
      </Box>
      <IconButton
        sx={{ p: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteNotification();
        }}
      >
        <CloseIcon width={14} height={14} color={theme.palette.neutral.ne500} />
      </IconButton>
    </Box>
  );
};

export default NotificationItem;
