/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import SMSFDetail from 'src/components/pages/applications/components/InvestmentEntity/SMSF/SMSFDetail';
import { investmentEntitySMSFDefaultValues } from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { INVESTMENT_ENTITY_STEP } from '../../InvestmentEntityForm';
import AddNew from '../AddNew';

interface ISMSFProps extends IInvestmentEntityProps {
  isSuperAdmin?: boolean;
}

const SMSF = (props: ISMSFProps) => {
  const { id = '' } = props;
  const { reset, watch } = useFormContext<IInvestmentEntityForm>();
  const allowEditAbn = watch('investmentEntityDetails.allowEditAbn');

  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntitySMSFDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                SMSF Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <SMSFDetail
              {...props}
              disabledAbn={!allowEditAbn}
              hideUBODeclaration
              enabledClearCorporateDetails
            />
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default SMSF;
