import { Administration } from 'src/assets/icons/Administration';
import { Applications } from 'src/assets/icons/Applications';
import { ArrowRepeatIcon } from 'src/assets/icons/ArrowRepeatIcon';
import BillingIcon from 'src/assets/icons/BillingIcon';
import { ChatIcon } from 'src/assets/icons/ChatIcon';
import { DashboardIcon } from 'src/assets/icons/DashboardIcon';
import { Documents } from 'src/assets/icons/Documents';
import { FolderIcon } from 'src/assets/icons/FolderIcon';
import FundRegistryIcon from 'src/assets/icons/FundRegistryIcon';
import { Fundraising } from 'src/assets/icons/Fundraising';
import { InvestorForms } from 'src/assets/icons/InvestorForms';
import { PeopleGroupIcon } from 'src/assets/icons/PeopleGroupIcon';
import { PeopleSettings } from 'src/assets/icons/PeopleSettings';
import PersonSwapIcon from 'src/assets/icons/PersonSwapIcon';
import { SettingsIcon } from 'src/assets/icons/SettingsIcon';
import { INavigationItem } from 'src/interfaces/navigation';
import {
  FEATURES,
  FUNDS_FEATURE_NAME,
  FUNDS_FEATURE_TITLE,
  INVESTOR_FEATURE_NAME,
} from './role-management';
import { ROUTES_PATH } from './routesPath';
import { SUBSCRIPTION_FEATURE_TYPE, StandardSubscriptionRef } from './subscription-billing';

export const GLOBAL_ADMIN_NAVIGATION_LIST: INavigationItem[] = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    path: ROUTES_PATH.HOME,
    name: FEATURES.Dashboard,
  },
  {
    icon: PeopleGroupIcon,
    title: 'Client Management',
    path: ROUTES_PATH.CLIENT_MANAGEMENT,
    name: FEATURES.ClientManagement,
  },
  {
    icon: FolderIcon,
    title: 'KYC Back Office',
    path: ROUTES_PATH.KYC_BACK_OFFICE,
    name: FEATURES.KYCBackOffice,
  },
  {
    title: 'KYC Back Office Detail',
    path: ROUTES_PATH.KYC_BACK_OFFICE_DETAIL,
    name: FEATURES.KYCBackOffice,
    hidden: true,
  },
  {
    icon: ChatIcon,
    title: 'Communications',
    path: ROUTES_PATH.COMMUNICATIONS,
    name: FEATURES.Communications,
  },
  {
    icon: ArrowRepeatIcon,
    title: 'Audit Trail',
    path: ROUTES_PATH.AUDIT_TRAIL,
    name: 'AuditTrails',
    skipPermission: true,
  },
  {
    icon: PeopleSettings,
    title: 'User Management',
    path: ROUTES_PATH.USER_MANAGEMENT,
    name: FEATURES.UserManagement,
  },
  {
    icon: PersonSwapIcon,
    title: 'Role Management',
    path: ROUTES_PATH.ROLE_MANAGEMENT,
    name: FEATURES.RoleManagement,
  },
  {
    icon: BillingIcon,
    title: 'Billing',
    path: ROUTES_PATH.BILLING,
    name: FEATURES.Billing,
    skipPermission: true,
  },
];

export const FUND_NAVIGATION_LIST: INavigationItem[] = [
  {
    icon: DashboardIcon,
    title: FUNDS_FEATURE_TITLE.Dashboard,
    path: ROUTES_PATH.HOME,
    // subscriptionType: SUBSCRIPTION_FEATURE_TYPE.Dashboard,
  },
  {
    icon: Fundraising,
    title: FUNDS_FEATURE_TITLE.Fundraising,
    path: ROUTES_PATH.FUNDRAISING_OFFERS,
    submenu: [
      {
        title: 'Offers',
        path: ROUTES_PATH.FUNDRAISING_OFFERS,
        name: FUNDS_FEATURE_NAME.FundraisingOffers,
      },
      {
        title: 'Applications',
        path: ROUTES_PATH.FUNDRAISING_APPLICATIONS,
        name: FUNDS_FEATURE_NAME.FundraisingApplications,
      },
      {
        title: 'Capital Calls',
        path: ROUTES_PATH.FUNDRAISING_CAPITAL_CALLS,
        name: FUNDS_FEATURE_NAME.FundraisingCapitalCalls,
        subscriptionType: SUBSCRIPTION_FEATURE_TYPE.FundraisingCapitalCalls,
      },
      {
        title: 'KYC Back Office',
        path: ROUTES_PATH.FUNDRAISING_KYC_BACK_OFFICE,
        name: FUNDS_FEATURE_NAME.KYCBackOffice,
        subscriptionType: SUBSCRIPTION_FEATURE_TYPE.FundraisingKYCBackOffice,
      },
      {
        title: 'KYC Back Office Detail',
        path: ROUTES_PATH.FUNDRAISING_KYC_BACK_OFFICE_DETAIL,
        name: FUNDS_FEATURE_NAME.KYCBackOffice,
        hidden: true,
      },
    ],
  },
  {
    icon: Administration,
    title: FUNDS_FEATURE_TITLE.Administration,
    path: ROUTES_PATH.ADMINISTRATION_INVESTORS,
    submenu: [
      {
        title: 'Investors',
        path: ROUTES_PATH.ADMINISTRATION_INVESTORS,
        name: FUNDS_FEATURE_NAME.AdministrationInvestor,
      },
      {
        title: 'Investment Entity',
        path: ROUTES_PATH.ADMINISTRATION_INVESTMENT_ENTITY,
        name: FUNDS_FEATURE_NAME.AdministrationInvestmentEntity,
        hidden: true,
      },
      {
        title: 'Bulk Upload',
        path: ROUTES_PATH.BULK_UPLOAD,
        name: FUNDS_FEATURE_NAME.AdministrationInvestor,
        hidden: true,
      },
      {
        title: 'Funds',
        path: ROUTES_PATH.FUNDS,
        name: FUNDS_FEATURE_NAME.AdministrationFunds,
      },
      {
        title: 'Transactions',
        path: ROUTES_PATH.TRANSACTIONS,
      },
      {
        title: 'Reports',
        path: ROUTES_PATH.REPORTS,
        name: FUNDS_FEATURE_NAME.AdministrationReports,
        subscriptionType: SUBSCRIPTION_FEATURE_TYPE.AdministrationReporting,
      },
    ],
  },
  {
    icon: FundRegistryIcon,
    title: 'Registry',
    path: ROUTES_PATH.FUND_REGISTRY,
    submenu: [
      {
        title: 'Fund Registry',
        path: ROUTES_PATH.FUND_REGISTRY,
        name: FUNDS_FEATURE_NAME.FundRegistry,
        subscriptionType: SUBSCRIPTION_FEATURE_TYPE.FundRegistry,
      },
    ],
  },
  {
    icon: Documents,
    title: FUNDS_FEATURE_TITLE.Documents,
    path: ROUTES_PATH.DOCUMENTS,
    subscriptionType: StandardSubscriptionRef,
    name: FUNDS_FEATURE_NAME.Documents,
  },
  {
    icon: ChatIcon,
    title: FUNDS_FEATURE_TITLE.Communications,
    path: ROUTES_PATH.COMMUNICATIONS,
    name: FUNDS_FEATURE_NAME.Communications,
  },
  {
    icon: SettingsIcon,
    title: FUNDS_FEATURE_TITLE.Settings,
    path: ROUTES_PATH.USER_MANAGEMENT,
    submenu: [
      {
        title: 'User Management',
        path: ROUTES_PATH.USER_MANAGEMENT,
        name: FUNDS_FEATURE_NAME.SettingsUserManagement,
      },
      {
        title: 'Role Management',
        path: ROUTES_PATH.ROLE_MANAGEMENT,
        name: FUNDS_FEATURE_NAME.SettingsRoleManagement,
      },
      {
        title: 'Client Management',
        path: ROUTES_PATH.CLIENT_MANAGEMENT,
        name: FUNDS_FEATURE_NAME.SettingsClientManagement,
        subscriptionType: SUBSCRIPTION_FEATURE_TYPE.ClientManagement,
      },
      {
        title: 'Organisation Settings',
        path: ROUTES_PATH.ORGINISATION_SETTINGS,
        name: FUNDS_FEATURE_NAME.SettingsOrganisationSettings,
      },
    ],
  },
];

export const INVESTOR_NAVIGATION_LIST: INavigationItem[] = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    path: ROUTES_PATH.HOME,
  },
  {
    icon: Applications,
    title: 'Applications',
    path: ROUTES_PATH.APPLICATIONS,
    name: INVESTOR_FEATURE_NAME.Applications,
    subPath: [ROUTES_PATH.REQUEST_VERIFY],
  },
  {
    icon: InvestorForms,
    title: 'Investor Forms',
    path: ROUTES_PATH.INVESTOR_FORMS,
    name: INVESTOR_FEATURE_NAME.InvestorForms,
    subPath: [ROUTES_PATH.INVESTOR_OFFER_DETAILS],
  },
  {
    icon: Documents,
    title: 'Documents',
    path: ROUTES_PATH.DOCUMENTS,
    name: INVESTOR_FEATURE_NAME.Documents,
  },
  {
    icon: ChatIcon,
    title: 'Communications',
    path: ROUTES_PATH.COMMUNICATIONS,
    name: INVESTOR_FEATURE_NAME.Communications,
  },
  {
    icon: SettingsIcon,
    title: 'Settings',
    path: ROUTES_PATH.ACCOUNT_DETAILS,
    submenu: [
      {
        title: 'Investment Entity',
        path: ROUTES_PATH.INVESTMENT_ENTITY,
        name: INVESTOR_FEATURE_NAME.SettingsInvestmentEntity,
      },
      {
        title: 'Unallocated Holding',
        path: ROUTES_PATH.UNALLOCATED_HOLDING,
        name: INVESTOR_FEATURE_NAME.SettingsInvestmentEntity,
        hidden: true,
      },
      {
        title: 'User Management',
        path: ROUTES_PATH.USER_MANAGEMENT,
        name: INVESTOR_FEATURE_NAME.SettingsUserManagement,
      },
      {
        title: 'Role List',
        path: ROUTES_PATH.ROLE_LIST,
        name: INVESTOR_FEATURE_NAME.SettingsRoleList,
      },
    ],
  },
];
