import { Box } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import UnitPriceTabs from 'src/components/pages/administration-investor/investor/components/InvestorDetails/shared/UnitPriceTabs';
import UpdateUnitPriceModal from 'src/components/pages/administration-investor/investor/components/InvestorDetails/shared/UpdateUnitPriceModal';
import { IIssueForm } from 'src/modules/administration-investor/types';

interface IIssueUnitPriceTabsProps {
  unitPriceRounding?: number;
  currentUnitPrice?: number;
  isTrustInFirstCase?: boolean;
  isTrustInSecondCase?: boolean;
  isTrustInThirdCase?: boolean;
  unitClassId?: string;
}

export enum IssuePriceTab {
  ENTRY_UNIT_PRICE,
  CURRENT_UNIT_PRICE,
}

const IssueUnitPriceTabs: FC<IIssueUnitPriceTabsProps> = ({
  unitPriceRounding,
  isTrustInFirstCase,
  isTrustInSecondCase,
  isTrustInThirdCase,
  unitClassId,
}) => {
  const [tabSelected, setTabSelected] = useState<IssuePriceTab>(IssuePriceTab.ENTRY_UNIT_PRICE);
  const updateUnitPriceModalRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const form = useFormContext<IIssueForm>();
  const watchInvestmentAmount = form.watch('investmentAmount');
  const watchCapitalCallAmount = form.watch('capitalCallAmount') || 0;
  const watchPaidAmount = form.watch('paidAmount') || 0;
  const watchPartiallyPaidUnits = form.watch('partiallyPaidUnits');
  const watchCurrentUnitPrice = form.watch('currentUnitPrice');
  const watchEntryUnitPrice = form.watch('entryUnitPrice');
  const midPriceRounding = watchCurrentUnitPrice?.toString().split('.')?.[1]?.length || 0;
  const entryPriceRounding = watchEntryUnitPrice?.toString().split('.')?.[1]?.length || 0;

  useEffect(() => {
    handleCalcUnits();
  }, [
    watchInvestmentAmount,
    watchCapitalCallAmount,
    tabSelected,
    watchPaidAmount,
    watchPartiallyPaidUnits,
    watchCurrentUnitPrice,
  ]);

  // Set selected price tab to validate price in yup
  useEffect(() => {
    if (Number.isInteger(tabSelected)) {
      form.trigger('entryUnitPrice');
      form.trigger('currentUnitPrice');
    }
  }, [tabSelected]);

  const handleCalcUnits = () => {
    const entryUnitPrice = form.watch('entryUnitPrice');
    const price =
      tabSelected === IssuePriceTab.CURRENT_UNIT_PRICE ? watchCurrentUnitPrice : entryUnitPrice;
    let units = 0;

    if (isTrustInFirstCase && price) {
      units = watchInvestmentAmount / price;
    } else if (isTrustInSecondCase && price) {
      units = watchCapitalCallAmount / price;
    } else if (isTrustInThirdCase && watchPartiallyPaidUnits && price) {
      units = watchInvestmentAmount / price;
    } else if (isTrustInThirdCase && !watchPartiallyPaidUnits && price) {
      units = watchPaidAmount / price;
    }

    form.setValue('units', units);
  };

  const handleUpdateUnitPrice = (price: number) => {
    form.setValue('currentUnitPrice', price);
    form.setValue('isUpdateUnitPrice', true);
    modalAlertRef.current?.open();
  };

  const PRICE_TABS = [
    {
      label: 'Entry Unit Price',
      key: IssuePriceTab.ENTRY_UNIT_PRICE,
      component: (
        <FormCurrencyInput
          name='entryUnitPrice'
          label='Entry Unit Price'
          hideCurrency
          decimalScale={
            entryPriceRounding <= (unitPriceRounding as number)
              ? unitPriceRounding
              : entryPriceRounding
          }
          placeholder='Enter entry unit price'
          fixedDecimalScale
          disabled
        />
      ),
    },
    {
      label: 'Mid Price',
      key: IssuePriceTab.CURRENT_UNIT_PRICE,
      component: (
        <FormCurrencyInput
          name='currentUnitPrice'
          label='Mid Price'
          hideCurrency
          disabled
          decimalScale={
            midPriceRounding <= (unitPriceRounding as number) ? unitPriceRounding : midPriceRounding
          }
          fixedDecimalScale
        />
      ),
    },
  ];

  return (
    <Box className='mt-8'>
      <UnitPriceTabs
        tabs={PRICE_TABS}
        tabSelected={tabSelected}
        setTabSelected={(tab: number) => {
          form.setValue('priceTab', tab);
          setTabSelected(tab);
        }}
      />

      <BasicModal ref={updateUnitPriceModalRef}>
        <UpdateUnitPriceModal
          onClose={() => updateUnitPriceModalRef.current?.close()}
          unitPriceRounding={unitPriceRounding}
          onConfirmUpdateUnitPrice={handleUpdateUnitPrice}
          unitClassId={unitClassId}
          currentUnitPriceValue={watchCurrentUnitPrice}
        />
      </BasicModal>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title='You did it !'
          description='Current unit price has been updated.'
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default IssueUnitPriceTabs;
