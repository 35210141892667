import * as React from 'react';

interface ILogoutIconProps {}

const LogoutIcon: React.FunctionComponent<ILogoutIconProps> = () => {
  return (
    <svg
      width='13.3311599px'
      height='10.6666667px'
      viewBox='0 0 13.3311599 10.6666667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-9.7%'
          y='-10.1%'
          width='119.4%'
          height='128.2%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx={0} dy={5} in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation={5} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Profile_00'
          transform='translate(-1259.333333, -160.666667)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g
            id='Group-26-Copy-2'
            filter='url(#filter-1)'
            transform='translate(1242.000000, 64.000000)'
          >
            <g id='Group-20-Copy' transform='translate(4.000000, 84.000000)'>
              <g
                id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                transform='translate(12.000000, 10.000000)'
              >
                <g id='Group' transform='translate(1.333333, 2.666667)'>
                  <path
                    d='M4.33466667,5.13674857 C4.70359288,5.13674857 5.00266667,5.42859277 5.00266667,5.78860083 C5.00266667,6.14860889 4.70359288,6.44045309 4.33466667,6.44045309 C3.96574045,6.44045309 3.66666667,6.14860889 3.66666667,5.78860083 C3.66666667,5.42859277 3.96574045,5.13674857 4.33466667,5.13674857 L4.33466667,5.13674857 Z'
                    id='Path'
                  />
                  <path
                    d='M6.66666667,0.487910004 L6.66666667,4.81472575 L11.628,4.8140752 L10.48,3.692525 C10.3049976,3.52179256 10.2843051,3.25182358 10.4313333,3.05758707 L10.48,3.00294078 C10.6549618,2.83216878 10.9316185,2.81197642 11.1306667,2.95545054 L11.1866667,3.00294078 L13.1846667,4.95264259 C13.359669,5.12337504 13.3803616,5.39334401 13.2333333,5.58758052 L13.1846667,5.64222682 L11.1873333,7.5964825 C11.002282,7.7763198 10.7065666,7.78688517 10.5085338,7.62073476 C10.3105009,7.45458434 10.276869,7.167695 10.4313333,6.96219512 L10.4793333,6.90689827 L11.6213333,5.78990193 L6.66733333,5.79055248 L6.66666667,10.17852 C6.66673616,10.3227566 6.60140294,10.4596318 6.48821174,10.5523889 C6.37502054,10.645146 6.22557224,10.6842784 6.08,10.6592773 L0.413333333,9.68214951 C0.174707751,9.64116784 0.000532311638,9.43912873 0,9.2026933 L0,1.39607942 C0,1.1572469 0.177177555,0.953521653 0.418666667,0.914671563 L6.08533333,0.00650214787 C6.23019933,-0.0168034877 6.37824114,0.0232013412 6.4901862,0.115904149 C6.60213126,0.208606956 6.66666455,0.344637655 6.66666667,0.487910004 Z M5.66666667,1.06234668 L1,1.81048051 L1,8.79284607 L5.66666667,9.5969273 L5.66666667,1.06234668 Z'
                    id='Shape'
                  />
                  <path
                    d='M7.33333333,9.69125722 L7.84333333,9.69125722 L7.91133333,9.68735391 C8.15937203,9.65412824 8.3439903,9.44700508 8.34333508,9.2026933 L8.33866667,6.43785089 L7.33333333,6.43785089 L7.33333333,9.69125722 Z M7.33466667,4.16092184 L7.33333333,3.33146911 L7.33333333,0.908166051 L7.83,0.908166051 C8.07963634,0.908131514 8.29104995,1.08777899 8.32533333,1.32907265 L8.33,1.39542887 L8.33466667,4.16092184 L7.33466667,4.16092184 L7.33466667,4.16092184 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoutIcon;
