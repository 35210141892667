/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IFavouriteInActiveIconProps { }

export const FavouriteInActiveIcon: FC<IFavouriteInActiveIconProps> = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Investor-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="00_Investor-Portal_Application_01" transform="translate(-927, -264)">
          <g id="My-Offer-&amp;-Invitation" transform="translate(311, 178)">
            <g id="Group-3" transform="translate(0, 70)">
              <g id="Group-18-Copy-3" transform="translate(344, 0)">
                <g id="Avatar" transform="translate(272, 16)">
                  <circle id="Oval" fillOpacity="0.5" fill="#394042" cx="12" cy="12" r="12"></circle>
                  <g id="Avatar-Copy" transform="translate(4, 3)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="Group" transform="translate(1.3333, 2)">
                      <path d="M5.85868756,0.498046364 C6.18868756,-0.166015455 7.14268756,-0.166015455 7.47268756,0.498046364 L9.04468756,3.65763133 L12.5600209,4.1642761 C13.2980209,4.2707641 13.5926876,5.17095149 13.0586876,5.6875175 L10.5146876,8.1479856 L11.1153542,11.6204204 C11.2420209,12.3499624 10.4700209,12.9062134 9.80935423,12.562277 L6.66535423,10.9219649 L3.5220209,12.562277 C2.8620209,12.9062134 2.0900209,12.3506238 2.21535423,11.6204204 L2.8160209,8.1479856 L0.272687564,5.6875175 C-0.261312436,5.1716129 0.0333542302,4.2707641 0.77135423,4.1642761 L4.28668756,3.65763133 L5.85868756,0.498046364 Z M6.66535423,1.11713188 L5.1600209,4.1437722 C5.02890108,4.40709884 4.77564157,4.58961212 4.48268756,4.63189732 L1.1160209,5.11737678 L3.5520209,7.47334112 C3.7640209,7.67838013 3.86068756,7.97403315 3.81068756,8.26373342 L3.2360209,11.5906567 L6.24668756,10.0197933 C6.50898227,9.88290238 6.82239285,9.88290238 7.08468756,10.0197933 L10.0953542,11.5899953 L9.52068756,8.26373342 C9.47055341,7.974091 9.56728317,7.67851963 9.77935423,7.47334112 L12.2153542,5.11737678 L8.84868756,4.63189732 C8.55573355,4.58961212 8.30247405,4.40709884 8.17135423,4.1437722 L6.66535423,1.11713188 L6.66535423,1.11713188 Z" id="Shape"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
