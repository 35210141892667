import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomEditor from 'src/components/atoms/CustomEditor';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import StatusBadge from 'src/components/molecules/StatusBadge';
import VerticalTabs from 'src/components/molecules/VerticalTabs';
import NewMessage from 'src/components/pages/communication/components/NewMessage';
import { INVESTMENT_AMOUNT_REQUIRED, investorTypeLabel } from 'src/constants/applications';
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_FORMAT_SERVER,
  DATE_TIME_FORMAT,
} from 'src/constants/date';
import {
  KYC_SECTION_ENUM,
  KYC_TAB_ENUM_GA_PORTAL,
  kycBackOfficeStatuses,
} from 'src/constants/kyc-back-office';
import { UnitClassInvestorType } from 'src/constants/unit-class';
import { detectPortalType } from 'src/helpers/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import {
  useBillingNoCharge,
  useGetInvestorDetailAuditTrails,
  useGetKYCBackOfficeById,
  useGetKYCBackOfficeNote,
  useGetUboAuditTrails,
  useKYCBackOfficeCompletedExternally,
  useKYCBackOfficeNote,
  useKYCBackReviewSubmit,
  useKYCBackReviewSubmitAdditionalComment,
} from 'src/modules/kyc-back-office/hooks';
import {
  AdditionalComments,
  IKYCApplicationDetails,
  IKYCInvestorDetails,
} from 'src/modules/kyc-back-office/type';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import {
  kycBackOfficeReviewSchema,
  kycCompletedExternallySchema,
  kycNoteSchema,
} from 'src/validations/kyc-backoffice';
import { ApplicationDetails, InvestorDetails } from './components';
import InvestorDetailsAuditTrails from './components/AuditTrail/InvestorDetailsAuditTrails';
import OwnershipStructureAndUBOAuditTrails from './components/AuditTrail/OwnershipStructureAndUBOAuditTrails';
import Billing from './components/Billing';
import Documents from './components/Documents';
import KYCFormSection from './components/KYCFormSection';
import OwnershipStructureAndUBO from './components/OwnershipStructureAndUBO';
import Review from './components/Review';

interface DataItem {
  title: string;
  child: AdditionalComments[];
}

export interface IReviewFormData {
  offerTypeName?: string;
  approvedBy: string;
  approvedByName: string;
  approvedDate: string;
  externalCompletedBy: string;
  externalCompletedByName?: string;
  externalCompletedDate?: string;
  externaCompletedComment?: string;
  data?: DataItem[];
  investmentAmount?: number;
  additionalComments?: AdditionalComments;
  requestMoreInfoNotedByName?: string;
  requestMoreInfoNotedDate?: string;
  requestMoreInfoNoted?: string;
  additionalComment?: string;
}

export interface IKYCDetailForm {
  statusName: string;
  isKYCCompletedExternally: boolean;
  applicationDetails: IKYCApplicationDetails;
  investorDetails: IKYCInvestorDetails;
  review: IReviewFormData;
}

export interface IKycCompletedExternallyForm {
  comment: string;
  agree: boolean;
}

export interface IKycNoteForm {
  note: string;
}

const KYCDetail = () => {
  const { isFunds, isAdmin } = detectPortalType();
  const initiateComDrawerRef = useRef<ICustomDrawerElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [modalContent, setModalContent] = React.useState<string>();
  const [tabSelected, setTabSelected] = React.useState<number>(0);
  const [visualTree, setVisualTree] = useState('');

  const location = useLocation();
  const {
    id,
    isEditMode = false,
    editBillingOnly = false,
    tabActived = null,
  } = location.state || {};
  const modalKycCompletedExternallyRef = useRef<IBasicModalElement>(null);
  const modalKycCompletedExternallyNotSubmittedRef = useRef<IBasicModalElement>(null);
  const modalSubmitForReviewRef = useRef<IBasicModalElement>(null);
  const modalKycNoteRef = useRef<IBasicModalElement>(null);
  const drawerAuditTrailRef = useRef<ICustomDrawerElement>(null);

  const ownershipStructureAndUBOForm = useForm({});

  const kycCompletedExternallyForm = useForm<IKycCompletedExternallyForm>({
    resolver: yupResolver(kycCompletedExternallySchema),
  });

  const kycNoteForm = useForm<IKycNoteForm>({
    resolver: yupResolver(kycNoteSchema),
  });

  const getStep = () => {
    if (isFunds) {
      return tabSelected || 0;
    }
    return tabSelected === KYC_TAB_ENUM_GA_PORTAL.BILLING
      ? KYC_SECTION_ENUM.BILLING
      : tabSelected === KYC_TAB_ENUM_GA_PORTAL.REVIEW
      ? KYC_SECTION_ENUM.REVIEW
      : tabSelected || 0;
  };

  const { data: kycDetailsData, refetch } = useGetKYCBackOfficeById(id, getStep());
  const { data: userInfo } = useGetUserInfo();
  const { data: note, refetch: noteRefetch } = useGetKYCBackOfficeNote(id);
  const { mutate: billingNoCharge, isLoading: loadingBillingNoCharge } = useBillingNoCharge();
  const { data: investorDetailsAuditTrails = [] } = useGetInvestorDetailAuditTrails(
    id,
    tabSelected === KYC_SECTION_ENUM.INVESTOR_DETAILS,
  );
  const { data: ubosAuditTrails = [] } = useGetUboAuditTrails(
    id,
    tabSelected === KYC_SECTION_ENUM.OWNERSHIP_AND_UBO,
  );

  const {
    applicationDetails,
    investmentEntityName,
    statusName = '',
    caseId = '',
  } = kycDetailsData || {};

  const form = useForm<IKYCDetailForm>({
    resolver: yupResolver(kycBackOfficeReviewSchema),
  });

  const {
    mutateAsync: kycBackOfficeReviewSubmitMutate,
    isLoading: isKycBackOfficeReviewSubmitLoading,
  } = useKYCBackReviewSubmit();

  const {
    mutateAsync: kycBackOfficeReviewSubmitAdditionalMutate,
    isLoading: isKycBackOfficeReviewSubmitAdditionalLoading,
  } = useKYCBackReviewSubmitAdditionalComment();

  const {
    mutateAsync: kycBackOfficeCompletedExternallyMutate,
    isLoading: isKycBackOfficeCompletedExternallyLoading,
  } = useKYCBackOfficeCompletedExternally();

  const { mutateAsync: kycBackOfficeNoteMutate, isLoading: isKycBackOfficeNoteLoading } =
    useKYCBackOfficeNote();

  useEffect(() => {
    refetch();
  }, [tabSelected]);

  useEffect(() => {
    if (isNumber(tabActived)) {
      setTabSelected(tabActived);
    }
  }, [tabActived]);

  useEffect(() => {
    if (kycDetailsData?.ownershipStructureUBO?.visualTree) {
      setVisualTree(kycDetailsData?.ownershipStructureUBO?.visualTree);
    }
  }, [kycDetailsData]);

  useEffect(() => {
    const applicationDetailsFormat: IKYCApplicationDetails = {
      ...applicationDetails,
      dateSubmitted: applicationDetails?.dateSubmitted
        ? dayjs(applicationDetails?.dateSubmitted).format(DATE_PICKER_FORMAT)
        : '',
      dateCompleted: applicationDetails?.dateCompleted
        ? dayjs(applicationDetails?.dateCompleted).format(DATE_PICKER_FORMAT)
        : '',
      dateFinalised: applicationDetails?.dateFinalised
        ? dayjs(applicationDetails?.dateFinalised).format(DATE_PICKER_FORMAT)
        : '',
      isExistingInvestorInFund: applicationDetails?.isExistingInvestorInFund ? 'Existing' : 'New',
      foreignStatus: applicationDetails?.foreignStatus ? 'Yes' : 'No',
      currencyName: applicationDetails?.currencyName,
    };

    const investorDetailsFormat: IKYCInvestorDetails = { ...kycDetailsData?.investorDetails };

    form.reset({
      statusName: kycDetailsData?.statusName,
      isKYCCompletedExternally: kycDetailsData?.isKYCCompletedExternally,
      applicationDetails: applicationDetailsFormat,
      investorDetails: investorDetailsFormat,
      review: {
        ...kycDetailsData?.review,
        investmentAmount: kycDetailsData?.review?.investmentAmount || 0,
        data: [
          {
            title: 'Investor Details Verification',
            child: kycDetailsData?.review?.investorDetailsVerification || [],
          },
          {
            title: 'UBOs Verification',
            child: kycDetailsData?.review?.ubOsVerification || [],
          },
          ...(String(kycDetailsData?.review?.offerTypeName) === investorTypeLabel.Wholesale &&
          (kycDetailsData?.review?.investmentAmount || 0) < INVESTMENT_AMOUNT_REQUIRED &&
          ![UnitClassInvestorType.Professional, UnitClassInvestorType.Sophisticated].includes(
            kycDetailsData?.review?.investorType as UnitClassInvestorType,
          )
            ? [
                {
                  title: 'Wholesale Investor Status',
                  child:
                    kycDetailsData?.review?.wholesaleInvestorStatus?.map((item) => ({
                      ...item,
                    })) || [],
                },
              ]
            : []),
        ],
      },
    });
  }, [kycDetailsData]);

  const handlesReviewSubmit = () => {
    form.trigger().then((isValid) => {
      if (isValid) {
        const data = form.getValues();
        const dataFormat = {
          approvedBy: data?.review?.approvedBy,
          approvedByName: data?.review?.approvedByName,
          approvedDate: data?.review?.approvedDate,
          externalCompletedBy: data?.review?.externalCompletedBy,
          externalCompletedByName: data?.review?.externalCompletedByName,
          externalCompletedDate: data?.review?.externalCompletedDate,
          externaCompletedComment: data?.review?.externaCompletedComment,
          investorDetailsVerification: data?.review?.data?.[0]?.child.map((item) => ({
            ...item,
            yesNo: Boolean(item.yesNo),
          })),
          ubOsVerification: data?.review?.data?.[1]?.child.map((item) => ({
            ...item,
            yesNo: Boolean(item.yesNo),
            singleSelect: item?.singleSelect?.toString(),
          })),
          wholesaleInvestorStatus: data?.review?.data?.[2]?.child.map((item) => ({
            ...item,
            yesNo: Boolean(item.yesNo),
            date: item?.date && dayjs(item?.date).format(DATE_PICKER_FORMAT_SERVER),
          })),
          additionalComments: {
            ...kycDetailsData?.review?.additionalComments,
            comment: data?.review?.additionalComments?.comment,
          },
        };

        const dataAdditionalFormat = {
          additionalComment: data?.review?.additionalComment || '',
          additionalCommentBy: userInfo?.id || '',
        };

        if (data?.isKYCCompletedExternally) {
          kycBackOfficeReviewSubmitAdditionalMutate(
            { kycId: kycDetailsData?.id || '', data: dataAdditionalFormat },
            {
              onSuccess: () => refetch(),
              onError: handleErrorFromServer,
            },
          );
        } else {
          kycBackOfficeReviewSubmitMutate(
            { kycId: kycDetailsData?.id || '', data: dataFormat },
            {
              onSuccess: () => refetch(),
              onError: handleErrorFromServer,
            },
          );
        }
      }
    });
  };

  const handleKycCompletedExternallySubmit = async (data: IKycCompletedExternallyForm) => {
    kycBackOfficeCompletedExternallyMutate(
      {
        kycId: kycDetailsData?.id || '',
        data: { comment: data?.comment, completedBy: userInfo?.id || '' },
      },
      {
        onSuccess: () => {
          refetch();
          modalKycCompletedExternallyRef?.current?.close();
        },
      },
    );
  };

  const handleKycNoteSubmit = async (data: IKycNoteForm) => {
    kycBackOfficeNoteMutate(
      {
        kycId: kycDetailsData?.id || '',
        data: { notes: data?.note, notedBy: userInfo?.id || '' },
      },
      {
        onSuccess: () => {
          noteRefetch();
          modalKycNoteRef?.current?.close();
        },
      },
    );
  };

  const handleKYCCompletedExternally = () => {
    if (isFunds || (isAdmin && kycDetailsData?.isSubmittedBilling)) {
      modalKycCompletedExternallyRef?.current?.open();
    } else {
      modalKycCompletedExternallyNotSubmittedRef?.current?.open();
    }
  };

  const handleDeclineSubmitBilling = () => {
    billingNoCharge(
      { id, isClosed: false },
      {
        onError: handleErrorFromServer,
        onSuccess: () => {
          modalKycCompletedExternallyNotSubmittedRef.current?.close();
          isAdmin && modalKycCompletedExternallyRef.current?.open();
        },
      },
    );
  };

  const handleSubmitForReview = () => {
    if (isFunds || (isAdmin && kycDetailsData?.isSubmittedBilling)) {
      handlesReviewSubmit();
    } else {
      modalSubmitForReviewRef.current?.open();
    }
  };

  const handleReviewBilling = () => {
    setTabSelected(KYC_TAB_ENUM_GA_PORTAL.BILLING);
    modalKycCompletedExternallyNotSubmittedRef.current?.close();
    modalSubmitForReviewRef.current?.close();
  };

  const handleOpenAuditTrail = () => {
    drawerAuditTrailRef.current?.open();
  };

  const handleCloseInitiateCom = () => {
    initiateComDrawerRef.current?.close();
  };

  const OrganisationSettingTabs = [
    {
      label: 'Application Details',
      component: (
        <FormProvider {...form}>
          <KYCFormSection title='Application Details'>
            <ApplicationDetails id={id} />
          </KYCFormSection>
        </FormProvider>
      ),
    },
    {
      label: 'Investor Details',
      component: (
        <FormProvider {...form}>
          <KYCFormSection title='Investor Details'>
            <InvestorDetails
              id={id}
              isEditMode={isEditMode}
              onOpenAuditTrail={handleOpenAuditTrail}
            />
          </KYCFormSection>
        </FormProvider>
      ),
    },
    {
      label: 'Ownership Structure & UBO',
      component: (
        <>
          {visualTree && (
            <>
              <KYCFormSection title='Ownership Structure'>
                <Box
                  className='flex justify-center'
                  dangerouslySetInnerHTML={{ __html: visualTree.replaceAll('\\', '') }}
                ></Box>
              </KYCFormSection>
              <Box className='-mt-6' />
            </>
          )}
          <FormProvider {...ownershipStructureAndUBOForm}>
            <KYCFormSection title='Ultimate Beneficial Owners'>
              <OwnershipStructureAndUBO
                id={id}
                isEditMode={isEditMode}
                onOpenAuditTrail={handleOpenAuditTrail}
              />
            </KYCFormSection>
          </FormProvider>
        </>
      ),
    },
    {
      label: 'Documents',
      component: (
        <KYCFormSection title='Uploaded Documents'>
          <Documents id={id} />
        </KYCFormSection>
      ),
    },
    {
      isHidden: isFunds,
      label: 'Billing',
      component: (
        <KYCFormSection
          title='Billing'
          status={
            kycDetailsData?.isSubmittedBilling ? (
              <Box
                className='flex w-fit items-center h-6 rounded-lg px-2 ml-2'
                bgcolor='sematic.information100'
              >
                <Typography
                  variant='body3'
                  className='font-medium'
                  whiteSpace={'pre'}
                  color='sematic.information500'
                >
                  Submitted Billing
                </Typography>
              </Box>
            ) : null
          }
        >
          <Billing
            id={id}
            billing={kycDetailsData?.billing}
            allowEdit={
              isEditMode || (editBillingOnly && tabActived === KYC_TAB_ENUM_GA_PORTAL.BILLING)
            }
          />
        </KYCFormSection>
      ),
    },
    {
      label: 'Review',
      component: (
        <FormProvider {...form}>
          <KYCFormSection
            title='Review'
            showSubmit={statusName !== kycBackOfficeStatuses.Approved}
            submitLabel='Submit for Review'
            onSubmit={handleSubmitForReview}
            isLoading={
              isKycBackOfficeReviewSubmitLoading || isKycBackOfficeReviewSubmitAdditionalLoading
            }
            isDisableSubmit={statusName === kycBackOfficeStatuses.Awaiting_Approval || !isEditMode}
          >
            <Review disabled={!isEditMode} />
          </KYCFormSection>
        </FormProvider>
      ),
    },
  ];

  const auditTrails = useMemo(() => {
    switch (tabSelected) {
      case KYC_SECTION_ENUM.INVESTOR_DETAILS:
        return investorDetailsAuditTrails;
      case KYC_SECTION_ENUM.OWNERSHIP_AND_UBO:
        return ubosAuditTrails;
      default:
        return [];
    }
  }, [tabSelected, investorDetailsAuditTrails, ubosAuditTrails]);

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box component='div'>
          <Box className='flex items-center'>
            <Typography variant='h6' className='mr-[12px]'>
              {kycDetailsData?.caseId}
            </Typography>
            <StatusBadge status={kycDetailsData?.statusName || ''} />
          </Box>
          <Typography variant='body2' color='neutral.ne500'>
            {investmentEntityName}
          </Typography>
        </Box>
        <Box className='flex'>
          <CustomButton
            startIcon={<EditOutlinedIcon color='#003E4E' />}
            variant='text'
            onClick={() => modalKycNoteRef?.current?.open()}
          >
            <Typography color='primary.main'>Notes</Typography>
          </CustomButton>
          <CustomButton
            sx={{
              mx: '10px',
              backgroundColor: 'neutral.ne200',
              color: 'neutral.ne800',
            }}
            variant='contained'
            disabled={!isEditMode}
            onClick={() => initiateComDrawerRef.current?.open()}
          >
            Initiate Communication
          </CustomButton>

          <CustomButton
            sx={{
              padding: '5px 20px',
            }}
            variant='contained'
            onClick={handleKYCCompletedExternally}
            disabled={!!kycDetailsData?.isKYCCompletedExternally || !isEditMode}
          >
            KYC Completed Externally
          </CustomButton>
        </Box>
      </Box>
      <Divider
        sx={{
          borderWidth: '1px',
          marginTop: '20px',
        }}
      />
      <VerticalTabs
        tabActived={tabSelected}
        tabs={OrganisationSettingTabs.filter((x) => !x.isHidden)}
        onChange={(value) => setTabSelected(value)}
        sx={{
          '.custom-vertical-tab': { flexShrink: 0 },
          '.tab-panel': { width: '0 !important', flex: 1 },
        }}
      />
      <Divider
        sx={{
          borderWidth: '1px',
        }}
      />
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it!`}
          description={modalContent as string}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              modalAlertRef?.current?.close();
              setModalContent('');
            },
          }}
        />
      </BasicModal>
      <BasicModal ref={modalKycCompletedExternallyRef}>
        <FormProvider {...kycCompletedExternallyForm}>
          <Box className='w-[600px] p-10'>
            <Typography variant='h5'>KYC Completed Externally</Typography>
            <Box className='mt-6'>
              <FormInput
                name='comment'
                label='Comment'
                placeholder='Enter comment'
                labelClass='text-[16px] font-[500]'
                multiline
                rows={4}
                maxLength={280}
                isShowCharactersLeft
              />
              <Box className='mt-2'>
                <FormCheckbox
                  name='agree'
                  label='I certify that AML/KYC verification for this investor has been completed externally and this case can be closed and marked as complete.'
                  sx={{ alignSelf: 'flex-start' }}
                />
              </Box>
            </Box>
            <Box className='mt-6 flex flex-row-reverse'>
              <Box className='flex gap-2'>
                <CustomButton
                  sx={{ color: 'neutral.ne800' }}
                  variant='text'
                  onClick={() => modalKycCompletedExternallyRef?.current?.close()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  isLoading={isKycBackOfficeCompletedExternallyLoading}
                  onClick={kycCompletedExternallyForm.handleSubmit(
                    handleKycCompletedExternallySubmit,
                  )}
                >
                  Confirm
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <BasicModal ref={modalKycCompletedExternallyNotSubmittedRef}>
        <Box className='w-[600px] p-10'>
          <Typography variant='h5'>Billing Required?</Typography>
          <Typography className='mt-4' variant='body2' color='neutral.ne800' fontWeight={500}>
            Would you like to submit billing for this case?
          </Typography>
          <Box></Box>
          <Box className='mt-6 flex flex-row-reverse'>
            <Box className='flex gap-2'>
              <CustomButton
                onClick={handleDeclineSubmitBilling}
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                isLoading={loadingBillingNoCharge}
              >
                No
              </CustomButton>
              <CustomButton onClick={handleReviewBilling} disabled={loadingBillingNoCharge}>
                Yes
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal ref={modalSubmitForReviewRef}>
        <Box className='w-[600px] p-10'>
          <Typography variant='h5'>Submit for Review?</Typography>
          <Typography className='mt-4' variant='body2' color='neutral.ne800' fontWeight={500}>
            Please review and finalise billing before submitting for review.
          </Typography>
          <Box></Box>
          <Box className='mt-6 flex flex-row-reverse'>
            <Box className='flex gap-2'>
              <CustomButton
                onClick={() => modalSubmitForReviewRef.current?.close()}
                sx={{ color: 'neutral.ne800' }}
                variant='text'
              >
                Cancel
              </CustomButton>
              <CustomButton onClick={handleReviewBilling}>Review Billing</CustomButton>
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal ref={modalKycNoteRef}>
        <FormProvider {...kycNoteForm}>
          <Box className='w-[600px] p-10'>
            <Box className='flex items-center justify-between'>
              <Typography variant='h5'>Note</Typography>
              {note?.updatedBy && note?.updatedOn ? (
                <Typography variant='body3' color='neutral.ne800'>{`Last updated by ${
                  note?.updatedBy
                } at ${utcToLocalTimezone(note?.updatedOn, DATE_TIME_FORMAT)}`}</Typography>
              ) : (
                <Typography variant='body3' color='neutral.ne800'>
                  Last updated by
                </Typography>
              )}
            </Box>
            <Box className='mt-6'>
              <CustomEditor
                name='note'
                initValue={note?.notes || ''}
                height={300}
                readOnly={!isEditMode}
              />
            </Box>
            <Box className='mt-6 flex flex-row-reverse'>
              <Box className='flex gap-2'>
                <CustomButton
                  sx={{ color: 'neutral.ne800' }}
                  variant='text'
                  onClick={() => modalKycNoteRef?.current?.close()}
                >
                  Cancel
                </CustomButton>
                {isEditMode && (
                  <CustomButton
                    isLoading={isKycBackOfficeNoteLoading}
                    onClick={kycNoteForm.handleSubmit(handleKycNoteSubmit)}
                  >
                    Save
                  </CustomButton>
                )}
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <CustomDrawer ref={drawerAuditTrailRef} title='Audit Trail' isWithoutActions>
        {tabSelected === KYC_SECTION_ENUM.INVESTOR_DETAILS ? (
          <InvestorDetailsAuditTrails data={auditTrails} />
        ) : (
          <OwnershipStructureAndUBOAuditTrails data={auditTrails} />
        )}
      </CustomDrawer>
      <NewMessage
        kycBoId={id}
        isInitialCommunication
        ref={initiateComDrawerRef}
        onClose={handleCloseInitiateCom}
        subject={`AML/KYC - ${caseId} - ${investmentEntityName} - Request for more information`}
      />
    </Box>
  );
};
export default KYCDetail;
