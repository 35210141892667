import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FINANCIAL_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import FinancialDetailsContent from 'src/components/pages/applications/components/shared/FinancialDetailsContent';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { INVESTMENT_ENTITY_STEP } from './InvestmentEntityForm';

interface IFinancialDetailsProps extends IUpsertInvestmentApplicationForm {
  keyName?: string;
  isWithoutFinancialAndTaxDetails?: boolean;
}

const FinancialDetails: FC<IFinancialDetailsProps> = ({
  id = '',
  keyName = '',
  isWithoutFinancialAndTaxDetails,
  isViewMode,
}) => {
  const form = useFormContext();
  const { data: investmentEntityData } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.FINANCIAL_DETAILS,
  });

  const details = investmentEntityData?.financialDetails;

  useEffect(() => {
    if (!isEmpty(details) && isWithoutFinancialAndTaxDetails) {
      form.reset(details);
    }
  }, [details, isWithoutFinancialAndTaxDetails]);

  return <FinancialDetailsContent keyName={keyName} disabled={isViewMode} />;
};

export default FinancialDetails;
