import { Box, Chip, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import IndividualBasicInfo from 'src/components/molecules/individual/IndividualBasicInfo';
import { PARTNER_TYPE, partnerEntityTypeOptions } from 'src/constants/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';

interface IPartnerItemProps extends IInvestmentEntityProps {
  keyName: string;
  onDelete: () => void;
  canDelete?: boolean;
  beVerified?: boolean;
  partnerType?: number;
}

const PartnerItem: FC<IPartnerItemProps> = (props) => {
  const {
    keyName,
    isViewMode,
    onDelete,
    canDelete = true,
    beVerified = false,
    partnerType,
    isDisabledEdit,
    isExistingInvestmentEntity,
  } = props;
  const theme = useTheme();

  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;

  const { watch } = useFormContext();
  const firstName = watch(`${keyName}.firstName`);
  const lastName = watch(`${keyName}.lastName`);
  const entityName = watch(`${keyName}.entityName`);

  const renderHeaderName = () => {
    if (!Number.isInteger(partnerType)) return null;

    return partnerType === PARTNER_TYPE.Individuals ? (
      <Typography variant='body2' fontWeight={500}>
        {`${firstName || ''} ${lastName || ''}${firstName || lastName ? ` - ` : ''}Partner`}
      </Typography>
    ) : (
      <Typography variant='body2' fontWeight={500}>
        {`${entityName} ${entityName ? ' - ' : ''}Partner`}
      </Typography>
    );
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        border: `1px solid ${theme.palette.neutral.ne300} !important`,
        '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        backgroundColor: 'inherit',
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
          '.MuiAccordionSummary-content': { my: 0 },
          minHeight: 'unset',
        }}
      >
        <Box className='flex justify-between items-center w-full'>
          <Box className='flex items-center'>
            {renderHeaderName()}
            {beVerified && (
              <Chip
                label={<Typography variant='body3'>To be verified</Typography>}
                variant='outlined'
                color='success'
                sx={{
                  padding: '2px 8px',
                  ml: 1,
                  height: 24,
                  borderRadius: 2,
                  '.MuiChip-label': {
                    padding: 0,
                    height: 'fit-content',
                  },
                }}
              />
            )}
          </Box>
          {!isViewMode && canDelete && (
            <IconButton onClick={onDelete} sx={{ p: 0 }}>
              <DeleteIcon width={24} height={24} color={theme.palette.neutral.ne600} />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className='p-6 pt-0 mt-0'>
        {partnerType === PARTNER_TYPE.Individuals && (
          <IndividualBasicInfo
            {...props}
            keyName={keyName}
            disabled={disabled}
            position={{
              show: false,
            }}
            autocompleteAddress
          />
        )}
        {partnerType === PARTNER_TYPE.Other && (
          <Grid container columnSpacing='20px' rowSpacing={2}>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.entityName`}
                label='Entity Name'
                placeholder='Enter entity name'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name={`${keyName}.entityType`}
                label='Entity Type'
                placeholder='Select entity type'
                options={partnerEntityTypeOptions}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.acn`}
                label='ACN/ABN'
                placeholder='Enter ACN/ABN'
                maxLength={14}
                formatValue={(value) => formatValueWithSpaces(value)}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.businessAddress`}
                label='Business Address'
                placeholder='Enter business address'
                disabled={disabled}
              />
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PartnerItem;
