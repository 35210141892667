/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

export const PeopleIcon: FC<SVGProps<SVGSVGElement>> = ({ color = '#686D6E', ...props }) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 10 9.5'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      color={color}
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Overarching-Filters_01'
          transform='translate(-297.000000, -30.500000)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <g
            id='02.Atoms/06.Button/03.Small/02.With-Icon/Left/01.Primary/01.Default-Copy'
            transform='translate(284.000000, 19.000000)'
          >
            <g id='Icon-Left' transform='translate(12.000000, 10.000000)'>
              <g id='Group' transform='translate(1.000000, 1.500000)'>
                <path
                  d='M0.75,5.5 L5.75,5.5005 C6.13631096,5.50051854 6.45941415,5.79397329 6.4965,6.1785 L6.5,6.25 L6.5,7.25 C6.4995,9 4.642,9.5 3.25,9.5 C1.889,9.5 0.0825,9.022 0.0025,7.365 L0,7.25 L0,6.25 C0,5.86368904 0.293473285,5.54058585 0.678,5.5035 L0.75,5.5 Z M6.744,5.5 L9.25,5.5 C9.63613707,5.5 9.95917342,5.79317128 9.9965,6.1775 L10,6.25 L10,7 C9.9995,8.531 8.571,9 7.5,9 C7.1372362,9.00085536 6.77644952,8.94656876 6.43,8.839 C6.7565,8.464 6.968,7.9875 6.9965,7.39 L7,7.25 L7,6.25 C7,6.003 6.925,5.7745 6.8005,5.581 L6.744,5.5 L9.25,5.5 L6.744,5.5 Z M3.25,-4.4408921e-16 C4.49264069,-4.4408921e-16 5.5,1.00735931 5.5,2.25 C5.5,3.49264069 4.49264069,4.5 3.25,4.5 C2.00735931,4.5 1,3.49264069 1,2.25 C1,1.00735931 2.00735931,-4.4408921e-16 3.25,-4.4408921e-16 L3.25,-4.4408921e-16 Z M7.75,1 C8.71649831,1 9.5,1.78350169 9.5,2.75 C9.5,3.71649831 8.71649831,4.5 7.75,4.5 C6.78350169,4.5 6,3.71649831 6,2.75 C6,1.78350169 6.78350169,1 7.75,1 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
