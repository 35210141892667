/* eslint-disable no-empty-pattern */
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';

interface IApplicationDetailsProps {
  viewOnly?: boolean;
  id: string;
}

const ApplicationDetails: React.FC<IApplicationDetailsProps> = ({ id }) => {
  const { watch } = useFormContext();

  const firstName = watch(`applicationDetails.primaryContact.firstName`);
  const lastName = watch(`applicationDetails.primaryContact.lastName`);
  const currencyName = watch(`applicationDetails.currencyName`);

  return (
    <Box>
      <Grid container padding={0} spacing={3}>
        <Grid item xs={12} md={12}>
          <FormInput
            name='applicationDetails.applicationNumber'
            label='Application Number'
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.investorName' label='Investor' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='applicationDetails.investmentAmount'
            label='Investment Amount'
            readOnly
            hideCurrency
            endAdornment={
              <Typography variant='body3' fontWeight='medium'>
                {currencyName || ''}
              </Typography>
            }
            decimalScale={2}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.fundName' label='Fund' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.unitclassName' label='Unit Class' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.dateSubmitted' label='Date Submitted' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.dateFinalised' label='Date Finalised' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.offerTypeName' label='Offer Type' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.caseTeamMember' label='Case Team Member' readOnly />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.entityTypeName' label='Entity Type' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.investorTypeName' label='Investor Type' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='applicationDetails.isExistingInvestorInFund'
            label='Investor Status'
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.investorNumber' label='Investor Number' readOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput name='applicationDetails.foreignStatus' label='Foreign Status' readOnly />
        </Grid>
      </Grid>
      <Box className='flex items-center w-full h-[48px] mt-8'>
        <Typography variant='body2' className='font-medium' color='base.black'>
          Primary Contact Person
        </Typography>
      </Box>
      <Accordion
        defaultExpanded
        sx={(theme) => ({
          border: `1px solid ${theme.palette.neutral.ne300} !important`,
          '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        })}
      >
        <AccordionSummary
          sx={{
            '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
            '.MuiAccordionSummary-content': { my: 0 },
            minHeight: 'unset',
          }}
        >
          <Box className='flex justify-between items-center w-full'>
            <Typography variant='body2' fontWeight={500}>
              {`${firstName ? firstName : ''} ${lastName ? lastName : ''}`}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className='p-6 pt-0 mt-0'>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormInput
                name='applicationDetails.primaryContact.firstName'
                label='First Name'
                size='small'
                className='w-full'
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='applicationDetails.primaryContact.lastName'
                label='Last Name'
                size='small'
                className='w-full'
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='applicationDetails.primaryContact.email'
                label='Email Address'
                size='small'
                className='w-full'
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='applicationDetails.primaryContact.contactNumber'
                countryFlagCodeName='applicationDetails.primaryContact.countryFlagCode'
                label='Contact Number'
                size='small'
                className='w-full'
                isIncludeCode
                readOnly
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ApplicationDetails;
