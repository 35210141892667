/* eslint-disable no-empty-pattern */
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomAlert from 'src/components/atoms/CustomAlert';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { IKYCDetailForm } from 'src/components/pages/kyc-back-office/KYCDetail';
import { TrueFalseRadioNoLabelOptions } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import {
  EntityRiskRatingOptions,
  QUESTION_TYPE,
  kycBackOfficeStatuses,
} from 'src/constants/kyc-back-office';
import { utcToLocalTimezone } from 'src/utils/time';

interface IReviewProps {
  isFromFundApp?: boolean;
  disabled?: boolean;
  hideQuestionsForm?: boolean;
}

const Review: React.FC<IReviewProps> = ({ isFromFundApp = false, disabled, hideQuestionsForm }) => {
  const { control, watch } = useFormContext<IKYCDetailForm>();

  const isKYCCompletedExternally = watch('isKYCCompletedExternally');
  const externalCompletedByName = watch('review.externalCompletedByName');
  const externalCompletedDate = watch('review.externalCompletedDate');
  const approvedByName = watch('review.approvedByName');
  const approvedDate = watch('review.approvedDate');
  const statusName = watch('statusName');
  const requestMoreInfoNotedByName = watch('review.requestMoreInfoNotedByName');
  const requestMoreInfoNotedDate = watch('review.requestMoreInfoNotedDate');
  const requestMoreInfoNoted = watch('review.requestMoreInfoNoted');

  const { fields } = useFieldArray({
    control,
    name: 'review.data',
  });

  const isDisable =
    statusName == kycBackOfficeStatuses.Awaiting_Approval ||
    statusName == kycBackOfficeStatuses.Withdrawn ||
    statusName == kycBackOfficeStatuses.Closed ||
    statusName == kycBackOfficeStatuses.Approved ||
    isFromFundApp;

  const renderQuestion = (
    questionType: number,
    question = '',
    index: number,
    childIndex: number,
  ) => {
    switch (questionType) {
      case QUESTION_TYPE.Select:
        return (
          <FormSelect
            name={`review.data[${index}].child[${childIndex}].singleSelect`}
            size='small'
            placeholder='Select entity risk rating'
            fullWidth
            options={EntityRiskRatingOptions}
            className='w-[223px]'
            readOnly={isDisable || disabled}
            label='Entity Risk Rating'
          />
        );

      case QUESTION_TYPE.DatePicker:
        return (
          <Box>
            <FormDatePicker
              name={`review.data[${index}].child[${childIndex}].date`}
              datePickerProps={{ format: DATE_PICKER_FORMAT, readOnly: isDisable || disabled }}
              label='Date of Issue'
            />
          </Box>
        );

      default:
        return (
          <Typography variant='body2' color='neutral.ne800'>
            {question}
          </Typography>
        );
    }
  };

  const renderItems = () => {
    return fields.map((item, index) => {
      return (
        <Box className='mt-8' key={index}>
          <Box
            className='flex border-t-[1px] border-x-[1px] rounded-t-[10px] px-5'
            bgcolor='neutral.ne100'
          >
            <Grid xs={6} md={6} className='border-r-[1px] py-3 px-1' borderColor='neutral.ne200'>
              <Typography variant='body2'>{item.title}</Typography>
            </Grid>
            <Grid xs={6} md={6} className='flex py-3 px-1 justify-between'>
              <Grid xs={2} md={2} gap={3.5} className='flex ml-2'>
                <Typography variant='body2'>Yes</Typography>
                <Typography variant='body2'>No</Typography>
              </Grid>
              <Grid xs={8.5} md={8.5} className='flex'>
                <Typography variant='body2'>Comment</Typography>
              </Grid>
            </Grid>
          </Box>
          {item.child.map((childItem, childIndex) => {
            const isNotDatePickerOrSelectionType =
              childItem?.questionType !== QUESTION_TYPE.DatePicker &&
              childItem?.questionType !== QUESTION_TYPE.Select;

            return (
              <Box
                className={`flex border-[1px] px-5 ${
                  childIndex + 1 === item.child.length && 'rounded-b-[10px]'
                }`}
                key={childIndex}
              >
                <Grid
                  xs={6}
                  md={6}
                  className='border-r-[1px] py-3 px-1 flex items-center'
                  borderColor='neutral.ne200'
                >
                  {renderQuestion(
                    childItem?.questionType || QUESTION_TYPE?.Text,
                    childItem.question,
                    index,
                    childIndex,
                  )}
                </Grid>
                <Grid xs={6} md={6} className='flex py-3 px-1 justify-between'>
                  <Grid xs={2} md={2} className='w-full flex justify-between ml-3'>
                    {isNotDatePickerOrSelectionType && (
                      <Grid xs={4} md={4} className='flex justify-center'>
                        <FormRadioGroup
                          name={`review.data[${index}].child[${childIndex}].yesNo`}
                          className='w-full'
                          row
                          options={TrueFalseRadioNoLabelOptions}
                          disabled={isDisable || disabled}
                          isBooleanValue
                          isShowIconErrorText={false}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid xs={8.5} md={8.5} className='flex items-center'>
                    <FormInput
                      name={`review.data[${index}].child[${childIndex}].comment`}
                      placeholder='Enter comment'
                      readOnly={isDisable || disabled}
                      label={isNotDatePickerOrSelectionType ? '' : ' '}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      );
    });
  };

  return (
    <Grid container padding={0} spacing={3}>
      {statusName === kycBackOfficeStatuses.Approved && (
        <Grid item xs={12} md={12}>
          <CustomAlert severity='info' sx={{}}>
            {`The Case was Approved by ${approvedByName} on ${
              approvedDate && utcToLocalTimezone(approvedDate)}`}
          </CustomAlert>
        </Grid>
      )}
      {isKYCCompletedExternally && (
        <>
          <Grid item xs={12} md={12}>
            <CustomAlert
              severity={'error'}
            >{`The case was marked as KYC completed externally by ${externalCompletedByName} on ${
              externalCompletedDate && utcToLocalTimezone(externalCompletedDate)}`}</CustomAlert>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInput
              name='review.externaCompletedComment'
              label='Comment'
              disabled
              multiline
              rows={4}
              placeholder='Enter comment'
            />
          </Grid>
        </>
      )}
      {!isKYCCompletedExternally && !hideQuestionsForm && (
        <>
          <Grid item xs={12} md={12} borderColor='neutral.ne200'>
            {renderItems()}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInput
              name='review.additionalComments.comment'
              label='Additional Comments'
              placeholder='Enter additional comments'
              multiline
              rows={4}
              readOnly={isDisable || disabled}
            />
          </Grid>
        </>
      )}
      {requestMoreInfoNoted && (
        <>
          {isKYCCompletedExternally && (
            <Grid item xs={12} md={12}>
              <FormInput
                name='review.additionalComment'
                label='Additional Comments'
                placeholder='Enter additional comments'
                multiline
                rows={4}
                readOnly={isDisable || disabled}
              />
            </Grid>
          )}
          {!isFromFundApp && (
            <Grid item xs={12} md={12}>
              <CustomAlert severity={isFromFundApp ? 'info' : 'error'}>
                {`More information was requested by ${requestMoreInfoNotedByName} on ${
                  requestMoreInfoNotedDate &&
                  utcToLocalTimezone(requestMoreInfoNotedDate)}`}
              </CustomAlert>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <FormInput
              name='review.requestMoreInfoNoted'
              label='Request'
              multiline
              rows={4}
              readOnly
            />
          </Grid>
          {isFromFundApp && (
            <Grid item xs={12} md={12}>
              <CustomAlert severity={isFromFundApp ? 'info' : 'error'}>
                {`Submitted by ${requestMoreInfoNotedByName} on ${
                  requestMoreInfoNotedDate &&
                  utcToLocalTimezone(requestMoreInfoNotedDate)}`}
              </CustomAlert>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default Review;
