import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { BULK_UPLOAD_API } from 'src/constants/apiEndpoints';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { removeEmptyProps } from 'src/utils/common';
import {
  createBulkUpload,
  getBulkUploadInvestorClients,
  getBulkUploadInvestorFunds,
  getBulkUploadInvestorUnitClasses,
  getBulkUploadList,
  getBulkUploadListById,
  getBulkUploadMasterData,
} from './services';
import { IBulkUploadQueryBody, ICreateBulkUpload } from './type';

export const useGetBulkUploadList = () => {
  const [queryBody, setQueryBody] = useState<IBulkUploadQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery(
    [BULK_UPLOAD_API.getBulkUploadList.api, queryBody],
    () => getBulkUploadList(queryBody),
  );

  const setParams = (newParams: IBulkUploadQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetBulkUploadListById = (id: string) => {
  const [queryBody, setQueryBody] = useState<IBulkUploadQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery(
    [BULK_UPLOAD_API.getBulkUploadListById.api(id), id, queryBody],
    () => getBulkUploadListById(id, queryBody),
    { enabled: !!id, keepPreviousData: true },
  );

  const setParams = (newParams: IBulkUploadQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetBulkUploadInvestorClients = () => {
  return useQuery([BULK_UPLOAD_API.getBulkUploadInvestorClients.api], getBulkUploadInvestorClients);
};

export const useGetBulkUploadMasterData = () => {
  return useQuery([BULK_UPLOAD_API.getBulkUploadMasterData.api], getBulkUploadMasterData);
};

export const useGetBulkUploadInvestorFunds = (clientId: string) => {
  return useQuery(
    [BULK_UPLOAD_API.getBulkUploadInvestorFunds.api(clientId), clientId],
    () => getBulkUploadInvestorFunds(clientId),
    {
      enabled: !!clientId,
    },
  );
};

export const useGetBulkUploadInvestorUnitClasses = (fundId: string) => {
  return useQuery(
    [BULK_UPLOAD_API.getBulkUploadInvestorUnitClasses.api(fundId), fundId],
    () => getBulkUploadInvestorUnitClasses(fundId),
    { enabled: !!fundId },
  );
};

export const useCreateBulkUpload = () => {
  const queryClient = useQueryClient();
  const resp = async (params: ICreateBulkUpload) => {
    const data = await createBulkUpload(params);
    await queryClient.invalidateQueries([BULK_UPLOAD_API.getBulkUploadList.api]);
    return data;
  };
  return useMutation(resp);
};
