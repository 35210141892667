import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect, { IFormSelectOption } from 'src/components/atoms/FormSelect';
import { FundType } from 'src/constants/funds-management';
import { TRANSFER_TYPE, transferTypeOptions } from 'src/constants/investor-form';
import { formatValueWithSpaces } from 'src/utils/common';
import { IRedemptionFormFieldsProps } from './RedemptionFormFields';

export interface ITransferFormFieldsProps extends IRedemptionFormFieldsProps {}

const TransferFormFields: FC<ITransferFormFieldsProps> = ({
  investmentEntityOptions,
  getFundOptions,
  getUnitClassIdOptions,
  fundType,
  unitRounding,
  currencyName,
  isViewMode,
  onGenerateFormId,
}) => {
  const form = useFormContext();
  const watchTransferType = form.watch('transferType');
  const isTrust = fundType === FundType.Trust;

  const handleInvestmentEntityChange = (value: string | number) => {
    onGenerateFormId(value as string);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput name='formId' label='Transfer Request ID' disabled />
        </Grid>
        <Grid item xs={6}>
          <FormInput name='investorNumber' label='Investor Number' disabled />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityId'
            label='Investment Entity'
            placeholder='Select investment entity'
            options={investmentEntityOptions}
            disabled={isViewMode}
            onSelectValue={handleInvestmentEntityChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker name='transferDate' label='Transfer Date' disabled={isViewMode} />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='fundId'
            label='Fund'
            placeholder='Select fund'
            options={getFundOptions() as IFormSelectOption[]}
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='unitClassId'
            label={fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class'}
            placeholder={
              fundType === FundType.Partnership ? 'Select financial rule' : 'Select unit class'
            }
            options={getUnitClassIdOptions()}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <Box className='pt-10'>
        <Typography className='pb-4.5' variant='body2' fontWeight={600}>
          Current Holdings
        </Typography>
        <Grid container rowSpacing='18px' columnSpacing={3}>
          {isTrust && (
            <Grid item xs={6}>
              <FormCurrencyInput
                name='units'
                label='Units'
                disabled
                hideCurrency
                decimalScale={unitRounding}
              />
            </Grid>
          )}
          <Grid item xs={isTrust ? 6 : 12}>
            <FormCurrencyInput
              name='investedAmount'
              label='Invested Amount'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormCurrencyInput
              name='unpaidAmount'
              label='Unpaid Amount'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormCurrencyInput
              name='netValue'
              label='Current Net Value'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='pt-10'>
        <Typography className='py-2 px-4' bgcolor='neutral.ne100' variant='body1' fontWeight={600}>
          Transfer
        </Typography>
        {isTrust && (
          <FormRadioGroup
            className='mt-8'
            name='transferType'
            options={transferTypeOptions}
            isEnumValue
            optionGap={4}
            fullWidth={false}
            disabled={isViewMode}
          />
        )}
        <Box className='pt-10'>
          <Grid container rowSpacing='18px' columnSpacing={3}>
            {watchTransferType === TRANSFER_TYPE.PARTIAL && (
              <Grid item xs={6}>
                <FormCurrencyInput
                  hideCurrency
                  name='transferUnits'
                  label='Transfer Units'
                  placeholder='Enter transfer units'
                  decimalScale={unitRounding}
                  disabled={isViewMode}
                />
              </Grid>
            )}
            <Grid item xs={watchTransferType === TRANSFER_TYPE.PARTIAL ? 6 : 12}>
              <FormCurrencyInput
                hideCurrency
                name='considerationAmount'
                label='Consideration Amount'
                placeholder='Enter consideration amount'
                endAdornment={
                  <Typography variant='body3' color='neutral.ne500'>
                    {currencyName}
                  </Typography>
                }
                decimalScale={2}
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='transfereeName'
                label='Transferee Name'
                placeholder='Enter transferee name'
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='transfereeABN'
                label='Transferee ACN/ABN (if applicable)'
                placeholder='Enter transferee ACN/ABN'
                formatValue={(value) => formatValueWithSpaces(value)}
                maxLength={14}
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='transfereeAddress'
                label='Transferee Address'
                placeholder='Enter transferee address'
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='email'
                label='Email Address'
                placeholder='Enter email address'
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormPhoneCountryInput
                name='contactNumber'
                label='Contact Number'
                size='small'
                isIncludeCode
                disabled={isViewMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name='contactPersonName'
                label='Contact Person Name'
                placeholder='Enter contact person name'
                disabled={isViewMode}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TransferFormFields;
