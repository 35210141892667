import * as React from 'react';

interface ICheckmarkCircleIconProps {}

export const CheckmarkCircleIcon: React.FunctionComponent<ICheckmarkCircleIconProps> = () => {
  return (
    <svg
      width='13.3333333px'
      height='13.3333333px'
      viewBox='0 0 13.3333333 13.3333333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-11.1%'
          y='-15.2%'
          width='122.2%'
          height='142.7%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx='0' dy='5' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_List-Clients-Action_00'
          transform='translate(-1178.333333, -1004.333333)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Action-Copy-3' transform='translate(1161.000000, 916.000000)'>
            <g
              id='Group-26-Copy-2'
              filter='url(#filter-1)'
              transform='translate(0.000000, 35.000000)'
            >
              <g id='Group-20' transform='translate(4.000000, 42.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                  transform='translate(12.000000, 10.000000)'
                >
                  <g id='Group' transform='translate(1.333333, 1.333333)'>
                    <path
                      d='M6.66666667,0 C10.3486667,0 13.3333333,2.98466667 13.3333333,6.66666667 C13.3333333,10.3486667 10.3486667,13.3333333 6.66666667,13.3333333 C2.98466667,13.3333333 0,10.3486667 0,6.66666667 C0,2.98466667 2.98466667,0 6.66666667,0 Z M6.66666667,1 C3.53705308,1 1,3.53705308 1,6.66666667 C1,9.79628025 3.53705308,12.3333333 6.66666667,12.3333333 C9.79628025,12.3333333 12.3333333,9.79628025 12.3333333,6.66666667 C12.3333333,3.53705308 9.79628025,1 6.66666667,1 L6.66666667,1 Z M5.83333333,7.62666667 L8.81333333,4.64666667 C8.99860147,4.4628398 9.29409425,4.45243401 9.49183435,4.62277324 C9.68957445,4.79311246 9.72303556,5.08688894 9.56866667,5.29733333 L9.52,5.35333333 L6.18666667,8.68666667 C6.01170482,8.86166905 5.73504815,8.8823616 5.536,8.73533333 L5.48,8.68666667 L3.81333333,7.02 C3.62950647,6.83473186 3.61910068,6.53923908 3.7894399,6.34149898 C3.95977913,6.14375888 4.2535556,6.11029777 4.464,6.26466667 L4.52,6.31333333 L5.83333333,7.62666667 L8.81333333,4.64666667 L5.83333333,7.62666667 L5.83333333,7.62666667 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
