import { Box, OutlinedInput, SxProps } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';

interface SwitchLayoutDropdownProps {
  selected: number;
  onChange: (value: number) => void;
  layoutOptions: { value: number; label: string }[];
  icon: ReactNode;
  inputClassName?: string;
  inputSx?: SxProps;
}

export const SwitchLayoutDropdown: FC<SwitchLayoutDropdownProps> = ({
  selected,
  onChange,
  layoutOptions,
  icon,
  inputClassName = '',
  inputSx = {},
}) => {
  return (
    <CustomSelect
      className='ml-[10px]'
      value={selected}
      onChange={(e: any) => onChange(e.target.value)}
      input={
        <OutlinedInput
          className={clsx('h-[32px] w-[200px] rounded-lg', inputClassName)}
          sx={{
            borderRadius: '0px',
            fontSize: 14,
            color: 'neutral.ne800',
            ...inputSx,
          }}
        />
      }
      displayEmpty
      renderValue={(value) => {
        const selectedValue = layoutOptions.find((it) => it.value === value)?.label;
        return (
          <Box color='neutral.ne800' display='flex' alignItems='center'>
            <Box mr={0.75} fontSize='12px'>
              {icon}
            </Box>
            {selectedValue}
          </Box>
        );
      }}
    >
      {layoutOptions.map((option: { value: number; label: string }) => (
        <CustomMenuItem key={option.value} value={option.value}>
          {option.label}
        </CustomMenuItem>
      ))}
    </CustomSelect>
  );
};
