import { Box, ButtonProps, SxProps } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useRef } from 'react';
import CustomButton from './CustomButton';
import { CustomMenu, ICustomMenuElement, IMenuItem } from './CustomMenu';

interface ISplitButton extends ButtonProps {
  menus?: IMenuItem[];
  containerSx?: SxProps;
  menuSx?: SxProps;
}

const SplitButton: FC<ISplitButton> = ({
  children,
  disabled,
  menus,
  containerSx = {},
  menuSx = {},
  ...rest
}) => {
  const menuRef = useRef<ICustomMenuElement>(null);
  const containerRef = useRef<HTMLElement>();

  const handleClickOptionButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isEmpty(menus) || !containerRef?.current) return;

    menuRef.current?.open(containerRef.current);
  };

  return (
    <>
      <Box ref={containerRef} sx={{ ...containerSx }}>
        <CustomButton
          disabled={disabled}
          {...rest}
          sx={(theme) => ({
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: `1px solid ${theme.palette.base.white}`,
          })}
        >
          {children}
        </CustomButton>
        <CustomButton
          disabled={disabled}
          {...rest}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            maxWidth: 48,
            minWidth: 0,
            paddingX: 2.5,
          }}
          onClick={handleClickOptionButton}
        >
          <span className='material-symbols-rounded'>expand_more</span>
        </CustomButton>
      </Box>
      <CustomMenu
        menuItems={menus || []}
        ref={menuRef}
        sx={{
          '& .MuiMenu-list': {
            width: 195,
          },
          ...menuSx,
        }}
      />
    </>
  );
};

export default SplitButton;
