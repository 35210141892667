/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface ICancelIconProps extends React.SVGProps<SVGSVGElement> {}

export const CancelIcon: React.FunctionComponent<ICancelIconProps> = (props) => {
  const { width = '14px', height = '14px', color = '#212121', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm-2.476 3.024a.5.5 0 0 0 0 .707l1.769 1.77-1.767 1.766a.5.5 0 1 0 .707.708l1.767-1.767 1.77 1.769a.5.5 0 1 0 .707-.707L18.208 17.5l1.771-1.77a.5.5 0 0 0-.707-.707l-1.771 1.77-1.77-1.77a.5.5 0 0 0-.707 0ZM17.75 3A3.25 3.25 0 0 1 21 6.25l.001 5.773a6.469 6.469 0 0 0-1.5-.71L19.5 8.5h-15v9.25c0 .966.784 1.75 1.75 1.75h5.064c.172.534.412 1.038.709 1.501L6.25 21A3.25 3.25 0 0 1 3 17.75V6.25A3.25 3.25 0 0 1 6.25 3h11.5Zm0 1.5H6.25A1.75 1.75 0 0 0 4.5 6.25V7h15v-.75a1.75 1.75 0 0 0-1.75-1.75Z'
        fill={color}
      />
    </svg>
  );
};
