/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

interface IPlusPrimaryIconProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

export const PlusPrimaryIcon: FC<IPlusPrimaryIconProps> = ({
  color = '#002A37',
  disabled,
  width = '24px',
  height = '24px',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Sprint-1'
        stroke='none'
        strokeWidth='1'
        fill={disabled ? '#C2C4C5' : color}
        fillRule='evenodd'
      >
        <g
          id='00_Create-new_03'
          transform='translate(-716.390244, -648.000000)'
          fill={disabled ? '#C2C4C5' : color}
          fillRule='nonzero'
        >
          <g id='Group-3' transform='translate(654.000000, 0.000000)'>
            <g id='Add-new' transform='translate(50.000000, 626.000000)'>
              <g
                id='02.Atoms/03.Drop-Down/01.Stroke/01.Default-'
                transform='translate(0.000000, 10.000000)'
              >
                <g
                  id='01.Design-Token/06.Icons/Line/plus-outline'
                  transform='translate(12.390244, 12.000000)'
                >
                  <g id='Group' transform='translate(2.000000, 2.000000)'>
                    <rect
                      id='Rectangle'
                      opacity='0'
                      transform='translate(10.000000, 10.000000) rotate(180.000000) translate(-10.000000, -10.000000) '
                      x='1.77635684e-15'
                      y='1.77635684e-15'
                      width='20.0000001'
                      height='20.0000001'
                    ></rect>
                    <path
                      d='M15.8333334,9.16666671 L10.8333334,9.16666671 L10.8333334,4.16666669 C10.8333334,3.70642939 10.4602373,3.33333335 10.0000001,3.33333335 C9.53976276,3.33333335 9.16666671,3.70642939 9.16666671,4.16666669 L9.16666671,9.16666671 L4.16666669,9.16666671 C3.70642939,9.16666671 3.33333335,9.53976276 3.33333335,10 C3.33333335,10.4602373 3.70642939,10.8333334 4.16666669,10.8333334 L9.16666671,10.8333334 L9.16666671,15.8333334 C9.16666671,16.2935707 9.53976276,16.6666667 10.0000001,16.6666667 C10.4602373,16.6666667 10.8333334,16.2935707 10.8333334,15.8333334 L10.8333334,10.8333334 L15.8333334,10.8333334 C16.2935707,10.8333334 16.6666668,10.4602373 16.6666668,10 C16.6666668,9.53976276 16.2935707,9.16666671 15.8333334,9.16666671 Z'
                      id='Path'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
