import { actionPermissionKeys } from './common';

export const fundDocumentActionTypes = {
  download: 'Download',
  delete: 'Delete',
  publish: 'Publish',
  unpublish: ' Unpublish',
  hide: 'Hide',
  unhide: 'Unhide',
};

export const investorDocumentActionTypes = {
  download: 'Download',
  hide: 'Hide',
  unhide: 'Unhide',
};

export const fundDocumentStatus = {
  uploaded: 'Uploaded',
  published: 'Published',
};

export const fundInvestorDocumentStatus = {
  ...fundDocumentStatus,
  system: 'System',
};

export enum FUND_DOCUMENT {
  FUND_DOCUMENT,
  FUND_INVESTOR_DOCUMENT,
}

export enum FUND_DOCUMENT_STATUS {
  UPLOADED,
  PUBLISHED,
  SYSTEM,
}

export const fundDocumentStatusFilterOptions = [
  {
    value: FUND_DOCUMENT_STATUS.UPLOADED,
    label: fundDocumentStatus.uploaded,
  },
  {
    value: FUND_DOCUMENT_STATUS.PUBLISHED,
    label: fundDocumentStatus.published,
  },
];

export const fundInvestorDocumentStatusFilterOptions = [
  ...fundDocumentStatusFilterOptions,
  {
    value: FUND_DOCUMENT_STATUS.SYSTEM,
    label: fundInvestorDocumentStatus.system,
  },
];

export const FUND_DOCUMENT_ACCEPT_TYPE = '.doc, .docx, .pdf';

export const uploadFundDocumentDefaultValue = {
  file: undefined,
  name: '',
  type: FUND_DOCUMENT.FUND_DOCUMENT,
  isPublish: false,
  clientId: '',
  fundIds: [],
  unitClassIds: [],
  investorIds: [],
  isAllFund: false,
  isAllUnitClass: false,
  isAllInvestor: false,
};

// 1024 * 2 = 1MB * 2 = 2MB
export const MAX_DOCUMENTS_SIZE = 1024 * 2;

export const documentActionMenus = [
  {
    key: fundDocumentActionTypes.download,
    label: fundDocumentActionTypes.download,
    permissionKey: actionPermissionKeys.isDownload,
  },
  {
    key: fundDocumentActionTypes.delete,
    label: fundDocumentActionTypes.delete,
    permissionKey: actionPermissionKeys.isDelete,
  },
  {
    key: fundDocumentActionTypes.hide,
    label: fundDocumentActionTypes.hide,
    permissionKey: actionPermissionKeys.isCreate,
  },
  {
    key: fundDocumentActionTypes.unhide,
    label: fundDocumentActionTypes.unhide,
    permissionKey: actionPermissionKeys.isCreate,
  },
  {
    key: fundDocumentActionTypes.publish,
    label: fundDocumentActionTypes.publish,
    permissionKey: actionPermissionKeys.isCreate,
  },
  {
    key: fundDocumentActionTypes.unpublish,
    label: fundDocumentActionTypes.unpublish,
    permissionKey: actionPermissionKeys.isCreate,
  },
];

export const investorDocumentActionMenus = [
  {
    key: investorDocumentActionTypes.download,
    label: investorDocumentActionTypes.download,
    permissionKey: actionPermissionKeys.isDownload,
  },
  {
    key: investorDocumentActionTypes.hide,
    label: investorDocumentActionTypes.hide,
    permissionKey: actionPermissionKeys.isCreate,
  },
  {
    key: fundDocumentActionTypes.unhide,
    label: fundDocumentActionTypes.unhide,
    permissionKey: actionPermissionKeys.isCreate,
  },
];
