import * as React from 'react';

interface IStatsIconProps {}

const StatsIcon: React.FunctionComponent<IStatsIconProps> = (props) => {
  return (
    <svg
      width='39px'
      height='38.9425216px'
      viewBox='0 0 39 38.9425216'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Application_Financial-Details_00'
          transform='translate(-445.000000, -383.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(291.000000, 80.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 208.000000)'>
              <g id='Header' transform='translate(79.000000, 80.000000)'>
                <g id='Synbol' transform='translate(60.000000, 0.000000)'>
                  <g id='stats' transform='translate(15.000000, 15.000000)'>
                    <path
                      d='M36.3033847,20.173481 L21.1248318,20.173481 L31.8527585,30.9007349 C34.5679268,27.9700475 36.1463835,24.1655254 36.3033847,20.173481 Z'
                      id='Path'
                    />
                    <path
                      d='M13.445399,35.1871959 L13.445399,30.265604 C13.445399,29.8940223 13.7466255,29.5927958 14.1182072,29.5927958 L16.1366318,29.5927958 L16.1366318,24.8831384 C16.1366318,24.5115567 16.4378583,24.2103302 16.80944,24.2103302 L19.5006728,24.2103302 C19.8722545,24.2103302 20.173481,24.5115567 20.173481,24.8831384 L20.173481,26.901563 L22.1919056,26.901563 C22.5634873,26.901563 22.8647138,27.2027895 22.8647138,27.5743712 L22.8647138,29.5927958 L24.8831384,29.5927958 C25.2547201,29.5927958 25.5559466,29.8940223 25.5559466,30.265604 L25.5559466,35.1885416 C27.5317525,34.4227944 29.3453055,33.2910643 30.9014077,31.8527585 L19.5006728,20.4513508 L8.09993789,31.8520857 C9.65607671,33.2901767 11.4696312,34.4216795 13.445399,35.1871959 Z'
                      id='Path'
                    />
                    <path
                      d='M22.8647138,35.9844737 C23.3208778,35.8916261 23.7682952,35.7765759 24.2103302,35.6480696 L24.2103302,30.9384122 L22.8647138,30.9384122 L22.8647138,35.9844737 Z'
                      id='Path'
                    />
                    <path
                      d='M28.2471794,13.445399 C28.6187611,13.445399 28.9199876,13.1441725 28.9199876,12.7725908 C28.9199876,12.4010091 28.6187611,12.0997826 28.2471794,12.0997826 L28.2471794,13.445399 Z'
                      id='Path'
                    />
                    <path
                      d='M33.2972777,5.70272229 C29.8107286,2.19667517 25.1155364,0.156459364 20.173481,0 L20.173481,18.8278646 L39,18.8278646 C38.8438807,13.8853393 36.8036391,9.18960617 33.2972777,5.70272229 Z M27.5743712,17.4822482 C24.2301358,17.4822482 21.5190974,14.7712098 21.5190974,11.4269744 C21.5190974,8.08273907 24.2301358,5.37170065 27.5743712,5.37170065 C30.9186065,5.37170065 33.629645,8.08273907 33.629645,11.4269744 C33.6263071,14.7698262 30.917223,17.4789104 27.5743712,17.4822482 L27.5743712,17.4822482 Z'
                      id='Shape'
                    />
                    <path
                      d='M26.2287548,10.081358 C26.2287548,10.4529397 26.5299813,10.7541662 26.901563,10.7541662 L26.901563,9.40854984 C26.5299813,9.40854984 26.2287548,9.70977633 26.2287548,10.081358 Z'
                      id='Path'
                    />
                    <path
                      d='M27.5743712,6.71731705 C24.9732992,6.71731705 22.8647138,8.82590248 22.8647138,11.4269744 C22.8647138,14.0280464 24.9732992,16.1366318 27.5743712,16.1366318 C30.1754431,16.1366318 32.2840286,14.0280464 32.2840286,11.4269744 C32.2810624,8.8271321 30.1742135,6.72028321 27.5743712,6.71731705 L27.5743712,6.71731705 Z M28.2471794,10.7541662 C29.3619245,10.7541663 30.265604,11.6578457 30.265604,12.7725908 C30.265604,13.8873359 29.3619245,14.7910154 28.2471794,14.7910154 L28.2471794,15.4638236 L26.901563,15.4638236 L26.901563,14.7910154 C25.7868179,14.7910154 24.8831384,13.887336 24.8831384,12.7725908 L26.2287548,12.7725908 C26.2287548,13.1441725 26.5299813,13.445399 26.901563,13.445399 L26.901563,12.0997826 C25.7868179,12.0997826 24.8831384,11.1961032 24.8831384,10.081358 C24.8831384,8.96661292 25.7868179,8.06293344 26.901563,8.06293344 L26.901563,7.39012525 L28.2471794,7.39012525 L28.2471794,8.06293344 C29.3619245,8.06293344 30.265604,8.96661292 30.265604,10.081358 L28.9199876,10.081358 C28.9199876,9.70977633 28.6187611,9.40854984 28.2471794,9.40854984 L28.2471794,10.7541662 Z'
                      id='Shape'
                    />
                    <path
                      d='M20.173481,36.3027119 C20.6269537,36.2845461 21.0757168,36.2475417 21.5190974,36.1943898 L21.5190974,28.2471794 L20.173481,28.2471794 L20.173481,36.3027119 Z'
                      id='Path'
                    />
                    <path
                      d='M18.8278646,36.3027119 L18.8278646,25.5559466 L17.4822482,25.5559466 L17.4822482,36.1943898 C17.9256288,36.2475417 18.3737191,36.2845461 18.8278646,36.3027119 Z'
                      id='Path'
                    />
                    <path
                      d='M18.8278646,19.2201118 L18.8278646,2.69661526 C12.2742946,2.95378612 6.46906423,6.99989655 3.95961311,13.0594404 C1.450162,19.1189842 2.69501738,26.0847609 7.14791429,30.9000621 L18.8278646,19.2201118 Z M9.40854984,14.1182072 C9.40854984,13.0034621 10.3122293,12.0997826 11.4269744,12.0997826 L11.4269744,11.4269744 L12.7725908,11.4269744 L12.7725908,12.0997826 C13.887336,12.0997826 14.7910154,13.0034621 14.7910154,14.1182072 L13.445399,14.1182072 C13.445399,13.7466255 13.1441725,13.445399 12.7725908,13.445399 L12.7725908,14.7910154 C13.887336,14.7910154 14.7910154,15.6946949 14.7910154,16.80944 C14.7910154,17.9241851 13.887336,18.8278646 12.7725908,18.8278646 L12.7725908,19.5006728 L11.4269744,19.5006728 L11.4269744,18.8278646 C10.3122293,18.8278646 9.40854984,17.9241851 9.40854984,16.80944 L10.7541662,16.80944 C10.7541662,17.1810217 11.0553927,17.4822482 11.4269744,17.4822482 L11.4269744,16.1366318 C10.3122293,16.1366318 9.40854984,15.2329523 9.40854984,14.1182072 L9.40854984,14.1182072 Z'
                      id='Shape'
                    />
                    <path
                      d='M19.5006728,37.6664942 C9.73540066,37.6544108 1.723083,29.931695 1.35167167,20.173481 L0,20.173481 C0.399023515,30.6528921 9.01030365,38.9425216 19.4973088,38.9425216 C29.9843139,38.9425216 38.595594,30.6528921 38.9946175,20.173481 L37.6490011,20.173481 C37.2776002,29.9314348 29.2656842,37.6540498 19.5006728,37.6664942 L19.5006728,37.6664942 Z'
                      id='Path'
                    />
                    <path
                      d='M11.4269744,13.445399 C11.0553927,13.445399 10.7541662,13.7466255 10.7541662,14.1182072 C10.7541662,14.4897889 11.0553927,14.7910154 11.4269744,14.7910154 L11.4269744,13.445399 Z'
                      id='Path'
                    />
                    <path
                      d='M13.445399,16.80944 C13.445399,16.4378583 13.1441725,16.1366318 12.7725908,16.1366318 L12.7725908,17.4822482 C13.1441725,17.4822482 13.445399,17.1810217 13.445399,16.80944 Z'
                      id='Path'
                    />
                    <path
                      d='M16.1366318,35.9844737 L16.1366318,30.9384122 L14.7910154,30.9384122 L14.7910154,35.6440327 C15.2323776,35.7725391 15.6804679,35.8882621 16.1366318,35.9844737 Z'
                      id='Path'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StatsIcon;
