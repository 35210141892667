import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { IReviewFormData } from 'src/components/pages/kyc-back-office/KYCDetail';
import Review from 'src/components/pages/kyc-back-office/components/Review';
import {
  APPLICATION_APPROVE_STEP,
  FUNDRAISING_OVERVIEW_STEP,
  INVESTMENT_AMOUNT_REQUIRED,
  applicationStatuses,
  investorTypeLabel,
} from 'src/constants/applications';
import { UnitClassInvestorType } from 'src/constants/unit-class';
import { useApproveApplication, useGetApplicationOverview } from 'src/modules/applications/hooks';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useKYCRequestMoreInformation } from 'src/modules/kyc-back-office/hooks';
import { IRequestMoreInformation } from 'src/modules/kyc-back-office/type';
import {
  kycBackOfficeReviewSchema,
  requestMoreInformationSchema,
} from 'src/validations/kyc-backoffice';

interface IKYCReviewProps {
  applicationId: string;
  applicationStatus?: string;
  isViewMode?: boolean;
}

interface IRequestMoreInformationForm {
  note: string;
}

const KYCReview: FC<IKYCReviewProps> = ({ applicationStatus, applicationId, isViewMode }) => {
  const { mutate: approveApplication, isLoading: isApprovingApplication } = useApproveApplication();

  const { data: overviewKYCReview, refetch } = useGetApplicationOverview(
    applicationId || '',
    FUNDRAISING_OVERVIEW_STEP.KYCReview,
  );

  const { data: overviewSummary } = useGetApplicationOverview(
    applicationId || '',
    FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
  );

  const {
    mutateAsync: kycRequestMoreInformationMutate,
    isLoading: isKycRequestMoreInformationLoading,
  } = useKYCRequestMoreInformation();

  const { data: userInfo } = useGetUserInfo();

  const modalAlertRef = useRef<IBasicModalElement>(null);
  const modalRequestMoreInformationRef = useRef<IBasicModalElement>(null);

  const form = useForm<IReviewFormData>({
    resolver: yupResolver(kycBackOfficeReviewSchema),
    defaultValues: overviewKYCReview?.kycReview,
  });

  useEffect(() => {
    if (!isEmpty(overviewKYCReview?.kycReview)) {
      form.reset((prevState) => ({
        ...prevState,
        isKYCCompletedExternally: overviewKYCReview?.kycReview?.isKYCCompletedExternally,
        statusName: overviewKYCReview?.kycReview?.statusName,
        review: {
          ...overviewKYCReview?.kycReview,
          data: [
            {
              title: 'Investor Details Verification',
              child: overviewKYCReview?.kycReview?.investorDetailsVerification || [],
            },
            {
              title: 'UBOs Verification',
              child: overviewKYCReview?.kycReview?.ubOsVerification || [],
            },
            ...(String(overviewKYCReview?.kycReview?.offerTypeName) ===
              investorTypeLabel.Wholesale &&
            (overviewKYCReview?.kycReview?.investmentAmount || 0) < INVESTMENT_AMOUNT_REQUIRED &&
            ![UnitClassInvestorType.Professional, UnitClassInvestorType.Sophisticated].includes(
              overviewKYCReview?.kycReview?.investorType as UnitClassInvestorType,
            )
              ? [
                  {
                    title: 'Wholesale Investor Status',
                    child:
                      overviewKYCReview?.kycReview?.wholesaleInvestorStatus?.map((item) => ({
                        ...item,
                      })) || [],
                  },
                ]
              : []),
          ],
        },
      }));
    }
  }, [overviewKYCReview]);

  const requestMoreInformationForm = useForm<IRequestMoreInformationForm>({
    resolver: yupResolver(requestMoreInformationSchema),
    defaultValues: { note: '' },
  });

  const handleApprove = () => {
    approveApplication(
      { id: overviewKYCReview?.id || '', step: 3 },
      {
        onSuccess: () => {
          refetch();
          modalAlertRef?.current?.open();
        },
      },
    );
  };

  const handleRequestMoreInformation = (data: IRequestMoreInformationForm) => {
    const dataFormat: IRequestMoreInformation = {
      note: data?.note || '',
      kycId: overviewKYCReview?.kycReview?.kycId,
      requestMoreInfoNotedBy: userInfo?.id,
    };

    kycRequestMoreInformationMutate(
      { applicationId: applicationId || '', data: dataFormat },
      {
        onSuccess: () => {
          refetch(); // disabled this action
          modalRequestMoreInformationRef?.current?.close();
        },
      },
    );
  };

  const isSubmittedReview = [
    ...(overviewKYCReview?.kycReview?.investorDetailsVerification || []),
    ...(overviewKYCReview?.kycReview?.ubOsVerification || []),
    ...(overviewKYCReview?.kycReview?.wholesaleInvestorStatus || []),
  ].some((it) => typeof it.yesNo === 'boolean');

  return (
    <>
      <FormProvider {...form}>
        <Review isFromFundApp hideQuestionsForm={!isSubmittedReview} />
        {applicationStatus !== applicationStatuses.KYC_Completed &&
          !overviewSummary?.summary?.find(
            (it) => it.id === APPLICATION_APPROVE_STEP.KycApproved,
          ) && (
            <Box className='flex items-center flex-row-reverse gap-2 mt-8'>
              <CustomButton
                disabled={isViewMode}
                onClick={handleApprove}
                isLoading={isApprovingApplication}
                sx={() => ({
                  height: 40,
                  width: 125,
                })}
              >
                Approve
              </CustomButton>
              <CustomButton
                disabled={
                  isViewMode ||
                  !(isSubmittedReview || overviewKYCReview?.kycReview?.isKYCCompletedExternally)
                }
                variant='outlined'
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  height: 40,
                })}
                onClick={() => modalRequestMoreInformationRef?.current?.open()}
              >
                Request More Information
              </CustomButton>
            </Box>
          )}
        <BasicModal ref={modalAlertRef}>
          <ConfirmationAlert
            title={`You did it !`}
            description={`AML/KYC assessment is approved.`}
            buttonAction={{
              label: 'OK',
              onAction: () => modalAlertRef?.current?.close(),
            }}
          />
        </BasicModal>
      </FormProvider>
      <BasicModal ref={modalRequestMoreInformationRef}>
        <FormProvider {...requestMoreInformationForm}>
          <Box className='w-[600px] p-10'>
            <Typography variant='h5'>Request More Information</Typography>
            <Box className='mt-5'>
              <FormInput
                name='note'
                label='Request'
                placeholder='Enter request'
                multiline
                rows={4}
                maxLength={280}
                isShowCharactersLeft
              />
            </Box>
            <Box className='mt-6 flex flex-row-reverse'>
              <Box className='flex gap-2'>
                <CustomButton
                  sx={{ color: 'neutral.ne800' }}
                  variant='text'
                  onClick={() => modalRequestMoreInformationRef?.current?.close()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  isLoading={isKycRequestMoreInformationLoading}
                  onClick={requestMoreInformationForm.handleSubmit(handleRequestMoreInformation)}
                >
                  Submit
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
    </>
  );
};

export default KYCReview;
