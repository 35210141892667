/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IDocumentsProps extends React.SVGProps<SVGSVGElement> {}

export const Documents: React.FunctionComponent<IDocumentsProps> = ({
  color,
  width = '24',
  height = '24',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Documents_Fund-Documents_04'
          transform='translate(-23.000000, -304.000000)'
          fill={color || '#ffffff'}
          fillRule='nonzero'
        >
          <g id='Audit-Trail-Menu-Copy-3' transform='translate(11.000000, 292.000000)'>
            <g
              id='01.Design-Token/06.Icons/Line/grid-outline'
              transform='translate(12.000000, 12.000000)'
            >
              <g id='Group' transform='translate(4.000000, 2.000000)'>
                <path
                  d='M13.75,-4.4408921e-16 C14.9329775,-4.4408921e-16 15.9140318,0.915796691 15.995,2.096 L16,2.25 L16,17.748 C16,18.9309775 15.0842033,19.9120318 13.904,19.993 L13.75,19.998 L2.25,19.998 C1.06702253,19.998 0.0859682271,19.0822033 0.005,17.902 L0,17.748 L0,2.25 C0,1.06702253 0.915796691,0.0859682271 2.096,0.005 L2.25,-4.4408921e-16 L13.75,-4.4408921e-16 L13.75,-4.4408921e-16 Z M3.75,4.993 C3.33578644,4.993 3,5.32878644 3,5.743 C3,6.15721356 3.33578644,6.493 3.75,6.493 L12.25,6.493 C12.6642136,6.493 13,6.15721356 13,5.743 C13,5.32878644 12.6642136,4.993 12.25,4.993 L3.75,4.993 Z M3,9.743 C3,10.157 3.336,10.493 3.75,10.493 L12.25,10.493 C12.6642136,10.493 13,10.1572136 13,9.743 C13,9.32878645 12.6642136,8.993 12.25,8.993 L3.75,8.993 C3.33578644,8.993 3,9.32878644 3,9.743 L3,9.743 Z M3.75,12.993 C3.33578644,12.993 3,13.3287864 3,13.743 C3,14.1572136 3.33578644,14.493 3.75,14.493 L12.25,14.493 C12.6642136,14.493 13,14.1572136 13,13.743 C13,13.3287864 12.6642136,12.993 12.25,12.993 L3.75,12.993 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
