/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

export const MoreIcon: FC<SVGProps<SVGSVGElement>> = ({ color = '#D1D3D4', ...rest }) => {
  return (
    <svg
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7.75 12a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0ZM13.75 12a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0ZM18 13.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z'
        fill={color}
      />
    </svg>
  );
};
