/* eslint-disable autofix/no-unused-vars */
import { capitalize } from 'lodash';
import { actionPermissionKeys } from './common';

export enum CapitalCallOptionEnum {
  No,
  Yes,
}

export enum OFFER_STATUS {
  Draft,
  Pending,
  Open,
  Active,
  Inactive,
}

export const offerInvestmentDetailsDefaultValue = {
  isSaveDraft: false,
  investmentEntityTypes: [],
  capitalCall: CapitalCallOptionEnum.No,
  percentage: null,
  fixedAmount: null,
};

export const offerDetailsDefaultValues = {
  isSaveDraft: false,
  name: '',
  expirationDate: null,
  isOpenEnded: true,
  clientId: '',
  fundId: '',
  unitClassIds: [],
};

export const offerDocumentsDefaultValues = {
  isSaveDraft: false,
  defaultBrandingToOffer: false,
  description: '',
  overview: '',
  brandings: [],
  documents: [],
};

export const offerStatuses = {
  Active: 'Active',
  Draft: 'Draft',
  Expired: 'Expired',
  Suspended: 'Suspended',
};

export const offerActionTypes = {
  view: 'view',
  edit: 'edit',
  suspended: 'suspended',
  activate: 'activate',
  delete: 'delete',
  duplicate: 'duplicate',
};

export const offerActionMenus = [
  {
    key: offerActionTypes.view,
    label: capitalize(offerActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  {
    key: offerActionTypes.edit,
    label: capitalize(offerActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: offerActionTypes.suspended,
    label: capitalize(offerActionTypes.suspended),
  },
  {
    key: offerActionTypes.activate,
    label: capitalize(offerActionTypes.activate),
  },
  {
    key: offerActionTypes.delete,
    label: capitalize(offerActionTypes.delete),
    permissionKey: actionPermissionKeys.isDelete,
  },
  {
    key: offerActionTypes.duplicate,
    label: capitalize(offerActionTypes.duplicate),
    permissionKey: actionPermissionKeys.isCreate,
  },
];

export enum EXECUTION_METHOD {
  ESignature,
  Docusign,
  ManualEmail,
  ManualPost,
}

export const executionDeclarationDefaultValue = {
  isSaveDraft: false,
  declaration: '',
  defaultToFund: false,
  executionMethods: [
    { executionMethod: EXECUTION_METHOD.Docusign, isSelect: false },
    { executionMethod: EXECUTION_METHOD.ESignature, isSelect: false },
    { executionMethod: EXECUTION_METHOD.ManualEmail, email: '', isSelect: false },
    {
      executionMethod: EXECUTION_METHOD.ManualPost,
      isSelect: false,
      address: {
        country: '',
        countryName: '',
        countryId: '',
        state: '',
        stateName: '',
        stateId: '',
        city: '',
        cityName: '',
        cityId: '',
        postcode: '',
        streetAddress: '',
        isUseSameAsPhysicalAddress: true,
        manualEntry: false,
      },
    },
  ],
};

/**
 * Only for preview UI testing.
 * Wating for data from offer documents step.
 * Need to remove and recheck after offer documents step done.
 */

export const offerPreviewDefaultValue = {
  id: '',
  name: '',
  fundName: '',
  clientName: '',
  logoUrl: '',
  bannerUrl: '',
  overview: '',
  expirationDate: '',
  isOpenEnded: true,
  description: '',
  unitClass: {
    id: '',
    name: '',
    code: '',
    offerType: '',
    minimumInvestment: 0,
    documents: [],
    investorContactType: 0,
    email: '',
    contactNumber: '',
    firstName: '',
    lastName: '',
    position: '',
    countryFlagCode: '',
    organisationName: '',
    contactName: '',
  },
};

export const OFFER_BANNER_ASPECT_RATIO = 18 / 5;
