import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FUNDS_API } from 'src/constants/apiEndpoints';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES, useCommonQueryParams } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { exportCSV, removeEmptyProps } from 'src/utils/common';
import {
  archivedUnitClassById,
  checkFundCode,
  createFund,
  createFundDetails,
  deleteFundBrandingDocument,
  deleteFundById,
  deleteUnitClassById,
  exportFundList,
  getDetailServiceProvider,
  getExisting,
  getFundById,
  getFundContacts,
  getFundFilter,
  getFundList,
  getFundTeamByUserType,
  getOfferTypes,
  getSignatoryContacts,
  getTrusteeAndServiceProvidersCapacities,
  getUnitClassFilter,
  getUnitClassList,
  updateFundDetails,
  updateFundStatus,
  upsertFinancialDetails,
  upsertFundContactDetails,
  upsertFundTeam,
  upsertOverviewAndBranding,
  upsertServiceProvider,
  upsertSignatoryContacts,
  upsertTrusteeGP,
} from './services';
import {
  Fund,
  FundFilter,
  FundQueryBody,
  IFinancialDetailsParams,
  IFundContactDetailParams,
  IFundDetailParams,
  IFundOverviewAndBrading,
  IGetExistingParams,
  ISignatoryContactParams,
  ITrusteeGPParams,
  IUnitClass,
  IUpsertFundTeamParams,
  IUpsertServiceProvider,
  ServiceProviderTypeEnum,
  UnitClassFilter,
  UnitClassQueryBody,
} from './type';

export const useUpsertFundDetails = () => {
  const queryClient = useQueryClient();
  const resp = async (params: IFundDetailParams) => {
    let res;
    if (params.id) {
      res = await updateFundDetails(params, params.id);
    } else {
      res = await createFundDetails(params);
    }
    await queryClient.invalidateQueries([FUNDS_API.fundById.api]);
    return res;
  };
  return useMutation(resp);
};

export const useGetOfferTypes = () => {
  return useQuery([FUNDS_API.getOfferTypes.api], getOfferTypes);
};

export const useGetFundFilters = () => {
  const { data = { statuses: [] }, ...rest } = useQuery<FundFilter>(
    [FUNDS_API.getFundFilter.api],
    getFundFilter,
  );

  return {
    data,
    ...rest,
  };
};

export const useExportFundList = () => {
  const resp = async (params: FundQueryBody) => {
    const data = await exportFundList(params);
    exportCSV(data, 'fund-list');
  };
  return useMutation(resp);
};

export const useGetFundList = () => {
  const [queryBody, setQueryBody] = useState<FundQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<Fund>>(
    [FUNDS_API.getFundList.api, queryBody],
    () => getFundList(queryBody),
  );

  const setParams = (newParams: FundQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetFundById = (
  id: string,
  step: number,
  enabled = true,
  options?: Parameters<typeof useQuery<any, any, any, any>>[2],
) => {
  return useQuery([FUNDS_API.fundById.api, id, step], () => getFundById(id, String(step)), {
    enabled: !!id && enabled,
    ...options,
  });
};
export const useCreateFund = () => {
  return useMutation(createFund);
};

export const useDeleteFundById = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await deleteFundById(id);
    await queryClient.invalidateQueries([FUNDS_API.getFundList.api]);
  };
  return useMutation(resp);
};

export const useUpdateFundStatus = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await updateFundStatus(id);
    await queryClient.invalidateQueries([FUNDS_API.getFundList.api]);
  };
  return useMutation(resp);
};

export const useUpsertFundContactDetails = () => {
  const resp = async ({ params, fundId }: { params: IFundContactDetailParams; fundId: string }) => {
    return await upsertFundContactDetails(params, fundId);
  };
  return useMutation(resp);
};

export const useCheckFundCode = () => {
  const resp = async ({
    params,
    fundId,
  }: {
    params: { id: string; code: string };
    fundId: string;
  }) => {
    return await checkFundCode(params, fundId);
  };
  return useMutation(resp);
};

export const useGetSignatoryContacts = (fundId: string, enabled?: boolean) => {
  return useQuery(
    [FUNDS_API.getSignatoryContacts.api(fundId), fundId],
    () => getSignatoryContacts(fundId),
    {
      enabled,
    },
  );
};

export const useUpsertSignatoryContacts = () => {
  const resp = async ({ params, fundId }: { params: ISignatoryContactParams; fundId: string }) => {
    return await upsertSignatoryContacts(params, fundId);
  };
  return useMutation(resp);
};

export const useGetTrusteeAndServiceProvidersCapacities = () => {
  return useQuery(
    [FUNDS_API.getTrusteeAndServiceProvidersCapacites.api],
    getTrusteeAndServiceProvidersCapacities,
  );
};

export const useGetExisting = (params: IGetExistingParams) => {
  const { clientId = '', serviceProviderType, capacityIds = '' } = params || {};
  const enabled =
    !!clientId &&
    (capacityIds instanceof Array ? !!capacityIds.length : typeof capacityIds === 'number');
  const endPointWithQueryParams = useCommonQueryParams(
    FUNDS_API.getExisting.api(serviceProviderType),
    {
      clientId,
      capacityIds,
    },
    {
      removeBaseQuery: true,
    },
  );

  return useQuery(
    [FUNDS_API.getExisting.api(serviceProviderType), clientId, capacityIds],
    () => getExisting(endPointWithQueryParams),
    {
      enabled,
    },
  );
};

export const useUpsertServiceProvider = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, ...params }: IUpsertServiceProvider) => {
    const data = await upsertServiceProvider(params, id);
    await queryClient.invalidateQueries([
      FUNDS_API.getExisting.api(ServiceProviderTypeEnum.TrusteeGpDetails),
    ]);
    await queryClient.invalidateQueries([
      FUNDS_API.getExisting.api(ServiceProviderTypeEnum.InvestmentManager),
    ]);
    await queryClient.invalidateQueries([
      FUNDS_API.getExisting.api(ServiceProviderTypeEnum.ServiceProviders),
    ]);
    return data;
  };

  return useMutation(resp);
};

export const useUpsertOverviewAndBranding = () => {
  const resp = async ({ params, fundId }: { params: IFundOverviewAndBrading; fundId: string }) => {
    return await upsertOverviewAndBranding(params, fundId);
  };
  return useMutation(resp);
};

export const useGetDetailServiceProvider = (id: string, clientId: string) => {
  const endPointWithQueryParams = useCommonQueryParams(
    FUNDS_API.getDetailServiceProvider.api(id),
    {
      clientId,
    },
    {
      removeBaseQuery: true,
    },
  );

  return useQuery(
    [endPointWithQueryParams, id],
    () => getDetailServiceProvider(id, endPointWithQueryParams),
    {
      enabled: !!id,
    },
  );
};

export const useUpsertTrusteeGP = () => {
  const resp = async ({ params, id }: { params: ITrusteeGPParams; id: string }) => {
    return await upsertTrusteeGP(params, id);
  };
  return useMutation(resp);
};

export const useGetFundContacts = (fundId: string, enabled?: boolean) => {
  return useQuery([FUNDS_API.getFundContacts.api(fundId), fundId], () => getFundContacts(fundId), {
    enabled: !!fundId && enabled,
  });
};

export const useUpsertFinancialDetails = () => {
  const resp = async ({ params, id }: { params: IFinancialDetailsParams; id: string }) => {
    return await upsertFinancialDetails(params, id);
  };
  return useMutation(resp);
};

export const useUpsertFundTeam = () => {
  const resp = async ({ params, fundId }: { params: IUpsertFundTeamParams; fundId: string }) => {
    return await upsertFundTeam(params, fundId);
  };
  return useMutation(resp);
};

export const useGetFundTeamByUserType = (fundId: string, enabled?: boolean) => {
  return useQuery(
    [FUNDS_API.getFundTeamByUserType.api(fundId), fundId],
    () => getFundTeamByUserType(fundId),
    {
      enabled,
    },
  );
};

export const useGetUnitClassList = (fundId: string, enabled = true) => {
  const [queryBody, setQueryBody] = useState<UnitClassQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<IUnitClass>
  >(
    [FUNDS_API.getUnitClassList.api(fundId), queryBody],
    () => getUnitClassList(fundId, queryBody),
    {
      enabled: enabled && !!fundId,
    },
  );

  const setParams = (newParams: UnitClassQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetUnitClassFilters = () => {
  const { data = { statuses: [] }, ...rest } = useQuery<UnitClassFilter>(
    [FUNDS_API.getUnitClassFilter.api],
    getUnitClassFilter,
  );

  return {
    data,
    ...rest,
  };
};

export const useDeleteUnitClassById = (fundId: string) => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await deleteUnitClassById(id);
    await queryClient.invalidateQueries([FUNDS_API.getUnitClassList.api(fundId)]);
  };
  return useMutation(resp);
};

export const useArchivedUnitClassById = (fundId: string) => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await archivedUnitClassById(id);
    await queryClient.invalidateQueries([FUNDS_API.getUnitClassList.api(fundId)]);
  };
  return useMutation(resp);
};

export const useDeleteFundBrandingDocument = () => {
  return useMutation(deleteFundBrandingDocument);
};
