/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { PlusIcon } from 'src/assets/icons/PlusIcon';
import Done from 'src/assets/images/done.svg';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';

import { useRef, useState } from 'react';
import CommonSection from 'src/components/molecules/CommonSection';
import { CLIENT_STATUS } from 'src/constants/client-management';
import { ActionType } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { detectPortalType } from 'src/helpers/common';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { useRole } from 'src/hooks/useRole';
import { IClient } from 'src/modules/client/type';
import ClientList from './components/ClientList';
import CreateUpdateClientForm from './components/admin/CreateUpdateClientForm';
import FundsCreateUpdateClientForm from './components/funds/FundsCreateUpdateClientForm';

interface IClientManagementProps {}

export interface IAdministratorInfo {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber: string;
  id?: string;
}

const ClientManagement: React.FC<IClientManagementProps> = () => {
  const modalRef = useRef<IBasicModalElement>(null);
  const createEditModalRef = useRef<IBasicModalElement>(null);
  const [isRefetchClientList, setIsRefetchClientList] = useState<boolean>(false);
  const navigate = useNavigateWithParams();
  const { permissions } = useRole(ROUTES_PATH.CLIENT_MANAGEMENT);
  const { isAdmin } = detectPortalType();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [actionType, setActionType] = useState<ActionType>('create');

  const handleOpenCreateClientModal = (isOpen = true) => {
    if (isOpen) {
      createEditModalRef.current?.open();
      setIsRefetchClientList(false);
    } else {
      createEditModalRef.current?.close();
      navigate(ROUTES_PATH.CLIENT_MANAGEMENT, {});
      setIsRefetchClientList(true);
      setSelectedClient(undefined);
    }
  };

  const handleViewEditClient = (client: IClient, actionType: ActionType) => {
    setSelectedClient(client);
    setActionType(actionType);
    handleOpenCreateClientModal();
  };

  return (
    <CommonSection
      title='Client management'
      description='View client list'
      ButtonComponent={
        permissions?.isFullAdmin || permissions?.isCreate ? (
          <CustomButton
            startIcon={<PlusIcon />}
            size='large'
            onClick={() => {
              setActionType('create');
              handleOpenCreateClientModal();
            }}
          >
            Create New Client
          </CustomButton>
        ) : (
          <></>
        )
      }
    >
      <ClientList
        isRefetchClientList={isRefetchClientList}
        onViewEditClient={handleViewEditClient}
      />

      <BasicModal
        ref={createEditModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleOpenCreateClientModal(false)}
        isPreventClickOutside
      >
        {isAdmin ? (
          <CreateUpdateClientForm
            handleOpenCreateClientModal={handleOpenCreateClientModal}
            id={selectedClient?.id}
            isEditMode={actionType === 'edit'}
            isViewMode={actionType === 'view'}
            isCreateMode={actionType === 'create'}
            isDraft={selectedClient?.status === CLIENT_STATUS.Draft.id}
          />
        ) : (
          <FundsCreateUpdateClientForm
            handleOpenCreateClientModal={handleOpenCreateClientModal}
            id={selectedClient?.id || ''}
            isEditMode={actionType === 'edit'}
            isViewMode={actionType === 'view'}
          />
        )}
      </BasicModal>

      <BasicModal ref={modalRef}>
        <Box className='flex flex-col items-center w-[485px] h-[368px] rounded-[22px] bg-white'>
          <img src={Done} alt={Done} className='mt-10' />
          <Typography variant='h5' className='mt-[6px]'>{`You did it!`}</Typography>
          <Typography variant='body2' color='neutral.ne500' className='mt-4'>
            Email notification sent.
          </Typography>
          <CustomButton className='w-[127px] h-12 mt-8' onClick={() => modalRef.current?.close()}>
            OK
          </CustomButton>
        </Box>
      </BasicModal>
    </CommonSection>
  );
};

export default ClientManagement;
