import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ContactIcon from 'src/assets/icons/ContactIcon';
import SearchField from 'src/components/atoms/SearchField';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { INVESTMENT_ENTITY_ROLE, INVESTMENT_ENTITY_TYPE } from 'src/constants/applications';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import {
  useGetApplicationById,
  useGetInvestmentEntitiesByEntityType,
} from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, IInvestmentEntitySelection } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { IInvestmentEntityProps } from '..';

interface ISelectExistingProps extends IInvestmentEntityProps {
  defaultValue: any;
}

const SelectExisting = ({
  id = '',
  isViewMode,
  isDisabledEdit,
  currentStep,
  investmentEntityId,
  defaultValue,
  isCreateFundApplicationForm,
  isExistingInvestor,
  isFundApplication,
}: ISelectExistingProps) => {
  const { isFunds } = detectPortalType();
  const [keyword, setKeyword] = useState('');
  const { watch, setValue, reset } = useFormContext<IInvestmentEntityForm>();
  const currentEntityType = watch('entityType');
  const isAddNew = watch('isAddNewEntityDetail');
  const { data: userInfo } = useGetUserInfo(isCreateFundApplicationForm);
  const { data: investmentEntities } = useGetInvestmentEntitiesByEntityType(
    currentEntityType,
    currentStep === INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
    isCreateFundApplicationForm ? keyword : undefined,
  );

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  const disabledSelectExisting =
    (Number.isInteger(investmentEntity?.entityType) && !isCreateFundApplicationForm) ||
    isViewMode ||
    isDisabledEdit;

  const handleSelect = (item: IInvestmentEntitySelection) => {
    if (disabledSelectExisting) return;

    const isUnselect = item.id === investmentEntityId;
    if (isUnselect) {
      reset(defaultValue, {
        keepErrors: false,
        keepIsValid: false,
      });
    }

    if (isCreateFundApplicationForm) {
      handleSetDelegateDetails();
    }

    setValue('isAddNewEntityDetail', !isUnselect);
    setValue('investmentEntityId', isUnselect ? undefined : item.id);
  };

  const handleSetDelegateDetails = () => {
    setValue('editorDetails.firstName', userInfo?.firstName || '');
    setValue('editorDetails.lastName', userInfo?.lastName || '');
    setValue('editorDetails.email', userInfo?.email || '');
    setValue('editorDetails.contactNumber', userInfo?.contactNumber || '');
    setValue('editorDetails.countryFlagCode', userInfo?.countryFlagCode || DEFAULT_COUNTRY);
    setValue('editorDetails.userType', INVESTMENT_ENTITY_ROLE.Delegate);
  };

  if (isAddNew && !investmentEntityId) return null;

  const formatEntityType = (entityType: INVESTMENT_ENTITY_TYPE) => {
    if (entityType === INVESTMENT_ENTITY_TYPE.SoleTrader) {
      return 'Sole Trader';
    }
    return INVESTMENT_ENTITY_TYPE[entityType];
  };

  const handleSetKeyword = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }, 500);

  if (
    !Number.isInteger(investmentEntity?.entityType) &&
    !investmentEntities?.length &&
    !isCreateFundApplicationForm
  )
    return null;

  if (isCreateFundApplicationForm && isExistingInvestor) return null;
  /**
   * This component is used for both investor portal and fund portal
   * If fundraising application in fund portal that created from investor portal
   * Then we hide the select existing box
   */
  if (isFunds && !isFundApplication && !isCreateFundApplicationForm) return null;

  return (
    <Box mb={isCreateFundApplicationForm ? 4 : 2}>
      <Typography variant='body2' fontWeight={500}>
        Select Existing
      </Typography>
      {isCreateFundApplicationForm && (
        <SearchField
          className='mt-2'
          placeholder='Search Investment Entity'
          onChange={handleSetKeyword}
        />
      )}
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        <Grid container spacing={2}>
          {(investmentEntities || []).map((item: IInvestmentEntitySelection, index: number) => {
            const isSelected = investmentEntityId === item.id;
            return (
              <React.Fragment key={`item-${index}`}>
                <Grid item xs={1} className='flex items-center justify-center'>
                  <Box
                    className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
                    bgcolor={'neutral.ne200'}
                  >
                    <ContactIcon color='#9DA0A1' height='16px' />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box className='flex items-center '>
                    <Typography variant='body3' mr='4px'>
                      {item.name}
                    </Typography>
                  </Box>
                  <Typography variant='body3' color='neutral.ne800'>
                    {formatEntityType(currentEntityType)}
                  </Typography>
                </Grid>
                <Grid item xs={3} className='flex items-center justify-end'>
                  <Box
                    className={clsx('px-[9px] py-[5px] rounded-[4px]', {
                      'cursor-pointer': !disabledSelectExisting,
                      'cursor-default': disabledSelectExisting,
                    })}
                    bgcolor='neutral.ne200'
                    onClick={() => handleSelect(item)}
                  >
                    <Typography
                      color={disabledSelectExisting ? 'neutral.ne400' : 'primary.main'}
                      variant='body3'
                      fontWeight={500}
                    >
                      {isSelected ? 'Unselect' : 'Select'}
                    </Typography>
                  </Box>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default SelectExisting;
