/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Box, IconButton, Typography } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { LinkIcon } from 'src/assets/icons/LinkIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { ContactDetailsProps } from './ContactDetails';

interface IGeneralContactProps extends ContactDetailsProps {}

const MAX_ADDITIONAL_AUTHORISED_CONTACTS = 10;

const GeneralContact: React.FC<IGeneralContactProps> = ({ isViewMode }) => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'contacts',
  });
  const watchFieldArray = form?.watch('contacts');

  const getControlledFields = () => {
    const controlledContactDetailFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });

    return controlledContactDetailFields;
  };

  const handleAddMoreItem = () => {
    append({
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      contactNumber: '',
      isPrimary: false,
      isAddNew: true,
      countryFlagCode: DEFAULT_COUNTRY,
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const controlledContactDetailFields = getControlledFields();
  const isDisabledAddNewContact =
    isViewMode || controlledContactDetailFields.length > MAX_ADDITIONAL_AUTHORISED_CONTACTS;

  const renderContactItems = () => {
    return (controlledContactDetailFields || []).map((item, index) => {
      const itemIndex = index.toString();
      const isPrimary = item.isPrimary;
      const panelId = `panel${itemIndex}`;

      return (
        <Accordion
          key={item.id}
          expanded={isPrimary || expanded === panelId}
          className='flex flex-col gap-2 mt-4 py-4 px-6'
          onChange={handleChange(panelId)}
        >
          <AccordionSummary
            hideIcon={isPrimary}
            className='w-full flex items-center justify-between'
          >
            <Box
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
              className={`flex items-center w-full ${isPrimary ? '' : 'justify-between'}`}
            >
              {isPrimary && (
                <Box className='rotate-[135deg] ml-[-8px]'>
                  <LinkIcon />
                </Box>
              )}
              <Typography variant='body2' className='ml-2 font-medium' color='base.black'>
                {isPrimary ? 'Primary Contact' : 'Additional Authorised Contact'}
              </Typography>
              {!isPrimary && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveItem(index);
                  }}
                >
                  <DeleteIcon width={22} height={24} />
                </IconButton>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className='flex flex-col gap-6 rounded-[10px]' bgcolor='neutral.ne100'>
              <Box className='w-full flex gap-6'>
                <FormInput
                  name={`contacts[${itemIndex}].firstName`}
                  label='First Name'
                  size='small'
                  placeholder='Enter first name'
                  className='w-full'
                  disabled={isViewMode}
                />
                <FormInput
                  name={`contacts[${itemIndex}].lastName`}
                  label='Last Name'
                  size='small'
                  placeholder='Enter last name'
                  className='w-full'
                  disabled={isViewMode}
                />
              </Box>
              <Box className='w-full flex gap-6'>
                <FormInput
                  name={`contacts[${itemIndex}].email`}
                  label='Email Address'
                  size='small'
                  placeholder='Enter email address'
                  className='w-full'
                  disabled={isViewMode}
                />
                <FormPhoneCountryInput
                  name={`contacts[${itemIndex}].contactNumber`}
                  countryFlagCodeName={`contacts[${itemIndex}].countryFlagCode`}
                  label='Contact Number'
                  size='small'
                  className='w-full'
                  countryFlagCode={item?.countryFlagCode || DEFAULT_COUNTRY}
                  disabled={isViewMode}
                  isIncludeCode
                />
              </Box>
              <Box className='w-full flex gap-6'>
                <FormInput
                  name={`contacts[${itemIndex}].position`}
                  label='Position'
                  size='small'
                  placeholder='Enter position'
                  disabled={isViewMode}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <FormProvider {...form}>
      {renderContactItems()}
      <CustomButton
        className='mt-6'
        variant='text'
        disabled={isDisabledAddNewContact}
        onClick={handleAddMoreItem}
        startIcon={<PlusPrimaryIcon disabled={isDisabledAddNewContact} />}
      >
        <Typography
          variant='body2'
          className='font-medium'
          color={isDisabledAddNewContact ? 'neutral.ne400' : 'initial'}
        >
          Add an additional authorised contact
        </Typography>
      </CustomButton>
    </FormProvider>
  );
};

export default GeneralContact;
