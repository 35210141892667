/* eslint-disable no-restricted-imports */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isNumber } from 'lodash';
import { useMemo, useState } from 'react';
import {
  FINANCIAL_STEP,
  INVESTMENT_APPLICATION_STEP,
} from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { APPLICATION_API, USER_MANAGEMENT_API } from 'src/constants/apiEndpoints';
import { APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE, TeamType } from 'src/constants/applications';
import { USER_STATUS } from 'src/constants/user-management';
import { detectPortalType } from 'src/helpers/common';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { IDocument as IDocumentCommon, Paginated } from 'src/modules/common/type';
import { IDocument } from 'src/modules/offer/type';
import { filterSKYCDataResponse } from 'src/utils/applications';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import { IUserBody } from '../users-management/type';
import {
  acceptDuplicatedEntity,
  applicationSkycCreateApp,
  applicationSkycGetApp,
  approveApplication,
  archiveApplication,
  cancelApplication,
  checkDuplicateAbnAcn,
  completedBiometricKYC,
  completedConnectIdKYC,
  completedDocumentIdKYC,
  confirmInvestmentAmount,
  connectIdRetrieveToken,
  connectIdSelectBank,
  deleteAdditionalDocument,
  deleteApplication,
  deleteApplicationReviewDocument,
  deleteTeamContact,
  downloadAdditionalDocument,
  downloadDeclarationDocument,
  downloadFundDocument,
  downloadOverviewDocument,
  favouriteApplicationOffer,
  finaliseApplication,
  getApplicationById,
  getApplicationEntityTypes,
  getApplicationFilter,
  getApplicationInvestorTypes,
  getApplicationList,
  getApplicationOfferList,
  getApplicationOfferUnitClassDetail,
  getApplicationOverview,
  getApplicationUnitClassFilter,
  getDuplicatedEntity,
  getInvestmentEntitiesByEntityType,
  getInvestmentEntityDetail,
  getInvestmentEntityDocuments,
  getInvestmentEntityUserInfo,
  getInvestorOfferDetailsPreview,
  getInvestorOfferInfo,
  getInvestorOfferInfoByOfferCode,
  getKYCInfo,
  getLetterFile,
  getReceivedSignatoryTypes,
  getSignatoryContact,
  getTaxDetails,
  getTeamContact,
  getWholesaleCertificate,
  initiateVerificationApplication,
  markSignedApplicationSignatory,
  overrideApplicationTeam,
  registerBiometricKYC,
  registerConnectIdKYC,
  registerDocumentIdKYC,
  rejectApplication,
  rejectApplicationOffer,
  rejectDuplicatedEntity,
  removeApplicationOffer,
  requestVerifyKYC,
  revertInvestmentAmountReceived,
  saveApplicationSignatories,
  searchSkyc,
  searchSuperFund,
  submitApplication,
  updateFinancialDetail,
  updateInvestmentAmountReceived,
  uploadApplicationReviewDocument,
  upsertApplicationAcceptance,
  upsertApplicationAdditional,
  upsertApplicationDetails,
  upsertFinancialAndTaxDetails,
  upsertFundApplicationKYC,
  upsertInvestmentEntity,
  upsertTeam,
  upsertTeamContact,
  withdrawApplication,
} from './services';
import {
  ApplicationFilter,
  ApplicationOfferQueryBody,
  ApplicationQueryBody,
  ELetterType,
  IAcceptanceSignatory,
  IAmountReceived,
  IApplicationAddtional,
  IApplicationById,
  IApplicationDetails,
  IApplicationFinancialDetail,
  IApplicationInvestorType,
  IApplicationOfferUnitClassDetail,
  IApplicationOverview,
  ICompleteConnectId,
  IDuplicatedEntity,
  IFinaliseDetail,
  IFinancialTaxDetailsForm,
  IFundKYCAMLForm,
  IInvestmentEntityForm,
  IInvestmentEntityTypeInfo,
  IInvestorOfferDetails,
  IInvestorOfferInfo,
  ISkyc,
  ISkycCreateAppBody,
  ISkycGetApp,
  ISuperFund,
  ISuperFundDetail,
  ITaxFilter,
  ITeam,
  ITeamContact,
  SearchSkycQueryBody,
  SearchSuperFundQueryBody,
} from './type';

export const useGetApplicationList = <T>() => {
  const [queryBody, setQueryBody] = useState<ApplicationQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<T>>(
    [APPLICATION_API.getApplicationList.api, queryBody],
    () => getApplicationList(queryBody),
  );

  const setParams = (newParams: ApplicationQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useArchiveApplication = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await archiveApplication(id);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
  };
  return useMutation(resp);
};

export const useGetApplicationOfferList = () => {
  const [queryBody, setQueryBody] = useState<ApplicationOfferQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data, ...rest } = useQuery([APPLICATION_API.getApplicationOfferList.api, queryBody], () =>
    getApplicationOfferList(queryBody),
  );
  const setParams = (newParams: ApplicationOfferQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetApplicationFilters = () => {
  return useQuery<ApplicationFilter>(
    [APPLICATION_API.getApplicationFilter.api],
    getApplicationFilter,
  );
};

export const useGetApplicationUnitClassFilters = () => {
  const [offerIds, setOfferIds] = useState<string[]>([]);
  const { data, ...rest } = useQuery<ApplicationFilter>(
    [APPLICATION_API.getApplicationFilter.api, offerIds],
    () => getApplicationUnitClassFilter(offerIds),
  );

  return {
    data,
    setOfferIds,
    ...rest,
  };
};

export const useGetApplicationInvestorTypes = (unitClassId: string) => {
  return useQuery<IApplicationInvestorType[]>(
    [APPLICATION_API.getApplicationInvestorTypes.api(unitClassId), unitClassId],
    () => getApplicationInvestorTypes(unitClassId),
    {
      enabled: !!unitClassId,
    },
  );
};

export const useGetInvestorOfferDetailsPreview = (
  offerId: string,
  unitClassId: string,
  options?: any,
) => {
  const { isInvestor } = detectPortalType();
  return useQuery<IInvestorOfferDetails>(
    [
      APPLICATION_API.getInvestorOfferDetailsPreview.api(offerId, unitClassId),
      offerId,
      unitClassId,
    ],
    () => getInvestorOfferDetailsPreview(offerId, unitClassId),
    {
      enabled: !!offerId && !!unitClassId && isInvestor,
      ...options,
    },
  );
};

export const useGetInvestorOfferInfo = (offerId: string) => {
  return useQuery<IInvestorOfferInfo>(
    [APPLICATION_API.getInvestorOfferInfo.api(offerId), offerId],
    () => getInvestorOfferInfo(offerId),
    {
      enabled: !!offerId,
    },
  );
};

export const useGetInvestorOfferInfoByOfferCode = (offerCode: string, options?: any) => {
  return useQuery(
    [APPLICATION_API.getInvestorOfferInfoByOfferCode.api(offerCode), offerCode],
    () => getInvestorOfferInfoByOfferCode(offerCode),
    {
      enabled: !!offerCode,
      ...options,
    },
  );
};

export const useUpsertInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const resp = async ({ data, id }: { data: IInvestmentEntityForm; id?: string }) => {
    await upsertInvestmentEntity(data, id);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id || '')]);
    await queryClient.invalidateQueries([APPLICATION_API.getTaxDetails.api(id || '')]);
  };
  return useMutation(resp);
};

export const useUpsertFinancialDetail = () => {
  const queryClient = useQueryClient();
  const resp = async ({ data, id }: { data: IApplicationFinancialDetail; id: string }) => {
    await updateFinancialDetail(id, data);
    await queryClient.invalidateQueries([
      APPLICATION_API.getApplicationById.api(id || ''),
      id,
      INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
      FINANCIAL_STEP.FINANCIAL_DETAILS,
    ]);
  };
  return useMutation(resp);
};

export const useUpsertApplicationDetail = () => {
  const resp = async ({ data, id }: { data: IApplicationDetails; id?: string }) => {
    return await upsertApplicationDetails(data, id);
  };
  return useMutation(resp);
};

export const useDownloadFundDocument = () => {
  const resp = async (document: IDocument) => {
    const data = await downloadFundDocument(document.id || '');
    downloadFileFromArrayBuffer(data, document.fileName);
  };
  return useMutation(resp);
};

export const useGetTeamContact = (id: string, enabled?: boolean) => {
  const { isInvestor } = detectPortalType();
  return useQuery([APPLICATION_API.getTeamContact.api(id), id], () => getTeamContact(id), {
    enabled: !!id && enabled && isInvestor,
  });
};

export const useUpsertTeamContact = () => {
  const resp = async ({ id, data }: { id: string; data: ITeamContact }) => {
    return await upsertTeamContact(id, data);
  };
  return useMutation(resp);
};

export const useUpsertTeam = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: ITeam }) => {
    await upsertTeam(id, data);
    await queryClient.invalidateQueries([
      APPLICATION_API.getApplicationById.api(id || ''),
      id,
      INVESTMENT_APPLICATION_STEP.TEAM,
    ]);
  };
  return useMutation(resp);
};

export const useDeleteTeamContact = () => {
  return useMutation(deleteTeamContact);
};

export const useGetContactListByTeamType = (
  selectedUsers: ITeamContact[],
  onlyAllowActiveTeamUsers = false,
) => {
  const { primaryContacts, teams, communicationList } = useMemo(() => {
    const _primaryContacts: ITeamContact[] = [],
      _teams: ITeamContact[] = [],
      _communicationList: ITeamContact[] = [];

    (selectedUsers || []).forEach((user: ITeamContact) => {
      const existingInPrimaryContact = user?.teamTypes?.findIndex(
        (f) => f === TeamType.PrimaryContact,
      );
      const existingInTeam = user?.teamTypes?.findIndex((f) => f === TeamType.Team);
      const existingInCL = user?.teamTypes?.findIndex((f) => f === TeamType.CommunicationList);
      if (existingInPrimaryContact !== -1) {
        _primaryContacts.push(user);
      }
      if (onlyAllowActiveTeamUsers) {
        if (existingInTeam !== -1 && user?.userStatus === USER_STATUS.Active) {
          _teams.push(user);
        }
      } else if (existingInTeam !== -1) {
        _teams.push(user);
      }

      if (existingInCL !== -1) {
        _communicationList.push(user);
      }
    });

    return {
      primaryContacts: _primaryContacts,
      teams: _teams,
      communicationList: _communicationList,
    };
  }, [selectedUsers]);
  return { primaryContacts, teams, communicationList };
};

export const useGetApplicationOfferUnitClassDetail = (offerId: string, unitClassId: string) => {
  return useQuery<IApplicationOfferUnitClassDetail>(
    [APPLICATION_API.getApplicationOfferUnitClassDetail.api(offerId), offerId, unitClassId],
    () => getApplicationOfferUnitClassDetail(offerId, unitClassId),
    {
      enabled: !!offerId && !!unitClassId,
    },
  );
};

export const useGetApplicationById = (
  id: string,
  { step, financialStep }: { step: number; financialStep?: number },
  enabled = true,
) => {
  return useQuery<IApplicationById>(
    [APPLICATION_API.getApplicationById.api(id), id, step, financialStep],
    () => getApplicationById(id, { step, financialStep }),
    {
      enabled: !!id && enabled,
      refetchOnWindowFocus: false || step === INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
    },
  );
};

export const useGetSignatoryContact = (applicationId: string, enabled?: boolean) => {
  return useQuery(
    [APPLICATION_API.getSignatoryContact.api(applicationId), applicationId],
    () => getSignatoryContact(applicationId),
    {
      enabled: !!applicationId && enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export const useUpsertApplicationAcceptance = () => {
  const queryClient = useQueryClient();
  const resp = async ({
    id,
    data,
  }: {
    id: string;
    data: { signatories: IAcceptanceSignatory[]; isSaveDraft?: boolean };
  }) => {
    const { isFunds } = detectPortalType();
    await upsertApplicationAcceptance(id, data);
    if (data.isSaveDraft) {
      // Refresh item when saveing
      await queryClient.invalidateQueries([
        APPLICATION_API.getApplicationById.api(id || ''),
        id,
        INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
      ]);
    }
    if (isFunds) {
      await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
      await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id || '')]);
    }
  };
  return useMutation(resp);
};

export const useUpsertApplicationAdditional = () => {
  const resp = async ({ data, id }: { data: IApplicationAddtional; id: string }) => {
    return await upsertApplicationAdditional(id, data);
  };
  return useMutation(resp);
};

export const useGetApplicationEntityTypes = (id?: string) => {
  return useQuery<
    {
      id: number;
      name: string;
    }[]
  >([APPLICATION_API.getApplicationEntityTypes.api(id)], () => getApplicationEntityTypes(id), {
    enabled: !!id,
  });
};

export const useGetWholesaleCertificate = (applicationId: string, enabled = true) => {
  return useQuery(
    [APPLICATION_API.getWholesaleCertificate.api(applicationId), applicationId],
    () => getWholesaleCertificate(applicationId),
    { enabled: !!applicationId && enabled },
  );
};

export const useDeleteAdditionalDocument = () => {
  return useMutation(deleteAdditionalDocument);
};

export const useDownloadAdditionalDocument = () => {
  const resp = async ({
    id,
    fileName,
    documentId,
    type,
  }: {
    id: string;
    fileName: string;
    documentId: string;
    type: number;
  }) => {
    const data = await downloadAdditionalDocument({
      applicationId: id,
      data: { documentId, type },
    });
    downloadFileFromArrayBuffer(data, fileName);
    return data;
  };
  return useMutation(resp);
};

export const useSearchSkyc = (initQuery?: SearchSkycQueryBody) => {
  const [queryBody, setQueryBody] = useState<SearchSkycQueryBody>({
    search: '',
    entityType: 0,
    ...(initQuery || {}),
  });
  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<{
    items: ISkyc[];
  }>([APPLICATION_API.searchSkyc.api, queryBody], () => searchSkyc(queryBody));
  const setParams = (newParams: SearchSkycQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };
  return {
    data: {
      ...data,
      items: filterSKYCDataResponse(data.items),
    },
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useSearchSuperFund = (initQuery?: SearchSuperFundQueryBody) => {
  const [queryBody, setQueryBody] = useState<SearchSuperFundQueryBody>({
    search: '',
    ...(initQuery || {}),
  });
  const { data = { matchingFundName: [] }, ...rest } = useQuery<
    | {
        matchingFundName: ISuperFund[];
      }
    | ISuperFundDetail
  >([APPLICATION_API.searchSuperFund.api, queryBody], () => searchSuperFund(queryBody));
  const setParams = (newParams: SearchSuperFundQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };
  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useDownloadDeclarationDocument = () => {
  const resp = async (document: any) => {
    const data = await downloadDeclarationDocument(
      document.applicationId,
      document.typeLetter,
      document.contactId,
    );
    downloadFileFromArrayBuffer(data, 'document_manual.pdf');
  };
  return useMutation(resp);
};

export const useRemoveApplicationOffer = () => {
  const resp = async (id: string | undefined) => {
    return await removeApplicationOffer(id);
  };
  return useMutation(resp);
};

export const useRejectApplicationOffer = () => {
  const resp = async (id: string | undefined) => {
    return await rejectApplicationOffer(id);
  };
  return useMutation(resp);
};

export const useFavouriteApplicationOffer = () => {
  const resp = async (data: { id?: string; isFavourite?: boolean }) => {
    return await favouriteApplicationOffer(data?.id, data?.isFavourite);
  };
  return useMutation(resp);
};

export const useRegisterDocumentIdKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; code?: string }) =>
    registerDocumentIdKYC(data);
  return useMutation(resp, options);
};

export const useRegisterBiometricKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; code?: string }) =>
    registerBiometricKYC(data);
  return useMutation(resp, options);
};

export const useRegisterConnectIdKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; code?: string }) =>
    registerConnectIdKYC(data);
  return useMutation(resp, options);
};

export const useRequestVerifyKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; email: string }) =>
    requestVerifyKYC(data);
  return useMutation(resp, options);
};

export const useCompletedDocumentIdKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; code?: string }) =>
    completedDocumentIdKYC(data);
  return useMutation(resp, options);
};

export const useCompletedBiometricKYC = (options?: any) => {
  const resp = (data: { appId: string; individualId: string; code?: string }) =>
    completedBiometricKYC(data);
  return useMutation(resp, options);
};

export const useCompletedConnectIdKYC = (options?: any) => {
  const resp = (data: ICompleteConnectId) => completedConnectIdKYC(data);
  return useMutation(resp, options);
};

export const useConnectIdSelectBank = (options?: any) => {
  const resp = (data: { authorizationServerId: string; code: string; essentialClaims: string[] }) =>
    connectIdSelectBank(data);
  return useMutation(resp, options);
};

export const useConnectIdRetrieveToken = (options?: any) => {
  const resp = (data: {
    code: string;
    queryState: string;
    iss: string;
    auth_server_id: string;
    state: string;
    nonce: string;
    code_verifier: string;
  }) => connectIdRetrieveToken(data);
  return useMutation(resp, options);
};

export const useGetKYCInfo = (id: string, options: any) => {
  return useQuery([APPLICATION_API.getKYCInfo.api(id), id], () => getKYCInfo(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useCheckDuplicateAbnAcn = () => {
  const resp = async ({
    params,
  }: {
    params: IInvestmentEntityTypeInfo & {
      abn: string;
      acn: string;
    };
  }): Promise<{ isDuplicated: boolean }> => {
    return await checkDuplicateAbnAcn(params);
  };
  return useMutation(resp);
};

export const useApplicationSkycCreateApp = () => {
  const resp = async (data: ISkycCreateAppBody): Promise<{ applicationId: string }> => {
    return await applicationSkycCreateApp(data);
  };
  return useMutation(resp);
};

export const useApplicationSkycGetApp = () => {
  const resp = async (id: string): Promise<ISkycGetApp> => {
    return await applicationSkycGetApp(id);
  };
  return useMutation(resp, {
    retry: 15,
    retryDelay: 8000,
  });
};

export const useSubmitApplication = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, callback }: { id?: string; callback?: () => void }) => {
    const result = await submitApplication(id);
    callback?.();
    await queryClient.invalidateQueries([APPLICATION_API.getSignatoryContact.api(id || ''), id]);
    await queryClient.invalidateQueries([
      APPLICATION_API.getApplicationById.api(id || ''),
      id,
      INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
    ]);
    await queryClient.invalidateQueries([USER_MANAGEMENT_API.investorUserEntities.api]);

    return result;
  };
  return useMutation(resp);
};

export const useGetInvestmentEntitiesByEntityType = (
  entityType: number,
  enabled = true,
  keyword?: string,
) => {
  const { isFunds } = detectPortalType();

  return useQuery(
    [APPLICATION_API.getInvestmentEntitiesByEntityType.api(entityType), entityType, keyword],
    () => getInvestmentEntitiesByEntityType(entityType, keyword),
    {
      enabled: isNumber(entityType) && enabled,
      keepPreviousData: isFunds,
    },
  );
};

export const useGetInvestmentEntityDetail = (entityId: string, enabled = true) => {
  // const { isInvestor } = detectPortalType();
  return useQuery(
    [APPLICATION_API.getInvestmentEntityDetail.api(entityId), entityId],
    () => getInvestmentEntityDetail(entityId),
    {
      enabled: !!entityId && enabled,
    },
  );
};

export const useGetInvestmentEntityUserInfo = (entityId: string, enabled = true) => {
  const { isInvestor } = detectPortalType();
  return useQuery(
    [APPLICATION_API.getInvestmentEntityUserInfo.api(entityId), entityId],
    () => getInvestmentEntityUserInfo(entityId),
    {
      enabled: !!entityId && enabled && isInvestor,
    },
  );
};

export const useGetInvestmentEntityDocuments = (entityId: string, enabled = true) => {
  const { isInvestor } = detectPortalType();
  return useQuery(
    [APPLICATION_API.getInvestmentEntityDocuments.api(entityId), entityId],
    () => getInvestmentEntityDocuments(entityId),
    {
      enabled: !!entityId && enabled,
    },
  );
};

export const useGetTaxDetails = (appId: string, enabled = true) => {
  return useQuery<ITaxFilter>(
    [APPLICATION_API.getTaxDetails.api(appId), appId],
    () => getTaxDetails(appId),
    {
      enabled: !!appId && enabled,
    },
  );
};

export const useUpsertFinancialAndTaxDetails = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IFinancialTaxDetailsForm }) => {
    await upsertFinancialAndTaxDetails(id, data);
    await queryClient.invalidateQueries([
      APPLICATION_API.getApplicationById.api(id || ''),
      id,
      INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
      FINANCIAL_STEP.TAX_DETAILS,
    ]);
    await queryClient.invalidateQueries([
      APPLICATION_API.getApplicationById.api(id || ''),
      id,
      INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
      undefined,
    ]);
  };
  return useMutation(resp);
};

export const useGetApplicationOverview = (appId: string, step: number) => {
  return useQuery<IApplicationOverview>(
    [APPLICATION_API.getApplicationOverview.api(appId), appId, step],
    () => getApplicationOverview(appId, step),
    {
      enabled: !!appId && Number.isInteger(step),
    },
  );
};

export const useConfirmInvestmentAmount = () => {
  const queryClient = useQueryClient();

  const resp = async ({
    appId,
    investmentAmount,
    capitalCallAmount,
  }: {
    appId: string;
    investmentAmount: number;
    capitalCallAmount?: number | null;
  }) => {
    await confirmInvestmentAmount(appId, { investmentAmount, capitalCallAmount });
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(appId)]);
  };
  return useMutation(resp);
};

export const useUpdateInvestmentAmountReceived = () => {
  const resp = async ({
    appId,
    investmentAmount,
  }: {
    appId: string;
    investmentAmount: IAmountReceived[];
  }) => {
    return await updateInvestmentAmountReceived(appId, investmentAmount);
  };
  return useMutation(resp);
};

export const useApproveApplication = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, step }: { id: string; step: number }) => {
    await approveApplication(id, step);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id)]);
  };
  return useMutation(resp);
};

export const useRejectApplication = () => {
  const queryClient = useQueryClient();

  const resp = async ({
    id,
    data,
  }: {
    id: string;
    data: { note: string; rejectedType: number };
  }) => {
    await rejectApplication(id, data);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id)]);
  };
  return useMutation(resp);
};

export const useInitiateVerificationApplication = () => {
  const queryClient = useQueryClient();

  const resp = async ({ id }: { id: string }) => {
    await initiateVerificationApplication(id);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
  };
  return useMutation(resp);
};

export const useUploadApplicationReviewDocument = () => {
  const queryClient = useQueryClient();

  const resp = async ({ id, data }: { id: string; data: IDocumentCommon }) => {
    await uploadApplicationReviewDocument(id, data);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id)]);
  };
  return useMutation(resp);
};

export const useDeleteApplicationReviewDocument = () => {
  const queryClient = useQueryClient();

  const resp = async ({ documentId, appId }: { documentId: string; appId: string }) => {
    await deleteApplicationReviewDocument(appId, documentId);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(appId)]);
  };
  return useMutation(resp);
};

export const useDownloadOverviewDocument = () => {
  const resp = async ({ appId, document }: { appId: string; document: IDocumentCommon }) => {
    const data = await downloadOverviewDocument(appId, document.id || '');
    downloadFileFromArrayBuffer(data, document.fileName);
  };
  return useMutation(resp);
};

export const useRevertInvestmentAmountReceived = () => {
  return useMutation(revertInvestmentAmountReceived);
};

export const useFinaliseApplication = () => {
  const queryClient = useQueryClient();

  const resp = async ({ data, appId }: { data: IFinaliseDetail; appId: string }) => {
    const result = await finaliseApplication(appId, data);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
    return result;
  };
  return useMutation(resp);
};

export const useSaveApplicationSignatories = () => {
  const queryClient = useQueryClient();

  const resp = async ({
    id,
    data,
    disableRevalidateApplication,
  }: {
    id: string;
    data: { signatories: IAcceptanceSignatory[]; removeContactIds: string[] };
    disableRevalidateApplication?: boolean;
  }) => {
    await saveApplicationSignatories(id, data);
    if (disableRevalidateApplication) return;
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(id)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(id)]);
  };
  return useMutation(resp);
};

export const useMarkSignedApplicationSignatory = () => {
  const queryClient = useQueryClient();

  const resp = async ({
    appId,
    contactId,
    receivedType,
    signedDate,
  }: {
    appId: string;
    contactId: string;
    receivedType: APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE;
    signedDate: string;
  }) => {
    await markSignedApplicationSignatory(appId, contactId, receivedType, signedDate);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(appId)]);
  };
  return useMutation(resp);
};

export const useGetReceivedSignatoryTypes = (appId: string) => {
  const { isFunds } = detectPortalType();
  return useQuery<{ id: number; name: string }[]>(
    [APPLICATION_API.getReceivedSignatoryTypes.api(appId)],
    () => getReceivedSignatoryTypes(appId),
    { enabled: isFunds },
  );
};

export const useCancelApplication = () => {
  const resp = ({ appId }: { appId: string }) => cancelApplication(appId);
  return useMutation(resp);
};

export const useWithdrawApplication = () => {
  const resp = ({ appId }: { appId: string }) => withdrawApplication(appId);
  return useMutation(resp);
};

export const useLetterFile = (options?: any) => {
  const resp = (data: { appId: string; type: ELetterType }) => getLetterFile(data);
  return useMutation(resp, options);
};

export const useGetDuplicatedEntity = (appId: string) => {
  return useQuery<IDuplicatedEntity>(
    [APPLICATION_API.getDuplicatedEntity.api(appId)],
    () => getDuplicatedEntity(appId),
    { enabled: !!appId, retry: false },
  );
};

export const useAcceptDuplicatedEntity = () => {
  const queryClient = useQueryClient();
  const resp = async ({ appId, data }: { appId: string; data: IUserBody }) => {
    await acceptDuplicatedEntity(appId, data);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
  };
  return useMutation(resp);
};

export const useRejectDuplicatedEntity = () => {
  const queryClient = useQueryClient();
  const resp = async (appId: string) => {
    await rejectDuplicatedEntity(appId);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);
  };
  return useMutation(resp);
};

export const useOverrideApplicationTeam = () => {
  const queryClient = useQueryClient();

  const resp = async ({ appId, investorId }: { appId: string; investorId: string }) => {
    const result = await overrideApplicationTeam(appId, { investorId });
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationOverview.api(appId)]);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationById.api(appId)]);
    return result;
  };
  return useMutation(resp);
};

export const useUpsertFundApplicationKYC = () => {
  const resp = async ({ data, id }: { data: IFundKYCAMLForm; id: string }) => {
    return await upsertFundApplicationKYC(id, data);
  };
  return useMutation(resp);
};

export const useDeleteApplication = () => {
  const queryClient = useQueryClient();
  const fn = async (id: string) => {
    const resp = await deleteApplication(id);
    await queryClient.invalidateQueries([APPLICATION_API.getApplicationList.api]);

    return resp;
  };
  return useMutation(fn);
};
