import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FC, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton, { ICustomButtonProps } from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { RejectApplicationType, applicationStatuses } from 'src/constants/applications';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { useRejectApplication } from 'src/modules/applications/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import * as Yup from 'yup';

interface IRejectApplicationActionProps extends ICustomButtonProps {
  appId: string;
  title: string;
  rejectedType: number;
  statusName: string;
  alwaysShow?: boolean;
}

const RejectApplicationAction: FC<IRejectApplicationActionProps> = ({
  appId = '',
  rejectedType,
  title,
  statusName,
  alwaysShow,
  ...props
}) => {
  const rejectModalRef = useRef<IBasicModalElement>(null);
  const alertModalRef = useRef<IBasicModalElement & { description: string }>({
    open: () => null,
    close: () => null,
    description: '',
  });

  const isShowRejectApplicationButton =
    statusName === applicationStatuses.Submitted ||
    statusName === applicationStatuses.KYC_In_Progress ||
    statusName === applicationStatuses.Action_Required ||
    statusName === applicationStatuses.KYC_Completed ||
    statusName === applicationStatuses.Awaiting_Deposit;

  const { mutate: rejectApplication, isLoading: isRejectingApplication } = useRejectApplication();

  const rejectForm = useForm({
    defaultValues: { note: '' },
    resolver: yupResolver(
      Yup.object({
        note: Yup.string().required(REQUIRE_MESSAGE),
      }),
    ),
  });

  const handleOpenRejectModal = () => {
    rejectModalRef.current?.open();
  };

  const handleCloseRejectModal = () => {
    rejectModalRef.current?.close();
  };

  const handleRejectApplication = ({ note }: { note: string }) => {
    const data = { note, rejectedType };
    rejectApplication(
      { id: appId, data },
      {
        onSuccess: () => {
          handleCloseRejectModal();
          alertModalRef.current.description = 'Application is rejected.';
          alertModalRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  return (
    <>
      {(isShowRejectApplicationButton || alwaysShow) && (
        <CustomButton
          variant='outlined'
          disabled={props.disabled || isRejectingApplication}
          onClick={handleOpenRejectModal}
          isLoading={isRejectingApplication}
          {...props}
        >
          {title}
        </CustomButton>
      )}
      <BasicModal ref={rejectModalRef} onClose={handleCloseRejectModal}>
        <FormProvider {...rejectForm}>
          <Box className='flex flex-col p-10 w-[600px]'>
            <Typography variant='h5' mb={1}>
              Reject Application?
            </Typography>
            <Typography variant='body2' color='neutral.ne800' mb={3}>
              {`If this application is rejected, the investor will be required to initiate a new application. This action cannot be undone. ${
                rejectedType === RejectApplicationType.InvestorType
                  ? 'You can change the investor type after application is finalised.'
                  : ''
              } Are you sure you want to continue?`}
            </Typography>
            <FormInput multiline rows={8} name={'note'} label='Note' />
            <Box className='flex justify-end mt-6 gap-2'>
              <CustomButton
                variant='text'
                onClick={handleCloseRejectModal}
                disabled={isRejectingApplication}
              >
                <Typography color='primary' variant='body2' fontWeight={500}>
                  Cancel
                </Typography>
              </CustomButton>
              <CustomButton
                onClick={rejectForm.handleSubmit(handleRejectApplication)}
                isLoading={isRejectingApplication}
              >
                <Typography color='white' variant='body2' fontWeight={500}>
                  Continue
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title='You did it'
          description={alertModalRef.current?.description}
          buttonAction={{
            label: 'OK',
            onAction: () => alertModalRef?.current?.close(),
          }}
        />
      </BasicModal>
    </>
  );
};

export default RejectApplicationAction;
