import { Link, Typography } from '@mui/material';

export default function NoteTerm() {
  return (
    <>
      <Typography variant='body3' component='p'>
        © 2023 Fundcorr Pty Limited. All rights reserved. ABN 29 665 655 952 Level 9, 4-6 Bligh
        Street Sydney NSW 2000.
      </Typography>
      <Typography variant='body3' component='p'>
        We are committed to protecting the privacy and security of your information. Please refer to
        our <Link href='https://fundcorr.com/privacy-policy/'>privacy policy</Link> and{' '}
        <Link href='https://fundcorr.com/terms-of-use/'>terms of use</Link>.
      </Typography>
    </>
  );
}
