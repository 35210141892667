import * as React from 'react';

interface IPersonSwapIconProps {}

const PersonSwapIcon: React.FunctionComponent<IPersonSwapIconProps> = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Global-Admin-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_List-Role_00'
          transform='translate(-23.000000, -484.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Audit-Trail-Menu-Copy-2' transform='translate(11.000000, 472.000000)'>
            <g
              id='01.Design-Token/06.Icons/Line/grid-outline'
              transform='translate(12.000000, 12.000000)'
            >
              <g id='Group' transform='translate(2.000000, 2.000000)'>
                <path
                  d='M13.7500001,11.9994043 C14.3468185,11.999139 14.9192691,12.2361936 15.3412835,12.658363 C15.7632979,13.0805324 16.0002655,13.6531931 16,14.2502307 L16,15.1685678 C16,15.4536724 15.9550001,15.735776 15.8700001,16.0048747 L13.8350001,16.0048747 C14.1955189,15.2350428 13.9510683,14.3173842 13.2554085,13.8291091 C12.5597487,13.3408341 11.6139826,13.4230943 11.0130001,14.0241477 L8.5130001,16.5230649 C8.18416058,16.8514602 7.99937292,17.297199 7.99937292,17.7620197 C7.99937292,18.2268404 8.18416058,18.6725792 8.5130001,19.0009745 L9.4480001,19.9343171 C8.9830001,19.980334 8.5,20.0043428 7.9960001,20.0043428 C4.5760001,20.0043428 2.0530001,18.9309488 0.510000096,16.7661542 C0.178126219,16.2999833 0,15.7418761 0,15.1695681 L0,14.2492303 C0,13.0066858 1.00691169,11.9994043 2.2490001,11.9994043 L13.7500001,11.9994043 Z M7.9960001,0 C10.7574238,0 12.9960001,2.23939824 12.9960001,5.00183597 C12.9960001,7.76427369 10.7574238,10.0036719 7.9960001,10.0036719 C5.23457635,10.0036719 2.9960001,7.76427369 2.9960001,5.00183597 C2.9960001,2.23939824 5.23457635,0 7.9960001,0 L7.9960001,0 Z'
                  id='Shape'
                />
                <path
                  d='M12.7800001,15.787795 C13.055359,15.4921768 13.047234,15.0315077 12.7616219,14.7457908 C12.4760098,14.4600738 12.0155099,14.4519458 11.7200001,14.7274058 L9.2200001,17.2283238 C8.92754672,17.5212489 8.92754672,17.995788 9.2200001,18.288713 L11.7200001,20.789631 C12.0144178,21.0738289 12.4821978,21.0695434 12.7713631,20.779999 C13.0605283,20.4904547 13.0643711,20.0224991 12.7800001,19.7282414 L11.5600001,18.5077934 L18.4400001,18.5077934 L17.2200001,19.7282414 C16.9446412,20.0238597 16.9527662,20.4845287 17.2383783,20.7702457 C17.5239904,21.0559626 17.9844903,21.0640906 18.2800001,20.7886306 L20.7800001,18.2897134 C21.0724535,17.9967883 21.0724535,17.5222492 20.7800001,17.2293242 L18.2800001,14.7264055 C18.0930295,14.5256786 17.8114446,14.4430523 17.5457112,14.5109408 C17.2799778,14.5788294 17.0724782,14.7864052 17.0046146,15.0522361 C16.9367509,15.3180671 17.0193469,15.5997554 17.2200001,15.7867947 L18.4380001,17.0072427 L11.5610001,17.0072427 L12.7810001,15.7867947 L12.7800001,15.787795 Z'
                  id='Path'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PersonSwapIcon;
