/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IApplicationsProps {}

export const Applications: React.FunctionComponent<IApplicationsProps> = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_08'
          transform='translate(-23.000000, -184.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Audit-Trail-Menu-Copy-3' transform='translate(11.000000, 172.000000)'>
            <g
              id='01.Design-Token/06.Icons/Line/grid-outline'
              transform='translate(12.000000, 12.000000)'
            >
              <g id='Group' transform='translate(2.000000, 2.000000)'>
                <path
                  d='M16.0289544,0.68186117 L19.3181388,3.97104565 C19.7547269,4.40762839 20,4.99976486 20,5.61718987 C20,6.23461489 19.7547269,6.82675136 19.3181388,7.2633341 L16.8111574,9.76928088 C17.8901611,10.1079659 18.6242389,11.108105 18.6238819,12.2390145 L18.6238819,17.4123151 C18.6238819,18.8408826 17.4657991,19.9989653 16.0372316,19.9989653 L2.58665026,19.9989653 C1.15808277,19.9989653 0,18.8408826 0,17.4123151 L0,3.9617337 C0,2.53316621 1.15808277,1.37508344 2.58665026,1.37508344 L7.75995079,1.37508344 C8.91980477,1.37508344 9.90169721,2.13762794 10.2296845,3.18987727 L12.7366659,0.68186117 C13.1732486,0.245273083 13.7653851,0 14.3828101,0 C15.0002351,0 15.5923716,0.245273083 16.0289544,0.68186117 Z M8.27728084,11.7216845 L2.06932021,11.7216845 L2.06932021,17.4123151 C2.06932021,17.6980286 2.30093677,17.9296451 2.58665026,17.9296451 L8.27728084,17.9296451 L8.27728084,11.7216845 Z M16.0372316,11.7216845 L10.3466011,11.7216845 L10.3466011,17.9296451 L16.0372316,17.9296451 C16.3229451,17.9296451 16.5545617,17.6980286 16.5545617,17.4123151 L16.5545617,12.2390145 C16.5545617,11.953301 16.3229451,11.7216845 16.0372316,11.7216845 Z M11.8365116,9.65132962 L10.3466011,8.16141907 L10.3466011,9.65132962 L11.8365116,9.65132962 L11.8365116,9.65132962 Z M7.75995079,3.44336899 L2.58665026,3.44336899 C2.30093677,3.44336899 2.06932021,3.67498555 2.06932021,3.96069904 L2.06932021,9.65236428 L8.27728084,9.65236428 L8.27728084,3.9617337 C8.27728084,3.67602021 8.04566429,3.44440365 7.75995079,3.44440365 L7.75995079,3.44336899 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
