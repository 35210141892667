import { Box, styled } from '@mui/material';

export const CustomTableContainer = styled(Box)(({ theme, maxHeight = 383 }) => ({
  '& .render-node-container': {
    margin: 0,
  },
  '& .MuiTableContainer-root': {
    maxHeight: maxHeight,

    /* Works on firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.neutral.ne300,

    /* Works on Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
      width: 3,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral.ne300,
      borderRadius: 3,
    },

    '&::-webkit-scrollbar-track': {
      marginTop: 100,
    },

    '& .MuiTable-root': {
      minWidth: 'auto !important',
    },
  },
}));

export default CustomTableContainer;
