import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { NOTIFICATION_API } from 'src/constants/apiEndpoints';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { removeEmptyProps } from 'src/utils/common';
import {
  clearAllNotification,
  deleteNotification,
  getNotificationList,
  updateNotificationState,
} from './services';
import { INotificationItem, INotificationQueryBody } from './type';

export const useGetNotificationList = () => {
  const [queryBody, setQueryBody] = useState<INotificationQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const {
    data = { notificationItems: { items: [], metadata: DEFAULT_PAGINATED }, totalUnread: 0 },
    ...rest
  } = useQuery<{
    notificationItems: Paginated<INotificationItem>;
    totalUnread: number;
  }>([NOTIFICATION_API.getNotificationList.api, queryBody], () => getNotificationList(queryBody), {
    keepPreviousData: true,
  });

  const setParams = (newParams: INotificationQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useDeleteNotification = () => {
  return useMutation(deleteNotification);
};

export const useUpdateNotificationState = () => {
  return useMutation(updateNotificationState);
};

export const useClearAllNotification = () => {
  return useMutation(clearAllNotification);
};
