import { Checkbox, styled } from '@mui/material';

export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    width: '26px',
    height: '26px',
    borderRadius: '4px',
  },
}));
