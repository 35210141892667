import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import AustralianCompanyForm from 'src/components/pages/applications/components/InvestmentEntity/shared/AustralianCompanyForm';
import ForeignCompanyForm from 'src/components/pages/applications/components/InvestmentEntity/shared/ForeignCompanyForm';
import { InvestmentEntityCompanyRegionType } from 'src/constants/applications';

interface ICompanyProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const Company: FC<ICompanyProps> = (props) => {
  const { watch } = useFormContext();
  const companyRegionType = watch('companyRegionType');

  return (
    <>
      {companyRegionType === InvestmentEntityCompanyRegionType.Australian && (
        <AustralianCompanyForm {...props} keyName='investmentEntityDetails.australianCompany' />
      )}
      {companyRegionType === InvestmentEntityCompanyRegionType.Foreign && (
        <ForeignCompanyForm {...props} keyName='investmentEntityDetails.foreignCompany' />
      )}
    </>
  );
};

export default Company;
