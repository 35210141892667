import { CircularProgress, CircularProgressProps } from '@mui/material';

const LoadingIndicator = (props: CircularProgressProps) => {
  return (
    <div className='flex justify-center items-center'>
      <CircularProgress {...props} />
    </div>
  );
};

export default LoadingIndicator;
