import { Claim } from './types';

export const claimsDescription: Record<Claim, string> = {
  address: 'Address',
  birthdate: 'Birthdate',
  email: 'Email',
  family_name: 'Family Name',
  given_name: 'First Name',
  middle_name: 'Middle Name',
  name: 'Full Name',
  phone_number: 'Phone Number',
  over18: 'Over 18',
  over21: 'Over 21',
  over25: 'Over 25',
  over65: 'Over 65',
  beneficiary_account_au: 'Beneficiary account',
  beneficiary_account_au_payid: 'PayID',
  beneficiary_account_international: 'International account',
};
export const claimsOrder: Record<Claim, number> = {
  name: 0,
  given_name: 1,
  middle_name: 2,
  family_name: 3,
  birthdate: 5,
  email: 4,
  address: 7,
  phone_number: 6,
  over18: 9,
  over21: 10,
  over25: 11,
  over65: 12,
  beneficiary_account_au: 13,
  beneficiary_account_au_payid: 14,
  beneficiary_account_international: 15,
};
