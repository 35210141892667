import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, Fragment, useRef, useState } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomScrollbarContainer from 'src/components/atoms/CustomScrollbarContainer';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_TYPE,
  InvestmentEntityCompanyRegionType,
} from 'src/constants/applications';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  useConfirmDuplication,
  useGetDuplicationInfo,
  useRejectDuplication,
} from 'src/modules/dashboard/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { generateReviewDuplicationInfoData } from 'src/utils/dashboard';

interface IReviewDuplicateEntityProps {
  entity: any;
  onClose: () => void;
}

const ReviewDuplicateEntity: FC<IReviewDuplicateEntityProps> = ({ entity, onClose }) => {
  const theme = useTheme();
  const confirmModalRef = useRef<IBasicModalElement>(null);
  const alertModalRef = useRef<IBasicModalElement>(null);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [alertDescription, setAlertDescription] = useState('');

  const { mutate: rejectDuplication, isLoading: rejectingDuplication } = useRejectDuplication();
  const { mutate: confirmDuplication, isLoading: confirmingDuplication } = useConfirmDuplication();

  const { data: duplicationInfo } = useGetDuplicationInfo(entity?.id);
  const { duplicateEntity, existingEntity } = duplicationInfo || {};
  const entityType = existingEntity?.investmentEntity?.entityType;
  const data = generateReviewDuplicationInfoData(duplicateEntity, existingEntity);

  const getTitleByEntityType = () => {
    switch (entityType) {
      case INVESTMENT_ENTITY_TYPE.Individuals:
        return 'Individuals';
      case INVESTMENT_ENTITY_TYPE.SoleTrader:
        return 'Sole Trader';
      case INVESTMENT_ENTITY_TYPE.Company:
        if (
          existingEntity?.investmentEntity?.companyRegionType ===
          InvestmentEntityCompanyRegionType.Australian
        ) {
          return 'Australian Company';
        }
        return 'Foreign Company';
      case INVESTMENT_ENTITY_TYPE.Partnership:
        return 'Partnership';
      case INVESTMENT_ENTITY_TYPE.SMSF:
        return 'SMSF';
      case INVESTMENT_ENTITY_TYPE.Other:
        if (existingEntity?.investmentEntity?.otherType === INVESTMENT_ENTITY_OTHER.Association) {
          return 'Association';
        }
        return 'Government Body';
      case INVESTMENT_ENTITY_TYPE.Trust:
        return 'Trust';
    }
  };

  const titleByEntityType = {
    [INVESTMENT_ENTITY_TYPE.Individuals]: 'Individuals',
    [INVESTMENT_ENTITY_TYPE.SoleTrader]: 'Sole Trader',
    [INVESTMENT_ENTITY_TYPE.Company]: 'Company',
    [INVESTMENT_ENTITY_TYPE.Partnership]: 'Partnership',
    [INVESTMENT_ENTITY_TYPE.Other]: 'Other',
    [INVESTMENT_ENTITY_TYPE.SMSF]: 'SMSF',
    [INVESTMENT_ENTITY_TYPE.Trust]: 'Trust',
  };

  const handleRejectDuplicate = () => {
    setConfirmFormat({
      title: 'Reject Duplicate?',
      content: `Are you sure you want to reject this duplicate?<br />
      This action cannot be undone.`,
      button: {
        type: 'error',
        label: 'Reject',
        onAction: () => {
          rejectDuplication(entity?.id, {
            onError: handleErrorFromServer,
            onSuccess: () => {
              setAlertDescription('The application submitted successfully.');
              alertModalRef.current?.open();
            },
          });
        },
      },
    });
    confirmModalRef?.current?.open();
  };

  const handleConfirmDuplicate = () => {
    setConfirmFormat({
      title: 'Confirm Duplicate?',
      content: `Are you sure you want to confirm this duplicate?<br />
      This action cannot be undone.`,
      button: {
        type: 'primary',
        label: 'Confirm',
        onAction: () => {
          confirmDuplication(entity?.id, {
            onError: handleErrorFromServer,
            onSuccess: () => {
              setAlertDescription('Duplicate entity request has been sent for review.');
              alertModalRef.current?.open();
            },
          });
        },
      },
    });
    confirmModalRef?.current?.open();
  };

  return (
    <>
      <Box className='review-entity'>
        <Box className='header px-15 py-5 relative border-b' borderColor='neutral.ne200'>
          <Typography variant='h5' fontWeight={700}>
            Review Duplicate Entity
          </Typography>
          <Typography variant='body1'>{getTitleByEntityType()}</Typography>
          <IconButton className='absolute top-5 right-8' onClick={onClose}>
            <CloseIcon color={theme.palette.neutral.ne400} />
          </IconButton>
        </Box>
        <Box className='body py-7.5'>
          <CustomScrollbarContainer
            className='review-table rounded-lg min-h-[400px] md:max-h-[500px] 2xl:max-h-[680px] overflow-auto px-15'
            scrollbarTrackProps={{
              marginTop: 0,
            }}
          >
            <Box
              className='table-header py-3.5 rounded-t-lg flex items-center gap-[11px]'
              bgcolor='neutral.ne1000'
            >
              <Box className='w-[25%]' />
              <Box className='w-[37%]'>
                <Typography textTransform='uppercase' variant='body3' fontWeight={500}>
                  Duplicate Entity
                </Typography>
              </Box>
              <Box className='w-[38%]'>
                <Typography textTransform='uppercase' variant='body3' fontWeight={500}>
                  Existing Entity
                </Typography>
              </Box>
            </Box>
            <Box className='table-body'>
              <Box className='group'>
                {data?.map((item, index) => (
                  <Fragment key={index}>
                    <Box
                      className='group-title w-full px-3 py-3.5 border'
                      bgcolor='neutral.ne100'
                      borderColor='neutral.ne200'
                    >
                      <Typography variant='body3' fontWeight={500}>
                        {item?.groupTitle}
                      </Typography>
                    </Box>
                    <Box className='group-body'>
                      {item?.data?.map((it: any, index: number) => (
                        <Box
                          key={index}
                          className='group-item flex items-center px-3 py-4.5 border border-t-0 gap-[11px]'
                          borderColor='neutral.ne200'
                        >
                          <Box className='w-[25%]'>
                            <Typography
                              className='break-words'
                              variant='body3'
                              color='neutral.ne800'
                              fontWeight={500}
                            >
                              {it?.label}
                            </Typography>
                          </Box>
                          <Box className='w-[37%]'>
                            <Typography
                              className='break-words'
                              variant='body3'
                              color='neutral.ne800'
                            >
                              {it?.duplicate}
                            </Typography>
                          </Box>
                          <Box className='w-[38%]'>
                            <Typography
                              className='break-words'
                              variant='body3'
                              color='neutral.ne800'
                            >
                              {it?.existing}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Fragment>
                ))}
              </Box>
            </Box>
          </CustomScrollbarContainer>
        </Box>
        <Box
          className='footer flex items-center justify-between px-6 py-3.5 border-t'
          borderColor='neutral.ne200'
        >
          <CustomButton color='error' sx={{ px: 3 }} onClick={handleRejectDuplicate}>
            Reject Duplicate
          </CustomButton>
          <Box className='flex items-center gap-3'>
            <CustomButton
              variant='outlined'
              sx={(theme) => ({
                color: theme.palette.neutral.ne800,
                borderColor: theme.palette.neutral.ne800,
                px: 3,
              })}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
            <CustomButton sx={{ px: 3 }} onClick={handleConfirmDuplicate}>
              Confirm Duplicate
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <ConfirmModal
        ref={confirmModalRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        disableBackdropClick
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => confirmModalRef?.current?.close()}
              disabled={confirmingDuplication || rejectingDuplication}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={confirmingDuplication || rejectingDuplication}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={alertDescription}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertModalRef?.current?.close();
              onClose();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default ReviewDuplicateEntity;
