import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DASHBOARD_API } from 'src/constants/apiEndpoints';
import {
  confirmDuplication,
  getClientSummary,
  getDashboardAllocations,
  getDashboardDetails,
  getDashboardFundAllocations,
  getDashboardSummary,
  getDashboardSummaryByClient,
  getDuplicationInfo,
  rejectDuplication,
  remoteAccess,
} from './services';
import { IClientSummaryData } from './type';

export const useGetDashboardSummary = (id?: string, fundId?: string) => {
  return useQuery([DASHBOARD_API.getDashboardSummary.api, id, fundId], () =>
    getDashboardSummary(id, fundId),
  );
};

export const useGetDashboardSummaryByClient = (cliendId: string) => {
  return useQuery([DASHBOARD_API.getDashboardSummaryByClient.api(cliendId), cliendId], () =>
    getDashboardSummaryByClient(cliendId),
  );
};

export const useGetDashboardAllocations = (fundId?: string) => {
  return useQuery([DASHBOARD_API.getDashboardAllocations.api, fundId], () =>
    getDashboardAllocations(fundId),
  );
};

export const useGetDashboardDetails = (id?: string, fundId?: string) => {
  return useQuery([DASHBOARD_API.getDashboardDetails.api, id, fundId], () =>
    getDashboardDetails(id, fundId),
  );
};

export const useGetDashboardFundAllocations = (clientId?: string) => {
  return useQuery([DASHBOARD_API.getDashboardFundAllocations.api, clientId], () =>
    getDashboardFundAllocations(clientId),
  );
};

export const useGetDuplicationInfo = (applicationId: string) => {
  return useQuery(
    [DASHBOARD_API.getDuplicationInfo.api(applicationId), applicationId],
    () => getDuplicationInfo(applicationId),
    {
      enabled: !!applicationId,
    },
  );
};

export const useRejectDuplication = () => {
  const queryClient = useQueryClient();
  const fn = async (applicationId: string) => {
    const resp = await rejectDuplication(applicationId);
    await queryClient.invalidateQueries([DASHBOARD_API.getDashboardSummary.api]);
    return resp;
  };
  return useMutation(fn);
};

export const useConfirmDuplication = () => {
  const queryClient = useQueryClient();
  const fn = async (applicationId: string) => {
    const resp = await confirmDuplication(applicationId);
    await queryClient.invalidateQueries([DASHBOARD_API.getDashboardSummary.api]);
    return resp;
  };

  return useMutation(fn);
};

export const useGetClientSummary = (clientIds?: string[]) => {
  return useQuery<IClientSummaryData>([DASHBOARD_API.getClientSummary.api, clientIds], () =>
    getClientSummary(clientIds),
  );
};

export const useRemoteAccess = () => {
  const queryClient = useQueryClient();
  const fn = async (clientId: string) => {
    const resp = await remoteAccess(clientId);
    await queryClient.invalidateQueries([DASHBOARD_API.getDashboardSummary.api]);
    return resp;
  };

  return useMutation(fn);
};
