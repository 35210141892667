import { Box, Typography } from '@mui/material';
import React from 'react';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { useGetKYCInfo } from 'src/modules/applications/hooks';
import { IIndividualDetail, IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import IndividualItem from './IndividualItem';

interface IVerificationProps extends IUpsertInvestmentApplicationForm {
  // eslint-disable-next-line autofix/no-unused-vars
  setLastStep: (step: INVESTMENT_APPLICATION_STEP) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExcludeSteps: any;
}

function Verification(props: IVerificationProps) {
  const currentStep = INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION;
  const {
    data: kycInfo,
    refetch,
    isRefetching,
  } = useGetKYCInfo(props.id as string, {
    retry: false,
    onError: () => {
      props.setLastStep(currentStep - 1);
      props.setExcludeSteps((prev: object) => ({
        ...prev,
        [currentStep]: true,
      }));
    },
    onSuccess: () => {
      props.setLastStep(currentStep);
      props.setExcludeSteps((prev: object) => ({
        ...prev,
        [currentStep]: false,
      }));
    },
  });

  React.useEffect(() => {
    if (props.step === currentStep) {
      refetch();
    }
  }, [props.step]);

  React.useEffect(() => {
    if (props.isDraft) {
      props.setLastStep(currentStep - 1);
      props.setExcludeSteps((prev: object) => ({
        ...prev,
        [currentStep]: true,
      }));
    }
  }, [props.isDraft]);

  return (
    <Box mt={2} gap={3} display={'flex'} flexDirection={'column'}>
      <Typography fontWeight='bold'>Ultimate Beneficial Owners</Typography>
      {(kycInfo?.individuals || []).filter(Boolean).map((individual: IIndividualDetail) => {
        return (
          <IndividualItem
            {...props}
            key={individual.id}
            appId={props.id as string}
            individualId={individual.id as string}
            individual={individual}
            refetch={refetch}
            code={props.verifyCode || ''}
            disabled={props.isViewMode}
            isRefetchingKYCInfo={isRefetching}
          />
        );
      })}
    </Box>
  );
}

export default Verification;
