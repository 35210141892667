/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import ContactIcon from 'src/assets/icons/ContactIcon';
import { DisableIcon } from 'src/assets/icons/DisableIcon';
import { FlagIcon } from 'src/assets/icons/FlagIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { PersonSelectedIcon } from 'src/assets/icons/PersonSelectedIcon';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import { InvestorRelationsContact } from 'src/constants/funds-management';
import { ISignatoryContact, ISignatoryContactForm } from 'src/modules/funds-management/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';

interface IUserSelectionBoxProps {
  title: string;
  numOfSelected?: string;
  users: ISignatoryContact[];
  onSelect: (index: number, user: ISignatoryContactForm) => void;
  onRemove?: (index: number) => void;
  tooltip?: string;
  disabled?: boolean;
  onSelectAll?: (isSelectAll?: boolean) => void;
}

const UserSelectionBox: React.FC<IUserSelectionBoxProps> = ({
  users,
  numOfSelected,
  title,
  onSelect,
  onRemove,
  tooltip,
  disabled,
  onSelectAll,
}) => {
  const { isFmPortal } = useFundPortalContext();
  const renderTooltip = () => {
    if (tooltip) {
      return (
        <CustomTooltip title={tooltip} placement='right-end'>
          <span className='cursor-pointer ml-[6px]'>
            <InfoIcon />
          </span>
        </CustomTooltip>
      );
    }
  };

  const renderAllOption = () => {
    if (onSelectAll && typeof renderAllOption === 'function') {
      const isAllSelected =
        !(users || []).some((user: ISignatoryContactForm) => !user.isSelected) && users?.length;
      return (
        <>
          <Grid item xs={1} className='flex items-center justify-center'>
            <Box
              className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
              bgcolor='neutral.ne200'
            >
              <ContactIcon color='#9DA0A1' height='16px' />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box className='flex items-center '>
              <Typography variant='body3' mr='4px'>
                All Users
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} className='flex items-center justify-end'>
            <Box
              className={clsx('px-[9px] py-[5px] rounded-[4px]', {
                'cursor-pointer': !disabled,
              })}
              bgcolor='neutral.ne200'
              onClick={() => {
                if (!disabled) {
                  isAllSelected ? onSelectAll(false) : onSelectAll(true);
                }
              }}
            >
              <Typography
                color={disabled ? 'neutral.ne400' : 'primary.main'}
                variant='body3'
                fontWeight={500}
              >
                {isAllSelected ? 'Unselect' : 'Select'}
              </Typography>
            </Box>
          </Grid>
        </>
      );
    }
  };

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box className='flex items-center'>
          <Typography fontWeight={500} variant='body2'>
            {title}
          </Typography>
          {renderTooltip()}
        </Box>

        <Typography variant='body3' color='neutral.ne800'>
          {numOfSelected}
        </Typography>
      </Box>
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        <Grid container spacing={2}>
          {renderAllOption()}
          {users.map((user: ISignatoryContactForm, index: number) => {
            const disabledSelection = disabled || user?.isSuperAdmin;
            const contactName = user?.isNew
              ? `${user.firstName} ${user.lastName}`
              : user?.option === InvestorRelationsContact.Organisation
              ? `${user.organisationName || ''} ${user.contactName || ''}`
              : `${user.firstName || ''} ${user.lastName || ''}`;
            return (
              <Grid item xs={12} key={user?.id}>
                <Grid container spacing={2}>
                  <Grid item xs={1} className='flex items-center justify-center'>
                    <Box
                      className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
                      bgcolor={user.isSelected ? 'secondary.se100' : 'neutral.ne200'}
                    >
                      {user.isSelected ? (
                        <PersonSelectedIcon />
                      ) : (
                        <ContactIcon color='#9DA0A1' height='16px' />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className='flex items-center '>
                      <Typography variant='body3' mr='4px'>
                        {contactName}
                      </Typography>
                      {user.isNew && <FlagIcon />}
                    </Box>
                    <Typography variant='body3' color='neutral.ne800'>
                      {user.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className='flex items-center justify-end'>
                    <Box
                      className={clsx('px-[9px] py-[5px] rounded-[4px]', {
                        'cursor-pointer': !disabledSelection,
                      })}
                      bgcolor='neutral.ne200'
                      onClick={() =>
                        !disabledSelection &&
                        onSelect(index, { ...user, isSelected: !user.isSelected })
                      }
                    >
                      <Typography
                        color={disabledSelection ? 'neutral.ne400' : 'primary.main'}
                        variant='body3'
                        fontWeight={500}
                      >
                        {user.isSelected ? 'Unselect' : 'Select'}
                      </Typography>
                    </Box>
                    {!disabled && user.isNew && (
                      <IconButton onClick={() => onRemove?.(index)}>
                        <DisableIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default UserSelectionBox;
