import { ArchiveOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { capitalize, debounce, startCase } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import ActionMenus from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import UpsertUnitClassForm from 'src/components/pages/unit-class/UpsertUnitClassForm';
import { ActionType, FUM_RATE } from 'src/constants/common';
import { FundType, unitClassActionTypes, unitClassStatuses } from 'src/constants/funds-management';
import { UNIT_CLASS_STATUS } from 'src/constants/unit-class';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  useArchivedUnitClassById,
  useDeleteUnitClassById,
  useGetUnitClassFilters,
  useGetUnitClassList,
} from 'src/modules/funds-management/hooks';
import { IFundDetailParams, IUnitClass, IUpsertFundForm } from 'src/modules/funds-management/type';
import { formatNumberWithCommas, handleErrorFromServer } from 'src/utils/common';

interface ITableData extends IUnitClass {}

interface UnitClassProps extends IUpsertFundForm {
  fundId: string;
  fundDetail?: IFundDetailParams;
  unitPriceRounding: number;
  investorUnitRounding: number;
}

const UnitClass: FC<UnitClassProps> = ({
  isViewMode,
  fundId,
  fundDetail,
  unitPriceRounding,
  investorUnitRounding,
}) => {
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<ITableData>();
  const [actionType, setActionType] = useState<ActionType>('create');
  const [successDescription, setSuccessDescription] = useState('');
  const isOneOfFiltersEmpty = Boolean(emptyFilters?.length);

  const modalUnitClassDetailRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);

  const fundType = (fundDetail?.type as FundType) || FundType.Trust;

  const {
    data: { items: unitClassList = [], metadata: { page, pageSize, totalItem = 0 } = {} },
    isLoading,
    params,
    setParams,
    refetch: refetchUnitClassList,
  } = useGetUnitClassList(fundId);
  const isGotLimitItems = totalItem >= 100;

  const {
    data: { statuses },
  } = useGetUnitClassFilters();

  const { mutate: deleteUnitClass } = useDeleteUnitClassById(fundId);
  const { mutate: archivedUnitClass } = useArchivedUnitClassById(fundId);

  const handleSearchUnitClass = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleSelectStatus = (value: number[] | typeof MULTIPLE_SELECT_EMPTY) => {
    setParams({ statuses: value === MULTIPLE_SELECT_EMPTY ? [] : value });
    handleSetEmptyFilters(value, 'statuses');
  };

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleSetEmptyFilters = (
    values: (string | number)[] | typeof MULTIPLE_SELECT_EMPTY,
    filterBy: keyof typeof params,
  ) => {
    let newEmptyFilters: any[] = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const unitClassStatusesFormat = useMemo(() => {
    return statuses.map((item: (typeof statuses)[0]) => ({
      label: item.name,
      value: item.id,
    }));
  }, [statuses]);

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const dataByFundType = {
    [FundType.Partnership]: {
      name: 'financial rules',
      searchPlaceholder: 'Search by Name, Code',
      table: {
        name: 'Name',
        code: 'Code',
      },
    },
    [FundType.Trust]: {
      name: 'unit class',
      searchPlaceholder: 'Search by Unit Class Name, Unit Class Code',
      table: {
        name: 'Unit Class Name',
        code: 'Unit Class Code',
      },
    },
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: dataByFundType[fundType].table.name,
      key: 'name',
      sorter: true,
      sortBy: 'name',
      sx: { minWidth: 154, width: '15%', px: '12px', py: '4px' },
      cellSx: () => () => ({ p: '12px' }),
      renderNode: (row) => <StringNodeTable value={row.name || ''} />,
    },
    {
      title: dataByFundType[fundType].table.code,
      key: 'code',
      sorter: true,
      sortBy: 'code',
      sx: { minWidth: 130, width: '10%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => <StringNodeTable value={row.code || ''} />,
    },
    {
      title: 'Offer Type',
      key: 'offerTypeName',
      sorter: true,
      sortBy: 'offerTypeName',
      sx: { minWidth: 100, width: '10%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => <StringNodeTable value={row.offerTypeName || ''} />,
    },
    {
      title: 'Currency',
      key: 'currencyName',
      sorter: true,
      sortBy: 'currencyName',
      sx: { minWidth: 100, width: '10%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => <StringNodeTable value={row?.currencyName || ''} />,
    },
    {
      title: 'Number of Investors',
      key: 'numberOfInvestors',
      sorter: true,
      sortBy: 'numberOfInvestors',
      sx: { minWidth: 120, width: '10%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => <StringNodeTable value={row?.numberOfInvestors?.toString() || ''} />,
    },
    ...(fundType === FundType.Trust
      ? ([
          {
            title: 'Current Unit Price',
            key: 'currentUnitPrice',
            sorter: true,
            sortBy: 'currentUnitPrice',
            sx: { minWidth: 130, width: '10%', px: '12px', py: '4px' },
            cellSx: () => ({ p: '12px' }),
            renderNode: (row) => (
              <StringNodeTable
                value={
                  <NumericFormat
                    displayType='text'
                    value={row?.currentUnitPrice || 0}
                    fixedDecimalScale
                    decimalScale={unitPriceRounding || 0}
                    thousandSeparator
                  />
                }
              />
            ),
          },
          {
            title: 'Number of Units',
            key: 'numberOfUnits',
            sorter: true,
            sortBy: 'numberOfUnits',
            sx: { minWidth: 100, width: '10%', px: '12px', py: '4px' },
            cellSx: () => ({ p: '12px' }),
            renderNode: (row) => (
              <StringNodeTable
                value={
                  <NumericFormat
                    displayType='text'
                    value={row?.numberOfUnits || 0}
                    fixedDecimalScale
                    decimalScale={investorUnitRounding || 0}
                    thousandSeparator
                  />
                }
              />
            ),
          },
        ] as ColumnProps<ITableData, 'action'>[])
      : []),
    ...(fundType === FundType.Partnership
      ? ([
          {
            title: 'Committed Capital',
            key: 'committedCapital',
            sorter: true,
            sortBy: 'committedCapital',
            sx: { minWidth: 100, width: '20%', px: '12px', py: '4px' },
            cellSx: () => ({ p: '12px' }),
            renderNode: (row) => (
              <StringNodeTable value={row?.committedCapital?.toString() || ''} />
            ),
          },
        ] as ColumnProps<ITableData, 'action'>[])
      : []),
    {
      title: 'FUM($m)',
      key: 'fum',
      sorter: true,
      sortBy: 'fum',
      sx: { minWidth: 65, width: '7%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => {
        return (
          <StringNodeTable value={formatNumberWithCommas((row?.fum || 0) / FUM_RATE, 2) || ''} />
        );
      },
    },
    {
      title: 'Status',
      key: 'statusName',
      sx: { minWidth: 75, width: '8%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => {
        return <StatusBadge status={row.statusName || ''} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      sx: { minWidth: 90, width: '10%', px: '12px', py: '4px' },
      cellSx: () => ({ p: '12px' }),
      renderNode: (row) => {
        const getMenus = {
          [unitClassStatuses.Draft]: [
            unitClassActionTypes.view,
            unitClassActionTypes.edit,
            unitClassActionTypes.duplicate,
            unitClassActionTypes.delete,
          ],
          [unitClassStatuses.Pending]: [
            unitClassActionTypes.view,
            unitClassActionTypes.edit,
            unitClassActionTypes.duplicate,
            unitClassActionTypes.delete,
            unitClassActionTypes.archive,
          ],
          [unitClassStatuses.Active]: [
            unitClassActionTypes.view,
            unitClassActionTypes.edit,
            unitClassActionTypes.duplicate,
          ],
          [unitClassStatuses.Archived]: [
            unitClassActionTypes.view,
            unitClassActionTypes.delete,
            unitClassActionTypes.edit,
          ],
        };

        const actionIcons = {
          [unitClassActionTypes.view]: <DocumentOnePageIcon />,
          [unitClassActionTypes.edit]: <EditOutlinedIcon />,
          [unitClassActionTypes.duplicate]: (
            <Box
              component='span'
              color='neutral.ne800'
              display='inline-flex'
              fontSize='16px !important'
            >
              <ContentCopyOutlined fontSize='inherit' />
            </Box>
          ),
          [unitClassActionTypes.archive]: (
            <Box
              component='span'
              color='neutral.ne800'
              display='inline-flex'
              fontSize='16px !important'
            >
              <ArchiveOutlined fontSize='inherit' />
            </Box>
          ),
          [unitClassActionTypes.delete]: <DeleteIcon />,
        };

        const actions = {
          [unitClassActionTypes.view]: () => {
            modalUnitClassDetailRef.current?.open();
            setActionType('view');
            setSelectedRow(row);
          },
          [unitClassActionTypes.edit]: () => {
            modalUnitClassDetailRef.current?.open();
            setActionType('edit');
            setSelectedRow(row);
          },
          [unitClassActionTypes.duplicate]: () => {
            modalUnitClassDetailRef.current?.open();
            setActionType('duplicate');
            setSelectedRow(row);
          },
          [unitClassActionTypes.delete]: () => {
            setConfirmFormat({
              title: `Delete ${startCase(dataByFundType[fundType].name)}?`,
              content: `Are you sure you want to delete the ${dataByFundType[fundType].name}?`,
              button: {
                type: 'primary',
                label: 'Delete',
                onAction: () =>
                  deleteUnitClass(row.id, {
                    onSuccess: () => {
                      setSuccessDescription(`${row?.name} has been deleted.`);
                      modalAlertRef.current?.open();
                    },
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [unitClassActionTypes.archive]: () => {
            setConfirmFormat({
              title: `Archive ${startCase(dataByFundType[fundType].name)}?`,
              content: `Are you sure you want to archive the ${dataByFundType[fundType].name}?`,
              button: {
                type: 'primary',
                label: 'Archive',
                onAction: () =>
                  archivedUnitClass(row.id, {
                    onSuccess: () => {
                      setSuccessDescription(`${row?.name} has been archived.`);
                      modalAlertRef.current?.open();
                    },
                    onError: handleErrorFromServer,
                    onSettled: () => {
                      modalConfirmRef?.current?.close();
                    },
                  }),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const menus = getMenus[row.statusName]
          .filter((type) => {
            if (isViewMode) return type === unitClassActionTypes.view;
            return true;
          })
          .map((type) => ({
            icon: actionIcons?.[type],
            label: capitalize(type),
            onAction: actions?.[type],
          }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  const handleCreateUnitClass = () => {
    setActionType('create');
    modalUnitClassDetailRef.current?.open();
  };

  const handleCloseModalCreateUnitClass = (unitClassName?: string) => {
    if (unitClassName) {
      setSuccessDescription(
        `${startCase(
          dataByFundType[fundType].name,
        )} ${unitClassName} has been created successfully.`,
      );
      modalAlertRef.current?.open();
    }
    refetchUnitClassList();
    setSelectedRow(undefined);
    modalUnitClassDetailRef.current?.close();
  };

  return (
    <Box>
      <Box className='flex items-center pt-6 pb-4 gap-6'>
        <TextField
          placeholder={dataByFundType[fundType].searchPlaceholder}
          className='flex-1 h-[46px]'
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
          }}
          onChange={handleSearchUnitClass}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <CustomMultiSelect
          label='Status'
          options={unitClassStatusesFormat}
          onChange={handleSelectStatus}
          customRenderLabel={customRenderStatusLabel}
        />
      </Box>
      {!isViewMode && (
        <Box className='w-full flex justify-end mb-3'>
          <CustomButton
            className='px-3 -mr-3'
            variant='text'
            startIcon={<PlusPrimaryIcon disabled={isGotLimitItems} />}
            disabled={isGotLimitItems}
            onClick={handleCreateUnitClass}
          >
            <Typography
              variant='body2'
              fontWeight={600}
              color={isGotLimitItems ? 'neutral.ne400' : 'primary.main'}
            >
              {`Create New ${startCase(dataByFundType[fundType].name)}`}
            </Typography>
          </CustomButton>
        </Box>
      )}
      {(unitClassList.length && !isOneOfFiltersEmpty) || isLoading ? (
        <CustomTable
          columns={columns}
          rows={unitClassList}
          isFetchingData={isLoading}
          totalResults={totalItem}
          currentPage={page}
          numberItemOnPage={pageSize}
          onChangePagination={handleChangePage}
          onSort={handleSort}
        />
      ) : (
        <EmptyData />
      )}
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={successDescription}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal
        ref={modalUnitClassDetailRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleCloseModalCreateUnitClass()}
      >
        <UpsertUnitClassForm
          handleCloseModal={handleCloseModalCreateUnitClass}
          fundId={fundId}
          isViewMode={actionType === 'view'}
          isEditMode={actionType === 'edit'}
          isDuplicateMode={actionType === 'duplicate'}
          unitClass={selectedRow}
          isDraftStatus={selectedRow?.status === UNIT_CLASS_STATUS.Draft}
          fundCode={fundDetail?.code}
        />
      </BasicModal>
    </Box>
  );
};

export default UnitClass;
