import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import BracketPrimaryIcon from 'src/assets/icons/BracketPrimaryIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import FormIcon from 'src/assets/icons/FormIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import UploadButton from 'src/components/atoms/UploadButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { PDF_ACCEPT_TYPE } from 'src/constants/common';
import { DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import {
  INVESTOR_FORM_PDF_NAME,
  INVESTOR_FORM_TYPE,
  investorChangeDetailsDefaultValue,
  investorRedemptionFormDefaultValue,
  investorTransferFormDefaultValue,
} from 'src/constants/investor-form';
import { RedemptionOptionsType } from 'src/constants/unit-class';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo } from 'src/modules/common/type';
import {
  useDownloadInvestorFormFile,
  useDownloadInvestorPDF,
  useGetInvestorFormById,
  useGetInvestorFormId,
  useGetInvestorFormMasterData,
  useGetInvestorFormPopulateData,
  useSubmitInvestorForm,
  useUpsertChangeDetail,
  useUpsertRedemptionRequest,
  useUpsertTransferRequest,
} from 'src/modules/investor-form/hooks';
import {
  IChangeDetailsForm,
  IInvestorFormFund,
  IRedemptionFormFields,
  ITransferForm,
} from 'src/modules/investor-form/type';
import { handleErrorFromServer, serializeObject } from 'src/utils/common';
import {
  investorChangeDetailSchema,
  investorRedemptionFormSchema,
  investorTransferRequestSchema,
} from 'src/validations/investor-form';
import ChangeOfDetailsFormFields from './ChangeOfDetailsFormFields';
import RedemptionFormFields from './RedemptionFormFields';
import TransferFormFields from './TransferFormFields';

export interface IUpsertInvestorFormProps {
  onClose: () => void;
  formType: INVESTOR_FORM_TYPE;
  id: string;
  isViewMode: boolean;
  isEditMode: boolean;
  isCreateMode: boolean;
  isDraft: boolean;
}

type DeclarationType = {
  [key in INVESTOR_FORM_TYPE]: string[];
};

const DECLARATION: DeclarationType = {
  [INVESTOR_FORM_TYPE.REDEMPTION_REQUEST]: [
    `I have read the current Offer Document and acknowledge this redemption request is
    subject to the terms and conditions set out in the current Offer Document.`,
    `To the maximum extent permitted by law, I release, discharge and indemnify Trustee/GP/RE
    from and against all actions, proceedings, accounts, costs, expenses, charges, liabilities,
    claims and demands arising directly or indirectly as a result of instructions given in this form.`,
    `My details in this form are true and correct.`,
  ],
  [INVESTOR_FORM_TYPE.TRANSFER]: [
    `I/We the registered holder and undersigned Seller for the above consideration do hereby transfer to the above name hereinafter called the Buyer named in the Transfer Form, 
    the Securities as specified above standing in my/our name(s) in the books of the above-named Fund or eligible body subject to the several conditions on which I/we held 
    the same at the time of signing hereof and I/we the Buyer do hereby agree to accept the said securities subject to the same conditions.`,
    `I/We have not received any notice of revocation of the Power of Attorney by death of the grantor or otherwise, under which this transfer is signed.`,
  ],
  [INVESTOR_FORM_TYPE.CHANGE_DETAILS]: [],
};

type IFormData = {
  [key in INVESTOR_FORM_TYPE]: {
    form: UseFormReturn<any>;
    mutate: UseMutateFunction<string, unknown, any, unknown>;
  };
};

const UpsertInvestorForm: FC<IUpsertInvestorFormProps> = (props) => {
  const { onClose, formType, id, isViewMode, isCreateMode, isEditMode, isDraft } = props;
  const alertRef = useRef<IBasicModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [investorFormId, setInvestorFormId] = useState(id);
  const [formId, setFormId] = useState('');

  const redemptionForm: any = useForm<IRedemptionFormFields>({
    defaultValues: investorRedemptionFormDefaultValue,
    resolver: yupResolver(investorRedemptionFormSchema),
  });
  const transferForm: any = useForm<ITransferForm>({
    defaultValues: investorTransferFormDefaultValue,
    resolver: yupResolver(investorTransferRequestSchema),
  });
  const changeDetailsForm: any = useForm<IChangeDetailsForm>({
    defaultValues: investorChangeDetailsDefaultValue,
    resolver: yupResolver(investorChangeDetailSchema),
  });

  const { data: investorFormMasterData } = useGetInvestorFormMasterData();
  const { investmentEntities = [] } = investorFormMasterData || {};
  const { mutate: upsertRedemptionRequest, isLoading: upsertingRedemptionRequest } =
    useUpsertRedemptionRequest();
  const { mutate: upsertTransferRequest, isLoading: upsertingTransferRequest } =
    useUpsertTransferRequest();
  const { mutate: upsertChangeDetail, isLoading: upsertingChangeDetail } = useUpsertChangeDetail();
  const { mutate: submitInvestorForm, isLoading: submittingInvestorForm } = useSubmitInvestorForm();
  const { mutate: downloadInvestorFormFile } = useDownloadInvestorFormFile();
  const { mutate: downloadInvestorPDF } = useDownloadInvestorPDF();
  const { data: investorFormDetails } = useGetInvestorFormById(id);

  const FORM_DATA: IFormData = {
    [INVESTOR_FORM_TYPE.REDEMPTION_REQUEST]: {
      form: redemptionForm,
      mutate: upsertRedemptionRequest,
    },
    [INVESTOR_FORM_TYPE.TRANSFER]: {
      form: transferForm,
      mutate: upsertTransferRequest,
    },
    [INVESTOR_FORM_TYPE.CHANGE_DETAILS]: {
      form: changeDetailsForm,
      mutate: upsertChangeDetail,
    },
  };
  const watchFile = FORM_DATA[formType].form.watch('file');
  const watchInvestmentEntityId = FORM_DATA[formType].form.watch('investmentEntityId');
  const watchFundId = FORM_DATA[formType].form.watch('fundId');
  const watchUnitClassId = FORM_DATA[formType].form.watch('unitClassId');

  const { mutate: getInvestorFormId } = useGetInvestorFormId();
  const { data: investorFormPopulateData } = useGetInvestorFormPopulateData({
    unitClassId: watchUnitClassId,
    fundId: watchFundId,
    investmentEntityId: watchInvestmentEntityId,
  });
  const {
    fundType,
    units,
    unpaidAmount,
    netValue,
    investedAmount,
    investmentRounding,
    currencyName,
    investorNumber,
    redemptionAllowedType,
    accountNumber,
    unitRounding,
  } = investorFormPopulateData || {};
  const disabledRedemptionSubmit =
    formType === INVESTOR_FORM_TYPE.REDEMPTION_REQUEST &&
    redemptionAllowedType === RedemptionOptionsType.No;

  useEffect(() => {
    if (formId) {
      FORM_DATA[formType].form.setValue('formId', formId);
    }
  }, [formId]);

  useEffect(() => {
    FORM_DATA[formType].form.setValue('formType', formType);
  }, [formType]);

  useEffect(() => {
    handleSetPopulateData();
  }, [investorFormPopulateData]);

  useEffect(() => {
    if (disabledRedemptionSubmit) {
      handleOpenAlertModal({
        title: 'Oops!',
        description:
          'Redemption for this fund is not allowed. Please contact Investor Relations for more information.',
        isError: true,
      });
    }
  }, [disabledRedemptionSubmit]);

  useEffect(() => {
    if (investorFormDetails) {
      handleFillData();
    }
  }, [investorFormDetails]);

  useEffect(() => {
    setInvestorFormId(id);
  }, [id]);

  const handleFillData = () => {
    const serializeData = serializeObject(investorFormDetails, true);
    if (investorFormDetails?.fileName) {
      serializeData.file = {
        name: investorFormDetails?.fileName,
        size: investorFormDetails?.fileSize,
      };
    }
    FORM_DATA[formType].form.reset(serializeData);
  };

  const handleSetPopulateData = () => {
    if (formType === INVESTOR_FORM_TYPE.CHANGE_DETAILS) return;
    FORM_DATA[formType].form.setValue('units', units);
    FORM_DATA[formType].form.setValue('investedAmount', investedAmount);
    FORM_DATA[formType].form.setValue('unpaidAmount', unpaidAmount);
    FORM_DATA[formType].form.setValue('netValue', netValue);
    FORM_DATA[formType].form.setValue('investorNumber', investorNumber);
  };

  const handleUploadForm = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    FORM_DATA[formType].form.setValue('file', file);
    FORM_DATA[formType].form.setValue('willFileRemoved', false);
  };

  const handleOpenAlertModal = (alertInfo: IAlertInfo) => {
    setAlertInfo(alertInfo);
    alertRef.current?.open();
  };

  const handleSubmitSuccess = (mode: SUBMIT_FORM_ACTIONS) => {
    const description =
      formType === INVESTOR_FORM_TYPE.REDEMPTION_REQUEST
        ? 'Redemption request submitted successfully.'
        : formType === INVESTOR_FORM_TYPE.CHANGE_DETAILS
        ? 'Change of details request submitted successfully.'
        : 'Transfer request submitted successfully.';

    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleOpenAlertModal({
        title: 'Draft saved!',
        description: 'You can come back and resume at any time.',
        isError: false,
      });
    } else {
      handleOpenAlertModal({
        title: 'You did it!',
        description,
        isError: false,
      });
    }
  };

  const getFormData = () => {
    const formData = cloneDeep(FORM_DATA[formType].form.getValues());

    switch (formType) {
      case INVESTOR_FORM_TYPE.REDEMPTION_REQUEST:
        formData.redemptionDate = formData?.redemptionDate
          ? dayjs(formData.redemptionDate).format(DATE_PICKER_FORMAT_SERVER)
          : undefined;
        break;
      case INVESTOR_FORM_TYPE.TRANSFER:
        formData.transferDate = dayjs(formData?.transferDate).format(DATE_PICKER_FORMAT_SERVER);
        formData.transfereeABN = formData.transfereeABN?.replace(/\s/g, '');
        break;
      default:
        break;
    }

    return formData;
  };

  const onSubmit = (mode: SUBMIT_FORM_ACTIONS) => {
    const isSaveDraft = mode === SUBMIT_FORM_ACTIONS.DRAFT;
    const formData = getFormData();

    // For edit mode we only call save api
    if (isEditMode && !isDraft) {
      FORM_DATA[formType].mutate(
        {
          id: investorFormId,
          data: formData,
        },
        {
          onSuccess: () => handleSubmitSuccess(mode),
          onError: handleErrorFromServer,
        },
      );
    } else {
      // For create mode, draft status
      FORM_DATA[formType].mutate(
        {
          id: investorFormId,
          data: formData,
        },
        {
          onSuccess: (formId: string) => {
            !investorFormId && setInvestorFormId(formId);
            if (isSaveDraft) {
              handleSubmitSuccess(mode);
            } else {
              submitInvestorForm(formId, {
                onSuccess: () => handleSubmitSuccess(mode),
                onError: handleErrorFromServer,
              });
            }
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const onDownloadForm = () => {
    const formData = getFormData();

    if (isDraft || isCreateMode) {
      FORM_DATA[formType].mutate(
        { data: formData, id: investorFormId },
        {
          onSuccess: (formId: string) => {
            !investorFormId && setInvestorFormId(formId);
            downloadInvestorPDF(
              { id: formId, fileName: INVESTOR_FORM_PDF_NAME[formType] },
              {
                onError: handleErrorFromServer,
              },
            );
          },
          onError: handleErrorFromServer,
        },
      );
    } else {
      downloadInvestorPDF(
        { id: investorFormId, fileName: INVESTOR_FORM_PDF_NAME[formType] },
        {
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const handleClickDownloadForm = () => {
    if (disabledRedemptionSubmit || (isViewMode && isDraft)) return;
    FORM_DATA[formType].form.setValue('isSaveDraft', false);
    FORM_DATA[formType].form.handleSubmit(onDownloadForm)();
  };

  const handleDownloadInvestorFormFile = () => {
    if (watchFile instanceof File) return;
    downloadInvestorFormFile(
      { id, fileName: watchFile?.name },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const handleRemoveFile = () => {
    if (investorFormDetails?.fileName) {
      FORM_DATA[formType].form.setValue('willFileRemoved', true);
    }
    FORM_DATA[formType].form.setValue('file', undefined);
  };

  const handleGetFormId = (entityId: string) => {
    getInvestorFormId(
      { formType, entityId },
      {
        onSuccess: (id: string) => setFormId(id),
        onError: handleErrorFromServer,
      },
    );
  };

  const getFundOptions = (keepOriginalFunds?: boolean) => {
    if (!watchInvestmentEntityId) return [];
    const investmentEntity = investmentEntities.find((it) => it.id === watchInvestmentEntityId);
    if (!investmentEntity) return [];

    if (keepOriginalFunds) return investmentEntity.funds;

    return investmentEntity.funds.map((it) => ({
      value: it.id,
      label: it.name,
    }));
  };

  const getUnitClassIdOptions = () => {
    if (!watchFundId) return [];
    const funds = getFundOptions(true) as IInvestorFormFund[];
    const fund = funds.find((it) => it.id === watchFundId);
    if (!fund) return [];

    return fund.unitClasses.map((it) => ({
      value: it.id,
      label: it.name,
    }));
  };

  const investmentEntityOptions = investmentEntities
    .map((it) => ({
      value: it.id,
      label: it.name,
    }))
    ?.sort((a, b) => a.label?.localeCompare(b.label));

  const renderFormFields = () => {
    const defaultProps = {
      ...props,
      investmentEntityOptions,
      getFundOptions,
      getUnitClassIdOptions,
      fundType,
      unitRounding,
      currencyName,
      onGenerateFormId: handleGetFormId,
    };

    switch (formType) {
      case INVESTOR_FORM_TYPE.REDEMPTION_REQUEST:
        return <RedemptionFormFields {...defaultProps} accountNumber={accountNumber} />;
      case INVESTOR_FORM_TYPE.TRANSFER:
        return <TransferFormFields {...defaultProps} />;
      case INVESTOR_FORM_TYPE.CHANGE_DETAILS:
        return (
          <ChangeOfDetailsFormFields
            {...defaultProps}
            fundsData={investorFormDetails?.funds}
            infoChangesData={investorFormDetails?.infoChanges}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Box>
        <Box
          className='header flex flex-col items-center pt-10 pb-11.5 border-b relative'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5' fontWeight={700}>
            Create New Form
          </Typography>
          <Box className='flex items-center mt-6'>
            <BracketPrimaryIcon />
            <Typography className='ml-3' variant='subtitle3' fontWeight={700}>
              {formType === INVESTOR_FORM_TYPE.REDEMPTION_REQUEST
                ? 'Redemption Request'
                : formType === INVESTOR_FORM_TYPE.TRANSFER
                ? 'Transfer Form'
                : 'Change of Details Request'}
            </Typography>
          </Box>
          <IconButton className='absolute top-10 right-10' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className='body pt-10 pb-18'>
          <Box className='mx-auto' width='667px'>
            <FormProvider {...FORM_DATA[formType].form}>
              {renderFormFields()}
              <Box className='pt-10'>
                <Typography
                  className='py-2 px-4 mb-6'
                  bgcolor='neutral.ne100'
                  variant='body1'
                  fontWeight={600}
                >
                  Declaration
                </Typography>
                <Box>
                  <FormCheckbox
                    name='isDeclarationRead'
                    label={
                      DECLARATION[formType].length
                        ? 'I/we declare and agree each of the following:'
                        : 'I/we declare that all the details on this form are correct and authorise the changes to be made to my account.'
                    }
                    disabled={isViewMode}
                  />
                  {!!DECLARATION[formType].length && (
                    <Box component='ul' className='flex flex-col gap-4 list-disc pl-10 mt-4.5'>
                      {DECLARATION[formType].map((it, index) => (
                        <Box key={index} component='li'>
                          <Typography variant='body2'>{it}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className='mt-10 px-4 py-3' bgcolor='secondary.se100'>
                <Box className='flex items-center'>
                  <FormIcon />
                  <Typography className='pl-2.5' variant='body3' fontWeight={500}>
                    To submit this form:
                  </Typography>
                </Box>
                <Box className='pt-2 flex flex-col gap-1 pl-7.5' component='ol'>
                  <Box component='li'>
                    <Typography variant='body3' fontWeight={500}>
                      1. Download the{' '}
                      <span
                        className='underline hover:cursor-pointer'
                        onClick={handleClickDownloadForm}
                      >
                        form
                      </span>
                      .
                    </Typography>
                  </Box>
                  <Box component='li'>
                    <Typography variant='body3' fontWeight={500}>
                      2. All authorised signatories must sign the form.
                    </Typography>
                  </Box>
                  <Box component='li'>
                    <Typography variant='body3' fontWeight={500}>
                      3. Upload the signed form.
                    </Typography>
                  </Box>
                  <Box component='li'>
                    <Typography variant='body3' fontWeight={500}>
                      4. Submit the form for processing.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className='pt-6 flex flex-col gap-4 items-start'>
                <UploadButton
                  startIcon={<UploadFilesIcon />}
                  label='Upload form'
                  inputProps={{
                    accept: PDF_ACCEPT_TYPE,
                  }}
                  fullWidth={false}
                  handleFileChange={handleUploadForm}
                  disabled={!investorFormId || disabledRedemptionSubmit || isViewMode}
                />
                <Typography variant='body3' color='neutral.ne500'>
                  Supported format: pdf
                </Typography>
                {watchFile && (
                  <Box
                    className='flex items-center justify-between rounded-xl px-5 py-4 w-full'
                    bgcolor='neutral.ne100'
                  >
                    <Box className='flex items-center'>
                      <PDFIcon width={32} height={32} />
                      <Typography
                        className='flex flex-col gap-1 px-2 hover:cursor-pointer'
                        variant='body2'
                        onClick={handleDownloadInvestorFormFile}
                      >
                        <span className='break-all'>{watchFile?.name}</span>
                        <Typography variant='body3' color='neutral.ne800'>
                          {`${Math.ceil((watchFile?.size || 0) / 1024)} kb`}
                        </Typography>
                      </Typography>
                    </Box>
                    {!isViewMode && (
                      <IconButton
                        sx={{ p: '6px', bgcolor: 'neutral.ne200' }}
                        onClick={handleRemoveFile}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Box>
            </FormProvider>
          </Box>
        </Box>
        {!isViewMode && (
          <Box
            className='footer flex justify-end gap-6 items-center py-3.5 px-7.5 border-t'
            borderColor='neutral.ne200'
          >
            <CustomButton
              variant='outlined'
              sx={(theme) => ({
                color: theme.palette.neutral.ne800,
                borderColor: theme.palette.neutral.ne800,
              })}
              onClick={() => {
                FORM_DATA[formType].form.setValue('isSaveDraft', true);
                FORM_DATA[formType].form.handleSubmit(() => onSubmit(SUBMIT_FORM_ACTIONS.DRAFT))();
              }}
              disabled={
                upsertingRedemptionRequest ||
                upsertingTransferRequest ||
                submittingInvestorForm ||
                disabledRedemptionSubmit ||
                upsertingChangeDetail
              }
            >
              Save
            </CustomButton>
            <CustomButton
              onClick={() => {
                FORM_DATA[formType].form.setValue('isSaveDraft', false);
                FORM_DATA[formType].form.handleSubmit(() => onSubmit(SUBMIT_FORM_ACTIONS.SUBMIT))();
              }}
              isLoading={
                upsertingRedemptionRequest ||
                upsertingTransferRequest ||
                submittingInvestorForm ||
                upsertingChangeDetail
              }
              disabled={disabledRedemptionSubmit || !watchFile}
            >
              Submit
            </CustomButton>
          </Box>
        )}
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title={alertInfo.title}
          description={alertInfo.description}
          isError={alertInfo.isError}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              !alertInfo.isError && onClose();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default UpsertInvestorForm;
