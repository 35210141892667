import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import OvalDownloadIcon from 'src/assets/icons/OvalDownloadIcon';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import { KYC_SECTION_ENUM } from 'src/constants/kyc-back-office';
import { detectPortalType } from 'src/helpers/common';
import { useDownloadDocumentByPath, useDownloadFileByPath } from 'src/modules/common/hooks';
import { IDocument } from 'src/modules/common/type';
import {
  useDownloadApplicationForm,
  useGetKYCBackOfficeById,
} from 'src/modules/kyc-back-office/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

interface IDocumentsProps {
  id: string;
}

interface ITableData extends IDocument {
  isApplicationForm?: boolean;
}

const Documents: FC<IDocumentsProps> = ({ id = '' }) => {
  const { data: KYCDocumentSectionData } = useGetKYCBackOfficeById(id, KYC_SECTION_ENUM.DOCUMENTS);
  const { mutate: downloadFileByPathMutate } = useDownloadFileByPath();
  const { mutate: downloadDocumentByPath } = useDownloadDocumentByPath();
  const { mutate: downloadApplicationForm } = useDownloadApplicationForm();
  const { isAdmin } = detectPortalType();

  const checkIsApplicationForm = (document: IDocument) => {
    if (!KYCDocumentSectionData?.documents?.applicationForm) return false;

    return document.path === KYCDocumentSectionData.documents.applicationForm.path;
  };

  const handleDownloadDocument = (row: ITableData) => {
    const isApplicationForm = checkIsApplicationForm(row);

    if (isAdmin) {
      downloadDocumentByPath(
        { path: row.path, fileName: row.fileName },
        {
          onError: handleErrorFromServer,
        },
      );
    } else if (isApplicationForm) {
      downloadApplicationForm(
        { kycId: id, fileName: row.fileName },
        {
          onError: handleErrorFromServer,
        },
      );
    } else {
      downloadFileByPathMutate(
        { path: row.path, fileName: row.fileName },
        {
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const getRows = () => {
    const rows = [
      ...(KYCDocumentSectionData?.documents?.documents || []),
      ...(KYCDocumentSectionData?.documents?.fundApplicationDocuments || []),
    ];

    if (KYCDocumentSectionData?.documents?.applicationForm) {
      rows.push(KYCDocumentSectionData.documents.applicationForm);
    }

    return rows;
  };

  const columns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable className='line-clamp-1' value={row.description || ''} />
      ),
    },
    {
      title: 'File name',
      key: 'fileName',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(row.fileType);
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='line-clamp-1 pl-2' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File size',
      key: 'fileSize',
      sx: { width: '10%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Date uploaded',
      key: 'dateUploaded',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={String(utcToLocalTimezone(row.uploadedDate || ''))} />
      ),
    },
    {
      title: 'Uploaded by',
      key: 'uploadedBy',
      sx: { width: '20%' },
      renderNode: (row: ITableData) =>
        row.uploadedBy && <StringNodeTable value={String(row.uploadedBy)} />,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '10%' },
      renderNode: (row: ITableData) => (
        <IconButton onClick={() => handleDownloadDocument(row)}>
          <OvalDownloadIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <CustomTableContainer
      sx={(theme) => ({
        '.MuiTableContainer-root': {
          maxHeight: 650,
          borderColor: theme.palette.neutral.ne200,
        },
        ' .MuiTableCell-body': {
          borderColor: theme.palette.neutral.ne100,
        },
      })}
    >
      <CustomTable
        rows={getRows()}
        columns={columns}
        hasPagination={false}
        stickyHeader
        displayEmpty
        customEmpty={
          <EmptyData
            isTable
            description='No document.'
            icon={<DocumentOnePageIcon width={22} height={27} />}
            marginY='34px'
          />
        }
      />
    </CustomTableContainer>
  );
};

export default Documents;
