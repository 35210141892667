import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import { useCommunicationFileDownload } from 'src/modules/communication/hooks';
import { ICommunicationAttachment } from 'src/modules/communication/type';
import { handleErrorFromServer } from 'src/utils/common';

interface IAttachment {
  attachments: ICommunicationAttachment[];
  disabledRemove?: boolean;
}

const Attachment: React.FC<IAttachment> = ({ attachments, disabledRemove }) => {
  const { mutate: downloadAttachment, isLoading } = useCommunicationFileDownload();

  const { control } = useFormContext();
  const { remove } = useFieldArray({
    control,
    name: 'attachments',
  });

  const handleDownloadAttachment = (attachment: ICommunicationAttachment) => {
    downloadAttachment(
      {
        path: attachment.path,
        fileName: attachment.fileName,
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };
  return (
    <Box className='flex'>
      <Box className='flex flex-col w-full'>
        {attachments?.map((attachment, index) => {
          const fileSizeInKB = (attachment?.fileSize / 1024).toFixed(1);
          return (
            <Box
              key={`attachment-${index}`}
              className='flex items-center justify-between my-1'
              bgcolor='neutral.ne200'
              pl={2}
              py={disabledRemove ? 1 : 0}
            >
              <Box
                className='flex items-center cursor-pointer'
                onClick={() => !isLoading && handleDownloadAttachment(attachment)}
              >
                {getFileTypeIcon(attachment?.fileType)}
                <Typography variant='body3' mx='8px' color='primary.main'>
                  {attachment?.fileName} {`(${fileSizeInKB}KB)`}
                </Typography>
              </Box>
              {!disabledRemove && (
                <IconButton onClick={() => remove(index)}>
                  <CloseOutlined />
                </IconButton>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Attachment;
