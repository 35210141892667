/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, styled, SxProps, Typography } from '@mui/material';
import * as React from 'react';
import OrganisationSettingLogoDefault from 'src/assets/images/OrganisationSettingLogoDefault';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import LoadingIndicator from 'src/components/atoms/LoadingIndicator';
import UploadButton from 'src/components/atoms/UploadButton';
import { IMAGE_ACCEPT_TYPE, MAX_IMAGE_SIZE } from 'src/constants/common';

interface LogoSettingProps {
  title?: string;
  description?: string;
  logo?: string;
  onChange?: (file: File) => void;
  isLoading?: boolean;
  sx?: SxProps;
  readOnly?: boolean;
  logoWidth?: string;
  logoHeight?: string;
  defaultLogo?: React.ReactElement;
  titleClass?: string;
  isOptional?: boolean;
  ratio?: number[];
  errorMessage?: string;
}

const CustomLogoImg = styled('img')(({ theme }) => ({
  width: 'inherit',
  height: 'inherit',
  border: `1px solid ${theme.palette.neutral.ne300}`,
  objectFit: 'contain',
}));

const defaultDescription = `.png, .jpeg, files up to 10MB. The recommended ratio size is 16:9.
This image should have a transparent background.`;

const LogoSetting: React.FC<LogoSettingProps> = ({
  title = 'Logo',
  description = defaultDescription,
  isLoading,
  logo,
  onChange,
  sx = {},
  readOnly = false,
  logoWidth,
  logoHeight,
  defaultLogo,
  titleClass,
  isOptional,
  ratio = [4, 8],
  errorMessage,
}) => {
  const onChangeFile = (files: FileList) => {
    const file = files[0];
    file && onChange?.(file);
  };

  const logoSize = React.useMemo(() => {
    return {
      width: logoWidth || '200px',
      height: logoHeight || '200px',
    };
  }, [logoWidth, logoHeight]);

  const renderLogo = () => {
    if (isLoading) {
      return (
        <Box
          width={logoSize.width}
          height={logoSize.height}
          className='flex justify-center items-center'
        >
          <LoadingIndicator />
        </Box>
      );
    } else if (logo) {
      return (
        <Box width={logoSize.width} height={logoSize.height}>
          <CustomLogoImg src={logo} />
        </Box>
      );
    }
    return (
      defaultLogo || (
        <OrganisationSettingLogoDefault width={logoSize.width} height={logoSize.height} />
      )
    );
  };

  return (
    <Box sx={{ backgroundColor: 'base.white', p: 3, borderRadius: '10px', ...sx }}>
      <Box className='flex justify-between items-center'>
        <Typography variant='h6' mb='24px' className={titleClass}>
          {title}
        </Typography>
        {isOptional && (
          <Typography variant='body2' mb='24px' color='neutral.ne800'>
            Optional
          </Typography>
        )}
      </Box>
      <Grid container>
        <Grid xs={ratio[0]} item>
          {renderLogo()}
        </Grid>
        <Grid xs={ratio[1]} item padding={0}>
          <UploadButton
            label='Upload image'
            disabled={readOnly || isLoading}
            handleFileChange={onChangeFile}
            validate={{ fileSize: MAX_IMAGE_SIZE }}
            inputProps={{ accept: IMAGE_ACCEPT_TYPE, disabled: readOnly || isLoading }}
          />
          <Box mt='20px'>
            <Typography variant='body3' color='neutral.ne800'>
              {description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {errorMessage && (
        <Box mt={1}>
          <CustomHelperText variant={'error'} message={errorMessage} />
        </Box>
      )}
    </Box>
  );
};

export default LogoSetting;
