/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IIosArrowRightIconProps {
  width?: string;
  height?: string;
}

const IosArrowRightIcon: React.FunctionComponent<IIosArrowRightIconProps> = ({ width, height }) => {
  return (
    <svg
      width={width || '24px'}
      height={height || '24px'}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='00_Navigation_03' transform='translate(-447.000000, -121.000000)'>
          <rect fill='#FFFFFF' x='0' y='0' width='1440' height='1080'></rect>
          <rect
            id='Rectangle-Copy-2'
            fill='#FFFFFF'
            x='284'
            y='96'
            width='1132'
            height='960'
            rx='10'
          ></rect>
          <g id='05.Templates/01.Expand'>
            <rect id='Rectangle' fill='#F9F9F9' x='0' y='0' width='1440' height='1080'></rect>
            <rect
              id='Rectangle-Copy-2'
              fill='#FFFFFF'
              x='284'
              y='96'
              width='1132'
              height='960'
              rx='10'
            ></rect>
            <g
              id='Breadcrumbs'
              transform='translate(308.000000, 120.000000)'
              fill='#1C1C1C'
              fillRule='nonzero'
            >
              <g
                id='01.Design-Token/06.Icons/Line/arrow-ios-forward-outline'
                transform='translate(139.000000, 1.000000)'
              >
                <g id='Group' transform='translate(2.000000, 2.000000)'>
                  <polygon
                    id='Rectangle'
                    opacity='0'
                    transform='translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000) '
                    points='-9.99999988e-08 2.24518581e-15 20 2.24518581e-15 20 20.0000001 -9.99999991e-08 20.0000001'
                  ></polygon>
                  <path
                    d='M8.33333337,15.8333334 C8.13862324,15.8337138 7.94992676,15.765901 7.80000004,15.6416667 C7.62957743,15.5003766 7.52237684,15.2970828 7.5020526,15.076643 C7.48172837,14.8562032 7.54995095,14.6367356 7.69166671,14.4666667 L11.4250001,10 L7.82500004,5.52500003 C7.68519129,5.35283884 7.61977593,5.13204926 7.64323707,4.9115146 C7.6666982,4.69097994 7.77710028,4.48889435 7.95000004,4.35000002 C8.12430554,4.19663406 8.35468953,4.12293728 8.58564513,4.1466656 C8.81660073,4.17039391 9.02718691,4.28939585 9.16666671,4.47500002 L13.1916667,9.47500005 C13.444431,9.7825016 13.444431,10.2258318 13.1916667,10.5333334 L9.02500005,15.5333334 C8.85544778,15.7378718 8.59852913,15.8493064 8.33333337,15.8333334 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IosArrowRightIcon;
