/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import FormSwitch from 'src/components/atoms/FormSwitch';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IUpsertInvestmentEntityForm } from 'src/modules/investment-entity/types';

interface IAddNewProps extends IUpsertInvestmentEntityForm {
  children?: ReactNode;
}

const AddNew = (props: IAddNewProps) => {
  const { children, isViewMode } = props;
  const { watch } = useFormContext<IInvestmentEntityForm>();

  const isAddNew = watch('isAddNewEntityDetail');

  return (
    <Box
      className='flex flex-col rounded-xl overflow-hidden border border-solid'
      borderColor={'neutral.ne200'}
    >
      <Box p={3} bgcolor='neutral.ne100' className='flex justify-between'>
        <Typography variant='body2' fontWeight={600}>
          Add New
        </Typography>
        <FormSwitch name='isAddNewEntityDetail' disabled={isAddNew || isViewMode} />
      </Box>
      <Box p={3} className='flex flex-col'>
        {isAddNew && children}
      </Box>
    </Box>
  );
};

export default AddNew;
