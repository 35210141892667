import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetTaxDetails } from 'src/modules/applications/hooks';
import {
  IFinancialTaxDetailsForm,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import TaxDetailsSection from './components/TaxDetailsSection';
import TaxInformationSection from './components/TaxInformationSection';

export interface ITaxDetailsProps extends IUpsertInvestmentApplicationForm {}

const TaxDetails: FC<ITaxDetailsProps> = (props) => {
  const { id = '', isExistingInvestmentEntity } = props;
  const [isShowTaxInfo, setIsShowTaxInfo] = useState(false);
  const { data: taxDetails } = useGetTaxDetails(id);
  const {
    directors,
    foreignControllingPersons,
    taxNoTINReasons,
    isForeignTaxResident,
    isAnyIndividualContacts,
    allowForeignInvestors,
    individualTaxPersons,
  } = taxDetails || {};
  const { watch } = useFormContext<IFinancialTaxDetailsForm>();

  const watchTaxResidentOtherCountryThanAustralia = watch('isTaxResidentOtherCountryThanAustralia');

  useEffect(() => {
    if (
      !(
        allowForeignInvestors === false &&
        isForeignTaxResident === false &&
        isAnyIndividualContacts &&
        watchTaxResidentOtherCountryThanAustralia
      ) ||
      isExistingInvestmentEntity
    ) {
      setIsShowTaxInfo(true);
    }
  }, [
    watchTaxResidentOtherCountryThanAustralia,
    isForeignTaxResident,
    isAnyIndividualContacts,
    allowForeignInvestors,
  ]);

  return (
    <Box>
      <TaxDetailsSection
        {...props}
        isForeignTaxResident={isForeignTaxResident}
        isAnyIndividualContacts={isAnyIndividualContacts}
        allowForeignInvestors={allowForeignInvestors}
        individualTaxPersons={individualTaxPersons}
      />
      <Box mb={3} />
      {isShowTaxInfo && (
        <TaxInformationSection
          directors={directors}
          taxNoTINReasons={taxNoTINReasons}
          isForeignTaxResident={isForeignTaxResident}
          isAnyIndividualContacts={isAnyIndividualContacts}
          allowForeignInvestors={allowForeignInvestors}
          foreignControllingPersons={foreignControllingPersons}
          {...props}
        />
      )}
    </Box>
  );
};

export default TaxDetails;
