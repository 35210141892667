import { APPLICATION_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { IFundApplicationCreationParams } from './types';

export const getApplicationFilterCreation = async (params: IFundApplicationCreationParams) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: APPLICATION_API.getApplicationFilterCreation.api,
  });
  return result.data;
};
