import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { AUTH_API } from 'src/constants/apiEndpoints';
import { ERROR_CODE } from 'src/constants/errorCode';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import * as StorageService from 'src/helpers/storage';
import { forceLogout, requestRefreshToken } from 'src/modules/auth/services';
import { handleErrorFromServer } from 'src/utils/common';
import { detectPortalType } from './common';
import { showToast } from './toast';

interface ApiConfig {
  baseURL: string | undefined;
}

const createApi = (config: ApiConfig): AxiosInstance => {
  let refreshTokenRequest: Promise<string> | null;
  const timezoneOffset = -new Date().getTimezoneOffset();
  const instance = axios.create({
    ...config,
    headers: {
      'Content-Type': 'application/json',
      'x-timezone': timezoneOffset,
    },
  });

  // Add interceptors and other configuration here

  const setAuthorizationHeader = (request: AxiosRequestConfig | any, token: string) => {
    request.headers.Authorization = `Bearer ${token}`;
  };

  const onRequest = (config: AxiosRequestConfig | any) => {
    const token = StorageService.getToken();
    token && setAuthorizationHeader(config, token);
    return config;
  };

  const onRequestError = (error: AxiosError) => {
    return Promise.reject(error);
  };

  const onResponse = (response: AxiosResponse) => {
    return response;
  };

  const handleRefreshToken = (refreshToken: string) =>
    requestRefreshToken(refreshToken)
      .then((result) => {
        StorageService.setToken(result.token);
        StorageService.setValue(STORAGE_KEYS.REFRESH_TOKEN, result.refreshToken);
        return result.token;
      })
      .catch((error) => {
        // Force logout when refresh token request is error
        forceLogout();
        throw error;
      });

  const onResponseError = (error: AxiosError) => {
    const token = StorageService.getToken();
    const refreshToken = StorageService.getValue(STORAGE_KEYS.REFRESH_TOKEN) || '';
    const config = error.config as any;
    const url = error.config?.url;

    if (error.isAxiosError) {
      if (error.code === ERROR_CODE.ERR_NETWORK) {
        showToast(error.message, 'error');
      }

      switch (error.response?.status) {
        case ERROR_CODE.UNAUTHORIZED:
          // Only refresh token for requests that are not refresh token request
          if (url !== AUTH_API.refreshToken.api) {
            // Prevent calling refresh token twice
            refreshTokenRequest = refreshTokenRequest
              ? refreshTokenRequest
              : handleRefreshToken(refreshToken).finally(() => {
                  // Holding refresh token request in 10 seconds for some next 401 request if any
                  setTimeout(() => {
                    refreshTokenRequest = null;
                  }, 10000);
                });

            return refreshTokenRequest.then(() => {
              // Recall the 401 request
              return instance(config);
            });
          }

          break;

        case ERROR_CODE.UNPROCESSABLE_ENTITY:
          // Only logout user if user have logged in
          if (token) {
            forceLogout();
          }
          // Only redirect to forbidden page when current page !== forbidden page
          if (window.location.pathname !== ROUTES_PATH.FORBIDDEN) {
            window.location.href = ROUTES_PATH.FORBIDDEN;
          }
          break;
        case ERROR_CODE.INTERNAL_SERVER_ERROR:
          handleErrorFromServer(error?.response);
          break;

        default:
          return Promise.reject(error.response);
      }
    }

    return Promise.reject(error.response);
  };

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);

  return instance;
};

// Export each instance of axios as a separate object
const adminPortalApi = createApi({ baseURL: process.env.REACT_APP_ADMIN_PORTAL_API_URL });
const fundPortalApi = createApi({ baseURL: process.env.REACT_APP_FUND_PORTAL_API_URL });
const investorPortalApi = createApi({ baseURL: process.env.REACT_APP_INVESTOR_PORTAL_API_URL });

const getApiFromPortalType = () => {
  const { isAdmin, isInvestor, portalType } = detectPortalType();
  if (isAdmin) return adminPortalApi;
  else if (isInvestor) return investorPortalApi;
  else {
    // add an interceptor to detect the subdomain and set x-tenant-id header
    fundPortalApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      // set x-tenant-id header with the subdomain value
      config.headers['x-tenant-id'] = portalType;
      return config;
    });
    return fundPortalApi;
  }
};

const portalApi = getApiFromPortalType();

export { adminPortalApi, fundPortalApi, investorPortalApi, portalApi };
