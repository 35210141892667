import { Box, IconButton, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import IndividualBasicInfo from 'src/components/molecules/individual/IndividualBasicInfo';
import { IInvestmentEntityProps } from '..';

interface IPublicOfficersFormItemProps extends IInvestmentEntityProps {
  keyName: string;
  onDelete: () => void;
}

const PublicOfficersFormItem: React.FunctionComponent<IPublicOfficersFormItemProps> = (props) => {
  const { keyName, isViewMode, isDisabledEdit, isExistingInvestmentEntity, onDelete } = props;
  const theme = useTheme();

  const { watch } = useFormContext();
  const firstName = watch(`${keyName}.firstName`);
  const lastName = watch(`${keyName}.lastName`);
  const position = watch(`${keyName}.position`);

  return (
    <Accordion
      defaultExpanded
      sx={{
        border: `1px solid ${theme.palette.neutral.ne300} !important`,
        '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        backgroundColor: 'inherit',
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
          '.MuiAccordionSummary-content': { my: 0 },
          minHeight: 'unset',
        }}
      >
        <Box className='flex justify-between items-center w-full'>
          <Typography variant='body2' fontWeight={500}>
            {`${firstName || ''} ${lastName || ''}${
              position && (firstName || lastName) ? ` - ` : ''
            }${position || ''}`}
          </Typography>
          {!(isViewMode || isDisabledEdit) && !isExistingInvestmentEntity && (
            <IconButton onClick={onDelete} sx={{ p: 0 }}>
              <DeleteIcon width={24} height={24} color={theme.palette.neutral.ne600} />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className='p-6 pt-0 mt-0'>
        <IndividualBasicInfo
          keyName={keyName}
          disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
          position={{
            show: true,
            disabled: isViewMode || isDisabledEdit || isExistingInvestmentEntity,
          }}
          hiddenFields={['dob', 'residentialAddress']}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default PublicOfficersFormItem;
