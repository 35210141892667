import { Box, TooltipProps } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { CustomTooltip } from './CustomTooltip';

type ILabelWithTooltipProps = Omit<TooltipProps, 'children' | 'title'> & {
  label: ReactNode;
  tooltip: ReactNode;
  className?: string;
  iconPosition?: 'left' | 'right';
};

const LabelWithTooltip: FC<ILabelWithTooltipProps> = ({
  label,
  tooltip,
  className,
  iconPosition = 'right',
  ...rest
}) => {
  return (
    <Box
      gap={1}
      component='span'
      className={clsx('flex items-center', className, {
        'flex-row-reverse': iconPosition === 'left',
      })}
    >
      <Box component='span' display='inline'>
        {label}{' '}
        <CustomTooltip {...rest} title={tooltip}>
          <Box
            component='span'
            sx={{
              '& > svg': {
                display: 'unset',
              },
            }}
            className='cursor-pointer'
          >
            <InfoIcon />
          </Box>
        </CustomTooltip>
      </Box>
    </Box>
  );
};

export default LabelWithTooltip;
