import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthLayout from 'src/components/organisms/AuthLayout';
import Layout from 'src/components/organisms/Layout';
import { IRouterType } from 'src/interfaces/routes';
import { useAuthenticated } from 'src/modules/auth/hooks';
import WithPrivateRoute from './PrivateRoute';
import WithRestrictedRoute from './RestrictedRoute';
import { appRoutes } from './routes';

function renderRoutes(routes: IRouterType[]) {
  return routes.map((route: IRouterType, index: number) => {
    const PrivateRoute = WithPrivateRoute(route.element);
    const RestrictedRoute = WithRestrictedRoute(route.element);
    let element = <route.element />;

    if (route.isProtected) element = <PrivateRoute />;
    else if (route.isRestricted) element = <RestrictedRoute />;

    return <Route key={index} path={route.path} element={element} />;
  });
}

export const AppRouter = () => {
  const { isAuthenticated } = useAuthenticated();
  const AppLayout = isAuthenticated ? Layout : AuthLayout;

  return (
    <Router>
      <AppLayout>
        <Routes>
          {renderRoutes(appRoutes)}
          <Route path='*' element={<div>Not found</div>} />
        </Routes>
      </AppLayout>
    </Router>
  );
};
