import { REQUIRE_MESSAGE } from 'src/constants/common';
import { RedemptionOptionsType, UnitClassOfferType } from 'src/constants/unit-class';
import * as Yup from 'yup';

export const unitClassDetailValidationSchema = Yup.object().shape({
  name: Yup.string().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  code: Yup.string().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.string()
      : Yup.string().min(1, REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  initialUnitPrice: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.mixed()
      : Yup.number()
          .test('test', REQUIRE_MESSAGE, (value) => {
            return Boolean(value);
          })
          .transform((current, origin) => {
            return String(origin)?.includes(',') ? Number(origin.replaceAll(',', '')) : current;
          })
          .typeError(REQUIRE_MESSAGE)
          .required(REQUIRE_MESSAGE);
  }),
  allowPartiallyPaidUnits: Yup.boolean().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.boolean().nullable() : Yup.boolean().required(REQUIRE_MESSAGE);
  }),
  allowCapitalCall: Yup.boolean().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.boolean().nullable() : Yup.boolean().required(REQUIRE_MESSAGE);
  }),
  additionalInformation: Yup.string().nullable(),
  isSaveDraft: Yup.boolean(),
  fundId: Yup.string(),
});

const transformNumberSchema = Yup.number().transform((current, origin) => {
  return String(origin)?.includes(',') ? Number(origin.replaceAll(',', '')) : current;
});
const transformMixedSchema = Yup.mixed().transform((current, origin) => {
  return String(origin)?.includes(',') ? Number(origin.replaceAll(',', '')) : current;
});

export const investorRelationSchema = Yup.object().shape({
  email: Yup.string().required('Investor Relation is required.'),
});

export const investmentDetailsValidationSchema = Yup.object().shape({
  offerType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.number().nullable()
      : Yup.number().typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  investorTypes: Yup.array().when(['isSaveDraft', 'offerType'], ([isSaveDraft, offerType]) => {
    return isSaveDraft || typeof offerType !== 'number' || offerType === UnitClassOfferType.Retail
      ? Yup.array().of(Yup.number())
      : Yup.array().of(Yup.number()).min(1, REQUIRE_MESSAGE);
  }),
  allowForeignInvestors: Yup.boolean().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.boolean() : Yup.boolean().required(REQUIRE_MESSAGE);
  }),
  acceptInvestmentAmountUnderMinimum: Yup.boolean().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.boolean() : Yup.boolean().required(REQUIRE_MESSAGE);
  }),
  redemptionAllowedType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  foreignInvestorTypes: Yup.array().when(
    ['isSaveDraft', 'allowForeignInvestors'],
    ([isSaveDraft, allowForeignInvestors]) => {
      return isSaveDraft || !allowForeignInvestors
        ? Yup.array().of(Yup.number())
        : Yup.array().of(Yup.number()).min(1, REQUIRE_MESSAGE);
    },
  ),
  minimumInvestmentAmount: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? transformMixedSchema
      : transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  minimumSubsequentInvestmentAmount: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? transformMixedSchema
      : transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  maximumInvestmentAmount: Yup.number().when(
    ['isSaveDraft', 'unlimitedInvestmentAmount'],
    ([isSaveDraft, unlimitedInvestmentAmount]) => {
      return isSaveDraft || unlimitedInvestmentAmount
        ? transformMixedSchema.nullable()
        : transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
    },
  ),
  incrementInvestmentAmount: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? Yup.number()
      : Yup.number().typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  hardFloor: Yup.number().when(
    ['isSaveDraft', 'acceptInvestmentAmountUnderMinimum'],
    ([isSaveDraft, acceptInvestmentAmountUnderMinimum]) => {
      return isSaveDraft || !acceptInvestmentAmountUnderMinimum
        ? transformMixedSchema.nullable()
        : transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE).nullable();
    },
  ),
  minimumRedeptionAmount: Yup.number().when(
    ['isSaveDraft', 'redemptionAllowedType'],
    ([isSaveDraft, redemptionAllowedType]) => {
      return isSaveDraft || redemptionAllowedType === RedemptionOptionsType.No
        ? transformMixedSchema.nullable()
        : transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE).nullable();
    },
  ),
  isSaveDraft: Yup.boolean(),
  unlimitedInvestmentAmount: Yup.boolean(),
});
