import { ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { CustomTextField } from 'src/components/atoms/FormInput';
import { IAuditTrail } from 'src/modules/kyc-back-office/type';
import { utcToLocalTimezone } from 'src/utils/time';

interface IAuditDetailProps {
  item: IAuditTrail;
}

const AuditTrailDetail = ({ item }: IAuditDetailProps) => {
  return (
    <>
      <Box className='flex justify-between items-center' my={1}>
        <Typography variant='body2'>{item.field}</Typography>
        <Box px='8px' py='2px' className='rounded-[8px]' bgcolor='sematic.information100'>
          <Typography variant='body3' color='sematic.information500'>
            Manual Intervention
          </Typography>
        </Box>
      </Box>
      <Box className='flex justify-between items-center'>
        <CustomTextField value={item.oldValue} className='w-full' />
        <Box mx={2}>
          <ArrowForward
            sx={{
              color: 'neutral.ne300',
            }}
          />
        </Box>
        <CustomTextField value={item.newValue} className='w-full' />
      </Box>
      <Box className='text-right'>
        <Typography variant='body3' color='neutral.ne800'>{`Last updated by ${
          item.updatedByName
        } on ${utcToLocalTimezone(item.updatedOn)}`}</Typography>
      </Box>
    </>
  );
};
export default AuditTrailDetail;
