import { Box, Stack, styled, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useRef } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import DatePickerIcon from 'src/assets/icons/DatePickerIcon';
import { DATE_PICKER_FORMAT, DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import CustomHelperText from './CustomHelperText';

interface IFormDatePickerProps {
  name: string;
  label?: string;
  defaultMessage?: string;
  optional?: ReactNode;
  rules?: Record<string, unknown>;
  disabled?: boolean;
  datePickerProps?: DatePickerProps<unknown>;
  disabledInput?: boolean;
  useServerFormat?: boolean;
  disableFuture?: boolean;
  onChange?: (date?: any) => void;
}

export const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    textTransform: 'lowercase',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.neutral.ne200,
    '& fieldset': {
      borderColor: theme.palette.neutral.ne400,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& fieldset': {
      borderWidth: 1,
    },
  },
}));

const FormDatePicker: React.FC<IFormDatePickerProps> = ({
  name,
  label,
  rules = {},
  defaultMessage = '',
  disabled,
  optional,
  datePickerProps,
  disabledInput,
  useServerFormat,
  disableFuture = false,
  onChange: handleChange,
}) => {
  const { control } = useFormContext();
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  const containerRef = useRef<any>();

  useEffect(() => {
    // cheat 😁😁
    const input = containerRef.current?.querySelector('input');
    if (disabledInput) {
      if (input) {
        input.onkeydown = (e: any) => e.preventDefault();
      }
    } else {
      if (input) {
        input.onkeydown = null;
      }
    }
  }, [disabledInput]);

  return (
    <Stack className='w-full' gap={0.5}>
      <Box className='flex items-center justify-between' minHeight='20px'>
        <Typography variant='body3' sx={{ width: optional ? 'auto' : '100%' }}>
          {label}
        </Typography>
        <Typography variant='body3' color='neutral.ne800'>
          {optional}
        </Typography>
      </Box>
      <Box width={'100%'} ref={containerRef}>
        <CustomDatePicker
          {...inputProps}
          format={DATE_PICKER_FORMAT}
          {...datePickerProps}
          value={
            typeof inputProps.value === 'string'
              ? dayjs(inputProps.value)
              : inputProps.value || null
          }
          disabled={disabled}
          slotProps={{
            textField: {
              error: invalid,
            },
          }}
          slots={{
            openPickerIcon: DatePickerIcon,
          }}
          inputRef={ref}
          sx={{ width: '100%' }}
          onChange={(newDate: any) => {
            if (newDate) {
              const formatDate = useServerFormat
                ? newDate?.format(DATE_PICKER_FORMAT_SERVER)
                : newDate;
              onChange(formatDate);
              handleChange && handleChange(formatDate);
            }
          }}
          disableFuture={disableFuture}
        />
      </Box>
      {invalid && (
        <Box className='ml-2'>
          <CustomHelperText
            variant={invalid ? 'error' : 'default'}
            message={invalid ? (error as FieldError)?.message : defaultMessage}
          />
        </Box>
      )}
    </Stack>
  );
};

export default FormDatePicker;
