/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
  debounce,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import FormAutocomplete from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  INVESTMENT_ENTITY_SMSF_TRUSTTYPE,
  SUPER_FUND_STATUS,
  SUPER_FUND_TYPE,
  abnAcnNotAllowedStatus,
  trusteeNameRegexp,
} from 'src/constants/applications';
import { useSearchSuperFund } from 'src/modules/applications/hooks';
import { searchSuperFund } from 'src/modules/applications/services';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import TrustTee from '../shared/TrustTee';
import TrustUbos from '../shared/TrustUbos';
import UploadCertified from '../shared/UploadCertified';

interface ISMSFProps extends IInvestmentEntityProps {
  hideUBODeclaration?: boolean;
  enabledClearCorporateDetails?: boolean;
  disabledAbn?: boolean;
}

const SMSFDetail = (props: ISMSFProps) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    hideAbnSearch,
    hideUbo,
    isInvestmentEntitySetting,
    disabledAbn,
  } = props;
  const { countryOptions } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { watch, setValue, setError, clearErrors } = useFormContext<IInvestmentEntityForm>();

  const watchTrustType = watch('investmentEntityDetails.smsf.trustType');
  const watchSuperFundStatus = watch('investmentEntityDetails.smsf.superFundStatus');

  const {
    data: superFundData,
    isLoading: isSearchSuperFundLoading,
    setParams,
  } = useSearchSuperFund({});

  const handleSearchSuperFundcInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear') {
        const trimValue = value.replace(/\s/g, '');
        // Only trim for number
        if (Number.isInteger(Number(trimValue))) {
          setParams({
            search: trimValue,
          });
        } else {
          setParams({
            search: value,
          });
        }
      }
    },
    500,
  );

  const options = superFundData
    ? ('matchingFundName' in superFundData &&
        superFundData.matchingFundName.map((item, index) => ({
          id: index,
          label: item.fundName.name,
          value: item.abn.value,
        }))) ||
      ('identifier' in superFundData && [
        {
          id: 0,
          label: superFundData.organisationName?.name,
          value: superFundData.identifier?.value,
          ...superFundData,
        },
      ]) ||
      []
    : [];

  const handleSuperFundChange = async (
    event: ChangeEvent<unknown>,
    data: (typeof options)[0],
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== 'selectOption') return;
    try {
      const superFund = Array.isArray(data) ? data[0] : data;

      const abn = superFund.value;
      let superFundData;
      if (!superFund?.complyingStatus?.description) {
        const data = await searchSuperFund({ search: abn });
        superFundData = data;
      } else {
        superFundData = superFund;
      }

      const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(
        superFundData.identifier.identifierStatus.description,
      );

      clearErrors('investmentEntityDetails.smsf.abn');
      setValue(
        'investmentEntityDetails.smsf.abn',
        formatValueWithSpaces(superFundData.identifier.value),
        { shouldDirty: true },
      );
      setValue(
        'investmentEntityDetails.smsf.name',
        superFundData.organisationName.name?.replaceAll(trusteeNameRegexp, '').trim(),
        { shouldDirty: true },
      );
      setValue(
        'investmentEntityDetails.smsf.trustType',
        superFundData.fundType.code === SUPER_FUND_TYPE.SMF
          ? INVESTMENT_ENTITY_SMSF_TRUSTTYPE.ATORegulatedSMSFund
          : INVESTMENT_ENTITY_SMSF_TRUSTTYPE.Other,
        { shouldDirty: true },
      );
      setValue(
        'investmentEntityDetails.smsf.superFundStatus',
        superFundData.complyingStatus?.description,
      );
      if (isNotAllowed) {
        setError('investmentEntityDetails.smsf.abn', {
          message: 'ABN is inactive',
          type: 'invalidAbn',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const trustTypeOptions = [
    {
      label: 'ATO Regulated Self-Managed Superannuation Fund',
      value: INVESTMENT_ENTITY_SMSF_TRUSTTYPE.ATORegulatedSMSFund,
    },
    { label: 'Other', value: INVESTMENT_ENTITY_SMSF_TRUSTTYPE.Other },
  ];

  return (
    <Box className='flex flex-col'>
      <Grid container spacing={3}>
        {!isExistingInvestmentEntity && !hideAbnSearch && (
          <Grid item xs={12}>
            <FormAutocomplete
              name=''
              dataKey='id'
              options={options}
              searchIcon={<SearchIcon />}
              isLoading={isSearchSuperFundLoading}
              placeholder='Search by Name or ABN'
              hidePopupIcon
              renderOption={(props, option) => (
                <li {...props}>
                  <Box className='flex flex-col'>
                    <Typography variant='body3' fontWeight='medium'>
                      {option.label}
                    </Typography>
                    <Typography variant='caption1' color='neutral.ne500' paddingTop='4px'>
                      {`ABN: ${option.value}`}
                    </Typography>
                  </Box>
                </li>
              )}
              renderTags={(selected) => (
                <Typography variant='body2'>
                  <span className='line-clamp-1'>
                    {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
                  </span>
                </Typography>
              )}
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.value || ''}
              onInputChange={handleSearchSuperFundcInputChange}
              onChange={handleSuperFundChange as any}
              disabled={disabled}
              blurOnSelect
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.smsf.name'
            label='Superannuation Fund Name'
            placeholder='Enter superannuation fund name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.smsf.abn'
            label='ABN'
            placeholder='Enter ABN'
            maxLength={14}
            formatValue={(value) => formatValueWithSpaces(value)}
            disabled={disabled || disabledAbn}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.smsf.countryOfEstablishment'
            options={countryOptions}
            label='Country of Establishment'
            placeholder='Select country of establishment'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.smsf.countryOfTaxResident'
            options={countryOptions}
            label='Country of Tax Residence'
            placeholder='Select country of tax residence'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name='investmentEntityDetails.smsf.registeredOffice'
            label='Registered Office'
            placeholder='Enter registered office'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.smsf.trustType'
            options={trustTypeOptions}
            label='Type'
            placeholder='Select type'
            disabled={disabled}
          />
        </Grid>
        {watchTrustType === INVESTMENT_ENTITY_SMSF_TRUSTTYPE.Other && (
          <Grid item xs={6}>
            <FormInput
              name='investmentEntityDetails.smsf.otherTrustType'
              label='Other Type'
              placeholder='Enter other type'
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      {watchSuperFundStatus !== SUPER_FUND_STATUS.Complying && (
        <Box>
          <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
          <UploadCertified
            {...props}
            containerSx={{ pt: 0 }}
            title='Upload Certified Trust Deed'
            keyName='investmentEntityDetails.smsf'
          />
        </Box>
      )}
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      <TrustTee {...props} hideUbo={false} keyName='investmentEntityDetails.smsf' />
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      {!hideUbo && <TrustUbos {...props} keyName='investmentEntityDetails.smsf' />}
    </Box>
  );
};

export default SMSFDetail;
