import { Box, IconButton, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { AuditTrailIcon } from 'src/assets/icons/AuditTrailIcon';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { DATE_TIME_FORMAT } from 'src/constants/date';
import { detectPortalType } from 'src/helpers/common';
import { useGetAuditTrailList } from 'src/modules/audit-trail/hooks';
import { IAuditTrailItem } from 'src/modules/audit-trail/type';
import { utcToLocalTimezone } from 'src/utils/time';
import AuditTrailDetails from './components/AuditTrailDetails';

interface IAuditTrailProps {}

const AuditTrail: FC<IAuditTrailProps> = (props) => {
  const { isAdmin } = detectPortalType();
  const drawerAuditTrailRef = useRef<ICustomDrawerElement>(null);
  const {
    data: { items: auditTrailList = [], metadata: { page, pageSize, totalItem } = {} },
    setParams,
    isLoading,
  } = useGetAuditTrailList<IAuditTrailItem>();
  const [selectedItem, setSelectedItem] = useState<IAuditTrailItem>();

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleClickViewDetail = (row: IAuditTrailItem) => {
    setSelectedItem(row);
    drawerAuditTrailRef.current?.open();
  };

  const columns: ColumnProps<IAuditTrailItem, 'action'>[] = [
    {
      title: 'Client',
      key: 'tenant',
      sx: {
        width: '10%',
      },
      renderNode: (row) => <StringNodeTable value={row?.tenant || ''} />,
    },
    {
      title: 'Feature',
      key: 'moduleName',
      sx: {
        width: '10%',
      },
      renderNode: (row) => <StringNodeTable value={row?.moduleName || ''} />,
    },
    {
      title: 'Timestamp',
      key: 'date',
      sx: {
        width: '15%',
      },
      renderNode: (row) => (
        <StringNodeTable value={utcToLocalTimezone(row?.date, DATE_TIME_FORMAT) || ''} />
      ),
    },
    {
      title: 'Action',
      sx: {
        width: '10%',
      },
      key: 'actionName',

      renderNode: (row) => <StringNodeTable value={row?.actionName || ''} />,
    },
    {
      title: 'Previous Value',
      key: 'oldValue',
      sx: {
        width: '20%',
      },
      renderNode: (row) => <StringNodeTable className='line-clamp-3' value={row?.oldValue || ''} />,
    },
    {
      title: 'Changed Value',
      key: 'auditor',
      sx: {
        width: '20%',
      },
      renderNode: (row) => <StringNodeTable className='line-clamp-3' value={row?.newValue || ''} />,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { minWidth: 90, width: 90 },
      isSticky: true,
      renderNode: (row) => (
        <IconButton onClick={() => handleClickViewDetail(row)}>
          <AuditTrailIcon />
        </IconButton>
      ),
    },
  ];

  if (!isAdmin) return <Box>Audit trail</Box>;
  return (
    <>
      <Box className='flex items-center justify-between'>
        <Box component='div'>
          <Typography variant='h6'>Audit Trail</Typography>
        </Box>
      </Box>
      <Box className='w-full h-[2px] mt-4' bgcolor='neutral.ne200' />
      <Box className='mt-10'>
        {auditTrailList?.length || isLoading ? (
          <CustomTable
            tableClassName='table-fixed'
            columns={columns}
            rows={auditTrailList}
            isFetchingData={isLoading}
            totalResults={totalItem}
            currentPage={page}
            numberItemOnPage={pageSize}
            onChangePagination={handleChangePage}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
      <CustomDrawer ref={drawerAuditTrailRef} title='Audit Trail' isWithoutActions>
        <AuditTrailDetails auditTrailItem={selectedItem} />
      </CustomDrawer>
    </>
  );
};

export default AuditTrail;
