import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { COMMUNICATION_API } from 'src/constants/apiEndpoints';
import { detectPortalType } from 'src/helpers/common';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  communicationArchive,
  communicationFileDownload,
  communicationFileUpload,
  communicationPriority,
  communicationRead,
  communicationUnread,
  createCommunicationMessage,
  deleteCommunicationMessage,
  downloadMessageAttachments,
  getCommunicationById,
  getCommunicationCategoryClient,
  getCommunicationCategoryInternal,
  getCommunicationCategoryInvestor,
  getCommunicationCategoryKycBo,
  getCommunicationForwardMessage,
  getCommunicationInboxes,
  getCommunicationInitKycBo,
  getCommunicationMasterData,
  getCommunicationOutboxes,
  getCommunicationReplyMessage,
  getInboxMessageDetailById,
  updateCommunicationMessage,
} from './services';
import {
  ICategoryInvestor,
  ICategoryKYCBO,
  IClientRecipientDetail,
  ICommunication,
  ICommunicationDetail,
  ICommunicationQueryBody,
  IFundCategoryInvestor,
  IInternalRecipientDetail,
  IUpsertCommunicationBody,
} from './type';

const { isAdmin, isInvestor } = detectPortalType();

export const useGetCommunicationInboxes = () => {
  const [queryBody, setQueryBody] = useState<ICommunicationQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<ICommunication>
  >([COMMUNICATION_API.getCommunicationInboxes.api, queryBody], () =>
    getCommunicationInboxes(queryBody),
  );

  const setParams = (newParams: ICommunicationQueryBody, isReplace = false) => {
    const mergedParams = { ...queryBody, ...newParams };
    if (isReplace) {
      setQueryBody(removeEmptyProps(newParams));
    } else {
      setQueryBody(removeEmptyProps(mergedParams));
    }
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetCommunicationOutboxes = () => {
  const [queryBody, setQueryBody] = useState<ICommunicationQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<ICommunication>
  >([COMMUNICATION_API.getCommunicationOutboxes.api, queryBody], () =>
    getCommunicationOutboxes(queryBody),
  );

  const setParams = (newParams: ICommunicationQueryBody, isReplace = false) => {
    const mergedParams = { ...queryBody, ...newParams };
    if (isReplace) {
      setQueryBody(removeEmptyProps(newParams));
    } else {
      setQueryBody(removeEmptyProps(mergedParams));
    }
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetCommunicationMasterData = () => {
  return useQuery([COMMUNICATION_API.getCommunicationMasterData.api], getCommunicationMasterData);
};

export const useGetCommunicationCategoryClient = () => {
  return useQuery<IClientRecipientDetail[]>(
    [COMMUNICATION_API.getCommunicationCategoryClient.api],
    getCommunicationCategoryClient,
    {
      enabled: !isInvestor,
    },
  );
};

export const useGetCommunicationCategoryInternal = () => {
  return useQuery<IInternalRecipientDetail[]>(
    [COMMUNICATION_API.getCommunicationCategoryInternal.api],
    getCommunicationCategoryInternal,
    {
      enabled: !isInvestor,
    },
  );
};

export const useGetCommunicationById = ({
  id,
  userMessageId,
}: {
  id: string;
  userMessageId?: string;
}) => {
  if (userMessageId) {
    return useQuery<ICommunicationDetail>(
      [COMMUNICATION_API.getInboxMessageDetailById.api(id), id, userMessageId],
      () => getInboxMessageDetailById(id, userMessageId),
      {
        enabled: !!id,
      },
    );
  }

  return useQuery<ICommunicationDetail>(
    [COMMUNICATION_API.communicationById.api(id), id],
    () => getCommunicationById(id),
    {
      enabled: !!id,
    },
  );
};

export const useUpdateCommunicationMessage = () => {
  const resp = async ({ data }: { data: IUpsertCommunicationBody }) => {
    await updateCommunicationMessage(data);
  };
  return useMutation(resp);
};

export const useCreateCommunicationMessage = () => {
  const resp = async ({ data }: { data: IUpsertCommunicationBody }) => {
    await createCommunicationMessage(data);
  };
  return useMutation(resp);
};

export const useCommunicationRead = () => {
  const resp = async ({ data }: { data: string[] }) => {
    await communicationRead(data);
  };
  return useMutation(resp);
};

export const useDeleteCommunicationMessage = () => {
  const resp = async ({ data }: { data: string[] }) => {
    await deleteCommunicationMessage(data);
  };
  return useMutation(resp);
};

export const useCommunicationUnread = () => {
  const resp = async ({ data }: { data: string[] }) => {
    await communicationUnread(data);
  };
  return useMutation(resp);
};

export const useCommunicationPriority = () => {
  const resp = async ({ data }: { data: string[] }) => {
    await communicationPriority(data);
  };
  return useMutation(resp);
};

export const useCommunicationArchive = () => {
  const resp = async ({ data }: { data: string[] }) => {
    await communicationArchive(data);
  };
  return useMutation(resp);
};

export const useCommunicationFileUpload = () => {
  const resp = async ({ files }: { files: File[] }) => {
    const uploadedFiles = [];
    // Iterate through selected files and upload each one
    for (const file of files) {
      const uploadedFile = await communicationFileUpload(file);
      uploadedFiles.push(uploadedFile);
    }
    return uploadedFiles;
  };

  return useMutation(resp);
};

export const useCommunicationFileDownload = (isShowing?: boolean) => {
  const resp = async ({ path, fileName }: { fileName: string; path: string }) => {
    const data = await communicationFileDownload(path);
    const res = downloadFileFromArrayBuffer(data, fileName, isShowing);
    return res;
  };
  return useMutation(resp);
};

export const useGetCommunicationReplyMessage = (id: string, userMessageId?: string) => {
  return useQuery<ICommunicationDetail>(
    [COMMUNICATION_API.communicationReply.api(id), id, userMessageId],
    () => getCommunicationReplyMessage(id, userMessageId),
    {
      enabled: !!id,
    },
  );
};

export const useGetCommunicationForwardMessage = (id: string) => {
  return useQuery<ICommunicationDetail>(
    [COMMUNICATION_API.communicationForward.api(id), id],
    () => getCommunicationForwardMessage(id),
    {
      enabled: !!id,
    },
  );
};

export const useGetCommunicationCategoryInvestor = () => {
  return useQuery<ICategoryInvestor[] | IFundCategoryInvestor[]>(
    [COMMUNICATION_API.getCommunicationCategoryInvestor.api],
    getCommunicationCategoryInvestor,
    {
      enabled: !isAdmin,
    },
  );
};

export const useGetCommunicationCategoryKycBo = (kycBoId: string) => {
  return useQuery<ICategoryKYCBO>(
    [COMMUNICATION_API.communicationCategoryKycBo.api(kycBoId), kycBoId],
    () => getCommunicationCategoryKycBo(kycBoId),
    {
      enabled: !isInvestor && !!kycBoId,
    },
  );
};

export const useGetCommunicationInitKycBo = (kycBoId: string, enabled = true) => {
  return useQuery<ICommunicationDetail>(
    [COMMUNICATION_API.communicationInitKycBo.api(kycBoId), kycBoId],
    () => getCommunicationInitKycBo(kycBoId),
    {
      enabled: enabled && !isInvestor && !!kycBoId,
      refetchOnWindowFocus: false,
    },
  );
};

export const useDownloadMessageAttachments = () => {
  const resp = async (messageId: string) => {
    const data = await downloadMessageAttachments(messageId);
    downloadFileFromArrayBuffer(data, 'AttachDoc.zip');
  };
  return useMutation(resp);
};
