import { Box, Typography } from '@mui/material';
import React from 'react';

interface ChartContainerProps {
  title: string;
  children: React.ReactNode;
  height?: string | number;
}

const ChartContainer: React.FC<ChartContainerProps> = ({ title, children, height }) => {
  return (
    <Box p={2} className='bg-white rounded-[14px] relative' height={height ? height : '320px'}>
      <Typography
        variant='body3'
        textTransform='uppercase'
        fontSize='12px'
        color='neutral.ne800'
        fontWeight={500}
      >
        {title}
      </Typography>
      <Box className='flex justify-center items-center h-full'>{children}</Box>
    </Box>
  );
};

export default ChartContainer;
