/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { USER_MANAGEMENT_API } from 'src/constants/apiEndpoints';
import { FundUserType } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import * as StorageService from 'src/helpers/storage';
import { BaseQuery, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { removeEmptyProps } from 'src/utils/common';
import {
  createUser,
  deleteUser,
  getDelegateInvestmentEntity,
  getInvestorUserEntities,
  getUserById,
  getUserClients,
  getUserFilters,
  getUserFunds,
  getUserRoles,
  getUsers,
  resendUserInvitation,
  resetUser2FA,
  resetUserPassword,
  updateUser,
  updateUserStatus,
} from './services';
import { IUserBody, User, UserEntities, UserFilter, UserQueryBody } from './type';

export const useGetUserRoles = (roleType?: number) => {
  const { data, isLoading, refetch } = useQuery([USER_MANAGEMENT_API.userRoles.api, roleType], () =>
    getUserRoles(roleType),
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetUserClients = (isFunds: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [USER_MANAGEMENT_API.userClients.api],
    getUserClients,
    { enabled: isFunds },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetUserFunds = (isFunds: boolean) => {
  const { data, isLoading, refetch } = useQuery([USER_MANAGEMENT_API.userFunds.api], getUserFunds, {
    enabled: isFunds,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useGetUserFilters = () => {
  const { data, isLoading, refetch } = useQuery(
    [USER_MANAGEMENT_API.userFilters.api],
    getUserFilters,
  );

  return {
    data: data as UserFilter,
    isLoading,
    refetch,
  };
};

export const useGetUsers = (userType?: number, enabled?: boolean) => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data, refetch, ...rest } = useQuery(
    [
      userType === FundUserType.Client
        ? USER_MANAGEMENT_API.clientUserList.api
        : USER_MANAGEMENT_API.userList.api,
    ],
    () => getUsers(queryBody, userType),
    {
      enabled,
    },
  );

  useEffect(() => {
    enabled && refetch();
  }, [queryBody]);

  const setParams = (newParams: UserQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  const result = {
    items: data?.items,
    metadata: data?.metadata,
  };

  return {
    data: result,
    refetch,
    setParams,
    ...rest,
  };
};

export const useGetUserById = (userId: string) => {
  const { data, isLoading, refetch } = useQuery(
    [USER_MANAGEMENT_API.userById.api(userId)],
    () => getUserById(userId),
    {
      enabled: !!userId,
    },
  );

  return {
    data: data as User | undefined,
    isLoading,
    refetch,
  };
};

export const useCreateUser = () => {
  const handleCreateUser = async (data: IUserBody) => {
    return await createUser(data);
  };
  return useMutation(handleCreateUser);
};

export const useUpdateUser = () => {
  const handleUpdateUser = async (data: IUserBody) => {
    return await updateUser(data?.id || '', data);
  };
  return useMutation(handleUpdateUser);
};

export const useUpdateUserStatus = () => {
  const handleUpdateUserStatus = async (user: User) => {
    return await updateUserStatus(user);
  };
  return useMutation(handleUpdateUserStatus);
};

export const useResetUserPassword = () => {
  const handleResetUserPassword = async (userId: string) => {
    return await resetUserPassword(userId);
  };
  return useMutation(handleResetUserPassword);
};

export const useResendUserInvitation = () => {
  const resp = async (userId: string) => {
    return await resendUserInvitation(userId);
  };
  return useMutation(resp);
};

export const useResetUser2FA = () => {
  const handleResetUser2FA = async (userId: string) => {
    return await resetUser2FA(userId);
  };
  return useMutation(handleResetUser2FA);
};

export const useDeleteUser = () => {
  const handleDeleteUser = async (userId: string) => {
    return await deleteUser(userId);
  };
  return useMutation(handleDeleteUser);
};

export const useGetInvestorUserEntities = () => {
  const token = StorageService.getToken();
  const { isInvestor } = detectPortalType();

  const { data, isLoading, refetch } = useQuery<UserEntities>(
    [USER_MANAGEMENT_API.investorUserEntities.api],
    getInvestorUserEntities,
    {
      enabled: isInvestor && !!token,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useDelegateInvestmentEntity = (isInvestor: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [USER_MANAGEMENT_API.delegateInvestmentEntity.api],
    getDelegateInvestmentEntity,
    {
      enabled: isInvestor,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
