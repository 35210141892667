/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
  debounce,
} from '@mui/material';
import clsx from 'clsx';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CorporateIcon from 'src/assets/icons/CorporateIcon';
import IndividualIcon from 'src/assets/icons/IndividualIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  INVESTMENT_ENTITY_APPOINTER_TYPE,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE,
  abnAcnNotAllowedStatus,
  appointerAustralianCompanyDetails,
  australianCompanyDefaultValues,
  individualDefaultValue,
  trusteeNameRegexp,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { showToast } from 'src/helpers/toast';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, ISkyc } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import AustralianCompanyForm from '../shared/AustralianCompanyForm';
import IndividualDetail from '../shared/IndividualDetail';
import TrustTee from '../shared/TrustTee';
import TrustUbos from '../shared/TrustUbos';
import UploadCertified from '../shared/UploadCertified';

interface IUnregulatedTrust extends IInvestmentEntityProps {
  hideUBODeclaration?: boolean;
  enabledClearCorporateDetails?: boolean;
  disabledAbn?: boolean;
  isSuperAdmin?: boolean;
}

const appointerTypeOptions = [
  {
    value: INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate,
    label: 'Corporate',
    icon: <CorporateIcon />,
  },
  {
    value: INVESTMENT_ENTITY_APPOINTER_TYPE.Individual,
    label: 'Individual',
    icon: <IndividualIcon />,
  },
];

const UnregulatedTrust = (props: IUnregulatedTrust) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    isInvestmentEntitySetting,
    hideUbo,
    hideAbnSearch,
    isReviewing,
    hideUBODeclaration,
    enabledClearCorporateDetails,
    disabledAbn,
  } = props;
  const { countryOptions } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const [isShowFullCorporateFields, setIsShowFullCorporateFields] = useState(false);
  const clearAllCorporateDetailsConfirmModalRef = useRef<IBasicModalElement>(null);

  const { setValue, watch, setError, control, getFieldState, clearErrors } =
    useFormContext<IInvestmentEntityForm>();

  const watchTrustType = watch('investmentEntityDetails.unregulatedTrust.trustType');
  const watchIsSettlorMoreThanMax = watch(
    'investmentEntityDetails.unregulatedTrust.isSettlorMoreThanMax',
  );
  const watchHasAppointer = watch('investmentEntityDetails.unregulatedTrust.hasAppointer');
  const watchAppointerType = watch('investmentEntityDetails.unregulatedTrust.appointerType');
  const watchTrusteeType = watch('investmentEntityDetails.unregulatedTrust.trusteeType');
  const watchIsAppointerSameAsTrustee = watch(
    'investmentEntityDetails.unregulatedTrust.isAppointerSameAsTrustee',
  );
  const watchTrusteeAustralianCompanyDetailsDirectors =
    watch('investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors') ||
    [];
  const watchTrusteeAustralianCompanyDetailsUBOs =
    watch('investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs') || [];
  const watchTrusteeIndividuals =
    watch('investmentEntityDetails.unregulatedTrust.trusteeIndividuals') || [];
  const appointerIndividualsExistingList =
    watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate
      ? watchTrusteeAustralianCompanyDetailsDirectors.concat(
          watchTrusteeAustralianCompanyDetailsUBOs,
        )
      : watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual
      ? watchTrusteeIndividuals
      : [];
  const selectedExistingIndividuals =
    watch('investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals') || [];
  const watchAppointerAustralianCompanyDetailsDirectors =
    watch('investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.directors') ||
    [];
  const watchAppointerAustralianCompanyDetailsUBOs =
    watch('investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs') || [];

  const { error: appointerIndividualsError } = getFieldState(
    'investmentEntityDetails.unregulatedTrust.appointerIndividuals',
  );

  const {
    fields: appointerIndividuals,
    append: addAppointerIndividual,
    remove: removeAppointerIndividual,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.unregulatedTrust.appointerIndividuals',
    keyName: 'dataId',
  });

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Trust,
  });

  const handleSearchSkycInputChange = debounce(
    (_event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    const isEntityTypeMatch = handleCheckEntityTypeMatch(skyc as OptionType & ISkyc, 'trust');
    const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

    if (reason === 'selectOption') {
      if (isEntityTypeMatch) {
        setValue('investmentEntityDetails.unregulatedTrust.abn', skyc.value);
        setValue(
          'investmentEntityDetails.unregulatedTrust.name',
          skyc.label?.replaceAll(trusteeNameRegexp, ''),
        );
        if (isNotAllowed) {
          setError('investmentEntityDetails.unregulatedTrust.abn', {
            message: 'ABN is invalid',
            type: 'invalidAbn',
          });
        }
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const onAddAppointerIndividual = () => {
    addAppointerIndividual(individualDefaultValue(true));
  };

  const handleResetAppointerExisting = () => {
    setValue('investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals', []);
  };

  const handleSelectAppointerExistingIndividuals = (
    individual: (typeof appointerIndividualsExistingList)[0],
    isSelected: boolean,
  ) => {
    if (!individual.refId) return;
    if (isSelected) {
      const idx = selectedExistingIndividuals.findIndex((item) => item === individual.refId);
      if (idx > -1) {
        selectedExistingIndividuals.splice(idx, 1);
        setValue('investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals', [
          ...selectedExistingIndividuals,
        ]);
      }
    } else {
      selectedExistingIndividuals.push(individual.refId || '');
      setValue('investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals', [
        ...selectedExistingIndividuals,
      ]);
    }
  };

  const handleShowFullFieldsChange = (isShowFullFields: boolean) => {
    setIsShowFullCorporateFields(isShowFullFields);
  };

  const options = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  const trustTypeOptions = [
    {
      label: 'Family Trust',
      value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Family,
    },
    {
      label: 'Discretionary Trust',
      value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Discretionary,
    },
    {
      label: 'Charitable Trust',
      value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Charitable,
    },
    {
      label: 'Testamentary Trust',
      value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Testamentary,
    },
    {
      label: 'Unit Trust',
      value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Unit,
    },
    { label: 'Other', value: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Other },
  ];

  const renderMoreTrustUBOs = () => {
    return (
      <>
        {watchIsSettlorMoreThanMax && (
          <Grid item xs={12}>
            <IndividualDetail
              {...props}
              keyName={`investmentEntityDetails.unregulatedTrust.settlor`}
              disabledDetail
              disabledTaxInfo
              hideTfn
              hasPosition
              autocompleteAddress
              defaultExpanded={false}
              hideDeclaration={hideUBODeclaration}
            />
          </Grid>
        )}
        {watchHasAppointer &&
          watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
          ((watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
            watchIsAppointerSameAsTrustee === false) ||
            watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual) && (
            <>
              {watchAppointerAustralianCompanyDetailsDirectors.map(
                (it, index) =>
                  it.refId && (
                    <Grid key={it.refId} item xs={12}>
                      <IndividualDetail
                        {...props}
                        keyName={`investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.directors.${index}`}
                        disabledDetail
                        disabledTaxInfo
                        disabledPosition
                        hideTfn
                        hasPosition
                        autocompleteAddress
                        defaultExpanded={false}
                        hideDeclaration={hideUBODeclaration}
                      />
                    </Grid>
                  ),
              )}
              {watchAppointerAustralianCompanyDetailsUBOs.map(
                (it, index) =>
                  it.refId && (
                    <Grid key={it.refId} item xs={12}>
                      <IndividualDetail
                        key={it.refId}
                        {...props}
                        keyName={`investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs.${index}`}
                        disabledDetail
                        disabledTaxInfo
                        disabledPosition
                        hideTfn
                        hasPosition
                        autocompleteAddress
                        defaultExpanded={false}
                        hideDeclaration={hideUBODeclaration}
                      />
                    </Grid>
                  ),
              )}
            </>
          )}
        {watchHasAppointer &&
          watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Individual &&
          appointerIndividuals.map((individual, index) => (
            <Grid key={individual.dataId} item xs={12}>
              <IndividualDetail
                key={individual.dataId}
                {...props}
                keyName={`investmentEntityDetails.unregulatedTrust.appointerIndividuals.${index}`}
                disabledDetail
                disabledTaxInfo
                disabledPosition
                hideTfn
                hasPosition
                autocompleteAddress
                defaultExpanded={false}
                hideDeclaration={hideUBODeclaration}
              />
            </Grid>
          ))}
      </>
    );
  };

  const handleOpenClearAllModal = (isOpen?: boolean) => {
    if (isOpen || typeof isOpen !== 'boolean') {
      clearAllCorporateDetailsConfirmModalRef.current?.open();
    } else {
      clearAllCorporateDetailsConfirmModalRef.current?.close();
    }
  };

  const handleClearAllCorporateDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Individual) {
      setValue('investmentEntityDetails.unregulatedTrust.appointerIndividuals', [], {
        shouldDirty: true,
      });
    } else {
      setValue(
        `investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails`,
        australianCompanyDefaultValues,
        {
          shouldDirty: true,
        },
      );
      setValue(
        `investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.directors`,
        [],
        {
          shouldDirty: true,
        },
      );
      setValue(
        `investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs`,
        [],
        {
          shouldDirty: true,
        },
      );
    }

    handleOpenClearAllModal(false);
  };

  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UploadCertified
              {...props}
              containerSx={{ pt: 0 }}
              title='Upload Certified Trust Deed'
              keyName='investmentEntityDetails.unregulatedTrust'
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: 'neutral.ne200' }} />
          </Grid>
          {!isExistingInvestmentEntity && !isInvestmentEntitySetting && !hideAbnSearch && (
            <Grid item xs={12}>
              <FormAutocomplete
                name=''
                dataKey='id'
                options={options}
                searchIcon={<SearchIcon />}
                isLoading={isSearchSkycLoading}
                placeholder='Search by Name or ABN'
                hidePopupIcon
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box className='flex flex-col'>
                      <Typography variant='body3' fontWeight='medium'>
                        {option.label}
                      </Typography>
                      <Typography variant='caption1' color='neutral.ne500' paddingTop='4px'>
                        {`ABN: ${option.value}`}
                      </Typography>
                    </Box>
                  </li>
                )}
                renderTags={(selected) => (
                  <Typography variant='body2'>
                    <span className='line-clamp-1'>
                      {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
                    </span>
                  </Typography>
                )}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option.value || ''}
                onInputChange={handleSearchSkycInputChange}
                onChange={handleSkycChange}
                onFocus={() => clearErrors('investmentEntityDetails.unregulatedTrust.abn')}
                disabled={disabled}
                blurOnSelect
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormInput
              name='investmentEntityDetails.unregulatedTrust.name'
              label='Trust Name'
              placeholder='Enter trust name'
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name='investmentEntityDetails.unregulatedTrust.abn'
              label='ABN'
              placeholder='Enter ABN'
              maxLength={14}
              formatValue={(value) => formatValueWithSpaces(value)}
              disabled={disabled || disabledAbn}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name='investmentEntityDetails.unregulatedTrust.countryOfEstablishment'
              options={countryOptions}
              label='Country of Establishment'
              placeholder='Select country of establishment'
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name='investmentEntityDetails.unregulatedTrust.countryOfTaxResident'
              options={countryOptions}
              label='Country of Tax Residence'
              placeholder='Select country of tax residence'
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              name='investmentEntityDetails.unregulatedTrust.registeredOffice'
              label='Registered Office'
              placeholder='Enter registered office'
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name='investmentEntityDetails.unregulatedTrust.trustType'
              options={trustTypeOptions}
              label='Trust Type'
              placeholder='Select trust type'
              disabled={disabled}
            />
          </Grid>
          {watchTrustType === INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Other && (
            <Grid item xs={6}>
              <FormInput
                name='investmentEntityDetails.unregulatedTrust.otherTrustType'
                label='Other Type'
                placeholder='Enter other type'
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
        <TrustTee
          {...props}
          hideUbo={false}
          keyName='investmentEntityDetails.unregulatedTrust'
          handleChangeTrusteeType={handleResetAppointerExisting}
          onShowFullFieldsChange={handleShowFullFieldsChange}
        />
        {(isShowFullCorporateFields ||
          watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual) && (
          <>
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={600} variant='body3'>
                  Settlor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormRadioGroup
                  name='investmentEntityDetails.unregulatedTrust.isSettlorMoreThanMax'
                  options={TrueFalseRadioOptions}
                  label={
                    <Typography variant='body3' fontWeight={500}>
                      Is the settled sum more than $10,000?
                    </Typography>
                  }
                  isBooleanValue
                  disabled={disabled}
                  showPickerOnly={isReviewing}
                />
              </Grid>
              {watchIsSettlorMoreThanMax && (
                <Grid item xs={12}>
                  <IndividualDetail
                    {...props}
                    keyName='investmentEntityDetails.unregulatedTrust.settlor'
                    taxInfoLogicCheck
                    hideTfn
                    autocompleteAddress
                  />
                </Grid>
              )}
            </Grid>
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={600} variant='body3'>
                  Appointer
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormRadioGroup
                  name='investmentEntityDetails.unregulatedTrust.hasAppointer'
                  options={TrueFalseRadioOptions}
                  label={
                    <Typography variant='body3' fontWeight={500}>
                      Is there an appointer for the Trust?
                    </Typography>
                  }
                  isBooleanValue
                  disabled={disabled}
                  onChangeValue={handleResetAppointerExisting}
                  showPickerOnly={isReviewing}
                />
              </Grid>
              {watchHasAppointer && (
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    borderRadius='10px'
                    sx={{ borderColor: 'neutral.ne300', borderStyle: 'solid', borderWidth: 1 }}
                    p={3}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormRadioGroup
                          name='investmentEntityDetails.unregulatedTrust.appointerType'
                          options={appointerTypeOptions}
                          isEnumValue
                          disabled={disabled}
                          optionGap={4}
                          onChangeValue={handleResetAppointerExisting}
                          showPickerOnly={isReviewing}
                        />
                      </Grid>
                      {watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate && (
                        <>
                          {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate && (
                            <Grid item xs={12}>
                              <FormRadioGroup
                                name='investmentEntityDetails.unregulatedTrust.isAppointerSameAsTrustee'
                                options={TrueFalseRadioOptions}
                                label={
                                  <Typography variant='body3' fontWeight={500}>
                                    Is Appointer the same as Trustee?
                                  </Typography>
                                }
                                isBooleanValue
                                disabled={disabled}
                                showPickerOnly={isReviewing}
                                onChangeValue={(value) => {
                                  // Need to clear the data when the value is false
                                  !value &&
                                    setValue(
                                      'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails',
                                      appointerAustralianCompanyDetails,
                                    );
                                }}
                              />
                            </Grid>
                          )}
                          {((watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
                            watchIsAppointerSameAsTrustee === false) ||
                            watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual) && (
                            <Grid item xs={12}>
                              <Accordion
                                defaultExpanded
                                sx={(theme) => ({
                                  mt: '0 !important',
                                  '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
                                  borderWidth: '1px',
                                  borderBottom: `1px solid ${theme.palette.neutral.ne300} !important`,
                                  background: 'white',
                                })}
                              >
                                <AccordionSummary
                                  sx={{
                                    '.MuiSvgIcon-root': { fontSize: '18px' },
                                    '.MuiAccordionSummary-content': { my: 0 },
                                    minHeight: 'unset',
                                  }}
                                >
                                  <Box className='flex justify-between items-center w-full'>
                                    <Typography variant='body2' fontWeight={600}>
                                      Corporate Appointer Details
                                    </Typography>
                                    {enabledClearCorporateDetails && !disabled && (
                                      <CustomButton
                                        variant='text'
                                        sx={(theme) => ({
                                          px: 1,
                                          py: 1,
                                          color: theme.palette.primary.main,
                                        })}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenClearAllModal();
                                        }}
                                      >
                                        Clear All
                                      </CustomButton>
                                    )}
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                  className='p-6 mt-0 border-t'
                                  sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
                                >
                                  <AustralianCompanyForm
                                    {...props}
                                    hideUbo={false}
                                    keyName={
                                      'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails'
                                    }
                                    disabledManualEntry
                                    searchAbnFor='Appointer'
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          )}
                        </>
                      )}
                      {watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Individual && (
                        <Grid item xs={12}>
                          <Typography variant='body3' fontWeight={500}>
                            Select from existing
                          </Typography>

                          <Box
                            className='mt-4 p-5 rounded-[10px] max-h-[380px] overflow-y-auto border border-solid bg-white'
                            borderColor='neutral.ne300'
                          >
                            <Grid container spacing={2}>
                              {appointerIndividualsExistingList.map((item, index) => {
                                if (!item.refId) return;
                                const isSelected = selectedExistingIndividuals.includes(
                                  item.refId || '',
                                );
                                const disabledSelectExisting = disabled;

                                return (
                                  <Fragment key={`item-${index}`}>
                                    <Grid item xs={9} display='flex' alignItems='center'>
                                      <Typography variant='body3' fontWeight={500}>
                                        {`${item.firstName || ''} ${item.lastName || ''}${
                                          item.position && (item.firstName || item.lastName)
                                            ? ` - `
                                            : ''
                                        }${item.position || ''}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3} className='flex items-center justify-end'>
                                      <Box
                                        className={clsx('px-[9px] py-[5px] rounded-[4px]', {
                                          'cursor-pointer': !disabledSelectExisting,
                                          'cursor-default': disabledSelectExisting,
                                        })}
                                        bgcolor='neutral.ne200'
                                        onClick={() =>
                                          handleSelectAppointerExistingIndividuals(item, isSelected)
                                        }
                                      >
                                        <Typography
                                          color={
                                            disabledSelectExisting
                                              ? 'neutral.ne400'
                                              : 'primary.main'
                                          }
                                          variant='body3'
                                          fontWeight={500}
                                        >
                                          {isSelected ? 'Unselect' : 'Select'}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Fragment>
                                );
                              })}
                            </Grid>
                          </Box>
                          {!disabled && (
                            <Box className='flex items-center justify-between' mt={2}>
                              <CustomButton
                                size='small'
                                startIcon={<PlusPrimaryIcon />}
                                variant='text'
                                sx={{ px: 1, py: 0, ml: -1, color: 'primary' }}
                                onClick={onAddAppointerIndividual}
                              >
                                Add new
                              </CustomButton>

                              {enabledClearCorporateDetails && (
                                <CustomButton
                                  variant='text'
                                  sx={(theme) => ({
                                    px: 1,
                                    py: 0,
                                    color: theme.palette.primary.main,
                                  })}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenClearAllModal();
                                  }}
                                >
                                  Clear All
                                </CustomButton>
                              )}
                            </Box>
                          )}

                          {appointerIndividualsError && (
                            <Box mt={2}>
                              <CustomHelperText
                                variant='error'
                                message={appointerIndividualsError?.message}
                              />
                            </Box>
                          )}
                          {(!!appointerIndividuals.length ||
                            !!selectedExistingIndividuals.length) && (
                            <Box mt={3} className='flex flex-col gap-4'>
                              {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate && (
                                <>
                                  {watchTrusteeAustralianCompanyDetailsDirectors.map(
                                    (individual, index) =>
                                      individual.refId &&
                                      selectedExistingIndividuals.includes(individual.refId) && (
                                        <IndividualDetail
                                          key={individual.refId}
                                          {...props}
                                          keyName={`investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors.${index}`}
                                          disabledDetail
                                          disabledTaxInfo
                                          disabledPosition
                                          hideTfn
                                          hasPosition
                                          autocompleteAddress
                                          hideDeclaration={hideUBODeclaration}
                                        />
                                      ),
                                  )}
                                  {watchTrusteeAustralianCompanyDetailsUBOs.map(
                                    (individual, index) =>
                                      individual.refId &&
                                      selectedExistingIndividuals.includes(individual.refId) && (
                                        <IndividualDetail
                                          key={individual.refId}
                                          {...props}
                                          keyName={`investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.${index}`}
                                          disabledDetail
                                          disabledTaxInfo
                                          disabledPosition
                                          hideTfn
                                          hasPosition
                                          autocompleteAddress
                                          hideDeclaration={hideUBODeclaration}
                                        />
                                      ),
                                  )}
                                </>
                              )}
                              {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
                                watchTrusteeIndividuals.map(
                                  (individual, index) =>
                                    individual.refId &&
                                    selectedExistingIndividuals.includes(individual.refId) && (
                                      <IndividualDetail
                                        key={individual.refId}
                                        {...props}
                                        keyName={`investmentEntityDetails.unregulatedTrust.trusteeIndividuals.${index}`}
                                        disabledDetail
                                        disabledTaxInfo
                                        hideTfn
                                        hasPosition
                                        autocompleteAddress
                                        defaultExpanded={false}
                                      />
                                    ),
                                )}
                              {appointerIndividuals.map((individual, index) => (
                                <IndividualDetail
                                  key={individual.dataId}
                                  {...props}
                                  keyName={`investmentEntityDetails.unregulatedTrust.appointerIndividuals.${index}`}
                                  canDelete
                                  handleDelete={() => removeAppointerIndividual(index)}
                                  hideTfn
                                  hasPosition
                                  autocompleteAddress
                                  hideDeclaration={hideUBODeclaration}
                                />
                              ))}
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            {!hideUbo && (
              <Grid item xs={12}>
                <TrustUbos
                  {...props}
                  keyName='investmentEntityDetails.unregulatedTrust'
                  renderMoreTrustUBOs={renderMoreTrustUBOs}
                />
              </Grid>
            )}
          </>
        )}
      </Box>
      <ConfirmModal
        ref={clearAllCorporateDetailsConfirmModalRef}
        title='Clear all details?'
        content={`Are you sure you want to clear all ${
          watchAppointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate
            ? 'Corporate'
            : 'Individual'
        } Appointer details?<br />
        This action cannot be undone.`}
        PaperProps={{
          style: {
            width: 500,
          },
        }}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => clearAllCorporateDetailsConfirmModalRef?.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton color='error' onClick={handleClearAllCorporateDetails}>
              Clear All
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default UnregulatedTrust;
