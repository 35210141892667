/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, StandardTextFieldProps, styled, TextField, Typography } from '@mui/material';

import React, { useEffect, useMemo } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { ICountry } from 'src/interfaces/common';
import { useCountry } from 'src/modules/common/hooks';

import CustomHelperText from './CustomHelperText';
import { CustomMenuItem, CustomSelect } from './CustomSelect';

export interface IFormPhoneCountryInputProps extends StandardTextFieldProps {
  name: string;
  rules?: Record<string, unknown>;
  defaultMessage?: string;
  countryFlagCode?: string;
  countryFlagCodeName?: string;
  readOnly?: boolean;
  isIncludeCode?: boolean;
}

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
    height: 46,
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.neutral.ne200,
    '& fieldset': {
      borderColor: theme.palette.neutral.ne400,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& fieldset': {
      borderWidth: 1,
    },
  },
  '& textarea': {
    padding: 0,
  },
}));
const FormPhoneCountryInput: React.FC<IFormPhoneCountryInputProps> = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  defaultMessage = '',
  disabled,
  countryFlagCode = DEFAULT_COUNTRY,
  countryFlagCodeName = 'countryFlagCode',
  isIncludeCode = false,
  readOnly,
  ...rest
}) => {
  const { countries = [], parsePhoneNumber } = useCountry();
  const { control, setValue, register, watch } = useFormContext();
  const watchCountryFlagCodeName = watch(countryFlagCodeName) || countryFlagCode;

  useEffect(() => {
    !watchCountryFlagCodeName && setValue(countryFlagCodeName, countryFlagCode);
  }, [watchCountryFlagCodeName]);

  const {
    field: { ref, value: phoneValue, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const currentCountry = useMemo(
    () => countries?.find((item: ICountry) => item.code === watchCountryFlagCodeName),
    [countries, watchCountryFlagCodeName],
  );

  const countryNumbersFormat = countries.map((country: ICountry) => {
    return { name: country.name, value: country.code, label: country.phone_code };
  });

  const handleSelectCountry = (event: any) => {
    const countrySelected = countries?.find((item: ICountry) => item.code === event?.target?.value);
    countrySelected && setValue(countryFlagCodeName, countrySelected.code);
    onChange(
      isIncludeCode
        ? `${countrySelected.phone_code} ${parsePhoneNumber(phoneValue).contactNumber}`
        : phoneValue,
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode;
    const invalidKeyCodes = [187, 189, 69];

    if (invalidKeyCodes.includes(keyCode)) {
      event.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const valueWithCode = isIncludeCode ? `${currentCountry?.phone_code} ${value}` : value;
    onChange(valueWithCode);
  };

  const renderValue = () => {
    const { contactNumber } = parsePhoneNumber(phoneValue);
    return isIncludeCode ? contactNumber : phoneValue;
  };

  return (
    <Stack className='w-full' gap={0.5}>
      <Typography variant='body3' className='flex'>
        {label}
      </Typography>
      <Box className='flex'>
        <CustomSelect
          {...register(countryFlagCodeName)}
          MenuProps={{
            BackdropProps: {
              style: {
                backgroundColor: 'transparent',
              },
            },
          }}
          defaultValue=''
          value={watchCountryFlagCodeName}
          inputProps={{ readOnly }}
          sx={{ height: 46, width: 90, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          onChange={handleSelectCountry}
          renderValue={(value: any) => (
            <Typography color='neutral.ne800'>
              <span className={`fi fi-${value?.toLowerCase() as string}`} />
            </Typography>
          )}
          disabled={disabled}
        >
          {countryNumbersFormat?.map((item: any) => {
            return (
              <CustomMenuItem key={item.value} value={item.value}>
                <Box className='flex flex-row gap-2'>
                  <span className={`fi fi-${item.value.toLowerCase()}`} />
                  &nbsp; &nbsp;
                  <Typography variant='body3' className='ml-2'>
                    {item?.name}
                  </Typography>
                </Box>
              </CustomMenuItem>
            );
          })}
        </CustomSelect>
        <CustomTextField
          {...inputProps}
          {...rest}
          className='flex-1'
          disabled={disabled}
          type='number'
          onWheel={(e: any) => e.target?.blur?.()}
          inputRef={ref}
          onKeyDown={handleKeyDown}
          value={renderValue()}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <Typography
                variant='body2'
                color='neutral.ne400'
                className='min-w-fit mr-2 text-center mt-[1px]'
              >
                {currentCountry?.phone_code}
              </Typography>
            ),
          }}
          inputProps={{ readOnly }}
          error={invalid}
        />
      </Box>
      {invalid && (
        <Box className='ml-2'>
          <CustomHelperText
            variant={invalid ? 'error' : 'default'}
            message={invalid ? (error as FieldError)?.message : defaultMessage}
          />
        </Box>
      )}
    </Stack>
  );
};

export default FormPhoneCountryInput;
