/* eslint-disable no-restricted-imports */
import { SUBSCRIPTION_BILLING_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { BaseQuery } from '../common/hooks';
import { BillingPlan, PricingDetail, SubscriptionFeatures } from './types';

export const getSubscriptionFeatures = async (): Promise<SubscriptionFeatures[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getSubscriptionFeatures.api,
  });

  return result.data;
};

export const getStandardPricingLatest = async (): Promise<PricingDetail> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getStandardPricingLatest.api,
  });

  return result.data;
};

export const getStandardPricingDetail = async (id: string): Promise<PricingDetail> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getStandardPricingDetail.api(id),
  });

  return result.data;
};

export const getStandardPricingDetailByDate = async (date: string): Promise<PricingDetail> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getStandardPricingDetailByDate.api(date),
  });

  return result.data;
};

export const getStandardPricingHistory = async (params?: BaseQuery): Promise<PricingDetail[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getStandardPricingHistory.api,
    params,
  });

  return result.data;
};

export const updateStandardPricing = async (data?: PricingDetail): Promise<void> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: SUBSCRIPTION_BILLING_API.updateStandardPricing.api,
    data,
  });

  return result.data;
};

export const updateStandardPricingHistory = async (
  id: string,
  data?: PricingDetail,
): Promise<void> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: SUBSCRIPTION_BILLING_API.updateStandardPricingHistory.api(id),
    data,
  });

  return result.data;
};

export const deleteStandardPricing = async (id: string): Promise<void> => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: SUBSCRIPTION_BILLING_API.deleteStandardPricing.api(id),
  });

  return result.data;
};

export const getBillingPlans = async (
  clientId: string,
  params?: BaseQuery,
): Promise<BillingPlan[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.getBillingPlans.api(clientId),
    params,
  });

  return result.data;
};

export const getBillingPlanDetail = async (
  clientId: string,
  billingPlanId: string,
): Promise<BillingPlan> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: SUBSCRIPTION_BILLING_API.billingPlanDetail.api(clientId, billingPlanId),
  });

  return result.data;
};

export const createBillingPlan = async (
  clientId: string,
  data: BillingPlan,
): Promise<BillingPlan> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: SUBSCRIPTION_BILLING_API.createBillingPlan.api(clientId),
    data,
  });

  return result.data;
};

export const updateBillingPlan = async (
  clientId: string,
  billingPlanId: string,
  data: BillingPlan,
): Promise<BillingPlan> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: SUBSCRIPTION_BILLING_API.billingPlanDetail.api(clientId, billingPlanId),
    data,
  });

  return result.data;
};

export const deleteBillingPlan = async (
  clientId: string,
  billingPlanId: string,
): Promise<BillingPlan> => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: SUBSCRIPTION_BILLING_API.billingPlanDetail.api(clientId, billingPlanId),
  });

  return result.data;
};
