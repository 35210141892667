import { DEFAULT_COUNTRY, actionPermissionKeys } from './common';

export const administratorDefaultValuesItem = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phoneNumber: '',
  id: '',
};

export const organisationDetailsDefaultValues = {
  legalName: '',
  abn: '',
  physicalAddress: {
    country: {
      value: '',
      label: '',
      id: '',
    },
    state: {
      value: '',
      label: '',
      id: '',
    },
    city: {
      value: '',
      label: '',
      id: '',
    },
    postcode: '',
    streetAddress: '',
    manualEntry: false,
  },
  postalAddress: {
    country: {
      value: '',
      label: '',
      id: '',
    },
    state: {
      value: '',
      label: '',
      id: '',
    },
    city: {
      value: '',
      label: '',
      id: '',
    },
    postcode: '',
    streetAddress: '',
    manualEntry: false,
  },
  capacityIds: [],
  hasOtherCapacity: false,
  otherCapacity: '',
  clientName: '',
  tenantId: '',
  isUseSamePhysicalAddress: false,
  isSaveDraft: false,
  sectionId: '',
};

export const contactDetailsDefaultValues = {
  clientAdministrators: [
    {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '',
      isPrimary: true,
      isSendEmail: true,
      countryFlagCode: DEFAULT_COUNTRY,
    },
  ],
  isSaveDraft: false,
};

export const onboardingContactDefaultValues = {
  assignees: [],
  isSaveDraft: false,
  isSendEmailForAssignee: true,
};

export const subscriptionBillingDefaultValues = {
  isSaveDraft: false,
  subscriptionFeatureIds: {},
  billings: null,
};

export const clientTeamDefaultValues = {
  isSaveDraft: false,
  users: [],
};

export const CLIENT_STATUS = {
  Pending: {
    id: 0,
    name: 'Pending',
  },
  Active: {
    id: 1,
    name: 'Active',
  },
  Disabled: {
    id: 2,
    name: 'Disabled',
  },
  Draft: {
    id: 3,
    name: 'Draft',
  },
};

export const CLIENT_STATUSNAME = {
  Pending: 'Pending',
  Active: 'Active',
  Disabled: 'Disabled',
  Draft: 'Draft',
};

export const CLIENT_ACTION = {
  edit: {
    key: 'edit',
    label: 'Edit',
    permissionKey: actionPermissionKeys.isEdit,
  },
  impersonate: {
    key: 'impersonate',
    label: 'Remote Access',
    skipPermission: true,
  },
  disable: {
    key: 'disable',
    label: 'Disable',
  },
  delete: {
    key: 'delete',
    label: 'Delete',
    permissionKey: actionPermissionKeys.isDelete,
  },
  view: {
    key: 'view',
    label: 'View',
    permissionKey: actionPermissionKeys.isView,
  },
  enable: {
    key: 'enable',
    label: 'Enable',
  },
  resendEmail: {
    key: 'resendEmail',
    label: 'Resend Email',
  },
  activate: {
    key: 'activate',
    label: 'Activate',
    skipPermission: true,
  },
};

export const createClienTeamUserDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  position: '',
  roleId: '',
  isSendMail: true,
  fundIds: [],
  isAllFund: false,
};

export const MAX_FUND_CLIENT_TEAM_USERS = 20;
export const MAX_FUND_ADMIN_ADDITIONAL_CONTACT = 10;

export const CLIENT_TEAM_USER_ACTION = {
  edit: {
    key: 'edit',
    label: 'Edit',
  },
  disable: {
    key: 'disable',
    label: 'Disable',
  },
  delete: {
    key: 'delete',
    label: 'Delete',
  },
  view: {
    key: 'view',
    label: 'View',
  },
  enable: {
    key: 'enable',
    label: 'Enable',
  },
  resendEmail: {
    key: 'resendEmail',
    label: 'Resend Email',
  },
  resetPassword: {
    key: 'resetPassword',
    label: 'Reset Password',
  },
  reset2FA: {
    key: 'reset2FA',
    label: 'Reset 2FA',
  },
};

export enum ClientInvoiceType {
  Manual,
  Monthly,
}

export const invoiceStatus = {
  Draft: 'Draft',
  Submitted: 'Submitted',
  Deleted: 'Deleted',
  Authorised: 'Authorised',
  Paid: 'Paid',
  Voided: 'Voided',
};
