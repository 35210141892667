import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import _, { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomBarChart, { barOptions } from 'src/components/atoms/CustomBarChart';
import CustomCollapsibleTable from 'src/components/atoms/CustomCollapsibleTable';
import { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomPieChart from 'src/components/atoms/CustomPieChart';
import { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import AmoutBox from 'src/components/pages/dashboard/components/AmoutBox';
import ChartContainer from 'src/components/pages/dashboard/components/ChartContainer';
import InvestmentEntityDetail from 'src/components/pages/dashboard/components/InvestmentEntityDetail';
import TableContainer from 'src/components/pages/dashboard/components/TableContainer';
import {
  useGetDashboardAllocations,
  useGetDashboardDetails,
  useGetDashboardSummary,
} from 'src/modules/dashboard/hooks';
import {
  IAllocation,
  IDetailItem,
  IFundDetail,
  IUpcomingCapitallCall,
} from 'src/modules/dashboard/type';
import { InvestorViewModeContext } from 'src/providers/InvestorViewModeProvider';
import { formatNumberWithCommas } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

interface InvestorDashboardProps {}

interface IInvestmentEntityTableData extends IFundDetail {}

interface IUpcomingCapitallCallTableData extends IUpcomingCapitallCall {}

const InvestorDashboard: React.FC<InvestorDashboardProps> = () => {
  const [selectedEntityId, setSelectedEntityId] = useState('');

  const { investmentEntities, viewMode } = useContext(InvestorViewModeContext);
  const { data: allocationsData = [] } = useGetDashboardAllocations();
  const { data: summaryData = {} } = useGetDashboardSummary(selectedEntityId);
  const { data: detailsData = {} } = useGetDashboardDetails(selectedEntityId);
  const { summary: summaryValue, investmentSummary = [] } = summaryData;

  // Reset data when change overarching filter
  useEffect(() => {
    setSelectedEntityId('');
  }, [investmentEntities, viewMode]);

  const handleClickChart = (id: string) => {
    setSelectedEntityId(id);
  };

  const pieChartLabels = allocationsData
    .sort(
      (a: { percentOfNetValue: number }, b: { percentOfNetValue: number }) =>
        b.percentOfNetValue - a.percentOfNetValue,
    )
    .map((item: IAllocation) => item.investmentEntity);

  const pieChartData = allocationsData.map((item: IAllocation) => ({
    id: item.investmentEntityId,
    nested: { value: item.percentOfNetValue.toFixed() },
  }));

  const labels = _.map(investmentSummary, 'fund');
  const paidAmountData = _.map(investmentSummary, 'paidAmount');
  const unpaidAmountData = _.map(investmentSummary, 'unpaidAmount');
  const currentNetValueData = _.map(investmentSummary, 'currentNetValue');

  const barData = {
    labels,
    datasets: [
      {
        label: 'Paid Amount',
        data: paidAmountData,
        backgroundColor: '#33B27F',
        stack: 'Stack 0',
      },
      {
        label: 'Unpaid Amount',
        data: unpaidAmountData,
        backgroundColor: '#E3F4EC',
        stack: 'Stack 0',
      },
      {
        label: 'Current Net Value',
        data: currentNetValueData,
        backgroundColor: '#00965F',
        stack: 'Stack 1',
      },
    ],
  };

  const investmentEntityColumns: ColumnProps<IInvestmentEntityTableData, 'action'>[] = [
    {
      title: 'Fund',
      key: 'fund',
      sx: {
        height: 70,
      },
    },
    {
      title: 'Current Net Value',
      key: 'currentNetValue',
      sx: {
        height: 70,
      },
      renderNode: (row) => (
        <StringNodeTable value={`${formatNumberWithCommas(row.currentNetValue)} AUD`} />
      ),
    },
    {
      title: 'Details',
      key: 'fundId',
      allowCollapse: true,
      sx: {
        height: 70,
      },
      collapseContent: (row) => <InvestmentEntityDetail detail={row} />,
    },
  ];

  const upcomingCapitallCall: ColumnProps<IUpcomingCapitallCallTableData, 'action'>[] = [
    {
      title: 'Fund',
      key: 'fund',
      sortBy: 'fund',
      sorter: true,
      sx: {
        height: 70,
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      sortBy: 'amount',
      sorter: true,
      sx: {
        height: 70,
      },
      renderNode: (row) => <StringNodeTable value={`${formatNumberWithCommas(row.amount)} AUD`} />,
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      sortBy: 'dueDate',
      sorter: true,
      sx: {
        height: 70,
      },
      renderNode: (row) => <StringNodeTable value={utcToLocalTimezone(row.dueDate)} />,
    },
  ];

  const { summary, isShowEmpty } = useMemo(() => {
    let _summary = summaryValue,
      _isShowEmpty = false;
    if (investmentEntities === MULTIPLE_SELECT_EMPTY) {
      _summary = {};
      _isShowEmpty = true;
    }
    return { summary: _summary, isShowEmpty: _isShowEmpty };
  }, [investmentEntities, summaryValue]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <Grid xs={3} item>
            <AmoutBox title='Number Of Investments' amount={summary?.numberOfInvestment} />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox
              title='Invested Amount'
              amount={`$ ${formatNumberWithCommas(summary?.investedAmount, 2)}`}
            />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox
              title='Unpaid Amount'
              amount={`$ ${formatNumberWithCommas(summary?.unpaidAmount, 2)}`}
            />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox
              title='Current Net Value'
              amount={`$ ${formatNumberWithCommas(summary?.currentNetValue, 2)}`}
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <ChartContainer title='Investment Allocation'>
            {isEmpty(pieChartData) || isShowEmpty ? (
              <EmptyData isTable />
            ) : (
              <CustomPieChart
                labels={pieChartLabels}
                data={pieChartData}
                onClick={(id) => handleClickChart(id)}
              />
            )}
          </ChartContainer>
        </Grid>
        <Grid item xs={7}>
          <ChartContainer title='Investment Summary'>
            {isEmpty(barData) || isShowEmpty ? (
              <EmptyData isTable />
            ) : (
              <Box height='80%' width='100%'>
                <CustomBarChart
                  data={barData}
                  options={{
                    scales: {
                      ...barOptions.scales,
                      y: {
                        ...barOptions.scales.y,
                        ticks: {
                          callback: function (value: any) {
                            return '$' + formatNumberWithCommas(value);
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            )}
          </ChartContainer>
        </Grid>
        {!isShowEmpty &&
          (detailsData?.investmentDetails || []).map((item: IDetailItem) => {
            return (
              <Grid
                item
                xs={12}
                container
                spacing={3}
                key={`investment-entity-${item.investmentEntityId}`}
              >
                <Grid xs={6} item>
                  <TableContainer title={item.investmentEntityName}>
                    <CustomCollapsibleTable
                      tableClassName='min-w-[400px] h-full'
                      columns={investmentEntityColumns}
                      rows={item.funds}
                    />
                  </TableContainer>
                </Grid>
                <Grid xs={6} item>
                  <TableContainer title='Upcoming Capital Call'>
                    <CustomCollapsibleTable
                      tableClassName={clsx(
                        'min-w-[400px]',
                        item.upcomingCapitalCalls.length ? 'h-auto' : 'h-full',
                      )}
                      columns={upcomingCapitallCall}
                      rows={item.upcomingCapitalCalls}
                      displayEmpty
                      emptyDescription='No Upcoming Capital Call'
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default InvestorDashboard;
