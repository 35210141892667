import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, FC, useContext, useState } from 'react';
import CustomButton from 'src/components/atoms/CustomButton';
import SearchField from 'src/components/atoms/SearchField';
import HorizontalTabs, { ITab } from 'src/components/molecules/HorizontalTabs';
import { useClearAllNotification } from 'src/modules/notifications/hooks';
import { NotificationContext } from 'src/providers/NotificationContextProvider';
import { handleErrorFromServer } from 'src/utils/common';
import NotificationList from './components/NotificationList';

interface INotificationsProps {}

const Notifications: FC<INotificationsProps> = (props) => {
  const [search, setSearch] = useState('');
  const { mutate: clearAllNotification, isLoading: clearingAll } = useClearAllNotification();
  const {
    notifications,
    setQueryNotificationParams,
    total,
    setNotifications,
    setTotal,
    setTotalUnread,
  } = useContext(NotificationContext);

  const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setQueryNotificationParams({ search: event.target.value });
  }, 300);

  const handleClearAll = () => {
    clearAllNotification('', {
      onSuccess: () => {
        setNotifications([]);
        setTotal(0);
        setTotalUnread(0);
      },
      onError: handleErrorFromServer,
    });
  };

  const Tabs: ITab[] = [
    {
      label: (
        <p className='flex items-center gap-1.5'>
          <span>All</span>
          <Typography
            className='inline-block rounded-[4px] w-5 h-5'
            bgcolor='secondary.se100'
            variant='caption1'
          >
            {notifications?.length ? total : 0}
          </Typography>
        </p>
      ),
      content: <NotificationList notifications={notifications} />,
    },
  ];

  return (
    <Box className='w-full py-6'>
      <Box className='header px-6'>
        <Box className='flex items-center justify-between mb-4'>
          <Typography variant='subtitle4' fontWeight={500}>
            Notifications
          </Typography>
          <CustomButton
            variant='text'
            sx={{ p: 0.5 }}
            onClick={handleClearAll}
            disabled={!notifications?.length}
          >
            <Typography variant='body3'>Clear All</Typography>
          </CustomButton>
        </Box>
        <SearchField
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
            handleSearch(e);
          }}
          placeholder='Search'
        />
      </Box>
      <Box className='body mt-3'>
        <HorizontalTabs
          sx={{
            '& .MuiTab-root': {
              maxWidth: 'fit-content',
            },
            '.MuiTabs-flexContainer': {
              justifyContent: 'flex-start',
              px: 3,
            },
            '.tabpanel-wrapper': {
              p: 0,
              pt: 3,
            },
          }}
          tabs={Tabs}
          destroyInactivePanel={false}
        />
      </Box>
    </Box>
  );
};

export default Notifications;
