/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports*/
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement, IStep } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import ContactDetailsForm from 'src/components/pages/client-management/components/ContactDetailsForm';
import OnboardingContactForm from 'src/components/pages/client-management/components/OnboardingContactForm';
import OrganisationForm from 'src/components/pages/client-management/components/OrganisationForm';
import Branding, {
  BrandingHandler,
} from 'src/components/pages/client-management/components/funds/Branding';
import ClientTeam, {
  IUserArrayFormField,
} from 'src/components/pages/client-management/components/funds/ClientTeam';
import {
  CLIENT_STATUS,
  contactDetailsDefaultValues,
  onboardingContactDefaultValues,
  organisationDetailsDefaultValues,
} from 'src/constants/client-management';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import {
  useFundSubmitBranding,
  useFundSubmitClient,
  useFundSubmitClientTeam,
  useFundSubmitContactnDetails,
  useFundSubmitOrganisationDetails,
  useFundSubmitOurTeam,
  useGetClientDetail,
} from 'src/modules/client/hooks';
import {
  IAssigneesResponse,
  IClientContactDetailFormFields,
  IClientTeamUser,
  IFundOurTeam,
  IFundSubmitClientTeamBody,
  IOrganisationDetailsFormData,
} from 'src/modules/client/type';
import { useCountry } from 'src/modules/common/hooks';
import { BrandingParams } from 'src/modules/organisation-settings/type';
import { BrandingDefault } from 'src/providers/BrandingContextProvider';
import { handleErrorFromServer, removeSpacesInString } from 'src/utils/common';
import { formatPhoneNumber } from 'src/utils/phone';
import {
  administratorsValidationSchema,
  clientTeamValidationSchema,
  organisationDetailsValidationSchema,
  ourTeamValidationSchema,
} from 'src/validations/client-management';

interface IFundsCreateUpdateClientFormProps {
  handleOpenCreateClientModal: (isOpen: boolean) => void;
  id: string;
  isEditMode: boolean;
  isViewMode: boolean;
}

export interface IModalInfo {
  title: string;
  description: string;
}

export const SUBMIT_MODE = {
  Draft: 'draft',
  Submit: 'submit',
};

interface BrandingFormFields extends BrandingParams {
  isSaveDraft?: boolean;
}
interface ISubmitSectionOptions {
  mode: string;
  isAsync?: boolean;
  nextStep?: boolean;
}

export enum FUND_UPSERT_CLIENT_STEP {
  ORGANISATION_DETAILS,
  CONTACT_DETAILS,
  OUR_TEAM,
  CLIENT_TEAM,
  BRANDING,
  SUBSCRIPTION_BILLING,
  AMLPOLICY,
}

type IFormsState = Omit<
  {
    [key in FUND_UPSERT_CLIENT_STEP]: {
      form?: UseFormReturn<any>;
      isDirty?: boolean;
      isValid?: boolean;
    };
  },
  | FUND_UPSERT_CLIENT_STEP.BRANDING
  | FUND_UPSERT_CLIENT_STEP.SUBSCRIPTION_BILLING
  | FUND_UPSERT_CLIENT_STEP.AMLPOLICY
>;

const FundsCreateUpdateClientForm: FC<IFundsCreateUpdateClientFormProps> = ({
  handleOpenCreateClientModal,
  id,
  isEditMode,
  isViewMode,
}) => {
  const navigate = useNavigateWithParams();
  const stepperRef = useRef<ICustomStepperElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [titleAlert, setTitleAlert] = useState('');
  const [descriptionAlert, setDescriptionAlert] = useState('');
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [clientId, setClientId] = useState(id);

  const brandingFormRef = useRef<BrandingHandler>(null);
  const {
    mutate: fundSubmitOrganisationMutate,
    mutateAsync: fundSubmitOrganisationMutateAsync,
    isLoading: isFundSubmitOrganisationLoading,
  } = useFundSubmitOrganisationDetails();
  const {
    mutate: fundSubmitContactDetailsMutate,
    mutateAsync: fundSubmitContactDetailsMutateAsync,
    isLoading: isFundSubmitContactDetailsLoading,
  } = useFundSubmitContactnDetails();
  const {
    mutate: fundSubmitOurTeamMutate,
    mutateAsync: fundSubmitOurTeamMutateAsync,
    isLoading: isFundSubmitOurTeamLoading,
  } = useFundSubmitOurTeam();
  const {
    mutate: fundSubmitClientTeamMutate,
    mutateAsync: fundSubmitClientTeamMutateAsync,
    isLoading: isFundSubmitClientTeamLoading,
  } = useFundSubmitClientTeam();

  const {
    mutate: fundSubmitBrandingMutate,
    mutateAsync: fundSubmitBrandingMutateAsync,
    isLoading: isFundSubmitBrandingLoading,
  } = useFundSubmitBranding();
  const {
    mutate: fundSubmitClientMutate,
    mutateAsync: fundSubmitClientMutateAsync,
    isLoading: isFundSubmitClientLoading,
  } = useFundSubmitClient();
  const { data: clientDetails, refetch: refetchClientDetails } = useGetClientDetail(clientId, 0);
  const { getPhoneCodeByCountryCode } = useCountry();

  const isLoading =
    isFundSubmitOrganisationLoading ||
    isFundSubmitContactDetailsLoading ||
    isFundSubmitOurTeamLoading ||
    isFundSubmitClientTeamLoading ||
    isFundSubmitBrandingLoading ||
    isFundSubmitClientLoading;

  const organisationDetailsForm = useForm<IOrganisationDetailsFormData>({
    defaultValues: organisationDetailsDefaultValues,
    resolver: yupResolver(organisationDetailsValidationSchema),
  });

  const contactDetailsForm = useForm<IClientContactDetailFormFields>({
    defaultValues: contactDetailsDefaultValues,
    resolver: yupResolver(administratorsValidationSchema),
  });

  const ourTeamForm = useForm({
    defaultValues: onboardingContactDefaultValues,
    resolver: yupResolver(ourTeamValidationSchema),
  });

  const clientTeamForm = useForm<IUserArrayFormField>({
    defaultValues: {
      users: [],
      isSaveDraft: false,
      isDirty: false,
    },
    resolver: yupResolver(clientTeamValidationSchema),
  });

  const brandingForm = useForm<BrandingFormFields>({
    defaultValues: BrandingDefault,
  });

  const watchClientName = organisationDetailsForm.watch('clientName');

  const formsState: IFormsState = {
    [FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS]: {
      isDirty: organisationDetailsForm.formState.isDirty,
      isValid: organisationDetailsForm.formState.isValid,
      form: organisationDetailsForm,
    },
    [FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS]: {
      isDirty: contactDetailsForm.formState.isDirty,
      isValid: contactDetailsForm.formState.isValid,
      form: contactDetailsForm,
    },
    [FUND_UPSERT_CLIENT_STEP.OUR_TEAM]: {
      isDirty: ourTeamForm.formState.isDirty,
      isValid: ourTeamForm.formState.isValid,
      form: ourTeamForm,
    },
    [FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM]: {
      isDirty: clientTeamForm.formState.isDirty,
      isValid: clientTeamForm.formState.isValid,
      form: clientTeamForm,
    },
  };

  const { clientStatus, isDraftStatus } = useMemo(() => {
    return {
      clientStatus: clientDetails?.status,
      isDraftStatus: clientDetails?.status === CLIENT_STATUS.Draft.id,
    };
  }, [clientDetails]);

  useEffect(() => {
    if (clientTeamForm.formState.errors.users) {
      handleShowClienTeamErrorMessage();
    }
  }, [clientTeamForm.formState.errors.users]);

  const handleShowClienTeamErrorMessage = () => {
    setIsErrorAlert(true);
    setDescriptionAlert(clientTeamForm.formState.errors.users?.message || '');
    modalAlertRef.current?.open();
  };

  const handleShowModal = ({ title, description }: IModalInfo) => {
    setTitleAlert(title);
    setDescriptionAlert(description);
    modalAlertRef.current?.open();
  };

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const getFundOrganisationBody = (data: IOrganisationDetailsFormData, mode?: string) => {
    if (isEmpty(data)) return {};
    const cityName =
      typeof data.physicalAddress.city === 'string'
        ? data.physicalAddress.city
        : String(get(data, 'physicalAddress.city.label', ''));

    const postalCityName =
      typeof data.postalAddress.city === 'string'
        ? data.postalAddress.city
        : String(get(data, 'postalAddress.city.label', ''));
    const cityId = String(get(data, 'physicalAddress.city.id', ''));
    const postalCityId = String(get(data, 'postalAddress.city.id', ''));

    const body: any = {
      legalName: data.legalName,
      abn: removeSpacesInString(data.abn),
      countryName: get(data, 'physicalAddress.country.label', ''),
      countryId: String(get(data, 'physicalAddress.country.id', '')),
      stateName: get(data, 'physicalAddress.state.label', ''),
      stateId: String(get(data, 'physicalAddress.state.id', '')),
      cityName,
      postcode: data.physicalAddress?.postcode,
      streetAddress: data.physicalAddress?.streetAddress,
      otherCapacity: data.otherCapacity,
      postalCountryName: get(data, 'postalAddress.country.label', ''),
      postalCountryId: String(get(data, 'postalAddress.country.id', '')),
      postalStateName: get(data, 'postalAddress.state.label', ''),
      postalStateId: String(get(data, 'postalAddress.state.id', '')),
      postalCityName,
      postalPostcode: data.postalAddress.postcode,
      postalStreetAddress: data.postalAddress.streetAddress,
      capacityIds: data.capacityIds.filter((item: string) => item !== 'other'),
      clientName: data.clientName,
      isUseSamePhysicalAddress: data.isUseSamePhysicalAddress,
      manualEntry: get(data, 'physicalAddress.manualEntry'),
      postalManualEntry: get(data, 'postalAddress.manualEntry'),
      isSaveDraft: mode === SUBMIT_MODE.Draft,
    };

    if (cityId) body.cityId = cityId;
    if (postalCityId) body.postalCityId = postalCityId;
    if (clientId) {
      body.clientId = clientId;
    }
    if (data?.sectionId) body.id = data?.sectionId;

    return body;
  };

  const getFundContactDetailBody = (data: IClientContactDetailFormFields, mode?: string) => {
    const contactDetails = (data?.clientAdministrators || []).map((item: any) => {
      const phoneNumber = formatPhoneNumber(
        getPhoneCodeByCountryCode(item?.countryFlagCode),
        item?.phoneNumber,
      );
      return {
        firstName: item?.firstName || '',
        lastName: item?.lastName || '',
        email: item?.email || '',
        isPrimary: item?.isPrimary,
        countryFlagCode: item?.countryFlagCode || '',
        position: item?.position || '',
        phoneNumber,
      };
    });

    const body: any = {
      contactDetails,
      clientId,
      isSaveDraft: mode === SUBMIT_MODE.Draft,
    };

    if (clientDetails?.id) body.id = clientDetails.id;

    return body;
  };

  const getFundOurTeamBody = (data: any, mode?: string) => {
    const body: any = {
      userIds: (data?.assignees || []).map((item: IAssigneesResponse | IFundOurTeam) => item.id),
      isSendEmail: data?.isSendEmailForAssignee,
      isSaveDraft: mode === SUBMIT_MODE.Draft,
    };

    if (isEmpty(data?.assignees)) body.isSendEmail = false;
    if (clientId) body.clientId = clientId;
    if (clientDetails?.id) body.id = clientDetails.id;

    return body;
  };

  const getFundClientTeamBody = (data: IUserArrayFormField, mode?: string) => {
    const users = data?.users || [];
    const body: IFundSubmitClientTeamBody = {
      users: users.map(({ userId, isSendMail, roleId }: IClientTeamUser) => ({
        userId,
        isSendMail,
        roleId,
      })),
      clientId,
      isSaveDraft: mode === SUBMIT_MODE.Draft,
    };

    if (clientDetails?.id) body.id = clientDetails.id;

    return body;
  };

  const handleSubmitSectionSuccess = (mode: string) => {
    switch (mode) {
      case SUBMIT_MODE.Draft:
        handleShowModal({
          title: 'Draft saved!',
          description: 'You can come back and resume at any time.',
        });
        break;
      default:
        handleGoToNextStep();
    }
  };

  const handleFundSubmitOrganisation = (options: ISubmitSectionOptions) => {
    const { isAsync, mode, nextStep } = options;
    const organisationFormData = organisationDetailsForm.getValues();
    const data = getFundOrganisationBody(organisationFormData, mode);

    if (isAsync) return fundSubmitOrganisationMutateAsync(data, { onError: handleErrorFromServer });

    if (nextStep) {
      handleGoToNextStep();
    } else if (isEditMode && !isDraftStatus) {
      handleFundSubmitAllSection();
    } else {
      fundSubmitOrganisationMutate(data, {
        onSuccess: (id: string) => {
          if (!clientId) setClientId(id);
          handleSubmitSectionSuccess(mode);
        },
        onError: handleErrorFromServer,
      });
    }
  };

  const isDuplicateEmails = (emails: string[]) => {
    return new Set(emails).size !== emails.length;
  };

  const handleFundSubmitContactDetails = (options: ISubmitSectionOptions) => {
    const { isAsync, mode, nextStep } = options;
    const contactDetailFormData = contactDetailsForm.getValues();
    const data = getFundContactDetailBody(contactDetailFormData, mode);
    const contactDetailEmails: string[] = data?.contactDetails?.map((item: any) => item?.email);
    const isDuplicateEmail = isDuplicateEmails(contactDetailEmails);

    if (isAsync) return fundSubmitContactDetailsMutateAsync(data);

    if (isDuplicateEmail) {
      setIsErrorAlert(true);
      setDescriptionAlert('Email address cannot be the same.');
      modalAlertRef.current?.open();
    } else if (nextStep) {
      handleGoToNextStep();
    } else if (isEditMode && !isDraftStatus) {
      handleFundSubmitAllSection();
    } else {
      fundSubmitContactDetailsMutate(data, {
        onSuccess: () => handleSubmitSectionSuccess(mode),
        onError: handleErrorFromServer,
      });
    }
  };

  const handleFundSubmitOurTeam = (options: ISubmitSectionOptions) => {
    const { isAsync, mode, nextStep } = options;
    const ourTeamFormData = ourTeamForm.getValues();
    const data = getFundOurTeamBody(ourTeamFormData, mode);

    if (isAsync) return fundSubmitOurTeamMutateAsync(data);

    if (nextStep) {
      handleGoToNextStep();
    } else if (isEditMode && !isDraftStatus) {
      handleFundSubmitAllSection();
    } else {
      fundSubmitOurTeamMutate(data, {
        onSuccess: () => handleSubmitSectionSuccess(mode),
        onError: handleErrorFromServer,
      });
    }
  };

  const handleFundSubmitClientTeam = (options: ISubmitSectionOptions) => {
    const { isAsync, mode, nextStep } = options;
    const clientTeamFormData = clientTeamForm.getValues();
    const data = getFundClientTeamBody(clientTeamFormData, mode);

    if (isAsync) return fundSubmitClientTeamMutateAsync(data);

    if (nextStep) {
      handleGoToNextStep();
    } else if (isEditMode && !isDraftStatus) {
      handleFundSubmitAllSection();
    } else {
      fundSubmitClientTeamMutate(data, {
        onSuccess: () => handleSubmitSectionSuccess(mode),
        onError: handleErrorFromServer,
      });
    }
  };

  const handleFundSubmitBranding = (options: ISubmitSectionOptions) => {
    const { isAsync, mode, nextStep } = options;
    const data = brandingForm.getValues();
    const body = { ...data, clientId, isSaveDraft: mode === SUBMIT_MODE.Draft };

    if (isAsync) return fundSubmitBrandingMutateAsync(body);

    if (nextStep) {
      handleGoToNextStep();
    } else if (isEditMode && !isDraftStatus) {
      handleFundSubmitAllSection();
    } else {
      fundSubmitBrandingMutate(body, {
        onSuccess: () => {
          if (mode === SUBMIT_MODE.Draft) {
            handleSubmitSectionSuccess(SUBMIT_MODE.Draft);
          } else {
            handleFundSubmitClient();
          }
        },
        onError: handleErrorFromServer,
      });
    }
  };

  const handleFundSubmitClient = (isAsync?: boolean) => {
    const mutateOptions = {
      onSuccess: () => {
        handleShowModal({
          title: 'You did it!',
          description: isEditMode
            ? `Client ${watchClientName} has been updated successfully.`
            : `Client ${watchClientName} has been created.`,
        });
      },
      onError: handleErrorFromServer,
    };

    if (isAsync) return fundSubmitClientMutateAsync(clientId, mutateOptions);
    fundSubmitClientMutate(clientId, mutateOptions);
  };

  const handleFundSubmitAllSection = () => {
    const formSections = [
      {
        key: FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
        canSubmit: formsState[FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS].isDirty,
        mutate: () => handleFundSubmitOrganisation({ mode: SUBMIT_MODE.Submit, isAsync: true }),
      },
      {
        key: FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
        canSubmit: formsState[FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS].isDirty,
        mutate: () => handleFundSubmitContactDetails({ mode: SUBMIT_MODE.Submit, isAsync: true }),
      },
      {
        key: FUND_UPSERT_CLIENT_STEP.OUR_TEAM,
        canSubmit: formsState[FUND_UPSERT_CLIENT_STEP.OUR_TEAM].isDirty,
        mutate: () => handleFundSubmitOurTeam({ mode: SUBMIT_MODE.Submit, isAsync: true }),
      },
      {
        key: FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM,
        canSubmit:
          formsState[FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM].isDirty ||
          clientTeamForm.getValues('isDirty'),
        mutate: () => handleFundSubmitClientTeam({ mode: SUBMIT_MODE.Submit, isAsync: true }),
      },
      {
        key: FUND_UPSERT_CLIENT_STEP.BRANDING,
        canSubmit: true,
        mutate: () => handleFundSubmitBranding({ mode: SUBMIT_MODE.Submit, isAsync: true }),
      },
    ];

    const canSubmitForms = formSections.filter((item) => item.canSubmit);

    if (isEmpty(canSubmitForms)) {
      handleFundSubmitClient();
    } else {
      Promise.all([...canSubmitForms.map((item) => item.mutate())])
        .then(() => {
          handleFundSubmitClient();
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const handleSubmitSection = (sectionKey: number) => {
    if (isViewMode) handleGoToNextStep();
    else
      switch (sectionKey) {
        case FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS:
          organisationDetailsForm.setValue('isSaveDraft', false);
          organisationDetailsForm.handleSubmit(() =>
            handleFundSubmitOrganisation({ mode: SUBMIT_MODE.Submit }),
          )();
          break;
        case FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS:
          contactDetailsForm.setValue('isSaveDraft', false);
          contactDetailsForm.handleSubmit(() =>
            handleFundSubmitContactDetails({ mode: SUBMIT_MODE.Submit }),
          )();
          break;
        case FUND_UPSERT_CLIENT_STEP.OUR_TEAM:
          ourTeamForm.setValue('isSaveDraft', false);
          ourTeamForm.handleSubmit(() => handleFundSubmitOurTeam({ mode: SUBMIT_MODE.Submit }))();
          break;
        case FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM:
          clientTeamForm.setValue('isSaveDraft', false);
          clientTeamForm.handleSubmit(() =>
            handleFundSubmitClientTeam({ mode: SUBMIT_MODE.Submit }),
          )();
          break;
        case FUND_UPSERT_CLIENT_STEP.BRANDING:
          clientTeamForm.setValue('isSaveDraft', false);
          brandingForm.handleSubmit(() => handleFundSubmitBranding({ mode: SUBMIT_MODE.Submit }))();
          break;
      }
  };

  const handleSaveSection = (sectionKey: number) => {
    const isSaveDraft = !isEditMode || isDraftStatus;
    const submitModeType: string = isSaveDraft ? SUBMIT_MODE.Draft : SUBMIT_MODE.Submit;

    switch (sectionKey) {
      case FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS:
        organisationDetailsForm.setValue('isSaveDraft', isSaveDraft);
        organisationDetailsForm.handleSubmit(() =>
          handleFundSubmitOrganisation({ mode: submitModeType, nextStep: !isSaveDraft }),
        )();
        break;
      case FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS:
        contactDetailsForm.setValue('isSaveDraft', isSaveDraft);
        contactDetailsForm.handleSubmit(() =>
          handleFundSubmitContactDetails({ mode: submitModeType, nextStep: !isSaveDraft }),
        )();
        break;
      case FUND_UPSERT_CLIENT_STEP.OUR_TEAM:
        ourTeamForm.setValue('isSaveDraft', isSaveDraft);
        ourTeamForm.handleSubmit(() =>
          handleFundSubmitOurTeam({ mode: submitModeType, nextStep: !isSaveDraft }),
        )();
        break;
      case FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM:
        clientTeamForm.setValue('isSaveDraft', isSaveDraft);
        clientTeamForm.handleSubmit(() =>
          handleFundSubmitClientTeam({ mode: submitModeType, nextStep: !isSaveDraft }),
        )();
        break;
      case FUND_UPSERT_CLIENT_STEP.BRANDING:
        brandingForm.setValue('isSaveDraft', isSaveDraft);
        brandingForm.handleSubmit(() =>
          handleFundSubmitBranding({ mode: submitModeType, nextStep: !isSaveDraft }),
        )();
        break;
    }
  };

  const handleCloseModal = () => {
    handleOpenCreateClientModal(false);
  };

  const handleSetErrorSteps = (step: number) => {
    const errorSteps: number[] = [];
    Object.entries(formsState).forEach(([key, { isValid, form }]) => {
      if (!isValid) {
        errorSteps.push(Number(key));
      }
      if (step === Number(key) && step !== FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM) {
        form?.trigger();
      }
    });

    setErrorSteps(errorSteps);
  };

  const getDefaultStepProps = (step: number) => ({
    isLoading,
    isEditMode,
    isViewMode,
    isDraft: isDraftStatus,
    onSubmit: () => {
      if (stepperRef.current?.getCurrentStep() === step) {
        handleSubmitSection(step);
      }
    },
    onSave: () => handleSaveSection(step),
  });

  const STEPS: IStep[] = [
    {
      key: FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
      label: 'Organisation Details',
      content: (
        <FormProvider {...organisationDetailsForm}>
          <FormSection
            title='Organisation Details'
            {...getDefaultStepProps(FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS)}
          >
            <OrganisationForm
              {...{
                clientStatus,
                isEditMode,
                isViewMode,
                clientDetails,
                clientId,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
      label: 'Contact Details',
      content: (
        <FormProvider {...contactDetailsForm}>
          <FormSection
            title='Contact Details'
            {...getDefaultStepProps(FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS)}
          >
            <ContactDetailsForm
              {...{
                clientStatus,
                isEditMode,
                isViewMode,
                clientId,
                clientDetails,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: FUND_UPSERT_CLIENT_STEP.OUR_TEAM,
      label: 'Our Team',
      content: (
        <FormProvider {...ourTeamForm}>
          <FormSection title='Our Team' {...getDefaultStepProps(FUND_UPSERT_CLIENT_STEP.OUR_TEAM)}>
            <OnboardingContactForm
              {...{
                clientStatus,
                isViewMode,
                clientId,
                clientDetails,
                handleGoToNextStep,
                handleShowModal,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM,
      label: 'Client Team',
      content: (
        <FormProvider {...clientTeamForm}>
          <FormSection
            title='Client Team'
            description='Please create the client team members.'
            fullWidth
            {...getDefaultStepProps(FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM)}
          >
            <ClientTeam
              {...{
                clientId,
                handleShowModal,
                handleGoToNextStep,
                clientDetails,
                isViewMode,
                clientStatus,
                refetchClientDetails,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: FUND_UPSERT_CLIENT_STEP.BRANDING,
      label: 'Branding',
      content: (
        <FormProvider {...brandingForm}>
          <FormSection
            title='Branding'
            fullWidth
            isLastStep
            {...getDefaultStepProps(FUND_UPSERT_CLIENT_STEP.BRANDING)}
          >
            <Branding ref={brandingFormRef} {...{ clientDetails, isViewMode, clientId }} />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {isViewMode ? 'View Client' : isEditMode ? 'Edit Client' : 'Create New Client'}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 5 }}
            onClickStep={handleSetErrorSteps}
            errorSteps={errorSteps}
            enableClickStep={(isEditMode && !isDraftStatus) || isViewMode}
            destroyInactiveStep={false}
          />
        </Box>
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          isError={isErrorAlert}
          title={titleAlert}
          description={descriptionAlert}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              modalAlertRef?.current?.close();
              handleCloseModal();
              setIsErrorAlert(false);
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default FundsCreateUpdateClientForm;
