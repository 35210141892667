import { ADMINISTRATION_INVESTOR_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { ITeam, ITeamContact } from 'src/modules/applications/type';
import { InvestmentEntityQueryBody } from 'src/modules/investment-entity/types';
import {
  ICreateInvestorDetail,
  IGenerateStatement,
  IInvestmentDetailForm,
  IInvestorDetailsForm,
  IIssueForm,
  IOffmarketTransfer,
  IPreviewStatement,
  IRecordPayment,
  IRedemptionForm,
  InvestorQueryBody,
} from './types';

export const getInvestorList = async (data: InvestorQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.getInvestorList.api,
  });

  return result.data;
};

export const archiveInvestor = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: ADMINISTRATION_INVESTOR_API.archiveInvestor.api(id),
  });

  return result.data;
};

export const unarchiveInvestor = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: ADMINISTRATION_INVESTOR_API.unarchiveInvestor.api(id),
  });

  return result.data;
};

export const ceaseInvestor = async (id: string, reason: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: { reason },
    url: ADMINISTRATION_INVESTOR_API.ceaseInvestor.api(id),
  });

  return result.data;
};

export const unceaseInvestor = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: ADMINISTRATION_INVESTOR_API.unceaseInvestor.api(id),
  });

  return result.data;
};

export const createInvestorDetail = async (data: ICreateInvestorDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.createInvestorDetail.api,
  });

  return result.data;
};

export const upsertInvestorDetail = async (id: string, data: IInvestorDetailsForm) => {
  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.upsertInvestorDetail.api(id),
  });

  return result.data;
};

export const upsertInvestorInvestmentDetail = async (id: string, data: IInvestmentDetailForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: ADMINISTRATION_INVESTOR_API.upsertInvestorInvestmentDetail.api(id),
  });

  return result.data;
};

export const getInvestorFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorFiltered.api,
  });

  return result.data;
};

export const getInvestorById = async (id: string, step: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { step },
    url: ADMINISTRATION_INVESTOR_API.getInvestorDetail.api(id),
  });

  return result.data;
};

export const getInvestorFunds = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getFunds.api,
  });

  return result.data;
};

export const getInvestorMasterData = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorMasterData.api,
  });

  return result.data;
};

export const getInvestorUnitClassesByFund = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getUnitClassesByFund.api(fundId),
  });

  return result.data;
};

export const getUnitClassesByMultiFunds = async (fundIds: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { fundIds },
    url: ADMINISTRATION_INVESTOR_API.getUnitClassesByMultiFunds.api,
  });

  return result.data;
};

export const getInvestorEntities = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorEntities.api,
  });

  return result.data;
};

export const getInvestorPoppulateData = async (
  fundId: string,
  unitClassId: string,
  investmentEntityId: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorPoppulateData.api(
      fundId,
      unitClassId,
      investmentEntityId,
    ),
  });

  return result.data;
};

export const archiveInvestmentEntity = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: ADMINISTRATION_INVESTOR_API.archiveInvestmentEntity.api(id),
  });

  return result.data;
};

export const unarchiveInvestmentEntity = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: ADMINISTRATION_INVESTOR_API.unarchiveInvestmentEntity.api(id),
  });

  return result.data;
};

export const getInvestmentEntityRoles = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestmentEntityRoles.api,
  });

  return result.data;
};

export const upsertInvestorTeamContact = async (id: string, data: ITeamContact) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: ADMINISTRATION_INVESTOR_API.upsertInvestorTeamContact.api(id),
  });
  id;

  return result.data;
};

export const deleteInvestorTeamContact = async (contactId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: ADMINISTRATION_INVESTOR_API.deleteInvestorTeamContact.api(contactId),
  });

  return result.data;
};

export const upsertInvestorTeam = async (id: string, data: ITeam) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: ADMINISTRATION_INVESTOR_API.upsertInvestorTeam.api(id),
  });

  return result.data;
};

export const createInvestor = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: ADMINISTRATION_INVESTOR_API.createInvestor.api(id),
  });

  return result.data;
};

export const getInvestorDetailIssue = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorDetailIssue.api(id),
  });

  return result.data;
};

export const createInvestorIssue = async (id: string, data: IIssueForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.createInvestorIssue.api(id),
  });

  return result.data;
};

export const getInvestorDetailRedeem = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.getInvestorDetailRedeem.api(id),
  });

  return result.data;
};

export const createInvestorRedeem = async (id: string, data: IRedemptionForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.createInvestorRedeem.api(id),
  });
  return result.data;
};

export const createOffmarketTransfer = async (id: string, data: IOffmarketTransfer) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.offmarketTransfer.api(id),
  });

  return result.data;
};

export const getOffmarketTransfer = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.offmarketTransfer.api(id),
  });

  return result.data;
};

export const createRecordPayment = async (id: string, data: IRecordPayment) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.recordPayment.api(id),
  });

  return result.data;
};

export const getRecordPayment = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ADMINISTRATION_INVESTOR_API.recordPayment.api(id),
  });

  return result.data;
};

export const previewStatement = async (id: string, data: IPreviewStatement) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    responseType: 'blob',
    data,
    url: ADMINISTRATION_INVESTOR_API.previewStatement.api(id),
  });

  return result.data;
};

export const generateStatement = async (id: string, data: IGenerateStatement) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.generateStatement.api(id),
  });

  return result.data;
};

export const deleteInvestor = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: ADMINISTRATION_INVESTOR_API.deleteInvestor.api(id),
  });

  return result.data;
};

export const updateCurrentUnitPrice = async (unitClassId: string, data: { unitPrice: number }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: ADMINISTRATION_INVESTOR_API.updateCurrentUnitPrice.api(unitClassId),
  });

  return result.data;
};

export const investorExportCSV = async (data: InvestorQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.investorExportCSV.api,
  });

  return result.data;
};

export const investmentEntityExportCSV = async (data: InvestmentEntityQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.investmentEntityExportCSV.api,
  });

  return result.data;
};

export const exportInvestorProfileCSV = async (data: InvestorQueryBody): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ADMINISTRATION_INVESTOR_API.exportInvestorProfileCSV.api,
  });

  return result.data;
};

export const downloadInvestorProfileCSV = async (documentId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    responseType: 'arraybuffer',
    url: ADMINISTRATION_INVESTOR_API.downloadInvestorProfileCSV.api(documentId),
  });

  return result.data;
};
