/* eslint-disable no-restricted-imports */
import { APPLICATION_API, KYC_BACK_OFFICE_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { detectPortalType } from 'src/helpers/common';
import { IIndividualDetail } from '../applications/type';
import {
  ICompletedExternally,
  INote,
  IRequestMoreInformation,
  IReview,
  IReviewAdditional,
  ISubmitBilling,
  KYCBackOfficeQueryBody,
} from './type';

export const getKYCBackOfficeList = async (data: KYCBackOfficeQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeList.api,
  });

  return result.data;
};

export const getKYCBackOfficeFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeFilter.api,
  });

  return result.data;
};

export const getKYCBackOfficeById = async (id: string, step: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      step,
    },
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id),
  });

  return result.data;
};

export const getKYCBackOfficeAssignees = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeAssignees.api,
  });

  return result.data;
};

export const getKYCBackOfficeAssigneesById = async (kycId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeAssigneesById.api(kycId),
  });
  return result.data;
};

export const openKycBackOffice = async (kycId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.openKycBackOffice.api(kycId),
  });
  return result.data;
};

export const closeKycBackOffice = async (kycId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.closeKycBackOffice.api(kycId),
  });
  return result.data;
};

export const kycBackOfficeAssign = async (kycId: string, memberId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.kycBackOfficeAssign.api(kycId),
    data: { memberId },
  });
  return result.data;
};

export const manuallyOverrideKyc = async (id: string, uboId: string, mannualReason: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.manuallyOverrideKyc.api(id, uboId),
    data: { mannualReason },
  });
  return result.data;
};

export const manuallyOverrideAml = async (id: string, uboId: string, mannualReason: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.manuallyOverrideAml.api(id, uboId),
    data: { mannualReason },
  });
  return result.data;
};

export const addNewUbo = async (id: string, data: IIndividualDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: KYC_BACK_OFFICE_API.addNewUbo.api(id),
    data,
  });
  return result.data;
};

export const removeUbo = async (id: string, uboId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: KYC_BACK_OFFICE_API.removeUbo.api(id, uboId),
  });
  return result.data;
};

export const kycBackOfficeReviewSubmit = async (kycId: string, data: IReview) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.kycBackOfficeReviewSubmit.api(kycId),
    data,
  });
  return result.data;
};

export const kycBackOfficeReviewSubmitAdditionalComment = async (
  kycId: string,
  data: IReviewAdditional,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.kycBackOfficeReviewSubmitAdditionalComment.api(kycId),
    data,
  });
  return result.data;
};

export const kycBackOfficeCompletedExternally = async (
  kycId: string,
  data: ICompletedExternally,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: KYC_BACK_OFFICE_API.kycBackOfficeCompletedExternally.api(kycId),
    data,
  });
  return result.data;
};

export const kycBackOfficeNote = async (kycId: string, data: INote) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: KYC_BACK_OFFICE_API.kycBackOfficeNote.api(kycId),
    data,
  });
  return result.data;
};

export const getKYCBackOfficeNote = async (kycId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getKYCBackOfficeNote.api(kycId),
  });

  return result.data;
};

export const kycRequestMoreInformation = async (
  applicationId: string,
  data: IRequestMoreInformation,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: KYC_BACK_OFFICE_API.kycRequestMoreInformation.api(applicationId),
    data,
  });
  return result.data;
};

export const downloadApplicationForm = async (kycId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.downloadApplicationForm.api(kycId),
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const getKYCBackOfficeMasterData = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getMasterData.api,
  });
  return result.data;
};

export const submitBilling = async (kycId: string, data: ISubmitBilling) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: KYC_BACK_OFFICE_API.billingSubmit.api(kycId),
    data,
  });
  return result.data;
};

export const billingCloseCase = async (kycId: string, data: ISubmitBilling) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: KYC_BACK_OFFICE_API.billingCloseCase.api(kycId),
    data,
  });
  return result.data;
};

export const billingNoCharge = async (kycId: string, isClosed: boolean) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: { isClosed },
    url: KYC_BACK_OFFICE_API.billingNoCharge.api(kycId),
  });
  return result.data;
};

export const getInvestorDetailAuditTrails = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.investorDetailAuditTrails.api(id),
  });

  return result.data;
};

export const getUboAuditTrails = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.uboAuditTrails.api(id),
  });

  return result.data;
};

export const getGapKycBoTaxDetails = async (appId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: KYC_BACK_OFFICE_API.getTaxDetails.api(appId),
  });

  return result.data;
};

export const getKycBoSkycApp = async (skycAppId: string) => {
  const { isFunds } = detectPortalType();
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: isFunds
      ? APPLICATION_API.skycGetApp.api(skycAppId)
      : KYC_BACK_OFFICE_API.getKycBoSkycApp.api(skycAppId),
  });

  return result.data;
};
