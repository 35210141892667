import { MenuItem, Select, styled } from '@mui/material';
import clsx from 'clsx';
import { SVGProps } from 'react';
import { ChevronDownIcon } from 'src/assets/icons/ChevronDownIcon';

export const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: 14,
    height: 18,
  },
  '&.MuiOutlinedInput-root.Mui-focused': {
    '& fieldset': {
      borderWidth: 1,
    },
    '& .arrow-icon': {
      transform: 'rotate(180deg)',
      color: theme.palette.primary.main,
    },
  },
  '& .arrow-icon': {
    color: theme.palette.neutral.ne300,
  },
  '&.MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.neutral.ne200,
    '& fieldset': {
      borderColor: theme.palette.neutral.ne300,
    },
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.base.white,
  padding: '9px 8px',
  fontSize: 16,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.palette.primary.pr100,
  },
}));

export const CustomSingleSelectIcon = (props: SVGProps<SVGSVGElement>) => {
  const isExpand = props.className?.includes('MuiSelect-iconOpen');
  return (
    <ChevronDownIcon
      {...props}
      color='#212121'
      className={clsx('absolute right-[7px]', {
        'rotate-180': isExpand,
      })}
    />
  );
};
