/* eslint-disable no-prototype-builtins */
import { saveAs } from 'file-saver';
import { get, isEmpty, isNumber } from 'lodash';
import { numericFormatter } from 'react-number-format';
import { showToast } from 'src/helpers/toast';
import { IErrorResponse } from 'src/interfaces/common';

const NUMBER_REGEX = /^\d+$/;

export const formatHiddenPhone = (phone: string) => {
  const phoneCode = phone.split(' ');
  const splicedLastString = phone.slice(-3);
  const formattedNumber = `${phoneCode?.[0]} xxx xxx ${splicedLastString}`;

  return formattedNumber;
};

export const calculateRange = (
  currentPage: number,
  totalResults: number,
  resultsPerPage: number,
) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  if (totalPages === 1) {
    return {
      from: '01',
      to: totalResults.toString().padStart(2, '0'),
      totalResults: totalResults.toString().padStart(2, '0'),
    };
  }

  const from = (currentPage * resultsPerPage + 1).toString().padStart(2, '0');
  let to = ((currentPage + 1) * resultsPerPage).toString().padStart(2, '0');
  if (Number(to) > totalResults) {
    to = totalResults.toString().padStart(2, '0');
  }

  return {
    from,
    to,
    totalResults: totalResults.toString().padStart(2, '0'),
  };
};

export const removeEmptyProps = (obj: any) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === '' ||
      newObj[key] === null ||
      newObj[key] === undefined ||
      (Array.isArray(newObj[key]) && newObj[key].length === 0)
    ) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Content copied to clipboard');
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const swapArrayElements = (array: any, index1: number, index2: number) => {
  if (isEmpty(array)) return [];
  const newArray = [...array];
  newArray[index1] = newArray.splice(index2, 1, newArray[index1])[0];

  return newArray;
};

const splitEveryNFromTheEnd = (str: string, n: number) => {
  const arr = [];
  let newString = str;
  for (let i = newString.length; i >= 0; i -= n) {
    arr.unshift(newString.slice(-n));
    newString = newString.slice(0, i - n);
  }
  return arr.filter((item) => item);
};

export const formatValueWithSpaces = (value: string, spaceBetweenNumber?: number) => {
  if (!value) return '';
  const stringValue = value.split(' ').join('');
  if (!NUMBER_REGEX.test(stringValue) && !spaceBetweenNumber) {
    return stringValue
      .split('')
      .filter((character) => NUMBER_REGEX.test(character))
      .join('');
  }
  const splitArray = splitEveryNFromTheEnd(stringValue, spaceBetweenNumber || 3);
  return splitArray.join(' ');
};

export const deepCheckEmptyObject = (object: any): boolean => {
  if (isEmpty(object)) return true;
  return isEmpty(
    Object.entries(object)
      .map(([, value]) => {
        if (isEmpty(value)) return true;
        if (value instanceof Object) return deepCheckEmptyObject(value);
        return false;
      })
      .filter((item) => item === false),
  );
};

export const serializeObject = (object: any, keepArray?: boolean) => {
  const newObject: any = {};
  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (keepArray) {
        newObject[key] = value;
      } else {
        newObject[key] = value.map((item: any) => {
          if (Array.isArray(item) || item instanceof Object) {
            return serializeObject(item);
          }
          return item;
        });
      }
    } else if (value instanceof Object) {
      newObject[key] = serializeObject(value);
    } else if (!!value || typeof value === 'boolean' || typeof value === 'number') {
      newObject[key] = value;
    }
  });
  return newObject;
};

export const removeSpacesInString = (value?: string) => {
  if (!value) return;
  return value.split(' ')?.join('');
};

export const exportCSV = (data: string, filename?: string) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  data.split('\n').forEach(function (row) {
    csvContent += row + '\n';
  });

  const encodedUri = encodeURI(csvContent);
  downloadFile(encodedUri, filename ? `${filename}.csv` : 'data.csv');
};

export const getErrorMessageFromServer = (error: unknown | IErrorResponse) => {
  const errorResponse = error as IErrorResponse;
  const { errorMessage, ErrorMessage } = errorResponse?.data || {};
  if (ErrorMessage && typeof ErrorMessage === 'string') {
    return ErrorMessage;
  } else if (errorMessage && typeof errorMessage === 'string') {
    return errorMessage;
  } else {
    return '';
  }
};

export const handleErrorFromServer = (error: unknown | IErrorResponse) => {
  const message = getErrorMessageFromServer(error);
  showToast(message, 'error');
};

export const currencyStringToNumber = (currency: string | number) => {
  if (typeof currency !== 'string') return currency;
  //eslint-disable-next-line
  return Number(currency.replace(/\,/g, ''));
};

export const formatNumberWithCommas = (number?: number, decimalScale?: number) => {
  if (isNumber(number)) {
    return number
      .toFixed(decimalScale)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
};

export const downloadFileFromArrayBuffer = (
  data: ArrayBuffer,
  fileName: string,
  isGettingFileUrl?: boolean,
) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  if (isGettingFileUrl) return url;
  return downloadFile(url, fileName);
};

export const downloadFile = (hrefAtr: string, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', hrefAtr);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFileWithSavingPreview = async (href: string) => {
  try {
    const fileName = href?.split('/')?.pop()?.split('#')[0].split('?')[0];
    const response = await fetch(href);

    if (!response.ok) {
      throw new Error('Can not download this file');
    }

    const blob = await response.blob();
    /**
     * Using saveAs to open the save as popup on first download time
     * Make it works on Safari
     */
    saveAs(blob, fileName);
  } catch (error) {
    throw new Error('Can not download this file');
  }
};

export const htmlToPlainText = (html: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.innerText;
};

export function isHexColor(color: string): boolean {
  return !!color && color.includes('#');
}

export const getObjectValueByKey = (obj: any, key: string) => {
  const keyParts = key.split('.');
  let result = obj;

  for (const part of keyParts) {
    if (result.hasOwnProperty(part)) {
      result = result[part];
    } else {
      result = undefined;
      break;
    }
  }
  return result;
};

export const formatAddressFormData = (address: any) => {
  const physicalAddress = {
    country: {
      value: get(address, 'countryId', ''),
      label: get(address, 'countryName', ''),
      id: get(address, 'countryId', ''),
    },
    state: {
      value: get(address, 'stateId', ''),
      label: get(address, 'stateName', ''),
      id: get(address, 'stateId', ''),
    },
    city: {
      value: get(address, 'cityId', ''),
      label: get(address, 'cityName', ''),
      id: get(address, 'cityId', ''),
    },
    postcode: get(address, 'postcode', ''),
    streetAddress: get(address, 'streetAddress', ''),
    manualEntry: get(address, 'manualEntry', false),
  };

  const postalAddress = {
    country: {
      value: get(address, 'postalCountryId', ''),
      label: get(address, 'postalCountryName', ''),
      id: get(address, 'postalCountryId', ''),
    },
    state: {
      value: get(address, 'postalStateId', ''),
      label: get(address, 'postalStateName', ''),
      id: get(address, 'postalStateId', ''),
    },
    city: {
      value: get(address, 'postalCityId', ''),
      label: get(address, 'postalCityName', ''),
      id: get(address, 'postalCityId', ''),
    },
    postcode: get(address, 'postalPostcode', ''),
    streetAddress: get(address, 'postalStreetAddress', ''),
    manualEntry: get(address, 'postalManualEntry', false),
  };

  if (physicalAddress.manualEntry) {
    physicalAddress.city = address?.cityName || '';
  }

  if (postalAddress?.manualEntry) {
    postalAddress.city = address?.postalCityName || '';
  }

  return {
    postalAddress,
    physicalAddress,
  };
};

export const formatAddressBodyData = (
  { physicalAddress, postalAddress }: any,
  isUseSameAsPhysicalAddress: boolean,
) => {
  const cityName =
    typeof physicalAddress?.city === 'string'
      ? physicalAddress.city
      : String(get(physicalAddress, 'city.label', ''));

  const postalCityName =
    typeof postalAddress?.city === 'string'
      ? postalAddress.city
      : String(get(postalAddress, 'city.label', ''));

  const address = {
    countryName: get(physicalAddress, 'country.label', ''),
    countryId: String(get(physicalAddress, 'country.id', '')),
    stateName: get(physicalAddress, 'state.label', ''),
    stateId: String(get(physicalAddress, 'state.id', '')),
    cityId: String(get(physicalAddress, 'city.id', '')),
    cityName,
    postcode: physicalAddress.postcode,
    streetAddress: physicalAddress.streetAddress,
    postalCountryName: get(postalAddress, 'country.label', ''),
    postalCountryId: String(get(postalAddress, 'country.id', '')),
    postalStateName: get(postalAddress, 'state.label', ''),
    postalStateId: String(get(postalAddress, 'state.id', '')),
    postalCityName,
    postalCityId: String(get(postalAddress, 'city.id', '')),
    postalPostcode: postalAddress.postcode,
    postalStreetAddress: postalAddress.streetAddress,
    manualEntry: physicalAddress.manualEntry,
    postalManualEntry: postalAddress.manualEntry,
    isUseSameAsPhysicalAddress,
  };

  return address;
};

const convertArrayObjectForFormData = (object: any, rootKey = '') => {
  const result: any = {};

  const handle = (object: any, rootKey = '') => {
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === 'object') {
        handle(value, Number.isInteger(Number(key)) ? `${rootKey}[${key}]` : `${rootKey}.${key}`);
      } else {
        result[Number.isInteger(Number(key)) ? `${rootKey}[${key}]` : `${rootKey}.${key}`] = value;
      }
    });
  };

  handle(object, rootKey);

  return result;
};

export const convertObjToFormData = (obj: any) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && !(value instanceof File)) {
      const objectForFormData = convertArrayObjectForFormData(value, key);
      Object.entries(objectForFormData).forEach(([key, value]) => {
        formData.append(key, value as any);
      });
    } else {
      formData.append(key, value as any);
    }
  });

  return formData;
};

export const generateArrayQueryString = (key: string, array: string[]) => {
  return array.reduce((prev, currentItem, index) => {
    let result = prev;
    if (!index) {
      result = result + `${key}=${currentItem}`;
    } else {
      result = result + `&${key}=${currentItem}`;
    }
    return result;
  }, '');
};

export const roundNumber = (number: number, decimalScale?: number) => {
  /**
   * Use toLocaleString instead of toFixed to handle the rounding of up to 0.5 decimal places
   * Example: a = 3.995, rounding 2 decimal places, expected output: 4.00
   * - a.toFixed(2) => 3.99 (wrong)
   */
  const roundedValue = number?.toLocaleString(undefined, {
    maximumFractionDigits: decimalScale || 0,
    minimumFractionDigits: decimalScale || 0,
  });

  return currencyStringToNumber(roundedValue);
};

export const formatCurrencyNumber = (value?: number | string, decimalScale?: number) => {
  if (!value && !Number.isInteger(value)) return 0;

  return numericFormatter(String(roundNumber(Number(value), decimalScale)), {
    thousandSeparator: true,
    decimalScale: decimalScale || 0,
    fixedDecimalScale: !!decimalScale,
  });
};

export const sortByAlphabet = (array: any[], path?: string) => {
  if (!array?.length) return array;
  if (typeof array[0] === 'string') return array.sort((a, b) => a?.localeCompare(b));

  return array.sort((a, b) =>
    get(a, path || '')
      ?.trim()
      ?.localeCompare(get(b, path || '')?.trim()),
  );
};

export const isJsonBlob = (data: any) => {
  return data instanceof Blob && data.type === 'application/json';
};

export const blobResponseToJson = async (response: any) => {
  const responseData = isJsonBlob(response) ? await response?.text() : response || {};
  const responseJson = typeof responseData === 'string' ? JSON.parse(responseData) : responseData;

  return responseJson;
};

export const trimAll = (value?: string) => {
  if (!value) return '';

  return value.replace(/\s/g, '');
};
