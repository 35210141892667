/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IConnectIDIconProps {}

export const ConnectIDIcon: React.FunctionComponent<IConnectIDIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='168' height='31' viewBox='0 0 168 31'>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-982 -738)'>
          <g transform='translate(290 54)'>
            <g transform='translate(0 213)'>
              <g transform='translate(220 138)'>
                <g transform='translate(0 36)'>
                  <g transform='translate(456 246)'>
                    <g transform='translate(16 51)'>
                      <rect
                        width='168'
                        height='31'
                        x='0'
                        y='0'
                        fill='#262626'
                        fillRule='nonzero'
                        rx='15.12'
                      ></rect>
                      <path
                        fill='#FFF'
                        fillRule='nonzero'
                        d='M40.21 16.57a3.035 3.035 0 01-3.044 3.03 3.035 3.035 0 01-3.043-3.03 3.036 3.036 0 013.043-3.032c.846 0 1.607.337 2.156.893l3.442-3.427a3.015 3.015 0 01-.888-2.147h-2.554c0 1.128.339 2.19.922 3.065a5.58 5.58 0 00-3.078-.918 5.569 5.569 0 00-5.597 5.565c0 3.082 2.503 5.574 5.597 5.574 3.095 0 5.598-2.492 5.598-5.574H40.21z'
                      ></path>
                      <text
                        fill='#FFF'
                        fontFamily='SFProDisplay-Medium, SF Pro Display'
                        fontSize='9'
                        fontWeight='400'
                        letterSpacing='0.113'
                      >
                        <tspan x='48.781' y='19'>
                          Verify with ConnectID
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
