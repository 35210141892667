import { useMutation, useQuery } from '@tanstack/react-query';
import { ORGANISATION_SETTING_API } from 'src/constants/apiEndpoints';
import { detectPortalType } from 'src/helpers/common';
import { downloadFileFromArrayBuffer } from 'src/utils/common';
import {
  getBranding,
  getOrganisationBillingInvoice,
  getOrganisationBillings,
  getOrganisationDetail,
  getOrganisationSubscriptions,
  updateOrganisationDetail,
  upsertBranding,
} from './services';
import { IOrganisationBilling, IOrganisationSubscription } from './type';

export const useUpsertBranding = () => {
  return useMutation(upsertBranding);
};

export const useGetBranding = (enabled = true) => {
  const { isFunds } = detectPortalType();
  return useQuery(
    [ORGANISATION_SETTING_API.branding.api],
    async () => {
      if (isFunds) {
        const resp = await getBranding();
        return resp;
      }
      return;
    },
    {
      enabled: isFunds && enabled,
    },
  );
};

export const useUpdateOrganisationDetail = () => {
  return useMutation(updateOrganisationDetail);
};

export const useGetOrganisationDetail = () => {
  return useQuery([ORGANISATION_SETTING_API.organisationDetail.api], () => getOrganisationDetail());
};

export const useGetOrganisationSubscriptions = () => {
  const { isFunds } = detectPortalType();
  return useQuery<IOrganisationSubscription[]>(
    [ORGANISATION_SETTING_API.organisationSubscriptions.api],
    () => getOrganisationSubscriptions(),
    { enabled: isFunds },
  );
};

export const useGetOrganisationBillings = () => {
  return useQuery<IOrganisationBilling[]>([ORGANISATION_SETTING_API.organisationBillings.api], () =>
    getOrganisationBillings(),
  );
};

export const useDownloadOrganisationBillingInvoice = () => {
  const resp = async ({ id, fileName }: { id: string; fileName: string }) => {
    const data = await getOrganisationBillingInvoice(id);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};
