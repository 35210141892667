import { ButtonProps, Typography, useTheme } from '@mui/material';
import { FC, useRef } from 'react';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { detectPortalType } from 'src/helpers/common';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';

export interface IUpdateDetailsButtonProps extends ButtonProps {
  entityId?: string;
  investorId?: string | null;
  isCreateFundApplicationForm?: boolean;
}

const UpdateDetailsButton: FC<IUpdateDetailsButtonProps> = ({
  entityId = '',
  variant = 'text',
  isCreateFundApplicationForm,
  investorId,
  ...rest
}) => {
  const navigate = useNavigateWithParams();
  const updateDetailModalRef = useRef<IBasicModalElement>(null);
  const { isFunds } = detectPortalType();
  const theme = useTheme();

  const handleNavigate = () => {
    if (investorId) {
      navigate(ROUTES_PATH.ADMINISTRATION_INVESTORS, { investorId });
    } else if (entityId) {
      navigate(
        isFunds ? ROUTES_PATH.ADMINISTRATION_INVESTMENT_ENTITY : ROUTES_PATH.INVESTMENT_ENTITY,
        { entityId },
      );
    }
    updateDetailModalRef.current?.close();
  };

  return (
    <>
      <CustomButton
        {...rest}
        variant={isCreateFundApplicationForm ? 'outlined' : 'text'}
        onClick={(e) => {
          e.stopPropagation();
          updateDetailModalRef?.current?.open();
        }}
        startIcon={
          isCreateFundApplicationForm ? (
            <EditOutlinedIcon width={20} height={20} color={theme.palette.primary.main} />
          ) : null
        }
        sx={{
          px: 2,
          ...(rest.sx || {}),
        }}
      >
        <Typography
          color={rest.color ? rest.color : rest.disabled ? 'neutral.ne400' : 'primary.main'}
          variant='body2'
        >
          Update Details
        </Typography>
      </CustomButton>
      <ConfirmModal
        ref={updateDetailModalRef}
        title='Update Investment Entity?'
        content='Please navigate to Investment Entity Profile to update details'
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => updateDetailModalRef?.current?.close()}
            >
              No
            </CustomButton>
            <CustomButton variant='contained' onClick={handleNavigate}>
              Navigate
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default UpdateDetailsButton;
