import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { FINANCIAL_STEP, INVESTMENT_APPLICATION_STEP } from './InvestmentApplicationForm';
import FinancialDetailsContent from './shared/FinancialDetailsContent';

interface IFinancialDetailsProps extends IUpsertInvestmentApplicationForm {
  keyName?: string;
  isWithoutFinancialAndTaxDetails?: boolean;
}

const FinancialDetails: FC<IFinancialDetailsProps> = ({
  id = '',
  keyName = '',
  isExistingInvestmentEntity,
  isViewMode,
  isDisabledEdit,
  currentStep,
  isWithoutFinancialAndTaxDetails,
  investmentEntityId,
  isReviewing,
  isCreateFundApplicationForm,
}) => {
  const form = useFormContext();
  const { data: applicationDetail } = useGetApplicationById(
    id,
    {
      step: INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
      financialStep: FINANCIAL_STEP.FINANCIAL_DETAILS,
    },
    currentStep === INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
  );

  const details = applicationDetail?.financialDetails;

  useEffect(() => {
    if (!isEmpty(details) && isWithoutFinancialAndTaxDetails) {
      form.reset(details);
    }
  }, [details, isWithoutFinancialAndTaxDetails]);

  return (
    <>
      <FinancialDetailsContent
        keyName={keyName}
        disabled={isViewMode || isExistingInvestmentEntity || isDisabledEdit}
        action={
          isWithoutFinancialAndTaxDetails &&
          isExistingInvestmentEntity &&
          !isReviewing &&
          !isCreateFundApplicationForm && (
            <UpdateDetailsButton
              entityId={investmentEntityId}
              disabled={isViewMode || isDisabledEdit}
            />
          )
        }
      />
    </>
  );
};

export default FinancialDetails;
