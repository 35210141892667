import { Box } from '@mui/material';
import { forwardRef, useImperativeHandle } from 'react';
import LoadingIndicator from 'src/components/atoms/LoadingIndicator';
import OfferThumbnail from 'src/components/organisms/OfferThumbnail';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { useRole } from 'src/hooks/useRole';
import { useGetApplicationOfferList } from 'src/modules/applications/hooks';
import { IApplicationOffer } from 'src/modules/applications/type';
import { IOfferClientBranding, IOfferPreview } from 'src/modules/offer/type';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface IMyOfferAndInvitationsProps {}

export interface IMyOfferAndInvitationsRef {
  onRefetchOfferList?: (value: number) => void;
}

interface IApplicationOfferPreviewBranding {
  preview: IOfferPreview;
  branding: IOfferClientBranding;
}

const MyOfferAndInvitations = forwardRef<IMyOfferAndInvitationsRef, IMyOfferAndInvitationsProps>(
  (props, ref) => {
    const navigate = useNavigateWithParams();
    const { canCreate } = useRole(ROUTES_PATH.APPLICATIONS);

    const {
      data: applicationOfferList = [],
      isLoading,
      setParams,
      refetch,
    } = useGetApplicationOfferList();

    const offersFormat = applicationOfferList?.map((item: IApplicationOffer) => {
      return {
        preview: {
          id: item?.id,
          name: item?.name,
          fundName: item?.fundName,
          logoUrl: item?.logo,
          bannerUrl: item?.thumbnail,
          overview: item?.overview,
          expirationDate: item?.expirationDate,
          isOpenEnded: item?.isOpenEnded,
          isInvitation: item?.isInvitation,
          description: item?.description,
          isFavourite: item?.isFavourite,
        },
        branding: {
          logoLink: item?.thumbnail,
          primaryBackground: item?.buttonBackgroundColor,
          primaryText: item?.buttonTextColor,
        },
      };
    });

    const handleCLickOfferDetail = (offer: IApplicationOfferPreviewBranding) => {
      navigate(ROUTES_PATH.INVESTOR_OFFER_DETAILS, {
        offerId: offer.preview.id,
      });
    };

    const handleRefetchOfferList = (value?: number) => {
      setParams({ ListType: value });
      refetch();
    };

    useImperativeHandle(ref, () => ({
      onRefetchOfferList: handleRefetchOfferList,
    }));

    return (
      <Box>
        {!isLoading ? (
          <Swiper
            spaceBetween={32}
            slidesPerView='auto'
            modules={[Navigation, Pagination]}
            navigation
            pagination={{
              clickable: true,
            }}
            className='h-[500px]'
            lazyPreloadPrevNext={3}
          >
            {offersFormat?.map((item: IApplicationOfferPreviewBranding) => {
              return (
                <SwiperSlide key={item?.preview?.id} className='w-[312px]'>
                  <OfferThumbnail
                    data={item.preview}
                    viewOnly={false}
                    onClickOfferDetail={() => handleCLickOfferDetail(item)}
                    hiddenTitle
                    onRefetchOfferList={() => handleRefetchOfferList()}
                    branding={item.branding}
                    disabledAction={!canCreate}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <LoadingIndicator />
        )}
      </Box>
    );
  },
);

export default MyOfferAndInvitations;
