import { REQUIRE_MESSAGE } from 'src/constants/common';
import * as Yup from 'yup';
import { applicationDetailsSchema } from './applications';

export const createFundApplicationDetailsSchema = applicationDetailsSchema.concat(
  Yup.object().shape({
    clientId: Yup.string()
      .when('isSaveDraft', {
        is: false,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    fundId: Yup.string()
      .when('isSaveDraft', {
        is: false,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    offerId: Yup.string()
      .when('isSaveDraft', {
        is: false,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    unitClassId: Yup.string()
      .when('isSaveDraft', {
        is: false,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
  }),
);
