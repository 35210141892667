/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { MAX_APPROVED_SIGNATORIES_USERS } from 'src/constants/funds-management';
import { ISignatoryContact, IUpsertFundForm } from 'src/modules/funds-management/type';
import CreateSignatoryContactUser, {
  CreateSignatoryContactUserHandler,
} from './CreateSignatoryContactUser';
import UserSelectionBox from './UserSelectionBox';

interface IApprovedSignatoriesProps extends IUpsertFundForm {}
const ApprovedSignatories: React.FC<IApprovedSignatoriesProps> = ({ isViewMode }) => {
  const createUserRef = React.useRef<CreateSignatoryContactUserHandler>(null);
  const createDrawerRef = React.useRef<ICustomDrawerElement>(null);

  const form = useFormContext();
  const { errors } = form.formState;

  const { append, remove, update, fields } = useFieldArray({
    control: form?.control,
    name: 'signatoryContacts',
  });

  const handleCreateNewUser = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  const handleAddUser = (newPerson: ISignatoryContact) => {
    const newContact = {
      ...newPerson,
      isSelected: false,
      isNew: true,
    };
    append(newContact);
    createDrawerRef.current?.close();
  };

  const watchFieldArray = form.watch('signatoryContacts');
  const controlledContacts = (fields || [])?.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  const { numOfSelected, isGotLimitation } = React.useMemo(() => {
    const selectedItems = controlledContacts?.filter((f) => f.isSelected);
    return {
      numOfSelected: selectedItems.length,
      isGotLimitation: selectedItems.length === MAX_APPROVED_SIGNATORIES_USERS,
    };
  }, [controlledContacts]);

  return (
    <Box>
      <UserSelectionBox
        users={controlledContacts}
        title='Select approved signatories'
        numOfSelected={`${numOfSelected}/${MAX_APPROVED_SIGNATORIES_USERS} authorised`}
        onRemove={(index) => remove(index)}
        onSelect={(index, user) => update(index, user)}
        disabled={isViewMode}
      />
      {errors && (
        <CustomHelperText
          variant='error'
          message={errors['signatoryContacts']?.message as string}
        />
      )}
      <CustomButton
        onClick={() => createDrawerRef.current?.open()}
        className='mt-[14px]'
        variant='text'
        startIcon={<PlusPrimaryIcon disabled={isGotLimitation} />}
        disabled={isViewMode || isGotLimitation}
      >
        <Typography
          variant='body2'
          fontWeight={600}
          color={isGotLimitation ? 'neutral.ne400' : 'primary.main'}
        >
          Add an approved signatory
        </Typography>
      </CustomButton>
      <CustomDrawer
        ref={createDrawerRef}
        title='Add New Approved Signatory'
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => createDrawerRef.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton className='w-[160px]' onClick={handleCreateNewUser}>
              Create new
            </CustomButton>
          </Box>
        }
      >
        <CreateSignatoryContactUser ref={createUserRef} />
      </CustomDrawer>
    </Box>
  );
};

export default ApprovedSignatories;
