import { Box, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import UnitPriceTabs, {
  IUnitPriceTab,
} from 'src/components/pages/administration-investor/investor/components/InvestorDetails/shared/UnitPriceTabs';
import UpdateUnitPriceModal from 'src/components/pages/administration-investor/investor/components/InvestorDetails/shared/UpdateUnitPriceModal';
import { REDEEM_OPTION } from 'src/constants/administration-investor';
import { FundType } from 'src/constants/funds-management';
import { IRedemptionForm } from 'src/modules/administration-investor/types';

interface IRedemptionUnitPriceTabsProps {
  unitPriceRounding?: number;
  currentUnitPrice?: number;
  detailIssuedUnits?: number | null;
  unitClassId?: string;
  fundType?: number;
  allowCapitalCall?: boolean;
  allowPartiallyPaidUnits?: boolean;
  detailUnpaidAmount?: number;
}

export enum UnitPriceTabsEnum {
  EXIT_UNIT_PRICE,
  CURRENT_UNIT_PRICE,
}

const RedemptionUnitPriceTabs: FC<IRedemptionUnitPriceTabsProps> = ({
  unitPriceRounding,
  detailIssuedUnits = 0,
  unitClassId = '',
  fundType,
  allowCapitalCall,
  allowPartiallyPaidUnits,
  detailUnpaidAmount = 0,
}) => {
  const [tabSelected, setTabSelected] = useState<UnitPriceTabsEnum>(
    UnitPriceTabsEnum.EXIT_UNIT_PRICE,
  );
  const updateUnitPriceModalRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const form = useFormContext<IRedemptionForm>();
  const watchOption = form.watch('option');
  const watchAmount = form.watch('amount');
  const watchUnits = form.watch('numberOfUnits');
  const watchCurrentUnitPrice = form.watch('currentUnitPrice');
  const watchEntryUnitPrice = form.watch('entryUnitPrice');
  const isTrust = fundType === FundType.Trust;
  const midPriceRounding = watchCurrentUnitPrice?.toString().split('.')?.[1]?.length || 0;
  const exitPriceRounding = watchEntryUnitPrice?.toString().split('.')?.[1]?.length || 0;

  useEffect(() => {
    handleCalcUnits();
  }, [watchOption, detailIssuedUnits, watchAmount, watchCurrentUnitPrice, tabSelected]);

  useEffect(() => {
    handleCalcAmount();
  }, [watchUnits, watchCurrentUnitPrice, watchOption, tabSelected, detailIssuedUnits]);

  // Set selected price tab to validate price in yup
  useEffect(() => {
    if (Number.isInteger(tabSelected)) {
      form.setValue('selectedPrice', tabSelected);
      form.trigger('entryUnitPrice');
      form.trigger('currentUnitPrice');
    }
  }, [tabSelected]);

  const handleUpdateUnitPrice = (value: number) => {
    form.setValue('currentUnitPrice', value);
  };

  const handleCalcUnits = () => {
    if (watchOption === REDEEM_OPTION.UNIT) return;
    const watchExitUnitPrice = form.watch('entryUnitPrice');
    const price =
      tabSelected === UnitPriceTabsEnum.EXIT_UNIT_PRICE
        ? watchExitUnitPrice
        : watchCurrentUnitPrice;
    let units = 0;
    if (watchOption === REDEEM_OPTION.AMOUNT && price) {
      if (isTrust && allowCapitalCall && allowPartiallyPaidUnits && detailIssuedUnits) {
        units = watchAmount / (price - detailUnpaidAmount / detailIssuedUnits);
      } else {
        units = watchAmount / price;
      }
    } else if (watchOption === REDEEM_OPTION.FULL_HOLDING) {
      units = detailIssuedUnits || 0;
    }

    form.setValue('numberOfUnits', units);
  };

  const handleCalcAmount = () => {
    if (watchOption === REDEEM_OPTION.AMOUNT) return;
    const watchExitUnitPrice = form.watch('entryUnitPrice');
    const price =
      tabSelected === UnitPriceTabsEnum.EXIT_UNIT_PRICE
        ? (watchExitUnitPrice as number)
        : (watchCurrentUnitPrice as number);
    let amount = 0;
    if (isTrust && allowCapitalCall && allowPartiallyPaidUnits && detailIssuedUnits) {
      amount = price * watchUnits - (watchUnits * detailUnpaidAmount) / detailIssuedUnits;
    } else {
      amount = watchUnits * price;
    }

    form.setValue('amount', amount);
  };

  const tabs: IUnitPriceTab[] = [
    {
      label: 'Exit Unit Price',
      key: UnitPriceTabsEnum.EXIT_UNIT_PRICE,
      component: (
        <FormCurrencyInput
          name='entryUnitPrice'
          label='Exit Unit Price'
          hideCurrency
          decimalScale={
            exitPriceRounding <= (unitPriceRounding as number)
              ? unitPriceRounding
              : exitPriceRounding
          }
          placeholder='Enter exit unit price'
          fixedDecimalScale
          disabled
        />
      ),
    },
    {
      label: 'Mid Price',
      key: UnitPriceTabsEnum.CURRENT_UNIT_PRICE,
      component: (
        <FormCurrencyInput
          name='currentUnitPrice'
          label='Mid Price'
          hideCurrency
          decimalScale={
            midPriceRounding <= (unitPriceRounding as number) ? unitPriceRounding : midPriceRounding
          }
          placeholder='Enter mid price'
          disabled
          fixedDecimalScale
        />
      ),
    },
  ];
  return (
    <Box className='mt-8'>
      <Typography className='block' variant='body3' mb={1}>
        Redemption Unit Price
      </Typography>
      <UnitPriceTabs
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab: number) => setTabSelected(tab)}
      />
      <BasicModal ref={updateUnitPriceModalRef}>
        <UpdateUnitPriceModal
          onClose={() => updateUnitPriceModalRef.current?.close()}
          unitPriceRounding={unitPriceRounding}
          onConfirmUpdateUnitPrice={handleUpdateUnitPrice}
          unitClassId={unitClassId}
          currentUnitPriceValue={watchCurrentUnitPrice}
        />
      </BasicModal>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title='You did it !'
          description='Current unit price has been updated.'
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default RedemptionUnitPriceTabs;
