import { Typography } from '@mui/material';
import { isNumber } from 'lodash';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArchiveIcon } from 'src/assets/icons/ArchiveIcon';
import { PeopleIcon } from 'src/assets/icons/PeopleIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { SwitchLayoutDropdown } from 'src/components/organisms/SwitchLayoutDropdown';
import { ROUTES_PATH } from 'src/constants/routesPath';
import InvestmentEntity from './investment-entity';
import Investor from './investor';

enum Layout {
  Investor,
  InvestmentEntity,
}

const LayoutOptions = [
  {
    label: 'Investor',
    value: Layout.Investor,
    pathname: ROUTES_PATH.ADMINISTRATION_INVESTORS,
  },
  {
    label: 'Investment Entity',
    value: Layout.InvestmentEntity,
    pathname: ROUTES_PATH.ADMINISTRATION_INVESTMENT_ENTITY,
  },
];

const AdministrationInvestor = () => {
  const navigate = useNavigate();
  const [layout, setLayout] = React.useState(Layout.Investor);
  const location = useLocation();

  React.useEffect(() => {
    const findOptionValue = LayoutOptions.find((f) => f.pathname === location?.pathname)?.value;
    if (isNumber(findOptionValue)) {
      setLayout(findOptionValue);
    }
  }, [location?.pathname]);

  const handleChange = (value: number) => {
    const pathname = LayoutOptions.find((f) => f.value === value)?.pathname;
    pathname && navigate(pathname);
  };

  const defaultProps = {
    bulkUploadButton: (
      <CustomButton
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral.ne200,
          mr: '10px',
        })}
        startIcon={<ArchiveIcon color='#686D6E' width={20} height={20} />}
        className='w-[193px] h-12 flex items-center justify-center px-5'
        onClick={() => navigate(ROUTES_PATH.BULK_UPLOAD)}
      >
        <Typography color='neutral.ne800' variant='body2'>
          Bulk Upload
        </Typography>
      </CustomButton>
    ),
    switchLayoutDropdown: (
      <SwitchLayoutDropdown
        selected={layout}
        onChange={handleChange}
        layoutOptions={LayoutOptions}
        icon={<PeopleIcon />}
      />
    ),
  };

  if (layout === Layout.InvestmentEntity) return <InvestmentEntity {...defaultProps} />;
  return <Investor {...defaultProps} />;
};

export default AdministrationInvestor;
