import { actionPermissionKeys } from './common';

export const investorFormActionTypes = {
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
};

export const investorFormActionMenus = [
  {
    key: investorFormActionTypes.view,
    label: investorFormActionTypes.view,
    permissionKey: actionPermissionKeys.isView,
  },
  {
    key: investorFormActionTypes.edit,
    label: investorFormActionTypes.edit,
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: investorFormActionTypes.delete,
    label: investorFormActionTypes.delete,
    permissionKey: actionPermissionKeys.isDelete,
  },
];

export const investorFormStatusName = {
  Draft: 'Draft',
  Submitted: 'Submitted',
};

export enum INVESTOR_FORM_TYPE {
  REDEMPTION_REQUEST,
  TRANSFER,
  CHANGE_DETAILS,
}

export enum INVESTOR_FORM_STATUS {
  DRAFT,
  SUBMITTED,
}

export enum REDEMPTION_TYPE {
  FULL,
  PARTIAL,
}

export enum AMOUNT_TYPE {
  AMOUNT,
  UNITS,
}

export enum PAYMENT_METHOD {
  EXISTING_BANK,
  BANK_ACCOUNT,
}

export enum TRANSFER_TYPE {
  FULL,
  PARTIAL,
}

export const investorFormTypeOptions = [
  {
    label: 'Redemption',
    value: INVESTOR_FORM_TYPE.REDEMPTION_REQUEST,
  },
  {
    label: 'Transfer',
    value: INVESTOR_FORM_TYPE.TRANSFER,
  },
  {
    label: 'Change of Details',
    value: INVESTOR_FORM_TYPE.CHANGE_DETAILS,
  },
];

export const redemptionOptions = [
  {
    label: 'Full Redemption',
    value: REDEMPTION_TYPE.FULL,
  },
  {
    label: 'Partial Redemption',
    value: REDEMPTION_TYPE.PARTIAL,
  },
];

export const transferTypeOptions = [
  {
    label: 'Full Holding',
    value: TRANSFER_TYPE.FULL,
  },
  {
    label: 'Partial Holding',
    value: TRANSFER_TYPE.PARTIAL,
  },
];

export const partialRedemptionOptions = [
  {
    label: 'Amount',
    value: AMOUNT_TYPE.AMOUNT,
  },
  {
    label: 'Units',
    value: AMOUNT_TYPE.UNITS,
  },
];

export const paymentMethodOptions = [
  {
    label: 'Pay to the existing bank account currently on file ending with xx1234.',
    value: PAYMENT_METHOD.EXISTING_BANK,
  },
  {
    label: `Pay to the bank account provided below (if you select this option, you may be required to provide additional information to verify the new bank account details prior to payment.`,
    value: PAYMENT_METHOD.BANK_ACCOUNT,
  },
];

export const investorFormStatusOptions = [
  {
    label: 'Draft',
    value: INVESTOR_FORM_STATUS.DRAFT,
  },
  {
    label: 'Submitted',
    value: INVESTOR_FORM_STATUS.SUBMITTED,
  },
];

export const investorRedemptionFormDefaultValue = {
  investmentEntityId: null,
  fundId: null,
  unitClassId: null,
  units: null,
  investedAmount: null,
  unpaidAmount: null,
  netValue: null,
  redemptionType: 0,
  amountType: 0,
  redemptionAmount: 0,
  redemptionUnits: 0,
  redemptionDate: undefined,
  paymentMethod: null,
  bankName: '',
  accountName: '',
  bsb: '',
  accountNumber: '',
  isDeclarationRead: false,
  file: undefined,
  isSaveDraft: false,
  willFileRemoved: false,
};

export const investorTransferFormDefaultValue = {
  investmentEntityId: null,
  fundId: null,
  unitClassId: null,
  transferDate: undefined,
  units: null,
  investedAmount: null,
  unpaidAmount: null,
  netValue: null,
  transferType: TRANSFER_TYPE.FULL,
  transferUnits: 0,
  considerationAmount: 0,
  transfereeName: '',
  transfereeABN: '',
  transfereeAddress: '',
  email: '',
  contactNumber: '',
  contactPersonName: '',
  isDeclarationRead: false,
  file: undefined,
  formId: '',
  formType: INVESTOR_FORM_TYPE.TRANSFER,
  isSaveDraft: false,
  willFileRemoved: false,
};

export const investorChangeDetailsDefaultValue = {
  formId: '',
  formType: INVESTOR_FORM_TYPE.CHANGE_DETAILS,
  file: undefined,
  isDeclarationRead: false,
  investmentEntityId: null,
  funds: [],
  infoChanges: [],
  currentDetails: '',
  updatedDetails: '',
  isSaveDraft: false,
  willFileRemoved: false,
};

export enum InfoChangeEnum {
  ContactPerson,
  Communication,
  Investor,
  DistributionAccount,
}

export const infoChangesOptions = [
  {
    label: 'Contact person details',
    value: InfoChangeEnum.ContactPerson,
  },
  {
    label: 'Communication details',
    value: InfoChangeEnum.Communication,
  },
  {
    label: 'Investor details',
    value: InfoChangeEnum.Investor,
  },
  {
    label: 'Distribution account details',
    value: InfoChangeEnum.DistributionAccount,
  },
];

export const INVESTOR_FORM_PDF_NAME = {
  [INVESTOR_FORM_TYPE.CHANGE_DETAILS]: 'ChangeOfDetail.pdf',
  [INVESTOR_FORM_TYPE.REDEMPTION_REQUEST]: 'RedemptionRequest.pdf',
  [INVESTOR_FORM_TYPE.TRANSFER]: 'TransferRequest.pdf',
};
