import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import ActionMenus from 'src/components/molecules/ActionMenus';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { ActionType } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { billingPlanActionTypes } from 'src/constants/subscription-billing';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { ConfirmFormat } from 'src/modules/common/type';
import { useDeleteBillingPlan, useGetBillingPlans } from 'src/modules/subscription-billing/hooks';
import { BillingPlan } from 'src/modules/subscription-billing/types';
import { handleErrorFromServer } from 'src/utils/common';
import { ISubscriptionAndBillingProps } from '.';
import CreateBillingPlanForm from './CreateBillingPlanForm';
import StandardPriceHistory from './StandardPriceHistory';
import StandardPricing from './StandardPricing';

interface ITableData extends BillingPlan {}

interface IBillingProps extends ISubscriptionAndBillingProps {}

const Billing = (props: IBillingProps) => {
  const { isSuperAdmin, isViewMode, clientId, isCreatedClient, isDraft } = props;

  const { watch, setValue, clearErrors } = useFormContext();
  const subscriptionFeatureIds = watch('subscriptionFeatureIds');

  const standardPricingModalRef = useRef<IBasicModalElement>(null);
  const standardPriceHistoryModalRef = useRef<IBasicModalElement>(null);
  const createBillingPlanModalRef = useRef<IBasicModalElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);

  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [selectedRow, setSelectedRow] = useState<ITableData>();
  const [billingList, setBillingList] = useState<BillingPlan[]>([]);

  const { data, isLoading, setParams } = useGetBillingPlans(clientId);
  const { data: userInfo } = useGetUserInfo();
  const { mutate: deleteBillingPlan, isLoading: isDeletingBillingPlan } = useDeleteBillingPlan();

  useEffect(() => {
    setBillingList(data || []);
  }, [data]);

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Start Date',
      key: 'startDate',
      sx: { width: '22%', py: '11px' },
      sorter: true,
      sortBy: 'startDate',
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable variant='body3' value={dayjs(row?.startDate).format(DATE_PICKER_FORMAT)} />
      ),
    },
    {
      title: 'End Date',
      key: 'endDate',
      sx: { width: '22%', py: '11px' },
      sorter: true,
      sortBy: 'endDate',
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={row?.endDate ? dayjs(row.endDate).format(DATE_PICKER_FORMAT) : 'Open-ended'}
        />
      ),
    },
    {
      title: 'Updated Date',
      key: 'modifiedBy',
      sx: { width: '22%', py: '11px' },
      sorter: true,
      sortBy: 'modifiedBy',
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={dayjs(row?.modifiedDate || row?.createdDate).format(DATE_PICKER_FORMAT)}
        />
      ),
    },
    {
      title: 'Updated By',
      key: 'createdBy',
      sx: { width: '22%', py: '11px' },
      sorter: true,
      sortBy: 'createdBy',
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable variant='body3' value={row?.modifiedByName || row?.createdByName} />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '12%', py: '11px' },
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => {
        const canEditBillingPlan = !isViewMode && (!isCreatedClient || isSuperAdmin);
        const canDeleteBillingPlan =
          !isViewMode &&
          (!isCreatedClient || (isSuperAdmin && !dayjs(row.startDate).isBefore(dayjs(), 'day')));

        const menus = [
          billingPlanActionTypes.view,
          ...(canEditBillingPlan ? [billingPlanActionTypes.edit] : []),
          ...(canDeleteBillingPlan ? [billingPlanActionTypes.delete] : []),
        ];

        const actionIcons = {
          [billingPlanActionTypes.view]: <DocumentOnePageIcon />,
          [billingPlanActionTypes.edit]: <EditOutlinedIcon />,
          [billingPlanActionTypes.delete]: <DeleteIcon />,
        };

        const actions = {
          [billingPlanActionTypes.view]: () => handleOpenBillingPlanForm('view', row),
          [billingPlanActionTypes.edit]: () => handleOpenBillingPlanForm('edit', row),
          [billingPlanActionTypes.delete]: () => {
            if (!row.id) {
              setBillingList([]);
              setValue('billings', null);
              return;
            }
            setConfirmFormat({
              title: `Are you sure you want to delete the billing plan?`,
              content: `This action can not be undone.`,
              button: {
                type: 'primary',
                label: 'Delete',
                onAction: () =>
                  deleteBillingPlan(
                    { clientId, billingPlanId: row.id || '' },
                    {
                      onError: handleErrorFromServer,
                      onSettled: () => {
                        modalConfirmRef?.current?.close();
                      },
                    },
                  ),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const menuAction = menus.map((it) => ({
          icon: actionIcons?.[it],
          label: capitalize(it),
          onAction: actions?.[it],
        }));

        return <ActionMenus menus={menuAction} />;
      },
    },
  ];

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleOpenBillingPlanForm = (type: ActionType, row?: ITableData) => {
    createBillingPlanModalRef.current?.open();
    setSelectedRow(row);
    setActionType(type);
  };

  const handleSaveBillingPlan = (data: BillingPlan) => {
    setValue('billings', data);
    setBillingList([
      {
        ...data,
        modifiedDate: dayjs(),
        modifiedByName: `${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`,
      } as any,
    ]);
    clearErrors();
  };

  return (
    <Box mt={2} mx={-3}>
      <Box className='flex justify-between items-center'>
        <Box className='flex'>
          <CustomButton
            disableRipple
            variant='text'
            sx={{
              p: 0,
              textDecoration: 'underline !important',
              bgcolor: 'transparent !important',
              color: 'secondary.main',
            }}
            onClick={() =>
              isSuperAdmin && !isViewMode
                ? standardPricingModalRef.current?.open()
                : standardPriceHistoryModalRef.current?.open()
            }
          >
            {isSuperAdmin && !isViewMode ? 'Update Standard Pricing' : 'Standard Price History'}
          </CustomButton>
        </Box>
        {!isViewMode && (
          <CustomButton
            size='small'
            startIcon={<PlusPrimaryIcon />}
            variant='text'
            sx={{ px: 2, py: 0.25, mt: 0, mr: -2, fontSize: '16px' }}
            onClick={() => handleOpenBillingPlanForm('create')}
            disabled={
              (!clientId && billingList.length === 1) || (isDraft && billingList.length === 1)
            }
          >
            Create Billing Plan
          </CustomButton>
        )}
      </Box>
      <Box mt={3} sx={{ '.render-node-container': { m: 0 } }}>
        {(billingList.length || isLoading) && !(!clientId && !billingList.length) ? (
          <CustomTable
            columns={columns}
            rows={billingList}
            isFetchingData={!!clientId && isLoading}
            hasPagination={false}
            onSort={handleSort}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
      <BasicModal
        ref={standardPricingModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => standardPricingModalRef.current?.close()}
      >
        <StandardPricing
          isViewMode={isViewMode}
          canViewHistory
          onClose={() => standardPricingModalRef.current?.close()}
          isSuperAdmin={isSuperAdmin}
        />
      </BasicModal>
      <BasicModal
        ref={standardPriceHistoryModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => standardPriceHistoryModalRef.current?.close()}
      >
        <StandardPriceHistory
          onClose={() => standardPriceHistoryModalRef.current?.close()}
          isSuperAdmin={isSuperAdmin}
          isViewMode={isViewMode}
        />
      </BasicModal>
      <BasicModal
        ref={createBillingPlanModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => createBillingPlanModalRef.current?.close()}
      >
        <CreateBillingPlanForm
          {...props}
          onClose={() => createBillingPlanModalRef.current?.close()}
          isViewMode={actionType === 'view' || isViewMode}
          actionType={actionType}
          id={selectedRow?.id}
          subscriptionFeatureIds={Object.keys(subscriptionFeatureIds).filter(
            (key) => subscriptionFeatureIds[key],
          )}
          handleSaveBillingPlan={handleSaveBillingPlan}
          defaultValue={!!selectedRow && !selectedRow?.id ? selectedRow : undefined}
        />
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={isDeletingBillingPlan}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={isDeletingBillingPlan}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default Billing;
