import { Box, Typography } from '@mui/material';
import { useGetOrganisationSubscriptions } from 'src/modules/organisation-settings/hooks';

const Subscription = () => {
  const { data: subscriptions } = useGetOrganisationSubscriptions();

  return (
    <Box
      mx={-3}
      mb={-3}
      p={3}
      bgcolor='neutral.ne100'
      borderRadius={'12px'}
      display={'flex'}
      flexDirection={'column'}
      gap={3}
    >
      <Box className='subscriptions' component='ul'>
        {(subscriptions || []).map((subscription) => (
          <Box className='pb-6 last:pb-0' key={subscription.id} component='li'>
            <Typography variant='body2' color='base.black' letterSpacing={'0.2px'}>
              {subscription.name}
            </Typography>
            <Box className='child-feature' component='ul'>
              {(subscription?.childFeatures || []).map((it, index) => (
                <Box className='pt-3' key={index} component='li' pl={4}>
                  <Typography variant='body3' color='neutral.ne800'>
                    {it}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Subscription;
