/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AssociationIcon from 'src/assets/icons/AssociationIcon';
import GovernmentBodyIcon from 'src/assets/icons/GovernmentBodyIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import Association from 'src/components/pages/applications/components/InvestmentEntity/Other/Association';
import GovernmentBody from 'src/components/pages/applications/components/InvestmentEntity/Other/GovernmentBody';
import {
  INVESTMENT_ENTITY_OTHER,
  investmentEntityOtherDefaultValue,
} from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { INVESTMENT_ENTITY_STEP } from '../../InvestmentEntityForm';
import AddNew from '../AddNew';

interface IOtherProps extends IInvestmentEntityProps {}

const otherTypeOptions = [
  {
    label: 'Association',
    value: INVESTMENT_ENTITY_OTHER.Association,
    icon: <AssociationIcon />,
  },
  {
    label: 'Government Body',
    value: INVESTMENT_ENTITY_OTHER.GovernmentBody,
    icon: <GovernmentBodyIcon />,
  },
];

const Other: FC<IOtherProps> = (props) => {
  const { isViewMode, id = '', isInvestmentEntitySetting, isEditMode, isDraft } = props;
  const { watch, reset } = useFormContext<IInvestmentEntityForm>();
  const watchOtherType = watch('otherType');
  const allowEditAbn = watch('investmentEntityDetails.allowEditAbn');

  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntityOtherDefaultValue, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <FormRadioGroup
        name='otherType'
        options={otherTypeOptions}
        isEnumValue
        disabled={
          isViewMode || isInvestmentEntitySetting || Number.isInteger(investmentEntity?.otherType)
        }
        sx={{ mb: 3, gap: 0 }}
        formControlLabelSx={{
          '.MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 500,
            maxWidth: '78px',
          },
        }}
        optionGap={4}
      />
      <AddNew {...props}>
        {Number.isInteger(watchOtherType) && (
          <Accordion
            defaultExpanded
            sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
          >
            <AccordionSummary
              sx={{
                '.MuiSvgIcon-root': { fontSize: '18px' },
                '.MuiAccordionSummary-content': { my: 0 },
                minHeight: 'unset',
              }}
            >
              <Box className='flex justify-between items-center w-full'>
                <Typography variant='body2' fontWeight={600}>
                  {watchOtherType === INVESTMENT_ENTITY_OTHER.Association && 'Association Details'}
                  {watchOtherType === INVESTMENT_ENTITY_OTHER.GovernmentBody &&
                    'Government Body Details'}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              className='p-6 mt-0 border-t'
              sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
            >
              {watchOtherType === INVESTMENT_ENTITY_OTHER.GovernmentBody && (
                <GovernmentBody {...props} disabledAbn={!allowEditAbn} hideUBODeclaration />
              )}
              {watchOtherType === INVESTMENT_ENTITY_OTHER.Association && (
                <Association {...props} disabledAbn={!allowEditAbn} hideUBODeclaration />
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </AddNew>
    </Box>
  );
};

export default Other;
