/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import RegulatedTrustIcon from 'src/assets/icons/RegulatedTrustIcon';
import UnregulatedTrustIcon from 'src/assets/icons/UnregulatedTrustIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import RegulatedTrust from 'src/components/pages/applications/components/InvestmentEntity/Trust/RegulatedTrust';
import UnregulatedTrust from 'src/components/pages/applications/components/InvestmentEntity/Trust/UnregulatedTrust';
import {
  INVESTMENT_ENTITY_TRUST,
  investmentEntityTrustDefaultValues,
} from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { INVESTMENT_ENTITY_STEP } from '../../InvestmentEntityForm';
import AddNew from '../AddNew';

interface ITrustProps extends IInvestmentEntityProps {
  isSuperAdmin?: boolean;
}

const trustOptions = [
  {
    value: INVESTMENT_ENTITY_TRUST.Unregulated,
    label: 'Unregulated Trust',
    icon: <UnregulatedTrustIcon />,
  },
  {
    value: INVESTMENT_ENTITY_TRUST.Regulated,
    label: 'Regulated Trust',
    icon: <RegulatedTrustIcon />,
  },
];

const Trust = (props: ITrustProps) => {
  const { isViewMode, id = '' } = props;
  const { watch, reset } = useFormContext<IInvestmentEntityForm>();
  const watchTrust = watch('trustType');
  const allowEditAbn = watch('investmentEntityDetails.allowEditAbn');

  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntityTrustDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <FormRadioGroup
        name='trustType'
        options={trustOptions}
        isEnumValue
        disabled={isViewMode || Number.isInteger(investmentEntity?.trustType)}
        sx={{ mb: 3, gap: 0 }}
        formControlLabelSx={{
          '.MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 500,
            maxWidth: '78px',
          },
        }}
        optionGap={4}
      />
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                {watchTrust === INVESTMENT_ENTITY_TRUST.Regulated && 'Regulated Trust Details'}
                {watchTrust === INVESTMENT_ENTITY_TRUST.Unregulated && 'Unregulated Trust Details'}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            {watchTrust === INVESTMENT_ENTITY_TRUST.Regulated && (
              <RegulatedTrust
                {...props}
                disabledAbn={!allowEditAbn}
                hideUBODeclaration
                enabledClearCorporateDetails
              />
            )}
            {watchTrust === INVESTMENT_ENTITY_TRUST.Unregulated && (
              <UnregulatedTrust
                {...props}
                disabledAbn={!allowEditAbn}
                hideUBODeclaration
                enabledClearCorporateDetails
              />
            )}
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default Trust;
