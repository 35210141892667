import dayjs from 'dayjs';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { BILLING_TIME_FORMAT, DATE_PICKER_FORMAT } from 'src/constants/date';
import * as yup from 'yup';
import { YupBase } from './common';

export const billingFormSchema = yup.object({
  startDate: YupBase.date
    .nullable()
    .required(REQUIRE_MESSAGE)
    .when(['isEditingClient', 'isCreateMode', 'isCreatedClient'], {
      is: true,
      then: (schema) =>
        schema
          .test('greater than existing', (value: any, context) => {
            if (
              context.parent.existingStartDate &&
              !dayjs(value).isAfter(dayjs(context.parent.existingStartDate), 'day')
            ) {
              return context.createError({
                path: 'startDate',
                message: `Start date must be greater than existing date (${dayjs(
                  context.parent.existingStartDate,
                ).format(DATE_PICKER_FORMAT)})`,
              });
            }
            return true;
          })
          .test('greater than today', 'Start date must be greater than today', (value: any) => {
            if (dayjs(value).isBefore(dayjs(), 'day')) {
              return false;
            }
            return true;
          }),
    })
    .when(['isEditingClient', 'isEditMode', 'isCreatedClient'], {
      is: true,
      then: (schema) =>
        schema.test(
          'greater than today',
          'Start date must be greater than today',
          (value: any, context: any) => {
            if (
              context.parent.existingStartDate &&
              !dayjs(context.parent.existingStartDate).isBefore(dayjs(), 'day') &&
              dayjs(value).isBefore(dayjs(), 'day')
            ) {
              return false;
            }
            return true;
          },
        ),
    }),
  endDate: yup
    .mixed()
    .nullable()
    .when(['isOpenEnded'], {
      is: true,
      otherwise: () =>
        YupBase.date
          .nullable()
          .required(REQUIRE_MESSAGE)
          .test('valid', 'End date must be greater than start date', (value: any, context) => {
            if (
              context.parent.startDate &&
              dayjs(context.parent.startDate).isValid() &&
              !dayjs(value).isAfter(dayjs(context.parent.startDate), 'day')
            ) {
              return false;
            }
            return true;
          }),
    })
    .when(['isEditingClient', 'isEditMode', 'isCreatedClient'], {
      is: true,
      then: (schema) =>
        schema.test(
          'greater than today',
          'End date must be greater than today',
          (value: any, context) => {
            if (
              context.parent.existingStartDate &&
              dayjs(context.parent.existingStartDate).isBefore(dayjs(), 'day') &&
              ((context.parent.existingEndDate &&
                !dayjs(context.parent.existingEndDate).isBefore(dayjs(), 'day')) ||
                context.parent.existingEndDate === null) &&
              value &&
              dayjs(value).isBefore(dayjs())
            ) {
              return false;
            }
            return true;
          },
        ),
    }),
});

export const standardPricingFormSchema = yup.object({
  isPriceHistory: yup.boolean().nullable(),
  effectiveDate: yup
    .mixed()
    .required(REQUIRE_MESSAGE)
    .test('invalidDate', 'Invalid date', (date: any) => {
      return dayjs(date).isValid();
    })
    .when(
      ['currentEffectiveDate', 'isPriceHistory'],
      ([currentEffectiveDate, isPriceHistory], schema) => {
        if (isPriceHistory) return schema;

        return schema.test(
          'greater',
          `Effective date must be greater than the previously updated effective date (${dayjs(
            currentEffectiveDate,
          ).format(BILLING_TIME_FORMAT)})`,
          (date: any) => {
            return dayjs(date).isAfter(dayjs(currentEffectiveDate), 'day');
          },
        );
      },
    ),
});

export const editTieringFeeFormSchema = yup.object({
  noMax: yup.boolean(),
  tier1MaxVolume: yup
    .mixed()
    .required(REQUIRE_MESSAGE)
    .test('valid', 'Tier 1 max volume must be greater than tier 1 min volume', (value, context) => {
      if (value <= context.parent.tier1MinVolume) {
        return false;
      }
      return true;
    }),
  tier2MaxVolume: yup.mixed().when(['noMax'], {
    is: true,
    otherwise: (schema) =>
      schema
        .required(REQUIRE_MESSAGE)
        .test(
          'valid',
          'Tier 2 max volume must be greater than tier 2 min volume',
          (value, context) => {
            if (value <= context.parent.tier2MinVolume) {
              return false;
            }
            return true;
          },
        ),
  }),
});
