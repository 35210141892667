import { Box, Typography } from '@mui/material';
import React from 'react';
import { Bar } from 'react-chartjs-2';

interface CustomBarChartProps {
  data: any;
  options?: any;
}

export const barOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  barPercentage: 0.9,
  borderRadius: 5,
  categoryPercentage: 0.3,
  scales: {
    x: {
      beginAtZero: true,
      border: {
        dash: [6, 6],
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      border: {
        dash: [6, 6],
      },
    },
  },
};

const CustomBarChart: React.FC<CustomBarChartProps> = ({ data, options }) => {
  return (
    <>
      <Box className='flex absolute top-[15px] right-[15px]'>
        {data?.datasets.map((item: any, index: number) => {
          return (
            <Box className='flex items-center' key={index} mx={2}>
              <Box
                width='12px'
                height='12px'
                borderRadius='6px'
                bgcolor={item?.backgroundColor}
                mr='5px'
                fontWeight={500}
              />
              <Typography variant='body3' fontSize='10px' color='neutral.ne800'>
                {item?.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Bar options={{ ...barOptions, ...options }} data={data} />
    </>
  );
};

export default CustomBarChart;
