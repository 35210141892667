import { Alert, AlertProps, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface ICustomAlertProps extends AlertProps {}

const CustomAlert: React.FunctionComponent<ICustomAlertProps> = ({ children, sx, ...rest }) => {
  const theme = useTheme();

  const CUSTOM_BACKGROUND_COLOR_MAPPING = {
    success: theme.palette.success.light,
    info: theme.palette.info.light,
    error: theme.palette.error.light,
    warning: theme.palette.warning.light,
  };

  const CUSTOM_TEXT_COLOR_MAPPING = {
    success: theme.palette.success.main,
    info: theme.palette.info.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
  };

  return (
    <Alert
      variant='standard'
      sx={{
        backgroundColor: CUSTOM_BACKGROUND_COLOR_MAPPING[rest.severity || 'info'],
        borderRadius: '8px',
        padding: '8px 16px',
        alignItems: 'center',
        '& .MuiAlert-icon, .MuiAlert-message': {
          padding: 0,
        },
        '& .MuiAlert-icon': {
          mr: 1,
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography
        sx={{
          color: CUSTOM_TEXT_COLOR_MAPPING[rest.severity || 'info'],
        }}
        variant='body3'
      >
        {children}
      </Typography>
    </Alert>
  );
};

export default CustomAlert;
