import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import EmptyData from 'src/components/molecules/EmptyData';
import { generaValueFromMultipleFields } from 'src/helpers/common';
import { ColumnProps, StyledTableCell, StyledTableRow, TableProps, TableSort } from './CustomTable';

export interface RowProps<T = any> {
  columns: ColumnProps<T, any, any>[];
  row: T;
  idx?: number;
}

function Row(props: RowProps) {
  const { row, columns, idx: rowIdx } = props;
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode | null>(null);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: Number(rowIdx) % 2 !== 0 ? 'base.white' : 'base.bg',
        }}
      >
        {columns.map((column, index) => {
          const data = row[column?.key];
          const hasRenderNode = !!column.renderNode;
          const hasCollapseContent = column.allowCollapse && !!column.collapseContent;

          if (hasCollapseContent) {
            return (
              <TableCell key={`${index}-${column.title}`} width='5%' sx={{ borderBottom: 0 }}>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => {
                    !content && setContent(column?.collapseContent?.(row, rowIdx));
                    setOpen(!open);
                  }}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            );
          }
          return (
            <TableCell key={`${index}-${column.title}`} sx={{ borderBottom: 0, ...column.sx }}>
              {hasRenderNode ? column.renderNode?.(row, index) : (data as React.ReactNode)}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow sx={{ height: open ? 'auto' : 0 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            {content}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CustomCollapsibleTable = <T extends object>({
  columns,
  rows = [],
  onSort,
  displayEmpty = false,
  customEmpty,
  emptyDescription,
  tableClassName,
  sx,
}: TableProps<T>): React.ReactElement => {
  const [sort, setSort] = React.useState<Partial<TableSort<T>>>({
    sortType: 'asc',
  });

  const handleSort = (sortBy: keyof T) => {
    if (onSort) {
      const isAsc = sort.sortBy === sortBy && sort.sortType === 'asc';
      const sortType = isAsc ? 'desc' : 'asc';

      setSort({ sortType, sortBy });
      onSort(sortBy, sortType === 'asc');
      return;
    }

    const newSort: typeof sort = {
      sortBy,
    };
    switch (sort.sortType) {
      case 'asc':
        newSort.sortType = 'desc';
        break;

      default:
        newSort.sortType = 'asc';
        break;
    }
    setSort(newSort);
  };

  const sortedRows = React.useMemo(() => {
    const sortedRows = Array.isArray(rows) ? [...rows].filter((row: any) => !row?.disableSort) : [];
    const disableSortRows = [...rows]?.filter((row: any) => row?.disableSort) || [];

    if (onSort) {
      return rows;
    }

    const { sortBy } = sort;
    if (!sortBy) {
      return rows;
    }
    switch (sort.sortType) {
      case 'asc':
        sortedRows.sort((a, b) =>
          generaValueFromMultipleFields(a, sortBy)?.localeCompare(
            generaValueFromMultipleFields(b, sortBy),
          ),
        );

        break;

      default:
        sortedRows.sort((a, b) =>
          generaValueFromMultipleFields(b, sortBy)?.localeCompare(
            generaValueFromMultipleFields(a, sortBy),
          ),
        );

        break;
    }

    return [...sortedRows, ...disableSortRows];
  }, [sort, rows]);

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table className={tableClassName} aria-label='collapsible table' sx={sx}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const { sortBy, sx } = column;
              return (
                <TableCell key={`${index}-${column.key}`} sx={{ color: 'neutral.ne400', ...sx }}>
                  {column.sorter ? (
                    <TableSortLabel
                      active={sort.sortBy === column.key}
                      direction={sort.sortBy === column.key ? sort.sortType || undefined : 'asc'}
                      onClick={() => handleSort(sortBy)}
                    >
                      {column.title}
                    </TableSortLabel>
                  ) : (
                    <span>{column.title}</span> // Wrap the title in a <span> element or any other appropriate container
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {!!rows.length && (
          <TableBody>
            {sortedRows.map((row: any, idx: number) => (
              <Row
                key={`${idx}-${row.title}-collapsible-table`}
                row={row}
                columns={columns}
                idx={idx}
              />
            ))}
          </TableBody>
        )}
        {displayEmpty && !rows?.length && (
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={999}>
                {customEmpty ? customEmpty : <EmptyData isTable description={emptyDescription} />}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CustomCollapsibleTable;
