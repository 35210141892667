import { Avatar, Box, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { FavouriteActiveIcon } from 'src/assets/icons/FavouriteActiveIcon';
import { FavouriteInActiveIcon } from 'src/assets/icons/FavouriteInActiveIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import OptionsButton from 'src/components/atoms/OptionsButton';
import { DATE_FORMAT } from 'src/constants/date';
import {
  useFavouriteApplicationOffer,
  useRejectApplicationOffer,
  useRemoveApplicationOffer,
} from 'src/modules/applications/hooks';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  IOfferClientBranding,
  IOfferInvestorBranding,
  IOfferPreview,
} from 'src/modules/offer/type';
import { utcToLocalTimezone } from 'src/utils/time';

interface IOfferThumbnailProps {
  data?: IOfferPreview;
  branding?: IOfferClientBranding | IOfferInvestorBranding;
  viewOnly?: boolean;
  sx?: SxProps;
  hiddenTitle?: boolean;
  onClickOfferDetail?: () => void;
  onRefetchOfferList?: () => void;
  disabledAction?: boolean;
}

const OfferThumbnail: FC<IOfferThumbnailProps> = ({
  data,
  branding,
  viewOnly = false,
  sx,
  hiddenTitle,
  onClickOfferDetail,
  onRefetchOfferList,
  disabledAction = false,
}) => {
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();

  const theme = useTheme();
  const brandingColor = branding?.primaryBackground || theme.palette.primary.main;

  const { mutate: removeApplicationOfferMutate, isLoading: isRemoveApplicationOfferLoading } =
    useRemoveApplicationOffer();
  const { mutate: rejectApplicationOfferMutate, isLoading: isRejectApplicationOfferLoading } =
    useRejectApplicationOffer();
  const { mutate: favouriteApplicationOfferMutate } = useFavouriteApplicationOffer();

  const handleRemoveApplicationOffer = () => {
    modalConfirmRef?.current?.open();
    setConfirmFormat({
      title: 'Remove Offer?',
      content: `Are you sure you want to remove this offer?
      This action cannot be undone.`,
      button: {
        type: 'error',
        label: 'Remove',
        onAction: () =>
          removeApplicationOfferMutate(data?.id, {
            onSuccess: () => {
              onRefetchOfferList?.();
              modalConfirmRef?.current?.close();
            },
          }),
      },
    });
  };

  const handleRejectApplicationOffer = () => {
    modalConfirmRef?.current?.open();
    setConfirmFormat({
      title: 'Reject Offer?',
      content: `Are you sure you want to reject this offer?
      This action cannot be undone.`,
      button: {
        type: 'error',
        label: 'Reject',
        onAction: () =>
          rejectApplicationOfferMutate(data?.id, {
            onSuccess: () => {
              onRefetchOfferList?.();
              modalConfirmRef?.current?.close();
            },
          }),
      },
    });
  };

  const handleFavouriteOffer = (id?: string, isFavourite?: boolean) => {
    favouriteApplicationOfferMutate(
      { id, isFavourite },
      { onSuccess: () => onRefetchOfferList?.() },
    );
  };

  const options = disabledAction
    ? []
    : [
        { title: 'Remove', onClick: () => handleRemoveApplicationOffer() },
        ...(data?.isInvitation ? [{ title: 'Reject', onClick: handleRejectApplicationOffer }] : []),
      ];

  return (
    <Box sx={sx}>
      {!hiddenTitle && (
        <Typography variant='body2' fontWeight='medium'>
          Offer Thumbnail
        </Typography>
      )}
      <Box className='thumbnailHeader h-[110px] relative pt-6'>
        <img
          src={data?.bannerUrl}
          alt='Banner'
          className='w-full h-full block object-cover rounded-t-xl'
        />
        {data?.logoUrl && (
          <Avatar
            className='absolute bottom-[-24px] left-4'
            alt='Logo'
            src={data?.logoUrl}
            sx={{
              width: 80,
              height: 80,
            }}
          />
        )}
        <IconButton
          onClick={() => handleFavouriteOffer(data?.id, data?.isFavourite)}
          className='absolute top-8 right-2'
        >
          {data?.isFavourite ? <FavouriteActiveIcon /> : <FavouriteInActiveIcon />}
        </IconButton>
      </Box>
      <Box
        className='thumbnailBody flex flex-col pt-8 px-4 pb-4 border border-t-0 rounded-b-xl '
        borderColor='neutral.ne200'
      >
        <Box className='min-h-[220px]'>
          <Typography variant='body2' fontWeight='bold'>
            {data?.fundName}
          </Typography>
          <Typography variant='caption1' color='neutral.ne800'>
            {data?.name}
          </Typography>
          <Box className='pt-3 pb-2'>
            <Typography
              component='div'
              variant='caption1'
              className='line-clamp-5'
              dangerouslySetInnerHTML={{ __html: data?.description || '' }}
            />
          </Box>
          {data?.isInvitation && (
            <Box className='py-1 px-3 w-fit rounded flex items-center' bgcolor='success.light'>
              <Typography color='success.main' variant='caption1'>
                Invitation
              </Typography>
            </Box>
          )}
          <Typography variant='caption2' color='neutral.ne500' paddingTop={1}>
            {data?.isOpenEnded
              ? 'Open-ended'
              : `Expires on ${utcToLocalTimezone(data?.expirationDate || '', DATE_FORMAT)}`}
          </Typography>
        </Box>
        <Box className='pt-6'>
          <OptionsButton
            variant='outlined'
            size='small'
            sx={{
              color: brandingColor,
              borderColor: brandingColor,
              '&.Mui-disabled': {
                color: brandingColor,
                borderColor: brandingColor,
              },
            }}
            optionIconColor={brandingColor}
            disabled={viewOnly}
            onClick={onClickOfferDetail}
            options={options}
          >
            Offer Details
          </OptionsButton>
        </Box>
        <ConfirmModal
          ref={modalConfirmRef}
          title={confirmFormat?.title}
          content={confirmFormat?.content}
          ButtonsComponent={
            <>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={() => modalConfirmRef?.current?.close()}
              >
                Cancel
              </CustomButton>
              <CustomButton
                color={confirmFormat?.button?.type}
                isLoading={isRemoveApplicationOfferLoading || isRejectApplicationOfferLoading}
                onClick={confirmFormat?.button?.onAction}
              >
                {confirmFormat?.button?.label}
              </CustomButton>
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default OfferThumbnail;
