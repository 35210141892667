/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useQueryClient } from '@tanstack/react-query';
import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import { USER_MANAGEMENT_API } from 'src/constants/apiEndpoints';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { portalApi } from 'src/helpers/axios';
import { detectPortalType } from 'src/helpers/common';
import * as StorageService from 'src/helpers/storage';
import { DELEGATE_VIEW_MODE, INVESTOR_VIEW_MODE } from 'src/modules/users-management/consts';
import { useGetInvestorUserEntities } from 'src/modules/users-management/hooks';
import { InvestmentEntity, UserEntities } from 'src/modules/users-management/type';

export type ViewMode = typeof DELEGATE_VIEW_MODE | typeof INVESTOR_VIEW_MODE;

export const InvestorViewModeContext = React.createContext<{
  viewMode: ViewMode;
  onChangeViewMode: (viewMode: ViewMode) => void;
  investmentEntities: string[] | string;
  onChangeInvestmentEntities: (investmentEntities: string[] | string) => void;
  entities?: UserEntities;
  disableShowDuplicationPopup: boolean;
  setDisableShowDuplicationPopup: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  viewMode: INVESTOR_VIEW_MODE,
  onChangeViewMode: () => {},
  investmentEntities: [],
  onChangeInvestmentEntities: () => {},
  entities: undefined,
  disableShowDuplicationPopup: false,
  setDisableShowDuplicationPopup: () => {},
});

interface IInvestorViewModeProvider {
  children: React.ReactNode;
}

const X_VIEW_MODE_HEADER = 'x-view-mode';
const X_ENTITY_IDS_HEADER = 'x-entity-ids';

const InvestorViewModeProvider: React.FC<IInvestorViewModeProvider> = ({ children }) => {
  const { isInvestor } = detectPortalType();
  const { data: entities } = useGetInvestorUserEntities();
  const [viewMode, setViewMode] = useState<ViewMode>(INVESTOR_VIEW_MODE);
  const [investmentEntities, setInvestmentEntities] = useState<string[] | string>([]);
  const queryClient = useQueryClient();
  const [disableShowDuplicationPopup, setDisableShowDuplicationPopup] = useState(false);

  useEffect(() => {
    onSetDefaultInvestmentEntity(viewMode);
  }, [viewMode]);

  const handleResetQueries = () => {
    queryClient.resetQueries({
      predicate: (q) => !q.queryKey?.includes(USER_MANAGEMENT_API.investorUserEntities.api),
    });
  };

  const onChangeInvestmentEntities = (
    investmentEntities: string[] | string,
    resetQueries = true,
  ) => {
    if (isInvestor) {
      investmentEntities !== MULTIPLE_SELECT_EMPTY &&
        (portalApi.defaults.headers.common[X_ENTITY_IDS_HEADER] = investmentEntities.toString());
      if (viewMode === DELEGATE_VIEW_MODE) {
        StorageService.setValue(
          STORAGE_KEYS.DELEGATE_INVESTMENT_ENTITY,
          investmentEntities as string,
        );
      }

      setInvestmentEntities(investmentEntities);
      resetQueries &&
        setTimeout(() => {
          handleResetQueries();
        }, 0);
    }
  };

  const onSetDefaultInvestmentEntity = (viewMode: ViewMode) => {
    const firstEntity = _get(entities, DELEGATE_VIEW_MODE, []).map(
      (entity: InvestmentEntity) => entity.id,
    );
    const storageDelegateInvestmentEntity = StorageService.getValue(
      STORAGE_KEYS.DELEGATE_INVESTMENT_ENTITY,
    );
    if (viewMode === DELEGATE_VIEW_MODE && firstEntity) {
      if (storageDelegateInvestmentEntity) {
        onChangeInvestmentEntities(storageDelegateInvestmentEntity, false);
      } else {
        // MUST have at least one selected entity
        onChangeInvestmentEntities(firstEntity[0], false);
      }
    } else if (viewMode === INVESTOR_VIEW_MODE) {
      const allInvestorEntities = _get(entities, INVESTOR_VIEW_MODE, []).map(
        (entity: InvestmentEntity) => entity.id,
      );
      onChangeInvestmentEntities(allInvestorEntities);
    }
  };

  const onChangeViewMode = (viewMode: ViewMode) => {
    if (isInvestor) {
      StorageService.setValue(STORAGE_KEYS.INVESTOR_VIEW_MODE, viewMode);
      viewMode === INVESTOR_VIEW_MODE &&
        StorageService.destroyValue(STORAGE_KEYS.DELEGATE_INVESTMENT_ENTITY);
      portalApi.defaults.headers.common[X_VIEW_MODE_HEADER] = viewMode;
      onSetDefaultInvestmentEntity(viewMode);
      setViewMode(viewMode);
      setTimeout(() => {
        handleResetQueries();
      }, 0);
    }
  };

  React.useEffect(() => {
    if (entities) {
      const storageInvestorViewMode = StorageService.getValue(STORAGE_KEYS.INVESTOR_VIEW_MODE);
      storageInvestorViewMode && onChangeViewMode(storageInvestorViewMode as ViewMode);
    }
  }, [entities]);

  return (
    <InvestorViewModeContext.Provider
      value={{
        viewMode,
        onChangeViewMode,
        investmentEntities,
        onChangeInvestmentEntities,
        entities,
        disableShowDuplicationPopup,
        setDisableShowDuplicationPopup,
      }}
    >
      {children}
    </InvestorViewModeContext.Provider>
  );
};

export default InvestorViewModeProvider;
