/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Link, Skeleton, Typography } from '@mui/material';
import { cloneDeep, get, isEmpty, omit } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { AcceptanceIcon } from 'src/assets/icons/AcceptanceIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import InvestmentApplicationTeamIcon from 'src/assets/icons/InvestmentApplicationTeamIcon';
import { KYCIcon } from 'src/assets/icons/KYCIcon';
import MoneyBagIcon from 'src/assets/icons/MoneyBagIcon';
import PaidSearchIcon from 'src/assets/icons/PaidSearchIcon';
import StatsIcon from 'src/assets/icons/StatsIcon';
import { BlueDoneImage } from 'src/assets/images/BlueDoneImage';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import Acceptance from 'src/components/pages/applications/components/Acceptance';
import Additional from 'src/components/pages/applications/components/Addtional';
import AmlKyc from 'src/components/pages/applications/components/AmlKyc';
import CustomFormSectionHeader from 'src/components/pages/applications/components/CustomFormSectionHeader';
import Details from 'src/components/pages/applications/components/Details';
import FinancialAndTaxDetails from 'src/components/pages/applications/components/FinancialAndTaxDetails';
import FinancialDetails from 'src/components/pages/applications/components/FinancialDetails';
import FundraisingApplicationTeam from 'src/components/pages/applications/components/FundraisingTeam';
import {
  GOVERNMENT_BODY_TYPE,
  INVESTMENT_ENTITY_APPOINTER_TYPE,
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_ROLE,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE,
  InvestmentEntityCompanyRegionType,
  additionalDefaultValues,
  applicationAcceptanceDefaultValue,
  applicationDetailsDefaultValue,
  applicationTeamDefaultValues,
  financialDetailsDefaultValues,
  financialTaxDetailsDefaultValues,
  fundKYCAMLDefaultValue,
  investmentEntityDefaultValues,
} from 'src/constants/applications';
import { IErrorResponse } from 'src/interfaces/common';
import {
  useGetApplicationById,
  useGetApplicationOfferUnitClassDetail,
  useGetContactListByTeamType,
  useGetInvestorOfferDetailsPreview,
  useLetterFile,
  useSubmitApplication,
  useUpsertApplicationAcceptance,
  useUpsertApplicationAdditional,
  useUpsertApplicationDetail,
  useUpsertFinancialAndTaxDetails,
  useUpsertFinancialDetail,
  useUpsertFundApplicationKYC,
  useUpsertInvestmentEntity,
  useUpsertTeam,
} from 'src/modules/applications/hooks';
import {
  ELetterType,
  IAcceptanceForm,
  IApplicationAddtional,
  IApplicationDetails,
  IApplicationFinancialDetail,
  IFinancialTaxDetailsForm,
  IFundKYCAMLForm,
  IInvestmentEntityForm,
  ITeam,
  ITeamError,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo } from 'src/modules/common/type';
import {
  currencyStringToNumber,
  getErrorMessageFromServer,
  handleErrorFromServer,
} from 'src/utils/common';
import {
  additionalSchema,
  applicationAcceptanceSchema,
  finacialAndTaxDetailsSchema,
  fundAMLKYCSchema,
  investmentEntityValidationSchema,
} from 'src/validations/applications';
import { createFundApplicationDetailsSchema } from 'src/validations/fundraising-application';
import { financialDetailsSchema } from 'src/validations/offer';
import FundInvestmentEntity from './FundInvestmentEntity';

interface ICreateFundraisingApplicationFormProps extends IUpsertInvestmentApplicationForm {
  onClose: (isOpen: boolean) => void;
}

export enum INVESTMENT_APPLICATION_STEP {
  DETAILS,
  INVESTMENT_ENTITY,
  FINANCIAL_DETAILS,
  TEAM,
  ADDITIONAL,
  ACCEPTANCE,
  KYC_VERIFICATION,
}

export enum FINANCIAL_STEP {
  FINANCIAL_DETAILS,
  TAX_DETAILS,
}

type IFormData = {
  [key in INVESTMENT_APPLICATION_STEP]: {
    form: UseFormReturn<any>;
    isDirty?: boolean;
    isValid?: boolean;
    submit: (mode: string) => void;
  };
};

interface IOfferInfo {
  offerId: string;
  fundId: string;
  unitClassId: string;
}

const CreateFundraisingApplicationForm: FC<ICreateFundraisingApplicationFormProps> = ({
  isEditMode: isEditing,
  isViewMode,
  isDraft: isDraftStatus,
  id = '',
  offerId = '',
  unitClassId = '',
  fundId = '',
  onClose,
}) => {
  const stepperRef = useRef<ICustomStepperElement>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const alertAmountInvestmentRef = useRef<IBasicModalElement>(null);
  const [applicationId, setApplicationId] = useState(id);
  const [isEditMode, setIsEditMode] = useState(isEditing);
  const [isDraft, setIsDraft] = useState(isDraftStatus);
  const [lastStep, setLastStep] = useState(INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION);
  const [excludeSteps, setExcludeSteps] = useState<
    | {
        [key in INVESTMENT_APPLICATION_STEP]?: boolean;
      }
    | null
  >();

  const defaultAlertBtnAction = {
    label: 'OK',
    onAction: () => {
      alertRef?.current?.close();
      onClose?.(false);
    },
  };
  const [alertBtnAction, setAlertBtnAction] = useState(defaultAlertBtnAction);
  const [offerInfo, setOfferInfo] = useState<IOfferInfo>({
    offerId,
    unitClassId,
    fundId,
  });
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(INVESTMENT_APPLICATION_STEP.DETAILS);
  const [teamErrors, setTeamErrors] = useState<ITeamError>({
    isPrimaryContactError: false,
    isTeamError: false,
    isCommunicationListError: false,
  });
  const [submittedAttachments, setSubmittedAttachments] = useState<{ name: string; src: string }[]>(
    [],
  );
  const [isAddNewEntityDetail, setIsAddNewEntityDetail] = useState(false);
  const { mutateAsync: getLetterFile, isLoading: getLetterLoading } = useLetterFile();

  const { mutate: upsertTeam, isLoading: upsertingTeam } = useUpsertTeam();

  const { data: applicationDetail } = useGetApplicationById(applicationId, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });
  const { data: offerPreviewData } = useGetInvestorOfferDetailsPreview(
    offerInfo.offerId,
    offerInfo.unitClassId,
  );

  const detailsForm = useForm<IApplicationDetails>({
    defaultValues: applicationDetailsDefaultValue,
    resolver: yupResolver(createFundApplicationDetailsSchema),
  });
  const investmentEntityForm = useForm<IInvestmentEntityForm>({
    defaultValues: investmentEntityDefaultValues,
    resolver: yupResolver(investmentEntityValidationSchema),
  });
  const teamForm = useForm<ITeam>({
    defaultValues: applicationTeamDefaultValues,
  });
  const financialDetailsForm = useForm<IApplicationFinancialDetail>({
    defaultValues: financialDetailsDefaultValues,
    resolver: yupResolver(financialDetailsSchema),
  });
  const financialTaxDetailsForm = useForm<IFinancialTaxDetailsForm>({
    defaultValues: financialTaxDetailsDefaultValues,
    resolver: yupResolver(finacialAndTaxDetailsSchema),
  });
  const additionalForm = useForm<IApplicationAddtional>({
    defaultValues: additionalDefaultValues,
    resolver: yupResolver(additionalSchema),
  });

  const acceptanceForm = useForm<IAcceptanceForm>({
    defaultValues: applicationAcceptanceDefaultValue,
    resolver: yupResolver(applicationAcceptanceSchema),
  });
  const fundraisingAmlKycForm = useForm<IFundKYCAMLForm>({
    defaultValues: fundKYCAMLDefaultValue,
    resolver: yupResolver(fundAMLKYCSchema),
  });

  const investorType = detailsForm.watch('investorType');
  const investmentAmount = detailsForm.watch('investmentAmount');
  const investmentEntityId =
    investmentEntityForm.watch('investmentEntityId') ||
    detailsForm.watch('investmentEntityId') ||
    '';
  const watchHasAuthToApplication = investmentEntityForm.watch(
    'editorDetails.hasAuthorityToComplete',
  );
  const watchUserType = investmentEntityForm.watch('editorDetails.userType');
  const teamMates = teamForm.watch('teammates');
  const isForeignTaxResident = detailsForm.watch('isForeignTaxResident');
  const entityType = investmentEntityForm.watch('entityType');
  const watchIsExistingInvestorInFund = detailsForm.watch('isExistingInvestorInFund');
  const isExistingInvestor = detailsForm.watch('isExistingInvestorInFund');
  const investorId = detailsForm.watch('investorId');

  const { data: applicationOfferUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    offerInfo.offerId || detailsForm?.watch('offerId'),
    offerInfo.unitClassId || detailsForm?.watch('unitClassId'),
  );

  const isWithoutFinancialAndTaxDetails = [
    INVESTMENT_ENTITY_TYPE.Individuals,
    INVESTMENT_ENTITY_TYPE.SoleTrader,
  ].includes(entityType);

  const investmentEntityError: any = investmentEntityForm.formState.errors;
  const isInvestmentEntityError =
    JSON.stringify(investmentEntityError).includes('"type":"foreignError"') ||
    (watchUserType === INVESTMENT_ENTITY_ROLE.Delegate && !watchHasAuthToApplication);

  const financialTaxError = financialTaxDetailsForm.formState?.errors || {};

  const { mutateAsync: upsertApplicationDetailMutate, isLoading: upsertApplicationDetailLoading } =
    useUpsertApplicationDetail();
  const { mutateAsync: upsertInvestmentEntity, isLoading: upsertInvestmentEntityLoading } =
    useUpsertInvestmentEntity();
  const { mutateAsync: upsertFinancialDetail, isLoading: upsertOfferDetailsLoading } =
    useUpsertFinancialDetail();
  const {
    mutateAsync: upsertApplicationAcceptancel,
    isLoading: upsertApplicationAcceptanceLoading,
  } = useUpsertApplicationAcceptance();
  const { primaryContacts, teams, communicationList } = useGetContactListByTeamType(teamMates);
  const { mutateAsync: upsertApplicationAdditionalMutate, isLoading: upsertAdditionalLoading } =
    useUpsertApplicationAdditional();

  const { mutate: submitApplicationMutate, isLoading: isSubmitApplicationLoading } =
    useSubmitApplication();
  const { mutate: upsertFinancialAndTaxDetails, isLoading: upsertingFinancialAndTaxDetails } =
    useUpsertFinancialAndTaxDetails();
  const { mutate: upsertFundApplicationKYC, isLoading: upsertingFundApplicationKYC } =
    useUpsertFundApplicationKYC();

  const isLoading =
    upsertApplicationDetailLoading ||
    upsertInvestmentEntityLoading ||
    upsertOfferDetailsLoading ||
    upsertApplicationAcceptanceLoading ||
    upsertAdditionalLoading ||
    isSubmitApplicationLoading ||
    upsertingFinancialAndTaxDetails ||
    upsertingFundApplicationKYC ||
    upsertingTeam;

  const isCreateMode = !isEditMode && !isViewMode;
  const isExistingInvestmentEntity = !!investmentEntityId && !isAddNewEntityDetail;

  useEffect(() => {
    setIsEditMode(isEditing);
  }, [isEditing]);

  useEffect(() => {
    setIsDraft(isDraftStatus);
  }, [isDraftStatus]);

  useEffect(() => {
    handleTeamValidation();
  }, [teamMates]);

  useEffect(() => {
    if (isEmpty(applicationDetail) || isCreateMode) return;
    const { fundId, unitClassId, offerId } = applicationDetail;

    setOfferInfo({
      fundId,
      unitClassId,
      offerId,
    });
  }, [applicationDetail]);

  useEffect(() => {
    if (watchIsExistingInvestorInFund) {
      setExcludeSteps({ [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: true });
    }
  }, [watchIsExistingInvestorInFund]);

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleShowAlert = ({ title, description }: IAlertInfo) => {
    setAlertInfo({ title, description });
    alertRef.current?.open();
  };

  const onSubmitDetails = async (mode: string) => {
    const data = cloneDeep(detailsForm.getValues());

    await upsertApplicationDetailMutate(
      {
        data,
        id: applicationId,
      },
      {
        onSuccess: (data: any) => {
          if (data?.id) {
            setApplicationId(data?.id);
          }
          if (data?.validation) {
            setAlertInfo({
              ...alertInfo,
              isError: false,
              title: 'Requires Approval!',
              image: <BlueDoneImage />,
              description: data?.validation,
            });
            alertAmountInvestmentRef.current?.open();
          } else {
            handleSuccess(mode);
          }
        },
        onError: (error: IErrorResponse | unknown) => {
          const errorResponse = error as IErrorResponse;
          setAlertInfo({
            ...alertInfo,
            isError: true,
            description: errorResponse?.data?.ErrorMessage,
          });
          alertAmountInvestmentRef.current?.open();
        },
      },
    );
  };

  const getInvestmentEntitySubmitData = () => {
    const data = cloneDeep(investmentEntityForm.getValues());
    if (data.investmentEntityDetails?.australianCompany?.certifiedAgreementFile?.id) {
      delete data.investmentEntityDetails.australianCompany.certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (data.investmentEntityDetails?.unregulatedTrust?.certifiedTrustDeed?.id) {
      delete data.investmentEntityDetails.unregulatedTrust.certifiedTrustDeed;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.appointerAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.smsf?.trusteeAustralianCompanyDetails?.certifiedAgreementFile
        ?.id
    ) {
      delete data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }

    if (data.investmentEntityDetails.partnership?.certifiedAgreementFileId) {
      delete data.investmentEntityDetails.partnership.certifiedAgreementFile;
    }

    if (
      data.companyRegionType === InvestmentEntityCompanyRegionType.Australian &&
      data.investmentEntityDetails?.australianCompany?.directors
    ) {
      data.investmentEntityDetails.australianCompany.ubOs = [
        ...data.investmentEntityDetails.australianCompany.directors.filter((it) => it.refId),
        ...(data.investmentEntityDetails.australianCompany.ubOs || []),
      ];
    }

    if (
      data.companyRegionType === InvestmentEntityCompanyRegionType.Foreign &&
      data.investmentEntityDetails?.foreignCompany?.directors
    ) {
      data.investmentEntityDetails.foreignCompany.ubOs = [
        ...data.investmentEntityDetails.foreignCompany.directors.filter((it) => it.refId),
        ...(data.investmentEntityDetails.foreignCompany.ubOs || []),
      ];
    }

    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.regulatedTrust?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs || []),
      ];
      data.investmentEntityDetails.regulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs,
        ...(data.investmentEntityDetails.regulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.regulatedTrust?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.regulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeIndividuals,
        ...(data.investmentEntityDetails.regulatedTrust.trustUBOs || []),
      ];
    }

    if (data.investmentEntityDetails?.unregulatedTrust?.trustUBOs) {
      data.investmentEntityDetails.unregulatedTrust.trustUBOs =
        data.investmentEntityDetails.unregulatedTrust.trustUBOs.map((it) => ({
          ...it,
          uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.AddNew,
        }));
    }

    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs ||
          []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.map(
          (it) => ({
            ...it,
            uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.CorporateTrustee,
          }),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeIndividuals,
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (data.investmentEntityDetails?.unregulatedTrust?.isSettlorMoreThanMax) {
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        {
          ...data.investmentEntityDetails.unregulatedTrust.settlor,
          uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.Settlor,
        },
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    } else if (data.investmentEntityDetails?.unregulatedTrust?.settlor) {
      (data as any).investmentEntityDetails.unregulatedTrust.settlor = null;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
      data.investmentEntityDetails?.unregulatedTrust?.isAppointerSameAsTrustee &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails =
        data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails;
      data.investmentEntityDetails.unregulatedTrust.appointerForeignCompanyDetails =
        data.investmentEntityDetails.unregulatedTrust.trusteeForeignCompanyDetails;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
      ((!data.investmentEntityDetails?.unregulatedTrust?.isAppointerSameAsTrustee &&
        data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) ||
        data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual)
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs ||
          []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs.map(
          (it) => ({
            ...it,
            uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.CorporateAppointer,
          }),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Individual
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerIndividuals = [
        ...(
          data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors ||
          []
        )
          .concat(
            data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs ||
              [],
          )
          .concat(data.investmentEntityDetails.unregulatedTrust.trusteeIndividuals || [])
          .filter(
            (it) =>
              it.refId &&
              data.investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals?.includes(
                it.refId,
              ),
          ),
        ...(data.investmentEntityDetails.unregulatedTrust.appointerIndividuals || []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerIndividuals.filter(
          (it) =>
            it.refId &&
            !data.investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals?.includes(
              it.refId,
            ),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }

    if (
      data.investmentEntityDetails?.smsf?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.smsf?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs || []),
      ];
      data.investmentEntityDetails.smsf.smsfubOs = [
        ...data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs,
        ...(data.investmentEntityDetails.smsf.smsfubOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.smsf?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.smsf?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.smsf.smsfubOs = [
        ...data.investmentEntityDetails.smsf.trusteeIndividuals,
        ...(data.investmentEntityDetails.smsf.smsfubOs || []),
      ];
    }

    if (data.investmentEntityDetails?.association?.publicOfficers) {
      data.investmentEntityDetails.association.ubOs = [
        ...data.investmentEntityDetails.association.publicOfficers.filter((it) => it.refId),
        ...(data.investmentEntityDetails?.association?.ubOs || []),
      ];
    }

    if (data.investmentEntityDetails?.governmentBody?.publicOfficers) {
      data.investmentEntityDetails.governmentBody.ubOs = [
        ...(data.investmentEntityDetails.governmentBody.type === GOVERNMENT_BODY_TYPE.Foreign
          ? data.investmentEntityDetails.governmentBody.publicOfficers.filter((it) => !!it?.refId)
          : []),
        ...(data.investmentEntityDetails.governmentBody.ubOs || []),
      ];
      data.investmentEntityDetails.governmentBody.publicOfficers = [
        ...data.investmentEntityDetails.governmentBody.publicOfficers.map(({ refId, ...rest }) =>
          data.investmentEntityDetails.governmentBody.type === GOVERNMENT_BODY_TYPE.Foreign
            ? { refId, ...rest }
            : rest,
        ),
      ];
    }
    return data;
  };

  const onSubmitInvestmentEntity = async (mode: string) => {
    const data = getInvestmentEntitySubmitData();

    if (data.investmentEntityDetails.partnership?.partners) {
      data.investmentEntityDetails.partnership.ubOs = [
        ...data.investmentEntityDetails.partnership.partners.filter((item) => !!item?.refId),
        ...(data.investmentEntityDetails?.partnership?.ubOs || []),
      ];
    }

    if (data.entityType === INVESTMENT_ENTITY_TYPE.Partnership) {
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFile =
        data.investmentEntityDetails.partnership?.certifiedAgreementFile || undefined;
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFileDescription =
        data.investmentEntityDetails.partnership.certifiedAgreementFileDescription;
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFileId =
        data.investmentEntityDetails.partnership?.certifiedAgreementFileId || undefined;
    }

    await upsertInvestmentEntity(
      { data, id: applicationId },
      {
        onSuccess: () => {
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitTeam = async (mode: string) => {
    const data = teamForm.getValues();
    if (handleTeamValidation()) {
      await upsertTeam(
        { data, id: applicationId },
        {
          onSuccess: () => {
            handleSuccess(mode);
          },
          onError: (error: unknown | IErrorResponse) => {
            const message = getErrorMessageFromServer(error);
            setTeamErrors((prevState) => ({ ...prevState, errorMessageFromServer: message }));
            handleErrorFromServer(error);
          },
        },
      );
    }
  };

  const onSubmitFinancialDetails = async (mode: string) => {
    const data = financialDetailsForm.getValues();
    if (isExistingInvestmentEntity) return handleSuccess(mode); // Not allow to change Financial Details when selecting exist entity so dont need to submit api
    await upsertFinancialDetail(
      {
        id: applicationId,
        data,
      },
      {
        onSuccess: () => {
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitFinancialAndTaxDetails = async (mode: string) => {
    const formValues = financialTaxDetailsForm.getValues();
    const financialDetailsData: IApplicationFinancialDetail = {
      ...formValues?.financialDetails,
      isSaveDraft: !!formValues.isSaveDraft,
    };
    const financialTaxDetailsData = cloneDeep(omit(formValues, ['financialDetails']));
    const { foreignControllingPersons, isRequiredDeclarationOnly, ...restFinancialTaxDetailsData } =
      financialTaxDetailsData;
    const { hasControllingPersonsOtherThanAustralia, directors = [] } =
      foreignControllingPersons || {};
    const upsertFinancialTaxDetailsData: IFinancialTaxDetailsForm = {
      ...restFinancialTaxDetailsData,
      foreignControllingPersons: {
        ...foreignControllingPersons,
        directorIds: hasControllingPersonsOtherThanAustralia ? directors.map((it) => it.id) : [],
      },
    };

    if (isRequiredDeclarationOnly) {
      delete upsertFinancialTaxDetailsData.taxStatus;
      delete upsertFinancialTaxDetailsData.foreignControllingPersons;
      delete upsertFinancialTaxDetailsData.entityCountryOfTaxResidency;
    }

    if (isExistingInvestmentEntity) {
      // Not allow to change Financial Details when selecting exist entity so dont need to submit api
      return upsertFinancialAndTaxDetails(
        {
          id: applicationId,
          data: upsertFinancialTaxDetailsData,
        },
        {
          onSuccess: () => handleSuccess(mode),
          onError: handleErrorFromServer,
        },
      );
    }
    return upsertFinancialDetail(
      {
        id: applicationId,
        data: financialDetailsData,
      },
      {
        onSuccess: () => {
          upsertFinancialAndTaxDetails(
            {
              id: applicationId,
              data: upsertFinancialTaxDetailsData,
            },
            {
              onSuccess: () => handleSuccess(mode),
              onError: handleErrorFromServer,
            },
          );
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleUpdateAddtionalDocumentsAfterSubmit = (additionalData: IApplicationAddtional) => {
    additionalForm.reset({
      ...additionalData,
      documents: additionalData.documents.map((item) => ({
        ...item,
        isAddNew: false,
      })),
    });
  };

  const onSubmitAdditional = async (mode: string) => {
    const { description, ...data } = additionalForm.getValues();
    const documents = data.documents
      .filter((item) => item.isAddNew)
      .map(({ uploadedBy, uploadedDate, isAddNew, ...rest }) => rest);

    await upsertApplicationAdditionalMutate(
      {
        id: applicationId,
        data: {
          ...data,
          documents,
        },
      },
      {
        onSuccess: () => {
          handleUpdateAddtionalDocumentsAfterSubmit(data);
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleTeamValidation = () => {
    setTeamErrors({
      isPrimaryContactError: primaryContacts.length === 0,
      isTeamError: false,
      isCommunicationListError: communicationList.length === 0,
    });
    if (primaryContacts.length && communicationList.length) {
      return true;
    }
    return false;
  };

  const onSubmitAcceptance = async (mode: string) => {
    const submitData = acceptanceForm.getValues();
    const signatories = submitData.signatories;
    if (signatories) {
      await upsertApplicationAcceptancel(
        {
          id: applicationId,
          data: { signatories, isSaveDraft: submitData.isSaveDraft },
        },
        {
          onSuccess: () => {
            handleSuccess(mode);
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const onSubmitKYC = async () => {
    onClose?.(false);
  };

  const onSubmitFundAMLKYC = (mode: string) => {
    const data = fundraisingAmlKycForm.getValues();

    upsertFundApplicationKYC(
      {
        id: applicationId,
        data,
      },
      {
        onSuccess: () => {
          if (isDraftStatus || isCreateMode) {
            handleSubmitApplication(mode);
          }
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const formData: IFormData = {
    [INVESTMENT_APPLICATION_STEP.DETAILS]: {
      form: detailsForm,
      isDirty: detailsForm.formState.isDirty,
      isValid: detailsForm.formState.isValid,
      submit: onSubmitDetails,
    },
    [INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY]: {
      form: investmentEntityForm,
      isDirty: investmentEntityForm.formState.isDirty,
      isValid: investmentEntityForm.formState.isValid,
      submit: onSubmitInvestmentEntity,
    },
    [INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS]: isWithoutFinancialAndTaxDetails
      ? {
          form: financialDetailsForm,
          isDirty: financialDetailsForm.formState.isDirty,
          isValid: financialDetailsForm.formState.isValid,
          submit: onSubmitFinancialDetails,
        }
      : {
          form: financialTaxDetailsForm,
          isDirty: financialTaxDetailsForm.formState.isDirty,
          isValid: financialTaxDetailsForm.formState.isValid,
          submit: onSubmitFinancialAndTaxDetails,
        },
    [INVESTMENT_APPLICATION_STEP.TEAM]: {
      form: teamForm,
      isDirty: teamForm.formState.isDirty,
      isValid: teamForm.formState.isValid,
      submit: onSubmitTeam,
    },
    [INVESTMENT_APPLICATION_STEP.ADDITIONAL]: {
      form: additionalForm,
      isDirty: additionalForm.formState.isDirty,
      isValid: additionalForm.formState.isValid,
      submit: onSubmitAdditional,
    },
    [INVESTMENT_APPLICATION_STEP.ACCEPTANCE]: {
      form: acceptanceForm,
      isDirty: acceptanceForm.formState.isDirty,
      isValid: acceptanceForm.formState.isValid,
      submit: onSubmitAcceptance,
    },
    [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: {
      form: fundraisingAmlKycForm,
      isDirty: fundraisingAmlKycForm.formState.isDirty,
      isValid: fundraisingAmlKycForm.formState.isValid,
      submit: onSubmitFundAMLKYC,
    },
  };

  const handleSave = (stepSelected: INVESTMENT_APPLICATION_STEP, mode: string) => {
    const viewOnlyWhenExistingEntityStep = isWithoutFinancialAndTaxDetails
      ? [INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS]
      : [];

    const isViewOnlyWhenExistingInvestmentEntity =
      investmentEntityId && viewOnlyWhenExistingEntityStep.includes(stepSelected);

    if (isViewMode || (isViewOnlyWhenExistingInvestmentEntity && (isDraft || isCreateMode))) {
      return mode === SUBMIT_FORM_ACTIONS.SUBMIT
        ? handleGoToNextStep()
        : handleSuccess(SUBMIT_FORM_ACTIONS.DRAFT);
    }
    if (isEditMode && !isDraft) {
      if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
        handleSetErrorSteps();
        handleGoToNextStep();
      } else {
        handleApplicationSubmitAllSection();
      }
      return;
    }
    const { form, submit } = formData[stepSelected];
    form.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
    form.handleSubmit(() => {
      submit(mode);
    })();
  };

  const handleSubmitApplication = (mode: string) => {
    const currentStep = stepperRef?.current?.getCurrentStep();
    const isLastStep = [
      INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
      INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION,
    ].includes(currentStep as number);
    if (isLastStep && mode === SUBMIT_FORM_ACTIONS.SUBMIT) {
      submitApplicationMutate(
        {
          id: applicationId,
          callback: () => {
            setIsEditMode(true); // Update Application Mode
            setIsDraft(false); // Update Application Status
          },
        },
        {
          onSuccess: async (data: any) => {
            const detailData = detailsForm.getValues();
            const investmentData = investmentEntityForm.getValues();
            const isInvestorExist = detailData.isExistingInvestorInFund;
            const isGovernmentForeign =
              investmentData.otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody &&
              get(
                investmentData.companyRegionType,
                'investmentEntityDetails.governmentBody.type',
              ) === GOVERNMENT_BODY_TYPE.Foreign;

            // remove dirty
            Object.values(formData).forEach(({ form }) =>
              form.reset((formState: any) => formState, { keepDirty: false }),
            );

            if (data?.isDuplicated) {
              handleShowAlert({
                title: 'Duplicated application!',
                description: `Your application is pending approval.`,
              });
            } else if (isInvestorExist) {
              setExcludeSteps({ [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: true });
              handleShowAlert({
                title: 'Submitted successfully!',
                description: `Your application has been submitted successfully. To progress with your application, please deposit the investment amount to the nominated bank account.`,
              });
            } else {
              if (isGovernmentForeign) {
                setExcludeSteps({ [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: true });
                handleShowAlert({
                  title: 'Submitted successfully!',
                  description: `Your application has been submitted successfully. To progress with your application, please deposit the investment amount to the nominated bank account.`,
                });
              } else {
                setExcludeSteps({ [INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION]: false });
                setAlertBtnAction(defaultAlertBtnAction);
                handleShowAlert({
                  title: 'Submitted successfully!',
                  description: 'Your application has been submitted successfully.',
                });
              }
            }
            const [depositLetter, applicationFormLetter] = await Promise.all([
              getLetterFile({
                appId: applicationId,
                type: ELetterType.deposit,
              }),
              getLetterFile({
                appId: applicationId,
                type: ELetterType.applicationForm,
              }),
            ]);
            setSubmittedAttachments([
              {
                name: 'Download Deposit Instruction Letter',
                src: URL.createObjectURL(depositLetter),
              },
              {
                name: 'Download Application Form',
                src: URL.createObjectURL(applicationFormLetter),
              },
            ]);
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const handleSuccess = (mode?: string) => {
    if (isEditMode && !isDraft) return;
    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleShowAlert({
        title: 'Draft saved!',
        description: `You can come back and resume at any time.`,
      });
    } else {
      handleGoToNextStep();
    }
  };

  const handleSetErrorSteps = () => {
    const validArray = Object.values(formData).map(({ form }) => form.formState.isValid);
    const errorStepList = validArray
      .map((it, id) => (it ? null : id))
      .filter((it) => it !== null) as number[];
    setErrorSteps(errorStepList);
    Object.values(formData).map(({ form }) => form.trigger());
    return !errorStepList.length;
  };

  const handleClickStep = () => {
    if (isViewMode) return;
    handleSetErrorSteps();
  };

  const handleApplicationSubmitAllSection = async () => {
    const isValid = handleSetErrorSteps();
    if (!isValid) {
      return;
    }

    const isViewOnlyWhenExistingInvestmentEntity = (step: string) =>
      investmentEntityId &&
      [INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS, INVESTMENT_APPLICATION_STEP.TEAM].includes(
        Number(step),
      );

    const allPromises = Object.entries(formData)
      .filter(
        ([step, { form }]) =>
          form.formState.isDirty && !isViewOnlyWhenExistingInvestmentEntity(step),
      )
      .map(([, { submit }]) => submit(SUBMIT_FORM_ACTIONS.SUBMIT));

    const results = await Promise.allSettled(allPromises);
    const resultStatuses = results.map((promise) => promise.status);

    if (!resultStatuses.includes('rejected')) {
      submittedAttachments.length && setSubmittedAttachments([]);
      setAlertBtnAction(defaultAlertBtnAction);
      handleShowAlert({
        title: 'You did it!',
        description: `Investment application has been updated successfully.`,
      });
    }
  };

  const handleStepChanged = (stepChanged: number) => {
    setCurrentStep(stepChanged);
  };

  const getDefaultStepProps = (step: number) => ({
    id: applicationId,
    offerId: offerInfo.offerId,
    fundId: offerInfo.fundId,
    unitClassId: offerInfo.unitClassId,
    step,
    isViewMode,
    isEditMode,
    isCreateMode,
    isDisabledEdit: isEditMode && !isDraft,
    isExistingInvestmentEntity,
    investmentEntityId,
    isDraft,
    isLoading,
    currentStep,
    verifyCode: applicationDetail?.verifyCode,
    setLastStep,
    setExcludeSteps,
    onSubmit: () => {
      if (stepperRef.current?.getCurrentStep() === step) {
        handleSave(step, SUBMIT_FORM_ACTIONS.SUBMIT);
      }
    },
    onSave: () => handleSave(step, SUBMIT_FORM_ACTIONS.DRAFT),
    getInvestmentEntitySubmitData,
    isCreateFundApplicationForm: true,
  });

  const STEPS = [
    {
      key: INVESTMENT_APPLICATION_STEP.DETAILS,
      label: 'Details',
      content: (
        <FormProvider {...detailsForm}>
          <FormSection
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.DETAILS)}
            customHeader={<CustomFormSectionHeader title='Details' icon={<PaidSearchIcon />} />}
            disabled={
              !!detailsForm.watch('isForeignTaxResident') &&
              !applicationOfferUnitClassDetailData?.allowForeignInvestors
            }
          >
            <Details
              {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.DETAILS)}
              fundName={offerPreviewData?.fundName}
              allowForeignInvestors={applicationOfferUnitClassDetailData?.allowForeignInvestors}
              currency={applicationOfferUnitClassDetailData?.currency}
              offerType={applicationOfferUnitClassDetailData?.offerType}
              setIsAddNewEntityDetail={setIsAddNewEntityDetail}
              investmentEntityForm={investmentEntityForm}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
      label: 'Investment Entity',
      content: (
        <FormProvider {...investmentEntityForm}>
          <FormSection
            customHeader={
              <CustomFormSectionHeader title='Investment Entity' icon={<MoneyBagIcon />} />
            }
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY)}
            disabled={isInvestmentEntityError}
          >
            <FundInvestmentEntity
              {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY)}
              isForeignTaxResident={isForeignTaxResident}
              isExistingInvestor={isExistingInvestor}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS,
      label: 'Financial Details',
      content: (
        <FormSection
          customHeader={
            <CustomFormSectionHeader
              title='Financial Details'
              icon={<StatsIcon />}
              isShowUpdateDetailsButton
              investmentEntityId={investmentEntityId}
            />
          }
          {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS)}
          disabled={JSON.stringify(financialTaxError).includes('"type":"taxDetailsError"')}
        >
          {isWithoutFinancialAndTaxDetails ? (
            <FormProvider {...financialDetailsForm} key={1}>
              <FinancialDetails
                {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS)}
                isWithoutFinancialAndTaxDetails
              />
            </FormProvider>
          ) : (
            <FormProvider {...financialTaxDetailsForm} key={2}>
              <FinancialAndTaxDetails
                {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.FINANCIAL_DETAILS)}
              />
            </FormProvider>
          )}
        </FormSection>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.TEAM,
      label: 'Team',
      content: (
        <FormProvider {...teamForm}>
          <FormSection
            customHeader={
              <CustomFormSectionHeader
                title='Team'
                icon={<InvestmentApplicationTeamIcon />}
                isShowUpdateDetailsButton={isExistingInvestor}
                investorId={investorId}
              />
            }
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.TEAM)}
          >
            <FundraisingApplicationTeam
              {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.TEAM)}
              errors={teamErrors}
              setTeamErrors={(fieldName: string, value: string) =>
                setTeamErrors((prev) => ({ ...prev, [fieldName]: value }))
              }
              isExistingInvestor={isExistingInvestor}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.ADDITIONAL,
      label: 'Additional',
      content: (
        <FormProvider {...additionalForm}>
          <FormSection
            customHeader={<CustomFormSectionHeader title='Additional' icon={<MoneyBagIcon />} />}
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.ADDITIONAL)}
          >
            <Additional
              {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.ADDITIONAL)}
              allowDRP={applicationOfferUnitClassDetailData?.allowDRP}
              investorType={investorType}
              investmentAmount={currencyStringToNumber(investmentAmount)}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
      label: 'Acceptance',
      content: (
        <FormProvider {...acceptanceForm}>
          <FormSection
            customHeader={<CustomFormSectionHeader title='Acceptance' icon={<AcceptanceIcon />} />}
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.ACCEPTANCE)}
          >
            <Acceptance
              {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.ACCEPTANCE)}
              executionMethods={applicationOfferUnitClassDetailData?.executionMethods}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION,
      label: 'AML/KYC',
      content: (
        <FormProvider {...fundraisingAmlKycForm}>
          <FormSection
            {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION)}
            customHeader={<CustomFormSectionHeader title='AML/KYC' icon={<KYCIcon />} />}
            isLastStep
            createBtnText='Submit'
          >
            <AmlKyc {...getDefaultStepProps(INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION)} />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            Create New Application
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 10 }}
            onClickStep={handleClickStep}
            errorSteps={errorSteps}
            enableClickStep={(!isCreateMode && !isDraft && !isLoading) || isViewMode}
            destroyInactiveStep={false}
            showErrorAllStep
            onStepChange={handleStepChanged}
          />
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={alertBtnAction}
        >
          <Box sx={{ marginTop: 2 }}>
            {getLetterLoading ? (
              <Box>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              </Box>
            ) : (
              submittedAttachments.map((item, index) => (
                <Box key={index}>
                  <Link
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    underline='hover'
                    href={item.src}
                    target='_blank'
                  >
                    <DownloadIcon />
                    <Typography marginLeft={1}>{item.name}</Typography>
                  </Link>
                </Box>
              ))
            )}
          </Box>
        </ConfirmationAlert>
      </BasicModal>
      <BasicModal ref={alertAmountInvestmentRef}>
        <ConfirmationAlert
          isError={alertInfo.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          image={alertInfo?.image}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              !alertInfo.isError && handleSuccess();
              alertAmountInvestmentRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default CreateFundraisingApplicationForm;
