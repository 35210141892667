import { REQUIRE_MESSAGE } from 'src/constants/common';
import { removeSpacesInString } from 'src/utils/common';
import * as Yup from 'yup';

export const detailValidationSchema = Yup.object().shape({
  legalName: Yup.string().required(REQUIRE_MESSAGE),
  abn: Yup.string()
    .required(REQUIRE_MESSAGE)
    .test('invalidAbn', 'ACN/ABN must have 9 or 11 digits.', (abn) => {
      const rawAbn = removeSpacesInString(abn);
      if (rawAbn?.length === 9 || rawAbn?.length === 11) return true;
      return false;
    }),
  physicalAddress: Yup.object().shape({
    country: Yup.object()
      .shape({
        id: Yup.string().required(REQUIRE_MESSAGE),
        value: Yup.string().required(REQUIRE_MESSAGE),
        label: Yup.string().required(REQUIRE_MESSAGE),
      })
      .required(REQUIRE_MESSAGE),
    state: Yup.object()
      .shape({
        id: Yup.string().required(REQUIRE_MESSAGE),
        value: Yup.string().required(REQUIRE_MESSAGE),
        label: Yup.string().required(REQUIRE_MESSAGE),
      })
      .required(REQUIRE_MESSAGE),
    city: Yup.object().when('manualEntry', ([manualEntry]) => {
      if (manualEntry) return Yup.string().required(REQUIRE_MESSAGE);

      return Yup.object()
        .shape({
          id: Yup.string().required(REQUIRE_MESSAGE),
          value: Yup.string().required(REQUIRE_MESSAGE),
          label: Yup.string().required(REQUIRE_MESSAGE),
        })
        .required(REQUIRE_MESSAGE);
    }),
    manualEntry: Yup.boolean(),
    postcode: Yup.string().required(REQUIRE_MESSAGE),
    streetAddress: Yup.string().required(REQUIRE_MESSAGE),
  }),
  postalAddress: Yup.object().shape({
    country: Yup.object()
      .shape({
        id: Yup.string().required(REQUIRE_MESSAGE),
        value: Yup.string().required(REQUIRE_MESSAGE),
        label: Yup.string().required(REQUIRE_MESSAGE),
      })
      .required(REQUIRE_MESSAGE),
    state: Yup.object()
      .shape({
        id: Yup.string().required(REQUIRE_MESSAGE),
        value: Yup.string().required(REQUIRE_MESSAGE),
        label: Yup.string().required(REQUIRE_MESSAGE),
      })
      .required(REQUIRE_MESSAGE),
    city: Yup.object().when('manualEntry', ([manualEntry]) => {
      if (manualEntry) return Yup.string().required(REQUIRE_MESSAGE);

      return Yup.object()
        .shape({
          id: Yup.string().required(REQUIRE_MESSAGE),
          value: Yup.string().required(REQUIRE_MESSAGE),
          label: Yup.string().required(REQUIRE_MESSAGE),
        })
        .required(REQUIRE_MESSAGE);
    }),
    manualEntry: Yup.boolean(),
    postcode: Yup.string().required(REQUIRE_MESSAGE),
    streetAddress: Yup.string().required(REQUIRE_MESSAGE),
  }),
  capacityIds: Yup.array().min(1, REQUIRE_MESSAGE),
  otherCapacity: Yup.string().when(['capacityIds'], (capacityIds) => {
    if (capacityIds[0].includes('other')) return Yup.string().required('This is reqruied');
    return Yup.string().nullable();
  }),
  isSameAsPhysicalAddress: Yup.boolean(),
});
