/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import UserSelectionItem from 'src/components/molecules/UserSelectionItem';
import { APPLICATION_SIGNATORY_STATUS, SignatoryMethodType } from 'src/constants/applications';
import { IAcceptanceSignatory } from 'src/modules/applications/type';

interface ISelectSignatoryProps {
  items: IAcceptanceSignatory[];
  selectedItems: IAcceptanceSignatory[];
  disabled?: boolean;
  isApplicationReview?: boolean;
  onSelect?: (item: IAcceptanceSignatory, selectedIndex: number) => void;
  errorMessage?: string;
}

const SelectSignatory: React.FC<ISelectSignatoryProps> = ({
  items,
  selectedItems,
  onSelect,
  disabled,
  isApplicationReview,
  errorMessage,
}) => {
  return (
    <Box pt={4}>
      <Box className='flex items-center justify-between'>
        <Box className='flex items-center'>
          <Typography fontWeight={500} variant='body2'>
            Select Signatory
          </Typography>
        </Box>
      </Box>
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        <Grid container spacing={2}>
          {(items || []).map((item: IAcceptanceSignatory, index: number) => {
            const selectedIndex = (selectedItems || []).findIndex((f) => f.contactId === item.id);
            // notRemoved: The person who makes a declaration in tax will be selected as default (not allowed to unselect)
            const isDisabledSelectSignatory =
              disabled ||
              selectedItems[selectedIndex]?.notRemoved ||
              (isApplicationReview &&
                selectedItems[selectedIndex]?.status === APPLICATION_SIGNATORY_STATUS.Signed) ||
              (isApplicationReview &&
                selectedItems.every((it) => it.status === APPLICATION_SIGNATORY_STATUS.Signed)) ||
              (isApplicationReview &&
                selectedItems.some(
                  (it) =>
                    it.status === APPLICATION_SIGNATORY_STATUS.Signed &&
                    it.method === SignatoryMethodType.DocuSign,
                ));
            return (
              <UserSelectionItem
                key={`approved-signatoty-${index}`}
                isSelected={selectedIndex !== -1}
                name={`${item.firstName} ${item.lastName}`}
                onSelect={() => onSelect?.(item, selectedIndex)}
                description={item.email}
                disabled={isDisabledSelectSignatory}
              />
            );
          })}
        </Grid>
      </Box>
      {errorMessage && (
        <Box pt={1}>
          <CustomHelperText message={errorMessage} variant='error' />
        </Box>
      )}
    </Box>
  );
};

export default SelectSignatory;
