import { Box, Typography } from '@mui/material';
import * as React from 'react';
import LoadingIcon from 'src/assets/icons/LoadingIcon';

interface ILoadingComponentProps {}

const LoadingComponent: React.FunctionComponent<ILoadingComponentProps> = (props) => {
  return (
    <Box className='w-[485px] flex flex-col items-center py-14 px-8'>
      <LoadingIcon />
      <Typography variant='body2' align='center' mt={3}>
        Please wait a moment. Retrieving information, this process may take up to 2 minutes. Please
        do not close this window.
      </Typography>
    </Box>
  );
};

export default LoadingComponent;
