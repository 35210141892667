import { Box, Divider, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import DirectorFormItem from 'src/components/pages/applications/components/InvestmentEntity/shared/DirectorFormItem';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';
import {
  DEFAULT_UBOS_POSITION,
  UBOS_META,
  foreignCompanyDefaultValues,
  individualDefaultValue,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';

interface IForeignCompanyFormProps extends IInvestmentEntityProps {
  keyName: 'investmentEntityDetails.foreignCompany';
  hideUBODeclaration?: boolean;
}

const ForeignCompanyForm: React.FunctionComponent<IForeignCompanyFormProps> = (props) => {
  const {
    isViewMode,
    isDisabledEdit,
    keyName,
    isExistingInvestmentEntity,
    hideUbo,
    isInvestmentEntitySetting,
    isReviewing,
    hideUBODeclaration,
  } = props;
  const { countryOptions } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { control, watch, getFieldState, setValue } = useFormContext<IInvestmentEntityForm>();
  const foreignCompanyData = watch(keyName);

  useEffect(() => {
    if (isEmpty(foreignCompanyData)) {
      setValue(keyName, foreignCompanyDefaultValues);
    }
  }, []);

  const {
    fields: directors,
    append: addDirector,
    remove: removeDirector,
  } = useFieldArray({
    control,
    name: `${keyName}.directors`,
    keyName: 'fieldId',
  });
  const { error: directorsError } = getFieldState(`${keyName}.directors`);

  const {
    fields: ubOsList,
    append: addUbOs,
    remove: removeUbOs,
  } = useFieldArray({
    control,
    name: `${keyName}.ubOs`,
    keyName: 'fieldId',
  });
  const { error: ubOsError } = getFieldState(`${keyName}.ubOs`);

  const watchRegisteredWithASIC = watch(`${keyName}.registeredWithASIC`);
  const watchIsListedCompany = watch(`${keyName}.isCompanyListed`);

  const handleAddDirector = () => {
    addDirector({
      ...individualDefaultValue(true),
      position: DEFAULT_UBOS_POSITION.DIRECTOR,
    });
  };

  const handleAddUBO = () => {
    addUbOs(individualDefaultValue(true));
  };

  return (
    <Box>
      <Grid container columnSpacing='20px' rowSpacing='18px'>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.companyName`}
            label='Company Name'
            placeholder='Enter company name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.companyType`}
            label='Company Type'
            placeholder='Enter company type'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.foreignEIN`}
            label='Foreign Company Identification Number'
            placeholder='Enter identification number'
            disabled={disabled || isInvestmentEntitySetting}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.nameOfRelevantForeignRegistrationBody`}
            label='Name of Relevant Foreign Registration Body'
            placeholder='Enter name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name={`${keyName}.countryOfEstablishment`}
            options={countryOptions}
            label='Country of Establishment'
            placeholder='Select country of establishment'
            disabled={disabled || isInvestmentEntitySetting}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name={`${keyName}.countryOfTaxResident`}
            options={countryOptions}
            label='Country of Tax Residence'
            placeholder='Select country of tax residence'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.registeredAddress`}
            label='Registered Address'
            placeholder='Enter registered address'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name={`${keyName}.principalPlaceOfBusiness`}
            label='Principal Place of Business'
            placeholder='Enter principal place of business'
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Divider className='my-6' sx={{ borderColor: 'neutral.ne200' }} />
      <Grid container spacing='18px'>
        <Grid item xs={12}>
          <FormRadioGroup
            name={`${keyName}.registeredWithASIC`}
            options={TrueFalseRadioOptions}
            label={
              <Typography variant='body3' fontWeight={600}>
                Registered with ASIC?
              </Typography>
            }
            isBooleanValue
            optionGap={8}
            disabled={disabled}
            showPickerOnly={isReviewing}
          />
        </Grid>
        {watchRegisteredWithASIC && (
          <>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.companyFullNameASIC`}
                label='Company’s full name as registered by ASIC'
                placeholder='Enter company’s full name'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.arbn`}
                label='ARBN'
                placeholder='Enter ARBN'
                maxLength={11}
                formatValue={(value) => formatValueWithSpaces(value)}
                disabled={disabled}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Divider className='mb-6 mt-4' sx={{ borderColor: 'neutral.ne200' }} />
      <Grid container spacing='18px'>
        <Grid item xs={12}>
          <FormRadioGroup
            name={`${keyName}.isCompanyListed`}
            options={TrueFalseRadioOptions}
            label={
              <Typography variant='body3' fontWeight={600}>
                Listed Company?
              </Typography>
            }
            isBooleanValue
            optionGap={8}
            disabled={disabled}
            showPickerOnly={isReviewing}
          />
        </Grid>
        {watchIsListedCompany && (
          <>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.nameOfStockExchangeName`}
                label='Name of Stock Exchange'
                placeholder='Enter name of stock exchange'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.listedTickerCode`}
                label='Listed Ticker Code'
                placeholder='Enter listed ticker code'
                disabled={disabled}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Divider className='mb-6 mt-4' sx={{ borderColor: 'neutral.ne200' }} />
      <FormArrayLayout
        {...props}
        title='Directors'
        onAddItem={handleAddDirector}
        disabled={disabled}
        helperText={<CustomHelperText variant='error' message={directorsError?.message} />}
      >
        <Grid container rowSpacing='16px' mb='16px'>
          {(directors || []).map((director, index) => (
            <Grid key={director.fieldId} item xs={12}>
              <DirectorFormItem
                {...props}
                keyName={`${keyName}.directors.${index}`}
                onDelete={() => removeDirector(index)}
              />
            </Grid>
          ))}
        </Grid>
      </FormArrayLayout>
      <Divider className='my-6' sx={{ borderColor: 'neutral.ne200' }} />
      {!hideUbo && (
        <FormArrayLayout
          {...props}
          title='Ultimate Beneficial Owners'
          tooltip={UBOS_META.COMMON.TOOLTIP}
          disabled={disabled}
          onAddItem={handleAddUBO}
          helperText={<CustomHelperText variant='error' message={ubOsError?.message} />}
        >
          <Grid container rowSpacing='16px' mb='16px'>
            {(directors || []).map(
              (director, index) =>
                director.refId && (
                  <Grid key={director.fieldId} item xs={12}>
                    <IndividualDetail
                      {...props}
                      keyName={`${keyName}.directors.${index}`}
                      hasPosition
                      hideTfn
                      disabledBasicInfoFields={['firstName', 'lastName']}
                      autocompleteAddress
                      hideDeclaration={hideUBODeclaration}
                    />
                  </Grid>
                ),
            )}
            {ubOsList.map((ubOs, index) => (
              <Grid key={ubOs.fieldId} item xs={12}>
                <IndividualDetail
                  {...props}
                  keyName={`${keyName}.ubOs.${index}`}
                  handleDelete={() => removeUbOs(index)}
                  canDelete
                  hasPosition
                  hideTfn
                  autocompleteAddress
                  hideDeclaration={hideUBODeclaration}
                />
              </Grid>
            ))}
          </Grid>
        </FormArrayLayout>
      )}
    </Box>
  );
};

export default ForeignCompanyForm;
