import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { BILLING_TIME_FORMAT, DATE_PICKER_FORMAT } from 'src/constants/date';
import {
  BILLING_FEE_TYPE,
  BILLING_VOLUME_ITEM_TIER,
  multipleFees,
  singleFees,
  tieringFees,
} from 'src/constants/subscription-billing';
import {
  useGetStandardPricingDetail,
  useGetStandardPricingLatest,
  useUpdateStandardPricing,
  useUpdateStandardPricingHistory,
} from 'src/modules/subscription-billing/hooks';
import {
  BillingPricingItem,
  PricingDetail,
  TieringFeeForm,
} from 'src/modules/subscription-billing/types';
import { formatNumberWithCommas, handleErrorFromServer } from 'src/utils/common';
import { standardPricingFormSchema } from 'src/validations/subscription-billing';
import EditMultipleFeeForm from './EditMultipleFeeForm';
import EditSingleFeeForm from './EditSingleFeeForm';
import EditTieringFeeForm from './EditTieringFeeForm';
import StandardPriceHistory from './StandardPriceHistory';

interface IProps {
  id?: string;
  isViewMode?: boolean;
  canViewHistory?: boolean;
  onClose: () => void;
  isSuperAdmin?: boolean;
  isPriceHistory?: boolean;
}

interface ITableData extends BillingPricingItem {}

const StandardPricing = ({
  onClose,
  isViewMode,
  canViewHistory,
  id = '',
  isSuperAdmin = false,
  isPriceHistory = false,
}: IProps) => {
  const form = useForm<PricingDetail>({
    resolver: yupResolver(standardPricingFormSchema),
  });

  const { reset, handleSubmit, getValues, setValue } = form;

  const modalAlertRef = useRef<IBasicModalElement>(null);
  const editSingleFeeModalRef = useRef<IBasicModalElement>(null);
  const editMultipleFeeModalRef = useRef<IBasicModalElement>(null);
  const editTieringFeeModalRef = useRef<IBasicModalElement>(null);
  const standardPricingHistoryModalRef = useRef<IBasicModalElement>(null);

  const [pricingList, setPricingList] = useState<BillingPricingItem[]>([]);
  const [selectedBillingPricing, setSelectedBillingPricing] = useState<BillingPricingItem>();

  const { data: standardPricing, isLoading } = id
    ? useGetStandardPricingDetail(id)
    : useGetStandardPricingLatest();
  const { mutate: updateStandardPricing, isLoading: isUpdating } = useUpdateStandardPricing();
  const { mutate: updateStandardPricingHistory, isLoading: isUpdatingHistory } =
    useUpdateStandardPricingHistory();

  useEffect(() => {
    if (!isEmpty(standardPricing)) {
      reset({
        ...standardPricing,
        currentEffectiveDate: standardPricing.effectiveDate,
        isPriceHistory,
      });
      updatePricingList(standardPricing);
    }
  }, [standardPricing]);

  const updatePricingList = (data: PricingDetail) => {
    const pricingList: BillingPricingItem[] = [];
    cloneDeep(data.standardItems).forEach((standardItem) => {
      let totalPrice = standardItem.price;
      const currentLength = pricingList.push(standardItem);

      standardItem.volumes?.forEach((child) => {
        if (child.tierType === BILLING_VOLUME_ITEM_TIER.NoTier) {
          pricingList[currentLength - 1].volume = 'No Tier';
          pricingList[currentLength - 1].price = child.price;
        } else {
          if (typeof child.minVolume === 'number') {
            pricingList.push({
              volume: `${child.minVolume} - ${
                typeof child.maxVolume === 'number' ? child.maxVolume : '∞'
              }`,
              price: child.price,
              isChildren: true,
            } as BillingPricingItem);
          }
        }
      });

      standardItem.subBillings?.forEach((child) => {
        totalPrice += child.price;
        pricingList.push({ ...child, isChildren: true, feeType: undefined });
      });
      if (!standardItem.hasTieringSystem) {
        pricingList[currentLength - 1].price = totalPrice;
      }
    });
    setPricingList(pricingList);
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '37%', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => (
        <Box ml={row.isChildren ? 2 : 0}>
          <StringNodeTable variant='body3' value={row?.description} />
        </Box>
      ),
    },
    {
      title: 'Fee Type',
      key: 'feeType',
      sx: { width: '17%', whiteSpace: 'pre', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={typeof row?.feeType === 'number' && BILLING_FEE_TYPE[row?.feeType]}
        />
      ),
    },
    {
      title: 'Volume',
      key: 'volume',
      sx: { width: '17%', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => <StringNodeTable variant='body3' value={row?.volume} />,
    },
    {
      title: 'Fees',
      key: 'price',
      sx: { width: '17%', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => {
        return (
          <StringNodeTable
            variant='body3'
            value={`$${formatNumberWithCommas(row?.price || 0, 2)}`}
          />
        );
      },
    },
    ...(!isViewMode
      ? ([
          {
            title: 'Action',
            key: 'action',
            sx: { width: '12%', py: '11px' },
            cellSx: (row) => ({
              py: '14.5px',
              ...(row.isChildren ? { bgcolor: 'neutral.ne100' } : {}),
            }),
            renderNode: (row) =>
              row.isChildren ? null : (
                <Box className='flex items-center justify-center'>
                  <IconButton
                    sx={{
                      width: '24px',
                      height: '24px',
                      p: 0,
                      boxSizing: 'content-box',
                      borderRadius: '50%',
                      bgcolor: 'neutral.ne200',
                      mx: 'auto',
                    }}
                    disabled={isViewMode}
                    onClick={() => {
                      setSelectedBillingPricing(row);
                      if (tieringFees.includes(row.billingType)) {
                        editTieringFeeModalRef.current?.open();
                      } else if (multipleFees.includes(row.billingType)) {
                        editMultipleFeeModalRef.current?.open();
                      } else if (singleFees.includes(row.billingType)) {
                        editSingleFeeModalRef.current?.open();
                      }
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Box>
              ),
          },
        ] as ColumnProps<ITableData, 'action'>[])
      : []),
  ];

  const onSubmit = () => {
    const data = getValues();

    if (isPriceHistory) {
      updateStandardPricingHistory(
        {
          id,
          data,
        },
        {
          onSuccess: () => modalAlertRef.current?.open(),
          onError: handleErrorFromServer,
        },
      );

      return;
    }

    updateStandardPricing(data, {
      onSuccess: () => modalAlertRef.current?.open(),
      onError: handleErrorFromServer,
    });
  };

  const onUpdateSingleFee = (fee: number, item?: BillingPricingItem) => {
    const data = getValues();
    const index = data.standardItems.findIndex((it) => it.id === item?.id);
    if (index > -1) {
      setValue(`standardItems.${index}.price`, fee);
    }
    updatePricingList(getValues());
  };

  const onUpdateMultipleFee = (fees: Record<string, number>, item?: BillingPricingItem) => {
    const data = getValues();
    const parentIndex = data.standardItems.findIndex((it) => it.id === item?.id);

    Object.keys(fees).forEach((id) => {
      const fee = fees[id];
      const childIndex = (data.standardItems[parentIndex]?.subBillings || []).findIndex(
        (it) => it.id === id,
      );
      if (childIndex > -1) {
        setValue(`standardItems.${parentIndex}.subBillings.${childIndex}.price` as any, fee);
      }
    });

    updatePricingList(getValues());
  };

  const onUpdateTieringFee = (tieringData: TieringFeeForm, item?: BillingPricingItem) => {
    const data = getValues();
    const parentIndex = data.standardItems.findIndex((it) => it.id === item?.id);
    if (parentIndex > -1) {
      data.standardItems[parentIndex].volumes?.forEach((it, index) => {
        let fee: any = null,
          minVolume: any = null,
          maxVolume: any = null;

        switch (it.tierType) {
          case BILLING_VOLUME_ITEM_TIER.NoTier:
            fee = Number(tieringData.noTierFee);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier1:
            fee = Number(tieringData.tier1Fee);
            minVolume = Number(tieringData.tier1MinVolume) || 1;
            maxVolume = Number(tieringData.tier1MaxVolume);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier2:
            fee = Number(tieringData.tier2Fee);
            minVolume = Number(tieringData.tier2MinVolume);
            maxVolume = tieringData.noMax ? null : Number(tieringData.tier2MaxVolume);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier3:
            // pass api validation
            fee = Number(tieringData.tier3Fee);
            if (!tieringData.noMax) {
              minVolume = Number(tieringData.tier3MinVolume);
            }
            break;
          default:
            break;
        }

        setValue(`standardItems.${parentIndex}.volumes.${index}.minVolume`, minVolume);
        setValue(`standardItems.${parentIndex}.volumes.${index}.maxVolume`, maxVolume);
        setValue(`standardItems.${parentIndex}.volumes.${index}.price`, fee || 0);
      });
    }
    updatePricingList(getValues());
  };

  return (
    <FormProvider {...form}>
      <Box width={'800px'}>
        <Box
          className='flex justify-between'
          py={4}
          px={7.5}
          borderBottom='1px solid'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5'>
            {isViewMode ? 'View Standard Pricing' : 'Update Standard Price'}
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component='form' onSubmit={handleSubmit(onSubmit)}>
          <Box py={5} px={7.5} mb={2}>
            <Grid spacing={3} container>
              <Grid item xs={4}>
                <FormDatePicker
                  name='effectiveDate'
                  label='Effective Date'
                  disabled={isViewMode || isPriceHistory}
                  datePickerProps={{
                    minDate: dayjs(standardPricing?.effectiveDate).add(1, 'month'),
                    disablePast: true,
                    format: BILLING_TIME_FORMAT,
                    views: ['month', 'year'],
                  }}
                  useServerFormat
                />
              </Grid>
              <Grid item xs={8} display={'flex'}>
                <Box className='w-full flex flex-col items-end justify-end'>
                  {canViewHistory && (
                    <CustomButton
                      disableRipple
                      variant='text'
                      sx={{
                        p: 0,
                        textDecoration: 'underline !important',
                        bgcolor: 'transparent !important',
                        color: 'secondary.main',
                      }}
                      onClick={() => standardPricingHistoryModalRef.current?.open()}
                    >
                      Standard Price History
                    </CustomButton>
                  )}
                  <Typography mt={1} variant='body3' color='neutral.ne800'>
                    {`Last updated by ${standardPricing?.modifiedByName} on ${dayjs(
                      standardPricing?.modifiedDate,
                    ).format(DATE_PICKER_FORMAT)}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              mt={4}
              sx={{
                '.render-node-container': { m: 0 },
                '.MuiTable-root': { minWidth: 'unset !important' },
              }}
            >
              {pricingList.length || isLoading ? (
                <CustomTable
                  columns={columns}
                  rows={pricingList}
                  isFetchingData={isLoading}
                  hasPagination={false}
                />
              ) : (
                <EmptyData />
              )}
            </Box>
          </Box>
          <Box
            px={4}
            py={2}
            borderColor={'neutral.ne200'}
            className='flex justify-end border-t border-solid gap-6'
          >
            <CustomButton
              variant='outlined'
              onClick={onClose}
              disabled={isUpdating || isUpdatingHistory}
            >
              Cancel
            </CustomButton>
            {!isViewMode && (
              <CustomButton
                variant='contained'
                type='submit'
                isLoading={isUpdating || isUpdatingHistory}
              >
                Save
              </CustomButton>
            )}
          </Box>
        </Box>
        <BasicModal
          ref={editSingleFeeModalRef}
          maxWidth='xl'
          PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
          sx={{
            '& .MuiDialog-container': {
              marginLeft: '260px',
            },
          }}
          onClose={() => editSingleFeeModalRef.current?.close()}
        >
          <EditSingleFeeForm
            billingPricing={selectedBillingPricing}
            onUpdate={onUpdateSingleFee}
            onClose={() => editSingleFeeModalRef.current?.close()}
          />
        </BasicModal>
        <BasicModal
          ref={editMultipleFeeModalRef}
          maxWidth='xl'
          PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
          sx={{
            '& .MuiDialog-container': {
              marginLeft: '260px',
            },
          }}
          onClose={() => editMultipleFeeModalRef.current?.close()}
        >
          <EditMultipleFeeForm
            billingPricing={selectedBillingPricing}
            onUpdate={onUpdateMultipleFee}
            onClose={() => editMultipleFeeModalRef.current?.close()}
          />
        </BasicModal>
        <BasicModal
          ref={editTieringFeeModalRef}
          maxWidth='xl'
          PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
          sx={{
            '& .MuiDialog-container': {
              marginLeft: '260px',
            },
          }}
          onClose={() => editTieringFeeModalRef.current?.close()}
        >
          <EditTieringFeeForm
            isStandard
            billingPricing={selectedBillingPricing}
            onUpdate={onUpdateTieringFee}
            onClose={() => editTieringFeeModalRef.current?.close()}
          />
        </BasicModal>
        <BasicModal
          ref={standardPricingHistoryModalRef}
          maxWidth='xl'
          PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
          sx={{
            '& .MuiDialog-container': {
              marginLeft: '260px',
            },
          }}
          onClose={() => standardPricingHistoryModalRef.current?.close()}
        >
          <StandardPriceHistory
            onClose={() => standardPricingHistoryModalRef.current?.close()}
            isSuperAdmin={isSuperAdmin}
            isViewMode={isViewMode}
          />
        </BasicModal>
        <BasicModal ref={modalAlertRef}>
          <ConfirmationAlert
            title='You did it !'
            description='Standard price updated successfully.'
            buttonAction={{
              label: 'OK',
              onAction: () => {
                modalAlertRef?.current?.close();
                onClose();
              },
            }}
          />
        </BasicModal>
      </Box>
    </FormProvider>
  );
};

export default StandardPricing;
