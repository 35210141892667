import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import { BillingPricingItem } from 'src/modules/subscription-billing/types';

interface IProps {
  billingPricing?: BillingPricingItem;
  onUpdate: (_index: Record<string, number>, _item?: BillingPricingItem) => void;
  onClose: () => void;
}

const EditMultipleFeeForm = ({ billingPricing, onUpdate, onClose }: IProps) => {
  const form = useForm();
  const { reset, getValues, handleSubmit } = form;

  useEffect(() => {
    if (billingPricing) {
      const data: any = {};
      billingPricing?.subBillings?.forEach((it) => {
        data[it?.id || it?.billingId] = it.price;
      });
      reset(data);
    }
  }, [billingPricing]);

  const onSubmit = () => {
    const data = getValues();
    onUpdate(data, billingPricing);
    onClose();
  };

  return (
    <FormProvider {...form}>
      <Box width={'800px'} component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Box
          className='flex justify-between'
          p={4}
          borderBottom='1px solid'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5'>{billingPricing?.description}</Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={4} mb={2}>
          <Grid spacing={3} container>
            {billingPricing?.subBillings?.map((it) => (
              <Fragment key={it.id}>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name={it?.id || it?.billingId}
                    label={it.description}
                    placeholder='Enter fee'
                    decimalScale={2}
                    hideCurrency
                    startAdornment={'$'}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
        <Box
          px={4}
          py={2}
          borderColor={'neutral.ne200'}
          className='flex justify-end border-t border-solid gap-6'
        >
          <CustomButton variant='outlined' onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant='contained' type='submit'>
            Save
          </CustomButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default EditMultipleFeeForm;
