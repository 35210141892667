import { IconButton, IconButtonProps, styled, Theme } from '@mui/material';
import { FC } from 'react';

type VariantType = 'contained' | 'outlined';

interface ICustomIconButtonProps extends IconButtonProps {
  variant?: VariantType;
}

const getStyles = (theme: Theme, variant: VariantType) => {
  switch (variant) {
    case 'contained':
      return {
        backgroundColor: theme.palette.primary.main,
        '&.Mui-disabled': {
          color: theme.palette.neutral.ne800,
          backgroundColor: theme.palette.neutral.ne300,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.pr400,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.pr900,
        },
      };
    case 'outlined':
      return {
        backgroundColor: theme.palette.base.white,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        '&.Mui-disabled': {
          color: theme.palette.neutral.ne300,
          backgroundColor: theme.palette.base.white,
          borderColor: theme.palette.neutral.ne300,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.pr100,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.pr400,
        },
      };
    default:
      break;
  }
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: VariantType }>(({ theme, variant }) => {
  const styles = getStyles(theme, variant);

  return {
    color: theme.palette.base.white,
    ...styles,
  };
});

const CustomIconButton: FC<ICustomIconButtonProps> = ({
  children,
  variant = 'contained',
  ...rest
}) => {
  return (
    <StyledIconButton variant={variant} {...rest}>
      {children}
    </StyledIconButton>
  );
};

export default CustomIconButton;
