import { capitalize } from 'lodash';
import { actionPermissionKeys } from './common';

export const investmentEntityActionTypes = {
  view: 'view',
  edit: 'edit',
  archive: 'archive',
  undo: 'undo',
};

export const INVESTMENT_ENTITY_ACTION = {
  view: {
    key: investmentEntityActionTypes.view,
    label: capitalize(investmentEntityActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  edit: {
    key: investmentEntityActionTypes.edit,
    label: capitalize(investmentEntityActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  archive: {
    key: investmentEntityActionTypes.archive,
    label: capitalize(investmentEntityActionTypes.archive),
    permissionKey: actionPermissionKeys.isEdit,
  },
  undo: {
    key: investmentEntityActionTypes.undo,
    label: capitalize(investmentEntityActionTypes.undo),
    permissionKey: actionPermissionKeys.isEdit,
  },
};

export const investmentEntityStatuses = {
  In_Progress: 'In Progress',
  Archived: 'Archived',
};

export const SPECIAL_ABN = ['11111111111', '00000000000'];
