/* eslint-disable autofix/no-unused-vars */
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import {
  FUND_STATUS,
  FundType,
  FundTypeOptions,
  Mit,
  MitAndAmitOptions,
  SubTypeOptions,
  TeamUserType,
  TrustType,
  TrustTypeOptions,
} from 'src/constants/funds-management';
import { IProfileResponse } from 'src/modules/auth/type';
import { useGetOverarchingFilter } from 'src/modules/common/hooks';
import { useGetOfferTypes } from 'src/modules/funds-management/hooks';
import { IUpsertFundForm } from 'src/modules/funds-management/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatValueWithSpaces } from 'src/utils/common';

interface FundDetailsProps extends IUpsertFundForm {
  currentUser?: IProfileResponse;
  isShowWarningWhenChangeSpecialField?: boolean;
  onChangeSpecialFieldConfirm: () => void;
  fundStatus?: number;
}

export enum ChangeTypeEnum {
  Client = 'client',
  FundType = 'fund type',
}

const FundDetails: React.FC<FundDetailsProps> = ({
  isViewMode,
  currentUser,
  fundStatus,
  isShowWarningWhenChangeSpecialField,
  onChangeSpecialFieldConfirm,
}) => {
  const { userType } = currentUser || {};
  const { data: offerTypes } = useGetOfferTypes();
  const { data: overarchingFilter } = useGetOverarchingFilter();
  const [prevClientId, setPrevClient] = useState('');
  const [prevFundType, setPrevFundType] = useState(null);
  const [changedType, setChangedType] = useState('');

  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const { selectedClients, isFmPortal } = useFundPortalContext();

  const { watch, setValue } = useFormContext();
  const fundTypeWatch = watch('type');
  const trustTypeWatch = watch('trustType');
  const mitWatch = watch('mit');
  const watchClientId = watch('clientId');
  const abnWatch = watch('abn');
  const tfnWatch = watch('tfn');

  const isShowMit = trustTypeWatch === TrustType.FixedUnit;
  const isShowAmit = isShowMit && mitWatch === Mit.Yes;
  const isShowTrustType = fundTypeWatch !== FundType.Trust;
  const isFundDraft = fundStatus === FUND_STATUS.Draft;

  const offerTypesOptions = useMemo(() => {
    return (offerTypes || []).map((item: { name: string; id: string }) => ({
      label: item.name,
      value: item.id,
    }));
  }, [offerTypes]);

  const clientOptions = useMemo(() => {
    return overarchingFilter.clientsForNewFund
      .filter((x) => {
        if (isEmpty(selectedClients)) return x;
        return (
          typeof selectedClients !== 'string' &&
          (selectedClients as string[])?.some((f) => f === x.id)
        );
      })
      .map((item) => ({ label: item.name, value: item.id }));
  }, [overarchingFilter, selectedClients]);

  const handleCancel = () => {
    if (changedType === ChangeTypeEnum.FundType) {
      setValue('type', prevFundType);
    } else {
      setValue('clientId', prevClientId);
    }
    modalConfirmRef?.current?.close();
  };

  const handleOk = () => {
    modalConfirmRef?.current?.close();
    onChangeSpecialFieldConfirm?.();
  };

  return (
    <Box component='form' className='pt-4'>
      <Grid container spacing={3}>
        {userType === TeamUserType.FAUser && !isFmPortal && (
          <Grid item xs={12} md={12}>
            <FormSelect
              name='clientId'
              label='Select Client'
              placeholder='Select client'
              disabled={isViewMode || !isFundDraft}
              options={clientOptions}
              onSelectValue={() => {
                if (isShowWarningWhenChangeSpecialField) {
                  setPrevClient(watchClientId);
                  setChangedType(ChangeTypeEnum.Client);
                  modalConfirmRef.current?.open();
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormInput
            name='name'
            label='Fund Name'
            placeholder='Enter fund name'
            disabled={isViewMode}
            maxLength={75}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='code'
            label='Fund Code'
            placeholder='Enter fund code'
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='abn'
            label='Fund ABN'
            placeholder='Enter fund ABN'
            disabled={isViewMode}
            optional='Optional'
            maxLength={14}
            value={formatValueWithSpaces(abnWatch)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='tfn'
            label='Fund TFN'
            placeholder='Enter fund TFN'
            disabled={isViewMode}
            optional='Optional'
            maxLength={11}
            value={formatValueWithSpaces(tfnWatch)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='industryCode'
            label='Fund Industry Code (APIR/ISIN etc)'
            placeholder='Enter fund industry code'
            disabled={isViewMode}
            optional='Optional'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDatePicker
            disabled={isViewMode || !isFundDraft}
            name='commencementDate'
            label='Commencement Date'
            optional='Optional'
            datePickerProps={{
              format: DATE_PICKER_FORMAT,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormSelect
            name='offerType'
            label='Offer Type'
            placeholder='Select offer type'
            disabled={isViewMode}
            options={offerTypesOptions}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormRadioGroup
            disabled={isViewMode || !isFundDraft}
            label='Fund Type'
            name='type'
            row
            options={FundTypeOptions}
            isEnumValue
            onChangeValue={() => {
              if (isShowWarningWhenChangeSpecialField) {
                setPrevFundType(fundTypeWatch);
                setChangedType(ChangeTypeEnum.FundType);
                modalConfirmRef.current?.open();
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className={clsx('p-0 rounded-[12px] ml-[24px]', {
            hidden: isShowTrustType,
          })}
          bgcolor='neutral.ne100'
        >
          <Box p='24px'>
            <FormRadioGroup
              label='Trust Type'
              name='trustType'
              row
              options={TrustTypeOptions}
              isEnumValue
              disabled={isViewMode}
            />
            <Box pt='20px'>
              <FormRadioGroup
                label='Sub-type'
                name='subType'
                row
                options={SubTypeOptions}
                disabled={isViewMode}
                isEnumValue
              />
            </Box>
            {isShowMit && (
              <Box pt='20px'>
                <FormRadioGroup
                  label='MIT'
                  name='mit'
                  row
                  options={MitAndAmitOptions}
                  isEnumValue
                  disabled={isViewMode}
                />
              </Box>
            )}
            {isShowAmit && (
              <Box pt='20px'>
                <FormRadioGroup
                  label='AMIT'
                  name='amit'
                  row
                  options={MitAndAmitOptions}
                  disabled={isViewMode}
                  isEnumValue
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <ConfirmModal
        ref={modalConfirmRef}
        title='Reset Information'
        content={`If you change the ${changedType}, the fund information will be reset`}
        ButtonsComponent={
          <>
            <CustomButton sx={{ color: 'neutral.ne800' }} variant='text' onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton color='primary' onClick={handleOk}>
              OK
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default FundDetails;
