/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import AustraliaIcon from 'src/assets/icons/AustraliaIcon';
import WorldIcon from 'src/assets/icons/WorldIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import AustralianCompanyForm from 'src/components/pages/applications/components/InvestmentEntity/shared/AustralianCompanyForm';
import ForeignCompanyForm from 'src/components/pages/applications/components/InvestmentEntity/shared/ForeignCompanyForm';
import { InvestmentEntityCompanyRegionType } from 'src/constants/applications';
import { IInvestmentEntityDetailsForm } from 'src/modules/investment-entity/types';

interface ICompanyDetailsProps extends IInvestmentEntityProps {}

const CompanyDetails: FC<ICompanyDetailsProps> = (props) => {
  const { isViewMode, isInvestmentEntitySetting } = props;
  const { watch } = useFormContext<IInvestmentEntityDetailsForm>();
  const disabled = isViewMode || isInvestmentEntitySetting;
  const companyRegionType = watch('companyRegionType');
  const allowEditAbn = watch('investmentEntityDetails.allowEditAbn');

  const companyRegionTypeOptions = [
    {
      value: InvestmentEntityCompanyRegionType.Australian,
      label: 'Australian',
      icon: <AustraliaIcon />,
    },
    {
      value: InvestmentEntityCompanyRegionType.Foreign,
      label: 'Foreign',
      icon: <WorldIcon />,
    },
  ];

  return (
    <Box className='flex flex-col'>
      <Box mb={3}>
        <FormRadioGroup
          name='companyRegionType'
          options={companyRegionTypeOptions}
          label={
            <Typography variant='body3' fontWeight={500}>
              Australian or Foreign Company?
            </Typography>
          }
          isEnumValue
          optionGap={4}
          disabled={disabled}
        />
      </Box>
      <Accordion
        defaultExpanded
        sx={{
          mt: '0 !important',
          '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        }}
      >
        <AccordionSummary
          sx={{
            '.MuiSvgIcon-root': { fontSize: '18px' },
            '.MuiAccordionSummary-content': { my: 0 },
            minHeight: 'unset',
          }}
        >
          <Box className='flex justify-between items-center w-full'>
            <Typography variant='body2' fontWeight={600}>
              Company Details
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          className='p-6 mt-0 border-t'
          sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
        >
          {companyRegionType === InvestmentEntityCompanyRegionType.Australian && (
            <AustralianCompanyForm
              {...props}
              keyName='investmentEntityDetails.australianCompany'
              hideUBODeclaration
              disabledFields={allowEditAbn ? [] : ['abn']}
            />
          )}
          {companyRegionType === InvestmentEntityCompanyRegionType.Foreign && (
            <ForeignCompanyForm
              {...props}
              keyName='investmentEntityDetails.foreignCompany'
              hideUBODeclaration
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanyDetails;
