import { Box, BoxProps, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { DoneImage } from 'src/assets/images/DoneImage';
import { ErrorImage } from 'src/assets/images/ErrorImage';
import CustomButton from 'src/components/atoms/CustomButton';

interface ConfirmationAlertProps extends BoxProps {
  title?: string;
  description: string;
  buttonAction: {
    label: string;
    onAction: () => void;
  };
  image?: any;
  isError?: boolean;
  hiddenBtn?: boolean;
  children?: JSX.Element;
  errorTitle?: string;
}

const CustomContent = styled('div')(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.neutral.ne500,
  maxHeight: 250,
  overflow: 'auto',

  /* Works on firefox */
  scrollbarWidth: 'thin',
  scrollbarColor: theme.palette.neutral.ne300,

  /* Works on Chrome, Edge, and Safari */
  '&::-webkit-scrollbar': {
    width: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral.ne300,
    borderRadius: 3,
  },

  '&::-webkit-scrollbar-track': {
    marginTop: 0,
    marginBottom: 50,
  },
}));

const ConfirmationAlert: React.FC<ConfirmationAlertProps> = ({
  title,
  description,
  buttonAction,
  image,
  isError = false,
  hiddenBtn = false,
  children,
  errorTitle = '',
  className = '',
}) => {
  const handleButtonClick = () => {
    buttonAction.onAction();
  };

  const renderImage = () => {
    if (isError) return <ErrorImage />;

    return image ? image : <DoneImage />;
  };

  const renderTitle = () => {
    if (isError) return errorTitle || 'Oops!';

    return title || 'You did it !';
  };

  return (
    <Box
      className={clsx(
        'flex flex-col items-center w-[485px] rounded-[22px] bg-white pb-10',
        className,
      )}
    >
      <Box className='mt-6'>{renderImage()}</Box>
      <Typography variant='h5' className='mt-[6px]'>
        {renderTitle()}
      </Typography>
      <CustomContent
        className='mt-4 text-center px-10'
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Box className='px-10 w-full'>{children}</Box>
      {!hiddenBtn && (
        <CustomButton className='h-12 mt-8' onClick={handleButtonClick}>
          {buttonAction.label}
        </CustomButton>
      )}
    </Box>
  );
};

export default ConfirmationAlert;
