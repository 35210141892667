/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import * as React from 'react';
import SelectColorBox from './SelectColorBox';

interface HyperlinkTextSettingProps extends BoxProps {
  hyperlinkText: string;
  onChangeFields: (field: string, value: string) => void;
  disabled?: boolean;
}

const HyperlinkTextSetting: React.FC<HyperlinkTextSettingProps> = ({
  hyperlinkText,
  onChangeFields,
  disabled,
  sx,
  ...props
}) => {
  return (
    <Box {...props} sx={{ backgroundColor: 'base.white', p: 3, borderRadius: '10px', ...sx }}>
      <Typography variant='h6' mb='24px'>
        Hyperlink Text
      </Typography>
      <Grid container>
        <Grid xs={4} item>
          <Typography
            fontWeight='500'
            fontSize='20px'
            variant='body1'
            sx={{
              textDecoration: 'underline',
              color: hyperlinkText || 'primary.main',
            }}
          >
            This is a hyperlink text
          </Typography>
        </Grid>
        <Grid xs={8} item>
          <SelectColorBox
            disabled={disabled}
            title='Text colour'
            currentColor={hyperlinkText}
            onChangeColor={(color) => onChangeFields('hyperlinkText', color)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HyperlinkTextSetting;
