import { USER_MANAGEMENT_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { FundUserType, userStatuses } from 'src/constants/common';
import { portalApi } from 'src/helpers/axios';
import { IUserBody, User } from './type';

export const getUserRoles = async (roletype?: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url:
      typeof roletype === 'number'
        ? `${USER_MANAGEMENT_API.userRoles.api}?roletype=${roletype}`
        : USER_MANAGEMENT_API.userRoles.api,
  });

  return result.data;
};

export const getUserClients = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.userClients.api,
  });

  return result.data;
};

export const getUserFunds = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.userFunds.api,
  });

  return result.data;
};

export const getUserFilters = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.userFilters.api,
  });

  return result.data;
};

export const getUsers = async (data: any, userType?: number) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: data,
    url:
      userType === FundUserType.Client
        ? USER_MANAGEMENT_API.clientUserList.api
        : USER_MANAGEMENT_API.userList.api,
  });

  return result.data;
};

export const getUserById = async (userId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.userById.api(userId),
  });

  return result.data;
};

export const createUser = async (data: IUserBody): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: USER_MANAGEMENT_API.user.api,
  });

  return result.data;
};

export const updateUser = async (userId: string, data: IUserBody): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: USER_MANAGEMENT_API.userById.api(userId),
  });

  return result.data;
};

export const updateUserStatus = async (user: User): Promise<any> => {
  const data = {
    id: user?.id,
    isDisabled: user?.statusName === userStatuses?.Active,
  };

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: user.isOnBoardingContact
      ? USER_MANAGEMENT_API.userContactOnboardingStatusById.api
      : USER_MANAGEMENT_API.userStatusById.api(user?.id || ''),
    data: user.isOnBoardingContact && data,
  });

  return result.data;
};

export const resetUserPassword = async (userId: string): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: USER_MANAGEMENT_API.userResetPasswordById.api(userId),
  });

  return result.data;
};

export const resendUserInvitation = async (userId: string): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: USER_MANAGEMENT_API.userResendInvitationById.api(userId),
  });

  return result.data;
};

export const resetUser2FA = async (userId: string): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: USER_MANAGEMENT_API.userReset2FAById.api(userId),
  });

  return result.data;
};

export const deleteUser = async (userId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: USER_MANAGEMENT_API.userById.api(userId),
  });

  return result.data;
};

export const getInvestorUserEntities = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.investorUserEntities.api,
  });

  return result.data;
};

export const getDelegateInvestmentEntity = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: USER_MANAGEMENT_API.delegateInvestmentEntity.api,
  });

  return result.data;
};
