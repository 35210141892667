/* eslint-disable no-restricted-imports */
/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { MAX_TEAM_CONTACT, TeamType } from 'src/constants/applications';
import { ActionType, REQUIRE_MESSAGE } from 'src/constants/common';
import { useGetContactListByTeamType } from 'src/modules/applications/hooks';
import { ITeamContact, IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import {
  useDeleteTeamContact,
  useGetInvestmentEntityById,
  useGetTeamContact,
  useUpsertTeamContact,
} from 'src/modules/investment-entity/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { INVESTMENT_ENTITY_STEP } from '../InvestmentEntityForm';
import TeamSelectionBox from './TeamSelectionBox';
import TeamUpsertContact, { TeamUpsertContactHandler } from './TeamUpsertContact';

interface IInvestmentApplicationTeamProps extends IUpsertInvestmentApplicationForm {
  errors: {
    isTeamError: boolean;
    errorMessageFromServer?: string;
  };
  setTeamErrors?: (fielName: string, value: string) => void;
  isLinked?: boolean;
}

const InvestmentEntityTeam: FC<IInvestmentApplicationTeamProps> = ({
  id = '',
  errors,
  isViewMode,
  isEditMode,
  currentStep,
  setTeamErrors,
  isLinked,
}) => {
  const isCreateMode = !isViewMode && !isEditMode;
  const createUserRef = useRef<TeamUpsertContactHandler>(null);
  const upsertDrawerRef = useRef<ICustomDrawerElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const cacheFromTeamTypeRef = useRef<{
    fromTeamType: number | null;
  }>({
    fromTeamType: null,
  });
  const [selectedUser, setSelectedUser] = useState<ITeamContact | null>();
  const [actionType, setActionType] = useState<ActionType>('create');

  const { data: existingContacts } = useGetTeamContact(
    id,
    currentStep === INVESTMENT_ENTITY_STEP.TEAM,
  );
  const { mutate: upsertTeamContact } = useUpsertTeamContact();
  const { mutate: removeTeamContact } = useDeleteTeamContact();
  const { data: investmentEntityData } = useGetInvestmentEntityById(
    id,
    { step: INVESTMENT_ENTITY_STEP.TEAM },
    currentStep === INVESTMENT_ENTITY_STEP.TEAM,
  );
  const teamDetail = investmentEntityData?.team;

  const form = useFormContext();
  const { append, update, remove } = useFieldArray({
    control: form?.control,
    name: 'teammates',
  });

  const selectedUsers = form.watch('teammates');
  const { teams } = useGetContactListByTeamType(selectedUsers, isLinked);

  const handleCreateContact = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  useEffect(() => {
    if (!isEmpty(teamDetail) && (isViewMode || isEditMode)) {
      form.reset({
        teammates: teamDetail,
      });
    }
  }, [teamDetail]);

  // Default current user shoule be added to 3 teams when creating
  useEffect(() => {
    if (isCreateMode && isEmpty(selectedUser)) {
      const currentUser = (existingContacts || []).find((f: ITeamContact) => !f.isCurrentUser);
      !isEmpty(currentUser) && append({ ...currentUser, teamTypes: [TeamType.Team] });
    }
  }, [existingContacts]);

  const handleAddUser = (data: ITeamContact) => {
    upsertTeamContact(
      { id, data },
      {
        onSuccess: (id) => {
          const existingItemIndex = selectedUsers.findIndex((f: ITeamContact) => f.id === data.id);
          if (existingItemIndex !== -1) {
            update(existingItemIndex, data);
          } else {
            append({ ...data, teamTypes: [TeamType.Team], id, isNew: actionType === 'create' });
          }
          errors.errorMessageFromServer && setTeamErrors?.('errorMessageFromServer', '');
        },
        onError: handleErrorFromServer,
      },
    );
    upsertDrawerRef.current?.close();
  };

  const handleViewContact = (user: ITeamContact) => {
    setActionType('view');
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };
  const handleEditContact = (user: ITeamContact, actionType?: ActionType) => {
    setActionType(actionType || 'edit');
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };

  const handleRemoveContact = (user: ITeamContact, fromTeamType: number) => {
    cacheFromTeamTypeRef.current.fromTeamType = fromTeamType;
    setSelectedUser(user);
    modalConfirmRef.current?.open();
  };

  const confirmRemoveContact = () => {
    if (selectedUser?.isNew && selectedUser?.id && selectedUser.teamTypes?.length === 1) {
      removeTeamContact(selectedUser?.id, {
        onSuccess: () => handleRemoveContactFromList(),
        onError: handleErrorFromServer,
      });
    } else {
      handleRemoveContactFromList();
    }
  };

  const handleRemoveContactFromList = () => {
    const newSelectedUser = [...selectedUsers];
    const userIndex = newSelectedUser.findIndex((f) => f.id === selectedUser?.id);
    if (userIndex !== -1) {
      newSelectedUser[userIndex].teamTypes = (newSelectedUser[userIndex].teamTypes || []).filter(
        (x: number) => x !== cacheFromTeamTypeRef.current.fromTeamType,
      );
      isEmpty(newSelectedUser[userIndex].teamTypes)
        ? remove(userIndex)
        : update(userIndex, newSelectedUser[userIndex]);
    }

    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    modalConfirmRef.current?.close();
    setSelectedUser(null);
  };

  const handleCloseUpsertModal = () => {
    upsertDrawerRef.current?.close();
    setSelectedUser(null);
  };

  const isEditAble = !isViewMode && !isLinked;

  return (
    <Box>
      <TeamSelectionBox
        title='Team'
        numOfSelected={`${teams.length}/${MAX_TEAM_CONTACT} selected`}
        users={teams}
        tooltip='Please create the team who will be assigned to access this account'
        mt='32px'
        onView={handleViewContact}
        onEdit={isEditAble ? handleEditContact : undefined}
        onRemove={isEditAble ? (user) => handleRemoveContact(user, TeamType.Team) : undefined}
        disabled={!isEditAble}
        action={
          isEditAble && (
            <CustomButton
              className='p-0 mt-[10px]'
              variant='text'
              startIcon={<PlusPrimaryIcon />}
              onClick={() => {
                setActionType('create');
                upsertDrawerRef.current?.open();
              }}
            >
              <Typography variant='body2' color='primary.main'>
                Create New
              </Typography>
            </CustomButton>
          )
        }
      />
      {(errors.isTeamError || errors?.errorMessageFromServer) && (
        <CustomHelperText
          variant='error'
          message={errors?.errorMessageFromServer || REQUIRE_MESSAGE}
        />
      )}
      <CustomDrawer
        ref={upsertDrawerRef}
        title={actionType === 'view' ? 'View' : actionType === 'create' ? 'Create New' : 'Edit'}
        onClose={handleCloseUpsertModal}
        ButtonComponents={
          actionType !== 'view' && (
            <Box className='flex gap-2'>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={handleCloseUpsertModal}
              >
                Cancel
              </CustomButton>
              <CustomButton className='w-[160px]' onClick={handleCreateContact}>
                Save
              </CustomButton>
            </Box>
          )
        }
      >
        <TeamUpsertContact
          ref={createUserRef}
          user={selectedUser || undefined}
          isEditMode={actionType === 'edit' || actionType === 'existing'}
          isViewMode={actionType === 'view'}
        />
      </CustomDrawer>
      <ConfirmModal
        ref={modalConfirmRef}
        title='Remove User?'
        content='Are you sure you want to remove this user? This action cannot be undone.'
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </CustomButton>
            <CustomButton color='error' onClick={confirmRemoveContact}>
              Remove
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default InvestmentEntityTeam;
