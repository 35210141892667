/* eslint-disable no-restricted-imports */
import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import UserSelectionItem from 'src/components/molecules/UserSelectionItem';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { IDirector, IFinancialTaxDetailsForm } from 'src/modules/applications/type';
import { ITaxInformationSectionProps } from './TaxInformationSection';

interface IForeignControllingPersonsProps extends ITaxInformationSectionProps {}

const ForeignControllingPersons: FC<IForeignControllingPersonsProps> = (props) => {
  const { isViewMode, isDisabledEdit, foreignControllingPersons, isExistingInvestmentEntity } =
    props;
  const { control, watch, getFieldState, setValue } = useFormContext<IFinancialTaxDetailsForm>();
  const {
    fields: selectedDirectors,
    append: selectDirector,
    remove: unSelectDirector,
  } = useFieldArray({
    control,
    name: 'foreignControllingPersons.directors',
    keyName: 'key',
  });

  const watchHasControllingPersons = watch(
    'foreignControllingPersons.hasControllingPersonsOtherThanAustralia',
  );
  const directorsState = getFieldState('foreignControllingPersons.directors');
  const directorIds = watch('foreignControllingPersons.directorIds');

  useEffect(() => {
    if (!isEmpty(directorIds)) {
      setValue(
        'foreignControllingPersons.directors',
        (foreignControllingPersons || []).filter((it) => directorIds.includes(it.id)),
      );
    }
  }, [directorIds, foreignControllingPersons]);

  const handleSelectionChange = (director: IDirector, isSelected: boolean) => {
    const directorIndex = selectedDirectors.findIndex((it) => it.id === director.id);

    if (isSelected) {
      unSelectDirector(directorIndex);
    } else {
      selectDirector(director);
    }
  };

  return (
    <Box>
      <Grid container rowSpacing='18px'>
        <Grid item xs={12}>
          <FormRadioGroup
            name='foreignControllingPersons.hasControllingPersonsOtherThanAustralia'
            label={
              <LabelWithTooltip
                label={
                  <Typography variant='body3'>
                    Does the entity have any Controlling Persons who are tax residents of countries
                    other than <br /> Australia?
                  </Typography>
                }
                tooltip='A Controlling Person is any individual who directly or indirectly exercises control over the entity. For a company, this includes any beneficial owners controlling more than 25% of the shares in the company. For a Trust, this includes Trustees, Settlors or Beneficiaries. For a partnership this includes any partners.'
              />
            }
            options={TrueFalseRadioOptions}
            optionGap={10}
            isBooleanValue
            disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
          />
        </Grid>
        {!!watchHasControllingPersons && (
          <Grid item xs={12}>
            <Box>
              <Typography variant='body3' fontWeight={500}>
                Select from the list
              </Typography>
              <Box
                className='mt-2 py-4 px-5 rounded-xl border'
                borderColor='neutral.ne400'
                bgcolor='base.white'
              >
                <Grid container rowSpacing='12px'>
                  {!isEmpty(foreignControllingPersons) ? (
                    foreignControllingPersons?.map((item) => {
                      const isSelected = (selectedDirectors || []).some((it) => it.id === item.id);
                      return (
                        <UserSelectionItem
                          key={item.id}
                          isSelected={isSelected}
                          name={item.fullName}
                          onSelect={() => handleSelectionChange(item, isSelected)}
                          description={item?.position || 'Director'}
                          disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
                        />
                      );
                    })
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        className='block'
                        variant='body3'
                        textAlign='center'
                        color='neutral.ne800'
                      >
                        No data
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {directorsState?.error && (
                <Box pt={1}>
                  <CustomHelperText message={directorsState.error?.message} variant='error' />
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ForeignControllingPersons;
