/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CreateSignatoryContactUser, {
  CreateSignatoryContactUserHandler,
} from 'src/components/pages/funds-management/funds/components/CreateSignatoryContactUser';
import { UPSERT_FUND_STEPS } from 'src/components/pages/funds-management/funds/components/UpsertFundsForm';
import UserSelectionBox from 'src/components/pages/funds-management/funds/components/UserSelectionBox';
import { InvestorRelationsContact } from 'src/constants/funds-management';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { IInvestorContact, IUpsertFundForm } from 'src/modules/funds-management/type';
import { useGetUnitClassById } from 'src/modules/unit-class/hooks';
import { CREATE_UNIT_CLASS_STEPS } from './UpsertUnitClassForm';

interface IInvestorRelationsProps extends IUpsertFundForm {
  fundId?: string;
  unitClassId?: string;
}

interface IUserForm extends IInvestorContact {
  isNew?: boolean;
  isSelected?: boolean;
}
const InvestorRelations: React.FC<IInvestorRelationsProps> = ({
  isViewMode,
  fundId = '',
  unitClassId = '',
}) => {
  const createUserRef = React.useRef<CreateSignatoryContactUserHandler>(null);
  const createDrawerRef = React.useRef<ICustomDrawerElement>(null);
  const [users, setUsers] = React.useState<IUserForm[]>([]);
  const isDisabledAddNewUser = users.some((user) => user.isNew) || isViewMode;

  const { data: fundContactDetail } = useGetFundById(fundId, UPSERT_FUND_STEPS.CONTACT_DETAILS);
  const { data: unitClassData } = useGetUnitClassById(
    unitClassId,
    CREATE_UNIT_CLASS_STEPS.INVESTOR_RELATIONS,
  );
  const investorContact = fundContactDetail?.contactDetails?.investorContact;

  const form = useFormContext();

  React.useEffect(() => {
    const selectedUser = users.find((f) => f.isSelected);
    let formData = {};
    if (!isEmpty(selectedUser)) {
      const isOrganisation = selectedUser.option === InvestorRelationsContact.Organisation;
      formData = {
        investorContactType: selectedUser.option,
        firstName: !isOrganisation ? selectedUser.firstName : null,
        lastName: !isOrganisation ? selectedUser.lastName : null,
        email: selectedUser.email,
        position: selectedUser.position,
        contactNumber: selectedUser.contactNumber,
        countryFlagCode: selectedUser.countryFlagCode,
        fundInvestorContactId: selectedUser.isNew ? null : selectedUser.id,
        organisationName: isOrganisation
          ? selectedUser.organisationName || selectedUser?.firstName
          : null,
        contactName: isOrganisation ? selectedUser.contactName || selectedUser?.lastName : null,
      };
    }
    form.reset(formData);
  }, [users]);

  React.useEffect(() => {
    handleFillInvestorRelations();
  }, [investorContact, unitClassData]);

  const handleFillInvestorRelations = () => {
    const selectedItem = unitClassData?.contactDetailsInvestorRelations || {};
    const newUsers = [];

    if (selectedItem?.email) {
      newUsers.push({
        ...selectedItem,
        option: selectedItem?.investorContactType,
        isSelected: true,
        isNew: false,
      });
    }

    if (investorContact?.email && !newUsers.some((f) => f.email === investorContact.email)) {
      newUsers.push({
        ...investorContact,
        isSelected: false,
      });
    }

    setUsers(newUsers);
  };

  const handleCreateNewUser = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  const handleAddUser = (newUser: IUserForm) => {
    const newUsers = [...users].map((user) => ({
      ...user,
      isSelected: false,
    }));
    newUsers.unshift({
      ...newUser,
      isSelected: true,
      isNew: true,
    });

    setUsers(newUsers);
    createDrawerRef.current?.close();
  };

  const onSelect = (index: number, user: IUserForm) => {
    setUsers((prev: IUserForm[]) =>
      prev.map((item, itemIndex) => ({
        ...item,
        isSelected: prev.length > 1 ? itemIndex === index : user.isSelected,
      })),
    );
  };

  const onRemove = (index: number) => {
    setUsers((prev) => {
      prev = prev.splice(index, 1);
      prev[0].isSelected = true;
      return prev;
    });
  };

  return (
    <Box>
      {!isEmpty(users) && (
        <UserSelectionBox
          users={users}
          title='Select primary investor relations contact'
          onRemove={onRemove}
          onSelect={onSelect}
          disabled={isViewMode}
        />
      )}
      <CustomButton
        onClick={() => createDrawerRef.current?.open()}
        className='mt-[14px]'
        variant='text'
        startIcon={<PlusPrimaryIcon disabled={isDisabledAddNewUser} />}
        disabled={isDisabledAddNewUser}
      >
        <Typography
          variant='body2'
          fontWeight={600}
          color={isDisabledAddNewUser ? 'neutral.ne400' : 'primary.main'}
        >
          Add New Contact
        </Typography>
      </CustomButton>
      <CustomDrawer
        ref={createDrawerRef}
        title='Add New Contact'
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => createDrawerRef.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton className='w-[160px]' onClick={handleCreateNewUser}>
              Create new
            </CustomButton>
          </Box>
        }
      >
        <CreateSignatoryContactUser ref={createUserRef} isIncludeContactOption />
      </CustomDrawer>
    </Box>
  );
};

export default InvestorRelations;
