/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { CLIENT_API, PROFILE_API } from 'src/constants/apiEndpoints';
import { detectPortalType } from 'src/helpers/common';
import { BaseQuery, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  activeClient,
  createClient,
  createClientTeamUser,
  createDraftClient,
  deleteClient,
  deleteClientTeamUser,
  downloadInvoice,
  fundGetClientTeamUserFunds,
  fundResendClientTeamUserEmail,
  fundSubmitBranding,
  fundSubmitClient,
  fundSubmitClientTeam,
  fundSubmitContactDetails,
  fundSubmitOrganisationDetails,
  fundSubmitOurTeam,
  generateManualInvoice,
  getAdministrators,
  getAssignees,
  getCapacity,
  getClientDetail,
  getClientFilters,
  getClientList,
  getFundOurTeam,
  getInvoices,
  impersonateClient,
  submitClient,
  toggleClientStatus,
  updateClient,
  updateClientTeamUser,
  updateClientTeamUserStatus,
} from './services';
import {
  IClientQueryBody,
  ICreateClientUserFormFields,
  IManualInvoiceBody,
  IResendClientTeamUserParams,
} from './type';

export const useGetCapacity = () => {
  const { data, isLoading, refetch } = useQuery([CLIENT_API.capacity.api], getCapacity);

  const capacity = useMemo(() => {
    return data?.map((i: { id: string; capacityName: string }) => ({
      ...i,
      value: i.id,
      label: i.capacityName,
    }));
  }, [data]);

  return {
    data: capacity,
    isLoading,
    refetch,
  };
};

export const useCreateClient = () => {
  const handleCreateClient = async (data: any) => {
    return await createClient(data);
  };
  return useMutation(handleCreateClient);
};

export const useGetClientList = () => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data, refetch, ...rest } = useQuery([CLIENT_API.getClientList.api], () =>
    getClientList(queryBody),
  );

  useEffect(() => {
    refetch();
  }, [queryBody]);

  const setParams = (newParams: IClientQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  const result = {
    items: data?.items,
    metadata: data?.metadata,
  };

  return {
    data: result,
    refetch,
    setParams,
    ...rest,
  };
};

export const useGetClientDetail = (id: string, step?: number) => {
  const { isFunds } = detectPortalType();
  let params = {};

  if (isFunds) {
    params = {
      step,
      pageSize: 20,
    };
  }

  return useQuery(
    [CLIENT_API.getClientDetail.api(id), id, step],
    () => getClientDetail(id, params),
    { enabled: !!id },
  );
};

export const useGetAdministrators = (clientId: string) => {
  return useQuery(
    [CLIENT_API.getAdministrators.api(clientId), clientId],
    () => getAdministrators(clientId),
    {
      enabled: Boolean(clientId),
    },
  );
};

export const useUpdateClient = () => {
  const handleUpdateClient = async ({ clientId, data }: { clientId: string; data: any }) => {
    return await updateClient(clientId, data);
  };
  return useMutation(handleUpdateClient);
};

export const useGetClientFilters = () => {
  return useQuery([CLIENT_API.filters.api], getClientFilters);
};

export const useDeleteClient = () => {
  const queryClient = useQueryClient();
  const handleDeleteClient = async (clientId: string) => {
    await deleteClient(clientId);
    await queryClient.invalidateQueries([PROFILE_API.getOverarchingFilter.api]);
  };
  return useMutation(handleDeleteClient);
};

export const useToggleClientStatus = () => {
  const handleToggleClientStatus = async (clientId: string) => {
    return await toggleClientStatus(clientId);
  };
  return useMutation(handleToggleClientStatus);
};

export const useActiveClient = () => useMutation(activeClient);

export const useImpersonateClient = () => {
  const handleImpersonateClient = async (clientId: string) => {
    return await impersonateClient(clientId);
  };
  return useMutation(handleImpersonateClient);
};

export const useGetAssignees = (enabled: boolean) => {
  return useQuery([CLIENT_API.getAssignees.api], getAssignees, {
    enabled,
  });
};

export const useCreateDraftClient = () => {
  const handleCreateDraftClient = async (data: any) => {
    return await createDraftClient(data);
  };

  return useMutation(handleCreateDraftClient);
};

export const useSubmitClient = () => {
  const handleSubmitClient = async (data: any) => {
    return await submitClient(data);
  };

  return useMutation(handleSubmitClient);
};

export const useFundSubmitOrganisationDetails = () => {
  const handleFundSubmitOrganisationDetails = async (data: any) => {
    return await fundSubmitOrganisationDetails(data);
  };

  return useMutation(handleFundSubmitOrganisationDetails);
};

export const useFundSubmitContactnDetails = () => {
  const handleFundSubmitContactDetails = async (data: any) => {
    return await fundSubmitContactDetails(data);
  };

  return useMutation(handleFundSubmitContactDetails);
};

export const useFundSubmitOurTeam = () => {
  const handleFundSubmitOurTeam = async (data: any) => {
    return await fundSubmitOurTeam(data);
  };

  return useMutation(handleFundSubmitOurTeam);
};

export const useGetFundOurTeam = (enabled: boolean) => {
  return useQuery([CLIENT_API.getFundOurTeam.api], getFundOurTeam, {
    enabled,
  });
};

export const useUpsertClientTeamUser = () => {
  const handeUpsertClientTeamUser = async ({
    userId,
    data,
  }: {
    userId: string;
    data: ICreateClientUserFormFields;
  }) => {
    return userId ? await updateClientTeamUser(userId, data) : await createClientTeamUser(data);
  };

  return useMutation(handeUpsertClientTeamUser);
};

export const useDeleteClientTeamUser = () => {
  const handleDeleteClientTeamUser = async ({
    userId,
    clientId,
  }: {
    userId: string;
    clientId: string;
  }) => {
    return await deleteClientTeamUser(userId, clientId);
  };

  return useMutation(handleDeleteClientTeamUser);
};

export const useFundSubmitClientTeam = () => {
  const handleFundSubmitClientTeam = async (data: any) => {
    return await fundSubmitClientTeam(data);
  };

  return useMutation(handleFundSubmitClientTeam);
};

export const useFundSubmitBranding = () => {
  const handleFundSubmitBranding = async (data: any) => {
    return await fundSubmitBranding(data);
  };

  return useMutation(handleFundSubmitBranding);
};

export const useFundSubmitClient = () => {
  const handleFundSubmitClient = async (id: string) => {
    return await fundSubmitClient(id);
  };

  return useMutation(handleFundSubmitClient);
};

export const useFundResendClientTeamUserEmail = () => {
  const handleFundResendClientTeamUserEmail = async ({
    userId,
    clientId,
  }: IResendClientTeamUserParams) => {
    return await fundResendClientTeamUserEmail(userId, clientId);
  };

  return useMutation(handleFundResendClientTeamUserEmail);
};

export const useFundGetClienTeamUserFunds = (clientId: string) => {
  return useQuery([CLIENT_API.fundGetClientTeamUserFunds.api(clientId)], () =>
    fundGetClientTeamUserFunds(clientId),
  );
};

export const useGenerateManualInvoice = () => {
  const queryClient = useQueryClient();
  const resp = async ({
    clientId,
    billingId,
    data,
  }: {
    clientId: string;
    billingId: string;
    data: IManualInvoiceBody;
  }) => {
    await generateManualInvoice(clientId, billingId, data);
    await queryClient.invalidateQueries([CLIENT_API.getInvoices.api(clientId)]);
  };

  return useMutation(resp);
};

export const useGetInvoices = (clientId: string) => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({}));

  const query = useQuery(
    [CLIENT_API.getInvoices.api(clientId), queryBody],
    () => getInvoices(clientId, queryBody),
    {
      enabled: !!clientId,
    },
  );

  const setParams = (newParams: BaseQuery) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    ...query,
    params: queryBody,
    setParams,
  };
};

export const useDownloadInvoice = () => {
  const resp = async ({
    clientId,
    invoiceId,
    invoiceName,
  }: {
    clientId: string;
    invoiceId: string;
    invoiceName: string;
  }) => {
    const data = await downloadInvoice(clientId, invoiceId);
    downloadFileFromArrayBuffer(data, invoiceName);
  };

  return useMutation(resp);
};

export const useUpdateClientTeamUserStatus = () => {
  return useMutation(updateClientTeamUserStatus);
};
