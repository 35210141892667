import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Box, CircularProgress, FormControlLabel, IconButton, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BiometricIcon } from 'src/assets/icons/BiometricIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BlueDoneImage } from 'src/assets/images/BlueDoneImage';
import InvestorHubLogoSrc from 'src/assets/images/logo/investorbase-logo-light.png';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import { Logo } from 'src/components/atoms/Logo';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { useGreenID } from 'src/components/molecules/GreenID';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { CAMERA_PERMISSION_STATUS } from 'src/constants/common';
import { BIOMETRIC_CAMERA_ACCESS_MESSAGE, GREEN_ID } from 'src/constants/greenId';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { detectPortalType } from 'src/helpers/common';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { useCompletedBiometricKYC, useRegisterBiometricKYC } from 'src/modules/applications/hooks';
import { IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import NoteTerm from './NoteTerm';
import VerifyMethod from './VerifyMethod';

export default function VerifyBiometric(props: IVerifyIndividualItem) {
  const [confirmed, setConfirmed] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [selectedIndividual, setSelectedIndividual] = useState('');
  const [cameraStatus, setCameraStatus] = useState('idle');
  const [sessionCancelled, setSessionCancelled] = useState(false);

  const biometricModalRef = React.useRef<IBasicModalElement>(null);
  const alertModalRef = React.useRef<IBasicModalElement>(null);
  const confirmCloseBioModalRef = useRef<IConfirmModalElement>(null);

  const navigate = useNavigateWithParams();
  const location = useLocation();
  const { isInvestor } = detectPortalType();

  const handleOpenBiometricModal = (status: boolean) => {
    if (status) {
      biometricModalRef.current?.open();
    } else {
      biometricModalRef.current?.close();
    }
  };

  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = useRegisterBiometricKYC({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (response: any) => {
      handleOpenBiometricModal(true);
      const token = get(response, 'token');
      setToken(token);
      initGreenID();
      if (token) greenVerifyBiometric(token);
    },
  });
  const { mutate: completed, isSuccess: completedBiometricSuccess } = useCompletedBiometricKYC({
    onSuccess: () => {
      props.refetch && props.refetch();
      handleOpenBiometricModal(false);
    },
  });

  // Camera check
  useEffect(() => {
    // Check camera permission when component mounts
    checkCameraPermission();
  }, []);

  /**
   * Refresh application if session is cancelled
   * To fix the blank page issue and allowing user to re-verify
   */
  useEffect(() => {
    if (sessionCancelled) {
      handleRefresh();
    }
  }, [sessionCancelled]);

  /**
   * Show KYC verification popup after completed document and refetch KYC info
   * Only show for selected individual to verify
   */
  useEffect(() => {
    if (
      completedBiometricSuccess &&
      !props.isRefetchingKYCInfo &&
      selectedIndividual === props.individualId
    ) {
      alertModalRef.current?.open();
      setSelectedIndividual('');
    }
  }, [completedBiometricSuccess, props.isRefetchingKYCInfo]);

  const { loading, domId, initGreenID, greenVerifyBiometric, skipVerify, removeGreenIdSource } =
    useGreenID(
      {
        accountId: GREEN_ID.ACCOUNT_ID,
        apiCode: GREEN_ID.API_CODE,
        scriptConfig: GREEN_ID.SCRIPT_CONFIG,
        scriptUi: GREEN_ID.SCRIPT_UI,
        style: GREEN_ID.STYLE,
        monoStyle: GREEN_ID.MONO_STYLE,
        env: GREEN_ID.ENV,
        autoInitSource: true,
      },
      {
        enableBackButtonWarning: true,
        sessionCompleteCallback: () => {
          completed({
            appId: props.appId,
            individualId: props.individualId,
            code: props.code,
          });
        },
        sessionCancelledCallback: () => {
          handleOpenBiometricModal(false);
          setSessionCancelled(true);
        },
      },
    );

  const checkCameraPermission = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'camera' as PermissionName });

      // Set camera permission state
      if (permission?.state) {
        setCameraStatus(permission.state);
      }

      // Listen for permission state changes
      permission.onchange = () => {
        setCameraStatus(permission.state);
      };
    } catch (error) {
      console.error('Error checking camera permission:', error);
    }
  };

  const onRegister = () => {
    if (props?.individual?.isKYCVerified) return;
    if (confirmed && started) {
      setSelectedIndividual(props.individualId);
      return register({
        appId: props.appId,
        individualId: props.individualId,
        code: props.code,
      });
    }
    handleOpenBiometricModal(true);
  };

  const onConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const onGetStarted = () => {
    setStarted(true);
    setSelectedIndividual(props.individualId);
    register({
      appId: props.appId,
      individualId: props.individualId,
      code: props.code,
    });
  };

  const handleRefresh = () => {
    if (!isInvestor) return;

    skipVerify();
    removeGreenIdSource();

    // Reload browser
    if (location.pathname === ROUTES_PATH.APPLICATIONS) {
      navigate(window.location.pathname, {
        id: props.appId,
        step: INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION,
      });
      window.location.reload();
    } else {
      // Handle for redirecting to KYC verification step from offer details page
      window.location.href = `${ROUTES_PATH.APPLICATIONS}?id=${props.appId}&step=${INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION}`;
    }
  };

  const onClose = () => {
    if (started) {
      if (!token) return biometricModalRef.current?.close();

      // Handle refresh browser to fix the blank page issue of Biometric verification
      handleRefresh();
      confirmCloseBioModalRef.current?.close();
    }
    handleOpenBiometricModal(false);
  };

  const onCloseAlert = () => {
    alertModalRef.current?.close();
  };

  React.useEffect(() => {
    if (isError) {
      alertModalRef.current?.open();
      biometricModalRef.current?.close();
    } else alertModalRef.current?.close();
  }, [isError]);

  const handleCloseBiometricModal = () => {
    if (started) {
      confirmCloseBioModalRef.current?.open();
    } else {
      handleOpenBiometricModal(false);
    }
  };

  return (
    <>
      <VerifyMethod
        onClick={onRegister}
        icon={isLoading || loading ? CircularProgress : BiometricIcon}
        label='Biometric Verification'
        disabled={
          props?.individual?.isKYCVerified ||
          props?.individual?.overallStatus === IOverallStatus.VERIFIED ||
          props?.disabled ||
          cameraStatus === CAMERA_PERMISSION_STATUS.DENIED
        }
        helperText={
          cameraStatus === CAMERA_PERMISSION_STATUS.DENIED ? BIOMETRIC_CAMERA_ACCESS_MESSAGE : ''
        }
      />
      <BasicModal
        ref={biometricModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        disableClose={true}
      >
        <Box className='w-[1120px]'>
          <Box className='flex justify-between items-center p-[45px] pb-5'>
            <Logo src={InvestorHubLogoSrc} alt='Funcorr' />
            <IconButton sx={{ p: 0 }} onClick={handleCloseBiometricModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display='flex' flexDirection='column' className='p-12.5 pt-0' fontSize='14px'>
            <Box className='rounded-xl border p-6 py-2'>
              {confirmed && started ? (
                <Box id={domId} />
              ) : (
                <Box>
                  <Typography variant='h6'>Verify your identity</Typography>
                  <Typography variant='body2' fontWeight={400}>
                    On the following pages you will be asked to verify your identity.
                  </Typography>
                  <Typography variant='body2' fontWeight={400}>
                    Please have your identity documents ready before you begin, such as your
                    driver&apos;s licence or passport.
                  </Typography>
                  <FormControlLabel
                    sx={{
                      marginY: 2,
                      alignItems: 'start',
                    }}
                    control={<CustomCheckbox onChange={onConfirm} />}
                    label={
                      <Box display='flex' flexDirection='column' gap={1}>
                        <Typography variant='body2' paddingLeft={1.5}>
                          For the purposes of identity verification, fraud prevention and AML/CTF
                          compliance, I confirm that I am authorised to provide this personal
                          information, and I consent to its collection and disclosure to third
                          parties.
                        </Typography>
                        <Typography variant='body2' paddingLeft={1.5}>
                          I consent for details and results from this identity verification to be
                          stored for audit and compliance purposes in accordance with the Australian
                          Privacy Principles and relevant privacy notice/s.
                        </Typography>
                      </Box>
                    }
                  />
                  <CustomButton
                    onClick={onGetStarted}
                    endIcon={<ArrowForwardOutlinedIcon />}
                    color='primary'
                    disabled={!confirmed}
                  >
                    Get Started
                  </CustomButton>
                </Box>
              )}
            </Box>
            <Box mt={2} px={2}>
              <NoteTerm />
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal disableClose={true} className='h-full' ref={alertModalRef}>
        <ConfirmationAlert
          title={props.isVerified ? 'Verification complete' : 'Verification in progress'}
          isError={isError}
          description={get(error, 'data.ErrorMessage') || get(error, 'data.errorMessage') || ''}
          image={props?.isVerified ? null : <BlueDoneImage />}
          buttonAction={{
            label: 'Close',
            onAction: onCloseAlert,
          }}
        />
      </BasicModal>
      <ConfirmModal
        title='Close Biometric Verification?'
        content={`The browser will be refreshed. Are you sure you want to close?`}
        ref={confirmCloseBioModalRef}
        PaperProps={{
          style: {
            width: 500,
          },
        }}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => confirmCloseBioModalRef.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton onClick={onClose}>Confirm</CustomButton>
          </>
        }
      />
    </>
  );
}
