/* eslint-disable no-restricted-imports */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { AuditTrailIcon } from 'src/assets/icons/AuditTrailIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { BasicModal } from 'src/components/atoms/BasicModal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer } from 'src/components/atoms/CustomDrawer';
import FormInput from 'src/components/atoms/FormInput';
import HorizontalTabs from 'src/components/molecules/HorizontalTabs';
import StatusBadge from 'src/components/molecules/StatusBadge';
import IndividualBasicInfo from 'src/components/molecules/individual/IndividualBasicInfo';
import IndividualTaxInfo from 'src/components/molecules/individual/IndividualTaxInfo';
import RequestToVerify from 'src/components/pages/applications/components/KYCVerification/RequestToVerify';
import VerifyDocumentID from 'src/components/pages/applications/components/KYCVerification/VerifyDocumentID';
import { KYC_BACK_OFFICE_API } from 'src/constants/apiEndpoints';
import { INVESTMENT_ENTITY_TYPE, individualDefaultValue } from 'src/constants/applications';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import {
  KYC_SECTION_ENUM,
  kycboUboAmlStatus,
  kycboUboKycStatus,
  kycboUboOverallStatus,
} from 'src/constants/kyc-back-office';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IKYCMethod } from 'src/modules/applications/type';
import {
  useAddNewUbo,
  useGetKYCBackOfficeById,
  useManuallyOverrideAml,
  useManuallyOverrideKyc,
  useRemoveUbo,
} from 'src/modules/kyc-back-office/hooks';
import { IKYCBackOfficeById } from 'src/modules/kyc-back-office/type';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import { individualDetailSchema } from 'src/validations/applications';
import * as yup from 'yup';
import { INVESTMENT_APPLICATION_STEP } from '../../applications/components/InvestmentApplicationForm';

interface IOwnershipStructureAndUBOProps {
  id: string;
  isEditMode?: boolean;
  onOpenAuditTrail: () => void;
}

const OwnershipStructureAndUBO = ({
  id = '',
  isEditMode,
  onOpenAuditTrail,
}: IOwnershipStructureAndUBOProps) => {
  const theme = useTheme();

  const queryClient = useQueryClient();

  const { data: { ownershipStructureUBO, applicationId = '' } = {} } = useGetKYCBackOfficeById(
    id,
    KYC_SECTION_ENUM.OWNERSHIP_AND_UBO,
  );
  const { data: { investorDetails } = {} } = useGetKYCBackOfficeById(
    id,
    KYC_SECTION_ENUM.INVESTOR_DETAILS,
  );
  const { data: applicationDetail } = useGetApplicationById(applicationId, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });

  const [verifyType, setVerifyType] = useState<{ [key: string]: 'email' | 'now' }>({});

  const { mutate: manuallyOverrideKyc, isLoading: manuallyOverridingKyc } =
    useManuallyOverrideKyc();
  const { mutate: manuallyOverrideAml, isLoading: manuallyOverridingAml } =
    useManuallyOverrideAml();
  const { mutate: addNewUbo, isLoading: addingNewUbo } = useAddNewUbo();
  const { mutate: removeUbo, isLoading: removingUbo } = useRemoveUbo();

  const manualOverrideModalRef = useRef<any>(null);
  const addNewUboRef = useRef<any>(null);

  const { reset, control } = useFormContext<IKYCBackOfficeById['ownershipStructureUBO']>();

  const { fields: ubOs } = useFieldArray({
    control,
    name: 'ubOs',
    keyName: 'dataId',
  });

  const addUboAndManuallyForm = useForm({
    defaultValues: {
      mannualReason: '',
      ubOs: [{ details: individualDefaultValue() }],
    },
    resolver: yupResolver(
      yup.object({
        mannualReason: yup.string().required(REQUIRE_MESSAGE),
        ubOs: yup.array().of(yup.object({ details: individualDetailSchema(true) })),
      }),
    ),
  });

  useEffect(() => {
    if (!isEmpty(ownershipStructureUBO)) {
      reset({
        ...ownershipStructureUBO,
        ubOs: ownershipStructureUBO?.ubOs?.map((item) => ({
          ...item,
          kycDetails: {
            ...(item?.kycDetails || {}),
            kycStatus: {
              ...(item?.kycDetails?.kycStatus || {}),
              referenceNumber:
                item?.kycDetails?.transactionId || item?.kycDetails?.kycStatus?.referenceNumber,
            },
          },
        })),
      });
    }
  }, [ownershipStructureUBO]);

  const handleVerify = (index: number, type: (typeof verifyType)[keyof typeof verifyType]) => {
    setVerifyType((prev) => {
      prev[String(index)] = type;
      return { ...prev };
    });
  };

  const handleRefetchAfterVerify = () => {
    queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id)]);
  };

  const Tabs = (index: number, disabled: boolean) => [
    {
      label: 'Details',
      content: (
        <Box mx='-24px' mb='-24px'>
          <IndividualBasicInfo
            keyName={`ubOs.${index}.details`}
            disabled={disabled}
            position={{ show: true, disabled: disabled }}
            autocompleteAddress
          />
        </Box>
      ),
    },
    {
      label: 'Tax Information',
      content: (
        <Box mx='-24px' mb='-24px'>
          <IndividualTaxInfo keyName={`ubOs.${index}.details`} disabled={disabled} />
        </Box>
      ),
    },
  ];

  const handleAuditTrail = () => onOpenAuditTrail();

  const handleOpenManuallyOverrideModal = (type: 'kyc' | 'aml', uboId: string) => {
    addUboAndManuallyForm.reset(
      { mannualReason: '', ubOs: [] },
      { keepErrors: false, keepDirty: false, keepTouched: false },
    );
    manualOverrideModalRef.current.title = `Manual ${type.toUpperCase()} Override`;
    manualOverrideModalRef.current?.open();
    manualOverrideModalRef.current.onSubmit = (mannualReason: string) => {
      if (type === 'kyc') {
        manuallyOverrideKyc(
          { id, uboId, mannualReason },
          {
            onSuccess: handleCloseManuallyOverrideModal,
            onError: handleErrorFromServer,
          },
        );
      } else if (type === 'aml') {
        manuallyOverrideAml(
          { id, uboId, mannualReason },
          {
            onSuccess: handleCloseManuallyOverrideModal,
            onError: handleErrorFromServer,
          },
        );
      }
    };
  };

  const handleCloseManuallyOverrideModal = () => {
    manualOverrideModalRef.current?.close();
  };

  const onSubmitReason = (data: any) => {
    manualOverrideModalRef.current.onSubmit(data.mannualReason);
  };

  const handleOpenAddNewUbo = () => {
    addUboAndManuallyForm.reset(
      { mannualReason: 'dummy reason', ubOs: [{ details: individualDefaultValue() }] },
      { keepErrors: false, keepDirty: false, keepTouched: false },
    );
    addNewUboRef.current?.open();
  };

  const handleCloseAddNewUbo = () => {
    addNewUboRef.current?.close();
  };

  const onSubmitNewUbo = () => {
    const data = addUboAndManuallyForm.getValues().ubOs[0].details;
    addNewUbo(
      { id, data },
      {
        onSuccess: handleCloseAddNewUbo,
        onError: handleErrorFromServer,
      },
    );
  };

  const onDeleteUbo = (uboId: string) => {
    removeUbo(
      { id, uboId },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const dataFoundToShow = [
    { label: 'First Name', keyValue: 'givenName' },
    { label: 'Middle Name', keyValue: 'middleName' },
    { label: 'Family Name', keyValue: 'familyName' },
    { label: 'Email', keyValue: 'email' },
    { label: 'Birthdate', keyValue: 'birthdate' },
    { label: 'Phone Number', keyValue: 'phoneNumber' },
    { label: 'Country', keyValue: 'address.country' },
    { label: 'Locality', keyValue: 'address.locality' },
    { label: 'Postal Code', keyValue: 'address.postalCode' },
    { label: 'Region', keyValue: 'address.region' },
    { label: 'Street Address', keyValue: 'address.streetAddress' },
  ];

  return (
    <Box className='flex flex-col gap-6 pt-4'>
      <CustomButton
        sx={{
          color: 'sematic.success500',
          borderColor: 'sematic.success500',
          border: 1,
          px: 2,
          position: 'absolute',
          right: 0,
          top: '-40px',
        }}
        variant='text'
        startIcon={<AuditTrailIcon />}
        onClick={handleAuditTrail}
      >
        Audit Trail
      </CustomButton>
      {ubOs?.map((ubo, index) => {
        const firstName = ubo.details.firstName;
        const lastName = ubo.details.lastName;
        const position = ubo.details.position;
        const dataFound = JSON.parse(ubo.kycDetails?.kycStatus?.dataFound || '""');
        const verifiedBackgroundSource = ubo.kycDetails?.kycStatus?.verifiedBackgroundSource;
        const matchedWatchList = ubo.kycDetails?.amlStatus?.matchedWatchList;

        const canDelete = ubo.details.isAddedFromKYCBO;
        const verificationMethod = ubo.kycDetails?.verificationMethod;
        const kycStatusName = ubo.kycDetails?.kycStatus?.kycStatusName;
        const amlStatusName = ubo.kycDetails?.amlStatus?.amlStatusName;
        const overallStatus = ubo.kycDetails?.overallStatus;
        const isRemovedFromSKYCPopulated = ubo.details.isRemovedFromSKYCPopulated;
        const canVerify =
          kycStatusName === kycboUboKycStatus.Not_Started ||
          kycStatusName === kycboUboKycStatus.In_Progress;

        return (
          <Accordion
            key={ubo?.id || ubo?.dataId}
            sx={{
              border: `1px solid ${theme.palette.neutral.ne300} !important`,
              bgcolor: 'white',
              '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
            }}
          >
            <AccordionSummary
              sx={{
                '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
                '.MuiAccordionSummary-content': { my: 0 },
                minHeight: 'unset',
              }}
            >
              <Box className='flex justify-between items-center w-full'>
                <Typography variant='body2' fontWeight={500}>
                  {`${firstName} ${lastName}${position ? ` - ${position}` : ''}`}
                </Typography>
                <Box className='flex items-center'>
                  {ubo.kycDetails?.kycVerifiedDate && (
                    <Typography variant='body3' mr={1}>
                      {utcToLocalTimezone(ubo.kycDetails?.kycVerifiedDate)}
                    </Typography>
                  )}
                  <StatusBadge
                    status={
                      isRemovedFromSKYCPopulated
                        ? kycboUboOverallStatus.Removed
                        : ubo.kycDetails?.overallStatusName
                    }
                    showDot={false}
                  />
                  {isEditMode && canDelete && (
                    <IconButton
                      sx={{ p: 0, ml: 1 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDeleteUbo(ubo.id);
                      }}
                      disabled={removingUbo}
                    >
                      <DeleteIcon width={24} height={24} color={theme.palette.neutral.ne600} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className='p-6 pt-0 mt-0'>
              <HorizontalTabs tabs={Tabs(index, true)} />
              <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
              <Box className='flex flex-col gap-6'>
                <CustomBox
                  title='Overall Verification Status'
                  status={
                    isRemovedFromSKYCPopulated
                      ? kycboUboOverallStatus.Removed
                      : ubo.kycDetails?.overallStatusName
                  }
                >
                  <Grid container spacing={3} pt={2}>
                    <Grid item xs={6}>
                      <FormInput
                        name={`ubOs.${index}.kycDetails.verificationMethodName`}
                        label='Selected Verification Method'
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        name={`ubOs.${index}.kycDetails.kycVerifiedDate`}
                        formatValue={(value) =>
                          value ? dayjs(value).format(DATE_PICKER_FORMAT) : ''
                        }
                        label='Date Completed'
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </CustomBox>
                <CustomBox title='KYC Status' status={kycStatusName}>
                  <Grid container spacing={3} pt={2}>
                    <Grid item xs={6}>
                      <FormInput
                        name={`ubOs.${index}.kycDetails.kycStatus.referenceNumber`}
                        label='Reference number'
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        name={
                          kycStatusName === kycboUboKycStatus.Manually_Verified
                            ? `ubOs.${index}.kycDetails.kycStatus.mannualDate`
                            : `ubOs.${index}.kycDetails.kycStatus.dateCompleted`
                        }
                        formatValue={(value) =>
                          value ? dayjs(value).format(DATE_PICKER_FORMAT) : ''
                        }
                        label='Date Completed'
                        readOnly
                      />
                    </Grid>
                    {verificationMethod === IKYCMethod.CONNECTION_ID && (
                      <Grid item xs={6}>
                        <FormInput
                          name={`ubOs.${index}.kycDetails.kycStatus.bankName`}
                          label='Bank Name'
                          readOnly
                        />
                      </Grid>
                    )}
                    {verificationMethod === IKYCMethod.BIOMETRIC && (
                      <Grid item xs={6}>
                        <FormInput
                          name={`ubOs.${index}.kycDetails.kycStatus.passRate`}
                          label='Pass Rate'
                          readOnly
                        />
                      </Grid>
                    )}
                    {verificationMethod === IKYCMethod.CONNECTION_ID && dataFound && (
                      <Grid item xs={12}>
                        <Typography variant='body3'>Verified Details</Typography>
                        <Box
                          mt={0.75}
                          py={2}
                          px={2.5}
                          bgcolor='white'
                          borderRadius={'12px'}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={3}
                        >
                          {dataFoundToShow.map((it, index) => {
                            const value = get(dataFound, it.keyValue);
                            return (
                              value && (
                                <Box display={'inline'} key={index}>
                                  <Typography variant='body3' color='neutral.ne800'>
                                    {it.label}:
                                  </Typography>
                                  <Typography variant='body3' fontWeight={500} ml={1.5}>
                                    {it.keyValue === 'birthdate'
                                      ? dayjs(value).format(DATE_PICKER_FORMAT)
                                      : value}
                                  </Typography>
                                </Box>
                              )
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant='body3'>Verified Background Source</Typography>
                      <Box
                        className='break-all'
                        mt={0.75}
                        py={2}
                        px={2.5}
                        bgcolor='white'
                        borderRadius={'12px'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={3}
                        minHeight={200}
                        fontSize='14px'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {verifiedBackgroundSource}
                      </Box>
                    </Grid>
                    {!!ubo.kycDetails?.kycStatus?.mannualReason && (
                      <Grid item xs={12}>
                        <FormInput
                          multiline
                          rows={3}
                          name={`ubOs.${index}.kycDetails.kycStatus.mannualReason`}
                          label='Reason'
                          sx={{ '.MuiFormHelperText-root': { textAlign: 'right', mr: 0 } }}
                          helperText={
                            <Typography variant='caption1' textAlign={'right'}>
                              {`Last update by ${
                                ubo.kycDetails?.kycStatus?.mannualBy
                              } at ${utcToLocalTimezone(ubo.kycDetails?.kycStatus?.mannualDate)}`}
                            </Typography>
                          }
                          readOnly
                        />
                      </Grid>
                    )}
                  </Grid>
                  <CustomButton
                    sx={{ mt: 3 }}
                    onClick={() => handleOpenManuallyOverrideModal('kyc', ubo.id)}
                    disabled={!isEditMode || !canVerify}
                  >
                    Manual Override
                  </CustomButton>
                </CustomBox>
                <CustomBox title='AML Status' status={amlStatusName}>
                  <Grid container spacing={3} pt={2}>
                    <Grid item xs={6}>
                      <FormInput
                        name={`ubOs.${index}.kycDetails.amlStatus.referenceNumber`}
                        label='Reference number'
                        readOnly
                      />
                    </Grid>
                    {amlStatusName && (
                      <Grid item xs={6}>
                        <FormInput
                          name={
                            amlStatusName === kycboUboAmlStatus.Manually_Verified
                              ? `ubOs.${index}.kycDetails.amlStatus.mannualDate`
                              : `ubOs.${index}.kycDetails.amlStatus.dateCompleted`
                          }
                          label='Date Completed'
                          formatValue={(value) =>
                            value ? dayjs(value).format(DATE_PICKER_FORMAT) : ''
                          }
                          readOnly
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant='body3'>List of matched watchlists</Typography>
                      <Box
                        mt={0.75}
                        py={2}
                        px={2.5}
                        bgcolor='white'
                        borderRadius={'12px'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={3}
                        minHeight={200}
                        fontSize='14px'
                        whiteSpace={'pre-line'}
                      >
                        {(matchedWatchList || [])?.join('\n')}
                      </Box>
                    </Grid>
                    {!!ubo.kycDetails?.amlStatus?.mannualReason && (
                      <Grid item xs={12}>
                        <FormInput
                          multiline
                          rows={3}
                          name={`ubOs.${index}.kycDetails.amlStatus.mannualReason`}
                          label='Reason'
                          sx={{ '.MuiFormHelperText-root': { textAlign: 'right', mr: 0 } }}
                          helperText={
                            <Typography variant='caption1' textAlign={'right'}>
                              {`Last update by ${
                                ubo.kycDetails?.amlStatus?.mannualBy
                              } at ${utcToLocalTimezone(ubo.kycDetails?.amlStatus?.mannualDate)}`}
                            </Typography>
                          }
                          readOnly
                        />
                      </Grid>
                    )}
                  </Grid>
                  <CustomButton
                    sx={{ mt: 3 }}
                    onClick={() => handleOpenManuallyOverrideModal('aml', ubo.id)}
                    disabled={
                      !isEditMode ||
                      !(
                        amlStatusName === kycboUboAmlStatus.Not_Started ||
                        amlStatusName === kycboUboAmlStatus.Match_Found
                      )
                    }
                  >
                    Manual Override
                  </CustomButton>
                </CustomBox>
                {canVerify && (
                  <Box className='mt-4 flex gap-3'>
                    <CustomButton
                      variant='outlined'
                      onClick={() => handleVerify(index, 'email')}
                      disabled={!isEditMode}
                    >
                      <Typography color='primary' variant='body2'>
                        Request UBO Verification
                      </Typography>
                    </CustomButton>
                    <CustomButton onClick={() => handleVerify(index, 'now')} disabled={!isEditMode}>
                      Verify Now
                    </CustomButton>
                  </Box>
                )}
                {verifyType[`${index}`] === 'now' && canVerify && (
                  <Box display='flex' flexDirection='row' gap={3}>
                    <Box gap={1} width='calc(100% / 3)' display='flex' flexDirection='column'>
                      <VerifyDocumentID
                        individual={{ ...ubo.details, overallStatus }}
                        individualId={ubo.details.id as string}
                        appId={applicationId}
                        code={applicationDetail?.verifyCode}
                        refetch={handleRefetchAfterVerify}
                      />
                    </Box>
                  </Box>
                )}
                {verifyType[`${index}`] === 'email' && canVerify && (
                  <RequestToVerify
                    individual={{ ...ubo.details, overallStatus }}
                    individualId={ubo.details.id as string}
                    appId={applicationId}
                    code={applicationDetail?.verifyCode}
                    refetch={handleRefetchAfterVerify}
                  />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {investorDetails?.entityType !== INVESTMENT_ENTITY_TYPE.Individuals &&
        investorDetails?.entityType !== INVESTMENT_ENTITY_TYPE.SoleTrader &&
        isEditMode && (
          <CustomButton
            size='small'
            startIcon={<PlusPrimaryIcon />}
            variant='text'
            sx={{ px: 1, py: 0, ml: -1, width: 'fit-content', color: 'primary' }}
            onClick={handleOpenAddNewUbo}
          >
            Add new
          </CustomButton>
        )}
      <BasicModal ref={manualOverrideModalRef} onClose={handleCloseManuallyOverrideModal}>
        <FormProvider {...addUboAndManuallyForm}>
          <Box className='flex flex-col p-10 w-[600px]'>
            <Typography variant='h5' mb={3}>
              {manualOverrideModalRef.current?.title}
            </Typography>
            <FormInput
              multiline
              rows={8}
              name={'mannualReason'}
              label='Reason'
              maxLength={280}
              isShowCharactersLeft
            />
            <Box className='flex justify-end mt-6 gap-2'>
              <CustomButton
                variant='text'
                onClick={handleCloseManuallyOverrideModal}
                disabled={manuallyOverridingKyc || manuallyOverridingAml}
              >
                <Typography color='primary' variant='body2' fontWeight={500}>
                  Cancel
                </Typography>
              </CustomButton>
              <CustomButton
                onClick={addUboAndManuallyForm.handleSubmit(onSubmitReason)}
                isLoading={manuallyOverridingKyc || manuallyOverridingAml}
              >
                <Typography color='white' variant='body2' fontWeight={500}>
                  Confirm
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
      <CustomDrawer
        ref={addNewUboRef}
        title='Add New'
        ButtonComponents={
          <>
            <CustomButton
              variant='text'
              sx={{ mr: 1 }}
              onClick={handleCloseAddNewUbo}
              disabled={addingNewUbo}
            >
              Cancel
            </CustomButton>
            <CustomButton
              isLoading={addingNewUbo}
              onClick={addUboAndManuallyForm.handleSubmit(onSubmitNewUbo)}
            >
              Submit
            </CustomButton>
          </>
        }
      >
        <FormProvider {...addUboAndManuallyForm}>
          <Box mt={4} pb={2}>
            <HorizontalTabs tabs={Tabs(0, false)} />
          </Box>
        </FormProvider>
      </CustomDrawer>
    </Box>
  );
};

const CustomBox = ({ title, status, children }: any) => {
  const theme = useTheme();
  return (
    <Box
      p={3}
      border={`1px solid ${theme.palette.neutral.ne300}`}
      borderRadius='10px'
      bgcolor='neutral.ne100'
    >
      {' '}
      <Box className='flex justify-between items-center'>
        <Typography variant='body2' fontWeight={500}>
          {title}
        </Typography>
        <StatusBadge status={status} showDot={false} />
      </Box>
      {children}
    </Box>
  );
};

export default OwnershipStructureAndUBO;
