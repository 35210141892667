import { Typography, TypographyProps } from '@mui/material';
import * as React from 'react';
import { DEFAULT_CURRENCY_NAME } from 'src/constants/common';

interface ICurrencyNameProps extends TypographyProps {
  currencyName?: string;
}
const CurrencyName: React.FC<ICurrencyNameProps> = ({ currencyName, ...props }) => {
  return (
    <Typography variant='body3' color='base.black' {...props}>
      {currencyName || DEFAULT_CURRENCY_NAME}
    </Typography>
  );
};
export default CurrencyName;
