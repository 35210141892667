import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomPieChart from 'src/components/atoms/CustomPieChart';
import AmoutBox from 'src/components/pages/dashboard/components/AmoutBox';
import { useGetDashboardSummary } from 'src/modules/dashboard/hooks';
import { formatNumberWithCommas } from 'src/utils/common';
import ActionList from './components/ActionList';
import ClientSummary from './components/ClientSummary';

interface AdminDashboardProps {}

enum ActionType {
  ReviewInvestmentEntity,
  RemoteAccess,
}
const AdminDashboard: React.FC<AdminDashboardProps> = () => {
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedFundId, setSelectedFundId] = useState('');

  const { data: summaryData = {} } = useGetDashboardSummary(selectedClientId, selectedFundId);
  const {
    summary,
    actions = [],
    clients = [],
    clientPortal = {},
    investorPortal = {},
  } = summaryData;
  const { numberOfInvestmentEntities, numberOfUsers } = investorPortal;
  const { numberOfActiveClients, numberOfInactiveClients, numberOfClients } = clientPortal;

  const sumClients = numberOfActiveClients + numberOfInactiveClients;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={3}>
        <Grid xs={3} item>
          <AmoutBox title='Active Clients' amount={summary?.numberOfActiveClients} />
        </Grid>
        <Grid xs={3} item>
          <AmoutBox title='Active Funds' amount={summary?.numberOfActiveFunds} />
        </Grid>
        <Grid xs={3} item>
          <AmoutBox title='Active Investors' amount={summary?.numberOfActiveInvestors} />
        </Grid>
        <Grid xs={3} item>
          <AmoutBox
            title='Funds Under Management'
            amount={`$ ${formatNumberWithCommas(summary?.fundUnderManagement, 2)}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid xs={6} item>
          <ActionList actions={actions} />
        </Grid>
        <Grid xs={6} item>
          <Box p={2} className='bg-white rounded-[14px] h-[320px] flex'>
            <Box width='50%'>
              <Typography fontWeight={500} variant='body2'>
                CLIENT PORTALS
              </Typography>
              <Typography variant='body3' color='neutral.ne800' fontSize='12px'>
                {`(${numberOfClients} Clients)`}
              </Typography>
              <Divider sx={{ my: 1.5 }} />
              <Typography className='inline-block' fontWeight={500} variant='body3'>
                Clients
              </Typography>
              <Box>
                <CustomPieChart
                  labels={['Active', 'Inactive']}
                  data={[
                    {
                      nested: {
                        value: ((numberOfActiveClients / sumClients) * 100).toFixed(),
                        quantity: numberOfActiveClients,
                      },
                    },
                    {
                      nested: {
                        value: ((numberOfInactiveClients / sumClients) * 100).toFixed(),
                        quantity: numberOfInactiveClients,
                      },
                    },
                  ]}
                  customColors={['#00965F', '#8FD0B2']}
                  dataLabelsOnChart
                  dataLabelsFormatter={(value: any) => {
                    return value?.nested?.quantity
                      ? `${value?.nested?.quantity} | ${value?.nested?.value}%`
                      : '';
                  }}
                />
              </Box>
            </Box>
            <Box width='50%'>
              <Typography fontWeight={500} variant='body2'>
                INVESTOR PORTAL
              </Typography>
              <Typography variant='body3' color='neutral.ne800' fontSize='12px'>
                {`(${numberOfInvestmentEntities} Investment Entities)`}
              </Typography>
              <Divider sx={{ my: 1.5 }} />
              <Typography className='inline-block' fontWeight={500} variant='body3'>
                Investor Users
              </Typography>
              <Box>
                <CustomPieChart
                  labels={['Investor Users']}
                  data={[
                    {
                      nested: {
                        value: 100,
                        quantity: numberOfUsers,
                      },
                    },
                  ]}
                  customColors={['#00965F', '#8FD0B2']}
                  dataLabelsOnChart
                  dataLabelsFormatter={(value: any) => {
                    return value?.nested?.quantity;
                  }}
                  tooltipEnabled={false}
                  hideNote
                  dataLabelsConfig={{
                    anchor: 'start',
                  }}
                  borderWidth={0}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ClientSummary clients={clients} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
