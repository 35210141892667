import { Box, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomScrollbarContainer from 'src/components/atoms/CustomScrollbarContainer';
import { ADMIN_DASHBOARD_ACTION_TYPE } from 'src/constants/dashboard';
import { useRemoteAccess } from 'src/modules/dashboard/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import ReviewDuplicateEntity from './ReviewDuplicateEntity';

interface IActionListProps {
  actions: any[];
}

const ActionList: FC<IActionListProps> = ({ actions }) => {
  const reviewDuplicateEntityRef = useRef<IBasicModalElement>(null);
  const remoteAccessModalRef = useRef<IBasicModalElement>(null);
  const [selectedItem, setSelectedItem] = useState<any>();

  const { mutate: remoteAccess, isLoading: remoteAccessing } = useRemoteAccess();

  const handleClickActionItem = (item: any) => {
    setSelectedItem(item);
    if (item?.type === ADMIN_DASHBOARD_ACTION_TYPE.ReviewEntity) {
      handleOpenReviewEntityModal(true);
    } else {
      remoteAccessModalRef.current?.open();
    }
  };

  const handleOpenReviewEntityModal = (isOpen: boolean) => {
    if (isOpen) {
      reviewDuplicateEntityRef.current?.open();
    } else {
      setSelectedItem(undefined);
      reviewDuplicateEntityRef.current?.close();
    }
  };

  const handleRemoteAccess = () => {
    remoteAccess(selectedItem?.id || '', {
      onSuccess: ({ url, token }) => {
        if (url && token) {
          window.open(`${url}/impersonate?token=${token}`, '_blank');
        }
      },
      onError: handleErrorFromServer,
      onSettled: () => {
        remoteAccessModalRef.current?.close();
      },
    });
  };

  return (
    <>
      <Box py={3} pl={2} className='bg-white rounded-[14px] h-[320px]'>
        <Typography className='pl-4' variant='body2' textTransform='capitalize' fontWeight={500}>
          Action List
        </Typography>
        <CustomScrollbarContainer
          className='h-[240px] overflow-auto pr-4 mt-5'
          scrollbarTrackProps={{
            marginTop: 0,
            marginBottom: 100,
          }}
        >
          {actions?.map((item: any) => (
            <Box key={item?.id} className='flex items-center justify-between p-4'>
              <Typography variant='body3' color='neutral.ne700'>
                {item?.name}
              </Typography>

              <CustomButton
                variant='text'
                sx={{
                  px: 1.5,
                  py: 0.5,
                  fontSize: 14,
                }}
                onClick={() => handleClickActionItem(item)}
              >
                {item?.type === ADMIN_DASHBOARD_ACTION_TYPE.ReviewEntity
                  ? 'Review'
                  : 'Remote Access'}
              </CustomButton>
            </Box>
          ))}
        </CustomScrollbarContainer>
      </Box>
      <BasicModal
        ref={reviewDuplicateEntityRef}
        maxWidth='lg'
        PaperProps={{
          style: {
            width: 800,
            top: '50%',
            transform: 'translateY(-50%)',
          },
        }}
      >
        <ReviewDuplicateEntity
          entity={selectedItem}
          onClose={() => handleOpenReviewEntityModal(false)}
        />
      </BasicModal>
      <ConfirmModal
        ref={remoteAccessModalRef}
        title='Remote Access'
        content='Would you like to access this client remotely?'
        disableBackdropClick={remoteAccessing}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => remoteAccessModalRef?.current?.close()}
              disabled={remoteAccessing}
            >
              Cancel
            </CustomButton>
            <CustomButton color='primary' onClick={handleRemoteAccess} isLoading={remoteAccessing}>
              Continue
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default ActionList;
