import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { useGenerateManualInvoice } from 'src/modules/client/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { YupBase } from 'src/validations/common';
import * as yup from 'yup';

interface IProps {
  clientId: string;
  billingId: string;
  onClose: () => void;
}

const GenerateInvoiceForm = ({ clientId, billingId, onClose }: IProps) => {
  const form = useForm({
    resolver: yupResolver(
      yup.object({
        invoiceDate: YupBase.date.required(REQUIRE_MESSAGE),
      }),
    ),
  });
  const { handleSubmit, getValues } = form;

  const { mutate: generateManualInvoice, isLoading } = useGenerateManualInvoice();

  const onSubmit = () => {
    const data: any = getValues();
    generateManualInvoice(
      { clientId, billingId, data },
      {
        onSuccess: onClose,
        onError: handleErrorFromServer,
      },
    );
  };

  return (
    <FormProvider {...form}>
      <Box width={'800px'} component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Box
          className='flex justify-between'
          p={4}
          borderBottom='1px solid'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5'>Generate Invoice</Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={4} mb={2}>
          <Grid spacing={3} container>
            <Grid item xs={4}>
              <FormDatePicker name='invoiceDate' label='Invoice Date' useServerFormat />
            </Grid>
          </Grid>
        </Box>
        <Box
          px={4}
          py={2}
          borderColor={'neutral.ne200'}
          className='flex justify-end border-t border-solid gap-6'
        >
          <CustomButton variant='outlined' onClick={onClose} disabled={isLoading}>
            Cancel
          </CustomButton>
          <CustomButton variant='contained' type='submit' isLoading={isLoading}>
            Generate
          </CustomButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default GenerateInvoiceForm;
