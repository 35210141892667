/* eslint-disable no-restricted-imports */
import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import {
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  UBOS_META,
  individualDefaultValue,
} from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import IndividualDetail from './IndividualDetail';

interface ITrustTeeProps extends IInvestmentEntityProps {
  keyName:
    | 'investmentEntityDetails.regulatedTrust'
    | 'investmentEntityDetails.unregulatedTrust'
    | 'investmentEntityDetails.smsf';
  renderMoreTrustUBOs?: () => ReactNode;
  hideUBODeclaration?: boolean;
}

const TrustUbos = ({ renderMoreTrustUBOs, ...props }: ITrustTeeProps) => {
  const { isViewMode, isDisabledEdit, isExistingInvestmentEntity, keyName, hideUBODeclaration } =
    props;
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { watch, control } = useFormContext<IInvestmentEntityForm>();
  const watchTrusteeType = watch(`${keyName}.trusteeType`);
  const trusteeIndividuals = watch(`${keyName}.trusteeIndividuals`);
  const australianCompanyDirectors = watch(`${keyName}.trusteeAustralianCompanyDetails.directors`);
  const australianCompanyUBOs = watch(`${keyName}.trusteeAustralianCompanyDetails.ubOs`);

  const trustUBOsKeyName =
    keyName === 'investmentEntityDetails.regulatedTrust' ||
    keyName === 'investmentEntityDetails.unregulatedTrust'
      ? (`${keyName}.trustUBOs` as const)
      : (`${keyName}.smsfubOs` as const);

  const {
    fields: trustUBOs,
    append: addTrustUBO,
    remove: removeTrustUBO,
  } = useFieldArray({
    control,
    name: trustUBOsKeyName,
    keyName: 'dataId',
  });

  const handleAddTrustUBO = () => {
    addTrustUBO(individualDefaultValue());
  };

  return (
    <FormArrayLayout
      {...props}
      description={
        keyName === 'investmentEntityDetails.regulatedTrust' ||
        keyName === 'investmentEntityDetails.unregulatedTrust'
          ? UBOS_META.TRUST.DESCRIPTION
          : ''
      }
      title={
        keyName === 'investmentEntityDetails.regulatedTrust' ||
        keyName === 'investmentEntityDetails.unregulatedTrust'
          ? 'Trust Ultimate Beneficial Owners'
          : 'SMSF Ultimate Beneficial Owners'
      }
      tooltip={UBOS_META.COMMON.TOOLTIP}
      onAddItem={handleAddTrustUBO}
      disabled={disabled}
    >
      <Grid container rowSpacing='16px' mb='16px'>
        {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
          australianCompanyDirectors?.map(
            (director, index) =>
              director.refId && (
                <Grid key={index} item xs={12}>
                  <IndividualDetail
                    {...props}
                    keyName={`${keyName}.trusteeAustralianCompanyDetails.directors.${index}`}
                    disabledDetail
                    disabledTaxInfo
                    hasPosition
                    disabledPosition
                    hideTfn
                    autocompleteAddress
                    defaultExpanded={false}
                    hideDeclaration={hideUBODeclaration}
                  />
                </Grid>
              ),
          )}
        {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
          australianCompanyUBOs?.map((_, index) => (
            <Grid key={index} item xs={12}>
              <IndividualDetail
                {...props}
                keyName={`${keyName}.trusteeAustralianCompanyDetails.ubOs.${index}`}
                disabledDetail
                disabledTaxInfo
                hasPosition
                disabledPosition
                hideTfn
                autocompleteAddress
                defaultExpanded={false}
                hideDeclaration={hideUBODeclaration}
              />
            </Grid>
          ))}
        {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
          trusteeIndividuals?.map((individual, index) => (
            <Grid key={individual.refId} item xs={12}>
              <IndividualDetail
                {...props}
                keyName={`${keyName}.trusteeIndividuals.${index}`}
                disabledDetail
                disabledTaxInfo
                hasPosition
                hideTfn
                autocompleteAddress
                defaultExpanded={false}
                hideDeclaration={hideUBODeclaration}
              />
            </Grid>
          ))}
        {renderMoreTrustUBOs && renderMoreTrustUBOs()}
        {trustUBOs?.map((tubo, index) => (
          <Grid key={tubo.dataId} item xs={12}>
            <IndividualDetail
              {...props}
              keyName={`${trustUBOsKeyName}.${index}`}
              canDelete
              handleDelete={() => removeTrustUBO(index)}
              hasPosition
              hideTfn
              autocompleteAddress
              defaultExpanded={false}
              hideDeclaration={hideUBODeclaration}
            />
          </Grid>
        ))}
      </Grid>
    </FormArrayLayout>
  );
};

export default TrustUbos;
