import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { BILLING_VOLUME_ITEM_TIER } from 'src/constants/subscription-billing';
import { BillingPricingItem, TieringFeeForm } from 'src/modules/subscription-billing/types';
import { editTieringFeeFormSchema } from 'src/validations/subscription-billing';

interface IProps {
  isStandard?: boolean;
  billingPricing?: BillingPricingItem;
  disabled?: boolean;
  onUpdate: (_data: TieringFeeForm, _item?: BillingPricingItem) => void;
  onClose: () => void;
}

const EditTieringFeeForm = ({
  isStandard,
  disabled,
  billingPricing,
  onUpdate,
  onClose,
}: IProps) => {
  const form = useForm<TieringFeeForm>({
    defaultValues: {
      tieringSystem: true,
      noMax: true,
      tier1MinVolume: 1,
    },
    resolver: yupResolver(editTieringFeeFormSchema),
  });
  const { watch, setValue, handleSubmit, reset } = form;

  const tieringSystem = watch('tieringSystem');
  const noMax = watch('noMax');
  const tier1MaxVolume = watch('tier1MaxVolume');
  const tier2MaxVolume = watch('tier2MaxVolume');

  useEffect(() => {
    if (billingPricing) {
      const volumes = billingPricing.volumes;
      const getDataByTier = (tierType: BILLING_VOLUME_ITEM_TIER) => {
        return volumes?.find((it) => it.tierType === tierType);
      };
      const noMax = typeof getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier2)?.maxVolume !== 'number';

      reset((formState) => ({
        ...formState,
        tieringSystem: billingPricing?.tieringSystem,
        noMax,
        noTierFee: getDataByTier(BILLING_VOLUME_ITEM_TIER.NoTier)?.price ?? '',
        tier1MinVolume:
          Number(getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier1)?.minVolume) > 0
            ? (getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier1)?.minVolume as number)
            : 1, // Default to 1
        tier1MaxVolume: getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier1)?.maxVolume ?? '',
        tier1Fee: getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier1)?.price ?? '',
        tier2MinVolume: getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier2)?.minVolume ?? '',
        tier2MaxVolume: noMax ? '' : getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier2)?.maxVolume ?? '',
        tier2Fee: getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier2)?.price ?? '',
        tier3MinVolume: noMax ? '' : getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier3)?.minVolume ?? '',
        tier3Fee: noMax ? '' : getDataByTier(BILLING_VOLUME_ITEM_TIER.Tier3)?.price ?? '',
      }));
    }
  }, [billingPricing]);

  useEffect(() => {
    setValue('tier2MinVolume', Number(tier1MaxVolume) + 1);
  }, [tier1MaxVolume]);

  useEffect(() => {
    if (tier2MaxVolume && !noMax) {
      setValue('tier3MinVolume', Number(tier2MaxVolume) + 1);
    }
  }, [tier2MaxVolume]);

  const onSubmit = (data: TieringFeeForm) => {
    onUpdate(data, billingPricing);
    onClose();
  };

  const handleChangeNoMax = (e: any, checked: boolean) => {
    if (checked) {
      setValue('tier2MaxVolume', '');
      setValue('tier3MinVolume', '');
      setValue('tier3Fee', '');
    }
  };

  return (
    <FormProvider {...form}>
      <Box width={'800px'} component='form' onSubmit={handleSubmit(onSubmit)}>
        <Box
          className='flex justify-between'
          p={4}
          borderBottom='1px solid'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5'>{billingPricing?.description}</Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={4} mb={2}>
          <Grid spacing={3} container>
            {!isStandard && (
              <Grid item xs={12}>
                <FormRadioGroup
                  label={
                    <Typography variant='body3' fontWeight={500}>
                      Tiering System
                    </Typography>
                  }
                  name='tieringSystem'
                  options={TrueFalseRadioOptions}
                  isBooleanValue
                />
              </Grid>
            )}
            {(!tieringSystem || isStandard) && (
              <>
                <Grid item xs={12}>
                  <Typography variant='body2' mb={-1.5} fontWeight={500}>
                    No Tier
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='noTierFee'
                    label='Fee'
                    placeholder='Enter fee'
                    decimalScale={2}
                    hideCurrency
                    startAdornment={'$'}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
              </>
            )}
            {(tieringSystem || isStandard) && (
              <>
                <Grid item xs={12}>
                  <Typography variant='body2' mb={-1.5} fontWeight={500}>
                    Tier 1
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput name='tier1MinVolume' label='Min Volume' disabled />
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='tier1MaxVolume'
                    label='Max Volume'
                    decimalScale={0}
                    hideCurrency
                    thousandSeparator={false}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='tier1Fee'
                    label='Fee'
                    placeholder='Enter fee'
                    decimalScale={2}
                    hideCurrency
                    startAdornment={'$'}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' mb={-1.5} fontWeight={500}>
                    Tier 2
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput name='tier2MinVolume' label='Min Volume' disabled />
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='tier2MaxVolume'
                    label='Max Volume'
                    decimalScale={0}
                    hideCurrency
                    thousandSeparator={false}
                    optional={
                      <FormCheckbox
                        sx={{ color: 'neutral.ne500' }}
                        name='noMax'
                        size='small'
                        labelSx={{ lineHeight: '20px' }}
                        label={
                          <Typography
                            variant='body3'
                            color='neutral.ne800'
                            ml={-0.5}
                            whiteSpace={'pre'}
                          >
                            No Max
                          </Typography>
                        }
                        onChange={handleChangeNoMax}
                        disabled={disabled}
                      />
                    }
                    disabled={noMax || disabled}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='tier2Fee'
                    label='Fee'
                    placeholder='Enter fee'
                    decimalScale={2}
                    hideCurrency
                    startAdornment={'$'}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' mb={-1.5} fontWeight={500}>
                    Tier 3
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput name='tier3MinVolume' label='Min Volume' disabled />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <FormCurrencyInput
                    name='tier3Fee'
                    label='Fee'
                    placeholder='Enter fee'
                    decimalScale={2}
                    hideCurrency
                    startAdornment={'$'}
                    disabled={noMax || disabled}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box
          px={4}
          py={2}
          borderColor={'neutral.ne200'}
          className='flex justify-end border-t border-solid gap-6'
        >
          <CustomButton variant='outlined' onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant='contained' type='submit'>
            Save
          </CustomButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default EditTieringFeeForm;
