import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

interface TableContainerProps {
  title?: string;
  children: React.ReactNode;
  boxClassName?: string;
}

const TableContainer: React.FC<TableContainerProps> = ({ title, children, boxClassName }) => {
  return (
    <Box p={3} className={clsx('bg-white rounded-[14px] h-full flex flex-col', boxClassName)}>
      {title && (
        <Typography variant='body2' textTransform='capitalize'>
          {title}
        </Typography>
      )}
      <Box className='flex-1' mt='15px'>
        {children}
      </Box>
    </Box>
  );
};

export default TableContainer;
