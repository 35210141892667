/* eslint-disable autofix/no-unused-vars */
import dayjs from 'dayjs';
import { capitalize, omit } from 'lodash';
import { createElement } from 'react';
import DelegateIcon from 'src/assets/icons/DelegateIcon';
import InvestorIcon from 'src/assets/icons/InvestorIcon';
import { IInvestorCompanyFlagDetailConfig, ITeamContact } from 'src/modules/applications/type';
import { v4 as uuidv4 } from 'uuid';
import { AUSTRALIA_STATE_ENUM } from './address';
import { DEFAULT_COUNTRY, DistributionInstructionType, actionPermissionKeys } from './common';
import { DATE_PICKER_FORMAT_SERVER } from './date';

export const MAX_TEAM_PRIMARY_CONTACT = 1;
export const MAX_TEAM_CONTACT = 10;
export const MAX_TEAM_COMMUNICATION_LIST = 3;

export enum TeamType {
  Team,
  PrimaryContact,
  CommunicationList,
}

export enum OfferFilter {
  ShowAll,
  ShowOpenOffers,
  ShowInvitations,
  ShowFavourites,
}

export const applicationStatuses = {
  In_Progress: 'In Progress',
  Submitted: 'Submitted',
  In_Review: 'In Review',
  Approved: 'Approved',
  Finalised: 'Finalised',
  Action_Required: 'Action Required',
  Cancelled: 'Cancelled',
  Withdrawn: 'Withdrawn',
  Pending: 'Pending',
  Rejected: 'Rejected',
  KYC_In_Progress: 'KYC In Progress',
  KYC_Completed: 'KYC Completed',
  Awaiting_Deposit: 'Awaiting Deposit',
  Draft: 'Draft',
};

export const applicationActionTypes = {
  view: 'view',
  edit: 'edit',
  cancel: 'cancel',
  withdraw: 'withdraw',
  archive: 'archive',
  undo: 'undo',
  delete: 'delete',
};

export const APPLICATION_ACTION = {
  view: {
    key: applicationActionTypes.view,
    label: capitalize(applicationActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  edit: {
    key: applicationActionTypes.edit,
    label: capitalize(applicationActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  cancel: {
    key: applicationActionTypes.cancel,
    label: capitalize(applicationActionTypes.cancel),
    permissionKey: [actionPermissionKeys.isCreate, actionPermissionKeys.isEdit],
  },
  withdraw: {
    key: applicationActionTypes.withdraw,
    label: capitalize(applicationActionTypes.withdraw),
    permissionKey: [actionPermissionKeys.isCreate, actionPermissionKeys.isEdit],
  },
  archive: {
    key: applicationActionTypes.archive,
    label: capitalize(applicationActionTypes.archive),
    permissionKey: [actionPermissionKeys.isCreate, actionPermissionKeys.isEdit],
  },
  undo: {
    key: applicationActionTypes.undo,
    label: capitalize(applicationActionTypes.undo),
    permissionKey: [actionPermissionKeys.isCreate, actionPermissionKeys.isEdit],
  },
  delete: {
    key: applicationActionTypes.delete,
    label: capitalize(applicationActionTypes.delete),
    permissionKey: actionPermissionKeys.isDelete,
  },
};

export const investorTypeLabel = {
  Wholesale: 'Wholesale',
  Sophisticated: 'Sophisticated',
  Professional: 'Professional',
  Retail: 'Retail',
};

export const investorTypeTooltips = {
  [investorTypeLabel.Wholesale]: `Investor has net assets of at least $2.5 million or gross income in the last 2 financial years of at least $250,000 p.a. (wholesale investor certificate is required) or investing at least $500,000 in the Fund.`,
  [investorTypeLabel.Sophisticated]: `Not a wholesale investor - this is subject to the sophisticated assessment and approval by the Fund. `,
  [investorTypeLabel.Professional]: `Investor holds an AFSL, regulated by APRA or a person who controls at least $10 million. `,
};

export const FOREIGN_TAX_ERROR_MESSAGE =
  'This offer is not available to foreign investors. Please contact investor relations for more information.';

export enum InvestorOptionType {
  New,
  Existing,
}

export const applicationDetailsDefaultValue = {
  isSaveDraft: false,
  fundId: '',
  offerId: '',
  unitClassId: '',
  isExistingInvestorInFund: false,
  investorNumber: '',
  investorType: null,
  investmentAmount: 0,
  isForeignTaxResident: false,
  isOptionalInvestorType: false,
  investmentEntityId: null,
  investorId: null,
};

export const OfferFilterOptions = [
  {
    label: 'Show All',
    value: OfferFilter.ShowAll,
  },
  {
    label: 'Show Open Offers',
    value: OfferFilter.ShowOpenOffers,
  },
  {
    label: 'Show Invitations',
    value: OfferFilter.ShowInvitations,
  },
  {
    label: 'Show Favourites',
    value: OfferFilter.ShowFavourites,
  },
];

export enum INVESTMENT_ENTITY_TYPE {
  Individuals,
  SoleTrader,
  Company,
  Trust,
  SMSF,
  Partnership,
  Other,
}

export enum INVESTMENT_ENTITY_ROLE {
  Investor,
  Delegate,
}

export const INVESTMENT_ENTITY_ROLE_TEXT = {
  INVESTOR: 'Investor',
  DELEGATE: 'Delegate',
};

export const InvestorUserRoleOptions = [
  {
    value: INVESTMENT_ENTITY_ROLE.Investor,
    label: 'Investor',
    icon: createElement(InvestorIcon),
  },
  {
    value: INVESTMENT_ENTITY_ROLE.Delegate,
    label: 'Delegate',
    icon: createElement(DelegateIcon),
  },
];

export enum INVESTMENT_ENTITY_DELEGATE_ROLE {
  Accountant,
  Adviser,
  Other,
}

export enum INVESTMENT_ENTITY_TRUST {
  Regulated,
  Unregulated,
}

export enum INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE {
  Registered,
  Unregistered,
  Government,
  Superannuation,
  Other,
}

export enum INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE {
  Family,
  Discretionary,
  Charitable,
  Testamentary,
  Unit,
  Other,
}

export enum INVESTMENT_ENTITY_SMSF_TRUSTTYPE {
  ATORegulatedSMSFund,
  Other,
}

export enum INVESTMENT_ENTITY_TRUSTEE_TYPE {
  Corporate,
  Individual,
}

export enum INVESTMENT_ENTITY_APPOINTER_TYPE {
  Corporate,
  Individual,
}

export enum INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE {
  AddNew,
  CorporateTrustee,
  CorporateAppointer,
  Settlor,
}

export const numberOfIndividualOptions = Array.from(Array(9)).map((_, index) => ({
  label: String(index + 1),
  value: index + 1,
}));

export enum InvestmentEntityCompanyRegionType {
  Australian,
  Foreign,
}

export enum INVESTMENT_ENTITY_OTHER {
  Association,
  GovernmentBody,
}

export enum GOVERNMENT_BODY_TYPE {
  CommonwealthOfAustralia,
  AustralianStateOrTerritory,
  Foreign,
}

export enum ASSOCIATION_TYPE {
  Incorporated,
  Unincorporated,
  Other,
}

export const DEFAULT_UBOS_POSITION = {
  DIRECTOR: 'Director',
  PARTNER: 'Partner',
  SETTLOR: 'Settlor',
  TRUSTEE: 'Trustee',
};

export const investmentEntityDefaultValues = {
  isSaveDraft: false,
  isAddNewEntityDetail: false,
  entityType: INVESTMENT_ENTITY_TYPE.Individuals,
  companyRegionType: InvestmentEntityCompanyRegionType.Australian,
  trustType: INVESTMENT_ENTITY_TRUST.Unregulated,
  editorDetails: {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dob: null,
    contactNumber: '',
    countryFlagCode: DEFAULT_COUNTRY,
    hasAuthorityToComplete: true,
    userType: INVESTMENT_ENTITY_ROLE.Investor,
    capacity: null,
    otherCapacity: '',
    companyName: '',
    licenseeName: '',
    afslNumber: '',
  },
  investmentEntityId: undefined,
};

export const residentialManualDefaultValue = {
  residentialCountry: DEFAULT_COUNTRY,
  residentialState: AUSTRALIA_STATE_ENUM.ACT,
  residentialSuburb: '',
  residentialPostcode: '',
  residentialStreetNumber: '',
  residentialStreetName: '',
};

export const individualDefaultValue = (initRefId?: boolean) => ({
  refId: initRefId ? uuidv4() : '',
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  dob: null,
  contactNumber: '',
  countryFlagCode: DEFAULT_COUNTRY,
  position: '',
  residentialAddress: '',
  residentialManualEntry: false,
  ...residentialManualDefaultValue,
  taxInfo: {
    isTaxResidentAustralia: true,
    tfnExemptionCode: '',
    isTaxResidentOtherCountry: false,
    country: '',
    hasTIN: true,
    tin: '',
    reasonType: null,
    isDeclarationChecked: false,
  },
});

export const investmentEntityIndividualsDefaultValues = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.Individuals,
  investmentEntityDetails: {
    numberOfIndividuals: null,
    individuals: [],
  },
};

export const investmentEntitySoleTraderDefaultValues = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.SoleTrader,
  investmentEntityDetails: {
    allowEditAbn: true,
    soleTrader: {
      ...individualDefaultValue(),
      abn: '',
      businessName: '',
      principalPlaceOfBusiness: '',
    },
  },
};

const companyDefaultValues = {
  companyName: '',
  countryOfEstablishment: '',
  countryOfTaxResident: DEFAULT_COUNTRY,
  registeredAddress: '',
  principalPlaceOfBusiness: '',
  nameOfStockExchangeName: '',
  listedTickerCode: '',
  directors: [],
  ubOs: [],
};

export const australianCompanyDefaultValues = {
  ...companyDefaultValues,
  abn: '',
  acn: '',
  specialCompanyType: null,
  asxTickerCode: '',
  nameOfListedCompany: '',
  regulatorName: '',
  licenceName: '',
  certifiedAgreementFile: undefined,
  companyTypeSelect: null,
  isSharesHeldBehalfOfTrust: null,
  redFlagComment: '',
};

export const foreignCompanyDefaultValues = {
  ...companyDefaultValues,
  foreignEIN: '',
  nameOfRelevantForeignRegistrationBody: '',
  registeredWithASIC: false,
  isCompanyListed: false,
  arbn: '',
  companyFullNameASIC: '',
  companyType: '',
};

export const investmentEntityCompanyDefaultValues = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.Company,
  investmentEntityDetails: {
    allowEditAbn: true,
    australianCompany: australianCompanyDefaultValues,
    foreignCompany: foreignCompanyDefaultValues,
    isManualEntry: null,
  },
};

export const trusteeDefaultValue = {
  trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
  trusteeCompanyRegionType: InvestmentEntityCompanyRegionType.Australian,
  numberOfIndividuals: null,
  trusteeIndividuals: [],
  trusteeAustralianCompanyDetails: australianCompanyDefaultValues,
  trusteeForeignCompanyDetails: foreignCompanyDefaultValues,
};

export const trustDefaultValue = (trustType: number | null = null) => ({
  trustType,
  name: '',
  abn: '',
  countryOfEstablishment: DEFAULT_COUNTRY,
  countryOfTaxResident: DEFAULT_COUNTRY,
  trustTypeString: '',
  otherTrustType: '',
  registeredOffice: '',
});

export const appointerAustralianCompanyDetails = {
  specialCompanyTypeText: '',
  companyType: '',
  isManualEntry: true,
  skycApplicationId: '',
  ...australianCompanyDefaultValues,
};

export const investmentEntityTrustDefaultValues = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.Trust,
  trustType: INVESTMENT_ENTITY_TRUST.Unregulated,
  investmentEntityDetails: {
    allowEditAbn: true,
    regulatedTrust: {
      ...trustDefaultValue(),
      enterManually: false,
      arsn: '',
      trustUBOs: [],
      ...trusteeDefaultValue,
    },
    unregulatedTrust: {
      ...trustDefaultValue(),
      isSettlorMoreThanMax: false,
      settlor: {
        ...individualDefaultValue(true),
        position: DEFAULT_UBOS_POSITION.SETTLOR,
      },
      hasAppointer: false,
      appointerType: INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate,
      isAppointerSameAsTrustee: true,
      appointerCompanyRegionType: InvestmentEntityCompanyRegionType.Australian,
      appointerAustralianCompanyDetails: appointerAustralianCompanyDetails,
      appointerForeignCompanyDetails: { ...foreignCompanyDefaultValues },
      selectedExistingIndividuals: [],
      appointerIndividuals: [],
      trustUBOs: [],
      ...trusteeDefaultValue,
    },
    isManualEntry: null,
  },
};

export const investmentEntitySMSFDefaultValues = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.SMSF,
  investmentEntityDetails: {
    allowEditAbn: true,
    smsf: {
      ...trustDefaultValue(),
      arsn: '',
      superFundStatus: '',
      smsfubOs: [],
      ...trusteeDefaultValue,
    },
    isManualEntry: null,
  },
};

export const otherPartnerDefaultValue = {
  entityName: '',
  entityType: null,
  abn: '',
  businessName: '',
  beVerified: false,
};

export const investmentEntityPartnershipDefaultValue = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.Partnership,
  investmentEntityDetails: {
    allowEditAbn: true,
    partnership: {
      abn: '',
      partnershipName: '',
      businessName: '',
      countryOfEstablishment: DEFAULT_COUNTRY,
      countryOfTaxResident: DEFAULT_COUNTRY,
      typeString: '',
      type: null,
      otherType: '',
      partnershipRegulated: null,
      principalPlaceOfBusiness: '',
      partners: [],
      ubOs: [],
      certifiedAgreementFileId: undefined,
      certifiedAgreementFile: undefined,
      certifiedAgreementFileDescription: undefined,
    },
  },
};

export const investmentEntityOtherDefaultValue = {
  ...investmentEntityDefaultValues,
  entityType: INVESTMENT_ENTITY_TYPE.Other,
  otherType: INVESTMENT_ENTITY_OTHER.Association,
  investmentEntityDetails: {
    allowEditAbn: true,
    governmentBody: {
      abn: '',
      nameOfGovernmentBody: '',
      principlePlaceOfOperation: '',
      typeString: '',
      type: 0,
      otherType: '',
      state: {},
      publicOfficers: [],
      ubOs: [],
    },
    association: {
      abn: '',
      associationName: '',
      associationNumber: '',
      countryOfEstablishment: DEFAULT_COUNTRY,
      countryOfTaxResident: DEFAULT_COUNTRY,
      registeredOffice: '',
      typeString: '',
      type: 0,
      otherType: '',
      certifiedAgreementFile: {},
      publicOfficers: [],
      ubOs: [],
    },
  },
};

export const reasonForTINOptions = [
  { label: 'Country of tax residency does not issue TINs', value: 0 },
  { label: 'Entity has not been issued with a TIN', value: 1 },
  { label: 'Country of tax residency does not require TIN to be disclosed', value: 2 },
];

export enum SignatoryMethodType {
  DocuSign,
  SignNow,
  Manual,
}

export const applicationTeamDefaultValues = {
  isSaveDraft: false,
  teammates: [],
};

export const createTeamContactDefaultValues: ITeamContact = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  position: '',
  teamTypes: [0, 0, 0],
  userType: 0,
  roleId: null,
  roleName: null,
  countryFlagCode: DEFAULT_COUNTRY,
  isAddTo: false,
};

export const applicationAcceptanceDefaultValue = {
  isSaveDraft: false,
  signatories: [],
};

export const fundKYCAMLDefaultValue = {
  comment: '',
  kycSubmitType: null,
  isSaveDraft: false,
};

export enum ApplicationLetterType {
  Deposit,
  Application,
}

export const distributionInstructionOptions = [
  {
    label: 'Payment to the nominated bank account',
    value: DistributionInstructionType.PaymentToTheNominatedBankAccount,
  },
  {
    label: 'Distribution Reinvestment Plan',
    value: DistributionInstructionType.DistributionReinvestmentPlan,
  },
];

export const additionalDefaultValues = {
  isSaveDraft: false,
  sourceOfFundsType: null,
  otherDescription: '',
  distributionInstructionType: DistributionInstructionType.PaymentToTheNominatedBankAccount,
  documents: [],
  existingDocuments: [],
  fundApplicationDocuments: [],
};

export const ADDITIONAL_DOCUMENT_ACCEPT_TYPE = 'image/jpeg, image/png, .doc, .docx, .pdf';

// 1024 * 20 = 1MB * 20 = 20MB
export const MAX_ADDITIONAL_DOCUMENT_UPLOAD = 1024 * 20;

export const COMPANY_DETAILS_KEY_NAME = {
  australian: 'australianCompany',
  foreign: 'foreignCompany',
};

export enum SpecialCompanyType {
  AustralianStockExchange,
  MajorityOwnedOrForeignStockExchange,
  LicensedAndRegulated,
  None,
}

export const specialCompanyTypeOptions = [
  {
    label: 'Australian Public Listed Company',
    value: SpecialCompanyType.AustralianStockExchange,
    id: SpecialCompanyType.AustralianStockExchange,
  },
  {
    label: 'Majority Owned Subsidiary of a Listed Company',
    value: SpecialCompanyType.MajorityOwnedOrForeignStockExchange,
    id: SpecialCompanyType.MajorityOwnedOrForeignStockExchange,
  },
  {
    label: 'Licensed and regulated by a Government Regulator ',
    value: SpecialCompanyType.LicensedAndRegulated,
    id: SpecialCompanyType.LicensedAndRegulated,
  },
  {
    label: 'Not Applicable',
    value: SpecialCompanyType.None,
    id: SpecialCompanyType.None,
  },
];

export enum AbnAcnTypeCode {
  ABN = 'ABR',
  ACN = 'ASIC',
}

export const abnAcnNotAllowedStatus = {
  [AbnAcnTypeCode.ACN]: [
    'Association Strike Off Status',
    'Cancelled',
    'Deregistered',
    'Not Active',
    'Not Registered (Organisations of type NONC and NRET)',
    'Pending - Schemes',
    'Provisional',
    'Strike-Off Action in Progress',
    'Winding Up - Managed Investments Schemes',
    'Winding Up - Prescribed Interest Schemes',
  ],
  [AbnAcnTypeCode.ABN]: ['Cancelled'],
};

export enum AustralianCompanyType {
  PublicAustralian,
  PrivateAustralian,
  Other,
}

export enum AustralianCompanyTypeLabel {
  PrivateAustralian = 'Australian Private Company',
  PublicAustralian = 'Australian Public Company',
  Other = 'Other',
}

export const australianCompanyTypeOptions = [
  {
    value: AustralianCompanyType.PrivateAustralian,
    label: AustralianCompanyTypeLabel.PrivateAustralian,
  },
  {
    value: AustralianCompanyType.PublicAustralian,
    label: AustralianCompanyTypeLabel.PublicAustralian,
  },
  {
    value: AustralianCompanyType.Other,
    label: AustralianCompanyTypeLabel.Other,
  },
];

export const governmentBodyTypeOptions = [
  {
    label: 'Commonwealth of Australia Government Body',
    value: GOVERNMENT_BODY_TYPE.CommonwealthOfAustralia,
  },
  {
    label: 'Australian State or Territory Government Body ',
    value: GOVERNMENT_BODY_TYPE.AustralianStateOrTerritory,
  },
  {
    label: 'Foreign (Non-Australian) Government Body',
    value: GOVERNMENT_BODY_TYPE.Foreign,
  },
];

export const associationTypeOptions = [
  {
    label: 'Incorporated Association',
    value: ASSOCIATION_TYPE.Incorporated,
  },
  {
    label: 'Unincorporated Association',
    value: ASSOCIATION_TYPE.Unincorporated,
  },
  {
    label: 'Other',
    value: ASSOCIATION_TYPE.Other,
  },
];

export const stateOfTerritoryOptions = [
  {
    id: '3907',
    label: 'Australian Capital Territory',
    value: '3907',
  },
  {
    id: '3909',
    label: 'New South Wales',
    value: '3909',
  },
  {
    id: '3910',
    label: 'Northern Territory',
    value: '3910',
  },
  {
    id: '3905',
    label: 'Queensland',
    value: '3905',
  },
  {
    id: '3904',
    label: 'South Australia',
    value: '3904',
  },
  {
    id: '3908',
    label: 'Tasmania',
    value: '3908',
  },
  {
    id: '3903',
    label: 'Victoria',
    value: '3903',
  },
  {
    id: '3906',
    label: 'Western Australia',
    value: '3906',
  },
];

export enum COMPANY_FLAG_DESCRIPTOR {
  TrustFlag = 'trustFlag',
  NonBeneficiallyHeldSharesFlag = 'nonBeneficiallyHeldSharesFlag',
  PulledFailFlag = 'pulledFailFlag',
  DataIssueFlag = 'dataIssueFlag',
  VerificationFailed = 'verificationFailed',
  UnidentifiedFlag = 'unidentifiedFlag',
}

export const investorCompanyFlagDetailConfig: IInvestorCompanyFlagDetailConfig = {
  [COMPANY_FLAG_DESCRIPTOR.TrustFlag]: {
    allowedPhrases: ['greater than 25%'],
  },
  [COMPANY_FLAG_DESCRIPTOR.NonBeneficiallyHeldSharesFlag]: {
    allowedPhrases: ['greater than 25%'],
  },
  [COMPANY_FLAG_DESCRIPTOR.PulledFailFlag]: {
    replaceMessage: 'Unable to retrieve, please complete application manually',
    isAllowAll: true,
  },
  [COMPANY_FLAG_DESCRIPTOR.DataIssueFlag]: {
    notAllowedPhrases: ['less than 10%', 'less then 25%'],
    replaceMessage: 'Unable to retrieve, please complete application manually',
  },
  [COMPANY_FLAG_DESCRIPTOR.VerificationFailed]: {
    replaceMessage: 'Identification Failed',
    isAllowAll: true,
  },
  [COMPANY_FLAG_DESCRIPTOR.UnidentifiedFlag]: {
    replaceMessage: 'Unable to retrieve, please complete application manually',
    isAllowAll: true,
  },
};

export const FLAG_COLOR = {
  RED: 'Red',
  ORANGE: 'Orange',
  BLUE: 'Blue',
};

export const FLAG_SEVERITY = {
  [FLAG_COLOR.RED]: 'error',
  [FLAG_COLOR.ORANGE]: 'warning',
  [FLAG_COLOR.BLUE]: 'info',
};

export const SUPER_FUND_TYPE = {
  SMF: 'SMF',
  POF: 'POF',
};

export const SUPER_FUND_STATUS = {
  Complying: 'Complying',
  Registered: 'Registered',
  'Non-complying': 'Non-complying',
  'Regulation details withheld': 'Regulation details withheld',
  'Regulation details removed': 'Regulation details removed',
};

export const UBOS_META = {
  COMMON: {
    TOOLTIP: `A beneficial owner is an individual who ultimately owns or controls an entity.
    Individual who ultimately owns 25% or more of the entity directly (such as through shareholdings)
    or indirectly (such as through another company’s ownership or through a bank or broker).
    Control includes exercising control through the capacity to determine decisions about financial or operating
    policies; or by means of trusts, agreements, arrangements, understanding & practices; voting rights of 25% or
    more; or power of veto. If no person can be identified as this then the most senior managing official/s of
    the company (such as the managing director or directors who are authorised to sign on the company’s behalf)
    should complete the below information.
    `,
    DESCRIPTION:
      'Provide the names of the individuals that directly or indirectly control the Government Body, such as the Chairman, President, Treasurer or Secretary of the Government Body.',
  },
  PARTNERSHIP: {
    TOOLTIP: `Please list all individuals, other than the partners listed, who ultimately own 25% or more of the partnership, or are entitled (directly or indirectly) to exercise 25% or more of the voting rights, including power of veto. If there are no individuals who meet the above criteria, please provide details of the most senior official(s), such as a managing partner or an individual with authorisation to sign on the partnership’s behalf.
  `,
    DESCRIPTION:
      'Please select one of the partners to be verified. If partner is not an individual, please provide details of all ultimate beneficial owners of the selected partner.',
    OTHER_DESCRIPTION:
      'Please provide details of all UBO of selected partners. There is no auto-added UBO, user will need to add new.',
  },
  ASSOCIATION: {
    DESCRIPTION:
      'Provide the names of the individual members that directly or indirectly control the Association, such as Chairman, President, Treasurer or Secretary of the Association.',
  },
  TRUST: {
    DESCRIPTION: 'Please provide details of all Ultimate Beneficial Owners of the Trust.',
  },
};

export const financialDetailsDefaultValues = {
  isSaveDraft: false,
  bankName: undefined,
  accountName: undefined,
  bankStateBranch: undefined,
  accountNumber: undefined,
};

export const financialTaxDetailsDefaultValues = {
  financialDetails: omit(financialDetailsDefaultValues, ['isSaveDraft']),
  isSaveDraft: false,
  isTaxResidentOtherCountryThanAustralia: null,
  taxFileNumber: '',
  taxStatus: {
    taxStatusType: null,
    hasGIIN: null,
    giin: '',
    reasonNotHavingGIIN: null,
    isAllowCRSAndManagedByAnotherFI: null,
    fatcaStatus: '',
    otherDescription: '',
  },
  foreignControllingPersons: {
    hasControllingPersonsOtherThanAustralia: null,
    directors: [],
    directorIds: [],
  },
  entityCountryOfTaxResidency: {
    hasEntityTaxOtherThanAustralia: null,
    hasTIN: null,
    tin: '',
    reasonNotHavingTIN: null,
    countryOfTax: '',
    countryHasTIN: null,
    countryTIN: '',
    countryReasonNotHavingTIN: null,
  },
  declarationDirectorId: null,
};

export enum TAX_STATUS {
  FinancialInstitution,
  AgencyFinancial,
  ForeignCharity,
  Other,
}

export enum REASON_NOT_HAVING_GIIN {
  DeemedCompliantFI,
  ExpectedFI,
  ExemptBenificialOwner,
  NonReportingIGAFI,
  NonParticipatingFI,
  USFI,
  Other,
}

export const taxStatusOptions = [
  {
    label: 'Financial Institution',
    value: TAX_STATUS.FinancialInstitution,
    tooltip:
      'A custodial or depository institution, an investment entity or a specified insurance company for FATCA / CRS purposes',
  },
  {
    label:
      'Public Listed Company, Majority Owned Subsidiary of a Public Listed Company, Governmental Entity, International Organisation, Central Bank, an Australian Registered Charity or Deceased Estate',
    value: TAX_STATUS.AgencyFinancial,
  },
  {
    label: 'Foreign Charity or an Active Non-Financial Entity (NFE)',
    value: TAX_STATUS.ForeignCharity,
    tooltip: `Active NFEs include entities where, during the previous reporting period, less than 50% of their gross income was passive income (e.g. dividends, interests and royalties) and less than 50% of assets held produced passive income. For other types of Active NFEs, refer to Section VIII in the Annexure of the OECD 'Standard for Automatic Exchange of Financial Account Information.`,
  },
  {
    label: 'Other',
    value: TAX_STATUS.Other,
    tooltip:
      'Passive Non-Financial Entities - entity earns 50% or more of its revenue from investment activities (e.g. rent, dividends, interest or distributions), or 50% or more of the entity’s assets are used to generate investment income',
  },
];

export const reasonNotHavingGIINOptions = [
  {
    label: 'Deemed Compliant Financial Institution',
    value: REASON_NOT_HAVING_GIIN.DeemedCompliantFI,
  },
  {
    label: 'Excepted Financial Institution',
    value: REASON_NOT_HAVING_GIIN.ExpectedFI,
  },
  {
    label: 'Exempt Beneficial Owner',
    value: REASON_NOT_HAVING_GIIN.ExemptBenificialOwner,
  },
  {
    label: 'Non-Reporting IGA Financial Institution',
    value: REASON_NOT_HAVING_GIIN.NonReportingIGAFI,
  },
  {
    label: 'Non-Participating Financial Institution',
    value: REASON_NOT_HAVING_GIIN.NonParticipatingFI,
  },
  {
    label: 'US Financial Institution',
    value: REASON_NOT_HAVING_GIIN.USFI,
  },
  {
    label: 'Other',
    value: REASON_NOT_HAVING_GIIN.Other,
  },
];

export enum APPLICATION_APPROVE_STEP {
  InvestorTypeApproved,
  InvestmentAmountApproved,
  ApplicationSigned,
  KycApproved,
  ApplicationApproved,
  InvestmentAmountReceived,
  ApplicationFinalised,
  InitiateKYC,
  ManualSigned,
}

export enum RejectApplicationType {
  Application,
  InvestmentAmount,
  InvestorType,
}

export enum APPLICATION_SIGNATORY_STATUS {
  NotSent,
  AwaitingSignature,
  Signed,
}

export enum PARTNERSHIP_TYPE {
  General,
  Limited,
  IncorporatedLimited,
  Other,
}

export const applicationSignatoryStatuses = {
  NotSent: 'Not Sent',
  Signed: 'Signed',
  AwaitingSignature: 'Awaiting Signature',
};

export const finaliseDetailDefaultValue = {
  investorNumber: '',
  firstInvestorNumber: false,
  investmentAmount: 0,
  amountPaid: 0,
  amountUnpaid: 0,
  currentUnitPrice: 0,
  capitalCall: false,
  allowPartiallyPaidUnits: false,
  capitalCallAmount: '',
  capitalCallPercent: '',
  transactionDate: dayjs().format(DATE_PICKER_FORMAT_SERVER),
  investorNumberCode: '',
  isOverwriteInvestorNumber: false,
  unitPrice: 0,
  updateUnitPrice: 0,
  isCurrentUnitPrice: false,
  numberOfUnits: 0,
  isSendToCommunicationList: true,
  partiallyPaidUnits: true,
  unitPriceRounding: 0,
};

export const partnershipTypeOptions = [
  {
    value: PARTNERSHIP_TYPE.General,
    label: 'General Partnership',
  },
  {
    value: PARTNERSHIP_TYPE.Limited,
    label: 'Limited Partnership',
  },
  {
    value: PARTNERSHIP_TYPE.IncorporatedLimited,
    label: 'Incorporated Limited Partnership',
  },
  {
    value: PARTNERSHIP_TYPE.Other,
    label: 'Other',
  },
];

export const PARTNERS_DESCRIPTION = {
  REGULATED:
    'Please provide details of ONE of the Partner. The selected Partner will be used for verification purposes.',
  UNREGULATED:
    'Please provide details of ALL Partners. Information of the first recorded Partner will be used for verification purposes.',
};
export enum APPLICATION_SIGNATORY_MANUAL_SIGN_TYPE {
  Post,
  Email,
  Signed,
}

export enum FUNDRAISING_OVERVIEW_STEP {
  ApplicationSummary,
  KYCReview,
  InvestmentAmount,
  InvestmentAmountCapitalCall,
  FinaliseApplication,
  ApplicationDocument,
  FinaliseApproval,
}

export const trusteeNameRegexp = /[tT][hH][eE][\s]+[tT][rR][uU][sS][tT][eE][eE][\s]+[fF][oO][rR]/g;

export enum PARTNER_TYPE {
  Individuals,
  Other,
}

export enum PartnerEntityType {
  Individuals,
  SoleTrader,
  Company,
  Trust,
  SMSF,
  Partnership,
  Association,
  GovernmentBody,
}

export const partnerEntityTypeOptions = [
  {
    label: 'Individuals',
    value: PartnerEntityType.Individuals,
  },
  {
    label: 'Sole Trader',
    value: PartnerEntityType.SoleTrader,
  },
  {
    label: 'Company',
    value: PartnerEntityType.Company,
  },
  {
    label: 'Trust',
    value: PartnerEntityType.Trust,
  },
  {
    label: 'SMSF',
    value: PartnerEntityType.SMSF,
  },
  {
    label: 'Partnership',
    value: PartnerEntityType.Partnership,
  },
  {
    label: 'Association',
    value: PartnerEntityType.Association,
  },
  {
    label: 'Government Body',
    value: PartnerEntityType.GovernmentBody,
  },
];

export const INVESTMENT_AMOUNT_REQUIRED = 500000;

export enum FUND_KYC_SUBMIT_TYPE {
  InitiateVerification,
  MarkCompleted,
}

export const fundKycSubmitTypeOptions = [
  {
    label: 'Initiate AML/KYC verification',
    value: FUND_KYC_SUBMIT_TYPE.InitiateVerification,
  },
  {
    label: 'Mark AML/KYC verification as completed',
    value: FUND_KYC_SUBMIT_TYPE.MarkCompleted,
  },
];
