import { Box, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import CurrencyName from 'src/components/atoms/CurrencyName';
import CustomAlert from 'src/components/atoms/CustomAlert';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import { useGetOffmarketTransfer } from 'src/modules/administration-investor/hooks';
import { formatCurrencyNumber } from 'src/utils/common';
import { InvestorDetailsProps } from '.';

interface IOffMarketTransferProps extends InvestorDetailsProps {}

const OffMarketTransfer: FC<IOffMarketTransferProps> = ({
  id = '',
  currencyName,
  investorUnitRounding,
}) => {
  const { data: { issuedUnit, investors = [] } = {} } = useGetOffmarketTransfer(id);

  const investorsOptions = useMemo(() => {
    return (investors || []).map((item: { name: string; id: string }) => ({
      label: item.name,
      value: item.id,
    }));
  }, [investors]);

  return (
    <Box>
      <CustomAlert
        severity='warning'
        sx={{
          my: 2,
        }}
      >
        Please create the investor profile first before transferring the holding to a new investor.
      </CustomAlert>
      <Grid container columnSpacing={3} rowSpacing='18px'>
        <Grid item xs={12}>
          <FormDatePicker name='transactionDate' label='Transaction Date' disableFuture />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name=''
            label='Units'
            value={formatCurrencyNumber(issuedUnit, investorUnitRounding)}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            hideCurrency
            name='transferAmount'
            label='Transfer Amount'
            placeholder='Enter transfer amount'
            decimalScale={2}
            endAdornment={<CurrencyName currencyName={currencyName} />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            label='Select investor to transfer to'
            placeholder='Select investor'
            name='investorId'
            options={investorsOptions}
          />
        </Grid>
      </Grid>
      <Box className='mt-8'>
        <FormCheckbox name='isSendMail' label='Send confirmation of transfer letter to investors' />
      </Box>
    </Box>
  );
};

export default OffMarketTransfer;
