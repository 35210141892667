/* eslint-disable no-restricted-imports */
import { FUND_PORTAL_TYPE } from 'src/constants/common';
import { IOrganisationSubscription } from '../organisation-settings/type';

/* eslint-disable autofix/no-unused-vars */
export type ILoginBody = {
  email: string;
  password: string;
  rememberMe?: boolean;
  groupId?: string;
};

export interface ILoginResponse {
  token: string;
  default2FAMethod: string;
  user: {
    id: string;
    email: string;
  };
}

export type IVerifyAuthenticatorBody = {
  code: string;
  groupId?: string;
};

export type IVerifyAuthenticatorResponse = {
  token: string;
  expiration: string;
  mfaEnabled: boolean;
  refreshToken: string;
};

export type ISignUpBody = {
  code: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export interface ISignUpResponse {
  user: {
    id: string;
    userName: string;
    email: string;
  };
}

export interface ISendEmailForgotPasswordBody {
  email: string;
}

export interface ISendEmailForgotPasswordResponse {
  email: string;
  code: string;
}

export interface IResetPasswordBody {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

export interface IResetPasswordResponse {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

export interface IResetPasswordInfoResponse {
  expiredTime: string;
}

export interface IProfileResponse {
  id: string;
  role: string;
  firstName: string;
  lastName: string;
  position: string;
  contactNumber: string;
  status: number;
  statusName: string;
  email: string;
  countryFlagCode: string;
  userType: number;
  portalType: number;
  duplicateApplication?: {
    applicationId: string;
    hasDuplicate: boolean;
    isDuplicated: boolean;
  };
  unallocatedHolding: IUnallocatedHolding;
}

export interface IUnallocatedHolding {
  fund: string;
  investorNumber: string;
  entityName: string;
}

export enum CLIENT_PORTAL_USER_TYPE {
  FA_USER,
  FM_USER,
}
export interface IClientPortalUserType {
  userType: CLIENT_PORTAL_USER_TYPE;
}

export type ISignUpInvestorBody = {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  email: string;
  contactNumber: string;
  isReceivingMarkingUpdates: boolean;
};

export interface ISignUpVerifyEmailBody {
  email: string;
  code: string;
}

export interface ISignUpResendVerifyEmailBody {
  email: string;
}

export type ISignUpInvestorResponse = {
  token: string;
  expiration: string;
  mfaEnabled: boolean;
  emailConfirmed: boolean;
  default2FAMethod: string;
};

export interface IChangePasswordBody {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface IUpdateProfileBody {
  firstName: string;
  lastName: string;
  contactNumber: string;
  position: string;
}

export interface PortalInfo {
  id: string;
  tenantId: string;
  gapClientId: string;
  name: string;
  portalType: FUND_PORTAL_TYPE;
  isKYCBOEnabled: boolean;
  isKYCBOProcessing: boolean;
  subscriptions: IOrganisationSubscription[];
}
