/* eslint-disable autofix/no-unused-vars */
import {
  Box,
  BoxProps,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import * as React from 'react';
import { useRole } from 'src/hooks/useRole';
import SelectColorBox from './SelectColorBox';

interface NavigationMenuSettingProps extends BoxProps {
  backgroundNavigation: string;
  backgroundActive: string;
  text: string;
  onChangeFields: (field: string, value: string) => void;
  disabled?: boolean;
}

const StyledList = styled(List)(({ theme }) => ({
  width: '166px',
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.pr900,
  color: theme.palette.base.white,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 8,
  padding: '5px 10px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NavigationMenuSetting: React.FC<NavigationMenuSettingProps> = ({
  backgroundNavigation,
  backgroundActive,
  text,
  onChangeFields,
  disabled,
  sx,
  ...props
}) => {
  const { allowedNavigationList } = useRole();

  return (
    <Box {...props} sx={{ backgroundColor: 'base.white', p: 3, borderRadius: '10px', ...sx }}>
      <Typography variant='h6' mb='24px'>
        Navigation menu
      </Typography>
      <Grid container>
        <Grid xs={4} item>
          <StyledList
            sx={{
              backgroundColor: backgroundNavigation,
            }}
          >
            <Typography textAlign='center' variant='h6' py='10px'>
              Logo
            </Typography>
            {allowedNavigationList.map((item, index) => {
              return (
                <StyledListItemButton
                  key={`custom-nav-item-${index}`}
                  sx={{
                    backgroundColor: index === 0 ? backgroundActive : '',
                    '&:hover': {
                      backgroundColor: backgroundActive,
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 0, width: '16px' }}>
                    {<item.icon color={text} />}
                  </ListItemIcon>
                  <ListItemText className='ml-4'>
                    <Typography fontSize='11px' variant='body2' color={text}>
                      {item.title}
                    </Typography>
                  </ListItemText>
                </StyledListItemButton>
              );
            })}
          </StyledList>
        </Grid>
        <Grid xs={8} item>
          <SelectColorBox
            disabled={disabled}
            title='Background colour'
            currentColor={backgroundNavigation}
            onChangeColor={(color) => onChangeFields('backgroundNavigation', color)}
          />
          <SelectColorBox
            py={3}
            disabled={disabled}
            title='Highlight colour'
            currentColor={backgroundActive}
            onChangeColor={(color) => onChangeFields('backgroundActive', color)}
          />
          <SelectColorBox
            disabled={disabled}
            title='Text colour'
            currentColor={text}
            onChangeColor={(color) => onChangeFields('text', color)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavigationMenuSetting;
