import {
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  InvestmentEntityCompanyRegionType,
  TAX_STATUS,
} from 'src/constants/applications';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import * as Yup from 'yup';
import {
  associationSchema,
  australianCompanySchema,
  foreignCompanySchema,
  governmentBodySchema,
  individualDetailSchema,
  partnerSchema,
  partnershipInvestmentEntitySchema,
  regulatedTrustInvestmentEntityDetailsSchema,
  smsfInvestmentEntityDetailsSchema,
  soleTraderInvestmentEntityDetailsSchema,
  unregulatedTrustInvestmentEntityDetailsSchema,
} from './applications';
import { EMAIL_REGEX } from './common';
import { financialDetailsSchema } from './offer';

export const investmentEntityDetailsSchema = Yup.object().shape({
  entityType: Yup.number().required(REQUIRE_MESSAGE),
  otherType: Yup.number()
    .nullable()
    .when(['entityType'], {
      is: (entityType: number) => entityType === INVESTMENT_ENTITY_TYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  companyRegionType: Yup.number()
    .nullable()
    .when(['entityType'], {
      is: (entityType: number) => entityType === INVESTMENT_ENTITY_TYPE.Company,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  investmentEntityDetails: Yup.object().when(
    ['entityType', 'companyRegionType', 'otherType', 'trustType'],
    ([entityType, companyRegionType, otherType, trustType], schema) => {
      switch (entityType) {
        case INVESTMENT_ENTITY_TYPE.Individuals:
          return schema.shape({
            numberOfIndividuals: Yup.number().required(REQUIRE_MESSAGE),
            individuals: Yup.array().of(individualDetailSchema()),
          });
        case INVESTMENT_ENTITY_TYPE.SoleTrader:
          return schema.shape({
            soleTrader: soleTraderInvestmentEntityDetailsSchema,
          });
        case INVESTMENT_ENTITY_TYPE.Company:
          return companyRegionType === InvestmentEntityCompanyRegionType.Foreign
            ? schema.shape({
                foreignCompany: foreignCompanySchema(Yup.object()),
              })
            : schema.shape({
                australianCompany: australianCompanySchema(Yup.object()),
              });
        case INVESTMENT_ENTITY_TYPE.Trust:
          if (trustType === INVESTMENT_ENTITY_TRUST.Regulated) {
            return schema.shape({
              regulatedTrust: regulatedTrustInvestmentEntityDetailsSchema,
            });
          } else {
            return schema.shape({
              unregulatedTrust: unregulatedTrustInvestmentEntityDetailsSchema,
            });
          }
        case INVESTMENT_ENTITY_TYPE.Other:
          if (otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody) {
            return schema.shape({
              governmentBody: governmentBodySchema,
            });
          } else {
            return schema.shape({
              association: associationSchema,
            });
          }
        case INVESTMENT_ENTITY_TYPE.SMSF:
          return schema.shape({
            smsf: smsfInvestmentEntityDetailsSchema,
          });
        case INVESTMENT_ENTITY_TYPE.Partnership:
          return schema.shape({
            partnership: partnershipInvestmentEntitySchema,
          });
        default:
          return Yup.object().nullable();
      }
    },
  ),
});

export const administrationInvestmentEntityDetailsSchema = investmentEntityDetailsSchema.shape({
  isAddNewEntityDetail: Yup.boolean().test('required', REQUIRE_MESSAGE, (val) => val),
  investmentEntityDetails: Yup.object().when(
    ['entityType', 'companyRegionType', 'otherType', 'trustType'],
    ([entityType, companyRegionType, otherType, trustType], schema) => {
      switch (entityType) {
        case INVESTMENT_ENTITY_TYPE.Individuals:
          return schema.shape({
            numberOfIndividuals: Yup.number().required(REQUIRE_MESSAGE),
            individuals: Yup.array().of(
              individualDetailSchema().concat(individualAndTaxOptional(true)),
            ),
          });
        case INVESTMENT_ENTITY_TYPE.SoleTrader:
          return schema.shape({
            soleTrader: soleTraderInvestmentEntityDetailsSchema.concat(
              individualAndTaxOptional(true),
            ),
          });
        case INVESTMENT_ENTITY_TYPE.Company:
          return companyRegionType === InvestmentEntityCompanyRegionType.Foreign
            ? schema.shape({
                foreignCompany: foreignCompanySchema(Yup.object()).concat(directorsAndUboOptional),
              })
            : schema.shape({
                australianCompany: australianCompanySchema(Yup.object()).concat(
                  directorsAndUboOptional,
                ),
              });
        case INVESTMENT_ENTITY_TYPE.Trust:
          if (trustType === INVESTMENT_ENTITY_TRUST.Regulated) {
            return schema.shape({
              regulatedTrust: regulatedTrustInvestmentEntityDetailsSchema.concat(
                trusteeCompanyDetailsOptional,
              ),
            });
          } else {
            return schema.shape({
              unregulatedTrust: unregulatedTrustInvestmentEntityDetailsSchema.concat(
                trusteeCompanyDetailsOptional,
              ),
            });
          }
        case INVESTMENT_ENTITY_TYPE.Other:
          if (otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody) {
            return schema.shape({
              governmentBody: governmentBodySchema.shape({
                abn: Yup.string().required(REQUIRE_MESSAGE),
                publicOfficers: Yup.array().of(
                  individualDetailSchema(true).concat(individualAndTaxOptional()),
                ),
                ubOs: Yup.array().of(
                  individualDetailSchema(true).concat(individualAndTaxOptional()),
                ),
              }),
            });
          } else {
            return schema.shape({
              association: associationSchema.shape({
                abn: Yup.string().required(REQUIRE_MESSAGE),
                publicOfficers: Yup.array().of(
                  individualDetailSchema(true).concat(individualAndTaxOptional()),
                ),
                ubOs: Yup.array().of(
                  individualDetailSchema(true).concat(individualAndTaxOptional()),
                ),
              }),
            });
          }
        case INVESTMENT_ENTITY_TYPE.SMSF:
          return schema.shape({
            smsf: smsfInvestmentEntityDetailsSchema.concat(trusteeCompanyDetailsOptional).shape({
              abn: Yup.string().required(REQUIRE_MESSAGE),
              smsfubOs: Yup.array().of(
                individualDetailSchema(true).concat(trusteeCompanyDetailsOptional),
              ),
            }),
          });
        case INVESTMENT_ENTITY_TYPE.Partnership:
          return schema.shape({
            partnership: partnershipInvestmentEntitySchema.shape({
              partners: Yup.array()
                .of(partnerSchema.concat(trusteeCompanyDetailsOptional))
                .nullable(),
              ubOs: Yup.array().of(
                individualDetailSchema(true).concat(trusteeCompanyDetailsOptional),
              ),
            }),
          });
        default:
          return Yup.object().nullable();
      }
    },
  ),
});

const trusteeCompanyDetailsOptional = Yup.object().shape({
  trusteeIndividuals: Yup.array()
    .when(['trusteeType'], {
      is: INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
      then: (schema) => schema.of(individualDetailSchema(true).concat(individualAndTaxOptional())),
    })
    .nullable(),
  trusteeAustralianCompanyDetails: Yup.object().when(['trusteeType', 'trusteeCompanyRegionType'], {
    is: (
      trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
      trusteeCompanyRegionType: InvestmentEntityCompanyRegionType,
    ) =>
      trusteeCompanyRegionType === InvestmentEntityCompanyRegionType.Australian &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
    then: (schema) => australianCompanySchema(schema).concat(directorsAndUboOptional),
  }),
  trusteeForeignCompanyDetails: Yup.object()
    .when(['trusteeType'], {
      is: (
        trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
        trusteeCompanyRegionType: InvestmentEntityCompanyRegionType,
      ) =>
        trusteeCompanyRegionType === InvestmentEntityCompanyRegionType.Foreign &&
        trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
      then: (schema) => foreignCompanySchema(schema).concat(directorsAndUboOptional),
    })
    .nullable(),
});

const individualAndTaxOptional = (isRequiredTFN?: boolean) =>
  Yup.object({
    contactNumber: Yup.string(),
    email: Yup.string()
      .transform((value) => (value === '' ? null : value))
      .nullable()
      .email('Invalid email format')
      .matches(EMAIL_REGEX, 'Invalid email format'),
    taxInfo: Yup.object().shape({
      isTaxResidentAustralia: Yup.boolean().nullable(),
      isTaxResidentOtherCountry: Yup.boolean().nullable(),
      country: Yup.string().nullable(),
      hasTIN: Yup.boolean().nullable(),
      tin: Yup.string().nullable(),
      reasonType: Yup.number().nullable(),
      isDeclarationChecked: Yup.boolean(),
      tfnExemptionCode: Yup.string()
        .nullable()
        .test('tfnExemptionCode', 'TFN should be between 8 and 9 digits.', (code: any) => {
          if (!isRequiredTFN) return true;
          if (code) {
            return code?.length >= 8 && code?.length <= 9;
          }
          return true;
        }),
    }),
  });

const directorsAndUboOptional = Yup.object().shape({
  directors: Yup.array()
    .of(individualDetailSchema(true).concat(individualAndTaxOptional()))
    .nullable(),
  ubOs: Yup.array().of(individualDetailSchema(true).concat(individualAndTaxOptional())).nullable(),
});

export const documentsSchema = Yup.object().shape({
  documents: Yup.array(),
});

export const finacialAndTaxDetailsSchema = Yup.object().shape({
  financialDetails: financialDetailsSchema,
  isSaveDraft: Yup.boolean(),
  taxFileNumber: Yup.string()
    .nullable()
    .test('taxFileNumber', 'TFN should be between 8 and 9 digits.', (taxFileNumber: any) => {
      if (taxFileNumber) {
        return taxFileNumber?.length >= 8 && taxFileNumber?.length <= 9;
      }
      return true;
    }),
  isTaxResidentOtherCountryThanAustralia: Yup.boolean().nullable(),
  taxStatus: Yup.object()
    .when('isTaxResidentOtherCountryThanAustralia', {
      is: true,
      then: (schema) =>
        schema.shape({
          taxStatusType: Yup.number().nullable().required(REQUIRE_MESSAGE),
          hasGIIN: Yup.boolean()
            .when('taxStatusType', {
              is: TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          giin: Yup.string()
            .when(['hasGIIN', 'taxStatusType'], {
              is: (hasGIIN: boolean, taxStatusType: number) =>
                hasGIIN && taxStatusType === TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          reasonNotHavingGIIN: Yup.number()
            .when(['hasGIIN', 'taxStatusType'], {
              is: (hasGIIN: boolean, taxStatusType: number) =>
                !hasGIIN && taxStatusType === TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          isAllowCRSAndManagedByAnotherFI: Yup.boolean()
            .when('taxStatusType', {
              is: TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
        }),
    })
    .nullable(),
  foreignControllingPersons: Yup.object()
    .when('isTaxResidentOtherCountryThanAustralia', {
      is: true,
      then: (schema) =>
        schema.shape({
          hasControllingPersonsOtherThanAustralia: Yup.boolean()
            .nullable()
            .required(REQUIRE_MESSAGE)
            .typeError(REQUIRE_MESSAGE),
          directors: Yup.array().when('hasControllingPersonsOtherThanAustralia', {
            is: true,
            then: (schema) => schema.min(1, REQUIRE_MESSAGE),
            otherwise: (schema) => schema.nullable(),
          }),
        }),
    })
    .nullable(),
  entityCountryOfTaxResidency: Yup.object()
    .when('isTaxResidentOtherCountryThanAustralia', {
      is: true,
      then: (schema) =>
        schema.shape({
          hasEntityTaxOtherThanAustralia: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
          hasTIN: Yup.boolean()
            .when('hasEntityTaxOtherThanAustralia', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          tin: Yup.string()
            .when('hasTIN', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          reasonNotHavingTIN: Yup.number()
            .when('hasTIN', {
              is: false,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          countryOfTax: Yup.string()
            .when('hasEntityTaxOtherThanAustralia', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
        }),
    })
    .nullable(),
  declarationDirectorId: Yup.string().nullable(),
});
