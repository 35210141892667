import { FC, SVGProps } from 'react';

export const CrossCircleFillIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      color='#FF3F52'
      {...props}
    >
      <title>Group 7</title>
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Fundraising_Applications_Overview_00'
          transform='translate(-1169.000000, -868.000000)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 174.000000)'>
              <g id='App-Summary' transform='translate(171.000000, 552.000000)'>
                <g id='Group-7' transform='translate(24.000000, 72.000000)'>
                  <g id='Group-7' transform='translate(682.000000, 14.000000)'>
                    <g id='Group' transform='translate(2.000000, 2.000000)'>
                      <path
                        d='M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M13.53,6.47 L13.446,6.397 C13.1845859,6.20376673 12.8284482,6.20094024 12.564,6.39 L12.47,6.47 L10,8.939 L7.53,6.469 L7.446,6.397 C7.1845859,6.20376673 6.82844817,6.20094024 6.564,6.39 L6.47,6.47 L6.397,6.554 C6.20376673,6.8154141 6.20094024,7.17155183 6.39,7.436 L6.47,7.53 L8.939,10 L6.469,12.47 L6.397,12.554 C6.20376673,12.8154141 6.20094024,13.1715518 6.39,13.436 L6.47,13.53 L6.554,13.603 C6.8154141,13.7962333 7.17155183,13.7990598 7.436,13.61 L7.53,13.53 L10,11.061 L12.47,13.531 L12.554,13.603 C12.8154141,13.7962333 13.1715518,13.7990598 13.436,13.61 L13.53,13.53 L13.603,13.446 C13.7962333,13.1845859 13.7990598,12.8284482 13.61,12.564 L13.53,12.47 L11.061,10 L13.531,7.53 L13.603,7.446 C13.7962333,7.1845859 13.7990598,6.82844817 13.61,6.564 L13.53,6.47 L13.446,6.397 L13.53,6.47 L13.53,6.47 Z'
                        id='Shape'
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
