const DelegateIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Company_02'
          transform='translate(-627.000000, -766.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Add-New-Entity' transform='translate(196.000000, 365.000000)'>
                <g id='Group-6' transform='translate(24.000000, 96.000000)'>
                  <g id='Type-1-Copy' transform='translate(109.000000, 32.000000)'>
                    <g id='broker' transform='translate(8.000000, 6.000000)'>
                      <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1'></use>
                      </mask>
                      <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                      <g id='user' mask='url(#mask-2)'>
                        <g transform='translate(6.000000, 8.000000)'>
                          <path
                            d='M0.952812697,45 C0.652859713,45.0004379 0.370266164,44.8572256 0.190294186,44.61357 C0.0103222078,44.3699145 -0.0459516846,44.0543502 0.0384510712,43.762087 C0.326763296,42.7500112 0.623313012,41.7462996 0.911625236,40.7425881 C1.17202107,39.6047022 1.62944533,38.5228414 2.26257394,37.5474395 C3.41768944,35.9021704 5.15755881,34.7773964 7.11445681,34.4108409 L13.177251,33.2900296 L23.0622416,33.2900296 L29.1250358,34.4108409 C31.0819338,34.7773964 32.8218031,35.9021704 33.9769186,37.5474395 C34.6100472,38.5228414 35.0674715,39.6047022 35.3278673,40.7425881 C35.6161796,41.7462996 35.9127293,42.7500112 36.2010415,43.762087 C36.2854443,44.0543502 36.2291704,44.3699145 36.0491984,44.61357 C35.8692264,44.8572256 35.5866329,45.0004379 35.2866799,45 L0.952812697,45 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M6.57853309,17.3632949 C4.44421047,14.9043323 4.44421047,12.6164212 4.44421047,10.8037393 C4.579439,4.76137532 9.46861873,-0.0506663407 15.4207268,0.000402747097 C24.9154135,0.000402747097 31.8855013,4.50192196 31.8855013,11.7038883 C31.7777111,13.7730863 31.0207269,15.7529829 29.7252619,17.354007 L6.57853309,17.3632949 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M28.0742109,17.0281577 L28.4782077,17.0281577 C29.1808126,17.0288701 29.8536918,17.3161074 30.34574,17.8253662 C31.1037361,18.5979838 31.3315477,19.7567912 30.9237836,20.7656954 C30.5160195,21.7745996 29.5521983,22.4368526 28.4782077,22.4460797 L7.85150408,22.4460797 C6.77751352,22.4368526 5.81369232,21.7745996 5.4059282,20.7656954 C4.99816409,19.7567912 5.22597573,18.5979838 5.98397179,17.8253662 C6.47601996,17.3161074 7.14889922,17.0288701 7.85150408,17.0281577 L28.0742109,17.0281577 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M23.0622416,33.2900296 C23.0622416,33.2900296 21.9337051,35.7993085 18.1197463,35.7993085 C14.3057874,35.7993085 13.177251,33.2900296 13.177251,33.2900296 C14.1743057,32.7037832 14.7985968,31.6324946 14.8247494,30.4629087 L14.8247494,28.5809495 L21.4147431,28.5809495 L21.4147431,30.4629087 C21.4408958,31.6324946 22.0651868,32.7037832 23.0622416,33.2900296 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M28.0742573,15.4027811 L28.0742573,20.1241131 C28.0742573,25.6811223 23.6376416,30.1859683 18.1648559,30.1859683 C12.6920702,30.1859683 8.25549863,25.6811223 8.25549863,20.1241131 L8.25549863,14.6837454 C8.25430668,13.8152164 8.73198919,13.0190796 9.49264573,12.6218391 C11.266199,11.7222634 12.7221379,10.2874468 13.6606729,8.51428022 C13.6606729,8.51428022 17.5268459,12.5003229 25.9757144,13.0862324 C27.1655114,13.1796356 28.0817621,14.1910927 28.0742573,15.4027811 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M7.23475574,41.6542927 L7.23475574,44.999998 L5.58725731,44.999998 L5.58725731,41.6542927 C5.58725731,41.1923472 5.9560624,40.8178664 6.41100652,40.8178664 C6.86595065,40.8178664 7.23475574,41.1923472 7.23475574,41.6542927 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          ></path>
                          <g
                            id='insurance'
                            strokeWidth='1'
                            fill='none'
                            fillRule='evenodd'
                            transform='translate(28.000000, 25.000000)'
                          >
                            <path
                              d='M14.4230769,3.1883121 C11.2297308,3.1883121 7.92034615,0.182021573 7.88757692,0.151904371 C7.66761538,-0.0506347903 7.33234615,-0.0506347903 7.11242308,0.151904371 C7.07946154,0.182255948 3.77876923,3.1883121 0.576923077,3.1883121 C0.258307692,3.1883121 0,3.45065598 0,3.77424989 L0,10.7913627 C0,16.4758967 4.16292308,18.7473822 7.29423077,19.9614844 C7.3605,19.9871875 7.43023077,20 7.5,20 C7.56976923,20 7.6395,19.9871484 7.70576923,19.9614844 C12.0963077,18.2591398 15,15.6037088 15,10.7913627 L15,3.77424989 C15,3.45065598 14.7416923,3.1883121 14.4230769,3.1883121 Z'
                              id='Path'
                              fill='#33B27F'
                              fillRule='nonzero'
                            ></path>
                            <path
                              d='M7.5,5.89843061 C5.27319231,5.89843061 3.46153846,7.73839245 3.46153846,9.99999512 C3.46153846,12.2615978 5.27319231,14.1015596 7.5,14.1015596 C9.72680769,14.1015596 11.5384615,12.2615978 11.5384615,9.99999512 C11.5384615,7.73839245 9.72680769,5.89843061 7.5,5.89843061 Z M9.06176923,9.82835441 L7.331,11.5861678 C7.21838462,11.7005819 7.07073077,11.7578085 6.92307692,11.7578085 C6.77542308,11.7578085 6.62776923,11.7006209 6.51515385,11.5861678 L5.93823077,11.00023 C5.71292308,10.7714017 5.71292308,10.400425 5.93823077,10.1715968 C6.1635,9.94276853 6.52880769,9.94276853 6.75411538,10.1715968 L6.92311538,10.3431984 L8.24596154,8.99972119 C8.47123077,8.77089295 8.83653846,8.77089295 9.06184615,8.99972119 C9.28711538,9.22854943 9.28711538,9.59952617 9.06176923,9.82835441 L9.06176923,9.82835441 Z'
                              id='Shape'
                              fill='#FFFFFF'
                              fillRule='nonzero'
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DelegateIcon;
