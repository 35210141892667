import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { INVESTMENT_ENTITY_API } from 'src/constants/apiEndpoints';
import {
  IApplicationFinancialDetail,
  IFinancialTaxDetailsForm,
  ITaxFilter,
  ITeam,
  ITeamContact,
} from 'src/modules/applications/type';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  archiveInvestmentEntity,
  createInvestmentEntity,
  createInvestmentEntityDetail,
  deleteInvestmentEntity,
  deleteInvestmentEntityDocument,
  deleteTeamContact,
  downloadInvestmentEntityDocument,
  getInvestmentEntityById,
  getInvestmentEntityList,
  getTaxDetails,
  getTeamContact,
  upsertFinancialDetail,
  upsertFinancialTaxDetail,
  upsertInvestmentEntityDetails,
  upsertInvestmentEntityDocuments,
  upsertTeam,
  upsertTeamContact,
} from './services';
import {
  IInvestment,
  IInvestmentDetailById,
  IInvestmentEntityDetailsForm,
  IInvestmentEntityDocument,
  InvestmentEntityQueryBody,
} from './types';

export const useGetInvestmentEntityList = () => {
  const [queryBody, setQueryBody] = useState<InvestmentEntityQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<IInvestment>
  >([INVESTMENT_ENTITY_API.getInvestmentEntityList.api, queryBody], () =>
    getInvestmentEntityList(queryBody),
  );

  const setParams = (newParams: InvestmentEntityQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useArchiveInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await archiveInvestmentEntity(id);
    await queryClient.invalidateQueries([INVESTMENT_ENTITY_API.getInvestmentEntityList.api]);
  };
  return useMutation(resp);
};

export const useGetTeamContact = (id: string, enabled?: boolean) => {
  return useQuery([INVESTMENT_ENTITY_API.getTeamContact.api(id), id], () => getTeamContact(id), {
    enabled: !!id && enabled,
  });
};

export const useUpsertTeamContact = () => {
  const resp = async ({ id, data }: { id: string; data: ITeamContact }) => {
    return await upsertTeamContact(id, data);
  };
  return useMutation(resp);
};

export const useUpsertTeam = () => {
  const resp = async ({ id, data }: { id: string; data: ITeam }) => {
    return await upsertTeam(id, data);
  };
  return useMutation(resp);
};

export const useDeleteTeamContact = () => {
  return useMutation(deleteTeamContact);
};

export const useGetInvestmentEntityById = (
  id: string,
  { step, financialStep }: { step: number; financialStep?: number },
  enabled = true,
) => {
  return useQuery<IInvestmentDetailById>(
    [INVESTMENT_ENTITY_API.investmentEntiryById.api(id), id, step, financialStep],
    () => getInvestmentEntityById(id, { step, financialStep }),
    {
      enabled: !!id && enabled,
    },
  );
};

export const useUpsertFinancialDetail = () => {
  const resp = async ({ data, id }: { data: IApplicationFinancialDetail; id: string }) => {
    await upsertFinancialDetail(id, data);
  };
  return useMutation(resp);
};

export const useUpsertFinancialTaxDetail = () => {
  const resp = async ({ data, id }: { data: IFinancialTaxDetailsForm; id: string }) => {
    await upsertFinancialTaxDetail(id, data);
  };
  return useMutation(resp);
};

export const useUpsertInvestmentEntityDetails = () => {
  const resp = async ({ id, data }: { id?: string; data: IInvestmentEntityDetailsForm }) => {
    if (!id) {
      return await createInvestmentEntityDetail(data);
    }
    return await upsertInvestmentEntityDetails(id, data);
  };
  return useMutation(resp);
};

export const useUpsertInvestmentEntityDocuments = () => {
  const resp = async ({
    id,
    documents,
  }: {
    id: string;
    documents: IInvestmentEntityDocument[];
  }) => {
    return await upsertInvestmentEntityDocuments(id, documents);
  };
  return useMutation(resp);
};

export const useDeleteInvestmentEntityDocument = () => {
  return useMutation(deleteInvestmentEntityDocument);
};

export const useGetInvestmentEntityTaxDetails = (id: string, enabled: boolean) => {
  return useQuery<ITaxFilter>(
    [INVESTMENT_ENTITY_API.getTaxDetails.api(id), id],
    () => getTaxDetails(id),
    {
      enabled: !!id && enabled,
    },
  );
};

export const useCreateInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id }: { id: string }) => {
    await createInvestmentEntity(id);
    await queryClient.invalidateQueries([INVESTMENT_ENTITY_API.getInvestmentEntityList.api]);
  };
  return useMutation(resp);
};

export const useDeleteInvestmentEntity = () => {
  const queryClient = useQueryClient();
  const fn = async (entityId: string) => {
    await deleteInvestmentEntity(entityId);
    await queryClient.invalidateQueries([INVESTMENT_ENTITY_API.getInvestmentEntityList.api]);
  };
  return useMutation(fn);
};

export const useDownloadInvestmentEntityDocument = () => {
  const resp = async ({
    appId,
    document,
  }: {
    appId: string;
    document: { documentId: string; fileName: string; type: number };
  }) => {
    const data = await downloadInvestmentEntityDocument(appId, document);
    downloadFileFromArrayBuffer(data, document.fileName);
  };
  return useMutation(resp);
};
