import { REQUIRE_MESSAGE } from 'src/constants/common';
import {
  COMMUNICATION_MESSAGE_TYPE,
  CommunicationCategoryEnum,
} from 'src/constants/communications';
import * as Yup from 'yup';
import { YupBase } from './common';

export const messageSchema = Yup.object({
  category: Yup.number().required(REQUIRE_MESSAGE),
  subject: Yup.string().required(REQUIRE_MESSAGE),
  clientRecipients: Yup.array().when(['category', 'messageType', 'hasExternalMail'], {
    is: (category: number, messageType: number, hasExternalMail: boolean) => {
      return (
        category === CommunicationCategoryEnum.ClientManagement &&
        [COMMUNICATION_MESSAGE_TYPE.Forward, COMMUNICATION_MESSAGE_TYPE.New].includes(
          messageType,
        ) &&
        !hasExternalMail
      );
    },
    then: (schema) => schema.min(1, REQUIRE_MESSAGE),
  }),
  internalRecipients: Yup.object().when(
    ['category', 'messageType', 'hasExternalMail'],
    (params) => {
      const [category, messageType, hasExternalMail] = params;
      if (
        category === CommunicationCategoryEnum.Internal &&
        [COMMUNICATION_MESSAGE_TYPE.Forward, COMMUNICATION_MESSAGE_TYPE.New].includes(
          messageType,
        ) &&
        !hasExternalMail
      ) {
        return Yup.object().shape({
          userIds: Yup.array().of(Yup.string().min(1, REQUIRE_MESSAGE)).required(REQUIRE_MESSAGE),
        });
      }
      return Yup.object();
    },
  ),
  investorRecipients: Yup.array().when(['category', 'messageType', 'hasExternalMail'], {
    is: (category: number, messageType: number, hasExternalMail: boolean) =>
      category === CommunicationCategoryEnum.InvestorRelation &&
      [COMMUNICATION_MESSAGE_TYPE.Forward, COMMUNICATION_MESSAGE_TYPE.New].includes(messageType) &&
      !hasExternalMail,
    then: (schema) => schema.min(1, REQUIRE_MESSAGE),
  }),
  kycboRecipients: Yup.object().when(['category', 'messageType'], (category) => {
    if (
      category[0] === CommunicationCategoryEnum.KYCBO &&
      [COMMUNICATION_MESSAGE_TYPE.Forward, COMMUNICATION_MESSAGE_TYPE.New].includes(category[1])
    ) {
      return Yup.object().shape({
        contactIds: Yup.array().of(Yup.string().min(1, REQUIRE_MESSAGE)).required(REQUIRE_MESSAGE),
      });
    }
    return Yup.object();
  }),
  messageType: Yup.number(),
  hasExternalMail: Yup.boolean().nullable(),
  externalEmails: Yup.array()
    .when('hasExternalMail', {
      is: true,
      then: (schema) =>
        schema.of(
          Yup.object({
            email: YupBase.email,
          }),
        ),
    })
    .nullable(),
});
