import { REQUIRE_MESSAGE } from 'src/constants/common';
import { QUESTION_TYPE, REQUIRED_OPTION } from 'src/constants/kyc-back-office';
import * as yup from 'yup';

const TWO_YEARS_IN_MILLISECONDS = 2 * 365 * 24 * 60 * 60 * 1000;

export const kycBackOfficeReviewSchema = yup.object().shape({
  isKYCCompletedExternally: yup.boolean().nullable(),
  review: yup.object().when('isKYCCompletedExternally', {
    is: true,
    then: (schema) =>
      schema.shape({
        additionalComment: yup.string().required(REQUIRE_MESSAGE),
      }),
    otherwise: (schema) =>
      schema.shape({
        data: yup.array().of(
          yup.object().shape({
            child: yup.array().of(
              yup.object().shape({
                singleSelect: yup
                  .string()
                  .nullable()
                  .when('questionType', {
                    is: QUESTION_TYPE.Select,
                    then: (schema) => schema.required(REQUIRE_MESSAGE),
                  }),
                yesNo: yup
                  .boolean()
                  .nullable()
                  .when(['questionType'], {
                    is: (questionType: any) =>
                      questionType !== QUESTION_TYPE.Select &&
                      questionType !== QUESTION_TYPE.DatePicker,
                    then: (schema) => schema.required(REQUIRE_MESSAGE),
                  }),
                date: yup
                  .string()
                  .nullable()
                  .when('questionType', {
                    is: QUESTION_TYPE.DatePicker,
                    then: (schema) =>
                      schema
                        .required(REQUIRE_MESSAGE)
                        .test(
                          'is-more-than-2-years-old',
                          'This certificate is more than 2 years old',
                          function (value) {
                            const date = new Date(value);
                            const currentDate = new Date();
                            const differenceInMilliseconds = currentDate.getTime() - date.getTime();
                            return differenceInMilliseconds <= TWO_YEARS_IN_MILLISECONDS;
                          },
                        ),
                  }),
                comment: yup
                  .string()
                  .nullable()
                  .when(['yesNo', 'requiredOption'], {
                    is: (yesNo: boolean, requiredOption: any) => (yesNo !== null) && ((yesNo && requiredOption === REQUIRED_OPTION.YES) || (!yesNo && requiredOption === REQUIRED_OPTION.NO)),
                    then: (schema) => schema.required(''),
                  }),
              }),
            ),
          }),
        ),
        additionalComments: yup.object().shape({
          comment: yup.string().required(REQUIRE_MESSAGE),
        }),
      }),
  }),
});

export const kycCompletedExternallySchema = yup.object().shape({
  comment: yup.string().required(REQUIRE_MESSAGE),
  agree: yup.boolean().test('required', REQUIRE_MESSAGE, (val) => val),
});

export const kycNoteSchema = yup.object().shape({
  note: yup.string(),
});

export const requestMoreInformationSchema = yup.object().shape({
  note: yup.string().required(REQUIRE_MESSAGE),
});
