/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IAuditTrailIconProps { }

export const AuditTrailIcon: React.FunctionComponent<IAuditTrailIconProps> = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>C947A18D-0ACD-4ABF-AFCA-2721ADC274A1</title>
      <g id="Fund-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="00-Fund-Portal_Fundraising_KYC-Back-Office_Investor-Details_Sode-Trader_01" transform="translate(-1211.000000, -313.000000)" fill="#33B27F" fillRule="nonzero">
          <g id="Logo" transform="translate(566.000000, 278.000000)">
            <g id="02.Atoms/02.Button/02.Medium/02.With-Icon/Left/01.Primary/01.Default-Copy" transform="translate(633.000000, 27.000000)">
              <g id="01.Design-Token/06.Icons/Line/plus-outline" transform="translate(12.000000, 8.000000)">
                <g id="Group" transform="translate(3.000000, 2.000000)">
                  <path d="M10.008478,5.99465378 L10.008478,0 L4.01483654,0 C2.91143762,0 2.01695605,0.894632639 2.01695605,1.99821793 L2.01695605,7.29649276 C4.36334972,5.9401719 7.32778317,6.32735329 9.24725584,8.24083426 C11.1667285,10.1543152 11.5636111,13.1179574 10.2152587,15.4692041 L13.7415177,18.9960587 C14.0192231,19.2728119 14.1840483,19.6215009 14.2359931,19.9821793 L16.0021195,19.9821793 C17.1055184,19.9821793 18,19.0875466 18,17.9839613 L18,7.99287171 L12.0063585,7.99287171 C10.9029596,7.99287171 10.008478,7.09823907 10.008478,5.99465378 Z" id="Path"></path>
                  <path d="M11.9763903,20.7614843 C12.1631627,20.9619587 12.4444493,21.0444811 12.7099011,20.9766779 C12.9753528,20.9088747 13.1826325,20.70156 13.2504243,20.4360634 C13.318216,20.1705668 13.2357075,19.8892327 13.035267,19.7024288 L8.92762468,15.5930936 C9.62925583,14.7107742 10.0103654,13.6162202 10.008478,12.488862 C10.0100026,11.1808271 9.4968783,9.92471641 8.57999347,8.99198067 C7.64174315,8.03218811 6.35586548,7.49181212 5.01377679,7.49331182 C3.93281434,7.49172415 2.88077032,7.84246472 2.01695605,8.49242619 C-0.170179519,10.1171047 -0.652116481,13.1950721 0.933716602,15.4107079 C2.51954969,17.6263437 5.58808064,18.1622184 7.83078829,16.6151821 L11.9763903,20.7614843 Z M5.01377679,8.99198067 C6.26288123,8.99198067 7.41710133,9.65848246 8.04165356,10.7404213 C8.66620578,11.8223602 8.66620578,13.1553639 8.04165356,14.2373028 C7.41710133,15.3192416 6.26288123,15.9857434 5.01377679,15.9857434 C3.08282869,15.9857434 1.517486,14.4201363 1.517486,12.488862 C1.517486,10.5575878 3.08282869,8.99198067 5.01377679,8.99198067 L5.01377679,8.99198067 Z M11.5068884,5.99465378 L11.5068884,0.499554482 L17.5005299,6.49420826 L12.0063585,6.49420826 C11.7305088,6.49420826 11.5068884,6.2705501 11.5068884,5.99465378 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
