import { Box, IconButton, Typography, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import ActionMenus from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { ActionType } from 'src/constants/common';
import { BILLING_TIME_FORMAT, DATE_PICKER_FORMAT } from 'src/constants/date';
import { billingPlanActionTypes } from 'src/constants/subscription-billing';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  useDeleteStandardPricing,
  useGetStandardPricingHistory,
} from 'src/modules/subscription-billing/hooks';
import { PricingDetail } from 'src/modules/subscription-billing/types';
import { handleErrorFromServer } from 'src/utils/common';
import StandardPricing from './StandardPricing';

interface IProps {
  onClose: () => void;
  isSuperAdmin?: boolean;
  isViewMode?: boolean
}

interface ITableData extends PricingDetail {}

const StandardPriceHistory = ({ onClose, isSuperAdmin = false, isViewMode = false }: IProps) => {
  const form = useForm();

  const standardPricingModalRef = useRef<IBasicModalElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);

  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [selectedPricing, setSelectedPricing] = useState('');

  const { data: historyList = [], isLoading, setParams } = useGetStandardPricingHistory();
  const { mutate: deleteStandardPricing, isLoading: deletingStandardPricing } =
    useDeleteStandardPricing();

  const isCurrentOrFutureMonth = (date: string) => {
    const givenDate = dayjs(date);
    const currentDate = dayjs();

    // Compare the year and month
    if (givenDate.year() > currentDate.year()) {
      return true;
    } else if (
      givenDate.year() === currentDate.year() &&
      givenDate.month() >= currentDate.month()
    ) {
      return true;
    }

    return false;
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Effective Date',
      key: 'effectiveDate',
      sorter: true,
      sortBy: 'effectiveDate',
      sx: { width: '30%', whiteSpace: 'pre', py: '11px' },
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={dayjs(row?.effectiveDate).format(BILLING_TIME_FORMAT)}
        />
      ),
    },
    {
      title: 'Updated Date',
      key: 'modifiedDate',
      sorter: true,
      sortBy: 'modifiedDate',
      sx: { width: '30%', whiteSpace: 'pre', py: '11px' },
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={dayjs(row?.modifiedDate).format(DATE_PICKER_FORMAT)}
        />
      ),
    },
    {
      title: 'Updated By',
      key: 'modifiedByName',
      sorter: true,
      sortBy: 'modifiedByName',
      sx: { width: '30%', whiteSpace: 'pre', py: '11px' },
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => <StringNodeTable variant='body3' value={row?.modifiedByName} />,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '10%', py: '11px' },
      cellSx: () => ({
        py: '14.5px',
      }),
      renderNode: (row) => {
        const canEditDelete = isSuperAdmin && isCurrentOrFutureMonth(row.effectiveDate) && !isViewMode;

        const menus = [
          billingPlanActionTypes.view,
          ...(canEditDelete ? [billingPlanActionTypes.edit, billingPlanActionTypes.delete] : []),
        ];

        const actionIcons = {
          [billingPlanActionTypes.view]: <EyeViewIcon />,
          [billingPlanActionTypes.edit]: <EditOutlinedIcon />,
          [billingPlanActionTypes.delete]: <DeleteIcon />,
        };

        const actions = {
          [billingPlanActionTypes.view]: () => {
            setSelectedPricing(row.id);
            setActionType('view');
            standardPricingModalRef.current?.open();
          },
          [billingPlanActionTypes.edit]: () => {
            setSelectedPricing(row.id);
            setActionType('edit');
            standardPricingModalRef.current?.open();
          },
          [billingPlanActionTypes.delete]: () => {
            setConfirmFormat({
              title: `Delete standard price?`,
              content: `Are you sure you want to delete the standard price?`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () => {
                  deleteStandardPricing(row.id, {
                    onSuccess: () => {
                      modalConfirmRef.current?.close();
                      modalAlertRef.current?.open();
                    },
                    onError: handleErrorFromServer,
                  });
                },
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const menuAction = menus.map((it) => ({
          icon: actionIcons?.[it],
          label: capitalize(it),
          onAction: actions?.[it],
        }));

        return <ActionMenus menus={menuAction} />;
      },
    },
  ];

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleCloseStandardPriceModal = () => {
    standardPricingModalRef.current?.close();
  };

  return (
    <>
      <FormProvider {...form}>
        <Box width={'800px'}>
          <Box
            className='flex justify-between'
            py={4}
            px={7.5}
            borderBottom='1px solid'
            borderColor='neutral.ne200'
          >
            <Typography variant='h5'>Standard Price History</Typography>
            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box py={5} px={7.5}>
            <Box
              sx={{
                '.render-node-container': { m: 0 },
                '.MuiTable-root': { minWidth: '500px !important' },
              }}
            >
              {historyList.length || isLoading ? (
                <CustomTable
                  columns={columns}
                  rows={historyList}
                  isFetchingData={isLoading}
                  hasPagination={false}
                  onSort={handleSort}
                />
              ) : (
                <EmptyData />
              )}
            </Box>
          </Box>
          <BasicModal
            ref={standardPricingModalRef}
            maxWidth='xl'
            PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
            sx={{
              '& .MuiDialog-container': {
                marginLeft: '260px',
              },
            }}
            onClose={handleCloseStandardPriceModal}
          >
            <StandardPricing
              isViewMode={actionType === 'view'}
              id={selectedPricing}
              onClose={handleCloseStandardPriceModal}
              isSuperAdmin={isSuperAdmin}
              isPriceHistory
            />
          </BasicModal>
        </Box>
      </FormProvider>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={deletingStandardPricing}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={deletingStandardPricing}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title='You did it !'
          description='Standard price deleted successfully.'
          buttonAction={{
            label: 'OK',
            onAction: () => {
              modalAlertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default StandardPriceHistory;
