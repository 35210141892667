/* eslint-disable no-restricted-imports */
import { Box, IconButton, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import HorizontalTabs, { IHorizontalTabsElement } from 'src/components/molecules/HorizontalTabs';
import StatusBadge from 'src/components/molecules/StatusBadge';
import { FundraisingApplicationOverview } from 'src/components/pages/applications/components';
import { FUNDRAISING_OVERVIEW_STEP } from 'src/constants/applications';
import {
  useGetApplicationFilters,
  useGetApplicationOverview,
} from 'src/modules/applications/hooks';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import ApplicationReview from './components/ApplicationReview';

export interface FundraisingApplicationFormProps extends IUpsertInvestmentApplicationForm {
  onClose: () => void;
}

export enum FUNDRAISING_APPLICATON_TAB {
  Overview,
  Review,
}

const FundraisingApplicationForm: React.FC<FundraisingApplicationFormProps> = ({
  onClose,
  ...props
}) => {
  const horizontalTabsRef = useRef<IHorizontalTabsElement>(null);
  const [reviewStep, setReviewStep] = useState<number>();
  const { data: { statuses = [] } = {} } = useGetApplicationFilters();
  const { data: overviewData } = useGetApplicationOverview(
    props.id || '',
    FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
  );
  const { status, applicationNumber, entityName } = overviewData || {};
  const statusName = statuses.find((it) => it.id === status)?.name || '';

  const handleSetTab = (tab: FUNDRAISING_APPLICATON_TAB, step?: number) => {
    horizontalTabsRef.current?.setTab(tab);
    if (Number.isInteger(step)) {
      setReviewStep(step);
    }
  };

  const Tabs: { label: string; content: React.ReactNode }[] = [
    {
      label: 'Overview',
      content: (
        <Box mx={-3} mb={-3}>
          <FundraisingApplicationOverview
            {...props}
            statusName={statusName}
            onSetApplicationTab={handleSetTab}
          />
        </Box>
      ),
    },
    {
      label: 'Application Review',
      content: (
        <Box mx={-3} mb={-3}>
          <ApplicationReview
            {...props}
            statusName={statusName}
            step={reviewStep}
            setReviewStep={setReviewStep}
            onClose={onClose}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box pb={2}>
        <Box className='flex justify-center' pt={4} pb={0.75}>
          <StatusBadge status={statusName} />
        </Box>
        <Typography variant='h5' align='center'>
          {applicationNumber}
        </Typography>
        <Typography variant='body1' align='center' mt={0.75}>
          {entityName}
        </Typography>
      </Box>
      <Box className='absolute right-10 top-10'>
        <IconButton sx={{ p: 0 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <HorizontalTabs ref={horizontalTabsRef} tabs={Tabs} destroyInactivePanel={false} />
    </Box>
  );
};

export default FundraisingApplicationForm;
