import { Box } from '@mui/material';
import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useAuthenticated } from 'src/modules/auth/hooks';

export default function WithRestrictedRoute(Wrapped: ComponentType) {
  const { isAuthenticated } = useAuthenticated();
  const searchParams = new URLSearchParams(window.location.search);
  const joinClient = searchParams.get('joinClient');

  return (props: any) =>
    isAuthenticated && !joinClient ? (
      <Navigate to={ROUTES_PATH.HOME} />
    ) : (
      <React.Suspense
        fallback={
          <Box className='flex justify-center items-center h-full'>
            <Box>Loading...</Box>
          </Box>
        }
      >
        <Wrapped {...props} />
      </React.Suspense>
    );
}
