import { AttachFile } from '@mui/icons-material';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomButton from 'src/components/atoms/CustomButton';
import UploadButton from 'src/components/atoms/UploadButton';
import { CommunicationCategoryEnum } from 'src/constants/communications';
import { FUND_DOCUMENT_ACCEPT_TYPE, MAX_DOCUMENTS_SIZE } from 'src/constants/documents';
import { detectPortalType } from 'src/helpers/common';
import { useCommunicationFileUpload } from 'src/modules/communication/hooks';
import { handleErrorFromServer } from 'src/utils/common';

interface IMessageButtons {
  category: number | null;
  onError: () => void;
  onSendViaPortal: () => void;
  onSendViaPortalAndEmail: () => void;
  loading?: boolean;
  isInitialCommunication?: boolean;
}

const MessageButtons: React.FC<IMessageButtons> = ({
  category,
  onError,
  onSendViaPortal,
  onSendViaPortalAndEmail,
  loading,
  isInitialCommunication,
}) => {
  const { isInvestor, isFunds } = detectPortalType();
  const { mutate: uploadFiles, isLoading } = useCommunicationFileUpload();

  const { control, watch } = useFormContext();

  const { append: appendAttachments } = useFieldArray({
    control,
    name: 'attachments',
  });

  const isShowSendViaPortalBtn = [
    CommunicationCategoryEnum.ClientManagement,
    CommunicationCategoryEnum.Internal,
    CommunicationCategoryEnum.InvestorRelation,
  ].includes(category as number);

  const handleUploadFile = async (files: FileList) => {
    if (isEmpty(files)) return;
    const arrFiles = Array.from(files);
    uploadFiles(
      { files: arrFiles },
      {
        onSuccess: (data) => {
          appendAttachments(data);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  return (
    <Box className='flex gap-2 w-full justify-end pt-3'>
      <UploadButton
        inputProps={{
          accept: FUND_DOCUMENT_ACCEPT_TYPE,
        }}
        handleFileChange={handleUploadFile}
        validate={{
          fileSize: MAX_DOCUMENTS_SIZE,
          onError,
        }}
        sx={{ p: 0 }}
        variant='text'
        multiple
        label={<AttachFile />}
        disabled={isLoading}
      />
      {!isInvestor && isShowSendViaPortalBtn && (
        <CustomButton
          isLoading={loading}
          onClick={onSendViaPortal}
          disabled={isFunds && watch('hasExternalMail')}
        >
          Send via Portal
        </CustomButton>
      )}
      <CustomButton isLoading={loading} onClick={onSendViaPortalAndEmail}>
        {isInitialCommunication ? 'Send Email' : 'Send via Portal and Email'}
      </CustomButton>
    </Box>
  );
};

export default MessageButtons;
