import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';
import _, { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { AccordionSummary } from 'src/components/atoms/CustomAccordion';
import EmptyData from 'src/components/molecules/EmptyData';
import { IAuditTrail } from 'src/modules/kyc-back-office/type';
import AuditTrailDetail from './AuditTrailDetail';

interface IAuditTrailProps {
  data?: IAuditTrail[];
}

interface IGroupAuditTrail {
  label: string;
  items: IAuditTrail[];
}

const OwnershipStructureAndUBOAuditTrails: React.FC<IAuditTrailProps> = ({ data = [] }) => {
  const uboAuditTrails: IGroupAuditTrail[] = useMemo(() => {
    return _.chain(data)
      .groupBy('uboId')
      .map((value, key) => {
        const findItem = value.find((f: IAuditTrail) => f.uboId === key);
        return {
          label: `${findItem?.firstName} ${findItem?.lastName} - ${findItem?.position}`,
          items: value,
        };
      })
      .value();
  }, []);

  return (
    <Box mt={1}>
      {!isEmpty(uboAuditTrails) ? (
        uboAuditTrails?.map((auditTrail: IGroupAuditTrail, index: number) => {
          {
            return (
              <Accordion
                defaultExpanded
                key={`ubo-audit-trail-${index}`}
                sx={() => ({
                  boxShadow: 'none',
                  '::before': {
                    display: 'none',
                  },
                })}
              >
                <AccordionSummary
                  sx={{
                    '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
                    '.MuiAccordionSummary-content': { my: 0 },
                    minHeight: '64px',
                  }}
                >
                  <Typography variant='body2' ml={2} fontWeight={700}>
                    {auditTrail.label}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className='p-6 pt-0 pb-0 mt-0'>
                  {auditTrail?.items?.map((item: IAuditTrail, index: number) => (
                    <AuditTrailDetail key={`audit-trail-${index}`} item={item} />
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          }
        })
      ) : (
        <EmptyData />
      )}
    </Box>
  );
};

export default OwnershipStructureAndUBOAuditTrails;
