import { IOptions } from 'src/components/atoms/FormMultiSelect';

export enum UnitOptionsType {
  No,
  Yes,
}

export enum PaymentMethodType {
  Bank_Account = '0',
  BPay = '1',
}

export enum FeeValueType {
  Percent,
  Amount,
  Basis_Points,
}

export enum PaymentTermType {
  In_Advance,
  In_Arrears,
}

export enum FeeType {
  Management,
  Performance,
  Entry_Exit,
  Other,
  Buy_Sell_Spread,
}

export enum CalculationBaseType {
  NAV,
  GAV,
  Total_Committed_Capital,
  Custom,
}

export enum FrequencyType {
  Annual,
  SemiAnnual,
  Quarterly,
  Monthly,
  Fortnightly,
  Weekly,
  Daily,
  AdHoc,
}

export enum RedemptionOptionsType {
  Yes,
  YesAndRequireApproval,
  No,
}

export enum UnitClassOfferType {
  Wholesale,
  Retail,
}

export enum UnitClassInvestorType {
  Wholesale,
  Professional,
  Sophisticated,
  RetailExemption,
  Retail,
}

export enum UnitClassForeignInvestorType {
  FATCA,
  CRS,
  Other,
}

export const PartiallyPaidUnitsOptions = [
  {
    label: 'Yes',
    value: UnitOptionsType.Yes,
  },
  {
    label: 'No',
    value: UnitOptionsType.No,
  },
];

export const DrpOptions = [
  {
    label: 'Yes',
    value: UnitOptionsType.Yes,
  },
  {
    label: 'No',
    value: UnitOptionsType.No,
  },
];

export const PaymentMethodOptions = [
  {
    id: PaymentMethodType.Bank_Account,
    label: 'Bank Account',
    value: PaymentMethodType.Bank_Account,
  },
  {
    id: PaymentMethodType.BPay,
    label: 'BPay',
    value: PaymentMethodType.BPay,
  },
];

export const FeeValueTypeOptions = [
  {
    label: '%',
    value: FeeValueType.Percent,
  },
  {
    label: 'Amount',
    value: FeeValueType.Amount,
  },
];

export const FeeValueTypeOfEntryExitFeesOptions = [
  {
    label: 'Basis Points',
    value: FeeValueType.Basis_Points,
  },
  ...FeeValueTypeOptions,
];

export const PaymentTermOptions = [
  {
    label: 'In Advance',
    value: PaymentTermType.In_Advance,
  },
  {
    label: 'In Arrears',
    value: PaymentTermType.In_Arrears,
  },
];

export const FeeTypeOptions = [
  {
    label: 'Management Fees',
    value: FeeType.Management,
  },
  {
    label: 'Performance Fees',
    value: FeeType.Performance,
  },
  {
    label: 'Entry / Exit Fees',
    value: FeeType.Entry_Exit,
  },
  {
    label: 'Other Fees',
    value: FeeType.Other,
  },
  {
    label: 'Buy/Sell Spread',
    value: FeeType.Buy_Sell_Spread,
  },
];

export const CalculationBaseTypeOptions = [
  {
    label: 'NAV',
    value: CalculationBaseType.NAV,
  },
  {
    label: 'GAV',
    value: CalculationBaseType.GAV,
  },
  {
    label: 'Total Committed Capital',
    value: CalculationBaseType.Total_Committed_Capital,
  },
  {
    label: 'Custom',
    value: CalculationBaseType.Custom,
  },
];

export const FrequencyTypeOptions = [
  {
    label: 'Annual',
    value: FrequencyType.Annual,
  },
  {
    label: 'Semi Annual',
    value: FrequencyType.SemiAnnual,
  },
  {
    label: 'Quarterly',
    value: FrequencyType.Quarterly,
  },
  {
    label: 'Monthly',
    value: FrequencyType.Monthly,
  },
  {
    label: 'Fortnightly',
    value: FrequencyType.Fortnightly,
  },
  {
    label: 'Weekly',
    value: FrequencyType.Weekly,
  },
  {
    label: 'Daily',
    value: FrequencyType.Daily,
  },
  {
    label: 'Ad Hoc',
    value: FrequencyType.AdHoc,
  },
];

export const MAX_INFORMATION_CHARACTERS = 280;

export const unitClassDetailDefaultValue = {
  isSaveDraft: false,
  name: '',
  code: '',
  currency: 0,
  initialUnitPrice: '',
  allowPartiallyPaidUnits: 1,
  allowCapitalCall: 1,
  additionalInformation: '',
  fundId: '',
};
export const unitClassInvestorRelationsDefaultValue = {
  isSaveDraft: false,
  investorContactType: 0,
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  contactNumber: '',
  countryFlagCode: '',
  organisationName: '',
  contactName: '',
  fundInvestorContactId: '',
};

export const investmentDetailDefaultValue = {
  isSaveDraft: false,
  offerType: UnitClassOfferType.Wholesale,
  investorTypes: [UnitClassInvestorType.Wholesale],
  allowForeignInvestors: UnitOptionsType.No,
  foreignInvestorTypes: [],
  minimumInvestmentAmount: 0,
  minimumSubsequentInvestmentAmount: 0,
  maximumInvestmentAmount: 0,
  incrementInvestmentAmount: 0,
  acceptInvestmentAmountUnderMinimum: UnitOptionsType.No,
  hardFloor: 0,
  redemptionAllowedType: RedemptionOptionsType.No,
  minimumRedeptionAmount: null,
  unlimitedInvestmentAmount: false,
};

export const MAX_UNIT_PRICE = 999999.999999;

export const RedemptionAllowedOptions = [
  {
    label: 'Yes',
    value: RedemptionOptionsType.Yes,
  },
  {
    label: 'Yes, require approval',
    value: RedemptionOptionsType.YesAndRequireApproval,
  },
  {
    label: 'No',
    value: RedemptionOptionsType.No,
  },
];

export const unitClassOfferOptions: IOptions[] = [
  {
    id: UnitClassOfferType.Wholesale,
    label: 'Wholesale',
    value: UnitClassOfferType.Wholesale,
  },
  {
    id: UnitClassOfferType.Retail,
    label: 'Retail',
    value: UnitClassOfferType.Retail,
  },
];

export const unitClassInvestorTypeOptions: IOptions[] = [
  {
    id: UnitClassInvestorType.Wholesale,
    label: 'Wholesale',
    value: UnitClassOfferType.Wholesale,
  },
  {
    id: UnitClassInvestorType.Professional,
    label: 'Professional',
    value: UnitClassInvestorType.Professional,
  },
  {
    id: UnitClassInvestorType.Sophisticated,
    label: 'Sophisticated',
    value: UnitClassInvestorType.Sophisticated,
  },
  {
    id: UnitClassInvestorType.RetailExemption,
    label: 'Retail Exemption',
    value: UnitClassInvestorType.RetailExemption,
  },
];

export const unitClassForeignInvestorTypeOptions: IOptions[] = [
  {
    id: UnitClassForeignInvestorType.FATCA,
    label: 'FATCA',
    value: UnitClassForeignInvestorType.FATCA,
  },
  {
    id: UnitClassForeignInvestorType.CRS,
    label: 'CRS',
    value: UnitClassForeignInvestorType.CRS,
  },
];

export const incrementInvestmentAmountOptions: IOptions[] = [
  {
    id: '0',
    label: '0',
    value: 0,
  },
  {
    id: '1000',
    label: '1000',
    value: 1000,
  },
  {
    id: '5000',
    label: '5000',
    value: 5000,
  },
  {
    id: '10000',
    label: '10000',
    value: 10000,
  },
  {
    id: '25000',
    label: '25000',
    value: 25000,
  },
  {
    id: '50000',
    label: '50000',
    value: 50000,
  },
  {
    id: '100000',
    label: '100000',
    value: 100000,
  },
  {
    id: '250000',
    label: '250000',
    value: 250000,
  },
];

export const investorTypeTooltip = {
  Wholesale: `- Wholesale - Investor has net assets of at least $2.5 million or gross income in the last 2 financial years of at least $250,000 p.a. (wholesale investor certificate is required) or investing at least $500,000 in the Fund.`,
  Professional: `- Professional – Investor holds an AFSL, regulated by APRA or a person who controls at least $10 million`,
  Sophisticated: `- Sophisticated – Subject to the sophisticated assessment`,
  RetailExemption: `- Retail - exemption - The Investor is applying as retail investor through a small scale offering under s1012E of, and is not a regulated offer under the Corporations Act 2001 9Cth)`,
};

export const MAX_UNIT_CLASS_CODE = 4;

export enum UNIT_CLASS_STATUS {
  Draft,
  Pending,
  Open,
  Active,
  Inactive,
}
