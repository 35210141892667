/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IOutlinedInfoIconProps {}

export const OutlinedInfoIcon: FC<IOutlinedInfoIconProps> = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='00_List-Clients-Action_00' transform='translate(-1347.000000, -233.000000)'>
          <rect fill='#FFFFFF' x='0' y='0' width='1440' height='1080'></rect>
          <g id='05.Templates/01.Expand'>
            <rect id='Rectangle' fill='#F9F9F9' x='0' y='0' width='1440' height='1080'></rect>
            <rect
              id='Container'
              fill='#FFFFFF'
              x='284'
              y='96'
              width='1132'
              height='960'
              rx='10'
            ></rect>
          </g>
          <g id='Organization' transform='translate(1211.000000, 218.000000)'>
            <rect
              id='Container'
              stroke='#C2C4C5'
              fill='#F9F9F9'
              x='0.5'
              y='0.5'
              width='167'
              height='45'
              rx='8'
            ></rect>
            <g
              id='Dropdown'
              transform='translate(16.000000, 11.000000)'
              fill='#9DA0A1'
              fillRule='nonzero'
            >
              <g
                id='01.Design-Token/06.Icons/Fluent/Line/Chevron-Down'
                transform='translate(120.000000, 4.000000)'
              >
                <g id='Group' transform='translate(1.333333, 1.333333)'>
                  <path
                    d='M6.668,-8.8817842e-16 C10.3506667,-8.8817842e-16 13.336,2.98533333 13.336,6.668 C13.336,10.35 10.3506667,13.3353333 6.668,13.3353333 C2.98533333,13.3353333 0,10.35 0,6.668 C0,2.98533333 2.98533333,-8.8817842e-16 6.668,-8.8817842e-16 Z M6.668,1 C4.62849046,0.977880039 2.73436898,2.05326734 1.7081753,3.81594003 C0.681981624,5.57861272 0.681981624,7.75672062 1.7081753,9.51939331 C2.73436898,11.282066 4.62849046,12.3574533 6.668,12.3353333 C9.76408968,12.2878429 12.2490696,9.76445388 12.2490696,6.668 C12.2490696,3.57154612 9.76408968,1.04815712 6.668,1.00066667 L6.668,1 Z M6.66533333,5.66666624 C6.91544392,5.66634376 7.12731847,5.85087967 7.16133333,6.09866667 L7.166,6.16666624 L7.168,9.83466667 C7.16791943,10.0975228 6.96432481,10.3154344 6.70207999,10.3333506 C6.43983517,10.3512669 6.20849566,10.1630695 6.17266667,9.90266667 L6.168,9.83533333 L6.166,6.16733333 C6.166,5.89119096 6.38985763,5.66733333 6.666,5.66733333 L6.66533333,5.66666624 Z M6.668,3.336 C6.91084334,3.32831389 7.13860271,3.45348138 7.26229755,3.66260228 C7.38599238,3.87172319 7.38599238,4.13161015 7.26229755,4.34073105 C7.13860271,4.54985195 6.91084334,4.67501944 6.668,4.66733333 C6.30856084,4.65595691 6.02306867,4.36128581 6.02306867,4.00166667 C6.02306867,3.64204752 6.30856084,3.34737642 6.668,3.336 L6.668,3.336 Z'
                    id='Shape'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
