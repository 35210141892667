/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable autofix/no-unused-vars */
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { isNumber } from 'lodash';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import LoadingIndicator from 'src/components/atoms/LoadingIndicator';
import EmptyData from 'src/components/molecules/EmptyData';
import { generaValueFromMultipleFields } from 'src/helpers/common';
import { calculateRange } from 'src/utils/table';
import CustomHelperText from './CustomHelperText';
import CustomPagination from './CustomPagination';

export interface ColumnProps<T = void, ADDITIONAL_KEY = void, S = void> {
  title: React.ReactNode;
  renderNode?: (row: T, index: number) => ReactNode;
  className?: string;
  key?: keyof T | ADDITIONAL_KEY;
  isHidden?: boolean;
  sortBy?: keyof T | S | [keyof T | S][];
  sorter?: boolean;
  sx?: SxProps<Theme>;
  cellSx?: (row: T) => SxProps<Theme>;
  isDisabled?: boolean;
  allowCollapse?: boolean;
  collapseContent?: (row: T, index?: number) => ReactNode;
  isSticky?: boolean;
}

export interface TableProps<T = any> {
  columns: ColumnProps<T, any, any>[];
  rows: T[];
  isFetchingData?: boolean;
  onSort?: (sortBy: keyof T, isAsc: boolean) => void;
  totalResults?: number;
  numberItemOnPage?: number;
  currentPage?: number;
  onChangePagination?: (pageNumber: number) => void;
  hasPagination?: boolean;
  gridStyle?: boolean;
  displayEmpty?: boolean;
  stickyHeader?: boolean;
  emptyDescription?: string;
  customEmpty?: ReactNode;
  hiddenHorizontalRule?: boolean;
  errorMessage?: string;
  tableClassName?: string;
  isAllowSelect?: boolean;
  rowKey?: string;
  onSelect?: (items: SelectItemType[]) => void;
  onClickRow?: (item: T) => void;
  selectedRows?: SelectItemType[];
  sx?: SxProps<Theme>;
}

export type SelectItemType = string | number;

type SortType = 'asc' | 'desc' | '';

export interface TableSort<T extends {}> {
  sortBy: keyof T;
  sortType: SortType;
}

export const StyledTableCell = styled(TableCell)<{
  gridStyle?: boolean;
  isLastRow?: boolean;
  hiddenHorizontalRule?: boolean;
  isDisabled?: boolean;
  isSticky?: boolean;
  right?: number;
  enableShadow?: boolean;
}>(
  ({
    theme,
    gridStyle,
    isLastRow = false,
    hiddenHorizontalRule = false,
    isDisabled = false,
    isSticky = false,
    right = 'unset',
    enableShadow = false,
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.neutral.ne100,
      color: theme.palette.neutral.ne800,
      fontSize: 14,
      borderRight: `1px solid ${theme.palette.neutral.ne200}`,
      borderBottomWidth: gridStyle ? `1px solid ${theme.palette.neutral.ne300}` : 0,
      '&:last-child': {
        borderRight: 0,
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.neutral.ne800,
      fontWeight: 500,
      borderRight: gridStyle ? `1px solid ${theme.palette.neutral.ne300}` : 0,
      borderBottomWidth: isLastRow || hiddenHorizontalRule ? 0 : '1px',
      borderBottomColor: theme.palette.neutral.ne200,
      backgroundColor: isDisabled ? theme.palette.neutral.ne200 : '',
      '&:last-child': {
        borderRight: 0,
      },
    },
    position: isSticky ? 'sticky' : 'relative',
    right: isSticky ? right : 'unset',
    background: isSticky ? 'white' : 'unset',
    boxShadow: isSticky && enableShadow ? 'rgba(149, 157, 165, 0.12) 0px 8px 10px' : 'unset',
    zIndex: isSticky ? 99 : 'unset',
  }),
);

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {},
}));

const CustomTable = <T extends object = {}>({
  columns,
  rows = [],
  isFetchingData,
  onSort,
  totalResults = 0,
  numberItemOnPage = 8,
  currentPage = 0,
  onChangePagination,
  hasPagination = true,
  gridStyle = false,
  displayEmpty = false,
  stickyHeader = false,
  emptyDescription,
  customEmpty,
  hiddenHorizontalRule = false,
  errorMessage = '',
  tableClassName = '',
  isAllowSelect = false,
  rowKey = 'id',
  onSelect,
  onClickRow,
  selectedRows,
  sx = {},
}: TableProps<T>): ReactElement => {
  const [selected, setSelected] = useState<SelectItemType[]>(selectedRows || []);

  useEffect(() => {
    if (selectedRows) setSelected(selectedRows);
  }, [selectedRows]);

  const [sort, setSort] = useState<Partial<TableSort<T>>>({
    sortType: 'asc',
  });

  const pages = Math.ceil(totalResults / numberItemOnPage);
  const {
    from,
    to,
    totalResults: totalResultsFormat,
  } = calculateRange(currentPage, totalResults, numberItemOnPage);

  const handleSort = (sortBy: keyof T) => {
    if (onSort) {
      const isAsc = sort.sortBy === sortBy && sort.sortType === 'asc';
      const sortType = isAsc ? 'desc' : 'asc';

      setSort({ sortType, sortBy });
      onSort(sortBy, sortType === 'asc');
      return;
    }

    const newSort: typeof sort = {
      sortBy,
    };
    switch (sort.sortType) {
      case 'asc':
        newSort.sortType = 'desc';
        break;

      default:
        newSort.sortType = 'asc';
        break;
    }
    setSort(newSort);
  };

  const sortedRows = useMemo(() => {
    const sortedRows = Array.isArray(rows) ? [...rows].filter((row: any) => !row?.disableSort) : [];
    const disableSortRows = [...rows]?.filter((row: any) => row?.disableSort) || [];

    if (onSort) {
      return rows;
    }

    const { sortBy } = sort;
    if (!sortBy) {
      return rows;
    }
    switch (sort.sortType) {
      case 'asc':
        sortedRows.sort((a, b) =>
          generaValueFromMultipleFields(a, sortBy)?.localeCompare(
            generaValueFromMultipleFields(b, sortBy),
          ),
        );

        break;

      default:
        sortedRows.sort((a, b) =>
          generaValueFromMultipleFields(b, sortBy)?.localeCompare(
            generaValueFromMultipleFields(a, sortBy),
          ),
        );

        break;
    }

    return [...sortedRows, ...disableSortRows];
  }, [sort, rows]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n[rowKey]);
      setSelected(newSelected);
      onSelect?.(newSelected);
      return;
    }
    setSelected([]);
    onSelect?.([]);
  };

  const handleSelect = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: SelectItemType[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    onSelect?.(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const rowCount = rows.length;
  const numSelected = selected.length;

  const getStickyCellRightOffset = (column: ColumnProps<any, any, any>) => {
    if (!column?.isSticky) return;
    const actionColumn = document.getElementById('action');
    const rightOffset = actionColumn?.getBoundingClientRect()?.width;

    console.log('rightOffset', rightOffset);

    return isNumber(rightOffset) ? rightOffset : 90;
  };

  return (
    <>
      <TableContainer
        sx={{
          borderColor: 'neutral.ne400',
          ...sx,
        }}
        className={`rounded-[10px] border`}
      >
        <Table
          className={clsx('min-w-[700px] border-separate', tableClassName)}
          aria-label='customized table'
          stickyHeader={stickyHeader}
        >
          {!!columns.length && (
            <TableHead>
              <StyledTableRow>
                {isAllowSelect && (
                  <StyledTableCell padding='checkbox' sx={{ width: '5%' }}>
                    <Checkbox
                      color='primary'
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'Select all communications',
                      }}
                    />
                  </StyledTableCell>
                )}
                {columns.map((column, index) => {
                  const { sortBy } = column;
                  const isLastColumn = index === columns.length - 1;
                  const stickyColumns = columns?.filter((item) => !item.isHidden && item?.isSticky);
                  let enableShadow = false;

                  if (column.isHidden) {
                    return null;
                  }

                  if (stickyColumns.length === 1) {
                    enableShadow = !!rows?.length;
                  } else if (stickyColumns.length > 1) {
                    enableShadow = stickyColumns[0]?.key === column.key && !!rows?.length;
                  }

                  return (
                    <StyledTableCell
                      key={`${index}-${column.key}`}
                      sx={column.sx}
                      sortDirection={sort.sortBy === column.key ? sort.sortType || false : false}
                      gridStyle={gridStyle}
                      isSticky={column?.isSticky}
                      right={isLastColumn ? 0 : getStickyCellRightOffset(column)}
                      id={column.key}
                      enableShadow={enableShadow}
                    >
                      {column.sorter ? (
                        <TableSortLabel
                          active={sort.sortBy === column.key}
                          direction={
                            sort.sortBy === column.key ? sort.sortType || undefined : 'asc'
                          }
                          onClick={() => handleSort(sortBy)}
                          sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <span>{column.title}</span>
                        </TableSortLabel>
                      ) : (
                        <span>{column.title}</span> // Wrap the title in a <span> element or any other appropriate container
                      )}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            </TableHead>
          )}

          {!!rows.length && (
            <TableBody>
              {!isFetchingData &&
                sortedRows.map((row: any, idx: number) => {
                  const labelId = `enhanced-table-checkbox-${idx}`;
                  const isItemSelected = isSelected(row.id);

                  return (
                    <StyledTableRow
                      key={`${idx}-${row.title}-table`}
                      role='checkbox'
                      onClick={() => isAllowSelect && onClickRow?.(row)}
                      selected={isItemSelected}
                      sx={isAllowSelect ? { cursor: 'pointer' } : {}}
                    >
                      {isAllowSelect && (
                        <StyledTableCell padding='checkbox' sx={{ width: '5%' }}>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            onClick={(event) => isAllowSelect && handleSelect(event, row[rowKey])}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </StyledTableCell>
                      )}
                      {columns.map((column, index) => {
                        const data = row[column.key] as any;
                        const hasRenderNode = !!column.renderNode;
                        const isLastColumn = index === columns.length - 1;
                        const stickyColumns = columns?.filter(
                          (item) => !item.isHidden && item?.isSticky,
                        );
                        let enableShadow = false;

                        const sx =
                          typeof column.cellSx === 'function' ? column.cellSx(row) : column.cellSx;

                        if (column.isHidden) {
                          return null;
                        }

                        if (stickyColumns.length === 1) {
                          enableShadow = true;
                        } else if (stickyColumns.length > 1) {
                          enableShadow = stickyColumns[0]?.key === column.key;
                        }

                        return hasRenderNode ? (
                          <StyledTableCell
                            key={`${index}-${column.title}`}
                            gridStyle={gridStyle}
                            isLastRow={idx === sortedRows.length - 1}
                            hiddenHorizontalRule={hiddenHorizontalRule}
                            sx={sx}
                            isDisabled={column.isDisabled}
                            isSticky={column?.isSticky}
                            right={isLastColumn ? 0 : getStickyCellRightOffset(column)}
                            enableShadow={enableShadow}
                          >
                            <Box
                              className='render-node-container'
                              sx={{ marginTop: 1, marginBottom: 1 }}
                            >
                              {column.renderNode?.(row, idx)}
                            </Box>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell
                            key={`${index}-${column.title}`}
                            gridStyle={gridStyle}
                            isLastRow={idx === sortedRows.length - 1}
                            hiddenHorizontalRule={hiddenHorizontalRule}
                            sx={sx}
                            isSticky={column?.isSticky}
                            right={isLastColumn ? 0 : getStickyCellRightOffset(column)}
                            enableShadow={enableShadow}
                          >
                            {data as ReactNode}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          )}
          {displayEmpty && !rows?.length && (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={999}>
                  {customEmpty ? customEmpty : <EmptyData isTable description={emptyDescription} />}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
        {isFetchingData && (
          <Box
            className='flex items-center justify-center py-4 border-b border-solid h-[200px]'
            borderColor='neutral.ne300'
          >
            <LoadingIndicator />
          </Box>
        )}
      </TableContainer>
      {errorMessage && (
        <Box mt={0.5}>
          <CustomHelperText variant='error' message={errorMessage} />
        </Box>
      )}
      {hasPagination && (
        <Box className='w-full flex items-center justify-between mt-[15px]'>
          <Typography variant='body3'>
            Showing {from} - {to} of {totalResultsFormat} results
          </Typography>
          <CustomPagination
            count={pages}
            page={currentPage}
            onChange={(value) => onChangePagination?.(value)}
          />
        </Box>
      )}
    </>
  );
};

export default CustomTable;
