/* eslint-disable no-restricted-imports */
import { Box } from '@mui/material';

import { FC } from 'react';

import TaxDetailsSection from 'src/components/pages/applications/components/TaxDetails/components/TaxDetailsSection';
import TaxInformationSection from 'src/components/pages/applications/components/TaxDetails/components/TaxInformationSection';
import {
  IDirector,
  ITaxNoTINReasons,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
export interface ITaxDetailsProps extends IUpsertInvestmentApplicationForm {
  taxNoTINReasons?: ITaxNoTINReasons[];
  directors?: IDirector[];
  foreignControllingPersons?: IDirector[];
}

const TaxDetails: FC<ITaxDetailsProps> = (props) => {
  const { taxNoTINReasons, directors, foreignControllingPersons } = props;

  return (
    <Box>
      <TaxDetailsSection {...props} isInvestmentEntitySetting />
      <Box mb={3} />
      <TaxInformationSection
        {...props}
        directors={directors}
        taxNoTINReasons={taxNoTINReasons}
        isInvestmentEntitySetting
        foreignControllingPersons={foreignControllingPersons}
      />
    </Box>
  );
};

export default TaxDetails;
