import { Avatar, Box, BoxProps, Grid, SelectChangeEvent, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import ContactNameIcon from 'src/assets/icons/ContactNameIcon';
import ContactNumberIcon from 'src/assets/icons/ContactNumberIcon';
import { DocsIcon } from 'src/assets/icons/DocsIcon';
import EmailIcon from 'src/assets/icons/EmailIcon';
import OrganisationIcon from 'src/assets/icons/OrganisationIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';
import { CustomTextField } from 'src/components/atoms/FormInput';
import { DATE_FORMAT } from 'src/constants/date';
import { InvestorRelationsContact } from 'src/constants/funds-management';
import { useCountry } from 'src/modules/common/hooks';
import {
  IDocument,
  IOfferClientBranding,
  IOfferInvestorBranding,
  IOfferPreview,
  IOfferUnitClass,
} from 'src/modules/offer/type';
import { formatNumberWithCommas } from 'src/utils/common';
import { formatPhoneNumber } from 'src/utils/phone';
import OfferThumbnail from './OfferThumbnail';

interface IOfferPreviewProps extends BoxProps {
  showUnitClass?: boolean;
  branding?: IOfferClientBranding | IOfferInvestorBranding;
  offerUnitClasses?: IOfferUnitClass[];
  previewData?: IOfferPreview;
  unitClassId: string;
  hideThumbnail?: boolean;
  onUnitClassIdChange?: (id: string) => void;
  onClickDocument?: (document: IDocument) => void;
  onClickInvest?: () => void;
  unitClassColSpan?: number;
  viewOnly?: boolean;
  hideInvestButton?: boolean;
  viewOnlyUnitClass?: boolean;
}

const OfferPreview: FC<IOfferPreviewProps> = ({
  showUnitClass = true,
  branding,
  offerUnitClasses = [],
  unitClassId,
  previewData,
  hideThumbnail = false,
  viewOnly = false,
  hideInvestButton = false,
  unitClassColSpan = 12,
  viewOnlyUnitClass = false,
  onClickInvest,
  onUnitClassIdChange,
  onClickDocument,
  ...rest
}) => {
  const { getPhoneCodeByCountryCode, parsePhoneNumber } = useCountry();

  useEffect(() => {
    if (isEmpty(offerUnitClasses)) return;
    onUnitClassIdChange && onUnitClassIdChange(offerUnitClasses[0].id);
  }, [offerUnitClasses]);

  const handleUnitClassChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    onUnitClassIdChange && onUnitClassIdChange(String(value));
  };

  const getUnitClassLabel = () => {
    const unitClass = offerUnitClasses.find((item) => item.id === unitClassId);

    return unitClass ? `${unitClass?.code} - ${unitClass?.name}` : '';
  };

  const unitClassInfo = [
    { value: previewData?.unitClass.offerType, label: 'Offer Type' },
    {
      value: `$${formatNumberWithCommas(previewData?.unitClass.minimumInvestment)}`,
      label: 'Minimum Investment',
    },
    {
      value: previewData?.isOpenEnded
        ? 'Open-ended'
        : dayjs(previewData?.expirationDate).format(DATE_FORMAT),
      label: 'Expiration Date',
    },
  ];

  const investorRelationsInfo = [
    { value: previewData?.unitClass.organisationName, icon: <OrganisationIcon /> },
    {
      value:
        previewData?.unitClass.contactName ||
        `${previewData?.unitClass.firstName} ${previewData?.unitClass.lastName}`,
      icon: <ContactNameIcon />,
    },
    { value: previewData?.unitClass.email, icon: <EmailIcon /> },
    {
      value: parsePhoneNumber(previewData?.unitClass.contactNumber || '')?.countryFlagCode
        ? previewData?.unitClass?.contactNumber || ''
        : formatPhoneNumber(
            getPhoneCodeByCountryCode(previewData?.unitClass?.countryFlagCode),
            previewData?.unitClass?.contactNumber || '',
          ),
      icon: <ContactNumberIcon />,
    },
  ];

  return (
    <Box {...rest}>
      <Box className='preview border rounded-xl' borderColor='neutral.ne200'>
        <Box className='previewHeader'>
          <Box
            className='bannerLogo relative'
            sx={{
              height: 201,
            }}
          >
            <img
              src={previewData?.bannerUrl}
              alt='Banner'
              className='w-full h-full block object-cover rounded-t-xl'
            />
            {previewData?.logoUrl && (
              <Avatar
                className='absolute logo'
                alt='Logo'
                src={previewData?.logoUrl}
                sx={{
                  width: 150,
                  height: 150,
                  bottom: -38,
                  left: 24,
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          className='previewBody'
          sx={{
            px: 3,
            pb: 3,
            pt: '54px',
          }}
        >
          <Box className='metaInfo'>
            <Grid container alignItems='center' columnSpacing={5}>
              <Grid item xs={6}>
                <Typography className='text-[2em]' fontWeight='bold' paddingBottom={1}>
                  {previewData?.fundName}
                </Typography>
                <Typography className='text-[1em]' color='neutral.ne800'>
                  {previewData?.name}
                </Typography>
              </Grid>
              {showUnitClass && (
                <Grid item xs={6}>
                  <Grid container justifyContent='flex-end' spacing={5}>
                    <Grid item xs={unitClassColSpan}>
                      {viewOnlyUnitClass ? (
                        <CustomTextField
                          value={getUnitClassLabel()}
                          inputProps={{ viewOnly: viewOnlyUnitClass }}
                        />
                      ) : (
                        <CustomSelect
                          fullWidth
                          value={unitClassId}
                          onChange={handleUnitClassChange}
                        >
                          {offerUnitClasses.map((unitClass, index) => (
                            <CustomMenuItem key={`unit-class-${index}`} value={unitClass.id}>
                              {`${unitClass.code} - ${unitClass.name}`}
                            </CustomMenuItem>
                          ))}
                        </CustomSelect>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
          <Grid container className='main pt-8' columnSpacing={5}>
            <Grid item xs={8}>
              <Box className='flex flex-col'>
                <Typography className='text-[1em]' fontWeight='medium'>
                  Overview
                </Typography>
                <Box paddingTop={2}>
                  <Typography
                    component='div'
                    dangerouslySetInnerHTML={{
                      __html: previewData?.overview || '',
                    }}
                    className='text-[1em] break-words'
                    color='neutral.ne800'
                  />
                </Box>
                <Box className='pt-8'>
                  <Typography className='text-[1em]' fontWeight='medium'>
                    Documents
                  </Typography>
                  <Box className='flex flex-wrap m-[-12px]' paddingTop={3}>
                    {(previewData?.unitClass.documents || []).map((item) => {
                      const docType = item?.fileName?.split('.').pop();
                      return (
                        <Box
                          key={item.id}
                          className={clsx('flex flex-col w-fit  px-3 py-3 max-w-[150px]', {
                            'cursor-pointer': !!onClickDocument,
                          })}
                          onClick={() => {
                            onClickDocument && onClickDocument(item);
                          }}
                        >
                          {docType === 'pdf' ? (
                            <PDFIcon className='self-center' width={60} height={60} />
                          ) : (
                            <DocsIcon className='self-center' width={60} height={60} />
                          )}
                          <Typography className='text-[1em] break-all' align='center'>
                            {item?.fileName}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className='aside'>
                <Box className='px-7 py-3 rounded-lg' bgcolor='neutral.ne100'>
                  <Box component='ul' className='list-disc my-[-8px]'>
                    {unitClassInfo.map((item, index) => (
                      <Box key={index} className='py-2' component='li'>
                        <Box className='flex flex-col'>
                          <Typography className='text-[1em]' fontWeight='medium'>
                            {item.value}
                          </Typography>
                          <Typography
                            className='text-[0.875em]'
                            color='neutral.ne500'
                            paddingTop={0.5}
                          >
                            {item.label}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>

                {!hideInvestButton && (
                  <CustomButton
                    className='text-[1em] py-[0.25em]'
                    fullWidth
                    variant='contained'
                    sx={{
                      borderRadius: 12,
                      mt: 2,
                      '&.Mui-disabled': {
                        color: branding?.primaryText,
                        background: branding?.primaryBackground,
                      },
                    }}
                    branding={branding}
                    disabled={viewOnly}
                    onClick={onClickInvest}
                  >
                    Invest now
                  </CustomButton>
                )}
                {previewData?.unitClass.email && (
                  <Box className='investorRelations pt-8'>
                    <Typography className='text-[1em]' fontWeight='medium'>
                      Investor Relations
                    </Typography>

                    <Box className='pt-4 my-[-8px]' component='ul'>
                      {investorRelationsInfo
                        .filter((item) =>
                          previewData?.unitClass.investorContactType ===
                          InvestorRelationsContact.Individual
                            ? item.value !== previewData.unitClass.organisationName
                            : item,
                        )
                        .map((item) => (
                          <Box className='flex items-center py-2' key={item.value} component='li'>
                            {item.icon}
                            <Typography
                              className='text-[0.875em]'
                              color='neutral.ne800'
                              paddingLeft='6px'
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!hideThumbnail && (
        <Box className='thumbnail w-[312px] pt-10'>
          <OfferThumbnail data={previewData} branding={branding} viewOnly={viewOnly} />
        </Box>
      )}
    </Box>
  );
};

export default OfferPreview;
