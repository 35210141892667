import { Box, Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import CurrencyName from 'src/components/atoms/CurrencyName';

import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';

import { useFormContext } from 'react-hook-form';
import { useGetRecordPayment } from 'src/modules/administration-investor/hooks';
import { InvestorDetailsProps } from '.';

interface IRecordPaymentProps extends InvestorDetailsProps {}

const RecordPayment: FC<IRecordPaymentProps> = ({ id = '', currencyName }) => {
  const { data: { unpaidAmount } = {} } = useGetRecordPayment(id);
  const form = useFormContext();
  const paymentAmountValue = form.watch('paymentAmount');

  const formCurrencyProps = {
    hideCurrency: true,
    endAdornment: <CurrencyName currencyName={currencyName} />,
    decimalScale: 2,
  };

  useEffect(() => {
    handleSetBalanceValue();
  }, [unpaidAmount, paymentAmountValue]);

  const handleSetBalanceValue = () => {
    const value = unpaidAmount - paymentAmountValue;
    form.setValue('balance', value || 0);
    form.trigger('balance');
  };

  return (
    <Box>
      <Grid container columnSpacing={3} rowSpacing='18px'>
        <Grid item xs={12}>
          <FormDatePicker name='transactionDate' label='Transaction Date' disableFuture />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name=''
            label='Unpaid Amount'
            value={unpaidAmount}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            name='paymentAmount'
            label='Payment Amount'
            placeholder='Enter payment amount'
            hideCurrency
            decimalScale={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='balance'
            label='Balance'
            disabled
            allowNegative
          />
        </Grid>
      </Grid>
      <Box className='mt-8'>
        <FormCheckbox name='isSendMail' label='Send payment confirmation letter to investor.' />
      </Box>
    </Box>
  );
};

export default RecordPayment;
