import {
  AuthorisationServer,
  CertificationProfileType,
  CertificationProfileVariant,
  CertificationStatus, Claim, Device, RequiredCertification
} from './types';
import { getDeviceType } from './userAgent';

const requiredCertificationsMap: Record<
  RequiredCertification,
  { type: CertificationProfileType; variant: CertificationProfileVariant }
> = {
  'TDIF.IDP': { type: 'TDIF Accreditation', variant: 'Identity Provider' },
  'Redirect.FAPI2': { type: 'Redirect', variant: 'FAPI2 Adv. OP w/Private Key, PAR' },
};

export const filterAuthorisationServers = (
  authorisationServers: AuthorisationServer[],
  showAllParticipants: boolean,
  certificationStatus: CertificationStatus,
  claims: Claim[],
  requiredCertifications?: RequiredCertification[],
) => {
  if (showAllParticipants) return authorisationServers;

  const authorisationServersWithValidCertifications =
    removeExpiredCertifications(authorisationServers);

  const device = getDeviceType();
  const filteredAuthorisationServersByDevice = filterAuthorisationServerByDevice(
    authorisationServersWithValidCertifications,
    device,
  );

  const filteredAuthorisationServersByClaims = filterAuthorisationServerByClaims(
    filteredAuthorisationServersByDevice,
    claims,
  );

  const filteredAuthorisationServers = filterAuthorisationServerByCertification(
    filteredAuthorisationServersByClaims,
    certificationStatus,
    requiredCertifications,
  );

  return filteredAuthorisationServers;
};

export const getManualAuthorisationServer = (
  authorisationServers: AuthorisationServer[],
  showAllParticipants: boolean,
): AuthorisationServer | undefined => {
  const filtered = showAllParticipants
    ? authorisationServers
    : removeExpiredCertifications(authorisationServers);

  return filtered.find(({ AuthorisationServerCertifications }) =>
    AuthorisationServerCertifications?.find(
      ({ ProfileVariant }) => ProfileVariant === 'Fallback Identity Service Provider',
    ),
  );
};

export const removeExpiredCertifications = (authorisationServers: AuthorisationServer[]) => {
  const certifiedAuthorisationServers = [...authorisationServers];
  certifiedAuthorisationServers.forEach((authorisationServer) => {
    authorisationServer.AuthorisationServerCertifications =
      authorisationServer.AuthorisationServerCertifications.filter(
        (cert) => cert.Status === 'Certified' && !isExpired(cert.CertificationExpirationDate),
      );
  });
  return certifiedAuthorisationServers;
};

export const filterAuthorisationServerByClaims = (
  authorisationServers: AuthorisationServer[],
  claims: Claim[],
) => {
  const nameClaims: Claim[] = ['name', 'given_name', 'middle_name', 'family_name'];
  const hasNameClaims = claims.find((claim) => nameClaims.includes(claim));

  // All name claims (name, given_name, middle_name, family_name) are mapped to `name` in the authorisation server
  // So if an authorisation server has any name claim, remove all name claims and leave only `name`
  const formattedClaims: Claim[] = hasNameClaims
    ? ['name', ...claims.filter((claim) => !nameClaims.includes(claim))]
    : claims;

  return authorisationServers.filter((authorisationServer) => {
    const authorisationServerClaims = authorisationServer.AuthorisationServerCertifications.filter(
      ({ ProfileType }) => ProfileType === 'ConnectID Claims',
    ).map((cert) => cert.ProfileVariant);

    return formattedClaims.every((claim) => authorisationServerClaims.includes(claim));
  });
};

export const filterAuthorisationServerByDevice = (
  authorisationServers: AuthorisationServer[],
  deviceType: Device,
) => {
  if (deviceType === 'mobile') return authorisationServers;

  const onlyAppAuthorisationServers = authorisationServers.filter((authorisationServer) => {
    const channelCertifications = authorisationServer.AuthorisationServerCertifications.filter(
      (cert) => cert.ProfileType === 'Channel',
    );
    return channelCertifications.length === 1 && channelCertifications[0].ProfileVariant === 'app';
  });

  const onlyAppAuthorisationServersIds = onlyAppAuthorisationServers.map(
    (authorisationServer) => authorisationServer.AuthorisationServerId,
  );

  return authorisationServers.filter(
    (authorisationServer) =>
      !onlyAppAuthorisationServersIds.includes(authorisationServer.AuthorisationServerId),
  );
};

export const filterAuthorisationServerByCertification = (
  authorisationServers: AuthorisationServer[],
  status: CertificationStatus,
  requiredCertifications?: RequiredCertification[],
) => {
  const filteredByStatus = filterByStatus(authorisationServers, status);
  const filteredByStatusAndRequiredCertifications = filterByRequiredCertifications(
    filteredByStatus,
    requiredCertifications,
  );
  return filteredByStatusAndRequiredCertifications;
};

const filterByStatus = (
  authorisationServers: AuthorisationServer[],
  status: CertificationStatus,
) => {
  if (status === 'All') return authorisationServers;

  if (status === 'Active') {
    return authorisationServers.filter(({ AuthorisationServerCertifications }) =>
      AuthorisationServerCertifications.find(
        ({ ProfileVariant, ProfileType }) =>
          ProfileType === 'Redirect' && ProfileVariant === 'FAPI2 Adv. OP w/Private Key, PAR',
      ),
    );
  }

  throw Error(`Status ${status} not supported`);
};

// The idea is to loop the requiredCertications and check if all
// of them were found in the auth server certifications (AND)
// if so return the auth server
const filterByRequiredCertifications = (
  authorisationServers: AuthorisationServer[],
  requiredCertifications?: RequiredCertification[],
) => {
  if (!requiredCertifications) return authorisationServers;

  return authorisationServers.filter(({ AuthorisationServerCertifications }) => {
    let requiredCertificationsFound = 0;
    requiredCertifications.forEach((required) => {
      const found = AuthorisationServerCertifications.find(
        ({ ProfileType, ProfileVariant }) =>
          ProfileType === requiredCertificationsMap[required].type &&
          ProfileVariant === requiredCertificationsMap[required].variant,
      );

      if (found) requiredCertificationsFound++;
    });
    return requiredCertificationsFound === requiredCertifications.length;
  });
};

const isExpired = (expiryDateStr: string) => {
  const expiryDateArr = expiryDateStr.split('/');
  const expiryDate = new Date(`${expiryDateArr[2]}-${expiryDateArr[1]}-${expiryDateArr[0]}`);
  expiryDate.setHours(23);
  expiryDate.setMinutes(59);
  expiryDate.setSeconds(59);
  const today = new Date();
  return expiryDate.getTime() < today.getTime();
};
