import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import FormInput, { IFormInputProps } from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import { FundType } from 'src/constants/funds-management';
import {
  IFinancialDetailsParams,
  IFundDetailParams,
  IUpsertFundForm,
} from 'src/modules/funds-management/type';
import { formatValueWithSpaces } from 'src/utils/common';

const unitOptions = Array.from(Array(7)).map((_, id) => ({
  label: `${id}`,
  value: id,
}));

type TPaymentMethodFields = {
  // eslint-disable-next-line autofix/no-unused-vars
  [key in 'bankAccounts' | 'bPays']: {
    name: keyof TPaymentMethodFields;
    label: string;
    subFields: Partial<IFormInputProps & { fullWidth?: boolean }>[];
    value: FieldArrayWithId<IFinancialDetailsParams, key, 'id'>[];
    limit: number;
    type?: string;
    onAdd: () => void;
    onRemove: (_: number) => void;
  };
};

interface FinancialDetailsProps extends IUpsertFundForm {
  fundDetail?: IFundDetailParams;
}

const FinancialDetails: React.FC<FinancialDetailsProps> = ({
  isViewMode,
  isEditMode,
  isDraft,
  fundDetail,
}) => {
  const { control, handleSubmit, watch, getFieldState } = useFormContext<IFinancialDetailsParams>();
  const theme = useTheme();
  const drawerRef = useRef<ICustomDrawerElement>(null);
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState<keyof TPaymentMethodFields>('bankAccounts');
  const [expanded, setExpanded] = useState<{
    bankAccounts: boolean;
    bPays: boolean;
  }>({
    bankAccounts: false,
    bPays: false,
  });

  const showRoundingRule = fundDetail?.type === FundType.Trust;

  const {
    fields: bankAccounts,
    append: addBankAccount,
    remove: removeBankAccount,
  } = useFieldArray({
    control,
    name: 'bankAccounts',
  });

  const {
    fields: bPays,
    append: addBPay,
    remove: removeBPay,
  } = useFieldArray({
    control,
    name: 'bPays',
  });

  useEffect(() => {
    setExpanded({
      bankAccounts: !!bankAccounts?.length,
      bPays: !!bPays?.length,
    });
  }, [bankAccounts, bPays]);

  const bankAccountFields = [
    { label: 'Nickname', placeholder: 'Enter nickname', name: 'nickName' },
    { label: 'Bank Name', placeholder: 'Enter bank name', name: 'bankName' },
    { label: 'Account Name', placeholder: 'Enter account name', name: 'accountName' },
    {
      label: 'Bank State Branch (BSB) Number',
      placeholder: 'Enter 6-digit number',
      name: 'bankStateBranchNumber',
      maxLength: 7,
      type: 'tel',
      formatValue: (value: string) => {
        if (Number.isNaN(+value) && value?.length === 1) return '';
        return formatValueWithSpaces(value, 3) || value;
      },
    },
    { label: 'Account Number', placeholder: 'Enter account number', name: 'accountNumber' },
    {
      label: 'SWIFT Code',
      placeholder: 'Enter XXXX-XX format',
      name: 'swiftCode',
      optional: 'Optional',
      maxLength: 11,
      fullWidth: false,
    },
  ];

  const bPayFields = [
    { label: 'Nickname', placeholder: 'Enter nickname', name: 'nickName' },
    { label: 'Biller Code', placeholder: 'Enter biller code', name: 'billerCode' },
    {
      label: 'Customer Reference Number',
      placeholder: 'Enter customer reference number',
      name: 'customerReferenceNumber',
      fullWidth: true,
    },
  ];

  const paymentMethodFields: TPaymentMethodFields = {
    bankAccounts: {
      name: 'bankAccounts',
      label: 'Bank Account',
      subFields: bankAccountFields,
      value: bankAccounts,
      limit: 6,
      onAdd: () =>
        addBankAccount({
          nickName: '',
          bankName: '',
          accountName: '',
          bankStateBranchNumber: '',
          accountNumber: '',
          swiftCode: '',
        }),
      onRemove: removeBankAccount,
    },
    bPays: {
      name: 'bPays',
      label: 'BPay',
      subFields: bPayFields,
      value: bPays,
      limit: 3,
      onAdd: () =>
        addBPay({
          nickName: '',
          billerCode: '',
          customerReferenceNumber: '',
        }),
      onRemove: removeBPay,
    },
  };

  const handleRemovePaymentMethod = (
    e: MouseEvent<HTMLButtonElement>,
    name: keyof TPaymentMethodFields,
    index: number,
  ) => {
    e.stopPropagation();
    paymentMethodFields[name].onRemove(index);
  };

  const handleAddNewPaymentMethod = (name: keyof TPaymentMethodFields) => {
    setCurrentPaymentMethod(name);
    paymentMethodFields[name].onAdd();
    drawerRef.current?.open();
  };

  const handleCreateNew = () => {
    handleSubmit(() => {})();
    setTimeout(() => {
      if (
        !(getFieldState as any)(`${currentPaymentMethod}`)?.error?.[
          paymentMethodFields[currentPaymentMethod].value.length - 1
        ]
      ) {
        drawerRef.current?.close();
      }
    }, 0);
  };

  const handleCancelCreateNew = () => {
    if (currentPaymentMethod === 'bankAccounts') {
      removeBankAccount(bankAccounts.length - 1);
    } else if (currentPaymentMethod === 'bPays') {
      removeBPay(bPays.length - 1);
    }
    drawerRef.current?.close();
  };

  return (
    <Box component='form' className='pt-4'>
      {showRoundingRule && (
        <Box mb={2} className='flex flex-col items-center'>
          <Box width='100%' mb={2} display='inline-flex' alignItems='center'>
            <Typography variant='body2' fontWeight={500} mr={0.5}>
              Rounding Rule
            </Typography>
            <CustomTooltip
              title={
                <Box maxWidth='250px'>
                  <Typography variant='caption1' color='neutral.ne800'>
                    Select number of decimal places for rounding purpose - 0 to 6.
                  </Typography>
                </Box>
              }
              placement='right'
            >
              <Box>
                <InfoIcon />
              </Box>
            </CustomTooltip>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='investorUnit'
                label='Investor Units'
                disabled={isViewMode || (isEditMode && !isDraft)}
                options={unitOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='unitPrice'
                label='Unit Price'
                disabled={isViewMode || (isEditMode && !isDraft)}
                options={unitOptions}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Typography mt={4} variant='body2' fontWeight={500}>
        Payment Method
      </Typography>
      <Box ml={2} className='flex flex-col gap-3.5'>
        {(Object.keys(paymentMethodFields) as (keyof TPaymentMethodFields)[]).map((key) => {
          const field = paymentMethodFields[key];
          const isGotLimitItems = field.value.length >= field.limit;

          return (
            <Box key={field.name}>
              <Accordion
                className='bg-white mt-1'
                expanded={expanded[key]}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded((prev) => ({
                    ...prev,
                    [key]: !prev[key],
                  }));
                }}
              >
                <AccordionSummary
                  sx={{
                    '.MuiAccordionSummary-content': { my: '10px' },
                    minHeight: 'unset',
                  }}
                >
                  <Typography variant='body2'>{field.label}</Typography>
                </AccordionSummary>
                <AccordionDetails className='flex flex-col gap-[18px] pt-2 pb-3.5'>
                  {field.value?.map((it, idx) => {
                    const nickName = watch(`${field.name}`)[idx].nickName;
                    return (
                      <Accordion key={it.id} className='p-6' onClick={(e) => e.stopPropagation()}>
                        <AccordionSummary
                          sx={{
                            '.MuiSvgIcon-root': { fontSize: '18px', color: 'black' },
                            '.MuiAccordionSummary-content': { my: 0 },
                            minHeight: 'unset',
                          }}
                        >
                          <Box className='flex justify-between items-center w-full'>
                            <Typography variant='body2' fontWeight={500}>
                              {nickName}
                            </Typography>
                            {!isViewMode && (
                              <IconButton
                                onClick={(e) => handleRemovePaymentMethod(e, field.name, idx)}
                                sx={{ p: 0 }}
                              >
                                <DeleteIcon
                                  width={24}
                                  height={24}
                                  color={theme.palette.neutral.ne600}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails className='pt-6'>
                          <Grid container spacing={3}>
                            {field.subFields.map(({ name, fullWidth, ...inputProps }) => (
                              <Grid key={name} item xs={12} md={fullWidth ? 12 : 6}>
                                <FormInput
                                  name={`${field.name}[${idx}].${name}`}
                                  disabled={isViewMode}
                                  {...inputProps}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              {!isViewMode && (
                <CustomButton
                  className='px-3 -ml-3'
                  variant='text'
                  startIcon={
                    <PlusPrimaryIcon color={isGotLimitItems ? theme.palette.neutral.ne400 : ''} />
                  }
                  disabled={isGotLimitItems}
                  onClick={() => handleAddNewPaymentMethod(field.name)}
                >
                  <Typography
                    variant='body2'
                    fontWeight={600}
                    color={isGotLimitItems ? 'neutral.ne400' : 'primary.main'}
                  >
                    {`Add New ${field.label}`}
                  </Typography>
                </CustomButton>
              )}
            </Box>
          );
        })}
      </Box>
      <CustomDrawer
        ref={drawerRef}
        title={`Add New ${paymentMethodFields[currentPaymentMethod].label}`}
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCancelCreateNew}
            >
              Cancel
            </CustomButton>
            <CustomButton className='w-[160px]' onClick={handleCreateNew}>
              Create new
            </CustomButton>
          </Box>
        }
      >
        <Grid container spacing={3} mt={4}>
          {paymentMethodFields[currentPaymentMethod].subFields.map(
            ({ name, fullWidth, ...inputProps }) => {
              const fieldValues = paymentMethodFields[currentPaymentMethod].value;
              return (
                <Grid key={name} item xs={12} md={fullWidth ? 12 : 6}>
                  <FormInput
                    name={`${currentPaymentMethod}[${fieldValues.length - 1}].${name}`}
                    {...inputProps}
                  />
                </Grid>
              );
            },
          )}
        </Grid>
      </CustomDrawer>
    </Box>
  );
};

export default FinancialDetails;
