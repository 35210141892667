export const ADDRESS = {
  COUNTRIES: String(process.env.REACT_APP_GET_COUNTRIES),
  STATES: String(process.env.REACT_APP_GET_STATES),
  CITIES: String(process.env.REACT_APP_GET_CITIES),
};
export enum AUSTRALIA_STATE_ENUM {
  ACT,
  NSW,
  NT,
  QLD,
  SA,
  TAS,
  VIC,
  WA,
}

export const AUSTRALIA_STATE_OPTIONS = [
  {
    label: 'ACT',
    value: AUSTRALIA_STATE_ENUM.ACT,
  },
  {
    label: 'NSW',
    value: AUSTRALIA_STATE_ENUM.NSW,
  },
  {
    label: 'NT',
    value: AUSTRALIA_STATE_ENUM.NT,
  },
  {
    label: 'QLD',
    value: AUSTRALIA_STATE_ENUM.QLD,
  },
  {
    label: 'SA',
    value: AUSTRALIA_STATE_ENUM.SA,
  },
  {
    label: 'TAS',
    value: AUSTRALIA_STATE_ENUM.TAS,
  },
  {
    label: 'VIC',
    value: AUSTRALIA_STATE_ENUM.VIC,
  },
  {
    label: 'WA',
    value: AUSTRALIA_STATE_ENUM.WA,
  },
];
