import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CSVIcon from 'src/assets/icons/CSVIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormSelect from 'src/components/atoms/FormSelect';
import UploadButton from 'src/components/atoms/UploadButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { BULK_UPLOAD_FILE_ACCEPT_TYPE } from 'src/constants/bulk-upload';
import { IErrorResponse } from 'src/interfaces/common';
import {
  useCreateBulkUpload,
  useGetBulkUploadInvestorClients,
  useGetBulkUploadInvestorFunds,
  useGetBulkUploadInvestorUnitClasses,
  useGetBulkUploadMasterData,
} from 'src/modules/bulk-upload/hooks';
import { ICreateBulkUpload, ICreateBulkUploadResponse } from 'src/modules/bulk-upload/type';
import { IAlertInfo } from 'src/modules/common/type';
import { IFilterOption } from 'src/modules/kyc-back-office/type';
import { downloadFile, getErrorMessageFromServer } from 'src/utils/common';
import { createBulkUploadSchema } from 'src/validations/bulk-upload';
import ErrorDetailsPopup from './ErrorDetailsPopup';

interface IBulkUploadFormProps {
  onClose: () => void;
}

const BulkUploadForm: FC<IBulkUploadFormProps> = ({ onClose }) => {
  const alertRef = useRef<IBasicModalElement>(null);
  const errorDetailRef = useRef<IBasicModalElement>(null);
  const [csvRows, setCsvRows] = useState<number>();
  const [uploadError, setUploadError] = useState<ICreateBulkUploadResponse | undefined>();
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
    isError: true,
  });

  const form = useForm<ICreateBulkUpload>({
    resolver: yupResolver(createBulkUploadSchema),
  });
  const file = form.watch('file');
  const clientId = form.watch('clientId');
  const fundId = form.watch('fundId');

  const { data: masterData = {} } = useGetBulkUploadMasterData();
  const { data: clientsData } = useGetBulkUploadInvestorClients();
  const { data: fundsData } = useGetBulkUploadInvestorFunds(clientId);
  const { data: unitClasseData } = useGetBulkUploadInvestorUnitClasses(fundId);
  const { mutate: createBulkUpload, isLoading } = useCreateBulkUpload();
  const { investmentEntityTypes } = masterData;

  const handleUploadFile = async (files: FileList) => {
    const file = files[0];
    if (!file) return;
    const rowsLength = await countRowsInCSV(file);
    setCsvRows(rowsLength);
    form.setValue('file', file);
  };

  const onUploadDocument = () => {
    const params = form.getValues();
    createBulkUpload(params, {
      onSuccess: (data: ICreateBulkUploadResponse) => {
        if (isEmpty(data.validations)) {
          setAlertInfo({
            isError: false,
            title: 'You did it!',
            description: 'File accepted successfully.',
          });
          alertRef.current?.open();
        } else {
          setUploadError(data);
          errorDetailRef.current?.open();
        }
      },
      onError: (error: unknown | IErrorResponse) => {
        const message = getErrorMessageFromServer(error);
        setAlertInfo({
          isError: true,
          title: 'Upload Failed',
          description: message,
        });
        alertRef.current?.open();
      },
    });
  };

  const handleDeleteFile = () => {
    form.setValue('file', null);
  };

  const clientsOptions = useMemo(() => {
    return (clientsData || [])
      .map((item: IFilterOption) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
  }, [clientsData]);

  const fundsOptions = useMemo(() => {
    return (fundsData || [])
      .map((item: IFilterOption) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
  }, [fundsData]);

  const unitclassesOptions = useMemo(() => {
    return (unitClasseData || [])
      .map((item: IFilterOption) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
  }, [unitClasseData]);

  const investmentEntityTypesOptions = useMemo(() => {
    return (investmentEntityTypes || []).map((item: IFilterOption) => ({
      label: item.name,
      value: item.id,
    }));
  }, [investmentEntityTypes]);

  const countRowsInCSV = async (csvFile: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = (event: any) => {
          const cvsData = event.target.result;
          const rowData = cvsData.split('\n');
          resolve(rowData.length);
        };

        reader.readAsText(csvFile);
      } catch (error: any) {
        reject(error);
      }
    });
  };

  const handleDownloadTemplate = () => {
    downloadFile(process.env.REACT_APP_BULK_UPLOAD_TEMPLATE || '', '');
  };

  return (
    <>
      <p style={{ margin: 8 }}></p>
      <Box className='w-full p-10'>
        <Box className='header flex items-center justify-between'>
          <Typography variant='h5' fontWeight={700}>
            Bulk Upload
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon color='#000000' />
          </IconButton>
        </Box>
        <Divider sx={{ bgcolor: 'neutral.ne200', mt: 2, mb: 5 }} />
        <FormProvider {...form}>
          <Grid container columnSpacing={3} rowSpacing='18px' mb={4}>
            <Grid item xs={6}>
              <FormSelect
                name='clientId'
                label='Client'
                placeholder='Select client'
                options={clientsOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name='fundId'
                label='Fund'
                placeholder='Select fund'
                options={fundsOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name='unitClassId'
                label='Unit Class'
                placeholder='Select unit class'
                options={unitclassesOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name='investmentEntityType'
                label='Investment Entity Type'
                placeholder='Select investment entity type'
                options={investmentEntityTypesOptions}
              />
            </Grid>
          </Grid>
        </FormProvider>
        <Box>
          <UploadButton
            startIcon={<UploadFilesIcon />}
            label='Select File'
            inputProps={{
              accept: BULK_UPLOAD_FILE_ACCEPT_TYPE,
            }}
            handleFileChange={handleUploadFile}
            sx={{
              px: 2,
            }}
          />
          {form.formState.errors.file && (
            <CustomHelperText variant='error' message={form.formState.errors.file.message} />
          )}
        </Box>
        <Box className='support-format flex items-center gap-1.5 mt-4'>
          <Typography variant='body3' color='neutral.ne500'>
            Supported format: CSV
          </Typography>
          <CustomButton variant='text' sx={{ p: 0.5 }} onClick={handleDownloadTemplate}>
            <Typography variant='body3' color='primary'>
              CSV Template
            </Typography>
          </CustomButton>
        </Box>
        {file && (
          <Box
            className='rounded-xl py-4 px-5 flex items-center justify-between mt-4'
            bgcolor='neutral.ne100'
          >
            <Box className='flex items-center gap-2'>
              <CSVIcon width={32} height={32} />
              <Box>
                <Typography variant='body2'>{file?.name}</Typography>
                <Typography className='mt-1' variant='body3' color='neutral.ne800'>
                  {csvRows + ' rows'}
                </Typography>
              </Box>
            </Box>
            <IconButton sx={{ p: '6px', bgcolor: 'neutral.ne200' }} onClick={handleDeleteFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <Box className='footer flex justify-end items-center mt-11.5'>
          <CustomButton sx={{ color: 'neutral.ne800' }} variant='text' onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton isLoading={isLoading} onClick={form.handleSubmit(onUploadDocument)}>
            Upload
          </CustomButton>
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          className='w-[551px]'
          isError={alertInfo?.isError}
          title={alertInfo.title}
          errorTitle={alertInfo?.isError ? alertInfo.title : undefined}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              !alertInfo?.isError && onClose?.();
            },
          }}
        />
      </BasicModal>
      <BasicModal
        ref={errorDetailRef}
        PaperProps={{
          style: {
            width: 993,
            top: 90,
          },
        }}
        maxWidth='xl'
      >
        <ErrorDetailsPopup
          onClose={() => errorDetailRef.current?.close()}
          errorDetail={uploadError}
        />
      </BasicModal>
    </>
  );
};

export default BulkUploadForm;
