import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import HorizontalTabs, { ITab } from 'src/components/molecules/HorizontalTabs';
import { FINANCIAL_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  IFinancialTaxDetailsForm,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import {
  useGetInvestmentEntityById,
  useGetInvestmentEntityTaxDetails,
} from 'src/modules/investment-entity/hooks';
import FinancialDetails from './FinancialDetails';
import { INVESTMENT_ENTITY_STEP } from './InvestmentEntityForm';
import TaxDetails from './TaxDetails';

export interface IFinancialAndTaxDetailsProps extends IUpsertInvestmentApplicationForm {}

const FinancialAndTaxDetails: FC<IFinancialAndTaxDetailsProps> = (props) => {
  const { id = '' } = props;
  const {
    reset,
    formState: { errors },
  } = useFormContext<IFinancialTaxDetailsForm>();
  const { financialDetails: financialDetailsErrors, ...taxDetailsErrors } = errors || {};
  const { data: investmentEntityFinancial } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.FINANCIAL_DETAILS,
  });
  const { data: investmentEntityTax } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS,
    financialStep: FINANCIAL_STEP.TAX_DETAILS,
  });
  const { data: filterTaxDetails } = useGetInvestmentEntityTaxDetails(id, true);

  const { directors, taxNoTINReasons, foreignControllingPersons } = filterTaxDetails || {};
  const financialDetails = investmentEntityFinancial?.financialDetails;
  const taxDetails = investmentEntityTax?.financialTax;

  useEffect(() => {
    if (!isEmpty(financialDetails) && !isEmpty(taxDetails)) {
      reset({
        ...taxDetails,
        foreignControllingPersons: {
          ...(taxDetails?.foreignControllingPersons || {}),
          directors: (directors || [])?.filter((it) =>
            (taxDetails?.foreignControllingPersons?.directorIds || [])?.includes(it.id),
          ),
        },
        financialDetails,
      });
    }
  }, [financialDetails, taxDetails, directors]);

  const Tabs: ITab[] = [
    {
      label: 'Financial Details',
      content: (
        <FinancialDetails
          {...props}
          keyName='financialDetails.'
          isWithoutFinancialAndTaxDetails={false}
        />
      ),
      isError: !isEmpty(financialDetailsErrors),
    },
    {
      label: 'Tax Details',
      content: <TaxDetails {...props} taxNoTINReasons={taxNoTINReasons} directors={directors} foreignControllingPersons={foreignControllingPersons} />,
      isError: !isEmpty(taxDetailsErrors),
    },
  ];
  return <HorizontalTabs tabs={Tabs} />;
};

export default FinancialAndTaxDetails;
