/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

export const SearchIcon: FC<React.SVGProps<SVGSVGElement>> = ({ color = '#9DA0A1', ...props }) => {
  return (
    <svg
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      color={color}
      {...props}
    >
      <path
        d='M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75Zm0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z'
        fill='currentColor'
      />
    </svg>
  );
};
