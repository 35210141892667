import { AUTH_API, PROFILE_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';

import { portalApi } from 'src/helpers/axios';

import { ROUTES_PATH } from 'src/constants/routesPath';
import * as StorageService from 'src/helpers/storage';
import {
  IChangePasswordBody,
  ILoginBody,
  ILoginResponse,
  IResetPasswordBody,
  IResetPasswordInfoResponse,
  IResetPasswordResponse,
  ISendEmailForgotPasswordBody,
  ISendEmailForgotPasswordResponse,
  ISignUpBody,
  ISignUpInvestorBody,
  ISignUpInvestorResponse,
  ISignUpResendVerifyEmailBody,
  ISignUpResponse,
  ISignUpVerifyEmailBody,
  IUpdateProfileBody,
  IVerifyAuthenticatorBody,
  IVerifyAuthenticatorResponse,
  PortalInfo,
} from './type';

export const login = async (
  data: ILoginBody,
): Promise<ILoginResponse | ISignUpInvestorResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.login.api,
  });

  return result.data.data;
};

export const logout = async (refreshToken: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { refreshToken },
    url: AUTH_API.logout.api,
  });

  return result;
};

export const forceLogout = () => {
  StorageService.destroy();
  localStorage.clear();
  window.location.href = ROUTES_PATH.LOGIN;
};

export const getMfaAuthenticator = async () => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: AUTH_API.mfaAuthenticator.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data;
};

export const verifyMfaAuthenticator = async (
  data: IVerifyAuthenticatorBody,
): Promise<IVerifyAuthenticatorResponse> => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.mfaAuthenticator.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data.data;
};

export const requestSMSAuthenticator = async (): Promise<ILoginResponse> => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: AUTH_API.requestSMSAuthenticator.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data;
};

export const verifySMSAuthenticator = async (
  data: IVerifyAuthenticatorBody,
): Promise<IVerifyAuthenticatorResponse> => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.verificationSMSAuthenticator.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data.data;
};

export const getPhoneNumber = async () => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: AUTH_API.phoneNumber.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data;
};

export const signup = async (data: ISignUpBody): Promise<ISignUpResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.signUp.api,
  });

  return result.data;
};

export const sendEmailForgotPassword = async (
  data: ISendEmailForgotPasswordBody,
): Promise<ISendEmailForgotPasswordResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.forgotPassword.api,
  });

  return result.data;
};

export const resetPassword = async (data: IResetPasswordBody): Promise<IResetPasswordResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.resetPassword.api,
  });

  return result.data;
};

export const getResetPasswordInfo = async (email: string): Promise<IResetPasswordInfoResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: `${AUTH_API.resetPasswordInfo.api}/${email}`,
  });

  return result.data;
};

export const getUserInfo = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: `${PROFILE_API.getProfile.api}`,
  });

  return result.data;
};

export const signupInvestor = async (
  data: ISignUpInvestorBody,
): Promise<ISignUpInvestorResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.investorSignup.api,
  });

  return result.data.data;
};

export const signupVerifyEmail = async (data: ISignUpVerifyEmailBody) => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.investorVerifyEmail.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data;
};

export const signupResendVerifyEmail = async (data: ISignUpResendVerifyEmailBody) => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.investorResendVerifyEmail.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });

  return result.data;
};

export const changePassword = async (data: IChangePasswordBody): Promise<IChangePasswordBody> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUTH_API.changePassword.api,
  });

  return result.data;
};

export const updateProfile = async (data: IUpdateProfileBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: PROFILE_API.updateProfile.api,
  });

  return result.data;
};

export const resetAuthenticator = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: PROFILE_API.resetAuthenticator.api,
  });

  return result;
};

export const getPortalInfo = async (): Promise<PortalInfo> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: `${PROFILE_API.getPortalInfo.api}`,
  });

  return result.data;
};

export const requestRefreshToken = async (
  refreshToken: string,
): Promise<IVerifyAuthenticatorResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { refreshToken },
    url: `${AUTH_API.refreshToken.api}`,
  });

  return result.data;
};
