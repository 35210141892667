import { REQUIRE_MESSAGE } from 'src/constants/common';
import { generateMinimumErrorMessage } from 'src/utils/validation';
import * as Yup from 'yup';
import { YupBase } from './common';

export const submitContactLawyerSchema = Yup.object({
  name: Yup.string().required(REQUIRE_MESSAGE).min(2, generateMinimumErrorMessage(2)),
  email: YupBase.email,
  contactNumber: Yup.string()
    .required(REQUIRE_MESSAGE)
    .test('contactNumber', REQUIRE_MESSAGE, function (value) {
      const [phoneCode, phoneNumber] = value.split(' ');
      return !!phoneNumber;
    }),
  subject: Yup.string().required(REQUIRE_MESSAGE).min(5, generateMinimumErrorMessage(5)),
  message: Yup.string().required(REQUIRE_MESSAGE).min(5, generateMinimumErrorMessage(5)),
});
