import { Box, Button, Menu, Stack, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { ReactNode, useImperativeHandle, useState } from 'react';
import { MoreIcon } from 'src/assets/icons/MoreIcon';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  minWidth: '150px',
  borderRadius: '8px',
  textTransform: 'none',
  justifyContent: 'flex-start',
  padding: '8px',
  paddingLeft: '16px',
  '&:hover': {
    backgroundColor: theme.palette.neutral.ne200,
  },
  '&:disabled': {
    // color: COLORS.prLgt,
  },
}));

export interface MenuItemProps {
  icon: React.ReactNode;
  label: string | React.ReactNode;
  onAction: () => void;
  sx?: SxProps;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, label, onAction, sx }) => {
  return (
    <StyledButton startIcon={icon} onClick={onAction} sx={sx}>
      <Typography
        className='inline-block w-full'
        variant='body3'
        color='neutral.ne800'
        align='left'
      >
        {label}
      </Typography>
    </StyledButton>
  );
};

interface ActionMenusProps {
  menus: MenuItemProps[];
  onOpen?: () => void;
  customIcon?: ReactNode;
}

export type ActionMenusHandler = {
  close: () => void;
};

const ActionMenus = React.forwardRef<ActionMenusHandler, ActionMenusProps>(
  ({ menus = [], onOpen, customIcon = null }, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useImperativeHandle(ref, () => ({
      close: handleCloseActionMenu,
    }));

    const handleClickActionMenus = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isEmpty(menus)) return;
      onOpen?.();
      setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
        {customIcon ? (
          <Box className='cursor-pointer' onClick={handleClickActionMenus as any}>
            {customIcon}
          </Box>
        ) : (
          <Box
            className='flex items-center justify-center w-8 h-8 rounded-lg border cursor-pointer icon-container'
            borderColor='neutral.ne300'
            onClick={handleClickActionMenus as any}
          >
            <MoreIcon />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseActionMenu}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
              minWidth: '150',
              padding: '0px',
            },
          }}
          BackdropProps={{ style: { backgroundColor: 'transparent' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack style={{ marginTop: '-5px' }}>
            {(menus || []).map(({ icon, label, onAction, sx }, index) => (
              <MenuItem
                key={index}
                icon={icon}
                label={label}
                onAction={() => {
                  onAction();
                  handleCloseActionMenu();
                }}
                sx={sx}
              />
            ))}
          </Stack>
        </Menu>
      </Box>
    );
  },
);

export default ActionMenus;
