import * as React from 'react';

interface IBusinessIconProps {}

const BusinessIcon: React.FunctionComponent<IBusinessIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_00'
          transform='translate(-613.000000, -583.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-4' transform='translate(214.000000, 270.000000)'>
                <g id='Business-Activities' transform='translate(101.000000, 40.000000)'>
                  <g id='company-(1)-copy' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                    <g id='cooperation' mask='url(#mask-2)'>
                      <g transform='translate(4.000000, 6.000000)' id='Group'>
                        <g transform='translate(5.901353, 23.963436)'>
                          <g fillRule='nonzero' id='Path'>
                            <path
                              d='M27.9588535,11.6418396 L18.8263073,6.2436787 C18.2681528,5.91350359 18.0838114,5.19421599 18.4145097,4.63709603 C18.4145097,4.63709603 18.6834608,4.18401492 18.9713141,3.69885966 L16.7692994,2.39864356 C15.3167114,1.56660228 14.6374028,1.41270025 13.3269413,1.91842969 L11.8986561,2.46953013 C11.0295156,2.80491617 10.0542528,2.72396711 9.25253008,2.24986261 L5.42969796,0 L0,9.14796185 L4.89512613,12.0318505 C5.22672448,12.2278892 5.51790816,12.4851113 5.75328538,12.7897708 L7.40281615,14.9250829 C8.31390152,15.9887409 10.194219,17.3683787 11.4004485,18.0816468 L15.1717046,20.3117438 C15.779725,20.6712978 16.5467937,20.6204463 17.1017979,20.1837167 L27.9588535,11.6418396 Z'
                              fill='#FFDECF'
                            />
                            <path
                              d='M29.7103661,12.677107 L25.7257852,10.3217681 C25.6763695,10.2925689 25.6125521,10.3079321 25.5820386,10.3565375 C25.5607062,10.3904085 25.5612462,10.4329046 25.5816786,10.4673146 C25.9238982,11.0424033 25.9346094,11.7881049 25.5275823,12.3908654 C25.0159632,13.1485162 23.9813835,13.3469807 23.1938824,12.8813214 L22.5979235,12.528955 C22.4742492,12.4558223 22.3144807,12.4972402 22.2421123,12.6213142 C22.2012476,12.6913921 22.195757,12.7768334 22.2275307,12.8514934 C22.4248335,13.3155354 22.3820785,13.9626786 22.1599328,14.4553807 C21.841206,15.1624496 21.0091498,15.6476049 20.1193068,15.1214809 L19.8605269,14.9684774 C19.6832064,14.8636299 19.4589905,14.8808799 19.2999421,15.0116921 C19.1335128,15.0116921 19.0426023,15.1399887 19.0885976,15.2996407 C19.2232532,15.7673664 19.1686169,16.2883692 18.892465,16.737587 C18.373825,17.5813978 17.2522053,17.8252333 16.3989066,17.3206718 L15.6967354,16.9054148 C15.5623498,16.8259033 15.3896198,16.8585165 15.2935787,16.9815123 C15.235072,17.0564419 15.2140095,17.154012 15.2377723,17.2460118 C15.3527156,17.6922648 15.2976292,18.1820021 15.0437098,18.6097473 C14.7548664,19.0964299 14.2719606,19.3966871 13.7517004,19.4722455 L17.6600424,21.7833815 C18.4759868,22.2658414 19.5467506,22.0434786 20.0587298,21.2467458 C20.596272,20.4103022 20.328131,19.3030701 19.4788828,18.8009344 L21.4096961,19.9427563 C22.2256404,20.4252162 23.2964043,20.2028534 23.8084735,19.4061207 C24.3460157,18.5696771 24.0778747,17.4624449 23.2287165,16.9603092 L24.5399881,17.7357491 C25.3709642,18.2271934 26.4437983,17.9530807 26.9360652,17.1236448 C27.4283321,16.294209 27.1538004,15.2233636 26.3228243,14.7320091 L27.8915257,15.6596439 C28.7073801,16.1421039 29.7780539,15.919741 30.2900331,15.1230083 C30.8275753,14.286385 30.5595243,13.1792427 29.7103661,12.677107 L29.7103661,12.677107 Z'
                              fill='#FFCDBE'
                            />
                          </g>
                          <path
                            d='M28.767687,0.546338732 L25.8315475,2.24977277 C25.0298248,2.72387727 24.054562,2.80482632 23.1853314,2.46944029 L21.5745051,1.83784001 C21.0707169,1.64027401 20.5175129,1.60532486 19.9927522,1.73784412 L17.5247568,2.36126863 C16.3758639,2.65146335 15.5710808,3.68340657 15.5710808,4.86628697 L15.5710808,8.15465137 C15.5710808,8.61959183 15.9485845,8.99774341 16.4143884,8.99810278 C17.8190908,8.99900122 18.9580825,7.86265978 18.9580825,6.4608306 L18.9580825,6.32157307 L29.5218843,12.5657908 C29.7175669,12.3577131 29.9419629,12.1777565 30.1887714,12.0318505 L34.197115,9.69430058 L28.767687,0.546338732 Z'
                            id='Path'
                            fill='#F2AD97'
                            fillRule='nonzero'
                          />
                          <g
                            transform='translate(2.881259, 11.206124)'
                            fill='#F2AD97'
                            fillRule='nonzero'
                            id='Path'
                          >
                            <path d='M4.15917495,2.76302304 L3.16879041,4.13996555 C2.60568529,4.92295224 1.51322894,5.10192063 0.728878228,4.53985927 C-0.055562494,3.97779791 -0.234863231,2.88736654 0.328241894,2.10446969 L1.31862643,0.727527184 C1.88173156,-0.0554595037 2.9741879,-0.23442789 3.75853862,0.327633467 C4.54297934,0.889784666 4.72228007,1.9801262 4.15917495,2.76302304 L4.15917495,2.76302304 Z' />
                            <path d='M8.09280988,3.2789048 L6.00933893,6.17555125 C5.4462338,6.95853794 4.35377746,7.13750633 3.56942674,6.57544497 C2.78498602,6.01338362 2.60568529,4.92295224 3.16879041,4.1400554 L5.25217136,1.24340895 C5.81527648,0.460422259 6.90773283,0.281453873 7.69208354,0.843515229 C8.47652426,1.40557659 8.65591501,2.49591812 8.09280988,3.2789048 L8.09280988,3.2789048 Z' />
                            <path d='M10.9333584,5.31440066 L8.84997745,8.21104711 C8.28687233,8.9940338 7.19441598,9.17300219 6.41006527,8.61094083 C5.62562455,8.04887947 5.44632381,6.9584481 6.00942894,6.17555125 L8.09280988,3.2789048 C8.65591501,2.49591812 9.74837135,2.31694973 10.5327221,2.87901109 C11.3170728,3.44107244 11.4964635,4.53150382 10.9333584,5.31440066 Z' />
                            <path d='M12.8481498,8.63699546 L12.1553397,9.60020835 C11.5922346,10.383195 10.4997783,10.5621634 9.71542754,10.0001021 C8.93098682,9.43804071 8.75168608,8.34760933 9.31479121,7.56471249 L10.0076013,6.60149961 C10.5707065,5.81851292 11.6631628,5.63954453 12.4475135,6.20160589 C13.2319542,6.76366724 13.411345,7.85409862 12.8481498,8.63699546 Z' />
                          </g>
                        </g>
                        <g transform='translate(0.000000, 16.200972)'>
                          <g
                            transform='translate(31.636527, 2.914954)'
                            fillRule='nonzero'
                            id='Path'
                          >
                            <path
                              d='M5.62490229,14.8959736 C7.70009229,13.6613433 8.97121929,11.4278322 8.97121929,9.01643087 L8.97121929,0.80293196 L8.46212042,0 L0.721675217,4.60529141 C0.0216642595,5.02180619 -0.208672379,5.92518325 0.206635652,6.6249748 L5.24766866,15.1182466 L5.62490229,14.8959736 Z'
                              fill='#3C6D91'
                            />
                            <path
                              d='M6.87379672,16.9291055 C7.07208966,16.9943905 7.27902359,16.9952514 7.481457,16.9262358 C7.29279518,16.938432 7.08784148,16.9391494 6.87379672,16.9291055 Z'
                              fill='#407093'
                            />
                          </g>
                          <path
                            d='M40.0986475,2.91495359 L40.0986475,11.9313845 C40.0986475,14.3427858 38.8274305,16.576207 36.7523305,17.8109272 L37.5368612,19.1327956 C37.9537894,19.8352824 38.8630746,20.0662703 39.5657859,19.6482281 L45.0022346,16.41368 C45.6209662,16.0455011 46,15.3795806 46,14.6605625 L46,0.787008705 C46,0.177240415 45.334373,-0.200192411 44.8096123,0.112013985 L40.0986475,2.91495359 Z'
                            id='Path'
                            fill='#365E7D'
                            fillRule='nonzero'
                          />
                          <path
                            d='M1.19029766,0.112013985 L13.6417077,7.52015516 C14.3417187,7.93666994 14.5720553,8.840047 14.1566572,9.53983855 L8.46313878,19.1327057 C8.04621056,19.8351926 7.1369254,20.0661804 6.43421413,19.6481383 L0.997765394,16.4135902 C0.379033836,16.0454112 0,15.3794907 0,14.6604727 L0,0.787008705 C0,0.177240415 0.665536971,-0.200192411 1.19029766,0.112013985 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          />
                        </g>
                        <g transform='translate(13.123788, 0.000000)'>
                          <g fillRule='nonzero' id='Path'>
                            <path
                              d='M13.3083091,5.34632115 C12.9248648,5.34632115 12.6141489,5.03618116 12.6141489,4.65344756 L12.6141489,2.2884055 C12.6141489,1.79067214 12.208652,1.38574718 11.7101743,1.38574718 L8.04234011,1.38574718 C7.54386246,1.38574718 7.13836557,1.79067214 7.13836557,2.2884055 L7.13836557,4.65344756 C7.13836557,5.03618116 6.82755962,5.34632115 6.44420529,5.34632115 C6.06085095,5.34632115 5.75004501,5.03618116 5.75004501,4.65344756 L5.75004501,2.2884055 C5.74995499,1.02664244 6.77832393,0 8.0422501,0 L11.7100843,0 C12.9740105,0 14.0023794,1.02664244 14.0023794,2.2884055 L14.0023794,4.65344756 C14.0024694,5.03618116 13.6916635,5.34632115 13.3083091,5.34632115 Z'
                              fill='#3C6D91'
                            />
                            <g transform='translate(0.000000, 4.079078)'>
                              <path
                                d='M3.47728216,5.51729346 C1.55682005,5.51729346 0,3.96335912 0,2.04645677 L0,11.3666487 C0,12.4244669 0.859149362,13.2820238 1.91893193,13.2820238 L12.5100968,13.2820238 C13.5698794,13.2820238 14.4290287,12.4244669 14.4290287,11.3666487 L14.4290287,5.51729346 L3.47728216,5.51729346 Z'
                                fill='#3C6D91'
                              />
                              <g transform='translate(0.000090, 0.000000)'>
                                <path
                                  d='M16.2750522,5.51729346 L13.8967972,5.51729346 L13.8967972,11.3666487 C13.8967972,12.4244669 13.0376478,13.2820238 11.9778653,13.2820238 L17.8333125,13.2820238 C18.893095,13.2820238 19.7522444,12.4244669 19.7522444,11.3666487 L19.7522444,2.04645677 C19.7523344,3.96326928 18.1955144,5.51729346 16.2750522,5.51729346 Z'
                                  fill='#365E7D'
                                />
                                <path
                                  d='M12.5154974,8.98435683e-05 L1.91893193,8.98435683e-05 C0.859149362,8.98435683e-05 0,0.857556849 0,1.91537501 L0,2.7352874 C0,4.65218975 1.55682005,6.20612409 3.47728216,6.20612409 L14.4345194,6.20612409 L14.4345194,1.91546485 C14.4345194,0.857556849 13.57537,8.98435683e-05 12.5154974,8.98435683e-05 L12.5154974,8.98435683e-05 Z'
                                  fill='#315B7B'
                                />
                                <path
                                  d='M13.8967972,6.20603425 L16.2750522,6.20603425 C18.1955144,6.20603425 19.7523344,4.65209991 19.7523344,2.73519756 L19.7523344,1.91528516 C19.7522444,0.857467005 18.893185,0 17.8334025,0 L11.9778653,0 C13.0376478,8.98435672e-05 13.8967972,0.857556849 13.8967972,1.91546485 L13.8967972,6.20603425 Z'
                                  fill='#2E485D'
                                />
                              </g>
                            </g>
                          </g>
                          <g
                            transform='translate(4.020585, 8.137222)'
                            fill='#F4F4F4'
                            fillRule='nonzero'
                            id='Path'
                          >
                            <path d='M0.694160281,4.29578032 C0.310805945,4.29578032 0,3.98564033 0,3.60290673 L0,0.69287359 C0,0.310139994 0.310805945,0 0.694160281,0 C1.07751462,0 1.38832056,0.310139994 1.38832056,0.69287359 L1.38832056,3.60299657 C1.38832056,3.98564033 1.07751462,4.29578032 0.694160281,4.29578032 L0.694160281,4.29578032 Z' />
                            <path d='M11.0170942,4.29578032 C10.6336498,4.29578032 10.3229339,3.98564033 10.3229339,3.60290673 L10.3229339,0.69287359 C10.3229339,0.310139994 10.6336498,0 11.0170942,0 C11.4005385,0 11.7112544,0.310139994 11.7112544,0.69287359 L11.7112544,3.60299657 C11.7112544,3.98564033 11.4005385,4.29578032 11.0170942,4.29578032 L11.0170942,4.29578032 Z' />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BusinessIcon;
