import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorImage } from 'src/assets/images/ErrorImage';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { IBulkUploadValidation, ICreateBulkUploadResponse } from 'src/modules/bulk-upload/type';

interface IErrorDetailsPopupProps {
  errorDetail?: ICreateBulkUploadResponse;
  onClose: () => void;
}

const ErrorDetailsPopup: FC<IErrorDetailsPopupProps> = ({ errorDetail = {}, onClose }) => {
  const columns: ColumnProps<IBulkUploadValidation>[] = [
    {
      title: 'Row Number',
      key: 'rowName',
      sx: { minWidth: 180, width: '26%' },
      renderNode: (row) => <StringNodeTable value={row.rowName} variant='body3' />,
    },
    {
      title: 'Error Message',
      key: 'summary',
      sx: { minWidth: 180 },
      renderNode: (row) => (
        <StringNodeTable
          className='line-clamp-1'
          value={row?.summary || ''}
          variant='body3'
          color='base.black'
        />
      ),
    },
  ];

  return (
    <Box className='py-10 flex flex-col items-center'>
      <Box className='header flex flex-col items-center'>
        <ErrorImage />
        <Typography className='my-2' variant='h5' fontWeight={700}>
          Upload Failed
        </Typography>
        <Typography variant='body2' fontWeight={500}>
          {`(${errorDetail?.totalRows || 0} row detected)`}
        </Typography>
      </Box>
      <Box className='error-details w-[728px] mx-auto mt-5 mb-12'>
        <Typography className='mb-3.5' variant='body2' color='neutral.ne800'>
          Error Details:
        </Typography>
        {errorDetail?.validations?.length ? (
          <CustomTableContainer
            sx={{
              '.render-node-container': {
                m: 0,
              },
              '.MuiTableCell-head': {
                py: '14px',
                borderColor: 'neutral.ne200',
              },
              '.MuiTableCell-body': {
                borderColor: 'neutral.ne200',
              },
              '.MuiTableContainer-root': {
                borderColor: 'neutral.ne200',
              },
            }}
          >
            <CustomTable
              columns={columns}
              rows={errorDetail?.validations || []}
              totalResults={0}
              currentPage={0}
              numberItemOnPage={0}
              hasPagination={false}
              stickyHeader
            />
          </CustomTableContainer>
        ) : (
          <EmptyData />
        )}
      </Box>
      <CustomButton className='w-[127px]' onClick={onClose}>
        OK
      </CustomButton>
    </Box>
  );
};

export default ErrorDetailsPopup;
