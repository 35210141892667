import { Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { InfoBox } from './InfoBox';
import { filterAuthorisationServers, getManualAuthorisationServer } from './certification';
import { claimsDescription, claimsOrder } from './const';
import { CidBodyProps, Claim, Participant } from './types';

import clsx from 'clsx';
import './Popup.css';

export const CidBody: React.FC<CidBodyProps> = (props) => {
  const {
    onProceed,
    claims,
    showAllParticipants,
    participantsUri,
    certificationStatus,
    enableManualVerification,
    requiredCertifications,
    selectBankLoading = false,
  } = props;
  const [showClaims, setShowClaims] = React.useState(false);
  const {
    data: participants,
    isLoading,
    error,
  } = useQuery<Participant[]>({
    queryFn: async () => {
      const { data: resData } = await axios.get(participantsUri);
      return resData;
    },
  });
  const sortClaims = (claims: Claim[]) => {
    const sortedClaims = [...claims];
    return sortedClaims.sort((claim1, claim2) => claimsOrder[claim1] - claimsOrder[claim2]);
  };

  const renderAuthorisationServers = () => {
    if (isLoading) {
      return (
        <div className='cid-idp-selector-popup-provider-wrapper'>
          <div className='cid-idp-selector-popup-skeleton-container'>
            <Skeleton height='160px' />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className='cid-idp-selector-popup-provider-wrapper'>
          <h3 className='cid-idp-selector-popup-subtitle'>Choose a provider</h3>
          <div className='cid-idp-selector-popup-info-box-error-wrapper'>
            <InfoBox type='warning'>
              <h4 className='cid-idp-selector-popup-info-box-title'>
                We were not able to load Identity Providers.
              </h4>
              <p className='cid-idp-selector-popup-info-box-text'>Come back to us later.</p>
            </InfoBox>
          </div>
        </div>
      );
    }

    const authorisationServers = participants!
      .map((participant) => participant.AuthorisationServers)
      .flat()
      .sort((authServer1, authServer2) =>
        authServer1.CustomerFriendlyName?.localeCompare(authServer2.CustomerFriendlyName),
      );

    const manualAuthorisationServer = getManualAuthorisationServer(
      authorisationServers,
      showAllParticipants,
    );
    const authorisationServersWithoutManual = authorisationServers.filter(
      ({ AuthorisationServerId }) =>
        AuthorisationServerId !== manualAuthorisationServer?.AuthorisationServerId,
    );

    const filteredAuthorisationServers = filterAuthorisationServers(
      authorisationServersWithoutManual,
      showAllParticipants,
      certificationStatus,
      claims,
      requiredCertifications,
    );

    const hasOnlyManualAuthorisationServer =
      manualAuthorisationServer && filteredAuthorisationServers.length === 0;

    // if there's only one IDP and it's a manual one (speficic situation for launch - might remove later)
    if (enableManualVerification && hasOnlyManualAuthorisationServer) {
      return (
        <div className='cid-idp-selector-popup-provider-wrapper'>
          <div className='cid-idp-selector-popup-manualonly'>
            <div className='cid-idp-selector-popup-manualonly-info'>
              {/* card icon */}
              <svg
                className='cid-idp-selector-popup-manualonly-icon'
                width='42'
                height='42'
                viewBox='0 0 42 42'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_401_12266)'>
                  <mask
                    id='mask0_401_12266'
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='42'
                    height='42'
                  >
                    <path d='M42 0H0V42H42V0Z' fill='white' />
                  </mask>
                  <g mask='url(#mask0_401_12266)'>
                    <path
                      d='M30 0H12C5.37258 0 0 5.37258 0 12V30C0 36.6274 5.37258 42 12 42H30C36.6274 42 42 36.6274 42 30V12C42 5.37258 36.6274 0 30 0Z'
                      fill='#EFFBF4'
                    />
                    <path
                      d='M35 9H12C10.3431 9 9 10.3431 9 12V28C9 29.6569 10.3431 31 12 31H35C36.6569 31 38 29.6569 38 28V12C38 10.3431 36.6569 9 35 9Z'
                      fill='#33b27f'
                    />
                    <path
                      d='M31.9996 11.8496H8.99961C7.25991 11.8496 5.84961 13.2599 5.84961 14.9996V27.9996C5.84961 29.7393 7.25991 31.1496 8.99961 31.1496H31.9996C33.7393 31.1496 35.1496 29.7393 35.1496 27.9996V14.9996C35.1496 13.2599 33.7393 11.8496 31.9996 11.8496Z'
                      fill='#F9FDFB'
                      stroke='#262626'
                      strokeWidth='1.7'
                    />
                    <path
                      d='M30.5 18H23'
                      stroke='#33b27f'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                    />
                    <path
                      d='M30.5 21H23'
                      stroke='#33b27f'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                    />
                    <path
                      d='M30.5 24H23'
                      stroke='#33b27f'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                    />
                    <path
                      d='M15 21C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17C13.8954 17 13 17.8954 13 19C13 20.1046 13.8954 21 15 21Z'
                      stroke='#262626'
                      strokeWidth='1.2'
                    />
                    <path
                      d='M19 26C19 24.9391 18.5786 23.9217 17.8284 23.1716C17.0783 22.4214 16.0609 22 15 22C13.9391 22 12.9217 22.4214 12.1716 23.1716C11.4214 23.9217 11 24.9391 11 26H19Z'
                      stroke='#262626'
                      strokeWidth='1.2'
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id='clip0_401_12266'>
                    <rect width='42' height='42' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <div>
                <h3 className='cid-idp-selector-popup-manualonly-title'>
                  You can verify your details with ConnectID using your own identity document.
                </h3>
                <p className='cid-idp-selector-popup-manualonly-description'>
                  ConnectID does not collect, hold, or store any personal information at any time.
                </p>
              </div>
            </div>
            <button
              className='cid-idp-selector-popup-manualonly-button'
              onClick={() => onProceed(manualAuthorisationServer.AuthorisationServerId)}
              tabIndex={1}
            >
              Confirm your identity with a document
            </button>
          </div>
        </div>
      );
    }

    // there a risk here when there's only manual IDP and manual verification is not enabled -> no providers available
    return (
      <>
        <div className='cid-idp-selector-popup-provider-wrapper'>
          <Typography variant='h6'>Choose a provider</Typography>
          {filteredAuthorisationServers.length > 0 ? (
            <div className='cid-idp-selector-popup-participants'>
              {filteredAuthorisationServers.map(
                ({ AuthorisationServerId, CustomerFriendlyLogoUri, CustomerFriendlyName }) => (
                  <div
                    data-testid='participant'
                    className={clsx('cid-idp-selector-popup-participant', {
                      'cursor-progress': selectBankLoading,
                    })}
                    tabIndex={1}
                    onClick={() => onProceed(AuthorisationServerId, CustomerFriendlyName)}
                    key={AuthorisationServerId}
                  >
                    <img
                      className='cid-idp-selector-popup-participant-icon'
                      src={CustomerFriendlyLogoUri}
                      alt={`${CustomerFriendlyName} logo`}
                    />
                    <div
                      className='cid-idp-selector-popup-participant-name'
                      title={CustomerFriendlyName}
                    >
                      {CustomerFriendlyName}
                    </div>
                  </div>
                ),
              )}
            </div>
          ) : (
            <div className='cid-idp-selector-popup-info-box-error-wrapper'>
              <InfoBox type='warning'>
                <h4 className='cid-idp-selector-popup-info-box-title'>
                  No Identity Providers available.
                </h4>
                <p className='cid-idp-selector-popup-info-box-text'>Come back to us later.</p>
              </InfoBox>
            </div>
          )}
        </div>
        {enableManualVerification && manualAuthorisationServer && (
          <>
            <p className='cid-idp-selector-popup-manual'>
              <span className='cid-idp-selector-popup-manual-title'>
                Can&apos;t see a provider that works for you?
              </span>
              <span
                className='cid-idp-selector-popup-manual-action'
                tabIndex={1}
                onClick={() => onProceed(manualAuthorisationServer!.AuthorisationServerId)}
                data-testid='manual-verification-action'
              >
                Confirm your identity with a document
              </span>
              <span className='cid-idp-selector-popup-manual-description'>
                ConnectID does not collect, hold or store your personal information at any time.
              </span>
            </p>
          </>
        )}
      </>
    );
  };
  return (
    <>
      {claims && (
        <div
          className='cid-idp-selector-popup-claims-wrapper'
          onClick={() => setShowClaims(!showClaims)}
        >
          <div
            className={`cid-idp-selector-popup-title-container ${
              showClaims ? 'cid-idp-selector-popup-title-container--open' : ''
            }`}
          >
            <Typography variant='h6'>Details requested</Typography>
            <svg
              className={`cid-idp-selector-popup-claims-toggle ${
                showClaims ? 'cid-idp-selector-popup-claims-toggle--open' : ''
              }`}
              xmlns='http://www.w3.org/2000/svg'
              width='10'
              height='10'
              viewBox='0 0 24 24'
            >
              <path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z' />
            </svg>
          </div>
          <div
            className={`cid-idp-selector-popup-claims ${
              showClaims ? 'cid-idp-selector-popup-claims--visible' : ''
            }`}
            data-testid='claims'
          >
            {sortClaims(claims).map((claim) => (
              <div className='cid-idp-selector-popup-claim' key={claim}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                    fill='#33b27f'
                  />
                  <path
                    d='M4.81445 8.14121L6.95437 10.2811L11.472 5.76465'
                    stroke='white'
                    strokeWidth='1.7'
                    strokeLinecap='round'
                  />
                </svg>

                {claimsDescription[claim]}
              </div>
            ))}
          </div>
        </div>
      )}
      {renderAuthorisationServers()}
      <div style={{ margin: '30px 0' }}>
        <InfoBox>
          <h4 className='cid-idp-selector-popup-info-box-title font-bold'>What is ConnectID?</h4>
          <p className='cid-idp-selector-popup-info-box-text'>
            ConnectID is an Australian-owned digital identity solution which allows you to securely
            prove who you are. ConnectID does not see or store your personal data. Visit{' '}
            <a
              className='cid-idp-selector-popup-info-box-link-underline'
              href='//www.connectid.com.au'
              target='_blank'
              tabIndex={1}
              rel='noreferrer'
            >
              connectid.com.au
            </a>{' '}
            to find out more.
          </p>
        </InfoBox>
      </div>
    </>
  );
};
