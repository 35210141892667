import { FC, SVGProps } from 'react';

const PngIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='15.2px'
      height='20px'
      viewBox='0 0 15.2 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_07'
          transform='translate(-528.400000, -1162.000000)'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(220.000000, 544.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Illustration/File/Doc'
                  transform='translate(16.000000, 351.000000)'
                >
                  <g id='doc' transform='translate(2.400000, 0.000000)'>
                    <path
                      d='M2.64382324,0 L9.44382527,0 L15.2,5.90995813 L15.2,17.3982316 C15.2,18.8352829 14.0164308,20 12.5561768,20 L2.64382324,20 C1.18354352,20 0,18.8352576 0,17.3982316 L0,2.60176839 C0,1.16474236 1.18354352,0 2.64382324,0 L2.64382324,0 Z'
                      id='Path'
                      fill='#00AEEF'
                    />
                    <g id='Group' transform='translate(2.400000, 0.000000)' fill='#FFFFFF'>
                      <polygon
                        id='Path'
                        opacity='0.302'
                        points='7.03560818 0 7.03560818 5.86141456 12.8 5.86141456'
                      />
                      <g transform='translate(0.000000, 10.800000)'>
                        <path
                          d='M1.42382166,0.0268862774 C2.25941164,0.0268862774 2.74024909,0.421238086 2.74024909,1.12039974 C2.74024909,1.85544925 2.25941164,2.28118812 1.42382166,2.28118812 L0.830963587,2.28118812 L0.830963587,3.16411215 L0,3.16411215 L0,0.0268862774 L1.42382166,0.0268862774 L1.42382166,0.0268862774 Z M0.830963587,1.65374294 L1.38181392,1.65374294 C1.74588096,1.65374294 1.95598132,1.47892292 1.95598132,1.14728602 C1.95598132,0.820090682 1.74588096,0.654331452 1.38181392,0.654331452 L0.830963587,0.654331452 L0.830963587,1.65374294 Z'
                          id='Shape'
                        />
                        <polygon
                          id='Path'
                          points='5.57419824 3.16411215 4.07098752 1.23244564 4.07098752 3.16411215 3.29146953 3.16411215 3.29146953 0.0268862774 4.02441507 0.0268862774 5.53231388 1.96299436 5.53231388 0.0268862774 6.30726715 0.0268862774 6.30726715 3.16411215'
                        />
                        <path
                          d='M9.96743013,1.56858333 L9.96743013,2.79208661 C9.61736566,3.02962154 9.06645365,3.2 8.60893327,3.2 C7.61456515,3.2 6.86292894,2.5097807 6.86292894,1.5954696 C6.86292894,0.685718516 7.63319413,0 8.6648819,0 C9.15034575,0 9.66393812,0.17926159 10,0.466068289 L9.53316514,1.00835384 C9.29505962,0.797705191 8.96362414,0.667774591 8.66019382,0.667774591 C8.12334606,0.667774591 7.7125832,1.07112797 7.7125832,1.5954696 C7.7125832,2.12881281 8.12803415,2.53216619 8.66956999,2.53216619 C8.83766262,2.53216619 9.0523277,2.47389285 9.24842548,2.37979088 L9.24842548,1.56858333 L9.96743013,1.56858333 Z'
                          id='Path'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PngIcon;
