import { ROUTES_PATH } from './routesPath';

export const notificationFeatureName = {
  ClientManagement: 'Client Management',
  KYCBackOffice: 'KYC Back Office',
  Communications: 'Communications',
  UserManagement: 'User Management',
};

export enum NOTIFICATION_STATE {
  NEW,
  READ,
}

export enum NOTIFICATION_TYPE {
  FundraisingOffers,
  FundraisingApplications,
  FundraisingCapitalCalls,
  OrganizationSettings,
  Investors,
  Funds,
  Applications,
  InvestorForms,
  InvestmentEntity,
  ClientManagement,
  KYCBackOffice,
  Documents,
  Communications,
  UserManagement,
  FundRegistry,
}

export enum NOTIFICATION_POPUP_TYPE {
  DuplicateApplication,
}

export const NOTIFICATION_REDIRECT = {
  path: (isFundPortal: boolean) => ({
    [NOTIFICATION_TYPE.FundraisingOffers]: ROUTES_PATH.FUNDRAISING_OFFERS,
    [NOTIFICATION_TYPE.FundraisingApplications]: ROUTES_PATH.FUNDRAISING_APPLICATIONS,
    [NOTIFICATION_TYPE.FundraisingCapitalCalls]: ROUTES_PATH.FUNDRAISING_CAPITAL_CALLS,
    [NOTIFICATION_TYPE.OrganizationSettings]: ROUTES_PATH.ORGINISATION_SETTINGS,
    [NOTIFICATION_TYPE.Investors]: ROUTES_PATH.ADMINISTRATION_INVESTORS,
    [NOTIFICATION_TYPE.Funds]: ROUTES_PATH.FUNDS,
    [NOTIFICATION_TYPE.Applications]: ROUTES_PATH.APPLICATIONS,
    [NOTIFICATION_TYPE.InvestorForms]: ROUTES_PATH.INVESTOR_FORMS,
    [NOTIFICATION_TYPE.InvestmentEntity]: ROUTES_PATH.INVESTMENT_ENTITY,
    [NOTIFICATION_TYPE.ClientManagement]: ROUTES_PATH.CLIENT_MANAGEMENT,
    [NOTIFICATION_TYPE.KYCBackOffice]: isFundPortal
      ? ROUTES_PATH.FUNDRAISING_KYC_BACK_OFFICE
      : ROUTES_PATH.KYC_BACK_OFFICE,
    [NOTIFICATION_TYPE.Documents]: ROUTES_PATH.DOCUMENTS,
    [NOTIFICATION_TYPE.Communications]: ROUTES_PATH.COMMUNICATIONS,
    [NOTIFICATION_TYPE.UserManagement]: ROUTES_PATH.USER_MANAGEMENT,
    [NOTIFICATION_TYPE.FundRegistry]: ROUTES_PATH.FUND_REGISTRY,
  }),
};
