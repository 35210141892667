import { Link, SxProps } from '@mui/material';
import { FC } from 'react';

interface IDownloadLocalButtonProps {
  children: string | React.ReactNode;
  downloadUrl: string;
  fileName: string;
  onClick?: () => void;
  sx?: SxProps;
}

const DownloadLocalButton: FC<IDownloadLocalButtonProps> = ({
  children,
  downloadUrl,
  fileName,
  onClick,
  sx = {},
}) => {
  if (!downloadUrl) return <>{children}</>;
  return (
    <Link
      component='a'
      download={fileName}
      target='_blank'
      href={downloadUrl}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      underline='none'
      variant='body3'
      color='neutral.ne800'
      display='inline-block'
      sx={sx}
    >
      {children}
    </Link>
  );
};

export default DownloadLocalButton;
