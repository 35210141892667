import { TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { SearchIcon } from 'src/assets/icons/SearchIcon';

const SearchField: FC<TextFieldProps> = ({ className = '', sx = {}, onChange, ...rest }) => {
  const [value, setValue] = useState('');

  return (
    <TextField
      {...rest}
      className={clsx('h-[44px]', className)}
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        ...sx,
      }}
      InputProps={{
        startAdornment: <SearchIcon className='mr-1.5' color='#212121' />,
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange && onChange(e);
      }}
      fullWidth
    />
  );
};

export default SearchField;
