/* eslint-disable no-extra-boolean-cast */
import { Box, Grid } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CurrencyName from 'src/components/atoms/CurrencyName';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import { FundOfferType } from 'src/constants/funds-management';
import {
  RedemptionAllowedOptions,
  RedemptionOptionsType,
  UnitClassOfferType,
  PartiallyPaidUnitsOptions as YesNoRadioOptions,
  incrementInvestmentAmountOptions,
  investorTypeTooltip,
  unitClassForeignInvestorTypeOptions,
  unitClassInvestorTypeOptions,
  unitClassOfferOptions,
} from 'src/constants/unit-class';
import { IInvestmentFormFields } from 'src/modules/unit-class/type';

interface IInvestmentDetailFormProps {
  currencyName?: string;
  isTrust: boolean;
  isViewMode: boolean;
  fundOfferType?: number;
}

const InvestmentDetailForm: FC<IInvestmentDetailFormProps> = ({
  currencyName,
  isTrust,
  isViewMode,
  fundOfferType,
}) => {
  const { watch, setValue } = useFormContext<IInvestmentFormFields>();
  const watchOfferType = watch('offerType');
  const watchAllowForeignInvestors = watch('allowForeignInvestors');
  const watchAcceptInvestmentAmountUnderMinimum = watch('acceptInvestmentAmountUnderMinimum');
  const watchRedemptionAllowedType = watch('redemptionAllowedType');
  const watchUnlimitedInvestmentAmount = watch('unlimitedInvestmentAmount');

  useEffect(() => {
    handleSetInvestorTypes();
  }, [watchOfferType]);

  const handleSetInvestorTypes = () => {
    if (watchOfferType === UnitClassOfferType.Retail) {
      setValue(
        'investorTypes',
        (unitClassForeignInvestorTypeOptions || []).map((item) => item.value),
      );
    }
  };

  const getInvestorTypeTooltip = () => {
    return (
      <Box component='ul'>
        {(Object.entries(investorTypeTooltip) || []).map(([_, value]) => (
          <Box key={value} component='li'>
            {value}
          </Box>
        ))}
      </Box>
    );
  };

  const handleUnlimitedInvestmentAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    if (checked) {
      setValue('maximumInvestmentAmount', null);
    }
  };

  /**
   * 1. Offer type in Fund Flow is Wholesale -> only allow option Wholesale
   * 2. Offer type in Fund Flow is Retail -> only allow option Retail
   * 3. Offer type in Fund Flow is Hybrid -> allow both options
   *
   */
  const offerTypeOptions = useMemo(() => {
    switch (fundOfferType) {
      case FundOfferType.Wholesale:
        return unitClassOfferOptions.filter((f) => f.id === UnitClassOfferType.Wholesale);
      case FundOfferType.Retail:
        return unitClassOfferOptions.filter((f) => f.id === UnitClassOfferType.Retail);
      case FundOfferType.Hybrid:
        return unitClassOfferOptions;
      default:
        return unitClassOfferOptions;
    }
  }, [fundOfferType]);

  return (
    <Box>
      <Grid container columnSpacing={3} rowSpacing={4}>
        <Grid item xs={12} md={6}>
          <FormSelect
            name='offerType'
            label='Offer Type'
            placeholder='Select offer type'
            options={offerTypeOptions}
            readOnly={isViewMode}
          />
        </Grid>
        {watchOfferType === UnitClassOfferType.Wholesale && (
          <Grid item xs={12} md={6}>
            <FormMultiSelect
              name='investorTypes'
              placeholder='Select investor type'
              label={
                <LabelWithTooltip
                  label='Investor Type'
                  placement='right'
                  tooltip={getInvestorTypeTooltip()}
                />
              }
              options={unitClassInvestorTypeOptions}
              allOption
              readOnly={isViewMode}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='minimumInvestmentAmount'
            placeholder='Enter amound'
            label='Minimum Investment Amount'
            hideCurrency
            endAdornment={<CurrencyName currencyName={currencyName} />}
            readOnly={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='minimumSubsequentInvestmentAmount'
            placeholder='Enter amound'
            label='Minimum Subsequent Investment Amount'
            hideCurrency
            endAdornment={<CurrencyName currencyName={currencyName} />}
            readOnly={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='maximumInvestmentAmount'
            placeholder='Enter amound'
            label='Maximum Investment Amount'
            optional={
              <FormCheckbox
                label='N/A'
                name='unlimitedInvestmentAmount'
                onChange={handleUnlimitedInvestmentAmount}
                disabled={isViewMode}
              />
            }
            hideCurrency
            endAdornment={<CurrencyName currencyName={currencyName} />}
            readOnly={isViewMode}
            disabled={watchUnlimitedInvestmentAmount}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            name='incrementInvestmentAmount'
            label='Increment Investment Amount'
            placeholder='Select increment investment amount'
            options={(incrementInvestmentAmountOptions || []).map((item) => ({
              ...item,
              label: item.value?.toLocaleString(),
            }))}
            readOnly={isViewMode}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={3}>
            <Grid item xs={6}>
              <FormRadioGroup
                label='Allow Foreign Investor'
                name='allowForeignInvestors'
                optionGap={10}
                className='w-full'
                row
                options={YesNoRadioOptions}
                isEnumValue
                disabled={isViewMode}
              />
            </Grid>
            {!!watchAllowForeignInvestors && (
              <Grid item xs={6}>
                <FormMultiSelect
                  label='Select Foreign Investor Criteria'
                  name='foreignInvestorTypes'
                  placeholder='Select foreign investor'
                  allOption
                  options={unitClassForeignInvestorTypeOptions}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={!!watchAcceptInvestmentAmountUnderMinimum ? 8 : 12}>
              <FormRadioGroup
                label='Accept Investments Under Minimum Investment Amount'
                name='acceptInvestmentAmountUnderMinimum'
                optionGap={10}
                className='w-full'
                row
                isEnumValue
                options={YesNoRadioOptions}
                disabled={isViewMode}
              />
            </Grid>
            {!!watchAcceptInvestmentAmountUnderMinimum && (
              <Grid item xs={12} md={4}>
                <FormCurrencyInput
                  name='hardFloor'
                  placeholder='Enter amount'
                  label={
                    <LabelWithTooltip
                      label='Hard Floor'
                      placement='right'
                      tooltip='Investment applications with investment amount below this number will be automatically rejected'
                    />
                  }
                  hideCurrency
                  endAdornment={<CurrencyName currencyName={currencyName} />}
                  readOnly={isViewMode}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            label='Redemption Allowed'
            name='redemptionAllowedType'
            optionGap={10}
            className='w-full'
            isEnumValue
            row
            options={RedemptionAllowedOptions}
            disabled={isViewMode}
          />
        </Grid>
        {watchRedemptionAllowedType !== RedemptionOptionsType.No &&
          watchRedemptionAllowedType !== null && (
            <Grid item xs={6}>
              <FormCurrencyInput
                name='minimumRedeptionAmount'
                placeholder='Enter amount'
                label={
                  <LabelWithTooltip
                    label='Minimum Redemption Amount'
                    placement='right'
                    tooltip='If no minimum redemption amount, input 0.'
                  />
                }
                hideCurrency
                endAdornment={<CurrencyName currencyName={currencyName} />}
                readOnly={isViewMode}
              />
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default InvestmentDetailForm;
