import dayjs from 'dayjs';
import { DATE_PICKER_FORMAT_SERVER } from './date';

export enum SUBSCRIPTION_FEATURE_TYPE {
  AdministrationFundsServiceProviders,
  AdministrationInvestorsInvestmentEntity,
  FundraisingApplications,
  FundraisingOffers,
  OrganisationSettings,
  RoleManagement,
  UserManagement,
  Communications,
  Documents,
  FundRegistry,
  AdministrationReporting,
  AdministrationTransactions,
  ClientManagement,
  ContactLawyer,
  Dashboard,
  FundraisingCapitalCalls,
  FundraisingKYCBackOffice,
}

export const StandardSubscriptionRef = 'Standard';

export enum BILLING_VOLUME_ITEM_TIER {
  NoTier,
  Tier1,
  Tier2,
  Tier3,
}

export enum BILLING_FEE_TYPE {
  'One Off',
  'Per Instance',
  Monthly,
}

export enum BILLING_PRICING_TYPE {
  SetupFee,
  ApplicationFee,
  PlatformFeesBaseSubscriptionFixed,
  PlatformFeesStandardSubscriptionFixed,
  PlatformFeePerClient,
  PlatformFeePerFund,
  PlatformFeePerUser,
  PlatformFeePerInvestor,
  PlatformFeeKYCBO,
  PlatformFeeContactALawyer,
  PlatformFeeCapitalCalls,
  PlatformFeeClientManagement,
  AMLKYCProcessingFees,
  DomesticEntityBasicStructure,
  DomesticEntityStandardStructure,
  DomesticEntityComplexStructure,
  ForeignEntity,
  KYBFees,
  KYBSoleTrader,
  KYBCompanyDomestic,
  KYBCompanyForeign,
  KYBTrust,
  KYBSMSF,
  KYBPartnership,
  KYBAssociation,
  KYBGovernmentBody,
  KYBPEPSanctionAdverseMedia,
  KYBASICSearch,
  IndividualKYCVerification,
  AUNZIndividualDocumentVerification,
  AUNZIndividualBiometricVerification,
  AUNZIndividualConnectID,
  ForeignIndividualVerification,
  InitialPEPsSanctionsScreening,
  OngoingPEPsSanctionsScreening,
}

export enum BILLING_MODE {
  Standard,
  Custom,
}

export const billingStatus = {
  Paid: 'Paid',
  Unpaid: 'Unpaid',
};

export const billingPlanActionTypes = {
  view: 'view',
  edit: 'edit',
  delete: 'delete',
};

export const billingTieringSystem = {
  yes: 'Yes',
  no: 'No',
};

export const billingFormDefaultValues = {
  startDate: dayjs().format(DATE_PICKER_FORMAT_SERVER),
  endDate: null,
  isOpenEnded: true,
  mode: BILLING_MODE.Standard,
  items: [],
  disabledItems: [],
};

export const singleFees = [
  BILLING_PRICING_TYPE.SetupFee,
  BILLING_PRICING_TYPE.PlatformFeesBaseSubscriptionFixed,
  BILLING_PRICING_TYPE.PlatformFeesStandardSubscriptionFixed,
  BILLING_PRICING_TYPE.PlatformFeeKYCBO,
  BILLING_PRICING_TYPE.PlatformFeeContactALawyer,
  BILLING_PRICING_TYPE.PlatformFeeCapitalCalls,
  BILLING_PRICING_TYPE.PlatformFeeClientManagement,
  BILLING_PRICING_TYPE.InitialPEPsSanctionsScreening,
  BILLING_PRICING_TYPE.OngoingPEPsSanctionsScreening,
];

export const multipleFees = [
  BILLING_PRICING_TYPE.AMLKYCProcessingFees,
  BILLING_PRICING_TYPE.KYBFees,
  BILLING_PRICING_TYPE.IndividualKYCVerification,
];

export const tieringFees = [
  BILLING_PRICING_TYPE.ApplicationFee,
  BILLING_PRICING_TYPE.PlatformFeePerClient,
  BILLING_PRICING_TYPE.PlatformFeePerFund,
  BILLING_PRICING_TYPE.PlatformFeePerUser,
  BILLING_PRICING_TYPE.PlatformFeePerInvestor,
];

export const billingBySubscriptionFeatures: {
  [key: string | keyof SUBSCRIPTION_FEATURE_TYPE]: {
    check: { show: BILLING_PRICING_TYPE[]; hide: BILLING_PRICING_TYPE[] };
    uncheck: { show: BILLING_PRICING_TYPE[]; hide: BILLING_PRICING_TYPE[] };
  };
} = {
  [StandardSubscriptionRef]: {
    check: {
      show: [BILLING_PRICING_TYPE.PlatformFeesStandardSubscriptionFixed],
      hide: [BILLING_PRICING_TYPE.PlatformFeesBaseSubscriptionFixed],
    },
    uncheck: {
      show: [BILLING_PRICING_TYPE.PlatformFeesBaseSubscriptionFixed],
      hide: [BILLING_PRICING_TYPE.PlatformFeesStandardSubscriptionFixed],
    },
  },
  [SUBSCRIPTION_FEATURE_TYPE.ClientManagement]: {
    check: {
      show: [
        BILLING_PRICING_TYPE.PlatformFeePerClient,
        BILLING_PRICING_TYPE.PlatformFeeClientManagement,
      ],
      hide: [],
    },
    uncheck: {
      show: [],
      hide: [
        BILLING_PRICING_TYPE.PlatformFeePerClient,
        BILLING_PRICING_TYPE.PlatformFeeClientManagement,
      ],
    },
  },
  [SUBSCRIPTION_FEATURE_TYPE.FundraisingKYCBackOffice]: {
    check: {
      show: [BILLING_PRICING_TYPE.PlatformFeeKYCBO],
      hide: [BILLING_PRICING_TYPE.AMLKYCProcessingFees],
    },
    uncheck: {
      show: [BILLING_PRICING_TYPE.AMLKYCProcessingFees],
      hide: [BILLING_PRICING_TYPE.PlatformFeeKYCBO],
    },
  },
  [SUBSCRIPTION_FEATURE_TYPE.ContactLawyer]: {
    check: {
      show: [BILLING_PRICING_TYPE.PlatformFeeContactALawyer],
      hide: [],
    },
    uncheck: {
      show: [],
      hide: [BILLING_PRICING_TYPE.PlatformFeeContactALawyer],
    },
  },
};
