export const calculateRange = (
  currentPage: number,
  totalResults: number,
  resultsPerPage: number,
) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  if (totalPages === 1) {
    return {
      from: '01',
      to: totalResults.toString().padStart(2, '0'),
      totalResults: totalResults.toString().padStart(2, '0'),
    };
  }

  const from = (currentPage * resultsPerPage + 1).toString().padStart(2, '0');
  let to = ((currentPage + 1) * resultsPerPage).toString().padStart(2, '0');
  if (Number(to) > totalResults) {
    to = totalResults.toString().padStart(2, '0');
  }

  return {
    from,
    to,
    totalResults: totalResults.toString().padStart(2, '0'),
  };
};
