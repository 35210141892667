import * as React from 'react';

interface ICompleteStepIconProps {}

const CompleteStepIcon: React.FunctionComponent<ICompleteStepIconProps> = () => {
  return (
    <svg
      width='32px'
      height='32px'
      viewBox='0 0 32 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='00_Create-Clients_07' transform='translate(-100.000000, -112.000000)'>
          <g id='Check-Copy-2' transform='translate(100.000000, 112.000000)'>
            <path
              d='M30.2199747,4.50865672 C30.2199747,3.00052678 28.9973311,1.7800878 27.4915016,1.7800878 L22.1154657,1.7800878 C21.1333052,1.7800878 20.3354404,0.982194908 20.3354404,0 L27.4915016,0 C29.981739,0 32,2.01833187 32,4.50865672 L32,11.6627217 C31.0088496,11.6627217 30.2042422,10.858086 30.2064897,9.86690079 L30.2199747,4.50865672 Z'
              id='Fill-26'
              fill='#33B27F'
            />
            <polyline
              id='Stroke-24'
              stroke='#33B27F'
              strokeWidth='2.04'
              strokeLinecap='round'
              strokeLinejoin='round'
              points='7.9797244 16.8107582 12.3237244 21.4427582 23.6157244 11.3099582'
            />
            <path
              d='M27.4903779,30.2199122 C28.9984548,30.2199122 30.218851,28.9972256 30.218851,27.4913433 L30.218851,22.1151185 C30.218851,21.1329236 31.0167158,20.3350307 31.9988762,20.3350307 L31.9988762,27.4913433 C31.9988762,29.9816681 29.9806153,32 27.4903779,32 L20.3365641,32 L20.3365641,32 C20.3365641,31.0088147 21.1411715,30.2041791 22.132322,30.2064267 L27.4903779,30.2199122 Z'
              id='Fill-28'
              fill='#33B27F'
            />
            <path
              d='M1.78002528,4.50865672 C1.78002528,3.00052678 3.00266891,1.7800878 4.50849838,1.7800878 L9.88453434,1.7800878 C10.8666948,1.7800878 11.6645596,0.982194908 11.6645596,0 L4.50849838,0 C2.01826099,0 0,2.01833187 0,4.50865672 L0,11.6627217 C0.991150442,11.6627217 1.79575783,10.858086 1.79351032,9.86690079 L1.78002528,4.50865672 Z'
              id='Fill-30'
              fill='#33B27F'
            />
            <path
              d='M4.50939739,30.2199122 C3.00132041,30.2199122 1.78092429,28.9972256 1.78092429,27.4913433 L1.78092429,22.1151185 C1.78092429,21.1329236 0.983059418,20.3350307 0.000899002662,20.3350307 L0.000899002662,27.4913433 C0.000899002662,29.9816681 2.01915999,32 4.50939739,32 L11.6632111,32 C11.6632111,31.0088147 10.8586037,30.2041791 9.86745329,30.2064267 L4.50939739,30.2199122 Z'
              id='Fill-32'
              fill='#33B27F'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CompleteStepIcon;
