import { FC, SVGProps } from 'react';

export const CrossCircleIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      color='#FF3F52'
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal-Fundraising_KYC-Back-Office_Investor-Details_Unregulated-Trust_05'
          transform='translate(-724, -552)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <g id='Right-Popup' transform='translate(654, 0)'>
            <g id='Group-5' transform='translate(64, 410)'>
              <g id='Container' transform='translate(0, 103)'>
                <g id='Change' transform='translate(0, 33)'>
                  <g id='01.Design-Token/06.Icons/Fluent/Filled/Flag' transform='translate(4, 4)'>
                    <g id='Group' transform='translate(2, 2)'>
                      <path
                        d='M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M10,1.5 C5.30557963,1.5 1.5,5.30557963 1.5,10 C1.5,14.6944204 5.30557963,18.5 10,18.5 C14.6944204,18.5 18.5,14.6944204 18.5,10 C18.5,5.30557963 14.6944204,1.5 10,1.5 L10,1.5 Z M13.446,6.397 L13.53,6.47 C13.7925036,6.73244277 13.8235424,7.14742777 13.603,7.446 L13.53,7.53 L11.061,10 L13.531,12.47 C13.7931927,12.732701 13.823805,13.1476681 13.603,13.446 L13.53,13.53 C13.2675572,13.7925036 12.8525722,13.8235424 12.554,13.603 L12.47,13.53 L10,11.061 L7.53,13.531 C7.26729904,13.7931927 6.85233194,13.823805 6.554,13.603 L6.47,13.53 C6.20749643,13.2675572 6.17645759,12.8525722 6.397,12.554 L6.47,12.47 L8.939,10 L6.469,7.53 C6.2068073,7.26729904 6.17619497,6.85233194 6.397,6.554 L6.47,6.47 C6.73244277,6.20749643 7.14742777,6.17645759 7.446,6.397 L7.53,6.47 L10,8.939 L12.47,6.469 C12.732701,6.2068073 13.1476681,6.17619497 13.446,6.397 L13.446,6.397 Z'
                        id='Shape'
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
