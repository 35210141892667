import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { showToast } from 'src/helpers/toast';
import { v4 as uuidv4 } from 'uuid';

export interface IUploadButton extends ButtonProps<'label'> {
  label?: string | ReactNode;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputLabelProps?: TypographyProps;
  validate?: {
    fileSize: number;
    onError?: () => void;
  };
  handleFileChange?: (_: FileList) => void;
  multiple?: boolean;
}

const UploadButton = ({
  label = 'Upload',
  inputProps,
  inputLabelProps,
  handleFileChange,
  validate,
  sx,
  multiple,
  ...rest
}: IUploadButton) => {
  const [id] = useState(uuidv4());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && handleValidateFile(files)) {
      handleFileChange?.(files);
    }
    event.target.value = '';
  };

  const handleValidateFile = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (validate?.fileSize && file) {
        const fileSizeKiloBytes = (file?.size || 0) / 1024;
        if (fileSizeKiloBytes > validate?.fileSize) {
          validate?.onError
            ? validate.onError()
            : showToast('File size is greater than maximum limit', 'error');
          return false;
        }
      }
    }
    return true;
  };

  return (
    <Button
      variant='outlined'
      component='label'
      htmlFor={id}
      sx={{ color: 'primary.main', py: '10px', ...sx }}
      {...rest}
    >
      <input
        id={id}
        type='file'
        hidden
        onChange={handleChange}
        {...inputProps}
        multiple={multiple}
      />
      <Typography variant='body2' fontWeight={500} whiteSpace='nowrap' {...inputLabelProps}>
        {label}
      </Typography>
    </Button>
  );
};

export default UploadButton;
