import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect, { IFormSelectOption } from 'src/components/atoms/FormSelect';
import { FundType } from 'src/constants/funds-management';
import {
  AMOUNT_TYPE,
  PAYMENT_METHOD,
  REDEMPTION_TYPE,
  partialRedemptionOptions,
  redemptionOptions,
} from 'src/constants/investor-form';
import { IInvestorFormFund } from 'src/modules/investor-form/type';
import { IUpsertInvestorFormProps } from './UpsertInvestorForm';

export interface IRedemptionFormFieldsProps extends IUpsertInvestorFormProps {
  investmentEntityOptions: IFormSelectOption[];
  getFundOptions: (keepOriginalFunds?: boolean) => IFormSelectOption[] | IInvestorFormFund[];
  getUnitClassIdOptions: () => IFormSelectOption[];
  fundType?: number;
  unitRounding?: number;
  currencyName?: string;
  accountNumber?: string;
  onGenerateFormId: (entityId: string) => void;
}

const RedemptionFormFields: FC<IRedemptionFormFieldsProps> = ({
  investmentEntityOptions,
  getFundOptions,
  getUnitClassIdOptions,
  fundType,
  unitRounding,
  currencyName,
  isViewMode,
  accountNumber = '',
  onGenerateFormId,
}) => {
  const form = useFormContext();
  const watchRedemptionType = form.watch('redemptionType');
  const watchAmountType = form.watch('amountType');
  const watchPaymentMethod = form.watch('paymentMethod');

  useEffect(() => {
    if (fundType === FundType.Partnership && watchRedemptionType === REDEMPTION_TYPE.PARTIAL) {
      form.setValue('amountType', AMOUNT_TYPE.AMOUNT);
    }
  }, [fundType]);

  const handleInvestmentEntityChange = (value: string | number) => {
    onGenerateFormId(value as string);
  };

  const renderRedemptionAmountOrUnits = () => {
    if (watchRedemptionType === REDEMPTION_TYPE.FULL) return null;

    return (
      <>
        {watchAmountType === AMOUNT_TYPE.AMOUNT && (
          <Grid item xs={6}>
            <FormCurrencyInput
              name='redemptionAmount'
              label={'Redemption Amount'}
              placeholder={'Enter redemption amount'}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
              hideCurrency
              decimalScale={2}
              fixedDecimalScale
              disabled={isViewMode}
            />
          </Grid>
        )}
        {watchAmountType === AMOUNT_TYPE.UNITS && (
          <Grid item xs={6}>
            <FormCurrencyInput
              name='redemptionUnits'
              label={'Redemption Units'}
              placeholder={'Enter redemption units'}
              hideCurrency
              decimalScale={unitRounding}
              disabled={isViewMode}
            />
          </Grid>
        )}
      </>
    );
  };

  const paymentMethodOptions = [
    {
      label: `Deposit to the nominated bank account ending with ${accountNumber}.`,
      value: PAYMENT_METHOD.EXISTING_BANK,
    },
    {
      label: `Deposit to the bank account provided below (if you select this option, you may be required to provide additional information to verify the bank account details prior to payment).`,
      value: PAYMENT_METHOD.BANK_ACCOUNT,
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput name='formId' label='Redemption Request ID' disabled />
        </Grid>
        <Grid item xs={6}>
          <FormInput name='investorNumber' label='Investor Number' disabled />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            name='investmentEntityId'
            label='Investment Entity'
            placeholder='Select investment entity'
            options={investmentEntityOptions}
            disabled={isViewMode}
            onSelectValue={handleInvestmentEntityChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='fundId'
            label='Fund'
            placeholder='Select fund'
            options={getFundOptions() as IFormSelectOption[]}
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='unitClassId'
            label={fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class'}
            placeholder={
              fundType === FundType.Partnership ? 'Select financial rule' : 'Select unit class'
            }
            options={getUnitClassIdOptions()}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <Box className='pt-10'>
        <Typography className='pb-4.5' variant='body2' fontWeight={600}>
          Current Holdings
        </Typography>
        <Grid container rowSpacing='18px' columnSpacing={3}>
          {fundType === FundType.Trust && (
            <Grid item xs={6}>
              <FormCurrencyInput
                name='units'
                label='Units'
                disabled
                hideCurrency
                decimalScale={unitRounding}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormCurrencyInput
              name='investedAmount'
              label='Invested Amount'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormCurrencyInput
              name='unpaidAmount'
              label='Unpaid Amount'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormCurrencyInput
              name='netValue'
              label='Current Net Value'
              disabled
              hideCurrency
              decimalScale={2}
              endAdornment={
                <Typography variant='body3' color='neutral.ne500'>
                  {currencyName}
                </Typography>
              }
              allowNegative
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='pt-10'>
        <Typography
          className='py-2 px-4 mb-8'
          bgcolor='neutral.ne100'
          variant='body1'
          fontWeight={600}
        >
          Redemption Details
        </Typography>
        <Grid container spacing={4}>
          <Grid className='flex items-center gap-17' item xs={12}>
            <FormRadioGroup
              name='redemptionType'
              options={redemptionOptions}
              isEnumValue
              optionGap={4}
              fullWidth={false}
              disabled={isViewMode}
            />
            {watchRedemptionType === REDEMPTION_TYPE.PARTIAL && fundType === FundType.Trust && (
              <FormRadioGroup
                name='amountType'
                options={partialRedemptionOptions}
                optionGap={4}
                isEnumValue
                fullWidth={false}
                disabled={isViewMode}
              />
            )}
          </Grid>
          {renderRedemptionAmountOrUnits()}
          <Grid item xs={6}>
            <FormDatePicker
              name='redemptionDate'
              label='Redemption Date'
              optional='Optional'
              disabled={isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRadioGroup
              name='paymentMethod'
              label={
                <Typography variant='body3' fontWeight={500}>
                  Payment Method
                </Typography>
              }
              options={paymentMethodOptions}
              containerSx={{
                '.MuiStack-root': {
                  flexDirection: 'column',
                  gap: 1.5,
                },
              }}
              formControlLabelSx={{
                textAlign: 'left',
                alignItems: 'flex-start',
                mt: 1.5,
                '.MuiRadio-root': {
                  py: 0,
                },
              }}
              isEnumValue
              disabled={isViewMode}
            />
          </Grid>
          {watchPaymentMethod === PAYMENT_METHOD.BANK_ACCOUNT && (
            <Grid item xs={12}>
              <Grid container rowSpacing='18px' columnSpacing={3}>
                <Grid item xs={6}>
                  <FormInput
                    name='bankName'
                    label='Bank Name'
                    placeholder='Enter bank name'
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name='accountName'
                    label='Account Name'
                    placeholder='Enter account name'
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name='bsb'
                    label='BSB'
                    placeholder='Enter bsb'
                    type='number'
                    disabled={isViewMode}
                    maxLength={6}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name='accountNumber'
                    label='Account Number'
                    placeholder='Enter account number'
                    type='number'
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default RedemptionFormFields;
