export interface IUpdateTextItem {
  // eslint-disable-next-line autofix/no-unused-vars
  target: (node: HTMLElement) => Element | null;
  text: string;
  className?: string[];
}

export async function makeScriptDOM(src: string, className: string): Promise<HTMLScriptElement> {
  const hasScript = document.querySelectorAll(`.${className}`);
  if (hasScript.length) return hasScript[0] as HTMLScriptElement;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.classList.add(className);

  document.body.appendChild(script);
  return new Promise((rs) => {
    script.onload = () => {
      return rs(script);
    };
  });
}
export async function makeStylesheetDOM(href: string, className: string): Promise<HTMLLinkElement> {
  const hasLink = document.querySelectorAll(`.${className}`);
  if (hasLink.length) return hasLink[0] as HTMLLinkElement;
  const link = document.createElement('link');
  link.href = href;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.media = 'screen';
  link.classList.add(className);
  document.head.appendChild(link);
  return new Promise((rs) => {
    link.onload = () => {
      return rs(link);
    };
  });
}

export function changeTextInDOM(item: IUpdateTextItem, parentNode: HTMLElement) {
  if (parentNode) {
    const element = item.target(parentNode);
    if (element && element?.textContent?.trim() !== item.text) {
      element.textContent = item.text;
      if (item.className && element?.classList) element.classList.add(...item.className);
    }
  }
}
