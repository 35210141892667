import * as React from 'react';

interface ILoadingIconProps {}

const LoadingIcon: React.FunctionComponent<ILoadingIconProps> = (props) => {
  return (
    <svg
      width='60px'
      height='60px'
      viewBox='0 0 60 60'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className='animate-spin'
    >
      <title>E5E00932-081C-4BAA-AE68-6E159A120037</title>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Company_11'
          transform='translate(-820.000000, -263.000000)'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(607.000000, 207.000000)'>
            <g id='loading' transform='translate(213.000000, 56.000000)'>
              <path
                d='M30,11.1290323 C28.3548387,11.1290323 27.0967742,9.87096774 27.0967742,8.22580645 L27.0967742,2.90322581 C27.0967742,1.25806452 28.3548387,0 30,0 C31.6451613,0 32.9032258,1.25806452 32.9032258,2.90322581 L32.9032258,8.22580645 C32.9032258,9.77419355 31.6451613,11.1290323 30,11.1290323 Z'
                id='Path'
                fill='#699DAF'
              />
              <path
                d='M30,60 C28.3548387,60 27.0967742,58.7419355 27.0967742,57.0967742 L27.0967742,51.7741935 C27.0967742,50.1290323 28.3548387,48.8709677 30,48.8709677 C31.6451613,48.8709677 32.9032258,50.1290323 32.9032258,51.7741935 L32.9032258,57.0967742 C32.9032258,58.7419355 31.6451613,60 30,60 Z'
                id='Path'
                fill='#002A37'
              />
              <path
                d='M19.0645161,14.0322581 C18.0967742,14.0322581 17.1290323,13.5483871 16.5483871,12.5806452 L13.9354839,8.03225806 C13.1612903,6.67741935 13.6451613,4.83870968 15,4.06451613 C16.3548387,3.29032258 18.1935484,3.77419355 18.9677419,5.12903226 L21.5806452,9.67741935 C22.3548387,11.0322581 21.8709677,12.8709677 20.516129,13.6451613 C20.1290323,13.8387097 19.5483871,14.0322581 19.0645161,14.0322581 Z'
                id='Path'
                fill='#8EBACA'
              />
              <path
                d='M43.5483871,56.3225806 C42.5806452,56.3225806 41.6129032,55.8387097 41.0322581,54.8709677 L38.4193548,50.3225806 C37.6451613,48.9677419 38.1290323,47.1290323 39.483871,46.3548387 C40.8387097,45.5806452 42.6774194,46.0645161 43.4516129,47.4193548 L46.0645161,51.9677419 C46.8387097,53.3225806 46.3548387,55.1612903 45,55.9354839 C44.516129,56.2258065 44.0322581,56.3225806 43.5483871,56.3225806 Z'
                id='Path'
                fill='#003E4E'
              />
              <path
                d='M11.1290323,21.9677419 C10.6451613,21.9677419 10.1612903,21.8709677 9.67741935,21.5806452 L5.12903226,18.9677419 C3.77419355,18.1935484 3.29032258,16.3548387 4.06451613,15 C4.83870968,13.6451613 6.67741935,13.1612903 8.03225806,13.9354839 L12.5806452,16.5483871 C13.9354839,17.3225806 14.4193548,19.1612903 13.6451613,20.516129 C13.0645161,21.483871 12.0967742,21.9677419 11.1290323,21.9677419 Z'
                id='Path'
                fill='#B1D8E4'
              />
              <path
                d='M53.516129,46.4516129 C53.0322581,46.4516129 52.5483871,46.3548387 52.0645161,46.0645161 L47.4193548,43.4516129 C46.0645161,42.6774194 45.5806452,40.8387097 46.3548387,39.483871 C47.1290323,38.1290323 48.9677419,37.6451613 50.3225806,38.4193548 L54.8709677,41.0322581 C56.2258065,41.8064516 56.7096774,43.6451613 55.9354839,45 C55.4516129,45.9677419 54.483871,46.4516129 53.516129,46.4516129 Z'
                id='Path'
                fill='#105163'
              />
              <path
                d='M8.22580645,32.9032258 L2.90322581,32.9032258 C1.25806452,32.9032258 0,31.6451613 0,30 C0,28.3548387 1.25806452,27.0967742 2.90322581,27.0967742 L8.22580645,27.0967742 C9.87096774,27.0967742 11.1290323,28.3548387 11.1290323,30 C11.1290323,31.6451613 9.77419355,32.9032258 8.22580645,32.9032258 Z'
                id='Path'
                fill='#E9F8FF'
              />
              <path
                d='M57.0967742,32.9032258 L51.7741935,32.9032258 C50.1290323,32.9032258 48.8709677,31.6451613 48.8709677,30 C48.8709677,28.3548387 50.1290323,27.0967742 51.7741935,27.0967742 L57.0967742,27.0967742 C58.7419355,27.0967742 60,28.3548387 60,30 C60,31.6451613 58.7419355,32.9032258 57.0967742,32.9032258 Z'
                id='Path'
                fill='#216578'
              />
              <path
                d='M6.48387097,46.4516129 C5.51612903,46.4516129 4.5483871,45.9677419 3.96774194,45 C3.19354839,43.6451613 3.67741935,41.8064516 5.03225806,41.0322581 L9.58064516,38.4193548 C10.9354839,37.6451613 12.7741935,38.1290323 13.5483871,39.483871 C14.3225806,40.8387097 13.8387097,42.6774194 12.483871,43.4516129 L7.93548387,46.0645161 C7.5483871,46.3548387 7.06451613,46.4516129 6.48387097,46.4516129 Z'
                id='Path'
                fill='#00141A'
              />
              <path
                d='M48.8709677,21.9677419 C47.9032258,21.9677419 46.9354839,21.483871 46.3548387,20.516129 C45.5806452,19.1612903 46.0645161,17.3225806 47.4193548,16.5483871 L51.9677419,13.9354839 C53.3225806,13.1612903 55.1612903,13.6451613 55.9354839,15 C56.7096774,16.3548387 56.2258065,18.1935484 54.8709677,18.9677419 L50.3225806,21.5806452 C49.8387097,21.8709677 49.3548387,21.9677419 48.8709677,21.9677419 Z'
                id='Path'
                fill='#2D7388'
              />
              <path
                d='M16.4516129,56.3225806 C15.9677419,56.3225806 15.483871,56.2258065 15,55.9354839 C13.6451613,55.1612903 13.1612903,53.3225806 13.9354839,51.9677419 L16.5483871,47.4193548 C17.3225806,46.0645161 19.1612903,45.5806452 20.516129,46.3548387 C21.8709677,47.1290323 22.3548387,48.9677419 21.5806452,50.3225806 L18.9677419,54.8709677 C18.3870968,55.8387097 17.4193548,56.3225806 16.4516129,56.3225806 Z'
                id='Path'
                fill='#001E27'
              />
              <path
                d='M40.9354839,14.0322581 C40.4516129,14.0322581 39.9677419,13.9354839 39.483871,13.6451613 C38.1290323,12.8709677 37.6451613,11.0322581 38.4193548,9.67741935 L41.0322581,5.12903226 C41.8064516,3.77419355 43.6451613,3.29032258 45,4.06451613 C46.3548387,4.83870968 46.8387097,6.67741935 46.0645161,8.03225806 L43.4516129,12.5806452 C42.8709677,13.4516129 41.9032258,14.0322581 40.9354839,14.0322581 Z'
                id='Path'
                fill='#4D889C'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LoadingIcon;
