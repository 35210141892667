import { ORGANISATION_SETTING_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { BrandingParams, BrandingResponse, OrganisationDetailParams } from './type';

export const upsertBranding = async (data: BrandingParams) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ORGANISATION_SETTING_API.branding.api,
  });

  return result;
};

export const getBranding = async (): Promise<BrandingResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ORGANISATION_SETTING_API.branding.api,
  });

  return result.data;
};

export const updateOrganisationDetail = async (data: OrganisationDetailParams) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ORGANISATION_SETTING_API.organisationDetail.api,
  });

  return result;
};

export const getOrganisationDetail = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ORGANISATION_SETTING_API.organisationDetail.api,
  });

  return result.data;
};

export const getOrganisationSubscriptions = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ORGANISATION_SETTING_API.organisationSubscriptions.api,
  });

  return result.data;
};

export const getOrganisationBillings = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ORGANISATION_SETTING_API.organisationBillings.api,
  });

  return result.data;
};

export const getOrganisationBillingInvoice = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ORGANISATION_SETTING_API.organisationBillingInvoice.api(id),
    responseType: 'arraybuffer',
  });

  return result.data;
};
