import dayjs from 'dayjs';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { currencyStringToNumber } from 'src/utils/common';
import * as Yup from 'yup';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%&_?=-])/;
export const EMAIL_REGEX = /@[^.]*\./;

export const YupBase = {
  firstName: Yup.string()
    .required('First name is required')
    .max(255, 'First name must not exceed 225 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .max(255, 'Last name must not exceed 225 characters'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format')
    .matches(EMAIL_REGEX, 'Invalid email format'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'The password does not match the rules.')
    .max(16, 'The password does not match the rules.')
    .matches(PASSWORD_REGEX, 'The password does not match the rules.'),
  contactNumber: Yup.string().required('Contact number is required'),
  transformNumberSchema: Yup.number().transform((current, origin) => {
    return typeof origin === 'string' && origin?.includes(',')
      ? currencyStringToNumber(origin)
      : current;
  }),
  date: Yup.mixed().test('valid', 'Invalid date', (value: any) =>
    value ? dayjs(value)?.isValid() : true,
  ),
  transactionDate: Yup.mixed()
    .test('valid', 'Invalid date', (value: any) => (value ? dayjs(value)?.isValid() : true))
    .test(
      'valid',
      'Transaction date cannot be in the future.',
      (value: any) => !dayjs(value).isAfter(dayjs()),
    ),
  residentialAddressString: (fieldName: string) =>
    Yup.string().max(255, `${fieldName} must less than 256 characters`).required(REQUIRE_MESSAGE),
};
