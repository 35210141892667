/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IConnectIDLogoProps {}

export const ConnectIDLogo: React.FunctionComponent<IConnectIDLogoProps> = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='KYC-Verification' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_KYC-Verification_08.2'
          transform='translate(-510, -141)'
          fillRule='nonzero'
        >
          <g id='Group-5' transform='translate(430, 80)'>
            <g id='Group-14' transform='translate(80, 60)'>
              <g id='Large-Buttons-Copy-2' transform='translate(0, 1)'>
                <rect
                  id='Rectangle'
                  fill='#262626'
                  x='0'
                  y='0'
                  width='53'
                  height='53'
                  rx='8'
                ></rect>
                <path
                  d='M34.6132102,29.15595 C34.6132102,33.317525 31.2083635,36.68435 27,36.68435 C22.7915014,36.68435 19.3866547,33.317525 19.3866547,29.15595 C19.3866547,24.9942375 22.7915014,21.6274125 27,21.6274125 C29.1147881,21.6274125 31.0181244,22.463825 32.3926286,23.84405 L41,15.3326625 C39.6253607,13.9733375 38.7794184,12.0912375 38.7794184,10 L32.3926286,10 C32.3926286,12.80225 33.2385709,15.4373 34.6977909,17.6121375 C32.4984221,16.1692125 29.8337242,15.3326625 27,15.3326625 C19.2597836,15.3117625 13,21.501875 13,29.15595 C13,36.8098875 19.2597836,43 27,43 C34.7400813,43 41,36.8098875 41,29.15595 L34.6132102,29.15595 Z'
                  id='Path'
                  fill='#FFFFFF'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
