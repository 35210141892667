/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useRef } from 'react';
import RectangleLogoDefault from 'src/assets/images/RectangleLogoDefault';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomSwitch } from 'src/components/atoms/CustomSwitch';
import {
  HyperlinkTextSetting,
  LogoSetting,
  NavigationMenuSetting,
  SystemButtonsSetting,
} from 'src/components/molecules/organisation-settings';
import { MAX_IMAGE_SIZE } from 'src/constants/common';
import { showToast } from 'src/helpers/toast';
import { useUploadImage } from 'src/modules/common/hooks';
import { IUploadFile } from 'src/modules/common/type';
import { BrandingParams } from 'src/modules/organisation-settings/type';
import { BrandingContext, BrandingDefault } from 'src/providers/BrandingContextProvider';
import { handleErrorFromServer } from 'src/utils/common';
import { SUBMIT_TYPES } from '..';

interface BrandingProps {
  viewOnly?: boolean;
}
export interface BrandingHandler {
  onSubmit: (callback: (params: any, type: string) => void) => void;
}

const Branding = React.forwardRef<BrandingHandler, BrandingProps>(
  ({ viewOnly = false }, forwardedRef) => {
    const { mutate: uploadImageMutate, isLoading } = useUploadImage();
    const { branding } = React.useContext(BrandingContext);
    const [logo, setLogo] = React.useState<string>();
    const [fields, setFields] = React.useState<BrandingParams>(BrandingDefault);
    const modalRef = useRef<IConfirmModalElement>(null);
    const [disableUploadImage, setDisableUploadImage] = React.useState<boolean>(false);

    React.useImperativeHandle(forwardedRef, () => ({
      onSubmit: (callback: (params: BrandingParams, type: string) => void) =>
        callback({ ...fields, id: branding?.id }, SUBMIT_TYPES.BRANDING),
    }));

    React.useEffect(() => {
      if (!isEmpty(branding)) {
        setFields(branding);
        setLogo(!branding?.defaultToStandard ? branding.logoLink : undefined);
        setDisableUploadImage(branding?.defaultToStandard);
      }
    }, [branding]);

    const handleChangeFields = (fieldName: string, value: string | boolean | IUploadFile) => {
      setFields((prev: BrandingParams) => ({
        ...prev,
        [fieldName]: value,
      }));
    };

    const handleDefaultToStandard = () => {
      if (!fields.defaultToStandard) {
        setFields(BrandingDefault);
        setLogo(undefined);
      }
      setDisableUploadImage(!fields.defaultToStandard);
      handleChangeFields('defaultToStandard', !fields.defaultToStandard);
      modalRef.current?.close();
    };

    const handleChangeLogo = (file: File) => {
      if (file) {
        const fileSizeKiloBytes = file.size / 1024;
        if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
          showToast('File size is greater than maximum limit', 'error');
        } else {
          uploadImageMutate(file, {
            onSuccess: (logo: IUploadFile) => {
              const localLogoInfo = URL.createObjectURL(file);
              setLogo(localLogoInfo);
              handleChangeFields('fileUpload', logo);
            },
            onError: handleErrorFromServer,
          });
        }
      }
    };
    const handleOpenDefaultToStandardModal = () => {
      if (!fields.defaultToStandard) {
        modalRef.current?.open();
      } else {
        setDisableUploadImage(false);
        handleChangeFields('defaultToStandard', !fields.defaultToStandard);
      }
    };
    const handleCloseDefaultToStandardModal = () => {
      modalRef.current?.close();
    };
    return (
      <Box bgcolor='neutral.ne100' p={3} className='w-full'>
        <LogoSetting
          isLoading={isLoading}
          logo={logo}
          onChange={handleChangeLogo}
          logoHeight='127px'
          logoWidth='229px'
          defaultLogo={<RectangleLogoDefault />}
          readOnly={disableUploadImage}
        />
        <Box className='flex my-[20px] justify-end'>
          <CustomSwitch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={fields.defaultToStandard}
            onChange={handleOpenDefaultToStandardModal}
          />
          <Typography ml='10px' variant='body1' color='neutral.ne800' fontSize='16px'>
            Default to Standard
          </Typography>
        </Box>
        <NavigationMenuSetting
          mb='20px'
          backgroundNavigation={fields.backgroundNavigation}
          backgroundActive={fields.backgroundActive}
          text={fields.text}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
        />
        <SystemButtonsSetting
          mb='20px'
          primaryBackground={fields.primaryBackground}
          primaryText={fields.primaryText}
          contactLawyerBackground={fields.contactLawyerBackground}
          contactLawyerText={fields.contactLawyerText}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
        />
        <HyperlinkTextSetting
          hyperlinkText={fields.hyperlinkText}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
        />
        <ConfirmModal
          title='Default to Standard?'
          content={`All settings including logo will default to standard.<br/>
        Are you sure you want to proceed?`}
          ref={modalRef}
          ButtonsComponent={
            <>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={handleCloseDefaultToStandardModal}
              >
                Cancel
              </CustomButton>
              <CustomButton color='error' onClick={handleDefaultToStandard}>
                Proceed
              </CustomButton>
            </>
          }
        />
      </Box>
    );
  },
);

export default Branding;
