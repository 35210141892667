import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import ActiveStepIcon from 'src/assets/icons/ActiveStepIcon';
import PenOutlinedIcon from 'src/assets/icons/PenOutlinedIcon';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';

interface ICustomFormSectionHeaderProps extends IUpsertInvestmentApplicationForm {
  title: ReactNode;
  icon?: ReactNode;
  isShowUpdateDetailsButton?: boolean;
  investorId?: string | null;
}

const CustomFormSectionHeader: FC<ICustomFormSectionHeaderProps> = ({
  title,
  icon,
  isViewMode,
  isDisabledEdit,
  investmentEntityId,
  isShowUpdateDetailsButton,
  investorId,
}) => {
  return (
    <Box className='px-20 pt-8'>
      <Box className='w-full h-[53px] flex items-center justify-between' bgcolor='primary.main'>
        <Box className='pl-15 flex items-center'>
          <Box className='pr-6' position='relative'>
            <ActiveStepIcon width={70} height={70} />
            <Box className='absolute w-[70px] h-[70px] top-0 flex justify-center items-center'>
              {icon}
            </Box>
          </Box>
          <Typography variant='subtitle4' fontWeight='medium' color='base.white'>
            {title}
          </Typography>
        </Box>
        {isShowUpdateDetailsButton && (
          <Box className='flex items-center pr-[76px]'>
            <Box className='hover:cursor-pointer'>
              <PenOutlinedIcon />
            </Box>
            <UpdateDetailsButton
              entityId={investmentEntityId}
              investorId={investorId}
              disabled={isViewMode || isDisabledEdit}
              color='inherit'
              sx={{
                px: 1,
                ':hover': {
                  bgcolor: 'transparent',
                },
                color: 'white',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomFormSectionHeader;
