/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import AustraliaIcon from 'src/assets/icons/AustraliaIcon';
import WorldIcon from 'src/assets/icons/WorldIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import {
  INVESTMENT_ENTITY_TYPE,
  InvestmentEntityCompanyRegionType,
} from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import AustralianCompanyForm from '../shared/AustralianCompanyForm';
import ForeignCompanyForm from '../shared/ForeignCompanyForm';

interface ICompanyDetailsProps extends IInvestmentEntityProps {
  investmentEntity?: IInvestmentEntityForm;
}

const CompanyDetails: FC<ICompanyDetailsProps> = (props) => {
  const {
    isForeignTaxResident,
    isViewMode,
    isDisabledEdit,
    investmentEntity,
    investmentEntityId,
    isExistingInvestmentEntity,
    isReviewing,
    isCreateFundApplicationForm,
  } = props;
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { watch } = useFormContext<IInvestmentEntityForm>();
  const entityType = watch('entityType');
  const companyRegionType = watch('companyRegionType');
  const skycApplicationId = watch('investmentEntityDetails.skycApplicationId');

  const companyRegionTypeOptions = [
    {
      value: InvestmentEntityCompanyRegionType.Australian,
      label: 'Australian',
      icon: <AustraliaIcon />,
    },
    {
      value: InvestmentEntityCompanyRegionType.Foreign,
      label: 'Foreign',
      icon: <WorldIcon />,
    },
  ];

  return (
    <Box className='flex flex-col'>
      {isForeignTaxResident && entityType === INVESTMENT_ENTITY_TYPE.Company && (
        <Box mb={3}>
          <FormRadioGroup
            name='companyRegionType'
            options={companyRegionTypeOptions}
            label={
              <Typography variant='body3' fontWeight={500}>
                Australian or Foreign Company?
              </Typography>
            }
            isEnumValue
            optionGap={4}
            disabled={
              disabled || Number.isInteger(investmentEntity?.entityType) || !!skycApplicationId
            }
            showPickerOnly={isReviewing}
          />
        </Box>
      )}
      <Accordion
        defaultExpanded
        sx={{
          mt: '0 !important',
          '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        }}
      >
        <AccordionSummary
          sx={{
            '.MuiSvgIcon-root': { fontSize: '18px' },
            '.MuiAccordionSummary-content': { my: 0 },
            minHeight: 'unset',
          }}
        >
          <Box className='flex justify-between items-center w-full'>
            <Typography variant='body2' fontWeight={600}>
              Company Details
            </Typography>
            {isExistingInvestmentEntity && !isReviewing && (
              <UpdateDetailsButton
                entityId={investmentEntityId}
                disabled={isViewMode || isDisabledEdit}
                isCreateFundApplicationForm={isCreateFundApplicationForm}
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          className='p-6 mt-0 border-t'
          sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
        >
          {companyRegionType === InvestmentEntityCompanyRegionType.Australian && (
            <AustralianCompanyForm
              {...props}
              keyName='investmentEntityDetails.australianCompany'
              hideUBODeclaration={isCreateFundApplicationForm}
            />
          )}
          {companyRegionType === InvestmentEntityCompanyRegionType.Foreign && (
            <ForeignCompanyForm
              {...props}
              keyName='investmentEntityDetails.foreignCompany'
              hideUBODeclaration={isCreateFundApplicationForm}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CompanyDetails;
