import { AustralianCompanyType, AustralianCompanyTypeLabel } from 'src/constants/applications';
import { ICountry } from 'src/interfaces/common';
import { ISkycGetApp } from 'src/modules/applications/type';
import { v4 as uuidv4 } from 'uuid';

interface IUseSkycMapping {
  data: ISkycGetApp;
  countries: ICountry[];
}

interface IUbOsMappingReturn {
  firstName: string;
  lastName: string;
  middleName: string;
  dob: string;
  position: string;
  residentialAddress: string;
  isPopulated?: boolean;
  refId: string;
}
interface IUseSkycMappingReturn {
  countryOfEstablishment: string;
  companyType: number;
  principalPlaceOfBusiness: string;
  registeredAddress: string;
  ubOs: IUbOsMappingReturn[];
  directors: {
    firstName: string;
    lastName: string;
    id?: string;
    populatedDirector: boolean;
    refId: string;
  }[];
}

export const useSkycMapping = ({
  data,
  countries = [],
}: IUseSkycMapping): IUseSkycMappingReturn => {
  const refIds: string[] = [];
  (data?.data?.interestedPersons || [])?.forEach(() => {
    const refId = uuidv4();
    refIds.push(refId);
  });

  const getCountryCode = (countryName: string) => {
    const country = countries.find((it: ICountry) => it.name === countryName);

    return country?.code || '';
  };

  const getCompanyType = (typeFromSkyc: string) => {
    switch (typeFromSkyc) {
      case AustralianCompanyTypeLabel.PrivateAustralian:
        return AustralianCompanyType.PrivateAustralian;
      case AustralianCompanyTypeLabel.PublicAustralian:
        return AustralianCompanyType.PublicAustralian;
      default:
        return AustralianCompanyType.Other;
    }
  };

  return {
    countryOfEstablishment: getCountryCode(data.data.businessOverview.countryOfEstablishment),
    companyType: getCompanyType(data.data.businessOverview.typeOfCompany),
    principalPlaceOfBusiness:
      data.data.businessOverview.principalPlaceOfBusiness?.unformattedAddress || '',
    registeredAddress: data.data.businessOverview.registeredOffice?.unformattedAddress || '',
    ubOs: (data.data?.interestedPersons || []).map(
      ({ firstName, lastName, middleName, dob, address, roles }, index) => ({
        firstName,
        lastName,
        middleName,
        dob: (dob || '').split('-').reverse().join('-'),
        refId: refIds[index],
        residentialAddress: address.unformattedAddress,
        position: roles,
        isSKYCPopulated: true,
      }),
    ),
    directors: (data.data?.interestedPersons || []).map(({ firstName, lastName }, index) => ({
      firstName,
      lastName,
      populatedDirector: true,
      refId: refIds[index],
    })),
  };
};
