/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IReset2FAIconProps {}

export const Reset2FAIcon: FC<IReset2FAIconProps> = () => {
  return (
    <svg
      width='12.000894px'
      height='12.0008165px'
      viewBox='0 0 12.000894 12.0008165'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-11.1%'
          y='-7.9%'
          width='122.2%'
          height='122.2%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx='0' dy='5' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur
            stdDeviation='5'
            in='shadowOffsetOuter1'
            result='shadowBlurOuter1'
          ></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
            <feMergeNode in='SourceGraphic'></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_List-User_11'
          transform='translate(-1195.000000, -524.000000)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g
            id='Group-26-Copy-2'
            filter='url(#filter-1)'
            transform='translate(1177.000000, 394.000000)'
          >
            <g id='Group-20-Copy' transform='translate(4.000000, 118.000000)'>
              <g
                id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                transform='translate(12.000000, 10.000000)'
              >
                <g id='Group' transform='translate(2.000000, 2.000000)'>
                  <path
                    d='M6.00126756,0.999185415 C8.09115862,0.99954648 9.96035907,2.29967207 10.6877657,4.2588873 C11.4151724,6.21810253 10.8473147,8.42304571 9.26402099,9.78716664 C7.68072731,11.1512876 5.41604623,11.3867795 3.58596554,10.377597 C1.75588486,9.36841438 0.746521016,7.32747795 1.05526756,5.26051875 C1.09993423,4.95785208 0.878600895,4.66585208 0.572600895,4.66585208 C0.325803218,4.66135264 0.112750816,4.83785749 0.0712675622,5.08118541 C-0.375554695,7.96455406 1.31667087,10.7513513 4.08113576,11.6847316 C6.84560065,12.6181118 9.88075764,11.4274447 11.2731909,8.86334747 C12.6656241,6.29925025 12.0116022,3.10517422 9.72343208,1.29472062 C7.43526194,-0.515732989 4.17639068,-0.417629297 2.00126756,1.52718541 L2.00126756,0.832518748 C2.00126756,0.556376373 1.77740994,0.332518748 1.50126756,0.332518748 C1.22512519,0.332518748 1.00126756,0.556376373 1.00126756,0.832518748 L1.00126756,2.83251875 C1.00126756,3.10851875 1.22526756,3.33251875 1.50126756,3.33251875 L3.50126756,3.33251875 C3.77740994,3.33251875 4.00126756,3.10866112 4.00126756,2.83251875 C4.00126756,2.55637637 3.77740994,2.33251875 3.50126756,2.33251875 L2.60126756,2.33251875 C3.52496856,1.47372219 4.74001769,0.997232333 6.00126756,0.999185415 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
