/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, IconButton, styled } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ColorResult, RGBColor, SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';
import ColorIcon from 'src/assets/icons/ColorIcon';
import SelectedIcon from 'src/assets/icons/SelectedIcon';
import { BrandingContext } from 'src/providers/BrandingContextProvider';

interface CustomColorPickerProps extends BoxProps {
  currentColor?: string;
  onChangeColor?: (color: string) => void;
}

const DefaultColors = ['#002A37', '#B845C4', '#1D94FF', '#2DDFFE', '#1FA95B'];

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 14,
  marginRight: 16,
  border: `1px solid ${theme.palette.neutral.ne200}`,
}));

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({
  currentColor,
  onChangeColor,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedColor, setSelectedColor] = React.useState<string>(DefaultColors[0]);
  const [selectedColorPicker, setSelectedColorPicker] = React.useState<RGBColor | string>(
    DefaultColors[0],
  );
  const [tempColor, setTempColor] = React.useState<string>(currentColor as string);
  const [colors, setColors] = React.useState(DefaultColors);
  const { readOnly } = React.useContext(BrandingContext);

  React.useLayoutEffect(() => {
    onSetColors(currentColor);
  }, [currentColor]);

  React.useEffect(() => {
    if (currentColor) {
      setSelectedColor(currentColor);
      setSelectedColorPicker(currentColor);
    } else {
      setSelectedColor(DefaultColors[0]);
      setSelectedColorPicker(DefaultColors[0]);
    }
  }, [currentColor]);

  const onSetColors = (color: string | undefined) => {
    if (color && !colors.some((_defaultColor) => _defaultColor === color)) {
      const newColors = [...colors];
      newColors[0] = color;
      setColors(newColors);
    }
  };

  const handleChangeColorPicker = (color: ColorResult) => {
    const hexColor = rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a);
    setSelectedColorPicker(color.rgb);
    onSetColor('#' + hexColor);
  };

  const handleChangeColor = (color: string) => {
    if (readOnly) return;
    onSetColors(tempColor);
    onSetColor(color);
  };

  const onSetColor = (color: string) => {
    setSelectedColor(color);
    onChangeColor?.(color);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (readOnly) return;
    if (!tempColor) {
      setTempColor(colors[0]);
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box {...props}>
      <Box className='flex items-center'>
        {colors.map((color: string, index: number) => {
          const isSelectedColor = color === selectedColor;
          return (
            <CustomIconButton
              onClick={() => handleChangeColor(color)}
              key={`default-color-${index}`}
              sx={{
                height: isSelectedColor ? 28 : 16,
                width: isSelectedColor ? 28 : 16,
                backgroundColor: color,
              }}
            >
              {isSelectedColor ? (
                <SelectedIcon color={color.toLowerCase() === '#ffffff' ? '#000000' : '#FFFFFF'} />
              ) : (
                <></>
              )}
            </CustomIconButton>
          );
        })}
        <Box className='flex items-center cursor-pointer ml-[80px]' onClick={handlePopoverOpen}>
          <Typography variant='body1' mr='3px' color='primary.main' fontSize='14px'>
            More colours
          </Typography>
          <ColorIcon />
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
      >
        <SketchPicker color={selectedColorPicker} onChange={handleChangeColorPicker} />
      </Popover>
    </Box>
  );
};

export default CustomColorPicker;
