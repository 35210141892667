/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  ASSOCIATION_TYPE,
  INVESTMENT_ENTITY_TYPE,
  UBOS_META,
  abnAcnNotAllowedStatus,
  associationTypeOptions,
  individualDefaultValue,
} from 'src/constants/applications';
import { showToast } from 'src/helpers/toast';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, ISkyc } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import IndividualDetail from '../shared/IndividualDetail';
import UploadCertified from '../shared/UploadCertified';

interface IAssociationProps extends IInvestmentEntityProps {
  disabledAbn?: boolean;
  hideUBODeclaration?: boolean;
}

const Association: FC<IAssociationProps> = (props) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    hideUbo,
    hideAbnSearch,
    disabledAbn,
    isInvestmentEntitySetting,
    hideUBODeclaration,
    isCreateFundApplicationForm,
  } = props;
  const { countryOptions } = useCountry();
  const { watch, control, getFieldState, setValue, setError, clearErrors } =
    useFormContext<IInvestmentEntityForm>();
  const {
    fields: publicOfficers,
    append: addPublicOfficers,
    remove: removePublicOfficers,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.association.publicOfficers',
    keyName: 'key',
  });
  const {
    fields: ubOs,
    append: addUbOs,
    remove: removeUbOs,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.association.ubOs',
    keyName: 'key',
  });

  const { error: publicOfficersError } = getFieldState(
    `investmentEntityDetails.association.publicOfficers`,
  );
  const watchType = watch('investmentEntityDetails.association.type');

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Other,
  });

  const handleAddPublicOfficers = () => {
    addPublicOfficers(individualDefaultValue(true));
  };

  const handleAddUbOs = () => {
    addUbOs(individualDefaultValue());
  };

  const handleDeletePublicOfficer = (index: number) => {
    removePublicOfficers(index);
  };

  const handleDeleteUbOs = (index: number) => {
    removeUbOs(index);
  };

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    if (reason === 'selectOption') {
      const isEntityTypeMatch = handleCheckEntityTypeMatch(
        skyc as OptionType & ISkyc,
        'association',
      );
      const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

      if (isEntityTypeMatch) {
        setValue('investmentEntityDetails.association.abn', skyc.value);
        setValue('investmentEntityDetails.association.associationName', skyc.label);
        if (isNotAllowed) {
          setError('investmentEntityDetails.association.abn', {
            message: 'ABN is invalid',
            type: 'invalidAbn',
          });
        }
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const skycOptions = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  const disabled = isExistingInvestmentEntity || isViewMode || isDisabledEdit;

  return (
    <Box>
      <Grid container rowSpacing='18px' columnSpacing='20px'>
        {!isExistingInvestmentEntity && !hideAbnSearch && !isInvestmentEntitySetting && (
          <Grid xs={12} item>
            <FormAutocomplete
              name='skyc'
              dataKey='id'
              options={skycOptions}
              searchIcon={<SearchIcon />}
              isLoading={isSearchSkycLoading}
              placeholder='Search by Business Name, ABN'
              hidePopupIcon
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                  <Box className='flex flex-col'>
                    <Typography variant='body3' fontWeight='medium'>
                      {option.label}
                    </Typography>
                    <Box className='flex items-center pt-1'>
                      <Typography variant='caption1' color='neutral.ne500' paddingRight={3}>
                        {`ABN: ${option.value}`}
                      </Typography>
                    </Box>
                  </Box>
                </li>
              )}
              filterOptions={(options) => options}
              getOptionLabel={() => ''}
              onInputChange={handleSearchSkycInputChange}
              onChange={handleSkycChange}
              onFocus={() => clearErrors('investmentEntityDetails.association.abn')}
              disabled={disabled}
              blurOnSelect
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.association.associationName'
            label='Association Name'
            placeholder='Enter association name'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.association.abn'
            label='ABN'
            placeholder='Enter ABN'
            formatValue={(value) => formatValueWithSpaces(value)}
            maxLength={14}
            disabled={disabled || disabledAbn}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name={`investmentEntityDetails.association.countryOfEstablishment`}
            options={countryOptions}
            label='Country of Establishment'
            placeholder='Select country of establishment'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name={`investmentEntityDetails.association.countryOfTaxResident`}
            label='Country of Tax Residence'
            placeholder='Select country of tax residence'
            disabled={disabled}
            options={countryOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.association.registeredOffice'
            label='Principal Place of Business'
            placeholder='Enter principal place of business'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.association.associationNumber'
            label='Association Number'
            placeholder='Enter association number'
            disabled={disabled || isInvestmentEntitySetting}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityDetails.association.type'
            label='Type'
            placeholder='Select type'
            options={associationTypeOptions}
            disabled={disabled}
          />
        </Grid>
        {watchType === ASSOCIATION_TYPE.Other && (
          <Grid item xs={6} alignSelf='flex-end'>
            <FormInput
              name='investmentEntityDetails.association.otherType'
              label=''
              placeholder='Enter other type'
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 3 }} />
      <UploadCertified
        {...props}
        title='Upload Certified Constitution or Rules of the Association'
        keyName='investmentEntityDetails.association'
        fileTableTitle={
          <Typography variant='body3' fontWeight={500}>
            Files Uploaded
          </Typography>
        }
        containerSx={{
          pt: 0,
        }}
      />
      <Divider sx={{ my: 3 }} />
      <FormArrayLayout
        {...props}
        title='Public Officers'
        description='Provide details of the chair, secretary and treasurer or equivalent officer.'
        onAddItem={handleAddPublicOfficers}
        disabled={disabled}
        helperText={<CustomHelperText variant='error' message={publicOfficersError?.message} />}
      >
        <Grid container rowSpacing='16px' mb='16px'>
          {publicOfficers.map((it, index) => (
            <Grid key={it.key} item xs={12}>
              <IndividualDetail
                {...props}
                keyName={`investmentEntityDetails.association.publicOfficers.${index}`}
                canDelete
                hasPosition
                handleDelete={() => handleDeletePublicOfficer(index)}
                hideTfn
                autocompleteAddress
                hideDeclaration={isCreateFundApplicationForm || hideUBODeclaration}
              />
            </Grid>
          ))}
        </Grid>
      </FormArrayLayout>
      <Divider sx={{ my: 3 }} />
      {!hideUbo && (
        <FormArrayLayout
          {...props}
          title='Ultimate Beneficial Owners'
          description={UBOS_META.ASSOCIATION.DESCRIPTION}
          tooltip={UBOS_META.COMMON.TOOLTIP}
          onAddItem={handleAddUbOs}
          disabled={disabled}
        >
          <Grid container rowSpacing='16px' mb='16px'>
            {publicOfficers.map((it, index) => (
              <Grid key={it.key} item xs={12}>
                <IndividualDetail
                  {...props}
                  keyName={`investmentEntityDetails.association.publicOfficers.${index}`}
                  hasPosition
                  hideTfn
                  disabledTaxInfo
                  disabledDetail
                  disabledPosition
                  autocompleteAddress
                  hideDeclaration={hideUBODeclaration}
                />
              </Grid>
            ))}
            {ubOs.map((it, index) => (
              <Grid key={it.key} item xs={12}>
                <IndividualDetail
                  {...props}
                  keyName={`investmentEntityDetails.association.ubOs.${index}`}
                  handleDelete={() => handleDeleteUbOs(index)}
                  hasPosition
                  canDelete={true}
                  hideTfn
                  autocompleteAddress
                  hideDeclaration={hideUBODeclaration}
                />
              </Grid>
            ))}
          </Grid>
        </FormArrayLayout>
      )}
    </Box>
  );
};

export default Association;
