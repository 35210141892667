const RegulatedTrustIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>EEE78887-AF7E-48CB-B838-60CB4E0E44FF@2x</title>
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Trust_Unregulated_01'
          transform='translate(-494.000000, -638.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(196.000000, 365.000000)'>
                <g id='individual' transform='translate(8.000000, 6.000000)'>
                  <mask id='mask-2' fill='white'>
                    <use xlinkHref='#path-1'></use>
                  </mask>
                  <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                  <g id='safety' mask='url(#mask-2)'>
                    <g transform='translate(11.000000, 8.000000)'>
                      <g id='Group'>
                        <path
                          d='M16,37 C15.1023158,37 14.2442105,36.72205 13.5191579,36.1962892 L7.95452632,32.1609911 C2.97347368,28.5484783 0,22.7332956 0,16.6041634 L0,4.18599389 C0,1.87783686 1.88884211,0 4.21052632,0 L27.7894737,0 C30.1111579,0 32,1.87783686 32,4.18599389 L32,16.6041634 C32,22.7341328 29.0265263,28.5493155 24.0454737,32.1609911 L18.4816842,36.1962892 C17.7557895,36.72205 16.8976842,37 16,37 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M15.0071579,34.1669193 L9.44252632,30.130784 C5.09642105,26.9795678 2.52631579,21.9521892 2.52631579,16.6041634 L2.52631579,4.18599389 C2.52631579,3.26088924 3.28,2.51159633 4.21052632,2.51159633 L27.7894737,2.51159633 C28.72,2.51159633 29.4736842,3.26088924 29.4736842,4.18599389 L29.4736842,16.6041634 C29.4736842,21.9530264 26.9035789,26.9795678 22.5566316,30.1316212 L16.992,34.1669193 C16.4008421,34.5955651 15.5991579,34.5955651 15.0071579,34.1669193 Z'
                          id='Path'
                          fill='#FFFFFF'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M29.4736842,4.18599389 L29.4736842,5.02319267 C29.4736842,4.10227401 28.7157895,3.34879511 27.7894737,3.34879511 L4.21052632,3.34879511 C3.28421053,3.34879511 2.52631579,4.10227401 2.52631579,5.02319267 L2.52631579,4.18599389 C2.52631579,3.26507523 3.28421053,2.51159633 4.21052632,2.51159633 L27.7894737,2.51159633 C28.7157895,2.51159633 29.4736842,3.26507523 29.4736842,4.18599389 Z'
                          id='Path'
                          fill='#D2D2D2'
                          fillRule='nonzero'
                        ></path>
                      </g>
                      <g id='unlock' transform='translate(10.000000, 9.000000)'>
                        <path
                          d='M6,0 C3.46728946,0.0028963783 1.41485554,1.91413631 1.41174524,4.27268181 L1.41174524,6.90200786 C1.41174524,7.08352451 1.56977494,7.23068518 1.7646981,7.23068518 L3.17647643,7.23068518 C3.37139958,7.23068518 3.52942928,7.08352451 3.52942928,6.90200786 L3.52942928,4.27268181 C3.52939619,3.00203448 4.63553793,1.97200225 6,1.97200225 C7.36446207,1.97200225 8.47060381,3.00203448 8.47060381,4.27268181 L8.47060381,5.58736024 C8.47060381,5.76887689 8.62863351,5.91603755 8.82355666,5.91603755 L10.2353019,5.91603755 C10.4302251,5.91603755 10.5882548,5.76887689 10.5882548,5.58736024 L10.5882548,4.27268181 C10.5851445,1.91413631 8.53271054,0.0028963783 6,0 Z'
                          id='Path'
                          fill='#59666C'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M1.7646981,5.79731258 L10.2353019,5.79731258 C11.2099177,5.79731258 12,6.53305429 12,7.44063751 L12,13.3566443 C12,14.2642583 11.2099177,15 10.2353019,15 L1.7646981,15 C0.790082335,15 0,14.2642583 0,13.3566751 L0,7.44066832 C0,6.53305429 0.790082335,5.79731258 1.7646981,5.79731258 Z'
                          id='Path'
                          fill='#39BC88'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M7.76471407,9.41267057 C7.76880105,8.50508735 6.98206063,7.76623357 6.00744487,7.76239794 C5.0328291,7.75859206 4.23940485,8.49122171 4.23528607,9.39880493 C4.23245631,10.0261481 4.61340186,10.6002473 5.217164,10.8785153 L4.94468188,12.6533173 C4.91738404,12.8330468 5.05172252,12.9993421 5.24472655,13.0247624 C5.26107217,13.0269193 5.27758323,13.028014 5.29409429,13.028014 L6.70587262,13.028014 C6.90079577,13.0297849 7.06034754,12.8841032 7.06226666,12.7025866 C7.0624321,12.6861019 7.06127401,12.669648 7.05879239,12.6533173 L6.78631027,10.8785153 C7.38279298,10.6003705 7.76092603,10.0338205 7.76471407,9.41267057 Z'
                          id='Path'
                          fill='#59666C'
                          fillRule='nonzero'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RegulatedTrustIcon;
