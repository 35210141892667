import {
  Box,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';

export interface Item {
  id: string;
  name: string;
  uboId?: string;
  clientId?: string;
  children?: Item[];
  parentRootId?: string;
  isFmRecipient?: boolean;
}
export type IMultiLevelSelectHandler = {
  clear: () => void;
};
interface IMultiLevelSelectProps {
  data: Item[];
  onSelect: (items: Item[]) => void;
  disabled?: boolean;
  selected?: Item[];
  selectAllChildren?: boolean;
  hideUnselectedItems?: boolean;
}

export const MultiLevelSelect = React.forwardRef<IMultiLevelSelectHandler, IMultiLevelSelectProps>(
  (
    { data, selected, onSelect, disabled, selectAllChildren = false, hideUnselectedItems = false },
    ref,
  ) => {
    const [selectedItems, setSelectedItems] = useState<Item[]>(selected || []);

    useImperativeHandle(ref, () => ({
      clear: () => setSelectedItems([]),
    }));

    useEffect(() => {
      if (!isEmpty(selected)) {
        setSelectedItems(selected || []);
      }
    }, [selected]);

    useEffect(() => {
      handleGetLatestSelected();
    }, [selectedItems]);

    const handleCheckboxChange = (item: Item) => {
      let updatedSelection: Item[] = [];
      const allChildrenSelection = handleGetAllChildrenOfSelection(item);

      if (
        selectedItems.findIndex((f) =>
          f?.parentRootId
            ? f.id === item.id && f.parentRootId === item?.parentRootId
            : f.id === item.id,
        ) !== -1
      ) {
        // Uncheck all children of selection when user uncheck selection
        updatedSelection = selectedItems.filter(
          (selected) =>
            !allChildrenSelection.some((it) =>
              it?.parentRootId
                ? it?.id === selected?.id && it.parentRootId === selected.parentRootId
                : it?.id === selected?.id,
            ),
        );
      } else {
        // Auto select all children when user select the parent if selectAllChildren is true
        updatedSelection = selectAllChildren
          ? [...selectedItems, ...allChildrenSelection]
          : [...selectedItems, item];
      }

      setSelectedItems(updatedSelection);
    };

    const handleGetAllChildrenOfSelection = (item: Item) => {
      const result: Item[] = [];
      const getAllChildren = (item: Item) => {
        result.push(item);
        if (item?.children) {
          item.children.forEach((it) => {
            getAllChildren(it);
          });
        }
      };
      getAllChildren(item);

      return result;
    };

    let latestSelected: Item[] = [];
    const getLatestSelectedChildren = (items: Item[], selected: Item[]): Item[] => {
      items.forEach((item) => {
        const isSelected = selected.findIndex((f) => f.id === item.id) !== -1;
        if (isSelected && !isEmpty(item.children)) {
          const childSelection = getLatestSelectedChildren(item.children || [], selected);
          if (childSelection.length > 0) {
            latestSelected = childSelection;
          }
        } else if (isSelected) {
          latestSelected.push(item);
        }
      });

      return latestSelected;
    };

    const handleGetLatestSelected = () => {
      const latestSelected = getLatestSelectedChildren(data, selectedItems);
      onSelect(latestSelected);
    };

    const renderCheckboxes = (items: Item[], isChildren?: boolean) => {
      if (hideUnselectedItems && !selectedItems?.length) return <></>;
      return (
        <List
          component='nav'
          disablePadding={isChildren}
          className={isChildren ? 'mt-0 py-0 pl-[20px]' : 'm-0'}
        >
          {(items || [])
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .map((item, index) => {
              const isChecked =
                selectedItems.findIndex((f) =>
                  f?.parentRootId
                    ? f.id === item.id && f.parentRootId === item.parentRootId
                    : f.id === item.id,
                ) !== -1;
              return (
                <React.Fragment key={`client-${index}`}>
                  <ListItemButton
                    className='p-0'
                    disabled={disabled}
                    sx={{
                      display: hideUnselectedItems ? (isChecked ? 'flex' : 'none') : 'flex',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox checked={isChecked} onChange={() => handleCheckboxChange(item)} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant='body2'>{item.name}</Typography>
                    </ListItemText>
                  </ListItemButton>
                  {isChecked &&
                    !isEmpty(item.children) &&
                    renderCheckboxes(item.children || [], true)}
                </React.Fragment>
              );
            })}
        </List>
      );
    };

    return <Box>{renderCheckboxes(data)}</Box>;
  },
);
