/* eslint-disable no-empty-pattern */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AuditTrailIcon } from 'src/assets/icons/AuditTrailIcon';
import { CheckCircleIcon } from 'src/assets/icons/CheckCircleIcon';
import { CrossCircleIcon } from 'src/assets/icons/CrossCircleIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import { RiskFlagIcon } from 'src/assets/icons/RiskFlagIcon';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import StatusBadge from 'src/components/molecules/StatusBadge';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import LoadingComponent from 'src/components/pages/applications/components/InvestmentEntity/shared/LoadingComponent';
import {
  FLAG_COLOR,
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  financialTaxDetailsDefaultValues,
  individualDefaultValue,
  investmentEntityDefaultValues,
} from 'src/constants/applications';
import { KYC_SECTION_ENUM, riskFlagStatus } from 'src/constants/kyc-back-office';
import { transformCompanyFlagSections } from 'src/helpers/sykc';
import { useApplicationSkycCreateApp, useGetApplicationById } from 'src/modules/applications/hooks';
import {
  IFinancialTaxDetailsForm,
  IInvestmentEntityForm,
  ISkycGetApp,
} from 'src/modules/applications/type';
import { useGetKYCBackOfficeById, useGetKycBoSkycApp } from 'src/modules/kyc-back-office/hooks';
import { handleErrorFromServer, swapArrayElements } from 'src/utils/common';
import {
  finacialAndTaxDetailsSchema,
  investmentEntityValidationSchema,
} from 'src/validations/applications';
import Company from './Company';
import Individuals from './Individuals';
import Other from './Other';
import Partnership from './Partnership';
import SMSF from './SMSF';
import SoleTrader from './SoleTrader';
import TaxInformation from './TaxInformation';
import Trust from './Trust';

interface IInvestorDetailsProps {
  id?: string;
  isEditMode?: boolean;
  onOpenAuditTrail: () => void;
}

const InvestorDetails: React.FC<IInvestorDetailsProps> = ({
  id = '',
  isEditMode,
  onOpenAuditTrail,
}) => {
  const theme = useTheme();
  const { watch } = useFormContext();
  const entityType = watch('investorDetails.entityType');

  const riskFlagDrawerRef = React.useRef<ICustomDrawerElement>(null);
  const loadingModalRef = React.useRef<IBasicModalElement>(null);
  const [skycAppData, setSkycAppData] = React.useState<ISkycGetApp>();

  const { data: { applicationId = '', investorDetails } = {} } = useGetKYCBackOfficeById(
    id,
    KYC_SECTION_ENUM.INVESTOR_DETAILS,
  );
  const { data: applicationDetail } = useGetApplicationById(applicationId, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });
  const { data: { investmentEntity } = {} } = useGetApplicationById(applicationId, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const { mutate: skycGetAppMutate } = useGetKycBoSkycApp();
  const { mutate: skycCreateAppMutate } = useApplicationSkycCreateApp();

  const investmentEntityForm = useForm<IInvestmentEntityForm>({
    defaultValues: investmentEntityDefaultValues,
    resolver: yupResolver(investmentEntityValidationSchema),
  });
  const financialTaxDetailsForm = useForm<IFinancialTaxDetailsForm>({
    defaultValues: financialTaxDetailsDefaultValues,
    resolver: yupResolver(finacialAndTaxDetailsSchema),
  });

  const skycApplicationId = investmentEntity?.investmentEntityDetails?.skycApplicationId;

  console.log('skycApplicationId', skycApplicationId);

  const kycReport = skycAppData?.data?.weblinks?.report;

  useEffect(() => {
    if (!isEmpty(investmentEntity)) {
      handleFillInvestmentEntityDetails(investmentEntity);
    }
  }, [investmentEntity]);

  useEffect(() => {
    handleGetFlag();
  }, [investmentEntity]);

  const entityProps = {
    id: applicationId,
    offerId: applicationDetail?.offerId,
    fundId: applicationDetail?.fundId,
    unitClassId: applicationDetail?.unitClassId,
    isViewMode: true,
    hideAbnSearch: true,
    hideUbo: true,
    hideCertifiedDescription: true,
  };

  const renderInvestorDetailByType = () => {
    switch (entityType) {
      case INVESTMENT_ENTITY_TYPE.Individuals:
        return <Individuals {...entityProps} />;
      case INVESTMENT_ENTITY_TYPE.SoleTrader:
        return <SoleTrader {...entityProps} />;
      case INVESTMENT_ENTITY_TYPE.Company:
        return (
          <FormProvider {...investmentEntityForm}>
            <Company {...entityProps} />
          </FormProvider>
        );
      case INVESTMENT_ENTITY_TYPE.Trust:
        return (
          <FormProvider {...investmentEntityForm}>
            <Trust {...entityProps} />
          </FormProvider>
        );
      case INVESTMENT_ENTITY_TYPE.SMSF:
        return (
          <FormProvider {...investmentEntityForm}>
            <SMSF {...entityProps} />
          </FormProvider>
        );
      case INVESTMENT_ENTITY_TYPE.Partnership:
        return (
          <FormProvider {...investmentEntityForm}>
            <Partnership {...entityProps} />
          </FormProvider>
        );
      case INVESTMENT_ENTITY_TYPE.Other:
        return (
          <FormProvider {...investmentEntityForm}>
            <Other {...entityProps} />
          </FormProvider>
        );
      default:
        break;
    }
  };

  const renderTaxInformation = () => {
    if (
      entityType === INVESTMENT_ENTITY_TYPE.Individuals ||
      entityType === INVESTMENT_ENTITY_TYPE.SoleTrader
    )
      return null;
    return (
      <FormProvider {...financialTaxDetailsForm}>
        <TaxInformation {...entityProps} />
      </FormProvider>
    );
  };

  const handleFillInvestmentEntityDetails = (investmentEntity: IInvestmentEntityForm) => {
    const {
      australianCompany,
      foreignCompany,
      association,
      governmentBody,
      regulatedTrust,
      unregulatedTrust,
      smsf,
      partnership,
    } = investmentEntity?.investmentEntityDetails || {};

    const australianCompanyDirectors =
      (australianCompany?.directors || []).map((it) => it.refId) || [];
    const australianCompanyUBOs: any = {};
    (australianCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        australianCompanyUBOs[it.refId] = it;
      }
    });

    const foreignCompanyDirectorRefs =
      (foreignCompany?.directors || []).map((it) => it.refId) || [];
    const foreignCompanyUBOs: any = {};
    (foreignCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        foreignCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeRegulatedTrustAustralianCompanyDirectors =
      regulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeRegulatedTrustAustralianCompanyUBOs: any = {};
    regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeRegulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.appointerAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const appointerUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        appointerUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerSelectedExistingIndividuals =
      unregulatedTrust?.appointerIndividuals
        ?.filter((it) =>
          (unregulatedTrust?.trusteeAustralianCompanyDetails?.directors || [])
            .concat(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs || [])
            .concat(unregulatedTrust?.trusteeIndividuals || [])
            .map((item) => item.refId)
            .includes(it.refId),
        )
        ?.map((it) => it.refId || '') || [];

    const trusteeSMSFAustralianCompanyDirectors =
      smsf?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeSMSFAustralianCompanyUBOs: any = {};
    smsf?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeSMSFAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const associationPublicOfficerRefs = (association?.publicOfficers || []).map((it) => it.refId);
    const associationUbos: any = (association?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const governmentBodyPublicOfficerRefs = (governmentBody?.publicOfficers || []).map(
      (it) => it.refId,
    );
    const governmentBodyUbos: any = (governmentBody?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const partnershiRefPartners = (partnership?.partners || []).map((it) => it.refId) || [];
    const partnershipUbos: any = {};
    (partnership?.ubOs || []).forEach((it) => {
      if (it.refId) {
        partnershipUbos[it.refId] = it;
      }
    });
    const beVerifiedPartnerIndex =
      (partnership?.partners || []).findIndex((it) => it?.beVerified) || 0;
    const partnershipPartners = (partnership?.partners || []).map(
      (it) => (it.refId && partnershipUbos[it.refId]) || it,
    );

    investmentEntityForm.reset((formState) => ({
      ...formState,
      ...investmentEntity,
      investmentEntityDetails: {
        ...investmentEntity.investmentEntityDetails,
        numberOfIndividuals: investmentEntity.investmentEntityDetails?.individuals?.length || null,
        australianCompany: {
          ...australianCompany,
          ...(australianCompany?.ubOs
            ? {
                ubOs: australianCompany.ubOs.filter(
                  (it) => !australianCompanyDirectors.includes(it.refId),
                ),
              }
            : {}),
          ...(australianCompany?.directors
            ? {
                directors: australianCompany.directors.map(
                  (it) =>
                    (it.refId && australianCompanyUBOs[it.refId]) || {
                      ...it,
                      populatedDirector: true,
                    },
                ),
              }
            : {}),
        },
        foreignCompany: {
          ...(foreignCompany || {}),
          ubOs: (foreignCompany?.ubOs || []).filter(
            (it) => !foreignCompanyDirectorRefs.includes(it.refId),
          ),
          directors: (foreignCompany?.directors || []).map(
            (it) => (it.refId && foreignCompanyUBOs[it.refId]) || it,
          ),
        },
        regulatedTrust: {
          ...regulatedTrust,
          numberOfIndividuals: regulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: regulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...regulatedTrust?.trusteeAustralianCompanyDetails,
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: regulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeRegulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: regulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeRegulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        unregulatedTrust: {
          ...unregulatedTrust,
          numberOfIndividuals: unregulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: unregulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          settlor:
            unregulatedTrust?.isSettlorMoreThanMax && unregulatedTrust?.settlor
              ? unregulatedTrust.settlor
              : individualDefaultValue(true),
          trusteeAustralianCompanyDetails: {
            ...unregulatedTrust?.trusteeAustralianCompanyDetails,
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
          appointerAustralianCompanyDetails: {
            ...unregulatedTrust?.appointerAustralianCompanyDetails,
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.appointerAustralianCompanyDetails.ubOs.filter(
                    (it) => !appointerUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.appointerAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && appointerUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
            isManualEntry:
              (unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
                unregulatedTrust?.isAppointerSameAsTrustee === false) ||
              unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
          },
          selectedExistingIndividuals: appointerSelectedExistingIndividuals,
          appointerIndividuals:
            unregulatedTrust?.appointerIndividuals?.filter(
              (it) => !appointerSelectedExistingIndividuals.includes(it.refId || ''),
            ) || [],
        },
        smsf: {
          ...smsf,
          numberOfIndividuals: smsf?.trusteeIndividuals?.length || null,
          smsfubOs: smsf?.smsfubOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...smsf?.trusteeAustralianCompanyDetails,
            ...(smsf?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: smsf.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeSMSFAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(smsf?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: smsf.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeSMSFAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        association: {
          ...(association || {}),
          ubOs: (association?.ubOs || []).filter(
            (it) => !associationPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (association?.publicOfficers || []).map(
            (it) => (it.refId && associationUbos[it.refId]) || it,
          ),
        },
        governmentBody: {
          ...(governmentBody || {}),
          ubOs: (governmentBody?.ubOs || []).filter(
            (it) => !governmentBodyPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (governmentBody?.publicOfficers || []).map(
            (it) => (it.refId && governmentBodyUbos[it.refId]) || it,
          ),
        },
        partnership: {
          ...(partnership || {}),
          ubOs: (partnership?.ubOs || []).filter(
            (it) => !it?.refId || !partnershiRefPartners.includes(it.refId),
          ),
          partners: swapArrayElements(partnershipPartners, beVerifiedPartnerIndex, 0),
          certifiedAgreementFile: partnership?.certifiedPartnershipAgreementFile,
          certifiedAgreementFileId: partnership?.certifiedPartnershipAgreementFileId,
          certifiedAgreementFileDescription:
            partnership?.certifiedPartnershipAgreementFileDescription,
        },
      },
      isAddNewEntityDetail: Number.isInteger(investmentEntity.entityType),
    }));
  };

  const handleGetFlag = () => {
    if (!skycApplicationId) return;
    skycGetAppMutate(skycApplicationId, {
      onSuccess: (data) => setSkycAppData(data),
    });
  };

  const renderFlagSection = () => {
    if (!skycAppData) return 'No data to display';
    const cloneFlags = cloneDeep(skycAppData?.data?.flagsToInvestigate);
    const companyFlagsToRender = transformCompanyFlagSections(cloneFlags);

    const getRiskFlagData = (colour: (typeof FLAG_COLOR)[keyof typeof FLAG_COLOR]) => {
      switch (colour) {
        case FLAG_COLOR.RED:
          return { status: riskFlagStatus.High, color: theme.palette.error.main };
        case FLAG_COLOR.ORANGE:
          return { status: riskFlagStatus.Medium, color: theme.palette.warning.main };
        case FLAG_COLOR.BLUE:
          return { status: riskFlagStatus.Low, color: theme.palette.info.main };
        default:
          return { status: '', color: '' };
      }
    };

    const otherData = [
      {
        label: 'Banned Directors Checks',
        status: skycAppData?.data?.entitiesDetail?.[0]?.sources?.find((it) =>
          it?.source?.startsWith('ASIC Banned / Disqualified Persons'),
        )?.status,
      },
      {
        label: 'Banned Organisation Checks',
        status: skycAppData?.data?.entitiesDetail?.[0]?.sources?.find((it) =>
          it?.source?.startsWith('ASIC Banned / Disqualified Organisations'),
        )?.status,
      },
      {
        label: 'PEP and Sanctions',
        status: skycAppData?.data?.interestedPersons?.[0]?.pepAndSanctions,
      },
      {
        label: 'Adverse Media Result',
        status: skycAppData?.data?.interestedPersons?.[0]?.statusMediaCheck,
      },
    ];

    return (
      <Box className='flex flex-col'>
        {!!companyFlagsToRender?.length && (
          <Box>
            <Typography fontSize='20px' fontWeight={600}>
              Case Flag Details
            </Typography>
            <Box mt={3} className='flex flex-col' gap={3}>
              {companyFlagsToRender.map((it, index) => (
                <Box key={index} className='flex items-center'>
                  {it.colour && (
                    <Box
                      className='w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0'
                      mr={1}
                      bgcolor='neutral.ne100'
                    >
                      <RiskFlagIcon
                        width='18'
                        height='18'
                        color={getRiskFlagData(it.colour).color}
                      />
                    </Box>
                  )}
                  <Typography variant='body2' fontWeight={500}>
                    {it.message}
                    {it.colour && (
                      <Box display='inline-flex' ml={1}>
                        <StatusBadge status={getRiskFlagData(it.colour).status} showDot={false} />
                      </Box>
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box mt={4} className='flex flex-col gap-6'>
          {otherData.map((it, index) => {
            if (!it.status) return null;
            const isError = it.status.startsWith('No') || it.status.startsWith('Not');
            return (
              <Box key={index}>
                <Typography fontSize='20px' fontWeight={600}>
                  {it.label}
                </Typography>
                <Box mt={1} className='flex items-center'>
                  <Box
                    className='w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0'
                    mr={1}
                    bgcolor='neutral.ne100'
                  >
                    {isError ? <CrossCircleIcon /> : <CheckCircleIcon />}
                  </Box>
                  <Typography
                    variant='body2'
                    fontWeight={500}
                    color={isError ? 'error.main' : 'success.main'}
                  >
                    {it.status}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  const handleSkycGetApp = (applicationId: string) => {
    skycGetAppMutate(applicationId, {
      onSuccess: (data) => {
        setSkycAppData(data);
        loadingModalRef?.current?.close();
      },
      onError: (error) => {
        handleErrorFromServer(error);
        loadingModalRef.current?.close();
      },
    });
  };

  const handleGetACNABN = () => {
    if (investmentEntityForm.getValues()?.entityType === INVESTMENT_ENTITY_TYPE.Company) {
      return {
        cn: investmentEntityForm.getValues()?.investmentEntityDetails?.australianCompany?.acn || '',
        bn: investmentEntityForm.getValues()?.investmentEntityDetails?.australianCompany?.abn || '',
      };
    } else if (investmentEntityForm.getValues()?.entityType === INVESTMENT_ENTITY_TYPE.Trust) {
      if (investmentEntityForm.getValues()?.trustType === INVESTMENT_ENTITY_TRUST.Regulated) {
        return {
          cn: investmentEntityForm.getValues()?.investmentEntityDetails?.regulatedTrust?.acn || '',
          bn: investmentEntityForm.getValues()?.investmentEntityDetails?.regulatedTrust?.abn || '',
        };
      }
      return {
        cn: investmentEntityForm.getValues()?.investmentEntityDetails?.unregulatedTrust?.acn || '',
        bn: investmentEntityForm.getValues()?.investmentEntityDetails?.unregulatedTrust?.abn || '',
      };
    } else if (investmentEntityForm.getValues()?.entityType === INVESTMENT_ENTITY_TYPE.SMSF) {
      return {
        cn: investmentEntityForm.getValues()?.investmentEntityDetails?.smsf?.acn || '',
        bn: investmentEntityForm.getValues()?.investmentEntityDetails?.smsf?.abn || '',
      };
    }
  };

  const handleCreateApp = () => {
    loadingModalRef?.current?.open();
    skycCreateAppMutate(
      {
        cn: handleGetACNABN()?.cn || '',
        bn: handleGetACNABN()?.bn || '',
        id: applicationId,
      },
      {
        onSuccess: (data: { applicationId: string }) => {
          handleSkycGetApp(data?.applicationId);
        },
        onError: (error) => {
          handleErrorFromServer(error);
          loadingModalRef?.current?.close();
        },
      },
    );
  };

  const renderKycReport = () => {
    const show =
      entityType === INVESTMENT_ENTITY_TYPE.Company ||
      (entityType === INVESTMENT_ENTITY_TYPE.Trust &&
        investmentEntity?.trustType === INVESTMENT_ENTITY_TRUST.Regulated &&
        investmentEntity?.investmentEntityDetails?.regulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) ||
      (entityType === INVESTMENT_ENTITY_TYPE.Trust &&
        investmentEntity?.trustType === INVESTMENT_ENTITY_TRUST.Unregulated &&
        investmentEntity?.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) ||
      (entityType === INVESTMENT_ENTITY_TYPE.SMSF &&
        investmentEntity?.investmentEntityDetails?.smsf?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate);
    if (!show) return null;

    return (
      <>
        <Box className='flex flex-col'>
          <CustomButton
            startIcon={<SearchIcon color='inherit' />}
            sx={{ width: 'fit-content', px: 2 }}
            disabled={!isEditMode || !!kycReport}
            onClick={handleCreateApp}
          >
            Run KYB Search
          </CustomButton>
          {!!kycReport && (
            <Box
              className='flex flex-col items-center w-fit mt-8 cursor-pointer'
              onClick={() => window.open(kycReport, '_blank')}
            >
              <PDFIcon width='80' height='80' />
              <Typography variant='body2' mt={2}>
                KYC report
              </Typography>
            </Box>
          )}
        </Box>
        <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      </>
    );
  };

  const handleAuditTrail = () => onOpenAuditTrail();

  return (
    <Box
      sx={{
        '.individual-detail, .director-detail': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <Box className='flex gap-2' position='absolute' right={0} top='-40px'>
        <CustomButton
          sx={{ color: 'sematic.danger500', borderColor: 'sematic.danger500', border: 1, px: 2 }}
          variant='text'
          startIcon={<RiskFlagIcon />}
          onClick={() => riskFlagDrawerRef.current?.open()}
        >
          Risk Flag
        </CustomButton>
        <CustomButton
          sx={{
            color: 'sematic.success500',
            borderColor: 'sematic.success500',
            border: 1,
            px: 2,
          }}
          variant='text'
          startIcon={<AuditTrailIcon />}
          onClick={handleAuditTrail}
        >
          Audit Trail
        </CustomButton>
      </Box>
      {investorDetails?.completionBanner ? (
        <CustomAlert
          severity='info'
          sx={{
            my: 2,
          }}
        >
          {investorDetails?.completionBanner}
        </CustomAlert>
      ) : null}
      {entityType === INVESTMENT_ENTITY_TYPE.SMSF &&
        investorDetails?.entityDetails?.superFundStatus && (
          <Box display='flex' alignItems='center' mb={3}>
            <Typography variant='body2' fontWeight={500} mr={1}>
              SMSF Status
            </Typography>
            <StatusBadge
              status={investorDetails?.entityDetails?.superFundStatus || ''}
              defaultColor={theme.palette.success.main}
              defaultBgColor={theme.palette.success.light}
              showDot={false}
            />
          </Box>
        )}
      {renderInvestorDetailByType()}
      {entityType === INVESTMENT_ENTITY_TYPE.Other &&
        investmentEntity?.otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody && (
          <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
        )}
      {renderKycReport()}
      {renderTaxInformation()}
      <CustomDrawer
        ref={riskFlagDrawerRef}
        title='Risk Flags'
        ButtonComponents={<></>}
        onClose={() => riskFlagDrawerRef.current?.close()}
      >
        <Box className='pt-8 flex flex-col'>{renderFlagSection()}</Box>
      </CustomDrawer>
      <BasicModal ref={loadingModalRef} disableClose>
        <LoadingComponent />
      </BasicModal>
    </Box>
  );
};

export default InvestorDetails;
