import { AUDIT_TRAIL_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';

import { portalApi } from 'src/helpers/axios';
import { AuditTrailQueryBody } from './type';

export const getAuditTrailList = async (data: AuditTrailQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUDIT_TRAIL_API.getAuditTrailList.api,
  });

  return result.data;
};

export const getAuditTrailModuleDetails = async (moduleId: string, data: AuditTrailQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: AUDIT_TRAIL_API.getAuditTrailModuleDetails.api(moduleId),
  });

  return result.data;
};
