import { ROLE_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { IRoleItem, IRoleListResponse, IUserRole } from './type';

export const getRole = () => ({
  name: 'admin',
  description: 'admin',
  features: [
    {
      name: 'UserManagement',
      description: 'desc',
      permissions: {
        isFullAdmin: true,
        isCreate: true,
        isEdit: true,
        isDelete: true,
        isView: true,
      },
    },
    {
      name: 'ClientManagement',
      description: 'desc',
      permissions: {
        isFullAdmin: false,
        isCreate: true,
        isEdit: true,
        isDelete: false,
        isView: true,
      },
    },
  ],
});

export const createRole = async (data: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ROLE_API.role.api,
  });
  return result.data;
};

export const getUserRole = async (): Promise<IUserRole> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ROLE_API.getUserRole.api,
  });

  return result.data;
};

export const getRoleById = async (roleId: string): Promise<IRoleItem> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: ROLE_API.roleById.api(roleId),
  });

  return result.data;
};

export const getRoleList = async (data: any): Promise<IRoleListResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: ROLE_API.getRoleList.api,
  });

  return result.data;
};

export const updateRole = async (roleId: string, data: IRoleItem): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: ROLE_API.roleById.api(roleId),
  });

  return result.data;
};

export const deleteRole = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: ROLE_API.deleteRole.api(id),
  });

  return result.data;
};
