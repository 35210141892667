/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IUnLockIconnProps {}

export const UnLockIcon: FC<IUnLockIconnProps> = () => {
  return (
    <svg
      width='60px'
      height='60px'
      viewBox='0 0 60 60'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='00_Forgot-Password_05' transform='translate(-691.000000, -275.000000)'>
          <g id='Modal' transform='translate(478.000000, 215.000000)'>
            <g id='Icon' transform='translate(213.000000, 60.000000)'>
              <g id='01.Design-Token/02.Background/03.Soild/50' fill='#E3F4EC'>
                <rect id='Background' x='0' y='0' width='60' height='60' rx='30'></rect>
              </g>
              <g id='-Round-/-Action-/-lock_open' transform='translate(12.857143, 12.857143)'>
                <polygon
                  id='Path'
                  points='0 0 34.2857143 0 34.2857143 34.2857143 0 34.2857143'
                ></polygon>
                <path
                  d='M17.1428571,19.1836735 C15.5714286,19.1836735 14.2857143,20.4081633 14.2857143,21.9047619 C14.2857143,23.4013605 15.5714286,24.6258503 17.1428571,24.6258503 C18.7142857,24.6258503 20,23.4013605 20,21.9047619 C20,20.4081633 18.7142857,19.1836735 17.1428571,19.1836735 Z M25.7142857,12.3809524 L24.2857143,12.3809524 L24.2857143,9.65986395 C24.2857143,5.9047619 21.0857143,2.85714286 17.1428571,2.85714286 C13.8857143,2.85714286 11.0428571,4.95238095 10.2285714,7.95918367 C10.0285714,8.69387755 10.4857143,9.42857143 11.2571429,9.61904762 C12.0142857,9.80952381 12.8,9.37414966 13,8.63945578 C13.4857143,6.84353741 15.1857143,5.57823129 17.1428571,5.57823129 C19.5,5.57823129 21.4285714,7.41496599 21.4285714,9.65986395 L21.4285714,12.3809524 L8.57142857,12.3809524 C7,12.3809524 5.71428571,13.6054422 5.71428571,15.1020408 L5.71428571,28.707483 C5.71428571,30.2040816 7,31.4285714 8.57142857,31.4285714 L25.7142857,31.4285714 C27.2857143,31.4285714 28.5714286,30.2040816 28.5714286,28.707483 L28.5714286,15.1020408 C28.5714286,13.6054422 27.2857143,12.3809524 25.7142857,12.3809524 Z M25.7142857,27.3469388 C25.7142857,28.0952381 25.0714286,28.707483 24.2857143,28.707483 L10,28.707483 C9.21428571,28.707483 8.57142857,28.0952381 8.57142857,27.3469388 L8.57142857,16.462585 C8.57142857,15.7142857 9.21428571,15.1020408 10,15.1020408 L24.2857143,15.1020408 C25.0714286,15.1020408 25.7142857,15.7142857 25.7142857,16.462585 L25.7142857,27.3469388 Z'
                  id='🔹Icon-Color'
                  fill='#33B27F'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
