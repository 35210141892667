import { FC, SVGProps } from 'react';

const JpgIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='15.2px'
      height='20px'
      viewBox='0 0 15.2 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_07'
          transform='translate(-528.400000, -991.000000)'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(220.000000, 544.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Illustration/File/Doc'
                  transform='translate(16.000000, 180.000000)'
                >
                  <g id='doc' transform='translate(2.400000, 0.000000)'>
                    <path
                      d='M2.64382324,0 L9.44382527,0 L15.2,5.90995813 L15.2,17.3982316 C15.2,18.8352829 14.0164308,20 12.5561768,20 L2.64382324,20 C1.18354352,20 0,18.8352576 0,17.3982316 L0,2.60176839 C0,1.16474236 1.18354352,0 2.64382324,0 L2.64382324,0 Z'
                      id='Path'
                      fill='#FFB302'
                    />
                    <g id='Group' transform='translate(2.400000, 0.000000)' fill='#FFFFFF'>
                      <polygon
                        id='Path'
                        opacity='0.302'
                        points='7.03560818 0 7.03560818 5.86141456 12.8 5.86141456'
                      />
                      <g id='Group-Copy-3' transform='translate(0.000000, 10.800000)'>
                        <path
                          d='M2.45536374,2.4150347 C2.45536374,3.16628111 1.9825351,3.59993338 1.22509572,3.59993338 C0.721905018,3.59993338 0.315174313,3.3932007 0,3.03523272 L0.41680661,2.43522162 C0.67098811,2.69745165 0.955666017,2.82856667 1.16410291,2.82856667 C1.40303621,2.82856667 1.54544233,2.68232812 1.54544233,2.39491441 L1.54544233,0.751179791 L0.325384564,0.751179791 L0.325384564,0.0251170538 L2.45536374,0.0251170538 L2.45536374,2.4150347 L2.45536374,2.4150347 Z'
                          id='Path'
                        />
                        <path
                          d='M4.78397259,0.0302470621 C5.69396117,0.0302470621 6.21750521,0.473892847 6.21750521,1.26044971 C6.21750521,2.0873804 5.693894,2.56633663 4.78397259,2.56633663 L4.13837576,2.56633663 L4.13837576,3.55962617 L3.23342514,3.55962617 L3.23342514,0.0302470621 L4.78397259,0.0302470621 L4.78397259,0.0302470621 Z M4.13837576,1.86046081 L4.73816081,1.86046081 C5.13474844,1.86046081 5.36340431,1.66378829 5.36340431,1.29069677 C5.36340431,0.922602017 5.13461409,0.736122883 4.73816081,0.736122883 L4.13837576,0.736122883 L4.13837576,1.86046081 L4.13837576,1.86046081 Z'
                          id='Shape'
                        />
                        <path
                          d='M9.96446564,1.76465624 L9.96446564,3.14109744 C9.58312622,3.40832423 8.98334117,3.6 8.48512125,3.6 C7.40236448,3.6 6.58386512,2.82350328 6.58386512,1.7949033 C6.58386512,0.77143333 7.42265063,0 8.54611406,0 C9.07476322,0 9.63397595,0.201669288 10,0.524326825 L9.49156983,1.13439808 C9.2322832,0.89741834 8.87136428,0.751246414 8.54094176,0.751246414 C7.95633774,0.751246414 7.50896756,1.20501897 7.50896756,1.7949033 C7.50896756,2.39491441 7.96144287,2.84868696 8.55108484,2.84868696 C8.73413045,2.84868696 8.96795862,2.78312945 9.18150064,2.67726474 L9.18150064,1.76465624 L9.96446564,1.76465624 Z'
                          id='Path'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default JpgIcon;
