import * as React from 'react';

interface ISaleIconProps {}

const SaleIcon: React.FunctionComponent<ISaleIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_00'
          transform='translate(-714.000000, -583.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-4' transform='translate(214.000000, 270.000000)'>
                <g id='Sale-of-Assets' transform='translate(202.000000, 40.000000)'>
                  <g id='company-(1)-copy' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                    <g id='gold-stack' mask='url(#mask-2)'>
                      <g transform='translate(3.000000, 6.000000)'>
                        <g
                          id='Group'
                          strokeWidth={1}
                          fill='none'
                          transform='translate(0.647672, 17.550471)'
                        >
                          <path
                            d='M13.5538514,20.2153432 L0.699721317,20.2153432 C0.484138423,20.2153432 0.268555529,20.1345895 0.13381622,19.9461642 C-0.000923088553,19.7846568 -0.0278709503,19.5693136 0.0260247732,19.3539704 L2.10101013,11.0632571 C2.15490585,10.7402423 2.45133233,10.5248991 2.77470667,10.5248991 L11.478866,10.5248991 C11.8022404,10.5248991 12.071719,10.7402423 12.1525626,11.0632571 L14.2006001,19.2732167 C14.2275479,19.3539704 14.2544958,19.4347241 14.2544958,19.5423957 C14.2544958,19.9192463 13.9311214,20.2153432 13.5538514,20.2153432 Z'
                            id='Path'
                            fill='#3C6D91'
                          />
                          <path
                            d='M28.7793933,20.2153432 L15.9252632,20.2153432 C15.7096803,20.2153432 15.4940974,20.1345895 15.3593581,19.9461642 C15.2515667,19.7846568 15.1976709,19.5693136 15.2515667,19.3539704 L17.326552,11.0632571 C17.4073956,10.7402423 17.6768742,10.5248991 18.0002486,10.5248991 L26.7044079,10.5248991 C27.0277822,10.5248991 27.2972609,10.7402423 27.3781044,11.0632571 L29.4261419,19.2732167 C29.4530898,19.3539704 29.4800377,19.4347241 29.4800377,19.5423957 C29.4800377,19.9192463 29.1566633,20.2153432 28.7793933,20.2153432 Z'
                            id='Path'
                            fill='#3C6D91'
                          />
                          <path
                            d='M44.0049351,20.2153432 L31.1508051,20.2153432 C30.9352222,20.2153432 30.7465872,20.1345895 30.6118479,19.9461642 C30.4771085,19.7846568 30.4232128,19.5693136 30.4771085,19.3539704 L32.5520939,11.0632571 C32.6329375,10.7402423 32.9024161,10.5248991 33.2257904,10.5248991 L41.9299498,10.5248991 C42.2533241,10.5248991 42.5228027,10.7402423 42.6036463,11.0632571 L44.6516838,19.2732167 C44.6786317,19.3539704 44.7055795,19.4347241 44.7055795,19.5423957 C44.7055795,19.9192463 44.3822052,20.2153432 44.0049351,20.2153432 Z'
                            id='Path'
                            fill='#3C6D91'
                          />
                          <path
                            d='M20.829774,9.69044415 L7.97564399,9.69044415 C7.76006109,9.69044415 7.57142606,9.58277254 7.43668675,9.42126514 C7.30194744,9.25975774 7.24805172,9.01749664 7.30194744,8.82907133 L9.3769328,0.511440108 C9.45777638,0.215343203 9.727255,0 10.0506293,0 L18.7547887,0 C19.078163,0 19.3476416,0.215343203 19.4284852,0.511440108 L21.4765227,8.72139973 C21.5304184,8.80215343 21.5304184,8.90982503 21.5304184,8.99057873 C21.5304184,9.36742934 21.233992,9.69044415 20.829774,9.69044415 Z'
                            id='Path'
                            fill='#3C6D91'
                          />
                          <path
                            d='M36.7290125,9.69044415 C36.7290125,9.69044415 36.7290125,9.69044415 36.7020646,9.69044415 L23.8479346,9.69044415 C23.6323517,9.69044415 23.4437166,9.58277254 23.3089773,9.42126514 C23.174238,9.25975774 23.1203423,9.01749664 23.174238,8.82907133 L25.2492234,0.511440108 C25.330067,0.215343203 25.6264934,0 25.9229199,0 L34.6270793,0 C34.9504536,0 35.2468801,0.215343203 35.3277237,0.511440108 L37.3757612,8.72139973 C37.402709,8.80215343 37.4296569,8.90982503 37.4296569,8.99057873 C37.4296569,9.36742934 37.1062825,9.69044415 36.7290125,9.69044415 Z'
                            id='Path'
                            fill='#3C6D91'
                          />
                        </g>
                        <path
                          d='M45.2993556,40 L0.700644405,40 C0.323374341,40 0,39.7039031 0,39.3270525 L0,37.0928668 C0,36.6890983 0.323374341,36.3930013 0.700644405,36.3930013 L45.2993556,36.3930013 C45.6766257,36.3930013 46,36.6890983 46,37.0928668 L46,39.3270525 C46,39.7039031 45.6766257,40 45.2993556,40 Z'
                          id='Path'
                          fill='#345E80'
                        />
                        <path
                          d='M44.2214411,29.4751009 L1.75161101,29.4751009 C1.37434095,29.4751009 1.05096661,29.1520861 1.05096661,28.7752355 L1.05096661,26.5410498 C1.05096661,26.1641992 1.37434095,25.8411844 1.75161101,25.8411844 L44.2214411,25.8411844 C44.6256591,25.8411844 44.9220855,26.1641992 44.9220855,26.5410498 L44.9220855,28.7752355 C44.9220855,29.1520861 44.6256591,29.4751009 44.2214411,29.4751009 Z'
                          id='Path'
                          fill='#345E80'
                        />
                        <g
                          id='Group-8'
                          strokeWidth={1}
                          fill='none'
                          transform='translate(14.754717, 0.000000)'
                        >
                          <ellipse
                            id='Oval'
                            fill='#F4A93C'
                            fillRule='nonzero'
                            cx='8.25837214'
                            cy='8.24919924'
                            rx='8.25823163'
                            ry='8.24904812'
                          />
                          <path
                            d='M0.000140513794,8.24919924 C0,11.3168701 1.70538665,14.1308237 4.42655267,15.5529064 C8.4260381,16.2201822 12.318197,13.8859155 13.6085998,10.0461048 C14.8990027,6.20629414 13.2051984,1.99898715 9.61272213,0.120587223 C7.21728249,-0.294212963 4.76098132,0.372757903 2.90543662,1.94184762 C1.04989192,3.51093733 -0.0140678873,5.82077715 0.000140513794,8.24919924 L0.000140513794,8.24919924 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          />
                          <path
                            d='M11.5616648,6.59938961 L11.5616648,5.7744848 C11.5616648,4.40773776 10.4524639,3.29977037 9.08419531,3.29977037 L9.08419531,2.47486556 L7.43254898,2.47486556 L7.43254898,3.29977037 C6.06428036,3.29977037 4.95507949,4.40773776 4.95507949,5.7744848 L4.95507949,6.59938961 C4.95507949,7.96613666 6.06428036,9.07410405 7.43254898,9.07410405 L7.43254898,11.5488185 C6.97645944,11.5488185 6.60672582,11.179496 6.60672582,10.7239137 L4.95507949,10.7239137 C4.95507949,12.0906607 6.06428036,13.1986281 7.43254898,13.1986281 L7.43254898,14.0235329 L9.08419531,14.0235329 L9.08419531,13.1986281 C10.4524639,13.1986281 11.5616648,12.0906607 11.5616648,10.7239137 L11.5616648,9.89900886 C11.5616648,8.53226182 10.4524639,7.42429443 9.08419531,7.42429443 L9.08419531,4.94957999 C9.54028485,4.94957999 9.91001847,5.31890245 9.91001847,5.7744848 L9.91001847,6.59938961 L11.5616648,6.59938961 Z M9.91001847,9.89900886 L9.91001847,10.7239137 C9.91001847,11.179496 9.54028485,11.5488185 9.08419531,11.5488185 L9.08419531,9.07410405 C9.54028485,9.07410405 9.91001847,9.44342651 9.91001847,9.89900886 Z M7.43254898,7.42429443 C6.97645944,7.42429443 6.60672582,7.05497196 6.60672582,6.59938961 L6.60672582,5.7744848 C6.60672582,5.31890245 6.97645944,4.94957999 7.43254898,4.94957999 L7.43254898,7.42429443 Z'
                            id='Shape'
                            fill='#FCE797'
                            fillRule='nonzero'
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SaleIcon;
