import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';

interface ITaxInformationLayoutProps extends BoxProps {
  allowExpanded?: boolean;
  title: string;
}

const TaxInformationLayout: React.FunctionComponent<ITaxInformationLayoutProps> = ({
  title,
  children,
  allowExpanded,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        mt: '0 !important',
        '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
        border: `1px solid ${theme.palette.neutral.ne300} !important`,
      }}
      {...(allowExpanded ? { defaultExpanded: true } : { expanded: true })}
    >
      <AccordionSummary
        sx={{
          '.MuiSvgIcon-root': { fontSize: '18px' },
          '.MuiAccordionSummary-content': { my: 0 },
          minHeight: 'unset',
        }}
      >
        <Typography variant='body2' fontWeight={500}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='p-6 pt-0 mt-0'>
        <Box className='flex flex-col gap-6 rounded-[10px]'>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TaxInformationLayout;
