/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';

import FormSelect from 'src/components/atoms/FormSelect';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import {
  individualDefaultValue,
  investmentEntityIndividualsDefaultValues,
  numberOfIndividualOptions,
} from 'src/constants/applications';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '.';
import { INVESTMENT_APPLICATION_STEP } from '../InvestmentApplicationForm';
import AddNew from './shared/AddNew';
import IndividualDetail from './shared/IndividualDetail';
import SelectExisting from './shared/SelectExisting';

interface IIndividualsProps extends IInvestmentEntityProps {}

const Individuals = (props: IIndividualsProps) => {
  const {
    id = '',
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    investmentEntityId,
    isReviewing,
    isCreateFundApplicationForm,
  } = props;
  const {
    reset,
    watch,
    control,
    formState: { isDirty },
  } = useFormContext<IInvestmentEntityForm>();

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  const numberOfIndividuals = watch('investmentEntityDetails.numberOfIndividuals');
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;

  const {
    fields: individuals,
    append: addIndividual,
    remove: removeIndividual,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.individuals',
    keyName: 'dataId',
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.entityType) && !isCreateFundApplicationForm) {
      reset(investmentEntityIndividualsDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  useEffect(() => {
    if (
      (!individuals.length || (numberOfIndividuals || 0) > individuals.length) &&
      numberOfIndividuals &&
      isDirty
    ) {
      Array.from(Array(numberOfIndividuals - (individuals.length || 0))).forEach(() => {
        addIndividual(individualDefaultValue());
      });
    }
  }, [numberOfIndividuals]);

  return (
    <Box className='flex flex-col'>
      <SelectExisting {...props} defaultValue={investmentEntityIndividualsDefaultValues} />
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                Investment Entity Details
              </Typography>
              {isExistingInvestmentEntity && !isReviewing && (
                <UpdateDetailsButton
                  entityId={investmentEntityId}
                  disabled={isViewMode || isDisabledEdit}
                />
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <Box className='flex items-center'>
              <Typography variant='body2' fontWeight={500} mr={1.5} whiteSpace={'nowrap'}>
                Number of individuals
              </Typography>
              <Box width={'160px'}>
                <FormSelect
                  name='investmentEntityDetails.numberOfIndividuals'
                  options={numberOfIndividualOptions}
                  placeholder='Select number'
                  disabled={disabled}
                />
              </Box>
            </Box>
            {!!individuals.length && (
              <Box mt={3} className='flex flex-col gap-4'>
                {individuals.map((individual, index) => (
                  <IndividualDetail
                    key={individual.dataId}
                    {...props}
                    keyName={`investmentEntityDetails.individuals.${index}`}
                    canDelete={individuals.length > (numberOfIndividuals || 0)}
                    handleDelete={() => removeIndividual(index)}
                    taxInfoLogicCheck
                    autocompleteAddress
                    hideDeclaration={isCreateFundApplicationForm}
                  />
                ))}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default Individuals;
