import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CurrencyName from 'src/components/atoms/CurrencyName';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { FundType } from 'src/constants/funds-management';
import { useGetInvestorDetailsIssue } from 'src/modules/administration-investor/hooks';
import { IIssueForm } from 'src/modules/administration-investor/types';
import { useGetUnitByDate } from 'src/modules/fund-registry/hooks';
import { currencyStringToNumber, handleErrorFromServer } from 'src/utils/common';
import { InvestorDetailsProps } from '..';
import IssueUnitPriceTabs from './IssueUnitPriceTabs';

interface IIssueFormProps extends InvestorDetailsProps {
  detailInvestmentAmount?: number | null;
  investorUnitRounding: number;
}

const IssueForm: FC<IIssueFormProps> = ({
  id = '',
  currencyName,
  fundType,
  investorUnitRounding,
  unitClassId = '',
}) => {
  const { data } = useGetInvestorDetailsIssue(id);
  const { mutate: getUnitByDate } = useGetUnitByDate();

  const form = useFormContext<IIssueForm>();
  const watchPaidAmount = form.watch('paidAmount');
  const watchInvestmentAmount = form.watch('investmentAmount');
  const {
    unitPriceRounding,
    currentUnitPrice,
    allowCapitalCall = false,
    allowPartiallyPaidUnits = false,
  } = data || {};

  console.log(form.watch('currentUnitPrice'));

  // We define 3 case for fund type is trust here
  const isTrustInFirstCase =
    fundType === FundType.Trust && !allowCapitalCall && !allowPartiallyPaidUnits;
  const isTrustInSecondCase =
    fundType === FundType.Trust && allowCapitalCall && !allowPartiallyPaidUnits;
  const isTrustInThirdCase =
    fundType === FundType.Trust && allowCapitalCall && allowPartiallyPaidUnits;
  // We define a case for fund type is partnership
  const isPartnershipCase = fundType === FundType.Partnership;

  useEffect(() => {
    if (isTrustInFirstCase) {
      form.setValue('paidAmount', watchInvestmentAmount || 0);
    }
  }, [watchInvestmentAmount, isTrustInFirstCase]);

  useEffect(() => {
    form.setValue('isTrustInFirstCase', isTrustInFirstCase);
    form.setValue('isTrustInThirdCase', isTrustInThirdCase);
  }, [isTrustInFirstCase, isTrustInThirdCase]);

  useEffect(() => {
    handleCalcAmountUnpaid();
  }, [watchPaidAmount]);

  // Set the unitPriceRounding to validate price fields in yup validation
  useEffect(() => {
    if (Number.isInteger(unitPriceRounding)) {
      form.setValue('unitPriceRounding', unitPriceRounding);
      form.trigger('currentUnitPrice');
      form.trigger('entryUnitPrice');
    }
  }, [unitPriceRounding]);

  // Get exit unit price and mid price for the first time use the current date
  useEffect(() => {
    handleGetEntryMidPrice(dayjs().endOf('day').utc().format());
  }, []);

  const handleGetEntryMidPrice = (strikeDate: string) => {
    getUnitByDate(
      {
        strikeDate,
        unitClassId,
      },
      {
        onSuccess: (data) => {
          const { midPrice, entryPrice } = data;
          form.setValue('entryUnitPrice', entryPrice);
          form.setValue('currentUnitPrice', midPrice);
          form.trigger('currentUnitPrice');
          form.trigger('entryUnitPrice');
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleTransactionDateChange = (date: any) => {
    form.trigger('transactionDate');
    /**
     * Handle for manually typing the date:
     * Check the date is valid and the date in the past or current date
     * Then we allow to call the API to get price
     */
    const isValid = dayjs(date).isValid() && !dayjs(date).isAfter();
    if (isValid) {
      handleGetEntryMidPrice(dayjs(date).endOf('day').utc().format());
    }
  };

  const handleCalcAmountUnpaid = () => {
    const investmentAmount = form.watch('investmentAmount') || 0;
    const paidAmount = form.watch('paidAmount') || 0;
    const amountUnpaid = investmentAmount - paidAmount;

    form.setValue('unpaidAmount', amountUnpaid > 0 ? amountUnpaid : 0);
  };

  const handleInvestmentAmountChange = () => {
    handleCalcCapitalCallPercent();
    handleCalcAmountUnpaid();
  };

  const handleCalcCapitalCallPercent = () => {
    if (isTrustInFirstCase) return;
    const capitalCallAmount = form.watch('capitalCallAmount') || 0;
    const investmentAmount = form.watch('investmentAmount') || 0;

    form.setValue(
      'capitalCallPercent',
      investmentAmount ? (capitalCallAmount * 100) / investmentAmount : 0,
    );
  };

  const handleCapitalCallAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isTrustInFirstCase) return;
    handleCalcCapitalCallPercent();
    form.setValue('paidAmount', currencyStringToNumber(e.target.value));
  };

  const formCurrencyProps = {
    hideCurrency: true,
    endAdornment: <CurrencyName currencyName={currencyName} />,
    decimalScale: 2,
    fixedDecimalScale: true,
  };

  return (
    <Box>
      <Grid container columnSpacing={3} rowSpacing='18px'>
        <Grid item xs={6}>
          <FormDatePicker
            name='transactionDate'
            label='Transaction Date'
            onChange={handleTransactionDateChange}
            disableFuture
          />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='investmentAmount'
            label='Investment Amount'
            placeholder='Enter investment amount'
            endAdornment={<></>}
            onChange={handleInvestmentAmountChange}
          />
        </Grid>
        {(isTrustInSecondCase || isTrustInThirdCase || isPartnershipCase) && (
          <>
            <Grid item xs={6}>
              <FormCurrencyInput
                {...formCurrencyProps}
                name='capitalCallAmount'
                label='Capital Call Amount'
                placeholder='Enter capital call amount'
                onChange={handleCapitalCallAmountChange}
                endAdornment={<></>}
              />
            </Grid>
            <Grid item xs={6}>
              <FormCurrencyInput
                {...formCurrencyProps}
                name='capitalCallPercent'
                label='Capital Call %'
                endAdornment={<Typography color='neutral.ne500'>%</Typography>}
                disabled
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='paidAmount'
            label='Amount Paid'
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='unpaidAmount'
            label='Amount Unpaid'
            disabled
          />
        </Grid>
      </Grid>
      {!isPartnershipCase && (
        <IssueUnitPriceTabs
          {...{
            unitPriceRounding,
            currentUnitPrice,
            isTrustInFirstCase,
            isTrustInSecondCase,
            isTrustInThirdCase,
            unitClassId,
          }}
        />
      )}
      {!isPartnershipCase && (
        <Grid container rowSpacing='18px' pt='18px'>
          {isTrustInThirdCase && (
            <Grid item xs={12}>
              <FormRadioGroup
                name='partiallyPaidUnits'
                label='Partially Paid Units'
                optionGap={10}
                options={TrueFalseRadioOptions}
                isBooleanValue
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormCurrencyInput
              name='units'
              label='Units'
              hideCurrency
              decimalScale={investorUnitRounding}
              disabled
              fixedDecimalScale
            />
          </Grid>
        </Grid>
      )}
      <Box className='mt-8'>
        <FormCheckbox
          name='isSendMail'
          label='Send confirmation of investment letter to investor.'
        />
      </Box>
    </Box>
  );
};

export default IssueForm;
