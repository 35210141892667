import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FundType, SubType } from 'src/constants/funds-management';
import { IFundDetail, IUnitClassDetail } from 'src/modules/dashboard/type';
import { formatCurrencyNumber } from 'src/utils/common';

interface InvestmentEntityDetailProps {
  detail: IFundDetail;
}

const InvestmentEntityDetail: React.FC<InvestmentEntityDetailProps> = ({ detail = {} }) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const { fundType, fundSubType, investorUnitRounding, unitPriceRounding } = detail;
  const isTrustAndStandardType = fundType === FundType.Trust && fundSubType === SubType.Standard;
  const isPartnership = fundType === FundType.Partnership;

  return (
    <Box m={1}>
      {detail?.unitClasses?.map((item: IUnitClassDetail) => {
        const open = selectedItem === item.unitClassId;
        return (
          <Box key={item.unitClassId}>
            <Box
              className='flex items-center cursor-pointer py-[10px]'
              onClick={() => setSelectedItem(open ? '' : item.unitClassId)}
            >
              <Typography
                variant='body2'
                fontSize='16px'
                textTransform='capitalize'
                fontWeight={500}
                mr={1}
              >
                {item.unitClassName}
              </Typography>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
            {open && (
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant='body3' color='neutral.ne800' minWidth='200px'>
                    {isTrustAndStandardType ? 'Investment Amount' : 'Committed Capital'}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='body3' color='neutral.ne800'>
                    {formatCurrencyNumber(item.investmentAmount, 2) + ' AUD'}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body3' color='neutral.ne800' minWidth='200px'>
                    {isTrustAndStandardType ? 'Unpaid Amount' : 'Unpaid Capital'}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='body3' color='neutral.ne800'>
                    {formatCurrencyNumber(item.unpaidAmount, 2) + ' AUD'}
                  </Typography>
                </Grid>
                {!isPartnership && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant='body3' color='neutral.ne800' minWidth='200px'>
                        Units
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body3' color='neutral.ne800'>
                        {formatCurrencyNumber(item.units, investorUnitRounding)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body3' color='neutral.ne800' minWidth='200px'>
                        Current Unit Price
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body3' color='neutral.ne800'>
                        {formatCurrencyNumber(item.currentUnitPrice, unitPriceRounding) + ' AUD'}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <Typography variant='body3' color='neutral.ne800' minWidth='200px'>
                    Current Net Value
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='body3' color='neutral.ne800'>
                    {formatCurrencyNumber(item.currentNetValue, 2) + ' AUD'}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default InvestmentEntityDetail;
