/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { AppBar, Avatar, Box, Button, Popover, styled, Toolbar, Typography } from '@mui/material';
import { FC, useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ArrowCounterClockWiseGrey from 'src/assets/icons/ArrowCounterClockWiseGrey';
import { ChevronDownIcon } from 'src/assets/icons/ChevronDownIcon';
import LogoutIcon from 'src/assets/icons/LogoutIcon';
import { NotificationIcon } from 'src/assets/icons/NotificationIcon';
import ProfileIcon from 'src/assets/icons/ProfileIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomIconButton from 'src/components/atoms/CustomIconButton';
import { CustomMenu, ICustomMenuElement, IMenuItem } from 'src/components/atoms/CustomMenu';
import ChangePassword from 'src/components/pages/change-password';
import Profile from 'src/components/pages/profile';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { detectPortalType } from 'src/helpers/common';
import { getValue } from 'src/helpers/storage';
import { useGetUserInfo, useLogout, useUpdateProfile } from 'src/modules/auth/hooks';
import { forceLogout } from 'src/modules/auth/services';
import { useCountry } from 'src/modules/common/hooks';
import { NotificationContext } from 'src/providers/NotificationContextProvider';
import { handleErrorFromServer } from 'src/utils/common';
import { formatPhoneNumber } from 'src/utils/phone';
import {
  globalAdminProfileValidationSchema,
  investorFundProfileValidationSchema,
} from 'src/validations/profile';
import ConfirmationAlert from './ConfirmationAlert';
import FundHeaderFilter from './FundHeaderFilter';
import InvestorHeaderFilter from './InvestorHeaderFilter';
import Notifications from './Notifications';

interface IHeaderProps {}

interface IProfileFormData {
  position: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  role: string;
  countryFlagCode: string;
}

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  left: 260,
  backgroundColor: theme.palette.base.white,
  height: 72,
  width: 'unset',
}));

const Header: FC<IHeaderProps> = () => {
  const { isAdmin: isGlobalAdminPortal, isInvestor, isFunds } = detectPortalType();
  const profileDrawerRef = useRef<ICustomDrawerElement>(null);
  const modalRef = useRef<IConfirmModalElement>(null);
  const alertModalRef = useRef<IBasicModalElement>(null);
  const changePasswordModalRef = useRef<IBasicModalElement>(null);
  const changePasswordAlertRef = useRef<IBasicModalElement>(null);
  const customMenuRef = useRef<ICustomMenuElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { mutate: logoutMutate } = useLogout();
  const { data: userInfo, refetch: refetchUserInfo } = useGetUserInfo();
  const { mutate: updateProfileMutate, isLoading: isUpdateProfileLoading } = useUpdateProfile();
  const { getPhoneCodeByCountryCode } = useCountry();
  const { totalUnread, notifications, queryNotificationParams, setQueryNotificationParams } =
    useContext(NotificationContext);

  const profileForm = useForm<IProfileFormData>({
    resolver: yupResolver(
      isGlobalAdminPortal
        ? globalAdminProfileValidationSchema
        : investorFundProfileValidationSchema,
    ),
  });

  const handleMenuOpen = (event: any) => {
    customMenuRef.current?.open(event.currentTarget);
  };

  const handleMenuClose = () => {
    customMenuRef.current?.close();
  };

  const handleLogout = () => {
    const refreshToken = getValue(STORAGE_KEYS.REFRESH_TOKEN) || '';
    logoutMutate(refreshToken, {
      onSuccess: forceLogout,
    });
  };

  const handleOpenLogoutModal = () => {
    handleMenuClose();
    modalRef.current?.open();
  };

  const handleCloseLogoutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenProfileDrawer = () => {
    handleMenuClose();
    profileDrawerRef.current?.open();
  };

  const handleCloseProfileDrawer = () => {
    profileDrawerRef.current?.close();
  };

  const handleOpenChangePasswordModal = () => {
    handleMenuClose();
    changePasswordModalRef.current?.open();
  };

  const handleCloseChangePasswordModal = () => {
    changePasswordModalRef.current?.close();
  };

  const onChangePasswordSuccess = () => {
    handleCloseChangePasswordModal();
    changePasswordAlertRef.current?.open();
  };

  const onEditProfile = (data: IProfileFormData) => {
    const { countryFlagCode, ...restData } = data;
    const body = {
      ...restData,
      contactNumber: formatPhoneNumber(
        getPhoneCodeByCountryCode(countryFlagCode),
        restData.contactNumber,
      ),
      countryFlagCode,
    };

    updateProfileMutate(body, {
      onSuccess() {
        handleCloseProfileDrawer();
        alertModalRef.current?.open();
        refetchUserInfo();
      },
      onError: handleErrorFromServer,
    });
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorEl(null);
    if (queryNotificationParams?.search) {
      setQueryNotificationParams({
        search: '',
      });
    }
  };

  const MENU_ITEMS: IMenuItem[] = [
    {
      title: 'Profile',
      icon: <ProfileIcon />,
      onClick: handleOpenProfileDrawer,
    },
    {
      title: 'Change password',
      icon: <ArrowCounterClockWiseGrey />,
      onClick: handleOpenChangePasswordModal,
    },
    {
      title: 'Logout',
      icon: <LogoutIcon />,
      onClick: handleOpenLogoutModal,
    },
  ];

  return (
    <CustomAppBar position='fixed' elevation={0}>
      <Toolbar className='flex justify-between'>
        <Box>
          {isInvestor && <InvestorHeaderFilter />}
          {isFunds && <FundHeaderFilter />}
        </Box>
        <Box>
          <CustomIconButton
            className='relative'
            variant='outlined'
            sx={{ width: 40, height: 40 }}
            onClick={handleClickNotification}
          >
            <NotificationIcon />
            {!!notifications?.length && totalUnread > 0 && (
              <Box
                className='inline-block absolute top-1 right-1.5 text-[0.5rem] w-3.5 h-3.5 text-white rounded-full'
                bgcolor='error.main'
              >
                {totalUnread > 9 ? '+9' : totalUnread}
              </Box>
            )}
          </CustomIconButton>
          <Button
            startIcon={<Avatar />}
            endIcon={<ChevronDownIcon />}
            variant='text'
            sx={{
              p: 0,
              ml: 3,
            }}
            onClick={handleMenuOpen}
          >
            <Typography className='flex flex-col items-start' component='span'>
              <Typography component='span' variant='body2' color='base.black' fontWeight='500'>
                {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
              </Typography>
              <Typography component='span' variant='body3' color='neutral.ne400'>
                {userInfo?.role}
              </Typography>
            </Typography>
          </Button>
          <CustomMenu ref={customMenuRef} menuItems={MENU_ITEMS} />
        </Box>
      </Toolbar>

      <ConfirmModal
        title='Log out?'
        content={`You will be returned to the login screen. <br/>
        Are you sure you want to logout?`}
        ref={modalRef}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseLogoutModal}
            >
              Cancel
            </CustomButton>
            <CustomButton color='error' onClick={handleLogout}>
              Log out
            </CustomButton>
          </>
        }
      />

      <CustomDrawer
        ref={profileDrawerRef}
        title='Profile'
        onClose={handleCloseProfileDrawer}
        ButtonComponents={
          <>
            <CustomButton
              variant='text'
              sx={{ color: 'neutral.ne800' }}
              onClick={handleCloseProfileDrawer}
              disabled={isUpdateProfileLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton
              isLoading={isUpdateProfileLoading}
              onClick={profileForm.handleSubmit(onEditProfile)}
              onKeyDown={profileForm.handleSubmit(onEditProfile)}
            >
              Save
            </CustomButton>
          </>
        }
      >
        <FormProvider {...profileForm}>
          <Profile userInfo={userInfo} onSubmit={profileForm.handleSubmit(onEditProfile)} />
        </FormProvider>
      </CustomDrawer>

      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description='Your Profile has been updated successfully.'
          buttonAction={{
            label: 'OK',
            onAction: () => alertModalRef?.current?.close(),
          }}
        />
      </BasicModal>

      <BasicModal ref={changePasswordAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description='Your password has been changed successfully.'
          buttonAction={{
            label: 'OK',
            onAction: () => changePasswordAlertRef?.current?.close(),
          }}
        />
      </BasicModal>

      <BasicModal
        ref={changePasswordModalRef}
        onClose={handleCloseChangePasswordModal}
        PaperProps={{
          style: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
        }}
      >
        <ChangePassword onChangePasswordSuccess={onChangePasswordSuccess} />
      </BasicModal>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseNotification}
        PaperProps={{
          style: {
            width: 540,
            borderRadius: 8,
            marginTop: 20,
          },
        }}
      >
        <Notifications />
      </Popover>
    </CustomAppBar>
  );
};

export default Header;
