import { detectPortalType } from 'src/helpers/common';

const { isInvestor } = detectPortalType();

export enum COMMUNICATION_VIEW_MODE {
  Inbox,
  Outbox,
}

export enum COMMUNICATION_MESSAGE_TYPE {
  New,
  Forward,
  Reply,
}

export const communicationStatuses = {
  Received: 'Received',
  Sent: 'Sent',
  Archived: 'Archived',
  Draft: 'Draft',
};

export enum CommunicationCategoryEnum {
  ClientManagement,
  Internal,
  InvestorRelation,
  SystemSupport,
  KYCBO,
  AccountAdministration,
}

export enum CommunicationDeliveryType {
  Portal,
  PortalAndEmail,
}

export const communicationDefaultValues = {
  category: null,
  content: '',
  subject: '',
  attachments: [],
  messageType: COMMUNICATION_MESSAGE_TYPE.New,
};

export enum InvestorRelationOrderEnum {
  InvestmentEntity,
  Fund,
}

export const InvestorRelationOrderOptions = [
  {
    label: 'Investment Entity',
    value: InvestorRelationOrderEnum.InvestmentEntity,
  },
  {
    label: 'Fund',
    value: InvestorRelationOrderEnum.Fund,
  },
];
