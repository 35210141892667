/* eslint-disable autofix/no-unused-vars */
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactLawyer } from 'src/assets/icons/ContactLawyer';
import InvestorHubLogoSrc from 'src/assets/images/logo/investorbase-logo.png';
import PoweredByFundcorr from 'src/assets/images/logo/powered-by-fundcorr.svg';
import PrimaryReversedLogoSrc from 'src/assets/images/logo/primany-reversed-logo.svg';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomScrollbarContainer from 'src/components/atoms/CustomScrollbarContainer';
import { Logo } from 'src/components/atoms/Logo';
import { portalTypes } from 'src/constants/common';
import { SUBSCRIPTION_FEATURE_TYPE } from 'src/constants/subscription-billing';
import { detectPortalType } from 'src/helpers/common';
import { useRole } from 'src/hooks/useRole';
import { INavigationItem, ISubNavigationItem } from 'src/interfaces/navigation';
import { useGetOrganisationSubscriptions } from 'src/modules/organisation-settings/hooks';
import { BrandingContext } from 'src/providers/BrandingContextProvider';
import ContactLawyerForm from './components/ContactLawyerForm';

interface ISidebarProps {}
interface NavigationItemProps {
  item: INavigationItem;
  currentPath: string;
  setCurrentPath: (currentPath: string) => void;
}

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.pr900,
  color: theme.palette.base.white,
  width: 260,
  height: '100vh',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: 0,
  overflowX: 'hidden',
  padding: '32px 12px',
}));

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 8,
  justifyContent: 'flex-start',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CustomSubNavigationList = styled(List)(({ theme }) => ({
  marginTop: -10,
  paddingLeft: 25,
  '&::before': {
    content: '""',
    width: 1,
    backgroundColor: theme.palette.primary.pr500,
    display: 'block',
    position: 'absolute',
    top: -3,
  },
}));

const CustomSubItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  '&::before': {
    content: '""',
    border: `1px solid ${theme.palette.primary.pr500}`,
    height: 11,
    width: 17,
    borderBottomLeftRadius: 20,
    borderTopWidth: 0,
    borderRightWidth: 0,
    marginRight: 10,
    marginTop: -11,
  },
}));

const NavigationItem: React.FC<NavigationItemProps> = ({ item, currentPath, setCurrentPath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { branding } = React.useContext(BrandingContext);

  const handleClickNavigationItem = (navigationItem: INavigationItem) => {
    if (navigationItem.submenu) {
      setCurrentPath(navigationItem.path);
    } else {
      navigate(navigationItem.path);
    }
  };

  const isOpen = React.useMemo(() => {
    if (
      item.submenu &&
      (item.path === currentPath ||
        (!currentPath && item.submenu.some((item) => item.path === location.pathname)))
    )
      return true;
    return false;
  }, [currentPath, item.path, item.submenu, location.pathname]);

  const buttonSx = React.useMemo(() => {
    return {
      '&.Mui-selected': {
        backgroundColor: branding?.backgroundActive,
      },
      '&:hover': {
        backgroundColor: branding?.backgroundActive,
      },
    };
  }, [branding]);

  return (
    <>
      <CustomListItemButton
        selected={location.pathname === item.path && !item.submenu}
        onClick={() => handleClickNavigationItem(item)}
        sx={buttonSx}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>{<item.icon color={branding?.text} />}</ListItemIcon>
        <ListItemText className='ml-4'>
          <Typography variant='body2' color={branding?.text}>
            {item.title}
          </Typography>
        </ListItemText>
        {item.submenu &&
          (isOpen ? (
            <ExpandLess sx={{ color: branding?.text }} />
          ) : (
            <ExpandMore sx={{ color: branding?.text }} />
          ))}
      </CustomListItemButton>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <CustomSubNavigationList
          sx={{
            '&::before': {
              height: 20 + 40 * ((item.submenu || []).length - 1),
              backgroundColor: branding?.text,
            },
          }}
        >
          {(item.submenu || []).map((subNavItem: ISubNavigationItem, subIndex: number) => (
            <CustomSubItem
              key={`sub-navigation-item-${subIndex}`}
              sx={{
                '&::before': {
                  border: `1px solid ${branding?.text}`,
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                },
              }}
            >
              <CustomListItemButton
                sx={buttonSx}
                selected={location.pathname === subNavItem.path}
                onClick={() => handleClickNavigationItem(subNavItem)}
              >
                <Typography variant='body2' color={branding?.text} fontSize={14}>
                  {subNavItem.title}
                </Typography>
              </CustomListItemButton>
            </CustomSubItem>
          ))}
        </CustomSubNavigationList>
      </Collapse>
    </>
  );
};

const Sidebar: React.FunctionComponent<ISidebarProps> = () => {
  const { portalType, isFunds } = detectPortalType();
  const navigate = useNavigate();
  const { allowedNavigationList } = useRole();
  const { branding } = React.useContext(BrandingContext) || {};
  const [currentPath, setCurrentPath] = React.useState('');
  const contactLawyerModalRef = React.useRef<IBasicModalElement>(null);

  const { data: subscriptions } = useGetOrganisationSubscriptions();

  const { navigationList, isAllowContactLawyer, portalLogo, isShowPowerLogo } =
    React.useMemo(() => {
      switch (portalType) {
        case portalTypes.ADMIN:
          return {
            navigationList: allowedNavigationList,
            isAllowContactLawyer: false,
            portalLogo: PrimaryReversedLogoSrc,
            isShowPowerLogo: false,
          };
        case portalTypes.INVESTOR:
          return {
            navigationList: allowedNavigationList,
            isAllowContactLawyer: false,
            portalLogo: InvestorHubLogoSrc,
            isShowPowerLogo: true,
          };
        default:
          return {
            navigationList: allowedNavigationList,
            isAllowContactLawyer: !!subscriptions?.find(
              (it) => it.featureType === SUBSCRIPTION_FEATURE_TYPE.ContactLawyer,
            ),
            portalLogo:
              isFunds && !branding?.defaultToStandard ? branding?.logoLink : PrimaryReversedLogoSrc,
            isShowPowerLogo: false,
          };
      }
    }, [portalType, allowedNavigationList, subscriptions, branding]);

  return (
    <Root
      sx={{
        backgroundColor: branding?.backgroundNavigation,
      }}
    >
      <Box className='h-full flex flex-col'>
        <Logo
          className='m-auto mb-[48px] cursor-pointer'
          src={portalLogo}
          alt='Funcorr'
          onClick={() => navigate('/')}
        />
        <CustomScrollbarContainer className='flex-1 overflow-y-auto overflow-x-hidden'>
          <List className='m-0 p-0'>
            <Stack gap={1.5}>
              {navigationList
                .filter((x) => !x.hidden)
                .map((navigationItem: INavigationItem, index: number) => (
                  <NavigationItem
                    currentPath={currentPath}
                    setCurrentPath={setCurrentPath}
                    key={`navigation-item-${index}`}
                    item={{
                      ...navigationItem,
                      submenu: navigationItem.submenu
                        ? (navigationItem.submenu || []).filter((x) => !x.hidden)
                        : undefined,
                    }}
                  />
                ))}
            </Stack>
          </List>
        </CustomScrollbarContainer>
        <CustomButton
          startIcon={<ContactLawyer />}
          sx={{
            backgroundColor: branding?.contactLawyerBackground || 'secondary.main',
            display: !isAllowContactLawyer ? 'none !important' : 'flex',
          }}
          className='w-full h-12 flex items-center justify-start px-5'
          onClick={() => contactLawyerModalRef.current?.open()}
        >
          <Typography variant='body2' color={branding?.contactLawyerText}>
            Contact a Lawyer{' '}
          </Typography>
        </CustomButton>
        <Box
          className='justify-center'
          sx={{
            display: !isShowPowerLogo ? 'none' : 'flex',
          }}
        >
          <img src={PoweredByFundcorr} alt='Powered by Fundcorr' />
        </Box>
      </Box>
      <BasicModal
        ref={contactLawyerModalRef}
        PaperProps={{
          style: {
            maxWidth: 780,
            width: 780,
            top: '50%',
            transform: 'translateY(-50%)',
          },
        }}
      >
        <ContactLawyerForm onClose={() => contactLawyerModalRef.current?.close()} />
      </BasicModal>
    </Root>
  );
};

export default Sidebar;
