import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/system';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';

export interface ICustomDrawerElement {
  open: () => void;
  close: () => void;
}

type ICustomDrawerProps = Omit<DrawerProps, 'open'> & {
  title?: ReactNode;
  description?: string;
  children: ReactNode;
  ButtonComponents?: ReactNode;
  onClose?: () => void;
  isWithoutActions?: boolean;
  buttonBoxProps?: BoxProps;
  isConfirmBeforeClose?: boolean;
};

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: 786,
    padding: 60,
    borderRadius: '40px 0 0 40px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
})) as typeof Drawer;

export const CustomDrawer = forwardRef<ICustomDrawerElement, ICustomDrawerProps>(
  (
    {
      children,
      description,
      title = 'Title',
      ButtonComponents,
      onClose,
      sx,
      isWithoutActions = false,
      buttonBoxProps,
      isConfirmBeforeClose,
    },
    ref,
  ): JSX.Element => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        open() {
          setOpenDrawer(true);
        },
        close() {
          setOpenDrawer(false);
        },
      }),
      [],
    );

    const handleClose = () => {
      !isConfirmBeforeClose && setOpenDrawer(false);
      onClose && onClose();
    };

    return (
      <StyledDrawer anchor='right' open={openDrawer} sx={sx}>
        <Box
          className='flex justify-between items-center pb-6 border-b-2'
          borderColor='neutral.ne200'
        >
          <Box component='div'>
            <Typography variant={description ? 'h6' : 'h5'}>{title}</Typography>
            {description && (
              <Typography variant='body2' color='neutral.ne500'>
                {description}
              </Typography>
            )}
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className='flex-1 overflow-auto'>{children}</Box>
        {!isWithoutActions && (
          <Box
            className='pt-[26px] border-t-2 flex justify-end items-center'
            borderColor='neutral.ne200'
            {...buttonBoxProps}
          >
            {ButtonComponents}
          </Box>
        )}
      </StyledDrawer>
    );
  },
);
