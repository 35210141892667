import { Box, Typography } from '@mui/material';
import React from 'react';

interface AmoutBoxProps {
  title: string;
  amount: string | number;
}

const AmoutBox: React.FC<AmoutBoxProps> = ({ title, amount }) => {
  return (
    <Box p={2} className='rounded-[14px]' bgcolor='base.white' position='relative'>
      <Box position='absolute' width='24px' height='5px' bgcolor='secondary.se700' top={0} />
      <Typography variant='body3' textTransform='uppercase' fontSize='12px' color='neutral.ne800'>
        {title}
      </Typography>
      <Typography variant='h6' mt='12px' fontWeight={500}>
        {amount || 0}
      </Typography>
    </Box>
  );
};

export default AmoutBox;
