/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Box, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { LinkIcon } from 'src/assets/icons/LinkIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import { CLIENT_STATUS, MAX_FUND_ADMIN_ADDITIONAL_CONTACT } from 'src/constants/client-management';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import { useGetClientDetail } from 'src/modules/client/hooks';
import { useCountry } from 'src/modules/common/hooks';
import { swapArrayElements } from 'src/utils/common';
import { FUND_UPSERT_CLIENT_STEP } from './funds/FundsCreateUpdateClientForm';

interface IContactDetailsFormProps {
  clientStatus: number;
  isEditMode: boolean;
  isViewMode: boolean;
  clientId: string;
  clientDetails: any;
}

const ContactDetailsForm: FC<IContactDetailsFormProps> = ({
  clientStatus,
  isEditMode,
  isViewMode,
  clientId,
}) => {
  const { parsePhoneNumber } = useCountry();
  const [isDisableCreateAdditional, setIsDisableCreateAdditional] = useState<boolean>(false);

  const { isAdmin } = detectPortalType();
  const { control, watch, reset, setValue } = useFormContext();
  const { data: clientDetails } = useGetClientDetail(
    clientId,
    FUND_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
  );

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'clientAdministrators',
  });

  const watchAdministrators = watch('clientAdministrators');

  useEffect(() => {
    handleFillFundData();
  }, [clientDetails?.contactDetails]);

  useEffect(() => {
    handleDisableCreateAdditional();
  }, [watchAdministrators]);

  const generateFormData = (data: any) => {
    if (isEmpty(data)) return;
    const primaryIndex = data?.findIndex((item: any) => item?.isPrimary);
    let clientAdministrators = data?.map((administrator: any) => {
      const { contactNumber, countryFlagCode } = parsePhoneNumber(administrator?.phoneNumber || '');

      return {
        ...administrator,
        phoneNumber: contactNumber || administrator?.phoneNumber,
        countryFlagCode,
      };
    });

    if (primaryIndex > 0) {
      clientAdministrators = swapArrayElements(clientAdministrators, primaryIndex, 0);
    }

    return clientAdministrators;
  };

  const handleFillFundData = () => {
    if (isEmpty(clientDetails) || isAdmin) return;
    const clientAdministrators = generateFormData(clientDetails?.contactDetails);

    if (!isEmpty(clientDetails?.contactDetails)) {
      reset({
        clientAdministrators,
      });
    }
  };

  const handleDisableCreateAdditional = () => {
    const additionalContacts = (watchAdministrators || [])?.filter((item: any) => !item?.isPrimary);
    const numOfAdditionalContacts = additionalContacts?.length || 0;

    setIsDisableCreateAdditional(numOfAdditionalContacts >= MAX_FUND_ADMIN_ADDITIONAL_CONTACT);
  };

  const getControlledFields = () => {
    const watchFieldArray = watch('clientAdministrators');
    const controlledContactDetailFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });

    return controlledContactDetailFields;
  };

  const handleAddMoreItem = () => {
    if (isViewMode || isDisableCreateAdditional) return;
    const isSendEmail = !(
      isEditMode && [CLIENT_STATUS.Active.id, CLIENT_STATUS.Pending.id].includes(clientStatus)
    );

    append({
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '',
      isPrimary: false,
      isSendEmail,
      isAddNew: true,
      countryFlagCode: DEFAULT_COUNTRY,
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const handleAssignPrimary = (index: number) => {
    if (isViewMode) return;

    const controlledContactDetailFields = getControlledFields();

    controlledContactDetailFields[0].isPrimary = false;
    controlledContactDetailFields[index].isPrimary = true;

    setValue('clientAdministrators', swapArrayElements(controlledContactDetailFields, index, 0));
  };

  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderContactItems = () => {
    const controlledContactDetailFields = getControlledFields();

    return (
      !!controlledContactDetailFields &&
      controlledContactDetailFields.map((item, index) => {
        const itemIndex = index.toString();
        const isPrimary = item.isPrimary;
        const panelId = `panel${itemIndex}`;
        const isPendingStatus = clientStatus === CLIENT_STATUS.Pending.id;
        const isDisabledField = isAdmin && isPendingStatus && !item.isAddNew && !isViewMode;

        return (
          <Accordion
            key={item.id}
            expanded={isPrimary || expanded === panelId}
            className='flex flex-col gap-2 mt-4 py-4 px-6'
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              hideIcon={isPrimary}
              className='w-full flex items-center justify-between'
            >
              <Box
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
                className={`flex items-center w-full ${isPrimary ? '' : 'justify-between'}`}
              >
                {isPrimary && (
                  <Box className='rotate-[135deg] ml-[-8px]'>
                    <LinkIcon />
                  </Box>
                )}
                <Typography variant='body2' className='ml-2 font-medium' color='base.black'>
                  {isPrimary ? 'Primary Contact' : 'Additional Contact'}
                </Typography>
                {!isPrimary && (
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveItem(index);
                    }}
                    disabled={isViewMode}
                  >
                    <DeleteIcon width={22} height={24} />
                  </IconButton>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className='flex flex-col gap-6 rounded-[10px]' bgcolor='neutral.ne100'>
                <Box className='w-full flex gap-6'>
                  <FormInput
                    name={`clientAdministrators[${itemIndex}].firstName`}
                    label='First Name'
                    size='small'
                    placeholder='Enter first name'
                    className='w-full'
                    disabled={isDisabledField}
                    readOnly={isViewMode}
                  />
                  <FormInput
                    name={`clientAdministrators[${itemIndex}].lastName`}
                    label='Last Name'
                    size='small'
                    placeholder='Enter last name'
                    className='w-full'
                    disabled={isDisabledField}
                    readOnly={isViewMode}
                  />
                </Box>
                <Box className='w-full flex gap-6'>
                  <FormInput
                    name={`clientAdministrators[${itemIndex}].email`}
                    label='Email Address'
                    size='small'
                    placeholder='Enter email address'
                    className='w-full'
                    disabled={isDisabledField}
                    readOnly={isViewMode}
                  />
                  <FormPhoneCountryInput
                    name={`clientAdministrators[${itemIndex}].phoneNumber`}
                    countryFlagCodeName={`clientAdministrators[${itemIndex}].countryFlagCode`}
                    label='Contact Number'
                    size='small'
                    className='w-full'
                    disabled={isDisabledField}
                    readOnly={isViewMode}
                    countryFlagCode={item?.countryFlagCode || DEFAULT_COUNTRY}
                  />
                </Box>
                <Box className='w-full flex gap-6'>
                  <FormInput
                    name={`clientAdministrators[${itemIndex}].position`}
                    label='Position'
                    size='small'
                    placeholder='Enter position'
                    className='w-full'
                    disabled={isDisabledField}
                    readOnly={isViewMode}
                  />
                </Box>
                <Box className={`flex items-center ${isAdmin ? 'justify-between' : 'justify-end'}`}>
                  {isAdmin && (
                    <Box className='flex items-center'>
                      <FormCheckbox
                        name={`clientAdministrators[${itemIndex}].isSendEmail`}
                        defaultChecked={!isEditMode}
                        disabled={isViewMode}
                        label=''
                      />
                      <Typography
                        className='ml-3'
                        variant='body2'
                        color={isViewMode ? 'neutral.ne500' : 'initial'}
                      >
                        Set as administrator and send invitation email.
                      </Typography>
                    </Box>
                  )}

                  {!isPrimary && (
                    <Typography
                      variant='body2'
                      className='italic underline cursor-pointer'
                      color={isViewMode ? 'neutral.ne500' : 'secondary'}
                      onClick={() => handleAssignPrimary(index)}
                    >
                      Assign as Primary Contact
                    </Typography>
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })
    );
  };

  return (
    <>
      {renderContactItems()}
      <Box className='flex items-center px-6 cursor-pointer mt-6' onClick={handleAddMoreItem}>
        <PlusPrimaryIcon />
        <Typography
          variant='body2'
          className='ml-2 font-medium'
          color={isViewMode || isDisableCreateAdditional ? 'neutral.ne500' : 'initial'}
        >
          Create additional contact
        </Typography>
      </Box>
    </>
  );
};

export default ContactDetailsForm;
