import _ from 'lodash';
import { detectPortalType } from 'src/helpers/common';
import { IContact } from 'src/modules/communication/type';

const { isInvestor, isFunds } = detectPortalType();
export const createNestedStructure = (
  data: any[],
  keys: string[],
  customRenderName?: (oldName: string, groupByName: string, groupedData: any) => string,
  parentRootId?: string,
): any => {
  const groupByName = keys[0];
  if (keys.length === 1) {
    return _.chain(data)
      .groupBy(groupByName)
      .map((groupedData, groupByValue) => {
        let investors: any = [];
        if (isInvestor) {
          groupedData.forEach((ele) => {
            investors = investors.concat(
              ele.investors.map((item: any) => ({
                ...groupedData[0],
                ...item,
                name: [item?.fullName, 'Investor Relation', item?.email]
                  .filter((it) => !!it)
                  .join(' | '),
                parentRootId,
              })),
            );
          });
        }
        if (isFunds) {
          groupedData.forEach((ele) => {
            // New update: Only get communication list
            investors = investors.concat(
              ele.communicationList.map((item: IContact) => ({
                ...item,
                investorId: ele.investorId,
                name: `${item.name} | ${item.email}`,
                parentRootId,
              })),
            );
          });
        }
        const name = groupedData[0]?.[groupByName.replace('Id', '')];

        return {
          id: groupByValue,
          parentRootId,
          name: customRenderName
            ? customRenderName(name, groupByName, {
                ...groupedData[0],
                investorNumber: investors?.[0]?.investorNumber,
              })
            : name,
          children: investors,
        };
      })
      .value();
  }

  const remainingKeys = keys.slice(1);

  return _.chain(data)
    .groupBy(groupByName)
    .map((groupedData, groupByValue) => {
      const name = groupedData[0]?.[groupByName.replace('Id', '')];
      return {
        id: groupByValue,
        name: customRenderName ? customRenderName(name, groupByName, groupedData[0]) : name,
        children: createNestedStructure(groupedData, remainingKeys, customRenderName, groupByValue),
      };
    })
    .value();
};
