/* eslint-disable @typescript-eslint/no-empty-function */
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { detectPortalType } from 'src/helpers/common';
import { getToken } from 'src/helpers/storage';
import { DEFAULT_PAGINATED } from 'src/modules/common/hooks';
import { useGetNotificationList } from 'src/modules/notifications/hooks';
import { INotificationItem, INotificationQueryBody } from 'src/modules/notifications/type';

export const NotificationContext = React.createContext<{
  notifications: INotificationItem[];
  totalUnread: number;
  setQueryNotificationParams: (newParams: INotificationQueryBody) => void;
  handleLoadMoreData: () => void;
  setNotifications: React.Dispatch<React.SetStateAction<INotificationItem[]>>;
  setTotalUnread: React.Dispatch<React.SetStateAction<number>>;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  hasMore: boolean;
  queryNotificationParams: INotificationQueryBody;
}>({
  notifications: [],
  totalUnread: 0,
  setQueryNotificationParams: () => {},
  handleLoadMoreData: () => {},
  setNotifications: () => {},
  setTotalUnread: () => {},
  setTotal: () => {},
  total: 0,
  hasMore: false,
  queryNotificationParams: {},
});

interface INotificationContextProvider {
  children: React.ReactNode;
}

const NotificationContextProvider: React.FC<INotificationContextProvider> = ({ children }) => {
  const { isFunds, portalType } = detectPortalType();
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const [totalUnread, setTotalUnread] = useState(0);
  const [total, setTotal] = useState(0);
  const {
    data: {
      notificationItems: {
        items = [],
        metadata: { totalItem, page, pageSize },
      },
      totalUnread: numOfUnread,
    },
    setParams,
    params,
  } = useGetNotificationList();
  const totalPage = Math.ceil(totalItem / pageSize);
  const hasMore = page + 1 < totalPage;

  useEffect(() => {
    // Create hub connection
    const url = isFunds
      ? `${
          process.env.REACT_APP_FUND_PORTAL_API_URL?.split('api/')[0]
        }notification?x-tenant-id=${portalType}`
      : `${process.env.REACT_APP_ADMIN_PORTAL_API_URL?.split('api/')[0]}notification`;
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => getToken() || '',
      })
      .build();
    hubConnection
      .start()
      .then(() => {
        hubConnection.on('NotificationItem', handleReceiveNotificationItem);
      })
      .catch((error) => console.log(error));

    return () => {
      hubConnection.stop();
    };
  }, []);

  useEffect(() => {
    if (items) setNotifications(items);
  }, [items]);

  useEffect(() => {
    if (Number.isInteger(numOfUnread)) setTotalUnread(numOfUnread);
  }, [numOfUnread]);

  useEffect(() => {
    if (Number.isInteger(totalItem)) setTotal(totalItem);
  }, [totalItem]);

  const handleReceiveNotificationItem = (item: INotificationItem) => {
    setNotifications((prev) => [item, ...prev]);
    setTotalUnread((prev) => prev + 1);
    setTotal((prev) => prev + 1);
  };

  const handleLoadMoreData = () => {
    if (!hasMore) return;

    setParams({
      pageSize: pageSize + DEFAULT_PAGINATED.pageSize,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        totalUnread,
        setQueryNotificationParams: setParams,
        queryNotificationParams: params,
        handleLoadMoreData,
        setNotifications,
        setTotalUnread,
        setTotal,
        total,
        hasMore,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
