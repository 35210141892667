/* eslint-disable no-restricted-imports */
import {
  AlertColor,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  AbnAcnTypeCode,
  COMPANY_FLAG_DESCRIPTOR,
  DEFAULT_UBOS_POSITION,
  FLAG_SEVERITY,
  INVESTMENT_ENTITY_TYPE,
  InvestmentEntityCompanyRegionType,
  SpecialCompanyType,
  UBOS_META,
  abnAcnNotAllowedStatus,
  australianCompanyDefaultValues,
  australianCompanyTypeOptions,
  individualDefaultValue,
  specialCompanyTypeOptions,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import { transformCompanyFlagSections } from 'src/helpers/sykc';
import { showToast } from 'src/helpers/toast';
import { useSkycMapping } from 'src/hooks/useSkycMapping';
import {
  useApplicationSkycCreateApp,
  useApplicationSkycGetApp,
  useCheckDuplicateAbnAcn,
  useSearchSkyc,
  useUpsertInvestmentEntity,
} from 'src/modules/applications/hooks';
import { applicationSkycGetApp } from 'src/modules/applications/services';
import {
  IFlagsToInvestigate,
  IInvestmentEntityForm,
  ISkyc,
  ISkycGetApp,
} from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { checkAllowEditAbn, handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces, handleErrorFromServer } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import DirectorFormItem from './DirectorFormItem';
import IndividualDetail from './IndividualDetail';
import LoadingComponent from './LoadingComponent';
import PopulateDetailsConfirm from './PopulateDetailsConfirm';
import UploadCertified from './UploadCertified';

export interface IAustralianCompanyFormProps extends IInvestmentEntityProps {
  disabledFields?: ('countryOfEstablishment' | 'acn' | 'abn')[];
  keyName:
    | 'investmentEntityDetails.australianCompany'
    | 'investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.smsf.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails';
  disabledManualEntry?: boolean;
  searchAbnFor?: 'TrustTee' | 'Appointer';
  onShowFullFieldsChange?: (isShowFullFields: boolean) => void;
  hideUBODeclaration?: boolean;
  isClearAllData?: boolean;
  isSuperAdmin?: boolean;
}

const AustralianCompanyForm: FC<IAustralianCompanyFormProps> = (props) => {
  const {
    keyName,
    isViewMode,
    isDisabledEdit,
    id = '',
    isEditMode,
    isExistingInvestmentEntity,
    getInvestmentEntitySubmitData,
    isInvestmentEntitySetting,
    disabledFields = [],
    disabledManualEntry = false,
    investmentEntityInfo = {},
    hideAbnSearch,
    hideUbo,
    searchAbnFor,
    isReviewing,
    onShowFullFieldsChange,
    hideUBODeclaration,
    isClearAllData = false,
    isSuperAdmin = false,
  } = props;
  const { countryOptions, countries } = useCountry();
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const { isInvestor, isFunds } = detectPortalType();
  const isCreateMode = !isViewMode && !isEditMode;
  const [showFullFields, setShowFullFields] = useState(false);
  const [showPopulateButton, setShowPopulateButton] = useState(false);
  const [selectedSkyc, setSelectedSkyc] = useState<ISkyc & OptionType>();
  const [flagsToInvestigate, setFlagsToInvestigate] = useState<IFlagsToInvestigate[]>([]);
  const [disabledAbnAcn, setDisabledAbnAcn] = useState({
    abn: false,
    acn: false,
  });
  const confirmModalRef = useRef<IBasicModalElement>(null);
  const loadingModalRef = useRef<IBasicModalElement>(null);
  const { control, watch, setValue, setError, clearErrors, getFieldState, getValues } =
    useFormContext<IInvestmentEntityForm>();

  const {
    fields: directors,
    append: addDirector,
    remove: removeDirector,
  } = useFieldArray({
    control,
    name: `${keyName}.directors`,
    keyName: 'dataId',
  });
  const { error: directorError } = getFieldState(`${keyName}.directors`);

  const {
    fields: ubOs,
    append: addUbO,
    remove: removeUBO,
  } = useFieldArray({
    control,
    name: `${keyName}.ubOs`,
    keyName: 'dataId',
  });

  const manualEntryKey =
    keyName === 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails'
      ? 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.isManualEntry'
      : 'investmentEntityDetails.isManualEntry';
  const applicationIdKey =
    keyName === 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails'
      ? 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.skycApplicationId'
      : 'investmentEntityDetails.skycApplicationId';

  const isSharesHeldBehalfOfTrust = watch(`${keyName}.isSharesHeldBehalfOfTrust`);
  const specialCompanyType = watch(`${keyName}.specialCompanyType`);
  const watchManualEntry = watch(manualEntryKey);
  const watchSkycApplicationId = watch(applicationIdKey);

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Company,
    companyRegionType: InvestmentEntityCompanyRegionType.Australian,
  });
  const { mutate: checkDuplicateAbnAcnMutate } = useCheckDuplicateAbnAcn();
  const { mutate: skycCreateAppMutate } = useApplicationSkycCreateApp();
  const { mutate: skycGetAppMutate } = useApplicationSkycGetApp();
  const { mutateAsync: upsertInvestmentEntity } = useUpsertInvestmentEntity();

  const australianCompanyData = watch(keyName);

  useEffect(() => {
    if (isEmpty(australianCompanyData)) {
      setValue(keyName, australianCompanyDefaultValues);
    }
  }, []);

  useEffect(() => {
    handleCheckDisabledAbnAcn();
  }, [isInvestmentEntitySetting, isClearAllData, isSuperAdmin, isFunds]);

  useEffect(() => {
    if (typeof watchManualEntry === 'boolean' && !showFullFields) {
      setShowFullFields(true);
    }
  }, [watchManualEntry]);

  useEffect(() => {
    if (watchSkycApplicationId && !showFullFields) {
      setShowFullFields(true);
    }
    if (!isCreateMode) handleGetFlag();
  }, [watchSkycApplicationId, isCreateMode]);

  useEffect(() => {
    onShowFullFieldsChange && onShowFullFieldsChange(showFullFields);
  }, [showFullFields]);

  const handleCheckDisabledAbnAcn = () => {
    if (
      [
        'investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails',
        'investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails',
        'investmentEntityDetails.smsf.trusteeAustralianCompanyDetails',
      ].includes(keyName) &&
      isInvestmentEntitySetting &&
      !isClearAllData
    ) {
      let isAllowedEditAbn = checkAllowEditAbn(australianCompanyData?.abn);
      if (isFunds && isSuperAdmin) {
        isAllowedEditAbn = true;
      }

      setDisabledAbnAcn({
        acn: !!australianCompanyData?.acn,
        abn: !isAllowedEditAbn,
      });
    } else if (isClearAllData) {
      setDisabledAbnAcn({
        acn: false,
        abn: false,
      });
    }
  };

  const handleAddDirector = () => {
    addDirector({
      ...individualDefaultValue(true),
      position: DEFAULT_UBOS_POSITION.DIRECTOR,
    });
  };

  const handleAddUBO = () => {
    addUbO(individualDefaultValue(true));
  };

  const handleGetFlag = () => {
    if (!watchSkycApplicationId || (isInvestor && isInvestmentEntitySetting)) return;
    skycGetAppMutate(isFunds ? watchSkycApplicationId : id, {
      onSuccess: (data) => setFlagsToInvestigate(data.data.flagsToInvestigate),
      onError: handleErrorFromServer,
    });
  };

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleCheckAbnAcnLogic = (isDuplicated: boolean, skyc: OptionType & ISkyc) => {
    const abnItem = skycData.find(
      (item) => item.status.type === AbnAcnTypeCode.ABN && skyc.bn === item.bn,
    );
    const acnItem = skycData.find(
      (item) => item.status.type === AbnAcnTypeCode.ACN && skyc.cn === item.cn,
    );
    const isNotAllowedAbn = abnAcnNotAllowedStatus[AbnAcnTypeCode.ABN].includes(
      abnItem?.status?.value || '',
    );
    const isNotAllowedAcn = abnAcnNotAllowedStatus[AbnAcnTypeCode.ACN].includes(
      acnItem?.status?.value || '',
    );

    if (!isNotAllowedAbn && !isNotAllowedAcn) {
      !showFullFields && setShowPopulateButton(!isDuplicated);
    } else if (isNotAllowedAbn) {
      setError(`${keyName}.abn`, {
        message: 'ABN is invalid',
      });
      !showFullFields && setShowPopulateButton(!isDuplicated);
    } else if (isNotAllowedAcn) {
      !!acnItem?.status?.value &&
        setError(`${keyName}.acn`, {
          message: `ACN is ${acnItem?.status?.value}`,
        });
      setShowPopulateButton(false);
    }

    const option = { shouldDirty: true };
    setValue(`${keyName}.companyName`, skyc.label, option);
    setValue(`${keyName}.abn`, skyc.value, option);
    setValue(`${keyName}.acn`, skyc.cn, option);

    !showFullFields && setShowFullFields(isDuplicated);
    if (
      isDuplicated &&
      keyName !== 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails'
    ) {
      setValue('investmentEntityDetails.isManualEntry', false);
    }
  };

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    if (reason === 'selectOption') {
      clearErrors(`${keyName}.abn`);
      clearErrors(`${keyName}.acn`);

      const isEntityTypeMatch = handleCheckEntityTypeMatch(skyc as OptionType & ISkyc, 'company');
      if (isEntityTypeMatch) {
        const searchParams = { ...investmentEntityInfo, abn: skyc.value, acn: skyc.cn };
        searchAbnFor === 'TrustTee' && delete searchParams.appointerType;
        searchAbnFor === 'Appointer' && delete searchParams.trusteeType;

        checkDuplicateAbnAcnMutate(
          {
            params: searchParams,
          },
          {
            onSuccess: ({ isDuplicated }) => {
              handleCheckAbnAcnLogic(isDuplicated, skyc as OptionType & ISkyc);
            },
            onError: handleErrorFromServer,
          },
        );
        setSelectedSkyc(skyc as OptionType & ISkyc);
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const handlePopulateDetails = (data: ISkycGetApp) => {
    const {
      companyType,
      countryOfEstablishment,
      principalPlaceOfBusiness,
      registeredAddress,
      ubOs,
    } = useSkycMapping({
      data,
      countries,
    });
    setValue(`${keyName}.countryOfEstablishment`, countryOfEstablishment);
    setValue(`${keyName}.principalPlaceOfBusiness`, principalPlaceOfBusiness);
    setValue(`${keyName}.companyTypeSelect`, companyType);
    setValue(`${keyName}.registeredAddress`, registeredAddress);
    setValue(
      `${keyName}.directors`,
      ubOs.map((it) => ({ ...individualDefaultValue(), ...it })),
    );
    setFlagsToInvestigate(data.data.flagsToInvestigate);
  };

  const handleSaveInvestmentEntity = () => {
    const data = getInvestmentEntitySubmitData ? getInvestmentEntitySubmitData() : getValues();
    upsertInvestmentEntity(
      {
        id,
        data,
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSkycGetApp = async (applicationId: string, intervalId?: any) => {
    try {
      const data = await applicationSkycGetApp(isFunds ? applicationId : id);
      intervalId && clearInterval(intervalId);
      setValue('investmentEntityDetails.skycApplicationId', applicationId);
      handlePopulateDetails(data);
      setValue('isSaveDraft', true);
      isInvestor && handleSaveInvestmentEntity();
      loadingModalRef?.current?.close();
      return data;
    } catch (error: any) {
      /**
       * If the status is 400 that means SKYC application's status is still in_progress so we continue to make API call
       * If the status !== 400 that means another error happens, so we break the API call and show the error from a server
       */
      if (error?.status !== 400) {
        handleErrorFromServer(error);
        loadingModalRef.current?.close();
        intervalId && clearInterval(intervalId);
      }
    }
  };

  const handleCreateApp = () => {
    loadingModalRef?.current?.open();
    skycCreateAppMutate(
      {
        searchId: selectedSkyc?.searchId || '',
        cn: selectedSkyc?.cn || '',
        bn: selectedSkyc?.bn || '',
        id,
      },
      {
        onSuccess: async (data) => {
          handleCloseConfirmModal();
          const skycResponse = await handleSkycGetApp(data.applicationId);
          if (skycResponse) return;
          /**
           * Make interval API call until having data
           * setTimeout: Auto clear interval after 20 times API call
           */
          const intervalId: any = setInterval(
            () => handleSkycGetApp(data.applicationId, intervalId),
            8000,
          );
          setTimeout(() => {
            clearInterval(intervalId);
            loadingModalRef.current?.close();
          }, 8000 * 15);
        },
        onError: (error) => {
          handleErrorFromServer(error);
          loadingModalRef.current?.close();
        },
      },
    );
  };

  const handleOpenConfirmModal = () => {
    confirmModalRef.current?.open();
  };

  const handleCloseConfirmModal = () => {
    confirmModalRef.current?.close();
    setShowFullFields(true);
    setShowPopulateButton(false);
  };

  const renderSpecialCompanyTypeRecord = () => {
    if (!Number.isInteger(specialCompanyType)) return null;
    let fields = null;
    switch (specialCompanyType) {
      case SpecialCompanyType.AustralianStockExchange:
        fields = (
          <Grid item xs={12} key={0}>
            <FormInput
              name={`${keyName}.asxTickerCode`}
              label='ASX Ticker Code'
              placeholder='Enter ASX ticker code'
              disabled={disabled}
            />
          </Grid>
        );
        break;
      case SpecialCompanyType.MajorityOwnedOrForeignStockExchange:
        fields = (
          <Fragment key={1}>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.nameOfListedCompany`}
                label='Name of Listed Company'
                placeholder='Enter name of listed company'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.nameOfStockExchangeName`}
                label='Name of Stock Exchange'
                placeholder='Enter name of stock exchange'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.listedTickerCode`}
                label='Listed Ticker Code'
                placeholder='Enter listed ticker code'
                disabled={disabled}
              />
            </Grid>
          </Fragment>
        );
        break;
      case SpecialCompanyType.LicensedAndRegulated:
        fields = (
          <Fragment key={2}>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.regulatorName`}
                label='Regulator Name'
                placeholder='Enter regulator name'
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name={`${keyName}.licenceName`}
                label='Licencee Number'
                placeholder='Enter licencee number'
                disabled={disabled}
              />
            </Grid>
          </Fragment>
        );
        break;
      default:
        break;
    }

    return (
      !!fields && (
        <Grid container rowSpacing='18px' columnSpacing='20px' pt='18px'>
          {fields}
        </Grid>
      )
    );
  };

  const renderFlagSection = () => {
    if (isExistingInvestmentEntity || (isInvestor && isInvestmentEntitySetting)) return null;
    const cloneFlags = cloneDeep(flagsToInvestigate);
    const companyFlagsToRender = transformCompanyFlagSections(cloneFlags);
    if (isEmpty(companyFlagsToRender)) return null;
    const isShowBehaveOnTrust = companyFlagsToRender.some(
      (it) => it.descriptor === COMPANY_FLAG_DESCRIPTOR.NonBeneficiallyHeldSharesFlag,
    );
    const hasTrustFlag = companyFlagsToRender.some(
      (it) => it.descriptor === COMPANY_FLAG_DESCRIPTOR.TrustFlag,
    );

    return (
      <>
        <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
        {companyFlagsToRender.map((it) =>
          it.colour ? (
            <CustomAlert
              key={it.descriptor}
              severity={FLAG_SEVERITY[it.colour || ''] as AlertColor}
              sx={{ mb: '18px' }}
            >
              {it.message}
            </CustomAlert>
          ) : (
            <Typography
              className='inline-block'
              key={it.descriptor}
              variant='body3'
              fontWeight={600}
              mb='18px'
            >
              {it.message}
            </Typography>
          ),
        )}
        {isShowBehaveOnTrust && (
          <FormRadioGroup
            name={`${keyName}.isSharesHeldBehalfOfTrust`}
            options={TrueFalseRadioOptions}
            isBooleanValue
            optionGap={10}
            disabled={isViewMode}
            label={
              <Typography variant='body3' fontWeight='medium'>
                Are the shares held on behalf of the Trust?
              </Typography>
            }
            showPickerOnly={isReviewing}
          />
        )}
        {(isSharesHeldBehalfOfTrust === true || hasTrustFlag) && (
          <UploadCertified title='Upload Certified Trust Deed' {...props} />
        )}
        {isSharesHeldBehalfOfTrust === false && (
          <Box mt='18px'>
            <FormInput
              name={`${keyName}.redFlagComment`}
              label='Comment'
              placeholder='Enter comment'
              multiline
              disabled={isViewMode}
              rows={4}
            />
          </Box>
        )}
      </>
    );
  };

  const options = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  const isShowPopulateDetails =
    showPopulateButton &&
    typeof watchManualEntry !== 'boolean' &&
    !watchSkycApplicationId &&
    !isExistingInvestmentEntity;

  return (
    <>
      <Box>
        <Grid container rowSpacing='18px' columnSpacing='20px'>
          {!isExistingInvestmentEntity && !isInvestmentEntitySetting && !hideAbnSearch && (
            <Grid item xs={12}>
              <Box className='flex flex-col items-end'>
                <FormAutocomplete
                  name='skyc'
                  dataKey='id'
                  options={options}
                  searchIcon={<SearchIcon />}
                  isLoading={isSearchSkycLoading}
                  placeholder='Search by Company Name, ABN, ACN'
                  hidePopupIcon
                  fullWidth
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box className='flex flex-col'>
                        <Typography variant='body3' fontWeight='medium'>
                          {option.label}
                        </Typography>
                        <Box className='flex items-center pt-1'>
                          <Typography variant='caption1' color='neutral.ne500' paddingRight={3}>
                            {`ABN: ${option.value}`}
                          </Typography>
                          {!!option?.cn && (
                            <Typography variant='caption1' color='neutral.ne500'>
                              {`ACN: ${option.cn}`}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </li>
                  )}
                  filterOptions={(options) => options}
                  getOptionLabel={() => ''}
                  onInputChange={handleSearchSkycInputChange}
                  onChange={handleSkycChange}
                  disabled={disabled}
                  onFocus={() => clearErrors(`${keyName}.abn`)}
                  blurOnSelect
                />
                <FormCheckbox
                  name={manualEntryKey}
                  label={
                    <Typography className='block' mt={1} variant='body3'>
                      Manual Entry
                    </Typography>
                  }
                  disabled={disabled || disabledManualEntry}
                  sx={{
                    mt: 1,
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInput
              name={`${keyName}.companyName`}
              label='Company Name'
              placeholder='Enter company name'
              disabled={(!watchManualEntry && !isInvestmentEntitySetting) || disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name={`${keyName}.acn`}
              label='ACN'
              placeholder='Enter ACN'
              formatValue={(value) => formatValueWithSpaces(value)}
              disabled={
                (!watchManualEntry && !isInvestmentEntitySetting) ||
                disabled ||
                disabledFields?.includes('acn') ||
                disabledAbnAcn.acn
              }
              maxLength={14}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name={`${keyName}.abn`}
              label='ABN'
              placeholder='Enter ABN'
              formatValue={(value) => formatValueWithSpaces(value)}
              disabled={
                (!watchManualEntry && !isInvestmentEntitySetting) ||
                disabled ||
                disabledFields?.includes('abn') ||
                disabledAbnAcn.abn
              }
              maxLength={14}
            />
          </Grid>
          {isShowPopulateDetails && (
            <>
              <Grid item xs={6}>
                <CustomHelperText
                  variant='error'
                  message={`Please select 'Search' to automatically fill in the information or select 'Manual Entry' to enter the details manually.`}
                />
              </Grid>
              <Grid item xs={6}>
                <Box className='flex justify-end'>
                  <CustomButton variant='outlined' onClick={handleOpenConfirmModal}>
                    Search
                  </CustomButton>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {(showFullFields || isExistingInvestmentEntity || isInvestmentEntitySetting) && (
          <>
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            <Grid container columnSpacing='20px' rowSpacing='18px'>
              <Grid item xs={6}>
                <FormSelect
                  name={`${keyName}.countryOfEstablishment`}
                  options={countryOptions}
                  label='Country of Establishment'
                  placeholder='Select country of establishment'
                  disabled={disabled || disabledFields?.includes('countryOfEstablishment')}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  name={`${keyName}.countryOfTaxResident`}
                  label='Country of Tax Residence'
                  placeholder='Select country of tax residence'
                  disabled={disabled}
                  options={countryOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  name={`${keyName}.companyTypeSelect`}
                  label='Company Type'
                  placeholder='Select company type'
                  options={australianCompanyTypeOptions}
                  disabled={disabled}
                  defaultValue={australianCompanyTypeOptions[0].value}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  name={`${keyName}.registeredAddress`}
                  label='Registered Address'
                  placeholder='Enter registered address'
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name={`${keyName}.principalPlaceOfBusiness`}
                  label='Principal Place of Business'
                  placeholder='Enter principal place of business'
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            {renderFlagSection()}
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            <FormSelect
              name={`${keyName}.specialCompanyType`}
              label={
                <Typography variant='body3' fontWeight={600}>
                  Listing and Regulatory Details
                </Typography>
              }
              options={specialCompanyTypeOptions}
              placeholder='Select listing and regulatory details'
              disabled={disabled}
              defaultValue={specialCompanyTypeOptions.slice(-1)[0].value}
            />
            {renderSpecialCompanyTypeRecord()}
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
            <FormArrayLayout
              {...props}
              title='Directors'
              onAddItem={handleAddDirector}
              disabled={disabled}
              helperText={<CustomHelperText variant='error' message={directorError?.message} />}
            >
              <Grid container rowSpacing='16px' mb='16px'>
                {(directors || []).map((director, index) => (
                  <Grid key={director.dataId} item xs={12}>
                    <DirectorFormItem
                      {...props}
                      keyName={`${keyName}.directors.${index}`}
                      onDelete={() => removeDirector(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormArrayLayout>
            <Divider className='my-6' sx={{ borderColor: 'neutral.ne200' }} />
            {!hideUbo && (
              <FormArrayLayout
                {...props}
                title='Ultimate Beneficial Owners'
                tooltip={UBOS_META.COMMON.TOOLTIP}
                disabled={disabled}
                onAddItem={handleAddUBO}
              >
                <Grid container rowSpacing='16px' mb='16px'>
                  {(directors || []).map(
                    (director, index) =>
                      director.refId && (
                        <Grid key={director.dataId} item xs={12}>
                          <IndividualDetail
                            {...props}
                            keyName={`${keyName}.directors.${index}`}
                            hasPosition
                            hideTfn
                            disabledBasicInfoFields={['firstName', 'lastName']}
                            autocompleteAddress
                            hideDeclaration={hideUBODeclaration}
                          />
                        </Grid>
                      ),
                  )}
                  {ubOs.map((ubOs, index) => (
                    <Grid key={ubOs.dataId} item xs={12}>
                      <IndividualDetail
                        {...props}
                        keyName={`${keyName}.ubOs.${index}`}
                        handleDelete={() => removeUBO(index)}
                        canDelete
                        hasPosition
                        hideTfn
                        autocompleteAddress
                        hideDeclaration={hideUBODeclaration}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormArrayLayout>
            )}
          </>
        )}
      </Box>
      <BasicModal
        ref={confirmModalRef}
        title={
          <Typography variant='h5' px={2} pt={3}>
            Confirmation
          </Typography>
        }
      >
        <PopulateDetailsConfirm onConfirm={handleCreateApp} onClose={handleCloseConfirmModal} />
      </BasicModal>
      <BasicModal ref={loadingModalRef} disableClose>
        <LoadingComponent />
      </BasicModal>
    </>
  );
};

export default AustralianCompanyForm;
