/* eslint-disable no-restricted-imports */
import { Box, Divider, Typography } from '@mui/material';
import { isEmpty, pick } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CompanyIcon from 'src/assets/icons/CompanyIcon';
import IndividualsIcon from 'src/assets/icons/IndividualsIcon';
import OtherIcon from 'src/assets/icons/OtherIcon';
import PartnershipIcon from 'src/assets/icons/PartnershipIcon';
import SMSFIcon from 'src/assets/icons/SMSFIcon';
import SoleTraderIcon from 'src/assets/icons/SoleTraderIcon';
import TrustIcon from 'src/assets/icons/TrustIcon';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import Company from 'src/components/pages/applications/components/InvestmentEntity/Company';
import Individuals from 'src/components/pages/applications/components/InvestmentEntity/Individuals';
import Other from 'src/components/pages/applications/components/InvestmentEntity/Other';
import Partnership from 'src/components/pages/applications/components/InvestmentEntity/Partnership';
import SMSF from 'src/components/pages/applications/components/InvestmentEntity/SMSF';
import SoleTrader from 'src/components/pages/applications/components/InvestmentEntity/SoleTrader';
import Trust from 'src/components/pages/applications/components/InvestmentEntity/Trust';
import {
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  individualDefaultValue,
} from 'src/constants/applications';
import {
  useGetApplicationById,
  useGetApplicationEntityTypes,
  useGetInvestmentEntityDetail,
  useGetInvestmentEntityUserInfo,
} from 'src/modules/applications/hooks';
import {
  IInvestmentEntityForm,
  IInvestmentEntityTypeInfo,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { swapArrayElements } from 'src/utils/common';
import { INVESTMENT_APPLICATION_STEP } from './CreateFundraisingApplicationForm';

export interface IInvestmentEntityProps extends IUpsertInvestmentApplicationForm {
  isForeignTaxResident?: boolean;
  initiateVerificationComponent?: ReactNode;
  getInvestmentEntitySubmitData?: () => IInvestmentEntityForm;
  hideUbo?: boolean;
  hideAbnSearch?: boolean;
  hideCertifiedDescription?: boolean;
  isExistingInvestor?: boolean;
}
const FundInvestmentEntity = (props: IInvestmentEntityProps) => {
  const { id = '', investmentEntityId = '', isViewMode, isReviewing, isExistingInvestor } = props;
  const { watch, reset, getValues } = useFormContext<IInvestmentEntityForm>();
  const currentEntityType = watch('entityType');
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  const { data } = useGetApplicationEntityTypes(id);
  const { data: existingInvestmentEntity } = useGetInvestmentEntityDetail(investmentEntityId);
  const { data: userInfoByEntity } = useGetInvestmentEntityUserInfo(investmentEntityId);

  const availableEntityTypes = data?.map((it) => it.id)?.sort() || [];
  const isInvestmentEntityAlreadySubmitted = Number.isInteger(investmentEntity?.entityType);

  // Handle fill data for investment entity section
  useEffect(() => {
    let editorDetails: any = {};
    if (isInvestmentEntityAlreadySubmitted) {
      editorDetails = { ...investmentEntity?.editorDetails };
    } else if (!isEmpty(userInfoByEntity)) {
      editorDetails = {
        ...userInfoByEntity,
        // default value for each application
        hasAuthorityToComplete: true,
      };
    }
    const newInvestmentEntity = { ...existingInvestmentEntity };
    newInvestmentEntity.investmentEntityDetails = existingInvestmentEntity?.entityDetails || {};
    delete newInvestmentEntity.entityDetails;

    if (!isEmpty(editorDetails)) {
      handleFillInvestmentEntityDetails({
        editorDetails: editorDetails,
        ...newInvestmentEntity,
      });
    } else {
      handleFillInvestmentEntityDetails(newInvestmentEntity);
    }
  }, [
    userInfoByEntity,
    investmentEntity,
    isInvestmentEntityAlreadySubmitted,
    existingInvestmentEntity,
  ]);

  const handleFillInvestmentEntityDetails = (investmentEntity: IInvestmentEntityForm) => {
    if (isEmpty(investmentEntity)) return;
    const {
      australianCompany,
      foreignCompany,
      association,
      governmentBody,
      regulatedTrust,
      unregulatedTrust,
      smsf,
      partnership,
    } = investmentEntity?.investmentEntityDetails || {};

    const australianCompanyDirectors =
      (australianCompany?.directors || []).map((it) => it.refId) || [];
    const australianCompanyUBOs: any = {};
    (australianCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        australianCompanyUBOs[it.refId] = it;
      }
    });

    const foreignCompanyDirectorRefs =
      (foreignCompany?.directors || []).map((it) => it.refId) || [];
    const foreignCompanyUBOs: any = {};
    (foreignCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        foreignCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeRegulatedTrustAustralianCompanyDirectors =
      regulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeRegulatedTrustAustralianCompanyUBOs: any = {};
    regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeRegulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.appointerAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const appointerUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        appointerUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerSelectedExistingIndividuals =
      unregulatedTrust?.appointerIndividuals
        ?.filter((it) =>
          (unregulatedTrust?.trusteeAustralianCompanyDetails?.directors || [])
            .concat(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs || [])
            .concat(unregulatedTrust?.trusteeIndividuals || [])
            .map((item) => item.refId)
            .includes(it.refId),
        )
        ?.map((it) => it.refId || '') || [];

    const trusteeSMSFAustralianCompanyDirectors =
      smsf?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeSMSFAustralianCompanyUBOs: any = {};
    smsf?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeSMSFAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const associationPublicOfficerRefs = (association?.publicOfficers || []).map((it) => it.refId);
    const associationUbos: any = (association?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const governmentBodyPublicOfficerRefs = (governmentBody?.publicOfficers || []).map(
      (it) => it.refId,
    );
    const governmentBodyUbos: any = (governmentBody?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const partnershiRefPartners = (partnership?.partners || []).map((it) => it.refId) || [];
    const partnershipUbos: any = {};
    (partnership?.ubOs || []).forEach((it) => {
      if (it.refId) {
        partnershipUbos[it.refId] = it;
      }
    });
    const beVerifiedPartnerIndex =
      (partnership?.partners || []).findIndex((it) => it?.beVerified) || 0;
    const partnershipPartners = (partnership?.partners || []).map(
      (it) => (it.refId && partnershipUbos[it.refId]) || it,
    );

    reset((formState) => ({
      ...formState,
      ...investmentEntity,
      investmentEntityId,
      investmentEntityDetails: {
        ...investmentEntity.investmentEntityDetails,
        numberOfIndividuals: investmentEntity.investmentEntityDetails?.individuals?.length || null,
        australianCompany: {
          ...australianCompany,
          ...(australianCompany?.ubOs
            ? {
                ubOs: australianCompany.ubOs.filter(
                  (it) => !australianCompanyDirectors.includes(it.refId),
                ),
              }
            : {}),
          ...(australianCompany?.directors
            ? {
                directors: australianCompany.directors.map(
                  (it) =>
                    (it.refId && australianCompanyUBOs[it.refId]) || {
                      ...it,
                      populatedDirector: true,
                    },
                ),
              }
            : {}),
        },
        foreignCompany: {
          ...(foreignCompany || {}),
          ubOs: (foreignCompany?.ubOs || []).filter(
            (it) => !foreignCompanyDirectorRefs.includes(it.refId),
          ),
          directors: (foreignCompany?.directors || []).map(
            (it) => (it.refId && foreignCompanyUBOs[it.refId]) || it,
          ),
        },
        regulatedTrust: {
          ...regulatedTrust,
          numberOfIndividuals: regulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: regulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...(regulatedTrust?.trusteeAustralianCompanyDetails || {}),
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: regulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeRegulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: regulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeRegulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        unregulatedTrust: {
          ...unregulatedTrust,
          numberOfIndividuals: unregulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: unregulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          settlor:
            unregulatedTrust?.isSettlorMoreThanMax && unregulatedTrust?.settlor
              ? unregulatedTrust.settlor
              : individualDefaultValue(true),
          trusteeAustralianCompanyDetails: {
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails || {}),
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
          appointerAustralianCompanyDetails: {
            ...unregulatedTrust?.appointerAustralianCompanyDetails,
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.appointerAustralianCompanyDetails.ubOs.filter(
                    (it) => !appointerUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.appointerAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && appointerUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
            isManualEntry:
              (unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
                unregulatedTrust?.isAppointerSameAsTrustee === false) ||
              unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
          },
          selectedExistingIndividuals: appointerSelectedExistingIndividuals,
          appointerIndividuals:
            unregulatedTrust?.appointerIndividuals?.filter(
              (it) => !appointerSelectedExistingIndividuals.includes(it.refId || ''),
            ) || [],
        },
        smsf: {
          ...smsf,
          numberOfIndividuals: smsf?.trusteeIndividuals?.length || null,
          smsfubOs: smsf?.smsfubOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...(smsf?.trusteeAustralianCompanyDetails || {}),
            ...(smsf?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: smsf.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeSMSFAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(smsf?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: smsf.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeSMSFAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        association: {
          ...(association || {}),
          ubOs: (association?.ubOs || []).filter(
            (it) => !associationPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (association?.publicOfficers || []).map(
            (it) => (it.refId && associationUbos[it.refId]) || it,
          ),
        },
        governmentBody: {
          ...(governmentBody || {}),
          ubOs: (governmentBody?.ubOs || []).filter(
            (it) => !governmentBodyPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (governmentBody?.publicOfficers || []).map(
            (it) => (it.refId && governmentBodyUbos[it.refId]) || it,
          ),
        },
        partnership: {
          ...(partnership || {}),
          ubOs: (partnership?.ubOs || []).filter(
            (it) => !it?.refId || !partnershiRefPartners.includes(it.refId),
          ),
          partners: swapArrayElements(partnershipPartners, beVerifiedPartnerIndex, 0),
          certifiedAgreementFile: partnership?.certifiedPartnershipAgreementFile,
          certifiedAgreementFileId: partnership?.certifiedPartnershipAgreementFileId,
          certifiedAgreementFileDescription:
            partnership?.certifiedPartnershipAgreementFileDescription,
        },
      },
      isAddNewEntityDetail: Number.isInteger(investmentEntity.entityType),
    }));
  };

  const formValues = getValues();
  const investmentEntityInfoProps = useMemo(() => {
    const results: IInvestmentEntityTypeInfo = pick(formValues, [
      'entityType',
      'companyRegionType',
      'trustType',
      'otherType',
    ]);
    const { entityType, trustType } = formValues;
    switch (entityType) {
      case INVESTMENT_ENTITY_TYPE.Trust: {
        results.trusteeType =
          trustType === INVESTMENT_ENTITY_TRUST.Regulated
            ? formValues.investmentEntityDetails?.regulatedTrust?.trusteeType
            : formValues.investmentEntityDetails?.unregulatedTrust?.trusteeType;
        results.appointerType =
          trustType === INVESTMENT_ENTITY_TRUST.Unregulated
            ? (formValues.investmentEntityDetails?.unregulatedTrust?.appointerType as number)
            : undefined;
        break;
      }
      case INVESTMENT_ENTITY_TYPE.SMSF: {
        results.trusteeType = formValues.investmentEntityDetails?.smsf?.trusteeType;
        break;
      }
      default:
        break;
    }
    return results;
  }, [formValues]);

  const entityData = {
    [INVESTMENT_ENTITY_TYPE.Individuals]: {
      icon: <IndividualsIcon />,
      value: INVESTMENT_ENTITY_TYPE.Individuals,
      label: 'Individuals',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.Individuals),
      component: <Individuals {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.SoleTrader]: {
      icon: <SoleTraderIcon />,
      value: INVESTMENT_ENTITY_TYPE.SoleTrader,
      label: 'Sole Trader',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.SoleTrader),
      component: <SoleTrader {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.Company]: {
      icon: <CompanyIcon />,
      value: INVESTMENT_ENTITY_TYPE.Company,
      label: 'Company',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.Company),
      component: <Company {...props} investmentEntityInfo={investmentEntityInfoProps} />,
    },
    [INVESTMENT_ENTITY_TYPE.Trust]: {
      icon: <TrustIcon />,
      value: INVESTMENT_ENTITY_TYPE.Trust,
      label: 'Trust',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.Trust),
      component: <Trust {...props} investmentEntityInfo={investmentEntityInfoProps} />,
    },
    [INVESTMENT_ENTITY_TYPE.SMSF]: {
      icon: <SMSFIcon />,
      value: INVESTMENT_ENTITY_TYPE.SMSF,
      label: 'SMSF',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.SMSF),
      component: <SMSF {...props} investmentEntityInfo={investmentEntityInfoProps} />,
    },
    [INVESTMENT_ENTITY_TYPE.Partnership]: {
      icon: <PartnershipIcon />,
      value: INVESTMENT_ENTITY_TYPE.Partnership,
      label: 'Partnership',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.Partnership),
      component: <Partnership {...props} investmentEntityInfo={investmentEntityInfoProps} />,
    },
    [INVESTMENT_ENTITY_TYPE.Other]: {
      icon: <OtherIcon />,
      value: INVESTMENT_ENTITY_TYPE.Other,
      label: 'Other',
      disabled: !availableEntityTypes.includes(INVESTMENT_ENTITY_TYPE.Other),
      component: <Other {...props} investmentEntityInfo={investmentEntityInfoProps} />,
    },
  };

  return (
    <Box mt={2} display={'flex'} flexDirection={'column'}>
      <Typography variant='body2' fontWeight={500}>
        Select Entity Type
      </Typography>
      <FormRadioGroup
        containerSx={{
          '.MuiStack-root': {
            gap: 0,
            justifyContent: 'space-between',
            mr: '-11px',
          },
          '.MuiFormControlLabel-root': { mr: 0 },
        }}
        name='entityType'
        options={Object.values(entityData).filter((it) =>
          isExistingInvestor ? it.value === currentEntityType : it,
        )}
        label={''}
        isEnumValue
        disabled={
          (Number.isInteger(investmentEntity?.entityType) && !!investmentEntityId) || isViewMode
        }
        showPickerOnly={isReviewing}
      />
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      {entityData[currentEntityType]?.component}
    </Box>
  );
};

export default FundInvestmentEntity;
