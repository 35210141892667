import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { IBasicDrawerElement } from 'src/components/atoms/BasicDrawer';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer } from 'src/components/atoms/CustomDrawer';
import { upsertProviderDefaultValues } from 'src/constants/funds-management';

import {
  useGetDetailServiceProvider,
  useGetTrusteeAndServiceProvidersCapacities,
  useUpsertServiceProvider,
} from 'src/modules/funds-management/hooks';
import {
  INewExisting,
  ITrusteeAndServiceProvidersFormFields,
  IUpsertFundForm,
  IUpsertProviderFormFields,
  IUpsertServiceProvider,
  ServiceProviderTypeEnum,
} from 'src/modules/funds-management/type';
import { deepCheckEmptyObject, handleErrorFromServer } from 'src/utils/common';
import { upsertProviderSchema } from 'src/validations/funds-management';
import TrusteeAndServiceProvidersSection from './TrusteeAndServiceProvidersSection';
import UpsertProviderForm from './UpsertProviderForm';

interface ITrusteeAndServiceProvidersProps extends IUpsertFundForm {}

export const SECTIONS = {
  trusteeDetails: {
    name: 'trustee',
    title: 'Trustee/GP Details',
  },
  manager: {
    name: 'manager',
    title: 'Investment Manager',
  },
  serviceProviders: {
    name: 'providers',
    title: 'Service Providers',
  },
};

export const ACTION_MODE = {
  edit: 'edit',
  view: 'view',
};

const TrusteeAndServiceProviders: React.FC<ITrusteeAndServiceProvidersProps> = ({
  isViewMode,
  isEditMode,
  isDraft,
}) => {
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [selectedExisting, setSelectedExisting] = useState<string>('');
  const [actionMode, setActionMode] = useState('');
  const upsertProviderFormRef = useRef<IBasicDrawerElement>(null);

  const { watch, setValue, getValues } = useFormContext<ITrusteeAndServiceProvidersFormFields>();
  const upsertProviderForm = useForm<IUpsertProviderFormFields>({
    defaultValues: {
      ...upsertProviderDefaultValues,
      capacities: selectedSection === SECTIONS.serviceProviders.name ? [] : undefined,
    },
    resolver: yupResolver(upsertProviderSchema),
  });

  const watchClientId = watch('clientId');

  console.log('checked', isViewMode, isEditMode, isDraft);

  const { data: capacitiesData } = useGetTrusteeAndServiceProvidersCapacities();
  const { mutate: upsertServiceProviderMutate, isLoading: upsertProviderLoading } =
    useUpsertServiceProvider();
  const { data: serviceProviderDetail, refetch: refetchServiceProviderDetail } =
    useGetDetailServiceProvider(selectedExisting, watchClientId);

  useEffect(() => {
    handleFillServiceProviderData();
  }, [serviceProviderDetail, actionMode]);

  const handleFillServiceProviderData = () => {
    if (isEmpty(serviceProviderDetail)) return;
    const { address = {}, ...restData } = serviceProviderDetail;
    const physicalAddress = {
      country: {
        value: get(address, 'countryId', ''),
        label: get(address, 'countryName', ''),
        id: get(address, 'countryId', ''),
      },
      state: {
        value: get(address, 'stateId', ''),
        label: get(address, 'stateName', ''),
        id: get(address, 'stateId', ''),
      },
      city: {
        value: get(address, 'cityId', ''),
        label: get(address, 'cityName', ''),
        id: get(address, 'cityId', ''),
      },
      postcode: get(address, 'postcode', ''),
      streetAddress: get(address, 'streetAddress', ''),
      manualEntry: get(address, 'manualEntry', false),
    };

    const postalAddress = {
      country: {
        value: get(address, 'postalCountryId', ''),
        label: get(address, 'postalCountryName', ''),
        id: get(address, 'postalCountryId', ''),
      },
      state: {
        value: get(address, 'postalStateId', ''),
        label: get(address, 'postalStateName', ''),
        id: get(address, 'postalStateId', ''),
      },
      city: {
        value: get(address, 'postalCityId', ''),
        label: get(address, 'postalCityName', ''),
        id: get(address, 'postalCityId', ''),
      },
      postcode: get(address, 'postalPostcode', ''),
      streetAddress: get(address, 'postalStreetAddress', ''),
      manualEntry: get(address, 'postalManualEntry', false),
    };
    const capacities = handleFilterCapacities(restData?.capacities || []);
    const additionalCapacities = handleGetAdditionalCapacities(restData?.capacities);

    upsertProviderForm.reset({
      ...restData,
      capacities,
      serviceProvidersSection: restData.type === ServiceProviderTypeEnum.ServiceProviders,
      physicalAddress,
      postalAddress,
      additionalCapacities,
      setAsInvestmentManager: Number.isInteger(additionalCapacities),
    });
  };

  const handleFilterCapacities = (capacities: number[]) => {
    const sectionType = getSectionType();
    if (sectionType === ServiceProviderTypeEnum.ServiceProviders) return capacities;
    if (sectionType === ServiceProviderTypeEnum.InvestmentManager) {
      return capacities?.filter((item) =>
        capacitiesData?.investmentManager?.some((capacity) => capacity.id === item),
      )?.[0];
    }

    return capacities?.filter((item) =>
      capacitiesData?.trusteeGpDetails?.some((capacity) => capacity.id === item),
    )?.[0];
  };

  const handleGetAdditionalCapacities = (capacities: number[]) => {
    const sectionType = getSectionType();

    if (sectionType === ServiceProviderTypeEnum.TrusteeGpDetails) {
      return capacities?.filter((item) =>
        capacitiesData?.trusteeGpDetails?.every((capacity) => capacity.id !== Number(item)),
      )?.[0];
    }
  };

  const handleAddNew = (sectionName: string) => {
    setSelectedSection(sectionName);
    upsertProviderForm.reset(upsertProviderDefaultValues);
    upsertProviderForm.setValue(
      'serviceProvidersSection',
      sectionName === SECTIONS.serviceProviders.name,
    );
    upsertProviderFormRef.current?.open();
  };

  const getSectionType = (): number => {
    switch (selectedSection) {
      case SECTIONS.trusteeDetails.name:
        return ServiceProviderTypeEnum.TrusteeGpDetails;
      case SECTIONS.manager.name:
        return ServiceProviderTypeEnum.InvestmentManager;
      case SECTIONS.serviceProviders.name:
        return ServiceProviderTypeEnum.ServiceProviders;
      default:
        return 0;
    }
  };

  const generateUpsertProviderBody = (data: IUpsertProviderFormFields) => {
    const {
      physicalAddress,
      postalAddress,
      primaryContacts,
      isUseSameAsPhysicalAddress,
      capacityNames,
      setAsInvestmentManager,
      additionalCapacities,
      ...restData
    } = data;
    const type = getSectionType();

    const cityName =
      typeof physicalAddress.city === 'string'
        ? physicalAddress.city
        : String(get(physicalAddress, 'city.label', ''));

    const postalCityName =
      typeof postalAddress.city === 'string'
        ? postalAddress.city
        : String(get(postalAddress, 'city.label', ''));

    const address = {
      countryName: get(physicalAddress, 'country.label', ''),
      countryId: String(get(physicalAddress, 'country.id', '')),
      stateName: get(physicalAddress, 'state.label', ''),
      stateId: String(get(physicalAddress, 'state.id', '')),
      cityId: String(get(physicalAddress, 'city.id', '')),
      cityName,
      postcode: physicalAddress.postcode,
      streetAddress: physicalAddress.streetAddress,
      postalCountryName: get(postalAddress, 'country.label', ''),
      postalCountryId: String(get(postalAddress, 'country.id', '')),
      postalStateName: get(postalAddress, 'state.label', ''),
      postalStateId: String(get(postalAddress, 'state.id', '')),
      postalCityName,
      postalCityId: String(get(postalAddress, 'city.id', '')),
      postalPostcode: postalAddress.postcode,
      postalStreetAddress: postalAddress.streetAddress,
      manualEntry: physicalAddress.manualEntry,
      postalManualEntry: postalAddress.manualEntry,
      isUseSameAsPhysicalAddress,
    };

    const capacities =
      restData.capacities instanceof Array ? restData.capacities : [Number(restData.capacities)];

    if (Number.isInteger(additionalCapacities)) {
      capacities.push(additionalCapacities as number);
    }

    const body: IUpsertServiceProvider = {
      ...restData,
      primaryContacts: primaryContacts.map(({ id, ...rest }) => ({
        ...rest,
        clientId: watchClientId,
      })),
      capacities,
      type,
    };

    if (!deepCheckEmptyObject(address)) {
      body.address = address;
    }

    return body;
  };

  const handleGetCapacityNames = (selectedCapacity: any, additionalCapacities?: number) => {
    const capacityNames = [
      ...(capacitiesData?.trusteeGpDetails || []),
      ...(capacitiesData?.investmentManager || []),
      ...(capacitiesData?.serviceProviders || []),
    ]
      .filter((item) => {
        if (selectedCapacity instanceof Array) {
          return selectedCapacity.includes(item.id);
        }

        if (additionalCapacities === item.id) return true;

        return item.id === Number(selectedCapacity);
      })
      .map((item) => item.name);

    return capacityNames;
  };

  const handleSyncProviders = (data: IUpsertProviderFormFields) => {
    const capacityNames = handleGetCapacityNames(data.capacities, data.additionalCapacities);

    const newExistingItem = {
      id: data?.id || '',
      capacityNames: capacityNames,
      capacityIds: data.capacities,
      displayName: data.displayName,
    };

    switch (selectedSection) {
      case SECTIONS.trusteeDetails.name: {
        if (data.setAsInvestmentManager) {
          setValue('manager.isAddNew', false);
          setValue('manager.newExisting', []);
          setValue('manager.type', ServiceProviderTypeEnum.InvestmentManager);
          setValue('manager.existing', data?.id as string);
          setValue('manager.capacity', data.additionalCapacities as any);
        } else if (watch('manager.existing') === data.id) {
          setValue('manager.isAddNew', false);
          setValue('manager.newExisting', []);
          setValue('manager.type', ServiceProviderTypeEnum.InvestmentManager);
          setValue('manager.existing', '');
          setValue('manager.capacity', '');
        }

        setValue('trustee.isAddNew', true);
        setValue('trustee.newExisting', [newExistingItem]);
        setValue('trustee.type', ServiceProviderTypeEnum.TrusteeGpDetails);
        break;
      }
      case SECTIONS.manager.name:
        setValue('manager.isAddNew', true);
        setValue('manager.newExisting', [newExistingItem]);
        setValue('manager.type', ServiceProviderTypeEnum.InvestmentManager);
        break;
      case SECTIONS.serviceProviders.name: {
        const currentNewExisting = getValues('providers.newExisting') || [];
        const existingIndex = currentNewExisting.findIndex(
          (item) => item.id === newExistingItem.id,
        );

        if (existingIndex >= 0) {
          currentNewExisting[existingIndex] = newExistingItem;
          setValue('providers.newExisting', currentNewExisting);
        } else {
          setValue('providers.newExisting', [newExistingItem, ...currentNewExisting]);
        }
        setValue('providers.type', ServiceProviderTypeEnum.ServiceProviders);
        break;
      }
    }
  };

  const handleUpsertProvider = (data: IUpsertProviderFormFields) => {
    const body = generateUpsertProviderBody(data);

    upsertServiceProviderMutate(body, {
      onSuccess: (id: string) => {
        handleSyncProviders(id ? { ...data, id } : data);
        handleCloseDrawer();
      },
      onError: handleErrorFromServer,
    });
  };

  const handleOpenDrawer = () => {
    upsertProviderFormRef.current?.open();
  };

  const handleCloseDrawer = () => {
    upsertProviderFormRef.current?.close();
    upsertProviderForm.setValue('serviceProvidersSection', false);
    actionMode && setActionMode('');
  };

  const handleClickProvider = (mode: string, existingItem: INewExisting, section: string) => {
    if (existingItem?.id !== selectedExisting) {
      setSelectedExisting(existingItem?.id || '');
    } else {
      refetchServiceProviderDetail();
    }
    setActionMode(mode);
    setSelectedSection(section);
    upsertProviderForm.setValue('serviceProvidersSection', mode === SECTIONS.serviceProviders.name);
    handleOpenDrawer();
  };

  const getCapacitesBySection = () => {
    switch (selectedSection) {
      case SECTIONS.trusteeDetails.name:
        return capacitiesData?.trusteeGpDetails;
      case SECTIONS.manager.name:
        return capacitiesData?.investmentManager;
      case SECTIONS.serviceProviders.name:
        return capacitiesData?.serviceProviders;
      default:
        return [];
    }
  };

  const renderButtonComponent = () => {
    if (actionMode === ACTION_MODE.view) return <></>;
    return (
      <Box className='flex gap-2'>
        <CustomButton
          sx={{ color: 'neutral.ne800' }}
          variant='text'
          disabled={upsertProviderLoading}
          onClick={handleCloseDrawer}
        >
          Cancel
        </CustomButton>
        <CustomButton
          className='w-[160px]'
          isLoading={upsertProviderLoading}
          onClick={upsertProviderForm.handleSubmit(handleUpsertProvider)}
        >
          {actionMode === ACTION_MODE.edit ? 'Save' : 'Create new'}
        </CustomButton>
      </Box>
    );
  };

  const renderDrawerTitle = () => {
    switch (actionMode) {
      case ACTION_MODE.edit:
        return 'Edit';
      case ACTION_MODE.view:
        return 'View';
      default:
        return 'Add New';
    }
  };

  return (
    <>
      <Box className='w-full' component='form'>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TrusteeAndServiceProvidersSection
              title={SECTIONS.trusteeDetails.title}
              name={SECTIONS.trusteeDetails.name}
              onAddNew={() => handleAddNew(SECTIONS.trusteeDetails.name)}
              onClickProvider={handleClickProvider}
              capacities={capacitiesData?.trusteeGpDetails || []}
              serviceProviderType={ServiceProviderTypeEnum.TrusteeGpDetails}
              isViewMode={!!isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <TrusteeAndServiceProvidersSection
              title={SECTIONS.manager.title}
              name={SECTIONS.manager.name}
              onAddNew={() => handleAddNew(SECTIONS.manager.name)}
              onClickProvider={handleClickProvider}
              capacities={capacitiesData?.investmentManager || []}
              serviceProviderType={ServiceProviderTypeEnum.InvestmentManager}
              isViewMode={!!isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <TrusteeAndServiceProvidersSection
              title={SECTIONS.serviceProviders.title}
              name={SECTIONS.serviceProviders.name}
              isOptional
              onAddNew={() => handleAddNew(SECTIONS.serviceProviders.name)}
              onClickProvider={handleClickProvider}
              capacities={capacitiesData?.serviceProviders || []}
              serviceProviderType={ServiceProviderTypeEnum.ServiceProviders}
              isViewMode={!!isViewMode}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomDrawer
        ref={upsertProviderFormRef}
        title={renderDrawerTitle()}
        ButtonComponents={renderButtonComponent()}
        onClose={handleCloseDrawer}
      >
        <FormProvider {...upsertProviderForm}>
          <UpsertProviderForm
            capacities={getCapacitesBySection() || []}
            readOnly={actionMode === ACTION_MODE.view}
            serviceProvidersSection={selectedSection === SECTIONS.serviceProviders.name}
            investmentManagerCapacities={capacitiesData?.investmentManager || []}
            /**
             * Only show the checkbox for these conditions:
             * - Create fund mode or edit draft fund
             * - Create mode with trustee details form
             */
            showSetAsIMCheckbox={
              actionMode !== ACTION_MODE.view &&
              selectedSection === SECTIONS.trusteeDetails.name &&
              ((!isViewMode && !isEditMode) || isDraft)
            }
          />
        </FormProvider>
      </CustomDrawer>
    </>
  );
};

export default TrusteeAndServiceProviders;
