import * as Yup from 'yup';
import { YupBase } from './common';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%&_?=-])/;

export const validationForgotPasswordSchema = Yup.object().shape({
  email: YupBase.email,
});

export const validationResetPasswordSchema = Yup.object().shape({
  password: YupBase.password,
  confirmPassword: YupBase.password.oneOf(
    [Yup.ref('password'), ''],
    'Passwords do not match. Please try again.',
  ),
});

export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Password is required'),
  newPassword: YupBase.password,
  confirmPassword: YupBase.password.oneOf(
    [Yup.ref('newPassword'), ''],
    'Passwords do not match. Please try again.',
  ),
});
