import * as React from 'react';
interface IAdviserIconProps {}
const AdviserIcon: React.FunctionComponent<IAdviserIconProps> = (props) => {
  return (
    <svg
      width='38px'
      height='38px'
      viewBox='0 0 38 38'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ borderRadius: '9px' }}
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={38} height={38} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Company_04'
          transform='translate(-762.000000, -1098.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Add-New-Entity' transform='translate(196.000000, 365.000000)'>
                <g
                  id='Collapse-First-name---Last-name'
                  transform='translate(24.000000, 251.000000)'
                >
                  <g id='Capacity' transform='translate(40.000000, 178.000000)'>
                    <g id='Op1-Copy' transform='translate(207.000000, 32.000000)'>
                      <g id='Group-2' transform='translate(5.000000, 5.000000)'>
                        <mask id='mask-2' fill='white'>
                          <use xlinkHref='#path-1' />
                        </mask>
                        <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                        <g id='consulting-services' mask='url(#mask-2)'>
                          <g transform='translate(22.000000, 3.000000)'>
                            <path
                              d='M4.36575875,13 C4.26459144,13 4.21400778,12.9444444 4.11284047,12.8888889 C4.06225681,12.7777778 4.01167315,12.6111111 4.11284047,12.4444444 L5.47859922,10.1111111 L3.80933852,10.1111111 C3.3540856,10.1111111 0,9.72222222 0,9.22222222 L0,0.888888889 C0,0.388888889 0.354085603,0 0.809338521,0 L12.1906615,0 C12.6459144,0 13,0.388888889 13,0.888888889 L13,9.22222222 C13,9.72222222 12.6459144,10.1111111 12.1906615,10.1111111 L8.76653696,10.1111111 L4.51750973,12.9444444 C4.46692607,12.9444444 4.41634241,13 4.36575875,13 Z'
                              id='Path'
                              fill='#E87187'
                            />
                            <g
                              id='Group'
                              strokeWidth={1}
                              fill='none'
                              transform='translate(1.461538, 2.154891)'
                            >
                              <path
                                d='M9.68421053,3.25 L0.315789474,3.25 C0.105263158,3.25 0,3.046875 0,2.84375 C0,2.640625 0.105263158,2.4375 0.315789474,2.4375 L9.68421053,2.4375 C9.89473684,2.4375 10,2.640625 10,2.84375 C10,3.046875 9.89473684,3.25 9.68421053,3.25 Z'
                                id='Path'
                                fill='#FFFFFF'
                              />
                              <path
                                d='M9.68421053,5.6875 L0.315789474,5.6875 C0.105263158,5.6875 0,5.484375 0,5.28125 C0,5.01041667 0.105263158,4.875 0.315789474,4.875 L9.68421053,4.875 C9.89473684,4.875 10,5.01041667 10,5.28125 C10,5.484375 9.89473684,5.6875 9.68421053,5.6875 Z'
                                id='Path'
                                fill='#FFFFFF'
                              />
                              <path
                                d='M9.68421053,0.8125 L0.315789474,0.8125 C0.105263158,0.8125 0,0.609375 0,0.40625 C0,0.135416667 0.105263158,0 0.315789474,0 L9.68421053,0 C9.89473684,0 10,0.135416667 10,0.40625 C10,0.609375 9.89473684,0.8125 9.68421053,0.8125 Z'
                                id='Path'
                                fill='#FFFFFF'
                              />
                            </g>
                          </g>
                        </g>
                        <g mask='url(#mask-2)'>
                          <g transform='translate(4.000000, 6.000000)'>
                            <path
                              d='M7.07865169,11.6455696 C6.87640449,11.6455696 6.6741573,11.5443038 6.57303371,11.3417722 C6.47191011,11.3417722 5.86516854,10.2278481 5.66292135,8.50632911 C5.56179775,6.98734177 5.86516854,4.65822785 8.08988764,2.32911392 C8.08988764,2.32911392 8.08988764,2.32911392 8.08988764,2.2278481 C8.19101124,2.2278481 10.5168539,0 13.3483146,0 C14.7640449,0 16.0786517,0.506329114 17.1910112,1.41772152 C17.5955056,1.62025316 18.505618,1.92405063 19.3146067,2.73417722 C20.3258427,3.64556962 21.3370787,5.26582278 20.7303371,8 L20.7303371,9.82278481 C20.6292135,10.2278481 20.6292135,10.9367089 19.9213483,10.9367089 C19.5168539,10.9367089 19.3146067,10.5316456 19.1123596,10.0253165 C18.8089888,9.41772152 18.3033708,8.20253165 17.5955056,8.10126582 C17.3932584,8.10126582 17.1910112,8 17.0898876,7.79746835 C16.7865169,7.18987342 16.3820225,7.08860759 16.0786517,7.08860759 C15.4719101,7.08860759 14.5617978,7.49367089 13.752809,7.89873418 C12.6404494,8.50632911 11.4269663,9.11392405 10.1123596,9.11392405 C9.70786517,9.11392405 9.30337079,9.01265823 9,8.91139241 L7.58426966,11.3417722 C7.48314607,11.5443038 7.28089888,11.6455696 7.07865169,11.6455696 Z'
                              id='Path'
                              fill='#4A5160'
                            />
                            <path
                              d='M26.3932584,32 L0.606741573,32 C0.303370787,32 0,31.6962025 0,31.3924051 L0,27.9493671 C0,23.0886076 3.84269663,19.2405063 8.69662921,19.2405063 L10.3146067,19.2405063 C10.6179775,19.2405063 10.9213483,19.443038 10.9213483,19.6455696 C11.2247191,20.8607595 12.3370787,21.6708861 13.5505618,21.6708861 C14.6629213,21.6708861 15.7752809,20.8607595 16.0786517,19.6455696 C16.1797753,19.443038 16.3820225,19.2405063 16.6853933,19.2405063 L18.4044944,19.2405063 C23.1573034,19.2405063 27,23.0886076 27,27.9493671 L27,31.3924051 C27,31.6962025 26.7977528,32 26.3932584,32 Z'
                              id='Path'
                              fill='#4689D3'
                            />
                            <g
                              id='_302687720'
                              strokeWidth={1}
                              fill='none'
                              transform='translate(5.764045, 24.708861)'
                            >
                              <path
                                d='M1.21348315,7.29113924 L0,7.29113924 L0,0.607594937 C0,0.202531646 0.202247191,0 0.606741573,0 C0.91011236,0 1.21348315,0.202531646 1.21348315,0.607594937 L1.21348315,7.29113924 Z'
                                id='Path'
                                fill='#3F7BBE'
                              />
                              <path
                                d='M15.5730337,7.29113924 L14.3595506,7.29113924 L14.3595506,0.607594937 C14.3595506,0.202531646 14.5617978,0 14.9662921,0 C15.2696629,0 15.5730337,0.202531646 15.5730337,0.607594937 L15.5730337,7.29113924 Z'
                                id='Path'
                                fill='#3F7BBE'
                              />
                            </g>
                            <path
                              d='M13.5505618,22.8860759 C11.4269663,22.8860759 9.60674157,21.164557 9.60674157,18.9367089 L9.60674157,16.9113924 C9.60674157,16.7088608 9.70786517,16.5063291 9.91011236,16.4050633 C10.1123596,16.3037975 10.4157303,16.3037975 10.5168539,16.4050633 C11.4269663,16.9113924 12.4382022,17.2151899 13.5505618,17.2151899 C14.5617978,17.2151899 15.5730337,16.9113924 16.4831461,16.4050633 C16.5842697,16.4050633 16.6853933,16.3037975 16.7865169,16.3037975 C17.0898876,16.3037975 17.3932584,16.6075949 17.3932584,16.9113924 L17.3932584,18.9367089 C17.3932584,21.164557 15.6741573,22.8860759 13.5505618,22.8860759 Z'
                              id='Path'
                              fill='#E6C2A5'
                            />
                            <path
                              d='M13.5505618,18.4303797 C9.60674157,18.4303797 6.47191011,15.2911392 6.47191011,11.3417722 L6.47191011,11.0379747 C6.47191011,10.7341772 6.7752809,10.4303797 7.07865169,10.4303797 L8.08988764,7.89873418 L8.08988764,7.79746835 L8.19101124,7.79746835 L18.7078652,6.27848101 L18.8089888,6.27848101 L18.8089888,6.37974684 L19.9213483,9.72151899 C20.3258427,9.72151899 20.5280899,10.0253165 20.5280899,10.3291139 L20.5280899,11.3417722 C20.5280899,15.2911392 17.3932584,18.4303797 13.5505618,18.4303797 Z'
                              id='Path'
                              fill='#FFD8B7'
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default AdviserIcon;
