/* eslint-disable react/self-closing-comp */
import * as React from 'react';
import { FC } from 'react';

export const Fundraising: FC<React.SVGProps<SVGSVGElement>> = ({ color, ...rest }) => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...rest}
    >
      <g id='Sprint-1---Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Navigation_01'
          transform='translate(-23.000000, -184.000000)'
          fill={color || '#FFFFFF'}
          fillRule='nonzero'
        >
          <g id='KYC-Menu' transform='translate(11.000000, 172.000000)'>
            <g
              id='01.Design-Token/06.Icons/Line/grid-outline'
              transform='translate(12.000000, 12.000000)'
            >
              <g id='Group' transform='translate(2.000000, 4.000000)'>
                <path
                  d='M8.49999994,3 C7.42820322,3 6.43782213,3.57179674 5.90192374,4.5 C5.36602534,5.42820322 5.36602534,6.57179678 5.90192374,7.5 C6.43782213,8.42820326 7.42820322,9 8.49999994,9 C10.1568542,9 11.4999999,7.65685425 11.4999999,6 C11.4999999,4.34314575 10.1568542,3 8.49999994,3 L8.49999994,3 Z M7,6 C7,5.17157288 7.67157288,4.5 8.5,4.5 C9.32842712,4.5 10,5.17157288 10,6 C10,6.82842712 9.32842712,7.5 8.5,7.5 C7.67157288,7.5 7,6.82842712 7,6 Z'
                  id='Shape'
                ></path>
                <path
                  d='M0,2.25 C0,1.00735931 1.00735931,0 2.25,0 L14.75,0 C15.3467371,0 15.9190334,0.237052891 16.3409903,0.659009742 C16.7629471,1.08096659 17,1.6532629 17,2.25 L17,9.75 C17,10.3467371 16.7629471,10.9190334 16.3409903,11.3409903 C15.9190334,11.7629471 15.3467371,12 14.75,12 L2.25,12 C1.00735931,12 0,10.9926407 0,9.75 L0,2.25 Z M2.25,1.5 C1.83578644,1.5 1.5,1.83578644 1.5,2.25 L1.5,3 L2.25,3 C2.66421356,3 3,2.66421356 3,2.25 L3,1.5 L2.25,1.5 Z M1.5,7.5 L2.25,7.5 C3.49264069,7.5 4.5,8.50735931 4.5,9.75 L4.5,10.5 L12.5,10.5 L12.5,9.75 C12.5,8.50735931 13.5073593,7.5 14.75,7.5 L15.5,7.5 L15.5,4.5 L14.75,4.5 C13.5073593,4.5 12.5,3.49264069 12.5,2.25 L12.5,1.5 L4.5,1.5 L4.5,2.25 C4.5,3.49264069 3.49264069,4.5 2.25,4.5 L1.5,4.5 L1.5,7.5 L1.5,7.5 Z M15.5,3 L15.5,2.25 C15.5,1.83578644 15.1642136,1.5 14.75,1.5 L14,1.5 L14,2.25 C14,2.664 14.336,3 14.75,3 L15.5,3 Z M15.5,9 L14.75,9 C14.3357864,9 14,9.33578644 14,9.75 L14,10.5 L14.75,10.5 C15.1642136,10.5 15.5,10.1642136 15.5,9.75 L15.5,9 Z M1.5,9.75 C1.5,10.164 1.836,10.5 2.25,10.5 L3,10.5 L3,9.75 C3,9.33578644 2.66421356,9 2.25,9 L1.5,9 L1.5,9.75 L1.5,9.75 Z'
                  id='Shape'
                ></path>
                <path
                  d='M2.401,13.5 C2.93691221,14.4286403 3.92781793,15.0005366 5,15.0000004 L15.25,15.0000004 C16.5097783,15.0000004 17.7179594,14.499555 18.6087572,13.6087572 C19.499555,12.7179594 20,11.5097783 20,10.25 L20,5 C20,3.89 19.397,2.92 18.5,2.401 L18.5,10.25 C18.5,12.0449254 17.0449254,13.5 15.25,13.5 L2.401,13.5 Z'
                  id='Path'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
