export const INVESTER_TYPE = 0;
export const DELEGATE_TYPE = 1;
export const INVESTOR_VIEW_MODE = 'investor';
export const DELEGATE_VIEW_MODE = 'delegate';

export const UserTypeOptions = [
  {
    label: 'Investor',
    value: INVESTER_TYPE,
  },
  {
    label: 'Delegate',
    value: DELEGATE_TYPE,
  },
];

export const ViewModeOptions = [
  {
    label: 'Investor',
    value: INVESTOR_VIEW_MODE,
  },
  {
    label: 'Delegate',
    value: DELEGATE_VIEW_MODE,
  },
];
