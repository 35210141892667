import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import FormSection from 'src/components/molecules/FormSection';
import StatusBadge from 'src/components/molecules/StatusBadge';
import RejectApplicationAction from 'src/components/pages/applications/components/RejectApplicationAction';
import { FUNDRAISING_APPLICATON_TAB } from 'src/components/pages/applications/fundraising';
import { FUNDRAISING_OVERVIEW_STEP, RejectApplicationType } from 'src/constants/applications';
import { kycBackOfficeStatuses } from 'src/constants/kyc-back-office';
import { useGetApplicationOverview } from 'src/modules/applications/hooks';
import { IUpsertInvestmentEntityForm } from 'src/modules/investment-entity/types';
import ActionRequired from './ActionRequired';
import ApplicationDocuments from './ApplicationDocuments';
import ApplicationSummary from './ApplicationSummary';
import KYCReview from './KYCReview';
import SectionLayout from './SectionLayout';

export interface IFundraisingApplicationOverviewProps extends IUpsertInvestmentEntityForm {
  statusName: string;
  onSetApplicationTab: (tab: FUNDRAISING_APPLICATON_TAB, step?: number) => void;
}

const FundraisingApplicationOverview: React.FC<IFundraisingApplicationOverviewProps> = (props) => {
  const { id: appId = '', statusName, isViewMode } = props;

  const { data: overviewKYCReview } = useGetApplicationOverview(
    appId,
    FUNDRAISING_OVERVIEW_STEP.KYCReview,
  );

  const [disableKYCCollapse, setDisableKYCCollapse] = React.useState(false);
  const kycSectionRef = React.useRef<HTMLInputElement>(null);

  const scrollDownToKYCSection = () => {
    kycSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    setDisableKYCCollapse(true);
  };

  const SECTIONS = [
    {
      id: FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
      title: 'Action Required',
      component: <ActionRequired {...props} scrollDownToKYCSection={scrollDownToKYCSection} />,
      canCollapse: false,
    },
    {
      id: FUNDRAISING_OVERVIEW_STEP.KYCReview,
      title: 'KYC Review',
      additionalInfo: (
        <Box ref={kycSectionRef} className='flex items-center gap-2'>
          <Typography variant='body3' fontWeight={500}>
            {`${overviewKYCReview?.kycReview?.caseId || ''} ${
              overviewKYCReview?.kycReview?.caseTeamMember ? '|' : ''
            } ${overviewKYCReview?.kycReview?.caseTeamMember || ''}`}
          </Typography>
          <StatusBadge status={overviewKYCReview?.kycReview?.statusName || ''} />
        </Box>
      ),
      component: overviewKYCReview?.kycReview &&
        overviewKYCReview?.kycReview?.statusName !== kycBackOfficeStatuses.Submitted && (
          <KYCReview applicationId={appId} applicationStatus={statusName} isViewMode={isViewMode} />
        ),
      canCollapse: true,
    },
    {
      id: FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
      title: 'Application Summary',
      component: <ApplicationSummary {...props} statusName={statusName} />,
      canCollapse: true,
    },
    {
      id: FUNDRAISING_OVERVIEW_STEP.ApplicationDocument,
      title: 'Application Documents',
      component: <ApplicationDocuments {...props} />,
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <FormSection
        isViewMode
        hiddenSaveBtn
        hiddenSubmitBtn
        customActionButton={
          <Box flex={1} display='flex' justifyContent='space-between'>
            <RejectApplicationAction
              title='Reject Application'
              appId={appId}
              color='error'
              sx={{ alignSelf: 'flex-start', color: 'error.main' }}
              disabled={isViewMode}
              rejectedType={RejectApplicationType.Application}
              statusName={statusName}
            />
          </Box>
        }
      >
        <Box className='m-auto pt-4'>
          <Grid container rowSpacing={4}>
            {SECTIONS.map((item, index) => (
              <Grid key={index} item xs={12}>
                <SectionLayout
                  title={item.title}
                  additionalInfo={item?.additionalInfo}
                  canCollapse={
                    item.id === FUNDRAISING_OVERVIEW_STEP.KYCReview
                      ? !disableKYCCollapse
                      : item?.canCollapse
                  }
                >
                  {item.component}
                </SectionLayout>
              </Grid>
            ))}
          </Grid>
        </Box>
      </FormSection>
    </Box>
  );
};

export default FundraisingApplicationOverview;
