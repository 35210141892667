import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { FC } from 'react';
import { IAuditTrailItem } from 'src/modules/audit-trail/type';
import AuditTrailDetailItem from './AuditTrailDetailItem';

interface IAuditTrailDetailsProps {
  auditTrailItem?: IAuditTrailItem;
}

interface IComparisonData {
  oldValue: string;
  newValue: string;
  fieldName: string;
}

const AuditTrailDetails: FC<IAuditTrailDetailsProps> = ({ auditTrailItem }) => {
  const { newValue = '', oldValue = '' } = auditTrailItem || {};

  const getComparisonData = () => {
    try {
      const newValueJson = JSON.parse(newValue);
      const oldValueJson = JSON.parse(oldValue);
      const fieldNames = Object.keys(newValueJson);
      const data: IComparisonData[] = [];

      fieldNames.forEach((it) => {
        const newValue = get(newValueJson, it);
        const oldValue = get(oldValueJson, it);

        if (oldValue !== newValue) {
          data.push({
            fieldName: it,
            newValue: typeof newValue === 'object' ? JSON.stringify(newValue) : newValue,
            oldValue: typeof oldValue === 'object' ? JSON.stringify(oldValue) : oldValue,
          });
        }
      });

      return data.sort((a, b) => a.fieldName?.localeCompare(b.fieldName));
    } catch (error) {
      return [];
    }
  };

  const comparisonData = getComparisonData();

  return (
    <Box className='mt-4'>
      <Box>
        <Typography variant='body2' fontWeight={500}>
          Raw Data
        </Typography>
        <AuditTrailDetailItem
          oldValue={oldValue}
          newValue={newValue}
          fieldName=''
          inputProps={{
            multiline: true,
            maxRows: 4,
          }}
        />
      </Box>
      <Box className='w-full h-[2px] my-6' bgcolor='neutral.ne200' />
      <Typography variant='body2' fontWeight={500}>
        Changed Data
      </Typography>
      <Grid container spacing={2}>
        {comparisonData.map((it, index) => (
          <Grid key={index} item xs={12}>
            <AuditTrailDetailItem {...it} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AuditTrailDetails;
