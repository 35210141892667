import { ArrowForward } from '@mui/icons-material';
import { Box, TextFieldProps, Typography } from '@mui/material';
import { FC } from 'react';
import { CustomTextField } from 'src/components/atoms/FormInput';

interface IAuditTrailDetailItemProps {
  oldValue: string;
  newValue: string;
  fieldName: string;
  inputProps?: TextFieldProps;
}

const AuditTrailDetailItem: FC<IAuditTrailDetailItemProps> = ({
  fieldName,
  oldValue,
  newValue,
  inputProps = {},
}) => {
  return (
    <>
      <Box className='flex justify-between items-center' my={1}>
        <Typography variant='body3' fontWeight={500}>
          {fieldName}
        </Typography>
      </Box>
      <Box className='flex justify-between items-center'>
        <CustomTextField {...inputProps} value={oldValue} className='w-full' />
        <Box mx={2}>
          <ArrowForward
            sx={{
              color: 'neutral.ne300',
            }}
          />
        </Box>
        <CustomTextField {...inputProps} value={newValue} className='w-full' />
      </Box>
    </>
  );
};
export default AuditTrailDetailItem;
