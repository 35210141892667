import * as React from 'react';

interface IAdminInvestorDetailIconProps {}

const AdminInvestorDetailIcon: React.FunctionComponent<IAdminInvestorDetailIconProps> = (props) => {
  return (
    <svg
      width='39.9967574px'
      height='40px'
      viewBox='0 0 39.9967574 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Administration_Investors_Edit-Investor_00'
          transform='translate(-445.000000, -341.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 192.000000)'>
              <g id='Header' transform='translate(80.000000, 80.000000)'>
                <g id='Synbol' transform='translate(60.000000, 0.000000)'>
                  <g transform='translate(15.000000, 15.000000)'>
                    <path
                      d='M28.5691149,17.144715 C22.2578023,17.144715 17.1414724,22.2610448 17.1414724,28.5723575 C17.1414724,34.8836702 22.2578023,40 28.5691149,40 C34.8804276,40 39.9967574,34.8836702 39.9967574,28.5723575 C39.9896725,22.2639818 34.8774906,17.1518 28.5691149,17.144715 Z M29.9975703,34.2133275 L29.9975703,34.2861788 C29.9975703,35.0750928 29.358029,35.7146341 28.5691149,35.7146341 C27.7802009,35.7146341 27.1406596,35.0750928 27.1406596,34.2861788 C26.3517455,34.2861788 25.7122043,33.6466375 25.7122043,32.8577234 C25.7122043,32.0688094 26.3517455,31.4292681 27.1406596,31.4292681 L29.2833426,31.4292681 C29.6777996,31.4292681 29.9975703,31.1094975 29.9975703,30.7150405 C29.9975703,30.3205834 29.6777996,30.0008128 29.2833426,30.0008128 L27.8548873,30.0008128 C26.0229106,29.9990631 24.4893952,28.6113416 24.305247,26.7886427 C24.1210988,24.9659439 25.3460536,23.2995327 27.1406596,22.9313875 L27.1406596,22.8585363 C27.1406596,22.0696222 27.7802009,21.4300809 28.5691149,21.4300809 C29.358029,21.4300809 29.9975703,22.0696222 29.9975703,22.8585363 C30.7864843,22.8585363 31.4260256,23.4980775 31.4260256,24.2869916 C31.4260256,25.0759056 30.7864843,25.7154469 29.9975703,25.7154469 L27.8548873,25.7154469 C27.4604302,25.7154469 27.1406596,26.0352175 27.1406596,26.4296745 C27.1406596,26.8241316 27.4604302,27.1439022 27.8548873,27.1439022 L29.2833426,27.1439022 C31.1153193,27.1456519 32.6488347,28.5333734 32.8329829,30.3560723 C33.0171311,32.1787711 31.7921763,33.8451823 29.9975703,34.2133275 L29.9975703,34.2133275 Z M17.412879,19.6587964 C20.1191834,16.2623562 24.2263184,14.2850415 28.5691149,14.2878044 C28.726245,14.2878044 28.8833751,14.2878044 29.0262206,14.3020889 C29.6710124,12.960778 30.0031292,11.4906725 29.9976389,10.0024384 C29.999011,4.81712712 26.0363988,0.491055153 20.8708641,0.0386099763 C15.7053294,-0.413835201 11.0509908,3.15748474 10.1509705,8.26409017 C9.25095025,13.3706956 12.4039088,18.3180227 17.412879,19.6587964 Z'
                      id='Shape'
                    />
                    <path
                      d='M16.1844074,21.45865 C7.09960385,21.9566079 -0.00907361374,29.4731088 0,38.5715447 L18.369944,38.5715447 C13.8868955,34.0024926 12.9935776,27.007755 16.1844074,21.45865 Z'
                      id='Path'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AdminInvestorDetailIcon;
