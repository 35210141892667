import * as React from 'react';

interface ICopyContentIconProps {}

const CopyContentIcon: React.FunctionComponent<ICopyContentIconProps> = () => {
  return (
    <svg
      width='16px'
      height='20.004px'
      viewBox='0 0 16 20.004'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Copy link</title>
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_Create-Clients_06'
          transform='translate(-1001.000000, -465.000000)'
          fill='#1C1C1C'
          fillRule='nonzero'
        >
          <g id='Content' transform='translate(408.000000, 336.000000)'>
            <g id='Group-2' transform='translate(324.000000, 90.000000)'>
              <g
                id='01.Design-Token/06.Icons/Line/link-2-outline'
                transform='translate(265.000000, 37.000000)'
              >
                <g id='Group' transform='translate(4.000000, 2.000000)'>
                  <path
                    d='M1.503,2.627 L1.5,4.75 L1.5,15.254 C1.5,17.0489254 2.95507456,18.504 4.75,18.504 L13.366,18.504 C13.0478289,19.403025 12.197666,20.004 11.244,20.004 L4.75,20.004 C2.12664744,20.004 0,17.8773526 0,15.254 L0,4.75 C0,3.77 0.627,2.935 1.503,2.627 Z M13.75,0 C14.3467371,0 14.9190334,0.237052891 15.3409903,0.659009742 C15.7629471,1.08096659 16,1.6532629 16,2.25 L16,15.25 C16,15.8467371 15.7629471,16.4190334 15.3409903,16.8409903 C14.9190334,17.2629471 14.3467371,17.5 13.75,17.5 L4.75,17.5 C3.50735931,17.5 2.5,16.4926407 2.5,15.25 L2.5,2.25 C2.5,1.00735931 3.50735931,0 4.75,0 L13.75,0 Z M13.75,1.5 L4.75,1.5 C4.33578644,1.5 4,1.83578644 4,2.25 L4,15.25 C4,15.664 4.336,16 4.75,16 L13.75,16 C14.1642136,16 14.5,15.6642136 14.5,15.25 L14.5,2.25 C14.5,1.83578644 14.1642136,1.5 13.75,1.5 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CopyContentIcon;
