import React from 'react';
import {
  changeTextInDOM,
  IUpdateTextItem,
  makeScriptDOM,
  makeStylesheetDOM,
} from 'src/helpers/dom';
import 'src/styles/green-id.css';

export interface IInitGreenIDProps {
  accountId: string;
  apiCode: string;
  scriptConfig: string;
  scriptUi: string;
  style: string;
  monoStyle: string;
  env: string;
  autoInitSource?: boolean;
  onInitCompleted?: () => void;
}

const domId = 'green-id-area';
const defaultText: Array<IUpdateTextItem> = [
  {
    target: (parentNode) => parentNode.querySelector('#greenid-intro-words .lead:nth-child(1)'),
    text: '',
    className: ['hidden'],
  },
  {
    target: (parentNode) => parentNode.querySelector('#greenid-intro-words .lead:nth-child(2)'),
    text: 'Please select your document for verification purposes.',
    className: ['mb-0'],
  },
  {
    target: (parentNode) => parentNode.querySelector('button#greenid-submit'),
    text: 'Verify',
  },
  {
    target: (parentNode) => {
      const elements = parentNode.querySelector('label[for^="greenid_"][for$="_tandc"]');
      const text = elements?.childNodes.item(2);
      return (text as Element) || null;
    },
    text: 'I confirm that I am authorised to provide the personal information presented in this form, and I consent to this information being verified with the documents issuers or official record holders, credit bureaus and other third-party systems for the purposes of identity verification and AML/CTF compliance. I consent for details and results from this identity verification to be stored for audit and compliance purposes in accordance with the Australian Privacy Principles and relevant privacy notice/s.',
  },
  {
    target: (parentNode) => {
      const elements = parentNode.querySelector(
        '#greenid-u-agree label[for="greenid-u-agree-checkbox"]',
      );
      const text = elements?.childNodes.item(2);
      return (text as Element) || null;
    },
    text: 'I confirm that I am authorised to provide the personal information presented in this form, and I consent to this information being verified with the documents issuers or official record holders, credit bureaus and other third-party systems for the purposes of identity verification and AML/CTF compliance. I consent for details and results from this identity verification to be stored for audit and compliance purposes in accordance with the Australian Privacy Principles and relevant privacy notice/s.',
  },
];

export function useGreenID(props: IInitGreenIDProps, callBack: { [key: string]: any }) {
  const [loading, setLoading] = React.useState(false);
  const [area, setArea] = React.useState<HTMLDivElement | null>(null);
  const initGreenIDSource = async () => {
    setLoading(true);
    await Promise.all([
      makeScriptDOM(props.scriptConfig, 'greenIDScriptConfig'),
      makeScriptDOM(props.scriptUi, 'greenIDScriptUi'),
      makeStylesheetDOM(props.style, 'greenIDStyle'),
    ]);
    props?.onInitCompleted?.();
    setLoading(false);
  };

  const removeGreenIdSource = async () => {
    const scriptConfigs = document.querySelectorAll(`script[src="${props.scriptConfig}"]`);
    const scriptUis = document.querySelectorAll(`script[src="${props.scriptUi}"]`);
    if (scriptConfigs.length > 0) {
      scriptConfigs.forEach((scriptConfig) => {
        scriptConfig.remove();
      });
    }
    if (scriptUis.length > 0) {
      scriptUis.forEach((scriptUi) => {
        scriptUi.remove();
      });
    }
  };

  const initGreenID = () => {
    if (!!window.greenidJQuery && !!window.greenidUI) {
      window.greenidUI.setup({
        environment: props.env,
        frameId: domId,
        enableCancelButton: false,
        ...callBack,
      });
    }
  };
  const greenVerifyDocumentID = (token: string) => {
    document.querySelector('.greenIDStyleMono')?.remove();
    window.greenidUI.show(props.accountId, props.apiCode, token);
  };
  const greenVerifyBiometric = (token: string) => {
    makeStylesheetDOM(props.monoStyle, 'greenIDStyleMono');
    window.greenidUI.show(props.accountId, props.apiCode, token);
  };
  const skipVerify = () => {
    setLoading(true);
    window?.greenidUI?.saveAndCompleteLater();
    /**
     * The situation is that after verifying with DocumentID the user cannot verify with the Biometric method for another user.
     * Because the documentID's verificationToken will remain in the "verificationToken" variable of greenidUI.
     *
     * After investigating I discovered the function "sessionCancelledCallback",
     * this function passed the verificationToken as an empty string,
     * then the "verificationToken" variable will be set to empty,
     * then the verify method with Biometric will work normally.
     */
    window?.greenidUI?.sessionCancelledCallback('');
    setLoading(false);
  };

  React.useEffect(() => {
    if (props.autoInitSource) initGreenIDSource();
    return () => {
      setLoading(false);
    };
  }, []);
  React.useEffect(() => {
    if (area) {
      const mutationCallback: MutationCallback = () => {
        defaultText.forEach((i) => changeTextInDOM(i, area));
      };
      const observer = new MutationObserver(mutationCallback);
      const observerConfig = {
        childList: true,
        subtree: true,
      };
      observer.observe(area, observerConfig);
      return () => {
        observer.disconnect();
      };
    }
  }, [area]);
  return {
    domId,
    loading,
    initGreenID,
    greenVerifyDocumentID,
    greenVerifyBiometric,
    initGreenIDSource,
    skipVerify,
    setArea,
    removeGreenIdSource,
  };
}
