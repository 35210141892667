import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';

export interface IConfirmModalElement {
  open: () => void;
  close: () => void;
}

type IConfirmModalProps = Omit<DialogProps, 'open'> & {
  title?: string;
  content?: string;
  ButtonsComponent: ReactNode;
  disableBackdropClick?: boolean;
};

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    padding: '48px 40px 32px',
    borderRadius: 22,
    width: 485,
  },
}));

const CustomContent = styled('div')(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.neutral.ne800,
}));

export const ConfirmModal = forwardRef<IConfirmModalElement, IConfirmModalProps>(
  (
    {
      title = 'Default title',
      content = 'Default content',
      ButtonsComponent,
      disableBackdropClick = false,
      ...rest
    },
    ref,
  ): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
      }),
      [],
    );

    const handleClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (disableBackdropClick && reason === 'backdropClick') {
        return;
      }
      setOpen(false);
    };

    return (
      <CustomDialog {...rest} open={open} onClose={handleClose}>
        <DialogTitle sx={{ p: 0, pb: 2 }}>
          <Typography variant='h5'>{title}</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 3 }}>
          <DialogContentText>
            <CustomContent dangerouslySetInnerHTML={{ __html: content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>{ButtonsComponent}</DialogActions>
      </CustomDialog>
    );
  },
);
