import { CONTACT_LAWYER_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { IContactLawyerForm } from './types';

export const submitContactLawyer = async (data: IContactLawyerForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CONTACT_LAWYER_API.submitContactLawyer.api,
  });
  return result.data;
};
