const UnregulatedTrustIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Trust_Unregulated_01'
          transform='translate(-603.000000, -638.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(196.000000, 365.000000)'>
                <g id='Type-1-Copy' transform='translate(105.000000, 0.000000)'>
                  <g id='broker' transform='translate(12.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g id='safety' mask='url(#mask-2)'>
                      <g transform='translate(11.000000, 8.000000)'>
                        <g id='Group'>
                          <path
                            d='M16,37 C15.1023158,37 14.2442105,36.72205 13.5191579,36.1962892 L7.95452632,32.1609911 C2.97347368,28.5484783 0,22.7332956 0,16.6041634 L0,4.18599389 C0,1.87783686 1.88884211,0 4.21052632,0 L27.7894737,0 C30.1111579,0 32,1.87783686 32,4.18599389 L32,16.6041634 C32,22.7341328 29.0265263,28.5493155 24.0454737,32.1609911 L18.4816842,36.1962892 C17.7557895,36.72205 16.8976842,37 16,37 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M15.0071579,34.1669193 L9.44252632,30.130784 C5.09642105,26.9795678 2.52631579,21.9521892 2.52631579,16.6041634 L2.52631579,4.18599389 C2.52631579,3.26088924 3.28,2.51159633 4.21052632,2.51159633 L27.7894737,2.51159633 C28.72,2.51159633 29.4736842,3.26088924 29.4736842,4.18599389 L29.4736842,16.6041634 C29.4736842,21.9530264 26.9035789,26.9795678 22.5566316,30.1316212 L16.992,34.1669193 C16.4008421,34.5955651 15.5991579,34.5955651 15.0071579,34.1669193 Z'
                            id='Path'
                            fill='#FFFFFF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M29.4736842,4.18599389 L29.4736842,5.02319267 C29.4736842,4.10227401 28.7157895,3.34879511 27.7894737,3.34879511 L4.21052632,3.34879511 C3.28421053,3.34879511 2.52631579,4.10227401 2.52631579,5.02319267 L2.52631579,4.18599389 C2.52631579,3.26507523 3.28421053,2.51159633 4.21052632,2.51159633 L27.7894737,2.51159633 C28.7157895,2.51159633 29.4736842,3.26507523 29.4736842,4.18599389 Z'
                            id='Path'
                            fill='#D2D2D2'
                            fillRule='nonzero'
                          ></path>
                        </g>
                        <g id='unlock' transform='translate(10.000000, 6.000000)'>
                          <path
                            d='M6,0 C3.46728946,0.00309009301 1.41485554,2.042157 1.41174524,4.55844604 L1.41174524,8.85611221 C1.41174524,9.049769 1.21682209,11.3857062 1.41174524,11.3857062 L3.52942928,10.3075777 C3.72435243,10.3075777 3.52942928,9.41602809 3.52942928,9.2223713 L3.52942928,4.55844604 C3.52939619,3.20281566 4.63553793,2.10389312 6,2.10389312 C7.36446207,2.10389312 8.47060381,3.20281566 8.47060381,4.55844604 L8.47060381,5.96105241 C8.47060381,6.1547092 8.62863351,6.31171222 8.82355666,6.31171222 L10.2353019,6.31171222 C10.4302251,6.31171222 10.5882548,6.1547092 10.5882548,5.96105241 L10.5882548,4.55844604 C10.5851445,2.042157 8.53271054,0.00309009301 6,0 Z'
                            id='Path'
                            fill='#59666C'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M1.7646981,8.4999894 L10.2353019,8.4999894 C11.2099177,8.4999894 12,9.25950167 12,10.1964074 L12,16.3035502 C12,17.2404877 11.2099177,18 10.2353019,18 L1.7646981,18 C0.790082335,18 0,17.2404877 0,16.303582 L0,10.1964392 C0,9.25950167 0.790082335,8.4999894 1.7646981,8.4999894 Z'
                            id='Path'
                            fill='#F6BF52'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M7.76471407,12.2321535 C7.76880105,11.2952478 6.98206063,10.5325229 6.00744487,10.5285633 C5.0328291,10.5246345 4.23940485,11.2809342 4.23528607,12.2178398 C4.23245631,12.8654515 4.61340186,13.4580988 5.217164,13.7453571 L4.94468188,15.5775 C4.91738404,15.7630363 5.05172252,15.9347042 5.24472655,15.9609459 C5.26107217,15.9631724 5.27758323,15.9643025 5.29409429,15.9643025 L6.70587262,15.9643025 C6.90079577,15.9661306 7.06034754,15.8157422 7.06226666,15.628361 C7.0624321,15.6113437 7.06127401,15.5943583 7.05879239,15.5775 L6.78631027,13.7453571 C7.38279298,13.458226 7.76092603,12.8733717 7.76471407,12.2321535 Z'
                            id='Path'
                            fill='#59666C'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UnregulatedTrustIcon;
