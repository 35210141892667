import { INVESTMENT_ENTITY_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import {
  IApplicationFinancialDetail,
  IFinancialTaxDetailsForm,
  ITeam,
  ITeamContact,
} from 'src/modules/applications/type';
import {
  IInvestmentEntityDetailsForm,
  IInvestmentEntityDocument,
  InvestmentEntityQueryBody,
} from './types';

export const getInvestmentEntityList = async (data: InvestmentEntityQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: INVESTMENT_ENTITY_API.getInvestmentEntityList.api,
  });

  return result.data;
};

export const archiveInvestmentEntity = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: INVESTMENT_ENTITY_API.archiveApplication.api(id),
  });

  return result.data;
};

export const upsertTeamContact = async (applicationId: string, data: ITeamContact) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: INVESTMENT_ENTITY_API.upsertTeamContact.api(applicationId),
  });

  return result.data;
};

export const createInvestmentEntity = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: INVESTMENT_ENTITY_API.investmentEntiryById.api(id),
  });

  return result.data;
};

export const getTeamContact = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTMENT_ENTITY_API.getTeamContact.api(id),
  });

  return result.data;
};

export const upsertTeam = async (id: string, data: ITeam) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: INVESTMENT_ENTITY_API.upsertTeam.api(id),
  });

  return result.data;
};

export const deleteTeamContact = async (contactId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: INVESTMENT_ENTITY_API.deleteTeamContact.api(contactId),
  });

  return result.data;
};

export const getInvestmentEntityById = async (
  id: string,
  params: { step: number; financialStep?: number },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: INVESTMENT_ENTITY_API.investmentEntiryById.api(id),
  });

  return result.data;
};

export const upsertFinancialDetail = async (id: string, data: IApplicationFinancialDetail) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: INVESTMENT_ENTITY_API.upsertFinancialDetail.api(id),
    data,
  });
  return result.data;
};

export const upsertFinancialTaxDetail = async (id: string, data: IFinancialTaxDetailsForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: INVESTMENT_ENTITY_API.upsertFinancialTaxDetail.api(id),
    data,
  });
  return result.data;
};

export const createInvestmentEntityDetail = async (data: IInvestmentEntityDetailsForm) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: INVESTMENT_ENTITY_API.createInvestmentEntityDetail.api,
  });

  return result.data;
};

export const upsertInvestmentEntityDetails = async (
  id: string,
  data: IInvestmentEntityDetailsForm,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: INVESTMENT_ENTITY_API.upsertInvestmentEntityDetails.api(id),
  });

  return result.data;
};

export const upsertInvestmentEntityDocuments = async (
  id: string,
  documents: IInvestmentEntityDocument[],
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: {
      documents,
    },
    url: INVESTMENT_ENTITY_API.upsertInvestmentEntityDocument.api(id),
  });

  return result.data;
};

export const deleteInvestmentEntityDocument = async ({
  id,
  documentId,
}: {
  id: string;
  documentId: string;
}) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    data: {
      documentId,
    },
    url: INVESTMENT_ENTITY_API.deleteInvestmentEntityDocuments.api(id),
  });

  return result.data;
};

export const getTaxDetails = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTMENT_ENTITY_API.getTaxDetails.api(id),
  });

  return result.data;
};

export const deleteInvestmentEntity = async (entityId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: INVESTMENT_ENTITY_API.investmentEntiryById.api(entityId),
  });

  return result.data;
};

export const downloadInvestmentEntityDocument = async (
  appId: string,
  document: { documentId: string; fileName: string; type: number },
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: document,
    url: INVESTMENT_ENTITY_API.downloadInvestmentEntityDocument.api(appId),
    responseType: 'arraybuffer',
  });
  return result.data;
};
