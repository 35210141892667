/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import { FOREIGN_TAX_ERROR_MESSAGE, reasonForTINOptions } from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';

interface ITaxInfo extends IUpsertInvestmentApplicationForm {
  keyName: string;
  disabled?: boolean;
  allowForeignInvestors?: boolean;
  hideTfn?: boolean;
  logicCheck?: boolean;
  hideDeclaration?: boolean;
}

const TaxInfo = ({
  keyName,
  disabled,
  allowForeignInvestors,
  hideTfn,
  logicCheck,
  hideDeclaration,
}: ITaxInfo) => {
  const { watch, setError, clearErrors, setValue } = useFormContext();
  const { countryOptions } = useCountry();

  const isTaxResidentAustralia = watch(`${keyName}.taxInfo.isTaxResidentAustralia`);
  const isTaxResidentOtherCountry = watch(`${keyName}.taxInfo.isTaxResidentOtherCountry`);
  const hasTIN = watch(`${keyName}.taxInfo.hasTIN`);

  useEffect(() => {
    if (logicCheck && isTaxResidentAustralia === false && !allowForeignInvestors) {
      setError(`${keyName}.taxInfo.isTaxResidentAustralia`, {
        type: 'foreignError',
        message: FOREIGN_TAX_ERROR_MESSAGE,
      });
    } else if (typeof isTaxResidentAustralia === 'boolean') {
      clearErrors(`${keyName}.taxInfo.isTaxResidentAustralia`);
    }
  }, [logicCheck, isTaxResidentAustralia, allowForeignInvestors]);

  useEffect(() => {
    if (
      logicCheck &&
      isTaxResidentAustralia &&
      isTaxResidentOtherCountry &&
      !allowForeignInvestors
    ) {
      setError(`${keyName}.taxInfo.isTaxResidentOtherCountry`, {
        type: 'foreignError',
        message: FOREIGN_TAX_ERROR_MESSAGE,
      });
    } else if (typeof isTaxResidentOtherCountry === 'boolean') {
      clearErrors(`${keyName}.taxInfo.isTaxResidentOtherCountry`);
    }
  }, [logicCheck, isTaxResidentAustralia, isTaxResidentOtherCountry, allowForeignInvestors]);

  useEffect(() => {
    if (hideDeclaration) {
      setValue(`${keyName}.taxInfo.isDeclarationChecked`, true);
    }
  }, [hideDeclaration]);

  return (
    <Box className='flex flex-col'>
      <FormRadioGroup
        label={
          <Typography variant='body3' fontWeight={600}>
            Australian tax resident
          </Typography>
        }
        name={`${keyName}.taxInfo.isTaxResidentAustralia`}
        optionGap={10}
        row
        options={TrueFalseRadioOptions}
        disabled={disabled}
        isBooleanValue
      />
      {isTaxResidentAustralia && !hideTfn && (
        <Box width='100%' mt={2}>
          <FormInput
            name={`${keyName}.taxInfo.tfnExemptionCode`}
            label={
              <LabelWithTooltip
                label='Tax File Number or Exemption Code'
                tooltip='Providing TFN is not a compulsory requirement. However, in the absence of a TFN, taxes will be withheld from any income at the highest marginal tax rate'
                placement='right'
              />
            }
            placeholder='Enter tax file number or exemption code'
            disabled={disabled}
            type='number'
          />
        </Box>
      )}
      <Box width='100%' mt={4}>
        <FormRadioGroup
          label={
            <Typography variant='body3' fontWeight={600}>
              Tax resident of another country (other than Australia)
            </Typography>
          }
          name={`${keyName}.taxInfo.isTaxResidentOtherCountry`}
          optionGap={10}
          row
          options={TrueFalseRadioOptions}
          disabled={disabled}
          isBooleanValue
        />
      </Box>
      {isTaxResidentOtherCountry && (
        <>
          <Box width='100%' mt={2}>
            <FormSelect
              name={`${keyName}.taxInfo.country`}
              options={countryOptions}
              label='Select Country'
              placeholder='Select country'
              disabled={disabled}
            />
          </Box>
          <Box width='100%' mt={4}>
            <FormRadioGroup
              label={
                <Typography variant='body3' fontWeight={600}>
                  Tax Identification Number (TIN)
                </Typography>
              }
              name={`${keyName}.taxInfo.hasTIN`}
              optionGap={10}
              row
              options={TrueFalseRadioOptions}
              disabled={disabled}
              isBooleanValue
            />
          </Box>
          {hasTIN && (
            <Box width='50%' mt={2}>
              <FormInput
                name={`${keyName}.taxInfo.tin`}
                placeholder='Enter TIN'
                disabled={disabled}
              />
            </Box>
          )}
          {hasTIN === false && (
            <Box width='100%' mt={2}>
              <FormSelect
                name={`${keyName}.taxInfo.reasonType`}
                options={reasonForTINOptions}
                label='Please select a reason'
                placeholder='Select a reason'
                disabled={disabled}
              />
            </Box>
          )}
        </>
      )}
      {!hideDeclaration && (
        <>
          <Typography variant='body3' fontWeight={600} mt={3} mb={1}>
            Declaration
          </Typography>
          <FormCheckbox
            name={`${keyName}.taxInfo.isDeclarationChecked`}
            label='I certify that the tax residency information I have provided is true and correct, and I will provide an updated Tax Status Declaration within 30 days if any of the information given is incorrect'
            sx={{ alignSelf: 'flex-start' }}
            disabled={disabled}
          />
        </>
      )}
    </Box>
  );
};

export default TaxInfo;
