const TrustIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-823.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1-Copy-3' transform='translate(329.000000, 43.000000)'>
                  <g id='company-(1)' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g id='Group-Copy' mask='url(#mask-2)' fillRule='nonzero'>
                      <g transform='translate(7.000000, 7.000000)'>
                        <path
                          d='M17.5338828,0.325221028 L2.03836664,7.52212611 C0.721020451,8.13365283 -0.0878517882,9.5283507 0.00761821664,11.019642 C0.276892589,15.2086 1.29838667,23.795686 5.16614584,30.2014284 C10.0449079,38.2815726 16.1028819,40.3962042 18.223505,40.9080104 C18.7351263,41.0306632 19.2649324,41.0306632 19.776204,40.9080104 C21.8964774,40.3962042 27.954801,38.2815726 32.8335631,30.2014284 C36.701672,23.7960335 37.7228163,15.2082526 37.9920907,11.019642 C38.0875607,9.5283507 37.2779891,8.13365283 35.9613423,7.52212611 L20.4658261,0.325221028 C19.5335073,-0.108407009 18.4658519,-0.108407009 17.5338828,0.325221028 Z'
                          id='Path'
                          fill='#F6B940'
                        ></path>
                        <path
                          d='M11.0041541,34.9810656 C10.8272024,34.9810656 10.6502507,34.9150485 10.5142147,34.7823195 C8.97935073,33.2854688 7.58367018,31.5033549 6.36564078,29.4863591 C3.64212283,24.9763495 2.40905603,19.3096511 1.85407106,15.3496681 C1.8009156,14.9695487 2.06774202,14.6186157 2.45032146,14.565802 C2.8336003,14.5095138 3.18610494,14.7777516 3.2396101,15.1582186 C3.77676002,18.9896419 4.96296609,24.4614162 7.56513571,28.7709423 C8.7223161,30.6875226 10.0442085,32.3761703 11.4940936,33.7899784 C11.7700124,34.0589111 11.7738592,34.4987934 11.503186,34.7729381 C11.3664506,34.911574 11.1853023,34.9810656 11.0041541,34.9810656 L11.0041541,34.9810656 Z M2.22615928,13.1867398 C1.86875875,13.1867398 1.56381427,12.9160697 1.5305921,12.555408 L1.5103091,12.3312973 C1.47708694,11.9487457 1.76244783,11.612406 2.14712551,11.5793974 C2.52935523,11.541177 2.87066924,11.8295674 2.90389141,12.2117716 L2.923475,12.4282382 C2.9587954,12.8104424 2.67553275,13.1485194 2.29085507,13.1836127 C2.26917324,13.1856974 2.24749141,13.1867398 2.22615928,13.1867398 L2.22615928,13.1867398 Z'
                          id='Shape'
                          fill='#FFC91D'
                        ></path>
                        <path
                          d='M35.9613423,7.52212611 L29.9337928,4.72265407 C30.6608336,5.44050305 31.0668434,6.4665476 30.9979512,7.54505836 C30.7283271,11.7336689 29.7075324,20.3214498 25.8394235,26.7268448 C20.9606615,34.806989 14.9026875,36.9216206 12.7820644,37.4334268 C12.3494769,37.5373168 11.9042999,37.5477406 11.4671662,37.4758167 C14.3847715,39.7551435 16.9806464,40.6081538 18.223505,40.9080104 C18.7351263,41.0306632 19.2649324,41.0306632 19.776204,40.9080104 C21.8964774,40.3962042 27.954801,38.2812252 32.8335631,30.2014284 C36.701672,23.795686 37.7228163,15.2082526 37.9920907,11.019642 C38.0875607,9.5283507 37.2779891,8.13365283 35.9613423,7.52212611 L35.9613423,7.52212611 Z'
                          id='Path'
                          fill='#F4A93C'
                        ></path>
                        <path
                          d='M35.9613423,7.52212611 L33.1717297,6.22645387 C34.0613843,6.94360793 34.5723062,8.08153407 34.4953706,9.28235017 C34.2257466,13.4709607 33.2049519,22.0587416 29.336843,28.4641366 C24.458081,36.5442808 18.400107,38.6589124 16.2794839,39.1707186 C15.7682123,39.2933714 15.2384062,39.2933714 14.7267849,39.1707186 C14.3421072,39.0779472 13.8262894,38.9313198 13.2129034,38.7068617 C15.3929767,40.0824493 17.2359325,40.669654 18.2238547,40.9080104 C18.735476,41.0306632 19.2652821,41.0306632 19.7765537,40.9080104 C21.8968271,40.3962042 27.9551507,38.2812252 32.8339128,30.2014284 C36.7020217,23.795686 37.723166,15.2082526 37.9924404,11.019642 C38.0875607,9.5283507 37.2779891,8.13365283 35.9613423,7.52212611 Z'
                          id='Path'
                          fill='#F4A93C'
                        ></path>
                        <path
                          d='M17.9682189,6.29976758 L7.06155766,11.3653631 C6.13413476,11.795864 5.5651615,12.7774339 5.63195554,13.8271056 C5.82149672,16.7756372 6.54049427,22.8196755 9.26296309,27.3282952 C12.6970856,33.0154937 16.9607131,34.5040053 18.4532625,34.8643196 C18.8134606,34.9508368 19.1862483,34.9508368 19.5460968,34.8643196 C21.0386461,34.5040053 25.3026233,33.0154937 28.7363961,27.3282952 C31.458865,22.8196755 32.1775128,16.7752898 32.3674037,13.8271056 C32.4345474,12.7774339 31.8648748,11.795864 30.9378016,11.3653631 L20.03149,6.29976758 C19.3754398,5.9950466 18.6239195,5.9950466 17.9682189,6.29976758 L17.9682189,6.29976758 Z'
                          id='Path'
                          fill='#F1F2F2'
                        ></path>
                        <path
                          d='M30.9381513,11.3653631 L24.7266559,8.48041627 C25.1714832,8.97762919 25.4183763,9.64752891 25.3732641,10.3525219 C25.1837229,13.3007061 24.4650751,19.3450918 21.7422566,23.8537116 C18.3081341,29.54091 14.0445066,31.0294217 12.5519572,31.3893885 C12.5306251,31.3946004 12.5089433,31.3963377 12.4876111,31.4008547 C15.0103973,33.8063089 17.4093871,34.6120649 18.4529128,34.8639722 C18.8131109,34.9504893 19.1858986,34.9504893 19.5457471,34.8639722 C21.0382964,34.5036578 25.3022736,33.0151462 28.7360464,27.3282952 C31.4585152,22.8196755 32.1771631,16.7752898 32.367054,13.8271056 C32.4345474,12.7777813 31.8648748,11.795864 30.9381513,11.3653631 L30.9381513,11.3653631 Z'
                          id='Path'
                          fill='#E6E7E8'
                        ></path>
                        <path
                          d='M18.2794581,27.4516429 C17.8454717,27.4516429 17.4251239,27.2911171 17.101645,26.9982097 L12.0847487,22.4548442 C11.3709967,21.8085716 11.3195898,20.7099083 11.9703945,20.0007458 C12.6204998,19.2915832 13.7266229,19.2405069 14.4403749,19.8871269 L18.0430562,23.1497609 L24.0429788,15.29199 C24.62629,14.5279291 25.7233208,14.3781745 26.4930259,14.9580825 C27.2620315,15.5379905 27.4127552,16.6279674 26.8290943,17.3923758 L19.672341,26.7650652 C19.3754398,27.1535236 18.9278148,27.4005665 18.4389245,27.4450412 C18.385769,27.4492107 18.3326136,27.4516429 18.2794581,27.4516429 L18.2794581,27.4516429 Z'
                          id='Path'
                          fill='#345E80'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default TrustIcon;
