import * as React from 'react';

interface IProfileIconProps {}

const ProfileIcon: React.FunctionComponent<IProfileIconProps> = () => {
  return (
    <svg
      width='10.6673336px'
      height='13.3306667px'
      viewBox='0 0 10.6673336 13.3306667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-9.7%'
          y='-10.1%'
          width='119.4%'
          height='128.2%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx={0} dy={5} in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation={5} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Profile_00'
          transform='translate(-1260.666667, -79.333333)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g
            id='Group-26-Copy-2'
            filter='url(#filter-1)'
            transform='translate(1242.000000, 64.000000)'
          >
            <g id='Group-20' transform='translate(4.000000, 4.000000)'>
              <g
                id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                transform='translate(12.000000, 10.000000)'
              >
                <g id='Group' transform='translate(2.666667, 1.333333)'>
                  <path
                    d='M9.16733363,7.99733333 C9.56509705,7.99715647 9.94662964,8.15504341 10.2279534,8.43624213 C10.5092771,8.71744084 10.6673336,9.09890321 10.6673336,9.49666667 L10.6673336,10.1086667 C10.6673336,10.4909669 10.5476903,10.8636828 10.3253336,11.1746667 C9.29466696,12.6166667 7.61133363,13.3306667 5.33133363,13.3306667 C3.0500003,13.3306667 1.3680003,12.616 0.340000297,11.1726667 C0.118883112,10.8621856 1.48179675e-07,10.4905043 1.48179675e-07,10.1093333 L1.48179675e-07,9.496 C-0.000176565756,9.09823658 0.157710376,8.71670399 0.438909091,8.43538024 C0.720107806,8.15405649 1.10157017,7.996 1.49933363,7.996 L9.16666696,7.996 L9.16733363,7.99733333 Z M5.33133363,0 C7.1722828,0 8.66466696,1.49238417 8.66466696,3.33333333 C8.66466696,5.1742825 7.1722828,6.66666667 5.33133363,6.66666667 C3.49038446,6.66666667 1.9980003,5.1742825 1.9980003,3.33333333 C1.9980003,1.49238417 3.49038446,0 5.33133363,0 L5.33133363,0 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ProfileIcon;
