import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomEditor from 'src/components/atoms/CustomEditor';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { contactLawyerDefaultValue } from 'src/constants/contact-lawyer';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useSubmitContactLawyer } from 'src/modules/contact-lawyer/hooks';
import { IContactLawyerForm } from 'src/modules/contact-lawyer/types';
import { handleErrorFromServer } from 'src/utils/common';
import { submitContactLawyerSchema } from 'src/validations/contact-lawyer';

interface IContactLawyerFormProps {
  onClose: () => void;
}

const ContactLawyerForm: FC<IContactLawyerFormProps> = ({ onClose }) => {
  const alertRef = useRef<IBasicModalElement>(null);
  const { data: userProfile } = useGetUserInfo();
  const form = useForm<IContactLawyerForm>({
    defaultValues: contactLawyerDefaultValue,
    resolver: yupResolver(submitContactLawyerSchema),
  });
  const { mutate: submitContactLawyer, isLoading } = useSubmitContactLawyer();

  useEffect(() => {
    if (userProfile) {
      handleFillData();
    }
  }, [userProfile]);

  const handleFillData = () => {
    const { firstName, lastName, countryFlagCode, contactNumber, email } = userProfile || {};
    const name = `${firstName} ${lastName}`;

    form.reset({
      ...contactLawyerDefaultValue,
      name,
      email,
      contactNumber,
      countryFlagCode,
    });
  };

  const handleSuccess = () => {
    alertRef.current?.open();
  };

  const onSubmitContactLawyer = (data: IContactLawyerForm) => {
    submitContactLawyer(data, {
      onError: handleErrorFromServer,
      onSuccess: handleSuccess,
    });
  };

  return (
    <>
      <Box className='contact-lawyer w-full p-10'>
        <Box className='flex justify-between items-center'>
          <Typography variant='h5'>Contact A Lawyer</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon width={20} height={20} color='#000000' />
          </IconButton>
        </Box>
        <Divider className='h-[2px] mt-4 mb-6' sx={{ bgcolor: 'neutral.ne200' }} />
        <Box className='body'>
          <FormProvider {...form}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <FormInput
                  name='name'
                  label='Contact Person Name'
                  placeholder='Enter contact person name'
                  isShowCharactersLeft
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput name='email' label='Contact Email' placeholder='Enter contact email' />
              </Grid>
              <Grid item xs={6}>
                <FormPhoneCountryInput
                  name='contactNumber'
                  label='Contact Number'
                  size='small'
                  isIncludeCode
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  name='subject'
                  label='Subject'
                  placeholder='Enter subject'
                  isShowCharactersLeft
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className='inline-block' mb={0.5} variant='body3'>
                  Message
                </Typography>
                <CustomEditor
                  name='message'
                  toolbar={false}
                  statusbar={false}
                  height={154}
                  isShowCharactersLeft
                  placeholder='Enter message'
                  customContentStyle='p { margin: 8px 0; }'
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
        <Box className='flex justify-end items-center pt-10'>
          <CustomButton
            sx={{ color: 'neutral.ne800' }}
            variant='text'
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CustomButton>
          <CustomButton onClick={form.handleSubmit(onSubmitContactLawyer)} isLoading={isLoading}>
            Submit
          </CustomButton>
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title='You did it!'
          description='Your request has been successfully submitted.'
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              onClose();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default ContactLawyerForm;
