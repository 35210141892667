import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable from 'src/components/atoms/CustomTable';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { DATE_PICKER_FORMAT } from 'src/constants/date';

import {
  useBillingCloseCase,
  useGetKYCBackOfficeMasterData,
  useSubmitBilling,
} from 'src/modules/kyc-back-office/hooks';
import {
  IBilling,
  IFilterOption,
  IKYC_BILLING,
  ISubmitBilling,
} from 'src/modules/kyc-back-office/type';
import { handleErrorFromServer } from 'src/utils/common';
import * as Yup from 'yup';

interface IBillingProps {
  id: string;
  allowEdit?: boolean;
  billing?: IKYC_BILLING;
}

interface IRowDetail extends IBilling {
  index: number;
}

const Billing: FC<IBillingProps> = ({ id = '', allowEdit, billing = {} }) => {
  const modalEditRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [description, setDescription] = useState<string>('');
  const [rowDetail, setRowDetail] = useState<IRowDetail | null>(null);
  const { mutate: submitBilling, isLoading: submitBillingLoading } = useSubmitBilling();
  const { mutate: billingCloseCase, isLoading: billingCloseCaseLoading } = useBillingCloseCase();
  const { data: { billingTypes = [] } = {} } = useGetKYCBackOfficeMasterData();
  const {
    billings = [],
    billingSubmittedByName,
    billingSubmittedDate,
    billingType,
    isSubmittedBilling,
  } = billing || {};

  const form = useForm<ISubmitBilling>({
    resolver: yupResolver(
      Yup.object({
        billingType: Yup.number().nullable().required(REQUIRE_MESSAGE),
      }),
    ),
  });

  const editForm = useForm<{
    quality: number;
  }>({
    resolver: yupResolver(
      Yup.object({
        quality: Yup.string().required(REQUIRE_MESSAGE),
      }),
    ),
  });

  const { control, reset } = form;

  const { fields: rowsData, update: updateRow } = useFieldArray({
    control,
    name: 'billings',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isEmpty(billings)) {
      const newBillings = billings?.map((item: any) => ({
        ...item,
        quantity: item?.quantity || 0,
        additionalQuantity: 0,
      }));
      reset((formState) => ({
        ...formState,
        billingType: billingType ?? null,
        billings: newBillings,
      }));
    }
  }, [billing]);

  const billingTypeOptions = useMemo(() => {
    return billingTypes?.map((item: IFilterOption) => ({
      label: item.name,
      value: item.id,
    }));
  }, [billingTypes]);

  const handleSubmitAndCloseCase = () => {
    const data = form.getValues();
    if (isSubmittedBilling) {
      data.billings = data.billings.map((item) => ({
        ...item,
        quantity: item.additionalQuantity as number,
        additionalQuantity: 0,
      }));
    }
    billingCloseCase(
      {
        kycId: id,
        data,
      },
      {
        onSuccess: () => {
          setDescription('Billing has been submitted successfully and case is closed.');
          modalAlertRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSubmitBilling = () => {
    const data = form.getValues();
    if (isSubmittedBilling) {
      data.billings = data.billings.map((item) => ({
        ...item,
        quantity: item.additionalQuantity as number,
        additionalQuantity: 0,
      }));
    }
    submitBilling(
      {
        kycId: id,
        data,
      },
      {
        onSuccess: () => {
          setDescription('Billing has been submitted successfully.');
          modalAlertRef.current?.open();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSaveEdit = () => {
    const qualityValue = editForm.getValues().quality;
    const clonedRow = { ...rowDetail };
    if (isSubmittedBilling) {
      clonedRow.additionalQuantity = qualityValue;
    } else {
      clonedRow.quantity = qualityValue;
    }
    updateRow(Number(clonedRow.index), clonedRow as IRowDetail);
    modalEditRef.current?.close();
  };

  const handleEditAction = (row: IRowDetail) => {
    editForm.setValue(
      'quality',
      isSubmittedBilling ? Number(row.additionalQuantity) : row.quantity,
    );
    setRowDetail(row);
    modalEditRef.current?.open();
  };

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Description',
        key: 'name',
        sx: { width: '60%' },
        renderNode: (row: IBilling) => (
          <StringNodeTable className='line-clamp-1' value={row.name || ''} />
        ),
      },
      {
        title: 'Quantity',
        key: 'quantity',
        sx: { width: '20%' },
        renderNode: (row: IBilling) => row.quantity,
        isDisabled: isSubmittedBilling,
      },
      {
        title: 'Additional Quantity',
        key: 'additionalQuantity',
        sx: { width: '20%' },
        renderNode: (row: IBilling) => Number(row.additionalQuantity),
        isHidden: !isSubmittedBilling,
      },
      {
        title: 'Action',
        key: 'action',
        sx: { width: '10%' },
        renderNode: (row: IBilling, index?: number) => {
          return (
            <IconButton
              disabled={!allowEdit}
              onClick={() => handleEditAction({ ...row, index: Number(index) })}
            >
              <EditOutlinedIcon />
            </IconButton>
          );
        },
      },
    ];
    return _columns;
  }, [isSubmittedBilling]);

  return (
    <FormProvider {...form}>
      <Box>
        <FormSelect
          label='AML/KYC Processing Fee'
          placeholder='Select AML/KYC Processing Fee'
          name='billingType'
          options={billingTypeOptions}
          className='w-[300px]'
          disabled={!allowEdit}
        />

        <Box mt='50px'>
          {billingSubmittedDate && billingSubmittedByName && (
            <Box className='text-right'>
              <Typography variant='body3' color='neutral.ne800'>
                {`Last submitted by ${billingSubmittedByName} on ${dayjs(
                  billingSubmittedDate,
                ).format(DATE_PICKER_FORMAT)}`}
              </Typography>
            </Box>
          )}
          <CustomTable
            rows={rowsData}
            columns={columns}
            hasPagination={false}
            stickyHeader
            displayEmpty
          />
        </Box>

        <Box className='flex justify-end items-center mt-[30px]'>
          <CustomButton
            variant='outlined'
            className='mr-[10px]'
            onClick={form.handleSubmit(handleSubmitAndCloseCase)}
            isLoading={billingCloseCaseLoading}
            disabled={!allowEdit || submitBillingLoading}
          >
            Submit and Close Case
          </CustomButton>
          <CustomButton
            variant='contained'
            onClick={form.handleSubmit(handleSubmitBilling)}
            isLoading={submitBillingLoading}
            disabled={!allowEdit || billingCloseCaseLoading}
          >
            Submit
          </CustomButton>
        </Box>
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title='You did it!'
          description={description}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
      <BasicModal ref={modalEditRef}>
        <FormProvider {...editForm}>
          <Box className='w-[600px] p-10'>
            <Box className='flex items-center justify-between'>
              <Typography variant='h5'>{rowDetail?.name}</Typography>
            </Box>
            <Box className='mt-6'>
              <FormInput name='quality' label='Quality' type='number' />
            </Box>
            <Box className='mt-6 flex flex-row-reverse'>
              <Box className='flex gap-2'>
                <CustomButton
                  sx={{ color: 'neutral.ne800' }}
                  variant='text'
                  onClick={() => modalEditRef?.current?.close()}
                >
                  Cancel
                </CustomButton>
                <CustomButton onClick={editForm.handleSubmit(handleSaveEdit)}>Save</CustomButton>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </BasicModal>
    </FormProvider>
  );
};

export default Billing;
