import { Box, Typography, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useRef, useState } from 'react';
import { AddIcon } from 'src/assets/icons/AddIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { PDFIcon } from 'src/assets/icons/PDFIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomMenu, ICustomMenuElement } from 'src/components/atoms/CustomMenu';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus from 'src/components/molecules/ActionMenus';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { ActionType } from 'src/constants/common';
import {
  INVESTOR_FORM_TYPE,
  investorFormActionMenus,
  investorFormActionTypes,
  investorFormStatusName,
  investorFormStatusOptions,
  investorFormTypeOptions,
} from 'src/constants/investor-form';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useRole } from 'src/hooks/useRole';
import { useDeleteInvestorForm, useGetInvestorFormList } from 'src/modules/investor-form/hooks';
import { IInvestorFormItem } from 'src/modules/investor-form/type';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import UpsertInvestorForm from './components/UpsertInvestorForm';

interface IInvestorFormProps {}

const FILTER_BY = {
  formTypes: 'formTypes',
  statuses: 'statuses',
};

const InvestorForm: FC<IInvestorFormProps> = (props) => {
  const theme = useTheme();
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const [formType, setFormType] = useState<INVESTOR_FORM_TYPE>(
    INVESTOR_FORM_TYPE.REDEMPTION_REQUEST,
  );
  const [selectedRow, setSelectedRow] = useState<IInvestorFormItem>();
  const [actionType, setActionType] = useState<ActionType>('create');

  const customMenuRef = useRef<ICustomMenuElement>(null);
  const upsertModalRef = useRef<IBasicModalElement>(null);
  const deleteModalConfirm = useRef<IBasicModalElement>(null);

  const isOneOfFiltersEmpty = Boolean(emptyFilters?.length);
  const { filterActions, canCreate } = useRole(ROUTES_PATH.INVESTOR_FORMS);

  const {
    data: { items: investorFormList = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    setParams,
  } = useGetInvestorFormList();
  const { mutate: deleteInvestorForm, isLoading: deletingInvestorForm } = useDeleteInvestorForm();

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({
      search: event.target.value,
    });
  }, 300);

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleFormTypeChange = (data: number[] | string) => {
    if (data !== MULTIPLE_SELECT_EMPTY) {
      setParams({ formTypes: data as number[] });
    }
    handleSetEmptyFilters(data, FILTER_BY.formTypes);
  };

  const handleStatusChange = (data: number[] | string) => {
    if (data !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: data as number[] });
    }
    handleSetEmptyFilters(data, FILTER_BY.statuses);
  };

  const handleSetEmptyFilters = (values: string | number[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const handleMenuOpen = (event: any) => {
    customMenuRef.current?.open(event.currentTarget);
  };

  const getCustomStatusBadgeColor = (isDraftStatus: boolean) => {
    const colorOptions: any = {};

    if (isDraftStatus) {
      colorOptions.customColor = theme.palette.neutral.ne800;
      colorOptions.customBgColor = theme.palette.neutral.ne100;
    }

    return colorOptions;
  };

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    const isDraft = label === investorFormStatusName.Draft;
    const customColor = getCustomStatusBadgeColor(isDraft);

    return <StatusBadge status={label as string} {...customColor} />;
  };

  const handleDeleteInvestorForm = () => {
    deleteInvestorForm(selectedRow?.id || '', {
      onError: handleErrorFromServer,
      onSuccess: () => {
        deleteModalConfirm.current?.close();
        setSelectedRow(undefined);
      },
    });
  };

  const columns: ColumnProps<IInvestorFormItem, 'action'>[] = [
    {
      title: 'Form ID',
      key: 'formId',
      sorter: true,
      sortBy: 'formId',
      renderNode: (row) => <StringNodeTable value={row.formId || ''} />,
    },
    {
      title: 'Type',
      key: 'formType',
      sorter: true,
      sortBy: 'formType',
      renderNode: (row) => <StringNodeTable value={row.formTypeName || ''} />,
    },
    {
      title: 'Investment Entity',
      key: 'investmentEntity',
      sorter: true,
      sortBy: 'investmentEntity',
      renderNode: (row) => <StringNodeTable value={row.investmentEntity || ''} />,
    },
    {
      title: 'Fund',
      key: 'fund',
      sorter: true,
      sortBy: 'fund',
      renderNode: (row) => <StringNodeTable value={row?.fund || ''} />,
    },
    {
      title: 'Unit Class',
      key: 'unitClass',
      sorter: true,
      sortBy: 'unitClass',
      renderNode: (row) => <StringNodeTable value={row?.unitClass || ''} />,
    },
    {
      title: 'Date Created',
      key: 'createdOn',
      sorter: true,
      sortBy: 'createdOn',
      renderNode: (row) => (
        <StringNodeTable value={row?.createdOn ? utcToLocalTimezone(row?.createdOn) : ''} />
      ),
    },
    {
      title: 'Created By',
      key: 'createdBy',
      sorter: true,
      sortBy: 'createdBy',
      renderNode: (row) => <StringNodeTable value={row?.createdBy || ''} />,
    },
    {
      title: 'File',
      key: 'fileName',
      sx: {
        width: '16%',
        minWidth: 240,
      },
      renderNode: (row) => (
        <Box className='flex items-center gap-1'>
          <PDFIcon className='mr-2' width={18} height={18} />
          <StringNodeTable className='w-4/5 break-all' value={row?.fileName || ''} />
        </Box>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      sortBy: 'status',
      isSticky: true,
      renderNode: (row) => {
        const isDraft = investorFormStatusName.Draft === row.statusName;
        const customColor = getCustomStatusBadgeColor(isDraft);

        return <StatusBadge status={row?.statusName || ''} {...customColor} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      sx: { minWidth: 90, width: 90 },
      isSticky: true,
      renderNode: (row: IInvestorFormItem) => {
        const actionIcons = {
          [investorFormActionTypes.view]: <EyeViewIcon />,
          [investorFormActionTypes.edit]: <EditOutlinedIcon />,
          [investorFormActionTypes.delete]: <DeleteIcon />,
        };

        const actions = {
          [investorFormActionTypes.view]: () => {
            setSelectedRow(row);
            setFormType(row.formType);
            setActionType('view');
            upsertModalRef.current?.open();
          },
          [investorFormActionTypes.edit]: () => {
            setSelectedRow(row);
            setFormType(row.formType);
            setActionType('edit');
            upsertModalRef.current?.open();
          },
          [investorFormActionTypes.delete]: () => {
            setSelectedRow(row);
            deleteModalConfirm.current?.open();
          },
        };

        const getMenus = {
          [investorFormStatusName.Draft]: [
            investorFormActionTypes.view,
            investorFormActionTypes.edit,
            investorFormActionTypes.delete,
          ],
          [investorFormStatusName.Submitted]: [investorFormActionTypes.view],
        };

        const filteredMenus = investorFormActionMenus.filter((menu) =>
          getMenus?.[row.statusName || '']?.includes(menu.key),
        );

        const permissionMenu = filterActions(filteredMenus, true);

        const menus = permissionMenu.map(({ key }) => ({
          icon: actionIcons?.[key],
          label: key,
          onAction: actions?.[key],
        }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  const handleCloseUpsertModal = () => {
    upsertModalRef.current?.close();
    setSelectedRow(undefined);
    setActionType('create');
  };

  const CREATE_NEW_MENU = [
    {
      title: <Typography variant='body2'>Redemption</Typography>,
      onClick: () => {
        setFormType(INVESTOR_FORM_TYPE.REDEMPTION_REQUEST);
        upsertModalRef.current?.open();
      },
    },
    {
      title: <Typography variant='body2'>Transfer</Typography>,
      onClick: () => {
        setFormType(INVESTOR_FORM_TYPE.TRANSFER);
        upsertModalRef.current?.open();
      },
    },
    {
      title: <Typography variant='body2'>Change of Details</Typography>,
      onClick: () => {
        setFormType(INVESTOR_FORM_TYPE.CHANGE_DETAILS);
        upsertModalRef.current?.open();
      },
    },
  ];

  return (
    <>
      <Box className='investor-form-section' component='section'>
        <Box className='header flex items-center justify-between'>
          <Box className='title-desc'>
            <Typography variant='h6' fontWeight={700}>
              Investor Forms
            </Typography>
            <Typography className='mt-1' variant='body2' color='neutral.ne500'>
              View Investor Forms list
            </Typography>
          </Box>
          {canCreate && (
            <CustomButton
              startIcon={<AddIcon width={24} height={24} />}
              className='w-[196px] h-12 flex items-center  px-5'
              onClick={handleMenuOpen}
            >
              <Typography variant='body2'>Create New Form</Typography>
            </CustomButton>
          )}
        </Box>
        <Box className='mt-4 mb-6 h-0.5' bgcolor='neutral.ne200' />
        <Box className='body investor-form-table'>
          <Box className='search-filter flex items-center gap-5 flex-wrap'>
            <SearchField
              className='w-[358px]'
              placeholder='Search by Form ID'
              onChange={handleSearch}
            />
            <CustomMultiSelect
              onChange={handleFormTypeChange}
              options={investorFormTypeOptions}
              label='Form Type'
              showClearAllIcon
            />
            <CustomMultiSelect
              onChange={handleStatusChange}
              options={investorFormStatusOptions}
              label='Status'
              customRenderLabel={customRenderStatusLabel}
              showClearAllIcon
            />
          </Box>
          <Box className='table-list mt-6'>
            {(investorFormList.length && !isOneOfFiltersEmpty) || isLoading ? (
              <CustomTable
                columns={columns}
                rows={investorFormList}
                isFetchingData={isLoading}
                totalResults={totalItem}
                currentPage={page}
                numberItemOnPage={pageSize}
                onChangePagination={handleChangePage}
                onSort={handleSort}
              />
            ) : (
              <EmptyData />
            )}
          </Box>
        </Box>
      </Box>
      <CustomMenu ref={customMenuRef} menuItems={CREATE_NEW_MENU} />
      <BasicModal
        ref={upsertModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 30, width: 1120 } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={handleCloseUpsertModal}
      >
        <UpsertInvestorForm
          onClose={handleCloseUpsertModal}
          formType={formType}
          isViewMode={actionType === 'view'}
          isEditMode={actionType === 'edit'}
          isCreateMode={actionType === 'create'}
          isDraft={selectedRow?.statusName === 'Draft'}
          id={selectedRow?.id || ''}
        />
      </BasicModal>
      <ConfirmModal
        ref={deleteModalConfirm}
        title='Delete Investor Form?'
        content={`Are you sure you want to delete the investor form? <br />
        This action cannot be undone.`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => deleteModalConfirm?.current?.close()}
              disabled={deletingInvestorForm}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color='error'
              onClick={handleDeleteInvestorForm}
              isLoading={deletingInvestorForm}
            >
              Delete
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default InvestorForm;
