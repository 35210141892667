import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { AUDIT_TRAIL_API } from 'src/constants/apiEndpoints';
import { detectPortalType } from 'src/helpers/common';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { removeEmptyProps } from 'src/utils/common';
import { getAuditTrailList, getAuditTrailModuleDetails } from './services';
import { AuditTrailQueryBody } from './type';

export const useGetAuditTrailList = <T>() => {
  const { isAdmin } = detectPortalType();
  const [queryBody, setQueryBody] = useState<AuditTrailQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<T>>(
    [AUDIT_TRAIL_API.getAuditTrailList.api, queryBody],
    () => getAuditTrailList(queryBody),
    {
      enabled: isAdmin,
    },
  );

  const setParams = (newParams: AuditTrailQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetAuditTrailModuleDetails = <T>(moduleId: string) => {
  const { isAdmin } = detectPortalType();
  const [queryBody, setQueryBody] = useState<AuditTrailQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<Paginated<T>>(
    [AUDIT_TRAIL_API.getAuditTrailModuleDetails.api(moduleId), moduleId, queryBody],
    () => getAuditTrailModuleDetails(moduleId, queryBody),
    {
      enabled: isAdmin,
    },
  );

  const setParams = (newParams: AuditTrailQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};
