import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { portalTypes } from './constants/common';
import { detectPortalType } from './helpers/common';
import BrandingContextProvider from './providers/BrandingContextProvider';
import FundPortalProvider from './providers/FundPortalProvider';
import InvestorViewModeProvider from './providers/InvestorViewModeProvider';
import { AppRouter } from './routes';
import themeCustom from './styles/theme';
ChartJS.register(...registerables);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});
const FAVICON_FUND_URL = '/faviconFund.png';
const FAVICON_INVESTOR_URL = '/faviconInvestor.png';

function App() {
  const { portalType } = detectPortalType();

  const data = useMemo(() => {
    const defaultValue = {
      favicon: FAVICON_FUND_URL,
      title: '',
    };
    if (portalType === portalTypes.ADMIN) {
      defaultValue.title = 'Fundcorr';
    } else if (portalType === portalTypes.INVESTOR) {
      defaultValue.favicon = FAVICON_INVESTOR_URL;
      defaultValue.title = 'Investor';
    } else {
      defaultValue.title = portalType;
    }
    return defaultValue;
  }, [portalType]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={themeCustom}>
        <QueryClientProvider client={queryClient}>
          <InvestorViewModeProvider>
            <FundPortalProvider>
              <BrandingContextProvider>
                <Helmet>
                  <title>{data.title} Portal</title>
                  <link rel='icon' href={process.env.PUBLIC_URL + data.favicon} />
                </Helmet>
                <ToastContainer transition={Slide} />
                <AppRouter />
              </BrandingContextProvider>
            </FundPortalProvider>
          </InvestorViewModeProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
