import * as React from 'react';

interface IGiftIconProps {}

const GiftIcon: React.FunctionComponent<IGiftIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Additional_00'
          transform='translate(-928.000000, -586.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-4' transform='translate(214.000000, 270.000000)'>
                <g id='Inheritance/Gift' transform='translate(413.000000, 43.000000)'>
                  <g id='company-(1)-copy' transform='translate(11.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                    <g id='giftbox' mask='url(#mask-2)'>
                      <g transform='translate(6.000000, 6.000000)'>
                        <path
                          d='M37.3908691,42 L4.60849003,42 C3.74972538,42 3.05374141,41.3035528 3.05374141,40.4442164 L3.05374141,15.3596105 L38.9459381,15.3596105 L38.9459381,40.4442164 C38.9459381,41.3035528 38.2496338,42 37.3908691,42 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        />
                        <polygon
                          id='Path'
                          fill='#345E80'
                          fillRule='nonzero'
                          points='3.05374141 15.3596105 38.9459381 15.3596105 38.9459381 20.5794376 3.05374141 20.5794376'
                        />
                        <path
                          d='M42,9.91789499 L42,16.3847219 C42,17.0753974 41.4398804,17.634928 40.7496643,17.634928 L1.24937441,17.634928 C0.559158305,17.634928 0,17.0753974 0,16.3847219 L0,9.91789499 C0,9.22721937 0.559158305,8.66768881 1.24937441,8.66768881 L40.7496643,8.66768881 C41.4398804,8.66768881 42,9.22721937 42,9.91789499 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        />
                        <path
                          d='M19.5721436,11.6407361 C19.2994537,11.9584982 19.0318908,12.2807494 18.7707367,12.6065276 C17.7975769,13.8182561 16.9067688,15.0880217 16.1015167,16.4058846 C15.8538208,16.8111835 15.6147766,17.2206509 15.382782,17.634928 L6.86466982,17.634928 C7.36230469,16.5049648 7.90800478,15.3958437 8.49920654,14.3117331 C9.57138066,12.3448789 10.7947998,10.4575454 12.1617736,8.66768881 C12.4882965,8.24026517 12.8225098,7.81797191 13.1650543,7.40209152 C13.3701324,7.1513449 13.5787354,6.90412535 13.7902222,6.65786776 C14.5675965,7.32802192 15.3443299,7.99753473 16.1220245,8.66768881 C16.2425079,8.77093739 16.3629913,8.87546865 16.4831543,8.97903794 C17.4008789,9.76975562 18.3186035,10.5601528 19.2360077,11.3518324 C19.3484803,11.4473854 19.4599914,11.5445418 19.5721436,11.6407361 Z'
                          id='Path'
                          fill='#345E80'
                          fillRule='nonzero'
                        />
                        <path
                          d='M32.2815399,17.634928 L23.7627869,17.634928 C23.5317535,17.2206509 23.291748,16.8111835 23.0440521,16.4058846 C22.2388,15.0880217 21.3470307,13.8182561 20.3748321,12.6065276 C20.1127167,12.2807494 19.8451538,11.9584982 19.5721436,11.6407361 C19.6846161,11.5445418 19.7961273,11.4480268 19.9085998,11.3518324 C20.8269653,10.5601528 21.7446899,9.76879366 22.6620941,8.97903794 C22.7825775,8.87546865 22.9030609,8.77093739 23.0235443,8.66768881 C23.8009186,7.99753473 24.5786133,7.32802192 25.3553467,6.65786776 C25.5668335,6.90348402 25.7754364,7.1513449 25.9805145,7.40145027 C26.3237,7.81797191 26.6582336,8.24026517 26.9837951,8.66768881 C28.350769,10.4575454 29.5741883,12.3448789 30.6470031,14.3117331 C31.238205,15.3958437 31.7839051,16.5049648 32.2815399,17.634928 L32.2815399,17.634928 Z'
                          id='Path'
                          fill='#EFB025'
                          fillRule='nonzero'
                        />
                        <g
                          id='Group'
                          strokeWidth={1}
                          fill='none'
                          fillRule='evenodd'
                          transform='translate(5.006470, 6.658188)'
                        >
                          <path
                            d='M15.9928894,4.983189 C15.7198791,5.30063047 15.4523163,5.62288159 15.1911621,5.94865985 C14.2183228,7.16038829 13.3271942,8.43015398 12.5219422,9.74833752 C9.96551514,13.9299068 8.26753233,18.6011054 7.55136112,23.4903446 C6.47502138,22.0618582 5.48167421,20.5163353 4.58798222,18.863716 C3.01593018,19.8897893 1.47944639,21.0633606 0,22.3837886 C0.759750387,17.1976295 2.43370058,12.2128372 4.91995235,7.65386543 C6.24783321,5.21758264 7.80834961,2.90282542 9.5854797,0.744223839 C9.79055782,0.49347714 9.99916075,0.246578297 10.2106476,0 C11.1085053,0.773723364 12.0057221,1.54744681 12.9039002,2.32117017 C13.8213043,3.11188794 14.7390289,3.90228499 15.6567535,4.69396464 C15.7689057,4.78951775 15.8804169,4.88667401 15.9928894,4.983189 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          />
                          <path
                            d='M31.9864197,22.3837886 C30.5069732,21.0633606 28.9704895,19.8897893 27.3984375,18.863716 C26.5047455,20.5163353 25.5113983,22.0618582 24.4350586,23.4903446 C23.7188873,18.6011054 22.021225,13.9299068 19.4644775,9.74833752 C18.6592255,8.43015398 17.7674561,7.16038829 16.7952576,5.94865985 C16.5331421,5.62288159 16.2655792,5.30063047 15.9928894,4.983189 C16.1050415,4.88667401 16.2165527,4.79015901 16.3290253,4.69396464 C17.2473907,3.90228499 18.1651153,3.11092597 19.0828399,2.32117017 C19.9806977,1.54648485 20.8788758,0.773723364 21.7757721,0 C21.9872589,0.245616333 22.1958618,0.49347714 22.4009399,0.743582502 C24.1783905,2.90218417 25.7389068,5.21694138 27.0674286,7.65386543 C29.5530396,12.2128372 31.2266693,17.1976295 31.9864197,22.3837886 L31.9864197,22.3837886 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          />
                          <polygon
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                            points='12.6494751 4.983189 19.337265 4.983189 19.337265 10.9767396 12.6494751 10.9767396'
                          />
                        </g>
                        <path
                          d='M24.4709473,16.4065259 C23.6656952,15.0883424 22.7739258,13.8185767 21.8017273,12.6068482 L21.335495,11.352153 L20.9993592,10.4472847 L24.0893097,8.97935857 L27.4074097,7.40177089 C29.1848603,9.56037256 30.7453766,11.8751298 32.0738983,14.3120538 L24.4709473,16.4065259 Z'
                          id='Path'
                          fill='#F4A93C'
                          fillRule='nonzero'
                        />
                        <path
                          d='M20.9993592,10.4472847 L20.6632232,11.352153 L20.1976318,12.6068482 C19.2247925,13.8185767 18.333664,15.0883424 17.5284119,16.4065259 L9.92642208,14.3120538 C11.2543029,11.875771 12.8148193,9.56101382 14.5919494,7.40241223 L17.9103699,8.97935857 L20.9993592,10.4472847 Z'
                          id='Path'
                          fill='#F4A93C'
                          fillRule='nonzero'
                        />
                        <path
                          d='M33.1335754,0.290762422 L20.9996796,6.05697356 L23.3305206,12.3298085 L36.2818451,8.76260058 C37.9756622,8.29605787 38.9062042,6.47702235 38.2938537,4.82921277 L37.224884,1.95300114 C36.612854,0.305191557 34.7206879,-0.46340144 33.1335754,0.290762422 Z'
                          id='Path'
                          fill='#F6B940'
                          fillRule='nonzero'
                        />
                        <path
                          d='M8.86578369,0.290762422 L20.9996796,6.05697356 L18.6688385,12.3298085 L5.71783447,8.76260058 C4.02401737,8.29605787 3.09347538,6.47702235 3.70582579,4.82921277 L4.7744751,1.95300114 C5.38682558,0.305191557 7.27867129,-0.46340144 8.86578369,0.290762422 Z'
                          id='Path'
                          fill='#F6B940'
                          fillRule='nonzero'
                        />
                        <path
                          d='M23.8970489,8.57790739 C23.6765899,8.57790739 23.4602967,8.46888711 23.3330841,8.26912344 C23.1340942,7.95777431 23.2247772,7.54413856 23.5359191,7.34501624 C23.6278839,7.28633765 25.8132477,5.89472576 28.3222504,4.87923384 C31.9393158,3.41515557 34.3547516,3.49371428 35.5006256,5.11266551 C35.7143555,5.41439525 35.6428985,5.8321994 35.3413696,6.04575094 C35.0398407,6.2593024 34.6226349,6.18811861 34.4092255,5.88638896 C32.8557587,3.69187464 26.396759,7.10420964 24.2565766,8.47273488 C24.1450653,8.54391876 24.0204162,8.57790739 23.8970489,8.57790739 Z'
                          id='Path'
                          fill='#FFC91D'
                          fillRule='nonzero'
                        />
                        <path
                          d='M18.0363007,8.57790739 C17.9129334,8.57790739 17.7882843,8.54391876 17.6767731,8.47273488 C15.5365906,7.10420964 9.07759092,3.69187464 7.52412413,5.88638896 C7.31039433,6.18811861 6.89318848,6.2593024 6.59165957,6.04575094 C6.29045107,5.83187877 6.21899414,5.41439525 6.43272402,5.11266551 C7.57859804,3.49371428 9.99371338,3.41515557 13.6110992,4.87923384 C16.1201019,5.89440513 18.3054657,7.28633765 18.3974305,7.34501624 C18.7085724,7.54413856 18.7992554,7.95777431 18.6005859,8.26912344 C18.4727325,8.46888711 18.2567597,8.57790739 18.0363007,8.57790739 Z'
                          id='Path'
                          fill='#FFC91D'
                          fillRule='nonzero'
                        />
                        <path
                          d='M22.90242,3.90093716 L19.0969391,3.90093716 C18.0555268,3.90093716 17.2111816,4.74584431 17.2111816,5.78827066 L17.2111816,11.3204089 C17.2111816,12.3628352 18.0555268,13.2077425 19.0969391,13.2077425 L22.90242,13.2077425 C23.9438324,13.2077425 24.7881775,12.3628352 24.7881775,11.3204089 L24.7881775,5.78827066 C24.7881775,4.74584431 23.9438324,3.90093716 22.90242,3.90093716 Z'
                          id='Path'
                          fill='#F4A93C'
                          fillRule='nonzero'
                        />
                        <polygon
                          id='Path'
                          fill='#F6B940'
                          fillRule='nonzero'
                          points='17.6559448 17.634928 24.3437348 17.634928 24.3437348 42 17.6559448 42'
                        />
                        <polygon
                          id='Path'
                          fill='#F4A93C'
                          fillRule='nonzero'
                          points='17.6559448 17.634928 24.3437348 17.634928 24.3437348 20.5794376 17.6559448 20.5794376'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GiftIcon;
