import { BULK_UPLOAD_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { Paginated } from 'src/modules/common/type';
import {
  IBulkUploadDetail,
  IBulkUploadItem,
  IBulkUploadQueryBody,
  ICreateBulkUpload,
} from './type';

export const getBulkUploadList = async (
  data: IBulkUploadQueryBody,
): Promise<Paginated<IBulkUploadItem>> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: BULK_UPLOAD_API.getBulkUploadList.api,
    data,
  });
  return result.data;
};

export const createBulkUpload = async (params: ICreateBulkUpload) => {
  const data = new FormData();
  data.append('clientId', params.clientId);
  data.append('fundId', params.fundId);
  data.append('unitClassId', params.unitClassId);
  data.append('investmentEntityType', params.investmentEntityType);
  data.append('file', params?.file || '');

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: BULK_UPLOAD_API.bulkUploadInvestor.api,
  });

  return result.data;
};

export const getBulkUploadListById = async (
  id: string,
  data: IBulkUploadQueryBody,
): Promise<Paginated<IBulkUploadDetail>> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: BULK_UPLOAD_API.getBulkUploadListById.api(id),
    data,
  });
  return result.data;
};

export const getBulkUploadMasterData = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: BULK_UPLOAD_API.getBulkUploadMasterData.api,
  });

  return result.data;
};

export const getBulkUploadInvestorClients = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: BULK_UPLOAD_API.getBulkUploadInvestorClients.api,
  });

  return result.data;
};

export const getBulkUploadInvestorFunds = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: BULK_UPLOAD_API.getBulkUploadInvestorFunds.api(clientId),
  });

  return result.data;
};

export const getBulkUploadInvestorUnitClasses = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: BULK_UPLOAD_API.getBulkUploadInvestorUnitClasses.api(fundId),
  });

  return result.data;
};
