import { Box } from '@mui/material';
import * as React from 'react';
import HorizontalTabs, { ITab } from 'src/components/molecules/HorizontalTabs';
import Billing from './Billing';
import Subscription from './Subscription';

interface SubscriptionAndBillingProps {}
const SubscriptionAndBilling: React.FC<SubscriptionAndBillingProps> = () => {
  const Tabs: ITab[] = [
    {
      label: 'Subscription',
      content: <Subscription />,
    },
    {
      label: 'Billing',
      content: <Billing />,
    },
  ];

  return (
    <Box p={3} width='81%'>
      <HorizontalTabs tabs={Tabs} destroyInactivePanel={false} />
    </Box>
  );
};

export default SubscriptionAndBilling;
