import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import OfferPreview from 'src/components/organisms/OfferPreview';
import { FundType } from 'src/constants/funds-management';
import { useDownloadFundDocument, useGetInvestorOfferInfo } from 'src/modules/applications/hooks';
import { IInvestorOfferDetails } from 'src/modules/applications/type';
import { IDocument } from 'src/modules/offer/type';
import { handleErrorFromServer } from 'src/utils/common';

interface IOfferOverviewProps {
  previewData?: IInvestorOfferDetails;
  onClose: () => void;
  offerId?: string;
  unitClassId?: string;
}

const OfferOverview: FC<IOfferOverviewProps> = ({
  onClose,
  previewData,
  offerId = '',
  unitClassId = '',
}) => {
  const { data: offerInfo } = useGetInvestorOfferInfo(offerId);
  const { mutate: downloadFundDocumentMutate } = useDownloadFundDocument();

  const handleClickDocument = async (document: IDocument) => {
    if (!document.id) return;
    downloadFundDocumentMutate(document, {
      onError: handleErrorFromServer,
    });
  };

  return (
    <Box className='w-[1120px]'>
      <Box className='py-7.5 relative border-b' borderColor='neutral.ne200'>
        <Typography variant='subtitle1' align='center' fontWeight='bold'>
          Offer Overview
        </Typography>
        <IconButton className='absolute right-8 top-1/2 translate-y-[-50%]' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className='pt-7.5 pb-12.5' fontSize='14px'>
        <OfferPreview
          className='w-3/5 min-w-[700px] m-auto'
          unitClassId={unitClassId}
          offerUnitClasses={offerInfo?.unitClasses || []}
          onClickDocument={handleClickDocument}
          showUnitClass={offerInfo?.fundType === FundType.Trust}
          previewData={previewData}
          hideThumbnail
          hideInvestButton
          unitClassColSpan={8}
          viewOnlyUnitClass
        />
      </Box>
    </Box>
  );
};

export default OfferOverview;
