import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import {
  REASON_NOT_HAVING_GIIN,
  TAX_STATUS,
  reasonNotHavingGIINOptions,
  taxStatusOptions,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { IFinancialTaxDetailsForm } from 'src/modules/applications/type';
import { ITaxDetailsProps } from '..';

interface ITaxStatusProps extends ITaxDetailsProps {}

const TaxStatus: React.FC<ITaxStatusProps> = ({
  isViewMode,
  isDisabledEdit,
  isExistingInvestmentEntity,
}) => {
  const { watch } = useFormContext<IFinancialTaxDetailsForm>();
  const watchTaxStatusType = watch('taxStatus.taxStatusType');
  const watchHasGIIN = watch('taxStatus.hasGIIN');
  const watchReasonNotHavingGIIN = watch('taxStatus.reasonNotHavingGIIN');

  const renderHasGIINSubSection = () => {
    if (typeof watchHasGIIN !== 'boolean') return null;

    return watchHasGIIN ? (
      <Grid item xs={12}>
        <FormInput
          name='taxStatus.giin'
          label='Please provider GIIN'
          placeholder='Enter GIIN'
          disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
        />
      </Grid>
    ) : (
      <>
        <Grid item xs={12}>
          <FormSelect
            name='taxStatus.reasonNotHavingGIIN'
            label='Select the FATCA status'
            placeholder='Select the FATCA status'
            options={reasonNotHavingGIINOptions}
            disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
          />
        </Grid>
        {watchReasonNotHavingGIIN === REASON_NOT_HAVING_GIIN.Other && (
          <Grid item xs={12}>
            <FormInput
              name='taxStatus.fatcaStatus'
              label='Describe the FATCA status'
              placeholder='Enter the FATCA status'
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Box>
      <FormSelect
        name='taxStatus.taxStatusType'
        label='Tax Status'
        options={taxStatusOptions}
        placeholder='Select tax status'
        disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
      />
      {watchTaxStatusType === TAX_STATUS.Other && (
        <Box className='pt-4.5'>
          <FormInput
            name='taxStatus.otherDescription'
            placeholder='Describe tax status'
            label='Describe Tax Status'
            disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
          />
        </Box>
      )}
      {watchTaxStatusType === TAX_STATUS.FinancialInstitution && (
        <Box className='pt-4.5'>
          <LabelWithTooltip
            label={
              <Typography variant='body3' fontWeight='bold'>
                Financial Institution
              </Typography>
            }
            tooltip='A custodial or depository institution, an investment entity or a specified insurance company for FATCA / CRS purposes'
            placement='right-start'
          />
          <Box className='mt-2 border rounded-xl p-6' borderColor='neutral.ne300'>
            <Grid container rowSpacing='18px'>
              <Grid item xs={12}>
                <FormRadioGroup
                  name='taxStatus.hasGIIN'
                  label='Do you have the entity’s Global Intermediary Identification Number (GIIN)?'
                  options={TrueFalseRadioOptions}
                  optionGap={10}
                  isBooleanValue
                  disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
                />
              </Grid>
              {renderHasGIINSubSection()}
              <Grid item xs={12}>
                <FormRadioGroup
                  name='taxStatus.isAllowCRSAndManagedByAnotherFI'
                  label='Is the Financial Institution an Investment Entity located in a Non-Participating CRS Jurisdiction and managed by another Financial Institution?'
                  options={TrueFalseRadioOptions}
                  optionGap={10}
                  isBooleanValue
                  disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TaxStatus;
