import { Box, Grid, Radio } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import { FUND_KYC_SUBMIT_TYPE, fundKycSubmitTypeOptions } from 'src/constants/applications';
import { IFundKYCAMLForm, IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';

interface IAmlKycProps extends IUpsertInvestmentApplicationForm {}

const AmlKyc: FC<IAmlKycProps> = ({ isViewMode, isDisabledEdit }) => {
  const form = useFormContext<IFundKYCAMLForm>();
  const watchKycSubmitType = form.watch('kycSubmitType');
  const renderInvestorTypeOptions = (options: RadioOption[], selectedValue?: string | number) => {
    return (
      <Grid container columnSpacing={3} rowSpacing={2.25}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6}>
            <Box
              className='border rounded-xl px-3 flex justify-between items-center'
              borderColor={option.value === selectedValue ? 'primary.main' : 'neutral.ne400'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  width: '100%',
                }}
                disabled={isViewMode || isDisabledEdit}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box className='mt-10'>
      <FormRadioGroup
        name='kycSubmitType'
        options={fundKycSubmitTypeOptions}
        label='Please select one of the following options:'
        renderOptions={renderInvestorTypeOptions}
        isEnumValue
        disabled={isViewMode || isDisabledEdit}
      />
      {watchKycSubmitType === FUND_KYC_SUBMIT_TYPE.MarkCompleted && (
        <Box className='mt-8.5'>
          <FormInput
            name='comment'
            label='Comment'
            placeholder='Enter comment'
            maxLength={280}
            isShowCharactersLeft
            multiline
            rows={4}
          />
        </Box>
      )}
    </Box>
  );
};

export default AmlKyc;
