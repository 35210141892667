import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { LinkIcon } from 'src/assets/icons/LinkIcon';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormSelect from 'src/components/atoms/FormSelect';
import AddressSection from 'src/components/pages/client-management/components/AddressSection';
import { ADDRESS_SECTION_NAME } from 'src/components/pages/client-management/components/OrganisationForm';
import { OTHER_LABEL } from 'src/constants/common';
import { IServiceProviderType } from 'src/modules/funds-management/type';
import { formatValueWithSpaces } from 'src/utils/common';

interface IUpsertProviderFormProps {
  capacities: IServiceProviderType[];
  readOnly: boolean;
  serviceProvidersSection: boolean;
  investmentManagerCapacities: IServiceProviderType[];
  showSetAsIMCheckbox?: boolean;
}

const UpsertProviderForm: React.FunctionComponent<IUpsertProviderFormProps> = ({
  capacities,
  readOnly,
  serviceProvidersSection,
  investmentManagerCapacities,
  showSetAsIMCheckbox = false,
}) => {
  const { resetField, watch, getValues, setValue } = useFormContext();

  const watchPhysicalManualEntry = watch('physicalAddress.manualEntry');
  const watchCountryFlagCode = watch('primaryContacts[0].countryFlagCode');
  const selectedCapacity = watch('capacities');

  React.useEffect(() => {
    handleCheckHasOtherOption();
  }, [selectedCapacity]);

  const handleFormatAbn = (abn: string) => {
    const formattedAbn = formatValueWithSpaces(abn);
    return formattedAbn;
  };

  const handleCheckHasOtherOption = () => {
    const otherOption = (capacities || []).find((item) => item.name === OTHER_LABEL);
    const hasOtherOption =
      selectedCapacity instanceof Array
        ? (selectedCapacity || []).includes(otherOption?.id)
        : selectedCapacity === otherOption?.id;

    setValue('hasOtherCapacity', !!hasOtherOption);
    if (!hasOtherOption && getValues('otherCapacity')) {
      setValue('otherCapacity', '');
    }

    return hasOtherOption;
  };

  const renderOtherCapacity = () => {
    const hasOtherOption = handleCheckHasOtherOption();
    return (
      hasOtherOption && (
        <Grid item xs={12} md={6} alignSelf='flex-end'>
          <FormInput
            name='otherCapacity'
            placeholder='Enter other capacity'
            label=''
            readOnly={readOnly}
          />
        </Grid>
      )
    );
  };

  const capacityOptions = (capacities || []).map(({ id, name }) => ({
    id,
    label: name,
    value: id,
  }));

  return (
    <Box className='pt-10 pb-15' component='form'>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormInput
                name='displayName'
                label='Display Name'
                placeholder='Enter display name'
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='legalName'
                label='Legal Name'
                placeholder='Enter legal name'
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='abn'
                label='ACN/ABN'
                placeholder='Enter ACN/ABN'
                readOnly={readOnly}
                formatValue={handleFormatAbn}
                maxLength={14}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {serviceProvidersSection ? (
                <FormMultiSelect
                  name='capacities'
                  label='Capacity'
                  options={capacityOptions}
                  placeholder='Select capacity'
                  readOnly={readOnly}
                />
              ) : (
                <FormSelect
                  name='capacities'
                  label='Capacity'
                  options={capacityOptions}
                  placeholder='Select capacity'
                  readOnly={readOnly}
                />
              )}
            </Grid>
            {renderOtherCapacity()}
            {showSetAsIMCheckbox && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormCheckbox
                      name='setAsInvestmentManager'
                      label={<Typography variant='body3'>Set as Investment Manager</Typography>}
                      onChange={(_, checked) => {
                        if (!checked) {
                          setValue('additionalCapacities', undefined);
                        }
                      }}
                    />
                  </Grid>
                  {watch('setAsInvestmentManager') && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <FormSelect
                            name='additionalCapacities'
                            label='Capacity'
                            options={investmentManagerCapacities
                              .filter((it) => it.name !== OTHER_LABEL)
                              .map(({ id, name }) => ({
                                id,
                                label: name,
                                value: id,
                              }))}
                            placeholder='Select capacity'
                            readOnly={readOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box className='flex pb-4'>
            <Box className='rotate-[135deg] mr-3'>
              <LinkIcon />
            </Box>
            <Typography variant='body2' fontWeight={600}>
              Primary Contact
            </Typography>
          </Box>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                name='primaryContacts[0].firstName'
                label='First Name'
                size='small'
                placeholder='Enter first name'
                className='w-full'
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='primaryContacts[0].lastName'
                label='Last Name'
                size='small'
                placeholder='Enter last name'
                className='w-full'
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='primaryContacts[0].email'
                label='Email Address'
                size='small'
                placeholder='Enter email address'
                className='w-full'
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='primaryContacts[0].phoneNumber'
                label='Contact Number'
                size='small'
                className='w-full'
                countryFlagCodeName='primaryContacts[0].countryFlagCode'
                countryFlagCode={watchCountryFlagCode}
                readOnly={readOnly}
                isIncludeCode
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                name='primaryContacts[0].position'
                label='Position'
                size='small'
                placeholder='Enter position'
                className='w-full'
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddressSection
        name={ADDRESS_SECTION_NAME.physicalAddress}
        title='Physical Address'
        resetField={resetField}
        watch={watch}
        isShowManualEntry
        optional='Optional'
        readOnly={readOnly}
      />
      <AddressSection
        name={ADDRESS_SECTION_NAME.postalAddress}
        title='Postal Address'
        resetField={resetField}
        watch={watch}
        isShowManualEntry
        optional='Optional'
        readOnly={readOnly}
      />
    </Box>
  );
};

export default UpsertProviderForm;
