/* eslint-disable no-restricted-imports */
import { Box, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CompanyIcon from 'src/assets/icons/CompanyIcon';
import IndividualsIcon from 'src/assets/icons/IndividualsIcon';
import OtherIcon from 'src/assets/icons/OtherIcon';
import PartnershipIcon from 'src/assets/icons/PartnershipIcon';
import SMSFIcon from 'src/assets/icons/SMSFIcon';
import SoleTraderIcon from 'src/assets/icons/SoleTraderIcon';
import TrustIcon from 'src/assets/icons/TrustIcon';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import {
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  individualDefaultValue,
} from 'src/constants/applications';
import { CLIENT_USER_ROLE } from 'src/constants/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import {
  IInvestmentDetailById,
  IInvestmentEntityDetailsForm,
} from 'src/modules/investment-entity/types';
import { checkAllowEditAbn } from 'src/utils/applications';
import { swapArrayElements } from 'src/utils/common';
import { INVESTMENT_ENTITY_STEP } from '../InvestmentEntityForm';
import Company from './Company';
import Individuals from './Individuals';
import Other from './Other';
import Partnership from './Partnership';
import SMSF from './SMSF';
import SoleTrader from './SoleTrader';
import Trust from './Trust';

export interface IInvestmentEntityDetails extends IInvestmentEntityProps {}

const Details = (props: IInvestmentEntityDetails) => {
  const { id = '' } = props;
  const { watch, reset } = useFormContext<IInvestmentEntityDetailsForm>();
  const { data: investmentEntityDetail } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });
  const { data: userInfo } = useGetUserInfo();
  const isSuperAdmin = userInfo?.role === CLIENT_USER_ROLE.SUPER_ADMIN;

  const currentEntityType = watch('entityType');
  const isDisabledEntitySelection = Number.isInteger(currentEntityType) && !!id;

  useEffect(() => {
    handleFillData();
  }, [investmentEntityDetail, isSuperAdmin]);

  const handleFillData = () => {
    if (isEmpty(investmentEntityDetail)) return;
    const {
      australianCompany,
      foreignCompany,
      association,
      governmentBody,
      regulatedTrust,
      unregulatedTrust,
      smsf,
      partnership,
    } = investmentEntityDetail.details || {};
    const australianCompanyDirectors =
      (australianCompany?.directors || []).map((it) => it.refId) || [];
    const australianCompanyUBOs: any = {};
    (australianCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        australianCompanyUBOs[it.refId] = it;
      }
    });

    const foreignCompanyDirectorRefs =
      (foreignCompany?.directors || []).map((it) => it.refId) || [];
    const foreignCompanyUBOs: any = {};
    (foreignCompany?.ubOs || []).forEach((it) => {
      if (it.refId) {
        foreignCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeRegulatedTrustAustralianCompanyDirectors =
      regulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeRegulatedTrustAustralianCompanyUBOs: any = {};
    regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeRegulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const trusteeUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerUnregulatedTrustAustralianCompanyDirectors =
      unregulatedTrust?.appointerAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const appointerUnregulatedTrustAustralianCompanyUBOs: any = {};
    unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        appointerUnregulatedTrustAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const appointerSelectedExistingIndividuals =
      unregulatedTrust?.appointerIndividuals
        ?.filter((it) =>
          (unregulatedTrust?.trusteeAustralianCompanyDetails?.directors || [])
            .concat(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs || [])
            .concat(unregulatedTrust?.trusteeIndividuals || [])
            .map((item) => item.refId)
            .includes(it.refId),
        )
        ?.map((it) => it.refId || '') || [];

    const trusteeSMSFAustralianCompanyDirectors =
      smsf?.trusteeAustralianCompanyDetails?.directors?.map((it) => it.refId) || [];
    const trusteeSMSFAustralianCompanyUBOs: any = {};
    smsf?.trusteeAustralianCompanyDetails?.ubOs?.forEach((it) => {
      if (it.refId) {
        trusteeSMSFAustralianCompanyUBOs[it.refId] = it;
      }
    });

    const associationPublicOfficerRefs = (association?.publicOfficers || []).map((it) => it.refId);
    const associationUbos: any = (association?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const governmentBodyPublicOfficerRefs = (governmentBody?.publicOfficers || []).map(
      (it) => it.refId,
    );
    const governmentBodyUbos: any = (governmentBody?.ubOs || []).reduce((prev, currentItem) => {
      const result: any = { ...prev };
      if (currentItem.refId) {
        result[currentItem.refId] = currentItem;
      }
      return result;
    }, {});

    const partnershiRefPartners = (partnership?.partners || []).map((it) => it.refId) || [];
    const partnershipUbos: any = {};
    (partnership?.ubOs || []).forEach((it) => {
      if (it.refId) {
        partnershipUbos[it.refId] = it;
      }
    });
    const beVerifiedPartnerIndex =
      (partnership?.partners || []).findIndex((it) => it?.beVerified) || 0;
    const partnershipPartners = (partnership?.partners || []).map(
      (it) => (it.refId && partnershipUbos[it.refId]) || it,
    );
    const allowEditAbn = handleCheckAllowEditAbn(investmentEntityDetail, isSuperAdmin);

    reset({
      entityType: investmentEntityDetail.type,
      companyRegionType: investmentEntityDetail.companyRegionType,
      otherType: investmentEntityDetail?.otherType,
      trustType: investmentEntityDetail?.trustType,
      investmentEntityDetails: {
        ...investmentEntityDetail.details,
        allowEditAbn,
        numberOfIndividuals: (investmentEntityDetail.details?.individuals || []).length,
        australianCompany: {
          ...australianCompany,
          ...(australianCompany?.ubOs
            ? {
                ubOs: australianCompany.ubOs.filter(
                  (it) => !australianCompanyDirectors.includes(it.refId),
                ),
              }
            : {}),
          ...(australianCompany?.directors
            ? {
                directors: australianCompany.directors.map(
                  (it) =>
                    (it.refId && australianCompanyUBOs[it.refId]) || {
                      ...it,
                      populatedDirector: true,
                    },
                ),
              }
            : {}),
        },
        foreignCompany: {
          ...(foreignCompany || {}),
          ubOs: (foreignCompany?.ubOs || []).filter(
            (it) => !foreignCompanyDirectorRefs.includes(it.refId),
          ),
          directors: (foreignCompany?.directors || []).map(
            (it) => (it.refId && foreignCompanyUBOs[it.refId]) || it,
          ),
        },
        regulatedTrust: {
          ...regulatedTrust,
          numberOfIndividuals: regulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: regulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...regulatedTrust?.trusteeAustralianCompanyDetails,
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: regulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeRegulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(regulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: regulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeRegulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        unregulatedTrust: {
          ...unregulatedTrust,
          numberOfIndividuals: unregulatedTrust?.trusteeIndividuals?.length || null,
          trustUBOs: unregulatedTrust?.trustUBOs?.filter((it) => !it.refId) || [],
          settlor:
            unregulatedTrust?.isSettlorMoreThanMax && unregulatedTrust?.settlor
              ? unregulatedTrust.settlor
              : individualDefaultValue(true),
          trusteeAustralianCompanyDetails: {
            ...unregulatedTrust?.trusteeAustralianCompanyDetails,
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
          appointerAustralianCompanyDetails: {
            ...unregulatedTrust?.appointerAustralianCompanyDetails,
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: unregulatedTrust.appointerAustralianCompanyDetails.ubOs.filter(
                    (it) => !appointerUnregulatedTrustAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(unregulatedTrust?.appointerAustralianCompanyDetails?.directors
              ? {
                  directors: unregulatedTrust.appointerAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && appointerUnregulatedTrustAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
            isManualEntry:
              (unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
                unregulatedTrust?.isAppointerSameAsTrustee === false) ||
              unregulatedTrust?.trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
          },
          selectedExistingIndividuals: appointerSelectedExistingIndividuals,
          appointerIndividuals:
            unregulatedTrust?.appointerIndividuals?.filter(
              (it) => !appointerSelectedExistingIndividuals.includes(it.refId || ''),
            ) || [],
        },
        smsf: {
          ...smsf,
          numberOfIndividuals: smsf?.trusteeIndividuals?.length || null,
          smsfubOs: smsf?.smsfubOs?.filter((it) => !it.refId) || [],
          trusteeAustralianCompanyDetails: {
            ...smsf?.trusteeAustralianCompanyDetails,
            ...(smsf?.trusteeAustralianCompanyDetails?.ubOs
              ? {
                  ubOs: smsf.trusteeAustralianCompanyDetails.ubOs.filter(
                    (it) => !trusteeSMSFAustralianCompanyDirectors.includes(it.refId),
                  ),
                }
              : {}),
            ...(smsf?.trusteeAustralianCompanyDetails?.directors
              ? {
                  directors: smsf.trusteeAustralianCompanyDetails.directors.map(
                    (it) =>
                      (it.refId && trusteeSMSFAustralianCompanyUBOs[it.refId]) || {
                        ...it,
                        populatedDirector: true,
                      },
                  ),
                }
              : {}),
          },
        },
        association: {
          ...(association || {}),
          ubOs: (association?.ubOs || []).filter(
            (it) => !associationPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (association?.publicOfficers || []).map(
            (it) => (it.refId && associationUbos[it.refId]) || it,
          ),
        },
        governmentBody: {
          ...(governmentBody || {}),
          ubOs: (governmentBody?.ubOs || []).filter(
            (it) => !governmentBodyPublicOfficerRefs.includes(it?.refId || ''),
          ),
          publicOfficers: (governmentBody?.publicOfficers || []).map(
            (it) => (it.refId && governmentBodyUbos[it.refId]) || it,
          ),
        },
        partnership: {
          ...(partnership || {}),
          ubOs: (partnership?.ubOs || []).filter(
            (it) => !it?.refId || !partnershiRefPartners.includes(it.refId),
          ),
          partners: swapArrayElements(partnershipPartners, beVerifiedPartnerIndex, 0),
          certifiedAgreementFile: partnership?.certifiedPartnershipAgreementFile,
          certifiedAgreementFileId: partnership?.certifiedPartnershipAgreementFileId,
          certifiedAgreementFileDescription:
            partnership?.certifiedPartnershipAgreementFileDescription,
        },
      },
      isAddNewEntityDetail: Number.isInteger(investmentEntityDetail.type),
    });
  };

  const handleCheckAllowEditAbn = (
    investmentEntityDetail: IInvestmentDetailById,
    isSuperAdmin: boolean,
  ): boolean => {
    const {
      australianCompany,
      smsf,
      association,
      governmentBody,
      regulatedTrust,
      partnership,
      unregulatedTrust,
      soleTrader,
    } = investmentEntityDetail.details || {};
    if (isSuperAdmin) return true;

    switch (investmentEntityDetail.type) {
      case INVESTMENT_ENTITY_TYPE.SoleTrader:
        return checkAllowEditAbn(soleTrader?.abn);
      case INVESTMENT_ENTITY_TYPE.Company:
        return checkAllowEditAbn(australianCompany?.abn);
      case INVESTMENT_ENTITY_TYPE.Trust:
        if (investmentEntityDetail.trustType === INVESTMENT_ENTITY_TRUST.Unregulated) {
          return checkAllowEditAbn(unregulatedTrust?.abn);
        }
        return checkAllowEditAbn(regulatedTrust?.abn);
      case INVESTMENT_ENTITY_TYPE.SMSF:
        return checkAllowEditAbn(smsf?.abn);
      case INVESTMENT_ENTITY_TYPE.Partnership:
        return checkAllowEditAbn(partnership?.abn);
      case INVESTMENT_ENTITY_TYPE.Other:
        if (investmentEntityDetail.otherType === INVESTMENT_ENTITY_OTHER.Association) {
          return checkAllowEditAbn(association?.abn);
        }
        return checkAllowEditAbn(governmentBody?.abn);
      default:
        return true;
    }
  };

  const entityData = {
    [INVESTMENT_ENTITY_TYPE.Individuals]: {
      icon: <IndividualsIcon />,
      value: INVESTMENT_ENTITY_TYPE.Individuals,
      label: 'Individuals',
      disabled:
        isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.Individuals,
      component: <Individuals {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.SoleTrader]: {
      icon: <SoleTraderIcon />,
      value: INVESTMENT_ENTITY_TYPE.SoleTrader,
      label: 'Sole Trader',
      disabled:
        isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.SoleTrader,
      component: <SoleTrader {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.Company]: {
      icon: <CompanyIcon />,
      value: INVESTMENT_ENTITY_TYPE.Company,
      label: 'Company',
      disabled: isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.Company,
      component: <Company {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.Trust]: {
      icon: <TrustIcon />,
      value: INVESTMENT_ENTITY_TYPE.Trust,
      label: 'Trust',
      disabled: isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.Trust,
      component: <Trust {...props} isSuperAdmin={isSuperAdmin} />,
    },
    [INVESTMENT_ENTITY_TYPE.SMSF]: {
      icon: <SMSFIcon />,
      value: INVESTMENT_ENTITY_TYPE.SMSF,
      label: 'SMSF',
      disabled: isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.SMSF,
      component: <SMSF {...props} isSuperAdmin={isSuperAdmin} />,
    },
    [INVESTMENT_ENTITY_TYPE.Partnership]: {
      icon: <PartnershipIcon />,
      value: INVESTMENT_ENTITY_TYPE.Partnership,
      label: 'Partnership',
      disabled:
        isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.Partnership,
      component: <Partnership {...props} />,
    },
    [INVESTMENT_ENTITY_TYPE.Other]: {
      icon: <OtherIcon />,
      value: INVESTMENT_ENTITY_TYPE.Other,
      label: 'Other',
      disabled: isDisabledEntitySelection && currentEntityType !== INVESTMENT_ENTITY_TYPE.Other,
      component: <Other {...props} />,
    },
  };

  return (
    <Box mt={2} display={'flex'} flexDirection={'column'}>
      <Typography variant='body2' fontWeight={500}>
        Entity Type
      </Typography>
      <FormRadioGroup
        containerSx={{
          '.MuiStack-root': {
            gap: 0,
            justifyContent: 'space-between',
            mr: '-11px',
          },
          '.MuiFormControlLabel-root': { mr: 0 },
        }}
        name='entityType'
        options={Object.values(entityData)}
        label=''
        isEnumValue
      />
      <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
      {Number.isInteger(currentEntityType) && entityData[currentEntityType || 0]?.component}
    </Box>
  );
};

export default Details;
