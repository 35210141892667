import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import _, { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { AccordionSummary as AccordionSummaryAtom } from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomEditor from 'src/components/atoms/CustomEditor';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import {
  COMMUNICATION_MESSAGE_TYPE,
  COMMUNICATION_VIEW_MODE,
  CommunicationCategoryEnum,
  InvestorRelationOrderEnum,
  InvestorRelationOrderOptions,
} from 'src/constants/communications';
import { detectPortalType } from 'src/helpers/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import {
  ICategoryInvestor,
  ICategoryInvestorItem,
  ICategoryKYCBO,
  IClientRecipientDetail,
  ICommunicationDetail,
  IFundCategoryInvestor,
  IInternalRecipientBody,
  IInternalRecipientDetail,
  IKycBoRecipientBody,
} from 'src/modules/communication/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { createNestedStructure } from 'src/utils/communication';
import Attachment from './Attachments';
import ForwaredOrRepliedContent from './ForwaredOrRepliedContent';
import { IMultiLevelSelectHandler, Item, MultiLevelSelect } from './MultiLevelSelect';

const { isFunds, isInvestor, isAdmin } = detectPortalType();

enum PANELS {
  Category,
  Recipient,
}

enum KYC_BO_RECIPIENT {
  PRIMARY_CONTACT,
  UBO,
  COMMUNICATION_LIST,
  TEAMS,
}

type RecipientType =
  | typeof KYC_BO_RECIPIENT.COMMUNICATION_LIST
  | typeof KYC_BO_RECIPIENT.PRIMARY_CONTACT
  | typeof KYC_BO_RECIPIENT.UBO
  | typeof KYC_BO_RECIPIENT.TEAMS;

const CustomAccordion = styled(Accordion)(() => ({
  margin: '0 !important',
  boxShadow: 'none',
  borderRadius: '0 !important',
  '::before': {
    display: 'none',
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.neutral.ne200}`,
  padding: '0px',
  minHeight: 'unset !important',
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.ne100,
  borderTopWidth: '0 !important',
  borderBottomRightRadius: '12px',
  borderBottomLeftRadius: '12px',
}));

interface IMessageContent {
  kycBoData?: ICategoryKYCBO;
  clientData?: IClientRecipientDetail[];
  internalData?: IInternalRecipientDetail[];
  categoryInvestorData: ICategoryInvestor[] | IFundCategoryInvestor[];
  categories?: ICategoryInvestorItem[];
  messageDetail?: ICommunicationDetail;
  isCategoryDisabled?: boolean;
  isRecipientDisabled?: boolean;
  isSubjectDisabled?: boolean;
  isContentDisabled?: boolean;
  recipientsError?: string;
  messageType?:
    | typeof COMMUNICATION_MESSAGE_TYPE.Forward
    | typeof COMMUNICATION_MESSAGE_TYPE.Reply
    | typeof COMMUNICATION_MESSAGE_TYPE.New;
  isExistingMessage?: boolean;
  viewMode?: number;
}

const MessageContent: React.FC<IMessageContent> = ({
  messageDetail,
  categories = [],
  kycBoData,
  clientData,
  internalData,
  categoryInvestorData,
  isCategoryDisabled,
  isRecipientDisabled,
  isSubjectDisabled,
  isContentDisabled,
  recipientsError,
  messageType,
  isExistingMessage,
  viewMode,
}) => {
  const { data: userProfile } = useGetUserInfo();
  const { isFAUser, isFaPortal } = useFundPortalContext();
  const [expanded, setExpanded] = useState<number | false>(false);

  const { watch, setValue, getValues, control } = useFormContext();
  const {
    fields: externalEmails,
    append: addExternalEmail,
    remove: deleteExternalEmail,
  } = useFieldArray({
    control,
    name: 'externalEmails',
  });

  const multiLevelSelectRef = useRef<IMultiLevelSelectHandler>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const attachments = watch('attachments');
  const watchCategory = watch('category');
  const watchContent = watch('content');
  const watchInvestorRelationOrder = watch('investorRelationOrder');

  const isForward = messageType === COMMUNICATION_MESSAGE_TYPE.Forward;
  const isReplied = messageType === COMMUNICATION_MESSAGE_TYPE.Reply;
  const isNewMessage = messageType === COMMUNICATION_MESSAGE_TYPE.New;
  const isInboxMode = viewMode === COMMUNICATION_VIEW_MODE.Inbox;
  const isOutboxMode = viewMode === COMMUNICATION_VIEW_MODE.Outbox;

  const isShowForwardOrReplyMessage =
    _.isNumber(messageType) &&
    (!_.isEmpty(messageDetail?.forwardedMessage) || !_.isEmpty(messageDetail?.repliedMessage));

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [attachments]);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const customRenderCategoryInvestors = (
    oldName: string,
    groupByName: string,
    groupedData: any,
  ) => {
    // Custom render option label in fund portal - investor relation category
    if (isFunds) {
      const replaceIdBy: any = {
        fundId: 'fundCode',
        unitClassId: 'unitClassCode',
        investorId: 'investorNumber',
      };
      return `${groupedData?.[replaceIdBy?.[groupByName]]} - ${oldName}`;
    }

    switch (watchInvestorRelationOrder) {
      case InvestorRelationOrderEnum.InvestmentEntity: {
        if (groupByName === 'investmentEntityId') {
          // Keep original investment entity name for investor portal - investor relation
          return oldName;
        }
        // Custom render unit class option label for investor portal - investor relation
        return `${groupedData?.fund} - ${groupedData?.unitClass} - ${groupedData?.investorNumber}`;
      }
      case InvestorRelationOrderEnum.Fund: {
        if (groupByName === 'fundId') {
          // Keep original fund name for investor portal - investor relation
          return oldName;
        }
        // Custom render investment entity option label for investor portal - investor relation
        return `${groupedData?.investorNumber} - ${oldName}`;
      }
      default:
        return oldName;
    }
  };

  const handleSelectRecipients = useCallback(
    (items: Item[], type?: RecipientType) => {
      switch (watchCategory) {
        case CommunicationCategoryEnum.KYCBO: {
          const kycboRecipients: IKycBoRecipientBody = getValues().kycboRecipients || {};
          if (type === KYC_BO_RECIPIENT.UBO) {
            kycboRecipients.uboIds = _.map(items, 'id');
          } else if (type === KYC_BO_RECIPIENT.PRIMARY_CONTACT) {
            const existingContactIds = getValues()?.kycboRecipients?.contactIds || [];
            kycboRecipients.contactIds = existingContactIds.concat(_.map(items, 'id'));
          } else {
            kycboRecipients.contactIds = _.map(items, 'id');
          }
          setValue('kycboRecipients', kycboRecipients, { shouldValidate: !isEmpty(items) });
          break;
        }
        case CommunicationCategoryEnum.ClientManagement:
        case CommunicationCategoryEnum.AccountAdministration: {
          const selectItems = _.chain(items)
            .groupBy('clientId')
            .map((groupItems, clientId) => {
              if (isFAUser && isFaPortal) {
                const fmRecipients: Item[] = [];
                const contactRecipients: Item[] = [];

                groupItems?.forEach((item) => {
                  if (item?.isFmRecipient) {
                    fmRecipients.push(item);
                  } else {
                    contactRecipients.push(item);
                  }
                });

                return {
                  clientId,
                  contactIds: _.map(contactRecipients, 'id'),
                  adminIds: _.map(fmRecipients, 'id'),
                };
              }
              return { clientId, adminIds: _.map(groupItems, 'id') };
            })
            .value();

          setValue('clientRecipients', selectItems, { shouldValidate: !isEmpty(items) });
          break;
        }
        case CommunicationCategoryEnum.Internal: {
          const internalRecipients: IInternalRecipientBody = { userIds: [] };
          internalRecipients.userIds = _.map(items, 'id');
          setValue('internalRecipients', internalRecipients, { shouldValidate: !isEmpty(items) });
          break;
        }
        case CommunicationCategoryEnum.InvestorRelation: {
          let selectItems: any = [];
          if (isFunds) {
            selectItems = _.chain(items)
              .groupBy('investorId')
              .map((groupItems, investorId) => ({
                investorId,
                contactIds: _.map(groupItems, 'id'),
              }))
              .value();
          }
          if (isInvestor) {
            items.forEach((item: any) => {
              const existingItemIndex = selectItems.findIndex(
                (f: any) =>
                  f.fundId === item?.fundId &&
                  f.investmentEntityId === item?.investmentEntityId &&
                  f.unitClassId === item?.unitClassId,
              );
              if (existingItemIndex === -1) {
                selectItems.push({ ...item, investorIds: [item.id] });
              } else {
                selectItems[existingItemIndex].investorIds = selectItems[
                  existingItemIndex
                ].investorIds.push(item.id);
              }
            });
          }
          setValue('investorRecipients', selectItems, { shouldValidate: !isEmpty(items) });
          break;
        }
      }
    },
    [watchCategory],
  );

  const categoriesOptions = useMemo(() => {
    return (categories || []).map((category: ICategoryInvestorItem) => ({
      value: Number(category.id),
      label: category.name,
    }));
  }, [categories]);

  const internalRecipients = useMemo(() => {
    return internalData?.map((item: IInternalRecipientDetail) => ({
      id: item.id,
      name: formatLableForShowing(item, CommunicationCategoryEnum.Internal),
      children: [],
    }));
  }, [internalData]);

  const clientRecipients = useMemo(() => {
    const getData = (data: any) => {
      if (_.isEmpty(data)) return [];
      return data?.map((item: any) => {
        let childrenData = [];
        if (isFAUser && isFaPortal) {
          const fmRecipients = (item?.recipients || [])?.map((it: any) => ({
            ...it,
            isFmRecipient: true,
          }));
          const contacts = [...(item?.contacts || []), ...fmRecipients];
          childrenData = item?.clientId
            ? contacts?.map((contact: any) => ({ ...contact, clientId: item.clientId }))
            : contacts;
        } else {
          childrenData = item?.clientId
            ? item?.recipients?.map((recipient: any) => ({ ...recipient, clientId: item.clientId }))
            : item?.recipients;
        }

        return {
          id: item.id || item.clientId,
          clientId: item.clientId,
          name: formatLableForShowing(item, CommunicationCategoryEnum.ClientManagement),
          children: getData(childrenData),
          ...(item?.isFmRecipient ? { isFmRecipient: item.isFmRecipient } : {}),
        };
      });
    };

    return getData(clientData);
  }, [clientData]);
  [];

  const getRecipientData = (results: any) => {
    if (_.isEmpty(results)) return [];
    return results.map((result: any) => ({
      ...result,
      id: result?.id,
      name: formatLableForShowing(result, CommunicationCategoryEnum.KYCBO),
      children: [],
    }));
  };

  const kycBoRecipients = useMemo(() => {
    const { primaryContact = [], communicationList = [], ubOs = [], team = [] } = kycBoData || {};
    const results = [
      {
        key: KYC_BO_RECIPIENT.UBO,
        label: 'UBOs',
        value: getRecipientData(ubOs),
      },
      {
        key: KYC_BO_RECIPIENT.COMMUNICATION_LIST,
        label: 'Communication List',
        value: getRecipientData(communicationList),
      },
      {
        key: KYC_BO_RECIPIENT.PRIMARY_CONTACT,
        label: 'Primary Contact',
        value: getRecipientData([primaryContact]),
      },
      {
        key: KYC_BO_RECIPIENT.TEAMS,
        label: 'Team',
        value: getRecipientData(team),
      },
    ];

    // if (
    //   !isEmpty(primaryContact) &&
    //   communicationList?.findIndex((f: IContact) => f.id === primaryContact?.id) === -1
    // ) {
    //   results.push({
    //     key: KYC_BO_RECIPIENT.PRIMARY_CONTACT,
    //     label: 'Primary Contact',
    //     value: getRecipientData([primaryContact]),
    //   });
    // }

    return results.sort((a, b) => a.key - b.key);
  }, [kycBoData]);

  const categoryInvestors = useMemo(() => {
    const keysToGroupBy = isFunds
      ? ['fundId', 'unitClassId', 'investorId']
      : watchInvestorRelationOrder === InvestorRelationOrderEnum.InvestmentEntity
      ? ['investmentEntityId', 'unitClassId']
      : ['fundId', 'investmentEntityId'];
    return createNestedStructure(
      categoryInvestorData,
      keysToGroupBy,
      customRenderCategoryInvestors,
    );
  }, [categoryInvestorData, watchInvestorRelationOrder]);

  const recipientsOptions = useMemo(() => {
    switch (watchCategory) {
      case CommunicationCategoryEnum.ClientManagement:
        return clientRecipients;
      case CommunicationCategoryEnum.Internal:
        return internalRecipients;
      case CommunicationCategoryEnum.InvestorRelation:
        return categoryInvestors;
      default:
        return clientRecipients || [];
    }
  }, [watchCategory, watchInvestorRelationOrder]);

  const selectedItems = useMemo(() => {
    const { category, clientRecipients, internalRecipients, investorRecipients, kycboRecipients } =
      messageDetail || {};
    switch (category) {
      case CommunicationCategoryEnum.KYCBO: {
        const { communicationList = [], primaryContact, ubOs = [] } = kycboRecipients || {};
        const items: any = [...ubOs, ...communicationList];
        if (
          !_.isEmpty(primaryContact) &&
          items?.findIndex((f: any) => f?.id === primaryContact?.id) === -1
        ) {
          items.push(primaryContact);
        }
        return items.map((item: any) => ({ ...item, itemForShow: true }));
      }
      case CommunicationCategoryEnum.ClientManagement:
      case CommunicationCategoryEnum.AccountAdministration: {
        let items: any = [];
        clientRecipients?.forEach((ele: any) => {
          items.push({ id: ele.clientId });
          items = items
            .concat(
              ele?.recipients?.map((recipient: any) => ({
                ...recipient,
                id: recipient.id,
                itemForShow: true,
              })),
            )
            .concat(
              (ele?.contacts || [])?.map((contact: any) => ({
                ...contact,
                id: contact.id,
                itemForShow: true,
              })),
            );
        });
        return items;
      }
      case CommunicationCategoryEnum.Internal:
        return internalRecipients?.recipients.map((item) => ({ ...item, itemForShow: true }));
      case CommunicationCategoryEnum.InvestorRelation: {
        let items: any = [];
        if (isFunds) {
          investorRecipients?.forEach((ele: any) => {
            let communications = ele?.communicationList || [];
            if (
              ele?.primaryContact &&
              communications?.findIndex((f: any) => f?.id === ele?.primaryContact?.id) === -1
            ) {
              communications = communications.concat(ele.primaryContact);
            }
            items.push({ id: ele.fundId });
            items.push({ id: ele.investorId });
            items.push({ id: ele.unitClassId });
            items = items.concat(
              communications.map((com: any) => ({ ...com, id: com.id, itemForShow: true })),
            );
          });
        } else if (isInvestor) {
          investorRecipients?.forEach((ele: any) => {
            items.push({ id: ele.fundId });
            items.push({ id: ele.investmentEntityId });
            items.push({ id: ele.unitClassId });
            items = items.concat(
              ele.investors.map((investor: any) => ({
                ...investor,
                id: investor.id,
                itemForShow: true,
              })),
            );
          });
        }
        return items;
      }
      default:
        return internalRecipients?.recipients || [];
    }
  }, [messageDetail]);

  /**
   * Recipients: default
   * Sender: Inbox or Replied
   *
   */
  const { title, isShowToExpanded } = useMemo(() => {
    let _title = 'Recipients',
      _isShowToExpanded = true;
    if (isExistingMessage && isInboxMode) {
      _title = `Sender: ${messageDetail?.sender}`;
      _isShowToExpanded = false;
    }

    if (isReplied) {
      _title = `Recipient: ${messageDetail?.sender}`;
      _isShowToExpanded = false;
    }

    if (isExistingMessage && isOutboxMode && isRecipientDisabled) {
      _title = `Recipients: ${selectedItems
        ?.filter((f: any) => f.itemForShow)
        .map((item: any) => formatLableForShowing(item, watchCategory))
        .join(', ')}`;
      _isShowToExpanded = false;
    }

    if (
      !isInboxMode &&
      messageDetail?.isCustomRecipient &&
      !_.isEmpty(messageDetail?.customRecipients)
    ) {
      _title = `Recipient: ${messageDetail.customRecipients.join(', ')}`;
      _isShowToExpanded = false;
    }

    return {
      title: _title,
      isShowToExpanded: _isShowToExpanded,
    };
  }, [messageDetail, watchCategory]);

  const categoryLabel = useMemo(() => {
    if (_.isNumber(watchCategory)) {
      return categoriesOptions.find((f) => f.value === watchCategory)?.label;
    }
    return 'Category';
  }, [watchCategory]);

  const renderTitle = (content: any, isOpen: boolean) => {
    return (
      <Box className='flex justify-between items-center w-full'>
        <Typography variant='body3' color='neutral.ne800'>
          {content}
        </Typography>
        {isShowToExpanded && (
          <Box>
            {isOpen ? (
              <ExpandMoreIcon
                sx={(theme) => ({
                  color: theme.palette.neutral.ne500,
                })}
              />
            ) : (
              <ExpandLessIcon
                sx={(theme) => ({
                  color: theme.palette.neutral.ne500,
                })}
              />
            )}
          </Box>
        )}
      </Box>
    );
  };

  const handleHasExternalMailChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      addExternalEmail({
        email: '',
      });
    } else {
      setValue('externalEmails', []);
    }
  };

  const handleAddExternalEmail = () => {
    addExternalEmail({
      email: '',
    });
  };

  const renderInvestmentEntities = () => {
    if (!messageDetail?.recipients?.length) return null;

    return (
      <Box className='mt-1 flex flex-col gap-2' component='ul'>
        {messageDetail?.recipients?.map((it, index) => (
          <Box key={index} color='neutral.ne800' component='li'>
            <Typography variant='body3' fontWeight={600}>
              {it?.fund}
            </Typography>
            <Box className='ml-3' component='ul'>
              {it?.investorItems?.map((investor, index) => (
                <Box key={index} color='neutral.ne800'>
                  <Typography variant='body3'>
                    {investor?.investorNumber} | {investor?.entityName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const editorFooter = useMemo(() => {
    if (!isExistingMessage) {
      return `<div><span>Kind Regards,</span><br/><span>${userProfile?.firstName} ${userProfile?.lastName}</span><br/><span>${userProfile?.email}</span><br/><span>${userProfile?.contactNumber}</span></div>`;
    }
    return '';
  }, [isExistingMessage]);

  return (
    <Box ref={containerRef}>
      <CustomAccordion
        expanded={expanded === PANELS.Category}
        onChange={isShowToExpanded ? handleChange(PANELS.Category) : undefined}
      >
        <CustomAccordionSummary>
          {renderTitle(categoryLabel, expanded === PANELS.Category)}
        </CustomAccordionSummary>
        <CustomAccordionDetails className='p-0 border-t'>
          <FormRadioGroup
            name='category'
            direction='column'
            options={categoriesOptions}
            className='px-[20px] py-[5px]'
            isEnumValue
            disabled={isCategoryDisabled}
          />
        </CustomAccordionDetails>
      </CustomAccordion>
      {watchCategory !== CommunicationCategoryEnum.SystemSupport && (
        <CustomAccordion
          expanded={
            expanded === PANELS.Recipient || (!isAdmin && isRecipientDisabled && isOutboxMode)
          }
          onChange={isShowToExpanded ? handleChange(PANELS.Recipient) : undefined}
        >
          <CustomAccordionSummary
            sx={{
              borderBottomColor: recipientsError ? 'error.main' : '',
            }}
          >
            {renderTitle(title, expanded === PANELS.Recipient)}
          </CustomAccordionSummary>
          <CustomAccordionDetails className='p-0 border-t'>
            <Box className='max-h-[300px] overflow-y-auto'>
              {isInvestor && watchCategory === CommunicationCategoryEnum.InvestorRelation && (
                <FormRadioGroup
                  className='px-2'
                  isEnumValue
                  name='investorRelationOrder'
                  options={InvestorRelationOrderOptions}
                  onChangeValue={() => {
                    multiLevelSelectRef.current?.clear();
                    setValue('investorRecipients', []);
                  }}
                  disabled={isRecipientDisabled}
                />
              )}
              {watchCategory === CommunicationCategoryEnum.KYCBO ? (
                kycBoRecipients.map((recipient, index) => {
                  return (
                    <Accordion
                      defaultExpanded
                      key={`KYCBORecipients-${index}`}
                      sx={() => ({
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        '::before': {
                          display: 'none',
                        },
                      })}
                    >
                      <AccordionSummaryAtom
                        sx={{
                          '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
                          '.MuiAccordionSummary-content': { my: 0 },
                          minHeight: '40px !important',
                          paddingX: '10px',
                        }}
                      >
                        <Typography variant='body2' fontWeight={700}>
                          {recipient.label}
                        </Typography>
                      </AccordionSummaryAtom>
                      <AccordionDetails className='p-6 pt-0 pb-0 mt-0'>
                        <MultiLevelSelect
                          disabled={isRecipientDisabled}
                          data={recipient?.value}
                          onSelect={(item) => handleSelectRecipients(item, recipient.key)}
                          selected={selectedItems}
                          hideUnselectedItems={!isAdmin && isRecipientDisabled && isOutboxMode}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <MultiLevelSelect
                  disabled={isRecipientDisabled}
                  data={recipientsOptions}
                  onSelect={handleSelectRecipients}
                  selected={selectedItems}
                  ref={multiLevelSelectRef}
                  selectAllChildren={
                    watchCategory === CommunicationCategoryEnum.InvestorRelation && isFunds
                  }
                  hideUnselectedItems={!isAdmin && isRecipientDisabled && isOutboxMode}
                />
              )}
            </Box>
            {recipientsError && (
              <Box p={1}>
                <CustomHelperText variant='error' message={recipientsError} />
              </Box>
            )}
          </CustomAccordionDetails>
        </CustomAccordion>
      )}
      {isInvestor && isInboxMode && renderInvestmentEntities()}
      {isFunds && !isInboxMode && (
        <Box className='pt-3'>
          <FormCheckbox
            name='hasExternalMail'
            label={<Typography variant='body3'>External Email</Typography>}
            onChange={handleHasExternalMailChange}
            disabled={isContentDisabled}
          />
          {!!externalEmails.length && (
            <Grid container spacing={2} pt={1} pl={4}>
              {externalEmails.map((it, index, arr) => (
                <Grid className='flex items-center gap-3' key={it.id} item xs={12}>
                  <Box className='w-1/2 flex items-center gap-3'>
                    <FormInput
                      name={`externalEmails.${index}.email`}
                      placeholder='Enter email'
                      label=''
                      sx={{
                        height: 36,
                      }}
                      disabled={isContentDisabled}
                    />
                  </Box>
                  {arr.length >= 2 && !isContentDisabled && (
                    <IconButton onClick={() => deleteExternalEmail(index)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <CustomButton
                  size='small'
                  startIcon={<PlusPrimaryIcon />}
                  variant='text'
                  sx={{ px: 1, py: 0, color: 'primary', height: 30 }}
                  onClick={handleAddExternalEmail}
                  disabled={isContentDisabled}
                >
                  Add
                </CustomButton>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Box pb='12px'>
        <FormInput
          onFocus={() => setExpanded(false)}
          disabled={isSubjectDisabled}
          name='subject'
          placeholder='Subject'
          sx={(theme) => ({
            '.MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: theme.palette.base.white,
            },
            '.MuiOutlinedInput-input': {
              paddingRight: 0,
              paddingLeft: 0.1,
              fontSize: '14px',
              '&::placeholder': {
                color: 'neutral.ne800',
                opacity: 1,
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderLeftColor: `${theme.palette.base.white} !important`,
              borderTopColor: `${theme.palette.base.white} !important`,
              borderRightColor: `${theme.palette.base.white} !important`,
              borderBottomColor: `${theme.palette.neutral.ne200} !important`,
              borderRadius: 0,
            },
          })}
        />
      </Box>
      <CustomEditor
        readOnly={isContentDisabled}
        initValue={watchContent}
        name='content'
        toolbar={false}
        statusbar={false}
        height={300}
        footer={editorFooter}
      />
      <Attachment attachments={attachments} />
      {isShowForwardOrReplyMessage && (
        <ForwaredOrRepliedContent
          title={
            isForward || (isNewMessage && !_.isEmpty(messageDetail?.forwardedMessage))
              ? 'Forwarded'
              : 'Replied'
          }
          messageDetail={
            isForward || (isNewMessage && !_.isEmpty(messageDetail?.forwardedMessage))
              ? messageDetail?.forwardedMessage
              : messageDetail?.repliedMessage
          }
          receiver={isNewMessage ? messageDetail?.sender : undefined}
        />
      )}
    </Box>
  );
};

export default MessageContent;

const formatLableForShowing = (item: any, type: number) => {
  switch (type) {
    case CommunicationCategoryEnum.ClientManagement:
    case CommunicationCategoryEnum.AccountAdministration:
      return (
        item?.clientName ||
        `${item?.name ? item.name : `${item?.firstName} ${item?.lastName}`} | ${item?.email} | ${
          item.position
        }`
      );
    case CommunicationCategoryEnum.Internal:
      return `${item?.firstName} ${item?.lastName} | ${item?.email} | ${item.role}`;
    case CommunicationCategoryEnum.InvestorRelation:
      return isFunds ? `${item?.firstName} ${item?.lastName}` : item?.name;
    case CommunicationCategoryEnum.KYCBO:
      return `${item?.firstName} ${item?.lastName} | ${item?.email}`;
    default:
      return '';
  }
};
