import cookie from 'js-cookie';
import { STORAGE_KEYS } from 'src/constants/storageKeys';

export const getToken = () => cookie.get(STORAGE_KEYS.ACCESS_TOKEN);

export const setToken = (token: string) => {
  cookie.set(STORAGE_KEYS.ACCESS_TOKEN, token);
};

export const destroy = () => {
  cookie.remove(STORAGE_KEYS.ACCESS_TOKEN);
  cookie.remove(STORAGE_KEYS.OVERARCHING_FILTER);
  cookie.remove(STORAGE_KEYS.REFRESH_TOKEN);
};

export const getVerifyToken = () => cookie.get(STORAGE_KEYS.VERIFY_TOKEN);

export const setVerifyToken = (token: string) => {
  cookie.set(STORAGE_KEYS.VERIFY_TOKEN, token);
};

export const destroyVerifyToken = () => {
  cookie.remove(STORAGE_KEYS.VERIFY_TOKEN);
};

export const getDefault2FAMethod = () => cookie.get(STORAGE_KEYS.DEFAULT_2FA_METHOD);

export const setDefault2FAMethod = (defaultMethod: string) => {
  cookie.set(STORAGE_KEYS.DEFAULT_2FA_METHOD, defaultMethod);
};

export const destroyDefault2FAMethod = () => {
  cookie.remove(STORAGE_KEYS.DEFAULT_2FA_METHOD);
};

export const getValue = (key: string) => cookie.get(key);

export const setValue = (key: string, value: string) => {
  cookie.set(key, value);
};

export const destroyValue = (key: string) => {
  cookie.remove(key);
};
