import { Box, IconButton, InputAdornment } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { EyeIcon } from 'src/assets/icons/EyeIcon';
import { EyeOffIcon } from 'src/assets/icons/EyeOffIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { SmallCircleIcon } from 'src/assets/icons/SmallCircleIcon';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import FormInput, { IFormInputProps } from './FormInput';

interface FormPasswordProps extends IFormInputProps {
  isShowRules?: boolean;
}

const PasswordRules = () => {
  const PASSWORD_RULES = [
    'Minimum of 8 characters in length',
    'Maximum of 16 characters in length',
    'Must have at least 1 capital letter, 1 number and at least one of following special characters: %, &, _ ,?, #, =, -, !, @',
  ];
  return (
    <Box className='w-[301px]' component='ul'>
      {PASSWORD_RULES.map((item: string, index: number) => (
        <Box key={index} className='flex' component='li'>
          <span className='mr-1'>
            <SmallCircleIcon width='16' height='16' />
          </span>
          <span>{item}</span>
        </Box>
      ))}
    </Box>
  );
};

const FormPassword: React.FC<FormPasswordProps> = ({ name, isShowRules = false, ...rest }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleToggleShowPassword = () => {
    setShowPassword((showPassword: boolean) => !showPassword);
  };

  const label = useMemo(() => {
    if (isShowRules) {
      return (
        <span className='flex items-center'>
          <span className='pr-1'>{rest.label}</span>
          <CustomTooltip
            title={<PasswordRules />}
            placement='right-end'
            componentsProps={{
              tooltip: {
                sx: {
                  ml: '8px !important',
                },
              },
            }}
          >
            <span className='cursor-pointer'>
              <InfoIcon />
            </span>
          </CustomTooltip>
        </span>
      );
    }
    return rest.label;
  }, [isShowRules, rest.label]);

  return (
    <FormInput
      {...rest}
      type={showPassword ? 'text' : 'password'}
      label={label}
      name={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={handleToggleShowPassword}
              sx={{ p: 0, width: 'auto', height: 'auto' }}
            >
              {showPassword ? <EyeIcon /> : <EyeOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FormPassword;
