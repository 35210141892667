/* eslint-disable autofix/no-unused-vars */
export const actionPermissionKeys = {
  isCreate: 'isCreate',
  isDelete: 'isDelete',
  isDownload: 'isDownload',
  isEdit: 'isEdit',
  isView: 'isView',
};

export const authType = {
  ggAuth: 'ggAuth',
  sms: 'sms',
};

export const authTypeValue = {
  ggAuth: '0',
  sms: '1',
};

export const AUTHENTICATION_TYPE = [
  { key: authType.ggAuth, label: 'Google Authenticator' },
  { key: authType.sms, label: 'SMS' },
];

export const userStatuses = {
  Active: 'Active',
  Pending: 'Pending',
  Disabled: 'Disabled',
  Draft: 'Draft',
};

export const actionTypes = {
  view: 'view',
  editUser: 'edit',
  disableUser: 'disableUser',
  disableContactOnBoardUser: 'disableContactOnBoardUser',
  enableUser: 'enableUser',
  resetPassword: 'resetPassword',
  resendEmail: 'resendEmail',
  reset2FA: 'reset2FA',
  delete: 'delete',
};

export const portalTypes = {
  ADMIN: 'admin',
  SPECIAL_ADMIN: 'saigontechdevelop',
  INVESTOR: 'investor',
};

export const DEFAULT_COUNTRY = 'AU';

export const actionMenus = [
  {
    key: actionTypes.view,
    label: 'View',
    permissionKey: actionPermissionKeys.isView,
  },
  {
    key: actionTypes.editUser,
    label: 'Edit',
    permissionKey: actionPermissionKeys.isEdit,
  },
  {
    key: actionTypes.disableUser,
    label: 'Disable',
  },
  {
    key: actionTypes.disableContactOnBoardUser,
    label: 'Disable',
  },
  {
    key: actionTypes.enableUser,
    label: 'Enable',
  },
  {
    key: actionTypes.resetPassword,
    label: 'Reset password',
  },
  {
    key: actionTypes.resendEmail,
    label: 'Resend email',
    permissionKey: actionPermissionKeys.isCreate,
  },
  {
    key: actionTypes.reset2FA,
    label: 'Reset 2FA',
  },
  {
    key: actionTypes.delete,
    label: 'Delete',
    permissionKey: actionPermissionKeys.isDelete,
  },
];

export const queryStringType = {
  login: 'login',
};

export const MAX_IMAGE_SIZE = 10240; // 10MB;
export const MAX_DOCUMENT_SIZE = 20480; // 20MB;

export const REQUIRE_MESSAGE = 'This field is required.';

export const OTHER_LABEL = 'Other';

export type ActionType =
  | 'create'
  | 'edit'
  | 'view'
  | 'duplicate'
  | 'cancel'
  | 'withdraw'
  | 'existing'
  | 'addTo'
  | 'startCase'
  | 'reAssign';

export enum YesNoOption {
  No,
  Yes,
}

export const YesNoRadioOptions = [
  {
    label: 'Yes',
    value: YesNoOption.Yes,
  },
  {
    label: 'No',
    value: YesNoOption.No,
  },
];

export const TrueFalseRadioOptions = [
  {
    label: 'Yes',
    value: true as any,
  },
  {
    label: 'No',
    value: false as any,
  },
];

export const TrueFalseRadioNoLabelOptions = [
  {
    label: '',
    value: true as any,
  },
  {
    label: '',
    value: false as any,
  },
];

export enum BRANDING_TYPE {
  Logo,
  Banner,
  Signature,
}

export enum DOCUMENT_TYPE {
  InformationMemorandum,
  ProductDisclosureStatement,
  TrustDeed,
  Constitution,
  Other,
}

export enum FileTypeEnum {
  png,
  jpeg,
  jpg,
  gif,
  docx,
  doc,
  pdf,
}

export const DocumentTypeOptions = [
  {
    value: DOCUMENT_TYPE.InformationMemorandum,
    label: 'Information Memorandum',
  },
  {
    value: DOCUMENT_TYPE.ProductDisclosureStatement,
    label: 'Product Disclosure Statement',
  },
  {
    value: DOCUMENT_TYPE.TrustDeed,
    label: 'Trust Deed',
  },
  {
    value: DOCUMENT_TYPE.Constitution,
    label: 'Constitution',
  },
  {
    value: DOCUMENT_TYPE.Other,
    label: 'Other',
  },
];

export const IMAGE_ACCEPT_TYPE = 'image/jpeg, image/png';
export const DOCUMENT_ACCEPT_TYPE = '.doc, .docx, .pdf';
export const PDF_ACCEPT_TYPE = '.pdf';

export enum FundUserType {
  FA,
  Client,
}

export enum InvestorSourceOfFunds {
  RetirementIncome,
  EmploymentIncome,
  BusinessActivities,
  SaleOfAssets,
  InheritanceGift,
  Savings,
  Other,
}

export enum DistributionInstructionType {
  PaymentToTheNominatedBankAccount,
  DistributionReinvestmentPlan,
}

export const DEFAULT_CURRENCY_NAME = 'AUD';

export const loqateApiEndpoint = 'https://hosted.mastersoftgroup.com/harmony/rest/v2/address';

export const COMMON_LINK = {
  TERMS_OF_USE: 'https://fundcorr.com/terms-of-use/',
  PRIVACY_POLICY: 'https://fundcorr.com/privacy-policy/',
};

export enum CLIENT_USER_ROLE {
  SUPER_ADMIN = 'Super Admin',
  VIEW_ONLY = 'View Only',
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  CONTACT_ONBOARDING = 'Contact Onboarding',
}

export enum FUND_PORTAL_TYPE {
  FA,
  FM,
}
export const FUM_RATE = 1000000;

export enum CAMERA_PERMISSION_STATUS {
  IDLE = 'idle',
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}
