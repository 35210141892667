import * as React from 'react';

interface IInvestorNewIconProps {}

const InvestorNewIcon: React.FunctionComponent<IInvestorNewIconProps> = (props) => {
  return (
    <svg
      width='44px'
      height='45px'
      viewBox='0 0 44 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Details_00'
          transform='translate(-523.000000, -495.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Content' transform='translate(220.000000, 182.000000)'>
                <g id='Type-1-Copy-2' transform='translate(0.000000, 22.000000)'>
                  <g id='broker' transform='translate(8.000000, 16.000000)'>
                    <g id='user' transform='translate(5.000000, 8.000000)'>
                      <path
                        d='M0.952812697,45 C0.652859713,45.0004379 0.370266164,44.8572256 0.190294186,44.61357 C0.0103222078,44.3699145 -0.0459516846,44.0543502 0.0384510712,43.762087 C0.326763296,42.7500112 0.623313012,41.7462996 0.911625236,40.7425881 C1.17202107,39.6047022 1.62944533,38.5228414 2.26257394,37.5474395 C3.41768944,35.9021704 5.15755881,34.7773964 7.11445681,34.4108409 L13.177251,33.2900296 L23.0622416,33.2900296 L29.1250358,34.4108409 C31.0819338,34.7773964 32.8218031,35.9021704 33.9769186,37.5474395 C34.6100472,38.5228414 35.0674715,39.6047022 35.3278673,40.7425881 C35.6161796,41.7462996 35.9127293,42.7500112 36.2010415,43.762087 C36.2854443,44.0543502 36.2291704,44.3699145 36.0491984,44.61357 C35.8692264,44.8572256 35.5866329,45.0004379 35.2866799,45 L0.952812697,45 Z'
                        id='Path'
                        fill='#3C6D91'
                        fillRule='nonzero'
                      />
                      <path
                        d='M6.57853309,17.3632949 C4.44421047,14.9043323 4.44421047,12.6164212 4.44421047,10.8037393 C4.579439,4.76137532 9.46861873,-0.0506663407 15.4207268,0.000402747097 C24.9154135,0.000402747097 31.8855013,4.50192196 31.8855013,11.7038883 C31.7777111,13.7730863 31.0207269,15.7529829 29.7252619,17.354007 L6.57853309,17.3632949 Z'
                        id='Path'
                        fill='#394042'
                        fillRule='nonzero'
                      />
                      <path
                        d='M28.0742109,17.0281577 L28.4782077,17.0281577 C29.1808126,17.0288701 29.8536918,17.3161074 30.34574,17.8253662 C31.1037361,18.5979838 31.3315477,19.7567912 30.9237836,20.7656954 C30.5160195,21.7745996 29.5521983,22.4368526 28.4782077,22.4460797 L7.85150408,22.4460797 C6.77751352,22.4368526 5.81369232,21.7745996 5.4059282,20.7656954 C4.99816409,19.7567912 5.22597573,18.5979838 5.98397179,17.8253662 C6.47601996,17.3161074 7.14889922,17.0288701 7.85150408,17.0281577 L28.0742109,17.0281577 Z'
                        id='Path'
                        fill='#FFCDBE'
                        fillRule='nonzero'
                      />
                      <path
                        d='M23.0622416,33.2900296 C23.0622416,33.2900296 21.9337051,35.7993085 18.1197463,35.7993085 C14.3057874,35.7993085 13.177251,33.2900296 13.177251,33.2900296 C14.1743057,32.7037832 14.7985968,31.6324946 14.8247494,30.4629087 L14.8247494,28.5809495 L21.4147431,28.5809495 L21.4147431,30.4629087 C21.4408958,31.6324946 22.0651868,32.7037832 23.0622416,33.2900296 Z'
                        id='Path'
                        fill='#FFCDBE'
                        fillRule='nonzero'
                      />
                      <path
                        d='M28.0742573,15.4027811 L28.0742573,20.1241131 C28.0742573,25.6811223 23.6376416,30.1859683 18.1648559,30.1859683 C12.6920702,30.1859683 8.25549863,25.6811223 8.25549863,20.1241131 L8.25549863,14.6837454 C8.25430668,13.8152164 8.73198919,13.0190796 9.49264573,12.6218391 C11.266199,11.7222634 12.7221379,10.2874468 13.6606729,8.51428022 C13.6606729,8.51428022 17.5268459,12.5003229 25.9757144,13.0862324 C27.1655114,13.1796356 28.0817621,14.1910927 28.0742573,15.4027811 Z'
                        id='Path'
                        fill='#FFDECF'
                        fillRule='nonzero'
                      />
                      <path
                        d='M8.23475574,41.6542927 L8.23475574,44.999998 L6.58725731,44.999998 L6.58725731,41.6542927 C6.58725731,41.1923472 6.9560624,40.8178664 7.41100652,40.8178664 C7.86595065,40.8178664 8.23475574,41.1923472 8.23475574,41.6542927 Z'
                        id='Path'
                        fill='#345E80'
                        fillRule='nonzero'
                      />
                      <ellipse
                        id='Oval'
                        fill='#FFB302'
                        fillRule='nonzero'
                        cx='34.0667519'
                        cy='34.9138821'
                        rx='9.93324812'
                        ry='10.086116'
                      />
                      <g id='plus-(1)' transform='translate(28.000000, 29.000000)' fill='#FFFFFF'>
                        <path
                          d='M10.5059538,4.50606458 L7.49448926,4.50606458 L7.49448926,1.49448926 C7.49448926,0.672475862 6.8220134,0 6,0 C5.17842967,0 4.50606458,0.672475862 4.50606458,1.49448926 L4.50606458,4.50606458 L1.49448926,4.50606458 C0.672475862,4.50606458 0,5.17842967 0,6 C0,6.8220134 0.672475862,7.49448926 1.49448926,7.49448926 L4.50606458,7.49448926 L4.50606458,10.5059538 C4.50606458,11.3275241 5.17842967,12 6,12 C6.8220134,12 7.49448926,11.3275241 7.49448926,10.5059538 L7.49448926,7.49448926 L10.5059538,7.49448926 C11.3275241,7.49448926 12,6.8220134 12,6 C12,5.17842967 11.3275241,4.50606458 10.5059538,4.50606458'
                          id='Path'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InvestorNewIcon;
