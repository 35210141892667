import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import OvalDownloadIcon from 'src/assets/icons/OvalDownloadIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import DownloadLocalButton from 'src/components/atoms/DownloadLocalButton';
import { CustomTextField } from 'src/components/atoms/FormInput';
import UploadButton from 'src/components/atoms/UploadButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import {
  ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
  MAX_ADDITIONAL_DOCUMENT_UPLOAD,
} from 'src/constants/applications';
import { IAddtionalDocument } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useUploadDocument } from 'src/modules/common/hooks';
import { IAlertInfo } from 'src/modules/common/type';
import {
  useDeleteInvestmentEntityDocument,
  useDownloadInvestmentEntityDocument,
  useGetInvestmentEntityById,
} from 'src/modules/investment-entity/hooks';
import {
  IInvestmentEntityDocument,
  IInvestmentEntityDocumentsForm,
  IUpsertInvestmentEntityForm,
} from 'src/modules/investment-entity/types';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import { INVESTMENT_ENTITY_STEP } from './InvestmentEntityForm';

interface IDocumentsProps extends IUpsertInvestmentEntityForm {
  investorType?: number | null;
  investmentAmount?: number;
  investmentEntityId?: string;
}

interface ITableData extends IInvestmentEntityDocument {
  key: string;
  localUrl?: string;
}

const Documents: FC<IDocumentsProps> = ({ id = '', isViewMode, currentStep }) => {
  const alertRef = useRef<IBasicModalElement>(null);
  const confirmModalRef = useRef<IConfirmModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [description, setDescription] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<ITableData>();

  const { mutate: uploadDocument, isLoading: isUploadingDocument } = useUploadDocument();
  const { data: currentUser } = useGetUserInfo();
  const { mutate: deleteDocumentMutate, isLoading: isDeleteDocumentLoading } =
    useDeleteInvestmentEntityDocument();
  const { data: investmentEntityData } = useGetInvestmentEntityById(
    id,
    { step: INVESTMENT_ENTITY_STEP.DOCUMENTS },
    currentStep === INVESTMENT_ENTITY_STEP.DOCUMENTS,
  );
  const { mutate: downloadDocument } = useDownloadInvestmentEntityDocument();

  const { control, reset } = useFormContext<IInvestmentEntityDocumentsForm>();

  const {
    fields: documents,
    append: addDocument,
    remove: removeDocument,
  } = useFieldArray({
    control,
    name: 'documents',
    keyName: 'key',
  });

  useEffect(() => {
    handleSetDocuments();
  }, [investmentEntityData?.documents]);

  const handleSetDocuments = () => {
    if (isEmpty(investmentEntityData?.documents)) return;

    reset({
      documents: investmentEntityData?.documents || [],
    });
  };

  const handleAddDocument = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    uploadDocument(file, {
      onSuccess: (data: IAddtionalDocument) => {
        const newData = {
          ...data,
          uploadedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
          uploadedDate: dayjs().toString(),
          description: description,
          isAddNew: true,
          localUrl: URL.createObjectURL(file),
        };

        handleShowAlertModal({
          title: 'You did it !',
          description: 'File uploaded successfully.',
          isError: false,
        });
        addDocument(newData);
        setDescription('');
      },
      onError: handleErrorFromServer,
    });
  };

  const handleShowAlertModal = (alertInfo: IAlertInfo) => {
    setAlertInfo(alertInfo);
    alertRef.current?.open();
  };

  const handleDeleteDocument = () => {
    const documentIdx = documents.findIndex((it) => it.key === selectedDocument?.key);
    if (selectedDocument?.id) {
      deleteDocumentMutate(
        { id, documentId: selectedDocument.id },
        {
          onSuccess: () => {
            removeDocument(documentIdx);
          },
          onError: handleErrorFromServer,
          onSettled: () => confirmModalRef.current?.close(),
        },
      );
    } else {
      removeDocument(documentIdx);
      confirmModalRef.current?.close();
    }
  };

  const handleDownloadDocument = (row: ITableData) => {
    downloadDocument(
      {
        appId: id,
        document: { documentId: row.id as string, type: row.documentType, fileName: row.fileName },
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };
  const renderDownloadAction = (row: ITableData) => {
    if (row.localUrl) {
      return (
        <DownloadLocalButton downloadUrl={row.localUrl} fileName={row.fileName}>
          <OvalDownloadIcon />
        </DownloadLocalButton>
      );
    }
    return (
      <IconButton onClick={() => handleDownloadDocument(row)}>
        <OvalDownloadIcon />
      </IconButton>
    );
  };

  const fileColumns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable className='line-clamp-1' value={row.description || ''} />
      ),
    },
    {
      title: 'File Name',
      key: 'fileName',
      sx: { width: '18%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(row.fileType);
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='pl-2 line-clamp-1' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File Size',
      key: 'fileSize',
      sx: { width: '18%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Date Uploaded',
      key: 'dateUploaded',
      sx: { width: '21%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={String(utcToLocalTimezone(row.uploadedDate || ''))} />
      ),
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      sx: { width: '23%' },
      renderNode: (row: ITableData) => (
        <Box className='flex items-center justify-between'>
          <StringNodeTable value={String(row.uploadedBy || '')} />
          {!isViewMode && !row.isExisting && (
            <IconButton
              onClick={() => {
                setSelectedDocument(row);
                confirmModalRef.current?.open();
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {renderDownloadAction(row)}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box className='mx-[-24px] pt-6'>
        <Box className='flex items-end justify-between'>
          <Box className='flex flex-col' width='76%'>
            <Typography variant='body3' pb='6px'>
              Description
            </Typography>
            <CustomTextField
              fullWidth
              placeholder='Enter description'
              value={description}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              disabled={isViewMode}
            />
          </Box>
          <Box width='22%'>
            <UploadButton
              startIcon={<UploadFilesIcon />}
              label={'Upload Files'}
              inputProps={{
                accept: ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
              }}
              disabled={isViewMode || !description || isUploadingDocument}
              handleFileChange={handleAddDocument}
              validate={{
                fileSize: MAX_ADDITIONAL_DOCUMENT_UPLOAD,
                onError: () =>
                  handleShowAlertModal({
                    title: '',
                    isError: true,
                    description: `Selected file exceeds the maximum allowed size of 20 MB.<br />Please choose a different file or reduce its size.`,
                  }),
              }}
              sx={{
                px: 2,
                width: '100%',
              }}
            />
          </Box>
        </Box>
        <Box className='pt-10'>
          <Typography className='pb-2' variant='body2' fontWeight='medium'>
            Uploaded Files
          </Typography>
          <CustomTableContainer>
            <CustomTable
              rows={documents}
              columns={fileColumns}
              hasPagination={false}
              stickyHeader
              displayEmpty
              hiddenHorizontalRule
              customEmpty={
                <EmptyData
                  isTable
                  description='No document.'
                  icon={<DocumentOnePageIcon width={22} height={27} />}
                  marginY='34px'
                />
              }
            />
          </CustomTableContainer>
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          isError={alertInfo?.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
      <ConfirmModal
        ref={confirmModalRef}
        title='Delete Document?'
        content={`This action might impact any ongoing application.<br/> Are you sure you want to continue?`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => confirmModalRef?.current?.close()}
              disabled={isDeleteDocumentLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant='contained'
              disabled={isDeleteDocumentLoading}
              onClick={handleDeleteDocument}
            >
              Confirm
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default Documents;
