import { COMMUNICATION_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { ICommunicationQueryBody, IUpsertCommunicationBody } from './type';

export const getCommunicationInboxes = async (data: ICommunicationQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.getCommunicationInboxes.api,
  });

  return result.data;
};

export const getCommunicationOutboxes = async (data: ICommunicationQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.getCommunicationOutboxes.api,
  });

  return result.data;
};

export const getCommunicationById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.communicationById.api(id),
  });

  return result.data;
};

export const getCommunicationMasterData = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.getCommunicationMasterData.api,
  });

  return result.data;
};

export const getCommunicationCategoryClient = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.getCommunicationCategoryClient.api,
  });

  return result.data;
};

export const getCommunicationCategoryInternal = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.getCommunicationCategoryInternal.api,
  });

  return result.data;
};

export const updateCommunicationMessage = async (data: IUpsertCommunicationBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: COMMUNICATION_API.communicationMessage.api,
  });

  return result.data;
};

export const createCommunicationMessage = async (data: IUpsertCommunicationBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.communicationMessage.api,
  });

  return result.data;
};

export const deleteCommunicationMessage = async (data: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    data,
    url: COMMUNICATION_API.communicationMessage.api,
  });

  return result.data;
};

export const communicationRead = async (data: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.communicationRead.api,
  });

  return result.data;
};

export const communicationUnread = async (data: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.communicationUnread.api,
  });

  return result.data;
};

export const communicationPriority = async (data: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.communicationPriority.api,
  });

  return result.data;
};

export const communicationArchive = async (data: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: COMMUNICATION_API.communicationArchive.api,
  });

  return result.data;
};

export const communicationFileUpload = async (file: File) => {
  const data = new FormData();
  data.append('file', file);
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: COMMUNICATION_API.communicationFileUpload.api,
  });

  return result.data;
};

export const communicationFileDownload = async (path: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { path },
    url: COMMUNICATION_API.communicationFileDownload.api,
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const getCommunicationReplyMessage = async (id: string, userMessageId?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { userMessageId },
    url: COMMUNICATION_API.communicationReply.api(id),
  });

  return result.data;
};

export const getCommunicationForwardMessage = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.communicationForward.api(id),
  });

  return result.data;
};

export const getCommunicationCategoryInvestor = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.getCommunicationCategoryInvestor.api,
  });

  return result.data;
};

export const getCommunicationCategoryKycBo = async (kycBoId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.communicationCategoryKycBo.api(kycBoId),
  });

  return result.data;
};

export const getCommunicationInitKycBo = async (kycBoId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: COMMUNICATION_API.communicationInitKycBo.api(kycBoId),
  });

  return result.data;
};

export const downloadMessageAttachments = async (messageId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    responseType: 'arraybuffer',
    url: COMMUNICATION_API.downloadMessageAttachments.api(messageId),
  });

  return result.data;
};

export const getInboxMessageDetailById = async (id: string, userMessageId?: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { userMessageId },
    url: COMMUNICATION_API.getInboxMessageDetailById.api(id),
  });

  return result.data;
};
