import { Box, IconButton, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useRef, useState } from 'react';
import { ArchiveIcon } from 'src/assets/icons/ArchiveIcon';
import CSVIcon from 'src/assets/icons/CSVIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import SearchField from 'src/components/atoms/SearchField';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { bulkUploadStatusOptions } from 'src/constants/bulk-upload';
import { useGetBulkUploadList } from 'src/modules/bulk-upload/hooks';
import { IBulkUploadItem } from 'src/modules/bulk-upload/type';
import { utcToLocalTimezone } from 'src/utils/time';
import BulkUploadForm from './components/BulkUploadForm';
import ViewResult from './components/ViewResult';

interface IBulkUploadProps {}

const FILTER_BY = {
  status: 'Status',
};

const BulkUpload: FC<IBulkUploadProps> = () => {
  const [selectedRow, setSelectedRow] = useState<IBulkUploadItem | undefined>(undefined);
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const isOneOfFiltersEmpty = Boolean(emptyFilters?.length);
  const viewResultModalRef = useRef<IBasicModalElement>(null);
  const uploadModalRef = useRef<IBasicModalElement>(null);

  const {
    data: { items: bulkUploadList = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    setParams,
  } = useGetBulkUploadList();

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: (status as string[]).map((item: string) => Number(item)) });
    }
    handleSetEmptyFilters(status, FILTER_BY.status);
  };

  const handleSetEmptyFilters = (values: string | string[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const handleOpenViewResultModal = (isOpen?: boolean, row?: IBulkUploadItem) => {
    if (typeof isOpen !== 'boolean') {
      viewResultModalRef.current?.open();
      return;
    }

    isOpen ? viewResultModalRef.current?.open() : viewResultModalRef.current?.close();
    setSelectedRow(row);
  };

  const handleOpenUploadModal = (isOpen?: boolean) => {
    if (typeof isOpen !== 'boolean') {
      uploadModalRef.current?.open();
      return;
    }

    isOpen ? uploadModalRef.current?.open() : uploadModalRef.current?.close();
  };

  const columns: ColumnProps<IBulkUploadItem, 'action'>[] = [
    {
      title: 'File Name',
      key: 'fileName',
      sorter: true,
      sortBy: 'fileName',
      sx: { minWidth: 180, width: '26%' },

      renderNode: (row) => (
        <Box className='flex items-center gap-2'>
          <CSVIcon />
          <StringNodeTable value={row?.fileName || ''} />
        </Box>
      ),
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      sorter: true,
      sortBy: 'uploadedBy',
      sx: { minWidth: 180, width: '19%' },
      renderNode: (row) => <StringNodeTable value={row?.uploadedBy || ''} />,
    },
    {
      title: 'Uploaded Date',
      key: 'uploadedOn',
      sorter: true,
      sortBy: 'uploadedOn',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => <StringNodeTable value={utcToLocalTimezone(row?.uploadedOn || '')} />,
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      sortBy: 'status',
      sx: { minWidth: 110, width: '14%' },
      renderNode: (row) => {
        return <StatusBadge status={row?.statusName || ''} />;
      },
      isSticky: true,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { minWidth: 90, width: 90, textAlign: 'center' },
      cellSx: () => ({
        textAlign: 'center',
      }),
      isSticky: true,
      renderNode: (row) => (
        <IconButton
          sx={{ p: 0, bgcolor: 'neutral.ne200', width: 24, height: 24 }}
          onClick={() => handleOpenViewResultModal(true, row)}
        >
          <EyeViewIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box>
        <Box className='flex items-center justify-between'>
          <Typography variant='h5' fontWeight={700}>
            Bulk Upload
          </Typography>
          <CustomButton
            startIcon={<ArchiveIcon color='#FFFFFF' width={24} height={24} />}
            className='h-12 flex items-center justify-between px-5'
            onClick={() => handleOpenUploadModal()}
          >
            <Typography className='text-white font-medium' variant='body2'>
              Bulk Upload
            </Typography>
          </CustomButton>
        </Box>

        <Box className='flex items-center gap-4 mt-6'>
          <Box width='33%'>
            <SearchField placeholder='Search by File Name' onChange={handleSearch} />
          </Box>
          <CustomMultiSelect
            options={bulkUploadStatusOptions.sort((a, b) => {
              return a.label < b.label ? -1 : a.label === b.label ? 0 : 1;
            })}
            customRenderLabel={customRenderStatusLabel}
            onChange={handleStatusChange}
            label='Status'
            showClearAllIcon
          />
        </Box>
        <Box className='mt-10'>
          {(!isOneOfFiltersEmpty && bulkUploadList?.length) || isLoading ? (
            <CustomTable
              columns={columns}
              rows={bulkUploadList}
              isFetchingData={isLoading}
              totalResults={totalItem}
              currentPage={page}
              numberItemOnPage={pageSize}
              onSort={handleSort}
              onChangePagination={handleChangePage}
              sx={{
                '.MuiTableCell-head': {
                  py: '14px',
                  borderColor: 'neutral.ne200',
                },
                '.MuiTableCell-body': {
                  borderColor: 'neutral.ne200',
                },
                borderColor: 'neutral.ne200',
              }}
            />
          ) : (
            <EmptyData />
          )}
        </Box>
      </Box>
      <BasicModal
        ref={viewResultModalRef}
        PaperProps={{
          style: {
            width: 1120,
            top: 69,
          },
        }}
        maxWidth='xl'
      >
        <ViewResult onClose={() => handleOpenViewResultModal(false)} id={selectedRow?.id} />
      </BasicModal>
      <BasicModal
        ref={uploadModalRef}
        PaperProps={{
          style: {
            top: '50%',
            width: 738,
            transform: 'translateY(-50%)',
          },
        }}
        maxWidth='xl'
      >
        <BulkUploadForm onClose={() => handleOpenUploadModal(false)} />
      </BasicModal>
    </>
  );
};

export default BulkUpload;
