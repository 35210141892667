import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { InvestorUserRoleOptions } from 'src/components/pages/applications/InvestorApplications';
import { INVESTMENT_ENTITY_ROLE } from 'src/constants/applications';
import { REQUIRE_MESSAGE } from 'src/constants/common';
import { FUND_ROLE_TYPE } from 'src/constants/role-management';
import {
  useAcceptDuplicatedEntity,
  useGetDuplicatedEntity,
  useRejectDuplicatedEntity,
} from 'src/modules/applications/hooks';
import { IDuplicatedEntity } from 'src/modules/applications/type';
import { useGetUserRoles } from 'src/modules/users-management/hooks';
import { Role } from 'src/modules/users-management/type';
import { handleErrorFromServer } from 'src/utils/common';
import * as yup from 'yup';

interface IDuplicateEntity {
  appId: string;
  onClose: () => void;
}

const DuplicateEntity = ({ appId, onClose }: IDuplicateEntity) => {
  const form = useForm<IDuplicatedEntity>({
    resolver: yupResolver(
      yup.object({
        editorDetails: yup.object({
          roleId: yup.string().required(REQUIRE_MESSAGE),
        }),
      }),
    ),
  });
  const userType = form.watch('editorDetails.userType');

  const { data: duplicatedEntity } = useGetDuplicatedEntity(appId);
  const { data: userRolesData = [] } = useGetUserRoles(FUND_ROLE_TYPE.FundClient.id);

  const { mutate: acceptDuplicatedEntity, isLoading: isAcceptingDuplicatedEntity } =
    useAcceptDuplicatedEntity();
  const { mutate: rejectDuplicatedEntity, isLoading: isRejectingDuplicatedEntity } =
    useRejectDuplicatedEntity();

  const userRoleOptions = userRolesData?.map((item: Role) => ({
    label: item.name,
    value: item?.id,
  }));

  useEffect(() => {
    if (!isEmpty(duplicatedEntity)) {
      form.reset({
        ...duplicatedEntity,
        editorDetails: {
          ...duplicatedEntity.editorDetails,
          position: '',
        },
      });
    }
  }, [duplicatedEntity]);

  useEffect(() => {
    if (userType === INVESTMENT_ENTITY_ROLE.Investor && userRolesData) {
      const superAdminRole = userRolesData.find((it: Role) => it.isSuperAdmin);
      if (superAdminRole) {
        form.setValue('editorDetails.roleId', superAdminRole.id);
      }
    } else {
      form.setValue('editorDetails.roleId', '');
    }
  }, [userType, userRolesData]);

  const onSubmit = (data: IDuplicatedEntity) => {
    acceptDuplicatedEntity(
      { appId, data: { ...data.editorDetails, sendMail: true, holdingIds: [], entityIds: [] } },
      { onSuccess: onClose, onError: handleErrorFromServer },
    );
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        className='w-[750px] flex flex-col p-10'
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Box className='relative pb-4 border-b-2 border-solid' borderColor='neutral.ne200'>
          <Typography variant='h5'>Add User</Typography>
          <Box className='absolute right-0 top-0'>
            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Grid container spacing={3} pt={3}>
          <Grid item xs={6}>
            <FormInput name='editorDetails.firstName' label='First Name' disabled />
          </Grid>
          <Grid item xs={6}>
            <FormInput name='editorDetails.lastName' label='Last Name' disabled />
          </Grid>
          <Grid item xs={6}>
            <FormInput name='editorDetails.email' label='Email Address' disabled />
          </Grid>
          <Grid item xs={6}>
            <FormPhoneCountryInput
              name='editorDetails.contactNumber'
              countryFlagCodeName='editorDetails.countryFlagCode'
              label='Contact Number'
              size='small'
              disabled
              isIncludeCode
            />
          </Grid>
          <Grid item xs={12}>
            <FormRadioGroup
              name='editorDetails.userType'
              options={Object.values(InvestorUserRoleOptions)}
              label='User Type'
              isEnumValue
              optionGap={4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput name='entityName' label='Investment Entity' disabled />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name='editorDetails.position'
              label='Position'
              optional='Optional'
              placeholder='Enter position'
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name='editorDetails.roleId'
              label='Role'
              placeholder='Select role'
              options={userRoleOptions}
              disabled={userType === INVESTMENT_ENTITY_ROLE.Investor}
            />
          </Grid>
        </Grid>
        <Box pt={4} display='flex' justifyContent='flex-end'>
          <CustomButton
            sx={{ color: 'neutral.ne800', mr: 2 }}
            variant='text'
            onClick={onClose}
            disabled={isAcceptingDuplicatedEntity}
            isLoading={isRejectingDuplicatedEntity}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type='submit'
            isLoading={isAcceptingDuplicatedEntity}
            disabled={isRejectingDuplicatedEntity}
          >
            Save
          </CustomButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default DuplicateEntity;
