import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import {
  IDirector,
  IFinancialTaxDetailsForm,
  ITaxNoGIINReason,
} from 'src/modules/applications/type';
import { ITaxDetailsProps } from '..';
import Declaration from './Declaration';
import EntityCountryOfTaxResidency from './EntityCountryOfTaxResidency';
import ForeignControllingPersons from './ForeignControllingPersons';
import TaxInformationLayout from './TaxInformationLayout';
import TaxStatus from './TaxStatus';

export interface ITaxInformationSectionProps extends ITaxDetailsProps {
  directors?: IDirector[];
  foreignControllingPersons?: IDirector[];
  taxNoTINReasons?: ITaxNoGIINReason[];
  isAnyIndividualContacts?: boolean;
  isForeignTaxResident?: boolean;
  allowForeignInvestors?: boolean;
  allowExpanded?: boolean;
}

interface IAccordionSection {
  title: string;
  content: React.ReactNode;
  key?: string | number;
}

enum ACCORDION_KEY {
  TAX_STATUS,
  CONTROLLING_PERSONS,
  TAX_RESIDENCY,
  DECLARATION,
}

const TaxInformationSection: FC<ITaxInformationSectionProps> = (props) => {
  const {
    directors,
    taxNoTINReasons,
    isAnyIndividualContacts,
    isForeignTaxResident,
    allowForeignInvestors,
    allowExpanded,
    isInvestmentEntitySetting,
    isExistingInvestmentEntity,
  } = props;
  const [sectionsToRender, setSectionsToRender] = useState<ACCORDION_KEY[]>([]);
  const { watch, setValue } = useFormContext<IFinancialTaxDetailsForm>();
  const watchTaxResidentOtherCountryThanAustralia = watch('isTaxResidentOtherCountryThanAustralia');

  useEffect(() => {
    if (isInvestmentEntitySetting || isExistingInvestmentEntity) return;
    handleSetSectionsToRender();
  }, [
    isAnyIndividualContacts,
    isForeignTaxResident,
    watchTaxResidentOtherCountryThanAustralia,
    allowForeignInvestors,
  ]);

  useEffect(() => {
    handleCheckRenderedSectionForInvestmentEntityMode();
  }, [isInvestmentEntitySetting, watchTaxResidentOtherCountryThanAustralia]);

  const handleSetSectionsToRender = () => {
    if (
      allowForeignInvestors === false &&
      isForeignTaxResident === false &&
      watchTaxResidentOtherCountryThanAustralia === false
    ) {
      setSectionsToRender(AccordionSections.filter((it) => it === ACCORDION_KEY.DECLARATION));
      setValue('isRequiredDeclarationOnly', true);
    } else if (
      allowForeignInvestors === false &&
      isForeignTaxResident === false &&
      isAnyIndividualContacts === false
    ) {
      setValue('isTaxResidentOtherCountryThanAustralia', false);
      setSectionsToRender(AccordionSections.filter((it) => it === ACCORDION_KEY.DECLARATION));
      setValue('isRequiredDeclarationOnly', true);
    } else if (allowForeignInvestors) {
      if (isForeignTaxResident) {
        setValue('isTaxResidentOtherCountryThanAustralia', true);
        setSectionsToRender(AccordionSections);
        setValue('isRequiredDeclarationOnly', false);
      }
      if (isForeignTaxResident === false && watchTaxResidentOtherCountryThanAustralia) {
        setSectionsToRender(AccordionSections);
        setValue('isRequiredDeclarationOnly', false);
      }
      if (isForeignTaxResident === false && watchTaxResidentOtherCountryThanAustralia === false) {
        setSectionsToRender(AccordionSections.filter((it) => it === ACCORDION_KEY.DECLARATION));
        setValue('isRequiredDeclarationOnly', true);
      }
    }
  };

  const handleCheckRenderedSectionForInvestmentEntityMode = () => {
    if (
      (isInvestmentEntitySetting || isExistingInvestmentEntity) &&
      watchTaxResidentOtherCountryThanAustralia
    ) {
      setSectionsToRender(AccordionSections);
    } else if (!watchTaxResidentOtherCountryThanAustralia && isInvestmentEntitySetting) {
      setSectionsToRender([]);
    } else if (!watchTaxResidentOtherCountryThanAustralia && isExistingInvestmentEntity) {
      setSectionsToRender(AccordionSections.filter((it) => it === ACCORDION_KEY.DECLARATION));
      setValue('isRequiredDeclarationOnly', true);
    }
  };

  const AccordionSections = [
    ACCORDION_KEY.TAX_STATUS,
    ACCORDION_KEY.CONTROLLING_PERSONS,
    ACCORDION_KEY.TAX_RESIDENCY,
    ACCORDION_KEY.DECLARATION,
  ];

  return (
    <>
      {!isEmpty(sectionsToRender) && (
        <Box>
          <Typography mb={1} variant='body2' fontWeight={500} className='tax-information-title'>
            Tax Information
          </Typography>
          <Box
            bgcolor='white'
            className='border rounded-xl tax-information-container gap-4 flex flex-col'
            borderColor='neutral.ne300'
            p={3}
          >
            {sectionsToRender.includes(ACCORDION_KEY.TAX_STATUS) && (
              <TaxInformationLayout title='Tax Status' allowExpanded={allowExpanded}>
                <TaxStatus {...props} />
              </TaxInformationLayout>
            )}
            {sectionsToRender.includes(ACCORDION_KEY.CONTROLLING_PERSONS) && (
              <TaxInformationLayout
                title='Foreign Controlling Persons'
                allowExpanded={allowExpanded}
              >
                <ForeignControllingPersons {...props} />
              </TaxInformationLayout>
            )}
            {sectionsToRender.includes(ACCORDION_KEY.TAX_RESIDENCY) && (
              <TaxInformationLayout
                title='Entity Country of Tax Residency'
                allowExpanded={allowExpanded}
              >
                <EntityCountryOfTaxResidency {...props} taxNoTINReasons={taxNoTINReasons} />
              </TaxInformationLayout>
            )}
            {sectionsToRender.includes(ACCORDION_KEY.DECLARATION) && !isInvestmentEntitySetting && (
              <TaxInformationLayout title='Declaration' allowExpanded={allowExpanded}>
                <Declaration {...props} directors={directors} />
              </TaxInformationLayout>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TaxInformationSection;
