import { FC } from 'react';
import PartnershipDetailsForm from 'src/components/pages/applications/components/InvestmentEntity/Partnership/PartnershipDetailsForm';

interface IPartnershipProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const Partnership: FC<IPartnershipProps> = (props) => {
  return <PartnershipDetailsForm {...props} />;
};

export default Partnership;
