import { formatValueWithSpaces } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

export enum ADMIN_DASHBOARD_ACTION_TYPE {
  ReviewEntity,
  RemoteAccess,
}

export const adminDashboardActionLabel = {
  [ADMIN_DASHBOARD_ACTION_TYPE.ReviewEntity]: 'Duplicate Entity',
  [ADMIN_DASHBOARD_ACTION_TYPE.RemoteAccess]: 'Client Onboarding',
};

export const australianCompanyReviewInfo = [
  {
    label: 'Company name',
    key: 'companyName',
  },
  {
    label: 'ACN',
    key: 'acn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'ABN',
    key: 'abn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'Registered Address',
    key: 'registeredAddress',
  },
  {
    label: 'Principal Place of Business',
    key: 'principalPlaceOfBusiness',
  },
  {
    label: 'Directors',
    key: 'directors',
    transform: (array: any) =>
      array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
  },
];

export const foreignCompanyReviewInfo = [
  {
    label: 'Company name',
    key: 'companyName',
  },
  {
    label: 'Company Type',
    key: 'companyType',
  },
  {
    label: 'Foreign Company Identification Number',
    key: 'foreignEIN',
  },
  {
    label: 'Name of Relevant Foreign Registration Body',
    key: 'nameOfRelevantForeignRegistrationBody',
  },
  {
    label: 'Country of Establishment',
    key: 'countryOfEstablishment',
  },
  {
    label: 'Country of Tax Residence',
    key: 'countryOfTaxResident',
  },
  {
    label: 'Registered Address',
    key: 'registeredAddress',
  },
  {
    label: 'Principal Place of Business',
    key: 'principalPlaceOfBusiness',
  },

  {
    label: 'Directors',
    key: 'directors',
    transform: (array: any) =>
      array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
  },
];

export const companyReviewInfo = {
  australianCompany: australianCompanyReviewInfo,
  foreignCompany: foreignCompanyReviewInfo,
};

export const smsfReviewInfo = {
  smsf: {
    title: 'Entity Details',
    data: [
      {
        label: 'Superannuation Fund Name',
        key: 'name',
      },
      {
        label: 'ABN',
        key: 'abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Office',
        key: 'registeredOffice',
      },
    ],
  },
  'smsf.trusteeAustralianCompanyDetails': {
    title: 'Corporate Trustee',
    data: [
      {
        label: 'Company name',
        key: 'trusteeAustralianCompanyDetails.companyName',
      },
      {
        label: 'ACN',
        key: 'trusteeAustralianCompanyDetails.acn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'ABN',
        key: 'trusteeAustralianCompanyDetails.abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Address',
        key: 'trusteeAustralianCompanyDetails.registeredAddress',
      },
      {
        label: 'Principal Place of Business',
        key: 'trusteeAustralianCompanyDetails.principalPlaceOfBusiness',
      },
      {
        label: 'Directors',
        key: 'trusteeAustralianCompanyDetails.directors',
        transform: (array: any) =>
          array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
      },
    ],
  },
  'smsf.trusteeForeignCompanyDetails': {
    title: 'Corporate Trustee',
    data: [
      {
        label: 'Company name',
        key: 'trusteeForeignCompanyDetails.companyName',
      },
      {
        label: 'ACN',
        key: 'trusteeForeignCompanyDetails.acn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'ABN',
        key: 'trusteeForeignCompanyDetails.abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Address',
        key: 'trusteeForeignCompanyDetails.registeredAddress',
      },
      {
        label: 'Principal Place of Business',
        key: 'trusteeForeignCompanyDetails.principalPlaceOfBusiness',
      },
      {
        label: 'Directors',
        key: 'trusteeForeignCompanyDetails.directors',
        transform: (array: any) =>
          array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
      },
    ],
  },
  individuals: [
    {
      label: 'First Name',
      key: 'firstName',
    },
    {
      label: 'Middle Name',
      key: 'middleName',
    },
    {
      label: 'Last Name',
      key: 'lastName',
    },
    {
      label: 'Date of Birth',
      key: 'dob',
      transform: (value: any) => value && utcToLocalTimezone(value),
    },
    {
      label: 'Residential Address',
      key: 'residentialAddress',
    },
  ],
};

export const trustReviewInfo = {
  trust: {
    title: 'Entity Details',
    data: [
      {
        label: 'Trust Name',
        key: 'name',
      },
      {
        label: 'ABN',
        key: 'abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Office',
        key: 'registeredOffice',
      },
    ],
  },
  trusteeAustralianCompanyDetails: {
    title: 'Corporate Trustee',
    data: [
      {
        label: 'Company name',
        key: 'trusteeAustralianCompanyDetails.companyName',
      },
      {
        label: 'ACN',
        key: 'trusteeAustralianCompanyDetails.acn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'ABN',
        key: 'trusteeAustralianCompanyDetails.abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Address',
        key: 'trusteeAustralianCompanyDetails.registeredAddress',
      },
      {
        label: 'Principal Place of Business',
        key: 'trusteeAustralianCompanyDetails.principalPlaceOfBusiness',
      },
      {
        label: 'Directors',
        key: 'trusteeAustralianCompanyDetails.directors',
        transform: (array: any) =>
          array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
      },
    ],
  },
  trusteeForeignCompanyDetails: {
    title: 'Corporate Trustee',
    data: [
      {
        label: 'Company name',
        key: 'trusteeForeignCompanyDetails.companyName',
      },
      {
        label: 'ACN',
        key: 'trusteeForeignCompanyDetails.acn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'ABN',
        key: 'trusteeForeignCompanyDetails.abn',
        transform: (value: string) => formatValueWithSpaces(value),
      },
      {
        label: 'Registered Address',
        key: 'trusteeForeignCompanyDetails.registeredAddress',
      },
      {
        label: 'Principal Place of Business',
        key: 'trusteeForeignCompanyDetails.principalPlaceOfBusiness',
      },
      {
        label: 'Directors',
        key: 'trusteeForeignCompanyDetails.directors',
        transform: (array: any) =>
          array?.map((it: any) => `${it?.firstName || ''} ${it?.lastName || ''}`)?.join(', '),
      },
    ],
  },
  individuals: [
    {
      label: 'First Name',
      key: 'firstName',
    },
    {
      label: 'Middle Name',
      key: 'middleName',
    },
    {
      label: 'Last Name',
      key: 'lastName',
    },
    {
      label: 'Date of Birth',
      key: 'dob',
      transform: (value: any) => value && utcToLocalTimezone(value),
    },
    {
      label: 'Residential Address',
      key: 'residentialAddress',
    },
  ],
};

export const individualsReviewInfo = [
  {
    label: 'First Name',
    key: 'firstName',
  },
  {
    label: 'Middle Name',
    key: 'middleName',
  },
  {
    label: 'Last Name',
    key: 'lastName',
  },
  {
    label: 'Date of Birth',
    key: 'dob',
    transform: (value: any) => value && utcToLocalTimezone(value),
  },
  {
    label: 'Residential Address',
    key: 'residentialAddress',
  },
];

export const soleTraderReviewInfo = [
  {
    label: 'Business Name',
    key: 'businessName',
  },
  {
    label: 'ABN',
    key: 'abn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'Principal Place of Business',
    key: 'principalPlaceOfBusiness',
  },
  {
    label: 'First Name',
    key: 'firstName',
  },
  {
    label: 'Middle Name',
    key: 'middleName',
  },
  {
    label: 'Last Name',
    key: 'lastName',
  },
  {
    label: 'Date of Birth',
    key: 'dob',
    transform: (value: any) => value && utcToLocalTimezone(value),
  },
  {
    label: 'Residential Address',
    key: 'residentialAddress',
  },
];

export const associationReviewInfo = [
  {
    label: 'Association Name',
    key: 'associationName',
  },
  {
    label: 'ABN',
    key: 'abn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'Association Number',
    key: 'associationNumber',
  },
  {
    label: 'Principal Place of Business',
    key: 'registeredOffice',
  },
  {
    label: 'Public Officers',
    key: 'publicOfficers',
    transform: (array: any) =>
      (array || [])
        .map((it: any) =>
          it?.entityName ? it?.entityName : `${it?.firstName || ''} ${it?.lastName || ''}`,
        )
        .join(', '),
  },
];

export const governmentReviewInfo = [
  {
    label: 'Name of Government Body',
    key: 'nameOfGovernmentBody',
  },
  {
    label: 'ABN',
    key: 'abn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'Principal Place of Operation',
    key: 'principlePlaceOfOperation',
  },
  {
    label: 'Public Officers',
    key: 'publicOfficers',
    transform: (array: any) =>
      (array || [])
        .map((it: any) =>
          it?.entityName ? it?.entityName : `${it?.firstName || ''} ${it?.lastName || ''}`,
        )
        .join(', '),
  },
];

export const otherReviewInfo = {
  association: associationReviewInfo,
  governmentBody: governmentReviewInfo,
};

export const partnershipReviewInfo = [
  {
    label: 'Partnership Name',
    key: 'partnershipName',
  },
  {
    label: 'ABN',
    key: 'abn',
    transform: (value: string) => formatValueWithSpaces(value),
  },
  {
    label: 'Business Name',
    key: 'businessName',
  },
  {
    label: 'Principal Place of Business',
    key: 'principalPlaceOfBusiness',
  },
  {
    label: 'Partners',
    key: 'partners',
    transform: (array: any) =>
      (array || [])
        .map((it: any) =>
          it?.entityName ? it?.entityName : `${it?.firstName || ''} ${it?.lastName || ''}`,
        )
        .join(', '),
  },
];

export const primaryContactReviewInfo = [
  {
    label: 'First Name',
    key: 'firstName',
  },
  {
    label: 'Last Name',
    key: 'lastName',
  },
  {
    label: 'Position',
    key: 'position',
  },
  {
    label: 'Email Address',
    key: 'email',
  },
  {
    label: 'Contact Number',
    key: 'phoneNumber',
  },
];
