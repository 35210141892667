/* eslint-disable no-restricted-imports */
import { Box, Divider, Grid, Radio, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AccountantIcon from 'src/assets/icons/AccountantIcon';
import AdviserIcon from 'src/assets/icons/AdviserIcon';
import OtherIcon from 'src/assets/icons/OtherIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import FormSwitch from 'src/components/atoms/FormSwitch';
import {
  INVESTMENT_ENTITY_DELEGATE_ROLE,
  INVESTMENT_ENTITY_ROLE,
  InvestorUserRoleOptions,
} from 'src/constants/applications';
import { DEFAULT_COUNTRY, TrueFalseRadioOptions } from 'src/constants/common';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { formatValueWithSpaces } from 'src/utils/common';

import { IInvestmentEntityProps } from '..';
import { INVESTMENT_APPLICATION_STEP } from '../../InvestmentApplicationForm';

interface IAddNewProps extends IInvestmentEntityProps {
  children?: ReactNode;
}

const AddNew = (props: IAddNewProps) => {
  const {
    children,
    id = '',
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    initiateVerificationComponent,
    isReviewing,
    isCreateFundApplicationForm,
    investmentEntityId,
  } = props;
  const { watch, setValue } = useFormContext<IInvestmentEntityForm>();
  const { data: userInfo } = useGetUserInfo();
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  const isAddNew = watch('isAddNewEntityDetail');
  const hasAuthorityToComplete = watch('editorDetails.hasAuthorityToComplete');
  const userType = watch('editorDetails.userType');
  const firstName = watch('editorDetails.firstName');
  const lastName = watch('editorDetails.lastName');
  const capacity = watch('editorDetails.capacity');

  useEffect(() => {
    if (isCreateFundApplicationForm && isExistingInvestmentEntity) {
      handleSelectDescribeRole(INVESTMENT_ENTITY_ROLE.Delegate);
      setValue('editorDetails.userType', INVESTMENT_ENTITY_ROLE.Delegate);
    }
  }, [isCreateFundApplicationForm, isExistingInvestmentEntity]);

  const investorDelegateCapacityOptions = [
    {
      label: 'Accountant',
      value: INVESTMENT_ENTITY_DELEGATE_ROLE.Accountant,
      icon: <AccountantIcon />,
    },
    {
      label: 'Adviser',
      value: INVESTMENT_ENTITY_DELEGATE_ROLE.Adviser,
      icon: <AdviserIcon />,
    },
    {
      label: 'Other',
      value: INVESTMENT_ENTITY_DELEGATE_ROLE.Other,
      icon: <OtherIcon width='38px' height='38px' />,
    },
  ];

  const renderCapacityOptions = (
    options: RadioOption[],
    selectedValue?: number | string,
    disabled?: boolean,
  ) => {
    return (
      <Grid container spacing={3}>
        {options.map((option) => (
          <Grid key={option.value} item xs={4}>
            <Box
              className={clsx('flex items-center border rounded-[15px] p-[5px] flex-1')}
              borderColor={selectedValue === option.value ? 'primary.main' : 'neutral.ne300'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={
                  <Radio icon={option.icon} checkedIcon={option.icon} sx={{ p: 0, mr: '12px' }} />
                }
                label={option.label}
                sx={{
                  width: '100%',
                  m: 0,
                }}
                disabled={disabled}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleSelectDescribeRole = (value: any) => {
    if (value === INVESTMENT_ENTITY_ROLE.Delegate) {
      setValue('editorDetails.firstName', userInfo?.firstName || '');
      setValue('editorDetails.lastName', userInfo?.lastName || '');
      setValue('editorDetails.email', userInfo?.email || '');
      setValue('editorDetails.contactNumber', userInfo?.contactNumber || '');
      setValue('editorDetails.countryFlagCode', userInfo?.countryFlagCode || DEFAULT_COUNTRY);
    }
  };

  return (
    <Box
      className={clsx('flex flex-col rounded-xl overflow-hidden  border-solid', {
        border: !isCreateFundApplicationForm,
      })}
      borderColor={'neutral.ne200'}
    >
      {!isCreateFundApplicationForm && (
        <Box p={3} bgcolor='neutral.ne100' className='flex justify-between'>
          <Typography variant='body2' fontWeight={600}>
            Add New
          </Typography>
          <FormSwitch
            name='isAddNewEntityDetail'
            disabled={
              isAddNew ||
              Number.isInteger(investmentEntity?.entityType) ||
              isViewMode ||
              isCreateFundApplicationForm
            }
          />
        </Box>
      )}
      <Box p={isCreateFundApplicationForm ? 1 : 3} className='flex flex-col'>
        {isAddNew && (
          <FormRadioGroup
            name='editorDetails.userType'
            options={Object.values(InvestorUserRoleOptions).filter((it) =>
              isCreateFundApplicationForm ? it.value !== INVESTMENT_ENTITY_ROLE.Investor : it,
            )}
            label={
              <Typography variant='body3' fontWeight={500}>
                Describe Your Role
              </Typography>
            }
            isEnumValue
            optionGap={4}
            onChangeValue={handleSelectDescribeRole}
            disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            sx={{ mb: 3 }}
            showPickerOnly={isReviewing}
          />
        )}
        {userType === INVESTMENT_ENTITY_ROLE.Delegate && (
          <Accordion
            defaultExpanded
            sx={{ mb: 3, '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
          >
            <AccordionSummary
              sx={{
                '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
                '.MuiAccordionSummary-content': { my: 0 },
                minHeight: 'unset',
              }}
            >
              <Box className='flex justify-between items-center w-full'>
                <Typography variant='body2' fontWeight={600}>
                  {`${firstName} ${lastName}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              className={clsx('p-6 mt-0 border-t', {
                'bg-white border px-10 border-t-0': isCreateFundApplicationForm,
              })}
              sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
            >
              <FormRadioGroup
                label={
                  <Typography variant='body3' fontWeight={500}>
                    Do you have authority to complete this application?
                  </Typography>
                }
                name='editorDetails.hasAuthorityToComplete'
                optionGap={10}
                className='w-full'
                row
                options={TrueFalseRadioOptions}
                disabled={isViewMode || isDisabledEdit}
                isBooleanValue
                showPickerOnly={isReviewing}
              />
              {!hasAuthorityToComplete && (
                <Typography fontStyle='italic' variant='body3' color='error'>
                  {`Sorry, cannot proceed with this application as you don't have the authority.`}
                </Typography>
              )}
              <Box mt={1.5} mb={3}>
                <FormRadioGroup
                  name='editorDetails.capacity'
                  options={investorDelegateCapacityOptions}
                  label={
                    <Typography variant='body3' marginBottom={1}>
                      Role
                    </Typography>
                  }
                  renderOptions={renderCapacityOptions}
                  disabled={!hasAuthorityToComplete || isViewMode || isDisabledEdit}
                  isEnumValue
                  showPickerOnly={isReviewing}
                />
              </Box>
              {capacity === INVESTMENT_ENTITY_DELEGATE_ROLE.Other && (
                <Box width={'100%'} mb={3}>
                  <FormInput
                    name='editorDetails.otherCapacity'
                    label='Describe your role'
                    placeholder='Enter your role description'
                    disabled={!hasAuthorityToComplete || isViewMode || isDisabledEdit}
                  />
                </Box>
              )}
              <Divider sx={{ borderColor: 'neutral.ne300' }} />
              <Grid container spacing={3} pt={2.5}>
                <Grid item xs={6}>
                  <FormInput
                    name='editorDetails.firstName'
                    label='First Name'
                    placeholder='Enter first name'
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name='editorDetails.lastName'
                    label='Last Name'
                    placeholder='Enter last name'
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name='editorDetails.email'
                    label='Email Address'
                    placeholder='Enter email address'
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormPhoneCountryInput
                    name='editorDetails.contactNumber'
                    countryFlagCodeName='editorDetails.countryFlagCode'
                    label='Contact Number'
                    size='small'
                    disabled
                    isIncludeCode
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    name='editorDetails.companyName'
                    label='Company Name'
                    placeholder='Enter company name'
                    disabled={!hasAuthorityToComplete || isViewMode || isDisabledEdit}
                  />
                </Grid>
                {capacity === INVESTMENT_ENTITY_DELEGATE_ROLE.Adviser && (
                  <>
                    <Grid item xs={6}>
                      <FormInput
                        name='editorDetails.licenseeName'
                        label='Licensee Name'
                        placeholder='Enter licensee name'
                        disabled={!hasAuthorityToComplete || isViewMode || isDisabledEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        name='editorDetails.afslNumber'
                        label='AFSL Number'
                        placeholder='Enter AFSL number'
                        disabled={!hasAuthorityToComplete || isViewMode || isDisabledEdit}
                        maxLength={11}
                        formatValue={(value: string) => {
                          if (Number.isNaN(+value) && value.length === 1) return '';
                          return formatValueWithSpaces(value, 3) || value;
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {isAddNew && <Divider sx={{ mb: 3, borderColor: 'neutral.ne200' }} />}
        {(isAddNew || (isCreateFundApplicationForm && investmentEntityId)) && children}
        {initiateVerificationComponent}
      </Box>
    </Box>
  );
};

export default AddNew;
