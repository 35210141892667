import { Box, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { createElement, useEffect, useState } from 'react';
import { ADDRESS } from 'src/constants/address';
import { PROFILE_API } from 'src/constants/apiEndpoints';
import { loqateApiEndpoint } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import * as StorageService from 'src/helpers/storage';
import { ICountry } from 'src/interfaces/common';
import { downloadFileFromArrayBuffer } from 'src/utils/common';
import {
  downloadDocumentByPath,
  downloadFileByPath,
  getCities,
  getCountries,
  getOverarchingFilter,
  getStates,
  uploadDocument,
  uploadImage,
} from './services';
import { IOverarchingFilter, LoqateAddressQueryBody, LoqateAddressResult } from './type';

export interface BaseQuery {
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  isAscending?: boolean;
  step?: number;
  type?: number | null;
  [x: string]: any;
}

export const DEFAULT_QUERIES: BaseQuery = {
  page: 0,
  pageSize: 8,
  isAscending: false,
};
export const DEFAULT_PAGINATED = {
  page: 0,
  pageSize: 8,
  totalItem: 0,
};

interface IQueryParamsHookOptions {
  removeBaseQuery: boolean;
}

export const useCommonQueryParams = (
  apiEndpoint: string,
  queryParams: BaseQuery,
  options?: IQueryParamsHookOptions,
) => {
  const { removeBaseQuery } = options || {};
  const createQueryKey = (queryParams: BaseQuery) =>
    Object.entries(queryParams)
      .filter(([, value]) => value != null)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((item) => `${key}=${item}`).join('&');
        }
        return `${key}=${value}`;
      })
      .join('&');

  const getCommonQueryParams = (queryParams: BaseQuery) => ({
    ...queryParams,
    page: queryParams.page != null ? queryParams.page : DEFAULT_QUERIES.page,
    pageSize: queryParams.pageSize != null ? queryParams.pageSize : DEFAULT_QUERIES.pageSize,
    isAscending:
      queryParams.isAscending != null ? queryParams.isAscending : DEFAULT_QUERIES.isAscending,
  });

  const getApiEndpointWithQueryParams = (queryParams: BaseQuery) =>
    `${apiEndpoint}?${createQueryKey(
      removeBaseQuery ? queryParams : getCommonQueryParams(queryParams),
    )}`;

  return getApiEndpointWithQueryParams(queryParams);
};

export const useCommonQueryBody = (queryBody: BaseQuery) => {
  const getCommonQueryBody = (queryBody: BaseQuery) => ({
    ...queryBody,
    page: queryBody.page != null ? queryBody.page : DEFAULT_QUERIES.page,
    pageSize: queryBody.pageSize != null ? queryBody.pageSize : DEFAULT_QUERIES.pageSize,
    isAscending:
      queryBody.isAscending != null ? queryBody.isAscending : DEFAULT_QUERIES.isAscending,
  });

  return getCommonQueryBody(queryBody);
};

export const useEnterKeyPress = (callback: () => void): void => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.keyCode === 13) {
        callback();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
};

export const useGetOverarchingFilter = () => {
  const token = StorageService.getToken();
  const { isFunds } = detectPortalType();
  const { data = { clients: [], funds: [], clientsForNewFund: [] }, ...rest } =
    useQuery<IOverarchingFilter>([PROFILE_API.getOverarchingFilter.api], getOverarchingFilter, {
      enabled: isFunds && !!token,
    });
  return {
    data,
    ...rest,
  };
};

export const useUploadImage = () => {
  return useMutation(uploadImage);
};

export const useUploadDocument = () => {
  return useMutation(uploadDocument);
};

export const useDownloadFileFromArrayBuffer = () => {
  const resp = async ({ data, fileName }: { data: ArrayBuffer; fileName: string }) => {
    downloadFileFromArrayBuffer(data, fileName);
  };

  return useMutation(resp);
};

export const useDownloadFileByPath = () => {
  const resp = async ({ path, fileName }: { fileName: string; path: string }) => {
    const data = await downloadFileByPath(path);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};

export const useDownloadDocumentByPath = (isShowing?: boolean) => {
  const resp = async ({ path, fileName }: { fileName: string; path: string }) => {
    const data = await downloadDocumentByPath(path);
    const res = downloadFileFromArrayBuffer(data, fileName, isShowing);
    return res;
  };
  return useMutation(resp);
};

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const useQueryLoqateAddress = () => {
  const apiUrl = `${loqateApiEndpoint}/find`;
  const [queryBody, setQueryBody] = useState<LoqateAddressQueryBody>({
    payload: [{ country: 'au', fullAddress: '' }],
    sourceOfTruth: 'AUPAF',
  });

  const { data = { payload: [] }, ...rest } = useQuery<{
    payload: LoqateAddressResult[];
  }>(
    [apiUrl, queryBody],
    async () => {
      const data = await axiosInstance.post(apiUrl, queryBody, {
        auth: {
          username: process.env.REACT_APP_LOQATE_USERNAME || '',
          password: process.env.REACT_APP_LOQATE_CREDENTIAL || '',
        },
      });
      return data?.data;
    },
    { enabled: !!queryBody.payload[0].fullAddress },
  );

  const setParams = (newParams: { fullAddress: string }) => {
    const formatParams = {
      ...queryBody,
      payload: queryBody.payload.map((it) => ({ ...it, ...newParams })),
    };
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetCountries = () => {
  return useQuery([ADDRESS.COUNTRIES], getCountries);
};

export const useGetStates = () => {
  return useQuery([ADDRESS.STATES], getStates);
};

export const useGetCities = () => {
  return useQuery([ADDRESS.CITIES], getCities);
};

export const useCountry = () => {
  const { data: countries = [] } = useGetCountries();

  const getPhoneCodeByCountryCode = (code: string | undefined): string | undefined => {
    const country = countries.find((item: ICountry) => item.code === code);
    return country?.phone_code;
  };

  const getCountryCodeByPhoneCode = (code: string | undefined): string | undefined => {
    const country = countries.find((item: ICountry) => item.phone_code === code);
    return country?.code;
  };

  const parsePhoneNumber = (
    phoneNumber: string,
  ): { countryFlagCode?: string; phoneCode: string; contactNumber: string } => {
    const [phoneCode, contactNumber] = (phoneNumber || '').split(' ');
    const countryFlagCode = getCountryCodeByPhoneCode(phoneCode);
    if (!countryFlagCode || !phoneCode || !contactNumber) {
      return { countryFlagCode: '', phoneCode: '', contactNumber: phoneNumber }; // Keep the current number incase didnt existing phoneCode and countryFlagCode
    }
    return { countryFlagCode, phoneCode, contactNumber };
  };

  const countryOptions = countries.map((it: ICountry) => ({
    label: createElement(
      Box,
      { className: 'flex flex-row gap-2' },
      createElement('span', { className: `fi fi-${it.code.toLowerCase()}` }),
      createElement(Typography, { variant: 'body3', className: 'ml-2' }, it.name),
    ),
    value: it.code,
  }));

  return {
    countries,
    countryOptions,
    getPhoneCodeByCountryCode,
    parsePhoneNumber,
  };
};
