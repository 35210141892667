/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { createTeamContactDefaultValues } from 'src/constants/administration-investor';
import { INVESTMENT_ENTITY_ROLE, InvestorUserRoleOptions } from 'src/constants/applications';
import { useGetInvestmentEntityRoles } from 'src/modules/administration-investor/hooks';
import { ITeamContact } from 'src/modules/applications/type';
import { Role } from 'src/modules/users-management/type';
import { createTeamContactSchema } from 'src/validations/administration-investor';

interface ITeamUpsertContactProps {
  isEditMode?: boolean;
  isViewMode?: boolean;
  user?: ITeamContact;
}

export interface TeamUpsertContactHandler {
  onCreate: (callback: (data: ITeamContact) => void) => void;
}

const TeamUpsertContact = forwardRef<TeamUpsertContactHandler, ITeamUpsertContactProps>(
  ({ isEditMode, isViewMode, user }, ref) => {
    const { data: userRoles = [] } = useGetInvestmentEntityRoles();
    const { notUpdate } = user || {};

    const form = useForm({
      resolver: yupResolver(createTeamContactSchema),
      defaultValues: createTeamContactDefaultValues,
    });
    const watchUserType = form.watch('userType');

    useEffect(() => {
      if (watchUserType === INVESTMENT_ENTITY_ROLE.Investor) {
        const fineSuperAdmin = (userRoles || []).find((f: Role) => f.isSuperAdmin);
        fineSuperAdmin?.id && form.setValue('roleId', fineSuperAdmin?.id, { shouldValidate: true });
      }
    }, [watchUserType, userRoles]);

    useEffect(() => {
      if (isEditMode || isViewMode) {
        form.reset({ ...user });
      }
    }, [user]);

    useImperativeHandle(ref, () => ({
      onCreate: (callback: (data: ITeamContact) => void) =>
        form.handleSubmit((data) => onSubmit(data, callback))(),
    }));

    const onSubmit = (data: ITeamContact, callback: (data: ITeamContact) => void) => {
      callback(data);
    };

    const userRolesFormat = useMemo(() => {
      return userRoles
        ?.map((item: Role) => {
          return {
            label: item.name,
            value: item?.id,
          };
        })
        .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
    }, [userRoles]);

    const disabledRole =
      isViewMode || watchUserType === INVESTMENT_ENTITY_ROLE.Investor || notUpdate;

    return (
      <FormProvider {...form}>
        <Box className='pt-12' component='form'>
          <Grid container rowSpacing={4} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <FormRadioGroup
                containerSx={{
                  '.MuiStack-root': {
                    ml: '2px',
                  },
                }}
                name='userType'
                label='User Type'
                isEnumValue
                disabled={isViewMode || notUpdate} // If users choose the "Add to Primary Contact" or "Add to Communication List" option
                options={InvestorUserRoleOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='firstName'
                label='First Name'
                size='small'
                placeholder='Enter first name'
                className='w-full'
                disabled={isEditMode || isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='lastName'
                label='Last Name'
                size='small'
                placeholder='Enter last name'
                className='w-full'
                disabled={isEditMode || isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='email'
                label='Email Address'
                size='small'
                placeholder='Enter email address'
                className='w-full'
                disabled={isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='phoneNumber'
                label='Contact Number'
                size='small'
                className='w-full'
                countryFlagCodeName='countryFlagCode'
                disabled={isViewMode || notUpdate}
                isIncludeCode
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='position'
                label='Position'
                size='small'
                placeholder='Enter position'
                className='w-full'
                disabled={isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='roleId'
                label='Role'
                size='small'
                placeholder='Select role'
                className='w-full'
                options={userRolesFormat}
                disabled={disabledRole}
              />
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    );
  },
);

export default TeamUpsertContact;
