/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormSelect from 'src/components/atoms/FormSelect';
import { createClienTeamUserDefaultValues } from 'src/constants/client-management';
import { useFundGetClienTeamUserFunds } from 'src/modules/client/hooks';
import { IClientTeamUser, ICreateClientUserFormFields } from 'src/modules/client/type';
import { Role } from 'src/modules/users-management/type';
import { createClientTeamUserValidationSchema } from 'src/validations/client-management';

interface ICreateClientTeamUserProps {
  selectedUser: IClientTeamUser | null;
  userRolesData: Role[];
  isViewUserMode: boolean;
  isHasFunds: boolean;
  hideSendMail: boolean;
  clientId: string;
}

export interface CreateClienUserFormHandler {
  onCreateEditClientTeamUser: (callback: (data: ICreateClientUserFormFields) => void) => void;
}

interface IRoleOption {
  value: string;
  label: string;
}

const CreateClientTeamUser = forwardRef<CreateClienUserFormHandler, ICreateClientTeamUserProps>(
  ({ selectedUser, userRolesData, isViewUserMode, isHasFunds, hideSendMail, clientId }, ref) => {
    const [userRoles, setUserRoles] = useState<IRoleOption[]>([]);
    const { data: userFunds = [] } = useFundGetClienTeamUserFunds(clientId);

    const form = useForm<ICreateClientUserFormFields>({
      resolver: yupResolver(createClientTeamUserValidationSchema),
      defaultValues: createClienTeamUserDefaultValues,
    });

    const userFundsFormat = useMemo(() => {
      return userFunds?.map((item) => {
        return {
          id: item.id,
          label: `${item.code} - ${item.name}`,
          value: item?.id,
        };
      });
    }, [userFunds]);

    useImperativeHandle(ref, () => ({
      onCreateEditClientTeamUser: (callback: (data: ICreateClientUserFormFields) => void) =>
        form.handleSubmit((data) => onSubmitClientTeamUser(data, callback))(),
    }));

    useEffect(() => {
      handleSetUserRoles();
    }, [userRolesData]);

    useEffect(() => {
      handleFillUserData();
    }, [selectedUser]);

    const handleSetUserRoles = () => {
      if (isEmpty(userRolesData)) return;

      const newUserRoles = userRolesData
        ?.map((item: Role) => ({
          label: item.name,
          value: item?.id,
        }))
        .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));

      setUserRoles(newUserRoles);
    };

    const handleFillUserData = () => {
      if (isEmpty(selectedUser)) return;

      const { roleName, userId, status, ...formData } = selectedUser;
      form.reset(formData);
    };

    const onSubmitClientTeamUser = (
      data: ICreateClientUserFormFields,
      callback: (data: ICreateClientUserFormFields) => void,
    ) => {
      const body = { ...data };
      body.isAllFund = data.fundIds?.length === userFundsFormat?.length;
      callback(body);
    };

    const renderSendMailCheckbox = () => {
      if (isViewUserMode || hideSendMail) return <></>;
      return (
        <Grid item xs={12}>
          <FormCheckbox
            name='isSendMail'
            label='Send email notifying user to set up a new password.'
            defaultChecked
          />
        </Grid>
      );
    };

    return (
      <FormProvider {...form}>
        <Box className='pt-12' component='form'>
          <Grid container rowSpacing={4} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormInput
                name='firstName'
                label='First Name'
                size='small'
                placeholder='Enter first name'
                className='w-full'
                disabled={hideSendMail && !isViewUserMode}
                readOnly={isViewUserMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='lastName'
                label='Last Name'
                size='small'
                placeholder='Enter last name'
                className='w-full'
                disabled={hideSendMail && !isViewUserMode}
                readOnly={isViewUserMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='email'
                label='Email Address'
                size='small'
                placeholder='Enter email address'
                className='w-full'
                disabled={hideSendMail && !isViewUserMode}
                readOnly={isViewUserMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='contactNumber'
                label='Contact Number'
                size='small'
                className='w-full'
                countryFlagCode={selectedUser?.countryFlagCode}
                disabled={hideSendMail && !isViewUserMode}
                readOnly={isViewUserMode}
                isIncludeCode
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='position'
                label='Position'
                size='small'
                placeholder='Enter position'
                optional='Optional'
                className='w-full'
                readOnly={isViewUserMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='roleId'
                label='Role'
                size='small'
                placeholder='Select role'
                fullWidth
                options={userRoles}
                readOnly={isViewUserMode}
              />
            </Grid>
            {isHasFunds && (
              <Grid item xs={12}>
                <FormMultiSelect
                  name='fundIds'
                  placeholder='Select funds'
                  label='Funds'
                  multiple
                  options={userFundsFormat}
                  className='w-full'
                  allOption
                  optional
                  readOnly={isViewUserMode}
                />
              </Grid>
            )}
            {renderSendMailCheckbox()}
          </Grid>
        </Box>
      </FormProvider>
    );
  },
);

export default CreateClientTeamUser;
