import { Box, Typography, alpha, useTheme } from '@mui/material';
import { FC } from 'react';
import {
  administrationInvestmentEntityStatuses,
  administrationInvestorStatuses,
} from 'src/constants/administration-investor';
import { applicationSignatoryStatuses, applicationStatuses } from 'src/constants/applications';
import { bulkUploadResultStatusName, bulkUploadStatusName } from 'src/constants/bulk-upload';
import { capitalCallDocumentStatusName, capitalCallStatus } from 'src/constants/capital-calls';
import { CLIENT_STATUSNAME, invoiceStatus } from 'src/constants/client-management';
import { userStatuses } from 'src/constants/common';
import { communicationStatuses } from 'src/constants/communications';
import { fundDocumentStatus, fundInvestorDocumentStatus } from 'src/constants/documents';
import { fundStatuses, unitClassStatuses } from 'src/constants/funds-management';
import {
  kycBackOfficeStatuses,
  kycboUboAmlStatus,
  kycboUboKycStatus,
  kycboUboOverallStatus,
  riskFlagStatus,
} from 'src/constants/kyc-back-office';
import { offerStatuses } from 'src/constants/offer';
import { billingTieringSystem } from 'src/constants/subscription-billing';

interface IStatusBadgeProps {
  status: string;
  showDot?: boolean;
  defaultColor?: string;
  defaultBgColor?: string;
  customColor?: string;
  customBgColor?: string;
}

const StatusBadge: FC<IStatusBadgeProps> = ({
  status,
  showDot = true,
  defaultColor,
  defaultBgColor,
  customColor,
  customBgColor,
}) => {
  const theme = useTheme();
  const statusEnum = {
    ...userStatuses,
    ...CLIENT_STATUSNAME,
    ...fundStatuses,
    ...unitClassStatuses,
    ...offerStatuses,
    ...applicationStatuses,
    ...applicationSignatoryStatuses,
    ...kycboUboOverallStatus,
    ...kycboUboKycStatus,
    ...kycboUboAmlStatus,
    ...kycBackOfficeStatuses,
    ...administrationInvestmentEntityStatuses,
    ...riskFlagStatus,
    ...administrationInvestorStatuses,
    ...billingTieringSystem,
    ...fundDocumentStatus,
    ...fundInvestorDocumentStatus,
    ...invoiceStatus,
    ...capitalCallStatus,
    ...capitalCallDocumentStatusName,
    ...communicationStatuses,
    ...bulkUploadStatusName,
    ...bulkUploadResultStatusName,
  };

  const color = {
    [statusEnum.Active]: theme.palette.sematic.success500,
    [statusEnum.Pending]: theme.palette.sematic.warning500,
    [statusEnum.Disabled]: theme.palette.neutral.ne800,
    [statusEnum.Draft]: 'info.main',
    [statusEnum.Open]: theme.palette.secondary.se500,
    [statusEnum.Inactive]: theme.palette.neutral.ne800,
    [statusEnum.Archived]: theme.palette.neutral.ne800,
    [statusEnum.Suspended]: theme.palette.neutral.ne800,
    [statusEnum.Expired]: 'error.main',
    [statusEnum.In_Progress]: theme.palette.sematic.warning500,
    [statusEnum.Submitted]: theme.palette.sematic.information500,
    [statusEnum.In_Review]: theme.palette.sematic.inReview500,
    [statusEnum.Approved]: theme.palette.sematic.success500,
    [statusEnum.Finalised]: theme.palette.sematic.finalised500,
    [statusEnum.Action_Required]: theme.palette.sematic.actionRequired500,
    [statusEnum.Cancelled]: theme.palette.neutral.ne500,
    [statusEnum.Withdrawn]: theme.palette.sematic.withdrawn500,
    [statusEnum.Rejected]: theme.palette.error.main,
    [statusEnum.KYC_Completed]: theme.palette.primary.main,
    [statusEnum.KYC_In_Progress]: theme.palette.sematic.kycInProgress500,
    [statusEnum.Awaiting_Deposit]: theme.palette.sematic.awaitingDeposit500,
    [statusEnum.Not_Required]: theme.palette.sematic.actionRequired500,
    [statusEnum.Required]: theme.palette.error.main,
    [statusEnum.Verified]: theme.palette.success.main,
    [statusEnum.Not_Started]: theme.palette.neutral.ne800,
    [statusEnum.Manually_Verified]: theme.palette.info.main,
    [statusEnum.KYC_Completed_Externally]: theme.palette.primary.main,
    [statusEnum.Match_Found]: theme.palette.error.main,
    [statusEnum.No_Match_Found]: theme.palette.success.main,
    [statusEnum.Removed]: theme.palette.neutral.ne900,
    [statusEnum.Withdrawn]: theme.palette.sematic.withdrawn500,
    [statusEnum.Awaiting_Approval]: theme.palette.sematic.warning500,
    [statusEnum.Signed]: theme.palette.success.main,
    [statusEnum.NotSent]: theme.palette.error.main,
    [statusEnum.AwaitingSignature]: theme.palette.sematic.inReview500,
    [statusEnum.New]: 'info.main',
    [statusEnum.Linked]: theme.palette.sematic.success500,
    [statusEnum.NotLinked]: 'error.main',
    [statusEnum.Closed]: theme.palette.neutral.ne800,
    [statusEnum.Low]: theme.palette.info.main,
    [statusEnum.Medium]: theme.palette.warning.main,
    [statusEnum.High]: theme.palette.error.main,
    [statusEnum.Ceased]: theme.palette.error.main,
    [billingTieringSystem.yes]: theme.palette.success.main,
    [billingTieringSystem.no]: theme.palette.error.main,
    [statusEnum.uploaded]: theme.palette.success.main,
    [statusEnum.published]: theme.palette.info.main,
    [statusEnum.system]: theme.palette.sematic.actionRequired500,
    [statusEnum.Paid]: theme.palette.success.main,
    [statusEnum.Deleted]: theme.palette.error.main,
    [statusEnum.Voided]: theme.palette.warning.main,
    [statusEnum.Authorised]: theme.palette.sematic.inReview500,
    [statusEnum.Created]: theme.palette.sematic.warning500,
    [statusEnum.Emailed]: theme.palette.sematic.withdrawn500,
    [statusEnum.Authorised]: theme.palette.sematic.inReview500,
    [statusEnum.Received]: theme.palette.sematic.success500,
    [statusEnum.Sent]: theme.palette.primary.main,
    [statusEnum.Partially_Uploaded]: 'info.main',
    [statusEnum.Uploaded]: theme.palette.sematic.success500,
    [statusEnum.Error]: theme.palette.error.main,
    [statusEnum.Imported]: theme.palette.sematic.warning500,
  };

  const bgColor = {
    [statusEnum.Active]: theme.palette.sematic.success100,
    [statusEnum.Pending]: theme.palette.sematic.warning100,
    [statusEnum.Disabled]: theme.palette.neutral.ne100,
    [statusEnum.Draft]: 'info.light',
    [statusEnum.Open]: theme.palette.secondary.se100,
    [statusEnum.Inactive]: theme.palette.neutral.ne100,
    [statusEnum.Archived]: theme.palette.neutral.ne100,
    [statusEnum.Suspended]: theme.palette.neutral.ne100,
    [statusEnum.Expired]: alpha(theme.palette.error.main, 0.1),
    [statusEnum.In_Progress]: theme.palette.sematic.warning100,
    [statusEnum.Submitted]: theme.palette.sematic.information100,
    [statusEnum.In_Review]: theme.palette.sematic.inReview100,
    [statusEnum.Approved]: theme.palette.sematic.success100,
    [statusEnum.Finalised]: theme.palette.sematic.finalised100,
    [statusEnum.Action_Required]: theme.palette.sematic.actionRequired100,
    [statusEnum.Cancelled]: theme.palette.neutral.ne100,
    [statusEnum.Withdrawn]: theme.palette.sematic.withdrawn100,
    [statusEnum.Rejected]: theme.palette.sematic.danger100,
    [statusEnum.KYC_Completed]: theme.palette.primary.pr50,
    [statusEnum.KYC_In_Progress]: theme.palette.sematic.kycInProgress100,
    [statusEnum.Awaiting_Deposit]: theme.palette.sematic.awaitingDeposit100,
    [statusEnum.Not_Required]: theme.palette.sematic.actionRequired100,
    [statusEnum.Required]: theme.palette.error.light,
    [statusEnum.Verified]: theme.palette.success.light,
    [statusEnum.Not_Started]: theme.palette.neutral.ne200,
    [statusEnum.Manually_Verified]: theme.palette.info.light,
    [statusEnum.KYC_Completed_Externally]: theme.palette.primary.pr50,
    [statusEnum.Match_Found]: theme.palette.error.light,
    [statusEnum.No_Match_Found]: theme.palette.success.light,
    [statusEnum.Removed]: theme.palette.neutral.ne300,
    [statusEnum.Awaiting_Approval]: theme.palette.sematic.warning100,
    [statusEnum.Signed]: theme.palette.success.light,
    [statusEnum.NotSent]: theme.palette.error.light,
    [statusEnum.AwaitingSignature]: theme.palette.sematic.inReview100,
    [statusEnum.New]: 'info.light',
    [statusEnum.Linked]: theme.palette.sematic.success100,
    [statusEnum.NotLinked]: alpha(theme.palette.error.main, 0.1),
    [statusEnum.Closed]: theme.palette.neutral.ne100,
    [statusEnum.Low]: theme.palette.info.light,
    [statusEnum.Medium]: theme.palette.warning.light,
    [statusEnum.High]: theme.palette.error.light,
    [statusEnum.Ceased]: theme.palette.error.light,
    [billingTieringSystem.yes]: theme.palette.success.light,
    [billingTieringSystem.no]: theme.palette.error.light,
    [statusEnum.uploaded]: theme.palette.success.light,
    [statusEnum.published]: theme.palette.info.light,
    [statusEnum.system]: theme.palette.sematic.actionRequired100,
    [statusEnum.Paid]: theme.palette.success.light,
    [statusEnum.Deleted]: theme.palette.error.light,
    [statusEnum.Voided]: theme.palette.warning.light,
    [statusEnum.Authorised]: theme.palette.sematic.inReview100,
    [statusEnum.Created]: theme.palette.sematic.warning100,
    [statusEnum.Emailed]: theme.palette.sematic.withdrawn100,
    [statusEnum.Received]: theme.palette.sematic.success100,
    [statusEnum.Sent]: theme.palette.primary.pr50,
    [statusEnum.Partially_Uploaded]: 'info.light',
    [statusEnum.Uploaded]: theme.palette.sematic.success100,
    [statusEnum.Error]: theme.palette.error.light,
    [statusEnum.Imported]: theme.palette.sematic.warning100,
  };

  return (
    <Box
      className='flex w-fit items-center h-6 rounded-lg px-2'
      bgcolor={customBgColor || bgColor[status] || defaultBgColor}
    >
      <Box
        className='w-[6px] h-[6px] rounded-[6px] mr-2'
        hidden={!showDot}
        bgcolor={customColor || color[status] || defaultColor}
      />
      <Typography
        variant='body3'
        className='font-medium'
        color={customColor || color[status] || defaultColor}
        whiteSpace={'pre'}
      >
        {status}
      </Typography>
    </Box>
  );
};

export default StatusBadge;
