/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import {
  InvestorRelationsContact,
  InvestorRelationsContactOptions,
  createSignatoryContactDefaultValues,
} from 'src/constants/funds-management';
import { ISignatoryContact } from 'src/modules/funds-management/type';
import { createSignatoryContactSchema } from 'src/validations/funds-management';

interface ICreateSignatoryContactUserProps {
  isIncludeContactOption?: boolean;
}

export interface CreateSignatoryContactUserHandler {
  onCreate: (callback: (data: ISignatoryContact) => void) => void;
}

interface IForm extends ISignatoryContact {
  option?: number;
}

const CreateSignatoryContactUser = forwardRef<
  CreateSignatoryContactUserHandler,
  ICreateSignatoryContactUserProps
>(({ isIncludeContactOption }, ref) => {
  const form = useForm<IForm>({
    resolver: yupResolver(createSignatoryContactSchema),
    defaultValues: {
      ...createSignatoryContactDefaultValues,
      ...(isIncludeContactOption && { option: InvestorRelationsContact.Individual }),
    },
  });

  useImperativeHandle(ref, () => ({
    onCreate: (callback: (data: ISignatoryContact) => void) =>
      form.handleSubmit((data) => onSubmit(data, callback))(),
  }));

  const onSubmit = (data: ISignatoryContact, callback: (data: ISignatoryContact) => void) => {
    callback(data);
  };

  const watchOption = form.watch('option');
  const isOrganisationOption = watchOption === InvestorRelationsContact.Organisation;

  return (
    <FormProvider {...form}>
      <Box className='pt-12' component='form'>
        <Grid container rowSpacing={4} columnSpacing={3}>
          {isIncludeContactOption && (
            <Grid item xs={12} md={12}>
              <FormRadioGroup
                row
                name='option'
                options={InvestorRelationsContactOptions}
                isEnumValue
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormInput
              name='firstName'
              label={isOrganisationOption ? 'Organisation Name' : 'First Name'}
              size='small'
              placeholder={`Enter ${isOrganisationOption ? 'organisation' : 'first'} name`}
              className='w-full'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name='lastName'
              label={isOrganisationOption ? 'Contact Name' : 'Last Name'}
              size='small'
              placeholder={`Enter ${isOrganisationOption ? 'contact' : 'last'} name`}
              className='w-full'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name='email'
              label='Email Address'
              size='small'
              placeholder='Enter email address'
              className='w-full'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormPhoneCountryInput
              name='contactNumber'
              label='Contact Number'
              size='small'
              className='w-full'
              countryFlagCodeName='countryFlagCode'
              countryFlagCode={DEFAULT_COUNTRY}
              isIncludeCode
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name='position'
              label='Position'
              size='small'
              placeholder='Enter position'
              className='w-full'
            />
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
});

export default CreateSignatoryContactUser;
