/* eslint-disable no-restricted-imports */
import { CLIENT_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import * as StorageService from 'src/helpers/storage';
import { BaseQuery } from '../common/hooks';
import {
  IAssigneesResponse,
  IClientTeamUserFund,
  IFundOurTeam,
  IGetCapacityResponse,
  IGetClientFiltersResponse,
  IImpersonateResponse,
  IManualInvoiceBody,
  Invoice,
} from './type';

export const getCapacity = async (): Promise<IGetCapacityResponse[]> => {
  const verifyToken = StorageService.getVerifyToken();

  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.capacity.api,
    headers: {
      Authorization: `Bearer ${verifyToken}`,
    },
  });
  return result.data;
};

export const createClient = async (data: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.createClient.api,
  });

  return result.data;
};

export const updateClient = async (clientId: string, { id, isSaveDraft, ...restData }: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: restData,
    url: CLIENT_API.updateClient.api(clientId),
  });

  return result.data;
};

export const getClientList = async (data: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.getClientList.api,
  });

  return result.data;
};

export const getClientDetail = async (id: string, params: { step?: number; pageSize?: number }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: CLIENT_API.getClientDetail.api(id),
  });

  return result.data;
};

export const getAdministrators = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.getAdministrators.api(clientId),
  });

  return result.data;
};

export const getClientFilters = async (): Promise<IGetClientFiltersResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.filters.api,
  });

  return result.data;
};

export const deleteClient = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: CLIENT_API.deleteClient.api(clientId),
  });

  return result.data;
};

export const toggleClientStatus = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: CLIENT_API.toggleClientStatus.api(clientId),
  });

  return result.data;
};

export const activeClient = async (clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: CLIENT_API.activeClient.api(clientId),
  });

  return result.data;
};

export const impersonateClient = async (clientId: string): Promise<IImpersonateResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: CLIENT_API.impersonateClient.api(clientId),
  });

  return result.data;
};

export const getAssignees = async (): Promise<IAssigneesResponse[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.getAssignees.api,
  });

  return result.data;
};

export const createDraftClient = async (data: any): Promise<string> => {
  const { id, ...restData } = data;
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: restData,
    url: id ? `${CLIENT_API.draftClient.api}?id=${id}` : CLIENT_API.draftClient.api,
  });

  return result.data;
};

export const submitClient = async (data: any): Promise<string> => {
  const { id, isSaveDraft, ...restData } = data;
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: restData,
    url: CLIENT_API.submitClient.api(id),
  });

  return result.data;
};

export const fundSubmitOrganisationDetails = async (data: any): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.fundSubmitOrganisationDetails.api,
  });

  return result.data;
};

export const fundSubmitContactDetails = async (data: any): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.fundSubmitContactDetails.api,
  });

  return result.data;
};

export const fundSubmitOurTeam = async (data: any): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.fundSubmitOurTeam.api,
  });

  return result.data;
};

export const getFundOurTeam = async (): Promise<IFundOurTeam[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.getFundOurTeam.api,
  });

  return result.data;
};

export const createClientTeamUser = async (data: any): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.createClientTeamUser.api,
  });

  return result.data;
};

export const updateClientTeamUser = async (id: string, data: any): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: CLIENT_API.updateClientTeamUser.api(id),
  });

  return result.data;
};

export const deleteClientTeamUser = async (userId: string, clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: CLIENT_API.deleteClientTeamUser.api(userId, clientId),
  });

  return result.data;
};

export const fundSubmitClientTeam = async (data: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.fundSubmitClientTeam.api,
  });

  return result.data;
};

export const fundSubmitBranding = async (data: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: CLIENT_API.fundSubmitBranding.api,
  });

  return result.data;
};

export const fundSubmitClient = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data: { id },
    url: CLIENT_API.fundSubmitClient.api,
  });

  return result.data;
};

export const fundResendClientTeamUserEmail = async (userId: string, clientId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: { clientId },
    url: CLIENT_API.fundResendClientTeamUserEmail.api(userId),
  });

  return result.data;
};

export const fundGetClientTeamUserFunds = async (
  clientId: string,
): Promise<IClientTeamUserFund[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.fundGetClientTeamUserFunds.api(clientId),
  });

  return result.data;
};

export const generateManualInvoice = async (
  clientId: string,
  billingId: string,
  data: IManualInvoiceBody,
): Promise<IClientTeamUserFund[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: CLIENT_API.generateManualInvoice.api(clientId, billingId),
    data,
  });

  return result.data;
};

export const getInvoices = async (clientId: string, params?: BaseQuery): Promise<Invoice[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.getInvoices.api(clientId),
    params,
  });

  return result.data;
};

export const downloadInvoice = async (clientId: string, invoiceId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: CLIENT_API.downloadInvoice.api(clientId, invoiceId),
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const updateClientTeamUserStatus = async ({
  clientId,
  userId,
}: {
  clientId: string;
  userId: string;
}): Promise<any> => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: CLIENT_API.updateClientTeamUserStatus.api(clientId, userId),
  });

  return result.data;
};
