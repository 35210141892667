import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UnLockIcon } from 'src/assets/icons/UnLockIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import FormPassword from 'src/components/atoms/FormPassword';
import { IErrorResponse } from 'src/interfaces/common';
import { useChangePassword } from 'src/modules/auth/hooks';
import { getErrorMessageFromServer } from 'src/utils/common';
import { changePasswordValidationSchema } from 'src/validations/reset-password';

interface IChangePasswordProps {
  onChangePasswordSuccess: () => void;
}

interface IChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: FC<IChangePasswordProps> = ({ onChangePasswordSuccess }) => {
  const { mutate: changePasswordMutate, isLoading } = useChangePassword();

  const changePasswordForm = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(changePasswordValidationSchema),
  });

  const { formState, setError } = changePasswordForm;

  const onChangePassword = (data: IChangePasswordFormData) => {
    changePasswordMutate(data, {
      onSuccess() {
        onChangePasswordSuccess();
      },
      onError(error: IErrorResponse | unknown) {
        const errorResponse = error as IErrorResponse;
        setError('oldPassword', {
          message: getErrorMessageFromServer(errorResponse),
        });
      },
    });
  };

  return (
    <Box className='pt-22.5 px-15 pb-14 w-[485px]'>
      <Box className='flex justify-center'>
        <UnLockIcon />
      </Box>
      <Typography variant='h5' align='center'>
        Change password
      </Typography>
      <FormProvider {...changePasswordForm}>
        <Box className='pt-10' component='form'>
          <Stack gap={3}>
            <FormPassword
              name='oldPassword'
              label='Current password'
              placeholder='Enter current password'
              size='small'
              fullWidth
            />
            <FormPassword
              name='newPassword'
              label='New password'
              isShowRules
              placeholder='Enter new password'
              size='small'
              fullWidth
            />
            <FormPassword
              name='confirmPassword'
              label='Confirm new password'
              placeholder='Confirm new password'
              size='small'
              fullWidth
            />
            <CustomButton
              onClick={changePasswordForm.handleSubmit(onChangePassword)}
              disabled={!formState.isDirty}
              isLoading={isLoading}
            >
              Change Password
            </CustomButton>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ChangePassword;
