import { FUNDS_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import {
  FundQueryBody,
  IExisting,
  IFinancialDetailsParams,
  IFundContactDetailParams,
  IFundDetailParams,
  IFundOverviewAndBrading,
  IServiceProvider,
  ISignatoryContactParams,
  ITrusteeAndServiceProvidersCapacitiesRes,
  ITrusteeGPParams,
  IUpsertFundTeamParams,
  IUpsertServiceProvider,
  UnitClassQueryBody,
} from './type';

export const createFundDetails = async (params: IFundDetailParams) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: FUNDS_API.createFundDetails.api,
    data: params,
  });
  return result.data;
};

export const updateFundDetails = async (params: IFundDetailParams, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.updateFundDetails.api(id),
    data: params,
  });
  return result.data;
};

export const getOfferTypes = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.getOfferTypes.api,
  });

  return result.data;
};

export const getFundList = async (data: FundQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUNDS_API.getFundList.api,
  });

  return result.data;
};

export const exportFundList = async (data: FundQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUNDS_API.exportFundList.api,
  });

  return result.data;
};

export const getFundFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.getFundFilter.api,
  });

  return result.data;
};

export const getFundById = async (id: string, step: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.fundById.api(id),
    params: { step },
  });

  return result.data;
};

export const deleteFundById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: FUNDS_API.fundById.api(id),
  });

  return result.data;
};

export const updateFundStatus = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.updateFundStatus.api(id),
  });

  return result.data;
};

export const upsertFundContactDetails = async (params: IFundContactDetailParams, id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.upsertFundContact.api(id),
    data: params,
  });
  return result.data;
};

export const checkFundCode = async (params: { id: string; code: string }, fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: FUNDS_API.checkFundCode.api(fundId),
    data: params,
  });
  return result.data;
};

export const getSignatoryContacts = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.getSignatoryContacts.api(fundId),
  });

  return result.data;
};

export const upsertSignatoryContacts = async (params: ISignatoryContactParams, fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.upsertSignatoryContacts.api(fundId),
    data: params,
  });
  return result.data;
};

export const getTrusteeAndServiceProvidersCapacities =
  async (): Promise<ITrusteeAndServiceProvidersCapacitiesRes> => {
    const result = await portalApi({
      method: REQUEST_METHOD.GET,
      url: FUNDS_API.getTrusteeAndServiceProvidersCapacites.api,
    });

    return result.data;
  };

export const getExisting = async (endPointWithQueryParams: string): Promise<IExisting[]> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: endPointWithQueryParams,
  });

  return result.data;
};

export const upsertServiceProvider = async (params: IUpsertServiceProvider, id?: string) => {
  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    url: FUNDS_API.upsertServiceProvider.api(id),
    data: params,
  });

  return result.data;
};

export const getDetailServiceProvider = async (
  id: string,
  endPointWithQueryParams: string,
): Promise<IServiceProvider> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: endPointWithQueryParams,
  });

  return result.data;
};

export const upsertTrusteeGP = async (params: ITrusteeGPParams, fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: params,
    url: FUNDS_API.upsertTrusteeGP.api(fundId),
  });

  return result.data;
};

export const upsertOverviewAndBranding = async (
  params: IFundOverviewAndBrading,
  fundId: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.upsertOverviewAndBranding.api(fundId),
    data: params,
  });
  return result.data;
};

export const upsertFinancialDetails = async (params: IFinancialDetailsParams, fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.upsertFinancialDetails.api(fundId),
    data: params,
  });
  return result.data;
};

export const getFundContacts = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.getFundContacts.api(fundId),
  });

  return result.data;
};

export const getUnitClassList = async (fundId: string, data: UnitClassQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUNDS_API.getUnitClassList.api(fundId),
  });

  return result.data;
};

export const getUnitClassFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUNDS_API.getUnitClassFilter.api,
  });

  return result.data;
};

export const deleteUnitClassById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: FUNDS_API.unitClassById.api(id),
  });

  return result.data;
};

export const archivedUnitClassById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.archivedUnitClassById.api(id),
  });

  return result.data;
};

export const getFundTeamByUserType = async (fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: FUNDS_API.getFundTeamByUserType.api(fundId),
  });
  return result.data;
};

export const upsertFundTeam = async (params: IUpsertFundTeamParams, fundId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: FUNDS_API.upsertFundTeam.api(fundId),
    data: params,
  });
  return result.data;
};

export const createFund = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: FUNDS_API.fundById.api(id),
  });

  return result.data;
};

export const deleteFundBrandingDocument = async (params: { docId: string; fundId: string }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: FUNDS_API.deleteFundBradingDocument.api(params.fundId),
    data: { documentId: params.docId },
  });

  return result.data;
};
