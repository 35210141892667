import { isEmpty } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import HorizontalTabs, {
  IHorizontalTabsElement,
  ITab,
} from 'src/components/molecules/HorizontalTabs';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { DistributionInstructionType } from 'src/constants/common';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import {
  IApplicationAddtional,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { IDocument } from 'src/modules/common/type';
import DocumentUpload from './DocumentUpload';
import InvestmentDetails from './InvestmentDetails';

interface IAdditionalProps extends IUpsertInvestmentApplicationForm {
  allowDRP?: boolean;
  investorType: number | null;
  investmentAmount: number;
  applicationReviewDocument?: {
    enable: boolean;
    data?: IDocument;
    setData: (_data: IDocument) => void;
    removeDocument: (_id: string) => void;
  };
  activeTab?: number;
  setActiveTab?: Dispatch<SetStateAction<number>>;
}

const Additional: FC<IAdditionalProps> = (props) => {
  const { id = '', allowDRP, currentStep, investmentEntityId, activeTab, setActiveTab } = props;
  const horizontalTabsRef = useRef<IHorizontalTabsElement>(null);

  const { data: applicationDetails } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.ADDITIONAL,
  });
  const {
    reset,
    formState: { errors: additionalErrors },
  } = useFormContext<IApplicationAddtional>();

  useEffect(() => {
    if (isEmpty(applicationDetails?.additional)) return;
    handleFillData();
  }, [applicationDetails?.additional]);

  useEffect(() => {
    if (Number.isInteger(Number(activeTab))) {
      horizontalTabsRef.current?.setTab(Number(activeTab));
    }
  }, [activeTab]);

  const handleFillData = () => {
    reset({
      ...(applicationDetails?.additional || {}),
      distributionInstructionType:
        applicationDetails?.additional.distributionInstructionType ||
        DistributionInstructionType.PaymentToTheNominatedBankAccount,
      sourceOfFundsType: Number.isInteger(applicationDetails?.additional.sourceOfFundsType)
        ? applicationDetails?.additional.sourceOfFundsType
        : null,
    });
  };

  const handleTabChange = (tab: number) => setActiveTab && tab !== activeTab && setActiveTab(tab);

  const Tabs: ITab[] = [
    {
      label: 'Investment Details',
      content: <InvestmentDetails {...props} />,
      isError: !isEmpty(additionalErrors),
    },
    {
      label: 'Document Upload',
      content: <DocumentUpload {...props} investmentEntityId={investmentEntityId} />,
    },
  ];

  return <HorizontalTabs ref={horizontalTabsRef} tabs={Tabs} onTabChange={handleTabChange} />;
};

export default Additional;
