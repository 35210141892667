import { Box, Divider, Grid, IconButton, Radio, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad, { default as SignatureCanvas } from 'react-signature-canvas';
import CloseIcon from 'src/assets/icons/CloseIcon';
import ContractIcon from 'src/assets/icons/ContractIcon';
import DigitalSignatureIcon from 'src/assets/icons/DigitalSignatureIcon';
import ShapeSignIcon from 'src/assets/icons/ShapeSignIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomAlert from 'src/components/atoms/CustomAlert';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomEditor from 'src/components/atoms/CustomEditor';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import StatusBadge from 'src/components/molecules/StatusBadge';
import {
  APPLICATION_SIGNATORY_STATUS,
  ApplicationLetterType,
  SignatoryMethodType,
} from 'src/constants/applications';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { detectPortalType } from 'src/helpers/common';
import {
  useDownloadDeclarationDocument,
  useGetReceivedSignatoryTypes,
} from 'src/modules/applications/hooks';
import {
  IAcceptanceSignatory,
  IExecutionMethod,
  ISignatoryFile,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { useDownloadDocumentByPath } from 'src/modules/common/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

interface ISignatoryProps extends IUpsertInvestmentApplicationForm {
  method?: string;
  user: IAcceptanceSignatory;
  ordered: number;
  declaration: string;
  keyName: string;
  applicationReview?: {
    enabled?: boolean;
    disabled?: boolean;
  };
  isAlreadySelectedManualOrDocuSignBefore: boolean;
  offerId: string;
  isLoadingMarkSigned: boolean;
  isDisabledAllSignatoryMethod?: boolean;
  isEnableSignNowMethod?: boolean;
  handleMarkSigned: (_index: number) => void;
  onChangeMethod: (_method: SignatoryMethodType) => void;
  executionMethods?: IExecutionMethod[];
}
interface IMethodOption extends RadioOption {
  caption?: string;
}

const Signatory: React.FC<ISignatoryProps> = ({
  user,
  isViewMode,
  ordered,
  declaration,
  id = '',
  keyName,
  applicationReview,
  isAlreadySelectedManualOrDocuSignBefore,
  isLoadingMarkSigned,
  handleMarkSigned,
  onChangeMethod,
  isDisabledAllSignatoryMethod = false,
  isEnableSignNowMethod,
  executionMethods,
  isCreateFundApplicationForm,
}) => {
  const { isFunds } = detectPortalType();
  const { setValue, watch, getFieldState } = useFormContext();
  const { mutate: downloadDocumentByPath } = useDownloadDocumentByPath(true);

  const error = getFieldState(keyName)?.error;

  const { mutate: downloadDeclarationDocumentMutate } = useDownloadDeclarationDocument();
  const { data: receivedSignatoryTypes = [] } = useGetReceivedSignatoryTypes(id);
  const [signedSignatory, setSignedSignatory] = useState<string>('');
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const receivedType = watch(`${keyName}.receivedType`);
  const receivedTypeName = watch(`${keyName}.receivedTypeName`);
  const signedDate = watch(`${keyName}.signedDate`);
  const isReadDeclaration = watch(`signatories.${ordered}.isReadDeclaration`);

  const padRef = useRef<SignatureCanvas>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);

  const methodSelected: SignatoryMethodType | undefined = watch(`signatories.${ordered}`)?.method;
  const isApplicationReview = applicationReview?.enabled;

  useEffect(() => {
    !isReadDeclaration && clear();
  }, [isReadDeclaration]);

  useEffect(() => {
    if (
      user?.method === SignatoryMethodType.SignNow &&
      !isEmpty(user?.signatoryFile) &&
      !isCleared &&
      !isFunds
    ) {
      handleDownloadDocumentByPath(user?.signatoryFile);
    }
  }, [user?.signatoryFile?.path]);

  const disabled =
    (isViewMode && !isApplicationReview) ||
    (isApplicationReview && applicationReview.disabled) ||
    user.status === APPLICATION_SIGNATORY_STATUS.Signed;

  const signatoriesMethod = {
    [SignatoryMethodType.DocuSign]: {
      icon: <DigitalSignatureIcon width='50%' height='100%' />,
      label: 'DocuSign',
      caption: 'Electronically sign via DocuSign',
      bgColor: 'inReview100',
      color: 'inReview500',
      value: 0,
    },
    [SignatoryMethodType.SignNow]: {
      icon: <ShapeSignIcon width='50%' height='100%' />,
      label: 'Sign Now',
      caption: 'Sign electronically now via e-signature',
      bgColor: 'withdrawn100',
      color: 'withdrawn500',
      value: 1,
    },
    [SignatoryMethodType.Manual]: {
      icon: <ContractIcon width='50%' height='100%' />,
      label: 'Manual',
      caption: 'Download, sign and return via email or post',
      bgColor: 'actionRequired100',
      color: 'actionRequired500',
      value: 2,
    },
  };

  const handleClickDocument = async () => {
    downloadDeclarationDocumentMutate(
      {
        applicationId: id,
        typeLetter: ApplicationLetterType.Application,
        contactId: user.id,
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const handleDownloadDocumentByPath = (file?: ISignatoryFile) => {
    if (!file?.path && file?.fileName) return '';
    downloadDocumentByPath(
      { path: file?.path || '', fileName: file?.fileName || '' },
      {
        onSuccess: (data: any) => {
          setSignedSignatory(data);
          setValue(`signatories.${ordered}.isReadDeclaration`, true);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleChangeEntityType = (value: SignatoryMethodType) => {
    if (value == SignatoryMethodType.SignNow) {
      modalConfirmRef?.current?.open();
    } else {
      setValue(`signatories.${ordered}.isReadDeclaration`, false);
    }

    if (value == SignatoryMethodType.Manual) {
      // Disable request download when select item manual.
      // handleClickDocument();
    }

    // 1 method will be apply for all if there arent any method existing
    onChangeMethod(value);
  };

  const trim = () => {
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
    const base64 = url?.split(',')?.[1]; // [0]: data:image/png;base64 (REMOVED)
    const data: IAcceptanceSignatory = {
      ...user,
      isSendMail: false,
      signature: base64 || '',
    };
    setFormValue(data);
  };

  const setFormValue = (data: IAcceptanceSignatory) =>
    setValue(`signatories.${ordered}`, data, {
      shouldDirty: true,
      shouldValidate: true,
    });

  const clear = () => {
    setIsCleared(true);
    setSignedSignatory('');
    setValue(`signatories.${ordered}.signature`, '');
    setValue(`signatories.${ordered}.signatoryFile`, null);
    padRef.current?.clear();
  };

  const handleCloseDeclarationModal = () => {
    modalConfirmRef?.current?.close();
  };

  const renderMethodTypeOptions = (options: RadioOption[], _: any, disabled?: boolean) => {
    return (
      <>
        <Grid container spacing={3}>
          {options.map((option: IMethodOption, index: number) => {
            const value = option.value;
            const isSelected = methodSelected === value;
            const isDisabled =
              disabled ||
              (value === SignatoryMethodType.SignNow &&
                (!user.isCurrentUser || isApplicationReview || !isEnableSignNowMethod));

            return (
              <Grid item xs={3} className='w-full' key={index}>
                <button
                  className='flex flex-col items-center w-full'
                  onClick={() => handleChangeEntityType(value as number)}
                  disabled={isDisabled}
                >
                  <Box
                    borderColor={isSelected ? 'primary.pr500' : 'neutral.ne300'}
                    sx={{
                      borderWidth: 2,
                      borderStyle: 'dashed',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: ' 0 10px',
                    }}
                    borderRadius='15px'
                    width='100%'
                    height='145px'
                    px={0.75}
                    py={0.5}
                    bgcolor='base.white'
                  >
                    {option.icon}
                  </Box>
                  <Typography mt={0.75} variant='body3' fontWeight={500}>
                    {option.label}
                  </Typography>
                  <Typography mt={0.75} variant='caption' fontWeight={500} color='neutral.ne800'>
                    {option?.caption}
                  </Typography>
                </button>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderManualSignOptions = (options: RadioOption[], _: any, disabled?: boolean) => {
    return (
      <>
        <Grid container columnSpacing={3} rowSpacing={2.25}>
          {options.map((option) => (
            <Grid key={option.value} item xs={4}>
              <Box
                className='border rounded-xl px-3 py-0.5 flex justify-between items-center'
                borderColor={option.value === receivedType ? 'primary.main' : 'neutral.ne400'}
              >
                <CustomFormControlLabel
                  key={option.value}
                  defaultValue={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{
                    width: '100%',
                  }}
                  disabled={disabled}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const manualSignOptions = useMemo(() => {
    const rest = receivedSignatoryTypes?.map((it) => ({
      label: it.name,
      value: it.id,
    }));
    if (receivedType && !rest.find((it) => it.value === receivedType)) {
      rest.push({ label: receivedTypeName, value: receivedType });
    }
    return rest;
  }, [receivedSignatoryTypes, receivedType]);

  const getSignatoryMethod = () => {
    return Object.values(signatoriesMethod).filter((it) => {
      const isAllowedMethod =
        (executionMethods || []).findIndex((method) => method.id === it.value) >= 0;
      return isCreateFundApplicationForm
        ? it.value !== SignatoryMethodType.SignNow && isAllowedMethod
        : isAllowedMethod;
    });
  };

  return (
    <Box>
      <Accordion
        className='bg-white border border-solid'
        sx={{ borderColor: error ? 'error.main' : 'neutral.ne200', p: 3 }}
        expanded
      >
        <AccordionSummary
          sx={{
            '.MuiAccordionSummary-content': {
              my: '0',
              display: 'flex',
              justifyContent: 'space-between',
            },
            minHeight: 'unset',
          }}
        >
          <Box className='flex items-center'>
            <Typography variant='body2'>
              {user.firstName} {user.lastName}
            </Typography>

            {Number.isInteger(methodSelected) && methodSelected !== undefined && (
              <Typography
                variant='body3'
                className='ml-4 px-2 py-0.5 rounded-lg'
                fontWeight={500}
                bgcolor={`sematic.${signatoriesMethod[methodSelected]?.bgColor}`}
                color={`sematic.${signatoriesMethod[methodSelected]?.color}`}
              >
                {signatoriesMethod[methodSelected]?.label}
              </Typography>
            )}
          </Box>
          {user.statusName && <StatusBadge status={user.statusName} showDot={false} />}
        </AccordionSummary>
        <AccordionDetails className='flex flex-col gap-[18px] pt-6'>
          <Box className='p-6 rounded-xl flex' bgcolor='neutral.ne100'>
            <Grid container spacing={1}>
              <Grid item xs={12} className='w-full'>
                <Typography variant='body2' fontWeight={500}>
                  Please select signatory method
                </Typography>
              </Grid>
              <Grid item xs={12} className='w-full'>
                <Box className='flex '>
                  <FormRadioGroup
                    containerSx={{
                      '.MuiStack-root': {
                        gap: 0,
                        justifyContent: 'space-between',
                        mr: '-11px',
                      },
                      '.MuiFormControlLabel-root': { mr: 0 },
                    }}
                    name={`${keyName}.method`}
                    options={getSignatoryMethod()}
                    renderOptions={renderMethodTypeOptions}
                    isEnumValue
                    disabled={disabled || isDisabledAllSignatoryMethod}
                  />
                </Box>
              </Grid>
              {[SignatoryMethodType.DocuSign, SignatoryMethodType.Manual].includes(
                methodSelected as any,
              ) && (
                <Grid item xs={12} mt={2}>
                  <FormInput
                    defaultValue={user?.email}
                    name={`${keyName}.email`}
                    label='Email Address'
                    size='small'
                    placeholder='Enter email address'
                    className='w-full'
                    disabled={disabled}
                  />
                </Grid>
              )}
              {methodSelected === SignatoryMethodType.SignNow && user.isCurrentUser && (
                <>
                  <Grid item xs={12} mt={2}>
                    <FormCheckbox
                      disabled={disabled || !isReadDeclaration}
                      labelSx={{ pl: 1.5 }}
                      label={
                        <Typography variant='body3' fontWeight={400}>
                          I certify that the tax residency information I have provided is true and
                          correct. I will provide an updated Tax Status Declaration within 30 days
                          if any of the information given is incorrect.
                        </Typography>
                      }
                      name={`signatories.${ordered}.isReadDeclaration`}
                    />
                  </Grid>
                  {isReadDeclaration && (
                    <>
                      <Grid item xs={12} mt={2}>
                        <Box className='flex items-center'>
                          <Typography fontWeight={500} variant='body2'>
                            Signature
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={9} display={'flex'} flexDirection={'column'}>
                        {signedSignatory ? (
                          <img src={signedSignatory} className='w-[470px] h-full' />
                        ) : (
                          <SignaturePad
                            onEnd={trim}
                            ref={padRef}
                            canvasProps={{ width: 470, height: 188, className: 'sigCanvas' }}
                            backgroundColor='#FFFFFF'
                          />
                        )}
                        {(error as any)?.signature?.message && (
                          <Box mt={1}>
                            <CustomHelperText
                              variant={'error'}
                              message={(error as any)?.signature?.message}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <CustomButton
                          size='small'
                          variant='outlined'
                          onClick={clear}
                          sx={{ width: '120px', marginLeft: '20px' }}
                          disabled={disabled}
                        >
                          Clear
                        </CustomButton>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {isAlreadySelectedManualOrDocuSignBefore &&
                [SignatoryMethodType.Manual, SignatoryMethodType.DocuSign].includes(
                  methodSelected as number,
                ) &&
                isApplicationReview && (
                  <Grid container item xs={12} spacing={3} mt={0}>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name={`${keyName}.receivedType`}
                        isEnumValue
                        options={manualSignOptions}
                        renderOptions={renderManualSignOptions}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormDatePicker
                        name={`${keyName}.signedDate`}
                        label='Date Signed'
                        datePickerProps={{ disableFuture: true, format: DATE_PICKER_FORMAT }}
                        disabled={disabled}
                      />
                    </Grid>
                    {user?.markSignedBy && user?.markSignedDate && (
                      <Grid item xs={12}>
                        <CustomAlert severity='info'>{`Marked as signed by ${
                          user.markSignedBy
                        } on ${utcToLocalTimezone(user.markSignedDate)}.`}</CustomAlert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CustomButton
                        sx={{ px: 3, py: 1 }}
                        disabled={
                          disabled || !(Number.isInteger(receivedType) && signedDate?.isValid())
                        }
                        isLoading={isLoadingMarkSigned}
                        onClick={() => handleMarkSigned(ordered)}
                      >
                        Mark as Signed
                      </CustomButton>
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <Divider sx={{ my: 3, borderColor: 'neutral.ne200' }} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <BasicModal
        ref={modalConfirmRef}
        maxWidth='md'
        PaperProps={{ sx: { top: 0, alignSelf: 'center', width: '800px', padding: '20px' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
      >
        <Box className='flex flex-col '>
          <Box className='flex justify-center items-center p-[45px]'>
            <Typography variant='h6' align='center' sx={{ pl: 1.5, width: '100%' }}>
              DECLARATION
            </Typography>
            <Box className='absolute right-10 top-10'>
              <IconButton sx={{ p: 0 }} onClick={() => modalConfirmRef.current?.close()}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ padding: '10px 80px' }}>
            <CustomEditor
              name='declaration'
              initValue={declaration}
              readOnly
              toolbar={false}
              height={300}
              statusbar={false}
            />
          </Box>
          <Box sx={{ padding: '10px 80px' }}>
            <FormCheckbox
              disabled={isViewMode}
              labelSx={{ pl: 1.5 }}
              label={
                <Typography variant='body3' fontWeight={400}>
                  I have read and agree to the above declaration.
                </Typography>
              }
              name={`signatories.${ordered}.isReadDeclaration`}
            />
          </Box>
          <Box sx={{ padding: '10px 80px' }} borderColor='neutral.ne200'>
            <CustomButton
              className='flex justify-end'
              variant='contained'
              disabled={!isReadDeclaration}
              onClick={handleCloseDeclarationModal}
            >
              Accept
            </CustomButton>
          </Box>
        </Box>
      </BasicModal>
    </Box>
  );
};
export default Signatory;
