import * as React from 'react';

interface IFundRegistryIconProps extends React.SVGProps<SVGSVGElement> {}

const FundRegistryIcon: React.FunctionComponent<IFundRegistryIconProps> = ({
  color = 'white',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <rect opacity='0.01' width='24' height='24' fill={color} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8332 4.16699H4.1665C2.78579 4.16699 1.6665 5.28628 1.6665 6.66699V13.3337C1.6665 14.7144 2.78579 15.8337 4.1665 15.8337H15.8332C17.2139 15.8337 18.3332 14.7144 18.3332 13.3337V6.66699C18.3332 5.28628 17.2139 4.16699 15.8332 4.16699ZM3.33317 6.66699C3.33317 6.20675 3.70627 5.83366 4.1665 5.83366H15.8332C16.2934 5.83366 16.6665 6.20675 16.6665 6.66699V7.50033H3.33317V6.66699ZM15.8332 14.167C16.2934 14.167 16.6665 13.7939 16.6665 13.3337V9.16699H3.33317V13.3337C3.33317 13.7939 3.70627 14.167 4.1665 14.167H15.8332Z'
        fill={color}
      />
      <path
        d='M5.83333 12.4997H9.16667C9.6269 12.4997 10 12.1266 10 11.6663C10 11.2061 9.6269 10.833 9.16667 10.833H5.83333C5.3731 10.833 5 11.2061 5 11.6663C5 12.1266 5.3731 12.4997 5.83333 12.4997Z'
        fill={color}
      />
      <path
        d='M12.4998 12.4997H14.1665C14.6267 12.4997 14.9998 12.1266 14.9998 11.6663C14.9998 11.2061 14.6267 10.833 14.1665 10.833H12.4998C12.0396 10.833 11.6665 11.2061 11.6665 11.6663C11.6665 12.1266 12.0396 12.4997 12.4998 12.4997Z'
        fill={color}
      />
    </svg>
  );
};

export default FundRegistryIcon;
