import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import CustomBarChart, { barOptions } from 'src/components/atoms/CustomBarChart';
import CustomCollapsibleTable from 'src/components/atoms/CustomCollapsibleTable';
import { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomPieChart from 'src/components/atoms/CustomPieChart';
import { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import AmoutBox from 'src/components/pages/dashboard/components/AmoutBox';
import ChartContainer from 'src/components/pages/dashboard/components/ChartContainer';
import InvestmentEntityDetail from 'src/components/pages/dashboard/components/InvestmentEntityDetail';
import TableContainer from 'src/components/pages/dashboard/components/TableContainer';
import {
  useGetDashboardAllocations,
  useGetDashboardDetails,
  useGetDashboardFundAllocations,
  useGetDashboardSummary,
} from 'src/modules/dashboard/hooks';
import {
  IAllocationFromClientPortal,
  IDetailItemFromClientPortal,
  IFundAllocation,
  IFundDetailForClientPortal,
  IUpcomingCapitallCall,
} from 'src/modules/dashboard/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatCurrencyNumber, formatNumberWithCommas } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';

enum PIE_CHART_TYPE {
  Client,
  Fund,
}

interface InvestorDashboardProps {}

interface IClientTableData extends IFundDetailForClientPortal {}

interface IUpcomingCapitallCallTableData extends IUpcomingCapitallCall {}

const ClientDashboard: React.FC<InvestorDashboardProps> = () => {
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedFundId, setSelectedFundId] = useState('');
  const [openValue, setOpenValue] = React.useState('');

  const { isFAUser, isFaPortal, selectedClients, selectedFunds } = useFundPortalContext();
  const { data: allocationsData = [] } = useGetDashboardAllocations(selectedFundId);
  const { data: summaryData = {} } = useGetDashboardSummary(selectedClientId, selectedFundId);
  const { data: detailsData = [] } = useGetDashboardDetails(selectedClientId, selectedFundId);
  const { data: fundAllocationData = [] } = useGetDashboardFundAllocations(selectedClientId);
  const { summary: summaryValue, fundSummary = [] } = summaryData;

  // Reset data when change overarching filter
  useEffect(() => {
    resetFilter();
  }, [selectedClients, selectedFunds]);

  const resetFilter = () => {
    setSelectedFundId('');
    setSelectedClientId('');
  };

  const handleClickChart = (id: string, type: number) => {
    if (type === PIE_CHART_TYPE.Client) {
      setSelectedClientId(id);
      setSelectedFundId(''); // Reset Fund after changing the client
    } else {
      setSelectedFundId(id);
    }
  };

  const clientAllocationPieLabels = allocationsData
    .sort(
      (a: { percentOfNetValue: number }, b: { percentOfNetValue: number }) =>
        b.percentOfNetValue - a.percentOfNetValue,
    )
    .map((item: IAllocationFromClientPortal) => item.clientName);

  const clientAllocationPieData = allocationsData.map((item: IAllocationFromClientPortal) => ({
    id: item.clientId,
    nested: { value: item.percentOfNetValue.toFixed() },
  }));

  const fundAllocationPieLabels = fundAllocationData
    .sort(
      (a: { percentOfNetValue: number }, b: { percentOfNetValue: number }) =>
        b.percentOfNetValue - a.percentOfNetValue,
    )
    .map((item: IFundAllocation) => item.fund);

  const fundAllocationPieData = fundAllocationData.map((item: IFundAllocation) => ({
    id: item.fundId,
    nested: { value: item.percentOfNetValue.toFixed() },
  }));

  const labels = _.map(fundSummary, 'fund');
  const paidAmountData = _.map(fundSummary, 'paidAmount');
  const unpaidAmountData = _.map(fundSummary, 'unpaidAmount');
  const currentNetValueData = _.map(fundSummary, 'currentNetValue');

  const barData = {
    labels,
    datasets: [
      {
        label: 'Paid Amount',
        data: paidAmountData,
        backgroundColor: '#33B27F',
        stack: 'Stack 0',
      },
      {
        label: 'Unpaid Amount',
        data: unpaidAmountData,
        backgroundColor: '#E3F4EC',
        stack: 'Stack 0',
      },
      {
        label: 'Current Net Value',
        data: currentNetValueData,
        backgroundColor: '#00965F',
        stack: 'Stack 1',
      },
    ],
  };

  const clientColumns: ColumnProps<IClientTableData, 'action'>[] = [
    {
      title: 'Fund',
      key: 'fundName',
      sx: { minWidth: 50, width: '20%' },
    },
    {
      title: 'Committed Capital',
      key: 'committedCapital',
      sx: { minWidth: 50, width: '20%' },
      renderNode: (row) => (
        <StringNodeTable value={`${formatNumberWithCommas(row.committedCapital)} AUD`} />
      ),
    },
    {
      title: 'Unpaid Amount',
      key: 'unpaidAmount',
      sx: { minWidth: 50, width: '20%' },
      renderNode: (row) => (
        <StringNodeTable value={`${formatNumberWithCommas(row.unpaidAmount)} AUD`} />
      ),
    },
    {
      title: 'Number of Investors',
      key: 'numberOfInvestors',
      sx: { minWidth: 50, width: '10%' },
      renderNode: (row) => <StringNodeTable value={formatCurrencyNumber(row.numberOfInvestors)} />,
    },
    {
      title: 'Current Net Value',
      key: 'currentNetValue',
      sx: { minWidth: 50, width: '20%' },
      renderNode: (row) => (
        <StringNodeTable value={`${formatNumberWithCommas(row.currentNetValue)} AUD`} />
      ),
    },
    {
      title: 'Details',
      key: 'fundId',
      allowCollapse: true,
      sx: { minWidth: 50, width: '5%' },
      collapseContent: (row) => <InvestmentEntityDetail detail={row} />,
    },
  ];

  const upcomingCapitallCall: ColumnProps<IUpcomingCapitallCallTableData, 'action'>[] = [
    {
      title: 'Fund',
      key: 'fund',
      sx: { minWidth: 50, width: '20%' },
      sortBy: 'fund',
      sorter: true,
    },
    {
      title: 'Amount',
      key: 'amount',
      sx: { minWidth: 50, width: '20%' },
      sortBy: 'amount',
      sorter: true,
      renderNode: (row) => <StringNodeTable value={`${formatNumberWithCommas(row.amount)} AUD`} />,
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      sortBy: 'dueDate',
      sorter: true,
      sx: { minWidth: 50, width: '20%' },
      renderNode: (row) => <StringNodeTable value={utcToLocalTimezone(row.dueDate)} />,
    },
  ];

  const { summary, isShowEmpty } = useMemo(() => {
    let _summary = summaryValue,
      _isShowEmpty = false;
    if (selectedClients === MULTIPLE_SELECT_EMPTY || selectedFunds === MULTIPLE_SELECT_EMPTY) {
      _summary = {};
      _isShowEmpty = true;
    }
    return { summary: _summary, isShowEmpty: _isShowEmpty };
  }, [selectedFunds, selectedClients, summaryValue]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <Grid xs={3} item>
            <AmoutBox
              title='Funds Under Management'
              amount={`$ ${formatNumberWithCommas(summary?.fundUnderManagement, 2)}`}
            />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox
              title='Committed Capital'
              amount={`$ ${formatNumberWithCommas(summary?.committedCapital, 2)}`}
            />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox
              title='Unpaid Amount'
              amount={`$ ${formatNumberWithCommas(summary?.unpaidAmount, 2)}`}
            />
          </Grid>
          <Grid xs={3} item>
            <AmoutBox title='Number Of Investors' amount={summary?.numberOfInvestors} />
          </Grid>
        </Grid>
        {isFAUser && isFaPortal && (
          <Grid item xs={6}>
            <ChartContainer title='Client Allocation' height={isFAUser && isFaPortal ? 420 : 320}>
              {isEmpty(clientAllocationPieData) || isShowEmpty ? (
                <EmptyData isTable />
              ) : (
                <CustomPieChart
                  labels={clientAllocationPieLabels}
                  data={clientAllocationPieData}
                  onClick={(id: string) => handleClickChart(id, PIE_CHART_TYPE.Client)}
                />
              )}
            </ChartContainer>
          </Grid>
        )}
        <Grid item xs={isFAUser && isFaPortal ? 6 : 5}>
          <ChartContainer title='Fund Allocation' height={isFAUser && isFaPortal ? 420 : 320}>
            {isEmpty(fundAllocationPieData) || isShowEmpty ? (
              <EmptyData isTable />
            ) : (
              <CustomPieChart
                labels={fundAllocationPieLabels}
                data={fundAllocationPieData}
                onClick={(id: string) => handleClickChart(id, PIE_CHART_TYPE.Fund)}
              />
            )}
          </ChartContainer>
        </Grid>
        <Grid item xs={isFAUser && isFaPortal ? 12 : 7}>
          <ChartContainer title='Fund Summary' height={isFAUser && isFaPortal ? 420 : 320}>
            {isEmpty(barData) || isShowEmpty ? (
              <EmptyData isTable />
            ) : (
              <Box height='80%' width='100%'>
                <CustomBarChart
                  data={barData}
                  options={{
                    scales: {
                      ...barOptions.scales,
                      y: {
                        ...barOptions.scales.y,
                        ticks: {
                          callback: function (value: any) {
                            return '$' + formatNumberWithCommas(value);
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            )}
          </ChartContainer>
        </Grid>
        <Grid item xs={12}>
          {!isShowEmpty &&
            (detailsData || []).map((item: IDetailItemFromClientPortal) => {
              const isOpen = openValue === item.clientId;
              return (
                <Box key={`client-${item.clientId}`} pb={2}>
                  <Box
                    className='w-full'
                    bgcolor='base.white'
                    px='20px'
                    py='23px'
                    borderRadius='14px'
                  >
                    <Box
                      className='flex w-full'
                      onClick={() => setOpenValue(isOpen ? '' : item.clientId)}
                    >
                      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      <Typography variant='body2' textTransform='capitalize' ml={1}>
                        {item.clientName}
                      </Typography>
                    </Box>
                  </Box>
                  <Collapse
                    in={isOpen}
                    timeout='auto'
                    unmountOnExit
                    className={isOpen ? 'mt-[-30px]' : ''}
                  >
                    <Grid item xs={12} container>
                      <Grid xs={12} item mb={3}>
                        <TableContainer boxClassName='pt-0'>
                          <CustomCollapsibleTable columns={clientColumns} rows={item.funds} />
                        </TableContainer>
                      </Grid>
                      <Grid xs={12} item>
                        <TableContainer title='Upcoming Capital Calls'>
                          <CustomCollapsibleTable
                            columns={upcomingCapitallCall}
                            rows={item.upcomingCapitalCalls}
                            displayEmpty
                            emptyDescription='No Upcoming Capital Call'
                          />
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
              );
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientDashboard;
