import { actionPermissionKeys } from './common';

export const ROLE_ACTION = {
  edit: {
    key: 'edit',
    label: 'Edit',
    permissionKey: actionPermissionKeys.isEdit,
  },
  view: {
    key: 'view',
    label: 'View',
    permissionKey: actionPermissionKeys.isView,
  },
  delete: {
    key: 'delete',
    label: 'Delete',
    permissionKey: actionPermissionKeys.isDelete,
  },
};

export const FEATURES = {
  RoleManagement: 'RoleManagement',
  UserManagement: 'UserManagement',
  ClientManagement: 'ClientManagement',
  KYCBackOffice: 'KYCBackOffice',
  Communications: 'Communications',
  Billing: 'Billing',
  AuditTrails: 'AuditTrails',
  Dashboard: 'Dashboard',
};

export const ROLE_TYPE = {
  fullAdmin: 'fullAdmin',
  view: 'view',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
  download: 'download',
};

export const INVESTOR_FEATURE_NAME = {
  Dashboard: 'Dashboard',
  SettingsInvestmentEntity: 'SettingsInvestmentEntity',
  InvestorForms: 'InvestorForms',
  Applications: 'Applications',
  SettingsUserManagement: 'SettingsUserManagement',
  Communications: 'Communications',
  Documents: 'Documents',
  SettingsRoleList: 'SettingsRoleList',
  RequestVerify: 'RequestVerify',
  SettingsUnallocatedHoldings: 'SettingsUnallocatedHoldings',
};

export const FUNDS_FEATURE_TITLE = {
  Dashboard: 'Dashboard',
  Fundraising: 'Fundraising',
  Administration: 'Administration',
  Documents: 'Documents',
  Communications: 'Communications',
  Settings: 'Settings',
  AuditTrails: 'Audit Trail',
};

export const FUNDS_FEATURE_NAME = {
  SettingsUserManagement: 'SettingsUserManagement',
  SettingsRoleManagement: 'SettingsRoleManagement',
  SettingsClientManagement: 'SettingsClientManagement',
  AdministrationFunds: 'AdministrationFunds',
  AdministrationReports: 'AdministrationReports',
  FundraisingOffers: 'FundraisingOffers',
  SettingsOrganisationSettings: 'SettingsOrganisationSettings',
  KYCBackOffice: 'KYCBackOffice',
  AdministrationInvestmentEntity: 'AdministrationInvestmentEntity',
  AdministrationInvestor: 'AdministrationInvestor',
  FundraisingApplications: 'FundraisingApplications',
  FundraisingCapitalCalls: 'FundraisingCapitalCalls',
  Documents: 'Documents',
  Communications: 'Communications',
  AuditTrails: 'AuditTrails',
  FundRegistry: 'FundRegistry',
};

export const FUND_ROLE_TYPE = {
  FundInternal: {
    id: 0,
    label: 'Internal',
  },
  FundClient: {
    id: 1,
    label: 'Client',
  },
};

export const FUND_ORDERED_FEATURES = [
  FUNDS_FEATURE_NAME.FundraisingOffers,
  FUNDS_FEATURE_NAME.FundraisingApplications,
  FUNDS_FEATURE_NAME.FundraisingCapitalCalls,
  FUNDS_FEATURE_NAME.KYCBackOffice,
  FUNDS_FEATURE_NAME.AdministrationInvestor,
  FUNDS_FEATURE_NAME.AdministrationInvestmentEntity,
  FUNDS_FEATURE_NAME.AdministrationFunds,
  FUNDS_FEATURE_NAME.AdministrationReports,
  FUNDS_FEATURE_NAME.FundRegistry,
  FUNDS_FEATURE_NAME.Documents,
  FUNDS_FEATURE_NAME.Communications,
  FUNDS_FEATURE_NAME.SettingsUserManagement,
  FUNDS_FEATURE_NAME.SettingsRoleManagement,
  FUNDS_FEATURE_NAME.SettingsClientManagement,
  FUNDS_FEATURE_NAME.SettingsOrganisationSettings,
];

export const GAP_ORDERED_FEATURES = [
  FEATURES.Dashboard,
  FEATURES.ClientManagement,
  FEATURES.KYCBackOffice,
  FEATURES.Communications,
  FEATURES.AuditTrails,
  FEATURES.UserManagement,
  FEATURES.RoleManagement,
];

export const INVESTOR_ORDERED_FEATURES = [
  INVESTOR_FEATURE_NAME.Dashboard,
  INVESTOR_FEATURE_NAME.Applications,
  INVESTOR_FEATURE_NAME.InvestorForms,
  INVESTOR_FEATURE_NAME.Documents,
  INVESTOR_FEATURE_NAME.Communications,
  INVESTOR_FEATURE_NAME.SettingsInvestmentEntity,
  INVESTOR_FEATURE_NAME.SettingsUnallocatedHoldings,
  INVESTOR_FEATURE_NAME.SettingsUserManagement,
  INVESTOR_FEATURE_NAME.SettingsRoleList,
];
