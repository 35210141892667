import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import EmptyData from 'src/components/molecules/EmptyData';
import { IAuditTrail } from 'src/modules/kyc-back-office/type';
import AuditTrailDetail from './AuditTrailDetail';

interface IAuditTrailProps {
  data?: IAuditTrail[];
}

const InvestorDetailsAuditTrails: React.FC<IAuditTrailProps> = ({ data = [] }) => {
  return (
    <Grid container spacing={3} mt={1}>
      {!isEmpty(data) ? (
        data?.map((item: IAuditTrail, index: number) => (
          <Grid item xs={12} key={`audit-trail-${index}`}>
            <AuditTrailDetail item={item} />
          </Grid>
        ))
      ) : (
        <EmptyData />
      )}
    </Grid>
  );
};

export default InvestorDetailsAuditTrails;
