import { Box, BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';

interface IFormArrayLayoutProps extends BoxProps {
  title: string;
  tooltip?: string;
  onAddItem: () => void;
  helperText?: ReactNode;
  disabled?: boolean;
  description?: ReactNode;
}

const FormArrayLayout: React.FunctionComponent<IFormArrayLayoutProps> = ({
  children,
  title,
  tooltip,
  helperText = '',
  disabled,
  description = '',
  onAddItem,
}) => {
  return (
    <Box className='directorsForm'>
      {tooltip ? (
        <Box className='pb-4'>
          <LabelWithTooltip
            label={
              <Typography variant='body3' fontWeight={600}>
                {title}
              </Typography>
            }
            tooltip={tooltip}
            placement='right'
          />
        </Box>
      ) : (
        <Typography className='pb-4 block' variant='body3' fontWeight={600}>
          {title}
        </Typography>
      )}
      {!!description && (
        <Typography className='pb-4 -mt-2 block' variant='caption1' color='neutral.ne800'>
          {description}
        </Typography>
      )}
      {children}
      {!disabled && (
        <CustomButton
          size='small'
          startIcon={<PlusPrimaryIcon />}
          variant='text'
          sx={{ px: 1, py: 0, mt: 0, ml: -1, color: 'primary' }}
          onClick={onAddItem}
        >
          Add new
        </CustomButton>
      )}
      {helperText && helperText}
    </Box>
  );
};

export default FormArrayLayout;
