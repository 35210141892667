import { isEmpty } from 'lodash';
import qs from 'qs';
import { DASHBOARD_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { detectPortalType } from 'src/helpers/common';

const { isInvestor, isFunds } = detectPortalType();

export const getDashboardSummary = async (id?: string, fundId?: string) => {
  const params: {
    investmentEntityId: string | null;
    clientId: string | null;
    fundId: string | null;
  } = {
    investmentEntityId: null,
    clientId: null,
    fundId: null,
  };
  if (id) {
    if (isInvestor) {
      params.investmentEntityId = id;
    } else if (isFunds) {
      // Filtering by Fund and Client
      params.clientId = id;
      fundId && (params.fundId = fundId);
    }
  } else if (fundId) {
    // Filtering by Fund Only (without Client)
    params.fundId = fundId;
  }
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: DASHBOARD_API.getDashboardSummary.api,
    params,
  });

  return result.data;
};

export const getDashboardSummaryByClient = async (cliendId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: DASHBOARD_API.getDashboardSummaryByClient.api(cliendId),
  });

  return result.data;
};

export const getDashboardAllocations = async (fundId?: string) => {
  const params: any = {};
  if (fundId) params.fundId = fundId;

  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: DASHBOARD_API.getDashboardAllocations.api,
  });

  return result.data;
};

export const getDashboardDetails = async (id?: string, fundId?: string) => {
  const params: {
    investmentEntityId: string | null;
    clientId: string | null;
    fundId: string | null;
  } = {
    investmentEntityId: null,
    clientId: null,
    fundId: null,
  };
  if (id) {
    if (isInvestor) {
      params.investmentEntityId = id;
    } else if (isFunds) {
      params.clientId = id;
      fundId && (params.fundId = fundId);
    }
  }
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: DASHBOARD_API.getDashboardDetails.api,
    params,
  });

  return result.data;
};

export const getDashboardFundAllocations = async (clientId?: string) => {
  const params: {
    clientId: string | null;
  } = {
    clientId: null,
  };
  if (clientId) {
    params.clientId = clientId;
  }
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: DASHBOARD_API.getDashboardFundAllocations.api,
    params,
  });

  return result.data;
};

export const getDuplicationInfo = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: DASHBOARD_API.getDuplicationInfo.api(applicationId),
  });

  return result.data;
};

export const rejectDuplication = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: DASHBOARD_API.rejectDuplication.api(applicationId),
  });

  return result.data;
};

export const confirmDuplication = async (applicationId: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: DASHBOARD_API.confirmDuplication.api(applicationId),
  });

  return result.data;
};

export const getClientSummary = async (clientIds?: string[]) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      clientIds: isEmpty(clientIds) ? '' : clientIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    url: DASHBOARD_API.getClientSummary.api,
  });

  return result.data;
};

export const remoteAccess = async (clientId: string): Promise<{ token: string; url: string }> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: DASHBOARD_API.remoteAccess.api(clientId),
  });

  return result.data;
};
