import { Box, Typography } from '@mui/material';
import { IKYCMethod, IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import VerifyBiometric from './VerifyBiometric';
import VerifyConnectID from './VerifyConnectID';
import VerifyDocumentID from './VerifyDocumentID';

export default function VerifyNow(props: IVerifyIndividualItem) {
  const isVerified = props.individual?.overallStatus === IOverallStatus.VERIFIED;
  return (
    <Box m={-3}>
      <Typography
        variant='body2'
        color='neutral.ne800'
        className='select-none'
        my={3}
        fontWeight={500}
      >
        Please select verification method
      </Typography>
      <Box display='flex' flexDirection='row' gap={3}>
        {(!isVerified || props.individual?.verificationMethod === IKYCMethod.DOCUMENT_ID) && (
          <Box gap={1} width='calc(100% / 3)' display='flex' flexDirection='column'>
            <VerifyDocumentID {...props} isVerified={isVerified} />
          </Box>
        )}
        {(!isVerified || props.individual?.verificationMethod === IKYCMethod.BIOMETRIC) && (
          <Box gap={1} width='calc(100% / 3)' display='flex' flexDirection='column'>
            <VerifyBiometric {...props} isVerified={isVerified} />
          </Box>
        )}
        {(!isVerified || props.individual?.verificationMethod === IKYCMethod.CONNECTION_ID) && (
          <Box gap={1} width='calc(100% / 3)' display='flex' flexDirection='column'>
            <VerifyConnectID {...props} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
