import * as React from 'react';

interface IBillingIconProps {}

const BillingIcon: React.FunctionComponent<IBillingIconProps> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      width={24}
      height={24}
      viewBox='0 0 256 256'
      xmlSpace='preserve'
    >
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
      >
        <path
          d='M 74.635 55.709 c -1.002 0 -1.993 -0.251 -2.896 -0.746 c -1.688 -0.924 -2.845 -2.616 -3.094 -4.527 c -0.143 -1.096 0.63 -2.099 1.726 -2.241 c 1.097 -0.149 2.099 0.63 2.241 1.726 c 0.085 0.657 0.468 1.217 1.048 1.534 c 0.482 0.264 1.032 0.324 1.56 0.17 c 0.524 -0.153 0.958 -0.503 1.221 -0.982 c 0.543 -0.991 0.178 -2.238 -0.813 -2.78 l -3.89 -2.233 c -2.89 -1.581 -3.968 -5.264 -2.367 -8.189 c 1.603 -2.926 5.283 -4.005 8.21 -2.403 c 1.689 0.924 2.847 2.617 3.096 4.527 c 0.143 1.096 -0.63 2.099 -1.726 2.242 c -1.087 0.137 -2.099 -0.63 -2.241 -1.725 c -0.085 -0.658 -0.468 -1.217 -1.049 -1.535 c -0.993 -0.542 -2.238 -0.177 -2.781 0.814 c -0.541 0.99 -0.176 2.237 0.814 2.78 l 3.89 2.233 c 2.889 1.581 3.967 5.264 2.366 8.189 c -0.776 1.417 -2.057 2.447 -3.607 2.901 C 75.779 55.628 75.205 55.709 74.635 55.709 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 74.66 38.293 c -1.104 0 -2 -0.896 -2 -2 v -2.71 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 2.71 C 76.66 37.397 75.765 38.293 74.66 38.293 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 74.66 58.417 c -1.104 0 -2 -0.896 -2 -2 v -2.71 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 2.71 C 76.66 57.521 75.765 58.417 74.66 58.417 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 65.928 90 H 20.04 c -5.918 0 -10.732 -4.814 -10.732 -10.732 V 10.732 C 9.308 4.814 14.122 0 20.04 0 h 45.888 C 71.846 0 76.66 4.814 76.66 10.732 v 14.709 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 10.732 C 72.66 7.02 69.64 4 65.928 4 H 20.04 c -3.712 0 -6.732 3.02 -6.732 6.732 v 68.535 c 0 3.712 3.02 6.732 6.732 6.732 h 45.888 c 3.712 0 6.732 -3.021 6.732 -6.732 V 64.559 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 14.709 C 76.66 85.186 71.846 90 65.928 90 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 60.489 66.087 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 66.087 60.489 66.087 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 60.489 54.159 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 54.159 60.489 54.159 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 60.489 42.231 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 42.231 60.489 42.231 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 60.489 30.303 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 30.303 60.489 30.303 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 26.875 66.087 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 66.087 26.875 66.087 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 26.875 54.159 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 54.159 26.875 54.159 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 26.875 42.231 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 42.231 26.875 42.231 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
        <path
          d='M 26.875 30.303 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 30.303 26.875 30.303 z'
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#ffffff',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=' matrix(1 0 0 1 0 0) '
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
};

export default BillingIcon;
