import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  IInvestmentEntityForm,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import CreateFundApplicationDetails from './CreateFundApplicationDetails';
import InvestorApplicationDetails from './InvestorApplicationDetails';
export interface IDetailsProps extends IUpsertInvestmentApplicationForm {
  fundName?: string;
  allowForeignInvestors?: boolean;
  offerType?: number;
  currency?: string;
  approveInvestorTypeComponent?: ReactNode;
  bannerInvestorTypeComponent?: ReactNode;
  approveInvestmentAmountComponent?: ReactNode;
  bannerInvestorAmountComponent?: ReactNode;
  additionalComponent?: ReactNode;
  setIsAddNewEntityDetail?: Dispatch<SetStateAction<boolean>>;
  onSelectInvestorNumberForFundApplication?: (investmentEntityId?: string) => void;
  investmentEntityForm?: UseFormReturn<IInvestmentEntityForm, any>;
}

const Details: FC<IDetailsProps> = (props) => {
  const { isCreateFundApplicationForm } = props;
  return isCreateFundApplicationForm ? (
    <CreateFundApplicationDetails {...props} />
  ) : (
    <InvestorApplicationDetails {...props} />
  );
};
export default Details;
