export const bulkUploadStatusName = {
  Partially_Uploaded: 'Partially Uploaded',
  Pending: 'Pending',
  Uploaded: 'Uploaded',
  In_Progress: 'In Progress',
  Error: 'Error',
};

export enum BULK_UPLOAD_STATUS {
  PENDING,
  IN_PROGRESS,
  PARTIALLY_UPLOADED,
  UPLOADED,
  ERROR,
}

export enum BULK_UPLOAD_RESULT_STATUS {
  IMPORTED = 2,
  ERROR,
}

export const bulkUploadStatusOptions = [
  {
    label: 'Partially Uploaded',
    value: BULK_UPLOAD_STATUS.PARTIALLY_UPLOADED,
  },
  {
    label: 'Pending',
    value: BULK_UPLOAD_STATUS.PENDING,
  },
  {
    label: 'Uploaded',
    value: BULK_UPLOAD_STATUS.UPLOADED,
  },
  {
    label: 'In Progress',
    value: BULK_UPLOAD_STATUS.IN_PROGRESS,
  },
  {
    label: 'Error',
    value: BULK_UPLOAD_STATUS.ERROR,
  },
];

export const bulkUploadResultStatusName = {
  Error: 'Error',
  Imported: 'Imported',
};

export const bulkUploadResultStatusOptions = [
  {
    label: bulkUploadResultStatusName.Error,
    value: BULK_UPLOAD_RESULT_STATUS.ERROR,
  },
  {
    label: bulkUploadResultStatusName.Imported,
    value: BULK_UPLOAD_RESULT_STATUS.IMPORTED,
  },
];

export const BULK_UPLOAD_FILE_ACCEPT_TYPE = '.csv';
