/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Grid,
  Typography,
  debounce,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';
import {
  AbnAcnTypeCode,
  INVESTMENT_ENTITY_TYPE,
  abnAcnNotAllowedStatus,
  investmentEntitySoleTraderDefaultValues,
} from 'src/constants/applications';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import { formatValueWithSpaces } from 'src/utils/common';
import { INVESTMENT_ENTITY_STEP } from '../InvestmentEntityForm';
import AddNew from './AddNew';

interface ISoleTraderProps extends IInvestmentEntityProps {}

const SoleTrader = (props: ISoleTraderProps) => {
  const { id = '', isViewMode, isInvestmentEntitySetting } = props;
  const { reset, watch, setValue, setError, clearErrors } = useFormContext<IInvestmentEntityForm>();
  const isEntityDetailKYCCompleted = watch('isEntityDetailKYCCompleted');
  const disabled = isViewMode || isEntityDetailKYCCompleted;
  const isDisabledAbn = !watch('investmentEntityDetails.allowEditAbn') || disabled;

  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.SoleTrader,
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntitySoleTraderDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    clearErrors('investmentEntityDetails.soleTrader.abn');
    const skyc = Array.isArray(data) ? data[0] : data;
    const isNotAllowed =
      AbnAcnTypeCode.ABN !== skyc?.status?.type ||
      abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

    if (reason === 'selectOption') {
      setValue('investmentEntityDetails.soleTrader.abn', skyc.value);
      setValue('investmentEntityDetails.soleTrader.businessName', skyc.label);
      if (isNotAllowed) {
        setError('investmentEntityDetails.soleTrader.abn', {
          message: 'ABN is inactive',
          type: 'invalidAbn',
        });
      }
    }
  };

  const options = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    status: item.status,
  }));

  const additionalDetail = (
    <>
      {!isInvestmentEntitySetting && (
        <Grid item xs={12}>
          <FormAutocomplete
            name=''
            dataKey='id'
            options={options}
            searchIcon={<SearchIcon />}
            isLoading={isSearchSkycLoading}
            placeholder='Search by business name, ABN, ACN'
            hidePopupIcon
            renderOption={(props, option) => (
              <li {...props}>
                <Box className='flex flex-col'>
                  <Typography variant='body3' fontWeight='medium'>
                    {option.label}
                  </Typography>
                  <Typography variant='caption1' color='neutral.ne500' paddingTop='4px'>
                    {`ABN: ${option.value}`}
                  </Typography>
                </Box>
              </li>
            )}
            renderTags={(selected) => (
              <Typography variant='body2'>
                <span className='line-clamp-1'>
                  {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
                </span>
              </Typography>
            )}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.value || ''}
            onInputChange={handleSearchSkycInputChange}
            onChange={handleSkycChange}
            disabled={disabled}
            blurOnSelect
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInput
          name='investmentEntityDetails.soleTrader.abn'
          label='ABN'
          placeholder='Enter ABN'
          formatValue={(value) => formatValueWithSpaces(value)}
          maxLength={14}
          onChange={() => clearErrors('investmentEntityDetails.soleTrader.abn')}
          disabled={disabled || isDisabledAbn}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name='investmentEntityDetails.soleTrader.businessName'
          label='Business Name (if any)'
          placeholder='Enter your business name'
          optional='Optional'
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          name='investmentEntityDetails.soleTrader.principalPlaceOfBusiness'
          label='Principal Place of Business'
          placeholder='Enter principal place of business'
          disabled={disabled}
        />
      </Grid>
    </>
  );

  return (
    <Box className='flex flex-col'>
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                Investment Entity Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <IndividualDetail
              {...props}
              keyName={`investmentEntityDetails.soleTrader`}
              disabledDetail={disabled}
              additionalDetail={additionalDetail}
              autocompleteAddress
              hideDeclaration
            />
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default SoleTrader;
