import { Box, Typography } from '@mui/material';
import { Chart as ChartJS, InteractionItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { Chart, getElementAtEvent } from 'react-chartjs-2';
import { detectPortalType } from 'src/helpers/common';

ChartJS.register(ChartDataLabels);

interface CustomPieChartProps {
  labels: string[];
  data: any;
  onClick?: (value: string) => void;
  customColors?: string[];
  dataLabelsOnChart?: boolean;
  dataLabelsFormatter?: (value: any) => void;
  tooltipEnabled?: boolean;
  hideNote?: boolean;
  borderWidth?: number;
  dataLabelsConfig?: any;
}

const FIXED_COLORS = [
  // Following the design: 10 colors
  '#1F422D',
  '#2A5442',
  '#386C54',
  '#4D856C',
  '#609D82',
  '#7BB699',
  '#97CFB3',
  '#BEE7CF',
  '#ADDAC1',
  '#BEE7CF',
];

const CustomPieChart: React.FC<CustomPieChartProps> = ({
  labels,
  data,
  onClick,
  customColors,
  dataLabelsOnChart = false,
  dataLabelsFormatter,
  tooltipEnabled = true,
  hideNote = false,
  borderWidth = 1,
  dataLabelsConfig = {},
}) => {
  const chartRef = useRef<ChartJS>(null);
  const { isAdmin } = detectPortalType();

  const handleClick = (event: any) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }
    const element: InteractionItem[] = getElementAtEvent(chart, event);
    if (!element.length) return;
    const { datasetIndex, index } = element[0];
    const value = chartData?.datasets[datasetIndex].data[index].id;
    onClick?.(value);
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: customColors || pieChartColors(labels),
        borderColor: 'white',
        borderWidth,
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: tooltipEnabled,
        callbacks: {
          label: (value: any) => value?.formattedValue + '%',
        },
      },
      datalabels: {
        ...dataLabelsConfig,
        display: dataLabelsOnChart,
        formatter: dataLabelsFormatter,
        color: '#ffffff',
        font: {
          size: 8,
          weight: 500,
        },
      },
    },
    parsing: {
      key: 'nested.value',
    },
  };

  return (
    <Box className='flex justify-evenly items-center'>
      <Box width={isAdmin ? '150px' : '200px'}>
        <Chart ref={chartRef} type='pie' data={chartData} onClick={handleClick} options={options} />
      </Box>

      <Box
        className={clsx(
          'h-[250px] overflow-y-auto w-[170px] flex flex-col gap-4',
          isAdmin ? 'ml-5' : 'ml-[40px]',
          {
            'justify-center': isAdmin,
          },
        )}
      >
        {!hideNote &&
          labels.map((item: string, index: number) => {
            const color = Array.isArray(customColors)
              ? customColors[index]
              : pieChartColors(labels)[index];
            return (
              <Box className='flex items-center' key={index} mb={1}>
                <Box width='11px' height='11px' bgcolor={color} mr='5px' />
                <Typography
                  className='inline-block'
                  variant='body3'
                  fontSize='10px'
                  color='neutral.ne800'
                  lineHeight='12px'
                >
                  {item}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default CustomPieChart;

const generateRandomColor = () => {
  const R = Math.floor(Math.random() * 127 + 127);
  const G = Math.floor(Math.random() * 127 + 127);
  const B = Math.floor(Math.random() * 127 + 127);

  const rgb = (R << 16) + (G << 8) + B;
  return `#${rgb.toString(16)}`;
};

const generateUniqueColor = (existingColors: string[]) => {
  let newColor = generateRandomColor();

  // Check if the generated color is already in the existing list
  while (existingColors.includes(newColor)) {
    newColor = generateRandomColor();
  }

  return newColor;
};

export const pieChartColors = (labels: string[]) => {
  const existingColors = FIXED_COLORS;
  while (existingColors.length <= labels.length) {
    const newColor = generateUniqueColor(existingColors);
    existingColors.push(newColor);
  }

  return existingColors;
};
