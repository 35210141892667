import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  ADMINISTRATION_INVESTOR_ACTION_NAME,
  GENERATE_STATEMENT_ERROR,
  REDEEM_OPTION,
  generateStatementDefaultValue,
  issueFormDefaultValue,
  offmarketTransferDefaultValue,
  recordPaymentDefaultValue,
  redemptionFormDefaultValue,
} from 'src/constants/administration-investor';
import {
  useCreateInvestorIssue,
  useCreateInvestorRedeem,
  useCreateOffmarketTransfer,
  useCreateRecordPayment,
  useGenerateStatement,
  usePreviewStatement,
} from 'src/modules/administration-investor/hooks';
import {
  IGenerateStatementForm,
  IIssueForm,
  IOffmarketTransfer,
  IRecordPayment,
  IRedemptionForm,
} from 'src/modules/administration-investor/types';
import { IAlertInfo } from 'src/modules/common/type';
import { blobResponseToJson, handleErrorFromServer } from 'src/utils/common';
import { getEndOfDateUTC } from 'src/utils/time';
import {
  createInvestorIssueSchema,
  createInvestorRedemptionSchema,
  createOffmarketTransferSchema,
  createRecordPaymentSchema,
  generateStatementSchema,
} from 'src/validations/administration-investor';
import { InvestorDetailsProps } from '.';
import GenerateStatement from './GenerateStatement';
import IssueForm from './IssueForm';
import { IssuePriceTab } from './IssueForm/IssueUnitPriceTabs';
import OffMarketTransfer from './OffMarketTransfer';
import RecordPayment from './RecordPayment';
import RedemptionForm from './RedemptionForm';
import { UnitPriceTabsEnum } from './RedemptionForm/RedemptionUnitPriceTabs';

interface IInvestorActionModalProps extends InvestorDetailsProps {
  selectedAction?: ADMINISTRATION_INVESTOR_ACTION_NAME;
  onClose: () => void;
  detailInvestmentAmount?: number | null;
  setAlertInfo: Dispatch<SetStateAction<IAlertInfo>>;
  onOpenAlert: () => void;
  setIsPreventClickOutside: Dispatch<SetStateAction<boolean>>;
  detailIssuedUnits?: number | null;
  investorUnitRounding: number;
  detailUnpaidAmount?: number;
}

type IFormSubmit = {
  [key in ADMINISTRATION_INVESTOR_ACTION_NAME]: {
    onSubmit: () => void;
    form: UseFormReturn<any>;
  };
};

const InvestorActionModal: FC<IInvestorActionModalProps> = (props) => {
  const {
    selectedAction,
    onClose,
    setAlertInfo,
    onOpenAlert,
    setIsPreventClickOutside,
    id = '',
    detailIssuedUnits = 0,
  } = props;
  const missingStatementAlertRef = useRef<IBasicModalElement>(null);
  const [previewInfo, setPreviewInfo] = useState<{ url: string; fileSize: number } | undefined>();
  const issueForm = useForm<IIssueForm>({
    defaultValues: issueFormDefaultValue,
    resolver: yupResolver(createInvestorIssueSchema),
  });
  const redemptionForm = useForm<IRedemptionForm>({
    defaultValues: redemptionFormDefaultValue,
    resolver: yupResolver(createInvestorRedemptionSchema),
  });
  const offMarketTransferForm = useForm<IOffmarketTransfer>({
    defaultValues: offmarketTransferDefaultValue,
    resolver: yupResolver(createOffmarketTransferSchema),
  });
  const recordPaymentForm = useForm<IRecordPayment>({
    defaultValues: recordPaymentDefaultValue,
    resolver: yupResolver(createRecordPaymentSchema),
  });
  const generateStatementForm = useForm<IGenerateStatementForm>({
    defaultValues: generateStatementDefaultValue,
    resolver: yupResolver(generateStatementSchema),
  });

  console.log(issueForm.formState.errors);

  const { mutate: createInvestorIssue, isLoading: creatingInvestorIssue } =
    useCreateInvestorIssue();
  const { mutate: createInvestorRedeem, isLoading: creatingInvestorRedeem } =
    useCreateInvestorRedeem();
  const { mutate: createOffmarketTransfer, isLoading: creatingOffmarketTransfer } =
    useCreateOffmarketTransfer();
  const { mutate: createRecordPayment, isLoading: creatingRecordPayment } =
    useCreateRecordPayment();
  const { mutate: previewStatement, isLoading: previewStatementLoading } = usePreviewStatement({});
  const { mutate: generateStatement, isLoading: generatingStatement } = useGenerateStatement();

  const isLoading =
    creatingInvestorIssue ||
    creatingInvestorRedeem ||
    creatingOffmarketTransfer ||
    creatingRecordPayment ||
    previewStatementLoading ||
    generatingStatement;

  const isPreview = generateStatementForm.watch('isPreview');

  useEffect(() => {
    setIsPreventClickOutside(isLoading);
  }, [isLoading]);

  const handleSubmitSuccess = () => {
    onClose();
    onOpenAlert();
  };

  const handleSubmitIssue = () => {
    const formData = cloneDeep(issueForm.getValues());
    const {
      priceTab,
      entryUnitPrice,
      currentUnitPrice,
      transactionDate,
      unitPriceRounding,
      ...restFormData
    } = formData;
    const data = {
      ...restFormData,
      transactionDate: getEndOfDateUTC(transactionDate),
      entryUnitPrice: priceTab === IssuePriceTab.CURRENT_UNIT_PRICE ? null : entryUnitPrice,
      currentUnitPrice: priceTab === IssuePriceTab.ENTRY_UNIT_PRICE ? null : currentUnitPrice,
    };

    createInvestorIssue(
      { id, data },
      {
        onSuccess: () => {
          setAlertInfo((prev) => ({
            ...prev,
            description: 'Issue successfully processed.',
            isError: false,
          }));
          handleSubmitSuccess();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSubmitOffmarketTransfer = () => {
    const formData = cloneDeep(offMarketTransferForm.getValues());
    const data = {
      ...formData,
      transactionDate: getEndOfDateUTC(formData.transactionDate),
    };
    createOffmarketTransfer(
      { id, data },
      {
        onSuccess: () => {
          setAlertInfo((prev) => ({
            ...prev,
            description: 'Transfer processed successfully.',
            isError: false,
          }));
          handleSubmitSuccess();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSubmitRecordPayment = () => {
    const formData = cloneDeep(recordPaymentForm.getValues());
    const data = {
      ...formData,
      transactionDate: getEndOfDateUTC(formData.transactionDate),
    };
    createRecordPayment(
      { id, data },
      {
        onSuccess: () => {
          setAlertInfo((prev) => ({
            ...prev,
            description: 'Payment recorded successfully.',
            isError: false,
          }));
          handleSubmitSuccess();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSubmitGenerateStatement = () => {
    const formData = cloneDeep(generateStatementForm.getValues());
    const data = {
      ...formData,
      transactionDate: getEndOfDateUTC(formData.transactionDate),
    };
    if (formData.isPreview) {
      generateStatement(
        { id, data },
        {
          onSuccess: () => {
            setAlertInfo((prev) => ({
              ...prev,
              description: 'Statement generated successfully.',
              isError: false,
            }));
            handleSubmitSuccess();
          },
          onError: handleErrorFromServer,
        },
      );
    } else {
      const { publishOption, ...restData } = data;
      previewStatement(
        { id, data: restData },
        {
          onSuccess: (data: Blob) => {
            setPreviewInfo({
              url: URL.createObjectURL(data),
              fileSize: data.size,
            });
            generateStatementForm.setValue('isPreview', true);
          },
          onError: handlePreviewStatementError,
        },
      );
    }
  };

  const handlePreviewStatementError = async (error: any) => {
    const jsonError = await blobResponseToJson(error?.data);
    if (jsonError?.ErrorMessage === GENERATE_STATEMENT_ERROR.STATEMENT_MISSING) {
      missingStatementAlertRef.current?.open();
    } else handleErrorFromServer(error);
  };

  const handleSubmitRedemption = () => {
    const { selectedPrice, unitPriceRounding, entryUnitPrice, currentUnitPrice, ...formData } =
      cloneDeep(redemptionForm.getValues());
    const data = {
      ...formData,
      transactionDate: getEndOfDateUTC(formData.transactionDate),
      entryUnitPrice: selectedPrice === UnitPriceTabsEnum.EXIT_UNIT_PRICE ? entryUnitPrice : null,
      currentUnitPrice:
        selectedPrice === UnitPriceTabsEnum.CURRENT_UNIT_PRICE ? currentUnitPrice : null,
    };
    const isAllowedUnits = handleCheckUnits(formData.numberOfUnits, formData.option);

    if (isAllowedUnits) {
      createInvestorRedeem(
        { id, data },
        {
          onSuccess: () => {
            setAlertInfo((prev) => ({
              ...prev,
              description: 'Redemption processed successfully.',
              isError: false,
            }));
            handleSubmitSuccess();
          },
          onError: handleErrorFromServer,
        },
      );
    } else {
      setAlertInfo((prev) => ({
        ...prev,
        isError: true,
        description: 'Number of units exceeds current unit holding.',
      }));
      onOpenAlert();
    }
  };

  const handleCheckUnits = (units: number, option: number | null) => {
    if (option === REDEEM_OPTION.FULL_HOLDING) return true;

    return units <= (detailIssuedUnits || 0);
  };

  const FORM_SUBMIT: IFormSubmit = {
    [ADMINISTRATION_INVESTOR_ACTION_NAME.ISSUE]: {
      onSubmit: handleSubmitIssue,
      form: issueForm,
    },
    [ADMINISTRATION_INVESTOR_ACTION_NAME.REDEMPTION]: {
      onSubmit: handleSubmitRedemption,
      form: redemptionForm,
    },
    [ADMINISTRATION_INVESTOR_ACTION_NAME.OFF_MARKET_TRANSFER]: {
      onSubmit: handleSubmitOffmarketTransfer,
      form: offMarketTransferForm,
    },
    [ADMINISTRATION_INVESTOR_ACTION_NAME.RECORD_PAYMENT]: {
      onSubmit: handleSubmitRecordPayment,
      form: recordPaymentForm,
    },
    [ADMINISTRATION_INVESTOR_ACTION_NAME.GENERATE_STATEMENT]: {
      onSubmit: handleSubmitGenerateStatement,
      form: generateStatementForm,
    },
  };

  const handleSubmitAction = () => {
    if (!selectedAction) return;
    const form = FORM_SUBMIT[selectedAction].form;
    form.handleSubmit(FORM_SUBMIT[selectedAction].onSubmit)();
  };

  const renderForms = () => {
    switch (selectedAction) {
      case ADMINISTRATION_INVESTOR_ACTION_NAME.ISSUE:
        return (
          <FormProvider {...issueForm}>
            <IssueForm {...props} />
          </FormProvider>
        );
      case ADMINISTRATION_INVESTOR_ACTION_NAME.REDEMPTION:
        return (
          <FormProvider {...redemptionForm}>
            <RedemptionForm {...props} />
          </FormProvider>
        );
      case ADMINISTRATION_INVESTOR_ACTION_NAME.OFF_MARKET_TRANSFER:
        return (
          <FormProvider {...offMarketTransferForm}>
            <OffMarketTransfer {...props} />
          </FormProvider>
        );
      case ADMINISTRATION_INVESTOR_ACTION_NAME.RECORD_PAYMENT:
        return (
          <FormProvider {...recordPaymentForm}>
            <RecordPayment {...props} />
          </FormProvider>
        );
      case ADMINISTRATION_INVESTOR_ACTION_NAME.GENERATE_STATEMENT:
        return (
          <FormProvider {...generateStatementForm}>
            <GenerateStatement {...props} previewInfo={previewInfo} />
          </FormProvider>
        );
      default:
        return <></>;
    }
  };

  const getActionButtonLabel = () => {
    const labelObj: any = {
      [ADMINISTRATION_INVESTOR_ACTION_NAME.ISSUE]: 'Issue',
      [ADMINISTRATION_INVESTOR_ACTION_NAME.REDEMPTION]: 'Redeem',
      [ADMINISTRATION_INVESTOR_ACTION_NAME.OFF_MARKET_TRANSFER]: 'Transfer',
      [ADMINISTRATION_INVESTOR_ACTION_NAME.RECORD_PAYMENT]: 'Record',
      [ADMINISTRATION_INVESTOR_ACTION_NAME.GENERATE_STATEMENT]: isPreview ? 'Confirm' : 'Generate',
    };

    return labelObj[selectedAction || ''];
  };

  return (
    <>
      <Box className='w-[738px] bg-white p-10'>
        <Box className='flex items-center justify-between'>
          <Typography variant='h5'>{selectedAction}</Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose} disabled={isLoading}>
            <CloseIcon width={20} height={20} />
          </IconButton>
        </Box>
        <Box className='h-[2px] mt-4 mb-6' bgcolor='neutral.ne200' />
        {renderForms()}
        <Box className='flex items-center justify-end mt-8'>
          <CustomButton
            variant='text'
            sx={{
              color: 'neutral.ne800',
            }}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleSubmitAction}
            isLoading={isLoading}
            disabled={
              !!selectedAction && !isEmpty(FORM_SUBMIT[selectedAction]?.form?.formState?.errors)
            }
          >
            {getActionButtonLabel()}
          </CustomButton>
        </Box>
      </Box>
      <BasicModal ref={missingStatementAlertRef}>
        <ConfirmationAlert
          description={'No transaction history available.'}
          isError={true}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              missingStatementAlertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default InvestorActionModal;
