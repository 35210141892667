import { styled } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

export const CustomInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  /* Works on firefox */
  scrollbarWidth: 'thin',
  scrollbarColor: theme.palette.neutral.ne300,

  /* Works on Chrome, Edge, and Safari */
  '&::-webkit-scrollbar': {
    width: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral.ne300,
    borderRadius: 3,
  },
}));
