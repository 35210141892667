import { isArray } from 'lodash';
import { portalTypes } from 'src/constants/common';

export const generaValueFromMultipleFields = <O = object>(obj: O, sortBy: keyof O | keyof O[]) => {
  let value: string | keyof O;
  if (!isArray(sortBy)) {
    value = sortBy as keyof O;
    return String(obj[value]);
  }

  value = '';
  sortBy.forEach((field) => (value = String(value).concat(obj[field as keyof O] as string)));

  return String(value);
};

export const detectPortalType = () => {
  // Extract the subdomain from the current URL
  const subdomain = window.location.hostname.split('.')[0];
  switch (subdomain) {
    case portalTypes.ADMIN:
    case portalTypes.SPECIAL_ADMIN:
      return { portalType: portalTypes.ADMIN, isAdmin: true, isFunds: false, isInvestor: false };
    case portalTypes.INVESTOR:
      return { portalType: portalTypes.INVESTOR, isAdmin: false, isFunds: false, isInvestor: true };
    default:
      return { portalType: subdomain, isAdmin: false, isFunds: true, isInvestor: false };
  }
};
