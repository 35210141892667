import { Box, BoxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';

interface ISectionLayoutProps extends BoxProps {
  title: string;
  canCollapse?: boolean;
  additionalInfo?: ReactNode;
}

const SectionLayout: FC<ISectionLayoutProps> = ({
  title = '',
  additionalInfo,
  children,
  canCollapse = true,
}) => {
  return (
    <Box className='p-6 border rounded-xl' borderColor='neutral.ne300'>
      <Accordion
        defaultExpanded
        sx={{ mt: '0 !important', bgcolor: 'transparent' }}
        canCollapse={canCollapse}
      >
        <AccordionSummary
          sx={{
            '.MuiSvgIcon-root': { fontSize: '18px' },
            '.MuiAccordionSummary-content': { my: 0, mx: canCollapse ? 1 : 0 },
            minHeight: 'unset',
          }}
          hideIcon={!canCollapse}
        >
          <Box className='flex justify-between items-center w-full'>
            <Typography variant='body2' fontWeight={600}>
              {title}
            </Typography>
            {additionalInfo}
          </Box>
        </AccordionSummary>
        <AccordionDetails className='pt-6'>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SectionLayout;
