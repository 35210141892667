import { Box, Grid, Radio, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import BusinessIcon from 'src/assets/icons/BusinessIcon';
import EmploymentIcon from 'src/assets/icons/EmploymentIcon';
import GiftIcon from 'src/assets/icons/GiftIcon';
import OtherIcon from 'src/assets/icons/OtherIcon';
import RetirementIcon from 'src/assets/icons/RetirementIcon';
import SaleIcon from 'src/assets/icons/SaleIcon';
import SavingsIcon from 'src/assets/icons/SavingsIcon';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import { distributionInstructionOptions } from 'src/constants/applications';
import { InvestorSourceOfFunds } from 'src/constants/common';
import {
  IApplicationAddtional,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';

interface IInvestmentDetailsProps extends IUpsertInvestmentApplicationForm {
  allowDRP?: boolean;
}

const InvestmentDetails: FC<IInvestmentDetailsProps> = ({
  allowDRP,
  isViewMode,
  isDisabledEdit,
  isReviewing,
}) => {
  const { watch } = useFormContext<IApplicationAddtional>();
  const watchSourceOfFundsType = watch('sourceOfFundsType');

  const investorSourceOfFundsOptions = [
    {
      label: 'Retirement Income',
      value: InvestorSourceOfFunds.RetirementIncome,
      icon: <RetirementIcon />,
    },
    {
      label: 'Business Activities',
      value: InvestorSourceOfFunds.BusinessActivities,
      icon: <BusinessIcon />,
    },
    {
      label: 'Sale of Assets',
      value: InvestorSourceOfFunds.SaleOfAssets,
      icon: <SaleIcon />,
    },
    {
      label: 'Employment Income',
      value: InvestorSourceOfFunds.EmploymentIncome,
      icon: <EmploymentIcon />,
    },
    {
      label: 'Inheritance/ Gift',
      value: InvestorSourceOfFunds.InheritanceGift,
      icon: <GiftIcon />,
    },
    {
      label: 'Savings',
      value: InvestorSourceOfFunds.Savings,
      icon: <SavingsIcon />,
    },
    {
      label: 'Other',
      value: InvestorSourceOfFunds.Other,
      icon: <OtherIcon />,
    },
  ];

  const renderDistributionInstruction = (
    options: RadioOption[],
    selectedValue?: number | string,
    disabled?: boolean,
  ) => {
    return (
      <Grid container columnSpacing={3} marginTop={2}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6}>
            <Box
              className='border rounded-xl px-3 flex justify-between items-center'
              borderColor={option.value === selectedValue ? 'primary.main' : 'neutral.ne400'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  width: '100%',
                }}
                disabled={disabled}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box className='mx-[-24px] pt-6'>
      <FormRadioGroup
        name='sourceOfFundsType'
        label={
          <Typography variant='body2' fontWeight='medium'>
            Source of Funds
          </Typography>
        }
        options={investorSourceOfFundsOptions}
        containerSx={{
          '.MuiStack-root': {
            gap: 0,
            justifyContent: 'space-between',
          },
          '.MuiFormControlLabel-root': { m: 0, width: '11%' },
          '.MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 500,
          },
        }}
        isEnumValue
        disabled={isViewMode || isDisabledEdit}
        showPickerOnly={isReviewing}
      />
      {watchSourceOfFundsType === InvestorSourceOfFunds.Other && (
        <Grid container mt={4}>
          <Grid item xs={6}>
            <FormInput
              name='otherDescription'
              label='Description'
              readOnly={isViewMode}
              placeholder='Enter description'
            />
          </Grid>
        </Grid>
      )}
      {allowDRP && (
        <Box className='pt-8'>
          <Typography variant='body2' fontWeight='medium' paddingBottom={2}>
            Distribution Instruction
          </Typography>
          <FormRadioGroup
            name='distributionInstructionType'
            label='Please select the preferred distribution payment method'
            options={distributionInstructionOptions}
            renderOptions={renderDistributionInstruction}
            isEnumValue
            disabled={isViewMode || isDisabledEdit}
            showPickerOnly={isReviewing}
          />
        </Box>
      )}
    </Box>
  );
};

export default InvestmentDetails;
