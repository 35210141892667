import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import {
  MAX_INFORMATION_CHARACTERS,
  MAX_UNIT_CLASS_CODE,
  MAX_UNIT_PRICE,
  PartiallyPaidUnitsOptions,
  UnitOptionsType,
} from 'src/constants/unit-class';
import { IUnitClassDetailFormFields } from 'src/modules/unit-class/type';

interface IUnitClassFormProps {
  isCreateMode: boolean;
  fundId: string;
  isTrust: boolean;
  isViewMode: boolean;
  unitPriceRounding: number;
  isDraftStatus?: boolean;
  fundCode?: string;
}

const DEFAULT_INITIAL_UNIT_PRICE = 1.0;

const UnitClassDetailForm: FC<IUnitClassFormProps> = ({
  isCreateMode,
  isTrust,
  isViewMode,
  unitPriceRounding,
  isDraftStatus,
  fundCode,
}) => {
  const { watch, setValue } = useFormContext<IUnitClassDetailFormFields>();
  const watchPartiallyPaidUnits = watch('allowPartiallyPaidUnits');
  const watchAdditionalInfo = watch('additionalInformation');
  const isEditMode = !isViewMode && !isCreateMode;
  const isDisabledWhenInEditModeAndNotDraft = !isDraftStatus && isEditMode;

  useEffect(() => {
    if (isTrust) return;
    setValue('initialUnitPrice', DEFAULT_INITIAL_UNIT_PRICE);
    setValue('allowPartiallyPaidUnits', UnitOptionsType.Yes);
    setValue('allowCapitalCall', UnitOptionsType.Yes);
  }, [isTrust]);

  const handleChangePartiallyPaidUnits = (value: any) => {
    setValue('allowCapitalCall', value ? UnitOptionsType.Yes : null);
  };

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <FormInput
            name='name'
            label={isTrust ? 'Unit Class Name' : 'Financial Rule Name'}
            placeholder={isTrust ? 'Enter unit class name' : 'Enter financial rule name'}
            readOnly={isViewMode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name='code'
            label={isTrust ? 'Unit Class Code' : 'Financial Rule Code'}
            placeholder={isTrust ? 'Enter unit class code' : 'Enter financial rule code'}
            maxLength={MAX_UNIT_CLASS_CODE}
            readOnly={isViewMode}
            sx={{
              '& .MuiInputBase-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: fundCode ? (
                <Box
                  bgcolor='neutral.ne200'
                  className='flex items-center px-[14px] h-full mr-[14px]'
                  sx={{
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                    borderRightColor: 'neutral.ne400',
                    borderRightWidth: 1,
                  }}
                >
                  <Typography variant='body2' color='neutral.ne800'>
                    {fundCode}
                  </Typography>
                </Box>
              ) : null,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='initialUnitPrice'
            label={isTrust ? 'Initial Unit Price' : 'Currency'}
            max={MAX_UNIT_PRICE}
            decimalScale={unitPriceRounding}
            placeholder='Enter initial unit price'
            hideInput={!isTrust}
            readOnly={isViewMode}
            disabled={isDisabledWhenInEditModeAndNotDraft}
          />
        </Grid>
        {isTrust && (
          <>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <FormRadioGroup
                label='Allow Partially Paid Units'
                name='allowPartiallyPaidUnits'
                className='w-full'
                row
                options={PartiallyPaidUnitsOptions}
                disabled={isViewMode || isDisabledWhenInEditModeAndNotDraft}
                isEnumValue
                onChangeValue={handleChangePartiallyPaidUnits}
              />
            </Grid>
            {watchPartiallyPaidUnits == UnitOptionsType.No && (
              <Grid item xs={12}>
                <FormRadioGroup
                  label='Allow Capital Call'
                  name='allowCapitalCall'
                  className='w-full'
                  row
                  isEnumValue
                  options={PartiallyPaidUnitsOptions}
                  disabled={isViewMode || isDisabledWhenInEditModeAndNotDraft}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Box className='flex flex-col items-end'>
            <FormInput
              name='additionalInformation'
              label='Additional Information'
              optional='Optional'
              placeholder='Enter additional information'
              multiline
              rows={4.5}
              maxLength={MAX_INFORMATION_CHARACTERS}
              readOnly={isViewMode}
            />
            <Typography color='neutral.ne800' variant='body3' paddingTop={1}>
              {`${MAX_INFORMATION_CHARACTERS - (watchAdditionalInfo?.length || 0)} characters left`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnitClassDetailForm;
