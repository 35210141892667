import { NOTIFICATION_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { INotificationQueryBody } from './type';

export const getNotificationList = async (data: INotificationQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: NOTIFICATION_API.getNotificationList.api,
  });

  return result.data;
};

export const deleteNotification = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: NOTIFICATION_API.deleteNotification.api(id),
  });

  return result.data;
};

export const updateNotificationState = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: NOTIFICATION_API.updateNotificationState.api(id),
  });

  return result.data;
};

export const clearAllNotification = async (optional?: any) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: NOTIFICATION_API.clearAllNotification.api,
  });

  return result.data;
};
