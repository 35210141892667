/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CustomAlert from 'src/components/atoms/CustomAlert';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  INVESTMENT_ENTITY_ROLE,
  InvestorUserRoleOptions,
  TeamType,
  createTeamContactDefaultValues,
} from 'src/constants/applications';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { useGetInvestmentEntityRoles } from 'src/modules/administration-investor/hooks';
import { ITeamContact } from 'src/modules/applications/type';
import { Role } from 'src/modules/users-management/type';
import { createInvestorTeamContactSchema } from 'src/validations/administration-investor';

interface ITeamUpsertContactProps {
  isAddTo?: boolean;
  isEditMode?: boolean;
  isViewMode?: boolean;
  user?: ITeamContact;
  teamType?: TeamType;
  disabledAddToPrimary?: boolean;
  disabledAddToComList?: boolean;
}

export interface TeamUpsertContactHandler {
  onCreate: (callback: (data: ITeamContact) => void) => void;
  onAddTo: (callback: (data: ITeamContact) => void) => void;
}

const TeamUpsertContact = forwardRef<TeamUpsertContactHandler, ITeamUpsertContactProps>(
  (
    {
      isEditMode,
      isViewMode,
      isAddTo,
      user,
      teamType,
      disabledAddToPrimary = false,
      disabledAddToComList = false,
    },
    ref,
  ) => {
    const [checked, setChecked] = useState<number[]>([]);
    const { data: userRoles = [] } = useGetInvestmentEntityRoles();

    const form = useForm({
      resolver: yupResolver(createInvestorTeamContactSchema),
      defaultValues: createTeamContactDefaultValues,
    });
    const watchUserType = form.watch('userType');
    const isTeam = teamType === TeamType.Team;

    useEffect(() => {
      if (watchUserType === INVESTMENT_ENTITY_ROLE.Investor) {
        const fineSuperAdmin = (userRoles || []).find((f: Role) => f.isSuperAdmin);
        fineSuperAdmin?.id && form.setValue('roleId', fineSuperAdmin?.id, { shouldValidate: true });
      }
    }, [watchUserType, userRoles]);

    useEffect(() => {
      if (isAddTo || isEditMode || isViewMode) {
        form.reset(user || {});
        setChecked(user?.teamTypes || []);
      }
    }, [user]);

    useEffect(() => {
      form.setValue('teamTypes', checked, { shouldValidate: true });
    }, [checked]);

    useEffect(() => {
      form.setValue('isAddTo', isAddTo);
    }, [isAddTo]);

    useImperativeHandle(ref, () => ({
      onCreate: (callback: (data: ITeamContact) => void) =>
        form.handleSubmit((data) => onSubmit(data, callback))(),
      onAddTo: (callback: (data: ITeamContact) => void) =>
        form.handleSubmit((data) => handleAddTo(data, callback))(),
    }));

    const onSubmit = (data: ITeamContact, callback: (data: ITeamContact) => void) => {
      callback(data);
    };

    const handleAddTo = (data: ITeamContact, callback: (data: ITeamContact) => void) => {
      callback(data);
    };

    const handleChange = (teamType: number) => {
      const newChecked = [...checked];
      const itemIndex = newChecked.findIndex((f) => f === teamType);
      if (itemIndex !== -1) {
        newChecked.splice(itemIndex, 1);
      } else {
        newChecked.push(teamType);
      }
      setChecked(newChecked);
    };

    const userRolesFormat = useMemo(() => {
      return userRoles
        ?.map((item: Role) => {
          return {
            label: item.name,
            value: item?.id,
          };
        })
        .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
    }, [userRoles]);

    return (
      <FormProvider {...form}>
        {isTeam && isViewMode && (
          <Box mt={4}>
            <CustomAlert severity='warning'>
              Update Details? Please navigate to Administration - Investment Entity to update
              details.
            </CustomAlert>
          </Box>
        )}
        <Box className='pt-12' component='form'>
          <Grid container rowSpacing={4} columnSpacing={3}>
            {isAddTo && (
              <Grid item xs={12} md={12}>
                <Typography variant='body3'>Add to</Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={checked.indexOf(TeamType.PrimaryContact) !== -1}
                        onChange={() => handleChange(TeamType.PrimaryContact)}
                      />
                    }
                    disabled={disabledAddToPrimary}
                    label='Primary Contact'
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={checked.indexOf(TeamType.CommunicationList) !== -1}
                        onChange={() => handleChange(TeamType.CommunicationList)}
                      />
                    }
                    label='Communication List'
                    disabled={disabledAddToComList}
                  />
                </FormGroup>
                {form.formState.errors && (
                  <CustomHelperText
                    variant='error'
                    message={form.formState.errors['teamTypes']?.message as string}
                  />
                )}
              </Grid>
            )}

            {(isAddTo || isTeam) && (
              <Grid item xs={12} md={12}>
                <FormRadioGroup
                  containerSx={{
                    '.MuiStack-root': {
                      ml: '2px',
                    },
                  }}
                  name='userType'
                  label='User Type'
                  isEnumValue
                  disabled={isAddTo || isViewMode || checked.indexOf(TeamType.Team) === -1}
                  options={InvestorUserRoleOptions}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormInput
                name='firstName'
                label='First Name'
                size='small'
                placeholder='Enter first name'
                className='w-full'
                disabled={isAddTo || isViewMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='lastName'
                label='Last Name'
                size='small'
                placeholder='Enter last name'
                className='w-full'
                disabled={isAddTo || isViewMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='email'
                label='Email Address'
                size='small'
                placeholder='Enter email address'
                className='w-full'
                disabled={isAddTo || isViewMode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='phoneNumber'
                label='Contact Number'
                size='small'
                className='w-full'
                countryFlagCode={DEFAULT_COUNTRY}
                disabled={isAddTo || isViewMode}
                isIncludeCode
              />
            </Grid>
            {(isAddTo || isTeam) && (
              <>
                <Grid item xs={12} md={6}>
                  <FormInput
                    name='position'
                    label='Position'
                    size='small'
                    placeholder='Enter position'
                    className='w-full'
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormSelect
                    name='roleId'
                    label='Role'
                    size='small'
                    placeholder='Select role'
                    className='w-full'
                    options={userRolesFormat}
                    disabled
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </FormProvider>
    );
  },
);

export default TeamUpsertContact;
