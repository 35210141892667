/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { cloneDeep, omit } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import InvestmentApplicationTeamIcon from 'src/assets/icons/InvestmentApplicationTeamIcon';
import MoneyBagIcon from 'src/assets/icons/MoneyBagIcon';
import PaidSearchIcon from 'src/assets/icons/PaidSearchIcon';
import StatsIcon from 'src/assets/icons/StatsIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import CustomFormSectionHeader from 'src/components/pages/applications/components/CustomFormSectionHeader';
import {
  GOVERNMENT_BODY_TYPE,
  INVESTMENT_ENTITY_APPOINTER_TYPE,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE,
  InvestmentEntityCompanyRegionType,
  applicationTeamDefaultValues,
  financialDetailsDefaultValues,
  financialTaxDetailsDefaultValues,
} from 'src/constants/applications';
import { IErrorResponse } from 'src/interfaces/common';
import { useGetContactListByTeamType } from 'src/modules/applications/hooks';
import {
  IApplicationFinancialDetail,
  IFinancialTaxDetailsForm,
  ITeam,
} from 'src/modules/applications/type';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo } from 'src/modules/common/type';
import {
  useCreateInvestmentEntity,
  useUpsertFinancialDetail,
  useUpsertFinancialTaxDetail,
  useUpsertInvestmentEntityDetails,
  useUpsertInvestmentEntityDocuments,
  useUpsertTeam,
} from 'src/modules/investment-entity/hooks';
import {
  IInvestmentEntityDetailsForm,
  IInvestmentEntityDocumentsForm,
  IUpsertInvestmentEntityForm,
} from 'src/modules/investment-entity/types';
import { getErrorMessageFromServer, handleErrorFromServer } from 'src/utils/common';
import {
  administrationInvestmentEntityDetailsSchema,
  documentsSchema,
  finacialAndTaxDetailsSchema,
} from 'src/validations/investment-entity';
import { financialDetailsSchema } from 'src/validations/offer';
import { v4 as uuidv4 } from 'uuid';
import Details from './Details';
import Documents from './Documents';
import FinancialAndTaxDetails from './FinancialAndTaxDetails';
import FinancialDetails from './FinancialDetails';
import InvestmentEntityTeam from './Team';

export interface IInvestmentApplicationFormProps extends IUpsertInvestmentEntityForm {
  onClose: (isOpen: boolean) => void;
  isLinked?: boolean;
}

export enum INVESTMENT_ENTITY_STEP {
  DETAILS,
  FINANCIAL_DETAILS,
  TEAM,
  DOCUMENTS,
}

type IFormData = {
  [key in INVESTMENT_ENTITY_STEP]: {
    form: UseFormReturn<any>;
    isDirty?: boolean;
    isValid?: boolean;
    submit: (mode: string) => void;
  };
};

const InvestmentEntityForm: FC<IInvestmentApplicationFormProps> = ({
  isEditMode,
  isViewMode,
  isDraft,
  id = '',
  onClose,
  isLinked,
}) => {
  const isCreateMode = !isEditMode && !isViewMode;
  const stepperRef = useRef<ICustomStepperElement>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const [investmentEntityId, setInvestmentEntityId] = useState(id);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(INVESTMENT_ENTITY_STEP.DETAILS);
  const [teamErrors, setTeamErrors] = useState<{
    isTeamError: boolean;
    errorMessageFromServer?: string;
  }>({
    isTeamError: false,
    errorMessageFromServer: '',
  });

  const detailsForm = useForm<IInvestmentEntityDetailsForm>({
    defaultValues: {
      entityType: null,
      companyRegionType: null,
      investmentEntityDetails: {
        numberOfIndividuals: null,
      },
    },
    resolver: yupResolver(administrationInvestmentEntityDetailsSchema),
  });
  const teamForm = useForm<ITeam>({
    defaultValues: applicationTeamDefaultValues,
  });
  const financialDetailsForm = useForm<IApplicationFinancialDetail>({
    defaultValues: financialDetailsDefaultValues,
    resolver: yupResolver(financialDetailsSchema),
  });
  const financialTaxDetailsForm = useForm<IFinancialTaxDetailsForm>({
    defaultValues: financialTaxDetailsDefaultValues,
    resolver: yupResolver(finacialAndTaxDetailsSchema),
  });
  const documentsForm = useForm<IInvestmentEntityDocumentsForm>({
    defaultValues: {
      documents: [],
    },
    resolver: yupResolver(documentsSchema),
  });
  const teamMates = teamForm.watch('teammates');
  const entityType = detailsForm.watch('entityType');

  const isWithoutFinancialAndTaxDetails = [
    INVESTMENT_ENTITY_TYPE.Individuals,
    INVESTMENT_ENTITY_TYPE.SoleTrader,
  ].includes(entityType as INVESTMENT_ENTITY_TYPE);

  const { mutate: upsertTeam } = useUpsertTeam();

  const { mutateAsync: upsertInvestmentEntityDetailsMutate, isLoading: upsertDetailsLoading } =
    useUpsertInvestmentEntityDetails();

  const { mutateAsync: upsertFinancialDetail, isLoading: upsertOfferDetailsLoading } =
    useUpsertFinancialDetail();
  const { mutateAsync: upsertFinancialAndTaxDetails } = useUpsertFinancialTaxDetail();

  const { mutateAsync: upsertInvestmentEntityDocuments, isLoading: upsertDocumentsLoading } =
    useUpsertInvestmentEntityDocuments();
  const { mutate: createInvestmentEntity } = useCreateInvestmentEntity();

  const { teams } = useGetContactListByTeamType(teamMates);

  const isLoading = upsertOfferDetailsLoading || upsertDetailsLoading || upsertDocumentsLoading;

  useEffect(() => {
    handleTeamValidation();
  }, [teamMates]);

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleShowAlert = ({ title, description }: IAlertInfo) => {
    setAlertInfo({ title, description });
    alertRef.current?.open();
  };

  const getInvestmentEntitySubmitData = () => {
    const data = cloneDeep(detailsForm.getValues());
    if (data.investmentEntityDetails?.australianCompany?.certifiedAgreementFile?.id) {
      delete data.investmentEntityDetails.australianCompany.certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (data.investmentEntityDetails?.unregulatedTrust?.certifiedTrustDeed?.id) {
      delete data.investmentEntityDetails.unregulatedTrust.certifiedTrustDeed;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.appointerAustralianCompanyDetails
        ?.certifiedAgreementFile?.id
    ) {
      delete data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails
        .certifiedAgreementFile;
    }
    if (
      data.investmentEntityDetails?.smsf?.trusteeAustralianCompanyDetails?.certifiedAgreementFile
        ?.id
    ) {
      delete data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails
        .certifiedAgreementFile;
    }

    if (data.investmentEntityDetails.partnership?.certifiedAgreementFileId) {
      delete data.investmentEntityDetails.partnership.certifiedAgreementFile;
    }

    if (
      data.companyRegionType === InvestmentEntityCompanyRegionType.Australian &&
      data.investmentEntityDetails?.australianCompany?.directors
    ) {
      data.investmentEntityDetails.australianCompany.ubOs = [
        ...data.investmentEntityDetails.australianCompany.directors.filter((it) => it.refId),
        ...(data.investmentEntityDetails.australianCompany.ubOs || []),
      ];
    }

    if (
      data.companyRegionType === InvestmentEntityCompanyRegionType.Foreign &&
      data.investmentEntityDetails?.foreignCompany?.directors
    ) {
      data.investmentEntityDetails.foreignCompany.ubOs = [
        ...data.investmentEntityDetails.foreignCompany.directors.filter((it) => it.refId),
        ...(data.investmentEntityDetails.foreignCompany.ubOs || []),
      ];
    }

    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.regulatedTrust?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs || []),
      ];
      data.investmentEntityDetails.regulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails.ubOs,
        ...(data.investmentEntityDetails.regulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.regulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.regulatedTrust?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.regulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.regulatedTrust.trusteeIndividuals,
        ...(data.investmentEntityDetails.regulatedTrust.trustUBOs || []),
      ];
    }

    if (data.investmentEntityDetails?.unregulatedTrust?.trustUBOs) {
      data.investmentEntityDetails.unregulatedTrust.trustUBOs =
        data.investmentEntityDetails.unregulatedTrust.trustUBOs.map((it) => ({
          ...it,
          uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.AddNew,
        }));
    }

    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs ||
          []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs.map(
          (it) => ({
            ...it,
            uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.CorporateTrustee,
          }),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.trusteeIndividuals,
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (data.investmentEntityDetails?.unregulatedTrust?.isSettlorMoreThanMax) {
      // This refId to handle the missing refId case - bulk upload
      if (!data.investmentEntityDetails.unregulatedTrust.settlor?.refId) {
        const refId = uuidv4();
        data.investmentEntityDetails.unregulatedTrust.settlor.refId = refId;
      }
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        {
          ...data.investmentEntityDetails.unregulatedTrust.settlor,
          uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.Settlor,
        },
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    } else if (data.investmentEntityDetails?.unregulatedTrust?.settlor) {
      (data as any).investmentEntityDetails.unregulatedTrust.settlor = null;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
      data.investmentEntityDetails?.unregulatedTrust?.isAppointerSameAsTrustee &&
      data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails =
        data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails;
      data.investmentEntityDetails.unregulatedTrust.appointerForeignCompanyDetails =
        data.investmentEntityDetails.unregulatedTrust.trusteeForeignCompanyDetails;
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
      ((!data.investmentEntityDetails?.unregulatedTrust?.isAppointerSameAsTrustee &&
        data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) ||
        data.investmentEntityDetails?.unregulatedTrust?.trusteeType ===
          INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual)
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs ||
          []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails.ubOs.map(
          (it) => ({
            ...it,
            uboSourceType: INVESTMENT_ENTITY_UNREGULATED_TRUST_UBO_SOURCE_TYPE.CorporateAppointer,
          }),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.unregulatedTrust?.hasAppointer &&
      data.investmentEntityDetails?.unregulatedTrust?.appointerType ===
        INVESTMENT_ENTITY_APPOINTER_TYPE.Individual
    ) {
      data.investmentEntityDetails.unregulatedTrust.appointerIndividuals = [
        ...(
          data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.directors ||
          []
        )
          .concat(
            data.investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails.ubOs ||
              [],
          )
          .concat(data.investmentEntityDetails.unregulatedTrust.trusteeIndividuals || [])
          .filter(
            (it) =>
              it.refId &&
              data.investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals?.includes(
                it.refId,
              ),
          ),
        ...(data.investmentEntityDetails.unregulatedTrust.appointerIndividuals || []),
      ];
      data.investmentEntityDetails.unregulatedTrust.trustUBOs = [
        ...data.investmentEntityDetails.unregulatedTrust.appointerIndividuals.filter(
          (it) =>
            it.refId &&
            !data.investmentEntityDetails.unregulatedTrust.selectedExistingIndividuals?.includes(
              it.refId,
            ),
        ),
        ...(data.investmentEntityDetails.unregulatedTrust.trustUBOs || []),
      ];
    }

    if (
      data.investmentEntityDetails?.smsf?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate &&
      data.investmentEntityDetails?.smsf?.trusteeAustralianCompanyDetails?.directors
    ) {
      data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs = [
        ...data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.directors.filter(
          (it) => it.refId,
        ),
        ...(data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs || []),
      ];
      data.investmentEntityDetails.smsf.smsfubOs = [
        ...data.investmentEntityDetails.smsf.trusteeAustralianCompanyDetails.ubOs,
        ...(data.investmentEntityDetails.smsf.smsfubOs || []),
      ];
    }
    if (
      data.investmentEntityDetails?.smsf?.trusteeType ===
        INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual &&
      data.investmentEntityDetails?.smsf?.trusteeIndividuals
    ) {
      data.investmentEntityDetails.smsf.smsfubOs = [
        ...data.investmentEntityDetails.smsf.trusteeIndividuals,
        ...(data.investmentEntityDetails.smsf.smsfubOs || []),
      ];
    }

    if (data.investmentEntityDetails?.association?.publicOfficers) {
      data.investmentEntityDetails.association.ubOs = [
        ...data.investmentEntityDetails.association.publicOfficers.filter((it) => it.refId),
        ...(data.investmentEntityDetails?.association?.ubOs || []),
      ];
    }

    if (data.investmentEntityDetails?.governmentBody?.publicOfficers) {
      data.investmentEntityDetails.governmentBody.ubOs = [
        ...(data.investmentEntityDetails.governmentBody.type === GOVERNMENT_BODY_TYPE.Foreign
          ? data.investmentEntityDetails.governmentBody.publicOfficers.filter((it) => !!it?.refId)
          : []),
        ...(data.investmentEntityDetails.governmentBody.ubOs || []),
      ];
      data.investmentEntityDetails.governmentBody.publicOfficers = [
        ...data.investmentEntityDetails.governmentBody.publicOfficers.map(({ refId, ...rest }) =>
          data.investmentEntityDetails.governmentBody?.type === GOVERNMENT_BODY_TYPE.Foreign
            ? { refId, ...rest }
            : rest,
        ),
      ];
    }
    return data;
  };

  const onSubmitDetails = async (mode: string) => {
    const data = getInvestmentEntitySubmitData();

    if (data.investmentEntityDetails.partnership?.partners) {
      data.investmentEntityDetails.partnership.ubOs = [
        ...data.investmentEntityDetails.partnership.partners.filter((item) => !!item?.refId),
        ...(data.investmentEntityDetails?.partnership?.ubOs || []),
      ];
    }

    if (data.entityType === INVESTMENT_ENTITY_TYPE.Partnership) {
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFile =
        data.investmentEntityDetails.partnership?.certifiedAgreementFile || undefined;
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFileDescription =
        data.investmentEntityDetails.partnership.certifiedAgreementFileDescription;
      data.investmentEntityDetails.partnership.certifiedPartnershipAgreementFileId =
        data.investmentEntityDetails.partnership?.certifiedAgreementFileId || undefined;
    }

    await upsertInvestmentEntityDetailsMutate(
      {
        id: investmentEntityId || '',
        data,
      },
      {
        onSuccess: (entityId: string) => {
          entityId && setInvestmentEntityId(entityId);
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitTeam = async (mode: string) => {
    const data = teamForm.getValues();
    if (handleTeamValidation()) {
      await upsertTeam(
        { data, id: investmentEntityId },
        {
          onSuccess: () => {
            handleSuccess(mode);
          },
          onError: (error: unknown | IErrorResponse) => {
            const message = getErrorMessageFromServer(error);
            setTeamErrors((prevState) => ({ ...prevState, errorMessageFromServer: message }));
            handleErrorFromServer(error);
          },
        },
      );
    }
  };

  const onSubmitFinancialDetails = async (mode: string) => {
    const data = financialDetailsForm.getValues();
    await upsertFinancialDetail(
      {
        id: investmentEntityId,
        data,
      },
      {
        onSuccess: () => {
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitFinancialAndTaxDetails = async (mode: string) => {
    const formValues = financialTaxDetailsForm.getValues();
    const financialDetailsData: IApplicationFinancialDetail = {
      ...formValues?.financialDetails,
      isSaveDraft: !!formValues.isSaveDraft,
    };
    const financialTaxDetailsData = cloneDeep(omit(formValues, ['financialDetails']));
    const { foreignControllingPersons, isRequiredDeclarationOnly, ...restFinancialTaxDetailsData } =
      financialTaxDetailsData;
    const { hasControllingPersonsOtherThanAustralia, directors = [] } =
      foreignControllingPersons || {};
    const upsertFinancialTaxDetailsData: IFinancialTaxDetailsForm = {
      ...restFinancialTaxDetailsData,
      foreignControllingPersons: {
        ...foreignControllingPersons,
        directorIds: hasControllingPersonsOtherThanAustralia ? directors.map((it) => it.id) : [],
      },
    };

    if (isRequiredDeclarationOnly) {
      delete upsertFinancialTaxDetailsData.taxStatus;
      delete upsertFinancialTaxDetailsData.foreignControllingPersons;
      delete upsertFinancialTaxDetailsData.entityCountryOfTaxResidency;
    }
    upsertFinancialDetail(
      {
        id: investmentEntityId,
        data: financialDetailsData,
      },
      {
        onSuccess: () => {
          // Financial and Tax is optional, so if there's no changes then dont need to call api
          if (typeof financialTaxDetailsData.isTaxResidentOtherCountryThanAustralia === 'boolean') {
            upsertFinancialAndTaxDetails(
              {
                id: investmentEntityId,
                data: upsertFinancialTaxDetailsData,
              },
              {
                onSuccess: () => handleSuccess(mode),
                onError: handleErrorFromServer,
              },
            );
          } else {
            handleSuccess(mode);
          }
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleTeamValidation = () => {
    setTeamErrors({
      isTeamError: teams.length === 0,
    });
    if (teams.length) {
      return true;
    }
    return false;
  };

  const onSubmitDocuments = async (mode: string) => {
    const data = documentsForm.getValues();

    const documents = data.documents
      .filter((item) => item.isAddNew)
      .map(({ uploadedBy, uploadedDate, isAddNew, ...rest }) => rest);

    upsertInvestmentEntityDocuments(
      { id: investmentEntityId, documents },
      {
        onSuccess: () => {
          if ((isCreateMode || isDraft) && mode === SUBMIT_FORM_ACTIONS.SUBMIT) {
            onCreateInvestmentEntity();
          } else {
            handleSuccess(mode);
          }
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onCreateInvestmentEntity = () => {
    createInvestmentEntity(
      { id: investmentEntityId },
      {
        onSuccess: () => {
          handleShowAlert({
            title: 'You did it!',
            description: `The Entity has been created successfully.`,
          });
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const formData: IFormData = {
    [INVESTMENT_ENTITY_STEP.DETAILS]: {
      form: detailsForm,
      isDirty: detailsForm.formState.isDirty,
      isValid: detailsForm.formState.isValid,
      submit: onSubmitDetails,
    },

    [INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS]: isWithoutFinancialAndTaxDetails
      ? {
          form: financialDetailsForm,
          isDirty: financialDetailsForm.formState.isDirty,
          isValid: financialDetailsForm.formState.isValid,
          submit: onSubmitFinancialDetails,
        }
      : {
          form: financialTaxDetailsForm,
          isDirty: financialTaxDetailsForm.formState.isDirty,
          isValid: financialTaxDetailsForm.formState.isValid,
          submit: onSubmitFinancialAndTaxDetails,
        },
    [INVESTMENT_ENTITY_STEP.TEAM]: {
      form: teamForm,
      isDirty: teamForm.formState.isDirty,
      isValid: teamForm.formState.isValid,
      submit: onSubmitTeam,
    },
    [INVESTMENT_ENTITY_STEP.DOCUMENTS]: {
      form: documentsForm,
      isDirty: documentsForm.formState.isDirty,
      isValid: documentsForm.formState.isValid,
      submit: onSubmitDocuments,
    },
  };

  const handleSave = (stepSelected: INVESTMENT_ENTITY_STEP, mode: string) => {
    if (isViewMode) {
      return handleGoToNextStep();
    }
    if (isEditMode && !isDraft) {
      if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
        handleSetErrorSteps();
        handleGoToNextStep();
      } else {
        handleInvestmentEntitySubmitAllSection();
      }
      return;
    }
    const { form, submit } = formData[stepSelected];
    form.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
    form.handleSubmit(() => submit(mode))();
  };

  const handleSuccess = (mode?: string) => {
    if (isEditMode && !isDraft) return;
    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleShowAlert({
        title: 'You did it!',
        description: `Investment entity has been updated successfully.`,
      });
    } else {
      handleGoToNextStep();
    }
  };

  const handleSetErrorSteps = () => {
    const validArray = Object.values(formData).map(({ form }) => form.formState.isValid);
    const errorStepList = validArray
      .map((it, id) => (it ? null : id))
      .filter((it) => it !== null) as number[];
    setErrorSteps(errorStepList);
    Object.values(formData).map(({ form }) => form.trigger());
    return !errorStepList.length;
  };

  const handleClickStep = () => {
    if (isViewMode) return;
    handleSetErrorSteps();
  };

  const handleInvestmentEntitySubmitAllSection = () => {
    const isValid = handleSetErrorSteps();
    if (!isValid) {
      return;
    }

    Promise.all([
      Object.values(formData)
        .filter(({ form }) => form.formState.isDirty)
        .map(({ submit }) => submit(SUBMIT_FORM_ACTIONS.SUBMIT)),
    ])
      .then(() => {
        handleShowAlert({
          title: 'You did it!',
          description: `Investment entity has been updated successfully.`,
        });
      })
      .catch((error) => {
        console.log('Submit all error: ', error);
      });
  };

  const handleStepChanged = (stepChanged: number) => {
    setCurrentStep(stepChanged);
  };

  const getDefaultStepProps = (step: number) => ({
    id: investmentEntityId,
    step,
    isViewMode,
    isEditMode,
    isCreateMode,
    isDraft,
    isLoading,
    currentStep,
    onSubmit: () => {
      if (stepperRef.current?.getCurrentStep() === step) {
        handleSave(step, SUBMIT_FORM_ACTIONS.SUBMIT);
      }
    },
    onSave: () => handleSave(step, SUBMIT_FORM_ACTIONS.DRAFT),
  });

  const STEPS = [
    {
      key: INVESTMENT_ENTITY_STEP.DETAILS,
      label: 'Details',
      content: (
        <FormProvider {...detailsForm}>
          <FormSection
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.DETAILS)}
            customHeader={<CustomFormSectionHeader title='Details' icon={<PaidSearchIcon />} />}
          >
            <Details
              {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.DETAILS)}
              isInvestmentEntitySetting={isEditMode && !isDraft}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS,
      label: 'Financial Details',
      subLabel: (
        <Typography variant='body3' color='neutral.ne500'>
          (Optional)
        </Typography>
      ),
      content: (
        <FormSection
          customHeader={<CustomFormSectionHeader title='Financial Details' icon={<StatsIcon />} />}
          {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS)}
        >
          {isWithoutFinancialAndTaxDetails ? (
            <FormProvider {...financialDetailsForm}>
              <FinancialDetails
                {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS)}
                isWithoutFinancialAndTaxDetails
              />
            </FormProvider>
          ) : (
            <FormProvider {...financialTaxDetailsForm}>
              <FinancialAndTaxDetails
                {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.FINANCIAL_DETAILS)}
              />
            </FormProvider>
          )}
        </FormSection>
      ),
    },
    {
      key: INVESTMENT_ENTITY_STEP.TEAM,
      label: 'Team',
      content: (
        <FormProvider {...teamForm}>
          <FormSection
            customHeader={
              <CustomFormSectionHeader title='Team' icon={<InvestmentApplicationTeamIcon />} />
            }
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.TEAM)}
          >
            <InvestmentEntityTeam
              {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.TEAM)}
              errors={teamErrors}
              setTeamErrors={(fieldName: string, value: string) =>
                setTeamErrors((prev) => ({ ...prev, [fieldName]: value }))
              }
              isLinked={isLinked}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_ENTITY_STEP.DOCUMENTS,
      label: 'Documents',
      content: (
        <FormProvider {...documentsForm}>
          <FormSection
            customHeader={<CustomFormSectionHeader title='Documents' icon={<MoneyBagIcon />} />}
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.DOCUMENTS)}
            isLastStep
            createBtnText='Create'
          >
            <Documents {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.DOCUMENTS)} />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {isCreateMode ? 'Create New Investment Entity' : 'Investment Entity'}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 10 }}
            onClickStep={handleClickStep}
            errorSteps={errorSteps}
            enableClickStep={!isCreateMode && !isLoading}
            destroyInactiveStep={false}
            showErrorAllStep
            onStepChange={handleStepChanged}
          />
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              onClose?.(false);
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default InvestmentEntityForm;
