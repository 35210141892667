import * as React from 'react';

interface IPenOutlinedIconProps extends React.SVGProps<SVGSVGElement> {}

const PenOutlinedIcon: React.FunctionComponent<IPenOutlinedIconProps> = ({
  width = '20px',
  height = '20px',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox='0 0 20 20.0017794'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Fundraising_Applications_Create-New-Application_47'
          transform='translate(-1137.000000, -349.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Pop-up-Copy' transform='translate(290.000000, 54.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 187.000000)'>
              <g
                id='02.Atoms/02.Button/01.Large/01.No-Icon/01.Primary/01.Default'
                transform='translate(833.000000, 98.000000)'
              >
                <g
                  id='01.Design-Token/06.Icons/Line/plus-outline'
                  transform='translate(12.000000, 8.000000)'
                >
                  <g id='Group' transform='translate(2.000000, 2.000000)'>
                    <path
                      d='M18.956093,1.043907 C20.347969,2.43581388 20.347969,4.69250808 18.956093,6.08441496 L7.03419592,18.0083043 C6.75841173,18.2842149 6.41538938,18.483367 6.03904425,18.5860701 L0.942752011,19.9756963 C0.684201128,20.0458427 0.407883291,19.9721864 0.218554062,19.7826514 C0.0292248337,19.5931164 -0.0441312518,19.3167187 0.0262960192,19.0582441 L1.41592222,13.9619519 C1.51862525,13.5856068 1.71777737,13.2425844 1.99368796,12.9668002 L13.915585,1.043907 C15.3074919,-0.347969001 17.5641861,-0.347969001 18.956093,1.043907 Z M12.9493217,4.12200376 L3.04960465,14.0237131 C2.95775524,14.1156792 2.89148762,14.2300166 2.85734812,14.3554303 L1.81139291,18.1905994 L5.64656201,17.1446442 C5.77147078,17.109619 5.88510705,17.0426732 5.97628693,16.9503954 L15.8779962,7.05067834 L12.9493217,4.12200376 Z M14.9715017,2.09982369 L14.0052383,3.06608707 L16.9339129,5.99476166 L17.9001763,5.02849827 C18.6950259,4.21742642 18.6884607,2.91754271 17.885459,2.11454102 C17.0824573,1.31153933 15.7825736,1.30497409 14.9715017,2.09982369 L14.9715017,2.09982369 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PenOutlinedIcon;
