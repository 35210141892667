import { Box, BoxProps, styled } from '@mui/material';
import * as React from 'react';

interface IScrollbarTrack {
  marginTop?: number;
  marginBottom?: number;
}
interface ICustomScrollbarContainerProps extends BoxProps {
  scrollbarTrackProps?: IScrollbarTrack;
}

const CustomBox = styled(Box)<{ scrollbarTrackProps?: IScrollbarTrack }>(
  ({ theme, scrollbarTrackProps }) => ({
    /* Works on firefox */
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.neutral.ne300,

    /* Works on Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
      width: '4px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral.ne300,
      borderRadius: 3,
    },

    '&::-webkit-scrollbar-track': {
      marginTop: Number.isInteger(scrollbarTrackProps?.marginTop)
        ? `${scrollbarTrackProps?.marginTop}px`
        : '300px',
      marginBottom: Number.isInteger(scrollbarTrackProps?.marginBottom)
        ? `${scrollbarTrackProps?.marginTop}px`
        : '300px',
    },
  }),
);

const CustomScrollbarContainer: React.FunctionComponent<ICustomScrollbarContainerProps> = ({
  children,
  ...rest
}) => {
  return <CustomBox {...rest}>{children}</CustomBox>;
};

export default CustomScrollbarContainer;
