import { Box, debounce, Typography } from '@mui/material';
import qs from 'qs';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CancelIcon } from 'src/assets/icons/CancelIcon';
import DelegateIcon from 'src/assets/icons/DelegateIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import InvestorIcon from 'src/assets/icons/InvestorIcon';
import { WithdrawIcon } from 'src/assets/icons/WithdrawIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { BorderLinearProgress } from 'src/components/atoms/BorderLinearProgress';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import {
  APPLICATION_ACTION,
  applicationStatuses,
  INVESTMENT_ENTITY_ROLE,
  OfferFilter,
  OfferFilterOptions,
} from 'src/constants/applications';
import { ActionType } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useRole } from 'src/hooks/useRole';
import { IAction } from 'src/interfaces/common';
import { appLocalStorageKey } from 'src/modules/applications/consts';
import {
  useCancelApplication,
  useCompletedConnectIdKYC,
  useConnectIdRetrieveToken,
  useGetApplicationFilters,
  useGetApplicationList,
  useGetDuplicatedEntity,
  useRejectDuplicatedEntity,
  useWithdrawApplication,
} from 'src/modules/applications/hooks';
import { IApplication } from 'src/modules/applications/type';
import { ConfirmFormat } from 'src/modules/common/type';
import { InvestorViewModeContext } from 'src/providers/InvestorViewModeProvider';
import { formatCurrencyNumber, handleErrorFromServer } from 'src/utils/common';
import DuplicateEntity from './components/DuplicateEntity';
import InvestmentApplicationForm, {
  INVESTMENT_APPLICATION_STEP,
} from './components/InvestmentApplicationForm';
import MyOfferAndInvitations, { IMyOfferAndInvitationsRef } from './offer/index';

interface IInvestorApplicationsProps {}

interface ITableData extends IApplication {}

const FILTER_BY = {
  investmentEntity: 'investmentEntity',
  status: 'Status',
};

export const InvestorUserRoleOptions = [
  {
    value: INVESTMENT_ENTITY_ROLE.Investor,
    label: 'Investor',
    icon: <InvestorIcon />,
  },
  {
    value: INVESTMENT_ENTITY_ROLE.Delegate,
    label: 'Delegate',
    icon: <DelegateIcon />,
  },
];

const InvestorApplications: FC<IInvestorApplicationsProps> = () => {
  const { investmentEntities: overarchingFilterInvestmentEntities } =
    useContext(InvestorViewModeContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { errorMessage = '' } = location.state || {};

  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [selectedRow, setSelectedRow] = useState<ITableData>();
  const [actionType, setActionType] = useState<ActionType>('view');
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const addUserModalRef = useRef<IBasicModalElement>(null);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [defaultStep, setDefaultStep] = useState(INVESTMENT_APPLICATION_STEP.DETAILS);
  const [duplicateAppId, setDuplicatedAppId] = useState('');

  const { filterActions } = useRole(ROUTES_PATH.APPLICATIONS);

  const {
    data: { items: applicationList = [], metadata: { page, pageSize, totalItem } = {} },
    isLoading,
    setParams,
    refetch,
  } = useGetApplicationList<IApplication>();

  const isOneOfFiltersEmpty =
    Boolean(emptyFilters?.length) || overarchingFilterInvestmentEntities === MULTIPLE_SELECT_EMPTY;

  const applicationOfferRef = useRef<IMyOfferAndInvitationsRef>(null);

  const { data: { statuses = [] } = {} } = useGetApplicationFilters();

  const { data: duplicatedEntity } = useGetDuplicatedEntity(duplicateAppId);

  const { mutate: cancelApplicationMutate, isLoading: isCancelApplicationLoading } =
    useCancelApplication();
  const { mutate: withdrawApplicationMutate, isLoading: isWithdrawApplicationLoading } =
    useWithdrawApplication();

  const { mutateAsync: connectIdRetrieveToken } = useConnectIdRetrieveToken();
  const { mutateAsync: completedConnectIdKYC } = useCompletedConnectIdKYC();

  const { mutateAsync: rejectDuplicatedEntity, isLoading: isRejectingDuplicatedEntity } =
    useRejectDuplicatedEntity();

  const applicationStatusesFormat = useMemo(() => {
    return statuses
      ?.map((item: (typeof statuses)[0]) => ({
        label: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.label?.localeCompare(b.label));
  }, [statuses]);

  useEffect(() => {
    handleKycConnectId();
    handleDuplicatedApplication();
    // Open the application modal after the page is reloaded when user closes the Biometric modal
    handleOpenAppModalAfterCloseBiometric();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      modalAlertRef.current?.open();
      clearErrorMessageInLocationState();
    }
  }, [errorMessage]);

  const handleOpenAppModalAfterCloseBiometric = () => {
    const { id, step } = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
      [key: string]: string;
    };

    if (id && step) {
      setDefaultStep(Number(step));
      setSelectedRow({ id: id, statusName: applicationStatuses.Submitted } as IApplication);
      // Remove id and step from url
      navigate({
        pathname: location.pathname,
        search: '',
      });

      setActionType('edit');
      handleOpenInvestmentApplicationModal(true);
    }
  };

  const handleKycConnectId = async () => {
    const { iss, state, code } = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
      [key: string]: string;
    };
    if (iss && state) {
      try {
        const kycData = JSON.parse(
          localStorage.getItem(appLocalStorageKey.verifyConnectIdKey) || '',
        );

        if (code) {
          const kycResult = await connectIdRetrieveToken({
            code,
            queryState: state,
            iss,
            auth_server_id: kycData?.auth_server_id,
            state: kycData?.state,
            nonce: kycData?.nonce,
            code_verifier: kycData?.code_verifier,
          });
          const kycInfo = JSON.parse(kycResult.decodedToken) || {};
          await completedConnectIdKYC({
            appId: kycData?.appId,
            individualId: kycData?.individualId,
            code: kycData?.authCode,
            body: {
              name: kycInfo.name,
              familyName: kycInfo.family_name,
              givenName: kycInfo.given_name,
              middleName: kycInfo.middle_name,
              email: kycInfo.email,
              phoneNumber: kycInfo.phone_number,
              birthdate: kycInfo.birthdate,
              address: {
                country: kycInfo.address?.country,
                locality: kycInfo.address?.locality,
                postalCode: kycInfo.address?.postal_code,
                region: kycInfo.address?.region,
                streetAddress: kycInfo.address?.street_address,
              },
              transactionId: kycInfo.txn,
              bankName: kycData?.bankName,
              issuer: iss,
            },
          });
        }
        setSelectedRow({ id: kycData?.appId } as IApplication);
        setActionType('edit');
        setDefaultStep(INVESTMENT_APPLICATION_STEP.KYC_VERIFICATION);
        handleOpenInvestmentApplicationModal(true);
      } catch (e) {
        console.error(e);
      } finally {
        window.history.replaceState({}, '', location.pathname);
      }
    }
  };

  const handleDuplicatedApplication = () => {
    const { ApplicationId: appId, IsDuplicated: isDuplicated } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as {
      [key: string]: string;
    };
    if (isDuplicated && appId) {
      setDuplicatedAppId(appId);
      window.history.replaceState({}, '', location.pathname);
    }
  };

  useEffect(() => {
    if (duplicatedEntity) {
      setConfirmFormat({
        title: 'Action Required!',
        content: `<span style="white-space: pre-line">A new application has been initiated by ${duplicatedEntity.firstName} ${duplicatedEntity.lastName} for ${duplicatedEntity.entityName}.\n${duplicatedEntity.firstName} ${duplicatedEntity.lastName} is currently not part of your team, and application cannot proceed without your authority.</span>`,
        button: {
          type: 'primary',
          label: 'Accept',
          onAction: async () => {
            modalConfirmRef?.current?.close();
            addUserModalRef.current?.open();
          },
        },
        cancelButton: {
          label: 'Reject',
          onAction: async () => {
            await rejectDuplicatedEntity(duplicateAppId, { onError: handleErrorFromServer });
          },
        },
      });
      modalConfirmRef.current?.open();
    }
  }, [!!duplicatedEntity]);

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const handleChangePage = (page: number) => {
    setParams({ page });
  };

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  const handleSearchApplication = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({ search: event.target.value });
  }, 300);

  const handleSetEmptyFilters = (values: string | string[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: (status as string[]).map((item: string) => Number(item)) });
    }

    handleSetEmptyFilters(status, FILTER_BY.status);
  };

  const handleCancelApplication = (appId: string) => {
    cancelApplicationMutate(
      { appId },
      {
        onSuccess: () => {
          refetch();
          modalConfirmRef?.current?.close();
        },
      },
    );
  };

  const handleWithdrawApplication = (appId: string) => {
    withdrawApplicationMutate(
      { appId },
      {
        onSuccess: () => {
          refetch();
          modalConfirmRef?.current?.close();
        },
      },
    );
  };

  const clearErrorMessageInLocationState = () => {
    window.history.replaceState({}, '');
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Fund Name',
      key: 'fundName',
      sorter: true,
      sortBy: 'fundName',
      sx: { minWidth: 180, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.fundName || ''} />,
    },
    {
      title: 'Offer Name',
      key: 'offerName',
      sorter: true,
      sortBy: 'offerName',
      sx: { minWidth: 180, width: '20%' },
      renderNode: (row) => <StringNodeTable value={row?.offerName || ''} />,
    },
    {
      title: 'Investment Entity Name',
      key: 'investmentEntity',
      sorter: true,
      sortBy: 'investmentEntity',
      sx: { minWidth: 150, width: '15%' },
      renderNode: (row) => <StringNodeTable value={row?.investmentEntity || ''} />,
    },
    {
      title: 'Investment Amount',
      key: 'investmentAmount',
      sx: { minWidth: 200, width: '15%' },
      renderNode: (row) => (
        <StringNodeTable
          value={`${formatCurrencyNumber(row?.investmentAmount, 2)} ${row?.currency}`}
        />
      ),
    },
    {
      title: 'Progress',
      key: 'progress',
      sx: { minWidth: 150, width: '10%' },
      renderNode: (row) => {
        return <BorderLinearProgress variant='determinate' value={row?.progress} />;
      },
    },
    {
      title: 'Status',
      key: 'statusName',
      sx: { minWidth: 130, width: '10%' },
      isSticky: true,
      renderNode: (row) => {
        return <StatusBadge status={row?.statusName || ''} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      sx: { minWidth: 90, width: 90 },
      isSticky: true,
      renderNode: (row) => {
        const getMenus = {
          [applicationStatuses.In_Progress]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.cancel,
          ],
          [applicationStatuses.Submitted]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.withdraw,
          ],
          [applicationStatuses.In_Review]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.withdraw,
          ],
          [applicationStatuses.Action_Required]: [
            APPLICATION_ACTION.view,
            APPLICATION_ACTION.edit,
            APPLICATION_ACTION.withdraw,
          ],
          [applicationStatuses.Approved]: [APPLICATION_ACTION.view, APPLICATION_ACTION.withdraw],
          [applicationStatuses.Finalised]: [APPLICATION_ACTION.view],
          [applicationStatuses.Cancelled]: [APPLICATION_ACTION.view],
          [applicationStatuses.Pending]: [APPLICATION_ACTION.view, APPLICATION_ACTION.withdraw],
          [applicationStatuses.Rejected]: [APPLICATION_ACTION.view],
          [applicationStatuses.Withdrawn]: [APPLICATION_ACTION.view],
          [applicationStatuses.Cancelled]: [APPLICATION_ACTION.view],
        };

        const actionIcons = {
          [APPLICATION_ACTION.view.key]: <DocumentOnePageIcon />,
          [APPLICATION_ACTION.edit.key]: <EditOutlinedIcon />,
          [APPLICATION_ACTION.cancel.key]: <CancelIcon />,
          [APPLICATION_ACTION.withdraw.key]: <WithdrawIcon />,
        };

        const actions = {
          [APPLICATION_ACTION.view.key]: () => {
            setActionType('view');
            setSelectedRow(row);
            handleOpenInvestmentApplicationModal(true);
          },
          [APPLICATION_ACTION.edit.key]: () => {
            setActionType('edit');
            setSelectedRow(row);
            handleOpenInvestmentApplicationModal(true);
          },
          [APPLICATION_ACTION.cancel.key]: () => {
            setConfirmFormat({
              title: 'Cancel Application?',
              content: `Are you sure you want to cancel the application? This action cannot be undone.`,
              button: {
                type: 'primary',
                label: 'Yes',
                onAction: () => handleCancelApplication(row?.id),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [APPLICATION_ACTION.withdraw.key]: () => {
            setConfirmFormat({
              title: 'Withdraw Application?',
              content: `Are you sure you want to withdraw the application? This action cannot be undone.`,
              button: {
                type: 'primary',
                label: 'Withdraw',
                onAction: () => handleWithdrawApplication(row?.id),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const permissionActions: IAction[] = filterActions(getMenus[row.statusName], true);

        const menus = permissionActions?.map((menuItem) => ({
          icon: actionIcons?.[menuItem.key],
          label: menuItem.label,
          onAction: actions?.[menuItem.key],
        }));

        return <ActionMenus menus={menus} />;
      },
    },
  ];

  const investmentApplicationModalRef = useRef<IBasicModalElement>(null);

  const handleOpenInvestmentApplicationModal = (isOpen: boolean) => {
    if (isOpen) {
      investmentApplicationModalRef.current?.open();
    } else {
      refetch();
      investmentApplicationModalRef.current?.close();
    }
  };

  const handleCloseAddUserModal = () => {
    addUserModalRef.current?.close();
  };

  const handleChangeShowType = (e: any) => {
    const value = e?.target?.value;
    applicationOfferRef?.current?.onRefetchOfferList?.(value);
  };

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box component='div'>
          <Typography variant='h6'>My Offers & Invitations</Typography>
        </Box>
        <Box className='flex items-center gap-4'>
          <CustomSelect
            sx={{ width: '246px' }}
            defaultValue={OfferFilter.ShowAll}
            onChange={handleChangeShowType}
          >
            {OfferFilterOptions.map((option) => (
              <CustomMenuItem key={option.value} value={option.value}>
                {option.label}
              </CustomMenuItem>
            ))}
          </CustomSelect>
        </Box>
      </Box>
      <MyOfferAndInvitations ref={applicationOfferRef} />
      <Box className='mt-[76px]'>
        <Box component='div'>
          <Typography variant='h6'>Applications</Typography>
        </Box>
        <Box className='flex items-center gap-4 mt-6'>
          <Box width='33%'>
            <SearchField
              placeholder='Search by fund name or offer name'
              onChange={handleSearchApplication}
            />
          </Box>
          <CustomMultiSelect
            onChange={handleStatusChange}
            options={applicationStatusesFormat}
            label='Status'
            customRenderLabel={customRenderStatusLabel}
            showClearAllIcon
          />
        </Box>
        <Box className='mt-10'>
          {(applicationList.length && !isOneOfFiltersEmpty) || isLoading ? (
            <CustomTable
              columns={columns}
              rows={applicationList}
              isFetchingData={isLoading}
              totalResults={totalItem}
              currentPage={page}
              numberItemOnPage={pageSize}
              onChangePagination={handleChangePage}
              onSort={handleSort}
              selectedRows={[]}
            />
          ) : (
            <EmptyData />
          )}
        </Box>
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          description={errorMessage}
          isError
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
      <BasicModal
        ref={investmentApplicationModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => handleOpenInvestmentApplicationModal(false)}
        isPreventClickOutside
      >
        <InvestmentApplicationForm
          onClose={() => handleOpenInvestmentApplicationModal(false)}
          id={selectedRow?.id}
          isEditMode={actionType === 'edit'}
          isViewMode={actionType === 'view'}
          isDraft={selectedRow?.statusName === applicationStatuses.In_Progress}
          step={defaultStep}
        />
      </BasicModal>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={async () => {
                if (confirmFormat?.cancelButton?.onAction) {
                  await confirmFormat?.cancelButton?.onAction();
                }
                modalConfirmRef?.current?.close();
              }}
              isLoading={isRejectingDuplicatedEntity}
            >
              {confirmFormat?.cancelButton?.label || 'Cancel'}
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={isCancelApplicationLoading || isWithdrawApplicationLoading}
              disabled={isRejectingDuplicatedEntity}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal
        ref={addUserModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={handleCloseAddUserModal}
      >
        <DuplicateEntity onClose={handleCloseAddUserModal} appId={duplicateAppId} />
      </BasicModal>
    </Box>
  );
};

export default InvestorApplications;
