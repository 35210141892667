import { createSearchParams, useNavigate } from 'react-router-dom';

export const useNavigateWithParams = () => {
  const navigate = useNavigate();

  return (pathname: string, queryObject?: any) => {
    navigate({
      pathname,
      search: queryObject ? createSearchParams(queryObject)?.toString() : '',
    });
  };
};
