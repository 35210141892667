import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormInput from 'src/components/atoms/FormInput';
import { IInvestmentEntityProps } from '..';

export interface IDirectorsFormItemProps extends IInvestmentEntityProps {
  keyName: string;
  onDelete: () => void;
}

const DirectorFormItem: FC<IDirectorsFormItemProps> = ({
  keyName,
  onDelete,
  isViewMode,
  isDisabledEdit,
  isExistingInvestmentEntity,
}) => {
  const theme = useTheme();
  const { watch } = useFormContext();
  const watchFirstName = watch(`${keyName}.firstName`) || '';
  const watchLastName = watch(`${keyName}.lastName`) || '';

  return (
    <Accordion
      defaultExpanded
      className='director-detail'
      sx={{
        border: `1px solid ${theme.palette.neutral.ne300} !important`,
        '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
      }}
    >
      <AccordionSummary
        sx={{
          '.MuiSvgIcon-root': { fontSize: '18px', color: 'n' },
          '.MuiAccordionSummary-content': { my: 0 },
          minHeight: 'unset',
        }}
      >
        <Box className='flex justify-between items-center w-full'>
          <Typography variant='body2' fontWeight={500}>
            {`${watchFirstName} ${watchLastName}`}
          </Typography>
          {!(isViewMode || isDisabledEdit) && !isExistingInvestmentEntity && (
            <IconButton sx={{ p: 0 }} onClick={onDelete}>
              <DeleteIcon width={20} height={22} color={theme.palette.neutral.ne600} />
            </IconButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className='p-6 pt-0 mt-0'>
        <Grid container columnSpacing='20px'>
          <Grid item xs={6}>
            <FormInput
              name={`${keyName}.firstName`}
              label='First Name'
              placeholder='Enter first name'
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name={`${keyName}.lastName`}
              label='Last Name'
              placeholder='Enter last name'
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DirectorFormItem;
