import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface IClientSummaryPercentBarProps {
  isActive: boolean;
  quantity: number;
  percent: number;
}

const ClientSummaryPercentBar: FC<IClientSummaryPercentBarProps> = ({
  isActive,
  quantity,
  percent,
}) => {
  if (!quantity) return null;
  return (
    <Box
      className='flex flex-col items-center'
      sx={{
        width: `${percent}%`,
      }}
    >
      <Typography
        className='flex items-center min-w-[36px]'
        variant='caption1'
        color='neutral.ne700'
      >
        <span className='inline-block'>{quantity}</span>
        <span className='inline-block w-[1px] h-[11px] mx-[3px] bg-black' />
        <span className='inline-block'>{`${percent}%`}</span>
      </Typography>
      <Box
        sx={(theme) => ({
          background: isActive ? theme.palette.secondary.se700 : theme.palette.secondary.se300,
          height: '10px',
          borderRadius: '8px',
          width: '100%',
        })}
      />
    </Box>
  );
};

export default ClientSummaryPercentBar;
