import { Skeleton, styled } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import * as storage from 'src/helpers/storage';

const defaultResolutionLogo = {
  width: 226,
  height: 127,
};

const CustomLogoImg = styled('img')(() => ({
  maxWidth: '229px',
  maxHeight: '127px',
  objectFit: 'contain',
}));

export const Logo: React.FunctionComponent<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ ...props }) => {
  const img = React.useRef<HTMLImageElement | null>(null);
  const [logoSize, setLogoSize] = React.useState(
    JSON.parse(
      storage.getValue(STORAGE_KEYS.LOGO_RESOLUTION_KEY) || JSON.stringify(defaultResolutionLogo),
    ),
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const onLoad = (e: any) => {
    if (e.target.clientWidth) {
      setLogoSize({ width: e.target.clientWidth, height: e.target.clientHeight });
    }
    setLoading(false);
    setError(false);
  };

  const onError = () => setError(true);

  React.useEffect(() => {
    if (logoSize.width) {
      storage.setValue(STORAGE_KEYS.LOGO_RESOLUTION_KEY, JSON.stringify(logoSize));
    }
  }, [logoSize]);

  return (
    <>
      <CustomLogoImg
        {...props}
        className={clsx(props.className, {
          hidden: loading || !!error,
        })}
        onLoadStart={() => console.log('capture')}
        onLoad={onLoad}
        onError={onError}
        ref={img}
      />
      <Skeleton
        variant='rectangular'
        className={clsx(props.className, {
          hidden: !loading,
        })}
        {...logoSize}
      />
    </>
  );
};
