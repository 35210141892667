import * as React from 'react';

const EmailIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='13.3333333px'
      height='10.6666667px'
      viewBox='0 0 13.3333333 10.6666667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Administration_Fundraising_Create-New-Offer_27'
          transform='translate(-997.333333, -1159.666667)'
          fill={props.color || '#686D6E'}
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 127.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 187.000000)'>
              <g id='Content' transform='translate(210.000000, 166.000000)'>
                <g id='Group-2' transform='translate(496.000000, 572.000000)'>
                  <g id='Group-Copy-2' transform='translate(0.000000, 104.000000)'>
                    <g
                      id='01.Design-Token/06.Icons/Fluent/Line/Mail'
                      transform='translate(0.000000, 1.000000)'
                    >
                      <g id='Group' transform='translate(1.333333, 2.666667)'>
                        <path
                          d='M2.16666667,0 L11.1666667,0 C12.3156848,0 13.2649479,0.896824823 13.33,2.044 L13.3333333,2.16666667 L13.3333333,8.5 C13.3333333,9.64901813 12.4365085,10.5982812 11.2893333,10.6633333 L11.1666667,10.6666667 L2.16666667,10.6666667 C1.01764854,10.6666667 0.0683854342,9.76984184 0.00333333333,8.62266667 L0,8.5 L0,2.16666667 C0,1.01764854 0.896824823,0.0683854342 2.044,0.00333333333 L2.16666667,0 L11.1666667,0 L2.16666667,0 Z M12.3333333,3.582 L6.9,6.442 C6.77595546,6.50752866 6.63008779,6.5179305 6.498,6.47066667 L6.434,6.44266667 L1,3.58266667 L1,8.5 C1,9.10709495 1.46563051,9.61271143 2.07066667,9.66266667 L2.16666667,9.66666667 L11.1666667,9.66666667 C11.7739975,9.66666667 12.2797057,9.20063313 12.3293333,8.59533333 L12.3333333,8.5 L12.3333333,3.582 Z M11.1666667,1 L2.16666667,1 C1.55957171,1 1.05395524,1.46563051 1.004,2.07066667 L1,2.16666667 L1,2.45266667 L6.66666667,5.43466667 L12.3333333,2.452 L12.3333333,2.16666667 C12.3333333,1.55933584 11.8672998,1.0536276 11.262,1.004 L11.1666667,1 Z'
                          id='Shape'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmailIcon;
