import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';

interface ICommonSectionProps extends BoxProps {
  title: string;
  description: string;
  ButtonComponent?: JSX.Element;
}

const CommonSection: FC<ICommonSectionProps> = ({
  title,
  description,
  ButtonComponent,
  children,
}) => {
  return (
    <Box className='section flex-1 flex flex-col' component='section'>
      <Box
        className='flex justify-between items-center border-b-2 pb-4 section-header'
        borderColor='neutral.ne200'
      >
        <Box>
          <Typography variant='h6' textTransform='capitalize'>
            {title}
          </Typography>
          <Typography variant='body2' color='neutral.ne500'>
            {description}
          </Typography>
        </Box>
        {ButtonComponent && ButtonComponent}
      </Box>
      {children}
    </Box>
  );
};

export default CommonSection;
