import { Box, BoxProps, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

type VariantType = 'success' | 'error' | 'default';
interface IInputMessageProps extends BoxProps {
  message: ReactNode | undefined;
  variant?: VariantType;
  icon?: ReactNode;
  isShowIcon?: boolean;
}

const MessageBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  minWidth: 120,
  borderRadius: 8,
  '&.default': {
    color: theme.palette.neutral.ne500,
  },
  '&.success': {
    color: theme.palette.primary.main,
  },
  '&.error': {
    color: theme.palette.error.main,
  },
  '& .icon': {
    fontSize: 16,
    marginRight: 8,
  },
}));

const InputMessage: React.FC<IInputMessageProps> = ({
  message = '',
  variant = 'default',
  className = '',
  isShowIcon,
  ...rest
}) => {
  return (
    <MessageBoxContainer
      className={`${className} ${variant} flex items-center`}
      component='span'
      {...rest}
    >
      {isShowIcon && <span className='material-symbols-rounded icon'>error</span>}
      <Typography variant='body3' lineHeight='unset'>
        {message}
      </Typography>
    </MessageBoxContainer>
  );
};

export default InputMessage;
