import * as React from 'react';
import HorizontalTabs from 'src/components/molecules/HorizontalTabs';
import { IUpsertFundForm } from 'src/modules/funds-management/type';
import GeneralContact from './GeneralContact';
import InvestorRelationsContact from './InvestorRelationsContact';

export interface ContactDetailsProps extends IUpsertFundForm {}

const ContactDetails: React.FC<ContactDetailsProps> = ({ ...props }) => {
  const Tabs: { label: string; content: React.ReactNode }[] = [
    {
      label: 'General Contact',
      content: <GeneralContact {...props} />,
    },
    {
      label: 'Investor Relations Contact',
      content: <InvestorRelationsContact {...props} />,
    },
  ];

  return <HorizontalTabs tabs={Tabs} />;
};

export default ContactDetails;
