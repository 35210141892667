import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { capitalize, cloneDeep, get } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import { CustomSwitch } from 'src/components/atoms/CustomSwitch';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { ActionType } from 'src/constants/common';
import { DATE_PICKER_FORMAT, DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import {
  BILLING_FEE_TYPE,
  BILLING_MODE,
  BILLING_VOLUME_ITEM_TIER,
  SUBSCRIPTION_FEATURE_TYPE,
  billingBySubscriptionFeatures,
  billingFormDefaultValues,
  billingTieringSystem,
  multipleFees,
  singleFees,
  tieringFees,
} from 'src/constants/subscription-billing';
import { useGetClientDetail } from 'src/modules/client/hooks';
import {
  useCreateBillingPlan,
  useGetBillingPlanDetail,
  useGetStandardPricingDetailByDate,
  useGetSubscriptionsFeatures,
  useUpdateBillingPlan,
} from 'src/modules/subscription-billing/hooks';
import {
  BillingPlan,
  BillingPricingItem,
  TieringFeeForm,
} from 'src/modules/subscription-billing/types';
import { formatNumberWithCommas, handleErrorFromServer } from 'src/utils/common';
import { billingFormSchema } from 'src/validations/subscription-billing';
import { ISubscriptionAndBillingProps } from '.';
import EditMultipleFeeForm from './EditMultipleFeeForm';
import EditSingleFeeForm from './EditSingleFeeForm';
import EditTieringFeeForm from './EditTieringFeeForm';
import GenerateInvoiceForm from './GenerateInvoiceForm';
import StandardPriceHistory from './StandardPriceHistory';
import StandardPricing from './StandardPricing';

interface ITableData extends BillingPricingItem {}

interface IBillingProps extends ISubscriptionAndBillingProps {
  id?: string;
  defaultValue?: BillingPlan;
  subscriptionFeatureIds: string[];
  actionType: ActionType;
  onClose: () => void;
  handleSaveBillingPlan: (_data: BillingPlan) => void;
}

const CreateBillingPlanForm = (props: IBillingProps) => {
  const {
    defaultValue,
    isSuperAdmin,
    isViewMode,
    isDraft,
    actionType,
    onClose,
    id,
    clientId,
    subscriptionFeatureIds,
    handleSaveBillingPlan,
    isEditingClient,
    isCreatedClient,
  } = props;

  const form = useForm<BillingPlan>({
    defaultValues: billingFormDefaultValues,
    resolver: yupResolver(billingFormSchema),
  });
  const { watch, setValue, handleSubmit, reset, getValues } = form;

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const isOpenEnded = watch('isOpenEnded');
  const mode = watch('mode');

  const editSingleFeeModalRef = useRef<IBasicModalElement>(null);
  const editMultipleFeeModalRef = useRef<IBasicModalElement>(null);
  const editTieringFeeModalRef = useRef<IBasicModalElement>(null);
  const standardPricingModalRef = useRef<IBasicModalElement>(null);
  const standardPriceHistoryModalRef = useRef<IBasicModalElement>(null);
  const confirmModalRef = useRef<IBasicModalElement & { onOk: () => void }>(null);
  const generateInvoiceModalRef = useRef<IBasicModalElement>(null);

  const [selectedBillingPricing, setSelectedBillingPricing] = useState<BillingPricingItem>();
  const [billingList, setBillingList] = useState<BillingPricingItem[]>([]);
  const [selectedBilling, setSelectedBilling] = useState<{ [key: string]: boolean }>({});

  const getDateQuery = () => {
    if ((!startDate || !dayjs(startDate)?.isValid()) && (!endDate || !dayjs(endDate)?.isValid())) {
      return dayjs();
    } else if (!startDate || !dayjs(startDate)?.isValid()) {
      return !dayjs(endDate).isBefore(dayjs(), 'day') ? dayjs() : dayjs(endDate);
    } else if (!endDate || !dayjs(endDate)?.isValid()) {
      return !dayjs(startDate).isAfter(dayjs(), 'day') ? dayjs() : dayjs(startDate);
    } else {
      return !dayjs(startDate).isAfter(dayjs(), 'day') && !dayjs(endDate).isBefore(dayjs(), 'day')
        ? dayjs()
        : dayjs(startDate);
    }
  };

  const { data: clientDetails } = useGetClientDetail(clientId);
  const { data: subscriptionsFeatures = [] } = useGetSubscriptionsFeatures();
  const { data: standardPricingDetailByDate, isLoading: isLoadingStandardPricingDetailByDate } =
    // use id to prevent refetch data in edit mode, no need this data in edit mode
    useGetStandardPricingDetailByDate(
      (id ? dayjs() : getDateQuery()).format(DATE_PICKER_FORMAT_SERVER),
    );
  const { data: billingPlanDetail, isLoading: isLoadingBillingPlanDetail } =
    useGetBillingPlanDetail(clientId, id || '');
  const { mutate: createBillingPlan, isLoading: isCreatingBillingPlan } = useCreateBillingPlan();
  const { mutate: updateBillingPlan, isLoading: isUpdatingBillingPlan } = useUpdateBillingPlan();

  const isLoading = id ? isLoadingBillingPlanDetail : isLoadingStandardPricingDetailByDate;

  const isCreateMode = actionType === 'create';
  const isEditMode = actionType === 'edit';

  const isDisabledEditAll =
    !isDraft &&
    isEditingClient &&
    isEditMode &&
    clientDetails?.billings?.startDate &&
    dayjs(clientDetails?.billings?.startDate).isBefore(dayjs(), 'day') &&
    clientDetails?.billings?.endDate &&
    dayjs(clientDetails?.billings?.endDate).isBefore(dayjs(), 'day');

  const isAllowedEditOnlyEndDate =
    !isDraft &&
    isEditingClient &&
    isEditMode &&
    clientDetails?.billings?.startDate &&
    dayjs(clientDetails?.billings?.startDate).isBefore(dayjs(), 'day') &&
    (clientDetails?.billings?.endDate === null ||
      (clientDetails?.billings?.endDate &&
        (dayjs(clientDetails?.billings?.endDate).isAfter(dayjs(), 'day') ||
          dayjs(clientDetails?.billings?.endDate).isSame(dayjs(), 'day'))));

  useEffect(() => {
    handleData();
  }, [
    id,
    standardPricingDetailByDate,
    billingPlanDetail,
    subscriptionsFeatures,
    JSON.stringify(subscriptionFeatureIds),
    isCreateMode,
  ]);

  const handleData = (overrideData?: Partial<BillingPlan>) => {
    const data = id
      ? billingPlanDetail?.items?.concat(billingPlanDetail?.disabledItems || [])
      : defaultValue?.items?.concat(defaultValue?.disabledItems || []) ||
        standardPricingDetailByDate?.standardItems;

    const hiddenItems = (
      Object.keys(billingBySubscriptionFeatures) as (string | SUBSCRIPTION_FEATURE_TYPE)[]
    )
      .map((key) => {
        // key: subscriptionFeature featureType or ref
        const subscriptionFeature = subscriptionsFeatures.find(
          (it) => String(it.featureType) === String(key) || it.ref === key,
        );

        if (subscriptionFeatureIds.includes(subscriptionFeature?.id || '')) {
          return billingBySubscriptionFeatures?.[key]?.check?.hide || [];
        } else {
          return billingBySubscriptionFeatures?.[key]?.uncheck?.hide || [];
        }
      })
      .flat();

    const processedData = cloneDeep(data?.sort((a, b) => a.billingType - b.billingType))
      ?.map((item) => {
        if (typeof item.tieringSystem !== 'boolean') {
          item.tieringSystem = true;
        }
        return item;
      })
      ?.filter((item) => !hiddenItems.includes(item.billingType));

    const formData = getValues();
    const newFormData = {
      ...formData,
      ...(defaultValue || billingPlanDetail),
      ...overrideData,
      existingStartDate: clientDetails?.billings?.startDate,
      existingEndDate: clientDetails?.billings?.endDate,
      items: processedData || [],
      isEditMode,
      isCreateMode,
      isEditingClient,
      isCreatedClient,
    };
    newFormData.isOpenEnded = !newFormData.endDate;

    if (newFormData?.disabledItems) {
      setSelectedBilling((prev) => {
        const disabledItems = newFormData?.disabledItems?.map((it) => it.billingId) || [];
        newFormData?.items?.forEach((it) => {
          prev[it.billingId] = !disabledItems.includes(it.billingId);
        });
        return { ...prev };
      });
    }

    if (isCreateMode) {
      setSelectedBilling((prev) => {
        newFormData.items?.forEach((it) => {
          prev[it.billingId] = true;
        });
        return { ...prev };
      });
    }

    reset(newFormData);
    updateBillingList(processedData);
  };

  const updateBillingList = (data: BillingPricingItem[] = []) => {
    const billingList: BillingPricingItem[] = [];
    cloneDeep(data).forEach((item) => {
      let totalPrice = item.price;
      const currentLength = billingList.push(item);

      item.volumes?.forEach((child) => {
        const itemToPush = { volume: '', price: child.price, isChildren: true };
        if (child.tierType === BILLING_VOLUME_ITEM_TIER.NoTier) {
          if (!item.tieringSystem) {
            itemToPush.volume = 'No Tier';
          }
        } else if (typeof child.minVolume === 'number') {
          if (item.tieringSystem) {
            itemToPush.volume = `${child.minVolume} - ${
              typeof child.maxVolume === 'number' ? child.maxVolume : '∞'
            }`;
          }
        }
        if (itemToPush.volume) {
          if (!billingList[currentLength - 1].volume) {
            billingList[currentLength - 1].volume = itemToPush.volume;
            billingList[currentLength - 1].price = itemToPush.price;
          } else {
            billingList.push(itemToPush as BillingPricingItem);
          }
        }
      });

      get(item, 'subBillings')?.forEach((child) => {
        totalPrice += child.price;
        billingList.push({ ...child, isChildren: true, feeType: undefined });
      });
      if (!item.hasTieringSystem) {
        billingList[currentLength - 1].price = totalPrice;
      }
    });
    setBillingList(billingList);
  };

  const handleChangeOpenEnded = () => {
    setValue('endDate', null);
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedBilling((prev) => {
        billingList.forEach((it) => {
          if (!it.isChildren) {
            prev[it.billingId] = true;
          }
        });
        return { ...prev };
      });
    } else {
      setSelectedBilling({});
    }
  };

  const handleChangeMode = (e: ChangeEvent<HTMLInputElement>) => {
    const newMode = e.target.checked ? BILLING_MODE.Custom : BILLING_MODE.Standard;
    setValue('mode', newMode);
    handleData({ mode: newMode });
  };

  const onUpdateSingleFee = (fee: number, item?: BillingPricingItem) => {
    const data = getValues();
    const index = data.items.findIndex((it) => it.billingId === item?.billingId);
    if (index > -1) {
      setValue(`items.${index}.price`, fee);
    }
    updateBillingList(getValues().items);
  };

  const onUpdateMultipleFee = (fees: Record<string, number>, item?: BillingPricingItem) => {
    const data = getValues();
    const parentIndex = data.items.findIndex((it) => it.billingId === item?.billingId);

    Object.keys(fees).forEach((id) => {
      const fee = fees[id];
      const childIndex = (data.items[parentIndex]?.subBillings || []).findIndex(
        (it) => it.billingId === id,
      );
      if (childIndex > -1) {
        setValue(`items.${parentIndex}.subBillings.${childIndex}.price` as any, fee);
      }
    });

    updateBillingList(getValues().items);
  };

  const onUpdateTieringFee = (tieringData: TieringFeeForm, item?: BillingPricingItem) => {
    const data = getValues();
    const parentIndex = data.items.findIndex((it) => it.billingId === item?.billingId);
    if (parentIndex > -1) {
      data.items[parentIndex].volumes?.forEach((it, index) => {
        let fee: any = null,
          minVolume: any = null,
          maxVolume: any = null;

        switch (it.tierType) {
          case BILLING_VOLUME_ITEM_TIER.NoTier:
            fee = Number(tieringData.noTierFee);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier1:
            fee = Number(tieringData.tier1Fee);
            minVolume = Number(tieringData.tier1MinVolume);
            maxVolume = Number(tieringData.tier1MaxVolume);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier2:
            fee = Number(tieringData.tier2Fee);
            minVolume = Number(tieringData.tier2MinVolume);
            maxVolume = tieringData.noMax ? null : Number(tieringData.tier2MaxVolume);
            break;
          case BILLING_VOLUME_ITEM_TIER.Tier3:
            // pass api validation
            fee = Number(tieringData.tier3Fee);
            if (!tieringData.noMax) {
              minVolume = Number(tieringData.tier3MinVolume);
            }
            break;
          default:
            break;
        }

        setValue(`items.${parentIndex}.volumes.${index}.minVolume`, minVolume);
        setValue(`items.${parentIndex}.volumes.${index}.maxVolume`, maxVolume);
        setValue(`items.${parentIndex}.volumes.${index}.price`, fee || 0);
      });
      setValue(`items.${parentIndex}.tieringSystem`, tieringData.tieringSystem);
    }
    updateBillingList(getValues().items);
  };

  const onSubmit = () => {
    let data = cloneDeep(form.getValues());
    data = {
      ...data,
      items: data.items.filter((it) => selectedBilling[it.billingId]),
      disabledItems: data.items.filter((it) => !selectedBilling[it.billingId]),
      startDate:
        !startDate || !dayjs(startDate).isValid()
          ? null
          : dayjs(startDate).format(DATE_PICKER_FORMAT_SERVER),
      endDate:
        !endDate || !dayjs(endDate).isValid()
          ? null
          : dayjs(endDate).format(DATE_PICKER_FORMAT_SERVER),
    };

    if (!clientId || isDraft) {
      handleSaveBillingPlan(data);
      onClose();
      return;
    }

    if (id) {
      updateBillingPlan(
        { clientId, billingPlanId: id, data },
        {
          onSuccess: () => onClose(),
          onError: handleErrorFromServer,
        },
      );
      return;
    }

    const createBillingPlanAction = () =>
      createBillingPlan(
        { clientId, data },
        {
          onSuccess: () => onClose(),
          onError: handleErrorFromServer,
        },
      );
    if (
      isEditingClient &&
      isCreateMode &&
      ((clientDetails?.billings?.endDate &&
        dayjs(startDate).isBefore(dayjs(clientDetails?.billings?.endDate), 'day')) ||
        clientDetails?.billings?.endDate === null)
    ) {
      if (confirmModalRef.current) {
        confirmModalRef.current.open();
        confirmModalRef.current.onOk = createBillingPlanAction;
      }
    } else {
      createBillingPlanAction();
    }
  };

  const columns: ColumnProps<ITableData, 'action' | 'checkbox'>[] = [
    {
      title: (
        <CustomCheckbox
          sx={{
            p: 0,
            '.MuiSvgIcon-root': {
              width: '22px',
              height: '22px',
            },
          }}
          disableRipple
          checked={
            Object.values(selectedBilling).filter(Boolean).length ===
            billingList.filter((it) => !it.isChildren).length
          }
          onChange={(e) => handleCheckAll(e.target.checked)}
          disabled={isViewMode || isDisabledEditAll || isAllowedEditOnlyEndDate}
        />
      ),
      key: 'checkbox',
      sx: { width: '9%', textAlign: 'center', borderRight: '0px !important', py: '11px' },
      cellSx: (row) => ({
        textAlign: 'center',
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) =>
        row.isChildren ? null : (
          <CustomCheckbox
            sx={{
              p: 0,
              '.MuiSvgIcon-root': {
                width: '22px',
                height: '22px',
              },
            }}
            checked={!!selectedBilling[row.billingId]}
            onChange={(e) =>
              setSelectedBilling((prev) => {
                prev[row.billingId] = e.target.checked;
                return { ...prev };
              })
            }
            disableRipple
            disabled={isViewMode || isDisabledEditAll || isAllowedEditOnlyEndDate}
          />
        ),
    },
    {
      title: 'Description',
      key: 'typeName',
      sx: { minWidth: 200, width: '30%', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => (
        <Box ml={row.isChildren && !row.volume ? 2 : 0}>
          <StringNodeTable variant='body3' value={row?.typeName || row?.description} />
        </Box>
      ),
    },
    {
      title: 'Fee Type',
      key: 'feeType',
      sx: { width: '12%', whiteSpace: 'pre', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          whiteSpace={'pre'}
          value={typeof row?.feeType === 'number' && BILLING_FEE_TYPE[row?.feeType]}
        />
      ),
    },
    {
      title: 'Tiering System',
      key: 'hasTieringSystem',
      sx: { width: '15%', whiteSpace: 'pre', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) =>
        !row.isChildren && (
          <Box className='flex items-center justify-center'>
            <StatusBadge
              status={row?.hasTieringSystem ? billingTieringSystem.yes : billingTieringSystem.no}
              showDot={false}
            />
          </Box>
        ),
    },
    {
      title: 'Volume',
      key: 'volume',
      sx: { width: '12%', py: '11px' },
      cellSx: (row) => ({
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => <StringNodeTable variant='body3' value={row?.volume} />,
    },
    {
      title: 'Fees',
      key: 'price',
      sx: { width: '12%', py: '11px' },
      cellSx: (row: any) => ({
        py: '14.5px',
        ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
      }),
      renderNode: (row) => {
        return (
          <StringNodeTable
            variant='body3'
            value={`$${formatNumberWithCommas(row?.price || 0, 2)}`}
          />
        );
      },
    },
    ...(!isViewMode
      ? ([
          {
            title: 'Action',
            key: 'action',
            sx: { width: '10%', py: '11px' },
            cellSx: (row) => ({
              py: '14.5px',
              ...(row.isChildren && !row.volume ? { bgcolor: 'neutral.ne100' } : {}),
            }),
            renderNode: (row) =>
              row.isChildren || (mode === BILLING_MODE.Standard && !row.hasTieringSystem) ? null : (
                <Box className='flex items-center justify-center'>
                  <IconButton
                    sx={{
                      width: '24px',
                      height: '24px',
                      p: 0,
                      boxSizing: 'content-box',
                      borderRadius: '50%',
                      bgcolor: 'neutral.ne200',
                      mx: 'auto',
                    }}
                    disabled={
                      isViewMode ||
                      !selectedBilling[row.billingId] ||
                      isDisabledEditAll ||
                      isAllowedEditOnlyEndDate
                    }
                    onClick={() => {
                      setSelectedBillingPricing(row);

                      if (tieringFees.includes(row.billingType)) {
                        editTieringFeeModalRef.current?.open();
                      } else if (multipleFees.includes(row.billingType)) {
                        editMultipleFeeModalRef.current?.open();
                      } else if (singleFees.includes(row.billingType)) {
                        editSingleFeeModalRef.current?.open();
                      }
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Box>
              ),
          },
        ] as ColumnProps<ITableData, 'action'>[])
      : []),
  ];

  return (
    <FormProvider {...form}>
      <Box width={'862px'} component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Box
          className='flex justify-between'
          p={4}
          borderBottom='1px solid'
          borderColor='neutral.ne200'
        >
          <Typography variant='h5'>{`${capitalize(actionType)} Billing Plan`}</Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={4} mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={3.125}>
              <FormDatePicker
                name='startDate'
                label='Start Date'
                datePickerProps={{ format: DATE_PICKER_FORMAT }}
                disabled={isViewMode || isDisabledEditAll || isAllowedEditOnlyEndDate}
              />
            </Grid>
            <Grid item xs={3.125}>
              <FormDatePicker
                name='endDate'
                label='End Date'
                datePickerProps={{ format: DATE_PICKER_FORMAT }}
                optional={
                  <FormCheckbox
                    sx={{ color: 'neutral.ne500' }}
                    name='isOpenEnded'
                    size='small'
                    label={
                      <Typography variant='body3' color='neutral.ne800'>
                        Open-ended
                      </Typography>
                    }
                    onChange={handleChangeOpenEnded}
                    disabled={isViewMode || isDisabledEditAll}
                    labelSx={{ lineHeight: '20px' }}
                  />
                }
                disabled={isOpenEnded || isViewMode || isDisabledEditAll}
              />
            </Grid>
            <Grid item xs={3} display={'flex'} alignItems={'flex-end'}>
              <CustomSwitch
                disabled={
                  isViewMode || !isSuperAdmin || isDisabledEditAll || isAllowedEditOnlyEndDate
                }
                sx={(theme) => ({
                  width: '184px',
                  height: '32px',
                  '.MuiSwitch-switchBase': {
                    width: '50%',
                    height: 'calc(100% - 4px)',
                    top: '1px',
                    left: '1px',
                  },
                  '.MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(88px)',
                    '.MuiSwitch-thumb': {
                      '&::before': {
                        left: '-75px',
                        color: 'white',
                      },
                      '&::after': {
                        right: '20px',
                        color: theme.palette.primary.main,
                      },
                    },
                  },
                  '.MuiSwitch-thumb': {
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&::before': {
                      content: "'Standard'",
                      color: theme.palette.primary.main,
                      fontSize: '14px',
                      fontWeight: 500,
                      position: 'absolute',
                      top: '3px',
                    },
                    '&::after': {
                      content: "'Custom'",
                      fontSize: '14px',
                      fontWeight: 500,
                      position: 'absolute',
                      right: '-68px',
                      top: '3px',
                    },
                  },
                  '.MuiSwitch-track': {
                    borderRadius: '10px',
                    bgcolor: `${theme.palette.primary.main} !important`,
                  },
                })}
                checked={mode === BILLING_MODE.Custom}
                onChange={handleChangeMode}
              />
            </Grid>
            <Grid
              item
              xs={2.75}
              display={'flex'}
              alignItems={'flex-end'}
              justifyContent={'flex-end'}
            >
              {isEditMode && isCreatedClient && (
                <CustomButton
                  variant='outlined'
                  sx={{
                    p: '3px 24px',
                    minHeight: 'unset',
                    fontSize: '14px',
                    width: '100%',
                    height: '32.5px',
                    '.MuiCircularProgress-root': {
                      color: 'black !important',
                    },
                  }}
                  onClick={() => generateInvoiceModalRef.current?.open()}
                >
                  Generate Invoice
                </CustomButton>
              )}
            </Grid>
          </Grid>
          <Box mt={3} sx={{ '.render-node-container': { m: 0 } }}>
            {billingList.length || isLoading ? (
              <CustomTable
                columns={columns}
                rows={billingList}
                isFetchingData={isLoading}
                hasPagination={false}
              />
            ) : (
              <EmptyData />
            )}
          </Box>
        </Box>
        <Box
          px={4}
          py={2}
          borderColor={'neutral.ne200'}
          className='flex justify-end border-t border-solid gap-6'
        >
          <CustomButton
            variant='outlined'
            onClick={onClose}
            disabled={isCreatingBillingPlan || isUpdatingBillingPlan}
          >
            Cancel
          </CustomButton>
          {!isViewMode && (
            <CustomButton
              variant='contained'
              type='submit'
              isLoading={isCreatingBillingPlan || isUpdatingBillingPlan}
              disabled={isDisabledEditAll}
            >
              Save
            </CustomButton>
          )}
        </Box>
      </Box>
      <BasicModal
        ref={editSingleFeeModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => editSingleFeeModalRef.current?.close()}
      >
        <EditSingleFeeForm
          billingPricing={selectedBillingPricing}
          onUpdate={onUpdateSingleFee}
          onClose={() => editSingleFeeModalRef.current?.close()}
        />
      </BasicModal>
      <BasicModal
        ref={editMultipleFeeModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => editMultipleFeeModalRef.current?.close()}
      >
        <EditMultipleFeeForm
          billingPricing={selectedBillingPricing}
          onUpdate={onUpdateMultipleFee}
          onClose={() => editMultipleFeeModalRef.current?.close()}
        />
      </BasicModal>
      <BasicModal
        ref={editTieringFeeModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => editTieringFeeModalRef.current?.close()}
      >
        <EditTieringFeeForm
          disabled={mode === BILLING_MODE.Standard}
          billingPricing={selectedBillingPricing}
          onUpdate={onUpdateTieringFee}
          onClose={() => editTieringFeeModalRef.current?.close()}
        />
      </BasicModal>
      <BasicModal
        ref={standardPricingModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => standardPricingModalRef.current?.close()}
      >
        <StandardPricing
          isViewMode={isViewMode}
          canViewHistory
          onClose={() => standardPricingModalRef.current?.close()}
          isSuperAdmin={isSuperAdmin}
        />
      </BasicModal>
      <BasicModal
        ref={standardPriceHistoryModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => standardPriceHistoryModalRef.current?.close()}
      >
        <StandardPriceHistory
          onClose={() => standardPriceHistoryModalRef.current?.close()}
          isSuperAdmin={isSuperAdmin}
          isViewMode={isViewMode}
        />
      </BasicModal>
      <ConfirmModal
        ref={confirmModalRef}
        title='This new plan will impact an ongoing plan.'
        content={`The ongoing plan will end on ${dayjs(startDate)
          .subtract(1, 'day')
          .format(DATE_PICKER_FORMAT)}. Are you sure you want to save this plan?`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => confirmModalRef?.current?.close()}
              disabled={isCreatingBillingPlan || isUpdatingBillingPlan}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant='contained'
              onClick={() => confirmModalRef?.current?.onOk()}
              isLoading={isCreatingBillingPlan || isUpdatingBillingPlan}
            >
              Yes
            </CustomButton>
          </>
        }
      />
      <BasicModal
        ref={generateInvoiceModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        onClose={() => generateInvoiceModalRef.current?.close()}
      >
        <GenerateInvoiceForm
          clientId={clientId}
          billingId={id || ''}
          onClose={() => generateInvoiceModalRef.current?.close()}
        />
      </BasicModal>
    </FormProvider>
  );
};

export default CreateBillingPlanForm;
