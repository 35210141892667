/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

interface IInfoIconProps extends SVGProps<SVGSVGElement> {}

export const InfoIcon: FC<IInfoIconProps> = ({
  width = '13.3333333px',
  height = '13.3333333px',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox='0 0 13.3333333 13.3333333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Application_Financial-&-Tax-Details_13'
          transform='translate(-636.333333, -609.333333)'
          fill='#9DA0A1'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(291.000000, 80.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 208.000000)'>
              <g id='Tax-Details' transform='translate(220.000000, 262.000000)'>
                <g id='Container' transform='translate(0.000000, 32.000000)'>
                  <g id='Tax-File-Number' transform='translate(24.000000, 24.000000)'>
                    <g
                      id='01.Design-Token/06.Icons/Fluent/Filled/Error-Circle-2'
                      transform='translate(100.000000, 2.000000)'
                    >
                      <g id='Group' transform='translate(1.333333, 1.333333)'>
                        <path
                          d='M6.66666667,0 C10.3486667,0 13.3333333,2.98533333 13.3333333,6.66666667 C13.3333333,10.348 10.3486667,13.3333333 6.66666667,13.3333333 C2.98466667,13.3333333 0,10.348 0,6.66666667 C0,2.98533333 2.98466667,0 6.66666667,0 Z M6.668,8.66933333 C6.42515666,8.66164722 6.19739729,8.78681471 6.07370245,8.99593562 C5.95000762,9.20505652 5.95000762,9.46494348 6.07370245,9.67406438 C6.19739729,9.88318529 6.42515666,10.0083528 6.668,10.0006667 C7.02743916,9.98929024 7.31293133,9.69461915 7.31293133,9.335 C7.31293133,8.97538085 7.02743916,8.68070976 6.668,8.66933333 L6.668,8.66933333 Z M6.66666667,3.33333333 C6.32845717,3.33341762 6.04389896,3.58674034 6.00466667,3.92266667 L6,4 L6.00133333,7.334 L6.006,7.412 C6.04589092,7.74736726 6.33026862,7.99992377 6.668,7.99992377 C7.00573138,7.99992377 7.29010908,7.74736726 7.33,7.412 L7.33466667,7.334 L7.33333333,4 L7.32866667,3.922 C7.28912263,3.58633807 7.00464989,3.33333333 6.66666667,3.33333333 Z'
                          id='Shape'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
