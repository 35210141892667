/* eslint-disable no-restricted-imports */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { CLIENT_API, SUBSCRIPTION_BILLING_API } from 'src/constants/apiEndpoints';
import { removeEmptyProps } from 'src/utils/common';
import { BaseQuery } from '../common/hooks';
import {
  createBillingPlan,
  deleteBillingPlan,
  deleteStandardPricing,
  getBillingPlanDetail,
  getBillingPlans,
  getStandardPricingDetail,
  getStandardPricingDetailByDate,
  getStandardPricingHistory,
  getStandardPricingLatest,
  getSubscriptionFeatures,
  updateBillingPlan,
  updateStandardPricing,
  updateStandardPricingHistory,
} from './services';
import { BillingPlan, BillingPricingItem, PricingDetail } from './types';

const handleSortBilling = (items: BillingPricingItem[] = []) => {
  return items
    ?.sort((a, b) => a.billingType - b.billingType)
    .map((it) => {
      if (it.subBillings) {
        it.subBillings = it.subBillings.sort((a, b) =>
          a.description && b.description
            ? a.description.localeCompare(b.description)
            : a.typeName?.localeCompare(b?.typeName || '') || 1,
        );
      }
      if (it.volumes) {
        it.volumes = it.volumes.sort((a, b) => (a.tierType > b.tierType ? 1 : -1));
      }
      if (typeof it.billingType !== 'number') {
        it.billingType = it.type;
      }
      if (!it.description) {
        it.description = it.typeName;
      }
      return it;
    });
};

export const useGetSubscriptionsFeatures = () => {
  return useQuery(
    [SUBSCRIPTION_BILLING_API.getSubscriptionFeatures.api],
    () => getSubscriptionFeatures(),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetStandardPricingLatest = () => {
  return useQuery(
    [SUBSCRIPTION_BILLING_API.getStandardPricingLatest.api],
    () => getStandardPricingLatest(),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        data.standardItems = handleSortBilling(data.standardItems);
        return data;
      },
    },
  );
};

export const useGetStandardPricingDetail = (id: string) => {
  return useQuery(
    [SUBSCRIPTION_BILLING_API.getStandardPricingDetail.api(id)],
    () => getStandardPricingDetail(id),
    {
      select: (data) => {
        data.standardItems = handleSortBilling(data.standardItems);
        return data;
      },
    },
  );
};

export const useGetStandardPricingDetailByDate = (date: string) => {
  return useQuery(
    [SUBSCRIPTION_BILLING_API.getStandardPricingDetailByDate.api(date)],
    () => getStandardPricingDetailByDate(date),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        data.standardItems = handleSortBilling(data.standardItems);
        return data;
      },
    },
  );
};

export const useGetStandardPricingHistory = () => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({}));

  const query = useQuery([SUBSCRIPTION_BILLING_API.getStandardPricingHistory.api, queryBody], () =>
    getStandardPricingHistory(queryBody),
  );

  const setParams = (newParams: BaseQuery) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    ...query,
    params: queryBody,
    setParams,
  };
};

export const useUpdateStandardPricing = () => {
  const queryClient = useQueryClient();
  const resp = async (data: PricingDetail) => {
    await updateStandardPricing(data);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getStandardPricingLatest.api]);
  };
  return useMutation(resp);
};

export const useUpdateStandardPricingHistory = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: PricingDetail }) => {
    await updateStandardPricingHistory(id, data);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getStandardPricingHistory.api]);
  };
  return useMutation(resp);
};

export const useDeleteStandardPricing = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await deleteStandardPricing(id);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getStandardPricingHistory.api]);
  };
  return useMutation(resp);
};

export const useGetBillingPlans = (clientId: string) => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({}));

  const query = useQuery(
    [SUBSCRIPTION_BILLING_API.getBillingPlans.api(clientId), queryBody],
    () => getBillingPlans(clientId, queryBody),
    {
      enabled: !!clientId,
    },
  );

  const setParams = (newParams: BaseQuery) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    ...query,
    params: queryBody,
    setParams,
  };
};

export const useGetBillingPlanDetail = (clientId: string, billingPlanId: string) => {
  return useQuery(
    [SUBSCRIPTION_BILLING_API.billingPlanDetail.api(clientId, billingPlanId)],
    () => getBillingPlanDetail(clientId, billingPlanId),
    {
      enabled: !!clientId && !!billingPlanId,
      refetchOnWindowFocus: false,
      select: (data) => {
        data.items = handleSortBilling(data.items);
        data.disabledItems = handleSortBilling(data.disabledItems);
        return data;
      },
    },
  );
};

export const useCreateBillingPlan = () => {
  const queryClient = useQueryClient();
  const resp = async ({ clientId, data }: { clientId: string; data: BillingPlan }) => {
    await createBillingPlan(clientId, data);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getBillingPlans.api(clientId)]);
    await queryClient.invalidateQueries([CLIENT_API.getClientDetail.api(clientId)]);
  };

  return useMutation(resp);
};

export const useUpdateBillingPlan = () => {
  const queryClient = useQueryClient();
  const resp = async ({
    clientId,
    billingPlanId,
    data,
  }: {
    clientId: string;
    billingPlanId: string;
    data: BillingPlan;
  }) => {
    await updateBillingPlan(clientId, billingPlanId, data);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getBillingPlans.api(clientId)]);
    await queryClient.invalidateQueries([
      SUBSCRIPTION_BILLING_API.billingPlanDetail.api(clientId, billingPlanId),
    ]);
    await queryClient.invalidateQueries([CLIENT_API.getClientDetail.api(clientId)]);
  };

  return useMutation(resp);
};

export const useDeleteBillingPlan = () => {
  const queryClient = useQueryClient();
  const resp = async ({ clientId, billingPlanId }: { clientId: string; billingPlanId: string }) => {
    await deleteBillingPlan(clientId, billingPlanId);
    await queryClient.invalidateQueries([SUBSCRIPTION_BILLING_API.getBillingPlans.api(clientId)]);
    await queryClient.invalidateQueries([CLIENT_API.getClientDetail.api(clientId)]);
  };

  return useMutation(resp);
};
