/* eslint-disable autofix/no-unused-vars */
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckmarkCircleIcon } from 'src/assets/icons/CheckmarkCircleIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DismissCircleIcon from 'src/assets/icons/DismissCircleIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { OutlinedInfoIcon } from 'src/assets/icons/OutlinedInfoIcon';
import PersonAccountOutlinedIcon from 'src/assets/icons/PersonAccountOutlinedIcon';
import { TickIcon } from 'src/assets/icons/TickIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import SearchField from 'src/components/atoms/SearchField';
import ActionMenus, { MenuItemProps } from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { CLIENT_ACTION, CLIENT_STATUS } from 'src/constants/client-management';
import { ActionType, CLIENT_USER_ROLE, OTHER_LABEL } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { detectPortalType } from 'src/helpers/common';
import { useRole } from 'src/hooks/useRole';
import { IAction } from 'src/interfaces/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import {
  useActiveClient,
  useDeleteClient,
  useGetCapacity,
  useGetClientFilters,
  useGetClientList,
  useImpersonateClient,
  useToggleClientStatus,
} from 'src/modules/client/hooks';
import { IClient, IStatuses } from 'src/modules/client/type';
import { ConfirmFormat } from 'src/modules/common/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { formatValueWithSpaces, handleErrorFromServer, sortByAlphabet } from 'src/utils/common';

interface IClientListProps {
  isRefetchClientList?: boolean;
  onViewEditClient: (client: IClient, actionType: ActionType) => void;
}

interface ITableData extends IClient {}

const FILTER_BY = {
  capacity: 'Capacity',
  status: 'Status',
};

const ClientList: React.FC<IClientListProps> = ({
  isRefetchClientList = false,
  onViewEditClient,
}) => {
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [descriptionAlert, setDescriptionAlert] = useState<string>('');
  const navigate = useNavigate();
  const { filterActions } = useRole(ROUTES_PATH.CLIENT_MANAGEMENT);
  const [emptyFilters, setEmptyFilters] = useState<string[]>([]);
  const { selectedClients, isFAUser } = useFundPortalContext();
  const isOneOfFiltersEmpty =
    Boolean(emptyFilters?.length) || selectedClients === MULTIPLE_SELECT_EMPTY;

  const { isFunds } = detectPortalType();

  const {
    data: { items: clientData = [], metadata: { page, pageSize, totalItem } = {} } = {},
    refetch: refetchClient,
    setParams,
    isLoading: isClientLoading,
  } = useGetClientList();
  const { data: filtersData } = useGetClientFilters();
  const { data: capacityData } = useGetCapacity();
  const { mutate: deleteClientMutate, isLoading: isDeleteClientLoading } = useDeleteClient();
  const { mutate: toggleClientStatusMutate, isLoading: isToggleClientStatusLoading } =
    useToggleClientStatus();
  const { mutate: impersonateMutate, isLoading: isImpersonateLoading } = useImpersonateClient();
  const { mutate: activeClient, isLoading: loadingActiveClient } = useActiveClient();
  const { data: currentUser } = useGetUserInfo();

  useEffect(() => {
    if (isRefetchClientList) refetchClient();
  }, [isRefetchClientList, refetchClient]);

  const handleSearchUser = debounce((event: any) => {
    setParams({ search: event?.target?.value, page: 0 });
  }, 300);

  const handleUpdateClientStatus = (client: IClient) => {
    toggleClientStatusMutate(client.id, {
      onSuccess() {
        setDescriptionAlert(
          `Client "${client.clientName}" has been ${
            client.statusName === CLIENT_STATUS.Disabled.name ? 'enabled' : 'disabled'
          }.`,
        );
        handelFinishClientAction();
      },
      onError: handleErrorFromServer,
    });
  };

  const handleDeleteClient = (client: IClient) => {
    deleteClientMutate(client.id, {
      onSuccess() {
        setDescriptionAlert(`Client "${client.clientName}" has been deleted.`);
        handelFinishClientAction();
      },
      onError: handleErrorFromServer,
    });
  };

  const handleActiveClient = (client: IClient) => {
    activeClient(client.id, {
      onSuccess() {
        setDescriptionAlert(`Client "${client.clientName}" has been activated.`);
        handelFinishClientAction();
      },
      onError: handleErrorFromServer,
    });
  };

  const handelFinishClientAction = () => {
    modalConfirmRef?.current?.close();
    modalAlertRef?.current?.open();
    refetchClient();
  };

  const generateCapacityContent = (row: ITableData) => {
    let capacityContent = (row.capacities || [])
      .reduce((result: string[], currentItem) => {
        const isOtherOption = currentItem.capacityName === OTHER_LABEL;
        if (!isOtherOption) result.push(currentItem.capacityName);
        return result;
      }, [])
      ?.join(', ');

    if (row.otherCapacity) {
      capacityContent = capacityContent
        ? `${capacityContent}, ${row.otherCapacity}`
        : row.otherCapacity;
    }
    capacityContent = capacityContent || 'Other';

    return capacityContent;
  };

  const getMenuByStatus = (statusId: number) => {
    const isAllowedActivateClient = isFAUser && currentUser?.role === CLIENT_USER_ROLE.SUPER_ADMIN;
    switch (statusId) {
      case CLIENT_STATUS.Pending.id:
        return isFunds
          ? [
              CLIENT_ACTION.view,
              CLIENT_ACTION.edit,
              ...(isAllowedActivateClient
                ? [CLIENT_ACTION.activate, CLIENT_ACTION.delete]
                : [CLIENT_ACTION.delete]),
            ]
          : [
              CLIENT_ACTION.view,
              CLIENT_ACTION.edit,
              CLIENT_ACTION.impersonate,
              CLIENT_ACTION.delete,
            ];
      case CLIENT_STATUS.Active.id:
        return isFunds
          ? [CLIENT_ACTION.view, CLIENT_ACTION.edit, CLIENT_ACTION.disable]
          : [
              CLIENT_ACTION.view,
              CLIENT_ACTION.edit,
              CLIENT_ACTION.impersonate,
              CLIENT_ACTION.disable,
            ];
      case CLIENT_STATUS.Draft.id:
        return [CLIENT_ACTION.view, CLIENT_ACTION.edit, CLIENT_ACTION.delete];
      case CLIENT_STATUS.Disabled.id:
        return [CLIENT_ACTION.view, CLIENT_ACTION.enable];
      default:
        return [];
    }
  };

  const columns: ColumnProps<ITableData, any>[] = [
    {
      title: 'Client Name',
      key: 'clientName',
      sorter: true,
      sortBy: 'clientName',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => <StringNodeTable value={row.clientName} />,
    },
    {
      title: 'Legal Name',
      key: 'legalName',
      sorter: true,
      sortBy: 'legalName',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => <StringNodeTable value={row.legalName} />,
    },
    {
      title: 'ACN/ABN',
      key: 'abn',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={formatValueWithSpaces(row.abn) || ''} />
      ),
    },
    {
      title: 'Capacity',
      key: 'capacity',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => {
        const capacityContent = generateCapacityContent(row);
        return <StringNodeTable value={capacityContent} />;
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: isFunds,
      sortBy: 'status',
      sx: { width: '10%' },
      isSticky: true,
      renderNode: (row: ITableData) => <StatusBadge status={row.statusName || ''} />,
    },
    {
      title: 'Action',
      key: 'action',
      isSticky: true,
      sx: { minWidth: 90, width: '7%' },
      renderNode: (row: ITableData) => {
        const menuByStatus = getMenuByStatus(row.status);

        const actionIcons = {
          [CLIENT_ACTION.edit.key]: <EditOutlinedIcon />,
          [CLIENT_ACTION.impersonate.key]: <PersonAccountOutlinedIcon />,
          [CLIENT_ACTION.disable.key]: <DismissCircleIcon />,
          [CLIENT_ACTION.delete.key]: <DeleteIcon />,
          [CLIENT_ACTION.view.key]: <DocumentOnePageIcon />,
          [CLIENT_ACTION.enable.key]: <CheckmarkCircleIcon />,
          [CLIENT_ACTION.activate.key]: <TickIcon />,
        };

        const actions = {
          [CLIENT_ACTION.edit.key]: () => {
            onViewEditClient(row, 'edit');
          },
          [CLIENT_ACTION.disable.key]: () => {
            setConfirmFormat({
              title: 'Disable Client?',
              content: 'Are you sure you want to disable the client?',
              button: {
                type: 'primary',
                label: 'Disable',
                onAction: () => handleUpdateClientStatus(row),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [CLIENT_ACTION.enable.key]: () => {
            setConfirmFormat({
              title: 'Enable Client?',
              content: 'Are you sure you want to enable the client?',
              button: {
                type: 'primary',
                label: 'Enable',
                onAction: () => handleUpdateClientStatus(row),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [CLIENT_ACTION.delete.key]: () => {
            setConfirmFormat({
              title: 'Delete Client?',
              content: `Are you sure you want to delete the client?<br />
                        This action cannot be undone.`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () => handleDeleteClient(row),
              },
            });
            modalConfirmRef?.current?.open();
          },
          [CLIENT_ACTION.impersonate.key]: () => {
            impersonateMutate(row.id, {
              onSuccess(data) {
                const { token, url } = data || {};
                window.open(`${url}/impersonate?token=${token}`, '_blank');
              },
              onError: handleErrorFromServer,
            });
          },
          [CLIENT_ACTION.view.key]: () => {
            onViewEditClient(row, 'view');
          },
          [CLIENT_ACTION.activate.key]: () => {
            setConfirmFormat({
              title: 'Activate Client?',
              content: `Are you sure you want to activate the client?`,
              button: {
                type: 'primary',
                label: 'Activate',
                onAction: () => handleActiveClient(row),
              },
            });
            modalConfirmRef?.current?.open();
          },
        };

        const permissionActions: IAction[] = filterActions(menuByStatus, true);

        const actionMenu: MenuItemProps[] = permissionActions?.map((menuItem: IAction) => ({
          icon: actionIcons?.[menuItem.key],
          label: menuItem.label,
          onAction: actions?.[menuItem.key],
        }));

        return <ActionMenus menus={actionMenu} />;
      },
    },
  ];

  const handleSetEmptyFilters = (values: string | string[], filterBy: string) => {
    let newEmptyFilters = [...emptyFilters];

    if (values === MULTIPLE_SELECT_EMPTY) {
      newEmptyFilters = [...newEmptyFilters, filterBy];
    } else {
      newEmptyFilters = newEmptyFilters.filter((item) => item !== filterBy);
    }

    setEmptyFilters(newEmptyFilters);
  };

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({ statuses: (status as string[]).map((item: string) => Number(item)) });
    }

    handleSetEmptyFilters(status, FILTER_BY.status);
  };

  const handleCapacityChange = (capacityIds: string[] | string) => {
    if (capacityIds !== MULTIPLE_SELECT_EMPTY) {
      setParams({ capacities: capacityIds as string[] });
    }

    handleSetEmptyFilters(capacityIds, FILTER_BY.capacity);
  };

  const handleImpersonateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setParams({ impersonate: checked });
  };

  const handleSort = (sortBy: string, isAsc: boolean) => {
    setParams({
      sortBy,
      isAscending: isAsc,
    });
  };

  const handleChangePage = (value: number) => {
    setParams({ page: value });
  };

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const filterStatusesOptions = filtersData?.statuses.map((status: IStatuses) => ({
    label: status.name,
    value: String(status.id),
  }));

  const filterCapacityOptions = capacityData?.map((capacity) => ({
    label: capacity.capacityName,
    value: capacity.id,
  }));

  const rows = clientData?.map((client: IClient) => ({
    clientName: client.clientName,
    legalName: client.legalName,
    abn: client.abn,
    capacities: client.capacities,
    statusName: client.statusName,
    id: client.id,
    status: client.status,
    otherCapacity: client.otherCapacity,
  }));

  return (
    <>
      <Box className='flex items-center py-6 gap-6'>
        <Box className='section-header w-full'>
          <Stack direction='row' gap={2}>
            <Box width='33%'>
              <SearchField
                placeholder='Search by client name or legal name'
                onChange={handleSearchUser}
              />
            </Box>
            <CustomMultiSelect
              onChange={handleCapacityChange}
              options={sortByAlphabet(filterCapacityOptions || [], 'label')}
              label='Capacity'
              showClearAllIcon
            />
            <CustomMultiSelect
              onChange={handleStatusChange}
              options={sortByAlphabet(filterStatusesOptions || [], 'label')}
              label='Status'
              customRenderLabel={customRenderStatusLabel}
              showClearAllIcon
            />
            {!isFunds && (
              <Box
                className='border rounded-lg flex items-center py-[11px] px-4 justify-between h-[46px]'
                borderColor='neutral.ne400'
                bgcolor='neutral.ne100'
              >
                <FormControlLabel
                  control={<Checkbox onChange={handleImpersonateChange} sx={{ p: 0 }} />}
                  label={
                    <Typography variant='body3' component='span' sx={{ mx: '8px' }}>
                      Remote Access
                    </Typography>
                  }
                  sx={{ m: 0 }}
                />
                <CustomTooltip
                  title='Displays clients that allow remote access.'
                  placement='bottom-end'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        width: 160,
                      },
                    },
                  }}
                >
                  <span className='cursor-pointer'>
                    <OutlinedInfoIcon />
                  </span>
                </CustomTooltip>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
      <Box className='flex-1 section-body'>
        {clientData?.length && !isOneOfFiltersEmpty ? (
          <CustomTable<ITableData>
            rows={rows}
            columns={columns}
            isFetchingData={isClientLoading}
            onSort={handleSort}
            totalResults={totalItem}
            currentPage={page}
            numberItemOnPage={pageSize}
            onChangePagination={handleChangePage}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
      <ConfirmModal
        ref={modalConfirmRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => modalConfirmRef?.current?.close()}
              disabled={
                isDeleteClientLoading ||
                isToggleClientStatusLoading ||
                isImpersonateLoading ||
                loadingActiveClient
              }
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={
                isDeleteClientLoading ||
                isToggleClientStatusLoading ||
                isImpersonateLoading ||
                loadingActiveClient
              }
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={descriptionAlert}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
    </>
  );
};

export default ClientList;
