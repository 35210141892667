import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IAuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: FC<IAuthLayoutProps> = ({ children }) => {
  return <Box className='min-h-screen'>{children}</Box>;
};

export default AuthLayout;
