/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ROLE_API } from 'src/constants/apiEndpoints';
import { BaseQuery, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { removeEmptyProps } from 'src/utils/common';
import { deleteRole, getRoleList, getUserRole } from './services';

export const useGetRoleList = () => {
  const [queryBody, setQueryBody] = useState<BaseQuery>(() => ({
    ...DEFAULT_QUERIES,
    sortBy: 'name',
    isAscending: true
  }));

  const { data, refetch, ...rest } = useQuery([ROLE_API.getRoleList.api], () =>
    getRoleList(queryBody),
  );

  useEffect(() => {
    refetch();
  }, [queryBody]);

  const setParams = (params: BaseQuery) => {
    const mergedParams = { ...queryBody, ...params };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  const result = {
    items: data?.items,
    metadata: data?.metadata,
  };

  return {
    data: result,
    refetch,
    setParams,
    ...rest,
  };
};

export const useGetUserRole = (enabled: boolean) => {
  return useQuery([ROLE_API.getUserRole.api], getUserRole, {
    enabled,
  });
};

export const useDeleteRole = () => {
  const handleDeleteRole = async (id: string) => {
    return await deleteRole(id);
  };
  return useMutation(handleDeleteRole);
};
