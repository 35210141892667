import { FC } from 'react';

const OtherIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-1153.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1-Copy-6' transform='translate(659.000000, 43.000000)'>
                  <g id='Group-4' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Rectangle' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g id='other' mask='url(#mask-2)'>
                      <g transform='translate(9.000000, 9.000000)'>
                        <path
                          d='M34.551028,3.96687003 C34.2810115,2.21484833 32.7853253,0.719170035 31.0332943,0.449154986 C29.9778375,0.289196703 28.5347874,0.13470708 26.565376,0.0499428622 C24.5959647,-0.033454191 23.1590669,-0.00132581805 22.1111295,0.0718174991 C20.3714031,0.193495167 18.996028,1.6256003 18.8442719,3.439828 C18.7554057,4.53014193 18.6925158,6.02650382 18.6986681,8.07383311 C18.7048204,10.121846 18.7759133,11.6182079 18.8743497,12.7092054 C19.0363596,14.5248003 20.4759918,15.9637412 22.2909126,16.1257502 C23.3812323,16.2235025 24.8782857,16.2959623 26.9263095,16.3014309 C28.9736496,16.3068996 30.470703,16.2446936 31.5603391,16.1558279 C33.3738928,16.0040726 34.8060056,14.6280212 34.9283675,12.8889876 C35.0015112,11.8410557 35.0343233,10.4041655 34.9502422,8.43476462 C34.8654775,6.46536372 34.7109871,5.02300485 34.551028,3.96687003 L34.551028,3.96687003 Z'
                          id='Path'
                          fill='#FAAB48'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M16.1256503,22.2909799 C15.9636404,20.4753851 14.5246918,19.0364441 12.7090874,18.8744351 C11.6180841,18.7766828 10.1217143,18.7042231 8.07369053,18.6987544 C6.02566681,18.6932857 4.52929701,18.7554917 3.43966088,18.8443575 C1.62610717,18.9961128 0.193310861,20.3721641 0.071632549,22.1111978 C-0.00151115545,23.1591296 -0.0343232846,24.5960198 0.0497577963,26.5654207 C0.134522463,28.5348216 0.28969649,29.9771805 0.448972034,31.0333153 C0.719672099,32.785337 2.21467473,34.2810153 3.96670571,34.5510303 C5.02284611,34.7109886 6.46521262,34.8654782 8.43462396,34.9502425 C10.4040353,35.0336395 11.8409331,35.0015111 12.8888705,34.9283678 C14.6279133,34.8066902 16.003972,33.374585 16.1557281,31.5603573 C16.2445943,30.4700434 16.3074842,28.9736815 16.3013319,26.9263522 C16.2958632,24.8783393 16.2234031,23.3819775 16.1256503,22.2909799 Z'
                          id='Path'
                          fill='#FAAB48'
                          fillRule='nonzero'
                        ></path>
                        <path
                          d='M34.9283675,22.1111978 C34.8066891,20.3721641 33.3745764,18.9961128 31.5603391,18.8443575 C30.4700194,18.7554917 28.9736496,18.6926022 26.9263095,18.6987544 C24.8782857,18.7042231 23.3819159,18.7759992 22.2909126,18.8744351 C20.4753082,19.0371277 19.0363596,20.4760687 18.8743497,22.2909799 C18.7765969,23.3819775 18.7041368,24.8783393 18.6986681,26.9263522 C18.6931994,28.9736815 18.7560893,30.470727 18.8442719,31.5603573 C18.996028,33.374585 20.3720867,34.8066902 22.1111295,34.9283678 C23.1590669,35.0015111 24.5959647,35.0343231 26.565376,34.9502425 C28.5347874,34.8654782 29.9778375,34.710305 31.0332943,34.5510303 C32.7853253,34.2810153 34.2810115,32.785337 34.551028,31.0333153 C34.7103035,29.9778641 34.8654775,28.5348216 34.9502422,26.5654207 C35.0336397,24.5960198 35.0015112,23.1591296 34.9283675,22.1111978 L34.9283675,22.1111978 Z'
                          id='Path'
                          fill='#FAAB48'
                          fillRule='nonzero'
                        ></path>
                        <g
                          id='Group'
                          strokeWidth='1'
                          fill='none'
                          fillRule='evenodd'
                          transform='translate(0.001907, 0.000000)'
                        >
                          <path
                            d='M3.43775411,16.1551443 C4.52807382,16.24401 6.02444362,16.3068996 8.07178376,16.3007473 C10.1198075,16.2952787 11.6161773,16.2228189 12.7071806,16.1250667 C14.5221015,15.962374 15.9610501,14.5234331 16.1237435,12.7085218 C16.2214963,11.6175243 16.2939564,10.1211624 16.2994251,8.07314953 C16.3048938,6.02513665 16.2420039,4.52877477 16.1538213,3.43914442 C16.0020652,1.62491672 14.6260065,0.192811585 12.8869637,0.0711339167 C11.8397099,-0.00132581805 10.4021285,-0.0341377734 8.43271718,0.0492592798 C6.46398943,0.134023498 5.02025575,0.289196703 3.96479893,0.448471403 C2.21276796,0.719170035 0.717765324,2.21484833 0.447748845,3.96687003 C0.288473302,5.02232126 0.132615688,6.46536372 0.0485346076,8.43408104 C0.0198239947,9.10467538 0.00546868818,9.71169655 0.000683586023,10.2647147 L0.000683586023,11.0084524 C0.00751944625,11.743987 0.0341793012,12.3646799 0.0704093604,12.8876204 C0.192087672,14.626654 1.62420039,16.0027054 3.4384377,16.1544607 L3.43775411,16.1551443 Z'
                            id='Path'
                            fill='#F8C358'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M22.1099063,0.0718174991 C20.3701799,0.193495167 18.9948048,1.6256003 18.8430487,3.439828 C18.7541825,4.53014193 18.6912926,6.02650382 18.6974449,8.07383311 C18.7035972,10.121846 18.7746901,11.6182079 18.8731265,12.7092054 C19.0351364,14.5248003 20.4747686,15.9637412 22.2896895,16.1257502 C23.1168285,16.2002607 24.177754,16.2590488 25.5346723,16.2863921 C26.633195,13.8897522 27.2456881,11.225148 27.2456881,8.4163079 C27.2456881,5.39624084 26.5368094,2.5422843 25.277644,0.0096115004 C23.9494363,-0.0163646309 22.9138035,0.015763742 22.1092227,0.0718174991 L22.1099063,0.0718174991 Z'
                            id='Path'
                            fill='#F8C358'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M12.7071806,18.8744351 C11.6161773,18.7766828 10.1198075,18.7042231 8.07178376,18.6987544 C6.02376004,18.6932857 4.52739023,18.7554917 3.43775411,18.8443575 C1.62420039,18.9961128 0.191404086,20.3721641 0.0697257744,22.1111978 C0.0334957151,22.6348219 0.00683586023,23.2561983 0,23.9924165 L0,24.7327363 C0.00205075807,24.9466976 0.00478510216,25.1688619 0.0102537903,25.3992291 C2.52243243,26.6330954 5.34700987,27.3262479 8.33428079,27.3262479 C11.3215517,27.3262479 13.8672261,26.6987193 16.2843862,25.5776441 C16.2577264,24.2002256 16.1982544,23.1256341 16.1230599,22.2902964 C15.9610501,20.4747015 14.5221015,19.0364441 12.706497,18.8737515 L12.7071806,18.8744351 Z'
                            id='Path'
                            fill='#F8C358'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M24.1702346,18.7548082 C23.438114,18.7855694 22.8160507,18.8265843 22.2890059,18.8737515 C20.4734014,19.0364441 19.0344528,20.4753851 18.8724429,22.2902964 C18.8245919,22.8234906 18.7828932,23.4551208 18.7521318,24.199542 C20.9081621,22.7735891 22.7545279,20.9183465 24.1702346,18.7548082 Z'
                            id='Path'
                            fill='#F8C358'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OtherIcon;
