import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { useDownloadInvoice, useGetInvoices } from 'src/modules/client/hooks';
import { Invoice } from 'src/modules/client/type';
import { handleErrorFromServer } from 'src/utils/common';
import { ISubscriptionAndBillingProps } from '.';

interface ITableData extends Invoice {}

interface IBillingHistoryProps extends ISubscriptionAndBillingProps {}

const BillingHistory = (props: IBillingHistoryProps) => {
  const { clientId } = props;

  const { data: billingList = [], isLoading, setParams } = useGetInvoices(clientId);
  const { mutate: downloadInvoice } = useDownloadInvoice();

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Invoice Date',
      key: 'invoiceDate',
      sorter: true,
      sortBy: 'invoiceDate',
      sx: { whiteSpace: 'pre', width: '15%', py: '11px' },
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={row?.invoiceDate ? dayjs(row.invoiceDate).format(DATE_PICKER_FORMAT) : ''}
        />
      ),
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      sorter: true,
      sortBy: 'invoiceNumber',
      sx: { width: '40%', whiteSpace: 'pre', py: '11px' },
      renderNode: (row) => <StringNodeTable variant='body3' value={row?.invoiceNumber} />,
    },
    {
      title: 'Amount',
      key: 'amount',
      sorter: true,
      sortBy: 'amount',
      sx: { width: '15%', whiteSpace: 'pre', py: '11px' },
      renderNode: (row) => <StringNodeTable variant='body3' value={`$${row?.amount}`} />,
    },
    {
      title: 'Status',
      key: 'status',
      sx: { width: '15%', py: '11px' },
      renderNode: (row) => <StatusBadge status={row?.statusName} showDot={false} />,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '10%', py: '11px' },
      renderNode: (row) => (
        <Box className='flex items-center justify-center'>
          <IconButton
            sx={{
              width: '24px',
              height: '24px',
              p: 0,
              boxSizing: 'content-box',
              borderRadius: '50%',
              bgcolor: 'neutral.ne200',
              mx: 'auto',
            }}
            onClick={() => {
              downloadInvoice(
                { clientId, invoiceId: row.id, invoiceName: `invoice-${row.invoiceNumber}.pdf` },
                {
                  onError: handleErrorFromServer,
                },
              );
            }}
          >
            <DownloadIcon width={'16'} height={'16'} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleSort = (sortBy: string, isAscending: boolean) => {
    setParams({ sortBy, isAscending });
  };

  return (
    <Box mx={-3} mb={-3} mt={2} display={'flex'} flexDirection={'column'} maxWidth={'720px'}>
      <Box sx={{ '.render-node-container': { m: 0 } }}>
        {billingList.length || isLoading ? (
          <CustomTable
            columns={columns}
            rows={billingList}
            isFetchingData={isLoading}
            hasPagination={false}
            onSort={handleSort}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
    </Box>
  );
};

export default BillingHistory;
