/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, FormControl, SelectProps, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { CustomMenuItem, CustomSelect } from './CustomSelect';
import InputMessage from './InputMessage';

export type IValue = string | number;
export interface IOptions {
  label: string;
  value: IValue;
  id: IValue;
  [x: string]: any;
}

export interface IFormMultiSelectProps extends SelectProps {
  name: string;
  options: IOptions[];
  rules?: Record<string, unknown>;
  renderItem?: (label: string | JSX.Element) => void;
  allOption?: boolean;
  optional?: boolean;
  splitItemSymbol?: string;
  processChangedByUser?: (value: any, isAll?: boolean) => void;
  disabledValues?: IValue[];
  renderSelectionLabel?: (options: IOptions) => ReactNode;
}

export const MULTIPLE_SELECT_ALL = 'all';

const FormMultiSelect: FC<IFormMultiSelectProps> = ({
  name,
  options,
  label,
  rules,
  defaultValue = '',
  placeholder = '',
  renderItem,
  allOption = false,
  optional = false,
  splitItemSymbol = ', ',
  processChangedByUser,
  disabledValues = [],
  renderSelectionLabel,
  ...rest
}) => {
  const [selecteds, setSelecteds] = useState<IValue[]>([]);

  const { control } = useFormContext();

  const {
    field: { ref, onChange, value: values, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  useEffect(() => {
    setSelecteds((values || [])?.map((it: any) => (it?.value ? it.value : it)));
  }, [values]);

  const handleSelectAllValues = () => {
    const unSelectedDisabledValues = disabledValues?.filter((it) => !selecteds?.includes(it));
    const allOptions: IOptions[] = options?.filter(
      (it) => !unSelectedDisabledValues?.includes(it?.value),
    );
    const allValues = allOptions.map((opt) => opt.value);

    setSelecteds(allValues);
    onChange(allValues);
    processChangedByUser?.(allValues, true);
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedExcludeDisabledValue = selecteds?.filter((it) => !disabledValues?.includes(it));

    if (allOption) {
      if (
        !value ||
        (value as IValue[]).length === 0 ||
        (!value && selecteds.length === options.length)
      ) {
        setSelecteds([]);
        onChange([]);
        processChangedByUser?.([]);
        return;
      }

      if ((value as IValue[]).includes(MULTIPLE_SELECT_ALL)) {
        if (
          selectedExcludeDisabledValue.length > 0 &&
          !(selectedExcludeDisabledValue as IValue[]).includes(MULTIPLE_SELECT_ALL)
        ) {
          const selectedValues = disabledValues?.filter((it) => selecteds.includes(it));

          setSelecteds(selectedValues);
          onChange(selectedValues);
          processChangedByUser?.(selectedValues);
        } else {
          handleSelectAllValues();
        }
        return;
      }
    }
    setSelecteds(value as IValue[]);
    onChange(value);
    processChangedByUser?.(value);
  };

  const renderValue = (selecteds: any) => {
    if (allOption && !isEmpty(selecteds) && selecteds.length === options.length) {
      return 'All';
    }

    const optionsSelected = selecteds
      ?.map((selected: string) => {
        const option = options.find((opt: IOptions) => opt.id === selected);

        return option;
      })
      .filter((x: string) => x);

    if (isEmpty(selecteds))
      return (
        <Typography className='placeholder' color='neutral.ne500' variant='body2'>
          {placeholder}
        </Typography>
      );

    if (renderItem) {
      return renderItem(optionsSelected);
    }

    return optionsSelected
      .map(({ label }: IOptions) => {
        return typeof label === 'string' ? (
          label
        ) : (
          <div dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToString(label) }} />
        );
      })
      .join(splitItemSymbol);
  };

  return (
    <Box className='flex flex-col w-full'>
      <Box className='w-full flex justify-between mb-1'>
        <Typography variant='body3' className='flex'>
          {label}
        </Typography>
        {optional && (
          <Typography variant='body3' color='neutral.ne800'>
            Optional
          </Typography>
        )}
      </Box>
      <FormControl fullWidth error={invalid}>
        <CustomSelect
          {...inputProps}
          {...rest}
          inputRef={ref}
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          value={selecteds}
          onChange={handleChange}
          displayEmpty
          renderValue={renderValue}
        >
          {allOption && !isEmpty(options) && (
            <CustomMenuItem
              key={MULTIPLE_SELECT_ALL}
              value={MULTIPLE_SELECT_ALL}
              disabled={disabledValues?.length === options?.length}
            >
              <Checkbox checked={selecteds?.length === options?.length} sx={{ padding: 0 }} />
              <Typography component='span' variant='body3' marginLeft={1}>
                All
              </Typography>
            </CustomMenuItem>
          )}
          {options.map((option) => (
            <CustomMenuItem
              key={option?.label}
              value={option?.value}
              disabled={disabledValues?.includes(option?.value)}
            >
              <Checkbox checked={selecteds.indexOf(option?.value) > -1} sx={{ padding: 0 }} />
              {renderSelectionLabel ? (
                renderSelectionLabel(option)
              ) : (
                <Typography component='span' variant='body3' marginLeft={1}>
                  {option?.label}
                </Typography>
              )}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </FormControl>
      <Box className='ml-2'>
        {invalid && <InputMessage message={(error as FieldError)?.message} variant='error' />}
      </Box>
    </Box>
  );
};

export default FormMultiSelect;
