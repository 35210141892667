/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import { detectPortalType } from 'src/helpers/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useGetAssignees, useGetClientDetail, useGetFundOurTeam } from 'src/modules/client/hooks';
import { IAssigneesResponse, IFundOurTeam } from 'src/modules/client/type';
import { FUND_UPSERT_CLIENT_STEP } from './funds/FundsCreateUpdateClientForm';

interface IOnboardingContactFormProps {
  isViewMode: boolean;
  clientDetails: any;
  clientId: string;
}

const OnboardingContactForm: FC<IOnboardingContactFormProps> = ({ isViewMode, clientId }) => {
  const { isFunds } = detectPortalType();
  const { data: assignees = [] } = useGetAssignees(!isFunds);
  const { data: teams = [] } = useGetFundOurTeam(isFunds);
  const { data: userInfo } = useGetUserInfo();
  const { data: clientDetails } = useGetClientDetail(clientId, FUND_UPSERT_CLIENT_STEP.OUR_TEAM);
  const { watch, reset } = useFormContext();
  const watchOptions = watch('assignees');

  useEffect(() => {
    handleFillFundClientData();
  }, [clientDetails, userInfo]);

  const handleFillFundClientData = () => {
    if (!isFunds) return;

    const onboardingContactData = {
      assignees: (clientDetails?.ourTeam || []).map((item: any) => ({
        id: item?.id || '',
        label: `${item?.firstName} ${item?.lastName}`,
        value: item?.id,
      })),
      sectionId: clientDetails?.id,
    };

    if (isEmpty(onboardingContactData.assignees)) {
      const team = teams.find((item) => item.email === userInfo?.email);
      onboardingContactData.assignees = [
        {
          id: team?.id,
          label: `${team?.firstName} ${team?.lastName}`,
          value: team?.id,
        },
      ];
    }
    reset(onboardingContactData, { keepDirty: false });
  };

  const options: OptionType[] = (isFunds ? teams : assignees).map(
    (item: IAssigneesResponse | IFundOurTeam) => ({
      id: item?.id,
      label: `${item?.firstName} ${item?.lastName} | ${item?.email}`,
      value: item?.id,
    }),
  );

  const renderPlaceHolder = () => {
    if (isFunds) {
      return isEmpty(watchOptions) ? 'Select team' : '';
    }
    return isEmpty(watchOptions) ? 'Onboarding contact' : '';
  };

  return (
    <Box className='pt-12' component='form'>
      <Stack>
        <FormAutocomplete
          multiple
          name='assignees'
          dataKey='id'
          options={options}
          placeholder={renderPlaceHolder()}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              <Typography variant='body3'>{option.label}</Typography>
            </li>
          )}
          renderTags={(selected) => (
            <Typography variant='body2'>
              <span className='line-clamp-1'>
                {selected.map((item) => item.label.split('|')[0].trim()).join(',')}
              </span>
            </Typography>
          )}
          readOnly={isViewMode}
          sx={{
            '& .MuiInputBase-root': {
              flexWrap: 'nowrap',
            },
          }}
        />
        <Typography variant='body3' color='neutral.ne800' sx={{ pt: 1, pb: '18px', pl: 2 }}>
          {isFunds
            ? 'Please select our team members who will be assigned to this client.'
            : 'List of “Active” users in Users Management.'}
        </Typography>
        <FormControlLabel
          control={
            <FormCheckbox name='isSendEmailForAssignee' defaultChecked disabled={isViewMode} />
          }
          label={
            <Typography color='primary' variant='body3' sx={{ pl: '12px' }}>
              Send notification to user email address.
            </Typography>
          }
          sx={{ ml: '13px' }}
        />
      </Stack>
    </Box>
  );
};

export default OnboardingContactForm;
