/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import ContactIcon from 'src/assets/icons/ContactIcon';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import {
  InvestorRelationsContact as InvestorRelationsContactEnum,
  InvestorRelationsContactOptions,
} from 'src/constants/funds-management';
import { ContactDetailsProps } from './ContactDetails';

interface IInvestorRelationsContactProps extends ContactDetailsProps {}

const InvestorRelationsContact: React.FC<IInvestorRelationsContactProps> = ({ isViewMode }) => {
  const { watch } = useFormContext();
  const watchOption = watch('investorContact.option');

  const isOrganisationOption = watchOption === InvestorRelationsContactEnum.Organisation;
  return (
    <Box className='flex flex-col gap-2 mt-4  py-4 px-6 rounded-[10px]' bgcolor='neutral.ne100'>
      <Box className={`flex items-center w-full h-[48px]`}>
        <ContactIcon />
        <Typography variant='body2' className='ml-2 font-medium' color='base.black'>
          Investor Relations Contact
        </Typography>
        <Typography variant='body2' className='ml-2' color='neutral.ne800'>
          (Optional)
        </Typography>
      </Box>
      <FormRadioGroup
        row
        name='investorContact.option'
        options={InvestorRelationsContactOptions}
        isEnumValue
      />
      <Box className='flex flex-col gap-6'>
        <Box className='w-full flex gap-6'>
          <FormInput
            name={'investorContact.firstName'}
            label={isOrganisationOption ? 'Organisation Name' : 'First Name'}
            size='small'
            placeholder={`Enter ${isOrganisationOption ? 'organisation' : 'first'} name`}
            className='w-full'
            disabled={isViewMode}
          />
          <FormInput
            name={'investorContact.lastName'}
            label={isOrganisationOption ? 'Contact Name' : 'Last Name'}
            size='small'
            placeholder={`Enter ${isOrganisationOption ? 'contact' : 'last'} name`}
            className='w-full'
            disabled={isViewMode}
          />
        </Box>
        <Box className='w-full flex gap-6'>
          <FormInput
            name={`investorContact.email`}
            label='Email Address'
            size='small'
            placeholder='Enter email address'
            className='w-full'
            disabled={isViewMode}
          />
          <FormPhoneCountryInput
            name={`investorContact.contactNumber`}
            countryFlagCodeName={`investorContact.countryFlagCode`}
            label='Contact Number'
            size='small'
            className='w-full'
            countryFlagCode={DEFAULT_COUNTRY}
            disabled={isViewMode}
            isIncludeCode
          />
        </Box>
        <Box className='w-full flex gap-6'>
          <FormInput
            disabled={isViewMode}
            name={`investorContact.position`}
            label='Position'
            size='small'
            placeholder='Enter position'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InvestorRelationsContact;
