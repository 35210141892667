import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { notificationFeatureName } from 'src/constants/notifications';

interface IFeatureBadgeProps {
  label: string;
}

const FeatureBadge: FC<IFeatureBadgeProps> = ({ label }) => {
  const theme = useTheme();
  const featureName = {
    ...notificationFeatureName,
  };

  const color = {
    [featureName.ClientManagement]: 'info.main',
    [featureName.KYCBackOffice]: theme.palette.sematic.kycInProgress500,
    [featureName.Communications]: theme.palette.sematic.withdrawn500,
    [featureName.UserManagement]: theme.palette.sematic.awaitingDeposit500,
  };

  const bgColor = {
    [featureName.ClientManagement]: 'info.light',
    [featureName.KYCBackOffice]: theme.palette.sematic.kycInProgress100,
    [featureName.Communications]: theme.palette.sematic.withdrawn100,
    [featureName.UserManagement]: theme.palette.sematic.awaitingDeposit100,
  };

  return (
    <Box className='flex w-fit items-center h-6 rounded-lg px-2' bgcolor={bgColor[label]}>
      <Typography
        variant='caption1'
        className='font-medium'
        color={color[label]}
        whiteSpace={'pre'}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default FeatureBadge;
