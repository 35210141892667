const SMSFIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-932.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1-Copy-4' transform='translate(438.000000, 43.000000)'>
                  <g id='consultant' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g mask='url(#mask-2)'>
                      <g transform='translate(3.000000, 9.000000)'>
                        <g
                          id='Group'
                          strokeWidth='1'
                          fill='none'
                          transform='translate(0.000000, 3.308203)'
                        >
                          <path
                            d='M13.4406828,13.3493728 C13.3378626,11.9213343 13.4587562,10.5757654 13.4587562,10.5757654 C13.1955894,10.3342077 12.7340556,9.9023185 12.2213873,9.5298251 C11.3915523,8.92692456 10.4273787,8.4795792 9.95228989,9.24686702 C9.52502954,9.93687405 10.1617874,11.5115253 10.2803668,12.2543041 C10.6296028,14.4422336 10.932443,16.3961127 13.4406828,13.3494832 L13.4406828,13.3493728 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M12.3164932,10.5270785 C12.2171996,10.1489546 11.8304942,9.92296351 11.453046,10.0225453 C11.0755978,10.1220167 10.8500105,10.5094143 10.9493042,10.8875381 C11.053557,11.2803453 11.1262916,11.6111071 11.1853608,11.9439665 C11.2443199,12.2766051 11.2882912,12.6064837 11.3354585,13.0002845 C11.3813033,13.3892276 11.7331842,13.6672177 12.1214324,13.6212908 C12.5096806,13.5753639 12.7871739,13.2228531 12.7413291,12.83391 C12.6907455,12.4117361 12.6435783,12.0577901 12.5802111,11.7000904 C12.5168438,11.342722 12.435844,10.9765214 12.3166034,10.5271889 L12.3164932,10.5270785 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M29.2787402,13.3493728 C29.3815604,11.9213343 29.2606668,10.5757654 29.2606668,10.5757654 C29.5238336,10.3342077 29.9853674,9.9023185 30.4980357,9.5298251 C31.3278708,8.92692456 32.2920443,8.4795792 32.7671331,9.24686702 C33.1943935,9.93687405 32.5576356,11.5115253 32.4390562,12.2543041 C32.0898202,14.4422336 31.78698,16.3961127 29.2787402,13.3494832 L29.2787402,13.3493728 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M30.4029298,10.5270785 C30.5022235,10.1489546 30.8888186,9.92296351 31.266377,10.0225453 C31.6438252,10.1220167 31.8694125,10.5094143 31.7701188,10.8875381 C31.665866,11.2803453 31.5931314,11.6111071 31.5340622,11.9439665 C31.4751031,12.2766051 31.4311318,12.6064837 31.3839645,13.0002845 C31.3381197,13.3892276 30.9862388,13.6672177 30.5979906,13.6212908 C30.2097424,13.5753639 29.9322491,13.2228531 29.9780939,12.83391 C30.0286775,12.4117361 30.0758447,12.0577901 30.139212,11.7000904 C30.2025792,11.342722 30.283579,10.9765214 30.4028196,10.5271889 L30.4029298,10.5270785 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M21.3597115,30.5566026 L15.7451556,25.5417419 C16.3164524,24.1956209 16.6079416,21.4203575 16.6196232,17.2160618 L26.0997998,17.2160618 C26.1114814,21.4204679 26.4029706,24.1957313 26.9742674,25.5417419 L21.3597115,30.5566026 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M21.3597115,30.5566026 L17.6407722,27.2348535 C18.0640652,25.7326254 18.2806158,23.1749625 18.2906443,19.5620857 L26.1402446,19.5620857 C26.2389873,22.4709346 26.5169214,24.4642269 26.9742674,25.5417419 L21.3597115,30.5566026 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M12.2214975,9.5297147 C12.4891827,12.5300861 13.3280544,17.4315648 15.3331034,19.6506274 C16.1101508,20.5106522 18.3885053,22.9709413 19.5588703,23.431866 C20.300432,23.7238769 20.5731866,23.460239 21.3597115,23.4517382 C22.1462364,23.460239 22.418991,23.7238769 23.1606629,23.431866 C24.3310279,22.9709413 26.6092722,20.5106522 27.3864298,19.6506274 C29.3914788,17.4315648 30.2303506,12.5300861 30.4980357,9.5297147 C30.4980357,2.9537821 25.9261185,0.037094778 21.3598217,0 C16.7936351,0.0369843768 12.2214975,2.9537821 12.2214975,9.5297147 L12.2214975,9.5297147 Z'
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M16.9862715,21.3866851 C17.8838819,22.2738686 18.8979778,23.1716505 19.5588703,23.431866 C20.300432,23.7238769 20.5731866,23.460239 21.3597115,23.4517382 C22.1462364,23.460239 22.418991,23.7238769 23.1606629,23.431866 C24.3310279,22.9709413 26.6092722,20.5106522 27.3864298,19.6506274 C29.3914788,17.4315648 30.2303506,12.5300861 30.4980357,9.5297147 C30.4980357,5.16875987 28.4870357,2.41767423 25.8007065,1.04792748 C24.9383614,0.797979331 24.0417428,0.671680444 23.1451242,0.663952365 C18.5789376,0.703255166 14.0069102,3.80331875 14.0069102,10.7923724 C14.2686443,13.9101001 15.0765488,18.958854 16.9863817,21.3866851 L16.9862715,21.3866851 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M42.7193128,40.6352316 L42.7193128,36.8280489 C42.7193128,30.9311936 37.6637109,29.8976182 30.3239136,26.8566193 L27.3672544,24.5080561 L21.3597115,29.8739924 L15.3520585,24.5080561 L12.3953992,26.8566193 C5.05571213,29.8976182 0,30.9311936 0,36.8280489 L0,40.6352316 L42.7193128,40.6352316 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M34.3708197,28.4712357 C34.0457183,28.3420664 33.713123,28.2110203 33.3734747,28.0774349 L28.152567,40.6352316 L29.3135647,40.6352316 L34.3707094,28.4712357 L34.3708197,28.4712357 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          ></path>
                          <polygon
                            id='Path'
                            fill='#E7EBF5'
                            fillRule='nonzero'
                            points='14.566856 40.6352316 9.34594827 28.0774349 12.3953992 26.8566193 15.3520585 24.5080561 21.3597115 29.8739924 27.3673646 24.5080561 30.3240238 26.8566193 33.3734747 28.0774349 28.1526772 40.6352316 21.3597115 40.6352316'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                            points='21.3597115 29.8739924 24.2141016 32.8113247 22.9319899 34.7603462 23.6352007 40.6352316 19.0843325 40.6352316 19.7875433 34.7603462 18.5054316 32.8113247'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                            points='21.3597115 29.8739924 24.2141016 32.8113247 22.9319899 34.7603462 23.6352007 40.6352316 20.2552485 40.6352316 20.8124392 34.7603462 19.5303275 32.8113247'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                            points='21.3597115 29.8739924 24.2141016 32.8113247 22.9319899 34.7603462 21.3597115 34.7603462 19.7874331 34.7603462 18.5053214 32.8113247'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                            points='21.8721594 30.4013786 24.2141016 32.8113247 22.9319899 34.7603462 21.3597115 34.7603462 20.8124392 34.7603462 19.5303275 32.8113247'
                          ></polygon>
                        </g>
                        <g
                          id='Group'
                          strokeWidth='1'
                          fill='none'
                          transform='translate(30.332118, 25.697728)'
                        >
                          <polygon
                            id='Path'
                            fill='#3C6D91'
                            points='9.81398405 1.6390151 11.5461683 2.35883043 12.932092 1.42770735 14.847986 3.3470309 13.9185267 4.73554585 14.6370558 6.47083073 16.2732525 6.79397483 16.2732525 9.50829688 14.6370558 9.83144098 13.9185267 11.5667259 14.847986 12.9552408 12.932092 14.8745644 11.5461683 13.9434413 9.81398405 14.6632566 9.49130716 16.3022717 6.78183514 16.3022717 6.45926845 14.6632566 4.72708423 13.9434413 3.34105032 14.8745644 1.42526648 12.9552408 2.35472581 11.5667259 1.63619666 9.83144098 0 9.50829688 0 6.79397483 1.63619666 6.47083073 2.35472581 4.73554585 1.42526648 3.3470309 3.34105032 1.42770735 4.72708423 2.35883043 6.45926845 1.6390151 6.78183514 0 9.49130716 0'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#345E80'
                            points='9.81398405 1.6390151 10.4619827 1.90839385 10.4730031 1.91292029 11.5461683 2.35883043 11.5469397 2.35838883 11.9149104 2.51096319 13.1710139 1.66705699 14.847986 3.3470309 13.9185267 4.73554585 14.6370558 6.47083073 16.2732525 6.79397483 16.2732525 9.50829688 14.6370558 9.83144098 13.9185267 11.5667259 14.847986 12.9552408 13.1710139 14.6352147 11.9149104 13.7913085 11.5469397 13.9439933 11.5461683 13.9434413 9.81398405 14.6632566 9.49130716 16.3022717 7.20545876 16.3022717 6.86757374 14.5856446 4.95454499 13.7906461 4.72708423 13.9434413 3.69733939 14.6352147 2.02036731 12.9552408 2.94982663 11.5668363 2.0858284 9.47429334 0.595100826 9.17996394 0.595100826 8.15113585 0.595100826 7.12241817 2.0858284 6.82797837 2.94982663 4.73554585 2.02036731 3.3470309 3.69733939 1.66705699 4.95454499 2.51173599 6.86757374 1.71673749 7.20545876 0 9.49130716 0'
                          ></polygon>
                          <ellipse
                            id='Oval'
                            fill='#3C6D91'
                            fillRule='nonzero'
                            cx='8.58289677'
                            cy='8.15113585'
                            rx='3.90496346'
                            ry='3.91195346'
                          ></ellipse>
                          <ellipse
                            id='Oval'
                            fill='#F0F0FF'
                            fillRule='nonzero'
                            cx='8.13668135'
                            cy='8.15113585'
                            rx='3.90507366'
                            ry='3.91206386'
                          ></ellipse>
                        </g>
                        <polygon
                          id='Path'
                          fill='#D6DBE8'
                          points='21.6672905 33.4987152 17.0452305 38.2550165 12.3953992 30.1648221 17.116863 37.5483389 21.3597115 33.1821952'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#FFFFFF'
                          points='21.3597115 33.1821952 17.116863 37.5483389 12.3955094 30.1648221 15.3521687 27.8162589'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#FFFFFF'
                          points='21.3597115 33.1821952 25.6026702 37.5483389 30.3240238 30.1648221 27.3673646 27.8162589'
                        ></polygon>
                        <path
                          d='M8.34860337,31.7794385 C8.67370475,31.6502692 9.00629999,31.5192231 9.34594827,31.3856377 L14.566856,43.9434345 L13.4058584,43.9434345 L8.34871357,31.7794385 L8.34860337,31.7794385 Z'
                          id='Path'
                          fill='#345E80'
                        ></path>
                        <polygon
                          id='Path'
                          fill='#C7CEE0'
                          points='21.3597115 33.8152353 25.6741925 38.2550165 30.3240238 30.1648221 25.6026702 37.5483389 21.3597115 33.1821952'
                        ></polygon>
                        <path
                          d='M12.22282,12.8369239 C6.91143487,-0.463320419 24.4324154,-2.49724034 26.7860392,2.49664414 C28.4420726,2.2378639 35.7430781,0.471666708 30.4977051,12.8369239 C30.4698235,11.9649758 30.0875264,10.392091 29.2295893,9.30486071 C29.2095322,9.51318763 29.0618591,10.1660999 28.2235383,10.9528183 C27.9605919,9.45003819 27.1832139,7.92274903 26.1277916,7.71210368 C24.6310028,11.4979791 16.0522938,5.56082736 12.22282,12.8369239 Z'
                          id='Path'
                          fill='#2C3033'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default SMSFIcon;
