/* eslint-disable no-empty-pattern */
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { TeamUserType } from 'src/constants/funds-management';
import { IProfileResponse } from 'src/modules/auth/type';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { ITeamUser, IUpsertFundForm } from 'src/modules/funds-management/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { UPSERT_FUND_STEPS } from './UpsertFundsForm';
import UserSelectionBox from './UserSelectionBox';

interface ITeamsProps extends IUpsertFundForm {
  teamUsers: ITeamUser[];
  currentUser?: IProfileResponse;
  fundId: string;
}
const Teams: React.FC<ITeamsProps> = ({
  isViewMode,
  isEditMode,
  teamUsers,
  currentUser,
  isDraft,
  fundId,
}) => {
  const { isFmPortal } = useFundPortalContext();
  const isCreateMode = !isViewMode && !isEditMode;

  const { data: fundDetail } = useGetFundById(fundId, UPSERT_FUND_STEPS.TEAM);

  const form = useFormContext();
  const { errors } = form.formState;
  const { append: addInternalTeam, remove: removeInternalTeam } = useFieldArray({
    control: form?.control,
    name: 'internalTeams',
  });

  const { append: addClientTeam, remove: removeClientTeam } = useFieldArray({
    control: form?.control,
    name: 'clientTeams',
  });

  const isDisabledSelection = isViewMode || (isEditMode && !isDraft);
  const watchInternalTeamArray = form.watch('internalTeams');
  const watchClientTeamArray = form.watch('clientTeams');

  React.useEffect(() => {
    if (!isEmpty(fundDetail)) {
      handleFillData();
    }
  }, [fundDetail, teamUsers]);

  React.useEffect(() => {
    if (isCreateMode && currentUser?.id) {
      const { userType, id } = currentUser;
      const formData = { ...form?.formState.defaultValues };
      if (userType === TeamUserType.FAUser) {
        formData.internalTeams = [id];
      } else if (userType === TeamUserType.ClientTeam) {
        formData.clientTeams = [id];
      }
      form.reset(formData);
    }
  }, [currentUser?.id]);

  const handleFillData = () => {
    // The super admin users are selected by default
    const superAdminOurTeam = (teamUsers || [])
      .filter((user: ITeamUser) => user.userType === TeamUserType.OurTeam && user?.isSuperAdmin)
      .map((user) => user.id);
    const superAdminClientTeam = (teamUsers || [])
      .filter((user: ITeamUser) => user.userType === TeamUserType.ClientTeam && user?.isSuperAdmin)
      .map((user) => user.id);
    // Combine default team and team returned from API
    const { fundTeam } = fundDetail || {};
    const internalTeams = [...new Set([...(fundTeam?.internalTeams || []), ...superAdminOurTeam])];
    const clientTeams = [...new Set([...(fundTeam?.clientTeams || []), ...superAdminClientTeam])];

    form.reset({
      internalTeams,
      clientTeams,
    });
  };

  const ourTeam = React.useMemo(() => {
    const _ourTeam = (teamUsers || [])
      .filter((user: ITeamUser) => user.userType === TeamUserType.OurTeam)
      .map((item) => {
        const userSelectedIndex = (watchInternalTeamArray || []).findIndex(
          (f: string) => f === item.id,
        );
        return { ...item, isSelected: userSelectedIndex > -1 };
      });

    return _ourTeam;
  }, [teamUsers, watchInternalTeamArray]);

  const clientTeam = React.useMemo(() => {
    const _clientTeam = (teamUsers || [])
      .filter((user: ITeamUser) => user.userType === TeamUserType.ClientTeam)
      .map((item) => {
        const userSelectedIndex = (watchClientTeamArray || []).findIndex(
          (f: string) => f === item.id,
        );
        return { ...item, isSelected: userSelectedIndex > -1 };
      });
    return _clientTeam;
  }, [teamUsers, watchClientTeamArray]);

  const handleOurTeamSelectAll = (isSelectAll?: boolean) => {
    if (isSelectAll) {
      form.setValue(
        'internalTeams',
        ourTeam.map((user) => user.id),
        {
          shouldDirty: true,
        },
      );
    } else {
      form.setValue(
        'internalTeams',
        ourTeam?.filter((it) => it?.isSuperAdmin)?.map((user) => user?.id),
        {
          shouldDirty: true,
        },
      );
    }
  };

  const handleClientTeamSelectAll = (isSelectAll?: boolean) => {
    if (isSelectAll) {
      form.setValue(
        'clientTeams',
        clientTeam.map((user) => user.id),
        {
          shouldDirty: true,
        },
      );
    } else {
      form.setValue('clientTeams', []);
    }
  };

  const handleRemoveInternalTeam = (userId?: string) => {
    if (!userId) return;
    const indexInInternalTeamsForm = (watchInternalTeamArray || [])?.findIndex(
      (it: string) => it === userId,
    );

    if (indexInInternalTeamsForm >= 0) {
      removeInternalTeam(indexInInternalTeamsForm);
    }
  };

  const handleRemoveClientTeam = (userId?: string) => {
    if (!userId) return;
    const indexInClientTeamForm = (watchClientTeamArray || [])?.findIndex(
      (it: string) => it === userId,
    );

    if (indexInClientTeamForm >= 0) {
      removeClientTeam(indexInClientTeamForm);
    }
  };

  return (
    <Box>
      {currentUser?.userType === TeamUserType.FAUser && (
        <>
          <UserSelectionBox
            users={ourTeam}
            title='Select our internal team assigned to this fund'
            tooltip='You can manage your team by navigating to User Management, then accessing User Profile.'
            numOfSelected={`${watchInternalTeamArray.length} selected`}
            onSelect={(index, user) => {
              if (user.isSelected) {
                addInternalTeam(user.id);
              } else {
                handleRemoveInternalTeam(user?.id);
              }
            }}
            disabled={isDisabledSelection}
            onSelectAll={handleOurTeamSelectAll}
          />
          {errors && (
            <CustomHelperText
              variant='error'
              message={errors['internalTeams']?.message as string}
            />
          )}
          <Box mt='40px' />
        </>
      )}
      {!isFmPortal && (
        <UserSelectionBox
          users={clientTeam}
          title={`Select the client's team assigned to this fund`}
          tooltip={`You can manage client's team by navigating to Client Management, then accessing Client Profile and selecting Team section.`}
          numOfSelected={`${watchClientTeamArray.length} selected`}
          disabled={isDisabledSelection}
          onSelect={(index, user) => {
            if (user.isSelected) {
              addClientTeam(user.id);
            } else {
              handleRemoveClientTeam(user?.id);
            }
          }}
          onSelectAll={handleClientTeamSelectAll}
        />
      )}
      {errors && (
        <CustomHelperText variant='error' message={errors['clientTeams']?.message as string} />
      )}
    </Box>
  );
};

export default Teams;
