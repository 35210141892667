/* eslint-disable no-restricted-imports */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { FundType } from 'src/constants/funds-management';
import {
  CalculationBaseTypeOptions,
  DrpOptions,
  FeeType,
  FeeTypeOptions,
  FeeValueType,
  FeeValueTypeOfEntryExitFeesOptions,
  FeeValueTypeOptions,
  FrequencyTypeOptions,
  MAX_INFORMATION_CHARACTERS,
  MAX_UNIT_PRICE,
  PaymentTermOptions,
} from 'src/constants/unit-class';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { BPay, BankAccount } from 'src/modules/funds-management/type';
import { UPSERT_FUND_STEPS } from '../funds-management/funds/components/UpsertFundsForm';

interface IFinancialDetailFormProps {
  currencyName: string | undefined;
  fundId: string;
  fundType: number;
  isViewMode: boolean;
}

const FinancialDetailForm: FC<IFinancialDetailFormProps> = ({
  fundId,
  currencyName,
  fundType,
  isViewMode,
}) => {
  const [expanded, setExpanded] = useState<string | false>();
  const { data: fundDetail } = useGetFundById(fundId, UPSERT_FUND_STEPS.FINANCIAL_DETAILS);

  const bankAccountOptions = useMemo(
    () =>
      fundDetail?.financialDetails?.bankAccounts?.map((item: BankAccount) => ({
        label: item.nickName,
        value: item?.id,
      })),
    [fundDetail],
  );

  const bPayOptions = useMemo(
    () =>
      fundDetail?.financialDetails?.bPays?.map((item: BPay) => ({
        label: item.nickName,
        value: item?.id,
      })),
    [fundDetail],
  );

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'fees',
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...form.watch('fees')[index],
  }));

  const handleAddMoreItem = () => {
    append({
      feeType: null,
      description: '',
      amountType: null,
      calculationBase: null,
      amount: null,
      paymentTerm: null,
      frequency: null,
      comment: null,
    });
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const renderFeeItems = () => {
    const buySellAlreadyExists = controlledFields?.some(
      (it: any) => it?.feeType === FeeType.Buy_Sell_Spread,
    );
    return controlledFields?.map((item: any, index: number) => {
      const panelId = `panel${index}`;
      const feeType = form.watch(`fees[${index}].feeType`);
      const feeSelected = FeeTypeOptions.find((item) => item.value === feeType);
      const feeTypesExcludeBuySell = FeeTypeOptions.filter(
        (item) => item.value !== FeeType.Buy_Sell_Spread,
      );
      const shouldExcludeBuySell =
        buySellAlreadyExists && item?.feeType !== FeeType.Buy_Sell_Spread;

      return (
        <Accordion
          key={`${item.id}-${feeType}`}
          expanded={expanded === panelId}
          className='flex flex-col gap-2 mt-4 py-4 px-6'
          onChange={handleChange(panelId)}
        >
          <AccordionSummary className='w-full flex items-center justify-between'>
            <Box
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
              className='flex items-center w-full justify-between'
            >
              <Typography variant='body2' className='ml-2 font-medium' color='base.black'>
                {feeSelected
                  ? `${feeSelected?.label} ${
                      feeSelected.value === FeeType.Buy_Sell_Spread ? 'Fee' : ''
                    }`
                  : 'Fee'}
              </Typography>
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveItem(index);
                }}
              >
                <DeleteIcon width={22} height={24} />
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className='flex flex-col gap-8'>
              <Box className='flex flex-col gap-6 rounded-[10px]' bgcolor='neutral.ne100'>
                <FormSelect
                  name={`fees[${index}].feeType`}
                  label='Fee Type'
                  size='small'
                  placeholder='Select fee type'
                  fullWidth
                  options={
                    fundType === FundType.Partnership || shouldExcludeBuySell
                      ? feeTypesExcludeBuySell
                      : FeeTypeOptions
                  }
                  readOnly={isViewMode}
                />
              </Box>
              <FormInput
                name={`fees[${index}].description`}
                label='Description'
                size='small'
                placeholder='Enter description'
                className='w-full'
                readOnly={isViewMode}
              />
              {feeType !== null && renderFeeContent(feeType, index)}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const renderFeeContent = (feeType: number, itemIndex: number) => {
    const isShowBuySellSpread = feeType === FeeType.Buy_Sell_Spread && fundType === FundType.Trust;
    const isShowFeeAmountAndFrequency =
      feeType !== FeeType.Other && feeType !== FeeType.Buy_Sell_Spread;
    const isShowCalculationBase = feeType === FeeType.Management;
    const isShowEntryExitFee = feeType === FeeType.Entry_Exit;
    const isShowNormalAmount = feeType !== FeeType.Entry_Exit;
    const amountType = form.watch(`fees[${itemIndex}].amountType`);
    const comment = form.watch(`fees[${itemIndex}].comment`);

    return (
      <Box className='flex flex-col gap-8'>
        <Grid container rowSpacing={4} columnSpacing={3} className='items-center'>
          {isShowBuySellSpread && (
            <>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].buySpread`}
                  label='Buy Spread'
                  size='small'
                  placeholder='Enter basis point'
                  className='w-full'
                  decimalScale={2}
                  hideCurrency
                  endAdornment={
                    <Typography color='base.black' variant='body2' fontWeight={400}>
                      BPS
                    </Typography>
                  }
                  allowedNull
                  readOnly={isViewMode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].sellSpread`}
                  label='Sell Spread'
                  size='small'
                  placeholder='Enter basis point'
                  className='w-full'
                  decimalScale={2}
                  hideCurrency
                  allowedNull
                  endAdornment={
                    <Typography color='base.black' variant='body2' fontWeight={400}>
                      BPS
                    </Typography>
                  }
                  readOnly={isViewMode}
                />
              </Grid>
            </>
          )}
          {isShowFeeAmountAndFrequency && (
            <Grid item xs={12} md={12} className='flex items-center'>
              <Grid item xs={12} md={6}>
                <FormRadioGroup
                  label={feeType === FeeType.Entry_Exit ? 'Calculation Basis' : 'Fee'}
                  name={`fees[${itemIndex}].amountType`}
                  className='w-full'
                  row
                  options={
                    feeType === FeeType.Entry_Exit
                      ? FeeValueTypeOfEntryExitFeesOptions
                      : FeeValueTypeOptions
                  }
                  onChangeValue={() => form.setValue(`fees[${itemIndex}].amount`, null)}
                  disabled={isViewMode}
                />
              </Grid>
              {isShowNormalAmount && (
                <Grid item xs={12} md={6} mt={1}>
                  {amountType == FeeValueType.Amount && (
                    <FormCurrencyInput
                      name={`fees[${itemIndex}].amount`}
                      label=''
                      decimalScale={10}
                      placeholder='Enter amount'
                      max={MAX_UNIT_PRICE}
                      hideCurrency
                      endAdornment={
                        <Typography color='base.black' variant='body2' fontWeight={400}>
                          {currencyName}
                        </Typography>
                      }
                      readOnly={isViewMode}
                    />
                  )}
                  {amountType == FeeValueType.Percent && (
                    <FormCurrencyInput
                      name={`fees[${itemIndex}].amount`}
                      label=''
                      size='small'
                      placeholder='Enter %'
                      className='w-full'
                      decimalScale={2}
                      hideCurrency
                      max={100}
                      startAdornment={
                        <Typography
                          color='base.black'
                          variant='body2'
                          className='mx-1'
                          fontWeight={400}
                        >
                          %
                        </Typography>
                      }
                      readOnly={isViewMode}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          )}
          {isShowCalculationBase && (
            <Grid container item xs={12} md={12} columnSpacing={4} className='items-center'>
              {amountType == FeeValueType.Percent && (
                <Grid item xs={12} md={6}>
                  <FormSelect
                    name={`fees[${itemIndex}].calculationBase`}
                    label='Calculation Base'
                    size='small'
                    placeholder='Select calculation base'
                    fullWidth
                    options={CalculationBaseTypeOptions}
                    readOnly={isViewMode}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormRadioGroup
                  label='Payment Term'
                  name={`fees[${itemIndex}].paymentTerm`}
                  className='w-full'
                  row
                  options={PaymentTermOptions}
                  disabled={isViewMode}
                />
              </Grid>
            </Grid>
          )}
          {isShowEntryExitFee && amountType == FeeValueType.Percent && (
            <>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].entryFee`}
                  label='Entry Fee'
                  size='small'
                  placeholder='Enter %'
                  className='w-full'
                  decimalScale={2}
                  hideCurrency
                  max={100}
                  startAdornment={
                    <Typography
                      color='base.black'
                      variant='body2'
                      className='mx-1'
                      fontWeight={400}
                    >
                      %
                    </Typography>
                  }
                  readOnly={isViewMode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].exitFee`}
                  label='Exit Fee'
                  size='small'
                  placeholder='Enter %'
                  className='w-full'
                  decimalScale={2}
                  hideCurrency
                  max={100}
                  startAdornment={
                    <Typography
                      color='base.black'
                      variant='body2'
                      className='mx-1'
                      fontWeight={400}
                    >
                      %
                    </Typography>
                  }
                  readOnly={isViewMode}
                />
              </Grid>
            </>
          )}
          {isShowEntryExitFee && amountType != FeeValueType.Percent && (
            <>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].entryFee`}
                  label='Entry Fee'
                  decimalScale={10}
                  placeholder={`Enter ${
                    amountType == FeeValueType.Basis_Points ? 'basis point' : 'amount'
                  }`}
                  max={MAX_UNIT_PRICE}
                  hideCurrency
                  endAdornment={
                    <Typography color='base.black' variant='body2' fontWeight={400}>
                      {amountType == FeeValueType.Basis_Points ? 'BPS' : currencyName}
                    </Typography>
                  }
                  readOnly={isViewMode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormCurrencyInput
                  name={`fees[${itemIndex}].exitFee`}
                  label='Exit Fee'
                  decimalScale={10}
                  placeholder={`Enter ${
                    amountType == FeeValueType.Basis_Points ? 'basis point' : 'amount'
                  }`}
                  max={MAX_UNIT_PRICE}
                  hideCurrency
                  endAdornment={
                    <Typography color='base.black' variant='body2' fontWeight={400}>
                      {amountType == FeeValueType.Basis_Points ? 'BPS' : currencyName}
                    </Typography>
                  }
                  readOnly={isViewMode}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box className='flex flex-col items-end'>
              <FormInput
                name={`fees[${itemIndex}].comment`}
                label='Comment'
                placeholder='Enter comment'
                multiline
                rows={4.5}
                maxLength={MAX_INFORMATION_CHARACTERS}
                readOnly={isViewMode}
              />
              <Typography color='neutral.ne800' variant='body3' paddingTop={1}>
                {`${MAX_INFORMATION_CHARACTERS - (comment?.length || 0)} characters left`}
              </Typography>
            </Box>
          </Grid>
          {isShowFeeAmountAndFrequency && (
            <Grid item xs={12} md={12}>
              <FormSelect
                name={`fees[${itemIndex}].frequency`}
                label='Frequency'
                size='small'
                placeholder='Select frequency'
                fullWidth
                options={FrequencyTypeOptions}
                readOnly={isViewMode}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant='body2' className='font-medium mb-4'>
            Fees
          </Typography>
          {renderFeeItems()}
          {controlledFields?.length < 10 && (
            <Box className='flex items-center px-6 cursor-pointer mt-6' onClick={handleAddMoreItem}>
              <PlusPrimaryIcon />
              <Typography variant='body2' className='ml-2 font-medium'>
                Add Fee
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} className='mt-6'>
          <Typography variant='body2' className='font-medium mb-4'>
            Frequency
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='assetValuationFrequency'
                label='Asset Valuation Frequency'
                size='small'
                placeholder='Select asset valuation frequency '
                fullWidth
                options={FrequencyTypeOptions}
              />
            </Grid>
            {fundType === FundType.Trust && (
              <Grid item xs={12} md={6}>
                <FormSelect
                  name='unitPricingFrequency'
                  label='Unit Pricing Frequency'
                  size='small'
                  placeholder='Select unit pricing frequency'
                  fullWidth
                  options={FrequencyTypeOptions}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormSelect
                name='distributionFrequency'
                label='Distribution Frequency'
                size='small'
                placeholder='Select distribution frequency'
                fullWidth
                options={FrequencyTypeOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        {fundType === FundType.Trust && (
          <Grid item xs={12} className='mt-5'>
            <Typography variant='body2' className='font-medium mb-4'>
              Distribution Method
            </Typography>
            <FormRadioGroup
              label='Allow DRP'
              name='allowDRP'
              className='w-full'
              row
              options={DrpOptions}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} mt={2}>
          <Typography variant='body2' className='font-medium'>
            Payment Method
          </Typography>
          <Grid item xs={12} md={12} container columnSpacing={3} mt={2}>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='fundBankAccountId'
                label='Bank Account'
                size='small'
                placeholder='Select bank account'
                fullWidth
                options={bankAccountOptions || []}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                name='fundBPayInfoId'
                label='BPay'
                size='small'
                placeholder='Select BPay'
                fullWidth
                options={bPayOptions || []}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialDetailForm;
