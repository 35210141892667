import * as React from 'react';

interface IDismissCircleIconProps {}

const DismissCircleIcon: React.FunctionComponent<IDismissCircleIconProps> = () => {
  return (
    <svg
      width='13.3333333px'
      height='13.3333333px'
      viewBox='0 0 13.3333333 13.3333333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-11.1%'
          y='-8.0%'
          width='122.2%'
          height='122.4%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx='0' dy='5' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_List-Clients-Action_00'
          transform='translate(-1178.333333, -518.333333)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Action' transform='translate(1161.000000, 356.000000)'>
            <g
              id='Group-26-Copy-2'
              filter='url(#filter-1)'
              transform='translate(0.000000, 35.000000)'
            >
              <g id='Group-20-Copy-2' transform='translate(4.000000, 116.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                  transform='translate(12.000000, 10.000000)'
                >
                  <g id='Group' transform='translate(1.333333, 1.333333)'>
                    <path
                      d='M6.66666667,0 C10.3486667,0 13.3333333,2.98466667 13.3333333,6.66666667 C13.3333333,10.3486667 10.3486667,13.3333333 6.66666667,13.3333333 C2.98466667,13.3333333 0,10.3486667 0,6.66666667 C0,2.98466667 2.98466667,0 6.66666667,0 Z M6.66666667,1 C3.53705308,1 1,3.53705308 1,6.66666667 C1,9.79628025 3.53705308,12.3333333 6.66666667,12.3333333 C9.79628025,12.3333333 12.3333333,9.79628025 12.3333333,6.66666667 C12.3333333,3.53705308 9.79628025,1 6.66666667,1 L6.66666667,1 Z M8.964,4.26466667 L9.02,4.31333333 C9.19500238,4.48829518 9.21569494,4.76495185 9.06866667,4.964 L9.02,5.02 L7.374,6.66666667 L9.02066667,8.31333333 C9.1954618,8.48846731 9.21587002,8.76511204 9.06866667,8.964 L9.02,9.02 C8.84503816,9.19500238 8.56838149,9.21569494 8.36933333,9.06866667 L8.31333333,9.02 L6.66666667,7.374 L5.02,9.02066667 C4.84486603,9.1954618 4.56822129,9.21587002 4.36933333,9.06866667 L4.31333333,9.02 C4.13833095,8.84503816 4.1176384,8.56838149 4.26466667,8.36933333 L4.31333333,8.31333333 L5.95933333,6.66666667 L4.31266667,5.02 C4.13787153,4.84486603 4.11746331,4.56822129 4.26466667,4.36933333 L4.31333333,4.31333333 C4.48829518,4.13833095 4.76495185,4.1176384 4.964,4.26466667 L5.02,4.31333333 L6.66666667,5.95933333 L8.31333333,4.31266667 C8.48846731,4.13787153 8.76511204,4.11746331 8.964,4.26466667 L8.964,4.26466667 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DismissCircleIcon;
