import { Box, BoxProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { ArchiveIcon } from 'src/assets/icons/ArchiveIcon';

interface IEmptyDataProps extends BoxProps {
  isTable?: boolean;
  description?: string;
  icon?: JSX.Element;
}

const EmptyData: FC<IEmptyDataProps> = ({ isTable = false, description, icon, ...rest }) => {
  return (
    <Box
      className={clsx(`w-full flex flex-col items-center justify-center`, {
        'mt-[57px]': !isTable,
      })}
      {...rest}
    >
      {icon ? icon : <ArchiveIcon width={isTable ? '28' : '60'} height={isTable ? '28' : '60'} />}
      <Typography variant={isTable ? 'body3' : 'body2'} className='mt-2' color='neutral.ne800'>
        {description || 'No data to display'}
      </Typography>
    </Box>
  );
};

export default EmptyData;
