/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import ContactIcon from 'src/assets/icons/ContactIcon';
import { DisableIcon } from 'src/assets/icons/DisableIcon';
import { FlagIcon } from 'src/assets/icons/FlagIcon';
import { PersonSelectedIcon } from 'src/assets/icons/PersonSelectedIcon';

interface IUserSelectionItemProps {
  onSelect: () => void;
  onRemove?: () => void;
  disabled?: boolean;
  name: string;
  description?: string;
  isNew?: boolean;
  isSelected?: boolean;
  icon?: React.ReactNode;
}

const UserSelectionItem: React.FC<IUserSelectionItemProps> = ({
  name,
  description,
  isNew,
  isSelected,
  onSelect,
  onRemove,
  disabled,
  icon,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={1} className='flex items-center justify-center'>
        <Box
          className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
          bgcolor={isSelected ? 'secondary.se100' : 'neutral.ne200'}
        >
          {icon ? (
            icon
          ) : isSelected ? (
            <PersonSelectedIcon />
          ) : (
            <ContactIcon color='#9DA0A1' height='16px' />
          )}
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box className='flex items-center '>
          <Typography variant='body3' mr='4px'>
            {name}
          </Typography>
          {isNew && <FlagIcon />}
        </Box>
        <Typography variant='body3' color='neutral.ne800'>
          {description}
        </Typography>
      </Grid>
      <Grid item xs={3} className='flex items-center justify-end user-selection-button'>
        <Box
          className={clsx('px-[9px] py-[5px] rounded-[4px]', {
            'cursor-pointer': !disabled,
          })}
          bgcolor='neutral.ne200'
          onClick={() => !disabled && onSelect()}
        >
          <Typography
            color={disabled ? 'neutral.ne400' : 'primary.main'}
            variant='body3'
            fontWeight={500}
          >
            {isSelected ? 'Unselect' : 'Select'}
          </Typography>
        </Box>
        {!disabled && isNew && (
          <IconButton onClick={onRemove}>
            <DisableIcon />
          </IconButton>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default UserSelectionItem;
