/* eslint-disable no-empty-pattern */
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { INVESTMENT_ENTITY_STEP } from 'src/components/pages/administration-investor/investor/components/UpsertInvestorForm';
import { InvestmentEntityStatuses } from 'src/constants/administration-investor';
import {
  MAX_TEAM_COMMUNICATION_LIST,
  MAX_TEAM_PRIMARY_CONTACT,
  TeamType,
} from 'src/constants/applications';
import { ActionType, CLIENT_USER_ROLE, REQUIRE_MESSAGE } from 'src/constants/common';
import { USER_STATUS } from 'src/constants/user-management';
import {
  useGetInvestorById,
  useUpsertInvestorTeamContact,
} from 'src/modules/administration-investor/hooks';
import { IInvitedTeamIdsForm } from 'src/modules/administration-investor/types';
import { useGetContactListByTeamType } from 'src/modules/applications/hooks';
import {
  ITeamContact,
  ITeamError,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { handleErrorFromServer } from 'src/utils/common';
import TeamSelectionBox from './TeamSelectionBox';
import TeamUpsertContact, { TeamUpsertContactHandler } from './TeamUpsertContact';

interface IInvestmentApplicationTeamProps extends IUpsertInvestmentApplicationForm {
  errors: ITeamError;
  entityId?: string;
  invitedTeamIdsRef: MutableRefObject<string[] | undefined>;
  onSubmitTeam: (mode: string) => void;
}

const InvestmentEntityTeam: FC<IInvestmentApplicationTeamProps> = ({
  id = '',
  errors,
  isViewMode,
  isEditMode,
  invitedTeamIdsRef,
  isDraft,
  onSubmitTeam,
}) => {
  const createUserRef = useRef<TeamUpsertContactHandler>(null);
  const upsertDrawerRef = useRef<ICustomDrawerElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const cacheFromTeamTypeRef = useRef<{
    fromTeamType: number | null;
  }>({
    fromTeamType: null,
  });
  const [selectedUser, setSelectedUser] = useState<ITeamContact | null>();
  const [actionType, setActionType] = useState<ActionType>('create');
  const [selectedTeamType, setSelectedTeamType] = useState<TeamType>(TeamType.PrimaryContact);

  const { data: currentUser } = useGetUserInfo();
  const isSuperAdmin = currentUser?.role === CLIENT_USER_ROLE.SUPER_ADMIN;
  const { mutate: upsertInvestorTeamContact } = useUpsertInvestorTeamContact();
  const { data: investorDetailData } = useGetInvestorById(id, INVESTMENT_ENTITY_STEP.TEAM);
  const teamDetail = investorDetailData?.team;
  const isAddTo = actionType === 'addTo';
  const isAllowResendInvite = isEditMode && !isDraft;

  const form = useFormContext();
  const {
    append: addTeaMates,
    update: updateTeammates,
    remove: removeTeammates,
  } = useFieldArray({
    control: form?.control,
    name: 'teammates',
  });
  const { append: addInvitedTeamId } = useFieldArray({
    control: form?.control,
    name: 'invitedTeamIds',
  });

  const invitedTeamIds: IInvitedTeamIdsForm[] = form.watch('invitedTeamIds');
  const selectedUsers = form.watch('teammates');
  const { primaryContacts, communicationList } = useGetContactListByTeamType(selectedUsers);

  const handleCreateContact = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  useEffect(() => {
    if (!isEmpty(teamDetail) && (isViewMode || isEditMode)) {
      form.reset({
        teammates: teamDetail?.teammates,
        invitedTeamIds: (teamDetail?.invitedTeamIds || []).map((it) => ({
          value: it,
          isResend: false,
        })),
      });
      invitedTeamIdsRef.current = teamDetail?.invitedTeamIds;
    }
  }, [teamDetail?.teammates]);

  const handleAddUser = (data: ITeamContact) => {
    const submitData = {
      ...data,
      teamTypes: [selectedTeamType],
    };
    upsertInvestorTeamContact(
      {
        id,
        data: submitData,
      },
      {
        onSuccess: (id) => {
          const existingItemIndex = selectedUsers.findIndex((f: ITeamContact) => f.id === data.id);
          if (existingItemIndex !== -1) {
            updateTeammates(existingItemIndex, submitData);
          } else {
            addTeaMates({ ...submitData, id, isNew: actionType === 'create' });
          }
        },
        onError: handleErrorFromServer,
      },
    );
    upsertDrawerRef.current?.close();
  };

  const handleRemoveContact = (user?: ITeamContact, teamType?: TeamType) => {
    cacheFromTeamTypeRef.current.fromTeamType = teamType || 0;
    setSelectedUser(user);
    modalConfirmRef.current?.open();
  };
  const handleEditContact = (user?: ITeamContact, teamType?: TeamType) => {
    setSelectedTeamType(teamType || TeamType.Team);
    setActionType('edit');
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };
  const handleViewContact = (user?: ITeamContact, teamType?: TeamType) => {
    setActionType('view');
    setSelectedTeamType(teamType || TeamType.Team);
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };
  const handleClickAddTo = (user?: ITeamContact) => {
    setActionType('addTo');
    setSelectedTeamType(TeamType.Team);
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };

  const confirmRemoveContact = () => {
    handleRemoveContactFromList();
  };

  const handleRemoveContactFromList = () => {
    const newSelectedUser = [...selectedUsers];
    const userIndex = newSelectedUser.findIndex((f) => f.id === selectedUser?.id);

    if (userIndex !== -1) {
      newSelectedUser[userIndex].teamTypes = (newSelectedUser[userIndex].teamTypes || []).filter(
        (x: number) => x !== cacheFromTeamTypeRef.current.fromTeamType,
      );
      isEmpty(newSelectedUser[userIndex].teamTypes)
        ? removeTeammates(userIndex)
        : updateTeammates(userIndex, newSelectedUser[userIndex]);
    }

    handleCloseConfirmModal();
  };

  const handleInviteTeam = (user?: ITeamContact, isResend?: boolean) => {
    const isExistingResendId = (invitedTeamIds || [])
      .filter((it) => it?.isResend)
      .map((it) => it.value)
      .includes(user?.id || '');
    if (isExistingResendId) return;

    addInvitedTeamId({
      value: user?.id,
      isResend: !!isResend,
    });

    if (isResend) {
      onSubmitTeam(SUBMIT_FORM_ACTIONS.SUBMIT);
    }
  };

  const handleAddTo = (data: ITeamContact) => {
    const existingUserIndex = (selectedUsers || []).findIndex(
      (item: ITeamContact) => item?.id === data.id,
    );
    if (existingUserIndex >= 0) {
      updateTeammates(existingUserIndex, {
        ...data,
        teamTypes: [
          ...new Set([
            ...(data.teamTypes || []),
            ...(selectedUsers[existingUserIndex]?.teamTypes || []),
          ]),
        ],
      });
    } else {
      addTeaMates(data);
    }

    upsertDrawerRef.current?.close();
  };

  const handleCloseConfirmModal = () => {
    modalConfirmRef.current?.close();
    setSelectedUser(null);
  };

  const handleCloseUpsertModal = () => {
    upsertDrawerRef.current?.close();
    setSelectedUser(null);
  };

  const getUpsertContactModalTitle = useMemo(() => {
    switch (actionType) {
      case 'edit':
        return 'Edit';
      case 'view':
        return 'View';
      default:
        return 'Create New';
    }
  }, [actionType]);

  return (
    <Box>
      <TeamSelectionBox
        title='Primary Contact'
        numOfSelected={`${primaryContacts.length}/${MAX_TEAM_PRIMARY_CONTACT} selected`}
        users={primaryContacts}
        mt='32px'
        onRemove={(user, teamType) => handleRemoveContact(user, teamType)}
        onEdit={handleEditContact}
        onView={handleViewContact}
        disabled={isViewMode}
        teamType={TeamType.PrimaryContact}
        entityTeams={teamDetail?.entityTeams || []}
        hideRemoveAction={!isSuperAdmin && isEditMode && !isDraft}
        createNewButton={
          primaryContacts?.length < MAX_TEAM_PRIMARY_CONTACT && (
            <CustomButton
              className='px-1'
              variant='text'
              startIcon={<PlusPrimaryIcon />}
              onClick={() => {
                setActionType('create');
                setSelectedTeamType(TeamType.PrimaryContact);
                upsertDrawerRef.current?.open();
              }}
              disabled={isViewMode}
            >
              <Typography variant='body2' color='primary.main'>
                Create New
              </Typography>
            </CustomButton>
          )
        }
      />
      {errors.isPrimaryContactError && (
        <CustomHelperText variant='error' message={REQUIRE_MESSAGE} />
      )}
      <TeamSelectionBox
        title='Communication List'
        numOfSelected={`${communicationList.length}/${MAX_TEAM_COMMUNICATION_LIST} selected`}
        tooltip='Please select the team to be added to the email distribution list to receive statements, news, and updates via email.'
        users={communicationList}
        mt='32px'
        onRemove={(user, teamType) => handleRemoveContact(user, teamType)}
        onEdit={handleEditContact}
        onView={handleViewContact}
        disabled={isViewMode}
        teamType={TeamType.CommunicationList}
        entityTeams={teamDetail?.entityTeams || []}
        hideRemoveAction={!isSuperAdmin && isEditMode && !isDraft}
        createNewButton={
          communicationList.length < MAX_TEAM_COMMUNICATION_LIST && (
            <CustomButton
              className='px-1'
              variant='text'
              startIcon={<PlusPrimaryIcon />}
              onClick={() => {
                setActionType('create');
                setSelectedTeamType(TeamType.CommunicationList);
                upsertDrawerRef.current?.open();
              }}
              disabled={isViewMode}
            >
              <Typography variant='body2' color='primary.main'>
                Create New
              </Typography>
            </CustomButton>
          )
        }
      />
      {errors.isCommunicationListError && (
        <CustomHelperText variant='error' message={REQUIRE_MESSAGE} />
      )}
      <TeamSelectionBox
        title='Team'
        /**
         * If entity is linked, show only active team
         * If entity is not linked, show all team
         */
        users={
          investorDetailData?.entityStatus === InvestmentEntityStatuses.Linked
            ? (teamDetail?.entityTeams || []).filter(
                (item) => item.userStatus === USER_STATUS.Active,
              )
            : teamDetail?.entityTeams || []
        }
        tooltip='Please create the team who will be assigned to access this account'
        mt='32px'
        onView={handleViewContact}
        teamType={TeamType.Team}
        onInvite={handleInviteTeam}
        onAddTo={handleClickAddTo}
        invitedTeamIds={invitedTeamIds}
        displayEmpty
        isAllowResendInvite={isAllowResendInvite}
        entityStatus={investorDetailData?.entityStatus || InvestmentEntityStatuses.Draft}
        disabled={isViewMode}
      />
      <CustomDrawer
        ref={upsertDrawerRef}
        title={getUpsertContactModalTitle}
        onClose={handleCloseUpsertModal}
        isWithoutActions={actionType === 'view'}
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseUpsertModal}
            >
              Cancel
            </CustomButton>

            <CustomButton
              className='w-[160px]'
              onClick={
                isAddTo
                  ? () => {
                      createUserRef.current?.onAddTo(handleAddTo);
                    }
                  : handleCreateContact
              }
            >
              {isAddTo ? 'Add' : 'Save'}
            </CustomButton>
          </Box>
        }
      >
        <TeamUpsertContact
          ref={createUserRef}
          user={selectedUser || undefined}
          isEditMode={actionType === 'edit'}
          isViewMode={actionType === 'view'}
          teamType={selectedTeamType}
          isAddTo={isAddTo}
          disabledAddToPrimary={primaryContacts?.length === MAX_TEAM_PRIMARY_CONTACT}
          disabledAddToComList={communicationList?.length === MAX_TEAM_COMMUNICATION_LIST}
        />
      </CustomDrawer>
      <ConfirmModal
        ref={modalConfirmRef}
        title='Remove User?'
        content={`Are you sure you want to remove this user?<br/> This action cannot be undone.`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={confirmRemoveContact}
              sx={(theme) => ({
                bgcolor: theme.palette.error.main,
              })}
            >
              Remove
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default InvestmentEntityTeam;
