import { Box, IconButton, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import CustomTable, { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import {
  useDownloadOrganisationBillingInvoice,
  useGetOrganisationBillings,
} from 'src/modules/organisation-settings/hooks';
import { IOrganisationBilling } from 'src/modules/organisation-settings/type';
import { handleErrorFromServer } from 'src/utils/common';

type ITableData = IOrganisationBilling;

const Billing = () => {
  const { data: billings = [], isLoading } = useGetOrganisationBillings();
  const { mutate: downloadBillingInvoice } = useDownloadOrganisationBillingInvoice();
  const theme = useTheme();

  const handleDownloadBillingInvoice = (row: ITableData) => {
    const { id, invoiceNumber } = row;
    downloadBillingInvoice(
      { id, fileName: `${invoiceNumber}.pdf` },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const columns: ColumnProps<ITableData, 'action'>[] = [
    {
      title: 'Invoice Date',
      key: 'invoiceDate',
      sorter: true,
      sortBy: 'invoiceDate',
      sx: { whiteSpace: 'pre', width: '15%', py: '11px' },
      renderNode: (row) => (
        <StringNodeTable
          variant='body3'
          value={row?.invoiceDate ? dayjs(row.invoiceDate).format(DATE_PICKER_FORMAT) : ''}
        />
      ),
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      sorter: true,
      sortBy: 'invoiceNumber',
      sx: { width: '40%', whiteSpace: 'pre', py: '11px' },
      renderNode: (row) => (
        <StringNodeTable variant='body3' value={row?.invoiceNumber} color='base.black' />
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      sorter: true,
      sortBy: 'amount',
      sx: { width: '15%', whiteSpace: 'pre', py: '11px' },
      renderNode: (row) => <StringNodeTable variant='body3' value={row?.amount} />,
    },
    {
      title: 'Status',
      key: 'status',
      sx: { width: '15%', py: '11px' },
      renderNode: (row) => (
        <StatusBadge
          status={row?.statusName}
          defaultColor={theme.palette.error.main}
          defaultBgColor={theme.palette.error.light}
          showDot={false}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '10%', py: '11px' },
      renderNode: (row) => (
        <Box className='flex items-center justify-center'>
          <IconButton
            sx={{
              width: '24px',
              height: '24px',
              p: 0,
              boxSizing: 'content-box',
              borderRadius: '50%',
              bgcolor: 'neutral.ne200',
              mx: 'auto',
            }}
            onClick={() => handleDownloadBillingInvoice(row)}
          >
            <DownloadIcon width={'16'} height={'16'} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box mx={-3} mb={-3} display={'flex'} flexDirection={'column'}>
      <Box
        sx={{
          '.render-node-container': { m: 0 },
          '.MuiTable-root': { minWidth: '500px !important' },
        }}
      >
        {billings?.length || isLoading ? (
          <CustomTable
            columns={columns}
            rows={billings}
            isFetchingData={isLoading}
            hasPagination={false}
          />
        ) : (
          <EmptyData />
        )}
      </Box>
    </Box>
  );
};

export default Billing;
