/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import { isEmpty } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CopyContentIcon from 'src/assets/icons/CopyContentIcon';
import FormInput from 'src/components/atoms/FormInput';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import { CLIENT_STATUS } from 'src/constants/client-management';
import { OTHER_LABEL } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import { useGetCapacity, useGetClientDetail } from 'src/modules/client/hooks';
import { copyTextToClipboard, formatValueWithSpaces, sortByAlphabet } from 'src/utils/common';
import AddressSection from './AddressSection';
import { FUND_UPSERT_CLIENT_STEP } from './funds/FundsCreateUpdateClientForm';

interface IOrganisationFormProps {
  clientStatus: number;
  isEditMode: boolean;
  isViewMode: boolean;
  clientDetails: any;
  clientId: string;
}

const COPY_TEXT = 'Copy link';
const COPIED_TEXT = 'Copied!';

export const ADDRESS_SECTION_NAME = {
  physicalAddress: 'physicalAddress',
  postalAddress: 'postalAddress',
};

const OrganisationForm: FC<IOrganisationFormProps> = ({
  clientStatus,
  isEditMode,
  isViewMode,
  clientId,
}) => {
  const { watch, setValue, getValues, reset } = useFormContext();
  const { data: capacityData = [] } = useGetCapacity();
  const [title, setTitle] = useState(COPY_TEXT);
  const { isFunds } = detectPortalType();
  const { data: clientDetails } = useGetClientDetail(
    clientId,
    FUND_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
  );

  const tenantId = watch('tenantId');

  const isDisabledDomainUrl = useMemo(() => {
    if (!isEditMode) return false;

    return [CLIENT_STATUS.Active.id, CLIENT_STATUS.Pending.id].includes(clientStatus);
  }, [clientStatus, isEditMode]);

  useEffect(() => {
    handleFillFundClientData();
  }, [clientDetails]);

  useEffect(() => {
    handleSetTenantId();
  }, [tenantId]);

  const handleFillFundClientData = () => {
    if (isEmpty(clientDetails) || !isFunds) return;
    const data = generateFormData(clientDetails?.organisationDetail);

    reset(data, { keepIsValid: false });
  };

  const handleSetTenantId = () => {
    if (!tenantId || isFunds) return;
    setValue('tenantId', tenantId.replaceAll(/\s/g, ''));
  };

  const generateFormData = (clientDetails: any) => {
    if (!clientDetails) return;

    const data: any = {
      legalName: clientDetails?.legalName || '',
      abn: formatValueWithSpaces(clientDetails?.abn || ''),
      physicalAddress: {
        country: {
          value: clientDetails?.countryId || '',
          label: clientDetails?.countryName || '',
          id: clientDetails?.countryId || '',
        },
        state: {
          value: clientDetails?.stateId || '',
          label: clientDetails?.stateName || '',
          id: clientDetails?.stateId || '',
        },
        city: {
          value: clientDetails?.cityId || '',
          label: clientDetails?.cityName || '',
          id: clientDetails?.cityId || '',
        },
        manualEntry: clientDetails?.manualEntry,
        postcode: clientDetails?.postcode || '',
        streetAddress: clientDetails?.streetAddress || '',
      },
      postalAddress: {
        country: {
          value: clientDetails?.postalCountryId || '',
          label: clientDetails?.postalCountryName || '',
          id: clientDetails?.postalCountryId || '',
        },
        state: {
          value: clientDetails?.postalStateId || '',
          label: clientDetails?.postalStateName || '',
          id: clientDetails?.postalStateId || '',
        },
        city: {
          value: clientDetails?.postalCityId || '',
          label: clientDetails?.postalCityName || '',
          id: clientDetails?.postalCityId || '',
        },
        manualEntry: clientDetails?.postalManualEntry,
        postcode: clientDetails?.postalPostcode || '',
        streetAddress: clientDetails?.postalStreetAddress || '',
      },
      isUseSamePhysicalAddress: clientDetails?.isUseSamePhysicalAddress,
      capacityIds: clientDetails?.capacityIds || [],
      otherCapacity: clientDetails?.otherCapacity || '',
      clientName: clientDetails?.clientName || '',
      sectionId: clientDetails?.id,
      hasOtherCapacity: handleCapacityChange(clientDetails?.capacityIds || []),
    };

    if (clientDetails?.manualEntry) {
      data.physicalAddress.city = clientDetails?.cityName;
    }

    if (clientDetails?.postalManualEntry) {
      data.postalAddress.city = clientDetails?.postalCityName;
    }

    return data;
  };

  const handleSetSubdomain = () => {
    const clientName = getValues('clientName') || '';
    setValue('tenantId', clientName.replaceAll(/\s/g, '').toLowerCase());
  };

  const handleCopyDomainUrl = () => {
    const tenantId = getValues('tenantId') || '';
    const domainUrl = `${tenantId}.${process.env.REACT_APP_TOP_LEVEL_DOMAIN_URL}`;
    setTitle(COPIED_TEXT);
    copyTextToClipboard(domainUrl);
  };

  const handleCloseTooltip = () => {
    setTitle(COPY_TEXT);
  };

  const handleCapacityChange = (value: string[]) => {
    const otherOption = (capacityData || []).find((item) => item.capacityName === OTHER_LABEL);
    const hasOtherOption = (value || []).includes(otherOption?.id || '');

    setValue('hasOtherCapacity', !!hasOtherOption, { shouldDirty: true });

    if (!hasOtherOption && getValues('otherCapacity')) {
      setValue('otherCapacity', '', { shouldDirty: true });
    }

    return hasOtherOption;
  };

  const renderUseAsSubdomain = () => {
    if (isDisabledDomainUrl || isViewMode || isFunds) return <></>;
    return (
      <Typography
        className='cursor-pointer'
        component='span'
        variant='body3'
        color='primary'
        fontWeight={500}
        sx={{ fontStyle: 'italic', cursor: 'pointer' }}
        onClick={handleSetSubdomain}
      >
        Use as subdomain name
      </Typography>
    );
  };

  const renderOtherCapacity = () => {
    return (
      watch('hasOtherCapacity') && (
        <Grid item xs={12} md={6} alignSelf='flex-end'>
          <FormInput
            name='otherCapacity'
            placeholder='Enter other capacity'
            readOnly={isViewMode}
          />
        </Grid>
      )
    );
  };

  return (
    <Box component='form'>
      <Box className='pt-4'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormInput
              name='legalName'
              label='Legal Name'
              placeholder='Enter legal name'
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name='abn'
              label='ACN/ABN'
              placeholder='Enter ACN/ABN'
              formatValue={(value) => formatValueWithSpaces(value)}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={12} md={isFunds ? 12 : 6}>
            <FormInput
              name='clientName'
              label={
                <span className='flex justify-between w-full'>
                  <span>Client Name</span>
                  {renderUseAsSubdomain()}
                </span>
              }
              placeholder='Enter client name'
              readOnly={isViewMode}
            />
          </Grid>
          {!isFunds && (
            <Grid item xs={12} md={6}>
              <Box className='flex items-end'>
                <FormInput
                  name='tenantId'
                  label='Domain URL'
                  placeholder='Domain name'
                  readOnly={isDisabledDomainUrl || isViewMode}
                  InputProps={{
                    style: {
                      paddingRight: 0,
                    },
                    endAdornment: (
                      <Box
                        className='px-3 flex justify-between items-center h-full rounded-r-xl border-l'
                        bgcolor='neutral.ne100'
                        borderColor='neutral.ne400'
                      >
                        <Typography variant='body2' color='neutral.ne600' sx={{ pr: 1 }}>
                          .fundcorr.com
                        </Typography>
                        <Tooltip onClose={handleCloseTooltip} title={title}>
                          <IconButton sx={{ p: 0 }} onClick={handleCopyDomainUrl}>
                            <CopyContentIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormMultiSelect
              name='capacityIds'
              label='Capacity'
              options={sortByAlphabet(capacityData || [], 'label')}
              placeholder='Select capacity'
              readOnly={isViewMode}
              allOption
              processChangedByUser={handleCapacityChange}
            />
          </Grid>
          {renderOtherCapacity()}
        </Grid>
      </Box>
      <AddressSection
        name={ADDRESS_SECTION_NAME.physicalAddress}
        title='Physical Address'
        readOnly={isViewMode}
        isShowManualEntry
      />
      <AddressSection
        name={ADDRESS_SECTION_NAME.postalAddress}
        title='Postal Address'
        readOnly={isViewMode}
        isShowManualEntry
      />
    </Box>
  );
};

export default OrganisationForm;
