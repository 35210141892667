export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  VERIFY_TOKEN: 'verifyToken',
  DEFAULT_2FA_METHOD: 'default2FAMethod',
  OFFER_CODE: 'offerCode',
  BRANDING_KEY: 'branding-theme',
  LOGO_RESOLUTION_KEY: 'logo-resolution',
  OVERARCHING_FILTER: 'overarchingFilter',
  INVESTOR_VIEW_MODE: 'investor-view-mode',
  DELEGATE_INVESTMENT_ENTITY: 'delegate-investment-entity',
  IS_SHOW_DUPLICATE_APP_POPUP: 'isShowDuplicateAppPopup',
};
