import * as React from 'react';

interface IBracketPrimaryIconProps {}

const BracketPrimaryIcon: React.FunctionComponent<IBracketPrimaryIconProps> = () => {
  return (
    <svg
      width='22px'
      height='22px'
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Client-Management_01'
          transform='translate(-718.000000, -321.000000)'
          fill='#44B588'
        >
          <g id='Pop-up-Copy' transform='translate(289.000000, 69.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 187.000000)'>
              <g id='Title' transform='translate(429.000000, 60.000000)'>
                <g id='Icon' transform='translate(0.000000, 5.000000)'>
                  <path
                    d='M8.89726854,14.6421568 L1.19545799,21.621089 C-0.480561016,19.8742308 -0.382100369,17.1391865 1.41425942,15.5092105 L4.97415879,12.2832607 C5.70276757,11.6244699 5.74215183,10.5172764 5.06386738,9.81173266 L1.50834404,6.10975339 C-0.169862976,4.36502033 -0.0714023299,1.62785082 1.72714548,0 L1.72714548,0 L9.14451417,7.72272828 C11.0437106,9.69910068 10.9321219,12.7975426 8.89726854,14.6421568'
                    id='Fill-100'
                  />
                  <path
                    d='M20.3980411,15.0210678 L12.6962305,22 L12.6962305,22 C11.0202115,20.2531418 11.1186722,17.5180974 12.915032,15.8881215 L16.4749313,12.6621717 C17.2035401,12.0033809 17.2429244,10.8961873 16.5646399,10.1906436 L13.0091166,6.48866435 C11.3309096,4.7439313 11.4293702,2.00676178 13.227918,0.378910967 L20.6452867,8.10163925 C22.5444832,10.0780116 22.4328944,13.1764535 20.3980411,15.0210678'
                    id='Fill-102'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BracketPrimaryIcon;
