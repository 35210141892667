import { useQuery } from '@tanstack/react-query';
import { APPLICATION_API } from 'src/constants/apiEndpoints';
import { getApplicationFilterCreation } from './services';
import { IApplicationFilterCreation, IFundApplicationCreationParams } from './types';

export const useGetApplicationFilterCreation = (
  params: IFundApplicationCreationParams,
  enabled: boolean,
) => {
  return useQuery<IApplicationFilterCreation>(
    [
      APPLICATION_API.getApplicationFilterCreation,
      params?.clientId,
      params?.fundId,
      params?.offerId,
      params?.unitClassId,
    ],
    () => getApplicationFilterCreation(params),
    {
      enabled,
      keepPreviousData: true,
    },
  );
};
