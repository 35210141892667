import { useFormContext } from 'react-hook-form';
import HorizontalTabs, { ITab } from 'src/components/molecules/HorizontalTabs';
import Billing from './Billing';
import BillingHistory from './BillingHistory';
import Subscription from './Subscription';

export interface ISubscriptionAndBillingProps {
  isViewMode?: boolean;
  isDraft?: boolean;
  isSuperAdmin: boolean;
  clientId: string;
  isCreatedClient: boolean;
  isEditingClient: boolean;
}

const SubscriptionAndBilling = (props: ISubscriptionAndBillingProps) => {
  const form = useFormContext();
  const Tabs: ITab[] = [
    {
      label: 'Subscription',
      content: <Subscription {...props} />,
    },
    {
      label: 'Billing',
      content: <Billing {...props} />,
      isError: !!form.formState.errors?.billings,
    },
    ...(props.isCreatedClient
      ? [
          {
            label: 'Billing History',
            content: <BillingHistory {...props} />,
          },
        ]
      : []),
  ];

  return <HorizontalTabs tabs={Tabs} destroyInactivePanel={false} />;
};

export default SubscriptionAndBilling;
