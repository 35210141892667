/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IDoneImageProps {}

export const DoneImage: FC<IDoneImageProps> = () => {
  return (
    <svg
      width='238px'
      height='98px'
      viewBox='0 0 238 98'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='00_Login_08' transform='translate(-602.000000, -255.000000)'>
          <g id='Pop-up' transform='translate(478.000000, 215.000000)'>
            <g id='Frame-1' transform='translate(124.000000, 40.000000)'>
              <rect
                id='Rectangle'
                fill='#FFFFFF'
                fillRule='nonzero'
                x='0'
                y='0'
                width='238'
                height='98'
              ></rect>
              <path
                d='M131.5,32 L131.5,28.25 C131.5,27.2835 132.284,26.5 133.25,26.5 C134.216,26.5 135,25.7165 135,24.75 L135,23.75 C135,22.2312 133.769,21 132.25,21 L132,21 C130.343,21 129,19.6569 129,18 L129,17 C129,15.3431 130.343,14 132,14 L153.5,14 C155.157,14 156.5,15.3431 156.5,17 L156.5,18.5 C156.5,20.1569 155.157,21.5 153.5,21.5 L148.5,21.5 C147.119,21.5 146,22.6193 146,24 C146,25.3807 147.119,26.5 148.5,26.5 L166,26.5 C167.657,26.5 169,27.8431 169,29.5 L169,32 C169,33.6569 167.657,35 166,35 L134.5,35 C132.843,35 131.5,33.6569 131.5,32 Z'
                id='Path'
                fill='#E6FCD0'
                fillRule='nonzero'
              ></path>
              <ellipse
                id='Oval'
                fill='#E6FCD0'
                fillRule='nonzero'
                cx='168'
                cy='21'
                rx='5'
                ry='4'
              ></ellipse>
              <path
                d='M197.477,44.4034 L197.477,38.7784 L198.432,38.7784 L198.432,44.4034 L197.477,44.4034 Z M195.142,42.0682 L195.142,41.1136 L200.767,41.1136 L200.767,42.0682 L195.142,42.0682 Z'
                id='Shape'
                fill='#8AC05E'
                fillRule='nonzero'
              ></path>
              <path
                d='M137.744,68 C135.286,77.5344 127.112,84.6176 117.302,84.985 C117.203,84.9949 117.102,85 117,85 L116.5,85 L38,85 C36.3431,85 35,83.6569 35,82 L35,79.1915 C35,77.5346 36.3431,76.1915 38,76.1915 L68.0492,76.1915 C69.6708,76.1915 70.9854,74.8769 70.9854,73.2553 C70.9854,71.6337 69.6708,70.3191 68.0492,70.3191 L56.5,70.3191 C54.8431,70.3191 53.5,68.976 53.5,67.3192 L53.5,65 C53.5,63.3431 54.8431,62 56.5,62 L94.5,62 C94.5,58.8061 95.1227,55.7643 96.2481,53 L90,53 C88.3431,53 87,51.6569 87,50 L87,47 C87,45.3431 88.3431,44 90,44 L101,44 L101,45.6778 C104.977,41.5502 110.453,39 116.5,39 C122.208,39 127.409,41.2728 131.319,45 L179,45 C180.657,45 182,46.3431 182,48 L182,49.3404 C182,50.9973 180.657,52.3404 179,52.3404 L156.174,52.3404 C154.517,52.3404 153.174,53.6836 153.174,55.3404 L153.174,56.6809 C153.174,58.3377 154.517,59.6809 156.174,59.6809 L169.576,59.6809 C171.233,59.6809 172.576,61.024 172.576,62.6809 L172.576,65 C172.576,66.6569 171.233,68 169.576,68 L137.744,68 Z'
                id='Path'
                fill='#E6FCD0'
              ></path>
              <ellipse
                id='Oval'
                fill='#F1FEE1'
                fillRule='nonzero'
                cx='195.5'
                cy='51.5'
                rx='4.5'
                ry='3.5'
              ></ellipse>
              <circle id='Oval' stroke='#CBE2BA' cx='153.5' cy='75.5' r='3'></circle>
              <circle id='Oval' stroke='#7AAC50' cx='96' cy='30' r='4.5'></circle>
              <path
                d='M110.934,40.3625 C110.861,40.0413 111.06,39.7207 111.382,39.6529 C115.769,38.7308 120.328,39.1496 124.486,40.8643 C128.828,42.6547 132.524,45.771 135.069,49.7889 C137.615,53.8067 138.886,58.5311 138.712,63.319 C138.545,67.9034 137.059,72.3328 134.447,76.0552 C134.256,76.3273 133.877,76.3791 133.614,76.1756 C133.359,75.9781 133.31,75.6135 133.495,75.3494 C135.969,71.8203 137.377,67.6215 137.536,63.2759 C137.701,58.7353 136.495,54.2549 134.081,50.4445 C131.667,46.6341 128.163,43.6788 124.045,41.9809 C120.115,40.3603 115.808,39.9603 111.661,40.823 C111.333,40.8911 111.007,40.6888 110.934,40.3625 Z M125.391,81.9805 C125.545,82.2749 125.431,82.6384 125.134,82.7811 C120.481,85.0142 115.186,85.6109 110.105,84.4639 C105.022,83.3167 100.463,80.4949 97.1607,76.4644 C96.951,76.2085 96.9983,75.8316 97.2588,75.6309 C97.5164,75.4324 97.8855,75.4813 98.0932,75.7342 C101.224,79.5476 105.542,82.2174 110.355,83.3038 C115.167,84.39 120.181,83.8273 124.59,81.7167 C124.883,81.5761 125.239,81.6898 125.391,81.9805 Z M132.574,78.6108 C132.834,78.342 132.817,77.9129 132.545,77.6593 C132.271,77.4038 131.843,77.4233 131.58,77.6935 C130.534,78.77 129.379,79.7331 128.135,80.5673 C127.823,80.776 127.723,81.1942 127.918,81.5131 C128.114,81.8329 128.532,81.9316 128.844,81.7228 C130.193,80.822 131.443,79.7788 132.574,78.6108 Z M93.6359,62.5485 C93.3939,62.4519 93.2364,62.2132 93.2455,61.9514 C93.4024,57.4448 94.3521,52.7958 96.8772,49.0573 C99.3677,45.3701 102.808,42.459 106.796,40.6551 C107.101,40.5169 107.457,40.6715 107.578,40.9876 C107.694,41.2902 107.55,41.6288 107.258,41.7611 C103.474,43.4716 100.21,46.2332 97.8468,49.7315 C95.5396,53.1472 94.6782,57.803 94.4888,61.9872 C94.4692,62.4194 94.0355,62.708 93.6359,62.5485 Z'
                id='Shape'
                fill='#FFFFFF'
              ></path>
              <path
                d='M110.3,39.9472 C110.224,39.6188 110.428,39.2899 110.758,39.2222 C115.31,38.289 120.04,38.7169 124.357,40.4598 C128.862,42.279 132.698,45.4422 135.341,49.5191 C137.985,53.5959 139.308,58.3883 139.13,63.244 C138.96,67.896 137.421,72.3896 134.712,76.1642 C134.515,76.4381 134.132,76.4905 133.863,76.2869 C133.594,76.0834 133.542,75.7009 133.738,75.4268 C136.296,71.8524 137.749,67.6007 137.91,63.1994 C138.079,58.5945 136.824,54.0496 134.317,50.1833 C131.81,46.317 128.173,43.3171 123.9,41.5919 C119.816,39.9429 115.341,39.5351 111.034,40.4119 C110.704,40.4791 110.376,40.2755 110.3,39.9472 Z M125.601,82.6977 C125.752,82.9991 125.63,83.3667 125.325,83.51 C120.514,85.7702 115.074,86.3452 109.887,85.1309 C104.699,83.9167 100.08,80.9873 96.7714,76.8265 C96.5615,76.5626 96.6158,76.1793 96.885,75.9763 C97.1542,75.7734 97.5364,75.8278 97.7466,76.0913 C100.883,80.0242 105.256,82.7931 110.165,83.9422 C115.074,85.0913 120.222,84.5508 124.778,82.4184 C125.083,82.2755 125.449,82.3964 125.601,82.6977 Z M132.778,78.6577 C133.047,78.3824 133.029,77.9402 132.746,77.6789 C132.462,77.4177 132.022,77.4363 131.752,77.7111 C130.665,78.8179 129.465,79.8078 128.171,80.6648 C127.85,80.8776 127.748,81.3066 127.951,81.6343 C128.153,81.962 128.584,82.0643 128.906,81.852 C130.306,80.9277 131.604,79.8569 132.778,78.6577 Z M93.4062,62.1623 C93.1614,62.0645 93.0016,61.8261 93.0079,61.5627 C93.1156,57.0289 94.0385,52.3612 96.596,48.629 C99.1207,44.9447 102.635,42.0545 106.728,40.2877 C107.037,40.1541 107.393,40.3067 107.519,40.6195 C107.644,40.9324 107.492,41.2871 107.183,41.4211 C103.314,43.0977 99.9914,45.8337 97.6031,49.3191 C95.2728,52.7197 94.439,57.379 94.2906,61.5789 C94.2748,62.0261 93.8217,62.3284 93.4062,62.1623 Z'
                id='Shape'
                fill='#7BBE40'
              ></path>
              <polyline
                id='Path'
                stroke='#FFFFFF'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                points='106 60.5 113.781 67.6875 127 55'
              ></polyline>
              <polyline
                id='Path'
                stroke='#7BBE40'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                points='106 61.5 113.781 68.6875 127 56'
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
