import * as React from 'react';

interface IAddToIconProps {}

const AddToIcon: React.FunctionComponent<IAddToIconProps> = (props) => {
  return (
    <svg
      width='10.6666667px'
      height='10.6666667px'
      viewBox='0 0 10.6666667 10.6666667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-11.1%'
          y='-10.5%'
          width='122.2%'
          height='129.4%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx={0} dy={5} in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation={5} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Administration_Investors_Create-New-Investor_Team_02'
          transform='translate(-1030.666667, -806.666667)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 192.000000)'>
              <g id='Select-Existing-Enitites' transform='translate(220.000000, 422.000000)'>
                <g id='Review-people' transform='translate(0.000000, 32.000000)'>
                  <g id='Action-Copy-4' transform='translate(502.000000, 24.000000)'>
                    <g
                      id='Group-26-Copy-2'
                      filter='url(#filter-1)'
                      transform='translate(0.000000, 28.000000)'
                    >
                      <g id='Group-20' transform='translate(4.000000, 42.000000)'>
                        <g
                          id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                          transform='translate(12.000000, 10.000000)'
                        >
                          <g id='Group' transform='translate(2.666667, 2.666667)'>
                            <path
                              d='M5.264,0.00414814815 L5.33333333,0 C5.63376286,0 5.88662753,0.224893103 5.92177778,0.523259259 L5.92592593,0.592592593 L5.92592593,4.74074074 L10.0740741,4.74074074 C10.3745036,4.74078037 10.6273683,4.96563384 10.6625185,5.264 L10.6666667,5.33333333 C10.6666667,5.63376286 10.4417736,5.88662753 10.1434074,5.92177778 L10.0740741,5.92592593 L5.92592593,5.92592593 L5.92592593,10.0740741 C5.92588629,10.3745036 5.70103282,10.6273683 5.40266667,10.6625185 L5.33333333,10.6666667 C5.0329038,10.6666667 4.78003914,10.4417736 4.74488889,10.1434074 L4.74074074,10.0740741 L4.74074074,5.92592593 L0.592592593,5.92592593 C0.292163064,5.92588629 0.0392984007,5.70103282 0.00414814815,5.40266667 L0,5.33333333 C0,5.0329038 0.224893103,4.78003914 0.523259259,4.74488889 L0.592592593,4.74074074 L4.74074074,4.74074074 L4.74074074,0.592592593 C4.74078037,0.292163064 4.96563384,0.0392984007 5.264,0.00414814815 L5.33333333,0 L5.264,0.00414814815 Z'
                              id='Path'
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddToIcon;
