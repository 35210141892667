import * as React from 'react';
interface IAccountantIconProps {}
const AccountantIcon: React.FunctionComponent<IAccountantIconProps> = (props) => {
  return (
    <svg
      width='38px'
      height='38px'
      viewBox='0 0 38 38'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ borderRadius: '9px' }}
    >
      <defs>
        <rect id='path-1' x={0} y={0} width={38} height={38} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Company_04'
          transform='translate(-555.000000, -1098.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Add-New-Entity' transform='translate(196.000000, 365.000000)'>
                <g
                  id='Collapse-First-name---Last-name'
                  transform='translate(24.000000, 251.000000)'
                >
                  <g id='Capacity' transform='translate(40.000000, 178.000000)'>
                    <g id='Op1' transform='translate(0.000000, 32.000000)'>
                      <g id='accountant' transform='translate(5.000000, 5.000000)'>
                        <mask id='mask-2' fill='white'>
                          <use xlinkHref='#path-1' />
                        </mask>
                        <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                        <g mask='url(#mask-2)'>
                          <g transform='translate(4.000000, 6.000000)'>
                            <path
                              d='M15.8839223,0 L14.1677413,0 C13.3434476,0 12.6125068,0.386061746 12.1567824,0.981498083 C12.0146227,1.16724772 11.8028035,1.28843498 11.5660644,1.31874742 C9.59526134,1.57099693 8.07417868,3.20305624 8.07417868,5.17992738 L8.07417868,14.2470972 C8.07417868,14.4281593 8.16288322,14.5983465 8.31272545,14.7067213 C10.1850755,16.0605936 12.508308,16.8623421 15.0250248,16.8623421 C17.2474802,16.8623421 19.3189312,16.2369683 21.0623563,15.1584079 C21.2324701,15.0531581 21.3355714,14.871096 21.3355714,14.6756588 L21.3355714,5.27773969 C21.3355714,2.36293288 18.8947763,0 15.8839223,0 L15.8839223,0 Z'
                              id='Path'
                              fill='#596C76'
                              fillRule='nonzero'
                            />
                            <path
                              d='M15.8839223,0 L14.1677413,0 C14.0728391,0 13.9794217,0.00568748889 13.8872955,0.0156249695 C16.7112503,0.214062082 18.9390641,2.49324513 18.9390641,5.27773969 L18.9390641,15.9256564 C18.9390641,16.0276562 18.9104643,16.125656 18.8594624,16.2113433 C19.6374025,15.9375939 20.3754449,15.5834071 21.0624208,15.1584079 C21.2325347,15.0531581 21.3356359,14.871096 21.3356359,14.6756588 L21.3356359,5.27773969 C21.3356359,2.36293288 18.8947763,0 15.8839223,0 L15.8839223,0 Z'
                              id='Path'
                              fill='#465A61'
                              fillRule='nonzero'
                            />
                            <path
                              d='M22.3543178,18.4759014 C22.0486939,17.6538405 21.3732095,17.0084043 20.5170881,16.7320298 C20.2776375,16.6547175 20.0381869,16.5822176 19.7987363,16.5145302 L9.61101382,16.5145302 C9.37156322,16.5822176 9.13211262,16.6547175 8.89259746,16.7320298 C8.03647603,17.0084043 7.36092707,17.6538405 7.05536771,18.4759014 L3.90609844,26.9476349 L7.90374202,26.9476349 L10.2414358,31.9999375 L18.6133622,31.9999375 L21.6982012,26.9476349 L25.5036516,26.9476349 L22.3543178,18.4759014 Z'
                              id='Path'
                              fill='#596C76'
                              fillRule='nonzero'
                            />
                            <path
                              d='M20.5451068,28.0627577 C20.277702,28.0627577 20.0609117,27.8528831 20.0609117,27.5940086 L20.0609117,21.9202697 C20.0609117,21.6613952 20.277702,21.4515206 20.5451068,21.4515206 C20.8125116,21.4515206 21.0293019,21.6613952 21.0293019,21.9202697 L21.0293019,27.5940086 C21.0293019,27.8528831 20.8125116,28.0627577 20.5451068,28.0627577 Z'
                              id='Path'
                              fill='#465A61'
                              fillRule='nonzero'
                            />
                            <path
                              d='M22.3543178,18.4759014 C22.0486939,17.6538405 21.3732095,17.0083418 20.5170881,16.7320298 C20.2776375,16.6547175 20.0381869,16.5822176 19.7987363,16.5145302 L19.253468,17.6334031 C20.1095895,17.9097775 20.7851384,18.5552138 21.0906978,19.3772747 L23.9049041,26.9475724 L25.5036516,26.9475724 L22.3543178,18.4759014 Z'
                              id='Path'
                              fill='#465A61'
                              fillRule='nonzero'
                            />
                            <path
                              d='M20.2263128,17.3907785 L19.7983489,16.5155927 C19.0906494,16.3155306 18.3507348,16.4907803 17.6429707,16.3739055 L11.7344351,16.0397812 C11.0267356,16.1566559 10.3190361,16.3155306 9.61127206,16.5155302 L9.18337274,17.390716 C9.07852837,17.6051531 9.14082813,17.8613401 9.33347321,18.0079648 L10.0327154,18.5400888 C10.2287176,18.689276 10.2894034,18.9512755 10.1779094,19.1671501 L9.84930236,19.8033363 C9.73645263,20.0217734 9.80004358,20.2870229 10.000694,20.4350851 L14.7048105,23.9058283 L19.4089269,20.4350851 C19.6095774,20.2870229 19.6731683,20.0217734 19.5603186,19.8033363 L19.2317115,19.1671501 C19.1202176,18.9512755 19.1808388,18.6892135 19.3769055,18.5400888 L20.0761477,18.0079648 C20.2688574,17.8614026 20.3311571,17.6052781 20.2263128,17.3907785 L20.2263128,17.3907785 Z'
                              id='Path'
                              fill='#7A8C98'
                              fillRule='nonzero'
                            />
                            <path
                              d='M20.2263128,17.3907785 L19.7983489,16.5155927 C19.0906494,16.3155306 18.3829499,16.1567184 17.6751858,16.0398437 L15.2131507,16.4370304 C15.2131507,16.64478 15.3714502,16.8209671 15.5841087,16.8485921 C16.7406893,16.9989043 18.0967583,17.2600913 19.2534035,17.6334031 C19.5408862,17.7262154 19.8073872,17.8614651 20.0469669,18.0302148 L20.0761477,18.0080273 C20.2688574,17.8614026 20.3311571,17.6052781 20.2263128,17.3907785 Z'
                              id='Path'
                              fill='#596C76'
                              fillRule='nonzero'
                            />
                            <path
                              d='M12.3802868,16.6659674 L12.212949,17.5707782 L14.4880202,24.8483265 C14.5529669,25.0560761 14.8567186,25.0561386 14.9216653,24.848389 C15.3933359,23.3396419 17.1968011,17.5707782 17.1968011,17.5707782 C17.1968011,17.5707782 16.9483767,16.9254044 17.0294633,16.6659674 L12.3802868,16.6659674 Z'
                              id='Path'
                              fill='#0ED2B3'
                              fillRule='nonzero'
                            />
                            <path
                              d='M16.2555904,15.4456573 L16.6763237,12.1507263 L13.1356311,12.2989135 L12.7333618,14.2669096 L12.7333618,15.5355322 C12.7333618,15.7445942 12.5728028,15.9196564 12.3587885,15.9475314 C12.1507783,15.9745938 11.9427036,16.0065312 11.7346934,16.0408437 L12.212949,17.5706532 C12.9835939,17.8686526 13.8244147,18.0328398 14.704875,18.0328398 C15.5853999,18.0328398 16.4261562,17.8686526 17.1968011,17.5706532 C17.3335378,17.1333415 17.451294,16.7565298 17.5407733,16.4703428 C17.5978437,16.2877182 17.3219816,16.3044057 17.3535512,16.2033434 C17.145541,16.1690934 16.8876264,16.131781 16.6795516,16.104656 C16.4655374,16.0769061 16.2555904,15.6547194 16.2555904,15.4456573 Z'
                              id='Path'
                              fill='#FFCEBF'
                              fillRule='nonzero'
                            />
                            <path
                              d='M17.050897,15.9476564 C16.8368828,15.9197814 16.6763237,15.7446567 16.6763237,15.5355947 L16.6763237,12.1507263 L12.7333618,12.1507263 L12.7333618,14.3840344 C12.7333618,15.0029707 13.2506113,15.5052197 13.8898779,15.5071572 C14.4102908,15.5087197 14.9096573,15.5095947 14.9096573,15.5095947 C15.0772533,15.5095947 15.2131507,15.641157 15.2131507,15.8034066 L15.2131507,16.4369679 C15.2131507,16.64478 15.3713857,16.8209046 15.5841087,16.8485296 C16.1355746,16.920217 16.7325549,17.0174043 17.3314719,17.139654 C17.4896423,16.633655 17.6110138,16.2454683 17.6749276,16.0409062 C17.4669819,16.0066562 17.2589717,15.9747188 17.050897,15.9476564 L17.050897,15.9476564 Z'
                              id='Path'
                              fill='#FFB09E'
                              fillRule='nonzero'
                            />
                            <path
                              d='M18.6772759,6.54104972 C18.2847551,6.56179968 17.8868759,6.56142468 17.4847358,6.53848723 C15.7223948,6.43898742 14.0882688,5.92117594 12.6788092,5.09130256 C12.5193476,4.99742774 12.3121767,5.06574011 12.2462616,5.23480228 C11.9691729,5.94523839 11.3658013,6.49323732 10.621884,6.71867438 C10.5142636,6.75129931 10.439633,6.84586163 10.439633,6.95504892 L10.439633,10.3288548 C10.439633,12.4077883 12.1804756,14.09316 14.3279776,14.09316 L15.081837,14.09316 C17.2292744,14.09316 18.9701817,12.4078508 18.9701817,10.3288548 L18.9701817,6.8096117 C18.9701817,6.6552995 18.8364793,6.53261224 18.6772759,6.54104972 L18.6772759,6.54104972 Z'
                              id='Path'
                              fill='#FFCEBF'
                              fillRule='nonzero'
                            />
                            <path
                              d='M23.8513844,21.6550827 C21.5515869,21.6550827 18.6132976,26.2844487 18.6132976,31.9999375 L24.8430159,31.9999375 C25.0545123,31.9999375 25.2272731,31.8362503 25.2317922,31.6315007 C25.3071976,28.2160699 26.4218146,25.2229507 27.7927322,23.4277667 C28.6473688,22.3086439 29.4398347,21.7782075 30.3039615,21.7782075 L23.8513844,21.6550827 Z'
                              id='Path'
                              fill='#FECE85'
                              fillRule='nonzero'
                            />
                            <path
                              d='M25.8907495,23.5410165 C24.5590193,25.347263 23.4886255,28.2869448 23.4147696,31.6315632 C23.4102504,31.8363128 23.2374896,32 23.0259932,32 L24.8430159,32 C25.0545123,32 25.2272731,31.8363128 25.2317922,31.6315632 C25.3025493,28.427882 26.2876603,25.5957 27.5409508,23.7752661 L25.8907495,23.5410165 Z'
                              id='Path'
                              fill='#FDB441'
                              fillRule='nonzero'
                            />
                            <path
                              d='M32,23.0767674 C32,23.6028289 31.7043828,24.0622655 31.2658312,24.308515 L24.5856178,24.308515 C25.024815,24.0622655 25.3197866,23.6028289 25.3197866,23.0767674 C25.3197866,22.291769 24.6622497,21.6550827 23.8513844,21.6550827 L30.5310167,21.6550827 C31.3418821,21.6550827 32,22.2918315 32,23.0767674 L32,23.0767674 Z'
                              id='Path'
                              fill='#E5EEF9'
                              fillRule='nonzero'
                            />
                            <path
                              d='M30.5310167,21.6550827 L28.9735226,21.6550827 C29.7843879,21.6550827 30.4425059,22.291769 30.4425059,23.0767674 C30.4425059,23.6028289 30.1468886,24.0622655 29.708337,24.308515 L31.2658312,24.308515 C31.7043828,24.0622655 32,23.6028289 32,23.0767674 C32,22.2918315 31.3418821,21.6550827 30.5310167,21.6550827 Z'
                              id='Path'
                              fill='#C4E2FF'
                              fillRule='nonzero'
                            />
                            <path
                              d='M26.2654519,30.3683157 L25.7017843,30.3683157 C24.6838771,30.3683157 23.8586796,29.5694422 23.8586796,28.5840067 L23.8586796,28.0108203 C23.8586796,27.0253847 24.6838771,26.2265113 25.7017843,26.2265113 L26.2654519,26.2265113 C26.708329,26.2265113 27.0673435,26.5740731 27.0673435,27.0028223 L27.0673435,29.5918797 C27.067408,30.0207539 26.708329,30.3683157 26.2654519,30.3683157 L26.2654519,30.3683157 Z'
                              id='Path'
                              fill='#FFCEBF'
                              fillRule='nonzero'
                            />
                            <path
                              d='M0,31.6096883 L0,20.6802721 C0,20.4647725 0.180443361,20.2900229 0.403108532,20.2900229 L8.55023927,20.2900229 L10.2415004,20.3525227 C10.464101,20.3525227 10.5800495,20.46471 10.5800495,20.6802721 L10.5800495,31.6097508 C10.5800495,31.8252503 10.4641655,31.9375001 10.2415004,31.9375001 L8.52815998,32 L0.403043973,32 C0.180443361,32 0,31.8252503 0,31.6096883 Z'
                              id='Path'
                              fill='#0ED2B3'
                              fillRule='nonzero'
                            />
                            <path
                              d='M10.2414358,20.2900229 L8.37922158,20.2900229 C8.60182219,20.2900229 8.78233011,20.46471 8.78233011,20.6802721 L8.78233011,31.6097508 C8.78233011,31.8252503 8.60188675,32 8.37922158,32 L10.2414358,32 C10.4640364,32 10.6445443,31.8253128 10.6445443,31.6097508 L10.6445443,20.6802721 C10.6445443,20.46471 10.464101,20.2900229 10.2414358,20.2900229 L10.2414358,20.2900229 Z'
                              id='Path'
                              fill='#0AB69F'
                              fillRule='nonzero'
                            />
                            <path
                              d='M1.84659086,22.7575181 L1.84659086,23.8837034 C1.84659086,24.0970779 2.02522656,24.2700151 2.24563216,24.2700151 L8.39891218,24.2700151 C8.61931777,24.2700151 8.79795347,24.0970779 8.79795347,23.8837034 L8.79795347,22.7575181 C8.79795347,22.5441435 8.61931777,22.3712063 8.39891218,22.3712063 L2.24563216,22.3712063 C2.02522656,22.3712063 1.84659086,22.5441435 1.84659086,22.7575181 Z'
                              id='Path'
                              fill='#E5EEF9'
                              fillRule='nonzero'
                            />
                            <g
                              id='Group'
                              strokeWidth={1}
                              fill='none'
                              fillRule='evenodd'
                              transform='translate(1.816571, 25.636387)'
                            >
                              <g fill='#596C76' fillRule='nonzero' id='Path'>
                                <path d='M1.04437648,1 L0.484195066,1 C0.216790271,1 0,0.776133333 0,0.5 C0,0.223866667 0.216790271,0 0.484195066,0 L1.04437648,0 C1.31178127,0 1.52857154,0.223866667 1.52857154,0.5 C1.52857154,0.776133333 1.31178127,1 1.04437648,1 Z' />
                                <path d='M3.78575982,1 L3.22557841,1 C2.95817362,1 2.74138335,0.776133333 2.74138335,0.5 C2.74138335,0.223866667 2.95817362,0 3.22557841,0 L3.78575982,0 C4.05316462,0 4.26995489,0.223866667 4.26995489,0.5 C4.26995489,0.776133333 4.05316462,1 3.78575982,1 L3.78575982,1 Z' />
                                <path d='M6.52720773,1 L5.96702632,1 C5.69962152,1 5.48283125,0.776133333 5.48283125,0.5 C5.48283125,0.223866667 5.69962152,0 5.96702632,0 L6.52720773,0 C6.79461252,0 7.01140279,0.223866667 7.01140279,0.5 C7.01140279,0.776133333 6.79461252,1 6.52720773,1 Z' />
                              </g>
                              <g
                                transform='translate(0.000000, 1.767184)'
                                fill='#596C76'
                                fillRule='nonzero'
                                id='Path'
                              >
                                <path d='M1.04437648,1 L0.484195066,1 C0.216790271,1 0,0.776133333 0,0.5 C0,0.223866667 0.216790271,0 0.484195066,0 L1.04437648,0 C1.31178127,0 1.52857154,0.223866667 1.52857154,0.5 C1.52857154,0.776133333 1.31178127,1 1.04437648,1 Z' />
                                <path d='M3.78575982,1 L3.22557841,1 C2.95817362,1 2.74138335,0.776133333 2.74138335,0.5 C2.74138335,0.223866667 2.95817362,0 3.22557841,0 L3.78575982,0 C4.05316462,0 4.26995489,0.223866667 4.26995489,0.5 C4.26995489,0.776133333 4.05316462,1 3.78575982,1 L3.78575982,1 Z' />
                                <path d='M6.52720773,1 L5.96702632,1 C5.69962152,1 5.48283125,0.776133333 5.48283125,0.5 C5.48283125,0.223866667 5.69962152,0 5.96702632,0 L6.52720773,0 C6.79461252,0 7.01140279,0.223866667 7.01140279,0.5 C7.01140279,0.776133333 6.79461252,1 6.52720773,1 Z' />
                              </g>
                              <g
                                transform='translate(0.000000, 3.534306)'
                                fill='#596C76'
                                fillRule='nonzero'
                                id='Path'
                              >
                                <path d='M1.04437648,1 L0.484195066,1 C0.216790271,1 0,0.776133333 0,0.5 C0,0.223866667 0.216790271,0 0.484195066,0 L1.04437648,0 C1.31178127,0 1.52857154,0.223866667 1.52857154,0.5 C1.52857154,0.776133333 1.31178127,1 1.04437648,1 Z' />
                                <path d='M3.78575982,1 L3.22557841,1 C2.95817362,1 2.74138335,0.776133333 2.74138335,0.5 C2.74138335,0.223866667 2.95817362,0 3.22557841,0 L3.78575982,0 C4.05316462,0 4.26995489,0.223866667 4.26995489,0.5 C4.26995489,0.776133333 4.05316462,1 3.78575982,1 L3.78575982,1 Z' />
                                <path d='M6.52720773,1 L5.96702632,1 C5.69962152,1 5.48283125,0.776133333 5.48283125,0.5 C5.48283125,0.223866667 5.69962152,0 5.96702632,0 L6.52720773,0 C6.79461252,0 7.01140279,0.223866667 7.01140279,0.5 C7.01140279,0.776133333 6.79461252,1 6.52720773,1 Z' />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default AccountantIcon;
