import { FC, SVGProps } from 'react';

const ContactIcon: FC<SVGProps<SVGSVGElement>> = ({ color, ...props }) => {
  return (
    <svg
      width='16.0000002px'
      height='19.997px'
      viewBox='0 0 16.0000002 19.997'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Administration_15'
          transform='translate(-565.000000, -534.000000)'
          fill={color || '#1C1C1C'}
          fillRule='nonzero'
        >
          <g id='Pop-up-Copy' transform='translate(289.000000, 70.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 180.000000)'>
              <g id='2.1-Primary-contact-Copy-2' transform='translate(248.000000, 258.000000)'>
                <g id='Group-9' transform='translate(24.000000, 24.000000)'>
                  <g id='Group' transform='translate(4.000000, 2.000000)'>
                    <path
                      d='M13.7500004,11.996 C14.3466456,11.9957347 14.9189445,12.2325651 15.3409301,12.6543632 C15.7629157,13.0761613 16.0000002,13.6483548 16.0000002,14.245 L16.0000002,14.82 C16.0000002,15.714 15.6800004,16.58 15.0980004,17.258 C13.5280004,19.092 11.1410004,19.997 7.99600044,19.997 C4.85000044,19.997 2.46400044,19.092 0.898000445,17.257 C0.318385515,16.5780885 2.22269511e-07,15.7146781 2.22269511e-07,14.822 L2.22269511e-07,14.245 C-0.000264848635,13.6483549 0.236565563,13.076056 0.658363636,12.6540704 C1.08016171,12.2320847 1.65235526,11.995 2.24900044,11.995 L13.7500004,11.995 L13.7500004,11.996 Z M13.7500004,13.496 L2.24900044,13.496 C2.05018009,13.4957346 1.859412,13.5745296 1.7187309,13.7150232 C1.5780498,13.8555167 1.49900044,14.0461795 1.49900044,14.245 L1.49900044,14.822 C1.49900044,15.358 1.69100044,15.876 2.03900044,16.283 C3.29200044,17.751 5.25800044,18.497 7.99600044,18.497 C10.7340004,18.497 12.7020004,17.751 13.9580004,16.283 C14.3070111,15.875442 14.4990004,15.3565743 14.4990004,14.82 L14.4990004,14.245 C14.4992659,14.0461796 14.4204708,13.8554116 14.2799773,13.7147305 C14.1394838,13.5740494 13.948821,13.495 13.7500004,13.495 L13.7500004,13.496 Z M7.99600044,0 C10.7574242,0 12.9960004,2.23857625 12.9960004,5 C12.9960004,7.76142375 10.7574242,10 7.99600044,10 C5.2345767,10 2.99600044,7.76142375 2.99600044,5 C2.99600044,2.23857625 5.2345767,0 7.99600044,0 L7.99600044,0 Z M7.99600039,1.5 C6.74557087,1.5 5.59012628,2.16709621 4.96491149,3.24999998 C4.33969669,4.33290376 4.33969669,5.66709624 4.96491149,6.75000002 C5.59012628,7.83290379 6.74557087,8.5 7.99600039,8.5 C9.92899707,8.5 11.4960004,6.93299662 11.4960004,5 C11.4960004,3.06700338 9.92899707,1.5 7.99600039,1.5 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ContactIcon;
