/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  INVESTMENT_ENTITY_ROLE,
  InvestorUserRoleOptions,
  TeamType,
  createTeamContactDefaultValues,
} from 'src/constants/applications';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { ITeamContact } from 'src/modules/applications/type';
import { useCountry } from 'src/modules/common/hooks';
import { useGetUserRoles } from 'src/modules/users-management/hooks';
import { Role } from 'src/modules/users-management/type';
import { createTeamContactSchema } from 'src/validations/applications';

interface ITeamUpsertContactProps {
  isEditMode?: boolean;
  isViewMode?: boolean;
  isPrimaryContactGotLimitation?: boolean;
  isTeamGotLimitation?: boolean;
  isCLGotLimitation?: boolean;
  user?: ITeamContact;
}

export interface TeamUpsertContactHandler {
  onCreate: (callback: (data: ITeamContact) => void) => void;
}

const TeamUpsertContact = forwardRef<TeamUpsertContactHandler, ITeamUpsertContactProps>(
  (
    {
      isEditMode,
      isViewMode,
      user,
      isPrimaryContactGotLimitation,
      isTeamGotLimitation,
      isCLGotLimitation,
    },
    ref,
  ) => {
    const { parsePhoneNumber } = useCountry();
    const [checked, setChecked] = useState<number[]>([]);
    const { data: userRoles = [] } = useGetUserRoles();
    const isAllowRole = checked.includes(TeamType.Team);
    const { notUpdate } = user || {};

    const form = useForm({
      resolver: yupResolver(createTeamContactSchema),
      defaultValues: createTeamContactDefaultValues,
    });
    const watchUserType = form.watch('userType');
    const watchRoleName = form.watch('roleName');

    useEffect(() => {
      if (isAllowRole && watchUserType === INVESTMENT_ENTITY_ROLE.Investor) {
        const fineSuperAdmin = (userRoles || []).find((f: Role) => f.isSuperAdmin);
        fineSuperAdmin?.id && form.setValue('roleId', fineSuperAdmin?.id, { shouldValidate: true });
      }
    }, [watchUserType, isAllowRole, userRoles]);

    useEffect(() => {
      if (isEditMode || isViewMode) {
        form.reset(user || {});
        setChecked(user?.teamTypes || []);
      }
    }, [user]);

    useEffect(() => {
      form.setValue('teamTypes', checked, { shouldValidate: true });
    }, [checked]);

    useImperativeHandle(ref, () => ({
      onCreate: (callback: (data: ITeamContact) => void) =>
        form.handleSubmit((data) => onSubmit(data, callback))(),
    }));

    const onSubmit = (data: ITeamContact, callback: (data: ITeamContact) => void) => {
      callback(data);
    };

    const handleChange = (teamType: number) => {
      const newChecked = [...checked];
      const itemIndex = newChecked.findIndex((f) => f === teamType);
      if (itemIndex !== -1) {
        newChecked.splice(itemIndex, 1);
      } else {
        newChecked.push(teamType);
      }
      setChecked(newChecked);
    };

    const userRolesFormat = useMemo(() => {
      return userRoles
        ?.map((item: Role) => {
          return {
            label: item.name,
            value: item?.id,
          };
        })
        .sort((a: { label: string }, b: { label: string }) => a.label?.localeCompare(b.label));
    }, [userRoles]);

    const disabledRole =
      isViewMode || watchUserType === INVESTMENT_ENTITY_ROLE.Investor || notUpdate;

    return (
      <FormProvider {...form}>
        <Box className='pt-12' component='form'>
          <Grid container rowSpacing={4} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant='body3'>Add to</Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={checked.indexOf(TeamType.PrimaryContact) !== -1}
                      onChange={() => handleChange(TeamType.PrimaryContact)}
                      disabled={isPrimaryContactGotLimitation || isViewMode}
                    />
                  }
                  label='Primary Contact'
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={checked.indexOf(TeamType.Team) !== -1}
                      onChange={() => handleChange(TeamType.Team)}
                      disabled={isTeamGotLimitation || isViewMode}
                    />
                  }
                  label='Team'
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={checked.indexOf(TeamType.CommunicationList) !== -1}
                      onChange={() => handleChange(TeamType.CommunicationList)}
                      disabled={isCLGotLimitation || isViewMode}
                    />
                  }
                  label='Communication List'
                />
              </FormGroup>
              {form.formState.errors && (
                <CustomHelperText
                  variant='error'
                  message={form.formState.errors['teamTypes']?.message as string}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRadioGroup
                containerSx={{
                  '.MuiStack-root': {
                    ml: '2px',
                  },
                }}
                name='userType'
                label='User Type'
                isEnumValue
                disabled={isViewMode || checked.indexOf(TeamType.Team) === -1 || notUpdate} // If users choose the "Add to Primary Contact" or "Add to Communication List" option
                options={InvestorUserRoleOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='firstName'
                label='First Name'
                size='small'
                placeholder='Enter first name'
                className='w-full'
                disabled={isEditMode || isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='lastName'
                label='Last Name'
                size='small'
                placeholder='Enter last name'
                className='w-full'
                disabled={isEditMode || isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='email'
                label='Email Address'
                size='small'
                placeholder='Enter email address'
                className='w-full'
                disabled={isViewMode || notUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormPhoneCountryInput
                name='phoneNumber'
                label='Contact Number'
                size='small'
                className='w-full'
                countryFlagCodeName='countryFlagCode'
                disabled={isViewMode || notUpdate}
                defaultValue={DEFAULT_COUNTRY}
                isIncludeCode
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name='position'
                label='Position'
                size='small'
                placeholder='Enter position'
                className='w-full'
                disabled={isViewMode || notUpdate}
              />
            </Grid>
            {isAllowRole && (
              <Grid item xs={12} md={6}>
                {watchRoleName && disabledRole ? (
                  <FormInput
                    name='roleName'
                    label='Role'
                    size='small'
                    className='w-full'
                    disabled
                  />
                ) : (
                  <FormSelect
                    name='roleId'
                    label='Role'
                    size='small'
                    placeholder='Select role'
                    className='w-full'
                    options={userRolesFormat}
                    disabled={disabledRole}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </FormProvider>
    );
  },
);

export default TeamUpsertContact;
