import { Box, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import MessageWithDotIcon from 'src/assets/icons/MessageWithDotIcon';
import { CustomInfiniteScroll } from 'src/components/atoms/CustomInfiniteScroll';
import { INotificationItem } from 'src/modules/notifications/type';
import { NotificationContext } from 'src/providers/NotificationContextProvider';
import NotificationItem from './NotificationItem';

interface INotificationListProps {
  notifications: INotificationItem[];
}

const NotificationList: FC<INotificationListProps> = ({ notifications }) => {
  const { handleLoadMoreData, hasMore } = useContext(NotificationContext);

  return (
    <>
      {notifications?.length ? (
        <CustomInfiniteScroll
          dataLength={notifications.length}
          next={() => {
            handleLoadMoreData();
          }}
          height={450}
          hasMore={hasMore}
          loader={<Typography variant='body3'>...Loading</Typography>}
          className='flex flex-col gap-6 h-full overflow-auto px-6'
        >
          {notifications.map((item, index) => (
            <Box className='hover:cursor-pointer' key={item.id}>
              <NotificationItem notification={item} />
            </Box>
          ))}
        </CustomInfiniteScroll>
      ) : (
        <Box className='flex flex-col items-center pt-11.5'>
          <MessageWithDotIcon />
          <Typography className='mt-2' variant='body3' color='neutral.ne500'>
            No Notifications.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NotificationList;
