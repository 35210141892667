import * as React from 'react';

interface ISelectedIconProps {
  color?: string;
}

const SelectedIcon: React.FunctionComponent<ISelectedIconProps> = ({ color }) => {
  return (
    <svg
      width='12px'
      height='8.66666667px'
      viewBox='0 0 12 8.66666667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Branding_00'
          transform='translate(-870.000000, -830.000000)'
          fill={color || '#FFFFFF'}
          fillRule='nonzero'
        >
          <g id='Navigation' transform='translate(556.000000, 694.000000)'>
            <g id='Group-4' transform='translate(306.000000, 92.000000)'>
              <g id='Group-Copy-3' transform='translate(0.000000, 34.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Fluent/Line/Checkmark'
                  transform='translate(6.000000, 6.000000)'
                >
                  <g id='Group' transform='translate(2.000000, 4.000000)'>
                    <path
                      d='M3.7682945,7.05592672 L1.16372251,4.52482601 C0.894284314,4.27193467 0.465998817,4.27555137 0.201123666,4.53295477 C-0.0637514852,4.79035817 -0.0674731705,5.20656234 0.192758942,5.46840007 L3.28281272,8.47128781 C3.55096061,8.73179295 3.98562838,8.73179295 4.25377628,8.47128781 L11.8072411,1.13089555 C12.0674732,0.869057824 12.0637515,0.452853652 11.7988763,0.195450253 C11.5340012,-0.0619531464 11.1057157,-0.0655698481 10.8362775,0.187321486 L3.7682945,7.05592672 Z'
                      id='Path'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SelectedIcon;
