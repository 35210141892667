import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomCheckbox } from 'src/components/atoms/CustomCheckbox';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import CustomScrollbarContainer from 'src/components/atoms/CustomScrollbarContainer';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import { infoChangesOptions } from 'src/constants/investor-form';
import { IInvestorFormFund, IInvestorFormUnitClass } from 'src/modules/investor-form/type';
import { IRedemptionFormFieldsProps } from './RedemptionFormFields';

interface IChangeOfDetailsFormFieldsProps extends IRedemptionFormFieldsProps {
  fundsData?: {
    fundId: string;
    unitClassIds: string[];
  }[];
  infoChangesData?: number[];
}

const ChangeOfDetailsFormFields: FC<IChangeOfDetailsFormFieldsProps> = ({
  investmentEntityOptions,
  getFundOptions,
  fundsData,
  infoChangesData,
  isViewMode,
  onGenerateFormId,
}) => {
  const fundOptions = getFundOptions(true) as IInvestorFormFund[];
  const [infoChanges, setInfoChanges] = useState<number[]>([]);
  const [selectedFunds, setSelectedFunds] = useState<
    {
      fundId: string;
      unitClassIds: string[];
    }[]
  >([]);
  const selectedFundIds = selectedFunds.map((it) => it?.fundId);
  const selectedUnitClassIds = selectedFunds.reduce((prev: string[], current) => {
    return [...prev, ...current.unitClassIds];
  }, []);
  const form = useFormContext();
  const fundsFieldState = form.getFieldState('funds');
  const infoChangesState = form.getFieldState('infoChanges');

  useEffect(() => {
    form.setValue('funds', selectedFunds);
  }, [selectedFunds]);

  useEffect(() => {
    form.setValue('infoChanges', infoChanges);
  }, [infoChanges]);

  useEffect(() => {
    if (infoChangesData) {
      setInfoChanges(infoChangesData);
    }
  }, [infoChangesData]);

  useEffect(() => {
    if (fundsData) {
      setSelectedFunds(fundsData);
    }
  }, [fundsData]);

  const handleSelectedFund = (checked: boolean, fund: IInvestorFormFund) => {
    const newSelectedFunds = cloneDeep(selectedFunds);
    if (checked) {
      setSelectedFunds([
        ...newSelectedFunds,
        {
          fundId: fund.id,
          unitClassIds: fund.unitClasses.map((it) => it.id),
        },
      ]);
    } else {
      setSelectedFunds(newSelectedFunds.filter((it) => it.fundId !== fund.id));
    }
  };

  const handleSelectUnitClass = (
    checked: boolean,
    fund: IInvestorFormFund,
    unitClass: IInvestorFormUnitClass,
  ) => {
    let newSelectedFunds = cloneDeep(selectedFunds);
    const selectedFund = newSelectedFunds.find((it) => it.fundId === fund.id);

    if (checked) {
      /**
       * Select unit class, If fund is selected before, only push unit class to selected unit class list
       * If fund is have not selected, push fund and unit class to selected list
       */
      if (selectedFund) {
        selectedFund.unitClassIds.push(unitClass.id);
      } else {
        newSelectedFunds = [...newSelectedFunds, { fundId: fund.id, unitClassIds: [unitClass.id] }];
      }
    } else if (selectedFund) {
      /**
       * Update new unit class list
       * Unselect unit class, If unit class list is empty after unselect then auto unselect fund
       */
      const newSelectedUnitClassIds = selectedFund.unitClassIds.filter((it) => it !== unitClass.id);
      if (newSelectedUnitClassIds.length) {
        selectedFund.unitClassIds = newSelectedUnitClassIds;
      } else {
        newSelectedFunds = newSelectedFunds.filter((it) => it.fundId !== fund.id);
      }
    }

    setSelectedFunds(newSelectedFunds);
  };

  const handleSelectInfoChanges = (checked: boolean, value: number) => {
    const newInfoChanges = cloneDeep(infoChanges);
    if (checked) {
      setInfoChanges([...newInfoChanges, value]);
    } else {
      setInfoChanges(newInfoChanges.filter((it) => it !== value));
    }
  };

  const handleInvestmentEntityChange = (value: string | number) => {
    onGenerateFormId(value as string);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput name='formId' label='Change of Details ID' disabled />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='investmentEntityId'
            label='Investment Entity'
            placeholder='Select investment entity'
            options={investmentEntityOptions}
            disabled={isViewMode}
            onSelectValue={(value) => {
              setSelectedFunds([]);
              handleInvestmentEntityChange(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant='body3'>Fund</Typography>
            <CustomScrollbarContainer
              className='mt-2 p-6 rounded-xl max-h-[300px] overflow-auto'
              bgcolor='neutral.ne100'
              component='ul'
              scrollbarTrackProps={{
                marginTop: 0,
                marginBottom: 300,
              }}
            >
              {fundOptions.map((fund, index) => (
                <Box key={index} component='li'>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        checked={selectedFundIds.includes(fund.id)}
                        onChange={(e, checked) => handleSelectedFund(checked, fund)}
                        disabled={isViewMode}
                      />
                    }
                    label={<Typography variant='body2'>{fund.name}</Typography>}
                  />
                  <Box className='pl-8' component='ul'>
                    {fund.unitClasses.map((unitClass, index) => (
                      <Box component='li' key={index}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={selectedUnitClassIds.includes(unitClass.id)}
                              onChange={(e, checked) =>
                                handleSelectUnitClass(checked, fund, unitClass)
                              }
                              disabled={isViewMode}
                            />
                          }
                          label={<Typography variant='body2'>{unitClass.name}</Typography>}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </CustomScrollbarContainer>
            {fundsFieldState?.error?.message && (
              <CustomHelperText
                className='mt-2'
                variant='error'
                message={fundsFieldState?.error?.message}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Box className='pt-8'>
        <Typography className='pb-6' variant='body2' fontWeight={600}>
          Check the box for the information you want to update
        </Typography>
        <Grid container>
          {infoChangesOptions.map((it, index) => (
            <Grid key={index} item xs={6}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={infoChanges.includes(it.value)}
                    onChange={(e, checked) => handleSelectInfoChanges(checked, it.value)}
                    disabled={isViewMode}
                  />
                }
                label={<Typography variant='body2'>{it.label}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
        {infoChangesState?.error?.message && (
          <CustomHelperText
            className='mt-2'
            variant='error'
            message={infoChangesState?.error?.message}
          />
        )}
      </Box>
      <Box className='pt-10'>
        <Typography
          className='px-4 py-2 mb-6'
          bgcolor='neutral.ne100'
          variant='body1'
          fontWeight={600}
        >
          Current Details
        </Typography>
        <FormInput
          name='currentDetails'
          label=''
          placeholder='Enter current details'
          labelClass='text-[16px] font-[500]'
          multiline
          rows={8}
          maxLength={2500}
          isShowCharactersLeft
          disabled={isViewMode}
        />
      </Box>
      <Box className='pt-10'>
        <Typography
          className='px-4 py-2 mb-6'
          bgcolor='neutral.ne100'
          variant='body1'
          fontWeight={600}
        >
          Updated Details
        </Typography>
        <FormInput
          name='updatedDetails'
          label=''
          placeholder='Enter new details'
          labelClass='text-[16px] font-[500]'
          multiline
          rows={8}
          maxLength={2500}
          isShowCharactersLeft
          disabled={isViewMode}
        />
      </Box>
    </>
  );
};

export default ChangeOfDetailsFormFields;
