import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Association from 'src/components/pages/applications/components/InvestmentEntity/Other/Association';
import GovernmentBody from 'src/components/pages/applications/components/InvestmentEntity/Other/GovernmentBody';
import { INVESTMENT_ENTITY_OTHER } from 'src/constants/applications';

interface IOtherProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const Other: FC<IOtherProps> = (props) => {
  const { watch } = useFormContext();
  const watchOtherType = watch('otherType');

  return (
    <>
      {watchOtherType === INVESTMENT_ENTITY_OTHER.GovernmentBody && <GovernmentBody {...props} />}
      {watchOtherType === INVESTMENT_ENTITY_OTHER.Association && <Association {...props} />}
    </>
  );
};

export default Other;
