import * as React from 'react';

interface IArrowCounterClockWisePrimaryProps {}

const ArrowCounterClockWisePrimary: React.FunctionComponent<
  IArrowCounterClockWisePrimaryProps
> = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Global-Admin-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Profile_01'
          transform='translate(-1257.000000, -486.000000)'
          fill='#003E4E'
          fillRule='nonzero'
        >
          <g id='Group-3-Copy' transform='translate(654.000000, 0.000000)'>
            <g
              id='02.Atoms/02.Button/01.Large/02.With-Icon/Left/01.Primary/01.Default-Copy'
              transform='translate(591.000000, 478.000000)'
            >
              <g
                id='01.Design-Token/06.Icons/Line/plus-outline'
                transform='translate(12.000000, 8.000000)'
              >
                <g id='Group' transform='translate(3.000000, 3.000000)'>
                  <path
                    d='M9.00190134,1.49877812 C12.1367379,1.49931972 14.9405386,3.44950811 16.0316486,6.38833095 C17.1227586,9.32715379 16.270972,12.6345686 13.8960315,14.68075 C11.521091,16.7269314 8.12406934,17.0801693 5.37894831,15.5663954 C2.63382728,14.0526216 1.11978152,10.9912169 1.58290134,7.89077812 C1.64990134,7.43677812 1.31790134,6.99877812 0.858901343,6.99877812 C0.488704827,6.99202896 0.169126224,7.25678624 0.106901343,7.62177812 C-0.563332043,11.9468311 1.9750063,16.1270269 6.12170364,17.5270974 C10.268401,18.9271678 14.8211365,17.1411671 16.9097863,13.2950212 C18.9984361,9.44887537 18.0174033,4.65776134 14.5851481,1.94208093 C11.1528929,-0.773599483 6.26458602,-0.626443946 3.00190134,2.29077812 L3.00190134,1.24877812 C3.00190134,0.83456456 2.66611491,0.498778122 2.25190134,0.498778122 C1.83768778,0.498778122 1.50190134,0.83456456 1.50190134,1.24877812 L1.50190134,4.24877812 C1.50190134,4.66277812 1.83790134,4.99877812 2.25190134,4.99877812 L5.25190134,4.99877812 C5.66611491,4.99877812 6.00190134,4.66299168 6.00190134,4.24877812 C6.00190134,3.83456456 5.66611491,3.49877812 5.25190134,3.49877812 L3.90190134,3.49877812 C5.28745283,2.21058329 7.11002654,1.4958485 9.00190134,1.49877812 Z'
                    id='Path'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowCounterClockWisePrimary;
