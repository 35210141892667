/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import UserSelectionItem from 'src/components/molecules/UserSelectionItem';
import { IDirector, IFinancialTaxDetailsForm } from 'src/modules/applications/type';

interface IDirectorSelectionBoxProps {
  directors?: IDirector[];
  disabled?: boolean;
  selected: string;
  onSelect?: (item: IDirector) => void;
}

const DirectorSelectionBox: React.FC<IDirectorSelectionBoxProps> = ({
  directors,
  disabled,
  selected,
  onSelect,
}) => {
  const { getFieldState } = useFormContext<IFinancialTaxDetailsForm>();
  const directorState = getFieldState('declarationDirectorId');
  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box className='flex items-center'>
          <Typography variant='body2'>
            Please select person who is making this declaration
          </Typography>
        </Box>
      </Box>
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] border overflow-y-auto'
        bgcolor='white'
        borderColor='neutral.ne400'
      >
        <Grid container spacing={2}>
          {(directors || []).map((item: IDirector, index: number) => {
            const isSelected = item.id === selected;
            return (
              <UserSelectionItem
                key={`user-${index}`}
                isSelected={isSelected}
                name={item.fullName}
                onSelect={() => onSelect?.(item)}
                description={item?.position || 'Director'}
                disabled={disabled}
              />
            );
          })}
        </Grid>
      </Box>
      {directorState?.error && (
        <Box pt={1}>
          <CustomHelperText message={directorState.error?.message} variant='error' />
        </Box>
      )}
    </Box>
  );
};

export default DirectorSelectionBox;
