/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
import { Divider, IconButton, StepButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { isInteger } from 'lodash';
import * as React from 'react';
import ActiveStepIcon from 'src/assets/icons/ActiveStepIcon';
import { ArrowLeftIcon } from 'src/assets/icons/ArrowLeftIcon';
import CompleteStepIcon from 'src/assets/icons/CompletedStepIcon';
import ErrorStepIcon from 'src/assets/icons/ErrorStepIcon';
import InActiveStepIcon from 'src/assets/icons/InActiveStepIcon';
import IosArrowRightIcon from 'src/assets/icons/IosArrowRightIcon';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomButton from './CustomButton';

export interface IStep {
  label: string;
  subLabel?: React.ReactNode;
  key: string | number;
  content: JSX.Element;
}

interface ICustomStepperProps {
  steps: IStep[];
  StepperStyles?: any;
  errorSteps?: number[];
  enableClickStep?: boolean;
  destroyInactiveStep?: boolean;
  customHeader?: React.ReactNode;
  showErrorAllStep?: boolean;
  onClickStep?: (currentStep: number) => void;
  onStepChange?: (step: number) => void;
  numItemsOnStep?: number;
  customBackFunc?: (currentStep: number) => void;
}

export interface ICustomStepperElement {
  back: () => void;
  next: () => void;
  getCurrentStep: () => number;
  setCurrentStep: (step: number) => void;
}

export const CustomStepper = React.forwardRef<ICustomStepperElement, ICustomStepperProps>(
  (
    {
      steps,
      StepperStyles,
      errorSteps = [],
      enableClickStep = false,
      destroyInactiveStep = true,
      customHeader,
      showErrorAllStep,
      onClickStep,
      onStepChange,
      numItemsOnStep = 5,
      customBackFunc, // Allow user to custom their back function
    },
    ref,
  ): JSX.Element => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [swiperElement, setSwiperElement] = React.useState<any>({});
    const [currentSlide, setCurrentSlide] = React.useState<number>(0);

    const handleNext = () => {
      if (activeStep === steps.length - 1) return;
      swiperElement?.slideTo(Math.floor((activeStep + 1) / numItemsOnStep));
      setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
      if (!activeStep) return;
      const currentSlide = isInteger(activeStep / numItemsOnStep)
        ? activeStep / numItemsOnStep - 1
        : Math.floor(activeStep / numItemsOnStep);
      swiperElement?.slideTo(currentSlide);
      setActiveStep(activeStep - 1);
    };

    const handleStep = (step: number) => () => {
      setActiveStep(step);
      onClickStep && onClickStep(step);
    };

    React.useImperativeHandle(ref, () => ({
      back() {
        handleBack();
      },
      next() {
        handleNext();
      },
      getCurrentStep() {
        return activeStep;
      },
      setCurrentStep(step: number) {
        setActiveStep(step);
      },
    }));

    React.useEffect(() => {
      onStepChange && onStepChange(activeStep);
    }, [activeStep]);

    const stepIconComponent = (index: number) => {
      if (showErrorAllStep && errorSteps.includes(index)) {
        return <ErrorStepIcon />;
      }
      if (activeStep > index) {
        if (errorSteps.includes(index)) return <ErrorStepIcon />;

        return <CompleteStepIcon />;
      }

      return activeStep === index ? <ActiveStepIcon /> : <InActiveStepIcon />;
    };

    const slideSteps = React.useMemo(() => {
      const result = [];
      const clonedSteps = [...steps];
      while (clonedSteps.length) {
        result.push(clonedSteps.splice(0, numItemsOnStep));
      }
      return result;
    }, [steps]);

    const isShowNextStep = steps.length > numItemsOnStep && currentSlide + 1 < slideSteps.length;
    const isShowPrevStep = currentSlide > 0;

    return (
      <Box className='flex flex-col w-full'>
        <Box className='w-full flex items-center px-[30px]'>
          {isShowPrevStep && (
            <IconButton onClick={() => swiperElement?.slidePrev()} className='rotate-180'>
              <IosArrowRightIcon width='30px' height='30px' />
            </IconButton>
          )}
          <Swiper
            onInit={(swiper) => setSwiperElement(swiper)}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {slideSteps.map((slide, index) => (
              <SwiperSlide key={`swiper-slide-${index}`}>
                <Stepper
                  nonLinear={enableClickStep}
                  activeStep={activeStep}
                  sx={{
                    justifyContent: 'center',
                    '.MuiStepConnector-horizontal': {
                      width: '24px',
                      flex: 'unset',
                    },
                    '.MuiStepLabel-label': {
                      whiteSpace: 'nowrap',
                    },
                    ...StepperStyles,
                  }}
                >
                  {slide.map(({ label, subLabel, key: stepKey }: IStep) => {
                    const stepProps: { completed?: boolean } = {};
                    const index = steps?.findIndex((f) => f.key === stepKey);
                    return (
                      <Step key={label} {...stepProps}>
                        <StepButton onClick={handleStep(index)} disabled={!enableClickStep}>
                          <StepLabel StepIconComponent={() => stepIconComponent(index)}>
                            <Box className='ml-3 flex flex-col items-start'>
                              <Typography
                                variant='body2'
                                color={
                                  errorSteps.includes(index) &&
                                  (activeStep > index || showErrorAllStep)
                                    ? 'error'
                                    : 'neutral.ne500'
                                }
                              >
                                {label}
                              </Typography>
                              {subLabel}
                            </Box>
                          </StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
              </SwiperSlide>
            ))}
          </Swiper>
          {isShowNextStep && (
            <IconButton onClick={() => swiperElement?.slideNext()}>
              <IosArrowRightIcon width='30px' height='30px' />
            </IconButton>
          )}
        </Box>
        {customHeader && customHeader}
        <Box className='pt-6 pb-10'>
          <Divider />
        </Box>
        <Box>
          <Box className='pl-[58px]'>
            {activeStep !== 0 && (
              <CustomButton
                startIcon={<ArrowLeftIcon />}
                variant='text'
                sx={(theme) => ({
                  color: theme.palette.neutral.ne800,
                })}
                onClick={customBackFunc ? () => customBackFunc(activeStep) : handleBack}
              >
                Back
              </CustomButton>
            )}
          </Box>
          {destroyInactiveStep
            ? steps[activeStep].content
            : steps.map((step, index) => (
                <Box key={index} hidden={step.key !== activeStep}>
                  {step.content}
                </Box>
              ))}
        </Box>
      </Box>
    );
  },
);
