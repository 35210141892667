import { detectPortalType } from 'src/helpers/common';
import { INVESTOR_FORM_TYPE } from './investor-form';

const { isAdmin } = detectPortalType();
export const AUTH_API = {
  login: {
    api: '/Account/login',
  },
  logout: {
    api: '/Account/logout',
  },
  mfaAuthenticator: {
    api: '/Account/mfa-authenticator',
  },
  requestSMSAuthenticator: {
    api: '/Account/sms-authenticator/request',
  },
  verificationSMSAuthenticator: {
    api: '/Account/sms-authenticator/verification',
  },
  phoneNumber: {
    api: '/Account/sms-authenticator/infor',
  },
  signUp: {
    api: '/Account/signup',
  },
  forgotPassword: {
    api: '/Account/forgot-password',
  },
  resetPassword: {
    api: '/Account/reset-password',
  },
  resetPasswordInfo: {
    api: '/Account/reset-password-info',
  },
  changePassword: {
    api: '/Account/change-password',
  },
  investorSignup: {
    api: '/Account/register',
  },
  investorVerifyEmail: {
    api: '/Account/verify-email',
  },
  investorResendVerifyEmail: {
    api: '/Account/resend-verify-email',
  },
  refreshToken: {
    api: '/Account/refresh-token',
  },
};

export const USER_MANAGEMENT_API = {
  userRoles: {
    api: '/User/roles',
  },
  userClients: {
    api: '/User/clients',
  },
  userFunds: {
    api: '/User/funds',
  },
  userFilters: {
    api: '/User/filters',
  },
  userList: {
    api: '/User/get-list',
  },
  clientUserList: {
    api: '/User/client-get-list',
  },
  user: {
    api: '/User',
  },
  userById: {
    api: (userId: string) => `/User/${userId}`,
  },
  userStatusById: {
    api: (userId: string) => `/User/${userId}/status`,
  },
  userContactOnboardingStatusById: {
    api: '/User/onboarding/status',
  },
  userResendInvitationById: {
    api: (userId: string) => `/User/${userId}/resend-invitation`,
  },
  userResetPasswordById: {
    api: (userId: string) => `/User/${userId}/send-reset-password`,
  },
  userReset2FAById: {
    api: (userId: string) => `/User/${userId}/reset-authenticator`,
  },
  investorUserEntities: {
    api: '/Profile/entities',
  },
  delegateInvestmentEntity: {
    api: '/InvestmentEntity/delegate',
  },
};
export const CLIENT_API = {
  capacity: {
    api: '/Capacity',
  },
  createClient: {
    api: '/Client',
  },
  getClientList: {
    api: '/Client/get-list',
  },
  getClientDetail: {
    api(id: string) {
      return `/Client/${id}`;
    },
  },
  getAdministrators: {
    api(clientId: string) {
      return `/Client/${clientId}/administrator`;
    },
  },
  updateClient: {
    api(clientId: string) {
      return `/Client/${clientId}`;
    },
  },
  filters: {
    api: '/Client/filters',
  },
  deleteClient: {
    api(clientId: string) {
      return `/Client/${clientId}`;
    },
  },
  toggleClientStatus: {
    api(clientId: string) {
      return `/Client/${clientId}/status`;
    },
  },
  impersonateClient: {
    api(clientId: string) {
      return `/Client/${clientId}/impersonate`;
    },
  },
  getAssignees: {
    api: '/Client/assignees',
  },
  draftClient: {
    api: '/Client/draft',
  },
  submitClient: {
    api(id: string) {
      return `/Client/${id}/submit`;
    },
  },
  fundSubmitOrganisationDetails: {
    api: '/Client/organisation-details',
  },
  fundSubmitContactDetails: {
    api: '/Client/contact-details',
  },
  fundSubmitOurTeam: {
    api: '/Client/our-team',
  },
  getFundOurTeam: {
    api: '/Client/our-team',
  },
  createClientTeamUser: {
    api: '/Client/client-team/user',
  },
  updateClientTeamUser: {
    api: (id: string) => `/Client/client-team/${id}/user`,
  },
  deleteClientTeamUser: {
    api: (userId: string, clientId: string) =>
      `/Client/client-team/${userId}/user?clientId=${clientId}`,
  },
  fundSubmitClientTeam: {
    api: '/Client/client-team',
  },
  fundSubmitBranding: {
    api: '/Client/branding',
  },
  fundSubmitClient: {
    api: '/Client',
  },
  fundResendClientTeamUserEmail: {
    api: (userId: string) => `/Client/${userId}/resendMail`,
  },
  fundGetClientTeamUserFunds: {
    api: (clientId: string) => `/Client/${clientId}/funds`,
  },
  generateManualInvoice: {
    api: (clientId: string, billingId: string) => `/Client/${clientId}/manual-invoice/${billingId}`,
  },
  getInvoices: {
    api: (clientId: string) => `/Client/${clientId}/invoices`,
  },
  downloadInvoice: {
    api: (clientId: string, invoiceId: string) => `/Client/${clientId}/invoice/${invoiceId}`,
  },
  updateClientTeamUserStatus: {
    api: (clientId: string, userId: string) => `/Client/${clientId}/${userId}/status`,
  },
  activeClient: {
    api: (clientId: string) => `/Client/${clientId}/active`,
  },
};

export const FILE_API = {
  uploadImage: {
    api: '/File/upload-image',
  },
  uploadDocument: {
    api: '/File/upload-document',
  },
  downloadFileByPath: {
    api: `/File/download`,
  },
  downloadDocumentByPath: {
    api: `/File/download-document`,
  },
};

export const ORGANISATION_SETTING_API = {
  branding: {
    api: '/Branding/organisation',
  },
  organisationDetail: {
    api: '/OrganisationDetail/tenant-organisation',
  },
  organisationSubscriptions: {
    api: '/OrganisationDetail/subscriptions',
  },
  organisationBillings: {
    api: '/OrganisationDetail/billings',
  },
  organisationBillingInvoice: {
    api: (id: string) => `/OrganisationDetail/${id}/billing-invoice`,
  },
};

export const PROFILE_API = {
  getProfile: {
    api: '/Profile',
  },
  updateProfile: {
    api: '/Profile',
  },
  resetAuthenticator: {
    api: '/Profile/reset-authenticator',
  },
  getOverarchingFilter: {
    api: '/Profile/overarching-filter',
  },
  getPortalInfo: {
    api: '/Profile/portal-info',
  },
};

export const FEATURE_API = {
  getFeature: {
    api: '/Feature',
  },
};

export const ROLE_API = {
  getRoleList: {
    api: '/Role/get-list',
  },
  getUserRole: {
    api: '/Profile/get-user-roles',
  },
  role: {
    api: '/Role',
  },
  roleById: {
    api: (roleId: string) => `/Role/${roleId}`,
  },
  deleteRole: {
    api(id: string) {
      return `/Role/${id}`;
    },
  },
};

export const FUNDS_API = {
  getFundList: {
    api: '/Fund/get-list',
  },
  exportFundList: {
    api: '/Fund/export-csv',
  },
  getFundFilter: {
    api: '/Fund/filters',
  },
  createFundDetails: {
    api: '/Fund/detail',
  },
  updateFundDetails: {
    api: (fundId: string) => `/Fund/${fundId}/detail`,
  },
  fundById: {
    api: (fundId: string) => `/Fund/${fundId}`,
  },
  updateFundStatus: {
    api: (fundId: string) => `/Fund/${fundId}/status`,
  },
  getOfferTypes: {
    api: '/Fund/offer-types',
  },
  upsertFundContact: {
    api: (fundId: string) => `/Fund/${fundId}/contact`,
  },
  checkFundCode: {
    api: (fundId: string) => `/Fund/${fundId}/check-code`,
  },
  getSignatoryContacts: {
    api: (fundId: string) => `/Fund/${fundId}/contacts`,
  },
  upsertSignatoryContacts: {
    api: (fundId: string) => `/Fund/${fundId}/signatory`,
  },
  getTrusteeAndServiceProvidersCapacites: {
    api: '/Fund/trustee-gp/filters',
  },
  getExisting: {
    api: (serviceProviderType: number) => {
      return `/ServiceProvider/${serviceProviderType}/capacity`;
    },
  },
  upsertServiceProvider: {
    api: (id?: string) => {
      return id ? `/ServiceProvider/${id}/fund` : `/ServiceProvider/fund`;
    },
  },
  getDetailServiceProvider: {
    api: (id: string) => {
      return `/ServiceProvider/${id}/fund`;
    },
  },
  upsertTrusteeGP: {
    api: (id: string) => {
      return `/Fund/${id}/trustee-gp`;
    },
  },
  upsertOverviewAndBranding: {
    api: (fundId: string) => `/Fund/${fundId}/overview-branding`,
  },
  getFundContacts: {
    api: (fundId: string) => `/Fund/${fundId}/fund-contacts`,
  },
  upsertFinancialDetails: {
    api: (fundId: string) => `/Fund/${fundId}/financial`,
  },
  getFundTeamByUserType: {
    api: (fundId: string) => `/Fund/${fundId}/teams`,
  },
  upsertFundTeam: {
    api: (fundId: string) => `/Fund/${fundId}/team`,
  },
  getUnitClassList: {
    api: (fundId: string) => `/UnitClass/get-list/${fundId}`,
  },
  getUnitClassFilter: {
    api: '/UnitClass/filters',
  },
  unitClassById: {
    api: (id: string) => `/UnitClass/${id}`,
  },
  archivedUnitClassById: {
    api: (id: string) => `/UnitClass/${id}/archived`,
  },
  deleteFundBradingDocument: {
    api: (docId: string) => `/Fund/${docId}/branding-document`,
  },
};

export const UNIT_CLASS_API = {
  currencies: {
    api: '/UnitClass/currencies',
  },
  upsertUnitClassDetail: {
    api: (id?: string) => {
      return id ? `/UnitClass/${id}/detail` : 'UnitClass/detail';
    },
  },
  upsertFinancialDetail: {
    api: (id?: string) => {
      return `/UnitClass/${id}/financial`;
    },
  },
  getUnitClassById: {
    api: (id: string) => {
      return `/UnitClass/${id}`;
    },
  },
  upsertInvestmentDetail: {
    api: (id: string) => {
      return `/UnitClass/${id}/investment`;
    },
  },
  upsertUnitClass: {
    api: (id: string) => {
      return `/UnitClass/${id}`;
    },
  },
  duplicateUnitClassById: {
    api: (id: string) => {
      return `/UnitClass/${id}/duplicate`;
    },
  },
  upsertInvestorRelations: {
    api: (fundId: string) => `/UnitClass/${fundId}/contact-investor`,
  },
};

export const OFFER_API = {
  getOfferList: {
    api: '/Offer/get-list',
  },
  getOfferFilter: {
    api: '/Offer/filters',
  },
  offerById: {
    api: (id: string) => `/Offer/${id}`,
  },
  exportOfferList: {
    api: '/Offer/export-csv',
  },
  createOfferDetails: {
    api: '/Offer/detail',
  },
  updateOfferDetails: {
    api: (id: string) => `/Offer/${id}/detail`,
  },
  getInvestmentEntityTypes: {
    api: '/Offer/investment-entity-types',
  },
  upsertOfferInvestmentDetails: {
    api: (id: string) => {
      return `/Offer/${id}/investment`;
    },
  },
  getOfferUnitClasses: {
    api: (offerId: string) => {
      return `/Offer/${offerId}/offer-unitclasses`;
    },
  },
  getOfferPreviewByUnitClass: {
    api: (offerId: string, unitClassId: string) => {
      return `/Offer/${offerId}/preview/${unitClassId}`;
    },
  },
  getOfferClientBranding: {
    api: '/Branding/client',
  },
  upsertOfferDocuments: {
    api: (id: string) => {
      return `/Offer/${id}/document`;
    },
  },
  getAllClient: {
    api: `/Offer/clients`,
  },
  getAllFundByClient: {
    api: (clientId: string) => `/Offer/${clientId}/funds`,
  },
  getAllUnitClassByFund: {
    api: (fundId: string) => `/Offer/${fundId}/unitclasses`,
  },
  upsertOfferExecutionDeclarations: {
    api: (id: string) => `/Offer/${id}/execution-declaration`,
  },
  fundBrandingOfferDetails: {
    api: (id: string) => `/Offer/${id}/fund-branding`,
  },
  getDeclarationOffer: {
    api: `/Offer/default-declaration`,
  },
  suspendOffer: {
    api: (id: string) => `/Offer/${id}/suspend`,
  },
  activateOffer: {
    api: (id: string) => `/Offer/${id}/activate`,
  },
  downloadDocument: {
    api: (offerId: string) => `/Offer/${offerId}/download-document`,
  },
  getFundBrandingForOffer: {
    api: (offerId: string) => `/Offer/${offerId}/fund-branding`,
  },
  sendEmailInvitation: {
    api: (offerId: string) => `/Offer/${offerId}/email-invitation`,
  },
  invitationWithEntity: {
    api: (offerId: string, isSentMail?: boolean) =>
      `/Offer/${offerId}/invitation?isSendMail=${Boolean(isSentMail)}`,
  },
  invitationHistories: {
    api: (offerId: string) => `/Offer/${offerId}/histories`,
  },
  duplicateOfferById: {
    api: (id: string) => `/Offer/${id}/duplicate`,
  },
};

export const APPLICATION_API = {
  getApplicationList: {
    api: '/Application/get-list',
  },
  archiveApplication: {
    api: (id: string) => `/Application/${id}/archive`,
  },
  getApplicationFilter: {
    api: '/Application/filters',
  },
  getApplicationOfferList: {
    api: '/Application/offers',
  },
  getApplicationInvestorTypes: {
    api: (unitClassId: string) => `/Application/unit-class/${unitClassId}/investor-types`,
  },
  upsertInvestmentEntity: {
    api: (id?: string) => `/Application/${id}/investment-entity`,
  },
  upsertApplicationDetails: {
    api: (id?: string) => (id ? `/Application/${id}/detail` : `/Application/detail`),
  },
  getInvestorOfferDetailsPreview: {
    api: (offerId: string, unitClassId: string) =>
      `/Application/offer/${offerId}/${unitClassId}/preview`,
  },
  getInvestorOfferInfo: {
    api: (offerId: string) => `/Application/offer/${offerId}`,
  },
  getInvestorOfferInfoByOfferCode: {
    api: (offerCode: string) => `/Application/offer/${offerCode}/detail`,
  },
  downloadFundDocument: {
    api: '/File/download-fund-document',
  },
  upsertTeamContact: {
    api: (applicationId: string) => `/Application/${applicationId}/team-contact`,
  },
  deleteTeamContact: {
    api: (contactId: string) => `/Application/${contactId}/team-contact`,
  },
  getTeamContact: {
    api: (applicationId: string) => `/Application/${applicationId}/team-contact/filter`,
  },
  upsertTeam: {
    api: (applicationId: string) => `/Application/${applicationId}/team`,
  },
  updateFinancialDetail: {
    api: (id: string) => `/Application/${id}/financial-detail`,
  },
  getApplicationOfferUnitClassDetail: {
    api: (offerId: string) => `/Application/${offerId}/offer/unit-class/detail`,
  },
  getApplicationById: {
    api: (id: string) => (isAdmin ? `/KYCBackOffice/${id}/application` : `/Application/${id}`),
  },
  getSignatoryContact: {
    api: (applicationId: string) => `/Application/${applicationId}/signatory-contact/filter`,
  },
  upsertApplicationAcceptance: {
    api: (applicationId: string) => `/Application/${applicationId}/acceptance`,
  },
  upsertApplicationAdditional: {
    api: (id: string) => `/Application/${id}/additional`,
  },
  getApplicationEntityTypes: {
    api: (id?: string) => `/Application/${id}/entity-types`,
  },
  getWholesaleCertificate: {
    api: (applicationId: string) => `/Application/${applicationId}/wholesale-certificate-template`,
  },
  deleteAdditionalDocument: {
    api: (applicationId: string) => `/Application/${applicationId}/document`,
  },
  downloadAdditionalDocument: {
    api: (applicationId: string) => `/Application/${applicationId}/additional-document`,
  },
  searchSkyc: {
    api: '/Application/search-skyc',
  },
  searchSuperFund: {
    api: '/Application/search-super-fund',
  },
  downloadDeclarationDocument: {
    api: (applicationId?: string) => `Application/${applicationId}/letter`,
  },
  removeApplicationOffer: {
    api: (id?: string) => `/Application/offer/${id}/remove`,
  },
  rejectApplicationOffer: {
    api: (id?: string) => `/Application/offer/${id}/reject`,
  },
  favouriteApplicationOffer: {
    api: (id?: string) => `/Application/offer/${id}/favourite`,
  },
  getKYCInfo: {
    api: (appId: string) => `/Application/${appId}/kyc-info`,
  },
  registerDocumentIdKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/greenid/register/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  registerBiometricKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/biometric/register/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  requestVerifyKYC: {
    api: (applicationId: string, individualId: string) =>
      `/KYC/request-verify/${applicationId}/${individualId}`,
  },
  registerConnectIdKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/connectid/register/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  completedDocumentIdKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/greenid/complete-document/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  completedBiometricKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/biometric/complete-document/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  completedConnectIdKYC: {
    api: (applicationId: string, individualId: string, code?: string) =>
      `/KYC/connectid/complete-document/${applicationId}/${individualId}${
        code ? `?code=${encodeURIComponent(code)}` : ''
      }`,
  },
  connectIdSelectBank: {
    api: (code?: string) =>
      `/KYC/connectid/select-bank${code ? `?code=${encodeURIComponent(code)}` : ''}`,
  },
  connectIdRetrieveToken: {
    api: () => `/KYC/connectid/retrieve-tokens`,
  },
  checkDuplicateAbnAcn: {
    api: `/Application/check-abn`,
  },
  applicationSkycCreateApp: {
    api: (id: string) => `/Application/${id}/skyc/create-app`,
  },
  applicationSkycGetApp: {
    api: (id: string) => `/Application/${id}/skyc/get-app`,
  },
  skycCreateApp: {
    api: '/KYC/skyc/create-app',
  },
  skycGetApp: {
    api: (applicationId: string) => `/KYC/skyc/get-app/${applicationId}`,
  },
  submitApplication: {
    api: (id?: string) => `/Application/${id}`,
  },
  getInvestmentEntitiesByEntityType: {
    api: (entityType: number) => `/Application/${entityType}/entities`,
  },
  getInvestmentEntityDetail: {
    api: (entityId: string) => `/Application/entity/${entityId}`,
  },
  getInvestmentEntityUserInfo: {
    api: (entityId: string) => `/Application/entity/${entityId}/user-info`,
  },
  getInvestmentEntityDocuments: {
    api: (entityId: string) => `/Application/entity/${entityId}/documents`,
  },
  getTaxDetails: {
    api: (appId: string) => `/Application/${appId}/tax/filters`,
  },
  upsertFinancialAndTaxDetails: {
    api: (appId: string) => `/Application/${appId}/financialtax-detail`,
  },
  getApplicationOverview: {
    api: (appId: string) => `/Application/${appId}/overview`,
  },
  approveApplication: {
    api: (appId: string) => `/Application/${appId}/approve`,
  },
  rejectApplication: {
    api: (appId: string) => `/Application/${appId}/reject`,
  },
  initiateVerificationApplication: {
    api: (appId: string) => `/Application/${appId}/initiate-kyc`,
  },
  uploadApplicationReviewDocument: {
    api: (appId: string) => `/Application/${appId}/fund-doc`,
  },
  deleteApplicationReviewDocument: {
    api: (appId: string) => `/Application/${appId}/document`,
  },
  downloadOverviewDocument: {
    api: (appId: string) => `/Application/${appId}/overview-document`,
  },
  confirmInvestmentAmount: {
    api: (appId: string) => `/Application/${appId}/confirm-investment-amount`,
  },
  updateInvestmentAmountReceived: {
    api: (appId: string) => `/Application/${appId}/investment-amount-received`,
  },
  revertInvestmentAmoutnReceived: {
    api: (appId: string) => `/Application/${appId}/revert-investment-amount-received`,
  },
  finaliseApplication: {
    api: (appId: string) => `/Application/${appId}/finalise`,
  },
  saveApplicationSignatories: {
    api: (appId: string) => `/Application/${appId}/signatories`,
  },
  markSignedApplicationSignatory: {
    api: (appId: string) => `/Application/${appId}/mark-signed`,
  },
  getReceivedSignatoryTypes: {
    api: (appId: string) => `/Application/${appId}/received-signatory-types`,
  },
  cancelApplication: {
    api: (appId: string) => `/Application/${appId}/cancel`,
  },
  withdrawApplication: {
    api: (appId: string) => `/Application/${appId}/withdraw`,
  },
  getLetterFile: {
    api: (appId: string) => `/Application/${appId}/letter`,
  },
  getDuplicatedEntity: {
    api: (appId: string) => `/Application/${appId}/duplicated`,
  },
  acceptDuplicatedEntity: {
    api: (appId: string) => `/Application/${appId}/approve-duplicated`,
  },
  rejectDuplicatedEntity: {
    api: (appId: string) => `/Application/${appId}/reject-duplicated`,
  },
  overrideApplicationTeam: {
    api: (appId: string) => `/Application/${appId}/override-team`,
  },
  getApplicationFilterCreation: {
    api: '/Application/filter-creation',
  },
  upsertFundApplicationKYC: {
    api: (appId: string) => `/Application/${appId}/kyc`,
  },
  deleteApplication: {
    api: (id: string) => `/Application/${id}`,
  },
};

export const INVESTMENT_ENTITY_API = {
  getInvestmentEntityList: {
    api: '/InvestmentEntity/get-list',
  },
  archiveApplication: {
    api: (id: string) => `/InvestmentEntity/${id}/archive`,
  },
  investmentEntiryById: {
    api: (id: string) => `/InvestmentEntity/${id}`,
  },
  upsertTeamContact: {
    api: (entityId: string) => `/InvestmentEntity/${entityId}/team-contact`,
  },
  deleteTeamContact: {
    api: (contactId: string) => `/InvestmentEntity/${contactId}/team-contact`,
  },
  getTeamContact: {
    api: (entityId: string) => `/InvestmentEntity/${entityId}/team-contact/filter`,
  },
  upsertTeam: {
    api: (entityId: string) => `/InvestmentEntity/${entityId}/team`,
  },
  upsertFinancialDetail: {
    api: (id: string) => `/InvestmentEntity/${id}/financial-detail`,
  },
  upsertFinancialTaxDetail: {
    api: (id: string) => `/InvestmentEntity/${id}/financial-tax-detail`,
  },
  createInvestmentEntityDetail: {
    api: '/InvestmentEntity/detail',
  },
  upsertInvestmentEntityDetails: {
    api: (id: string) => `/InvestmentEntity/${id}/details`,
  },
  upsertInvestmentEntityDocument: {
    api: (id: string) => `/InvestmentEntity/${id}/documents`,
  },
  deleteInvestmentEntityDocuments: {
    api: (id: string) => `/InvestmentEntity/${id}/document`,
  },
  getTaxDetails: {
    api: (id: string) => `/InvestmentEntity/${id}/tax/filters`,
  },
  downloadInvestmentEntityDocument: {
    api: (id: string) => `/InvestmentEntity/${id}/download-document`,
  },
};

export const KYC_BACK_OFFICE_API = {
  getKYCBackOfficeList: {
    api: '/KYCBackOffice/get-list',
  },
  getKYCBackOfficeFilter: {
    api: '/KYCBackOffice/filters',
  },
  getKYCBackOfficeById: {
    api: (id: string) => `/KYCBackOffice/${id}`,
  },
  getKYCBackOfficeAssignees: {
    api: '/KYCBackOffice/assigness',
  },
  getKYCBackOfficeAssigneesById: {
    api: (id: string) => `/KYCBackOffice/${id}/assigness`,
  },
  openKycBackOffice: {
    api: (id: string) => `/KYCBackOffice/${id}/open`,
  },
  closeKycBackOffice: {
    api: (id: string) => `/KYCBackOffice/${id}/close`,
  },
  kycBackOfficeAssign: {
    api: (memberId: string) => `/KYCBackOffice/${memberId}/assign`,
  },
  manuallyOverrideKyc: {
    api: (id: string, uboId: string) => `/KYCBackOffice/${id}/ubo/${uboId}/mannual-kyc`,
  },
  manuallyOverrideAml: {
    api: (id: string, uboId: string) => `/KYCBackOffice/${id}/ubo/${uboId}/mannual-aml`,
  },
  addNewUbo: {
    api: (id: string) => `/KYCBackOffice/${id}/ubo`,
  },
  removeUbo: {
    api: (id: string, uboId: string) => `/KYCBackOffice/${id}/ubo/${uboId}`,
  },
  kycBackOfficeReviewSubmit: {
    api: (kycId: string) => `/KYCBackOffice/${kycId}/submit`,
  },
  kycBackOfficeReviewSubmitAdditionalComment: {
    api: (kycId: string) => `/KYCBackOffice/${kycId}/additional-comment`,
  },
  kycBackOfficeCompletedExternally: {
    api: (kycId: string) => `/KYCBackOffice/${kycId}/complete-kyc-external`,
  },
  kycBackOfficeNote: {
    api: (kycId: string) => `/KYCBackOffice/${kycId}/notes`,
  },
  getKYCBackOfficeNote: {
    api: (id: string) => `/KYCBackOffice/${id}/notes`,
  },
  kycRequestMoreInformation: {
    api: (applicationId: string) => `/Application/${applicationId}/request-more-info`,
  },
  downloadApplicationForm: {
    api: (id: string) => `/KYCBackOffice/${id}/download-form`,
  },
  getMasterData: {
    api: '/KYCBackOffice/master-data',
  },
  billingSubmit: {
    api: (id: string) => `/KYCBackOffice/${id}/billing-submit`,
  },
  billingCloseCase: {
    api: (id: string) => `/KYCBackOffice/${id}/billing-close-case`,
  },
  billingNoCharge: {
    api: (id: string) => `/KYCBackOffice/${id}/billing-no-charge`,
  },
  investorDetailAuditTrails: {
    api: (id: string) => `/KYCBackOffice/${id}/audit_trails`,
  },
  uboAuditTrails: {
    api: (id: string) => `/KYCBackOffice/${id}/ubo_audit_trails`,
  },
  getTaxDetails: {
    api: (id: string) => `/KYCBackOffice/${id}/tax/filters`,
  },
  getKycBoSkycApp: {
    api: (id: string) => `/KYCBackOffice/skyc/get-app/${id}`,
  },
};

export const FUNDRAISING_APPLICATION_API = {
  getOverview: {
    api: (id: string) => `/Application/${id}/overview`,
  },
};

export const ADMINISTRATION_INVESTOR_API = {
  getInvestorList: {
    api: '/Investor/get-list',
  },
  getInvestorFiltered: {
    api: '/Investor/filter',
  },
  getFunds: {
    api: '/Investor/funds',
  },
  getInvestorMasterData: {
    api: '/Investor/master-data',
  },
  getInvestorEntities: {
    api: '/Investor/entities',
  },
  createInvestorDetail: {
    api: '/Investor/detail',
  },
  getInvestorPoppulateData: {
    api: (fundId: string, unitClassId: string, investmentEntityId: string) =>
      `/Investor/populate-data/${fundId}/${unitClassId}/${investmentEntityId}`,
  },
  getUnitClassesByFund: {
    api: (fundId: string) => `/Investor/fund/${fundId}/unitclasses`,
  },
  getUnitClassesByMultiFunds: {
    api: '/Investor/fund/unitclasses',
  },
  getInvestorDetail: {
    api: (id: string) => `/Investor/${id}`,
  },
  createInvestor: {
    api: (id: string) => `/Investor/${id}`,
  },
  deleteInvestor: {
    api: (id: string) => `/Investor/${id}`,
  },
  archiveInvestor: {
    api: (id: string) => `/Investor/${id}/archive`,
  },
  unarchiveInvestor: {
    api: (id: string) => `/Investor/${id}/unarchive`,
  },
  ceaseInvestor: {
    api: (id: string) => `/Investor/${id}/cease`,
  },
  unceaseInvestor: {
    api: (id: string) => `/Investor/${id}/uncease`,
  },
  upsertInvestorDetail: {
    api: (id: string) => (id ? `/Investor/${id}/detail` : '/Investor/detail'),
  },
  upsertInvestorInvestmentDetail: {
    api: (id: string) => `/Investor/${id}/investment-detail`,
  },
  upsertInvestorTeam: {
    api: (id: string) => `/Investor/${id}/team`,
  },
  upsertInvestorTeamContact: {
    api: (id: string) => `/Investor/${id}/team-contact`,
  },
  deleteInvestorTeamContact: {
    api: (id: string) => `/Investor/${id}/team-contact`,
  },
  archiveInvestmentEntity: {
    api: (id: string) => `/InvestmentEntity/${id}/archive`,
  },
  unarchiveInvestmentEntity: {
    api: (id: string) => `/InvestmentEntity/${id}/unarchive`,
  },
  getInvestmentEntityRoles: {
    api: `/InvestmentEntity/roles`,
  },
  searchSkyc: {
    api: `/KYC/skyc/search`,
  },
  getInvestorDetailIssue: {
    api: (id: string) => `/Investor/${id}/issue`,
  },
  createInvestorIssue: {
    api: (id: string) => `/Investor/${id}/issue`,
  },
  getInvestorDetailRedeem: {
    api: (id: string) => `/Investor/${id}/redeem`,
  },
  createInvestorRedeem: {
    api: (id: string) => `/Investor/${id}/redeem`,
  },
  offmarketTransfer: {
    api: (id: string) => `/Investor/${id}/offmarket-transfer`,
  },
  recordPayment: {
    api: (id: string) => `/Investor/${id}/record-payment`,
  },
  previewStatement: {
    api: (id: string) => `/Investor/${id}/preview-statement`,
  },
  generateStatement: {
    api: (id: string) => `/Investor/${id}/generate-statement`,
  },
  updateCurrentUnitPrice: {
    api: (unitClassId: string) => `/Investor/${unitClassId}/update-unit-price`,
  },
  investorExportCSV: {
    api: '/Investor/export-csv',
  },
  investmentEntityExportCSV: {
    api: '/InvestmentEntity/export-csv',
  },
  exportInvestorProfileCSV: {
    api: '/investor/export-profile-csv',
  },
  downloadInvestorProfileCSV: {
    api: (downloadId: string) => `/Investor/${downloadId}/export-profile-csv`,
  },
};

export const UNALLOCATED_HOLDING_API = {
  getUnallocatedHoldingList: {
    api: '/holdinglink/get-list',
  },
  unallocatedHoldingLink: {
    api: (id: string) => `/holdinglink/link/${id}`,
  },
  getUnallocatedHoldingInfo: {
    api: (id: string) => `/holdinglink/${id}/holding-info`,
  },
};

export const SUBSCRIPTION_BILLING_API = {
  getSubscriptionFeatures: {
    api: '/SubscriptionBilling/subscriptions',
  },
  getStandardPricingLatest: {
    api: '/SubscriptionBilling/standard-pricing-latest',
  },
  getStandardPricingDetail: {
    api: (id: string) => `/SubscriptionBilling/${id}/standard-pricing`,
  },
  getStandardPricingDetailByDate: {
    api: (date: string) => `/SubscriptionBilling/standard-pricing/${date}`,
  },
  getStandardPricingHistory: {
    api: `/SubscriptionBilling/standard-pricing-histories`,
  },
  updateStandardPricing: {
    api: '/SubscriptionBilling/save-standard-pricing',
  },
  getBillingPlans: {
    api: (clientId: string) => `/Client/${clientId}/billing-plans`,
  },
  billingPlanDetail: {
    api: (clientId: string, billingPlanId: string) =>
      `/Client/${clientId}/billing-plan/${billingPlanId}`,
  },
  createBillingPlan: {
    api: (clientId: string) => `/Client/${clientId}/billing-plan`,
  },
  updateStandardPricingHistory: {
    api: (id: string) => `/SubscriptionBilling/${id}/save-standard-pricing`,
  },
  deleteStandardPricing: {
    api: (id: string) => `/SubscriptionBilling/${id}`,
  },
};

export const FUND_DOCUMENT_API = {
  getFundDocumentList: {
    api: '/Document/get-list',
  },
  deleteFundDocument: {
    api: (id: string) => `/Document/${id}`,
  },
  unpublishFundDocument: {
    api: (id: string) => `/Document/${id}/unpublish`,
  },
  publishFundDocument: {
    api: (id: string) => `/Document/${id}/publish`,
  },
  hideFundDocument: {
    api: (id: string) => `/Document/${id}/hide`,
  },
  unhideFundDocument: {
    api: (id: string) => `/Document/${id}/unhide`,
  },
  downloadFundDocument: {
    api: (id: string) => `/Document/${id}/download`,
  },
  uploadByFilter: {
    api: '/Document/upload-by-filter',
  },
  unitClassFilter: {
    api: '/Document/unit-class-filter',
  },
  getDocumentCreationFilter: {
    api: '/Document/creation-filter',
  },
  uploadFundDocument: {
    api: '/Document',
  },
};

export const INVESTOR_DOCUMENT_API = {
  getDocumentList: {
    api: '/Document/investor/get-list',
  },
  hideDocument: {
    api: (id: string) => `/Document/${id}/hide`,
  },
  unhideDocument: {
    api: (id: string) => `/Document/${id}/unhide`,
  },
  downloadDocument: {
    api: '/File/download-fund-document',
  },
  getUnitClassFilter: {
    api: '/Document/unit-class-filter',
  },
};

export const SERVICE_PROVIDER_API = {
  getServiceProviders: {
    api: '/ServiceProvider/get-list',
  },
  deleteServiceProvider: {
    api: (id: string) => `/ServiceProvider/${id}`,
  },
  getServiceProviderClients: {
    api: '/ServiceProvider/clients',
  },
  upsertServiceProvider: {
    api: (id?: string) => (id ? `/ServiceProvider/${id}` : '/ServiceProvider'),
  },
  getServiceProviderById: {
    api: (id: string) => `/ServiceProvider/${id}`,
  },
};

export const CAPITAL_CALL_API = {
  getCapitalCallList: {
    api: '/CapitalCall/get-list',
  },
  getCapitalCallFilter: {
    api: '/CapitalCall/filter',
  },
  archiveCapitalCall: {
    api: (id: string) => `/CapitalCall/${id}/archive`,
  },
  unarchiveCapitalCall: {
    api: (id: string) => `/CapitalCall/${id}/un-archive`,
  },
  capitalCallById: {
    api: (id: string) => `/CapitalCall/${id}`,
  },
  createCapitalCallSummary: {
    api: '/CapitalCall/detail',
  },
  updateCapitalCallSummary: {
    api: (id: string) => `/CapitalCall/${id}/detail`,
  },
  getCapitalCallFilterCreation: {
    api: `/CapitalCall/filter-creation`,
  },
  calculateTotalCapitalCallAmount: {
    api: `/CapitalCall/calculate-total-capital-call-amount`,
  },
  getCapitalCallById: {
    api: (id: string) => `/CapitalCall/${id}`,
  },
  publishDocument: {
    api: (id: string) => `/CapitalCall/${id}/publish-document`,
  },
  emailDocument: {
    api: (id: string) => `/CapitalCall/${id}/email-document`,
  },
  downloadDocument: {
    api: (id: string) => `/CapitalCall/${id}/download`,
  },
  calculateTotalCapitalCallDetail: {
    api: (id: string) => `/CapitalCall/${id}/calculate-total-call-detail`,
  },
  updateCallDetailStatus: {
    api: (id: string) => `/CapitalCall/${id}/call-detail-status`,
  },
  recordPayment: {
    api: (id: string) => `/CapitalCall/${id}/record-payment`,
  },
  reversePayment: {
    api: (id: string) => `/CapitalCall/${id}/revert-payment`,
  },
  getRecordPayment: {
    api: (capitalCallId: string, callDetailId: string) =>
      `/CapitalCall/${capitalCallId}/record-payment/${callDetailId}`,
  },
  downloadMultipleDocument: {
    api: (downloadId: string) => `/CapitalCall/${downloadId}/download-multiple`,
  },
};

export const COMMUNICATION_API = {
  getCommunicationInboxes: {
    api: '/Communication/inboxes',
  },
  getCommunicationOutboxes: {
    api: '/Communication/outboxes',
  },
  communicationById: {
    api: (id: string) => `/Communication/${id}`,
  },
  getCommunicationMasterData: {
    api: '/Communication/master-data',
  },
  getCommunicationCategoryClient: {
    api: '/Communication/category/client',
  },
  getCommunicationCategoryInternal: {
    api: '/Communication/category/internal',
  },
  communicationMessage: {
    api: '/Communication/message',
  },
  communicationRead: {
    api: '/Communication/read',
  },
  communicationUnread: {
    api: '/Communication/unread',
  },
  communicationPriority: {
    api: '/Communication/priority',
  },
  communicationArchive: {
    api: '/Communication/archive',
  },
  communicationFileUpload: {
    api: '/Communication/file/upload',
  },
  communicationFileDownload: {
    api: '/Communication/file/download',
  },
  communicationReply: {
    api: (id: string) => `/Communication/${id}/reply`,
  },
  communicationForward: {
    api: (id: string) => `/Communication/${id}/forward`,
  },
  getCommunicationCategoryInvestor: {
    api: '/Communication/category/investor',
  },
  communicationInitKycBo: {
    api: (kycBoId: string) => `/Communication/init-kycbo/${kycBoId}`,
  },
  communicationCategoryKycBo: {
    api: (kycBoId: string) => `/Communication/category/kycbo/${kycBoId}`,
  },
  downloadMessageAttachments: {
    api: (messageId: string) => `/Communication/${messageId}/download`,
  },
  getInboxMessageDetailById: {
    api: (messageId: string) => `/Communication/${messageId}/inbox`,
  },
};
export const BULK_UPLOAD_API = {
  getBulkUploadList: {
    api: '/bulkupload/investor/get-list',
  },
  getBulkUploadListById: {
    api: (id: string) => `/bulkupload/investor/${id}/get-list`,
  },
  bulkUploadInvestor: {
    api: '/bulkupload/investor',
  },
  getBulkUploadInvestorClients: {
    api: '/bulkupload/investor/clients',
  },
  getBulkUploadMasterData: {
    api: '/bulkupload/investor/master-data',
  },
  getBulkUploadInvestorFunds: {
    api: (clientId: string) => `/bulkupload/investor/client/${clientId}/funds`,
  },
  getBulkUploadInvestorUnitClasses: {
    api: (fundId: string) => `/bulkupload/investor/fund/${fundId}/unitclasses`,
  },
};

export const NOTIFICATION_API = {
  getNotificationList: {
    api: '/Notification/get-list',
  },

  deleteNotification: {
    api: (id: string) => `/Notification/${id}`,
  },
  updateNotificationState: {
    api: (id: string) => `/Notification/${id}`,
  },
  clearAllNotification: {
    api: '/Notification/clear-all',
  },
};

export const INVESTOR_FORM_API = {
  getInvestorFormMasterData: {
    api: '/InvestorForm/master-data',
  },
  getInvestorFormPopulateData: {
    api: '/InvestorForm/investor/populate-data',
  },
  getInvestorFormId: {
    api: (formType: INVESTOR_FORM_TYPE) => `/InvestorForm/formId/${formType}`,
  },
  upsertRedemptionRequest: {
    api: (id?: string) =>
      id ? `/InvestorForm/${id}/redemption-request` : '/InvestorForm/redemption-request',
  },
  upsertChangeDetail: {
    api: (id?: string) =>
      id ? `/InvestorForm/${id}/change-detail` : '/InvestorForm/change-detail',
  },
  upsertTransferRequest: {
    api: (id?: string) =>
      id ? `/InvestorForm/${id}/transfer-request` : '/InvestorForm/transfer-request',
  },
  submitInvestorForm: {
    api: (id: string) => `/InvestorForm/${id}`,
  },
  getInvestorFormList: {
    api: '/InvestorForm/get-list',
  },
  downloadInvestorPDF: {
    api: (id: string) => `/InvestorForm/${id}/pdf`,
  },
  getInvestorFormById: {
    api: (id: string) => `/InvestorForm/${id}`,
  },
  deleteInvestorForm: {
    api: (id: string) => `/InvestorForm/${id}`,
  },
  downloadInvestorFormFile: {
    api: (id: string) => `/InvestorForm/${id}/file`,
  },
};

export const DASHBOARD_API = {
  getDashboardSummary: {
    api: '/Dashboard/summary',
  },
  getDashboardSummaryByClient: {
    api: (clientId: string) => `/Dashboard/${clientId}/summary`,
  },
  getDashboardAllocations: {
    api: '/Dashboard/allocations',
  },
  getDashboardDetails: {
    api: '/Dashboard/details',
  },
  getDashboardFundAllocations: {
    api: '/Dashboard/fund-allocations',
  },
  getDuplicationInfo: {
    api: (applicationId: string) => `/Dashboard/${applicationId}/duplication`,
  },
  rejectDuplication: {
    api: (applicationId: string) => `/Dashboard/${applicationId}/reject-duplication`,
  },
  confirmDuplication: {
    api: (applicationId: string) => `/Dashboard/${applicationId}/confirm-duplication`,
  },
  getClientSummary: {
    api: '/Dashboard/client-summary',
  },
  remoteAccess: {
    api: (clientId: string) => `/Dashboard/${clientId}/remote-access`,
  },
};

export const AUDIT_TRAIL_API = {
  getAuditTrailList: {
    api: '/AuditTrail/get-list',
  },
  getAuditTrailModuleDetails: {
    api: (moduleId: string) => `//AuditTrail/get-list/${moduleId}`,
  },
};

export const CONTACT_LAWYER_API = {
  submitContactLawyer: {
    api: '/ContactLawyer',
  },
};

export const BILLING_API = {
  lastMonthBilling: {
    api: '/Client/monthly-invoice',
  },
  currentMothBilling: {
    api: '/Client/current-invoice',
  },
};

export const FUND_REGISTRY_API = {
  getFundRegistryFilter: {
    api: '/FundRegistry/get-filter-list',
  },
  getFundRegistryUnitPriceHistory: {
    api: '/FundRegistry/get-list',
  },
  fundRegistryBulkUpload: {
    api: '/FundRegistry/upload-csv',
  },
  getFundRegistryBulkUploadStatus: {
    api: (id: string) => `/FundRegistry/${id}/bulk-upload-status`,
  },
  exportUnitPriceHistoryCsv: {
    api: '/FundRegistry/export-csv',
  },
  calTotalUnitIssued: {
    api: '/FundRegistry/cal-unit',
  },
  strikeUnitPrice: {
    api: '/FundRegistry',
  },
  editUnitPrice: {
    api: (id: string) => `/FundRegistry/${id}`,
  },
  getUnitPriceById: {
    api: (id: string) => `/FundRegistry/${id}`,
  },
  deleteUnitPrice: {
    api: (id: string) => `/FundRegistry/${id}`,
  },
  getUnitByDate: {
    api: '/FundRegistry/get-unit-by-date',
  },
};

export const ADMINISTRATION_REPORTS_API = {
  getReportsMasterData: {
    api: '/Report/master-data',
  },
  getReportsFilter: {
    api: '/Report/filter',
  },
  getReports: {
    api: '/Report/get-list',
  },
  getReportsFilterCreation: {
    api: '/Report/filter-creation',
  },
  generateReport: {
    api: '/Report/generate-report',
  },
  getReportDetails: {
    api: '/Report/details',
  },
  downloadReport: {
    api: '/Report/download',
  },
  getAuditHistories: {
    api: (taxId: string) => `/Report/${taxId}/audit-histories`,
  },
};
