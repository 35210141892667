/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IArrowRepeatIconProps {}

export const ArrowRepeatIcon: FC<IArrowRepeatIconProps> = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_Navigation_03'
          transform='translate(-23.000000, -364.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Audit-Trail-Menu' transform='translate(11.000000, 352.000000)'>
            <g id='Group' transform='translate(12.000000, 12.000000)'>
              <g id='Group' transform='translate(2.000000, 2.000000)'>
                <path
                  d='M12.61,0.225588327 L12.533,0.156871424 C12.2348256,-0.076053351 11.8146016,-0.0466774715 11.55,0.225588327 L11.482,0.30558711 C11.2559236,0.613366824 11.2849053,1.04480638 11.55,1.3178794 L13.521,3.34554085 L6.5,3.34554085 L6.267,3.34964335 C2.785,3.47579528 0,6.42036585 0,10.0367211 C0,11.770028 0.64,13.3494912 1.692,14.5392167 L1.764,14.6099848 C2.0571198,14.8671186 2.49250114,14.8547044 2.77123896,14.5812649 C3.04997678,14.3078254 3.08142791,13.8622809 2.844,13.5505138 L2.644,13.3135943 C1.90230107,12.3908305 1.49772089,11.2315885 1.5,10.0356955 C1.5,7.19368742 3.739,4.8891071 6.5,4.8891071 L13.381,4.8891071 L11.549,6.77625787 L11.482,6.85625665 C11.2559236,7.16403637 11.2849053,7.59547593 11.55,7.86854894 C11.6903644,8.01310224 11.8810862,8.09436139 12.08,8.09436139 C12.2789138,8.09436139 12.4696356,8.01310224 12.61,7.86854894 L15.792,4.59372698 L15.859,4.5137282 C16.0853403,4.20619666 16.056786,3.77477426 15.792,3.50143591 L12.61,0.225588327 Z M18.23,5.45730358 C17.9298196,5.20292452 17.4898593,5.22605188 17.2166094,5.5105744 C16.9433595,5.79509692 16.9274657,6.24662959 17.18,6.55062028 C18.0310853,7.5010926 18.502307,8.74521599 18.5,10.0356955 C18.5,12.8787291 16.261,15.1833094 13.5,15.1833094 L6.558,15.1833094 L8.463,13.2233393 L8.537,13.1351355 C8.7342708,12.859067 8.7314246,12.4829165 8.53,12.2100213 L8.463,12.1310482 L8.379,12.0561775 C8.1112208,11.8521822 7.74410354,11.8546923 7.479,12.0623313 L7.403,12.1310482 L4.221,15.4068958 L4.148,15.4940739 C3.95043449,15.7699164 3.95287408,16.1460656 4.154,16.4191881 L4.221,16.4981612 L7.403,19.7740088 L7.487,19.8478538 C7.78,20.0734914 8.197,20.0478508 8.463,19.7729832 C8.72809473,19.4999102 8.75707637,19.0684706 8.531,18.7606909 L8.463,18.6817177 L6.564,16.7268757 L13.5,16.7268757 L13.733,16.7227732 C17.215,16.5966213 20.0000082,13.651025 20.0000082,10.0356955 C20.0025763,8.36861083 19.3972736,6.76078723 18.303,5.52807174 L18.23,5.45730358 L18.23,5.45730358 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
