import { yupResolver } from '@hookform/resolvers/yup';
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import { Box } from '@mui/material';
import _, { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import {
  COMMUNICATION_MESSAGE_TYPE,
  COMMUNICATION_VIEW_MODE,
  CommunicationCategoryEnum,
  CommunicationDeliveryType,
  communicationDefaultValues,
  communicationStatuses,
} from 'src/constants/communications';
import { detectPortalType } from 'src/helpers/common';
import { showToast } from 'src/helpers/toast';
import { IAlertInfo } from 'src/modules/common/type';
import {
  useCreateCommunicationMessage,
  useGetCommunicationById,
  useGetCommunicationCategoryClient,
  useGetCommunicationCategoryInternal,
  useGetCommunicationCategoryInvestor,
  useGetCommunicationCategoryKycBo,
  useGetCommunicationMasterData,
  useUpdateCommunicationMessage,
} from 'src/modules/communication/hooks';
import {
  ICategoryInvestorItem,
  IClientRecipientBody,
  ICommunicationDetail,
  IInternalRecipientBody,
  IInvestorRecipientBody,
  IKycBoRecipientBody,
  IUpsertCommunicationForm,
} from 'src/modules/communication/type';
import { handleErrorFromServer } from 'src/utils/common';
import { messageSchema } from 'src/validations/communication';
import ForwardMessage from './ForwardMessage';
import MessageButtons from './MessageButtons';
import MessageContent from './MessageContent';
import ReplyMessage from './ReplyMessage';

const { isFunds, isInvestor, isAdmin } = detectPortalType();

interface INewMessageProps {
  kycBoId?: string;
  messageId?: string;
  onClose?: () => void;
  onRefresh?: () => void;
  isInitialCommunication?: boolean;
  viewMode?: number;
  subject?: string;
  userMessageId?: string;
}

type NewMessageHandler = {
  open: () => void;
  close: () => void;
};

const NewMessage = React.forwardRef<NewMessageHandler, INewMessageProps>(
  (
    {
      messageId,
      kycBoId: kycBoIdProp,
      onClose,
      onRefresh,
      isInitialCommunication,
      viewMode,
      subject,
      userMessageId,
    },
    ref,
  ): JSX.Element => {
    const { isInvestor } = detectPortalType();
    const isInboxMode = viewMode === COMMUNICATION_VIEW_MODE.Inbox;
    const isExistingMessage = !!messageId;
    const defaultValues = {
      ...communicationDefaultValues,
      ...(isInitialCommunication && { category: CommunicationCategoryEnum.KYCBO }),
      ...(isInvestor && { investorRelationOrder: 0 }),
      ...(isFunds && {
        externalEmails: [],
        hasExternalMail: false,
      }),
    };

    const alertRef = useRef<IBasicModalElement>(null);
    const upsertDrawerRef = useRef<ICustomDrawerElement>(null);
    const replyingDrawerRef = useRef<ICustomDrawerElement>(null);
    const forwardingDrawerRef = useRef<ICustomDrawerElement>(null);
    const confirmCloseModalRef = useRef<IConfirmModalElement>(null);

    const [forwardedId, setForwardedId] = useState<string | null>(null);
    const [repliedId, setRepliedId] = useState<string | null>(null);
    const [kycBoId, setKycBoId] = useState<string>(kycBoIdProp || '');

    const { data: clientData } = useGetCommunicationCategoryClient();
    const { data: internalData } = useGetCommunicationCategoryInternal();
    const { data: categoryInvestorData = [] } = useGetCommunicationCategoryInvestor();
    const { data: { categories: categoriesData, creatingCategories } = {} } =
      useGetCommunicationMasterData();
    const { data: messageDetail } = useGetCommunicationById({
      id: messageId || '',
      userMessageId: isInboxMode && isInvestor ? userMessageId : '',
    });
    const { data: categoryKycBo } = useGetCommunicationCategoryKycBo(kycBoId);

    const { mutate: updateCommunicationMessage } = useUpdateCommunicationMessage();
    const { mutate: createCommunicationMessage, isLoading } = useCreateCommunicationMessage();

    const isDraft =
      messageDetail?.outboxStatusName === communicationStatuses.Draft ||
      messageDetail?.inboxStatusName === communicationStatuses.Draft;

    const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
      title: '',
      description: '',
    });

    useImperativeHandle(ref, () => ({
      open: () => upsertDrawerRef.current?.open(),
      close: () => upsertDrawerRef.current?.close(),
    }));

    const form = useForm<IUpsertCommunicationForm>({
      defaultValues,
      resolver: yupResolver(messageSchema),
    });

    const recipientsError =
      form.formState.errors?.clientRecipients?.message ||
      form.formState.errors?.internalRecipients?.userIds?.message ||
      form.formState.errors?.investorRecipients?.message;

    const watchCategory = form.watch('category');

    useEffect(() => {
      repliedId && replyingDrawerRef.current?.open();
      forwardedId && forwardingDrawerRef.current?.open();
    }, [repliedId, forwardedId]);

    useEffect(() => {
      if (isExistingMessage && !isEmpty(messageDetail)) {
        handleFillDataToForm(messageDetail);
      }
      if (messageDetail?.kycboId) {
        setKycBoId(messageDetail?.kycboId);
      }
    }, [messageDetail, messageId]);

    useEffect(() => {
      if (subject) {
        form.setValue('subject', subject);
      }
    }, [subject]);

    const handleFillDataToForm = (detail: ICommunicationDetail) => {
      const {
        id,
        attachments,
        category,
        subject,
        content,
        clientRecipients,
        internalRecipients,
        investorRecipients,
        kycboRecipients,
        investorRelationOrder,
      } = detail || {};

      const _internalRecipients: IInternalRecipientBody = {
        userIds: [],
      };
      let _clientRecipients: IClientRecipientBody[] = [];
      let _investorRecipients: IInvestorRecipientBody[] = [];
      const _kycboRecipients: IKycBoRecipientBody = {
        uboIds: [],
        contactIds: [],
      };

      if (category === CommunicationCategoryEnum.Internal && !isEmpty(internalRecipients)) {
        _internalRecipients.userIds = _.map(internalRecipients.recipients, 'id');
      }

      if (
        [
          CommunicationCategoryEnum.ClientManagement,
          CommunicationCategoryEnum.AccountAdministration,
        ].includes(category) &&
        !isEmpty(clientRecipients)
      ) {
        _clientRecipients = clientRecipients.map((client) => ({
          clientId: client.clientId,
          adminIds: _.map(client.recipients, 'id'),
        }));
      }

      if (category === CommunicationCategoryEnum.InvestorRelation && !isEmpty(investorRecipients)) {
        if (isFunds) {
          _investorRecipients = investorRecipients.map((investor: any) => ({
            investorId: investor?.investorId,
            contactIds: _.map(investor.communicationList, 'id'),
          }));
        }
        if (isInvestor) {
          _investorRecipients = investorRecipients.map((investor: any) => ({
            ...investor,
            investorIds: _.map(investor.investors, 'id'),
          }));
        }
      }

      if (category === CommunicationCategoryEnum.KYCBO && !isEmpty(kycboRecipients)) {
        _kycboRecipients.uboIds = _.map(kycboRecipients.ubOs, 'id');
        _kycboRecipients.contactIds = _.map(kycboRecipients.communicationList, 'id');
        if (
          !_.isEmpty(kycboRecipients.primaryContact) &&
          _kycboRecipients.contactIds.findIndex(
            (f: any) => f.id === kycboRecipients.primaryContact.id,
          ) === -1
        ) {
          _kycboRecipients.contactIds = _kycboRecipients.contactIds.concat(
            kycboRecipients.primaryContact?.id,
          );
        }
      }

      form.reset((formState) => ({
        ...formState,
        id,
        category,
        attachments,
        subject,
        clientRecipients: _clientRecipients,
        internalRecipients: _internalRecipients,
        investorRecipients: _investorRecipients,
        kycboRecipients: _kycboRecipients,
        content,
        investorRelationOrder,
        hasExternalMail: messageDetail?.hasExternalMail,
        externalEmails: messageDetail?.externalEmails?.map((it) => ({
          email: it,
        })),
      }));
    };

    const handleCloseUpsertModal = () => {
      const isDirtyAlt = !!Object.keys(form.formState.dirtyFields).length;
      if (isDirtyAlt && (!isExistingMessage || (isExistingMessage && isDraft))) {
        if (isInitialCommunication) {
          confirmCloseModalRef.current?.open();
        } else {
          handleSaveMessage();
        }
      } else {
        handleClose();
      }
    };

    const handleShowAlertModal = (alertInfo: IAlertInfo) => {
      setAlertInfo(alertInfo);
      alertRef.current?.open();
    };

    const getEmailContentExcludeFooter = (content: string) => {
      return content.split('<footer>')?.[0];
    };

    const handleSendEmail = (deliveryType: number) => {
      const formData = cloneDeep(form.getValues());
      isInitialCommunication && (formData.kycboId = kycBoId);
      if (watchCategory === CommunicationCategoryEnum.InvestorRelation) {
        // Only get the content exclude footer for investor relation case
        formData.emailContent = getEmailContentExcludeFooter(formData.content);
      }

      createCommunicationMessage(
        {
          data: {
            ...formData,
            deliveryType,
            externalEmails: (formData?.externalEmails || []).map((it) => it.email),
          },
        },
        {
          onSuccess: () => handleSendEmailSuccess(),
          onError: handleErrorFromServer,
          onSettled: () => !isInitialCommunication && handleClose(),
        },
      );
    };

    const handleSaveMessage = () => {
      const formData = cloneDeep(form.getValues());
      if (watchCategory === CommunicationCategoryEnum.InvestorRelation) {
        // Only get the content exclude footer for investor relation case
        formData.emailContent = getEmailContentExcludeFooter(formData.content);
      }
      updateCommunicationMessage(
        {
          data: {
            ...formData,
            externalEmails: (formData?.externalEmails || []).map((it) => it.email),
          },
        },
        {
          onSuccess: () => handleSendEmailSuccess(true),
          onError: handleErrorFromServer,
          onSettled: handleClose,
        },
      );
    };

    const handleSendEmailSuccess = (isSave?: boolean) => {
      if (isInitialCommunication) {
        handleShowAlertModal({
          title: 'You did it!',
          description: 'Navigate to Communication section to view your message.',
        });
      } else {
        const message = isSave ? 'Draft saved!' : 'Message sent';
        onRefresh?.();
        showToast(message, 'success');
      }
    };

    const handleClose = () => {
      form.reset(defaultValues);
      onClose?.();
      upsertDrawerRef.current?.close();
    };

    const handleConfirmClose = () => {
      confirmCloseModalRef.current?.close();
      alertRef?.current?.close();
      upsertDrawerRef.current?.close();
      form.reset(defaultValues);
    };

    const renderNewMessageButtons = () => {
      return (
        <MessageButtons
          category={watchCategory}
          onError={() =>
            handleShowAlertModal({
              title: '',
              isError: true,
              description: 'File size exceeds the limit. Please choose a file smaller than 2MB.',
            })
          }
          onSendViaPortal={form.handleSubmit(() =>
            handleSendEmail(CommunicationDeliveryType.Portal),
          )}
          onSendViaPortalAndEmail={form.handleSubmit(() =>
            handleSendEmail(CommunicationDeliveryType.PortalAndEmail),
          )}
          loading={isLoading}
          isInitialCommunication={isInitialCommunication}
        />
      );
    };

    const renderMessageActions = () => {
      const isOutboxSentStatus =
        viewMode === COMMUNICATION_VIEW_MODE.Outbox &&
        messageDetail?.outboxStatusName === communicationStatuses.Sent;

      return (
        <Box className='flex gap-2 w-full justify-end mt-3'>
          {!isOutboxSentStatus && messageDetail && (
            <CustomButton
              startIcon={<TurnSlightLeftIcon />}
              onClick={() => {
                setRepliedId(messageId as string);
                upsertDrawerRef.current?.close();
              }}
            >
              Reply
            </CustomButton>
          )}
          <CustomButton
            startIcon={<TurnSlightRightIcon />}
            onClick={() => {
              setForwardedId(messageId as string);
              upsertDrawerRef.current?.close();
            }}
          >
            Forward
          </CustomButton>
        </Box>
      );
    };

    const categoriesWithoutKycbo = () => {
      if (!isInitialCommunication) {
        return creatingCategories?.filter(
          (f: ICategoryInvestorItem) => Number(f.id) !== CommunicationCategoryEnum.KYCBO,
        );
      }
      return creatingCategories;
    };

    /**
     * creatingCategories: New or Draft Status
     * categories: existing
     */
    const categories = useMemo(() => {
      let _categories = categoriesData;
      if (!isExistingMessage || isDraft) {
        _categories = categoriesWithoutKycbo();
      }

      if (isInitialCommunication) {
        _categories = creatingCategories?.filter(
          (f: ICategoryInvestorItem) => Number(f.id) === CommunicationCategoryEnum.KYCBO,
        );
      }
      return _categories;
    }, [isExistingMessage, isDraft, creatingCategories, categoriesData]);

    return (
      <>
        <FormProvider {...form}>
          <CustomDrawer
            ref={upsertDrawerRef}
            title={messageId ? 'Message' : 'New Message'}
            onClose={handleCloseUpsertModal}
            buttonBoxProps={{
              className: 'flex',
            }}
            isConfirmBeforeClose
            ButtonComponents={
              isExistingMessage && !isDraft ? renderMessageActions() : renderNewMessageButtons()
            }
          >
            <MessageContent
              kycBoData={categoryKycBo}
              messageDetail={messageDetail}
              categories={categories}
              clientData={clientData}
              internalData={internalData}
              categoryInvestorData={categoryInvestorData}
              isCategoryDisabled={(isExistingMessage && !isDraft) || isInitialCommunication}
              isRecipientDisabled={isExistingMessage && !isDraft}
              isSubjectDisabled={isExistingMessage && !isDraft}
              isContentDisabled={isExistingMessage && !isDraft}
              recipientsError={recipientsError}
              isExistingMessage={isExistingMessage}
              viewMode={viewMode}
              messageType={COMMUNICATION_MESSAGE_TYPE.New}
            />
          </CustomDrawer>
        </FormProvider>
        <BasicModal ref={alertRef}>
          <ConfirmationAlert
            isError={alertInfo?.isError}
            title={alertInfo.title}
            description={alertInfo.description}
            buttonAction={{
              label: 'OK',
              onAction: () => {
                alertRef?.current?.close();
                isInitialCommunication && onClose && onClose();
              },
            }}
          />
        </BasicModal>
        <ConfirmModal
          title='Close Message?'
          content={`Message will not be saved.
        Are you sure you want to close the message.`}
          ref={confirmCloseModalRef}
          ButtonsComponent={
            <>
              <CustomButton
                sx={{ color: 'neutral.ne800' }}
                variant='text'
                onClick={() => confirmCloseModalRef?.current?.close()}
              >
                Cancel
              </CustomButton>
              <CustomButton color='error' onClick={handleConfirmClose}>
                Close
              </CustomButton>
            </>
          }
        />

        {repliedId && (
          <ReplyMessage
            kycBoData={categoryKycBo}
            categories={categories}
            internalData={internalData}
            clientData={clientData}
            categoryInvestorData={categoryInvestorData}
            repliedId={messageId}
            ref={replyingDrawerRef}
            onRefresh={onRefresh}
            onClose={() => {
              setRepliedId(null);
              upsertDrawerRef.current?.close();
            }}
            userMessageId={userMessageId}
          />
        )}
        {forwardedId && (
          <ForwardMessage
            kycBoData={categoryKycBo}
            categories={categoriesWithoutKycbo()}
            internalData={internalData}
            clientData={clientData}
            categoryInvestorData={categoryInvestorData}
            forwardedId={messageId}
            ref={forwardingDrawerRef}
            onRefresh={onRefresh}
            onClose={() => {
              setForwardedId(null);
              upsertDrawerRef.current?.close();
            }}
          />
        )}
      </>
    );
  },
);

export default NewMessage;
