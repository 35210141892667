/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IChevronDownIconProps extends React.SVGProps<SVGSVGElement> {}

export const ChevronDownIcon: React.FunctionComponent<IChevronDownIconProps> = ({
  width = '20px',
  height = '20px',
  color = '#9DA0A1',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Sprint-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='00_Navigation_03' transform='translate(-1396.000000, -26.000000)'>
          <rect x='0' y='0' width='1440' height='1080'></rect>
          <g id='05.Templates/01.Expand'>
            <rect id='Rectangle' x='0' y='0' width='1440' height='1080'></rect>
            <g id='03.Molecules/02.Header' transform='translate(260.000000, 0.000000)'>
              <rect id='Rectangle' x='0' y='0' width='1180' height='72'></rect>
              <g
                id='Account-Information'
                transform='translate(1001.000000, 14.000000)'
                fill={color}
                fillRule='nonzero'
              >
                <g
                  id='01.Design-Token/06.Icons/Fluent/Line/Chevron-Down'
                  transform='translate(135.000000, 12.000000)'
                >
                  <g id='Group' transform='translate(3.333333, 6.666667)'>
                    <path
                      d='M0.243804343,0.254085906 C0.568974987,-0.0707956111 1.09607663,-0.0707956111 1.42124728,0.254085906 L6.66145122,5.49121187 L11.9016552,0.254085906 C12.1107258,0.037746262 12.4203278,-0.0490171054 12.7114655,0.0271433729 C13.0026032,0.103303851 13.2299688,0.330535831 13.306174,0.62150252 C13.3823792,0.91246921 13.2955649,1.22188942 13.0790981,1.43083723 L7.25017269,7.25633886 C6.92500204,7.58122038 6.3979004,7.58122038 6.07272975,7.25633886 L0.243804343,1.43083723 C-0.0812681143,1.10585759 -0.0812681143,0.579065551 0.243804343,0.254085906 Z'
                      id='Path'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
