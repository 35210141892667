/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import AdminInvestorDetailIcon from 'src/assets/icons/AdminInvestorDetailIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import InvestmentApplicationTeamIcon from 'src/assets/icons/InvestmentApplicationTeamIcon';
import MoneyBagIcon from 'src/assets/icons/MoneyBagIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import CustomFormSectionHeader from 'src/components/pages/applications/components/CustomFormSectionHeader';
import {
  investmentDetailDefaultValues,
  investorDetailsDefaultValue,
  teamDefaultValues,
} from 'src/constants/administration-investor';
import { CLIENT_USER_ROLE } from 'src/constants/common';
import {
  useCreateInvestor,
  useGetInvestorById,
  useGetInvestorMasterData,
  useGetInvestorPoppulateData,
  useUpsertInvestorDetails,
  useUpsertInvestorInvestmentDetail,
  useUpsertInvestorTeam,
} from 'src/modules/administration-investor/hooks';
import {
  IInvestmentDetailForm,
  IInvestorDetailsForm,
  IInvestorTeamForm,
} from 'src/modules/administration-investor/types';
import { useGetContactListByTeamType } from 'src/modules/applications/hooks';
import { ITeamError } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo } from 'src/modules/common/type';
import { IUpsertInvestmentEntityForm } from 'src/modules/investment-entity/types';
import { handleErrorFromServer } from 'src/utils/common';
import {
  investmentDetailsSchema,
  investorDetailsSchema,
} from 'src/validations/administration-investor';
import InvestmentDetails from './InvestmentDetails';
import InvestorDetails from './InvestorDetails';
import InvestmentEntityTeam from './Team';

export interface IInvestmentApplicationFormProps extends IUpsertInvestmentEntityForm {
  onClose: (isOpen: boolean) => void;
}

export enum INVESTMENT_ENTITY_STEP {
  INVESTOR_DETAILS,
  INVESTMENT_DETAILS,
  TEAM,
}

type IFormData = {
  [key in INVESTMENT_ENTITY_STEP]: {
    form: UseFormReturn<any>;
    isDirty?: boolean;
    isValid?: boolean;
    submit: (mode: string) => void;
  };
};

const UpsertInvestorForm: FC<IInvestmentApplicationFormProps> = ({
  isEditMode,
  isViewMode,
  isDraft,
  id = '',
  onClose,
}) => {
  const isCreateMode = !isEditMode && !isViewMode;
  const stepperRef = useRef<ICustomStepperElement>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const invitedTeamIdsRef = useRef<string[]>([]);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS);
  const [teamErrors, setTeamErrors] = useState<ITeamError>({
    isPrimaryContactError: false,
    isCommunicationListError: false,
  });
  const [investorId, setInvestorId] = useState(id);

  const { data: currentUser } = useGetUserInfo();
  const { data: { investorTypes = [] } = {} } = useGetInvestorMasterData();
  const { data: investorDetail } = useGetInvestorById(
    id,
    INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS,
    (!isCreateMode && !isDraft) || currentStep === INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS,
  );
  const isSuperAdmin = currentUser?.role === CLIENT_USER_ROLE.SUPER_ADMIN;
  const isNotAllowToEditActiveInvestor = !isDraft && isEditMode && !isSuperAdmin; // Allow to Super Admin Only

  const { mutateAsync: upsertInvestmentDetail, isLoading: upsertingInvestmentDetail } =
    useUpsertInvestorDetails();
  const { mutateAsync: upsertTeam, isLoading: upsertingTeam } = useUpsertInvestorTeam();
  const {
    mutateAsync: upsertInvestorInvestmentDetail,
    isLoading: upsertingInvestorInvestmentDetail,
  } = useUpsertInvestorInvestmentDetail();
  const { mutate: createInvestor, isLoading: creatingInvestor } = useCreateInvestor();
  const isLoading =
    upsertingInvestmentDetail ||
    upsertingInvestorInvestmentDetail ||
    upsertingTeam ||
    creatingInvestor;

  const investorDetailsForm = useForm<IInvestorDetailsForm>({
    defaultValues: investorDetailsDefaultValue,
    resolver: yupResolver(investorDetailsSchema),
  });

  const investmentDetailForm = useForm<IInvestmentDetailForm>({
    defaultValues: investmentDetailDefaultValues,
    resolver: yupResolver(investmentDetailsSchema),
  });

  const teamForm = useForm<IInvestorTeamForm>({
    defaultValues: teamDefaultValues,
  });

  useEffect(() => {
    if (isEmpty(investorDetail?.investmentDetails) || isCreateMode) return;
    investmentDetailForm.reset((formState) => ({
      ...formState,
      ...investorDetail?.investmentDetails,
    }));
  }, [investorDetail?.investmentDetails, isCreateMode]);

  const [fundId, entityId, unitClassId] = [
    investorDetailsForm.watch('fundId') || '',
    investorDetailsForm.watch('investmentEntityId') || '',
    investorDetailsForm.watch('unitClassId') || '',
  ];
  const teamMates = teamForm.watch('teammates');

  const { primaryContacts, teams, communicationList } = useGetContactListByTeamType(teamMates);
  const { data: populateData } = useGetInvestorPoppulateData(
    fundId,
    unitClassId,
    entityId,
    currentStep === INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS,
  );
  const { fund, unitClass, investorNumber, isExistingInvestor } = populateData || {};

  useEffect(() => {
    if (isCreateMode && !isEmpty(populateData)) {
      investorDetailsForm.setValue(
        'mannualInvestorNumber',
        isExistingInvestor ? investorNumber || '' : unitClass?.code,
      );
      investorDetailsForm.setValue('currentUnitPrice', unitClass?.currentUnitPrice);
    }
  }, [populateData]);

  useEffect(() => {
    handleTeamValidation();
  }, [teamMates]);

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleShowAlert = ({ title, description }: IAlertInfo) => {
    setAlertInfo({ title, description });
    alertRef.current?.open();
  };

  const onSubmitInvestorDetails = async (mode: string) => {
    const data = investorDetailsForm.getValues();
    await upsertInvestmentDetail(
      { id: investorId, data },
      {
        onSuccess: (id: string) => {
          if (id) {
            setInvestorId(id);
          }
          handleSuccess(mode);
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitInvestmentDetail = async (mode: string) => {
    const data = investmentDetailForm.getValues();
    await upsertInvestorInvestmentDetail(
      { id: investorId, data },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const onSubmitTeam = async (mode: string) => {
    const formData = cloneDeep(teamForm.getValues());
    const invitedTeamIds = (formData?.invitedTeamIds || [])
      .filter((item) => !invitedTeamIdsRef.current?.includes(item.value) || item.isResend)
      .map((it) => it.value);
    const data = { ...formData, invitedTeamIds };

    if (handleTeamValidation()) {
      await upsertTeam(
        {
          id: investorId,
          data,
        },
        {
          onSuccess: () => {
            if ((isCreateMode || isDraft) && mode === SUBMIT_FORM_ACTIONS.SUBMIT) {
              handleCreateInvestor();
            } else {
              handleSuccess(mode);
            }
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const handleCreateInvestor = () => {
    createInvestor(investorId, {
      onSuccess: () => {
        handleShowAlert({
          title: 'You did it !',
          description: `Investor created successfully.`,
        });
      },
      onError: handleErrorFromServer,
    });
  };

  const handleTeamValidation = () => {
    setTeamErrors({
      isPrimaryContactError: primaryContacts.length === 0,
      isCommunicationListError: communicationList.length === 0,
    });
    if (primaryContacts.length && communicationList.length) {
      return true;
    }
    return false;
  };

  const formData: IFormData = {
    [INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS]: {
      form: investorDetailsForm,
      isDirty: investorDetailsForm.formState.isDirty,
      isValid: investorDetailsForm.formState.isValid,
      submit: onSubmitInvestorDetails,
    },
    [INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS]: {
      form: investmentDetailForm,
      isDirty: investmentDetailForm.formState.isDirty,
      isValid: investmentDetailForm.formState.isValid,
      submit: onSubmitInvestmentDetail,
    },
    [INVESTMENT_ENTITY_STEP.TEAM]: {
      form: teamForm,
      isDirty: teamForm.formState.isDirty,
      isValid: teamForm.formState.isValid,
      submit: onSubmitTeam,
    },
  };

  const handleSave = (stepSelected: INVESTMENT_ENTITY_STEP, mode: string) => {
    if (
      isViewMode ||
      (isNotAllowToEditActiveInvestor && stepSelected !== INVESTMENT_ENTITY_STEP.TEAM)
    ) {
      return handleGoToNextStep();
    }
    if (isEditMode && !isDraft) {
      if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
        handleSetErrorSteps();
        handleGoToNextStep();
      } else {
        handleInvestmentEntitySubmitAllSection();
      }
      return;
    }
    const { form, submit } = formData[stepSelected];
    form.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
    form.handleSubmit(() => submit(mode))();
  };

  const handleSuccess = (mode?: string) => {
    if (isEditMode && !isDraft) return;
    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleShowAlert({
        title: 'Investor Saved!',
        description: 'You can resume at any time.',
      });
    } else {
      handleGoToNextStep();
    }
  };

  const handleSetErrorSteps = () => {
    const validArray = Object.values(formData).map(({ form }) => form.formState.isValid);
    const errorStepList = validArray
      .map((it, id) => (it ? null : id))
      .filter((it) => it !== null) as number[];
    setErrorSteps(errorStepList);
    Object.values(formData).map(({ form }) => form.trigger());
    return !errorStepList.length;
  };

  const handleClickStep = () => {
    if (isViewMode) return;
    handleSetErrorSteps();
  };

  const handleInvestmentEntitySubmitAllSection = () => {
    const isValid = handleSetErrorSteps();
    if (!isValid) {
      return;
    }

    Promise.all([
      Object.values(formData)
        .filter(({ form }) => form.formState.isDirty)
        .map(({ submit }) => submit(SUBMIT_FORM_ACTIONS.SUBMIT)),
    ])
      .then(() => {
        handleShowAlert({
          title: 'You did it!',
          description: `Investor has been updated successfully.`,
        });
      })
      .catch((error) => {
        console.log('Submit all error: ', error);
      });
  };

  const getDefaultStepProps = (step: number) => ({
    step,
    isViewMode,
    isEditMode,
    isCreateMode,
    isDraft,
    isLoading,
    currentStep,
    onSubmit: () => {
      if (stepperRef.current?.getCurrentStep() === step) {
        handleSave(step, SUBMIT_FORM_ACTIONS.SUBMIT);
      }
    },
    onSave: () => handleSave(step, SUBMIT_FORM_ACTIONS.DRAFT),
    id: investorId,
    isSuperAdmin,
    isDisabledEdit: !isDraft && isEditMode,
    currencyName: unitClass?.currencyName,
    hiddenSubmitBtn: isNotAllowToEditActiveInvestor,
  });

  const STEPS = [
    {
      key: INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS,
      label: 'Investor Details',
      content: (
        <FormProvider {...investorDetailsForm}>
          <FormSection
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS)}
            customHeader={
              <CustomFormSectionHeader
                title='Investor Details'
                icon={<AdminInvestorDetailIcon />}
              />
            }
          >
            <InvestorDetails
              {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS)}
              investorTypes={investorTypes}
              fundId={fundId}
              fundType={fund?.type}
              isExistingInvestor={isExistingInvestor}
              offerType={unitClass?.offerType}
              investorUnitRounding={fund?.investorUnitRounding}
              unitPriceRounding={fund?.unitPriceRounding}
              allowCapitalCall={unitClass?.allowCapitalCall}
              allowPartiallyPaidUnits={unitClass?.allowPartiallyPaidUnits}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS,
      label: 'Investment Details',
      content: (
        <FormProvider {...investmentDetailForm}>
          <FormSection
            customHeader={
              <CustomFormSectionHeader title='Investment Details' icon={<MoneyBagIcon />} />
            }
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS)}
          >
            <InvestmentDetails
              {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.INVESTMENT_DETAILS)}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: INVESTMENT_ENTITY_STEP.TEAM,
      label: 'Team',
      content: (
        <FormProvider {...teamForm}>
          <FormSection
            customHeader={
              <CustomFormSectionHeader title='Team' icon={<InvestmentApplicationTeamIcon />} />
            }
            {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.TEAM)}
            isLastStep
            createBtnText='Create'
            hiddenSubmitBtn={false}
          >
            <InvestmentEntityTeam
              {...getDefaultStepProps(INVESTMENT_ENTITY_STEP.TEAM)}
              errors={teamErrors}
              entityId={entityId}
              invitedTeamIdsRef={invitedTeamIdsRef}
              onSubmitTeam={onSubmitTeam}
            />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {isCreateMode ? 'Create New Investor' : isEditMode ? 'Edit Investor' : 'View Investor'}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 10 }}
            onClickStep={handleClickStep}
            errorSteps={errorSteps}
            enableClickStep={(!isCreateMode && !isDraft && !isLoading) || isViewMode}
            showErrorAllStep
            onStepChange={(stepChanged: number) => setCurrentStep(stepChanged)}
          />
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              onClose?.(false);
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default UpsertInvestorForm;
