import { ToastOptions } from 'react-toastify';

export const TOAST_CONFIG: ToastOptions = {
  toastId: 'toast-id',
  position: 'top-right',
  autoClose: 1500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  style: {
    fontSize: '15px',
    borderRadius: '4px',
  },
};
