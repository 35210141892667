import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import CustomButton from 'src/components/atoms/CustomButton';

interface IPopulateDetailsConfirmProps {
  onClose: () => void;
  onConfirm: () => void;
}

const PopulateDetailsConfirm: FC<IPopulateDetailsConfirmProps> = ({ onClose, onConfirm }) => {
  const [isConfirm, setIsConfirm] = useState(false);

  const handleCheckConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsConfirm(checked);
  };

  return (
    <Box className='px-10 pb-8'>
      <FormControlLabel
        control={<Checkbox value={isConfirm} onChange={handleCheckConfirm} sx={{ p: 0 }} />}
        label={
          <Typography
            variant='body2'
            pl={1.5}
            sx={{
              maxWidth: 373,
            }}
          >
            I confirm that the displayed details are correct and I am authorised to conduct the
            search on behalf of the entity.
          </Typography>
        }
        labelPlacement='end'
        sx={{
          alignItems: 'flex-start',
          margin: 0,
        }}
      />
      <Box className='pt-8 flex justify-end'>
        <CustomButton
          variant='text'
          size='large'
          sx={(theme) => ({ color: theme.palette.neutral.ne800 })}
          onClick={onClose}
        >
          Cancel
        </CustomButton>
        <CustomButton size='large' disabled={!isConfirm} onClick={onConfirm}>
          Confirm
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PopulateDetailsConfirm;
