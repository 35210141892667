import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  IAddtionalDocument,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { getFileTypeIcon } from './DocumentUpload';

interface ISelectExistingDocumentsProps extends IUpsertInvestmentApplicationForm {
  documents: IAddtionalDocument[];
  selectedDocs: IAddtionalDocument[];
  onAddNew: (item: IAddtionalDocument) => void;
  onRemove: (item: IAddtionalDocument) => void;
}

const SelectExistingDocuments = ({
  isEditMode,
  isViewMode,
  documents,
  selectedDocs,
  onAddNew,
  onRemove,
}: ISelectExistingDocumentsProps) => {
  const disabled = isEditMode || isViewMode;

  const handleSelect = (item: IAddtionalDocument, isSelected: boolean) => {
    if (disabled) return;
    isSelected ? onRemove(item) : onAddNew({ ...item, isExisting: true });
  };

  const renderEmpty = () => {
    return (
      <Box className='text-center py-2'>
        <Typography variant='body3' color='neutral.ne800'>
          No data to display
        </Typography>
      </Box>
    );
  };

  return (
    <Box my={2}>
      <Typography variant='body2' fontWeight={500}>
        Select Existing
      </Typography>
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        {!isEmpty(documents) ? (
          <Grid container spacing={2}>
            {(documents || []).map((item: IAddtionalDocument, index: number) => {
              const isSelected = selectedDocs.some((f) => f.id === item.id);
              const docType = item.fileName.split('.').pop();

              return (
                <React.Fragment key={`item-${index}`}>
                  <Grid item xs={1} className='flex items-center justify-center'>
                    <Box
                      className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
                      bgcolor='neutral.ne200'
                    >
                      {getFileTypeIcon(item.fileType)}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className='flex items-center '>
                      <Typography variant='body3' mr='4px'>
                        {item.fileName}
                      </Typography>
                    </Box>
                    <Typography variant='body3' color='neutral.ne800' className='uppercase'>
                      {docType}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className='flex items-center justify-end'>
                    <Box
                      className={clsx('px-[9px] py-[5px] rounded-[4px]', {
                        'cursor-pointer': !disabled,
                      })}
                      bgcolor='neutral.ne200'
                      onClick={() => handleSelect(item, isSelected)}
                    >
                      <Typography
                        color={disabled ? 'neutral.ne400' : 'primary.main'}
                        variant='body3'
                        fontWeight={500}
                      >
                        {isSelected ? 'Unselect' : 'Select'}
                      </Typography>
                    </Box>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          renderEmpty()
        )}
      </Box>
    </Box>
  );
};

export default SelectExistingDocuments;
