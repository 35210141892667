import { FC } from 'react';
import { detectPortalType } from 'src/helpers/common';
import ClientApplications from './ClientApplications';
import InvestorApplications from './InvestorApplications';

const Applications: FC = () => {
  const { isFunds } = detectPortalType();

  return isFunds ? <ClientApplications /> : <InvestorApplications />;
};

export default Applications;
