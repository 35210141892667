/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface INewHoldingIconProps {}

export const NewHoldingIcon: React.FunctionComponent<INewHoldingIconProps> = () => {
  return (
    <svg
      width='33.75px'
      height='35.625px'
      viewBox='0 0 33.75 35.625'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Settings_Investment-Entity_00'
          transform='translate(-603.125, -193.125)'
          fill='#33B27F'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(550, 140)'>
            <g id='Remove-Copy-6' transform='translate(40, 40)'>
              <g id='01.Design-Token/06.Icons/Fluent/Line/Dismiss' transform='translate(7.5, 7.5)'>
                <g id='Group' transform='translate(5.625, 5.625)'>
                  <path
                    d='M16.8657668,1.202563e-05 C24.4712572,-0.00969009712 30.7092429,5.85246583 30.9529372,13.2384625 L30.9604544,13.6949604 L30.9604544,21.1760476 L33.5538769,26.9388768 C33.8707503,27.6443327 33.8003455,28.4574529 33.3666196,29.1015518 C32.9328937,29.7456506 32.1942711,30.1339655 31.4020879,30.1343619 L22.5036418,30.1398399 C22.5076205,33.1050599 20.0826024,35.5350614 17.0321551,35.6225702 C13.9817078,35.7100791 11.4133643,33.4233234 11.2372882,30.4630404 L11.2278917,30.1343619 L2.34823856,30.1343619 C1.5572953,30.1334587 0.819829358,29.7461809 0.385732419,29.1037583 C-0.0483645195,28.4613358 -0.120905169,27.6498802 0.192691003,26.9443548 L2.77107918,21.1778736 L2.77107918,13.6949604 C2.77107918,6.10796567 9.07046489,1.202563e-05 16.8657668,1.202563e-05 L16.8657668,1.202563e-05 Z M19.6847043,30.1361879 L14.0468292,30.1398399 C14.0465729,31.6011973 15.2271073,32.8053708 16.7293594,32.8760853 C18.2316115,32.9467998 19.5264175,31.8591464 19.6715492,30.4046087 L19.682825,30.1361879 L19.6847043,30.1361879 Z M16.8657668,2.73899134 C10.6265184,2.73899134 5.5900167,7.62171263 5.5900167,13.6949604 L5.5900167,21.7475829 L3.06424869,27.3972007 L30.6841985,27.3972007 L28.1415168,21.7494089 L28.1415168,13.7186983 L28.1339997,13.3078502 C27.93104,7.40655818 22.9426951,2.72782873 16.8657668,2.73899134 Z'
                    id='Shape'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
