/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IChatIconProps {
  color?: string;
}

export const ChatIcon: FC<IChatIconProps> = ({ color }) => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_Navigation_03'
          transform='translate(-23.000000, -304.000000)'
          fill={color || '#FFFFFF'}
          fillRule='nonzero'
        >
          <g id='Communication-Menu' transform='translate(11.000000, 292.000000)'>
            <g id='Group' transform='translate(12.000000, 12.000000)'>
              <g id='Group' transform='translate(2.000000, 2.000000)'>
                <path
                  d='M10.0000083,0 C15.5230168,0 20.0000083,4.477 20.0000083,10 C20.0000083,15.523 15.5230168,20 10.0000083,20 C8.40400642,20.0022025 6.83083783,19.6208283 5.41301677,18.888 L1.58701677,19.955 C1.15241493,20.0762874 0.686179187,19.9539432 0.367126377,19.6348904 C0.0480735671,19.3158376 -0.0742706576,18.8496018 0.047016766,18.415 L1.11501677,14.592 C0.380368723,13.1729389 -0.00206045176,11.597948 8.34905382e-06,10 C8.34905382e-06,4.477 4.47701677,0 10.0000083,0 Z M10.0000168,1.5 C5.30559639,1.5 1.50001677,5.30557963 1.50001677,10 C1.50001677,11.47 1.87301677,12.883 2.57301677,14.137 L2.72301677,14.407 L1.61101677,18.391 L5.59801677,17.279 L5.86801677,17.429 C8.97630849,19.1571457 12.8318754,18.7675142 15.5315711,16.4524313 C18.2312667,14.1373485 19.2042949,10.3862919 17.9703591,7.05082075 C16.7364234,3.71534961 13.5564145,1.50068197 10.0000168,1.5 Z M6.75001677,11 L11.2480168,11 C11.6423009,11.0001208 11.9691683,11.3055128 11.9960427,11.69888 C12.0229171,12.0922472 11.740621,12.4392565 11.3500168,12.493 L11.2480168,12.5 L6.75001677,12.5 C6.35573261,12.4998792 6.02886522,12.1944872 6.00199084,11.80112 C5.97511645,11.4077528 6.25741256,11.0607435 6.64801677,11.007 L6.75001677,11 L11.2480168,11 L6.75001677,11 Z M6.75001677,7.5 L13.2550168,7.5 C13.6491106,7.50064258 13.9754843,7.8061823 14.0020836,8.19937793 C14.0286829,8.59257356 13.7464334,8.93928606 13.3560168,8.993 L13.2550168,9 L6.75001677,9 C6.35573261,8.99987915 6.02886522,8.69448722 6.00199084,8.30111999 C5.97511645,7.90775276 6.25741256,7.5607435 6.64801677,7.507 L6.75001677,7.5 L13.2550168,7.5 L6.75001677,7.5 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
