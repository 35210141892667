/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, Grid, Typography, styled } from '@mui/material';
import * as React from 'react';
import { ContactLawyer } from 'src/assets/icons/ContactLawyer';
import CustomButton from 'src/components/atoms/CustomButton';
import { SUBSCRIPTION_FEATURE_TYPE } from 'src/constants/subscription-billing';
import { useGetOrganisationSubscriptions } from 'src/modules/organisation-settings/hooks';
import SelectColorBox from './SelectColorBox';

interface SystemButtonsSettingProps extends BoxProps {
  primaryBackground: string;
  primaryText: string;
  contactLawyerText: string;
  contactLawyerBackground: string;
  onChangeFields: (field: string, value: string) => void;
  disabled?: boolean;
}

const CustomTitleBox = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    padding: '5px 10px',
    marginBottom: '25px',

    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  },
}));

const SystemButtonsSetting: React.FC<SystemButtonsSettingProps> = ({
  primaryBackground,
  primaryText,
  contactLawyerBackground,
  contactLawyerText,
  onChangeFields,
  disabled,
  sx,
  ...props
}) => {
  const { data: subscriptions } = useGetOrganisationSubscriptions();

  return (
    <Box {...props} sx={{ backgroundColor: 'base.white', p: 3, borderRadius: '10px', ...sx }}>
      <Typography variant='h6' mb='24px'>
        System Buttons
      </Typography>
      <Grid container>
        <Grid xs={4} item>
          <CustomTitleBox>
            <Typography variant='body1'>Primary button</Typography>
          </CustomTitleBox>
          <CustomButton
            sx={{
              backgroundColor: primaryBackground,
              color: primaryText,
            }}
          >
            Button
          </CustomButton>
        </Grid>
        <Grid xs={8} item>
          <SelectColorBox
            disabled={disabled}
            title='Background colour'
            currentColor={primaryBackground}
            onChangeColor={(color) => onChangeFields('primaryBackground', color)}
          />
          <SelectColorBox
            pt={3}
            disabled={disabled}
            title='Text colour'
            currentColor={primaryText}
            onChangeColor={(color) => onChangeFields('primaryText', color)}
          />
        </Grid>
      </Grid>
      {!!subscriptions?.find(
        (it) => it.featureType === SUBSCRIPTION_FEATURE_TYPE.ContactLawyer,
      ) && (
        <Grid container pt='40px'>
          <Grid xs={4} item>
            <CustomTitleBox>
              <Typography variant='body1'>Contact a Lawyer button</Typography>
            </CustomTitleBox>
            <CustomButton
              startIcon={<ContactLawyer color={contactLawyerText} />}
              sx={{
                backgroundColor: contactLawyerBackground || 'secondary.main',
              }}
              className='h-12'
            >
              <Typography variant='body2' color={contactLawyerText}>
                Contact a Lawyer
              </Typography>
            </CustomButton>
          </Grid>
          <Grid xs={8} item>
            <SelectColorBox
              disabled={disabled}
              title='Background colour'
              currentColor={contactLawyerBackground}
              onChangeColor={(color) => onChangeFields('contactLawyerBackground', color)}
            />
            <SelectColorBox
              pt={3}
              disabled={disabled}
              title='Text colour'
              currentColor={contactLawyerText}
              onChangeColor={(color) => onChangeFields('contactLawyerText', color)}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SystemButtonsSetting;
