import * as React from 'react';

interface INotificationFilledIconProps extends React.SVGProps<SVGSVGElement> {}

const NotificationFilledIcon: React.FunctionComponent<INotificationFilledIconProps> = (props) => {
  return (
    <svg
      {...props}
      width='14.9985427px'
      height='15.8333333px'
      viewBox='0 0 14.9985427 15.8333333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-3.2%'
          y='-2.0%'
          width='106.5%'
          height='105.6%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx={0} dy={5} in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation={5} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Global-Admin-Portal_Notification_01'
          transform='translate(-694.5, -277.5)'
          fill={props.color || '#858A8B'}
          fillRule='nonzero'
        >
          <g id='Group-26-Copy-2' filter='url(#filter-1)' transform='translate(654, 71)'>
            <g id='Group-8' transform='translate(24, 190)'>
              <g id='01.Design-Token/06.Icons/Fluent/Filled/Folder' transform='translate(14, 14)'>
                <g id='Group' transform='translate(2.5, 2.5)'>
                  <path
                    d='M5.03320723,13.8035858 L9.96320723,13.8035858 C9.75980308,14.9755311 8.71805646,15.8333333 7.49820723,15.8333333 C6.27835799,15.8333333 5.23661137,14.9755311 5.03320723,13.8035858 L5.03320723,13.8035858 Z M7.49820723,0 C10.9499869,0 13.7482072,2.72650406 13.7482072,6.08981727 L13.7482072,9.33771982 L14.9298739,11.9035628 C15.0390237,12.1419891 15.0169956,12.4180299 14.8713334,12.6371511 C14.7256713,12.8562723 14.4761446,12.9887345 14.2082072,12.9891743 L0.791540562,12.9891743 C0.523227274,12.9891743 0.2731971,12.856672 0.127289056,12.6372701 C-0.0186189875,12.4178681 -0.0405442389,12.1413885 0.0690405616,11.9027509 L1.24820723,9.33690785 L1.24820723,6.07926159 L1.25237389,5.87626768 C1.3708586,2.59852654 4.13216447,0.000965976373 7.49820723,0.000811975637 L7.49820723,0 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NotificationFilledIcon;
