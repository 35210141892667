import { capitalize } from 'lodash';
import { actionPermissionKeys } from './common';

export const kycBackOfficeStatuses = {
  In_Review: 'In Review',
  Submitted: 'Submitted',
  Archived: 'Archived',
  Approved: 'Approved',
  Action_Required: 'Action Required',
  Awaiting_Approval: 'Awaiting Approval',
  Withdrawn: 'Withdrawn',
  Closed: 'Closed',
};

export const kycBackOfficeActionTypes = {
  view: 'view',
  edit: 'edit',
  startCase: 'startCase',
  reAssign: 'reAssign',
  close: 'close',
  open: 'open',
  editBilling: 'editBilling',
};

export const KYC_BO_ACTIONS = {
  view: {
    key: kycBackOfficeActionTypes.view,
    label: capitalize(kycBackOfficeActionTypes.view),
    permissionKey: actionPermissionKeys.isView,
  },
  edit: {
    key: kycBackOfficeActionTypes.edit,
    label: capitalize(kycBackOfficeActionTypes.edit),
    permissionKey: actionPermissionKeys.isEdit,
  },
  startCase: {
    key: kycBackOfficeActionTypes.startCase,
    label: 'Start Case',
    permissionKey: actionPermissionKeys.isEdit,
  },
  reAssign: {
    key: kycBackOfficeActionTypes.reAssign,
    label: 'Re-assign',
    permissionKey: actionPermissionKeys.isEdit,
  },
  close: {
    key: kycBackOfficeActionTypes.close,
    label: capitalize(kycBackOfficeActionTypes.close),
  },
  open: {
    key: kycBackOfficeActionTypes.open,
    label: capitalize(kycBackOfficeActionTypes.open),
  },
  editBilling: {
    key: kycBackOfficeActionTypes.editBilling,
    label: 'Edit Billing',
    permissionKey: actionPermissionKeys.isEdit,
  },
};

export const kycboUboOverallStatus = {
  Not_Required: 'Not Required',
  Required: 'Required',
  Pending: 'Pending',
  Verified: 'Verified',
  Removed: 'Removed',
};

export const kycboUboKycStatus = {
  Not_Started: 'Not Started',
  In_Progress: 'In Progress',
  Verified: 'Verified',
  Manually_Verified: 'Manually Verified',
  KYC_Completed_Externally: 'KYC Completed Externally',
};

export const kycboUboAmlStatus = {
  Not_Started: 'Not Started',
  Match_Found: 'Match Found',
  No_Match_Found: 'No Match Found',
  Manually_Verified: 'Manually Verified',
  KYC_Completed_Externally: 'KYC Completed Externally',
};

export enum KYC_SECTION_ENUM {
  APPLICATION_DETAILS,
  INVESTOR_DETAILS,
  OWNERSHIP_AND_UBO,
  DOCUMENTS,
  REVIEW,
  BILLING,
}

export enum KYC_TAB_ENUM_GA_PORTAL {
  APPLICATION_DETAILS,
  INVESTOR_DETAILS,
  OWNERSHIP_AND_UBO,
  DOCUMENTS,
  BILLING,
  REVIEW,
}

export enum ENTITY_RISK_RATING {
  Low,
  Medium,
  High,
  Extreme,
}

export const EntityRiskRatingOptions = [
  {
    label: 'Low',
    value: ENTITY_RISK_RATING.Low.toString(),
  },
  {
    label: 'Medium',
    value: ENTITY_RISK_RATING.Medium.toString(),
  },
  {
    label: 'High',
    value: ENTITY_RISK_RATING.High.toString(),
  },
  {
    label: 'Extreme',
    value: ENTITY_RISK_RATING.Extreme.toString(),
  },
];

export const riskFlagStatus = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
};

export enum QUESTION_TYPE {
  Text,
  Select = 4,
  DatePicker = 8,
}

export const groupKycReviewQuestionType = {
  Investor_Details_Verification: 'Investor Details Verification',
  UBOs_Verification: 'UBOs Verificationtted',
  Wholesale_Investor_Status: 'Wholesale Investor Status',
};

export enum REQUIRED_OPTION {
  YES,
  NO,
  NONE,
}
