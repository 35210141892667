import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CurrencyName from 'src/components/atoms/CurrencyName';
import CustomAlert from 'src/components/atoms/CustomAlert';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormSelect from 'src/components/atoms/FormSelect';
import { REDEEM_OPTION } from 'src/constants/administration-investor';
import { RedemptionOptionsType } from 'src/constants/unit-class';
import { useGetInvestorDetailRedeem } from 'src/modules/administration-investor/hooks';
import { IRedemptionForm } from 'src/modules/administration-investor/types';
import { useGetUnitByDate } from 'src/modules/fund-registry/hooks';
import { handleErrorFromServer } from 'src/utils/common';
import { InvestorDetailsProps } from '..';
import RedemptionUnitPriceTabs from './RedemptionUnitPriceTabs';

interface IRedemptionFormProps extends InvestorDetailsProps {
  detailIssuedUnits?: number | null;
  investorUnitRounding: number;
  detailUnpaidAmount?: number;
}

const RedemptionForm: FC<IRedemptionFormProps> = (props) => {
  const {
    id = '',
    currencyName,
    detailIssuedUnits,
    investorUnitRounding,
    unitClassId = '',
    fundType,
    allowCapitalCall,
    allowPartiallyPaidUnits,
    detailUnpaidAmount,
  } = props;
  const { data } = useGetInvestorDetailRedeem(id);
  const { mutate: getUnitByDate } = useGetUnitByDate();
  const { options = [], unitPriceRounding, currentUnitPrice, redemptionAllowedType } = data || {};
  const redeemOptions = options.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const form = useFormContext<IRedemptionForm>();
  const watchOption = form.watch('option');

  // Get exit unit price and mid price for the first time use the current date
  useEffect(() => {
    handleGetExitMidPrice(dayjs().endOf('day').utc().format());
  }, []);

  // Set the unitPriceRounding to validate price fields in yup validation
  useEffect(() => {
    if (Number.isInteger(unitPriceRounding)) {
      form.setValue('unitPriceRounding', unitPriceRounding);
      form.trigger('currentUnitPrice');
      form.trigger('entryUnitPrice');
    }
  }, [unitPriceRounding]);

  const handleGetExitMidPrice = (strikeDate: string) => {
    getUnitByDate(
      {
        strikeDate,
        unitClassId,
      },
      {
        onSuccess: (data) => {
          const { midPrice, exitPrice } = data;
          form.setValue('entryUnitPrice', exitPrice);
          form.setValue('currentUnitPrice', midPrice);
          form.trigger('currentUnitPrice');
          form.trigger('entryUnitPrice');
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleTransactionDateChange = (date: any) => {
    form.trigger('transactionDate');
    /**
     * Handle for manually typing the date:
     * Check the date is valid and the date in the past or current date
     * Then we allow to call the API to get price
     */
    const isValid = dayjs(date).isValid() && !dayjs(date).isAfter();
    if (isValid) {
      handleGetExitMidPrice(dayjs(date).endOf('day').utc().format());
    }
  };

  return (
    <Box>
      {redemptionAllowedType === RedemptionOptionsType.No && (
        <CustomAlert className='mb-6' severity='warning'>
          Redemptions are not allowed for this holding. Are you sure you want to continue?
        </CustomAlert>
      )}
      <Grid container rowSpacing='18px'>
        <Grid item xs={12}>
          <FormSelect
            name='option'
            label='Option'
            options={redeemOptions}
            placeholder='Select option'
            menuItemSx={{
              maxWidth: 'auto',
            }}
          />
        </Grid>
        {Number.isInteger(watchOption) && (
          <>
            <Grid item xs={12}>
              <FormDatePicker
                name='transactionDate'
                label='Transaction Date'
                disableFuture
                onChange={handleTransactionDateChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      {Number.isInteger(watchOption) && (
        <>
          <RedemptionUnitPriceTabs
            {...{
              fundType,
              allowCapitalCall,
              allowPartiallyPaidUnits,
              unitPriceRounding,
              currentUnitPrice,
              detailIssuedUnits,
              unitClassId,
              detailUnpaidAmount,
            }}
          />
          <Grid container columnSpacing={3} pt={4}>
            <Grid item md={6} xs={12}>
              <FormCurrencyInput
                name='amount'
                label='Amount'
                decimalScale={2}
                hideCurrency
                placeholder='Enter amount'
                endAdornment={<CurrencyName currencyName={currencyName} color='neutral.ne500' />}
                disabled={watchOption !== REDEEM_OPTION.AMOUNT}
                fixedDecimalScale
                allowNegative
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormCurrencyInput
                name='numberOfUnits'
                label='Units'
                decimalScale={investorUnitRounding}
                hideCurrency
                placeholder='Enter units'
                disabled={watchOption !== REDEEM_OPTION.UNIT}
                fixedDecimalScale
              />
            </Grid>
          </Grid>
          <Box className='mt-8'>
            <FormCheckbox
              name='isSendMail'
              label='Send confirmation of redemption letter to investor.'
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RedemptionForm;
