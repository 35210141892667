import { Breadcrumbs, Link, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { Link as LinkRoute, useLocation } from 'react-router-dom';
import IosArrowRightIcon from 'src/assets/icons/IosArrowRightIcon';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { getNavigationList } from 'src/helpers/navigate';

interface ICustomBreadcrumbsProps {}

const CustomBreadcrumbs: FC<ICustomBreadcrumbsProps> = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((item: string) => !!item);

  // NEED TO ENHANCEMENT
  const renderParentTitle = () => {
    const navigationList = getNavigationList();
    const parent = navigationList.find((nav) =>
      nav.submenu?.map((sub) => sub.path).includes(`/${pathnames[0]}`),
    );
    let parentTitle = '';

    if (parent) parentTitle = parent.title;

    return (
      parentTitle && (
        <Typography variant='body2' color='neutral.ne500'>
          {parentTitle}
        </Typography>
      )
    );
  };

  return (
    <Breadcrumbs
      className='pb-6 capitalize'
      aria-label='Breadcrumb'
      separator={<IosArrowRightIcon />}
    >
      {renderParentTitle()}
      {pathnames.length ? (
        pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const realPath = Object.values(ROUTES_PATH).includes(to) ? to : '#';
          const title = value.split('-').join(' ');
          const formatTitle = capitalize(title).replaceAll(/[kK][yY][cC]/g, 'KYC');

          return last ? (
            <LinkRoute key={to} to={realPath}>
              <Link component='span' underline='hover' variant='body2' color='base.black'>
                {formatTitle}
              </Link>
            </LinkRoute>
          ) : (
            <LinkRoute key={to} to={realPath}>
              <Link component='span' underline='hover' variant='body2' color='neutral.ne500'>
                {formatTitle}
              </Link>
            </LinkRoute>
          );
        })
      ) : (
        <LinkRoute to='/'>
          <Link component='span' underline='hover' variant='body2' color='base.black'>
            Dashboard
          </Link>
        </LinkRoute>
      )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
