import { Box, ButtonProps, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { MoreIcon } from 'src/assets/icons/MoreIcon';
import CustomButton from './CustomButton';
import { CustomMenu, ICustomMenuElement, IMenuItem } from './CustomMenu';

interface IOptionsButtonProps extends ButtonProps {
  optionIconColor?: string;
  options?: IMenuItem[];
}

const OptionsButton: React.FunctionComponent<IOptionsButtonProps> = ({
  optionIconColor,
  children,
  options,
  ...rest
}) => {
  const menuRef = useRef<ICustomMenuElement>(null);

  const handleClickOptionButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isEmpty(options)) return;
    menuRef.current?.open(event.currentTarget);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={10}>
          <CustomButton
            fullWidth
            {...rest}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 0,
              ...rest.sx,
            }}
          >
            {children}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton
            fullWidth
            {...rest}
            startIcon={<MoreIcon color={optionIconColor} />}
            sx={{
              minWidth: 0,
              height: '100%',
              p: 0,
              '& .MuiButton-startIcon': {
                m: 0,
              },
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              ...rest.sx,
            }}
            onClick={handleClickOptionButton}
          />
        </Grid>
      </Grid>
      <CustomMenu menuItems={options || []} ref={menuRef} />
    </Box>
  );
};

export default OptionsButton;
