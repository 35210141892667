import * as React from 'react';

interface IContactNumberIconProps {}

const ContactNumberIcon: React.FunctionComponent<IContactNumberIconProps> = (props) => {
  return (
    <svg
      width='10.6666667px'
      height='13.3333333px'
      viewBox='0 0 10.6666667 13.3333333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Administration_Fundraising_Create-New-Offer_27'
          transform='translate(-998.666667, -1192.333333)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 127.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 187.000000)'>
              <g id='Content' transform='translate(210.000000, 166.000000)'>
                <g id='Group-2' transform='translate(496.000000, 572.000000)'>
                  <g id='Group-Copy-3' transform='translate(0.000000, 138.000000)'>
                    <g
                      id='01.Design-Token/06.Icons/Fluent/Line/Phone'
                      transform='translate(0.000000, 1.000000)'
                    >
                      <g id='Group' transform='translate(2.666667, 1.333333)'>
                        <path
                          d='M1.95633323,0.310875136 L2.76987648,0.0779903826 C3.69177747,-0.186593968 4.67684865,0.240724036 5.07177865,1.07654182 L5.70058415,2.40750141 C6.03713654,3.12005452 5.85970604,3.95746817 5.2593049,4.49019441 L4.21780223,5.41443504 C4.18735776,5.44143724 4.16806995,5.47791634 4.16342658,5.51727588 C4.13275315,5.78068115 4.3202794,6.2935584 4.75249604,7.00680942 C5.06689879,7.52499459 5.35202234,7.88925023 5.59392424,8.09294145 C5.76262815,8.23559166 5.85534559,8.26611217 5.89508164,8.25549634 L7.2963001,7.8474504 C8.08084543,7.61905618 8.9311311,7.89112176 9.41137314,8.52420951 L10.3043885,9.70256656 C10.8660407,10.4436951 10.7650375,11.4659629 10.0680636,12.0944456 L9.45041206,12.6517766 C8.78360108,13.2527706 7.83435239,13.4766811 6.94982967,13.2416186 C5.02995124,12.7313953 3.30875303,11.1894461 1.7667156,8.64761853 C0.222586801,6.10247347 -0.319078247,3.91030473 0.18006226,2.07044883 C0.408573568,1.22889039 1.08195752,0.561836364 1.95633323,0.310875136 L1.95633323,0.310875136 Z M2.25818775,1.2643093 C1.73371988,1.41470086 1.3296735,1.81459497 1.19228575,2.31925733 C0.772617331,3.86651444 1.2529355,5.80987468 2.67227918,8.15000153 C4.08953148,10.4868109 5.61553507,11.853599 7.2314676,12.28354 C7.76199732,12.424173 8.33115561,12.2896929 8.7309805,11.9292367 L9.34932914,11.3719057 C9.66625959,11.0861841 9.7121488,10.6213554 9.45668618,10.2844467 L8.56436795,9.10675314 C8.3460527,8.81864034 7.95919518,8.69484222 7.60233737,8.7988941 L6.19763329,9.20826701 C5.38199866,9.4391613 4.64165338,8.81481784 3.84693246,7.50442642 C3.31154153,6.62198561 3.06197128,5.93859161 3.12401528,5.40713666 C3.15678009,5.1311251 3.29132494,4.87634519 3.50464477,4.68658725 L4.54614745,3.76234661 C4.8191801,3.5202218 4.8999122,3.13950518 4.74691905,2.81554735 L4.11811355,1.48458776 C3.93861275,1.10498373 3.4912623,0.910833961 3.07242813,1.03076106 L2.25818775,1.2643093 Z'
                          id='Shape'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ContactNumberIcon;
