/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, Typography } from '@mui/material';
import * as React from 'react';
import CustomColorPicker from 'src/components/atoms/CustomColorPicker';

interface SelectColorBoxProps extends BoxProps {
  title: string;
  currentColor: string;
  onChangeColor: (color: string) => void;
  disabled?: boolean;
}
const SelectColorBox: React.FC<SelectColorBoxProps> = ({
  title,
  currentColor,
  onChangeColor,
  disabled,
  ...props
}) => {
  const disabledSx = React.useMemo(() => {
    if (disabled) {
      return {
        opacity: 0.5,
        pointerEvents: 'none',
      };
    }
    return {};
  }, [disabled]);

  return (
    <Box
      sx={{
        ...disabledSx,
      }}
      {...props}
    >
      <Typography variant='body1' color='neutral.ne800' fontSize='16px'>
        {title}
      </Typography>
      <CustomColorPicker mt='14px' currentColor={currentColor} onChangeColor={onChangeColor} />
    </Box>
  );
};

export default SelectColorBox;
