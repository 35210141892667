/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import FormSelect from 'src/components/atoms/FormSelect';
import { IInvestmentEntityProps } from 'src/components/pages/applications/components/InvestmentEntity';
import IndividualDetail from 'src/components/pages/applications/components/InvestmentEntity/shared/IndividualDetail';
import { individualDefaultValue, numberOfIndividualOptions } from 'src/constants/applications';
import { IInvestmentEntityDetailsForm } from 'src/modules/investment-entity/types';
import AddNew from './AddNew';

interface IIndividualsProps extends IInvestmentEntityProps {}

const Individuals = (props: IIndividualsProps) => {
  const { isViewMode, isInvestmentEntitySetting } = props;

  const {
    watch,
    control,
    formState: { isDirty },
  } = useFormContext<IInvestmentEntityDetailsForm>();

  const {
    fields: individuals,
    append: addIndividual,
    remove: removeIndividual,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.individuals',
    keyName: 'dataId',
  });

  const numberOfIndividuals = watch('investmentEntityDetails.numberOfIndividuals');

  useEffect(() => {
    if (
      (!individuals.length || (numberOfIndividuals || 0) > individuals.length) &&
      numberOfIndividuals &&
      isDirty
    ) {
      Array.from(Array(numberOfIndividuals - (individuals.length || 0))).forEach(() => {
        addIndividual(individualDefaultValue());
      });
    }
  }, [numberOfIndividuals]);

  return (
    <Box className='flex flex-col'>
      <AddNew {...props}>
        <Accordion
          defaultExpanded
          sx={{ mt: '0 !important', '.MuiAccordionSummary-root': { px: 2.5, py: 3 } }}
        >
          <AccordionSummary
            sx={{
              '.MuiSvgIcon-root': { fontSize: '18px' },
              '.MuiAccordionSummary-content': { my: 0 },
              minHeight: 'unset',
            }}
          >
            <Box className='flex justify-between items-center w-full'>
              <Typography variant='body2' fontWeight={600}>
                Investment Entity Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className='p-6 mt-0 border-t'
            sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
          >
            <Box className='flex items-center'>
              <Typography variant='body2' fontWeight={500} mr={1.5} whiteSpace={'nowrap'}>
                Number of individuals
              </Typography>
              <Box width='160px'>
                <FormSelect
                  name='investmentEntityDetails.numberOfIndividuals'
                  options={numberOfIndividualOptions}
                  placeholder='Select number'
                  disabled={isViewMode || isInvestmentEntitySetting}
                />
              </Box>
            </Box>
            {!!individuals.length && (
              <Box mt={3} className='flex flex-col gap-4'>
                {individuals.map((individual, index) => (
                  <IndividualDetail
                    key={individual.dataId}
                    {...props}
                    keyName={`investmentEntityDetails.individuals.${index}`}
                    canDelete={individuals.length > (numberOfIndividuals || 0)}
                    handleDelete={() => removeIndividual(index)}
                    disabledBasicInfoFields={isInvestmentEntitySetting ? ['dob'] : []}
                    autocompleteAddress
                    hideDeclaration
                  />
                ))}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </AddNew>
    </Box>
  );
};

export default Individuals;
