import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import OvalDownloadIcon from 'src/assets/icons/OvalDownloadIcon';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import { FUNDRAISING_OVERVIEW_STEP } from 'src/constants/applications';
import {
  useDownloadOverviewDocument,
  useGetApplicationOverview,
} from 'src/modules/applications/hooks';
import { IDocument } from 'src/modules/common/type';
import { handleErrorFromServer } from 'src/utils/common';
import { IFundraisingApplicationOverviewProps } from '.';

interface IApplicationDocumentsProps extends IFundraisingApplicationOverviewProps {}

interface ITableData extends IDocument {}

const ApplicationDocuments: FC<IApplicationDocumentsProps> = (props) => {
  const { id = '' } = props;
  const { data: overviewData } = useGetApplicationOverview(
    id,
    FUNDRAISING_OVERVIEW_STEP.ApplicationDocument,
  );
  const { mutate: downloadDocumentMutate } = useDownloadOverviewDocument();

  const handleDownloadDocument = (row: ITableData) => {
    downloadDocumentMutate(
      {
        appId: id,
        document: row,
      },
      {
        onError: handleErrorFromServer,
      },
    );
  };

  const fileColumns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '35%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable className='line-clamp-1' value={row.description || ''} />
      ),
    },
    {
      title: 'File Name',
      key: 'fileName',
      sx: { width: '35%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(row.fileType);
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='pl-2 line-clamp-1' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File Size',
      key: 'fileSize',
      sx: { width: '15%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '15%' },
      renderNode: (row: ITableData) => (
        <IconButton onClick={() => handleDownloadDocument(row)}>
          <OvalDownloadIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <CustomTableContainer
      sx={(theme) => ({
        '.MuiTableCell-head': {
          borderBottomWidth: `1px !important`,
          borderColor: `${theme.palette.neutral.ne200} !important`,
          background: `${theme.palette.base.white} !important`,
        },
        '.MuiTableContainer-root': {
          borderColor: theme.palette.neutral.ne200,
        },
      })}
    >
      <CustomTable
        rows={overviewData?.documents || []}
        columns={fileColumns}
        hasPagination={false}
        stickyHeader
        displayEmpty
        hiddenHorizontalRule
        customEmpty={
          <EmptyData
            isTable
            description='No document.'
            icon={<DocumentOnePageIcon width={22} height={27} />}
            marginY='34px'
          />
        }
      />
    </CustomTableContainer>
  );
};

export default ApplicationDocuments;
