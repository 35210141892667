import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  ICustomMultiSelectOptionItem,
  MULTIPLE_SELECT_EMPTY,
} from 'src/components/atoms/CustomMultiSelect';
import { ADDRESS } from 'src/constants/address';
import { ORGANISATION_SETTING_API, PROFILE_API, ROLE_API } from 'src/constants/apiEndpoints';
import { FUND_PORTAL_TYPE } from 'src/constants/common';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { portalApi } from 'src/helpers/axios';
import { detectPortalType } from 'src/helpers/common';
import { useGetClientUserInfo } from 'src/modules/auth/hooks';
import { CLIENT_PORTAL_USER_TYPE } from 'src/modules/auth/type';
import { useGetOverarchingFilter } from 'src/modules/common/hooks';

interface IClient {
  createdDate: string;
  id: string;
  name: string;
}

interface IFund {
  clientId: string;
  id: string;
  name: string;
}

const FundPortalContext = React.createContext<{
  onChangeClient: (_: string[] | string) => void;
  selectedClients: string[] | string;
  onChangeFund: (_: string[] | string) => void;
  selectedFunds: string[] | string;
  isMultiSelectClient: boolean;
  overarchingFilter: {
    clients: IClient[];
    funds: IFund[];
  };
  isFAUser: boolean;
  isFmPortal: boolean;
  isFaPortal: boolean;
  fundOptions: ICustomMultiSelectOptionItem[];
  isOverarchingFilterEmpty: boolean;
}>({
  onChangeClient: () => {},
  selectedClients: [],
  onChangeFund: () => {},
  selectedFunds: [],
  isMultiSelectClient: false,
  overarchingFilter: {
    clients: [],
    funds: [],
  },
  isFAUser: false,
  isFmPortal: false,
  isFaPortal: false,
  fundOptions: [],
  isOverarchingFilterEmpty: false,
});

export const useFundPortalContext = () => {
  const context = useContext(FundPortalContext);
  if (!context) {
    throw new Error('FundPortalContext must be used within provider!');
  }
  return context;
};

interface IFundPortalProvider {
  children: React.ReactNode;
}

export const X_CLIENT_IDS = 'x-client-ids';
const X_FUND_IDS = 'x-fund-ids';

const FundPortalProvider: React.FC<IFundPortalProvider> = ({ children }) => {
  const { isFunds } = detectPortalType();
  const queryClient = useQueryClient();
  const [selectedClients, setSelectedClients] = useState<string | string[]>('');
  const [selectedFunds, setSelectedFunds] = useState<string | string[]>([]);
  const [isMultiSelectClient, setIsMultiSelectClient] = useState(true);
  const [filterKeyChange, setFilterKeyChange] = useState<'clients' | 'funds'>();
  const [fundOptions, setFundOptions] = useState<ICustomMultiSelectOptionItem[]>([]);
  const { data: userInfo } = useGetClientUserInfo();
  const { data: overarchingFilter } = useGetOverarchingFilter();
  const isFAUser = userInfo?.userType === CLIENT_PORTAL_USER_TYPE.FA_USER;
  const isFmPortal = userInfo?.portalType === FUND_PORTAL_TYPE.FM;
  const isFaPortal = userInfo?.portalType === FUND_PORTAL_TYPE.FA;
  const isFMUser = userInfo?.userType === CLIENT_PORTAL_USER_TYPE.FM_USER;

  useEffect(() => {
    if (isFAUser && isFaPortal) handleGetOverarchingFilerFromStorage();
  }, [isFAUser, isFaPortal]);

  useEffect(() => {
    if (
      !isMultiSelectClient &&
      overarchingFilter.clients.length &&
      (!selectedClients || Array.isArray(selectedClients))
    ) {
      const overarchingFilerFromStorage = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.OVERARCHING_FILTER) || '{}',
      );
      const defaultClient = !isFmPortal
        ? overarchingFilter.clients.sort(
            (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
          )[0].id
        : overarchingFilter.clients[0].id;

      if (overarchingFilerFromStorage?.clients) {
        handleSetSelectedClients(overarchingFilerFromStorage.clients);
      } else {
        handleSetSelectedClients(defaultClient);
      }

      if (overarchingFilerFromStorage?.funds) {
        handleSetSelectedFunds(overarchingFilerFromStorage.funds);
      }
    }
  }, [isMultiSelectClient, overarchingFilter, isFmPortal]);

  useEffect(() => {
    if (userInfo) {
      setIsMultiSelectClient(userInfo.userType === CLIENT_PORTAL_USER_TYPE.FA_USER && !isFmPortal);
    }
  }, [userInfo]);

  useEffect(() => {
    handleResetQueries();
  }, [selectedClients, selectedFunds]);

  useEffect(() => {
    if (selectedClients && overarchingFilter) handleGetFundOptions();
  }, [filterKeyChange, selectedClients, overarchingFilter, selectedFunds]);

  const handleGetOverarchingFilerFromStorage = () => {
    const overarchingFilerFromStorage = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.OVERARCHING_FILTER) || '{}',
    );

    handleSetSelectedClients(overarchingFilerFromStorage?.clients || []);
    handleSetSelectedFunds(overarchingFilerFromStorage?.funds || []);
  };

  const handleSetOverarchingFilterStorage = (mergedParams?: any) => {
    const overarchingFilerFromStorage = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.OVERARCHING_FILTER) || '{}',
    );
    localStorage.setItem(
      STORAGE_KEYS.OVERARCHING_FILTER,
      JSON.stringify({
        ...overarchingFilerFromStorage,
        ...mergedParams,
      }),
    );
  };

  const handleResetQueries = () => {
    queryClient.resetQueries({
      predicate: (q) =>
        !q.queryKey?.includes(PROFILE_API.getOverarchingFilter.api) &&
        !q.queryKey?.includes(PROFILE_API.getProfile.api) &&
        !q.queryKey?.includes(ORGANISATION_SETTING_API.organisationSubscriptions.api) &&
        !q.queryKey?.includes(ROLE_API.getUserRole.api) &&
        !q.queryKey?.includes(ADDRESS.COUNTRIES),
    });
    if (isFMUser) {
      queryClient.invalidateQueries([PROFILE_API.getProfile.api]);
      queryClient.invalidateQueries([ROLE_API.getUserRole.api]);
    }
  };

  const handleGetFundOptions = () => {
    let newFundOptions: ICustomMultiSelectOptionItem[] = cloneDeep(fundOptions);

    // Use for select all clients case
    if (Array.isArray(selectedClients) && !selectedClients.length) {
      newFundOptions = overarchingFilter?.funds?.map((it) => ({ label: it.name, value: it.id }));
      setFundOptions(newFundOptions);
      return;
    }

    if (filterKeyChange !== 'funds') {
      newFundOptions = overarchingFilter?.funds
        ?.filter((x) => selectedClients?.includes(x.clientId))
        .map((it) => ({ label: it.name, value: it.id }));
    }

    setFundOptions(newFundOptions);
  };

  const handleSetSelectedClients = (client: string[] | string) => {
    portalApi.defaults.headers.common[X_CLIENT_IDS] =
      client === MULTIPLE_SELECT_EMPTY ? '' : client.toString();
    setSelectedClients(client);
    handleSetOverarchingFilterStorage({ clients: client });
  };

  const handleSetSelectedFunds = (fund: string[] | string) => {
    const isSelectAllFund = fund?.length === overarchingFilter?.funds?.length;
    portalApi.defaults.headers.common[X_FUND_IDS] =
      fund === MULTIPLE_SELECT_EMPTY || isSelectAllFund ? '' : fund.toString();
    setSelectedFunds(fund);
    handleSetOverarchingFilterStorage({ funds: fund });
  };

  // Uncheck clients who are not relevant with selected funds
  const handleUpdateSelectedClients = (selectedFundIds: string[] | string) => {
    // Case empty funds
    if (selectedFundIds === MULTIPLE_SELECT_EMPTY) {
      handleSetSelectedClients(MULTIPLE_SELECT_EMPTY);
      return;
    }
    // Normal case
    // Only get funds from fund options variable
    const selectedFunds = overarchingFilter.funds
      ?.filter((fund) => fundOptions?.some((opt) => opt.value === fund.id))
      ?.filter(
        (fund) =>
          // Select all case
          (Array.isArray(selectedFundIds) && !selectedFundIds.length) ||
          selectedFundIds?.includes(fund?.id),
      );

    // Handle for single client selection case - FM users
    const relevantClientIds: any = isMultiSelectClient
      ? [...new Set(selectedFunds?.map((it) => it?.clientId))]
      : selectedFunds[0]?.clientId;

    handleSetSelectedClients(relevantClientIds);
  };

  // Uncheck funds which are not relevant with selected clients
  const handleUpdateSelectedFunds = (selectedClientIds: string[] | string) => {
    // Case empty client
    if (selectedClientIds === MULTIPLE_SELECT_EMPTY) {
      handleSetSelectedFunds(MULTIPLE_SELECT_EMPTY);
      return;
    }
    // Normal case
    const relevantFundIds = overarchingFilter.funds
      ?.filter(
        (fund) =>
          // Select all case
          (Array.isArray(selectedClientIds) && !selectedClientIds.length) ||
          selectedClientIds?.includes(fund?.clientId),
      )
      .map((fund) => fund.id);

    handleSetSelectedFunds(relevantFundIds?.length ? relevantFundIds : MULTIPLE_SELECT_EMPTY);
  };

  const onChangeFund = (fund: string[] | string) => {
    if (isFunds) {
      handleSetSelectedFunds(fund);
      // Only uncheck clients who are not relevant with selected funds in the multiple client mode - FA users
      isMultiSelectClient && handleUpdateSelectedClients(fund);
    }
    filterKeyChange !== 'funds' && isFAUser && isFaPortal && setFilterKeyChange('funds');
  };

  const onChangeClient = (client: string[] | string) => {
    if (isFunds) {
      handleSetSelectedClients(client);
      handleUpdateSelectedFunds(client);
    }
    filterKeyChange !== 'clients' && setFilterKeyChange('clients');
  };

  return (
    <FundPortalContext.Provider
      value={{
        selectedClients,
        onChangeClient,
        selectedFunds,
        onChangeFund,
        isMultiSelectClient,
        overarchingFilter,
        isFAUser,
        isFmPortal,
        isFaPortal,
        fundOptions,
        isOverarchingFilterEmpty:
          selectedClients === MULTIPLE_SELECT_EMPTY || selectedFunds === MULTIPLE_SELECT_EMPTY,
      }}
    >
      {children}
    </FundPortalContext.Provider>
  );
};

export default FundPortalProvider;
