import { FC, SVGProps } from 'react';

export const RiskFlagIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      color='#FF3F52'
      {...props}
    >
      <title>367B481E-C662-403C-AD26-A8BDD217BC62</title>
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00-Fund-Portal_Fundraising_KYC-Back-Office_Investor-Details_Sode-Trader_01'
          transform='translate(-1070.590164, -313.000000)'
          fill='currentColor'
          fillRule='nonzero'
        >
          <g id='Logo' transform='translate(566.000000, 278.000000)'>
            <g
              id='02.Atoms/02.Button/02.Medium/02.With-Icon/Left/01.Primary/01.Default'
              transform='translate(492.000000, 27.000000)'
            >
              <g
                id='01.Design-Token/06.Icons/Line/plus-outline'
                transform='translate(12.590164, 8.000000)'
              >
                <g id='Group' transform='translate(3.000000, 3.000000)'>
                  <path
                    d='M0,0.75 C0,0.335786438 0.335786438,0 0.75,0 L17.254,0 C17.538079,0 17.7977764,0.160501782 17.9248204,0.414589803 C18.0518644,0.668677825 18.0244474,0.972736765 17.854,1.2 L13.69,6.751 L17.854,12.303 C18.0244474,12.5302632 18.0518644,12.8343222 17.9248204,13.0884102 C17.7977764,13.3424982 17.538079,13.503 17.254,13.503 L1.5,13.503 L1.5,18.252 C1.4999842,18.6267858 1.22330358,18.9440291 0.852,18.995 L0.75,19.003 C0.375214222,19.003 0.0579708656,18.7263036 0.007,18.355 L0,18.252 L0,0.75 Z'
                    id='Path'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
