import * as React from 'react';

interface IEditOutlinedIconProps extends React.SVGProps<SVGSVGElement> {}

export const EditOutlinedIcon: React.FunctionComponent<IEditOutlinedIconProps> = ({
  color = '#686D6E',
  width = '13.3333333px',
  height = '13.3345196px',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox='0 0 13.3333333 13.3345196'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-11.1%'
          y='-8.0%'
          width='122.2%'
          height='122.4%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx='0' dy='5' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_List-Clients-Action_00'
          transform='translate(-1178.333333, -444.333333)'
          fill={color}
          fillRule='nonzero'
        >
          <g id='Action' transform='translate(1161.000000, 356.000000)'>
            <g
              id='Group-26-Copy-2'
              filter='url(#filter-1)'
              transform='translate(0.000000, 35.000000)'
            >
              <g id='Group-20' transform='translate(4.000000, 42.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                  transform='translate(12.000000, 10.000000)'
                >
                  <g id='Group' transform='translate(1.333333, 1.333333)'>
                    <path
                      d='M12.6373953,0.695938002 C13.5653127,1.62387592 13.5653127,3.12833872 12.6373953,4.05627664 L4.68946394,12.0055362 C4.50560782,12.1894766 4.27692625,12.3222447 4.0260295,12.3907134 L0.628501341,13.3171309 C0.456134085,13.3638951 0.271922194,13.3147909 0.145702708,13.1884343 C0.0194832225,13.0620776 -0.0294208345,12.8778124 0.0175306795,12.7054961 L0.94394815,9.30796793 C1.01241683,9.05707118 1.14518491,8.82838961 1.32912531,8.64453349 L9.27705669,0.695938002 C10.2049946,-0.231979334 11.7094574,-0.231979334 12.6373953,0.695938002 Z M8.6328811,2.74800251 L2.03306976,9.34914204 C1.97183683,9.41045282 1.92765841,9.48667776 1.90489874,9.57028686 L1.20759527,12.1270663 L3.76437467,11.4297628 C3.84764719,11.4064127 3.9234047,11.3617821 3.98419129,11.3002636 L10.5853308,4.70045223 L8.6328811,2.74800251 Z M9.98100115,1.39988246 L9.33682556,2.04405805 L11.2892753,3.99650777 L11.9334509,3.35233218 C12.4633506,2.81161761 12.4589738,1.94502847 11.9236393,1.40969401 C11.3883049,0.874359554 10.5217157,0.869982729 9.98100115,1.39988246 L9.98100115,1.39988246 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
