/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IFavouriteActiveIconProps { }

export const FavouriteActiveIcon: FC<IFavouriteActiveIconProps> = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Investor-Portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="00_Investor-Portal_Application_01" transform="translate(-583, -264)">
          <g id="My-Offer-&amp;-Invitation" transform="translate(311, 178)">
            <g id="Group-3" transform="translate(0, 70)">
              <g id="Avatar" transform="translate(272, 16)">
                <circle id="Oval" fill="#FFB302" cx="12" cy="12" r="12"></circle>
                <path d="M11.2738595,6.4718334 L9.85885167,9.46512442 L6.69458694,9.94510368 C6.03028938,10.045987 5.76445034,10.8987961 6.24572102,11.3881745 L8.53505725,13.7191442 L7.99497793,17.0088193 C7.88096118,17.6999644 8.57586324,18.226939 9.17055058,17.9011045 L11.9999661,16.3471247 L14.8299818,17.9011045 C15.424069,18.226939 16.1189711,17.700591 16.0061545,17.0088193 L15.464875,13.7191442 L17.7548113,11.3881745 C18.2348818,10.8994228 17.9702429,10.045987 17.3059454,9.94510368 L14.1416807,9.46512442 L12.7266728,6.4718334 C12.4296292,5.8427222 11.5709031,5.8427222 11.2738595,6.4718334 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
