import * as Yup from 'yup';
import { YupBase } from './common';

export const globalAdminProfileValidationSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  contactNumber: YupBase.contactNumber,
  position: Yup.string().required('Position is required'),
  role: Yup.string(),
  email: Yup.string(),
});

export const investorFundProfileValidationSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  contactNumber: YupBase.contactNumber,
  email: Yup.string(),
});
