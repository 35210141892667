import { get } from 'lodash';
import {
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TYPE,
  TeamType,
} from 'src/constants/applications';
import {
  companyReviewInfo,
  individualsReviewInfo,
  otherReviewInfo,
  partnershipReviewInfo,
  primaryContactReviewInfo,
  smsfReviewInfo,
  soleTraderReviewInfo,
  trustReviewInfo,
} from 'src/constants/dashboard';

const generateDuplicateCompanyData = (duplicateEntityDetails: any, existingEntityDetails: any) => {
  const result: any[] = [];

  Object.entries(companyReviewInfo).forEach(([key, value]) => {
    if (get(duplicateEntityDetails, key) || get(existingEntityDetails, key)) {
      result.push({
        groupTitle: 'Entity Details',
        data: value?.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(get(duplicateEntityDetails, `${key}.${it.key}`))
            : get(duplicateEntityDetails, `${key}.${it.key}`),
          existing: it?.transform
            ? it?.transform(get(existingEntityDetails, `${key}.${it.key}`))
            : get(existingEntityDetails, `${key}.${it.key}`),
        })),
      });
    }
  });

  return result;
};

export const generateSMSFDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
) => {
  const result: any[] = [];
  Object.entries(smsfReviewInfo).forEach(([key, value]) => {
    const { title, data }: any = value || {};
    if (get(duplicateEntityDetails, key) || get(existingEntityDetails, key)) {
      result.push({
        groupTitle: title,
        data: data?.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(get(duplicateEntityDetails?.smsf, it.key))
            : get(duplicateEntityDetails?.smsf, it.key),
          existing: it?.transform
            ? it?.transform(get(existingEntityDetails?.smsf, it.key))
            : get(existingEntityDetails?.smsf, it.key),
        })),
      });
    }
  });

  const trusteeIndividuals =
    (duplicateEntityDetails?.smsf?.trusteeIndividuals || [])?.length >
    (existingEntityDetails?.smsf?.trusteeIndividuals || [])?.length
      ? duplicateEntityDetails?.smsf?.trusteeIndividuals
      : existingEntityDetails?.smsf?.trusteeIndividuals || [];

  if (trusteeIndividuals?.length) {
    trusteeIndividuals?.forEach((item: any, index: number) => {
      result.push({
        groupTitle: `Individual Trustee ${index + 1}`,
        data: smsfReviewInfo.individuals.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(
                get(duplicateEntityDetails?.smsf?.trusteeIndividuals, `${index}.${it.key}`),
              )
            : get(duplicateEntityDetails?.smsf?.trusteeIndividuals, `${index}.${it.key}`),
          existing: it?.transform
            ? it?.transform(
                get(existingEntityDetails?.smsf?.trusteeIndividuals, `${index}.${it.key}`),
              )
            : get(existingEntityDetails?.smsf?.trusteeIndividuals, `${index}.${it.key}`),
        })),
      });
    });
  }

  return result;
};

export const generateIndividualDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
) => {
  return duplicateEntityDetails?.individuals?.map((item: any, index: number) => ({
    groupTitle: `Individual ${index + 1}`,
    data: individualsReviewInfo.map((it: any) => ({
      ...it,
      duplicate: it?.transform
        ? it?.transform(get(duplicateEntityDetails?.individuals, `${index}.${it.key}`))
        : get(duplicateEntityDetails?.individuals, `${index}.${it.key}`),
      existing: it?.transform
        ? it?.transform(get(existingEntityDetails?.individuals, `${index}.${it.key}`))
        : get(existingEntityDetails?.individuals, `${index}.${it.key}`),
    })),
  }));
};

export const generateTrustDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
  duplicateTrustType: any,
  existingTrustType: any,
) => {
  const result: any[] = [];
  const existingTrust =
    existingTrustType === INVESTMENT_ENTITY_TRUST.Regulated
      ? existingEntityDetails?.regulatedTrust
      : existingEntityDetails?.unregulatedTrust;
  const duplicateTrust =
    duplicateTrustType === INVESTMENT_ENTITY_TRUST.Regulated
      ? duplicateEntityDetails?.regulatedTrust
      : duplicateEntityDetails?.unregulatedTrust;

  Object.entries(trustReviewInfo).forEach(([key, value]) => {
    const { title, data }: any = value || {};
    if (key === 'trust' || get(existingTrust, key) || get(duplicateTrust, key)) {
      result.push({
        groupTitle: title,
        data: data?.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(get(duplicateTrust, it.key))
            : get(duplicateTrust, it.key),
          existing: it?.transform
            ? it?.transform(get(existingTrust, it.key))
            : get(existingTrust, it.key),
        })),
      });
    }
  });

  const trusteeIndividuals =
    (duplicateTrust?.trusteeIndividuals || [])?.length >
    (existingTrust?.trusteeIndividuals || [])?.length
      ? duplicateTrust?.trusteeIndividuals
      : existingTrust?.trusteeIndividuals || [];

  if (trusteeIndividuals?.length) {
    trusteeIndividuals?.forEach((item: any, index: number) => {
      result.push({
        groupTitle: `Individual Trustee ${index + 1}`,
        data: trustReviewInfo.individuals.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(get(duplicateTrust?.trusteeIndividuals, `${index}.${it.key}`))
            : get(duplicateTrust?.trusteeIndividuals, `${index}.${it.key}`),
          existing: it?.transform
            ? it?.transform(get(existingTrust?.trusteeIndividuals, `${index}.${it.key}`))
            : get(existingTrust?.trusteeIndividuals, `${index}.${it.key}`),
        })),
      });
    });
  }

  return result;
};

const generateSoleTraderDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
) => {
  return [
    {
      groupTitle: 'Entity Details',
      data: soleTraderReviewInfo.map((it) => ({
        ...it,
        duplicate: it?.transform
          ? it?.transform(get(duplicateEntityDetails?.soleTrader, it.key))
          : get(duplicateEntityDetails?.soleTrader, it.key),
        existing: it?.transform
          ? it?.transform(get(existingEntityDetails?.soleTrader, it.key))
          : get(existingEntityDetails?.soleTrader, it.key),
      })),
    },
  ];
};

export const generateOtherDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
) => {
  const result: any[] = [];

  Object.entries(otherReviewInfo).forEach(([key, value]) => {
    if (get(duplicateEntityDetails, key) || get(existingEntityDetails, key)) {
      result.push({
        groupTitle: 'Entity Details',
        data: value?.map((it: any) => ({
          ...it,
          duplicate: it?.transform
            ? it?.transform(get(duplicateEntityDetails, `${key}.${it.key}`))
            : get(duplicateEntityDetails, `${key}.${it.key}`),
          existing: it?.transform
            ? it?.transform(get(existingEntityDetails, `${key}.${it.key}`))
            : get(existingEntityDetails, `${key}.${it.key}`),
        })),
      });
    }
  });

  return result;
};

export const generatePartnershipDuplicateData = (
  duplicateEntityDetails: any,
  existingEntityDetails: any,
) => {
  return [
    {
      groupTitle: 'Entity Details',
      data: partnershipReviewInfo.map((it) => ({
        ...it,
        duplicate: it?.transform
          ? it?.transform(get(duplicateEntityDetails?.partnership, it.key))
          : get(duplicateEntityDetails?.partnership, it.key),
        existing: it?.transform
          ? it?.transform(get(existingEntityDetails?.partnership, it.key))
          : get(existingEntityDetails?.partnership, it.key),
      })),
    },
  ];
};

export const generateReviewDuplicationInfoData = (
  duplicateEntity: any,
  existingEntity: any,
): any[] => {
  let result: any = [];
  const { investmentEntity: duplicateInvestmentEntity, teams: duplicateTeams = [] } =
    duplicateEntity || {};
  const { investmentEntity: existingInvestmentEntity, teams: existingTeams = [] } =
    existingEntity || {};
  const { entityDetails: duplicateEntityDetails } = duplicateInvestmentEntity || {};
  const { entityDetails: existingEntityDetails } = existingInvestmentEntity || {};
  const duplicatePrimaryContact = (duplicateTeams || []).find((it: any) =>
    it?.teamTypes?.includes(TeamType.PrimaryContact),
  );
  const existingPrimaryContact = (existingTeams || []).find((it: any) =>
    it?.teamTypes?.includes(TeamType.PrimaryContact),
  );

  switch (existingInvestmentEntity?.entityType) {
    case INVESTMENT_ENTITY_TYPE.Company: {
      const data = generateDuplicateCompanyData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }
    case INVESTMENT_ENTITY_TYPE.SMSF: {
      const data = generateSMSFDuplicateData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }
    case INVESTMENT_ENTITY_TYPE.Individuals: {
      const data = generateIndividualDuplicateData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }
    case INVESTMENT_ENTITY_TYPE.Trust: {
      const data = generateTrustDuplicateData(
        duplicateEntityDetails,
        existingEntityDetails,
        duplicateInvestmentEntity?.trustType,
        existingInvestmentEntity?.trustType,
      );
      result = data;
      break;
    }

    case INVESTMENT_ENTITY_TYPE.SoleTrader: {
      const data = generateSoleTraderDuplicateData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }

    case INVESTMENT_ENTITY_TYPE.Other: {
      const data = generateOtherDuplicateData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }

    case INVESTMENT_ENTITY_TYPE.Partnership: {
      const data = generatePartnershipDuplicateData(duplicateEntityDetails, existingEntityDetails);
      result = data;
      break;
    }

    default:
      return [];
  }

  if (duplicatePrimaryContact || existingPrimaryContact) {
    result = [
      ...result,
      {
        groupTitle: 'Primary Contact',
        data: primaryContactReviewInfo.map((it) => ({
          ...it,
          duplicate: get(duplicatePrimaryContact, it.key),
          existing: get(existingPrimaryContact, it.key),
        })),
      },
    ];
  }

  return result;
};
