import { Box, Typography } from '@mui/material';
import { DATE_TIME_FORMAT } from 'src/constants/date';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { ICommunicationDetail } from 'src/modules/communication/type';
import { utcToLocalTimezone } from 'src/utils/time';
import Attachment from './Attachments';

interface IForwaredOrRepliedContent {
  messageDetail?: ICommunicationDetail | ICommunicationDetail[];
  title?: string;
  receiver?: string;
}

const ForwaredOrRepliedContent: React.FC<IForwaredOrRepliedContent> = ({
  messageDetail,
  title = 'Forwarded',
  receiver,
}) => {
  const titleFormatted = `${title} message`;
  return (
    <Box
      px='16px'
      py='20px'
      borderRadius='10px'
      bgcolor='neutral.ne100'
      mt={3}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.neutral.ne400}`,
      })}
    >
      {Array.isArray(messageDetail) ? (
        messageDetail.map((detail, index) => {
          return (
            <Detail
              key={`message-item-${index}`}
              title={index > 0 ? `${titleFormatted} (${index})` : titleFormatted}
              messageDetail={detail}
              boxClass={index < messageDetail?.length - 1 ? 'mb-[15px]' : ''}
              receiver={receiver}
            />
          );
        })
      ) : (
        <Detail title={titleFormatted} messageDetail={messageDetail} receiver={receiver} />
      )}
    </Box>
  );
};

export default ForwaredOrRepliedContent;

interface IDetail {
  messageDetail?: ICommunicationDetail;
  title?: string;
  boxClass?: string;
  receiver?: string;
}

const Detail = (props: IDetail) => {
  const { title, messageDetail, boxClass, receiver } = props;
  const { data: currentUser } = useGetUserInfo();

  return (
    <Box className={boxClass}>
      <Typography variant='body3'>{`- - - - - ${title} - - - - -`}</Typography>
      <Box className='flex'>
        <Typography variant='body3' width='40px'>
          From:
        </Typography>
        <Typography variant='body3' fontWeight='bold' ml='8px'>
          {messageDetail?.sender}
        </Typography>
      </Box>
      <Box className='flex'>
        <Typography variant='body3' width='40px'>
          Date:
        </Typography>
        <Typography variant='body3' fontWeight='bold' ml='8px'>
          {utcToLocalTimezone(messageDetail?.dateSubmitted || '', DATE_TIME_FORMAT)}
        </Typography>
      </Box>
      <Box className='flex'>
        <Typography variant='body3' width='40px'>
          To:
        </Typography>
        <Typography variant='body3' fontWeight='bold' ml='8px'>
          {receiver
            ? receiver
            : `${currentUser?.firstName || ''} ${currentUser?.lastName || ''} | ${
                currentUser?.email
              }`}
        </Typography>
      </Box>
      <Attachment attachments={messageDetail?.attachments || []} disabledRemove />
      <Box
        mt={2}
        component='div'
        fontSize='14px'
        dangerouslySetInnerHTML={{
          __html: messageDetail?.content || '',
        }}
      />
    </Box>
  );
};
