import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { CustomInfiniteScroll } from 'src/components/atoms/CustomInfiniteScroll';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import { ColumnProps } from 'src/components/atoms/CustomTable';
import EmptyData from 'src/components/molecules/EmptyData';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { bulkUploadResultStatusOptions } from 'src/constants/bulk-upload';
import { useGetBulkUploadListById } from 'src/modules/bulk-upload/hooks';
import { IBulkUploadDetail } from 'src/modules/bulk-upload/type';
import { DEFAULT_PAGINATED } from 'src/modules/common/hooks';

interface IViewResultProps {
  id?: string;
  onClose: () => void;
}

const ViewResult: FC<IViewResultProps> = ({ onClose, id = '' }) => {
  const {
    setParams,
    data: {
      items: bulkUploadListById = [],
      metadata: { page = 0, pageSize = 8, totalItem = 0 } = {},
    },
  } = useGetBulkUploadListById(id);
  const theme = useTheme();
  const [isEmptyFilter, setIsEmptyFilter] = useState(false);
  const totalPage = Math.ceil(totalItem / pageSize);
  const hasMore = page + 1 < totalPage;

  const handleStatusChange = (status: string[] | string) => {
    if (status !== MULTIPLE_SELECT_EMPTY) {
      setParams({
        statuses: (status as string[]).map((item: string) => Number(item)),
        ...DEFAULT_PAGINATED,
      });
      isEmptyFilter && setIsEmptyFilter(false);
    } else {
      setIsEmptyFilter(true);
    }
  };

  const customRenderStatusLabel = (label: string | JSX.Element) => {
    return <StatusBadge status={label as string} />;
  };

  const columns: ColumnProps<IBulkUploadDetail>[] = [
    {
      title: 'Row Number',
      key: 'name',
      sx: { minWidth: 180, width: '45%' },

      renderNode: (row) => <StringNodeTable value={row.name} variant='body3' />,
    },
    {
      title: 'Error Message',
      key: 'error',
      sx: { minWidth: 180, width: '40%' },
      renderNode: (row) => (
        <StringNodeTable value={row?.error || ''} variant='body3' color='base.black' />
      ),
    },
    {
      title: 'Status',
      key: 'statusName',
      sx: { minWidth: 110, width: '15%' },
      renderNode: (row) => {
        return <StatusBadge status={row?.statusName || ''} showDot={false} />;
      },
    },
  ];

  const handleLoadMoreData = () => {
    if (!hasMore) return;

    setParams({
      pageSize: pageSize + DEFAULT_PAGINATED.pageSize,
    });
  };

  return (
    <Box className='w-full px-17.5 py-11.5'>
      <Box className='header flex justify-between items-center pb-14'>
        <Typography variant='h5' fontWeight={700}>
          Upload Details
        </Typography>
        <IconButton sx={{ p: 0 }} onClick={onClose}>
          <CloseIcon color={theme.palette.neutral.ne400} />
        </IconButton>
      </Box>

      <Box className='body'>
        <CustomMultiSelect
          containerClass='mb-6'
          options={bulkUploadResultStatusOptions}
          label='Status'
          showClearAllIcon
          customRenderLabel={customRenderStatusLabel}
          onChange={handleStatusChange}
        />
        {bulkUploadListById?.length && !isEmptyFilter ? (
          <Box
            className='data-table max-w-[728px] border'
            borderColor='neutral.ne200'
            borderRadius='10px'
          >
            <Box className='header flex items-center w-full'>
              {columns.map((item, index) => (
                <Box
                  className='border-r last:border-r-0'
                  key={index}
                  px={2}
                  py='14px'
                  sx={item.sx}
                  bgcolor='base.bg'
                  borderColor='neutral.ne200'
                >
                  <Typography variant='body3' color='neutral.ne800'>
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className='body'>
              <CustomInfiniteScroll
                dataLength={bulkUploadListById.length}
                next={() => {
                  handleLoadMoreData();
                }}
                height={Math.ceil(totalItem / DEFAULT_PAGINATED.pageSize) > 1 ? 450 : 'fit-content'}
                hasMore={hasMore}
                loader={<Typography variant='body3'>...Loading</Typography>}
                className='overflow-auto'
              >
                {bulkUploadListById.map((row, index) => (
                  <Box
                    key={index}
                    className='flex w-full items-center border-b last:border-b-0'
                    borderColor='neutral.ne200'
                  >
                    {columns.map((column, idx) => (
                      <Box key={idx} sx={column.sx} p={2}>
                        {column?.renderNode ? column.renderNode(row, idx) : ''}
                      </Box>
                    ))}
                  </Box>
                ))}
              </CustomInfiniteScroll>
            </Box>
          </Box>
        ) : (
          <EmptyData />
        )}
      </Box>
    </Box>
  );
};

export default ViewResult;
