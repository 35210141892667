import * as React from 'react';

interface IDocumentOnePageIconProps extends React.SVGProps<SVGSVGElement> {}

const DocumentOnePageIcon: React.FunctionComponent<IDocumentOnePageIconProps> = (props) => {
  return (
    <svg
      width='10.6666667px'
      height='13.3313333px'
      viewBox='0 0 10.6666667 13.3313333'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <filter
          x='-11.1%'
          y='-8.0%'
          width='122.2%'
          height='122.4%'
          filterUnits='objectBoundingBox'
          id='filter-1'
        >
          <feOffset dx='0' dy='5' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.678431373   0 0 0 0 0.678431373   0 0 0 0 0.678431373  0 0 0 0.16 0'
            type='matrix'
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Global-Admin-Portal-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_List-Clients-Action_00'
          transform='translate(-1179.666667, -406.333333)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Action' transform='translate(1161.000000, 356.000000)'>
            <g
              id='Group-26-Copy-2'
              filter='url(#filter-1)'
              transform='translate(0.000000, 35.000000)'
            >
              <g id='Group-20-Copy' transform='translate(4.000000, 4.000000)'>
                <g
                  id='01.Design-Token/06.Icons/Fluent/Filled/Edit'
                  transform='translate(12.000000, 10.000000)'
                >
                  <g id='Group' transform='translate(2.666667, 1.333333)'>
                    <path
                      d='M9.16666667,0 C9.95531831,0 10.6093545,0.610531127 10.6633333,1.39733333 L10.6666667,1.49933333 L10.6666667,11.8313333 C10.6666667,12.619985 10.0561355,13.2740212 9.26933333,13.328 L9.16666667,13.3313333 L1.5,13.3313333 C0.711348357,13.3313333 0.0573121514,12.7208022 0.00333333333,11.934 L0,11.8326667 L0,1.5 C0,0.711348357 0.610531127,0.0573121514 1.39733333,0.00333333333 L1.5,0 L9.16666667,0 Z M9.16666667,1 L1.5,1 C1.25014281,1.00001054 1.03864724,1.18446428 1.00466667,1.432 L1,1.5 L1,11.832 C1,12.0853333 1.188,12.2946667 1.432,12.3273333 L1.5,12.332 L9.16666667,12.332 C9.41652385,12.332 9.62801942,12.1475357 9.662,11.9 L9.66666667,11.832 L9.66666667,1.5 C9.66666667,1.25014281 9.48220239,1.03864724 9.23466667,1.00466667 L9.16666667,0.999333333 L9.16666667,1 Z'
                      id='Shape'
                    />
                    <path
                      d='M2,9.166 C2,8.88985763 2.22385763,8.666 2.5,8.666 L8.16666667,8.666 C8.44280904,8.666 8.66666667,8.88985763 8.66666667,9.166 C8.66666667,9.44214237 8.44280904,9.666 8.16666667,9.666 L2.5,9.666 C2.22385763,9.666 2,9.44214237 2,9.166 L2,9.166 Z M2,3.83266667 C2,3.55652429 2.22385763,3.33266667 2.5,3.33266667 L8.16666667,3.33266667 C8.44280904,3.33266667 8.66666667,3.55652429 8.66666667,3.83266667 C8.66666667,4.10880904 8.44280904,4.33266667 8.16666667,4.33266667 L2.5,4.33266667 C2.22385763,4.33266667 2,4.10880904 2,3.83266667 L2,3.83266667 Z M2,6.49933333 C2,6.22319096 2.22385763,5.99933333 2.5,5.99933333 L8.16666667,5.99933333 C8.34529947,5.99933333 8.51036298,6.09463279 8.59967939,6.24933333 C8.68899579,6.40403387 8.68899579,6.5946328 8.59967939,6.74933334 C8.51036298,6.90403388 8.34529947,6.99933333 8.16666667,6.99933333 L2.5,6.99933333 C2.22385763,6.99933333 2,6.77547571 2,6.49933333 L2,6.49933333 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DocumentOnePageIcon;
