import { Box, CircularProgress, IconButton } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { DocumentIDIcon } from 'src/assets/icons/DocumentIDIcon';
import { BlueDoneImage } from 'src/assets/images/BlueDoneImage';
import InvestorHubLogoSrc from 'src/assets/images/logo/investorbase-logo-light.png';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { Logo } from 'src/components/atoms/Logo';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { useGreenID } from 'src/components/molecules/GreenID';
import { GREEN_ID } from 'src/constants/greenId';
import {
  useCompletedDocumentIdKYC,
  useRegisterDocumentIdKYC,
} from 'src/modules/applications/hooks';
import { IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import NoteTerm from './NoteTerm';
import VerifyMethod from './VerifyMethod';

export default function VerifyDocumentID(props: IVerifyIndividualItem) {
  const documentIdModalRef = React.useRef<IBasicModalElement>(null);
  const alertModalRef = React.useRef<IBasicModalElement>(null);
  const [selectedIndividual, setSelectedIndividual] = useState('');

  const handleOpenDocumentIdModal = (status: boolean) => {
    if (status) {
      documentIdModalRef.current?.open();
    } else {
      documentIdModalRef.current?.close();
    }
  };

  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = useRegisterDocumentIdKYC({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (response: any) => {
      handleOpenDocumentIdModal(true);
      const token = get(response, 'token');
      initGreenID();
      if (token) greenVerifyDocumentID(token);
    },
  });
  const { mutate: completed, isSuccess: completedDocumentSuccess } = useCompletedDocumentIdKYC({
    onSuccess: () => {
      props.refetch && props.refetch();
      handleOpenDocumentIdModal(false);
    },
  });

  /**
   * Show KYC verification popup after completed document and refetch KYC info
   * Only show for selected individual to verify
   */
  useEffect(() => {
    if (
      completedDocumentSuccess &&
      !props.isRefetchingKYCInfo &&
      selectedIndividual === props.individualId
    ) {
      alertModalRef.current?.open();
      setSelectedIndividual('');
    }
  }, [completedDocumentSuccess, props.isRefetchingKYCInfo]);

  const { loading, domId, initGreenID, greenVerifyDocumentID, skipVerify, setArea } = useGreenID(
    {
      accountId: GREEN_ID.ACCOUNT_ID,
      apiCode: GREEN_ID.API_CODE,
      scriptConfig: GREEN_ID.SCRIPT_CONFIG,
      scriptUi: GREEN_ID.SCRIPT_UI,
      style: GREEN_ID.STYLE,
      monoStyle: GREEN_ID.MONO_STYLE,
      env: GREEN_ID.ENV,
      autoInitSource: true,
    },
    {
      enableBackButtonWarning: true,
      sessionCompleteCallback: () => {
        completed({
          appId: props.appId,
          individualId: props.individualId,
          code: props.code,
        });
      },
      sessionCancelledCallback: () => {
        handleOpenDocumentIdModal(false);
      },
    },
  );
  const onRegisterDocId = () => {
    if (props?.individual?.isKYCVerified) return;
    setSelectedIndividual(props.individualId);
    register({
      appId: props.appId,
      individualId: props.individualId,
      code: props.code,
    });
  };

  const onCloseAlert = () => {
    alertModalRef.current?.close();
    documentIdModalRef.current?.close();
  };

  React.useEffect(() => {
    if (isError) alertModalRef.current?.open();
    else alertModalRef.current?.close();
  }, [isError]);

  return (
    <>
      <VerifyMethod
        onClick={onRegisterDocId}
        icon={isLoading || loading ? CircularProgress : DocumentIDIcon}
        label='Document Verification'
        disabled={
          props?.individual?.isKYCVerified ||
          props?.individual?.overallStatus === IOverallStatus.VERIFIED ||
          props?.disabled
        }
      />
      <BasicModal
        ref={documentIdModalRef}
        maxWidth='xl'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        disableClose={true}
      >
        <Box className='w-[1120px]'>
          <Box className='flex justify-between items-center p-[45px] pb-5'>
            <Logo src={InvestorHubLogoSrc} alt='Funcorr' />
            <IconButton sx={{ p: 0 }} onClick={skipVerify}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display='flex' flexDirection='column' className='p-12.5 pt-0' fontSize='14px'>
            <Box className='rounded-xl border p-6 py-2'>
              <div ref={setArea} id={domId} />
            </Box>
            <Box mt={2} px={2}>
              <NoteTerm />
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal disableClose={true} className='h-full' ref={alertModalRef}>
        <ConfirmationAlert
          title={props.isVerified ? 'Verification complete' : 'Verification in progress'}
          isError={isError}
          description={get(error, 'data.ErrorMessage') || get(error, 'data.errorMessage') || ''}
          image={props?.isVerified ? null : <BlueDoneImage />}
          buttonAction={{
            label: 'Close',
            onAction: onCloseAlert,
          }}
        />
      </BasicModal>
    </>
  );
}
