import { Box, Typography } from '@mui/material';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import { SUBSCRIPTION_FEATURE_TYPE } from 'src/constants/subscription-billing';
import { useGetSubscriptionsFeatures } from 'src/modules/subscription-billing/hooks';
import { ISubscriptionAndBillingProps } from '.';

interface ISubscriptionProps extends ISubscriptionAndBillingProps {}

const Subscription = (props: ISubscriptionProps) => {
  const { isViewMode, isSuperAdmin, isCreatedClient } = props;
  const { data = [] } = useGetSubscriptionsFeatures();

  const canNotEditFeatures = [SUBSCRIPTION_FEATURE_TYPE.ClientManagement];

  return (
    <Box
      mx={-3}
      mb={-3}
      mt={2}
      p={3}
      bgcolor='neutral.ne100'
      borderRadius={'12px'}
      display={'flex'}
      flexDirection={'column'}
      gap={3}
      maxWidth={'720px'}
    >
      {data.map((it) => (
        <Box key={it.id} className='flex flex-col gap-2.5'>
          {it.baseFeature ? (
            <Typography variant='body2' pl={4} color='base.black' letterSpacing={'0.2px'}>
              {it.name}
            </Typography>
          ) : (
            <FormCheckbox
              name={`subscriptionFeatureIds.${it.id}`}
              label={
                <Box
                  component={'span'}
                  color={it.futureFeature ? 'neutral.ne400' : 'base.black'}
                  letterSpacing={'0.2px'}
                >
                  {it.name}
                </Box>
              }
              disabled={
                it.futureFeature ||
                isViewMode ||
                (canNotEditFeatures.includes(it.featureType) && isCreatedClient) ||
                (!isSuperAdmin && isCreatedClient)
              }
            />
          )}
          {it.childFeatures?.map((child, index) => (
            <Typography key={index} variant='body3' pl={4} color='neutral.ne800'>
              {child}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Subscription;
