import { REQUIRE_MESSAGE } from 'src/constants/common';
import {
  AMOUNT_TYPE,
  PAYMENT_METHOD,
  REDEMPTION_TYPE,
  TRANSFER_TYPE,
} from 'src/constants/investor-form';
import { removeSpacesInString } from 'src/utils/common';
import * as Yup from 'yup';
import { YupBase } from './common';

export const investorRedemptionFormSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  investmentEntityId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  fundId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  unitClassId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  redemptionType: Yup.number()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  amountType: Yup.number()
    .when(['isSaveDraft', 'redemptionType'], {
      is: (isSaveDraft: boolean, redemptionType: number) =>
        !isSaveDraft && redemptionType === REDEMPTION_TYPE.PARTIAL,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  netValue: Yup.number().nullable(),
  units: Yup.number().nullable(),
  redemptionAmount: Yup.number()
    .when(['isSaveDraft', 'amountType', 'redemptionType'], {
      is: (isSaveDraft: boolean, amountType: number, redemptionType: number) =>
        !isSaveDraft &&
        amountType === AMOUNT_TYPE.AMOUNT &&
        redemptionType === REDEMPTION_TYPE.PARTIAL,
      then: () =>
        YupBase.transformNumberSchema
          .typeError(REQUIRE_MESSAGE)
          .required(REQUIRE_MESSAGE)
          .test('redemptionAmount', 'Cannot exceed the current holding.', function (value) {
            return value <= this.parent.netValue;
          }),
    })
    .nullable(),
  redemptionUnits: Yup.number()
    .when(['isSaveDraft', 'amountType', 'redemptionType'], {
      is: (isSaveDraft: boolean, amountType: number, redemptionType: number) =>
        !isSaveDraft &&
        amountType === AMOUNT_TYPE.UNITS &&
        redemptionType === REDEMPTION_TYPE.PARTIAL,
      then: () =>
        YupBase.transformNumberSchema
          .typeError(REQUIRE_MESSAGE)
          .required(REQUIRE_MESSAGE)
          .test('redemptionUnits', 'Cannot exceed the current holding.', function (value) {
            return value <= this.parent.units;
          }),
    })
    .nullable(),
  redemptionDate: Yup.string().nullable(),
  paymentMethod: Yup.number()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  bankName: Yup.string().when(['isSaveDraft', 'paymentMethod'], {
    is: (isSaveDraft: boolean, paymentMethod: number) =>
      !isSaveDraft && paymentMethod === PAYMENT_METHOD.BANK_ACCOUNT,
    then: (schema) =>
      schema.test('bankName', REQUIRE_MESSAGE, function (value) {
        if (!value)
          return !this.parent.accountName && !this.parent.bsb && !this.parent.accountNumber;
        return !!value;
      }),
  }),
  accountName: Yup.string().when(['isSaveDraft', 'paymentMethod'], {
    is: (isSaveDraft: boolean, paymentMethod: number) =>
      !isSaveDraft && paymentMethod === PAYMENT_METHOD.BANK_ACCOUNT,
    then: (schema) =>
      schema.test('accountName', REQUIRE_MESSAGE, function (value) {
        if (!value) return !this.parent.bankName && !this.parent.bsb && !this.parent.accountNumber;
        return !!value;
      }),
  }),
  bsb: Yup.string().when(['isSaveDraft', 'paymentMethod'], {
    is: (isSaveDraft: boolean, paymentMethod: number) =>
      !isSaveDraft && paymentMethod === PAYMENT_METHOD.BANK_ACCOUNT,
    then: (schema) =>
      schema
        .test('bsb', REQUIRE_MESSAGE, function (value) {
          if (!value)
            return !this.parent.bankName && !this.parent.accountName && !this.parent.accountNumber;
          return !!value;
        })
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits'),
  }),
  accountNumber: Yup.string().when(['isSaveDraft', 'paymentMethod'], {
    is: (isSaveDraft: boolean, paymentMethod: number) =>
      !isSaveDraft && paymentMethod === PAYMENT_METHOD.BANK_ACCOUNT,
    then: (schema) =>
      schema.test('accountNumber', REQUIRE_MESSAGE, function (value) {
        if (!value) return !this.parent.bankName && !this.parent.accountName && !this.parent.bsb;
        return !!value;
      }),
  }),
  isDeclarationRead: Yup.boolean().when('isSaveDraft', {
    is: false,
    then: (schema) => schema.test('isDeclarationRead', REQUIRE_MESSAGE, (value) => value),
  }),
});

export const investorTransferRequestSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  investmentEntityId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  fundId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  unitClassId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  transferType: Yup.number()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  transferDate: Yup.string().when('isSaveDraft', {
    is: false,
    then: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
  units: Yup.number().nullable(),
  transferUnits: Yup.number()
    .when(['isSaveDraft', 'transferType'], {
      is: (isSaveDraft: boolean, transferType: number) =>
        !isSaveDraft && transferType === TRANSFER_TYPE.PARTIAL,
      then: () =>
        YupBase.transformNumberSchema
          .typeError(REQUIRE_MESSAGE)
          .required(REQUIRE_MESSAGE)
          .test('transferUnits', 'Cannot exceed the current holding.', function (value) {
            return value <= this.parent.units;
          }),
    })
    .nullable(),
  considerationAmount: Yup.number()
    .when('isSaveDraft', {
      is: false,
      then: () =>
        YupBase.transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE),
    })
    .nullable(),
  transfereeName: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  transfereeABN: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) =>
        schema.test('invalidAbn', 'ACN/ABN must have 9 or 11 digits.', (abn) => {
          const rawAbn = removeSpacesInString(abn);
          if (!rawAbn?.length || rawAbn?.length === 9 || rawAbn?.length === 11) return true;
          return false;
        }),
    })
    .nullable(),
  transfereeAddress: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  email: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => YupBase.email,
    })
    .nullable(),
  contactNumber: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => YupBase.contactNumber,
    })
    .nullable(),
  contactPersonName: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  isDeclarationRead: Yup.boolean().when('isSaveDraft', {
    is: false,
    then: (schema) => schema.test('isDeclarationRead', REQUIRE_MESSAGE, (value) => value),
  }),
});

export const investorChangeDetailSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  investmentEntityId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  funds: Yup.array()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.min(1, REQUIRE_MESSAGE),
    })
    .nullable(),
  infoChanges: Yup.array()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.min(1, REQUIRE_MESSAGE),
    })
    .nullable(),
  currentDetails: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  updatedDetails: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  isDeclarationRead: Yup.boolean().when('isSaveDraft', {
    is: false,
    then: (schema) => schema.test('isDeclarationRead', REQUIRE_MESSAGE, (value) => value),
  }),
});
