/* eslint-disable no-restricted-imports */
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CorporateIcon from 'src/assets/icons/CorporateIcon';
import IndividualIcon from 'src/assets/icons/IndividualIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'src/components/atoms/CustomAccordion';
import CustomButton from 'src/components/atoms/CustomButton';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  DEFAULT_UBOS_POSITION,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  australianCompanyDefaultValues,
  individualDefaultValue,
  numberOfIndividualOptions,
} from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import AustralianCompanyForm from './AustralianCompanyForm';
import IndividualDetail from './IndividualDetail';

interface ITrustTeeProps extends IInvestmentEntityProps {
  keyName:
    | 'investmentEntityDetails.regulatedTrust'
    | 'investmentEntityDetails.unregulatedTrust'
    | 'investmentEntityDetails.smsf';
  handleChangeTrusteeType?: () => void;
  onShowFullFieldsChange?: (isShowFullFields: boolean) => void;
  hideUBODeclaration?: boolean;
  enabledClearCorporateDetails?: boolean;
  isSuperAdmin?: boolean;
}

const TrustTee = ({ handleChangeTrusteeType, ...props }: ITrustTeeProps) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    keyName,
    isInvestmentEntitySetting,
    isReviewing,
    enabledClearCorporateDetails = false,
    hideUBODeclaration,
  } = props;
  const disabled = isViewMode || isDisabledEdit || isExistingInvestmentEntity;
  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext<IInvestmentEntityForm>();
  const watchTrusteeType = watch(`${keyName}.trusteeType`);
  const numberOfIndividuals = watch(`${keyName}.numberOfIndividuals`);
  const skycApplicationId = watch('investmentEntityDetails.skycApplicationId');
  const clearAllCorporateDetailsConfirmModalRef = useRef<IBasicModalElement>(null);
  const [isClearAllData, setIsClearAllData] = useState(false);

  const {
    fields: individuals,
    append: addIndividual,
    remove: removeIndividual,
  } = useFieldArray({
    control,
    name: `${keyName}.trusteeIndividuals`,
    keyName: 'dataId',
  });

  useEffect(() => {
    if (
      (!individuals.length || (numberOfIndividuals || 0) > individuals.length) &&
      numberOfIndividuals &&
      isDirty
    ) {
      Array.from(Array(numberOfIndividuals - (individuals.length || 0))).forEach(() => {
        addIndividual({
          ...individualDefaultValue(true),
          position: DEFAULT_UBOS_POSITION.TRUSTEE,
        });
      });
    }
  }, [numberOfIndividuals]);

  const handleClearAllCorporateDetails = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
  ) => {
    e.stopPropagation();
    if (trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) {
      setValue(`${keyName}.trusteeAustralianCompanyDetails`, australianCompanyDefaultValues, {
        shouldDirty: true,
      });
      setValue(`${keyName}.trusteeAustralianCompanyDetails.directors`, [], {
        shouldDirty: true,
      });
      setValue(`${keyName}.trusteeAustralianCompanyDetails.ubOs`, [], {
        shouldDirty: true,
      });
    } else {
      setValue(`${keyName}.trusteeIndividuals`, [], {
        shouldDirty: true,
      });
      setValue(`${keyName}.numberOfIndividuals`, null);
    }

    handleOpenClearAllModal(false);
    setIsClearAllData(true);
  };

  const handleOpenClearAllModal = (isOpen?: boolean) => {
    if (isOpen || typeof isOpen !== 'boolean') {
      clearAllCorporateDetailsConfirmModalRef.current?.open();
    } else {
      clearAllCorporateDetailsConfirmModalRef.current?.close();
    }
  };

  const trusteeTypeOptions = [
    {
      value: INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
      label: 'Corporate',
      icon: <CorporateIcon />,
    },
    {
      value: INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
      label: 'Individual',
      icon: <IndividualIcon />,
    },
  ];

  return (
    <>
      <Box className='flex flex-col'>
        <Typography mb={3} fontWeight={600} variant='body3'>
          Trustee
        </Typography>
        <FormRadioGroup
          name={`${keyName}.trusteeType`}
          options={trusteeTypeOptions}
          label={
            <Typography variant='body3' fontWeight={500}>
              Trustee Type
            </Typography>
          }
          isEnumValue
          disabled={disabled || (!!skycApplicationId && !isInvestmentEntitySetting)}
          sx={{ mb: 3 }}
          optionGap={4}
          onChangeValue={handleChangeTrusteeType}
          showPickerOnly={isReviewing}
        />
        {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual && (
          <Box>
            <Box className='flex items-center justify-between'>
              <Box className='flex items-center'>
                <Typography variant='body2' fontWeight={500} mr={1.5} whiteSpace={'nowrap'}>
                  Number of individuals
                </Typography>
                <Box width={'160px'}>
                  <FormSelect
                    name={`${keyName}.numberOfIndividuals`}
                    options={numberOfIndividualOptions}
                    placeholder='Select number'
                    disabled={disabled}
                  />
                </Box>
              </Box>
              {enabledClearCorporateDetails && !disabled && (
                <CustomButton
                  variant='text'
                  sx={(theme) => ({ px: 1, py: 1, color: theme.palette.primary.main })}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenClearAllModal();
                  }}
                >
                  Clear All
                </CustomButton>
              )}
            </Box>
            {!!individuals.length && (
              <Box mt={3} className='flex flex-col gap-4'>
                {individuals.map((individual, index) => (
                  <IndividualDetail
                    key={individual.dataId}
                    {...props}
                    keyName={`${keyName}.trusteeIndividuals.${index}`}
                    canDelete={individuals.length > (numberOfIndividuals || 0)}
                    handleDelete={() => removeIndividual(index)}
                    hideTfn
                    defaultExpanded
                    autocompleteAddress
                    disabledBasicInfoFields={
                      individual.dob && isInvestmentEntitySetting ? ['dob'] : []
                    }
                    hideDeclaration={hideUBODeclaration}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
        {watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate && (
          <>
            <Accordion
              defaultExpanded
              sx={(theme) => ({
                mt: '0 !important',
                '.MuiAccordionSummary-root': { px: 2.5, py: 3 },
                borderWidth: '1px',
                borderBottom: `1px solid ${theme.palette.neutral.ne300} !important`,
              })}
            >
              <AccordionSummary
                sx={{
                  '.MuiSvgIcon-root': { fontSize: '18px' },
                  '.MuiAccordionSummary-content': { my: 0 },
                  minHeight: 'unset',
                }}
              >
                <Box className='flex justify-between items-center w-full'>
                  <Typography variant='body2' fontWeight={600}>
                    Corporate Trustee Details
                  </Typography>
                  {enabledClearCorporateDetails && !disabled && (
                    <CustomButton
                      variant='text'
                      sx={(theme) => ({ px: 1, py: 1, color: theme.palette.primary.main })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenClearAllModal();
                      }}
                    >
                      Clear All
                    </CustomButton>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                className='p-6 mt-0 border-t'
                sx={{ borderColor: 'neutral.ne200', borderStyle: 'solid' }}
              >
                <AustralianCompanyForm
                  {...props}
                  keyName={`${keyName}.trusteeAustralianCompanyDetails`}
                  disabledFields={
                    isClearAllData
                      ? []
                      : isInvestmentEntitySetting
                      ? ['countryOfEstablishment']
                      : []
                  }
                  searchAbnFor='TrustTee'
                  isClearAllData={isClearAllData}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
      <ConfirmModal
        ref={clearAllCorporateDetailsConfirmModalRef}
        title='Clear all details?'
        content={`Are you sure you want to clear all ${
          watchTrusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate ? 'Corporate' : 'Individual'
        } Trustee details?<br />
        This action cannot be undone.`}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={() => clearAllCorporateDetailsConfirmModalRef?.current?.close()}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color='error'
              onClick={(e) => handleClearAllCorporateDetails(e, watchTrusteeType)}
            >
              Clear All
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default TrustTee;
