import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import RegulatedTrust from 'src/components/pages/applications/components/InvestmentEntity/Trust/RegulatedTrust';
import UnregulatedTrust from 'src/components/pages/applications/components/InvestmentEntity/Trust/UnregulatedTrust';
import { INVESTMENT_ENTITY_TRUST } from 'src/constants/applications';

interface ITrustProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const Trust: FC<ITrustProps> = (props) => {
  const { watch } = useFormContext();
  const watchTrust = watch('trustType');

  return (
    <>
      {watchTrust === INVESTMENT_ENTITY_TRUST.Regulated && <RegulatedTrust {...props} />}
      {watchTrust === INVESTMENT_ENTITY_TRUST.Unregulated && <UnregulatedTrust {...props} />}
    </>
  );
};

export default Trust;
