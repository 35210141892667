import { TypeOptions, toast } from 'react-toastify';
import { TOAST_CONFIG } from 'src/configs/toast';

export const showToast = (
  message: string,
  type: TypeOptions = 'default',
  config = TOAST_CONFIG,
) => {
  toast(message, {
    ...config,
    type,
  });
};
