const CompanyIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-714.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1-Copy-2' transform='translate(220.000000, 43.000000)'>
                  <g id='company-(1)-copy' transform='translate(8.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g id='company-(1)' mask='url(#mask-2)'>
                      <g transform='translate(4.000000, 7.000000)'>
                        <polyline
                          id='Path'
                          fill='#B5B5B5'
                          fillRule='nonzero'
                          points='18.4000783 41.400014 18.4000783 6.13345506 39.8666841 6.13345506 39.8666841 41.400014'
                        ></polyline>
                        <polygon
                          id='Path'
                          fill='#CCCCCC'
                          fillRule='nonzero'
                          points='18.4000783 6.13345506 35.2666971 6.13345506 35.2666971 39.1000211 18.4000783 39.1000211'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='27.6000522 15.333427 30.6667101 15.333427 30.6667101 18.4000843 27.6000522 18.4000843'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#939393'
                          fillRule='nonzero'
                          points='6.13344639 12.2667697 18.4000783 12.2667697 18.4000783 41.400014 6.13344639 41.400014'
                        ></polygon>
                        <path
                          d='M16.1000848,37.5666924 L16.1000848,12.2667697 L6.13344639,12.2667697 L6.13344639,39.1000211 L14.5667558,39.1000211 C15.41359,39.1000211 16.1000848,38.4135264 16.1000848,37.5666924 Z'
                          id='Path'
                          fill='#A0A0A0'
                          fillRule='nonzero'
                        ></path>
                        <g id='Group-8' strokeWidth='1' fill='none' fillRule='evenodd'>
                          <ellipse
                            id='Oval'
                            fill='#F4A93C'
                            fillRule='nonzero'
                            cx='7.66677537'
                            cy='7.66678371'
                            rx='7.66664493'
                            ry='7.66664326'
                          ></ellipse>
                          <path
                            d='M0.000130447947,7.66678371 C0,10.5178688 1.58321957,13.1331497 4.10945213,14.4548296 C7.82243041,15.0749939 11.4357706,12.9055327 12.6337341,9.33682294 C13.8316977,5.76811317 12.2592308,1.85785331 8.92410514,0.112073443 C6.70026522,-0.273440741 4.41992365,0.346440198 2.69730276,1.80474798 C0.974681863,3.26305576 -0.0130601201,5.40981471 0.000130447947,7.66678371 L0.000130447947,7.66678371 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M10.7334333,6.13345506 L10.7334333,5.36679073 C10.7334333,4.09653969 9.70369119,3.06679775 8.43343987,3.06679775 L8.43343987,2.30013343 L6.90011088,2.30013343 L6.90011088,3.06679775 C5.62985956,3.06679775 4.6001174,4.09653969 4.6001174,5.36679073 L4.6001174,6.13345506 C4.6001174,7.4037061 5.62985956,8.43344804 6.90011088,8.43344804 L6.90011088,10.733441 C6.47669377,10.733441 6.13344639,10.3901937 6.13344639,9.96677669 L4.6001174,9.96677669 C4.6001174,11.2370277 5.62985956,12.2667697 6.90011088,12.2667697 L6.90011088,13.033434 L8.43343987,13.033434 L8.43343987,12.2667697 C9.70369119,12.2667697 10.7334333,11.2370277 10.7334333,9.96677669 L10.7334333,9.20011236 C10.7334333,7.92986131 9.70369119,6.90011938 8.43343987,6.90011938 L8.43343987,4.60012641 C8.85685697,4.60012641 9.20010436,4.94337372 9.20010436,5.36679073 L9.20010436,6.13345506 L10.7334333,6.13345506 Z M9.20010436,9.20011236 L9.20010436,9.96677669 C9.20010436,10.3901937 8.85685697,10.733441 8.43343987,10.733441 L8.43343987,8.43344804 C8.85685697,8.43344804 9.20010436,8.77669535 9.20010436,9.20011236 Z M6.90011088,6.90011938 C6.47669377,6.90011938 6.13344639,6.55687207 6.13344639,6.13345506 L6.13344639,5.36679073 C6.13344639,4.94337372 6.47669377,4.60012641 6.90011088,4.60012641 L6.90011088,6.90011938 Z'
                            id='Shape'
                            fill='#FCE797'
                            fillRule='nonzero'
                          ></path>
                        </g>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='21.4667362 9.20011236 24.5333942 9.20011236 24.5333942 12.2667697 21.4667362 12.2667697'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='27.6000522 9.20011236 30.6667101 9.20011236 30.6667101 12.2667697 27.6000522 12.2667697'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='21.4667362 15.333427 24.5333942 15.333427 24.5333942 18.4000843 21.4667362 18.4000843'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='21.4667362 21.4667416 24.5333942 21.4667416 24.5333942 24.5333989 21.4667362 24.5333989'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='27.6000522 21.4667416 30.6667101 21.4667416 30.6667101 24.5333989 27.6000522 24.5333989'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='33.7333681 15.333427 36.8000261 15.333427 36.8000261 18.4000843 33.7333681 18.4000843'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='33.7333681 21.4667416 36.8000261 21.4667416 36.8000261 24.5333989 33.7333681 24.5333989'
                        ></polygon>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='33.7333681 9.20011236 36.8000261 9.20011236 36.8000261 12.2667697 33.7333681 12.2667697'
                        ></polygon>
                        <polyline
                          id='Path'
                          fill='#345F7F'
                          fillRule='nonzero'
                          points='23.0000652 41.400014 23.0000652 29.1333848 35.2666971 29.1333848 35.2666971 41.400014'
                        ></polyline>
                        <path
                          d='M32.2000391,38.3333567 L32.2000391,29.1333848 L23.0000652,29.1333848 L23.0000652,39.1000211 L31.4333746,39.1000211 C31.8567917,39.1000211 32.2000391,38.7567738 32.2000391,38.3333567 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        ></path>
                        <g
                          id='Group'
                          strokeWidth='1'
                          fill='none'
                          fillRule='evenodd'
                          transform='translate(8.433440, 19.933413)'
                        >
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='6.13331594 0 7.66664493 0 7.66664493 1.53332865 6.13331594 1.53332865'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='3.06665797 0 4.59998696 0 4.59998696 1.53332865 3.06665797 1.53332865'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='0 3.0666573 1.53332899 3.0666573 1.53332899 4.59998595 0 4.59998595'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='3.06665797 3.0666573 4.59998696 3.0666573 4.59998696 4.59998595 3.06665797 4.59998595'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='6.13331594 3.0666573 7.66664493 3.0666573 7.66664493 4.59998595 6.13331594 4.59998595'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='0 6.13331461 1.53332899 6.13331461 1.53332899 7.66664326 0 7.66664326'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='3.06665797 6.13331461 4.59998696 6.13331461 4.59998696 7.66664326 3.06665797 7.66664326'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='6.13331594 6.13331461 7.66664493 6.13331461 7.66664493 7.66664326 6.13331594 7.66664326'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='0 9.19997191 1.53332899 9.19997191 1.53332899 10.7333006 0 10.7333006'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='3.06665797 9.19997191 4.59998696 9.19997191 4.59998696 10.7333006 3.06665797 10.7333006'
                          ></polygon>
                          <polygon
                            id='Path'
                            fill='#F2F2F2'
                            fillRule='nonzero'
                            points='6.13331594 9.19997191 7.66664493 9.19997191 7.66664493 10.7333006 6.13331594 10.7333006'
                          ></polygon>
                        </g>
                        <polygon
                          id='Path'
                          fill='#3B5892'
                          fillRule='nonzero'
                          points='28.3667167 29.1333848 29.9000457 29.1333848 29.9000457 41.400014 28.3667167 41.400014'
                        ></polygon>
                        <polyline
                          id='Path'
                          fill='#345F7F'
                          fillRule='nonzero'
                          points='20.7000717 6.13345506 20.7000717 0.000140451048 37.5666906 0.000140451048 37.5666906 6.13345506'
                        ></polyline>
                        <path
                          d='M34.5000326,2.30013343 L34.5000326,0.000140451048 L20.7000717,0.000140451048 L20.7000717,3.83346208 L32.9667036,3.83346208 C33.8135378,3.83346208 34.5000326,3.14696746 34.5000326,2.30013343 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        ></path>
                        <polygon
                          id='Path'
                          fill='#345E80'
                          fillRule='nonzero'
                          points='0.000130447947 41.400014 46 41.400014 46 46 0.000130447947 46'
                        ></polygon>
                        <path
                          d='M43.7000065,42.9333427 L43.7000065,41.400014 L0.000130447947,41.400014 L0.000130447947,43.700007 L42.933342,43.700007 C43.3567591,43.700007 43.7000065,43.3567597 43.7000065,42.9333427 Z'
                          id='Path'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        ></path>
                        <polygon
                          id='Path'
                          fill='#F2F2F2'
                          fillRule='nonzero'
                          points='8.43343987 19.9334129 9.96676885 19.9334129 9.96676885 21.4667416 8.43343987 21.4667416'
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default CompanyIcon;
