import { INVESTOR_FORM_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { INVESTOR_FORM_TYPE } from 'src/constants/investor-form';
import { portalApi } from 'src/helpers/axios';
import {
  IChangeDetailsForm,
  IRedemptionFormFields,
  ITransferForm,
  InvestorFormQueryBody,
} from 'src/modules/investor-form/type';
import { convertObjToFormData, serializeObject } from 'src/utils/common';
import {
  IGetRedemptionQueryParams,
  IInvestorFormMasterData,
  IRedemptionPopulateData,
} from './type';

export const getInvestorFormList = async (data: InvestorFormQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: INVESTOR_FORM_API.getInvestorFormList.api,
  });

  return result.data;
};

export const getInvestorFormId = async ({
  formType,
  entityId,
}: {
  formType: INVESTOR_FORM_TYPE;
  entityId: string;
}): Promise<string> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      entityId,
    },
    url: INVESTOR_FORM_API.getInvestorFormId.api(formType),
  });

  return result.data;
};

export const getInvestorFormMasterData = async (): Promise<IInvestorFormMasterData> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTOR_FORM_API.getInvestorFormMasterData.api,
  });

  return result.data;
};

export const getInvestorFormPopulateData = async (
  params: IGetRedemptionQueryParams,
): Promise<IRedemptionPopulateData> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params,
    url: INVESTOR_FORM_API.getInvestorFormPopulateData.api,
  });

  return result.data;
};

export const upsertRedemptionRequest = async ({
  id,
  data,
}: {
  id?: string;
  data: IRedemptionFormFields;
}): Promise<string> => {
  const { file, ...restData } = data;
  const serializeData = serializeObject(restData, true);
  const formData = convertObjToFormData({
    ...serializeData,
    file,
  });

  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    headers: id
      ? {
          'Content-Type': 'multipart/form-data',
        }
      : {},
    data: id ? formData : serializeData,
    url: INVESTOR_FORM_API.upsertRedemptionRequest.api(id),
  });

  return result.data;
};

export const upsertTransferRequest = async ({
  id,
  data,
}: {
  id?: string;
  data: ITransferForm;
}): Promise<string> => {
  const { file, ...restData } = data;
  const serializeData = serializeObject(restData, true);
  const formData = convertObjToFormData({
    ...serializeData,
    file,
  });

  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    headers: id
      ? {
          'Content-Type': 'multipart/form-data',
        }
      : {},
    data: id ? formData : serializeData,
    url: INVESTOR_FORM_API.upsertTransferRequest.api(id),
  });

  return result.data;
};

export const upsertChangeDetail = async ({
  id,
  data,
}: {
  id?: string;
  data: IChangeDetailsForm;
}): Promise<string> => {
  const { file, ...restData } = data;
  const serializeData = serializeObject(restData, true);

  const formData = convertObjToFormData({
    ...serializeData,
    file,
  });

  const result = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    headers: id
      ? {
          'Content-Type': 'multipart/form-data',
        }
      : {},
    data: id ? formData : serializeData,
    url: INVESTOR_FORM_API.upsertChangeDetail.api(id),
  });

  return result.data;
};

export const submitInvestorForm = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    url: INVESTOR_FORM_API.submitInvestorForm.api(id),
  });

  return result.data;
};

export const downloadInvestorPDF = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTOR_FORM_API.downloadInvestorPDF.api(id),
    responseType: 'arraybuffer',
  });
  return result.data;
};

export const getInvestorFormById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTOR_FORM_API.getInvestorFormById.api(id),
  });

  return result.data;
};

export const deleteInvestorForm = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: INVESTOR_FORM_API.deleteInvestorForm.api(id),
  });

  return result.data;
};

export const downloadInvestorFormFile = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: INVESTOR_FORM_API.downloadInvestorFormFile.api(id),
    responseType: 'arraybuffer',
  });
  return result.data;
};
