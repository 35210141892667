import { FC, SVGProps } from 'react';

const ContractIcon: FC<SVGProps<SVGSVGElement>> = ({ width = '55', height = '61', ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 55 61'
      version='1.1'
      {...props}
    >
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Acceptance_11-(1)'
          transform='translate(-878.000000, -1220.000000)'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Signatories-List' transform='translate(220.000000, 751.000000)'>
                <g id='DocuSign-expand' transform='translate(0.000000, 40.000000)'>
                  <g id='DocuSign-' transform='translate(24.000000, 64.000000)'>
                    <g id='Singature-group' transform='translate(23.000000, 68.000000)'>
                      <g id='Signature-default' transform='translate(285.000000, 0.000000)'>
                        <g id='contract-(1)' transform='translate(36.000000, 30.000000)'>
                          <g id='Group'>
                            <path
                              d='M50.9137763,16.7709492 L32.8986171,4.31991992 C29.6509267,2.07531055 25.3497893,2.07531055 22.1020989,4.31991992 L4.08693964,16.7709492 C1.52778441,18.5395918 0.000715981132,21.4493633 0.000715981132,24.5567891 L0.000715981132,51.530584 C0.000715981132,56.7603809 4.24708075,61 9.48519872,61 L45.5155173,61 C50.7536352,61 55,56.7603809 55,51.530584 L55,24.5567891 C55,21.4493633 53.4729316,18.5395918 50.9137763,16.7709492 Z'
                              id='Path'
                              fill='#FFD064'
                            />
                            <path
                              d='M5.75768161,24.5564316 L5.75768161,51.5312988 C5.75768161,56.7610957 10.0033304,61 15.2414484,61 L9.48519872,61 C4.24696142,61 0,56.7610957 0,51.5312988 L0,24.5564316 C0,21.449959 1.52778441,18.5393535 4.08658165,16.7704727 L22.1016216,4.31968164 C24.5610168,2.62097461 27.6247,2.20815234 30.3783635,3.08264453 C29.49711,3.36310156 28.6464051,3.77592383 27.8593032,4.31968164 L9.84295062,16.7704727 C7.28415338,18.5393535 5.75768161,21.449959 5.75768161,24.5564316 Z'
                              id='Path'
                              fill='#FFC250'
                            />
                            <path
                              d='M54.998568,24.3791504 L54.9973747,24.3791504 C54.9408122,27.4193809 53.4238869,30.2520684 50.9148503,31.9863984 L48.6603451,33.5439238 L32.8980204,39.2265742 C29.651404,41.4726133 25.350028,41.4726133 22.1022182,39.2265742 L6.87449285,33.9120684 L4.08765562,31.9862793 C1.57611313,30.2520684 0.0591877736,27.4192617 0.00381856604,24.3790312 C0.0591877736,21.3401113 1.57730644,18.5061133 4.08765562,16.7705918 L6.87449285,14.8436113 L22.1020989,4.3189668 C25.3499087,2.07531055 29.6512846,2.07531055 32.8992137,4.3189668 L48.6603451,15.2130664 L50.9148503,16.7705918 C53.4237675,18.5062324 54.9420055,21.3402305 54.998568,24.3791504 Z'
                              id='Path'
                              fill='#EBB044'
                            />
                            <g transform='translate(6.873658, 0.000000)' id='Path'>
                              <path
                                d='M41.7865682,4.23687891 L41.7865682,33.5439238 L26.0242436,44.436832 C22.7776271,46.6828711 18.4762511,46.6828711 15.2284414,44.436832 L0.000835311321,33.9120684 L0.000835311321,13.9244414 C0.000835311321,12.5203691 0.558942604,11.1726504 1.5534404,10.1797324 L10.1967646,1.55133008 C11.1913818,0.558412109 12.5399322,0 13.9462385,0 L37.542948,0 C39.8859963,0 41.7865682,1.89755273 41.7865682,4.23687891 Z'
                                fill='#F9F6F9'
                              />
                              <g transform='translate(0.000000, 0.000119)'>
                                <path
                                  d='M41.7865682,28.3335469 L41.7865682,33.5436855 L26.0242436,44.4365937 C22.7776271,46.6826328 18.4762511,46.6826328 15.2284414,44.4365937 L0.000835311321,33.9119492 L0.000835311321,28.7018105 L15.2284414,39.2264551 C18.4762511,41.4724941 22.7776271,41.4724941 26.0242436,39.2264551 L41.7865682,28.3335469 Z'
                                  fillOpacity='0.195476399'
                                  fill='#685E68'
                                />
                                <path
                                  d='M14.8526706,0 C13.1384924,0 11.7500857,1.3871543 11.7500857,3.09765625 L11.7500857,7.49466016 C11.7500857,9.83458203 9.85022976,11.7314199 7.50658486,11.7314199 L3.10258491,11.7314199 C1.38936139,11.7314199 0,13.1185742 0,14.8290762 L0,13.9245605 C0,12.5200117 0.558823274,11.1734844 1.55367906,10.1800898 L10.1962873,1.55121094 C11.1911431,0.557935547 12.5399322,0 13.9467158,0 L14.8526706,0 L14.8526706,0 Z'
                                  fill='#DDDAEC'
                                />
                              </g>
                            </g>
                          </g>
                          <path
                            d='M27.766703,36.3495664 L26.99225,36.3495664 C26.3186311,36.3495664 25.7703089,35.8021152 25.7703089,35.1295664 L25.7703089,32.9522715 C25.7703089,31.6709141 24.7266471,30.6289102 23.4432509,30.6289102 C22.1598547,30.6289102 21.1161929,31.6709141 21.1161929,32.9522715 L21.1161929,33.8986055 C21.1161929,34.1648848 20.898654,34.3810059 20.6318317,34.3810059 L20.5077283,34.3810059 L20.5077283,32.1545059 C20.5077283,29.9121602 18.6797091,28.0870449 16.432483,28.0870449 C14.1865695,28.0870449 12.3585503,29.9121602 12.3585503,32.1545059 C12.3585503,32.2723359 12.3634429,32.3876641 12.3732279,32.5031113 C12.5515072,34.5835449 14.3044677,36.2220859 16.432483,36.2220859 L18.6637188,36.2220859 L18.6637188,38.5368691 C18.6637188,39.0450039 19.0755273,39.4573496 19.5856639,39.4573496 C20.0946071,39.4573496 20.5076089,39.0450039 20.5076089,38.5368691 L20.5076089,36.2220859 L20.6317123,36.2220859 C21.9151085,36.2220859 22.960083,35.180082 22.960083,33.8987246 L22.960083,32.9523906 C22.960083,32.6861113 23.1764286,32.4699902 23.4432509,32.4699902 C23.7100732,32.4699902 23.9264188,32.6859922 23.9264188,32.9523906 L23.9264188,35.1296855 C23.9264188,36.8173125 25.3020573,38.1907656 26.9923694,38.1907656 L27.7668223,38.1907656 C28.2769589,38.1907656 28.6887673,37.7784199 28.6887673,37.2702852 C28.6887673,36.7608398 28.2769589,36.3495664 27.766703,36.3495664 L27.766703,36.3495664 Z M16.432483,34.3808867 C15.2031434,34.3808867 14.2025597,33.383084 14.2025597,32.1543867 C14.2025597,30.927 15.2032627,29.9278867 16.432483,29.9278867 C17.6630159,29.9278867 18.6637188,30.927 18.6637188,32.1543867 L18.6637188,34.3808867 L16.432483,34.3808867 Z'
                            id='Shape'
                            fill='#685E68'
                          />
                          <g id='Group' transform='translate(13.806622, 7.055031)' fill='#685E68'>
                            <g transform='translate(0.000000, 10.163172)' id='Path'>
                              <path d='M26.4648106,1.84096094 L0.921945039,1.84096094 C0.412643793,1.84096094 0,1.42885352 0,0.920480469 C0,0.412107422 0.412763123,0 0.921945039,0 L26.4648106,0 C26.9741119,0 27.3867557,0.412107422 27.3867557,0.920480469 C27.3867557,1.42885352 26.9741119,1.84096094 26.4648106,1.84096094 Z' />
                              <path d='M20.6504472,8.42491016 L0.921945039,8.42491016 C0.412643793,8.42491016 0,8.01280273 0,7.50442969 C0,6.99605664 0.412763123,6.58394922 0.921945039,6.58394922 L20.6504472,6.58394922 C21.1597484,6.58394922 21.5723922,6.99605664 21.5723922,7.50442969 C21.5723922,8.01280273 21.1596291,8.42491016 20.6504472,8.42491016 Z' />
                            </g>
                            <path
                              d='M19.4048787,1.84096094 L7.98199633,1.84096094 C7.47269508,1.84096094 7.06005129,1.42885352 7.06005129,0.920480469 C7.06005129,0.412107422 7.47281441,0 7.98199633,0 L19.4048787,0 C19.9141799,0 20.3268237,0.412107422 20.3268237,0.920480469 C20.3268237,1.42885352 19.9141799,1.84096094 19.4048787,1.84096094 Z'
                              id='Path'
                            />
                          </g>
                          <g id='Group' transform='translate(5.619294, 48.112953)' fill='#FFC250'>
                            <path
                              d='M0.922625955,8.77800578 C0.570482568,8.77800578 0.234210095,8.57546672 0.0808708029,8.2337714 C-0.127360377,7.76983781 0.0803934821,7.22524601 0.544945907,7.01722648 L16.0431928,0.0807401527 C16.5076259,-0.127160238 17.0534422,0.0802635902 17.2616734,0.544197184 C17.4699046,1.00813078 17.2621507,1.55272257 16.7975983,1.76074211 L1.29935136,8.69722843 C1.17691859,8.75203312 1.04875796,8.77800578 0.922625955,8.77800578 Z'
                              id='Path'
                            />
                            <path
                              d='M42.8391447,8.77800578 C42.7130127,8.77800578 42.5849714,8.75203312 42.4624193,8.69722843 L26.9641724,1.76074211 C26.49962,1.55284172 26.2917468,1.00813078 26.5000973,0.544197184 C26.7084478,0.0802635902 27.2540254,-0.127160238 27.7185778,0.0807401527 L43.2168248,7.01722648 C43.6813772,7.22512687 43.8892504,7.76983781 43.6808999,8.2337714 C43.5274413,8.57534757 43.1912881,8.77800578 42.8391447,8.77800578 L42.8391447,8.77800578 Z'
                              id='Path'
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default ContractIcon;
