/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomSwitch } from 'src/components/atoms/CustomSwitch';
import {
  HyperlinkTextSetting,
  LogoSetting,
  NavigationMenuSetting,
  SystemButtonsSetting,
} from 'src/components/molecules/organisation-settings';
import { SUBMIT_TYPES } from 'src/components/pages/organisation-settings';
import { MAX_IMAGE_SIZE } from 'src/constants/common';
import { showToast } from 'src/helpers/toast';
import { useGetClientDetail } from 'src/modules/client/hooks';
import { useUploadImage } from 'src/modules/common/hooks';
import { IUploadFile } from 'src/modules/common/type';
import { BrandingParams, BrandingResponse } from 'src/modules/organisation-settings/type';
import { BrandingContext, BrandingDefault } from 'src/providers/BrandingContextProvider';
import { handleErrorFromServer } from 'src/utils/common';
import { FUND_UPSERT_CLIENT_STEP } from './FundsCreateUpdateClientForm';

interface BrandingProps {
  clientDetails: any;
  isViewMode: boolean;
  clientId: string;
}
export interface BrandingHandler {
  onFundSubmitBranding: (callback: (params: any, type: string) => void) => void;
}

const Branding = React.forwardRef<BrandingHandler, BrandingProps>(
  ({ isViewMode, clientId }, forwardedRef) => {
    const { mutate: uploadImageMutate, isLoading } = useUploadImage();
    const [logo, setLogo] = React.useState<string>();
    const [fields, setFields] = React.useState<BrandingParams>(BrandingDefault);
    const [branding, setBranding] = React.useState<BrandingResponse | undefined>();
    const { setReadOnly: setReadOnlyBranding } = React.useContext(BrandingContext);
    const { reset } = useFormContext<BrandingParams>();
    const { data: clientDetails } = useGetClientDetail(clientId, FUND_UPSERT_CLIENT_STEP.BRANDING);

    React.useImperativeHandle(forwardedRef, () => ({
      onFundSubmitBranding: (callback: (params: BrandingParams, type: string) => void) =>
        callback({ ...fields, id: branding?.id }, SUBMIT_TYPES.BRANDING),
    }));

    React.useEffect(() => {
      if (!isEmpty(branding)) {
        setFields(branding);
        setLogo(branding.logoLink);
      }
    }, [branding]);

    React.useEffect(() => {
      if (!isEmpty(clientDetails?.branding)) {
        setBranding(clientDetails?.branding);
      }
    }, [clientDetails?.branding]);

    React.useEffect(() => {
      setReadOnlyBranding(isViewMode);

      return () => {
        setReadOnlyBranding(false);
      };
    }, [isViewMode]);

    React.useEffect(() => {
      reset({ ...fields, id: branding?.id });
    }, [fields]);

    const handleChangeFields = (fieldName: string, value: string | boolean | IUploadFile) => {
      setFields((prev: BrandingParams) => ({
        ...prev,
        [fieldName]: value,
      }));
    };

    const handleDefaultToStandard = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setFields(BrandingDefault);
      }
      handleChangeFields('defaultToStandard', event.target.checked);
    };

    const handleChangeLogo = (file: File) => {
      if (file) {
        const fileSizeKiloBytes = file.size / 1024;
        if (fileSizeKiloBytes > MAX_IMAGE_SIZE) {
          showToast('File size is greater than maximum limit', 'error');
        } else {
          uploadImageMutate(file, {
            onSuccess: (logo: IUploadFile) => {
              const localLogoInfo = URL.createObjectURL(file);
              setLogo(localLogoInfo);
              handleChangeFields('fileUpload', logo);
            },
            onError: handleErrorFromServer,
          });
        }
      }
    };

    return (
      <Box className='w-full p-6'>
        <LogoSetting
          isLoading={isLoading}
          logo={logo}
          onChange={handleChangeLogo}
          sx={{
            backgroundColor: 'neutral.ne100',
            '& p': {
              fontSize: 16,
            },
          }}
          logoHeight='127px'
          logoWidth='229px'
          readOnly={isViewMode}
        />
        <Box className='flex my-[20px] justify-end'>
          <CustomSwitch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={fields.defaultToStandard}
            onChange={handleDefaultToStandard}
            disabled={isViewMode}
          />
          <Typography ml='10px' variant='body3' color='neutral.ne800'>
            Default to Standard
          </Typography>
        </Box>
        <NavigationMenuSetting
          mb='20px'
          backgroundNavigation={fields.backgroundNavigation}
          backgroundActive={fields.backgroundActive}
          text={fields.text}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
          sx={{
            backgroundColor: 'neutral.ne100',
          }}
        />
        <SystemButtonsSetting
          mb='20px'
          primaryBackground={fields.primaryBackground}
          primaryText={fields.primaryText}
          contactLawyerBackground={fields.contactLawyerBackground}
          contactLawyerText={fields.contactLawyerText}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
          sx={{
            backgroundColor: 'neutral.ne100',
          }}
        />
        <HyperlinkTextSetting
          hyperlinkText={fields.hyperlinkText}
          onChangeFields={handleChangeFields}
          disabled={fields.defaultToStandard}
          sx={{
            backgroundColor: 'neutral.ne100',
          }}
        />
      </Box>
    );
  },
);

export default Branding;
