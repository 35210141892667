/* eslint-disable autofix/no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CurrencyName from 'src/components/atoms/CurrencyName';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import SplitButton from 'src/components/atoms/SplitButton';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { INVESTMENT_ENTITY_STEP } from 'src/components/pages/administration-investor/investor/components/UpsertInvestorForm';
import {
  ADMINISTRATION_INVESTOR_ACTION_NAME,
  MAX_INVESTOR_NUMBER,
} from 'src/constants/administration-investor';
import { FundType } from 'src/constants/funds-management';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { UnitClassInvestorType, UnitClassOfferType } from 'src/constants/unit-class';
import { useRole } from 'src/hooks/useRole';
import {
  useGetInvestorById,
  useGetInvestorEntities,
  useGetInvestorFunds,
  useGetInvestorUnitClassesByFund,
} from 'src/modules/administration-investor/hooks';
import { IInvestorDetailsForm } from 'src/modules/administration-investor/types';
import { IUpsertInvestmentApplicationForm } from 'src/modules/applications/type';
import { IAlertInfo } from 'src/modules/common/type';
import InvestorActionModal from './InvestorActionModal';

export interface InvestorDetailsProps extends IUpsertInvestmentApplicationForm {
  investorTypes?: { id: string; name: string }[];
  fundId?: string;
  fundType?: number;
  currencyName?: string;
  isExistingInvestor?: boolean;
  offerType?: number;
  investorUnitRounding?: number;
  unitPriceRounding?: number;
  isSuperAdmin?: boolean;
  allowCapitalCall?: boolean;
  allowPartiallyPaidUnits?: boolean;
}

interface IDataSelectOption {
  id: string;
  name: string;
}

const CustomMenuTitle = ({ children }: { children: ReactNode }) => (
  <Typography variant='body2' component='span'>
    {children}
  </Typography>
);

const InvestorDetails: React.FC<InvestorDetailsProps> = (props) => {
  const {
    fundId = '',
    currencyName,
    fundType,
    isViewMode,
    investorTypes = [],
    offerType,
    isExistingInvestor,
    investorUnitRounding = 0,
    unitPriceRounding = 0,
    id = '',
    isCreateMode,
    isDisabledEdit,
    isDraft,
    isSuperAdmin,
    isEditMode,
    allowCapitalCall,
    allowPartiallyPaidUnits,
  } = props;
  const { permissions } = useRole(ROUTES_PATH.ADMINISTRATION_INVESTORS);
  const [actionName, setActionName] = useState<ADMINISTRATION_INVESTOR_ACTION_NAME>();
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: 'You did it !',
    description: '',
  });
  const [isPreventClickOutside, setIsPreventClickOutside] = useState(false);
  const actionModalRef = useRef<IBasicModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const form = useFormContext<IInvestorDetailsForm>();
  const { data: entities = [] } = useGetInvestorEntities();
  const { data: funds = [] } = useGetInvestorFunds();
  const { data: unitClasses = [] } = useGetInvestorUnitClassesByFund(fundId);
  const { data: investorDetail } = useGetInvestorById(id, INVESTMENT_ENTITY_STEP.INVESTOR_DETAILS);
  const isPartnership = fundType === FundType.Partnership;
  const isTrust = fundType === FundType.Trust;
  const isWholesaleOffer = offerType === UnitClassOfferType.Wholesale;
  const watchInvestmentAmount = form.watch('investmentAmount') || 0;
  const watchPaidAmount = form.watch('paidAmount') || 0;
  const watchIssuedUnit = form.watch('issuedUnit') || 0;

  useEffect(() => {
    handleSetRelatedFields();
  }, [isTrust, watchPaidAmount, watchInvestmentAmount, watchIssuedUnit, isPartnership]);

  useEffect(() => {
    if (isEmpty(investorDetail?.detail) || isCreateMode) return;
    form.reset({
      ...investorDetail?.detail,
      mannualInvestorNumber: investorDetail?.detail?.investorNumber,
    });
  }, [investorDetail?.detail]);

  useEffect(() => {
    form.setValue('isOptionalInvestorType', !isWholesaleOffer);
  }, [isWholesaleOffer]);

  const handleSetRelatedFields = () => {
    const unpaidAmount = watchInvestmentAmount - watchPaidAmount;
    let currentInvestmentAmount = 0;
    let netValue = 0;

    // GIV/CIV calculation
    if (isTrust) {
      currentInvestmentAmount = watchIssuedUnit * (form.watch('currentUnitPrice') || 0);
    } else if (isPartnership) {
      currentInvestmentAmount = watchInvestmentAmount - unpaidAmount;
    } else {
      currentInvestmentAmount = watchInvestmentAmount;
    }

    // NIV calculation
    if (isTrust) {
      if (allowPartiallyPaidUnits && allowCapitalCall) {
        netValue = currentInvestmentAmount - unpaidAmount;
      } else if (!allowPartiallyPaidUnits && allowCapitalCall) {
        netValue = currentInvestmentAmount;
      } else if (!allowPartiallyPaidUnits && !allowCapitalCall) {
        netValue = currentInvestmentAmount - unpaidAmount;
      }
    } else if (isPartnership) {
      netValue = currentInvestmentAmount;
    } else {
      netValue = currentInvestmentAmount;
    }
    netValue = netValue > 0 ? netValue : 0;

    form.setValue('unpaidAmount', unpaidAmount);
    form.setValue('currentInvestmentAmount', currentInvestmentAmount);
    form.setValue('netValue', netValue);
  };

  const getSelectOptions = (data: IDataSelectOption[]) => {
    const results = useMemo(() => {
      return (data || []).map((item: IDataSelectOption) => ({
        label: item.name,
        value: item.id,
      }));
    }, [data]);

    return results;
  };

  const handleInvestmentEntityChange = (value: string | number) => {
    const investmentEntity = (entities || []).find((it) => it.id === value);

    form.setValue('investorName', investmentEntity?.name || '');
  };

  const investorTypesOptions = getSelectOptions(
    investorTypes?.filter((it) => (it.id as any) !== UnitClassInvestorType.Retail),
  );
  const fundsOptions = getSelectOptions(funds);
  const entitiesOptions = getSelectOptions(entities);
  const unitClassesOptions = getSelectOptions(unitClasses);

  const formCurrencyProps = {
    hideCurrency: true,
    endAdornment: <CurrencyName currencyName={currencyName} />,
    disabled: isViewMode,
    decimalScale: 2,
    fixedDecimalScale: true,
  };

  const handleOpenActionDialog = (actionName: ADMINISTRATION_INVESTOR_ACTION_NAME) => {
    setActionName(actionName);
    actionModalRef.current?.open();
  };

  const ACTION_MENU = [
    {
      title: <CustomMenuTitle>Issue</CustomMenuTitle>,
      onClick: () => handleOpenActionDialog(ADMINISTRATION_INVESTOR_ACTION_NAME.ISSUE),
    },
    {
      title: <CustomMenuTitle>Redemption</CustomMenuTitle>,
      onClick: () => handleOpenActionDialog(ADMINISTRATION_INVESTOR_ACTION_NAME.REDEMPTION),
      hidden: !isTrust,
    },
    {
      title: <CustomMenuTitle>Off Market Transfer</CustomMenuTitle>,
      onClick: () =>
        handleOpenActionDialog(ADMINISTRATION_INVESTOR_ACTION_NAME.OFF_MARKET_TRANSFER),
      hidden: !isTrust,
    },
    {
      title: <CustomMenuTitle>Record Payment</CustomMenuTitle>,
      onClick: () => handleOpenActionDialog(ADMINISTRATION_INVESTOR_ACTION_NAME.RECORD_PAYMENT),
    },
    {
      title: <CustomMenuTitle>Generate Statement</CustomMenuTitle>,
      onClick: () => handleOpenActionDialog(ADMINISTRATION_INVESTOR_ACTION_NAME.GENERATE_STATEMENT),
    },
  ];
  const isShowActionButton = useMemo(() => {
    return (permissions?.isFullAdmin || permissions?.isCreate) && isEditMode && !isDraft;
  }, [permissions]);

  return (
    <Box component='form' className='pt-4'>
      {isShowActionButton && (
        <Box className='flex justify-end items-center mb-6'>
          <SplitButton
            menus={ACTION_MENU}
            containerSx={{
              width: 195,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Action
          </SplitButton>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <FormInput
            name='mannualInvestorNumber'
            label='Investor Number'
            placeholder='Enter investor number'
            disabled={isViewMode || isDisabledEdit || isExistingInvestor}
            maxLength={MAX_INVESTOR_NUMBER}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isCreateMode || isDraft ? (
            <FormSelect
              name='investmentEntityId'
              label='Investor'
              placeholder='Select investment entity'
              disabled={isViewMode || isDisabledEdit}
              options={entitiesOptions}
              onSelectValue={handleInvestmentEntityChange}
            />
          ) : (
            <FormInput
              name='investorName'
              label='Investor'
              disabled={isViewMode || isDisabledEdit}
            />
          )}
        </Grid>
        {(isWholesaleOffer || !Number.isInteger(offerType)) && (
          <Grid item xs={12} md={6}>
            <FormSelect
              name='investorType'
              label='Investor Type'
              placeholder='Select investor type'
              disabled={isViewMode || (isDisabledEdit && !isSuperAdmin)}
              options={investorTypesOptions}
              menuItemSx={{
                textTransform: 'capitalize',
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormSelect
            name='fundId'
            label='Fund'
            placeholder='Select fund'
            disabled={isViewMode || isDisabledEdit}
            options={fundsOptions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelect
            name='unitClassId'
            label={isPartnership ? 'Financial Rule' : 'Unit Class'}
            placeholder={isPartnership ? 'Select financial rule' : 'Select unit class'}
            disabled={isViewMode || isDisabledEdit}
            options={unitClassesOptions}
          />
        </Grid>

        {(isTrust || !Number.isInteger(fundType)) && (
          <>
            <Grid item xs={12} md={6}>
              <FormCurrencyInput
                name='issuedUnit'
                label='Issued Unit'
                placeholder='Enter issued unit'
                disabled={isViewMode || (isDisabledEdit && !isSuperAdmin)}
                hideCurrency
                decimalScale={investorUnitRounding}
                allowedNull
                fixedDecimalScale
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormCurrencyInput
                {...formCurrencyProps}
                name='currentUnitPrice'
                label='Current Unit Price'
                placeholder='Enter current unit price'
                decimalScale={unitPriceRounding}
                disabled
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='investmentAmount'
            label='Investment Amount'
            placeholder='Enter investment amount'
            {...formCurrencyProps}
            disabled={isViewMode || (isDisabledEdit && !isSuperAdmin)}
            allowedNull
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='paidAmount'
            label='Paid Amount'
            placeholder='Enter paid amount'
            {...formCurrencyProps}
            disabled={isViewMode || (isDisabledEdit && !isSuperAdmin)}
            allowedNull
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='unpaidAmount'
            label='Unpaid Amount'
            placeholder='Enter unpaid amount'
            disabled
            allowNegative
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='currentInvestmentAmount'
            label={isTrust ? 'Gross Investment Value' : 'Current Investment Value'}
            placeholder='Enter investment value'
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            {...formCurrencyProps}
            name='netValue'
            label='Net Investment Value'
            placeholder='Enter net investment value'
            allowNegative
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormCurrencyInput
            name='specialPurposeCallAmount'
            label='Special Purpose Call Amount'
            placeholder='Enter special purpose call amount'
            {...formCurrencyProps}
            disabled={isViewMode || (isDisabledEdit && !isSuperAdmin)}
            allowedNull
          />
        </Grid>
      </Grid>
      <BasicModal
        ref={actionModalRef}
        maxWidth='lg'
        PaperProps={{
          sx: {
            top: '5%',
          },
        }}
        isPreventClickOutside={isPreventClickOutside}
      >
        <InvestorActionModal
          {...props}
          selectedAction={actionName}
          onClose={() => actionModalRef.current?.close()}
          detailInvestmentAmount={watchInvestmentAmount}
          detailIssuedUnits={form.watch('issuedUnit')}
          detailUnpaidAmount={form.watch('unpaidAmount')}
          setAlertInfo={setAlertInfo}
          onOpenAlert={() => modalAlertRef.current?.open()}
          setIsPreventClickOutside={setIsPreventClickOutside}
          investorUnitRounding={investorUnitRounding}
          unitClassId={investorDetail?.detail?.unitClassId}
        />
      </BasicModal>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={alertInfo?.title}
          isError={alertInfo?.isError}
          description={alertInfo?.description || ''}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default InvestorDetails;
