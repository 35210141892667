import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { NewHoldingIcon } from 'src/assets/icons/NewHoldingIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { IUnallocatedHolding } from 'src/modules/auth/type';

interface INewHoldingPopupProps {
  onCancel: () => void;
  onLinkNow: () => void;
  data?: IUnallocatedHolding;
}

const NewHoldingPopup: FC<INewHoldingPopupProps> = ({ onCancel, onLinkNow, data = {} }) => {
  const { entityName, investorNumber, fund } = data;

  return (
    <Box padding='40px'>
      <Box
        height='60px'
        width='60px'
        bgcolor='secondary.se100'
        className='flex items-center justify-center rounded-[30px]'
      >
        <NewHoldingIcon />
      </Box>
      <Typography variant='h5' className='mt-[24px]'>
        New Holding
      </Typography>
      <Typography variant='body2' className='mt-[24px]' color='neutral.ne500' fontWeight={500}>
        {`Exciting news! You've been invited to view new holding for ${entityName}:`}
      </Typography>
      <Box my='16px' bgcolor='secondary.se100' padding='16px' className='rounded-[8px]'>
        <Typography component='div' variant='body3' color='secondary.se500' fontWeight={500}>
          {`Investor Number: ${investorNumber}`}
        </Typography>
        <Typography variant='body3' color='secondary.se500' fontWeight={500}>
          {`Fund Name: ${fund}`}
        </Typography>
      </Box>
      <Typography variant='body2' className='mb-[24px]' color='neutral.ne500' fontWeight={500}>
        Would you like to link your holding to Investment Entity?
      </Typography>
      <Box textAlign='right'>
        <CustomButton sx={{ color: 'neutral.ne800', mr: '10px' }} variant='text' onClick={onCancel}>
          Cancel
        </CustomButton>
        <CustomButton onClick={onLinkNow}>Link Now</CustomButton>
      </Box>
    </Box>
  );
};

export default NewHoldingPopup;
