import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormSelect from 'src/components/atoms/FormSelect';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { detectPortalType } from 'src/helpers/common';
import {
  useGetApplicationById,
  useGetApplicationOfferUnitClassDetail,
} from 'src/modules/applications/hooks';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useGetApplicationFilterCreation } from 'src/modules/fundraising-application/hooks';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { IDetailsProps } from '.';
import InvestorApplicationDetails from './InvestorApplicationDetails';

interface ICreateFundApplicationDetailsAppProps extends IDetailsProps {}

const CreateFundApplicationDetails: FC<ICreateFundApplicationDetailsAppProps> = (props) => {
  const { isViewMode, isDisabledEdit, id = '', isCreateMode, investmentEntityForm } = props;
  const form = useFormContext();
  const watchUnitClassId = form.watch('unitClassId');
  const watchFundId = form.watch('fundId');
  const watchClientId = form.watch('clientId');
  const watchOfferId = form.watch('offerId');
  const { isFunds } = detectPortalType();
  const { selectedClients, isFmPortal } = useFundPortalContext();

  const { data: currentUser } = useGetUserInfo();
  const { data: offerUnitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    watchOfferId,
    watchUnitClassId,
  );
  const { data: filterCreationData } = useGetApplicationFilterCreation(
    {
      clientId: watchClientId,
      fundId: watchFundId,
      offerId: watchOfferId,
      unitClassId: watchUnitClassId,
    },
    isFunds,
  );
  const {
    clients = [],
    funds = [],
    offers = [],
    unitClasses = [],
    investors = [],
  } = filterCreationData || {};
  const { allowForeignInvestors, offerType } = offerUnitClassDetailData || {};
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });

  useEffect(() => {
    form.setValue('isFundApplication', true);
  }, []);

  useEffect(() => {
    handleFillData();
  }, [applicationDetail]);

  useEffect(() => {
    if (isFmPortal) {
      form.setValue(
        'clientId',
        Array.isArray(selectedClients) ? selectedClients[0] : selectedClients,
      );
    }
  }, [isFmPortal]);

  const handleFillData = () => {
    if (isEmpty(applicationDetail?.detail) || isCreateMode) return;
    const { fundId, offerId, unitClassId } = applicationDetail || {};
    form.reset({
      ...applicationDetail?.detail,
      ...{ fundId, offerId, unitClassId },
    });
  };

  const handleResetFields = (fields: string[]) => {
    fields.forEach((it) => {
      form.setValue(it, '', { shouldDirty: true });
    });
  };

  const handleSelectInvestorNumber = (value: string | number) => {
    const investor = (filterCreationData?.investors || []).find(
      (it) => it.investorNumber === value,
    );

    if (Number.isInteger(investor?.investorType)) {
      form.setValue('investorType', investor?.investorType);
      form.setValue('investmentEntityId', investor?.investmentEntityId);
      form.setValue('investorId', investor?.investorId);
      if (investmentEntityForm) {
        investmentEntityForm.setValue('investmentEntityId', investor?.investmentEntityId);
        investmentEntityForm.setValue('isAddNewEntityDetail', true);
      }
    }
  };

  const getCommonOptions = (array: { name: string; id: string }[]) => {
    return (array || []).map((it) => ({
      id: it.id,
      label: it.name,
      value: it.id,
    }));
  };

  const investorNumberOptions = (investors || []).map((it) => ({
    value: it.investorNumber,
    label: `${it.investorNumber} - ${it.investmentEntityName}`,
  }));

  return (
    <>
      <Grid container rowSpacing='18px' columnSpacing={3} marginBottom={3} pt={1}>
        {!isFmPortal && (
          <Grid item xs={6}>
            <FormSelect
              name='clientId'
              label='Client'
              placeholder='Select client'
              options={getCommonOptions(clients)}
              onSelectValue={() => handleResetFields(['fundId', 'offerId', 'unitClassId'])}
              disabled={isViewMode || isDisabledEdit}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormSelect
            name='fundId'
            label='Fund'
            placeholder='Select fund'
            options={getCommonOptions(funds)}
            onSelectValue={() => handleResetFields(['offerId', 'unitClassId'])}
            disabled={isViewMode || isDisabledEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='offerId'
            label='Offer'
            placeholder='Select offer'
            options={getCommonOptions(offers)}
            onSelectValue={() => handleResetFields(['unitClassId'])}
            disabled={isViewMode || isDisabledEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name='unitClassId'
            label='Unit Class'
            placeholder='Select unit class'
            options={getCommonOptions(unitClasses)}
            disabled={isViewMode || isDisabledEdit}
          />
        </Grid>
      </Grid>
      <InvestorApplicationDetails
        {...props}
        unitClassId={watchUnitClassId}
        allowForeignInvestors={allowForeignInvestors}
        offerType={offerType}
        customInvestorNumberComponent={
          <Box className='p-6 rounded-xl' bgcolor='neutral.ne100'>
            <FormSelect
              name='investorNumber'
              options={investorNumberOptions}
              label='Investor Number'
              placeholder='Select investor number'
              onSelectValue={handleSelectInvestorNumber}
              disabled={isViewMode || isDisabledEdit}
            />
          </Box>
        }
      />
    </>
  );
};

export default CreateFundApplicationDetails;
