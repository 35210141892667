import { REQUIRE_MESSAGE } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import { removeSpacesInString } from 'src/utils/common';
import * as Yup from 'yup';
import { YupBase } from './common';

const TENANT_REGEX = /^[a-z0-9]+$/i;
const TENANT_VALIDATION_MESSAGE =
  'Invalid subdomain name. Subdomain name must have maximum 63 characters, no blank space and no special character(s)';

const { isFunds } = detectPortalType();

export const administratorsValidationSchema = Yup.object().shape({
  clientAdministrators: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            position: Yup.string(),
            email: Yup.string().email('Invalid Email format'),
            phoneNumber: Yup.string(),
            isSendEmail: Yup.boolean(),
            countryFlagCode: Yup.string(),
          }),
        )
      : Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required(REQUIRE_MESSAGE),
            lastName: Yup.string().required(REQUIRE_MESSAGE),
            position: Yup.string().required(REQUIRE_MESSAGE),
            email: YupBase.email,
            phoneNumber: Yup.string().required(REQUIRE_MESSAGE),
            isSendEmail: Yup.boolean(),
            countryFlagCode: Yup.string(),
          }),
        );
  }),
  isSaveDraft: Yup.boolean(),
});

export const organisationDetailsValidationSchema = Yup.object().shape({
  legalName: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  abn: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.string()
      : Yup.string()
          .required(REQUIRE_MESSAGE)
          .test('invalidAbn', 'ACN/ABN must have 9 or 11 digits.', (abn) => {
            const rawAbn = removeSpacesInString(abn);
            if (rawAbn?.length === 9 || rawAbn?.length === 11) return true;
            return false;
          });
  }),
  physicalAddress: Yup.object().when(['isSaveDraft'], (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.object().shape({
          country: Yup.object(),
          state: Yup.object(),
          manualEntry: Yup.boolean(),
          city: Yup.object().when('manualEntry', ([manualEntry]) => {
            if (manualEntry) return Yup.string();
            return Yup.object();
          }),
          postcode: Yup.string(),
          streetAddress: Yup.string(),
        })
      : Yup.object().shape({
          country: Yup.object()
            .shape({
              id: Yup.string().required(REQUIRE_MESSAGE),
              value: Yup.string().required(REQUIRE_MESSAGE),
              label: Yup.string().required(REQUIRE_MESSAGE),
            })
            .required(REQUIRE_MESSAGE),
          state: Yup.object()
            .shape({
              id: Yup.string().required(REQUIRE_MESSAGE),
              value: Yup.string().required(REQUIRE_MESSAGE),
              label: Yup.string().required(REQUIRE_MESSAGE),
            })
            .required(REQUIRE_MESSAGE),
          manualEntry: Yup.boolean(),
          city: Yup.object().when('manualEntry', ([manualEntry]) => {
            if (manualEntry) return Yup.string().required(REQUIRE_MESSAGE);

            return Yup.object()
              .shape({
                id: Yup.string().required(REQUIRE_MESSAGE),
                value: Yup.string().required(REQUIRE_MESSAGE),
                label: Yup.string().required(REQUIRE_MESSAGE),
              })
              .required(REQUIRE_MESSAGE);
          }),
          postcode: Yup.string().required(REQUIRE_MESSAGE),
          streetAddress: Yup.string().required(REQUIRE_MESSAGE),
        });
  }),
  postalAddress: Yup.object().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.object().shape({
          country: Yup.object(),
          state: Yup.object(),
          manualEntry: Yup.boolean(),
          city: Yup.object().when('manualEntry', ([manualEntry]) => {
            if (manualEntry) return Yup.string();

            return Yup.object();
          }),
          postcode: Yup.string(),
          streetAddress: Yup.string(),
        })
      : Yup.object().shape({
          country: Yup.object()
            .shape({
              id: Yup.string().required(REQUIRE_MESSAGE),
              value: Yup.string().required(REQUIRE_MESSAGE),
              label: Yup.string().required(REQUIRE_MESSAGE),
            })
            .required(REQUIRE_MESSAGE),
          state: Yup.object()
            .shape({
              id: Yup.string().required(REQUIRE_MESSAGE),
              value: Yup.string().required(REQUIRE_MESSAGE),
              label: Yup.string().required(REQUIRE_MESSAGE),
            })
            .required(REQUIRE_MESSAGE),
          manualEntry: Yup.boolean(),
          city: Yup.object().when('manualEntry', ([manualEntry]) => {
            if (manualEntry) return Yup.string().required(REQUIRE_MESSAGE);

            return Yup.object()
              .shape({
                id: Yup.string().required(REQUIRE_MESSAGE),
                value: Yup.string().required(REQUIRE_MESSAGE),
                label: Yup.string().required(REQUIRE_MESSAGE),
              })
              .required(REQUIRE_MESSAGE);
          }),
          postcode: Yup.string().required(REQUIRE_MESSAGE),
          streetAddress: Yup.string().required(REQUIRE_MESSAGE),
        });
  }),
  capacityIds: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.array() : Yup.array().min(1, REQUIRE_MESSAGE);
  }),
  hasOtherCapacity: Yup.boolean(),
  otherCapacity: Yup.string().when(
    ['hasOtherCapacity', 'isSaveDraft'],
    ([hasOtherCapacity, isSaveDraft]) => {
      if (hasOtherCapacity && !isSaveDraft) return Yup.string().required(REQUIRE_MESSAGE);
      return Yup.string().nullable();
    },
  ),
  clientName: Yup.string().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0] ? Yup.string() : Yup.string().required(REQUIRE_MESSAGE);
  }),
  tenantId: isFunds
    ? Yup.string().nullable()
    : Yup.string().when('isSaveDraft', (isSaveDraft) => {
        return isSaveDraft[0]
          ? Yup.string()
          : Yup.string()
              .required(REQUIRE_MESSAGE)
              .max(63, TENANT_VALIDATION_MESSAGE)
              .matches(TENANT_REGEX, TENANT_VALIDATION_MESSAGE);
      }),
  isUseSamePhysicalAddress: Yup.boolean(),
  isSaveDraft: Yup.boolean(),
  sectionId: Yup.string().nullable(),
});

export const onboardingContactValidationSchema = Yup.object().shape({
  assignees: Yup.array(),
  isSaveDraft: Yup.boolean(),
  isSendEmailForAssignee: Yup.boolean(),
});

export const ourTeamValidationSchema = Yup.object().shape({
  assignees: Yup.array().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.array() : Yup.array().min(1, REQUIRE_MESSAGE);
  }),
  isSaveDraft: Yup.boolean(),
  isSendEmailForAssignee: Yup.boolean(),
});

export const createClientTeamUserValidationSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  email: YupBase.email,
  contactNumber: YupBase.contactNumber,
  roleId: Yup.string().required('Role is required'),
  position: Yup.string().nullable(),
  isSendMail: Yup.boolean(),
  fundIds: Yup.array(),
  isAllFund: Yup.boolean(),
});

export const clientTeamValidationSchema = Yup.object().shape({
  users: Yup.array().when('isSaveDraft', (isSaveDraft) => {
    return isSaveDraft[0]
      ? Yup.array()
      : Yup.array().min(1, 'At least 1 user is required. Please create a new user.');
  }),
  isSaveDraft: Yup.boolean(),
  isDirty: Yup.boolean(),
});
