import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FUND_REGISTRY_API } from 'src/constants/apiEndpoints';
import { DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  calTotalUnitIssued,
  deleteUnitPrice,
  editUnitPrice,
  exportUnitPriceHistoryCsv,
  fundRegistryBulkUpload,
  getFundRegistryFilter,
  getFundRegistryUnitPriceHistory,
  getUnitByDate,
  getUnitPriceById,
  strikeUnitPrice,
} from './services';
import {
  IFundRegistryBulkUploadBody,
  IFundRegistryFilterList,
  IFundRegistryFilterQueryBody,
  IStrikeUnitPriceBody,
  IUnitPriceById,
  IUnitPriceHistoryItem,
  IUnitPriceHistoryQueryBody,
} from './type';

export const useGetUnitPriceHistory = () => {
  const [queryBody, setQueryBody] = useState<IUnitPriceHistoryQueryBody>(() => ({
    ...DEFAULT_QUERIES,
    pageSize: 100,
    clientId: '',
    fundId: '',
    unitClassId: '',
  }));
  const enabled = !!queryBody.clientId && !!queryBody.fundId && !!queryBody.unitClassId;

  const { data, ...rest } = useQuery<{
    items: Paginated<IUnitPriceHistoryItem>;
    currency: string;
    unitPrice: number;
    investorUnit: number;
  }>(
    [FUND_REGISTRY_API.getFundRegistryUnitPriceHistory.api, queryBody],
    () => getFundRegistryUnitPriceHistory(queryBody),
    {
      enabled,
    },
  );

  const setParams = (newParams: IUnitPriceHistoryQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetFundRegistryFilter = () => {
  const [queryBody, setQueryBody] = useState<IFundRegistryFilterQueryBody>({});

  const { data } = useQuery<IFundRegistryFilterList>(
    [FUND_REGISTRY_API.getFundRegistryFilter.api, queryBody],
    () => getFundRegistryFilter(queryBody),
    {
      keepPreviousData: true,
    },
  );

  const setParams = (newParams: IUnitPriceHistoryQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
  };
};

export const useFundRegistryBulkUpload = () => {
  const resp = async (params: IFundRegistryBulkUploadBody) => {
    const data = await fundRegistryBulkUpload(params);
    return data;
  };
  return useMutation(resp);
};

export const useExportUnitPriceHistoryCsv = () => {
  const resp = async ({
    body,
    fileName,
  }: {
    body: IUnitPriceHistoryQueryBody;
    fileName: string;
  }) => {
    const data = await exportUnitPriceHistoryCsv(body);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};

export const useCalTotalUnitIssued = () => {
  return useMutation(calTotalUnitIssued);
};

export const useStrikeUnitPrice = () => {
  const queryClient = useQueryClient();
  const fn = async (data: IStrikeUnitPriceBody) => {
    await strikeUnitPrice(data);
    await queryClient.invalidateQueries([FUND_REGISTRY_API.getFundRegistryUnitPriceHistory.api]);
  };
  return useMutation(fn);
};

export const useEditUnitPrice = () => {
  const queryClient = useQueryClient();
  const fn = async ({ id, data }: { id: string; data: IStrikeUnitPriceBody }) => {
    await editUnitPrice({ id, data });
    await queryClient.invalidateQueries([FUND_REGISTRY_API.getFundRegistryUnitPriceHistory.api]);
  };
  return useMutation(fn);
};

export const useGetUnitPriceById = (id: string) =>
  useQuery<IUnitPriceById>(
    [FUND_REGISTRY_API.getUnitPriceById.api(id), id],
    () => getUnitPriceById(id),
    {
      enabled: !!id,
    },
  );

export const useDeleteUnitPrice = () => {
  const queryClient = useQueryClient();
  const mutationFn = async (id: string) => {
    await deleteUnitPrice(id);
    await queryClient.invalidateQueries([FUND_REGISTRY_API.getFundRegistryUnitPriceHistory.api]);
  };

  return useMutation(mutationFn);
};

export const useGetUnitByDate = () => useMutation(getUnitByDate);
