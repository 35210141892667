import { Box, IconButton, SxProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, FC, ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import UploadFilesIcon from 'src/assets/icons/UploadFilesIcon';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import { CustomTextField } from 'src/components/atoms/FormInput';
import UploadButton from 'src/components/atoms/UploadButton';
import EmptyData from 'src/components/molecules/EmptyData';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import { getFileTypeIcon } from 'src/components/pages/applications/components/Addtional/DocumentUpload';
import {
  ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
  MAX_ADDITIONAL_DOCUMENT_UPLOAD,
} from 'src/constants/applications';
import { DATE_PICKER_FORMAT_SERVER } from 'src/constants/date';
import { ICertifiedFile, IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import { useUploadDocument } from 'src/modules/common/hooks';
import { deepCheckEmptyObject, handleErrorFromServer } from 'src/utils/common';
import { utcToLocalTimezone } from 'src/utils/time';
import { IInvestmentEntityProps } from '..';
interface IUploadCertifiedUploadCertifiedProps extends IInvestmentEntityProps {
  title?: ReactNode;
  keyName:
    | 'investmentEntityDetails.australianCompany'
    | 'investmentEntityDetails.regulatedTrust.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.unregulatedTrust'
    | 'investmentEntityDetails.unregulatedTrust.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.association'
    | 'investmentEntityDetails.smsf.trusteeAustralianCompanyDetails'
    | 'investmentEntityDetails.smsf'
    | 'investmentEntityDetails.unregulatedTrust.appointerAustralianCompanyDetails'
    | 'investmentEntityDetails.partnership';
  containerSx?: SxProps;
  fileTableTitle?: ReactNode;
  emptyDataDescription?: string;
}

interface ITableData extends ICertifiedFile {}

const UploadCertified: FC<IUploadCertifiedUploadCertifiedProps> = ({
  isViewMode,
  isDisabledEdit,
  keyName,
  title = '',
  containerSx = {},
  fileTableTitle = '',
  emptyDataDescription = 'No document.',
  isExistingInvestmentEntity,
  hideCertifiedDescription,
}) => {
  const { mutate: uploadDocument, isLoading: isUploadingDocument } = useUploadDocument();
  const { data: currentUser } = useGetUserInfo();

  const { watch, setValue, getFieldState } = useFormContext<IInvestmentEntityForm>();

  const certifiedKey =
    keyName === 'investmentEntityDetails.unregulatedTrust'
      ? (`${keyName}.certifiedTrustDeed` as const)
      : (`${keyName}.certifiedAgreementFile` as const);
  const certifiedIdKey =
    keyName === 'investmentEntityDetails.unregulatedTrust'
      ? (`${keyName}.certifiedTrustDeedId` as const)
      : (`${keyName}.certifiedAgreementFileId` as const);
  const certifiedDescriptionKey =
    keyName === 'investmentEntityDetails.unregulatedTrust'
      ? (`${keyName}.certifiedTrustDeedDescription` as const)
      : (`${keyName}.certifiedAgreementFileDescription` as const);

  const document = watch(certifiedKey);
  const watchDescription = watch(certifiedDescriptionKey);
  const certifiedFileState = getFieldState(`${certifiedKey}.fileName`);

  const [description, setDescription] = useState(document?.description || '');

  const handleAddDocument = (files: FileList) => {
    const file = files[0];
    if (!file) return;

    uploadDocument(file, {
      onSuccess: (data: ITableData) => {
        setValue(certifiedIdKey, undefined);
        setValue(
          certifiedKey,
          {
            ...data,
            uploadedBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
            uploadedDate: dayjs().format(DATE_PICKER_FORMAT_SERVER),
            description,
          },
          {
            shouldDirty: true,
          },
        );
        setValue(certifiedDescriptionKey, description, {
          shouldDirty: true,
        });
      },
      onError: handleErrorFromServer,
    });
  };

  const handleDeleteDocument = () => {
    setValue(certifiedIdKey, undefined, {
      shouldDirty: true,
    });
    setValue(certifiedDescriptionKey, undefined, {
      shouldDirty: true,
    });
    setValue(certifiedKey, undefined, {
      shouldDirty: true,
    });
  };

  const disabled =
    isViewMode || isDisabledEdit || isUploadingDocument || isExistingInvestmentEntity;

  const fileColumns = [
    {
      title: 'Description',
      key: 'description',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable
          className='line-clamp-1'
          value={row.description || watchDescription || ''}
        />
      ),
    },
    {
      title: 'File Name',
      key: 'fileName',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => {
        const icon = getFileTypeIcon(Number(row.fileType));
        return (
          <Box className='flex items-center'>
            <Box>{icon}</Box>
            <StringNodeTable className='line-clamp-1 pl-2' value={row.fileName} />
          </Box>
        );
      },
    },
    {
      title: 'File Size',
      key: 'fileSize',

      sx: { width: '20%' },
      renderNode: (row: ITableData) => {
        const fileSizeMB = row.fileSize / 1024 ** 2;
        const value = fileSizeMB ? `${fileSizeMB.toFixed(1)}MB` : '';
        return <StringNodeTable value={value} />;
      },
    },
    {
      title: 'Date Uploaded',
      key: 'dateUploaded',

      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <StringNodeTable value={String(utcToLocalTimezone(row.uploadedDate))} />
      ),
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      sx: { width: '20%' },
      renderNode: (row: ITableData) => (
        <Box className='flex items-center justify-between'>
          <StringNodeTable className='line-clamp-1' value={String(row.uploadedBy)} />
          {!disabled && (
            <IconButton
              onClick={handleDeleteDocument}
              sx={(theme) => ({
                padding: '4px',
                borderRadius: '50%',
                bgcolor: theme.palette.neutral.ne200,
                '&.Mui-disabled': {
                  bgcolor: theme.palette.neutral.ne200,
                },
              })}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        pt: 3,
        ...containerSx,
      }}
    >
      {!!title && (
        <Typography variant='body3' fontWeight='medium'>
          {title}
        </Typography>
      )}
      {!hideCertifiedDescription && (
        <Box>
          <Box className='flex items-end justify-between pt-3'>
            <Box className='flex flex-col' width='76%'>
              <Typography variant='body3' pb='6px'>
                Description
              </Typography>
              <CustomTextField
                fullWidth
                placeholder='Enter description'
                value={description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                disabled={disabled}
              />
            </Box>
            <Box width='22%'>
              <UploadButton
                startIcon={<UploadFilesIcon />}
                label={'Upload Files'}
                inputProps={{
                  accept: ADDITIONAL_DOCUMENT_ACCEPT_TYPE,
                }}
                disabled={!description || disabled}
                handleFileChange={handleAddDocument}
                validate={{
                  fileSize: MAX_ADDITIONAL_DOCUMENT_UPLOAD,
                }}
                sx={{
                  px: 2,
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Typography variant='body3' color='neutral.ne500' pt={1}>
            Supported format: .doc, .png, jpeg, .pdf - 20 MB max file size.
          </Typography>
        </Box>
      )}
      <Box className='pt-6'>
        <Typography variant='body2' fontWeight='medium'>
          {fileTableTitle || 'Uploaded Files'}
        </Typography>
        <Typography className='inline-block' variant='body3' color='neutral.ne500' pt={0.5}>
          Supported format: .doc, .png, jpeg, .pdf - 20 MB max file size.
        </Typography>
        <Typography className='inline-block' variant='body3' color='neutral.ne500' pt={0.5} pb={1}>
          Additional documents can be uploaded in the Additional - Document Upload section.
        </Typography>
        <CustomTableContainer
          sx={(theme) => ({
            '.MuiTableCell-head': {
              borderBottomWidth: `1px !important`,
              borderColor: `${theme.palette.neutral.ne200} !important`,
              background: `${theme.palette.base.white} !important`,
            },
            '.MuiTableContainer-root': {
              borderColor: theme.palette.neutral.ne200,
              bgcolor: theme.palette.base.white,
            },
          })}
        >
          <CustomTable
            rows={!deepCheckEmptyObject(document) && document ? [document] : []}
            columns={fileColumns}
            hasPagination={false}
            stickyHeader
            displayEmpty
            hiddenHorizontalRule
            customEmpty={
              <EmptyData
                isTable
                description={emptyDataDescription}
                icon={<DocumentOnePageIcon width={22} height={27} />}
                marginY='34px'
              />
            }
            errorMessage={certifiedFileState?.error?.message || ''}
            selectedRows={[]}
          />
        </CustomTableContainer>
      </Box>
    </Box>
  );
};

export default UploadCertified;
