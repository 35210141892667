import { Box, Grid, Radio, Typography } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import InvestorExistingIcon from 'src/assets/icons/InvestorExistingIcon';
import InvestorNewIcon from 'src/assets/icons/InvestorNewIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import FormCurrencyInput from 'src/components/atoms/FormCurrencyInput';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup, {
  CustomFormControlLabel,
  RadioOption,
} from 'src/components/atoms/FormRadioGroup';
import LabelWithTooltip from 'src/components/atoms/LabelWithTooltip';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  FOREIGN_TAX_ERROR_MESSAGE,
  investorTypeLabel,
  investorTypeTooltips,
} from 'src/constants/applications';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { UnitClassInvestorType, UnitClassOfferType } from 'src/constants/unit-class';
import {
  useGetApplicationById,
  useGetApplicationInvestorTypes,
} from 'src/modules/applications/hooks';
import { IApplicationDetails } from 'src/modules/applications/type';
import { IAlertInfo } from 'src/modules/common/type';
import { IDetailsProps } from '.';

interface IInvestorApplicationDetailsProps extends IDetailsProps {
  customInvestorNumberComponent?: ReactNode;
}

const InvestorApplicationDetails: FC<IInvestorApplicationDetailsProps> = ({
  fundName,
  allowForeignInvestors,
  offerType,
  currency,
  id = '',
  unitClassId = '',
  isViewMode,
  isEditMode,
  isDraft,
  approveInvestorTypeComponent,
  bannerInvestorTypeComponent,
  approveInvestmentAmountComponent,
  bannerInvestorAmountComponent,
  isDisabledEdit,
  isReviewing,
  setIsAddNewEntityDetail,
  customInvestorNumberComponent,
  isCreateFundApplicationForm,
  isCreateMode,
  investmentEntityForm,
}) => {
  const alertRef = useRef<IBasicModalElement>(null);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const { watch, setValue, reset } = useFormContext<IApplicationDetails>();
  const isExistingInvestorInFund = watch('isExistingInvestorInFund');
  const investorType = watch('investorType');
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });
  const { data: unitClassInvestorTypes = [] } = useGetApplicationInvestorTypes(unitClassId);

  useEffect(() => {
    setValue('isOptionalInvestorType', offerType === UnitClassOfferType.Retail, {
      shouldValidate: isEditMode && !isDraft,
    });
  }, [offerType]);

  useEffect(() => {
    handleFillData();
    setIsAddNewEntityDetail && setIsAddNewEntityDetail(!!applicationDetail?.isAddNewEntityDetail);
  }, [applicationDetail]);

  const handleFillData = () => {
    if (isEmpty(applicationDetail?.detail) || isCreateMode || isCreateFundApplicationForm) return;
    reset({
      ...applicationDetail?.detail,
      isOptionalInvestorType: offerType === UnitClassOfferType.Retail,
    });
  };

  const handleForeignTaxResidentChange = (value: any) => {
    if (value && !allowForeignInvestors) {
      setAlertInfo({
        ...alertInfo,
        isError: true,
        description: FOREIGN_TAX_ERROR_MESSAGE,
      });
      alertRef.current?.open();
    }
  };

  const handleChangeInvestorStatus = (value: any) => {
    if (!isCreateFundApplicationForm) return;
    if (!value) {
      investmentEntityForm?.setValue('investmentEntityId', undefined);
      investmentEntityForm?.setValue('isAddNewEntityDetail', false);
      setValue('investmentEntityId', null);
      setValue('investorNumber', null);
    }
  };

  const renderInvestorTypeOptions = (options: RadioOption[], selectedValue?: string | number) => {
    return (
      <Grid container columnSpacing={3} rowSpacing={2.25}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6}>
            <Box
              className='border rounded-xl px-3 flex justify-between items-center'
              borderColor={option.value === selectedValue ? 'primary.main' : 'neutral.ne400'}
            >
              <CustomFormControlLabel
                key={option.value}
                defaultValue={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  width: '100%',
                }}
                disabled={isViewMode || isDisabledEdit}
              />
              {!!investorTypeTooltips[option.label] && (
                <CustomTooltip
                  title={investorTypeTooltips[option.label]}
                  placement='right-start'
                  sx={{ maxWidth: 240 }}
                >
                  <span className='cursor-pointer'>
                    <InfoIcon />
                  </span>
                </CustomTooltip>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const investorTypeOptions: RadioOption[] = unitClassInvestorTypes
    .filter(({ id }) => (isViewMode ? id === investorType : true))
    .map(({ id, name }) => ({
      value: id,
      label: id === UnitClassInvestorType.RetailExemption ? investorTypeLabel.Retail : name,
    }))
    .sort((a, b) => b.label?.localeCompare(a.label));

  const investorRadioOptions = [
    {
      label: 'New',
      value: false as any,
      icon: <InvestorNewIcon />,
    },
    {
      label: 'Existing',
      value: true as any,
      icon: <InvestorExistingIcon />,
    },
  ];

  return (
    <>
      <Box className='pt-2'>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <FormRadioGroup
              name='isExistingInvestorInFund'
              options={investorRadioOptions}
              label={
                fundName
                  ? `Are you a new or an existing investor in ${fundName} ?`
                  : isCreateFundApplicationForm
                  ? 'Investor Status'
                  : 'Investor Type'
              }
              isBooleanValue
              disabled={isViewMode || isDisabledEdit}
              showPickerOnly={isReviewing}
              onChangeValue={handleChangeInvestorStatus}
            />
          </Grid>
          {!!isExistingInvestorInFund && (
            <Grid item xs={12}>
              {customInvestorNumberComponent ? (
                customInvestorNumberComponent
              ) : (
                <FormInput
                  name='investorNumber'
                  label='Please provide your investor number'
                  placeholder='Enter investor number'
                  maxLength={75}
                  disabled={isViewMode || isDisabledEdit}
                />
              )}
            </Grid>
          )}
          {offerType === UnitClassOfferType.Wholesale && (
            <>
              <Grid item xs={12} position={'relative'}>
                <FormRadioGroup
                  name='investorType'
                  options={investorTypeOptions}
                  label='Investor type'
                  renderOptions={renderInvestorTypeOptions}
                  isEnumValue
                  disabled={isViewMode || isDisabledEdit}
                />
                {approveInvestorTypeComponent}
              </Grid>
              {bannerInvestorTypeComponent}
            </>
          )}
          <Grid item xs={12}>
            <FormRadioGroup
              name='isForeignTaxResident'
              options={TrueFalseRadioOptions}
              onChangeValue={handleForeignTaxResidentChange}
              isBooleanValue
              label={
                <LabelWithTooltip
                  label='Foreign Tax Resident'
                  tooltip={`Select Yes if entity or any of its controlling persons
                  are foreign tax residents. A controlling person is any individual who directly or indirectly exercises control over the entity.
                  For a Company, this includes any beneficial owners controlling more than 25% of the shares in the company.
                  For a Trust, this includes Trustees, Settlors or Beneficiaries.
                  For a Partnership this includes any partners.`}
                  placement='right-start'
                />
              }
              optionGap={10}
              disabled={isViewMode || isDisabledEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              className={clsx('flex justify-between gap-6', {
                'p-6 rounded-xl': !approveInvestmentAmountComponent,
              })}
              bgcolor={approveInvestmentAmountComponent ? '' : 'neutral.ne100'}
            >
              <FormCurrencyInput
                name='investmentAmount'
                label='Investment Amount'
                hideCurrency
                placeholder='Enter investment amount'
                endAdornment={
                  <Typography variant='body3' fontWeight='medium' sx={{ pr: 1.5 }}>
                    {currency || ''}
                  </Typography>
                }
                disabled={isViewMode || isDisabledEdit}
                containerSx={{
                  flex: 1,
                  maxWidth: approveInvestmentAmountComponent ? '50%' : 'unset',
                }}
                decimalScale={2}
              />
              {approveInvestmentAmountComponent}
            </Box>
          </Grid>
          {bannerInvestorAmountComponent}
        </Grid>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          isError={alertInfo?.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
            },
          }}
        />
      </BasicModal>
    </>
  );
};

export default InvestorApplicationDetails;
