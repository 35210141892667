import {
  ASSOCIATION_TYPE,
  FUND_KYC_SUBMIT_TYPE,
  GOVERNMENT_BODY_TYPE,
  INVESTMENT_ENTITY_APPOINTER_TYPE,
  INVESTMENT_ENTITY_DELEGATE_ROLE,
  INVESTMENT_ENTITY_OTHER,
  INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE,
  INVESTMENT_ENTITY_ROLE,
  INVESTMENT_ENTITY_SMSF_TRUSTTYPE,
  INVESTMENT_ENTITY_TRUST,
  INVESTMENT_ENTITY_TRUSTEE_TYPE,
  INVESTMENT_ENTITY_TYPE,
  INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE,
  InvestmentEntityCompanyRegionType,
  PARTNERSHIP_TYPE,
  PARTNER_TYPE,
  SignatoryMethodType,
  SpecialCompanyType,
  TAX_STATUS,
  TeamType,
} from 'src/constants/applications';
import { InvestorSourceOfFunds, REQUIRE_MESSAGE } from 'src/constants/common';
import { IPartner } from 'src/modules/applications/type';
import { removeSpacesInString } from 'src/utils/common';
import * as Yup from 'yup';
import { EMAIL_REGEX, YupBase } from './common';
import { financialDetailsSchema } from './offer';

export const applicationDetailsSchema = Yup.object().shape({
  investorNumber: Yup.string()
    .nullable()
    .when(
      ['isSaveDraft', 'isExistingInvestorInFund'],
      ([isSaveDraft, isExistingInvestorInFund], schema) => {
        return isSaveDraft || !isExistingInvestorInFund ? schema : schema.required(REQUIRE_MESSAGE);
      },
    ),
  investmentAmount: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft
      ? YupBase.transformNumberSchema.nullable()
      : YupBase.transformNumberSchema.typeError(REQUIRE_MESSAGE).required(REQUIRE_MESSAGE);
  }),
  fixedAmount: Yup.number().when(
    ['isSaveDraft', 'capitalCall', 'isRequiredCapitalCallFields'],
    ([isSaveDraft, capitalCall, isRequiredCapitalCallFields]) => {
      return isSaveDraft || !capitalCall || !isRequiredCapitalCallFields
        ? Yup.mixed().nullable()
        : YupBase.transformNumberSchema
            .nullable()
            .typeError(REQUIRE_MESSAGE)
            .required(REQUIRE_MESSAGE);
    },
  ),
  isExistingInvestorInFund: Yup.boolean()
    .nullable()
    .when('isSaveDraft', ([isSaveDraft], schema) => {
      return isSaveDraft ? schema : schema.required(REQUIRE_MESSAGE);
    }),
  isForeignTaxResident: Yup.boolean().when('isSaveDraft', ([isSaveDraft], schema) => {
    return isSaveDraft ? schema : schema.required(REQUIRE_MESSAGE);
  }),
  investorType: Yup.number().when(
    ['isSaveDraft', 'isOptionalInvestorType'],
    ([isSaveDraft, isOptionalInvestorType], schema) => {
      return isSaveDraft || isOptionalInvestorType
        ? schema.nullable()
        : schema.required(REQUIRE_MESSAGE);
    },
  ),
  isSaveDraft: Yup.boolean(),
  isOptionalInvestorType: Yup.boolean(),
  investorId: Yup.string().nullable(),
});

const editorDetailsSchema = (schema: Yup.ObjectSchema<object | null, Yup.AnyObject, object, ''>) =>
  schema.shape({
    userType: Yup.number().required(REQUIRE_MESSAGE),
    hasAuthorityToComplete: Yup.boolean()
      .nullable()
      .when('userType', {
        is: INVESTMENT_ENTITY_ROLE.Delegate,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    capacity: Yup.number()
      .nullable()
      .when('userType', {
        is: INVESTMENT_ENTITY_ROLE.Delegate,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    otherCapacity: Yup.string()
      .nullable()
      .when(['capacity', 'userType'], {
        is: (capacity: number, userType: number) =>
          capacity === INVESTMENT_ENTITY_DELEGATE_ROLE.Other &&
          userType === INVESTMENT_ENTITY_ROLE.Delegate,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    licenseeName: Yup.string()
      .nullable()
      .when(['capacity', 'userType'], {
        is: (capacity: number, userType: number) =>
          capacity === INVESTMENT_ENTITY_DELEGATE_ROLE.Adviser &&
          userType === INVESTMENT_ENTITY_ROLE.Delegate,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    afslNumber: Yup.string()
      .nullable()
      .when(['capacity', 'userType'], {
        is: (capacity: number, userType: number) =>
          capacity === INVESTMENT_ENTITY_DELEGATE_ROLE.Adviser &&
          userType === INVESTMENT_ENTITY_ROLE.Delegate,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
  });

export const individualDetailSchema = (hasPosition = false, TFNValidation = false) =>
  Yup.object({
    email: YupBase.email.when('populatedDirector', {
      is: (populatedDirector: any) => !populatedDirector,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
      otherwise: () => Yup.string().nullable(),
    }),
    firstName: YupBase.firstName.required(REQUIRE_MESSAGE),
    lastName: YupBase.lastName.required(REQUIRE_MESSAGE),
    dob: Yup.mixed()
      .when('populatedDirector', {
        is: (populatedDirector: any) => !populatedDirector,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    contactNumber: Yup.string().when('populatedDirector', {
      is: (populatedDirector: any) => !populatedDirector,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
    residentialManualEntry: Yup.boolean().nullable(),
    residentialAddress: Yup.string()
      .when(['populatedDirector', 'residentialManualEntry'], {
        is: (populatedDirector: any, residentialManualEntry: boolean) =>
          !populatedDirector && !residentialManualEntry,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    residentialCountry: Yup.string()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    residentialState: Yup.number()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      })
      .nullable(),
    residentialSuburb: Yup.string()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) => YupBase.residentialAddressString('Suburb'),
      })
      .nullable(),
    residentialPostcode: Yup.string()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) =>
          schema
            .required(REQUIRE_MESSAGE)
            .test(
              'residentialPostcode',
              'Postcode must be 4 digits',
              (value: string) => value?.trim()?.length === 4,
            ),
      })
      .nullable(),
    residentialStreetNumber: Yup.string()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) => YupBase.residentialAddressString('Street number'),
      })
      .nullable(),
    residentialStreetName: Yup.string()
      .when('residentialManualEntry', {
        is: true,
        then: (schema) => YupBase.residentialAddressString('Street name'),
      })
      .nullable(),
    ...(hasPosition
      ? {
          position: Yup.string().when('populatedDirector', {
            is: (populatedDirector: any) => !populatedDirector,
            then: (schema) => schema.required(REQUIRE_MESSAGE),
          }),
        }
      : {}),
    taxInfo: Yup.object().when('populatedDirector', {
      is: (populatedDirector: any) => !populatedDirector,
      then: (schema) =>
        schema.shape({
          isTaxResidentAustralia: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
          isTaxResidentOtherCountry: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
          country: Yup.string()
            .when('isTaxResidentOtherCountry', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          hasTIN: Yup.boolean()
            .nullable()
            .when('isTaxResidentOtherCountry', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            }),
          tin: Yup.string().when(
            ['isTaxResidentOtherCountry', 'hasTIN'],
            ([isTaxResidentOtherCountry, hasTIN], schema) => {
              if (isTaxResidentOtherCountry && hasTIN) {
                return schema.required(REQUIRE_MESSAGE);
              } else {
                return schema.nullable();
              }
            },
          ),
          reasonType: Yup.number()
            .nullable()
            .when(
              ['isTaxResidentOtherCountry', 'hasTIN'],
              ([isTaxResidentOtherCountry, hasTIN], schema) => {
                if (isTaxResidentOtherCountry && hasTIN === false) {
                  return schema.required(REQUIRE_MESSAGE);
                } else {
                  return schema.nullable();
                }
              },
            ),
          isDeclarationChecked: Yup.boolean().test('required', REQUIRE_MESSAGE, (val) => val),
          tfnExemptionCode: Yup.string()
            .nullable()
            .test('tfnExemptionCode', 'TFN should be between 8 and 9 digits.', (code: any) => {
              if (!TFNValidation) return true;
              if (code) {
                return code?.length >= 8 && code?.length <= 9;
              }
              return true;
            }),
        }),
    }),
    populatedDirector: Yup.boolean().nullable(),
  });

export const soleTraderInvestmentEntityDetailsSchema = individualDetailSchema(false, true).shape({
  abn: Yup.string().required(REQUIRE_MESSAGE),
  principalPlaceOfBusiness: Yup.string().required(REQUIRE_MESSAGE),
});

export const australianCompanySchema = (
  schema: Yup.ObjectSchema<object | null, Yup.AnyObject, object, ''>,
) =>
  schema.shape({
    companyName: Yup.string().required(REQUIRE_MESSAGE),
    abn: Yup.string().test('abn', REQUIRE_MESSAGE, function (value) {
      return !!value || !!this.parent.acn;
    }),
    acn: Yup.string().test('acn', REQUIRE_MESSAGE, function (value) {
      return !!value || !!this.parent.abn;
    }),
    countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
    countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
    companyTypeSelect: Yup.number().nullable().required(REQUIRE_MESSAGE).typeError(REQUIRE_MESSAGE),
    registeredAddress: Yup.string().required(REQUIRE_MESSAGE),
    principalPlaceOfBusiness: Yup.string().required(REQUIRE_MESSAGE),
    isSharesHeldBehalfOfTrust: Yup.boolean().nullable(),
    redFlagComment: Yup.string()
      .nullable()
      .when('isSharesHeldBehalfOfTrust', {
        is: (isSharesHeldBehalfOfTrust: boolean | null) =>
          typeof isSharesHeldBehalfOfTrust === 'boolean' && !isSharesHeldBehalfOfTrust,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    specialCompanyType: Yup.number().nullable().required(REQUIRE_MESSAGE),
    asxTickerCode: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.AustralianStockExchange,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    nameOfListedCompany: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.MajorityOwnedOrForeignStockExchange,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    nameOfStockExchangeName: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.MajorityOwnedOrForeignStockExchange,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    listedTickerCode: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.MajorityOwnedOrForeignStockExchange,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    regulatorName: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.LicensedAndRegulated,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    licenceName: Yup.string()
      .nullable()
      .when('specialCompanyType', {
        is: SpecialCompanyType.LicensedAndRegulated,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    directors: Yup.array().of(individualDetailSchema(true)).min(1, REQUIRE_MESSAGE),
    ubOs: Yup.array().of(individualDetailSchema(true)),
  });

export const foreignCompanySchema = (
  schema: Yup.ObjectSchema<object | null, Yup.AnyObject, object, ''>,
) =>
  schema.shape({
    companyName: Yup.string().required(REQUIRE_MESSAGE),
    countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
    countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
    companyType: Yup.string().required(REQUIRE_MESSAGE),
    registeredAddress: Yup.string().required(REQUIRE_MESSAGE),
    principalPlaceOfBusiness: Yup.string().required(REQUIRE_MESSAGE),
    foreignEIN: Yup.string().required(REQUIRE_MESSAGE),
    nameOfRelevantForeignRegistrationBody: Yup.string().required(REQUIRE_MESSAGE),
    registeredWithASIC: Yup.boolean().required(REQUIRE_MESSAGE),
    isCompanyListed: Yup.boolean().required(REQUIRE_MESSAGE),
    companyFullNameASIC: Yup.string().when('registeredWithASIC', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
    arbn: Yup.string().when('registeredWithASIC', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
    nameOfStockExchangeName: Yup.string()
      .nullable()
      .when('isCompanyListed', {
        is: true,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    listedTickerCode: Yup.string()
      .nullable()
      .when('isCompanyListed', {
        is: true,
        then: (schema) => schema.required(REQUIRE_MESSAGE),
      }),
    directors: Yup.array().of(individualDetailSchema(true)).min(1, REQUIRE_MESSAGE),
    ubOs: Yup.array().of(individualDetailSchema(true)),
  });

const trusteeSchema = Yup.object({
  trusteeType: Yup.number().nullable().required(REQUIRE_MESSAGE),
  numberOfIndividuals: Yup.number()
    .nullable()
    .when(['trusteeType'], {
      is: INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  trusteeIndividuals: Yup.array()
    .when(['trusteeType'], {
      is: INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual,
      then: (schema) => schema.of(individualDetailSchema(true)),
    })
    .nullable(),
  trusteeCompanyRegionType: Yup.number().nullable(),
  trusteeAustralianCompanyDetails: Yup.object().when(['trusteeType', 'trusteeCompanyRegionType'], {
    is: (
      trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
      trusteeCompanyRegionType: InvestmentEntityCompanyRegionType,
    ) =>
      trusteeCompanyRegionType === InvestmentEntityCompanyRegionType.Australian &&
      trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
    then: (schema) => australianCompanySchema(schema),
  }),
  trusteeForeignCompanyDetails: Yup.object()
    .when(['trusteeType'], {
      is: (
        trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
        trusteeCompanyRegionType: InvestmentEntityCompanyRegionType,
      ) =>
        trusteeCompanyRegionType === InvestmentEntityCompanyRegionType.Foreign &&
        trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate,
      then: (schema) => foreignCompanySchema(schema),
    })
    .nullable(),
});

export const regulatedTrustInvestmentEntityDetailsSchema = trusteeSchema.shape({
  name: Yup.string().required(REQUIRE_MESSAGE),
  countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
  countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
  trustType: Yup.number().nullable().required(REQUIRE_MESSAGE),
  otherTrustType: Yup.string()
    .nullable()
    .when(['trustType'], {
      is: INVESTMENT_ENTITY_REGULATED_TRUST_TRUSTTYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  registeredOffice: Yup.string().required(REQUIRE_MESSAGE),
  trustUBOs: Yup.array().of(individualDetailSchema(true)),
});

export const unregulatedTrustInvestmentEntityDetailsSchema = trusteeSchema.shape({
  name: Yup.string().required(REQUIRE_MESSAGE),
  countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
  countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
  trustType: Yup.number().nullable().required(REQUIRE_MESSAGE),
  otherTrustType: Yup.string()
    .nullable()
    .when(['trustType'], {
      is: INVESTMENT_ENTITY_UNREGULATED_TRUST_TRUSTTYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  registeredOffice: Yup.string().required(REQUIRE_MESSAGE),
  isSettlorMoreThanMax: Yup.boolean().nullable(),
  settlor: Yup.object().when(['isSettlorMoreThanMax'], {
    is: true,
    then: () => individualDetailSchema(),
  }),
  hasAppointer: Yup.boolean().nullable(),
  isAppointerSameAsTrustee: Yup.boolean().nullable(),
  appointerCompanyRegionType: Yup.number().nullable(),
  appointerType: Yup.number()
    .nullable()
    .when(['hasAppointer'], {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  selectedExistingIndividuals: Yup.array().of(Yup.string()).nullable(),
  appointerIndividuals: Yup.array()
    .of(individualDetailSchema(true))
    .when(['appointerType', 'hasAppointer', 'selectedExistingIndividuals'], {
      is: (
        appointerType: INVESTMENT_ENTITY_APPOINTER_TYPE,
        hasAppointer: boolean,
        selectedExistingIndividuals: string[],
      ) =>
        appointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Individual &&
        hasAppointer &&
        !selectedExistingIndividuals?.length,
      then: (schema) => schema.test('min', REQUIRE_MESSAGE, (val) => !!val?.length),
    })
    .nullable(),
  appointerAustralianCompanyDetails: Yup.object()
    .when(
      [
        'hasAppointer',
        'appointerCompanyRegionType',
        'appointerType',
        'isAppointerSameAsTrustee',
        'trusteeType',
      ],
      {
        is: (
          hasAppointer: boolean,
          appointerCompanyRegionType: InvestmentEntityCompanyRegionType,
          appointerType: INVESTMENT_ENTITY_APPOINTER_TYPE,
          isAppointerSameAsTrustee: boolean,
          trusteeType: INVESTMENT_ENTITY_TRUSTEE_TYPE,
        ) =>
          hasAppointer &&
          appointerCompanyRegionType === InvestmentEntityCompanyRegionType.Australian &&
          appointerType === INVESTMENT_ENTITY_APPOINTER_TYPE.Corporate &&
          ((!isAppointerSameAsTrustee &&
            trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Corporate) ||
            trusteeType === INVESTMENT_ENTITY_TRUSTEE_TYPE.Individual),
        then: (schema) => australianCompanySchema(schema),
      },
    )
    .nullable(),
  trustUBOs: Yup.array().of(individualDetailSchema(true)),
});

export const smsfInvestmentEntityDetailsSchema = trusteeSchema.shape({
  name: Yup.string().required(REQUIRE_MESSAGE),
  countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
  countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
  trustType: Yup.number().nullable().required(REQUIRE_MESSAGE),
  otherTrustType: Yup.string()
    .nullable()
    .when(['trustType'], {
      is: INVESTMENT_ENTITY_SMSF_TRUSTTYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  registeredOffice: Yup.string().required(REQUIRE_MESSAGE),
  smsfubOs: Yup.array().of(individualDetailSchema(true)),
});

export const governmentBodySchema = Yup.object().shape({
  abn: Yup.string().nullable(),
  nameOfGovernmentBody: Yup.string().required(REQUIRE_MESSAGE),
  principlePlaceOfOperation: Yup.string().required(REQUIRE_MESSAGE),
  type: Yup.number().required(REQUIRE_MESSAGE),
  govermentBodyStateId: Yup.string()
    .when('type', {
      is: GOVERNMENT_BODY_TYPE.AustralianStateOrTerritory,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  govermentBodyStateName: Yup.string().nullable(),
  countryId: Yup.string()
    .when('type', {
      is: GOVERNMENT_BODY_TYPE.Foreign,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  countryName: Yup.string().nullable(),
  publicOfficers: Yup.array()
    .when('type', {
      is: GOVERNMENT_BODY_TYPE.Foreign,
      then: (schema) => schema.of(individualDetailSchema(true)),
      otherwise: (schema) => {
        return schema.of(
          Yup.object({
            email: YupBase.email.required(REQUIRE_MESSAGE),
            firstName: YupBase.firstName.required(REQUIRE_MESSAGE),
            lastName: YupBase.lastName.required(REQUIRE_MESSAGE),
            dob: Yup.mixed().nullable(),
            contactNumber: Yup.string().required(REQUIRE_MESSAGE),
            residentialManualEntry: Yup.boolean().nullable(),
            residentialAddress: Yup.string().nullable(),
          }),
        );
      },
    })
    .min(1, REQUIRE_MESSAGE),
  ubOs: Yup.array().of(individualDetailSchema(true)),
});

export const associationSchema = Yup.object().shape({
  abn: Yup.string().nullable(),
  associationName: Yup.string().required(REQUIRE_MESSAGE),
  associationNumber: Yup.string().required(REQUIRE_MESSAGE),
  type: Yup.number().required(REQUIRE_MESSAGE),
  otherType: Yup.string().when('type', {
    is: ASSOCIATION_TYPE.Other,
    then: (schema) => schema.required(REQUIRE_MESSAGE),
  }),
  countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
  countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
  registeredOffice: Yup.string().required(REQUIRE_MESSAGE),
  certifiedAgreementFile: Yup.object().nullable(),
  publicOfficers: Yup.array(individualDetailSchema(true)).min(1, REQUIRE_MESSAGE),
  ubOs: Yup.array().of(individualDetailSchema(true)),
});

export const partnerSchema = Yup.object({
  refId: Yup.string().nullable(),
  partnerType: Yup.number().nullable(),
  email: Yup.string()
    .when('partnerType', ([partnerType], schema, { value }) => {
      if (partnerType === PARTNER_TYPE.Individuals && value) {
        return schema.email('Invalid email format').matches(EMAIL_REGEX, 'Invalid email format');
      }

      return schema.nullable();
    })
    .nullable(),
  firstName: Yup.string()
    .when('partnerType', {
      is: PARTNER_TYPE.Individuals,
      then: (schema) =>
        schema.max(255, 'First name must not exceed 225 characters').required(REQUIRE_MESSAGE),
    })
    .nullable(),
  lastName: Yup.string()
    .when('partnerType', {
      is: PARTNER_TYPE.Individuals,
      then: (schema) =>
        schema.max(255, 'Last name must not exceed 225 characters').required(REQUIRE_MESSAGE),
    })
    .nullable(),
  middleName: Yup.string().nullable(),
  dob: Yup.mixed()
    .when('partnerType', {
      is: PARTNER_TYPE.Individuals,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  contactNumber: Yup.string().nullable(),
  residentialManualEntry: Yup.boolean().nullable(),
  residentialAddress: Yup.string()
    .when(['partnerType', 'residentialManualEntry'], {
      is: (partnerType: number, residentialManualEntry: boolean) =>
        partnerType === PARTNER_TYPE.Individuals && !residentialManualEntry,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  residentialCountry: Yup.string()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  residentialState: Yup.number()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  residentialSuburb: Yup.string()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) => YupBase.residentialAddressString('Suburb'),
    })
    .nullable(),
  residentialPostcode: Yup.string()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) =>
        schema
          .required(REQUIRE_MESSAGE)
          .test(
            'residentialPostcode',
            'Postcode must be 4 digits',
            (value: string) => value?.trim()?.length === 4,
          ),
    })
    .nullable(),
  residentialStreetNumber: Yup.string()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) => YupBase.residentialAddressString('Street number'),
    })
    .nullable(),
  residentialStreetName: Yup.string()
    .when('residentialManualEntry', {
      is: true,
      then: (schema) => YupBase.residentialAddressString('Street name'),
    })
    .nullable(),
  taxInfo: Yup.object().when(['partnerType', 'refId'], {
    is: (partnerType: number, refId: string | null) =>
      partnerType === PARTNER_TYPE.Individuals && !!refId,
    then: (schema) =>
      schema.shape({
        isTaxResidentAustralia: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
        isTaxResidentOtherCountry: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
        country: Yup.string()
          .when('isTaxResidentOtherCountry', {
            is: true,
            then: (schema) => schema.required(REQUIRE_MESSAGE),
          })
          .nullable(),
        hasTIN: Yup.boolean()
          .nullable()
          .when('isTaxResidentOtherCountry', {
            is: true,
            then: (schema) => schema.required(REQUIRE_MESSAGE),
          }),
        tin: Yup.string().when(
          ['isTaxResidentOtherCountry', 'hasTIN'],
          ([isTaxResidentOtherCountry, hasTIN], schema) => {
            if (isTaxResidentOtherCountry && hasTIN) {
              return schema.required(REQUIRE_MESSAGE);
            } else {
              return schema.nullable();
            }
          },
        ),
        reasonType: Yup.number()
          .nullable()
          .when(
            ['isTaxResidentOtherCountry', 'hasTIN'],
            ([isTaxResidentOtherCountry, hasTIN], schema) => {
              if (isTaxResidentOtherCountry && hasTIN === false) {
                return schema.required(REQUIRE_MESSAGE);
              } else {
                return schema.nullable();
              }
            },
          ),
        isDeclarationChecked: Yup.boolean().test('required', REQUIRE_MESSAGE, (val) => val),
      }),
  }),
  entityName: Yup.string()
    .when('partnerType', {
      is: PARTNER_TYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  entityType: Yup.number()
    .when('partnerType', {
      is: PARTNER_TYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  acn: Yup.string().nullable(),
  businessAddress: Yup.string()
    .when('partnerType', {
      is: PARTNER_TYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  beVerified: Yup.boolean().nullable(),
});

export const partnershipInvestmentEntitySchema = Yup.object().shape({
  partnershipName: Yup.string().required(REQUIRE_MESSAGE),
  abn: Yup.string().required(REQUIRE_MESSAGE),
  businessName: Yup.string().nullable(),
  countryOfTaxResident: Yup.string().required(REQUIRE_MESSAGE),
  countryOfEstablishment: Yup.string().required(REQUIRE_MESSAGE),
  principalPlaceOfBusiness: Yup.string().required(REQUIRE_MESSAGE),
  type: Yup.number().nullable().required(REQUIRE_MESSAGE),
  otherType: Yup.string()
    .when('type', {
      is: PARTNERSHIP_TYPE.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  partnershipRegulated: Yup.boolean()
    .nullable()
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
  nameOfAssociation: Yup.string()
    .when('partnershipRegulated', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  membershipDetails: Yup.string()
    .when('partnershipRegulated', {
      is: true,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  partners: Yup.array().of(partnerSchema).min(1, REQUIRE_MESSAGE),
  ubOs: Yup.array()
    .of(individualDetailSchema(true))
    .when('partners', {
      is: (partners: IPartner[]) => !partners.some((it) => !!it?.refId),
      then: (schema) => schema.min(1, REQUIRE_MESSAGE),
    }),
});

export const investmentEntityValidationSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  entityType: Yup.number(),
  isAddNewEntityDetail: Yup.boolean().test('required', REQUIRE_MESSAGE, (val) => val),
  companyRegionType: Yup.number()
    .nullable()
    .when(['entityType', 'isSaveDraft'], {
      is: (entityType: number, isSaveDraft: boolean) =>
        entityType === INVESTMENT_ENTITY_TYPE.Company && !isSaveDraft,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  editorDetails: Yup.object().when(
    ['isSaveDraft', 'isAddNewEntityDetail'],
    ([isSaveDraft, isAddNewEntityDetail], schema) => {
      return isSaveDraft || !isAddNewEntityDetail ? schema.nullable() : editorDetailsSchema(schema);
    },
  ),
  otherType: Yup.number()
    .nullable()
    .when(['entityType', 'isSaveDraft'], {
      is: (entityType: number, isSaveDraft: boolean) =>
        entityType === INVESTMENT_ENTITY_TYPE.Other && !isSaveDraft,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    }),
  investmentEntityDetails: Yup.object().when(
    [
      'isSaveDraft',
      'isAddNewEntityDetail',
      'entityType',
      'companyRegionType',
      'trustType',
      'otherType',
    ],
    (
      [isSaveDraft, isAddNewEntityDetail, entityType, companyRegionType, trustType, otherType],
      schema,
    ) => {
      if (isSaveDraft || !isAddNewEntityDetail) return schema.nullable();
      switch (entityType) {
        case INVESTMENT_ENTITY_TYPE.Individuals:
          return schema.shape({
            numberOfIndividuals: Yup.number().nullable().required(REQUIRE_MESSAGE),
            individuals: Yup.array().of(individualDetailSchema(false, true)),
          });
        case INVESTMENT_ENTITY_TYPE.SoleTrader:
          return schema.shape({
            soleTrader: soleTraderInvestmentEntityDetailsSchema,
          });
        case INVESTMENT_ENTITY_TYPE.Company:
          return companyRegionType === InvestmentEntityCompanyRegionType.Foreign
            ? schema.shape({
                foreignCompany: foreignCompanySchema(Yup.object()),
              })
            : schema.shape({
                australianCompany: australianCompanySchema(Yup.object()),
              });
        case INVESTMENT_ENTITY_TYPE.Trust:
          if (trustType === INVESTMENT_ENTITY_TRUST.Regulated) {
            return schema.shape({
              regulatedTrust: regulatedTrustInvestmentEntityDetailsSchema,
            });
          } else {
            return schema.shape({
              unregulatedTrust: unregulatedTrustInvestmentEntityDetailsSchema,
            });
          }
        case INVESTMENT_ENTITY_TYPE.Other:
          if (otherType === INVESTMENT_ENTITY_OTHER.GovernmentBody) {
            return schema.shape({
              governmentBody: governmentBodySchema,
            });
          } else {
            return schema.shape({
              association: associationSchema,
            });
          }
        case INVESTMENT_ENTITY_TYPE.SMSF:
          return schema.shape({
            smsf: smsfInvestmentEntityDetailsSchema,
          });
        case INVESTMENT_ENTITY_TYPE.Partnership:
          return schema.shape({
            partnership: partnershipInvestmentEntitySchema,
          });
        default:
          return schema.nullable();
      }
    },
  ),
});

export const createTeamContactSchema = Yup.object().shape({
  firstName: YupBase.firstName,
  lastName: YupBase.lastName,
  email: YupBase.email,
  position: Yup.string().required(REQUIRE_MESSAGE),
  roleId: Yup.string()
    .nullable()
    .when(['teamTypes'], ([teamTypes], schema) => {
      if (teamTypes.includes(TeamType.Team)) {
        return schema.required(REQUIRE_MESSAGE);
      }
      return schema.nullable();
    }),
  teamTypes: Yup.array().of(Yup.number()).min(1, REQUIRE_MESSAGE),
  userType: Yup.number()
    .nullable()
    .when(['teamTypes'], ([teamTypes], schema) => {
      if (teamTypes.includes(TeamType.Team)) {
        return schema.required(REQUIRE_MESSAGE);
      }
      return schema.nullable();
    }),
});

export const additionalSchema = Yup.object().shape({
  sourceOfFundsType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.number() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  distributionInstructionType: Yup.number().when('isSaveDraft', ([isSaveDraft]) => {
    return isSaveDraft ? Yup.number().nullable() : Yup.number().required(REQUIRE_MESSAGE);
  }),
  otherDescription: Yup.string()
    .when('sourceOfFundsType', {
      is: InvestorSourceOfFunds.Other,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  documents: Yup.array(),
  isSaveDraft: Yup.boolean(),
});

const individualAcceptanceSchema = Yup.object({
  method: Yup.string().required(REQUIRE_MESSAGE),
  isReadDeclaration: Yup.boolean().when('method', (method) => {
    return +method[0] === SignatoryMethodType.SignNow
      ? Yup.boolean().test('required', REQUIRE_MESSAGE, (value) => {
          return !!value;
        })
      : Yup.boolean();
  }),
  signature: Yup.string()
    .nullable()
    .when(['method', 'signatoryFile'], {
      is: (method: any, signatoryFile: any) => {
        return method === String(SignatoryMethodType.SignNow) && !signatoryFile;
      },
      then: (schema) => schema.required('Please record your signature'),
    }),
});

export const applicationAcceptanceSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  validateCase1: Yup.boolean().nullable(),
  validateCase2: Yup.boolean().nullable(),
  signatories: Yup.array()
    .of(individualAcceptanceSchema)
    .when(['validateCase1'], {
      is: (validateCase1: boolean) => validateCase1,
      then: (schema) =>
        schema.test('length', 'Please select 2 directors to sign', (value) => {
          if (value?.length === 2) return true;
          return false;
        }),
    })
    .when(['validateCase2'], {
      is: (validateCase2: boolean) => validateCase2,
      then: (schema) =>
        schema
          .min(1, 'Please select at least 1 individual')
          .required('Please select at least 1 individual'),
    }),
});

export const finacialAndTaxDetailsSchema = Yup.object().shape({
  financialDetails: financialDetailsSchema,
  isSaveDraft: Yup.boolean(),
  taxFileNumber: Yup.string()
    .nullable()
    .test('invalidAbn', 'TFN should be between 8 and 9 digits.', (abn) => {
      if (abn) {
        const tfnAbn = removeSpacesInString(abn) as string;
        return tfnAbn.length >= 8 && tfnAbn.length <= 9;
      }
      return true;
    }),
  isRequiredDeclarationOnly: Yup.boolean().nullable(),
  isTaxResidentOtherCountryThanAustralia: Yup.boolean()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  taxStatus: Yup.object()
    .when(['isSaveDraft', 'isRequiredDeclarationOnly'], {
      is: (isSaveDraft: boolean, isRequiredDeclarationOnly: boolean) =>
        !isSaveDraft && !isRequiredDeclarationOnly,
      then: (schema) =>
        schema.shape({
          taxStatusType: Yup.number().nullable().required(REQUIRE_MESSAGE),
          otherDescription: Yup.string()
            .when('taxStatusType', {
              is: TAX_STATUS.Other,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          hasGIIN: Yup.boolean()
            .when('taxStatusType', {
              is: TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          giin: Yup.string()
            .when(['hasGIIN', 'taxStatusType'], {
              is: (hasGIIN: boolean, taxStatusType: number) =>
                hasGIIN && taxStatusType === TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          reasonNotHavingGIIN: Yup.number()
            .when(['hasGIIN', 'taxStatusType'], {
              is: (hasGIIN: boolean, taxStatusType: number) =>
                !hasGIIN && taxStatusType === TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          isAllowCRSAndManagedByAnotherFI: Yup.boolean()
            .when('taxStatusType', {
              is: TAX_STATUS.FinancialInstitution,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
        }),
    })
    .nullable(),
  foreignControllingPersons: Yup.object()
    .when(['isSaveDraft', 'isRequiredDeclarationOnly'], {
      is: (isSaveDraft: boolean, isRequiredDeclarationOnly: boolean) =>
        !isSaveDraft && !isRequiredDeclarationOnly,
      then: (schema) =>
        schema.shape({
          hasControllingPersonsOtherThanAustralia: Yup.boolean()
            .nullable()
            .required(REQUIRE_MESSAGE),
          directors: Yup.array().when('hasControllingPersonsOtherThanAustralia', {
            is: true,
            then: (schema) => schema.min(1, REQUIRE_MESSAGE),
            otherwise: (schema) => schema.nullable(),
          }),
        }),
    })
    .nullable(),
  entityCountryOfTaxResidency: Yup.object()
    .when(['isSaveDraft', 'isRequiredDeclarationOnly'], {
      is: (isSaveDraft: boolean, isRequiredDeclarationOnly: boolean) =>
        !isSaveDraft && !isRequiredDeclarationOnly,
      then: (schema) =>
        schema.shape({
          hasEntityTaxOtherThanAustralia: Yup.boolean().nullable().required(REQUIRE_MESSAGE),
          hasTIN: Yup.boolean()
            .when('hasEntityTaxOtherThanAustralia', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          tin: Yup.string()
            .when('hasTIN', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          reasonNotHavingTIN: Yup.number()
            .when(['hasEntityTaxOtherThanAustralia', 'hasTIN'], {
              is: (hasEntityTaxOtherThanAustralia: boolean, hasTIN: boolean) =>
                hasEntityTaxOtherThanAustralia && !hasTIN,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
          countryOfTax: Yup.string()
            .when('hasEntityTaxOtherThanAustralia', {
              is: true,
              then: (schema) => schema.required(REQUIRE_MESSAGE),
            })
            .nullable(),
        }),
    })
    .nullable(),
  declarationDirectorId: Yup.string()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
});

export const applicationSummarySchema = Yup.object().shape({
  investmentAmountOutstanding: Yup.number(),
  investmentAmountReceived: Yup.array().of(
    Yup.object({
      receivedAmount: YupBase.transformNumberSchema
        .typeError(REQUIRE_MESSAGE)
        .required(REQUIRE_MESSAGE),
      receivedDate: Yup.string().required(REQUIRE_MESSAGE),
    }),
  ),
});

export const finaliseDetailSchema = Yup.object({
  investorNumber: Yup.string().nullable().required(REQUIRE_MESSAGE),
  transactionDate: YupBase.transactionDate,
  unitPriceRounding: Yup.number().nullable(),
  unitPrice: Yup.number()
    .test(
      'unitPrice',
      'Decimal places do not comply with the fund rule. Please correct this in the unit registry before proceeding.',
      function (value) {
        /**
         * If decimal places of value > unit price rounding rule, then show error
         * Else return true
         */
        const decimalPlaces = Number(value)?.toString()?.split('.')?.[1]?.length || 0;

        return decimalPlaces <= this.parent.unitPriceRounding;
      },
    )
    .typeError(REQUIRE_MESSAGE)
    .required(REQUIRE_MESSAGE),
});

export const fundAMLKYCSchema = Yup.object().shape({
  isSaveDraft: Yup.boolean(),
  kycSubmitType: Yup.number()
    .when('isSaveDraft', {
      is: false,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
  comment: Yup.string()
    .when(['isSaveDraft', 'kycSubmitType'], {
      is: (isSaveDraft: boolean, kycSubmitType: number) =>
        !isSaveDraft && kycSubmitType === FUND_KYC_SUBMIT_TYPE.MarkCompleted,
      then: (schema) => schema.required(REQUIRE_MESSAGE),
    })
    .nullable(),
});
