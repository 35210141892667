/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { CustomStepper, ICustomStepperElement, IStep } from 'src/components/atoms/CustomStepper';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import { UPSERT_FUND_STEPS } from 'src/components/pages/funds-management/funds/components/UpsertFundsForm';
import { FundType } from 'src/constants/funds-management';
import {
  UnitOptionsType,
  investmentDetailDefaultValue,
  unitClassDetailDefaultValue,
  unitClassInvestorRelationsDefaultValue,
} from 'src/constants/unit-class';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { IAlertInfo } from 'src/modules/common/type';
import { useGetFundById } from 'src/modules/funds-management/hooks';
import { IUnitClass, IUpsertFundForm } from 'src/modules/funds-management/type';
import {
  useDuplicateUnitClassById,
  useGetDuplicateUnitClassById,
  useGetUnitClassById,
  useUpsertFinancialDetail,
  useUpsertInvestmentDetail,
  useUpsertUnitClass,
  useUpsertUnitClassDetail,
  useUpsertUnitClassInvestorRelations,
} from 'src/modules/unit-class/hooks';
import {
  IFinancialDetailFormFields,
  IInvestmentFormFields,
  IUnitClassDetailFormFields,
  IUnitClassInvestorRelationsParams,
  IUpsertInvestmentDetailsParams,
  IUpsertUnitClassDetailParams,
} from 'src/modules/unit-class/type';
import { currencyStringToNumber, handleErrorFromServer } from 'src/utils/common';
import {
  investmentDetailsValidationSchema,
  investorRelationSchema,
  unitClassDetailValidationSchema,
} from 'src/validations/unit-class';
import FinancialDetailForm from './FinancialDetailForm';
import InvestmentDetailForm from './InvestmentDetailForm';
import InvestorRelations from './InvestorRelations';
import UnitClassDetailForm from './UnitClassDetailForm';

interface IUpsertUnitClassFormProps extends IUpsertFundForm {
  handleCloseModal: (name?: string) => void;
  fundId: string;
  isDuplicateMode: boolean;
  unitClass?: IUnitClass;
  isDraftStatus?: boolean;
  fundCode?: string;
}

export enum CREATE_UNIT_CLASS_STEPS {
  UNIT_CLASS_DETAIL,
  INVESTOR_RELATIONS,
  FINANCIAL_DETAILS,
  INVESTMENT_DETAILS,
}

type IFormData = {
  [key in CREATE_UNIT_CLASS_STEPS]: {
    form?: UseFormReturn<any>;
    isDirty?: boolean;
    isValid?: boolean;
    submit: (mode: string) => void;
  };
};

const UpsertUnitClassForm: FC<IUpsertUnitClassFormProps> = ({
  handleCloseModal,
  fundId,
  isViewMode,
  isEditMode,
  isDuplicateMode,
  unitClass,
  isDraftStatus,
  fundCode = '',
}) => {
  const isCreateMode = !isViewMode && !isEditMode;
  const stepperRef = useRef<ICustomStepperElement>(null);
  const alertRef = useRef<IBasicModalElement>(null);
  const [unitClassId, setUnitClassId] = useState<string>(unitClass?.id || '');
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [currentStep, setCurrenStep] = useState<number>(CREATE_UNIT_CLASS_STEPS.UNIT_CLASS_DETAIL);
  const [isDuplicating, setIsDuplicating] = useState(isDuplicateMode);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);

  const { data: fundFinancialDetails } = useGetFundById(
    fundId,
    UPSERT_FUND_STEPS.FINANCIAL_DETAILS,
  );

  const { data: unitClassData } = useGetUnitClassById(unitClassId, currentStep);
  const { data: duplicateUnitClassData } = useGetDuplicateUnitClassById(
    isDuplicating ? unitClassId : '',
  );

  const { mutate: onDuplicateUnitClassData } = useDuplicateUnitClassById(unitClassId);
  const { mutateAsync: upsertUnitClassDetailMutate, isLoading: upsertUnitClassDetailLoading } =
    useUpsertUnitClassDetail();
  const {
    mutateAsync: upsertUnitClassInvestorRelationsMutate,
    isLoading: upsertUnitClassInvestorRelationsLoading,
  } = useUpsertUnitClassInvestorRelations();
  const { data: fundDetail } = useGetFundById(fundId, UPSERT_FUND_STEPS.FUND_DETAILS);
  const { type: fundType, offerType: fundOfferType, name: fundName } = fundDetail?.detail || {};

  const unitClassDetailForm = useForm<IUnitClassDetailFormFields>({
    defaultValues: unitClassDetailDefaultValue,
    resolver: yupResolver(unitClassDetailValidationSchema),
  });
  const watchUnitClassName = unitClassDetailForm.watch('name');

  const investmentDetailForm = useForm<IInvestmentFormFields>({
    defaultValues: investmentDetailDefaultValue,
    resolver: yupResolver(investmentDetailsValidationSchema),
  });

  const financialDetailForm = useForm<IFinancialDetailFormFields>();

  const investorRelationsForm = useForm<IUnitClassInvestorRelationsParams>({
    defaultValues: unitClassInvestorRelationsDefaultValue,
    resolver: yupResolver(investorRelationSchema),
  });

  const { mutateAsync: upsertFinancialDetailMutate, isLoading: upsertFinancialDetailLoading } =
    useUpsertFinancialDetail();
  const { mutateAsync: upsertInvestmentDetailMutate, isLoading: upsertInvestmentDetailLoading } =
    useUpsertInvestmentDetail();
  const { mutate: upsertUnitClassMutate, isLoading: upsertUnitClassLoading } = useUpsertUnitClass();

  const isLoading =
    upsertUnitClassDetailLoading ||
    upsertInvestmentDetailLoading ||
    upsertUnitClassLoading ||
    upsertUnitClassInvestorRelationsLoading ||
    upsertFinancialDetailLoading;

  useEffect(() => {
    handleFillData();
  }, [unitClassData, duplicateUnitClassData, isDuplicating]);

  const handleFillData = () => {
    if (isCreateMode && !isDuplicateMode) return;
    const fillData = isDuplicating ? duplicateUnitClassData : unitClassData;
    switch (currentStep) {
      case CREATE_UNIT_CLASS_STEPS.UNIT_CLASS_DETAIL:
        if (!isEmpty(fillData?.details)) {
          unitClassDetailForm.reset({
            ...fillData?.details,
            allowCapitalCall: Number(fillData?.details.allowCapitalCall),
            allowPartiallyPaidUnits: Number(fillData?.details.allowPartiallyPaidUnits),
            currencyName: unitClass?.currencyName || '',
            code: isDraftStatus
              ? fillData?.details.code
              : fillData?.details.code.split(fundCode)?.[1] || fillData?.details.code,
          });
        }
        break;
      case CREATE_UNIT_CLASS_STEPS.FINANCIAL_DETAILS:
        if (!isEmpty(fillData?.financialDetails)) {
          financialDetailForm.reset({
            ...fillData?.financialDetails,
            allowDRP: fillData?.financialDetails.allowDRP
              ? UnitOptionsType.Yes.toString()
              : UnitOptionsType.No.toString(),
            currencyName: unitClass?.currencyName || '',
          });
        }
        break;
      case CREATE_UNIT_CLASS_STEPS.INVESTMENT_DETAILS: {
        const { investmentDetails } = fillData || {};
        if (!isEmpty(investmentDetails)) {
          investmentDetailForm.reset({
            ...investmentDetails,
            offerType: investmentDetails.offerType,
            unlimitedInvestmentAmount: investmentDetails.maximumInvestmentAmount === null,
            incrementInvestmentAmount: investmentDetails.incrementInvestmentAmount || 0,
            allowForeignInvestors: Number(investmentDetails.allowForeignInvestors),
            acceptInvestmentAmountUnderMinimum: Number(
              investmentDetails.acceptInvestmentAmountUnderMinimum,
            ),
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleShowAlert = ({ title, description }: IAlertInfo) => {
    setAlertInfo({ title, description });
    alertRef.current?.open();
  };

  const handleSuccess = (mode?: string) => {
    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleShowAlert({
        title: 'Draft saved!',
        description: `You can come back and resume at any time.`,
      });
    } else {
      handleGoToNextStep();
    }
  };

  const handleSubmitUnitClassDetail = async (mode: string) => {
    const data = unitClassDetailForm.getValues();
    const params: IUpsertUnitClassDetailParams = {
      ...data,
      allowCapitalCall: Boolean(data.allowCapitalCall),
      allowPartiallyPaidUnits: Boolean(data.allowPartiallyPaidUnits),
      initialUnitPrice: currencyStringToNumber(data.initialUnitPrice),
    };

    if (isDuplicating) {
      duplicateUnitClassData &&
        onDuplicateUnitClassData(
          { ...duplicateUnitClassData, details: { ...params } },
          {
            onSuccess: (id?: string) => {
              id && setUnitClassId(id);
              handleSuccess(mode);
              setIsDuplicating(false);
            },
            onError: handleErrorFromServer,
          },
        );
    } else {
      await upsertUnitClassDetailMutate(
        { ...params, id: unitClassId, fundId },
        {
          onSuccess: (id?: string) => {
            id && setUnitClassId(id);
            handleSuccess(mode);
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const handleSubmitFinancialDetail = async (mode: string) => {
    const data = financialDetailForm.getValues();
    const dataFormat = {
      ...data,
      allowDRP: fundType === FundType.Trust && data?.allowDRP === UnitOptionsType.Yes.toString(),
      unitPricingFrequency:
        fundType === FundType.Trust ? data?.unitPricingFrequency : data?.assetValuationFrequency,
      fees: data.fees.map((fee) => ({
        ...fee,
        amount: parseFloat(fee.amount as unknown as string) || 0,
        amountType: parseInt(fee.amountType as unknown as string) || 0,
        paymentTerm: parseInt(fee.paymentTerm as unknown as string) || 0,
        buySpread: fundType === FundType.Trust ? fee.buySpread : 0,
        sellSpread: fundType === FundType.Trust ? fee.sellSpread : 0,
      })),
    };

    await upsertFinancialDetailMutate(
      { ...dataFormat, id: unitClassId },
      {
        onSuccess: () => {
          handleSuccess(mode);
        },
      },
    );
  };

  const handleSubmitUnitClassInvestorRelations = async (mode: string) => {
    const params = investorRelationsForm.getValues();

    await upsertUnitClassInvestorRelationsMutate(
      {
        params,
        unitClassId,
      },
      {
        onSuccess: () => handleSuccess(mode),
        onError: handleErrorFromServer,
      },
    );
  };

  const handleSubmitInvestmentDetail = async (mode: string) => {
    const data = investmentDetailForm.getValues();
    const { maximumInvestmentAmount, minimumRedeptionAmount } = data;

    const params: IUpsertInvestmentDetailsParams = {
      ...data,
      id: unitClassId,
      allowForeignInvestors: Boolean(data.allowForeignInvestors),
      acceptInvestmentAmountUnderMinimum: Boolean(data.acceptInvestmentAmountUnderMinimum),
      minimumInvestmentAmount: currencyStringToNumber(data.minimumInvestmentAmount),
      minimumSubsequentInvestmentAmount: currencyStringToNumber(
        data.minimumSubsequentInvestmentAmount,
      ),
      maximumInvestmentAmount:
        maximumInvestmentAmount === null
          ? maximumInvestmentAmount
          : currencyStringToNumber(maximumInvestmentAmount),
      hardFloor: currencyStringToNumber(data.hardFloor),
      minimumRedeptionAmount:
        minimumRedeptionAmount === null
          ? minimumRedeptionAmount
          : currencyStringToNumber(minimumRedeptionAmount),
    };
    const isDraftMode = mode === SUBMIT_FORM_ACTIONS.DRAFT;

    await upsertInvestmentDetailMutate(params, {
      onSuccess: (data) => {
        if (isEditMode && !isDraftStatus) return;
        isDraftMode
          ? handleSuccess(mode)
          : handleSubmitUnitClass(unitClassDetailForm.getValues('name'));
      },
      onError: handleErrorFromServer,
    });
  };

  const handleSubmitUnitClass = (unitClassName?: string) => {
    upsertUnitClassMutate(unitClassId, {
      onSuccess: () => handleCloseModal(unitClassName),
      onError: handleErrorFromServer,
    });
  };

  const handleUnitClassSubmitAllSection = () => {
    const isValid = handleSetErrorSteps();
    if (!isValid) {
      return;
    }

    Promise.all(
      Object.values(formData)
        .filter(({ isDirty }) => isDirty)
        .map(({ submit }) => submit(SUBMIT_FORM_ACTIONS.SUBMIT)),
    )
      .then(() => {
        handleShowAlert({
          title: 'You did it!',
          description: `Unit Class ${watchUnitClassName} has been updated successfully.`,
        });
      })
      .catch((error) => {
        console.log('Submit all error: ', error);
      });
  };

  const formData: IFormData = {
    [CREATE_UNIT_CLASS_STEPS.UNIT_CLASS_DETAIL]: {
      form: unitClassDetailForm,
      isDirty: unitClassDetailForm.formState.isDirty,
      isValid: unitClassDetailForm.formState.isValid,
      submit: handleSubmitUnitClassDetail,
    },
    [CREATE_UNIT_CLASS_STEPS.INVESTOR_RELATIONS]: {
      form: investorRelationsForm,
      isDirty: true,
      isValid: investorRelationsForm.formState.isValid,
      submit: handleSubmitUnitClassInvestorRelations,
    },
    [CREATE_UNIT_CLASS_STEPS.FINANCIAL_DETAILS]: {
      form: financialDetailForm,
      isDirty: financialDetailForm.formState.isDirty,
      isValid: financialDetailForm.formState.isValid,
      submit: handleSubmitFinancialDetail,
    },
    [CREATE_UNIT_CLASS_STEPS.INVESTMENT_DETAILS]: {
      form: investmentDetailForm,
      isDirty: investmentDetailForm.formState.isDirty,
      isValid: investmentDetailForm.formState.isValid,
      submit: handleSubmitInvestmentDetail,
    },
  };

  const handleSetErrorSteps = () => {
    const validArray = Object.values(formData).map(({ form }) => form?.formState?.isValid || !form);
    const errorStepList = validArray
      .map((it, id) => (it ? null : id))
      .filter((it) => it !== null) as number[];
    setErrorSteps(errorStepList);
    Object.values(formData).map(({ form }) => form?.trigger?.());
    return !errorStepList.length;
  };

  const handleClickStep = () => {
    if (isViewMode) return;
    handleSetErrorSteps();
  };

  const handleSave = (step: CREATE_UNIT_CLASS_STEPS, mode: string) => {
    const isSaveDraft = mode === SUBMIT_FORM_ACTIONS.DRAFT;
    if (isViewMode) {
      return handleGoToNextStep();
    }

    if (isEditMode && !isDraftStatus) {
      if (isSaveDraft) {
        handleSetErrorSteps();
        handleGoToNextStep();
      } else {
        handleUnitClassSubmitAllSection();
      }
      return;
    }

    const { form, submit } = formData[step];
    if (form) {
      form.setValue('isSaveDraft', isSaveDraft);
      form.handleSubmit(() => submit(mode))();
    } else {
      submit(mode);
    }
  };

  const handleCloseDrawer = () => {
    handleCloseModal();
    setUnitClassId('');
  };

  const handleStepChange = (step: number) => {
    setCurrenStep(step);
  };

  const getDefaultStepProps = (step: number) => ({
    isViewMode,
    isEditMode,
    isDraft: isDraftStatus,
    isLoading,
    onSubmit: () => {
      if (stepperRef.current?.getCurrentStep() === step) {
        handleSave(step, SUBMIT_FORM_ACTIONS.SUBMIT);
      }
    },
    onSave: () => handleSave(step, SUBMIT_FORM_ACTIONS.DRAFT),
  });

  const STEPS: IStep[] = [
    {
      key: CREATE_UNIT_CLASS_STEPS.UNIT_CLASS_DETAIL,
      label: fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class',
      content: (
        <FormProvider {...unitClassDetailForm}>
          <FormSection
            title={fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class'}
            {...getDefaultStepProps(CREATE_UNIT_CLASS_STEPS.UNIT_CLASS_DETAIL)}
          >
            <UnitClassDetailForm
              fundId={fundId}
              isCreateMode={isCreateMode}
              isTrust={fundType === FundType.Trust}
              isViewMode={!!isViewMode}
              isDraftStatus={isDraftStatus}
              unitPriceRounding={fundFinancialDetails?.financialDetails?.unitPrice || 0}
              fundCode={fundCode}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: CREATE_UNIT_CLASS_STEPS.INVESTOR_RELATIONS,
      label: 'Investor Relations',
      content: (
        <FormProvider {...investorRelationsForm}>
          <FormSection
            title='Investor Relations'
            {...getDefaultStepProps(CREATE_UNIT_CLASS_STEPS.INVESTOR_RELATIONS)}
          >
            {investorRelationsForm.formState.errors?.email && (
              <CustomHelperText
                className='w-full flex justify-center -mt-3 mb-2'
                variant='error'
                message={investorRelationsForm.formState.errors.email?.message}
              />
            )}
            <InvestorRelations
              isViewMode={!!isViewMode}
              unitClassId={unitClassId}
              fundId={fundId}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: CREATE_UNIT_CLASS_STEPS.FINANCIAL_DETAILS,
      label: 'Financial Details',
      content: (
        <FormProvider {...financialDetailForm}>
          <FormSection
            title='Financial Details'
            {...getDefaultStepProps(CREATE_UNIT_CLASS_STEPS.FINANCIAL_DETAILS)}
          >
            <FinancialDetailForm
              fundId={fundId}
              fundType={fundType}
              currencyName={unitClassDetailForm.getValues('currencyName')}
              isViewMode={!!isViewMode}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: CREATE_UNIT_CLASS_STEPS.INVESTMENT_DETAILS,
      label: 'Investment Details',
      content: (
        <FormProvider {...investmentDetailForm}>
          <FormSection
            title='Investment Details'
            {...getDefaultStepProps(CREATE_UNIT_CLASS_STEPS.INVESTMENT_DETAILS)}
            isLastStep
            createBtnText={`Create ${
              fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class'
            }`}
          >
            <InvestmentDetailForm
              currencyName={unitClassDetailForm.getValues('currencyName')}
              isTrust={fundType === FundType.Trust}
              isViewMode={!!isViewMode}
              fundOfferType={fundOfferType}
            />
          </FormSection>
        </FormProvider>
      ),
    },
  ];

  return (
    <Box className='w-[1120px] h-full'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {`${isEditMode ? 'Edit' : isViewMode ? 'View' : 'Create'} ${
              fundType === FundType.Partnership ? 'Financial Rule' : 'Unit Class'
            } - ${fundName || 'Fund Name'}`}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={() => handleCloseModal()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 5 }}
            errorSteps={errorSteps}
            onClickStep={handleClickStep}
            enableClickStep={!isCreateMode && !isDraftStatus}
            onStepChange={handleStepChange}
            destroyInactiveStep={false}
          />
        </Box>
      </Box>
      <BasicModal ref={alertRef}>
        <ConfirmationAlert
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              alertRef?.current?.close();
              handleCloseDrawer();
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default UpsertUnitClassForm;
