/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */

import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocomplete from 'src/components/atoms/FormAutocomplete';
import FormCheckbox from 'src/components/atoms/FormCheckbox';
import FormInput from 'src/components/atoms/FormInput';
import { ICity, ICountry, IState } from 'src/interfaces/common';
import { useGetCities, useGetCountries, useGetStates } from 'src/modules/common/hooks';
import { ADDRESS_SECTION_NAME } from './OrganisationForm';
interface IAddressSectionProps extends BoxProps {
  title?: string;
  name: string;
  watch?: any;
  readOnly?: boolean;
  disabled?: boolean;
  isShowManualEntry?: boolean;
  optional?: string;
  resetField?: (field: string) => void;
  isPostalAddress?: boolean;
}

const AddressSection: React.FunctionComponent<IAddressSectionProps> = ({
  title = '',
  name,
  readOnly = false,
  disabled,
  isShowManualEntry = false,
  optional = '',
  isPostalAddress = false,
  ...rest
}) => {
  const { data: Countries = [] } = useGetCountries();
  const { data: Cities = [] } = useGetCities();
  const { data: States = [] } = useGetStates();

  const countryOptions = useMemo(
    () =>
      Countries.map((i: ICountry) => ({
        label: i.name,
        value: i.name,
        id: i.id,
      })),
    [Countries],
  );

  const { setValue, resetField, watch, getValues } = useFormContext();

  const {
    formState: { errors },
  } = useFormContext();

  const selectedCountry = watch(`${name}.country`);
  const selectedState = watch(`${name}.state`);
  const selectedCity = watch(`${name}.city`);
  const watchManualEntry = watch(`${name}.manualEntry`);
  const watchPhysicalManualEntry = watch('physicalAddress.manualEntry');

  const stateOptions = useMemo(() => {
    return States.filter((i: IState) => i.country_id === Number(selectedCountry?.id)).map(
      (i: IState) => ({
        ...i,
        value: i.name,
        label: i.name,
      }),
    );
  }, [selectedCountry]);

  const cityOptions = useMemo(() => {
    return (Cities as ICity[])
      ?.filter((i: ICity) => i.state_id === Number(selectedState?.id))
      .map((i: ICity) => ({
        ...i,
        value: i.name,
        label: i.name,
      }));
  }, [selectedState]);

  useEffect(() => {
    if (typeof selectedCity === 'string' && !watchManualEntry) {
      setValue(`${name}.manualEntry`, true);
    }
  }, [selectedCity, watchManualEntry]);

  const handleSetPostalAddress = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const country = getValues('physicalAddress.country');
    const state = getValues('physicalAddress.state');
    const city = getValues('physicalAddress.city');
    const postcode = getValues('physicalAddress.postcode');
    const streetAddress = getValues('physicalAddress.streetAddress');

    if (watchPhysicalManualEntry) {
      setValue('postalAddress.manualEntry', true);
    } else setValue('postalAddress.manualEntry', false);

    if (checked) {
      const option = {
        shouldValidate: true,
      };
      setValue('postalAddress.country', country, option);
      setValue('postalAddress.state', state, option);
      setValue('postalAddress.city', city, option);
      setValue('postalAddress.postcode', postcode, option);
      setValue('postalAddress.streetAddress', streetAddress, option);
    } else {
      resetField('postalAddress.country');
      resetField('postalAddress.state');
      watchPhysicalManualEntry
        ? setValue('postalAddress.city', '')
        : resetField('postalAddress.city');
      resetField('postalAddress.postcode');
      resetField('postalAddress.streetAddress');
    }
  };

  const handleManualEntryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setValue(`${name}.city`, '');
    } else {
      setValue(`${name}.city`, {
        id: '',
        value: '',
        label: '',
      });
    }
  };

  const renderManualEntryCheckbox = () => {
    if (!isShowManualEntry) return <></>;
    return (
      <span className='flex items-center'>
        <FormCheckbox
          name={`${name}.manualEntry`}
          disabled={readOnly || disabled}
          label={
            <Typography component='span' color='neutral.ne800' variant='body3'>
              Manual entry
            </Typography>
          }
          onChange={handleManualEntryChange}
          size='small'
        />
      </span>
    );
  };

  const renderStreetAddressLabel = () => {
    return isPostalAddress || name === ADDRESS_SECTION_NAME.postalAddress
      ? 'Postal Address'
      : 'Street Address';
  };

  const renderStreetAddressPlaceholder = () => {
    return isPostalAddress || name === ADDRESS_SECTION_NAME.postalAddress
      ? 'Enter postal address'
      : 'Enter street address';
  };

  return (
    <Box className={'pt-8'} {...rest}>
      <Box className='flex justify-between items-center'>
        <Typography variant='body2' fontWeight='600'>
          {title}
        </Typography>
        {optional && (
          <Typography variant='body2' fontWeight='500' color='neutral.ne800'>
            {optional}
          </Typography>
        )}
      </Box>
      <Box className='pt-4'>
        <Grid container columnSpacing={3} rowSpacing='18px'>
          {name === 'postalAddress' && (
            <Grid item xs={12}>
              <FormCheckbox
                name='isUseSameAsPhysicalAddress'
                label={
                  <Typography
                    variant='body2'
                    component='span'
                    color='neutral.ne800'
                    sx={{ ml: '12px' }}
                  >
                    Use same as Physical Address.
                  </Typography>
                }
                onChange={handleSetPostalAddress}
                disabled={readOnly || disabled}
                sx={{ ml: 0 }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormAutocomplete
              disabled={disabled}
              name={`${name}.country`}
              dataKey='id'
              label='Country'
              options={countryOptions}
              placeholder='Select country'
              readOnly={readOnly}
              errorMessage={get(errors, `${name}.country.id.message`, '') as string}
              blurOnSelect
              onChange={(e, value, reason) => {
                if (reason === 'selectOption') {
                  setValue(`${name}.state`, {
                    label: '',
                    id: '',
                    value: '',
                  });
                  if (!watchManualEntry)
                    setValue(`${name}.city`, {
                      label: '',
                      id: '',
                      value: '',
                    });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormAutocomplete
              disabled={disabled}
              name={`${name}.state`}
              dataKey='id'
              label='State '
              options={stateOptions}
              placeholder='Select state'
              readOnly={readOnly}
              errorMessage={get(errors, `${name}.state.id.message`, '') as string}
              blurOnSelect
              onChange={(e, value, reason) => {
                if (reason === 'selectOption' && !watchManualEntry) {
                  setValue(`${name}.city`, {
                    label: '',
                    id: '',
                    value: '',
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {watchManualEntry ? (
              <FormInput
                name={`${name}.city`}
                label={
                  <span className='flex justify-between w-full'>
                    <span>City/Suburb/Town</span>
                    {renderManualEntryCheckbox()}
                  </span>
                }
                placeholder={
                  watchManualEntry ? 'Enter city/suburb/town' : 'Select city/suburb/town'
                }
                readOnly={readOnly}
                disabled={disabled}
              />
            ) : (
              <FormAutocomplete
                name={`${name}.city`}
                dataKey='id'
                label={
                  <span className='flex justify-between w-full'>
                    <span>City/Suburb/Town</span>
                    {renderManualEntryCheckbox()}
                  </span>
                }
                options={cityOptions}
                placeholder='Select city/suburb/town'
                readOnly={readOnly}
                disabled={disabled}
                blurOnSelect
              />
            )}
          </Grid>
          <Grid className='flex items-end' item xs={12} md={4}>
            <FormInput
              name={`${name}.postcode`}
              label='Postcode'
              placeholder='Enter postcode'
              readOnly={readOnly}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              name={`${name}.streetAddress`}
              label={renderStreetAddressLabel()}
              placeholder={renderStreetAddressPlaceholder()}
              readOnly={readOnly}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddressSection;
