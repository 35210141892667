/* eslint-disable no-restricted-imports */
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { investmentEntityCompanyDefaultValues } from 'src/constants/applications';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { useGetInvestmentEntityById } from 'src/modules/investment-entity/hooks';
import {
  IInvestmentDetailById,
  IUpsertInvestmentEntityForm,
} from 'src/modules/investment-entity/types';
import { INVESTMENT_ENTITY_STEP } from '../../InvestmentEntityForm';
import AddNew from '../AddNew';
import CompanyDetails from './CompanyDetails';

interface ICompanyProps extends IUpsertInvestmentEntityForm {
  investmentEntityDetail?: IInvestmentDetailById;
}

const Company: FC<ICompanyProps> = (props) => {
  const { id = '' } = props;
  const { reset } = useFormContext<IInvestmentEntityForm>();
  const { data: investmentEntity } = useGetInvestmentEntityById(id, {
    step: INVESTMENT_ENTITY_STEP.DETAILS,
  });

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.type)) {
      reset(investmentEntityCompanyDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <AddNew {...props}>
        <CompanyDetails {...props} />
      </AddNew>
    </Box>
  );
};

export default Company;
