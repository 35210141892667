/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import * as React from 'react';
import AddToIcon from 'src/assets/icons/AddToIcon';
import ContactIcon from 'src/assets/icons/ContactIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import ResendIcon from 'src/assets/icons/ResendIcon';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import ActionMenus from 'src/components/molecules/ActionMenus';
import { InvestmentEntityStatuses } from 'src/constants/administration-investor';
import {
  INVESTMENT_ENTITY_ROLE,
  INVESTMENT_ENTITY_ROLE_TEXT,
  TeamType,
} from 'src/constants/applications';
import { ITeamContact } from 'src/modules/applications/type';

enum MENU_KEY {
  VIEW,
  ADD_TO,
  RESEND_INVITE,
  EDIT,
  REMOVE,
}

interface ITeamSelectionBoxProps extends BoxProps {
  title: string;
  numOfSelected?: string;
  users: ITeamContact[];
  tooltip?: string;
  disabled?: boolean;
  onView?: (user?: ITeamContact, teamType?: TeamType) => void;
  onAddTo?: (user?: ITeamContact) => void;
  onRemove?: (user?: ITeamContact, teamType?: TeamType) => void;
  onInvite?: (user?: ITeamContact, isResend?: boolean) => void;
  onEdit?: (user?: ITeamContact, teamType?: TeamType) => void;
  createNewButton?: React.ReactNode;
  invitedTeamIds?: { value: string; isResend: boolean }[];
  teamType: TeamType;
  displayEmpty?: boolean;
  entityTeams?: ITeamContact[];
  isAllowResendInvite?: boolean;
  entityStatus?: number;
  hideRemoveAction?: boolean;
}

const TeamSelectionBox: React.FC<ITeamSelectionBoxProps> = ({
  users,
  numOfSelected,
  title,
  tooltip,
  disabled, // Represent for view investor mode
  createNewButton,
  onView,
  onRemove,
  onAddTo,
  onInvite,
  onEdit,
  invitedTeamIds = [],
  teamType,
  displayEmpty = false,
  entityTeams = [],
  isAllowResendInvite,
  entityStatus,
  hideRemoveAction,
  ...props
}) => {
  const [selectedUser, setSelectedUser] = React.useState<ITeamContact>();
  const isEntityLinked = entityStatus === InvestmentEntityStatuses.Linked;

  const renderTooltip = () => {
    if (tooltip) {
      return (
        <CustomTooltip title={tooltip} placement='right-end'>
          <span className='cursor-pointer ml-[6px]'>
            <InfoIcon />
          </span>
        </CustomTooltip>
      );
    }
  };

  const getActionMenus = (user: ITeamContact) => {
    const isAddFromTeam = entityTeams.map((it) => it?.id).includes(user.id);
    // View mode:
    if (disabled) return menus.filter((item) => item.key === MENU_KEY.VIEW);
    switch (teamType) {
      case TeamType.PrimaryContact:
      case TeamType.CommunicationList:
        return menus.filter((item) => {
          if (isAddFromTeam) {
            return [MENU_KEY.VIEW, MENU_KEY.REMOVE]
              .filter((it) => (hideRemoveAction ? it !== MENU_KEY.REMOVE : true))
              .includes(item.key);
          }
          return [MENU_KEY.VIEW, MENU_KEY.EDIT, MENU_KEY.REMOVE]
            .filter((it) => (hideRemoveAction ? it !== MENU_KEY.REMOVE : true))
            .includes(item.key);
        });
      case TeamType.Team:
        return menus.filter((item) => {
          const isInvited = (invitedTeamIds || []).map((it) => it.value).includes(user?.id || '');

          if (isEntityLinked) return item.key === MENU_KEY.VIEW;
          return [MENU_KEY.VIEW, MENU_KEY.ADD_TO, MENU_KEY.RESEND_INVITE]
            .filter((it) => (isAllowResendInvite && isInvited) || it !== MENU_KEY.RESEND_INVITE)
            .includes(item.key);
        });
      default:
        return menus;
    }
  };

  const renderEmpty = () => {
    return (
      <Box className='text-center py-2'>
        <Typography variant='body3' color='neutral.ne800'>
          No data to display
        </Typography>
      </Box>
    );
  };

  const menus = [
    {
      key: MENU_KEY.VIEW,
      icon: <EyeViewIcon />,
      label: 'View',
      onAction: () => onView?.(selectedUser, teamType),
    },
    {
      key: MENU_KEY.ADD_TO,
      icon: <AddToIcon />,
      label: 'Add To',
      onAction: () => onAddTo?.(selectedUser),
    },
    {
      key: MENU_KEY.RESEND_INVITE,
      icon: <ResendIcon />,
      label: 'Resend Invite',
      onAction: () => onInvite?.(selectedUser, true),
    },
    {
      key: MENU_KEY.EDIT,
      icon: <EditOutlinedIcon />,
      label: 'Edit',
      onAction: () => onEdit?.(selectedUser, teamType),
    },
    {
      key: MENU_KEY.REMOVE,
      icon: <DeleteIcon />,
      label: 'Remove',
      onAction: () => onRemove?.(selectedUser, teamType),
    },
  ];

  return (
    <Box {...props}>
      <Box className='flex items-center justify-between'>
        <Box className='flex items-center'>
          <Typography fontWeight={500} variant='body2'>
            {title}
          </Typography>
          {renderTooltip()}
        </Box>
        <Typography variant='body3' color='neutral.ne800'>
          {numOfSelected}
        </Typography>
      </Box>
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        {!isEmpty(users) && (
          <Grid container spacing={2}>
            {users.map((user: ITeamContact, index: number) => {
              const isInvited = (invitedTeamIds || [])
                .map((it) => it?.value)
                .includes(user?.id || '');
              return (
                <React.Fragment key={`approved-signatoty-${index}`}>
                  <Grid item xs={1} className='flex items-center justify-center'>
                    <Box
                      className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
                      bgcolor='neutral.ne200'
                    >
                      <ContactIcon color='#9DA0A1' height='16px' />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className='flex items-center '>
                      <Typography variant='body3' mr='4px'>
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                      {isInvited && (
                        <Box
                          paddingX='8px'
                          paddingY='2px'
                          bgcolor='secondary.se100'
                          borderRadius='8px'
                          marginLeft={1}
                        >
                          <Typography variant='body3' color='secondary.main' fontWeight='500'>
                            Invited
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Typography variant='body3' color='neutral.ne800'>
                      {user?.userType === INVESTMENT_ENTITY_ROLE.Investor
                        ? INVESTMENT_ENTITY_ROLE_TEXT.INVESTOR
                        : INVESTMENT_ENTITY_ROLE_TEXT.DELEGATE}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className='flex items-center justify-end'>
                    {!isEntityLinked && onInvite && !disabled && (
                      <Box
                        className={clsx('px-[9px] py-[5px] rounded-[4px] mr-[10px]', {
                          'cursor-pointer': !disabled,
                        })}
                        bgcolor='neutral.ne200'
                        onClick={() =>
                          !disabled &&
                          !isInvited &&
                          onInvite({ ...user, isInvited: !user.isInvited })
                        }
                      >
                        <Typography
                          color={disabled || isInvited ? 'neutral.ne400' : 'primary.main'}
                          variant='body3'
                          fontWeight={500}
                        >
                          {isInvited ? 'Invited' : 'Invite'}
                        </Typography>
                      </Box>
                    )}
                    <ActionMenus
                      menus={getActionMenus(user)}
                      onOpen={() => setSelectedUser(user)}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        )}
        {isEmpty(users) && displayEmpty && renderEmpty()}
        {createNewButton}
      </Box>
    </Box>
  );
};

export default TeamSelectionBox;
