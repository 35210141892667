/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IFolderIconProps {}

export const FolderIcon: React.FunctionComponent<IFolderIconProps> = () => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_Navigation_03'
          transform='translate(-23.000000, -244.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='KYC-Menu' transform='translate(11.000000, 232.000000)'>
            <g id='Group' transform='translate(12.000000, 12.000000)'>
              <g id='Group' transform='translate(2.000000, 4.000000)'>
                <path
                  d='M6.207,0 C6.667,0 7.115,0.141 7.491,0.402 L7.647,0.522 L10.022,2.5 L17.75,2.5 C18.8723712,2.49963237 19.8233646,3.32647179 19.979,4.438 L19.995,4.596 L20,4.75 L20,13.75 C20,14.9329775 19.0842033,15.9140318 17.904,15.995 L17.75,16 L2.25,16 C1.06702253,16 0.0859682271,15.0842033 0.005,13.904 L0,13.75 L0,2.25 C0,1.06702253 0.915796691,0.0859682271 2.096,0.005 L2.25,0 L6.207,0 Z M7.647,5.979 C7.29424978,6.27253549 6.8601771,6.45118919 6.403,6.491 L6.207,6.5 L1.5,6.499 L1.5,13.75 C1.5,14.13 1.782,14.444 2.148,14.493 L2.25,14.5 L17.75,14.5 C18.1247858,14.5 18.4420291,14.2233036 18.493,13.852 L18.5,13.75 L18.5,4.75 C18.5,4.37521422 18.2233036,4.05797087 17.852,4.007 L17.75,4 L10.021,4 L7.647,5.979 Z M6.207,1.5 L2.25,1.5 C1.87521422,1.5 1.55797087,1.77669642 1.507,2.148 L1.5,2.25 L1.5,4.999 L6.207,5 C6.34661796,4.99983366 6.48341783,4.96069851 6.602,4.887 L6.687,4.827 L8.578,3.249 L6.688,1.674 C6.5804041,1.58420389 6.4498012,1.52635061 6.311,1.507 L6.207,1.5 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
