import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomAlert from 'src/components/atoms/CustomAlert';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { IFinancialTaxDetailsForm } from 'src/modules/applications/type';
import { ITaxDetailsProps } from '..';

interface ITaxDetailsSectionProps extends ITaxDetailsProps {
  isAnyIndividualContacts?: boolean;
  isForeignTaxResident?: boolean;
  allowForeignInvestors?: boolean;
  individualTaxPersons?: string[];
}

const TaxDetailsSection: React.FC<ITaxDetailsSectionProps> = ({
  isAnyIndividualContacts,
  isForeignTaxResident,
  allowForeignInvestors,
  isViewMode,
  isDisabledEdit,
  individualTaxPersons,
  isInvestmentEntitySetting,
  isExistingInvestmentEntity,
}) => {
  const [isShowWarningMessage, setIsShowWarningMessage] = useState(false);

  const { watch, setError, clearErrors } = useFormContext<IFinancialTaxDetailsForm>();
  const watchTaxResidentOtherCountryThanAustralia = watch('isTaxResidentOtherCountryThanAustralia');

  const isHiddenQuestion =
    (!allowForeignInvestors && !isForeignTaxResident && !isAnyIndividualContacts) ||
    (allowForeignInvestors && isForeignTaxResident);

  useEffect(() => {
    if (!isInvestmentEntitySetting) handleCheckShowWarningMessage();
  }, [
    watchTaxResidentOtherCountryThanAustralia,
    isAnyIndividualContacts,
    isForeignTaxResident,
    allowForeignInvestors,
  ]);

  const handleCheckShowWarningMessage = () => {
    if (
      allowForeignInvestors === false &&
      isForeignTaxResident === false &&
      isAnyIndividualContacts &&
      typeof watchTaxResidentOtherCountryThanAustralia === 'boolean'
    ) {
      setIsShowWarningMessage(true);
      if (watchTaxResidentOtherCountryThanAustralia === true) {
        setError('isTaxResidentOtherCountryThanAustralia', {
          message:
            'This offer is not available to foreign investors. Please contact investor relations for more information.',
          type: 'taxDetailsError',
        });
      } else if (typeof watchTaxResidentOtherCountryThanAustralia === 'boolean') {
        clearErrors('isTaxResidentOtherCountryThanAustralia');
      }
    } else {
      setIsShowWarningMessage(false);
    }
  };

  return (
    <Box mb={3} className='tax-detail'>
      <Typography mb={1} variant='body2' fontWeight={500}>
        Tax Details
      </Typography>
      <Box
        bgcolor='white'
        className='border rounded-xl tax-detail-content'
        borderColor='neutral.ne300'
        p={3}
      >
        <FormInput
          label='Tax File Number'
          name='taxFileNumber'
          placeholder='Enter tax file number'
          tooltip='Providing TFN is not a compulsory requirement. However, in the absence of a TFN, taxes will be withheld from any income at the highest marginal tax rate.'
          tooltipPlacement='right'
          disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
          labelClass='w-auto'
          tooltipSX={{
            maxWidth: 243,
          }}
        />
        {isShowWarningMessage && (
          <CustomAlert sx={{ mt: 2.5 }} severity='info'>
            <Typography variant='body3'>
              The following persons have declared foreign tax residency status. Please review and
              confirm
            </Typography>
            {(individualTaxPersons || []).map((item: string, index: number) => (
              <Typography component='div' variant='body3' key={index}>
                {`${index + 1}. ${item}`}
              </Typography>
            ))}
          </CustomAlert>
        )}
        {(!isHiddenQuestion || isInvestmentEntitySetting || isExistingInvestmentEntity) && (
          <Box mt={2.5}>
            <FormRadioGroup
              label='Does the Entity have any Controlling Persons who are tax residents of countries other than Australia?'
              name='isTaxResidentOtherCountryThanAustralia'
              className='w-full'
              row
              options={TrueFalseRadioOptions}
              isBooleanValue
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaxDetailsSection;
