import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import CustomMultiSelect, { MULTIPLE_SELECT_EMPTY } from 'src/components/atoms/CustomMultiSelect';
import CustomPieChart from 'src/components/atoms/CustomPieChart';
import EmptyData from 'src/components/molecules/EmptyData';
import { useGetClientSummary } from 'src/modules/dashboard/hooks';
import ClientSummaryPercentBar from './ClientSummaryPercentBar';

interface IClientSummaryProps {
  clients: any[];
}

const ClientSummary: FC<IClientSummaryProps> = ({ clients = [] }) => {
  const [selectedClientIds, setSelectedClientIds] = useState<string[] | string>([]);
  const { data: clientSummaryData } = useGetClientSummary(
    Array.isArray(selectedClientIds) ? selectedClientIds : undefined,
  );
  const {
    numberOfActiveFunds = 0,
    numberOfInactiveFunds = 0,
    numberOfActiveOffers = 0,
    numberOfInactiveOffers = 0,
    numberOfActiveInvestors = 0,
    numberOfInactiveInvestors = 0,
    numberOfActiveUsers = 0,
    numberOfInactiveUsers = 0,
    numberOfCompletedApplications = 0,
    numberOfInprogressApplications = 0,
    numberOfCompletedKYCCases = 0,
    numberOfInprogressKYCCases = 0,
  } = clientSummaryData || {};
  const totalFunds = numberOfActiveFunds + numberOfInactiveFunds;
  const totalOffers = numberOfActiveOffers + numberOfInactiveOffers;
  const totalInvestors = numberOfActiveInvestors + numberOfInactiveInvestors;
  const totalUsers = numberOfActiveUsers + numberOfInactiveUsers;
  const totalApplications = numberOfCompletedApplications + numberOfInprogressApplications;
  const totalKYCCases = numberOfCompletedKYCCases + numberOfInprogressKYCCases;

  const summaryData = [
    {
      label: 'Funds',
      active: {
        quantity: numberOfActiveFunds,
        percent: Math.round((numberOfActiveFunds * 100) / totalFunds),
      },
      inActive: {
        quantity: numberOfInactiveFunds,
        percent: 100 - Math.round((numberOfActiveFunds * 100) / totalFunds),
      },
    },
    {
      label: 'Offers',
      active: {
        quantity: numberOfActiveOffers,
        percent: Math.round((numberOfActiveOffers * 100) / totalOffers),
      },
      inActive: {
        quantity: numberOfInactiveOffers,
        percent: 100 - Math.round((numberOfActiveOffers * 100) / totalOffers),
      },
    },
    {
      label: 'Investors',
      active: {
        quantity: numberOfActiveInvestors,
        percent: Math.round((numberOfActiveInvestors * 100) / totalInvestors),
      },
      inActive: {
        quantity: numberOfInactiveInvestors,
        percent: 100 - Math.round((numberOfActiveInvestors * 100) / totalInvestors),
      },
    },
    {
      label: 'Users',
      active: {
        quantity: numberOfActiveUsers,
        percent: Math.round((numberOfActiveUsers * 100) / totalUsers),
      },
      inActive: {
        quantity: numberOfInactiveUsers,
        percent: 100 - Math.round((numberOfActiveUsers * 100) / totalUsers),
      },
    },
  ];

  const clientOptions = clients
    ?.map((it) => ({
      id: it?.id,
      value: it?.id,
      label: it?.name,
    }))
    .sort((a, b) => a.label?.localeCompare(b.label));

  const handleClientChange = (clientIds: string[] | string) => {
    setSelectedClientIds(clientIds);
  };

  return (
    <Box p={4} pb={0} className='bg-white rounded-[14px]'>
      <Box className='summary-header flex items-center justify-between mb-8'>
        <Typography variant='body2' textTransform='capitalize' fontWeight={500}>
          Client Summary
        </Typography>
        <Box className='flex items-center gap-3'>
          <Typography className='inline-block' variant='body3'>
            Client
          </Typography>
          <CustomMultiSelect
            onChange={handleClientChange}
            options={clientOptions}
            label=''
            showClearAllIcon
            inputClassName='!w-[248px] !h-[35px]'
            customSx={{
              borderRadius: '12px',
            }}
          />
        </Box>
      </Box>
      {selectedClientIds === MULTIPLE_SELECT_EMPTY ? (
        <Box pb={4}>
          <EmptyData isTable />
        </Box>
      ) : (
        <Box className='summary-body flex items-center'>
          <Box width='48%'>
            <Box className='flex flex-col gap-4.5' component='ul'>
              {summaryData.map((it, index) => (
                <Box className='flex items-end w-full gap-3' key={index} component='li'>
                  <Typography className='inline-block w-14' variant='body3' color='neutral.ne700'>
                    {it.label}
                  </Typography>
                  <Box className='percent flex items-center gap-1 flex-1'>
                    <ClientSummaryPercentBar
                      isActive
                      quantity={it.active.quantity}
                      percent={it.active.percent}
                    />
                    <ClientSummaryPercentBar
                      isActive={false}
                      quantity={it.inActive.quantity}
                      percent={it.inActive.percent}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className='note flex items-center justify-center gap-3 pt-8'>
              <Box className='flex items-center gap-2'>
                <Box
                  className='w-3 h-3 rounded-full'
                  sx={(theme) => ({
                    bgcolor: theme.palette.secondary.se700,
                  })}
                />
                <Typography variant='caption2' color='neutral.ne800'>
                  Active
                </Typography>
              </Box>
              <Box className='flex items-center gap-2'>
                <Box
                  className='w-3 h-3 rounded-full'
                  sx={(theme) => ({
                    bgcolor: theme.palette.secondary.se300,
                  })}
                />
                <Typography variant='caption2' color='neutral.ne800'>
                  Inactive
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className='flex pl-12 gap-2.5' width='52%'>
            <Box width='50%'>
              <Typography className='pt-3 inline-block' fontWeight={500} variant='body3'>
                Applications
              </Typography>
              <Box>
                <CustomPieChart
                  labels={['Completed', 'In Progress']}
                  data={[
                    {
                      nested: {
                        value: (
                          (numberOfCompletedApplications / totalApplications) *
                          100
                        ).toFixed(),
                        quantity: numberOfCompletedApplications,
                      },
                    },
                    {
                      nested: {
                        value: (
                          (numberOfInprogressApplications / totalApplications) *
                          100
                        ).toFixed(),
                        quantity: numberOfInprogressApplications,
                      },
                    },
                  ]}
                  customColors={['#00965F', '#8FD0B2']}
                  dataLabelsOnChart
                  dataLabelsFormatter={(value: any) => {
                    return value?.nested?.quantity
                      ? `${value?.nested?.quantity} | ${value?.nested?.value}%`
                      : '';
                  }}
                />
              </Box>
            </Box>
            <Box width='50%'>
              <Typography className='pt-3 inline-block' fontWeight={500} variant='body3'>
                KYC Cases
              </Typography>
              <Box>
                <CustomPieChart
                  labels={['Completed', 'In Progress']}
                  data={[
                    {
                      nested: {
                        value: Math.round((numberOfCompletedKYCCases * 100) / totalKYCCases),
                        quantity: numberOfCompletedKYCCases,
                      },
                    },
                    {
                      nested: {
                        value: 100 - Math.round((numberOfCompletedKYCCases * 100) / totalKYCCases),
                        quantity: numberOfInprogressKYCCases,
                      },
                    },
                  ]}
                  customColors={['#00965F', '#8FD0B2']}
                  dataLabelsOnChart
                  dataLabelsFormatter={(value: any) => {
                    return value?.nested?.quantity
                      ? `${value?.nested?.quantity} | ${value?.nested?.value}%`
                      : '';
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ClientSummary;
