import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export interface IUnitPriceTab {
  label: string;
  key: number;
  component: ReactNode;
}

interface IUnitPriceTabsProps {
  tabs: IUnitPriceTab[];
  tabSelected: number;
  setTabSelected: (tab: number) => void;
}

const UnitPriceTabs: FC<IUnitPriceTabsProps> = ({ tabs, tabSelected, setTabSelected }) => {
  return (
    <>
      <Box
        className='tab-item flex items-center mb-4.5 border w-fit rounded-lg'
        borderColor='neutral.ne400'
      >
        {tabs.map((item) => (
          <Typography
            key={item.key}
            className='cursor-pointer capitalize px-2 py-1 text-black border-r last:border-r-0'
            borderColor='neutral.ne400'
            variant='body3'
            fontWeight={tabSelected === item.key ? 500 : 400}
            onClick={() => setTabSelected(item.key)}
          >
            {item.label}
          </Typography>
        ))}
      </Box>
      <Box className='tab-content'>
        <Grid container>
          <Grid item xs={12}>
            {tabs.map((tab) => (
              <div key={tab.key} className={clsx(tab.key !== tabSelected && 'hidden')}>
                {tab.component}
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UnitPriceTabs;
