import { FC } from 'react';
import SMSFDetail from 'src/components/pages/applications/components/InvestmentEntity/SMSF/SMSFDetail';

interface ISMSFProps {
  id: string;
  offerId?: string;
  fundId?: string;
  unitClassId?: string;
  isViewMode?: boolean;
}

const SMSF: FC<ISMSFProps> = (props) => {
  return <SMSFDetail {...props} />;
};

export default SMSF;
