/* eslint-disable no-restricted-imports */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { KYC_BACK_OFFICE_API } from 'src/constants/apiEndpoints';

import { detectPortalType } from 'src/helpers/common';
import { IIndividualDetail, ISkycGetApp, ITaxFilter } from 'src/modules/applications/type';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import { useGetTaxDetails } from '../applications/hooks';
import {
  addNewUbo,
  billingCloseCase,
  billingNoCharge,
  closeKycBackOffice,
  downloadApplicationForm,
  getGapKycBoTaxDetails,
  getInvestorDetailAuditTrails,
  getKYCBackOfficeAssignees,
  getKYCBackOfficeAssigneesById,
  getKYCBackOfficeById,
  getKYCBackOfficeFilter,
  getKYCBackOfficeList,
  getKYCBackOfficeMasterData,
  getKYCBackOfficeNote,
  getKycBoSkycApp,
  getUboAuditTrails,
  kycBackOfficeAssign,
  kycBackOfficeCompletedExternally,
  kycBackOfficeNote,
  kycBackOfficeReviewSubmit,
  kycBackOfficeReviewSubmitAdditionalComment,
  kycRequestMoreInformation,
  manuallyOverrideAml,
  manuallyOverrideKyc,
  openKycBackOffice,
  removeUbo,
  submitBilling,
} from './services';
import {
  ICompletedExternally,
  IKYCBackOffice,
  IKYCBackOfficeAssignees,
  IKYCBackOfficeById,
  IKYCBackOfficeNote,
  INote,
  IRequestMoreInformation,
  IReview,
  IReviewAdditional,
  ISubmitBilling,
  KYCBackOfficeFilter,
  KYCBackOfficeQueryBody,
} from './type';

export const useGetKYCBackOfficeList = () => {
  const [queryBody, setQueryBody] = useState<KYCBackOfficeQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<IKYCBackOffice>
  >([KYC_BACK_OFFICE_API.getKYCBackOfficeList.api, queryBody], () =>
    getKYCBackOfficeList(queryBody),
  );

  const setParams = (newParams: KYCBackOfficeQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetKYCBackOfficeFilters = () => {
  const { data = { statuses: [] }, ...rest } = useQuery<KYCBackOfficeFilter>(
    [KYC_BACK_OFFICE_API.getKYCBackOfficeFilter.api],
    getKYCBackOfficeFilter,
  );

  return {
    data,
    ...rest,
  };
};

export const useGetKYCBackOfficeById = (id: string, step: number) => {
  return useQuery<IKYCBackOfficeById>(
    [KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id), id, step],
    () => getKYCBackOfficeById(id, step),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetKYCBackOfficeAssignees = () => {
  return useQuery<IKYCBackOfficeAssignees[]>([KYC_BACK_OFFICE_API.getKYCBackOfficeAssignees], () =>
    getKYCBackOfficeAssignees(),
  );
};

export const useGetKYCBackOfficeAssigneesById = (kycId: string) => {
  return useQuery<IKYCBackOfficeAssignees[]>(
    [KYC_BACK_OFFICE_API.getKYCBackOfficeAssigneesById.api(kycId), kycId],
    () => getKYCBackOfficeAssigneesById(kycId),
  );
};

export const useOpenKYCBackOffice = () => {
  const queryClient = useQueryClient();
  const resp = async (kycId: string) => {
    await openKycBackOffice(kycId);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeList.api]);
  };
  return useMutation(resp);
};

export const useCloseKYCBackOffice = () => {
  const queryClient = useQueryClient();
  const resp = async (kycId: string) => {
    await closeKycBackOffice(kycId);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeList.api]);
  };
  return useMutation(resp);
};

export const useKYCBackOfficeAssign = () => {
  const resp = async ({ kycId, memberId }: { kycId: string; memberId: string }) => {
    await kycBackOfficeAssign(kycId, memberId);
  };
  return useMutation(resp);
};

export const useManuallyOverrideKyc = () => {
  const queryClient = useQueryClient();
  const resp = async ({
    id,
    uboId,
    mannualReason,
  }: {
    id: string;
    uboId: string;
    mannualReason: string;
  }) => {
    await manuallyOverrideKyc(id, uboId, mannualReason);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id)]);
  };
  return useMutation(resp);
};

export const useManuallyOverrideAml = () => {
  const queryClient = useQueryClient();
  const resp = async ({
    id,
    uboId,
    mannualReason,
  }: {
    id: string;
    uboId: string;
    mannualReason: string;
  }) => {
    await manuallyOverrideAml(id, uboId, mannualReason);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id)]);
  };
  return useMutation(resp);
};

export const useAddNewUbo = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id: string; data: IIndividualDetail }) => {
    await addNewUbo(id, data);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id)]);
  };
  return useMutation(resp);
};

export const useRemoveUbo = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, uboId }: { id: string; uboId: string }) => {
    await removeUbo(id, uboId);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id)]);
  };
  return useMutation(resp);
};

export const useKYCBackReviewSubmit = () => {
  const resp = async ({ kycId, data }: { kycId: string; data: IReview }) => {
    await kycBackOfficeReviewSubmit(kycId, data);
  };
  return useMutation(resp);
};

export const useKYCBackReviewSubmitAdditionalComment = () => {
  const resp = async ({ kycId, data }: { kycId: string; data: IReviewAdditional }) => {
    await kycBackOfficeReviewSubmitAdditionalComment(kycId, data);
  };
  return useMutation(resp);
};

export const useKYCBackOfficeCompletedExternally = () => {
  const resp = async ({ kycId, data }: { kycId: string; data: ICompletedExternally }) => {
    await kycBackOfficeCompletedExternally(kycId, data);
  };
  return useMutation(resp);
};

export const useKYCBackOfficeNote = () => {
  const resp = async ({ kycId, data }: { kycId: string; data: INote }) => {
    await kycBackOfficeNote(kycId, data);
  };
  return useMutation(resp);
};

export const useGetKYCBackOfficeNote = (kycId: string) => {
  return useQuery<IKYCBackOfficeNote>([KYC_BACK_OFFICE_API.getKYCBackOfficeNote.api(kycId)], () =>
    getKYCBackOfficeNote(kycId),
  );
};

export const useKYCRequestMoreInformation = () => {
  const resp = async ({
    applicationId,
    data,
  }: {
    applicationId: string;
    data: IRequestMoreInformation;
  }) => {
    await kycRequestMoreInformation(applicationId, data);
  };
  return useMutation(resp);
};

export const useDownloadApplicationForm = () => {
  const resp = async ({ kycId, fileName }: { kycId: string; fileName: string }) => {
    const data = await downloadApplicationForm(kycId);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};

export const useGetKYCBackOfficeMasterData = () => {
  return useQuery([KYC_BACK_OFFICE_API.getMasterData.api], () => getKYCBackOfficeMasterData());
};

export const useSubmitBilling = () => {
  const queryClient = useQueryClient();
  const resp = async ({ kycId, data }: { kycId: string; data: ISubmitBilling }) => {
    await submitBilling(kycId, data);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(kycId)]);
  };
  return useMutation(resp);
};

export const useBillingCloseCase = () => {
  const queryClient = useQueryClient();
  const resp = async ({ kycId, data }: { kycId: string; data: ISubmitBilling }) => {
    await billingCloseCase(kycId, data);
    await queryClient.invalidateQueries([KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(kycId)]);
  };
  return useMutation(resp);
};

export const useBillingNoCharge = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, isClosed }: { id: string; isClosed: boolean }) => {
    await billingNoCharge(id, isClosed);
    await queryClient.invalidateQueries([
      isClosed
        ? KYC_BACK_OFFICE_API.getKYCBackOfficeList.api
        : KYC_BACK_OFFICE_API.getKYCBackOfficeById.api(id),
    ]);
  };
  return useMutation(resp);
};

export const useGetInvestorDetailAuditTrails = (kycId: string, enabled = true) => {
  return useQuery(
    [KYC_BACK_OFFICE_API.investorDetailAuditTrails.api, kycId],
    () => getInvestorDetailAuditTrails(kycId),
    {
      enabled: !!kycId && enabled,
    },
  );
};

export const useGetUboAuditTrails = (kycId: string, enabled = true) => {
  return useQuery([KYC_BACK_OFFICE_API.uboAuditTrails.api, kycId], () => getUboAuditTrails(kycId), {
    enabled: !!kycId && enabled,
  });
};

export const useGetKycBoTaxDetails = (appId: string) => {
  const { isAdmin } = detectPortalType();

  if (!isAdmin) return useGetTaxDetails(appId);

  return useQuery<ITaxFilter>(
    [KYC_BACK_OFFICE_API.getTaxDetails.api(appId), appId],
    () => getGapKycBoTaxDetails(appId),
    {
      enabled: !!appId,
    },
  );
};

export const useGetKycBoSkycApp = () => {
  const resp = async (skycAppId: string): Promise<ISkycGetApp> => {
    return await getKycBoSkycApp(skycAppId);
  };
  return useMutation(resp, {
    retry: 15,
    retryDelay: 8000,
  });
};
