/* eslint-disable no-restricted-imports */
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, FC, createElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SearchIcon } from 'src/assets/icons/SearchIcon';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import FormArrayLayout from 'src/components/atoms/FormArrayLayout';
import FormAutocomplete, { OptionType } from 'src/components/atoms/FormAutocomplete';
import FormInput from 'src/components/atoms/FormInput';
import FormSelect from 'src/components/atoms/FormSelect';
import {
  GOVERNMENT_BODY_TYPE,
  INVESTMENT_ENTITY_TYPE,
  UBOS_META,
  abnAcnNotAllowedStatus,
  governmentBodyTypeOptions,
  individualDefaultValue,
  stateOfTerritoryOptions,
} from 'src/constants/applications';
import { showToast } from 'src/helpers/toast';
import { ICountry } from 'src/interfaces/common';
import { useSearchSkyc } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm, ISkyc } from 'src/modules/applications/type';
import { useGetCountries } from 'src/modules/common/hooks';
import { handleCheckEntityTypeMatch } from 'src/utils/applications';
import { formatValueWithSpaces } from 'src/utils/common';
import { IInvestmentEntityProps } from '..';
import IndividualDetail from '../shared/IndividualDetail';
import PublicOfficersFormItem from './PublicOfficersFormItem';

interface IGovernmentBodyProps extends IInvestmentEntityProps {
  disabledAbn?: boolean;
  hideUBODeclaration?: boolean;
}

const GovernmentBody: FC<IGovernmentBodyProps> = (props) => {
  const {
    isViewMode,
    isDisabledEdit,
    isExistingInvestmentEntity,
    hideUbo,
    hideAbnSearch,
    isInvestmentEntitySetting,
    disabledAbn,
    hideUBODeclaration,
  } = props;
  const { data: countries = [] } = useGetCountries();
  const { watch, control, getFieldState, setValue, setError, clearErrors } =
    useFormContext<IInvestmentEntityForm>();
  const {
    fields: publicOfficers,
    append: addPublicOfficers,
    remove: removePublicOfficers,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.governmentBody.publicOfficers',
    keyName: 'key',
  });
  const {
    fields: ubOs,
    append: addUbOs,
    remove: removeUbOs,
  } = useFieldArray({
    control,
    name: 'investmentEntityDetails.governmentBody.ubOs',
    keyName: 'key',
  });

  const { error: publicOfficersError } = getFieldState(
    `investmentEntityDetails.governmentBody.publicOfficers`,
  );
  const watchGovernmentBodyType = watch('investmentEntityDetails.governmentBody.type');
  const watchCountryId = watch('investmentEntityDetails.governmentBody.countryId');

  const {
    data: { items: skycData },
    isLoading: isSearchSkycLoading,
    setParams,
  } = useSearchSkyc({
    entityType: INVESTMENT_ENTITY_TYPE.Other,
  });

  const countryIdFlagOptions = countries.map((it: ICountry) => ({
    label: createElement(
      Box,
      { className: 'flex flex-row gap-2' },
      createElement('span', { className: `fi fi-${it.code.toLowerCase()}` }),
      createElement(Typography, { variant: 'body3', className: 'ml-2' }, it.name),
    ),
    value: String(it.id),
  }));

  const handleAddPublicOfficers = () => {
    addPublicOfficers(individualDefaultValue(true));
  };

  const handleAddUbOs = () => {
    addUbOs(individualDefaultValue());
  };

  const handleDeletePublicOfficer = (index: number) => {
    removePublicOfficers(index);
  };

  const handleDeleteUbOs = (index: number) => {
    removeUbOs(index);
  };

  const handleSelectStateId = (value: number | string) => {
    const state = stateOfTerritoryOptions.find((it) => it.value === value);
    setValue('investmentEntityDetails.governmentBody.govermentBodyStateName', state?.label || '');
  };

  const handleSelectType = (value: number | string) => {
    if (value !== GOVERNMENT_BODY_TYPE.Foreign) {
      removeUbOs();
    }
  };

  const handleSelectCountryId = (id: string | number) => {
    const country = getCountryById(id);

    if (country) {
      setValue('investmentEntityDetails.governmentBody.countryName', country?.name || '');
    }
  };

  const getCountryById = (id: number | string) => {
    return countries.find((item: any) => item.id === +id);
  };

  const getOfficerOriginalIndex = (refId: string) => {
    return publicOfficers.findIndex((it) => it.refId === refId);
  };

  const handleSearchSkycInputChange = debounce(
    (event: ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear')
        setParams({
          search: value,
        });
    },
    500,
  );

  const handleSkycChange = (
    event: ChangeEvent<unknown>,
    data: OptionType[],
    reason: AutocompleteChangeReason,
  ) => {
    const skyc = Array.isArray(data) ? data[0] : data;
    if (reason === 'selectOption') {
      const isEntityTypeMatch = handleCheckEntityTypeMatch(
        skyc as OptionType & ISkyc,
        'governmentBody',
      );
      const isNotAllowed = abnAcnNotAllowedStatus.ABR.includes(skyc?.status?.value || '');

      if (isEntityTypeMatch) {
        setValue('investmentEntityDetails.governmentBody.abn', skyc.value);
        setValue('investmentEntityDetails.governmentBody.nameOfGovernmentBody', skyc.label);
        if (isNotAllowed) {
          setError('investmentEntityDetails.governmentBody.abn', {
            message: 'ABN is invalid',
            type: 'invalidAbn',
          });
        }
      } else {
        showToast('Entity Type does not match.', 'error');
      }
    }
  };

  const skycOptions = skycData.map((item) => ({
    id: item.searchId,
    label: item.entityName,
    value: item.bn,
    ...item,
  }));

  const disabled = isExistingInvestmentEntity || isViewMode || isDisabledEdit;

  return (
    <Box>
      <Grid container rowSpacing='18px' columnSpacing='20px'>
        {!isExistingInvestmentEntity && !hideAbnSearch && !isInvestmentEntitySetting && (
          <Grid xs={12} item>
            <FormAutocomplete
              name='skyc'
              dataKey='id'
              options={skycOptions}
              searchIcon={<SearchIcon />}
              isLoading={isSearchSkycLoading}
              placeholder='Search by Business Name, ABN'
              hidePopupIcon
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                  <Box className='flex flex-col'>
                    <Typography variant='body3' fontWeight='medium'>
                      {option.label}
                    </Typography>
                    <Box className='flex items-center pt-1'>
                      <Typography variant='caption1' color='neutral.ne500' paddingRight={3}>
                        {`ABN: ${option.value}`}
                      </Typography>
                    </Box>
                  </Box>
                </li>
              )}
              filterOptions={(options) => options}
              getOptionLabel={() => ''}
              onInputChange={handleSearchSkycInputChange}
              onChange={handleSkycChange}
              onFocus={() => clearErrors('investmentEntityDetails.governmentBody.abn')}
              disabled={disabled}
              blurOnSelect
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.governmentBody.nameOfGovernmentBody'
            label='Name of Government Body'
            placeholder='Enter name of government body'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='investmentEntityDetails.governmentBody.abn'
            label='ABN'
            placeholder='Enter ABN'
            formatValue={(value) => formatValueWithSpaces(value)}
            maxLength={14}
            disabled={disabled || disabledAbn}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name='investmentEntityDetails.governmentBody.principlePlaceOfOperation'
            label='Principal Place of Operation'
            placeholder='Enter principal place of operation'
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            name='investmentEntityDetails.governmentBody.type'
            label='Type'
            placeholder='Select type'
            options={governmentBodyTypeOptions}
            onSelectValue={handleSelectType}
            disabled={disabled}
          />
        </Grid>
        {watchGovernmentBodyType === GOVERNMENT_BODY_TYPE.AustralianStateOrTerritory && (
          <Grid item xs={6}>
            <FormSelect
              name='investmentEntityDetails.governmentBody.govermentBodyStateId'
              label='State or Territory'
              placeholder='Select state or territory'
              options={stateOfTerritoryOptions}
              onSelectValue={handleSelectStateId}
              disabled={disabled}
            />
          </Grid>
        )}
        {watchGovernmentBodyType === GOVERNMENT_BODY_TYPE.Foreign && (
          <Grid item xs={6}>
            <FormSelect
              name='investmentEntityDetails.governmentBody.countryId'
              options={countryIdFlagOptions}
              label='Country'
              placeholder='Select country'
              disabled={isViewMode || isDisabledEdit}
              onSelectValue={handleSelectCountryId}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 3 }} />
      <FormArrayLayout
        {...props}
        title='Public Officers'
        onAddItem={handleAddPublicOfficers}
        disabled={disabled}
        helperText={<CustomHelperText variant='error' message={publicOfficersError?.message} />}
      >
        <Grid container rowSpacing='16px' mb='16px'>
          {publicOfficers.map((it, index) => (
            <Grid key={it.key} item xs={12}>
              <PublicOfficersFormItem
                {...props}
                keyName={`investmentEntityDetails.governmentBody.publicOfficers.${index}`}
                onDelete={() => handleDeletePublicOfficer(index)}
              />
            </Grid>
          ))}
        </Grid>
      </FormArrayLayout>
      {watchGovernmentBodyType === GOVERNMENT_BODY_TYPE.Foreign && !hideUbo && (
        <>
          <Divider sx={{ my: 3 }} />
          <FormArrayLayout
            {...props}
            title='Ultimate Beneficial Owners'
            description={UBOS_META.COMMON.DESCRIPTION}
            tooltip={UBOS_META.COMMON.TOOLTIP}
            onAddItem={handleAddUbOs}
            disabled={disabled}
          >
            <Grid container rowSpacing='16px'>
              {publicOfficers
                .filter((it) => !!it.refId)
                .map((it) => (
                  <Grid key={it.key} item xs={12}>
                    <IndividualDetail
                      {...props}
                      keyName={`investmentEntityDetails.governmentBody.publicOfficers.${getOfficerOriginalIndex(
                        it.refId || '',
                      )}`}
                      hasPosition
                      hideTfn
                      disabledBasicInfoFields={[
                        'firstName',
                        'lastName',
                        'middleName',
                        'contactNumber',
                        'email',
                        'position',
                      ]}
                      autocompleteAddress
                      hideDeclaration={hideUBODeclaration}
                    />
                  </Grid>
                ))}
              {ubOs.map((it, index) => (
                <Grid key={it.key} item xs={12}>
                  <IndividualDetail
                    {...props}
                    keyName={`investmentEntityDetails.governmentBody.ubOs.${index}`}
                    handleDelete={() => handleDeleteUbOs(index)}
                    hasPosition
                    canDelete={true}
                    hideTfn
                    autocompleteAddress
                    hideDeclaration={hideUBODeclaration}
                  />
                </Grid>
              ))}
            </Grid>
          </FormArrayLayout>
        </>
      )}
    </Box>
  );
};

export default GovernmentBody;
