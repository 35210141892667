import { isEmpty, uniqWith } from 'lodash';
import { investorCompanyFlagDetailConfig } from 'src/constants/applications';
import { IFlagsToInvestigate } from 'src/modules/applications/type';

export const transformCompanyFlagSections = (flagsToInvestigate: IFlagsToInvestigate[]) => {
  if (isEmpty(flagsToInvestigate)) return [];
  const flagsToRender = flagsToInvestigate
    .filter((flag) => {
      const flagOption = investorCompanyFlagDetailConfig[flag.descriptor || ''];
      const isCompanyFlag = Object.keys(investorCompanyFlagDetailConfig).includes(
        flag.descriptor || '',
      );
      if (flagOption?.isAllowAll) return isCompanyFlag;
      if (flagOption?.allowedPhrases) {
        return (
          isCompanyFlag &&
          flagOption.allowedPhrases.some((it) => (flag.flagDetail || '')?.includes(it))
        );
      }
      if (flagOption?.notAllowedPhrases) {
        return (
          isCompanyFlag &&
          !flagOption.notAllowedPhrases.some((it) => (flag.flagDetail || '')?.includes(it))
        );
      }
      return isCompanyFlag;
    })
    .map((flag) => {
      const flagOption = investorCompanyFlagDetailConfig[flag.descriptor || ''];
      if (flagOption?.replaceMessage)
        return {
          ...flag,
          message: flagOption.replaceMessage,
        };
      return {
        ...flag,
        message: flag.flagDetail,
      };
    });
  return uniqWith(flagsToRender, (arrVal, othVal) => arrVal.message === othVal.message);
};
