import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { INVESTOR_FORM_API } from 'src/constants/apiEndpoints';
import { DEFAULT_PAGINATED, DEFAULT_QUERIES } from 'src/modules/common/hooks';
import { Paginated } from 'src/modules/common/type';
import { downloadFileFromArrayBuffer, removeEmptyProps } from 'src/utils/common';
import {
  deleteInvestorForm,
  downloadInvestorFormFile,
  downloadInvestorPDF,
  getInvestorFormById,
  getInvestorFormId,
  getInvestorFormList,
  getInvestorFormMasterData,
  getInvestorFormPopulateData,
  submitInvestorForm,
  upsertChangeDetail,
  upsertRedemptionRequest,
  upsertTransferRequest,
} from './services';
import {
  IChangeDetailsForm,
  IGetRedemptionQueryParams,
  IInvestorFormItem,
  IRedemptionFormFields,
  ITransferForm,
  InvestorFormQueryBody,
} from './type';

export const useGetInvestorFormList = () => {
  const [queryBody, setQueryBody] = useState<InvestorFormQueryBody>(() => ({
    ...DEFAULT_QUERIES,
  }));

  const { data = { items: [], metadata: DEFAULT_PAGINATED }, ...rest } = useQuery<
    Paginated<IInvestorFormItem>
  >([INVESTOR_FORM_API.getInvestorFormList.api, queryBody], () => getInvestorFormList(queryBody));

  const setParams = (newParams: InvestorFormQueryBody) => {
    const mergedParams = { ...queryBody, ...newParams };
    const formatParams = removeEmptyProps(mergedParams);
    setQueryBody(formatParams);
  };

  return {
    data,
    params: queryBody,
    setParams,
    ...rest,
  };
};

export const useGetInvestorFormId = () => {
  return useMutation(getInvestorFormId);
};

export const useGetInvestorFormMasterData = () => {
  return useQuery([INVESTOR_FORM_API.getInvestorFormMasterData.api], getInvestorFormMasterData);
};

export const useGetInvestorFormPopulateData = (params: IGetRedemptionQueryParams) => {
  return useQuery(
    [INVESTOR_FORM_API.getInvestorFormPopulateData.api, params],
    () => getInvestorFormPopulateData(params),
    {
      enabled: !!params?.unitClassId,
    },
  );
};

export const useUpsertRedemptionRequest = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id?: string; data: IRedemptionFormFields }) => {
    const res = await upsertRedemptionRequest({ id, data });
    await queryClient.invalidateQueries([INVESTOR_FORM_API.getInvestorFormList.api]);

    return res;
  };
  return useMutation(resp);
};

export const useUpsertTransferRequest = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id?: string; data: ITransferForm }) => {
    const res = await upsertTransferRequest({ id, data });
    await queryClient.invalidateQueries([INVESTOR_FORM_API.getInvestorFormList.api]);

    return res;
  };
  return useMutation(resp);
};

export const useUpsertChangeDetail = () => {
  const queryClient = useQueryClient();
  const resp = async ({ id, data }: { id?: string; data: IChangeDetailsForm }) => {
    const res = await upsertChangeDetail({ id, data });
    await queryClient.invalidateQueries([INVESTOR_FORM_API.getInvestorFormList.api]);

    return res;
  };
  return useMutation(resp);
};

export const useSubmitInvestorForm = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await submitInvestorForm(id);
    await queryClient.invalidateQueries([INVESTOR_FORM_API.getInvestorFormList.api]);
  };
  return useMutation(resp);
};

export const useDownloadInvestorPDF = () => {
  const resp = async ({ id, fileName }: { id: string; fileName: string }) => {
    const data = await downloadInvestorPDF(id);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};

export const useGetInvestorFormById = (id: string) => {
  return useQuery(
    [INVESTOR_FORM_API.getInvestorFormById.api(id), id],
    () => getInvestorFormById(id),
    {
      enabled: !!id,
    },
  );
};

export const useDeleteInvestorForm = () => {
  const queryClient = useQueryClient();
  const resp = async (id: string) => {
    await deleteInvestorForm(id);
    await queryClient.invalidateQueries([INVESTOR_FORM_API.getInvestorFormList.api]);
  };
  return useMutation(resp);
};

export const useDownloadInvestorFormFile = () => {
  const resp = async ({ id, fileName }: { id: string; fileName: string }) => {
    const data = await downloadInvestorFormFile(id);
    downloadFileFromArrayBuffer(data, fileName);
  };
  return useMutation(resp);
};
