import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FUNDS_API, UNIT_CLASS_API } from 'src/constants/apiEndpoints';
import { detectPortalType } from 'src/helpers/common';
import {
  duplicateUnitClassById,
  getCurrencies,
  getDuplicateUnitClassById,
  getUnitClassById,
  upsertFinancialDetail,
  upsertInvestmentDetail,
  upsertUnitClass,
  upsertUnitClassDetail,
  upsertUnitClassInvestorRelations,
} from './services';
import {
  IUnitClassById,
  IUnitClassInvestorRelationsParams,
  IUpsertFinancialDetailParams,
  IUpsertInvestmentDetailsParams,
  IUpsertUnitClassDetailParams,
} from './type';

export const useGetCurrencies = () => {
  const { isFunds } = detectPortalType();
  return useQuery([UNIT_CLASS_API.currencies.api], getCurrencies, {
    enabled: isFunds, // Note: This API is applied for Fund Portal only. Need a new one if using in other portak
  });
};

export const useUpsertUnitClassDetail = () => {
  const queryClient = useQueryClient();
  const resp = async (params: IUpsertUnitClassDetailParams) => {
    const res = await upsertUnitClassDetail(params);
    await queryClient.invalidateQueries([FUNDS_API.getUnitClassList.api(params.fundId || '')]);
    return res;
  };
  return useMutation(resp);
};

export const useUpsertFinancialDetail = () => {
  const resp = async (params: IUpsertFinancialDetailParams) => {
    return await upsertFinancialDetail(params);
  };
  return useMutation(resp);
};

export const useGetUnitClassById = (id: string, step: number) => {
  return useQuery(
    [UNIT_CLASS_API.getUnitClassById.api(id), id, step],
    () => getUnitClassById(id, String(step)),
    {
      enabled: !!id,
    },
  );
};

export const useUpsertInvestmentDetail = () => {
  const resp = async (params: IUpsertInvestmentDetailsParams) => {
    return await upsertInvestmentDetail(params);
  };

  return useMutation(resp);
};
export const useGetDuplicateUnitClassById = (id: string) => {
  return useQuery(
    [UNIT_CLASS_API.duplicateUnitClassById.api(id), id],
    () => getDuplicateUnitClassById(id),
    {
      enabled: !!id,
    },
  );
};

export const useDuplicateUnitClassById = (id: string) => {
  const resp = async (params: IUnitClassById) => {
    return await duplicateUnitClassById(id, params);
  };
  return useMutation(resp);
};

export const useUpsertUnitClass = () => {
  const resp = async (id: string) => {
    return await upsertUnitClass(id);
  };
  return useMutation(resp);
};

export const useUpsertUnitClassInvestorRelations = () => {
  const resp = async ({
    params,
    unitClassId,
  }: {
    params: IUnitClassInvestorRelationsParams;
    unitClassId: string;
  }) => {
    return await upsertUnitClassInvestorRelations(params, unitClassId);
  };
  return useMutation(resp);
};
