/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IConnectIDVerifiedIconProps {}

export const ConnectIDVerifiedIcon: React.FunctionComponent<IConnectIDVerifiedIconProps> = () => {
  return (
    <svg
      width='147px'
      height='27px'
      viewBox='0 0 147 27'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>01</title>
      <g id='KYC-Verification' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_KYC-Verification_09'
          transform='translate(-798, -630)'
        >
          <g id='01' transform='translate(798, 630)'>
            <g id='01.Design-Token/02.Background/03.Soild/12' fill='#E9E9E9'>
              <rect id='Background' x='0' y='0' width='147' height='27' rx='13.5'></rect>
            </g>
            <g
              id='Verified-with-Connec'
              transform='translate(30.127, 9.8369)'
              fill='#1C1C1C'
              fillRule='nonzero'
            >
              <polygon
                id='Path'
                points='2.50976562 7.16308594 3.71582031 7.16308594 6.22558594 0.1171875 5.04882812 0.1171875 3.125 5.94726562 3.10058594 5.94726562 1.17675781 0.1171875 0 0.1171875'
              ></polygon>
              <path
                d='M8.50878906,7.265625 C9.75390625,7.265625 10.5449219,6.5234375 10.7109375,5.70800781 L10.7207031,5.65429688 L9.71972656,5.65429688 L9.70507812,5.69335938 C9.57324219,6.07910156 9.15820312,6.40136719 8.53320312,6.40136719 C7.69335938,6.40136719 7.16113281,5.83496094 7.14160156,4.86328125 L10.7792969,4.86328125 L10.7792969,4.50683594 C10.7792969,2.96386719 9.89550781,1.92871094 8.45507812,1.92871094 C7.01464844,1.92871094 6.08691406,3.00292969 6.08691406,4.609375 L6.08691406,4.61425781 C6.08691406,6.23535156 7,7.265625 8.50878906,7.265625 Z M8.45996094,2.79296875 C9.1484375,2.79296875 9.64648438,3.22753906 9.734375,4.11621094 L7.15625,4.11621094 C7.24902344,3.25683594 7.77636719,2.79296875 8.45996094,2.79296875 Z'
                id='Shape'
              ></path>
              <path
                d='M11.7197266,7.16308594 L12.7744141,7.16308594 L12.7744141,4.1015625 C12.7744141,3.33496094 13.1943359,2.890625 13.8730469,2.890625 C14.078125,2.890625 14.2636719,2.91503906 14.3466797,2.94921875 L14.3466797,1.96777344 C14.2636719,1.953125 14.1318359,1.92871094 13.9804688,1.92871094 C13.3945312,1.92871094 12.9648438,2.3046875 12.7988281,2.90527344 L12.7744141,2.90527344 L12.7744141,2.02636719 L11.7197266,2.02636719 L11.7197266,7.16308594 Z'
                id='Path'
              ></path>
              <path
                d='M15.6972656,1.23535156 C16.0439453,1.23535156 16.3125,0.961914062 16.3125,0.629882812 C16.3125,0.29296875 16.0439453,0.01953125 15.6972656,0.01953125 C15.3554688,0.01953125 15.0820312,0.29296875 15.0820312,0.629882812 C15.0820312,0.961914062 15.3554688,1.23535156 15.6972656,1.23535156 Z M15.1699219,7.16308594 L16.2246094,7.16308594 L16.2246094,2.02636719 L15.1699219,2.02636719 L15.1699219,7.16308594 Z'
                id='Shape'
              ></path>
              <path
                d='M17.7460938,7.16308594 L18.8056641,7.16308594 L18.8056641,2.86621094 L19.8310547,2.86621094 L19.8310547,2.02636719 L18.7861328,2.02636719 L18.7861328,1.49902344 C18.7861328,1.04492188 19.0107422,0.815429688 19.484375,0.815429688 C19.6162109,0.815429688 19.7480469,0.825195312 19.8505859,0.83984375 L19.8505859,0.05859375 C19.7089844,0.0341796875 19.4746094,0.0146484375 19.2597656,0.0146484375 C18.2148438,0.0146484375 17.7460938,0.483398438 17.7460938,1.47949219 L17.7460938,2.02636719 L17.0380859,2.02636719 L17.0380859,2.86621094 L17.7460938,2.86621094 L17.7460938,7.16308594 Z'
                id='Path'
              ></path>
              <path
                d='M21.3574219,1.23535156 C21.7041016,1.23535156 21.9726562,0.961914062 21.9726562,0.629882812 C21.9726562,0.29296875 21.7041016,0.01953125 21.3574219,0.01953125 C21.015625,0.01953125 20.7421875,0.29296875 20.7421875,0.629882812 C20.7421875,0.961914062 21.015625,1.23535156 21.3574219,1.23535156 Z M20.8300781,7.16308594 L21.8847656,7.16308594 L21.8847656,2.02636719 L20.8300781,2.02636719 L20.8300781,7.16308594 Z'
                id='Shape'
              ></path>
              <path
                d='M25.2910156,7.265625 C26.5361328,7.265625 27.3271484,6.5234375 27.4931641,5.70800781 L27.5029297,5.65429688 L26.5019531,5.65429688 L26.4873047,5.69335938 C26.3554688,6.07910156 25.9404297,6.40136719 25.3154297,6.40136719 C24.4755859,6.40136719 23.9433594,5.83496094 23.9238281,4.86328125 L27.5615234,4.86328125 L27.5615234,4.50683594 C27.5615234,2.96386719 26.6777344,1.92871094 25.2373047,1.92871094 C23.796875,1.92871094 22.8691406,3.00292969 22.8691406,4.609375 L22.8691406,4.61425781 C22.8691406,6.23535156 23.7822266,7.265625 25.2910156,7.265625 Z M25.2421875,2.79296875 C25.9306641,2.79296875 26.4287109,3.22753906 26.5166016,4.11621094 L23.9384766,4.11621094 C24.03125,3.25683594 24.5585938,2.79296875 25.2421875,2.79296875 Z'
                id='Shape'
              ></path>
              <path
                d='M30.4208984,7.265625 C31.1582031,7.265625 31.7246094,6.89453125 32.0322266,6.29394531 L32.0517578,6.29394531 L32.0517578,7.16308594 L33.1113281,7.16308594 L33.1113281,0.1171875 L32.0517578,0.1171875 L32.0517578,2.90039062 L32.0322266,2.90039062 C31.7246094,2.29492188 31.1386719,1.92871094 30.4111328,1.92871094 C29.1220703,1.92871094 28.2724609,2.95410156 28.2724609,4.58984375 L28.2724609,4.59472656 C28.2724609,6.23046875 29.1123047,7.265625 30.4208984,7.265625 Z M30.6992188,6.35742188 C29.8642578,6.35742188 29.3515625,5.68847656 29.3515625,4.59472656 L29.3515625,4.58984375 C29.3515625,3.50585938 29.8691406,2.83203125 30.6992188,2.83203125 C31.4902344,2.83203125 32.0566406,3.52539062 32.0566406,4.58984375 L32.0566406,4.59472656 C32.0566406,5.6640625 31.4951172,6.35742188 30.6992188,6.35742188 Z'
                id='Shape'
              ></path>
              <polygon
                id='Path'
                points='37.4775391 7.16308594 38.5664062 7.16308594 39.6357422 3.31542969 39.6601562 3.31542969 40.7392578 7.16308594 41.8378906 7.16308594 43.2441406 2.02636719 42.1992188 2.02636719 41.2617188 6.06933594 41.2373047 6.06933594 40.1630859 2.02636719 39.1474609 2.02636719 38.078125 6.06933594 38.0585938 6.06933594 37.1210938 2.02636719 36.0664062 2.02636719'
              ></polygon>
              <path
                d='M44.5849609,1.23535156 C44.9316406,1.23535156 45.2001953,0.961914062 45.2001953,0.629882812 C45.2001953,0.29296875 44.9316406,0.01953125 44.5849609,0.01953125 C44.2431641,0.01953125 43.9697266,0.29296875 43.9697266,0.629882812 C43.9697266,0.961914062 44.2431641,1.23535156 44.5849609,1.23535156 Z M44.0576172,7.16308594 L45.1123047,7.16308594 L45.1123047,2.02636719 L44.0576172,2.02636719 L44.0576172,7.16308594 Z'
                id='Shape'
              ></path>
              <path
                d='M48.1279297,7.265625 C48.3427734,7.265625 48.5429688,7.24121094 48.6650391,7.22167969 L48.6650391,6.40625 C48.5869141,6.41601562 48.4941406,6.42578125 48.3818359,6.42578125 C47.9375,6.42578125 47.703125,6.26953125 47.703125,5.74707031 L47.703125,2.86621094 L48.6650391,2.86621094 L48.6650391,2.02636719 L47.703125,2.02636719 L47.703125,0.688476562 L46.6240234,0.688476562 L46.6240234,2.02636719 L45.8867188,2.02636719 L45.8867188,2.86621094 L46.6240234,2.86621094 L46.6240234,5.81054688 C46.6240234,6.8359375 47.1123047,7.265625 48.1279297,7.265625 Z'
                id='Path'
              ></path>
              <path
                d='M49.6982422,7.16308594 L50.7529297,7.16308594 L50.7529297,4.18945312 C50.7529297,3.38867188 51.2363281,2.83203125 51.9638672,2.83203125 C52.7060547,2.83203125 53.1357422,3.33984375 53.1357422,4.18945312 L53.1357422,7.16308594 L54.1904297,7.16308594 L54.1904297,3.98925781 C54.1904297,2.74902344 53.4677734,1.92871094 52.3691406,1.92871094 C51.6025391,1.92871094 51.0507812,2.27539062 50.7773438,2.87109375 L50.7529297,2.87109375 L50.7529297,0.1171875 L49.6982422,0.1171875 L49.6982422,7.16308594 Z'
                id='Path'
              ></path>
              <path
                d='M60.6269531,7.28027344 C62.1845703,7.28027344 63.3515625,6.33789062 63.5712891,4.93652344 L63.5761719,4.88769531 L62.4921875,4.88769531 L62.4775391,4.93164062 C62.2138672,5.82519531 61.5644531,6.31347656 60.6269531,6.31347656 C59.3232422,6.31347656 58.4785156,5.26855469 58.4785156,3.63769531 L58.4785156,3.6328125 C58.4785156,2.00195312 59.3183594,0.966796875 60.6269531,0.966796875 C61.5742188,0.966796875 62.2285156,1.46972656 62.4628906,2.29492188 L62.4921875,2.39257812 L63.5761719,2.39257812 L63.5664062,2.34863281 C63.3466797,0.95703125 62.1796875,0 60.6269531,0 C58.6298828,0 57.3603516,1.39648438 57.3603516,3.6328125 L57.3603516,3.63769531 C57.3603516,5.87402344 58.6298828,7.28027344 60.6269531,7.28027344 Z'
                id='Path'
              ></path>
              <path
                d='M66.7724609,7.265625 C68.2421875,7.265625 69.2089844,6.25 69.2089844,4.59472656 L69.2089844,4.58496094 C69.2089844,2.93945312 68.2324219,1.92871094 66.7675781,1.92871094 C65.3076172,1.92871094 64.3359375,2.94433594 64.3359375,4.58496094 L64.3359375,4.59472656 C64.3359375,6.24511719 65.2978516,7.265625 66.7724609,7.265625 Z M66.7773438,6.38183594 C65.9521484,6.38183594 65.4150391,5.73242188 65.4150391,4.59472656 L65.4150391,4.58496094 C65.4150391,3.46191406 65.9570312,2.8125 66.7675781,2.8125 C67.5927734,2.8125 68.1298828,3.46191406 68.1298828,4.58496094 L68.1298828,4.59472656 C68.1298828,5.72753906 67.6025391,6.38183594 66.7773438,6.38183594 Z'
                id='Shape'
              ></path>
              <path
                d='M70.1494141,7.16308594 L71.2041016,7.16308594 L71.2041016,4.16992188 C71.2041016,3.36914062 71.6826172,2.83203125 72.4248047,2.83203125 C73.1572266,2.83203125 73.5185547,3.26660156 73.5185547,4.03808594 L73.5185547,7.16308594 L74.578125,7.16308594 L74.578125,3.83789062 C74.578125,2.65625 73.9287109,1.92871094 72.8007812,1.92871094 C72.0244141,1.92871094 71.4970703,2.28027344 71.2285156,2.80273438 L71.2041016,2.80273438 L71.2041016,2.02636719 L70.1494141,2.02636719 L70.1494141,7.16308594 Z'
                id='Path'
              ></path>
              <path
                d='M75.71875,7.16308594 L76.7734375,7.16308594 L76.7734375,4.16992188 C76.7734375,3.36914062 77.2519531,2.83203125 77.9941406,2.83203125 C78.7265625,2.83203125 79.0878906,3.26660156 79.0878906,4.03808594 L79.0878906,7.16308594 L80.1474609,7.16308594 L80.1474609,3.83789062 C80.1474609,2.65625 79.4980469,1.92871094 78.3701172,1.92871094 C77.59375,1.92871094 77.0664062,2.28027344 76.7978516,2.80273438 L76.7734375,2.80273438 L76.7734375,2.02636719 L75.71875,2.02636719 L75.71875,7.16308594 Z'
                id='Path'
              ></path>
              <path
                d='M83.4804688,7.265625 C84.7255859,7.265625 85.5166016,6.5234375 85.6826172,5.70800781 L85.6923828,5.65429688 L84.6914062,5.65429688 L84.6767578,5.69335938 C84.5449219,6.07910156 84.1298828,6.40136719 83.5048828,6.40136719 C82.6650391,6.40136719 82.1328125,5.83496094 82.1132812,4.86328125 L85.7509766,4.86328125 L85.7509766,4.50683594 C85.7509766,2.96386719 84.8671875,1.92871094 83.4267578,1.92871094 C81.9863281,1.92871094 81.0585938,3.00292969 81.0585938,4.609375 L81.0585938,4.61425781 C81.0585938,6.23535156 81.9716797,7.265625 83.4804688,7.265625 Z M83.4316406,2.79296875 C84.1201172,2.79296875 84.6181641,3.22753906 84.7060547,4.11621094 L82.1279297,4.11621094 C82.2207031,3.25683594 82.7480469,2.79296875 83.4316406,2.79296875 Z'
                id='Shape'
              ></path>
              <path
                d='M88.8984375,7.265625 C90.1337891,7.265625 90.9931641,6.47460938 91.1054688,5.44433594 L91.1103516,5.41015625 L90.0947266,5.41015625 L90.0898438,5.44921875 C89.9628906,6.00585938 89.5478516,6.38183594 88.8984375,6.38183594 C88.0830078,6.38183594 87.5410156,5.72265625 87.5410156,4.59960938 L87.5410156,4.59472656 C87.5410156,3.49609375 88.0732422,2.8125 88.8935547,2.8125 C89.5820312,2.8125 89.9726562,3.23730469 90.0849609,3.74023438 L90.0947266,3.77929688 L91.1005859,3.77929688 L91.0957031,3.74511719 C91.0078125,2.77832031 90.2070312,1.92871094 88.8837891,1.92871094 C87.4189453,1.92871094 86.4619141,2.95898438 86.4619141,4.58496094 L86.4619141,4.58984375 C86.4619141,6.23535156 87.3994141,7.265625 88.8984375,7.265625 Z'
                id='Path'
              ></path>
              <path
                d='M93.7841797,7.265625 C93.9990234,7.265625 94.1992188,7.24121094 94.3212891,7.22167969 L94.3212891,6.40625 C94.2431641,6.41601562 94.1503906,6.42578125 94.0380859,6.42578125 C93.59375,6.42578125 93.359375,6.26953125 93.359375,5.74707031 L93.359375,2.86621094 L94.3212891,2.86621094 L94.3212891,2.02636719 L93.359375,2.02636719 L93.359375,0.688476562 L92.2802734,0.688476562 L92.2802734,2.02636719 L91.5429688,2.02636719 L91.5429688,2.86621094 L92.2802734,2.86621094 L92.2802734,5.81054688 C92.2802734,6.8359375 92.7685547,7.265625 93.7841797,7.265625 Z'
                id='Path'
              ></path>
              <polygon
                id='Path'
                points='95.4423828 7.16308594 96.5361328 7.16308594 96.5361328 0.1171875 95.4423828 0.1171875'
              ></polygon>
              <path
                d='M97.9599609,7.16308594 L100.445312,7.16308594 C102.544922,7.16308594 103.780273,5.85449219 103.780273,3.62792969 L103.780273,3.61816406 C103.780273,1.40136719 102.535156,0.1171875 100.445312,0.1171875 L97.9599609,0.1171875 L97.9599609,7.16308594 Z M99.0537109,6.21582031 L99.0537109,1.05957031 L100.342773,1.05957031 C101.827148,1.05957031 102.662109,1.97265625 102.662109,3.62792969 L102.662109,3.63769531 C102.662109,5.30273438 101.836914,6.21582031 100.342773,6.21582031 L99.0537109,6.21582031 Z'
                id='Shape'
              ></path>
            </g>
            <path
              d='M21.2628044,14.70725 C21.2628044,16.598875 19.8035844,18.12925 18,18.12925 C16.1963577,18.12925 14.7371377,16.598875 14.7371377,14.70725 C14.7371377,12.8155625 16.1963577,11.2851875 18,11.2851875 C18.9063378,11.2851875 19.7220533,11.665375 20.3111266,12.29275 L24,8.4239375 C23.4108689,7.8060625 23.0483222,6.9505625 23.0483222,6 L20.3111266,6 C20.3111266,7.27375 20.6736732,8.4715 21.2990532,9.4600625 C20.3564666,8.8041875 19.2144532,8.4239375 18,8.4239375 C14.6827644,8.4144375 12,11.228125 12,14.70725 C12,18.1863125 14.6827644,21 18,21 C21.3171777,21 24,18.1863125 24,14.70725 L21.2628044,14.70725 Z'
              id='Path'
              fill='#131415'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
