import { FC, ReactNode } from 'react';
import InputMessage from './InputMessage';

type VariantType = 'error' | 'default';

interface ICustomHelperTextProps {
  variant?: VariantType;
  message?: ReactNode;
  isShowIcon?: boolean;
  className?: string;
}

const CustomHelperText: FC<ICustomHelperTextProps> = ({
  variant = 'default',
  message,
  isShowIcon = true,
  className = '',
}) => {
  return message ? (
    <InputMessage
      className={className}
      variant={variant}
      message={message}
      isShowIcon={isShowIcon}
    />
  ) : (
    <></>
  );
};

export default CustomHelperText;
