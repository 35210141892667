import { FC, SVGProps } from 'react';

const CircleCheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Details_10'
          transform='translate(-667.000000, -473.000000)'
          fill='#2D7388'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Content' transform='translate(220.000000, 182.000000)'>
                <g id='Type-1-Copy-2' transform='translate(100.000000, 22.000000)'>
                  <g
                    id='01.Design-Token/06.Icons/Fluent/Filled/Checkmark-Circle'
                    transform='translate(55.000000, 0.000000)'
                  >
                    <g id='Group' transform='translate(2.000000, 2.000000)'>
                      <path
                        d='M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M13.22,6.97 L8.75,11.44 L6.78,9.47 C6.48449025,9.19464114 6.02399028,9.20276612 5.7383782,9.4883782 C5.45276612,9.77399028 5.44464114,10.2344902 5.72,10.53 L8.22,13.03 C8.51281755,13.3224534 8.98718245,13.3224534 9.28,13.03 L14.28,8.03 C14.4806532,7.84302942 14.5632492,7.56144446 14.4953855,7.29571109 C14.4275219,7.02997772 14.2200223,6.8224781 13.9542889,6.75461446 C13.6885555,6.68675082 13.4069706,6.76934682 13.22,6.97 L13.22,6.97 Z'
                        id='Shape'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CircleCheckIcon;
