import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from 'src/components/atoms/CustomButton';
import VerticalTabs from 'src/components/molecules/VerticalTabs';
import {
  useUpdateOrganisationDetail,
  useUpsertBranding,
} from 'src/modules/organisation-settings/hooks';

import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { useRole } from 'src/hooks/useRole';

import { BrandingContext } from 'src/providers/BrandingContextProvider';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { handleErrorFromServer } from 'src/utils/common';
import { AMLCTFPolicy, Branding, OrganisationDetails, SubscriptionAndBilling } from './components';
import { BrandingHandler } from './components/Branding';
import { OrganisationDetailsHandler } from './components/OrganisationDetails';

export const SUBMIT_TYPES = {
  ORGANISATION_DETAIL: 'organisationDetails',
  BRANDING: 'branding',
};

const OrganisationSettings = () => {
  const organisationRef = React.useRef<BrandingHandler | OrganisationDetailsHandler>(null);
  const { onRefresh, setReadOnly } = React.useContext(BrandingContext);
  const { mutate: upsertBrandingMutate } = useUpsertBranding();
  const { mutate: updateOrganisationDetailMutate } = useUpdateOrganisationDetail();
  const modalAlertRef = React.useRef<IBasicModalElement>(null);
  const [modalContent, setModalContent] = React.useState<string>();
  const [activeTab, setActiveTab] = useState(0);
  const { viewOnly } = useRole(ROUTES_PATH.ORGINISATION_SETTINGS);
  const { isFAUser } = useFundPortalContext();

  useEffect(() => {
    setReadOnly(viewOnly);
  }, [viewOnly]);

  const OrganisationSettingFaUsersTabs = [
    {
      label: 'Organisation Details',
      component: <OrganisationDetails viewOnly={viewOnly} ref={organisationRef} />,
    },
    {
      label: 'Branding',
      component: <Branding viewOnly={viewOnly} ref={organisationRef} />,
    },
    {
      label: 'Subscription and Billing',
      component: <SubscriptionAndBilling />,
    },
    {
      label: 'AML/CTF Policy',
      component: <AMLCTFPolicy />,
    },
  ];
  const OrganisationSettingFmUsersTabs = [
    {
      label: 'Organisation Details',
      component: <OrganisationDetails viewOnly={viewOnly} ref={organisationRef} />,
    },
    {
      label: 'Branding',
      component: <Branding viewOnly={viewOnly} ref={organisationRef} />,
    },
  ];

  const handleSubmit = () => {
    organisationRef?.current?.onSubmit(handleCallback);
  };

  const handleCallback = (params: any, type: string) => {
    switch (type) {
      case SUBMIT_TYPES.ORGANISATION_DETAIL: {
        updateOrganisationDetailMutate(params, {
          onSuccess: () => {
            setModalContent('Organisation details have been saved successfully.');
            modalAlertRef?.current?.open();
          },
          onError: handleErrorFromServer,
        });
        break;
      }
      case SUBMIT_TYPES.BRANDING: {
        upsertBrandingMutate(params, {
          onSuccess: () => {
            setModalContent('Branding settings have been saved successfully.');
            modalAlertRef?.current?.open();
            onRefresh();
          },
          onError: handleErrorFromServer,
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box>
      <Box className='flex items-center justify-between'>
        <Box component='div'>
          <Typography variant='h6'>Organisation Settings</Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          borderWidth: '1px',
          marginTop: '35px',
        }}
      />
      <VerticalTabs
        tabs={isFAUser ? OrganisationSettingFaUsersTabs : OrganisationSettingFmUsersTabs}
        tabActived={activeTab}
        onChange={(val) => setActiveTab(val)}
      />
      <Divider
        sx={{
          borderWidth: '1px',
        }}
      />
      <Box className='flex justify-end items-center mt-[20px]'>
        {viewOnly || activeTab === 2 ? (
          <></>
        ) : (
          <CustomButton onClick={handleSubmit}>Save</CustomButton>
        )}
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={modalContent as string}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              modalAlertRef?.current?.close();
              setModalContent('');
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};
export default OrganisationSettings;
