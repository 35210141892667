/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable autofix/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { get } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormMultiSelect from 'src/components/atoms/FormMultiSelect';
import AddressSection from 'src/components/pages/client-management/components/AddressSection';
import { organisationDetailsDefaultValues } from 'src/constants/client-management';
import { OTHER_LABEL } from 'src/constants/common';
import { useGetCapacity } from 'src/modules/client/hooks';
import { useGetOrganisationDetail } from 'src/modules/organisation-settings/hooks';
import { formatValueWithSpaces, removeSpacesInString } from 'src/utils/common';
import { detailValidationSchema } from 'src/validations/organisation-settings';
import { SUBMIT_TYPES } from '..';

interface OrganisationDetailsProps {
  viewOnly?: boolean;
}
export interface OrganisationDetailsHandler {
  onSubmit: (callback: (data: any, type: string) => void) => void;
}

const OrganisationDetails = React.forwardRef<OrganisationDetailsHandler, OrganisationDetailsProps>(
  ({ viewOnly = false }, forwardedRef) => {
    const { data: capacityData = [] } = useGetCapacity();
    const { data: details } = useGetOrganisationDetail();

    React.useImperativeHandle(forwardedRef, () => ({
      onSubmit: (callback: (data: any, type: string) => void) => {
        form.handleSubmit((data) => {
          const cityName =
            typeof data.physicalAddress.city === 'string'
              ? data.physicalAddress.city
              : String(get(data, 'physicalAddress.city.label', ''));

          const postalCityName =
            typeof data.postalAddress.city === 'string'
              ? data.postalAddress.city
              : String(get(data, 'postalAddress.city.label', ''));
          const cityId = String(get(data, 'physicalAddress.city.id', ''));
          const postalCityId = String(get(data, 'postalAddress.city.id', ''));

          const params: any = {
            id: data.id,
            legalName: data.legalName,
            abn: removeSpacesInString(data.abn),
            countryName: data.physicalAddress.country.label,
            countryId: String(data.physicalAddress.country.id),
            stateName: data.physicalAddress.state.label,
            stateId: String(data.physicalAddress.state.id),
            cityName,
            postcode: data.physicalAddress.postcode,
            streetAddress: data.physicalAddress.streetAddress,
            otherCapacity: data.otherCapacity,
            postalCountryName: data.postalAddress.country.label,
            postalCountryId: String(data.postalAddress.country.id),
            postalStateName: data.postalAddress.state.label,
            postalStateId: String(data.postalAddress.state.id),
            postalCityName,
            postalPostcode: data.postalAddress.postcode,
            postalStreetAddress: data.postalAddress.streetAddress,
            capacityIds: data.capacityIds.filter((item: string) => item !== 'other'),
            manualEntry: get(data, 'physicalAddress.manualEntry'),
            postalManualEntry: get(data, 'postalAddress.manualEntry'),
          };
          if (cityId) params.cityId = cityId;
          if (postalCityId) params.postalCityId = postalCityId;
          callback(params, SUBMIT_TYPES.ORGANISATION_DETAIL);
        })();
      },
    }));

    const form = useForm({
      defaultValues: { ...organisationDetailsDefaultValues, id: '' },
      resolver: yupResolver(detailValidationSchema),
    });

    const selectedCapacity: string[] = form.watch('capacityIds');
    const watchAbn = form.watch('abn');
    const watchPhysicalManualEntry = form.watch('physicalAddress.manualEntry');

    React.useEffect(() => {
      handleFormatAbn(watchAbn);
    }, [watchAbn]);

    React.useEffect(() => {
      if (details) {
        const formValues = {
          legalName: details?.legalName,
          abn: details?.abn,
          physicalAddress: {
            country: {
              value: details?.countryId,
              label: details?.countryName,
              id: details?.countryId,
            },
            state: {
              value: details?.stateId,
              label: details?.stateName,
              id: details?.stateId,
            },
            city: {
              value: details?.cityId,
              label: details?.cityName,
              id: details?.cityId,
            },
            manualEntry: details?.manualEntry,
            postcode: details?.postcode,
            streetAddress: details?.streetAddress,
          },
          postalAddress: {
            country: {
              value: details?.postalCountryId,
              label: details?.postalCountryName,
              id: details?.postalCountryId,
            },
            state: {
              value: details?.postalStateId,
              label: details?.postalStateName,
              id: details?.postalStateId,
            },
            city: {
              value: details?.postalCityId,
              label: details?.postalCityName,
              id: details?.postalCityId,
            },
            manualEntry: details?.postalManualEntry,
            postcode: details?.postalPostcode,
            streetAddress: details?.postalStreetAddress,
          },
          capacityIds: details?.capacityIds,
          otherCapacity: details?.otherCapacity,
          id: details?.id,
        };

        if (details?.manualEntry) {
          formValues.physicalAddress.city = details?.cityName;
        }

        if (details?.postalManualEntry) {
          formValues.postalAddress.city = details?.postalCityName;
        }

        form.reset(formValues);
      }
    }, [form, details]);

    const handleFormatAbn = (abn: string) => {
      const formattedAbn = formatValueWithSpaces(abn);
      form.setValue('abn', formattedAbn as string);
    };

    const renderOtherCapacity = () => {
      const otherOption = (capacityData || []).find((item) => item.capacityName === OTHER_LABEL);
      const hasOtherOption = (selectedCapacity || []).includes(otherOption?.id || '');

      return (
        hasOtherOption && (
          <Grid item xs={12} md={6} alignSelf='flex-end'>
            <FormInput name='otherCapacity' placeholder='This is other capacity' disabled />
          </Grid>
        )
      );
    };

    return (
      <FormProvider {...form}>
        <Box component='form' sx={{ p: 3 }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormInput
                  name='legalName'
                  label='Legal Name'
                  placeholder='Enter legal name'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput name='abn' label='ACN/ABN' placeholder='Enter ACN/ABN' disabled />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormMultiSelect
                  name='capacityIds'
                  label='Capacity'
                  options={capacityData || []}
                  placeholder='Select capacity'
                  disabled
                />
              </Grid>
              {renderOtherCapacity()}
            </Grid>
          </Box>
          <AddressSection
            watch={form.watch}
            resetField={(field: any) => form.resetField(field)}
            name='physicalAddress'
            title='Physical Address'
            readOnly={viewOnly}
            isShowManualEntry
          />
          <AddressSection
            watch={form.watch}
            resetField={(field: any) => form.resetField(field)}
            name='postalAddress'
            title='Postal Address'
            readOnly={viewOnly}
            isShowManualEntry
          />
        </Box>
      </FormProvider>
    );
  },
);

export default OrganisationDetails;
