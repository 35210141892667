import axios from 'axios';
import { ADDRESS } from 'src/constants/address';
import { FILE_API, PROFILE_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { ICountry } from 'src/interfaces/common';

export const uploadImage = async (file: File) => {
  const data = new FormData();
  data.append('file', file);
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: FILE_API.uploadImage.api,
  });

  return result.data;
};

export const getOverarchingFilter = async () => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: PROFILE_API.getOverarchingFilter.api,
  });

  return result.data;
};

export const uploadDocument = async (file: File) => {
  const data = new FormData();
  data.append('file', file);
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: FILE_API.uploadDocument.api,
  });

  return result.data;
};

export const downloadFileByPath = async (path: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { path },
    url: FILE_API.downloadFileByPath.api,
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const downloadDocumentByPath = async (path: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    params: { path },
    url: FILE_API.downloadDocumentByPath.api,
    responseType: 'arraybuffer',
  });

  return result.data;
};

export const getCountries = async () => {
  const result = await axios.get(ADDRESS.COUNTRIES);
  return result.data?.map((item: ICountry) => ({
    ...item,
    phone_code: item.phone_code.startsWith('+') ? item.phone_code : `+${item.phone_code}`,
  }));
};

export const getStates = async () => {
  const result = await axios.get(ADDRESS.STATES);
  return result.data;
};

export const getCities = async () => {
  const result = await axios.get(ADDRESS.CITIES);
  return result.data;
};
