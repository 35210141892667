import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { DATE_PICKER_FORMAT } from 'src/constants/date';
import { IIndividualDetail } from 'src/modules/applications/type';
import { dayjs } from 'src/utils/time';

interface IConnectIDVerifiedDetailsProps {
  individual?: IIndividualDetail;
}

const dataFoundToShow = [
  { label: 'First Name', keyValue: 'givenName' },
  { label: 'Middle Name', keyValue: 'middleName' },
  { label: 'Family Name', keyValue: 'familyName' },
  { label: 'Email', keyValue: 'email' },
  { label: 'Birthdate', keyValue: 'birthdate' },
  { label: 'Phone Number', keyValue: 'phoneNumber' },
  { label: 'Address', keyValue: 'address' },
];

const ConnectIDVerifiedDetails: React.FC<IConnectIDVerifiedDetailsProps> = ({ individual }) => {
  const dataFound = JSON.parse(individual?.dataFound || '""');

  return (
    <Box>
      <Typography variant='body3' fontWeight='bold'>
        Verified Details
      </Typography>
      <Box
        mt={0.75}
        py={2}
        px={2.5}
        bgcolor='white'
        borderRadius={'12px'}
        display={'flex'}
        flexDirection={'column'}
        gap={3}
      >
        {dataFoundToShow.map((it, index) => {
          const valueFromKey = get(dataFound, it.keyValue);
          let value = valueFromKey;
          if (it.keyValue === 'address') {
            value = [
              valueFromKey?.streetAddress,
              valueFromKey?.locality,
              valueFromKey?.region,
              valueFromKey?.postalCode,
              valueFromKey?.country,
            ]
              .filter((f) => f !== null)
              .join(', ');
          }
          if (it.keyValue === 'birthdate') {
            value = dayjs(value).format(DATE_PICKER_FORMAT);
          }
          return (
            value && (
              <Box display={'inline'} key={index}>
                <Typography variant='body3' color='neutral.ne800'>
                  {it.label}:
                </Typography>
                <Typography variant='body3' fontWeight={500} ml={1.5}>
                  {value}
                </Typography>
              </Box>
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default ConnectIDVerifiedDetails;
