import * as React from 'react';

interface IGovernmentBodyIconProps {}

const GovernmentBodyIcon: React.FunctionComponent<IGovernmentBodyIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>0CF5E536-2F62-4FB1-B9A8-1FFF2197CA95</title>
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Other_01'
          transform='translate(-605.000000, -638.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(196.000000, 365.000000)'>
                <g id='Type-1-Copy' transform='translate(107.000000, 0.000000)'>
                  <g id='broker' transform='translate(12.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                    <g id='courthouse' mask='url(#mask-2)'>
                      <g transform='translate(7.000000, 7.000000)'>
                        <polygon
                          id='path7513'
                          fill='#E9F8FF'
                          fillRule='nonzero'
                          points='5.33464754 13.9471579 4.00098642 31.2798398 11.9980312 31.2798398 10.6643696 13.9471579'
                        />
                        <polygon
                          id='path7529'
                          fill='#E9F8FF'
                          fillRule='nonzero'
                          points='29.3356275 13.9471579 28.0019661 31.2798398 35.9990084 31.2798398 34.6653495 13.9471579'
                        />
                        <polygon
                          id='path7541'
                          fill='#E9F8FF'
                          fillRule='nonzero'
                          points='17.3326764 13.9471579 15.9990156 31.2798398 23.9960611 31.2798398 22.6623984 13.9471579'
                        />
                        <g
                          id='Group'
                          strokeWidth={1}
                          fill='none'
                          fillRule='evenodd'
                          transform='translate(2.667320, 11.333968)'
                        >
                          <path
                            d='M0.664375249,19.330716 C0.296070466,19.3320515 -0.001411028,19.6316923 0,19.9999946 L0,22.6673325 L10.6643777,22.6673325 L10.6643777,19.9999946 C10.6657727,19.6316923 10.3683069,19.3320263 10,19.330716 L0.664375249,19.330716 Z'
                            id='path7501'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                          <path
                            d='M1.33366641,0 L1.33366641,2.66733786 C1.33495976,3.03372516 1.63165436,3.3303928 1.99803662,3.33170304 L8.66634348,3.33170304 C9.03272599,3.33044319 9.3294158,3.03372516 9.33071117,2.66733786 L9.33071117,0 L1.33366641,0 Z'
                            id='path7509'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                          <path
                            d='M24.665355,19.3307109 C24.2951277,19.329305 23.9946402,19.6297774 23.9960588,19.9999946 L23.9960588,22.6673325 L34.6653571,22.6673325 L34.6653571,19.9999946 C34.6667507,19.6297774 34.3662884,19.3292798 33.9960588,19.3307109 L24.665355,19.3307109 Z'
                            id='path7521'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                          <path
                            d='M25.3297252,0 L25.3297252,2.66733786 C25.3310581,3.03564012 25.6307141,3.33311406 25.9990163,3.33170804 L32.6673207,3.33170804 C33.0337029,3.33044319 33.3303958,3.03372516 33.3316884,2.66733786 L33.3316884,0 L25.3297252,0 Z'
                            id='path7525'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                          <path
                            d='M12.6673251,19.3307109 C12.2970978,19.329305 11.9966105,19.6297774 11.9980289,19.9999946 L11.9980289,22.6673325 L22.6673282,22.6673325 L22.6673282,19.9999946 C22.6687218,19.6297774 22.3682595,19.3292798 21.9980289,19.3307109 L12.6673251,19.3307109 Z'
                            id='path7533'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                          <path
                            d='M13.3316955,0 L13.3316955,2.66733786 C13.3329622,3.03372516 13.6296835,3.3303928 13.9960652,3.33170786 L20.6643708,3.33170786 C21.0326731,3.33308886 21.3323568,3.03564012 21.3336595,2.66733786 L21.3336595,0 L13.3316955,0 Z'
                            id='path7537'
                            fill='#C8D4D9'
                            fillRule='nonzero'
                          />
                        </g>
                        <path
                          d='M1.99803389,33.3319918 C1.62972936,33.3333322 1.33225014,33.6329983 1.33366116,34.0013005 L1.33366116,36.6686132 L38.666336,36.6686132 L38.666336,34.0013005 C38.6677321,33.6310581 38.3672698,33.3305857 37.99704,33.3319918 L1.99803389,33.3319918 Z'
                          id='path7497'
                          fill='#345E80'
                          fillRule='nonzero'
                        />
                        <path
                          d='M19.7637893,0.0446734862 L0.428162696,7.37735812 C0.169449008,7.47650005 -0.000991437207,7.72530828 6.47627803e-06,8.00236582 L6.47627803e-06,11.3340437 C0.00130048814,11.7004339 0.297992941,11.9971364 0.664383104,11.9984438 L39.3307039,11.9984438 C39.50757,11.9991137 39.677459,11.9294903 39.8029819,11.8048865 C39.9285048,11.6802827 39.9993748,11.51091 39.9999953,11.3340437 L39.9999953,8.00236582 C39.9999953,7.72406364 39.8275761,7.47489148 39.5669243,7.37735812 L20.2362198,0.0446734862 C20.1531126,0.012616475 20.0644243,-0.00246250014 19.9753924,0.000327041508 C19.9029152,0.0034985756 19.8314413,0.0184776124 19.7637893,0.0446734862 Z'
                          id='path7505'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        />
                        <path
                          d='M19.9999992,4.00130864 C18.5351356,4.00130864 17.3326764,5.20375241 17.3326764,6.6686213 C17.3326764,8.1334902 18.5351356,9.33102058 19.9999992,9.33102058 C21.4648608,9.33102058 22.6673196,8.1334902 22.6673196,6.6686213 C22.6673196,5.20375241 21.4648608,4.00130864 19.9999992,4.00130864 Z'
                          id='path7517'
                          fill='#E9F8FF'
                          fillRule='nonzero'
                        />
                        <path
                          d='M0.664372774,40.0003212 C0.487508114,39.9996901 0.318139299,39.9288157 0.193540031,39.8032907 C0.0689407625,39.6777657 -0.000678020521,39.5078769 0,39.3310125 L0,36.6686132 C-0.000671319832,36.4917531 0.0689504254,36.3218708 0.193548969,36.1963513 C0.318147512,36.0708317 0.487512497,35.9999608 0.664372774,35.9993297 L39.3307039,35.9993297 C39.5084173,35.9986515 39.6790494,36.0689448 39.8047138,36.1946069 C39.9303783,36.320269 40.0006748,36.4908998 40,36.6686132 L40,39.3310125 C40.0006815,39.5087302 39.930388,39.6793675 39.8047228,39.8050351 C39.6790576,39.9307026 39.5084216,40.0009994 39.3307039,40.0003212 L0.664372774,40.0003212 Z'
                          id='path7493'
                          fill='#3C6D91'
                          fillRule='nonzero'
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GovernmentBodyIcon;
