import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useRef } from 'react';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { ConnectIDFundcorrLogo } from 'src/assets/icons/ConnectIDFundcorrLogo';
import { ConnectIDIcon } from 'src/assets/icons/ConnectIDIcon';
import { ConnectIDLogo } from 'src/assets/icons/ConnectIDLogo';
import { ConnectIDSeparateIcon } from 'src/assets/icons/ConnectIDSeparateIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { CidBody } from 'src/components/molecules/connect-id';
import { Claim } from 'src/components/molecules/connect-id/types';
import { CONNECT_ID } from 'src/constants/connectId';
import { appLocalStorageKey } from 'src/modules/applications/consts';
import { useConnectIdSelectBank, useRegisterConnectIdKYC } from 'src/modules/applications/hooks';
import { IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import VerifyMethod from './VerifyMethod';

export default function VerifyConnectID(props: IVerifyIndividualItem) {
  const connectIdModalRef = React.useRef<IBasicModalElement>(null);
  const alertModalRef = React.useRef<IBasicModalElement>(null);
  const claims = React.useRef<Claim[]>([
    'family_name',
    'given_name',
    'middle_name',
    'address',
    'birthdate',
    'email',
    'phone_number',
  ]);
  const selectedBankName = useRef('');
  const handleOpenConnectIdModal = (status: boolean) => {
    if (status) {
      connectIdModalRef.current?.open();
    } else {
      connectIdModalRef.current?.close();
    }
  };
  const {
    mutate: register,
    isLoading,
    isError,
    error,
  } = useRegisterConnectIdKYC({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: () => {
      handleOpenConnectIdModal(true);
    },
  });
  const {
    mutate: selectBank,
    isLoading: selectBankLoading,
    isError: selectBankIsError,
    error: selectBankError,
  } = useConnectIdSelectBank({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (d: any) => {
      localStorage.setItem(
        appLocalStorageKey.verifyConnectIdKey,
        JSON.stringify({
          appId: props.appId,
          individualId: props.individualId,
          authCode: props.code,
          bankName: selectedBankName.current,
          ...d,
        }),
      );
      window.open(d.authUrl, '_self');
      handleOpenConnectIdModal(false);
    },
  });
  const closedModal = () => {
    handleOpenConnectIdModal(false);
  };
  const onCloseAlert = () => {
    alertModalRef.current?.close();
  };
  const onRegister = () => {
    if (props?.individual?.isKYCVerified) return;
    register({
      appId: props.appId,
      individualId: props.individualId,
      code: props.code,
    });
  };
  const onProceed = (id: string, bankName?: string) => {
    if (!selectBankLoading) {
      // Store bank name to show on CP and GAP
      if (bankName) {
        selectedBankName.current = bankName;
      }
      selectBank({
        authorizationServerId: id,
        code: props.code as string,
        essentialClaims: claims.current,
      });
    }
  };

  React.useEffect(() => {
    if (isError || selectBankIsError) alertModalRef.current?.open();
    else alertModalRef.current?.close();
  }, [isError, selectBankIsError]);

  return (
    <>
      <VerifyMethod
        onClick={onRegister}
        icon={isLoading ? CircularProgress : ConnectIDIcon}
        label='ConnectID'
        helpLabel="What's ConnectID?"
        helpContent={
          <span>
            ConnectID is an Australian-owned digital identity solution which allows you to securely
            prove who you are. ConnectID does not see or store your personal data. Visit{' '}
            <a
              className='cid-idp-selector-popup-info-box-link-underline'
              href='//www.connectid.com.au'
              target='_blank'
              tabIndex={1}
              rel='noreferrer'
            >
              connectid.com.au
            </a>{' '}
            to find out more.
          </span>
        }
        additionalDescription={'ConnectID does not see or store your personal data.'}
        disabled={
          props?.individual?.isKYCVerified ||
          props?.individual?.overallStatus === IOverallStatus.VERIFIED ||
          props?.disabled
        }
        description='ConnectID makes it easy to verify who you are, using organisations you already trust.'
      />
      <BasicModal
        ref={connectIdModalRef}
        maxWidth='lg'
        PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
        sx={{
          '& .MuiDialog-container': {
            marginLeft: '260px',
          },
        }}
        disableClose={true}
      >
        <Box className='w-[860px]'>
          <Box className='flex justify-between items-center p-[45px] pb-5'>
            <Box className='flex justify-start items-center'>
              <ConnectIDLogo />
              <Box className='px-3'>
                <ConnectIDSeparateIcon />
              </Box>
              <ConnectIDFundcorrLogo />
            </Box>
            <IconButton sx={{ p: 0 }} onClick={closedModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display='flex' flexDirection='column' className='p-12.5 pt-0' fontSize='14px'>
            <Box py={3}>
              <Typography variant='h5'>Verify with ConnectID</Typography>
              <Typography variant='body3' color='neutral.ne800'>
                Your details will be shared for the purpose of verifying your identity with
                Fundcorr.
              </Typography>
            </Box>
            <Box>
              <CidBody
                onProceed={onProceed}
                onCancel={closedModal}
                claims={claims.current}
                showAllParticipants={false}
                participantsUri={CONNECT_ID.PARTICIPANTS_URI}
                certificationStatus={'Active'}
                enableManualVerification={false}
                selectBankLoading={selectBankLoading}
              />
            </Box>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal disableClose={true} className='h-full' ref={alertModalRef}>
        <ConfirmationAlert
          title='Verified'
          isError={isError || selectBankIsError}
          description={
            get(error || selectBankError, 'data.ErrorMessage') ||
            get(error || selectBankError, 'data.errorMessage') ||
            ''
          }
          buttonAction={{
            label: 'Close',
            onAction: onCloseAlert,
          }}
        />
      </BasicModal>
    </>
  );
}
