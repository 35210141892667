/* eslint-disable react/self-closing-comp */
import * as React from 'react';

interface IConnectIDSeparateIconProps {}

export const ConnectIDSeparateIcon: React.FunctionComponent<IConnectIDSeparateIconProps> = () => {
  return (
    <svg
      width='34px'
      height='6px'
      viewBox='0 0 34 6'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='KYC-Verification' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_KYC-Verification_08.2'
          transform='translate(-571, -165)'
        >
          <g id='Group-5' transform='translate(430, 80)'>
            <g id='Group-14' transform='translate(80, 60)'>
              <g id='Group-13' transform='translate(61, 25)'>
                <circle id='Oval' fill='#939393' cx='3' cy='3' r='3'></circle>
                <circle id='Oval-Copy' fill='#C9C9C9' cx='17' cy='3' r='3'></circle>
                <circle id='Oval-Copy-2' fill='#E9E9E9' cx='31' cy='3' r='3'></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
