import { REQUIRE_MESSAGE } from 'src/constants/common';
import * as Yup from 'yup';

export const createBulkUploadSchema = Yup.object({
  clientId: Yup.string().required(REQUIRE_MESSAGE),
  fundId: Yup.string().required(REQUIRE_MESSAGE),
  unitClassId: Yup.string().required(REQUIRE_MESSAGE),
  investmentEntityType: Yup.string().required(REQUIRE_MESSAGE),
  file: Yup.mixed().required(REQUIRE_MESSAGE),
});
