import * as React from 'react';

interface IAssociationIconProps {}

const AssociationIcon: React.FunctionComponent<IAssociationIconProps> = (props) => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>EE8FDA38-DDDA-435A-8F78-6BCECFFEE895</title>
      <defs>
        <rect id='path-1' x={0} y={0} width={53} height={53} rx={10} />
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Other_01'
          transform='translate(-496.000000, -638.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Group-6' transform='translate(196.000000, 365.000000)'>
                <g id='individual' transform='translate(10.000000, 6.000000)'>
                  <mask id='mask-2' fill='white'>
                    <use xlinkHref='#path-1' />
                  </mask>
                  <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1' />
                  <g id='handshake' mask='url(#mask-2)'>
                    <g transform='translate(7.000000, 9.000000)'>
                      <polygon
                        id='Path'
                        fill='#6A7F91'
                        fillRule='nonzero'
                        points='27.8032258 16.3877419 34.9 28.68 40 25.7354839 40 9.34645161'
                      />
                      <polygon
                        id='Path'
                        fill='#345E80'
                        fillRule='nonzero'
                        points='27.8032258 16.3877419 34.9 28.68 40 25.7354839 40 9.34645161'
                      />
                      <polygon
                        id='Path'
                        fill='#3C6D91'
                        fillRule='nonzero'
                        points='28.4490323 17.5051613 34.9 28.68 40 25.7354839 40 10.836129'
                      />
                      <path
                        d='M22.0567742,36.8387097 L27.4006452,33.7535484 C29.2141674,32.7062513 30.639025,31.0989287 31.4612903,29.1729032 L34.2548387,27.56 L28.4483871,17.5025806 L25.6548387,19.1154839 L23.3606452,18.7664516 C22.2030219,18.5905572 21.0199932,18.8135126 20.0058065,19.3987097 L14.8690323,22.3664516'
                        id='Path'
                        fill='#F5C0A6'
                        fillRule='nonzero'
                      />
                      <path
                        d='M20.3012903,34.6316129 L22.5806452,37.3496774 C22.8558057,37.677389 22.9894625,38.1010126 22.9521942,38.527298 C22.914926,38.9535835 22.7097878,39.3475877 22.3819355,39.6225806 C21.6998565,40.1948697 20.683065,40.1062145 20.1103226,39.4245161 L17.9432258,36.8387097 L18.6090323,37.6129032 L12.5993548,33.7548387 C10.7858326,32.7075417 9.36097497,31.100219 8.53870968,29.1741935 L5.74516129,27.5612903 L11.5516129,17.503871 L14.3451613,19.1167742 L16.6393548,18.7677419 C17.7969781,18.5918475 18.9800068,18.8148029 19.9941935,19.4 L25.1309677,22.3677419'
                        id='Path'
                        fill='#FCD4C5'
                        fillRule='nonzero'
                      />
                      <path
                        d='M23.5677419,23.4690323 L30.8245161,32.1180645 C31.3970867,32.800411 31.308123,33.8177164 30.6258065,34.3903226 C29.9439731,34.96336 28.9267217,34.8752676 28.3535484,34.1935484 L21.0967742,25.5425806'
                        id='Path'
                        fill='#FCD4C5'
                        fillRule='nonzero'
                      />
                      <path
                        d='M21.0967742,25.5425806 L29.04,35.0096774 C29.6125706,35.6920239 29.5236069,36.7093293 28.8412903,37.2819355 C28.1587729,37.8543506 27.1414493,37.7650965 26.5690323,37.0825806 L18.6251613,27.616129'
                        id='Path'
                        fill='#FCD4C5'
                        fillRule='nonzero'
                      />
                      <path
                        d='M18.6251613,27.616129 L25.8825806,36.2651613 C26.4551512,36.9475078 26.3661876,37.9648132 25.683871,38.5374194 C25.0015245,39.10999 23.9842191,39.0210263 23.4116129,38.3387097 L16.1541935,29.6896774'
                        id='Path'
                        fill='#FCD4C5'
                        fillRule='nonzero'
                      />
                      <path
                        d='M24.5019355,31.1070968 C24.4194124,31.0087309 24.3793687,30.881599 24.3906223,30.7536956 C24.4018759,30.6257922 24.4635038,30.5076059 24.5619355,30.4251613 C24.7665131,30.25359 25.0714001,30.2801522 25.2432258,30.4845161 L29.04,35.0096774 C29.6125706,35.6920239 29.5236069,36.7093293 28.8412903,37.2819355 C28.7136282,37.3858906 28.5706454,37.4694604 28.4174194,37.5296774 C28.7968281,36.9390148 28.7487569,36.1703993 28.2987097,35.6316129 L24.5019355,31.1070968 Z'
                        id='Path'
                        fill='#F9C9B2'
                        fillRule='nonzero'
                      />
                      <g
                        id='Group'
                        strokeWidth={1}
                        fill='none'
                        fillRule='evenodd'
                        transform='translate(8.139174, 18.710456)'
                      >
                        <path
                          d='M1.95833045,12.164289 L3.71851084,12.164289 C4.609384,12.164289 5.33157938,12.8864843 5.33157938,13.7773575 C5.33157938,14.6682306 4.609384,15.390426 3.71851084,15.390426 L1.95833045,15.390426 C1.06745729,15.390426 0.34526191,14.6682306 0.34526191,13.7773575 C0.34526191,12.8864843 1.06745729,12.164289 1.95833045,12.164289 Z'
                          id='Rectangle'
                          fill='#F5C0A6'
                          fillRule='nonzero'
                          transform='translate(2.838421, 13.777357) rotate(-49.989042) translate(-2.838421, -13.777357) '
                        />
                        <path
                          d='M3.55270186,14.6477593 L6.38073362,14.6477593 C7.27160678,14.6477593 7.99380216,15.3699547 7.99380216,16.2608278 C7.99380216,17.151701 7.27160678,17.8738964 6.38073362,17.8738964 L3.55270186,17.8738964 C2.6618287,17.8738964 1.93963332,17.151701 1.93963332,16.2608278 C1.93963332,15.3699547 2.6618287,14.6477593 3.55270186,14.6477593 Z'
                          id='Rectangle'
                          fill='#F5C0A6'
                          fillRule='nonzero'
                          transform='translate(4.966718, 16.260828) rotate(-49.989042) translate(-4.966718, -16.260828) '
                        />
                        <path
                          d='M6.90105819,16.3124464 L8.66123858,16.3124464 C9.55211174,16.3124464 10.2743071,17.0346418 10.2743071,17.9255149 C10.2743071,18.8163881 9.55211174,19.5385835 8.66123858,19.5385835 L6.90105819,19.5385835 C6.01018504,19.5385835 5.28798965,18.8163881 5.28798965,17.9255149 C5.28798965,17.0346418 6.01018504,16.3124464 6.90105819,16.3124464 Z'
                          id='Rectangle'
                          fill='#F5C0A6'
                          fillRule='nonzero'
                          transform='translate(7.781148, 17.925515) rotate(-49.989042) translate(-7.781148, -17.925515) '
                        />
                        <path
                          d='M10.4347555,17.8933253 L10.9038358,17.8933253 C11.794709,17.8933253 12.5169044,18.6155212 12.5169044,19.5063944 C12.5169044,20.3972675 11.794709,21.1194629 10.9038358,21.1194629 L10.4334651,21.1194629 C9.5425919,21.1194629 8.82039651,20.3972675 8.82039651,19.5063944 C8.82039651,19.0783583 8.99052131,18.6678723 9.2933095,18.3653262 C9.5960977,18.06278 10.0067196,17.8929834 10.4347555,17.8933253 L10.4347555,17.8933253 Z'
                          id='Path'
                          fill='#F5C0A6'
                          fillRule='nonzero'
                          transform='translate(10.668650, 19.506394) rotate(-49.989042) translate(-10.668650, -19.506394) '
                        />
                        <path
                          d='M17.5156645,0.407608462 L15.221471,0.0585762042 C14.0638477,-0.117318253 12.8808191,0.105637142 11.8666323,0.690834269 L6.47308387,3.80567298 C5.70168361,4.25107682 5.43739139,5.23747893 5.88276129,6.00889878 C6.32816513,6.78029904 7.31456724,7.04459126 8.0859871,6.59922137 L12.2763097,4.17986653 C12.9606552,5.36556018 14.0880329,6.23079398 15.4103808,6.58518661 C16.7327287,6.93957924 18.1417018,6.75409397 19.3272774,6.06954395'
                          id='Path'
                          fill='#F5C0A6'
                          fillRule='nonzero'
                        />
                      </g>
                      <polygon
                        id='Path'
                        fill='#F5FDFF'
                        fillRule='nonzero'
                        transform='translate(33.026863, 21.565343) rotate(-30.000728) translate(-33.026863, -21.565343) '
                        points='31.0914219 14.4687252 34.9623045 14.4687252 34.9623045 28.6619613 31.0914219 28.6619613'
                      />
                      <polygon
                        id='Path'
                        fill='#345E80'
                        fillRule='nonzero'
                        points='12.1967742 16.3877419 5.1 28.68 0 25.7354839 0 9.34645161'
                      />
                      <polygon
                        id='Path'
                        fill='#3C6D91'
                        fillRule='nonzero'
                        points='0 25.7354839 5.1 28.68 11.5509677 17.5051613 2.29207334e-15 10.836129'
                      />
                      <polygon
                        id='Path'
                        fill='#F5FDFF'
                        fillRule='nonzero'
                        transform='translate(6.970827, 21.566132) rotate(-59.999272) translate(-6.970827, -21.566132) '
                        points='-0.125790966 19.6306903 14.0674452 19.6306903 14.0674452 23.5015729 -0.125790966 23.5015729'
                      />
                      <path
                        d='M22.7741935,32.5580645 C22.6917717,32.4597687 22.5736482,32.3982788 22.4458536,32.3871451 C22.3180589,32.3760115 22.1910816,32.416148 22.0929032,32.4987097 C21.9944715,32.5811543 21.9328436,32.6993406 21.92159,32.827244 C21.9103365,32.9551474 21.9503801,33.0822793 22.0329032,33.1806452 L25.1432258,36.8870968 C25.5930714,37.4258991 25.6413872,38.1942513 25.2625806,38.7851613 C25.4156074,38.7249409 25.558377,38.6413685 25.6858065,38.5374194 C26.0136587,38.2624265 26.218797,37.8684222 26.2560652,37.4421367 C26.2933334,37.0158513 26.1596767,36.5922277 25.8845161,36.2645161 L22.7741935,32.5580645 Z'
                        id='Path'
                        fill='#F9C9B2'
                        fillRule='nonzero'
                      />
                      <path
                        d='M19.56,35.2541935 C19.4774015,35.155909 19.4372484,35.0288276 19.4483807,34.9009275 C19.4595129,34.7730274 19.5210179,34.6547943 19.6193548,34.5722581 C19.8241448,34.4006171 20.1292341,34.4271717 20.3012903,34.6316129 L22.5806452,37.3503226 C23.1532158,38.032669 23.0642521,39.0499745 22.3819355,39.6225806 C22.2542733,39.7265358 22.1112905,39.8101056 21.9580645,39.8703226 C22.3374732,39.27966 22.2894021,38.5110444 21.8393548,37.9722581 L19.56,35.2541935 Z'
                        id='Path'
                        fill='#F9C9B2'
                        fillRule='nonzero'
                      />
                      <path
                        d='M12.036129,32.2212903 L10.9032258,33.5690323 C10.4508457,34.1057239 9.70249279,34.2867391 9.05483871,34.016129 C9.14079438,34.1566137 9.24810132,34.2828572 9.37290323,34.3903226 C9.70040714,34.6659112 10.1240237,34.8000194 10.5504574,34.7631118 C10.9768911,34.7262042 11.3711671,34.5213079 11.6464516,34.1935484 L12.7774194,32.8451613 C13.1148922,32.4543819 13.2419262,31.9242244 13.1182067,31.4229362 C12.9944872,30.921648 12.6353762,30.5114726 12.1548387,30.3225806 C12.5339341,30.9135532 12.4858812,31.6821383 12.036129,32.2212903 L12.036129,32.2212903 Z'
                        id='Path'
                        fill='#EBB69D'
                        fillRule='nonzero'
                      />
                      <path
                        d='M14.5070968,34.2948387 L12.6896774,36.4606452 C12.2369706,36.9972597 11.4885115,37.1782374 10.8406452,36.9077419 C11.1101002,37.3475811 11.5759981,37.629566 12.0906415,37.6643029 C12.6052849,37.6990397 13.1048505,37.4822205 13.4309677,37.0825806 L15.2483871,34.9167742 C15.5847524,34.5260064 15.7111735,33.9965986 15.5876607,33.4960133 C15.464148,32.9954279 15.1059797,32.5855861 14.6264516,32.396129 C15.0053661,32.9872395 14.9570516,33.7558318 14.5070968,34.2948387 L14.5070968,34.2948387 Z'
                        id='Path'
                        fill='#EBB69D'
                        fillRule='nonzero'
                      />
                      <path
                        d='M16.9780645,36.3683871 L15.8470968,37.716129 C15.3945043,38.2529285 14.6459137,38.433938 13.9980645,38.1632258 C14.267407,38.6031494 14.7332449,38.8852634 15.2478929,38.9201287 C15.7625409,38.954994 16.2621719,38.7382867 16.5883871,38.3387097 L17.7193548,36.9903226 C18.0557202,36.5995548 18.1821412,36.070147 18.0586284,35.5695616 C17.9351157,35.0689763 17.5769474,34.6591345 17.0974194,34.4696774 C17.4763338,35.0607879 17.4280193,35.8293802 16.9780645,36.3683871 L16.9780645,36.3683871 Z'
                        id='Path'
                        fill='#EBB69D'
                        fillRule='nonzero'
                      />
                      <path
                        d='M19.4509677,38.4412903 L19.1490323,38.8012903 C18.6961782,39.338154 17.9472443,39.5189222 17.2993548,39.2477419 C17.5692309,39.6871756 18.0350712,39.9688471 18.5495814,40.0036944 C19.0640916,40.0385417 19.5636581,39.8222564 19.8903226,39.4232258 L20.1922581,39.063871 C20.7648287,38.3815245 20.675865,37.3642191 19.9935484,36.7916129 C19.8664359,36.6875488 19.7238597,36.6039622 19.5709677,36.543871 C19.9492752,37.1347728 19.9007049,37.9027572 19.4509677,38.4412903 L19.4509677,38.4412903 Z'
                        id='Path'
                        fill='#EBB69D'
                        fillRule='nonzero'
                      />
                      <polygon
                        id='Path'
                        fill='#E6F5FA'
                        fillRule='nonzero'
                        transform='translate(6.648218, 22.124841) rotate(-59.999272) translate(-6.648218, -22.124841) '
                        points='0.196747098 20.1894 13.099689 20.1894 13.099689 24.0602826 0.196747098 24.0602826'
                      />
                      <polygon
                        id='Path'
                        fill='#F5FDFF'
                        fillRule='nonzero'
                        transform='translate(33.026863, 21.565343) rotate(-30.000728) translate(-33.026863, -21.565343) '
                        points='31.0914219 14.4687252 34.9623045 14.4687252 34.9623045 28.6619613 31.0914219 28.6619613'
                      />
                      <polygon
                        id='Path'
                        fill='#E6F5FA'
                        fillRule='nonzero'
                        transform='translate(33.350003, 22.123702) rotate(-30.000728) translate(-33.350003, -22.123702) '
                        points='31.4145613 15.672231 35.2854439 15.672231 35.2854439 28.5751729 31.4145613 28.5751729'
                      />
                      <circle
                        id='Oval'
                        fill='#00A56A'
                        fillRule='nonzero'
                        cx={20}
                        cy='7.74193548'
                        r='7.74193548'
                      />
                      <path
                        d='M14.3237907,5.64920713 C13.0294527,8.31772288 13.3751037,11.4913919 15.2135484,13.8187097 C18.2917513,16.2502077 22.7027604,15.9922207 25.4765228,13.2184583 C28.2502852,10.4446959 28.5082723,6.03368674 26.0767742,2.95548387 C24.7150091,1.87602284 23.0280334,1.28913991 21.2903226,1.29031484 C18.3244705,1.28736224 15.6181287,2.98069138 14.3237907,5.64920713 Z'
                        id='Path'
                        fill='#33B27F'
                        fillRule='nonzero'
                      />
                      <path
                        d='M18.4032258,9.11032258 L16.8064516,7.51354839 C16.4285485,7.13575941 15.8159676,7.13575941 15.4380645,7.51354839 C15.2564435,7.695053 15.1544002,7.94129594 15.1544002,8.19806452 C15.1544002,8.4548331 15.2564435,8.70107603 15.4380645,8.88258065 L17.7187097,11.1612903 C17.9002143,11.3429113 18.1464572,11.4449547 18.4032258,11.4449547 C18.6599944,11.4449547 18.9062373,11.3429113 19.0877419,11.1612903 L24.5619355,5.68709677 C24.9397245,5.30919369 24.9397245,4.69661276 24.5619355,4.31870968 C24.1840324,3.9409207 23.5714515,3.9409207 23.1935484,4.31870968 L18.4032258,9.11032258 Z'
                        id='Path'
                        fill='#FFFFFF'
                        fillRule='nonzero'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AssociationIcon;
