/* eslint-disable autofix/no-unused-vars */
import { Box, IconButton, Slider, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import BracketPrimaryIcon from 'src/assets/icons/BracketPrimaryIcon';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import getCroppedImg from 'src/utils/cropImage';

const BoxContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 15,
  left: 70,
  right: 70,
  bottom: 80,
}));

const BoxControls = styled(Box)(() => ({
  position: 'absolute',
  bottom: '0',
  left: '50%',
  width: '50%',
  transform: 'translateX(-50%)',
  height: 80,
  display: 'flex',
  alignItems: 'center',
}));

const CustomViewImg = styled('img')(() => ({
  width: '100%',
  maxHeight: '390px',
  objectFit: 'contain',
}));

interface IImageCropperProps {
  title: string;
  imgUrl: string;
  imageName?: string;
  onCancel?: () => void;
  onOk?: (file: File) => void;
  viewOnly?: boolean;
  isLoading?: boolean;
  aspect?: number;
}

const ImageCropper = forwardRef<IBasicModalElement, IImageCropperProps>((props, ref) => {
  const { title, onCancel, imgUrl, imageName, onOk, viewOnly, isLoading, aspect = 4 / 3 } = props;
  const basicModalRef = useRef<IBasicModalElement>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  useImperativeHandle(ref, () => ({
    open: () => basicModalRef.current?.open(),
    close: () => basicModalRef.current?.close(),
  }));

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imgUrl, croppedAreaPixels, imageName);
      onOk?.(croppedImage as File);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <BasicModal
      onClose={onCancel}
      ref={basicModalRef}
      maxWidth='md'
      PaperProps={{ sx: { top: 0, alignSelf: 'center' } }}
      sx={{
        '& .MuiDialog-container': {
          marginLeft: '260px',
        },
      }}
    >
      <Box className='w-[900px] h-[698px] flex flex-col'>
        <Box className='flex justify-center items-center p-[45px]'>
          <BracketPrimaryIcon />
          <Typography variant='h6' align='center' sx={{ pl: 1.5 }}>
            {viewOnly ? 'View' : title}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          className={clsx('relative w-[100%] h-[100%]', {
            'p-[80px]': viewOnly,
            'pt-[0px]': viewOnly,
          })}
        >
          {viewOnly ? (
            <CustomViewImg src={imgUrl} />
          ) : (
            <>
              <BoxContainer
                className={clsx('crop-container', {
                  'h-[470px]': viewOnly,
                })}
              >
                <Cropper
                  image={imgUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </BoxContainer>
              <BoxControls
                className={clsx('controls', {
                  hidden: viewOnly,
                })}
              >
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby='Zoom'
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </BoxControls>
            </>
          )}
        </Box>
        <Box
          className={clsx('flex justify-end py-3.5 px-7.5 border-t', {
            hidden: viewOnly,
          })}
          borderColor='neutral.ne200'
        >
          <CustomButton
            sx={(theme) => ({
              color: theme.palette.neutral.ne800,
              borderColor: theme.palette.neutral.ne800,
            })}
            variant='outlined'
            onClick={onCancel}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className='ml-6'
            variant='contained'
            onClick={showCroppedImage}
            isLoading={isLoading}
          >
            OK
          </CustomButton>
        </Box>
      </Box>
    </BasicModal>
  );
});

export default ImageCropper;
