const InvestorIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_Company_02'
          transform='translate(-518.000000, -766.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Add-New-Entity' transform='translate(196.000000, 365.000000)'>
                <g id='Group-6' transform='translate(24.000000, 96.000000)'>
                  <g id='Type-1' transform='translate(0.000000, 32.000000)'>
                    <g id='individual' transform='translate(8.000000, 6.000000)'>
                      <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1'></use>
                      </mask>
                      <use id='Mask' fill='#EDEEEE' xlinkHref='#path-1'></use>
                      <g id='investor' mask='url(#mask-2)'>
                        <g transform='translate(7.000000, 7.000000)'>
                          <path
                            d='M23.7930574,31.632696 L24.6178832,31.632696 C26.3016997,31.629306 27.9499425,32.1157445 29.3606321,33.0324201 L33.3102791,44.925969 L33.3102791,45.1473915 C33.3089733,45.5442877 32.9866107,45.8657125 32.5885565,45.8670145 L0.72172559,45.8670145 C0.32367132,45.8657125 0.00130875984,45.5442877 0,45.1473915 L0,40.2998213 C-0.00189296642,38.0005825 0.91330156,35.7949713 2.54385317,34.1691633 C4.17440477,32.5433554 6.3864514,31.6308234 8.69239882,31.632696 L23.7930574,31.632696 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M23.7930574,31.6327138 C23.7930574,31.6327138 24.1102981,43.7010284 19.8275483,45.8670145 L13.4827338,45.8670145 C9.19998398,44.4230238 9.51722471,31.6327138 9.51722471,31.6327138 L23.7930574,31.6327138 Z'
                            id='Path'
                            fill='#FFFFFF'
                            fillRule='nonzero'
                          ></path>
                          <polygon
                            id='Path'
                            fill='#FFCDBE'
                            fillRule='nonzero'
                            points='20.6206501 26.0971525 20.6206501 32.4235083 12.689632 32.4235083 12.689632 26.1169223'
                          ></polygon>
                          <path
                            d='M28.5516682,13.4444408 L27.7585664,14.2352352 L5.55171564,14.2352352 L4.75861382,13.4444408 L4.75861382,10.1072881 C4.83605859,4.44961254 9.49783599,-0.0746973758 15.1720406,0.000934587146 L23.0475416,0.000934587146 C23.4060575,-0.0064526654 23.7404976,0.180236597 23.9215912,0.488841626 C24.1026849,0.797446655 24.1020583,1.17961522 23.9199536,1.48762821 L23.3013342,2.37331803 L22.2068537,3.95490699 C23.804645,3.94671129 25.3574216,4.48232528 26.6085688,5.47323239 C27.821447,6.41399915 28.5370271,7.85555688 28.5516682,9.38766507 L28.5516682,13.4444408 Z'
                            id='Path'
                            fill='#3C6D91'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M16.655141,32.4235083 C14.9256432,32.5636977 13.484085,33.7998311 13.0861829,35.4838829 C13.0861829,35.4838829 9.51722471,34.5191137 9.51722471,31.6327138 C10.2310163,30.1855599 12.689632,30.0511249 12.689632,30.0511249 C12.689632,30.0511249 13.0861829,31.7038853 16.655141,32.4235083 Z'
                            id='Path'
                            fill='#D6DBE3'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M23.7930574,31.6327138 C23.7930574,34.5191137 20.2240992,35.4838829 20.2240992,35.4838829 C19.826197,33.7998311 18.3846388,32.5636977 16.655141,32.4235083 C20.2240992,31.7038853 20.6206501,30.0511249 20.6206501,30.0511249 C20.6206501,30.0511249 23.0792657,30.1855599 23.7930574,31.6327138 L23.7930574,31.6327138 Z'
                            id='Path'
                            fill='#D6DBE3'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M19.8196173,34.4479422 L18.2413447,36.3774807 L15.0689374,36.3774807 L13.4906648,34.4479422 C14.1576965,33.305072 15.3352275,32.5517614 16.655141,32.4235083 C17.9750545,32.5517614 19.1525856,33.305072 19.8196173,34.4479422 L19.8196173,34.4479422 Z'
                            id='Path'
                            fill='#EC3A48'
                            fillRule='nonzero'
                          ></path>
                          <polygon
                            id='Path'
                            fill='#D92936'
                            fillRule='nonzero'
                            points='19.8275483 45.8670145 18.2413447 36.3774807 15.0689374 36.3774807 13.4827338 45.8670145'
                          ></polygon>
                          <path
                            d='M28.5516682,13.4444408 L28.5516682,13.8240221 C28.5516682,15.6033097 28.5516682,16.6313425 27.8537386,18.0231408 C27.5365422,18.6784445 26.9437807,19.1593864 26.2358109,19.3358596 C25.9489154,19.4255153 25.7491211,19.6848084 25.7361568,19.9843111 C25.426026,24.757782 21.4525743,28.4713243 16.655141,28.4713243 C11.8577077,28.4713243 7.88425608,24.757782 7.57412526,19.9843111 C7.56116094,19.6848084 7.3613667,19.4255153 7.07447112,19.3358596 C6.36650134,19.1593864 5.77373982,18.6784445 5.45654342,18.0231408 C4.75861382,16.6313425 4.75861382,15.6033097 4.75861382,13.8240221 L4.75861382,13.4444408 C4.76122694,12.5720348 5.46986605,11.8654573 6.34481745,11.8628518 C7.21815641,11.8693104 7.92454364,12.5736426 7.93102108,13.4444408 L7.93102108,12.5982907 C7.93296891,11.856549 8.49908769,11.2373405 9.23963907,11.1669527 C10.976532,11.0008858 13.8158365,10.8110951 15.7113499,9.07925523 C16.2646664,8.57644433 17.1089015,8.5696283 17.6703114,9.06343934 C18.7647919,10.0203007 20.6127191,10.9218064 24.0151259,11.1906765 C24.7746079,11.2400925 25.3684671,11.8632077 25.379261,12.6220145 L25.379261,13.4444408 C25.3818741,12.5720348 26.0905132,11.8654573 26.9654646,11.8628518 C27.8388036,11.8693104 28.5451908,12.5736426 28.5516682,13.4444408 L28.5516682,13.4444408 Z'
                            id='Path'
                            fill='#FFDECF'
                            fillRule='nonzero'
                          ></path>
                          <g
                            id='Group-5'
                            strokeWidth='1'
                            fill='none'
                            fillRule='evenodd'
                            transform='translate(25.000000, 19.000000)'
                          >
                            <path
                              d='M10.3909122,7 C14.7272201,8.1212096 18.0203595,14.4900164 18,18.6385683 C17.9727934,23.2817153 13.9214295,27.0251985 8.95093072,27 C3.98043196,26.974546 -0.0269749786,23.1900008 0,18.5468539 C0.0214092124,14.419702 3.33009393,8.14260965 7.62222054,7.0061143 L10.3909122,7 Z'
                              id='Path'
                              fill='#F6B940'
                              fillRule='nonzero'
                            ></path>
                            <path
                              d='M12.9423929,3.0133352 L11.1964792,8 L7.51551109,8 L5.06395726,1.25333585 C4.96287978,0.978584956 4.98256056,0.659978159 5.11624408,0.406878601 C5.24992761,0.153779043 5.47942095,0.00063092372 5.72594954,0 L7.38456756,0 C7.69731593,-0.000983464828 7.97557964,0.242392482 8.0756584,0.604447206 L8.39574258,1.7777801 L12.2731259,1.7777801 C12.5170036,1.77802288 12.7445564,1.92757073 12.8791594,2.17606565 C13.0137624,2.42456058 13.0375053,2.73893768 12.9423929,3.0133352 Z'
                              id='Path'
                              fill='#F6B940'
                              fillRule='nonzero'
                            ></path>
                            <path
                              d='M9.47112226,2.96282528 C9.39415085,2.98602709 9.31418307,2.99854275 9.23359731,3 C8.90262155,2.99837647 8.60965993,2.79190199 8.50569828,2.48698885 L8,1 L9.61670207,1 L9.96149635,2.01858736 C10.0937063,2.41065112 9.87453334,2.83267873 9.47112226,2.96282528 L9.47112226,2.96282528 Z'
                              id='Path'
                              fill='#F4A93C'
                              fillRule='nonzero'
                            ></path>
                            <circle id='Oval' fill='#FBE390' cx='9' cy='17' r='5'></circle>
                            <path
                              d='M9.00065115,16.5 C8.67730933,16.4997635 8.40084188,16.3254317 8.34490104,16.0865044 C8.28896021,15.847577 8.469687,15.6129936 8.77356494,15.5300984 C9.07744289,15.4472033 9.41679183,15.5399149 9.57846296,15.75 C9.77006455,15.9761263 10.1652031,16.0487401 10.4744113,15.9146466 C10.7836196,15.7805531 10.896968,15.4874252 10.7314208,15.25 C10.4898831,14.9453754 10.1119811,14.7150164 9.66510142,14.6 L9.66510142,14.5 C9.66510142,14.2238576 9.36672176,14 8.9986518,14 C8.63058184,14 8.33220218,14.2238576 8.33220218,14.5 L8.33220218,14.592 C7.41480264,14.8344262 6.86421781,15.5379221 7.02903504,16.257089 C7.19385228,16.9762559 8.02601039,17.5013827 8.9986518,17.5 C9.32207769,17.5 9.59882381,17.6742062 9.65499152,17.9131676 C9.71115923,18.1521289 9.53053321,18.3868523 9.22661322,18.4698449 C8.92269323,18.5528374 8.58321938,18.4601397 8.42150643,18.25 C8.23197643,18.0201589 7.83330531,17.944915 7.52137312,18.0801116 C7.20944092,18.2153082 7.09716918,18.5120033 7.26788214,18.75 C7.5094198,19.0546246 7.88732183,19.2849836 8.33420153,19.4 L8.33420153,19.5 C8.33420153,19.7761424 8.63258119,20 9.00065115,20 C9.36872111,20 9.66710076,19.7761424 9.66710076,19.5 L9.66710076,19.408 C10.4630754,19.1968727 10.9965002,18.6333927 11,18 C11,17.1715729 10.104861,16.5 9.00065115,16.5 Z'
                              id='Path'
                              fill='#FFFFFF'
                              fillRule='nonzero'
                            ></path>
                            <path
                              d='M14.1428571,8 L3.85714286,8 C3.38375593,8 3,7.55228475 3,7 C3,6.44771525 3.38375593,6 3.85714286,6 L14.1428571,6 C14.6162441,6 15,6.44771525 15,7 C15,7.55228475 14.6162441,8 14.1428571,8 Z'
                              id='Path'
                              fill='#F4A93C'
                              fillRule='nonzero'
                            ></path>
                          </g>
                          <path
                            d='M20.3013342,2.37331803 L19.2068537,3.95490699 L16.0344465,3.95490699 C15.5964284,3.95490699 15.2413447,3.60085624 15.2413447,3.16411251 C15.2413447,2.72736878 15.5964284,2.37331803 16.0344465,2.37331803 L20.3013342,2.37331803 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InvestorIcon;
