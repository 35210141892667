import { FUND_REGISTRY_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { FUND_REGISTRY_BULK_UPLOAD_STATUS } from 'src/constants/fund-registry';
import { portalApi } from 'src/helpers/axios';
import {
  ICalTotalUnitIssuedBody,
  ICalTotalUnitIssuedResponse,
  IFundRegistryBulkUploadBody,
  IFundRegistryBulkUploadResponse,
  IFundRegistryFilterQueryBody,
  IGetUnitByDateResponse,
  IStrikeUnitPriceBody,
  IUnitPriceHistoryQueryBody,
} from './type';

export const getFundRegistryUnitPriceHistory = async (data: IUnitPriceHistoryQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUND_REGISTRY_API.getFundRegistryUnitPriceHistory.api,
  });

  return result.data;
};

export const getFundRegistryFilter = async (data: IFundRegistryFilterQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUND_REGISTRY_API.getFundRegistryFilter.api,
  });

  return result.data;
};

export const fundRegistryBulkUpload = async (
  params: IFundRegistryBulkUploadBody,
): Promise<IFundRegistryBulkUploadResponse> => {
  const data = new FormData();
  data.append('clientId', params.clientId);
  data.append('fundId', params.fundId);
  data.append('unitClassId', params.unitClassId);
  data.append('file', params?.file || '');

  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: FUND_REGISTRY_API.fundRegistryBulkUpload.api,
  });

  return result.data;
};

export const getFundRegistryBulkUploadStatus = async (
  intervalId: string,
): Promise<{ status: FUND_REGISTRY_BULK_UPLOAD_STATUS }> => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUND_REGISTRY_API.getFundRegistryBulkUploadStatus.api(intervalId),
  });

  return result.data;
};

export const exportUnitPriceHistoryCsv = async (data: IUnitPriceHistoryQueryBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    responseType: 'arraybuffer',
    url: FUND_REGISTRY_API.exportUnitPriceHistoryCsv.api,
  });

  return result.data;
};

export const calTotalUnitIssued = async (
  data: ICalTotalUnitIssuedBody,
): Promise<ICalTotalUnitIssuedResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUND_REGISTRY_API.calTotalUnitIssued.api,
  });

  return result.data;
};

export const strikeUnitPrice = async (data: IStrikeUnitPriceBody) => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUND_REGISTRY_API.strikeUnitPrice.api,
  });

  return result.data;
};

export const editUnitPrice = async ({ id, data }: { id: string; data: IStrikeUnitPriceBody }) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    data,
    url: FUND_REGISTRY_API.editUnitPrice.api(id),
  });

  return result.data;
};

export const getUnitPriceById = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.GET,
    url: FUND_REGISTRY_API.getUnitPriceById.api(id),
  });

  return result.data;
};

export const deleteUnitPrice = async (id: string) => {
  const result = await portalApi({
    method: REQUEST_METHOD.DELETE,
    url: FUND_REGISTRY_API.deleteUnitPrice.api(id),
  });

  return result.data;
};

export const getUnitByDate = async (
  data: ICalTotalUnitIssuedBody,
): Promise<IGetUnitByDateResponse> => {
  const result = await portalApi({
    method: REQUEST_METHOD.POST,
    data,
    url: FUND_REGISTRY_API.getUnitByDate.api,
  });

  return result.data;
};
