/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface IDashboardIconProps {
  color?: string;
}

export const DashboardIcon: FC<IDashboardIconProps> = ({ color }) => {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Sprint-1---Global-Admin-Portal-'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='00_Navigation_03'
          transform='translate(-23.000000, -124.000000)'
          fill={color || '#FFFFFF'}
          fillRule='nonzero'
        >
          <g id='Menu---Selecyed' transform='translate(11.000000, 112.000000)'>
            <g
              id='01.Design-Token/06.Icons/Line/grid-outline'
              transform='translate(12.000000, 12.000000)'
            >
              <g id='Group' transform='translate(3.000000, 3.000000)'>
                <path
                  d='M5.75,10 C6.99264069,10 8,11.0073593 8,12.25 L8,15.75 C8,16.9926407 6.99264069,18 5.75,18 L2.25,18 C1.00735931,18 0,16.9926407 0,15.75 L0,12.25 C0,11.0073593 1.00735931,10 2.25,10 L5.75,10 Z M15.75,10 C16.9926407,10 18,11.0073593 18,12.25 L18,15.75 C18,16.9926407 16.9926407,18 15.75,18 L12.25,18 C11.0073593,18 10,16.9926407 10,15.75 L10,12.25 C10,11.0073593 11.0073593,10 12.25,10 L15.75,10 Z M5.75,11.5 L2.25,11.5 C1.83578644,11.5 1.5,11.8357864 1.5,12.25 L1.5,15.75 C1.5,16.164 1.836,16.5 2.25,16.5 L5.75,16.5 C6.16421356,16.5 6.5,16.1642136 6.5,15.75 L6.5,12.25 C6.5,11.8357864 6.16421356,11.5 5.75,11.5 Z M15.75,11.5 L12.25,11.5 C11.8357864,11.5 11.5,11.8357864 11.5,12.25 L11.5,15.75 C11.5,16.164 11.836,16.5 12.25,16.5 L15.75,16.5 C16.1642136,16.5 16.5,16.1642136 16.5,15.75 L16.5,12.25 C16.5,11.8357864 16.1642136,11.5 15.75,11.5 Z M5.75,0 C6.99264069,0 8,1.00735931 8,2.25 L8,5.75 C8,6.99264069 6.99264069,8 5.75,8 L2.25,8 C1.00735931,8 0,6.99264069 0,5.75 L0,2.25 C0,1.00735931 1.00735931,0 2.25,0 L5.75,0 L5.75,0 Z M15.75,0 C16.9926407,0 18,1.00735931 18,2.25 L18,5.75 C18,6.99264069 16.9926407,8 15.75,8 L12.25,8 C11.0073593,8 10,6.99264069 10,5.75 L10,2.25 C10,1.00735931 11.0073593,0 12.25,0 L15.75,0 L15.75,0 Z M5.75,1.5 L2.25,1.5 C1.83578644,1.5 1.5,1.83578644 1.5,2.25 L1.5,5.75 C1.5,6.164 1.836,6.5 2.25,6.5 L5.75,6.5 C6.16421356,6.5 6.5,6.16421356 6.5,5.75 L6.5,2.25 C6.5,1.83578644 6.16421356,1.5 5.75,1.5 Z M15.75,1.5 L12.25,1.5 C11.8357864,1.5 11.5,1.83578644 11.5,2.25 L11.5,5.75 C11.5,6.164 11.836,6.5 12.25,6.5 L15.75,6.5 C16.1642136,6.5 16.5,6.16421356 16.5,5.75 L16.5,2.25 C16.5,1.83578644 16.1642136,1.5 15.75,1.5 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
