import * as React from 'react';

interface IDatePickerIconProps extends React.SVGProps<SVGSVGElement> {}

const DatePickerIcon: React.FunctionComponent<IDatePickerIconProps> = ({
  color = 'black',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
    >
      <rect
        x='1'
        y='3.50098'
        width='15'
        height='15'
        rx='2'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8327 1.83398V5.16732'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16667 1.83398V5.16732'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 8.50065H16'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DatePickerIcon;
