/* eslint-disable no-empty-pattern */
import { useMutation, useQuery } from '@tanstack/react-query';
import { AUTH_API, PROFILE_API } from 'src/constants/apiEndpoints';
import { authType } from 'src/constants/common';
import { STORAGE_KEYS } from 'src/constants/storageKeys';
import { detectPortalType } from 'src/helpers/common';
import * as StorageService from 'src/helpers/storage';
import {
  changePassword,
  getMfaAuthenticator,
  getPhoneNumber,
  getPortalInfo,
  getResetPasswordInfo,
  getUserInfo,
  login,
  logout,
  requestSMSAuthenticator,
  resetAuthenticator,
  resetPassword,
  sendEmailForgotPassword,
  signup,
  signupInvestor,
  signupResendVerifyEmail,
  signupVerifyEmail,
  updateProfile,
  verifyMfaAuthenticator,
  verifySMSAuthenticator,
} from './services';
import {
  IChangePasswordBody,
  IClientPortalUserType,
  ILoginBody,
  IProfileResponse,
  IResetPasswordBody,
  ISendEmailForgotPasswordBody,
  ISignUpBody,
  ISignUpInvestorBody,
  ISignUpResendVerifyEmailBody,
  ISignUpVerifyEmailBody,
  IUpdateProfileBody,
  IVerifyAuthenticatorBody,
} from './type';

export const useLogin = () => {
  const handleLogin = async (data: ILoginBody) => {
    const resp = await login(data);
    if (resp.token) {
      StorageService.setVerifyToken(resp?.token);
    }
    StorageService.setDefault2FAMethod(resp?.default2FAMethod);
    return resp;
  };
  return useMutation(handleLogin);
};

export const useLogout = () => {
  const handleLogout = async (refreshToken: string) => {
    StorageService.destroyDefault2FAMethod();
    return await logout(refreshToken);
  };
  return useMutation(handleLogout);
};

export const useGetMfaAuthenticator = (type: string | undefined = authType.ggAuth) => {
  const { data, isLoading, refetch } = useQuery(
    [AUTH_API.mfaAuthenticator.api],
    getMfaAuthenticator,
    { enabled: type === authType.ggAuth },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useVerifyMfaAuthenticator = () => {
  const handleVerifyMfaAuthenticator = async (data: IVerifyAuthenticatorBody) => {
    const result = await verifyMfaAuthenticator(data);
    handleSetAuthToken(result.token, result.refreshToken);
    return result;
  };
  return useMutation(handleVerifyMfaAuthenticator);
};

export const useRequestSMSAuthenticator = () => {
  const handleRequestSMSAuthenticator = async ({}) => {
    return await requestSMSAuthenticator();
  };
  return useMutation(handleRequestSMSAuthenticator);
};

export const useVerifySMSAuthenticator = () => {
  const handleVerifySMSAuthenticator = async (data: IVerifyAuthenticatorBody) => {
    const result = await verifySMSAuthenticator(data);
    handleSetAuthToken(result.token, result.refreshToken);
    return result;
  };
  return useMutation(handleVerifySMSAuthenticator);
};

export const useGetPhoneNumber = (type: string | undefined = authType.sms) => {
  const { data, isLoading, refetch } = useQuery([AUTH_API.phoneNumber.api], getPhoneNumber, {
    enabled: type === authType.sms,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useSignUp = () => {
  const handleSignUp = async (data: ISignUpBody) => {
    return await signup(data);
  };
  return useMutation(handleSignUp);
};

export const useAuthenticated = () => {
  const token = StorageService.getToken();

  const isAuthenticated = Boolean(token);
  return {
    token,
    isAuthenticated,
  };
};

export const useSendEmailForgotPassword = () => {
  const handleSendEmailForgotPassword = async (data: ISendEmailForgotPasswordBody) => {
    return await sendEmailForgotPassword(data);
  };
  return useMutation(handleSendEmailForgotPassword);
};

export const useResetPassword = () => {
  const handleResetPassword = async (data: IResetPasswordBody) => {
    return await resetPassword(data);
  };
  return useMutation(handleResetPassword);
};

export const useGetResetPasswordInfo = (email: string) => {
  return useQuery([AUTH_API.resetPasswordInfo.api, email], () => getResetPasswordInfo(email), {
    enabled: Boolean(email),
  });
};

export const useGetUserInfo = <T = unknown>(enabled?: boolean) => {
  const token = StorageService.getToken();
  const enabledOption = typeof enabled === 'boolean' ? enabled : true;
  const { data, isLoading, refetch, ...rest } = useQuery<T & IProfileResponse>(
    [PROFILE_API.getProfile.api],
    getUserInfo,
    { enabled: !!token && enabledOption },
  );

  return {
    data,
    isLoading,
    refetch,
    ...rest,
  };
};

export const useGetClientUserInfo = useGetUserInfo<IClientPortalUserType>;

const handleSetAuthToken = (token: string, refreshToken: string) => {
  if (token) {
    StorageService.setToken(token);
    StorageService.destroyVerifyToken();
  }
  if (refreshToken) {
    StorageService.setValue(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  }
};

export const useSignUpInvestor = () => {
  const resp = async (data: ISignUpInvestorBody) => {
    const { token } = await signupInvestor(data);
    StorageService.setVerifyToken(token);
  };
  return useMutation(resp);
};

export const useSignUpVerifyEmailInvestor = () => {
  const resp = async (data: ISignUpVerifyEmailBody) => {
    return await signupVerifyEmail(data);
  };
  return useMutation(resp);
};

export const useSignUpResendVerifyInvestor = () => {
  const resp = async (data: ISignUpResendVerifyEmailBody) => {
    return await signupResendVerifyEmail(data);
  };
  return useMutation(resp);
};

export const useChangePassword = () => {
  const handleChangePassword = async (data: IChangePasswordBody) => {
    return await changePassword(data);
  };
  return useMutation(handleChangePassword);
};

export const useUpdateProfile = () => {
  const handleUpdateProfile = async (data: IUpdateProfileBody) => {
    return await updateProfile(data);
  };
  return useMutation(handleUpdateProfile);
};

export const useResetAuthenticator = () => {
  const handleResetAuthenticator = async ({}) => {
    return await resetAuthenticator();
  };
  return useMutation(handleResetAuthenticator);
};

export const useGetPortalInfo = (enabled = true) => {
  const { isFunds } = detectPortalType();
  return useQuery([PROFILE_API.getPortalInfo.api], getPortalInfo, { enabled: isFunds && enabled });
};
