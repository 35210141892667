/* eslint-disable react/self-closing-comp */
import { FC } from 'react';

interface ISmallCircleIconProps {
  width?: string;
  height?: string;
}

export const SmallCircleIcon: FC<ISmallCircleIconProps> = ({ height = '24', width = '24' }) => {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z' fill='#686D6E' />
    </svg>
  );
};
