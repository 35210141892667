import { yupResolver } from '@hookform/resolvers/yup';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box } from '@mui/material';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import { useRequestVerifyKYC } from 'src/modules/applications/hooks';
import { IOverallStatus, IVerifyIndividualItem } from 'src/modules/applications/type';
import { YupBase } from 'src/validations/common';
import * as yup from 'yup';

export default function RequestToVerify(props: IVerifyIndividualItem) {
  const alertModalRef = useRef<IBasicModalElement>(null);

  const form = useForm<{ email: string }>({
    resolver: yupResolver(yup.object().shape({ email: YupBase.email })),
  });
  const { mutate: requestVerify, isLoading } = useRequestVerifyKYC({
    onSuccess: () => {
      props.refetch && props.refetch();
    },
  });
  const onSubmit = form.handleSubmit((data) => {
    requestVerify(
      {
        appId: props.appId,
        individualId: props.individual?.id as string,
        email: data.email,
      },
      {
        onSuccess: () => {
          alertModalRef.current?.open();
        },
      },
    );
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Box display='flex' flexDirection='row' gap={2} alignItems='end'>
          <FormInput
            label='Please enter email address'
            placeholder='Enter email address'
            defaultValue={props.individual?.email}
            name='email'
            disabled={
              props?.individual?.isKYCVerified ||
              props?.individual?.overallStatus === IOverallStatus.VERIFIED ||
              props?.disabled
            }
          />
          <CustomButton
            style={{ paddingLeft: '5px', paddingRight: '5px', width: '160px' }}
            endIcon={<MailOutlineIcon />}
            type='submit'
            isLoading={isLoading}
            disabled={
              props?.individual?.isKYCVerified ||
              props?.individual?.overallStatus === IOverallStatus.VERIFIED ||
              props?.disabled
            }
          >
            Send Email
          </CustomButton>
        </Box>
      </form>
      <BasicModal ref={alertModalRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description='Verification request email sent'
          buttonAction={{
            label: 'OK',
            onAction: () => alertModalRef?.current?.close(),
          }}
        />
      </BasicModal>
    </FormProvider>
  );
}
