import './InfoBox.css';

type InfoBoxProps = {
  children: React.ReactNode;
  type?: 'info' | 'warning';
};

export const InfoBox: React.FC<InfoBoxProps> = ({ children, type = 'info' }) => {
  const renderIcon = () => {
    if (type === 'info') {
      return (
        <svg
          className='cid-idp-selector-popup-info-box-icon'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
            fill='#33b27f'
          />
          <path d='M8.07129 13.8572H11.9284H8.07129Z' fill='#33b27f' />
          <path
            d='M8.07129 13.8572H11.9284'
            stroke='white'
            strokeWidth='1.7'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.0003 6.78571C10.3553 6.78571 10.6431 6.49789 10.6431 6.14286C10.6431 5.78782 10.3553 5.5 10.0003 5.5C9.64524 5.5 9.35742 5.78782 9.35742 6.14286C9.35742 6.49789 9.64524 6.78571 10.0003 6.78571Z'
            fill='white'
            stroke='white'
            strokeWidth='1.7'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10.0006 13.8572V9.35718H8.71484'
            stroke='white'
            strokeWidth='1.7'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      );
    }

    // warning
    return (
      <svg
        className='cid-idp-selector-popup-info-box-icon'
        width='20'
        height='20'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.96487 2.53513L2.53513 9.96487C1.96344 10.5366 1.96344 11.4634 2.53513 12.0351L9.96487 19.4649C10.5366 20.0366 11.4634 20.0366 12.0351 19.4649L19.4649 12.0351C20.0366 11.4634 20.0366 10.5366 19.4649 9.96487L12.0351 2.53513C11.4634 1.96344 10.5366 1.96344 9.96487 2.53513Z'
          fill='#FFC1A0'
          stroke='#FFC1A0'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.0029 6.66895V10.7733'
          stroke='#262626'
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.9419 15.4863C11.3197 15.4863 11.6259 15.18 11.6259 14.8022C11.6259 14.4244 11.3197 14.1182 10.9419 14.1182C10.5641 14.1182 10.2578 14.4244 10.2578 14.8022C10.2578 15.18 10.5641 15.4863 10.9419 15.4863Z'
          fill='#262626'
          stroke='#262626'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  };

  return (
    <div className={`cid-idp-selector-popup-info-box cid-idp-selector-popup-info-box--${type} w-full`}>
      <div className='cid-idp-selector-popup-info-box-container w-full'>
        {renderIcon()}
        <div className='cid-idp-selector-popup-info-box-content'>{children}</div>
      </div>
    </div>
  );
};
