import { portalTypes } from 'src/constants/common';
import {
  FUND_NAVIGATION_LIST,
  GLOBAL_ADMIN_NAVIGATION_LIST,
  INVESTOR_NAVIGATION_LIST,
} from 'src/constants/navigation';
import { detectPortalType } from './common';

export const getNavigationList = () => {
  const { portalType } = detectPortalType();
  switch (portalType) {
    case portalTypes.ADMIN:
      return GLOBAL_ADMIN_NAVIGATION_LIST;
    case portalTypes.INVESTOR:
      return INVESTOR_NAVIGATION_LIST;
    default:
      return FUND_NAVIGATION_LIST;
  }
};
