/* eslint-disable no-restricted-imports */
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import AddNew from 'src/components/pages/applications/components/InvestmentEntity/shared/AddNew';
import SelectExisting from 'src/components/pages/applications/components/InvestmentEntity/shared/SelectExisting';
import { investmentEntityCompanyDefaultValues } from 'src/constants/applications';
import { useGetApplicationById } from 'src/modules/applications/hooks';
import { IInvestmentEntityForm } from 'src/modules/applications/type';
import { IInvestmentEntityProps } from '..';
import { INVESTMENT_APPLICATION_STEP } from '../../InvestmentApplicationForm';
import CompanyDetails from './CompanyDetails';

interface ICompanyProps extends IInvestmentEntityProps {}

const Company: FC<ICompanyProps> = (props) => {
  const { id = '', isCreateFundApplicationForm } = props;

  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.INVESTMENT_ENTITY,
  });
  const investmentEntity = applicationDetail?.investmentEntity;

  const { reset } = useFormContext<IInvestmentEntityForm>();

  useEffect(() => {
    if (!Number.isInteger(investmentEntity?.entityType) && !isCreateFundApplicationForm) {
      reset(investmentEntityCompanyDefaultValues, {
        keepErrors: false,
        keepIsValid: false,
      });
    }
  }, []);

  return (
    <Box className='flex flex-col'>
      <SelectExisting {...props} defaultValue={investmentEntityCompanyDefaultValues} />
      <AddNew {...props}>
        <CompanyDetails {...props} investmentEntity={investmentEntity} />
      </AddNew>
    </Box>
  );
};

export default Company;
