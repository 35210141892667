import { UNIT_CLASS_API } from 'src/constants/apiEndpoints';
import { REQUEST_METHOD } from 'src/constants/apiMethods';
import { portalApi } from 'src/helpers/axios';
import { serializeObject } from 'src/utils/common';
import {
  ICurrency,
  IUnitClassById,
  IUnitClassInvestorRelationsParams,
  IUpsertFinancialDetailParams,
  IUpsertInvestmentDetailsParams,
  IUpsertUnitClassDetailParams,
} from './type';

export const getCurrencies = async (): Promise<ICurrency[]> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.GET,
    url: UNIT_CLASS_API.currencies.api,
  });

  return resp.data;
};

export const upsertUnitClassDetail = async (
  params: IUpsertUnitClassDetailParams,
): Promise<string> => {
  const { id, ...restParams } = params;
  const resp = await portalApi({
    method: id ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
    data: serializeObject(restParams),
    url: UNIT_CLASS_API.upsertUnitClassDetail.api(id),
  });

  return resp.data;
};

export const upsertFinancialDetail = async (
  params: IUpsertFinancialDetailParams,
): Promise<string> => {
  const { id, ...restParams } = params;
  const resp = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: serializeObject(restParams),
    url: UNIT_CLASS_API.upsertFinancialDetail.api(id),
  });
  return resp.data;
};

export const getUnitClassById = async (id: string, step: string): Promise<IUnitClassById> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.GET,
    params: {
      step,
    },
    url: UNIT_CLASS_API.getUnitClassById.api(id),
  });

  return resp.data;
};

export const upsertInvestmentDetail = async (params: IUpsertInvestmentDetailsParams) => {
  const { id, ...restParams } = params;
  const resp = await portalApi({
    method: REQUEST_METHOD.PUT,
    data: restParams,
    url: UNIT_CLASS_API.upsertInvestmentDetail.api(id),
  });
};
export const getDuplicateUnitClassById = async (id: string): Promise<IUnitClassById> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.GET,
    url: UNIT_CLASS_API.duplicateUnitClassById.api(id),
  });

  return resp.data;
};

export const upsertUnitClass = async (id: string) => {
  const resp = await portalApi({
    method: REQUEST_METHOD.POST,
    url: UNIT_CLASS_API.upsertUnitClass.api(id),
  });
};
export const duplicateUnitClassById = async (id: string, data: IUnitClassById): Promise<string> => {
  const resp = await portalApi({
    method: REQUEST_METHOD.POST,
    url: UNIT_CLASS_API.duplicateUnitClassById.api(id),
    data,
  });

  return resp.data;
};

export const upsertUnitClassInvestorRelations = async (
  params: IUnitClassInvestorRelationsParams,
  id: string,
) => {
  const result = await portalApi({
    method: REQUEST_METHOD.PUT,
    url: UNIT_CLASS_API.upsertInvestorRelations.api(id),
    data: params,
  });
  return result.data;
};
