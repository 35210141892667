/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

export const FlagIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width='12.0026695px'
      height='12.6686667px'
      viewBox='0 0 12.0026695 12.6686667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Administration_Approved-Signatory-List_04'
          transform='translate(-789.000000, -488.000000)'
          fill='#686D6E'
          fillRule='nonzero'
        >
          <g id='Pop-up-Copy' transform='translate(290.000000, 70.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 186.000000)'>
              <g id='Review-people' transform='translate(333.000000, 212.000000)'>
                <g id='User' transform='translate(20.000000, 16.000000)'>
                  <g
                    id='01.Design-Token/06.Icons/Fluent/Filled/Flag'
                    transform='translate(144.000000, 2.000000)'
                  >
                    <g id='Group' transform='translate(2.000000, 2.000000)'>
                      <path
                        d='M0,0.5 C0,0.223857625 0.223857625,0 0.5,0 L11.5026667,0 C11.6920527,0 11.8651843,0.107001188 11.9498803,0.276393202 C12.0345763,0.445785216 12.0162983,0.648491177 11.9026667,0.8 L9.12666667,4.50066667 L11.9026667,8.202 C12.0162983,8.35350882 12.0345763,8.55621478 11.9498803,8.7256068 C11.8651843,8.89499881 11.6920527,9.002 11.5026667,9.002 L1,9.002 L1,12.168 C0.999989463,12.4178572 0.815535722,12.6293528 0.568,12.6633333 L0.5,12.6686667 C0.250142814,12.6686667 0.0386472437,12.4842024 0.00466666667,12.2366667 L0,12.168 L0,0.5 Z'
                        id='Path'
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
