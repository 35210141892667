/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable autofix/no-unused-vars */
/* eslint-disable no-restricted-imports*/
import { Box, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { DisableIcon } from 'src/assets/icons/DisableIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import EyeViewIcon from 'src/assets/icons/EyeViewIcon';
import { PlusIcon } from 'src/assets/icons/PlusIcon';
import { Reset2FAIcon } from 'src/assets/icons/Reset2FAIcon';
import { ResetIcon } from 'src/assets/icons/ResetIcon';
import { SendIcon } from 'src/assets/icons/SendIcon';
import { TickIcon } from 'src/assets/icons/TickIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomTable from 'src/components/atoms/CustomTable';
import CustomTableContainer from 'src/components/atoms/CustomTableContainer';
import ActionMenus, { MenuItemProps } from 'src/components/molecules/ActionMenus';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import StatusBadge from 'src/components/molecules/StatusBadge';
import StringNodeTable from 'src/components/molecules/StringNodeTable';
import {
  CLIENT_STATUS,
  CLIENT_TEAM_USER_ACTION,
  MAX_FUND_CLIENT_TEAM_USERS,
} from 'src/constants/client-management';
import { CLIENT_USER_ROLE, DEFAULT_COUNTRY, userStatuses } from 'src/constants/common';
import { FUND_ROLE_TYPE } from 'src/constants/role-management';
import { showToast } from 'src/helpers/toast';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import {
  useDeleteClientTeamUser,
  useFundResendClientTeamUserEmail,
  useGetClientDetail,
  useUpdateClientTeamUserStatus,
  useUpsertClientTeamUser,
} from 'src/modules/client/hooks';
import { IClientTeamUser, ICreateClientUserFormFields } from 'src/modules/client/type';
import { ConfirmFormat } from 'src/modules/common/type';
import {
  useGetUserRoles,
  useResetUser2FA,
  useResetUserPassword,
} from 'src/modules/users-management/hooks';
import { Role } from 'src/modules/users-management/type';
import { useFundPortalContext } from 'src/providers/FundPortalProvider';
import { handleErrorFromServer } from 'src/utils/common';
import CreateClientTeamUser, { CreateClienUserFormHandler } from './CreateClientTeamUser';
import { FUND_UPSERT_CLIENT_STEP } from './FundsCreateUpdateClientForm';

interface IClientTeamProps {
  clientId: string;
  clientDetails: any;
  isViewMode: boolean;
  clientStatus: number;
  refetchClientDetails: () => Promise<QueryObserverResult<any, unknown>>;
}

export interface IUserArrayFormField {
  users: IClientTeamUser[];
  isSaveDraft?: boolean;
  isDirty?: boolean;
}

interface ITableData extends IClientTeamUser {}

export const CLIENT_TEAM_USER_ACTION_MODE = {
  view: 'View',
  edit: 'Edit',
};

const ClientTeam: FC<IClientTeamProps> = ({ clientId, clientStatus, isViewMode }) => {
  const [selectedUser, setSelectedUser] = useState<IClientTeamUser | null>(null);
  const [confirmFormat, setConfirmFormat] = useState<ConfirmFormat>();
  const [descriptionAlert, setDescriptionAlert] = useState<string>('');
  const [userActionMode, setUserActionMode] = useState<string>('');

  const { isFAUser } = useFundPortalContext();

  const createDrawerRef = useRef<ICustomDrawerElement>(null);
  const createClientTeamUserRef = useRef<CreateClienUserFormHandler>(null);
  const confirmModalRef = useRef<IConfirmModalElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);

  const { mutate: upsertClientTeamUserMutate, isLoading: isUpsertClienTeamUserLoading } =
    useUpsertClientTeamUser();
  const { mutate: deleteClientTeamUserMutate, isLoading: isDeleteClientTeamUserLoading } =
    useDeleteClientTeamUser();
  const { mutate: resendClientTeamUserEmailMutate, isLoading: isResendLoading } =
    useFundResendClientTeamUserEmail();

  const { mutate: updateClientTeamUserStatus, isLoading: updatingClientTeamUserStatus } =
    useUpdateClientTeamUserStatus();
  const { mutate: resetUserPasswordMutate, isLoading: isResetUserPasswordLoading } =
    useResetUserPassword();
  const { mutate: resetUser2FAMutate, isLoading: isResetUser2FALoading } = useResetUser2FA();
  const { data: clientDetails, refetch: refetchClientDetails } = useGetClientDetail(
    clientId,
    FUND_UPSERT_CLIENT_STEP.CLIENT_TEAM,
  );

  const isLoading =
    isUpsertClienTeamUserLoading ||
    isDeleteClientTeamUserLoading ||
    isResendLoading ||
    updatingClientTeamUserStatus ||
    isResetUserPasswordLoading ||
    isResetUser2FALoading;

  const { data: userRolesData = [] } = useGetUserRoles(FUND_ROLE_TYPE.FundClient.id);
  const { data: currentUser } = useGetUserInfo();

  const { control, watch, reset } = useFormContext<IUserArrayFormField>();

  const { fields, prepend, update, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const watchFieldArray = watch('users') || [];
  const controlledUsers = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const isViewUserMode = userActionMode === CLIENT_TEAM_USER_ACTION_MODE.view;

  useEffect(() => {
    handleFillClientTeamData();
  }, [clientDetails?.clientTeam]);

  const handleFillClientTeamData = () => {
    const users = (clientDetails?.clientTeam?.items || []).map(({ id, ...restInfo }: any) => {
      const role = userRolesData?.find((item: Role) => item.id === restInfo?.roleId);
      return {
        ...restInfo,
        roleName: role?.name,
        userId: id,
        isSendMail: false,
      };
    });

    reset({
      users,
      isDirty: true,
    });
  };

  const handleOpenCreateModal = () => {
    createDrawerRef.current?.open();
  };

  const handleCloseCreateModal = () => {
    setSelectedUser(null);
    setUserActionMode('');
    createDrawerRef.current?.close();
  };

  const handleCloseConfirmModal = () => {
    setSelectedUser(null);
    confirmModalRef.current?.close();
  };

  const handleOpenConfirmModal = () => {
    confirmModalRef.current?.open();
  };

  const handleUpdateUserList = (userId: string, data: ICreateClientUserFormFields) => {
    const { roleId, countryFlagCode = DEFAULT_COUNTRY, ...restData } = data;
    const role = userRolesData?.find((item: Role) => item.id === roleId);
    const isDraftClient = clientStatus === CLIENT_STATUS.Draft.id;
    const userCreated = {
      ...restData,
      userId,
      roleName: role.name,
      roleId,
      status: isDraftClient ? CLIENT_STATUS.Draft.name : CLIENT_STATUS.Pending.name,
      countryFlagCode,
    };

    const existedUserIndex = controlledUsers.findIndex(
      (item: IClientTeamUser) => item.userId === userCreated.userId,
    );

    if (existedUserIndex >= 0) {
      update(existedUserIndex, {
        ...userCreated,
        status: controlledUsers[existedUserIndex].status,
      });
    } else prepend(userCreated);
  };

  const checkIsDuplicateEmail = (email: string) => {
    const userId = selectedUser?.userId || '';

    if (userId) {
      return watchFieldArray.some((item) => item.userId !== userId && item.email === email);
    }

    return watchFieldArray.some((item) => item.email === email);
  };

  const handleCreateEditClientTeamUser = (data: ICreateClientUserFormFields) => {
    const body = { ...data, clientId };
    const isDuplicateEmail = checkIsDuplicateEmail(data.email);

    if (isDuplicateEmail) {
      showToast(`Email ${data.email} is already taken.`, 'error');
    } else {
      upsertClientTeamUserMutate(
        {
          userId: selectedUser?.userId || '',
          data: body,
        },
        {
          onSuccess: (userId: string) => {
            handleUpdateUserList(userId, data);
            handleCloseCreateModal();
          },
          onError: handleErrorFromServer,
        },
      );
    }
  };

  const handleSubmitClientTeamUser = () => {
    createClientTeamUserRef.current?.onCreateEditClientTeamUser(handleCreateEditClientTeamUser);
  };

  const syncUserListAfterDelete = (userId: string) => {
    const userIndex = controlledUsers.findIndex((item: IClientTeamUser) => item.userId === userId);
    if (userIndex >= 0) remove(userIndex);
  };

  const syncUserListAfterUpdateStatus = (userId: string, previousStatus: string) => {
    const userIndex = controlledUsers.findIndex((item: IClientTeamUser) => item.userId === userId);
    if (userIndex >= 0) {
      update(userIndex, {
        ...controlledUsers[userIndex],
        status:
          previousStatus === CLIENT_STATUS.Disabled.name
            ? CLIENT_STATUS.Active.name
            : CLIENT_STATUS.Disabled.name,
      });
    }
  };

  const handleFinishAction = () => {
    handleCloseConfirmModal();
    modalAlertRef?.current?.open();
    refetchClientDetails();
  };

  const handleDeleteClientTeamUser = (user: IClientTeamUser) => {
    deleteClientTeamUserMutate(
      { userId: user.userId, clientId },
      {
        onSuccess: () => {
          syncUserListAfterDelete(user.userId);
          setDescriptionAlert(`User "${user.firstName} ${user.lastName}" has been deleted.`);
          handleFinishAction();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleResendEmail = (user: IClientTeamUser) => {
    resendClientTeamUserEmailMutate(
      { userId: user.userId, clientId },
      {
        onSuccess: () => {
          setDescriptionAlert('Email invitation sent.');
          handleFinishAction();
        },
        onError: handleErrorFromServer,
      },
    );
  };

  const handleUpdateUserStatus = (user: IClientTeamUser) => {
    updateClientTeamUserStatus(
      { clientId, userId: user?.userId },
      {
        onSuccess: () => {
          setDescriptionAlert(
            `User "${user?.firstName} ${user?.lastName}" has been ${
              user?.status === userStatuses?.Active ? 'disabled.' : 'enabled.'
            }`,
          );
          syncUserListAfterUpdateStatus(user.userId, user.status);
          handleFinishAction();
        },
      },
    );
  };

  const handleResetPassword = (user: IClientTeamUser) => {
    resetUserPasswordMutate(user?.userId || '', {
      onSuccess: () => {
        setDescriptionAlert('Password reset email sent.');
        handleFinishAction();
      },
    });
  };

  const handleResetUser2FA = (user: IClientTeamUser) => {
    resetUser2FAMutate(user?.userId || '', {
      onSuccess: () => {
        setDescriptionAlert('User 2FA has been reset successfully.');
        handleFinishAction();
      },
    });
  };

  const getActionsByUserStatus = (userStatus: string) => {
    const isAllowedDeleteDisabledUser =
      isFAUser && currentUser?.role === CLIENT_USER_ROLE.SUPER_ADMIN;

    switch (userStatus) {
      case CLIENT_STATUS.Active.name:
        return [
          CLIENT_TEAM_USER_ACTION.view,
          CLIENT_TEAM_USER_ACTION.edit,
          CLIENT_TEAM_USER_ACTION.disable,
          CLIENT_TEAM_USER_ACTION.resetPassword,
          CLIENT_TEAM_USER_ACTION.reset2FA,
        ];
      case CLIENT_STATUS.Pending.name:
        return [
          CLIENT_TEAM_USER_ACTION.view,
          CLIENT_TEAM_USER_ACTION.edit,
          CLIENT_TEAM_USER_ACTION.resendEmail,
          CLIENT_TEAM_USER_ACTION.delete,
        ];
      case CLIENT_STATUS.Disabled.name:
        return [
          CLIENT_TEAM_USER_ACTION.view,
          CLIENT_TEAM_USER_ACTION.enable,
          ...(isAllowedDeleteDisabledUser ? [CLIENT_TEAM_USER_ACTION.delete] : []),
        ];
      default:
        return [];
    }
  };

  const getActionsByClientStatus = (userStatus: string) => {
    if (isViewMode) return [CLIENT_TEAM_USER_ACTION.view];

    switch (clientStatus) {
      case CLIENT_STATUS.Draft.id:
        return [CLIENT_TEAM_USER_ACTION.edit, CLIENT_TEAM_USER_ACTION.delete];
      case CLIENT_STATUS.Pending.id:
        return [
          CLIENT_TEAM_USER_ACTION.view,
          CLIENT_TEAM_USER_ACTION.edit,
          CLIENT_TEAM_USER_ACTION.resendEmail,
          CLIENT_TEAM_USER_ACTION.delete,
        ];
      case CLIENT_STATUS.Active.id:
        return getActionsByUserStatus(userStatus);

      default:
        return [CLIENT_TEAM_USER_ACTION.edit, CLIENT_TEAM_USER_ACTION.delete];
    }
  };

  const columns = [
    {
      title: 'First name',
      key: 'firstName',
      sorter: true,
      sortBy: 'firstName',
      renderNode: (row: ITableData) => <StringNodeTable value={row.firstName} />,
    },
    {
      title: 'Last name',
      key: 'lastName',
      sorter: true,
      sortBy: 'lastName',
      renderNode: (row: ITableData) => <StringNodeTable value={row.lastName || ''} />,
    },
    {
      title: 'Email',
      key: 'email',
      sorter: true,
      sortBy: 'email',
      renderNode: (row: ITableData) => <StringNodeTable value={String(row.email)} />,
    },
    {
      title: 'Role',
      key: 'roleName',
      sorter: true,
      sortBy: 'roleName',
      renderNode: (row: ITableData) => <StringNodeTable value={String(row.roleName)} />,
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      sortBy: 'status',
      renderNode: (row: ITableData) => <StatusBadge status={row.status} />,
    },
    {
      title: 'Action',
      key: 'action',
      sx: { width: '10%' },
      renderNode: (row: ITableData) => {
        const menus = getActionsByClientStatus(row.status);
        const menuIcon = {
          [CLIENT_TEAM_USER_ACTION.edit.key]: <EditOutlinedIcon />,
          [CLIENT_TEAM_USER_ACTION.delete.key]: <DeleteIcon />,
          [CLIENT_TEAM_USER_ACTION.view.key]: <EyeViewIcon />,
          [CLIENT_TEAM_USER_ACTION.resendEmail.key]: <SendIcon />,
          [CLIENT_TEAM_USER_ACTION.resetPassword.key]: <ResetIcon />,
          [CLIENT_TEAM_USER_ACTION.reset2FA.key]: <Reset2FAIcon />,
          [CLIENT_TEAM_USER_ACTION.disable.key]: <DisableIcon />,
          [CLIENT_TEAM_USER_ACTION.enable.key]: <TickIcon />,
        };
        const action = {
          [CLIENT_TEAM_USER_ACTION.edit.key]: () => {
            setSelectedUser(row);
            setUserActionMode(CLIENT_TEAM_USER_ACTION_MODE.edit);
            handleOpenCreateModal();
          },
          [CLIENT_TEAM_USER_ACTION.view.key]: () => {
            setSelectedUser(row);
            setUserActionMode(CLIENT_TEAM_USER_ACTION_MODE.view);
            handleOpenCreateModal();
          },
          [CLIENT_TEAM_USER_ACTION.delete.key]: () => {
            setSelectedUser(row);
            setConfirmFormat({
              title: 'Delete User?',
              content: `Are you sure you want to delete the user?<br />
                          This action cannot be undone.`,
              button: {
                type: 'error',
                label: 'Delete',
                onAction: () => handleDeleteClientTeamUser(row),
              },
            });
            handleOpenConfirmModal();
          },
          [CLIENT_TEAM_USER_ACTION.resendEmail.key]: () => {
            setSelectedUser(row);
            setConfirmFormat({
              title: 'Resend Email?',
              content: `We will resend the invitation email to the user.<br /> Are you sure?`,
              button: {
                type: 'primary',
                label: 'Send',
                onAction: () => handleResendEmail(row),
              },
            });
            handleOpenConfirmModal();
          },
          [CLIENT_TEAM_USER_ACTION.disable.key]: () => {
            setSelectedUser(row);
            setConfirmFormat({
              title: 'Disable User?',
              content: 'Are you sure you want to disable the user?',
              button: {
                type: 'primary',
                label: 'Disable',
                onAction: () => handleUpdateUserStatus(row),
              },
            });
            handleOpenConfirmModal();
          },
          [CLIENT_TEAM_USER_ACTION.enable.key]: () => {
            setSelectedUser(row);
            setConfirmFormat({
              title: 'Enable User?',
              content: `Are you sure you want to enable the user?`,
              button: {
                type: 'primary',
                label: 'Enable',
                onAction: () => handleUpdateUserStatus(row),
              },
            });
            handleOpenConfirmModal();
          },
          [CLIENT_TEAM_USER_ACTION.resetPassword.key]: () => {
            setConfirmFormat({
              title: 'Reset Password?',
              content: `We will send a reset password link to this email.<br /> Are you sure?`,
              button: {
                type: 'primary',
                label: 'Send',
                onAction: () => handleResetPassword(row),
              },
            });
            handleOpenConfirmModal();
          },
          [CLIENT_TEAM_USER_ACTION.reset2FA.key]: () => {
            setConfirmFormat({
              title: 'Reset 2FA?',
              content: `Are you sure you want to reset 2FA for this user?`,
              button: {
                type: 'primary',
                label: 'Reset',
                onAction: () => handleResetUser2FA(row),
              },
            });
            handleOpenConfirmModal();
          },
        };

        const actionMenus: MenuItemProps[] = menus.map((menuItem) => ({
          label: menuItem.label,
          icon: menuIcon[menuItem.key],
          onAction: action[menuItem.key],
        }));

        return <ActionMenus menus={actionMenus} />;
      },
    },
  ];

  const renderCreateNewUserButton = () => {
    if (isViewMode) return <></>;

    return (
      <CustomButton
        startIcon={<PlusIcon />}
        sx={{ mb: 2 }}
        onClick={handleOpenCreateModal}
        disabled={watchFieldArray?.length >= MAX_FUND_CLIENT_TEAM_USERS}
      >
        Create New User
      </CustomButton>
    );
  };

  const renderDrawerTitle = () => {
    if (isEmpty(selectedUser)) return 'Create New User';

    switch (userActionMode) {
      case CLIENT_TEAM_USER_ACTION_MODE.edit:
        return 'Edit User';
      case CLIENT_TEAM_USER_ACTION_MODE.view:
        return 'View User';
      default:
        return '';
    }
  };

  const renderDrawerButtons = () => {
    const isEditUserMode = userActionMode === CLIENT_TEAM_USER_ACTION_MODE.edit;

    if (isViewUserMode) return <></>;
    return (
      <Box className='flex gap-2'>
        <CustomButton
          sx={{ color: 'neutral.ne800' }}
          variant='text'
          onClick={handleCloseCreateModal}
          disabled={isLoading}
        >
          Cancel
        </CustomButton>
        <CustomButton
          className='w-[160px]'
          onClick={handleSubmitClientTeamUser}
          isLoading={isLoading}
        >
          {isEditUserMode ? 'Save' : 'Create new'}
        </CustomButton>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Box className='flex justify-between items-center'>
          <Typography color='neutral.ne800' variant='body3'>{`All Users (${
            watchFieldArray?.length || 0
          }/${MAX_FUND_CLIENT_TEAM_USERS})`}</Typography>
          {renderCreateNewUserButton()}
        </Box>
        <CustomTableContainer>
          <CustomTable<ITableData>
            rows={controlledUsers}
            columns={columns}
            hasPagination={false}
            stickyHeader
            displayEmpty
          />
        </CustomTableContainer>
      </Box>
      <CustomDrawer
        ref={createDrawerRef}
        title={renderDrawerTitle()}
        ButtonComponents={renderDrawerButtons()}
        onClose={handleCloseCreateModal}
      >
        <CreateClientTeamUser
          ref={createClientTeamUserRef}
          {...{
            selectedUser,
            userRolesData,
            isViewUserMode,
            isHasFunds: clientStatus === CLIENT_STATUS.Active.id,
            hideSendMail:
              clientStatus === CLIENT_STATUS.Active.id &&
              [CLIENT_STATUS.Active.name, CLIENT_STATUS.Pending.name].includes(
                selectedUser?.status || '',
              ),
            clientId,
          }}
        />
      </CustomDrawer>
      <ConfirmModal
        ref={confirmModalRef}
        title={confirmFormat?.title}
        content={confirmFormat?.content}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
              disabled={isLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton
              color={confirmFormat?.button?.type}
              onClick={confirmFormat?.button?.onAction}
              isLoading={isLoading}
            >
              {confirmFormat?.button?.label}
            </CustomButton>
          </>
        }
      />
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          title={`You did it !`}
          description={descriptionAlert}
          buttonAction={{
            label: 'OK',
            onAction: () => modalAlertRef?.current?.close(),
          }}
        />
      </BasicModal>
    </>
  );
};

export default ClientTeam;
