import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';

export default function VerifyMethod(props: {
  // eslint-disable-next-line autofix/no-unused-vars
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FunctionComponent<any>;
  helpLabel?: string;
  helpContent?: string | React.ReactNode;
  disabled?: boolean;
  description?: string;
  additionalDescription?: string;
  helperText?: ReactNode;
}) {
  return (
    <>
      <Box
        height='190px'
        onClick={props?.disabled ? () => null : props.onClick}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23A4A6A7FF' strokeWidth='2' stroke-dasharray='10%2c 10' stroke-dashoffset='17' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
        className={clsx(
          'flex flex-col items-center justify-center gap-3 p-2.5 select-none rounded-xl bg-white active:bg-white',
          {
            'cursor-pointer hover:bg-gray-100': !props?.disabled,
          },
        )}
      >
        {props?.description && (
          <Typography
            variant='body3'
            paddingX='10px'
            color='neutral.ne800'
            fontSize={10}
            lineHeight={'16px'}
          >
            {props?.description}
          </Typography>
        )}
        <Box>
          <props.icon />
        </Box>
        {!!props.helpLabel && (
          <Box component='span' className='flex items-center w-full' paddingX='10px'>
            {props.helpContent && (
              <CustomTooltip placement='right' title={props.helpContent}>
                <Box component='span' className='cursor-pointer'>
                  <InfoIcon />
                </Box>
              </CustomTooltip>
            )}
            <Typography variant='body3' paddingX='10px' color='neutral.ne800' fontSize={10}>
              {props.helpLabel}
            </Typography>
          </Box>
        )}
        {!!props.additionalDescription && (
          <Typography
            variant='body3'
            paddingX='10px'
            color='neutral.ne800'
            fontSize={10}
            lineHeight={'12px'}
            mt={-1}
          >
            {props.additionalDescription}
          </Typography>
        )}
      </Box>
      <Typography
        variant='body2'
        color='neutral.ne800'
        className='select-none'
        align='center'
        fontWeight='bold'
      >
        {props.label}
      </Typography>
      {props?.helperText && (
        <Typography margin='auto' variant='body3' paddingX='8px' color='error.main' align='center'>
          {props.helperText}
        </Typography>
      )}
    </>
  );
}
