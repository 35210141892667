import * as React from 'react';

interface IEyeViewIconProps extends React.SVGProps<SVGSVGElement> {}

const EyeViewIcon: React.FunctionComponent<IEyeViewIconProps> = ({
  width = '18px',
  height = '10px',
  color = '#686D6E',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 18 10'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3132 6.57453C16.3661 2.73794 12.952 0 8.99795 0C5.06603 0.000240661 1.63818 2.71237 0.681828 6.57975C0.605156 6.92526 0.814536 7.26964 1.1534 7.35537C1.49226 7.44109 1.83663 7.2368 1.92899 6.89526C2.74261 3.6094 5.65522 1.30524 8.99618 1.30442C12.3371 1.3036 15.2509 3.60632 16.066 6.89179C16.1524 7.24101 16.5017 7.4531 16.8461 7.36549C17.1905 7.27789 17.3996 6.92376 17.3132 6.57453ZM8.99795 3.0465C10.8915 3.0465 12.4266 4.6031 12.4266 6.52325C12.4266 8.44341 10.8915 10 8.99795 10C7.10437 10 5.56933 8.44341 5.56933 6.52325C5.56933 4.6031 7.10437 3.0465 8.99795 3.0465ZM8.99795 4.35028C7.81446 4.35028 6.85506 5.32315 6.85506 6.52325C6.85506 7.72335 7.81446 8.69622 8.99795 8.69622C10.1814 8.69622 11.1408 7.72335 11.1408 6.52325C11.1408 5.32315 10.1814 4.35028 8.99795 4.35028Z'
        fill={color}
      />
    </svg>
  );
};

export default EyeViewIcon;
