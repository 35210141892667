import { FC } from 'react';

interface IInvestmentApplicationTeamIconProps {}

const InvestmentApplicationTeamIcon: FC<IInvestmentApplicationTeamIconProps> = () => {
  return (
    <svg
      width='38px'
      height='38px'
      viewBox='0 0 38 38'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Team_00'
          transform='translate(-446.000000, -359.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 50.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Header' transform='translate(80.000000, 80.000000)'>
                <g id='Synbol' transform='translate(60.000000, 0.000000)'>
                  <g id='teamwork' transform='translate(16.000000, 16.000000)'>
                    <path
                      d='M29.8987088,9.3954127 C32.4978953,9.3954127 34.6084493,7.27658038 34.6084493,4.68236131 C34.3747628,-1.56137745 25.426068,-1.56021709 25.1889615,4.68242238 C25.1889615,7.27658038 27.306308,9.3954127 29.8987088,9.3954127 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M37.601214,12.6415556 C36.7732958,11.1067493 35.5381736,9.8911498 34.0587521,9.0966038 C31.938291,11.2767383 27.8589773,11.2759647 25.7386655,9.09648165 C23.5479654,10.3202376 20.6887707,13.1744106 22.2707718,15.8741339 C22.8680436,16.8656692 23.9131251,17.4564945 25.0668187,17.4564945 L34.7305989,17.4564945 C37.133244,17.5358263 38.8011183,14.7147472 37.6012208,12.6415556 L37.601214,12.6415556 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M8.08508645,9.3954127 C10.6842729,9.3954127 12.7948269,7.27658038 12.7948269,4.68236131 C12.5611405,-1.56137745 3.61244562,-1.56021709 3.37533918,4.68242238 C3.37533918,7.27658038 5.49268567,9.3954127 8.08508645,9.3954127 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M15.7129556,15.8741475 C17.292602,13.1735013 14.4412245,10.321398 12.2452044,9.09658344 C10.1246754,11.2767383 6.04535497,11.2759647 3.92504991,9.09648165 C1.73434986,10.3202376 -1.12484486,13.1744106 0.457156251,15.8741339 C1.05442805,16.8656692 2.09950951,17.4564945 3.25320311,17.4564945 L12.9169834,17.4564945 C14.0774695,17.4564945 15.1225509,16.8656692 15.7129624,15.8741475 L15.7129556,15.8741475 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M14.2831513,25.2242299 C14.2831513,27.818449 16.400491,29.9372813 18.9928917,29.9372813 C25.2327769,29.6990756 25.2305512,20.7440846 18.9928307,20.5111718 C16.400491,20.5111718 14.2831513,22.6232116 14.2831513,25.2242299 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M23.1529351,29.6384724 C21.0324739,31.8186069 16.9531603,31.8178333 14.8328484,29.6383503 C13.3602127,30.4330252 12.118298,31.6486247 11.290339,33.1834243 C10.0880529,35.2562359 11.7664044,38.0774913 14.1610762,37.9983089 C14.1609948,37.9983631 23.8247751,37.9983631 23.8247751,37.9983631 C26.2274201,38.0776949 27.8952945,35.2566159 26.6953155,33.1833835 C25.8674787,31.6486247 24.6323565,30.4330252 23.1529351,29.6384724 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M15.7559091,10.2171151 C17.8529121,9.61273197 20.12633,9.61273197 22.2233329,10.2171151 C22.5626525,9.83001731 22.922309,9.47010984 23.3091354,9.13734522 C20.5403264,8.15942209 17.4389563,8.16621459 14.6701066,9.13734522 C15.0637256,9.46330376 15.423382,9.82325873 15.7559091,10.2171151 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M7.45619322,21.2799049 C7.45619322,20.44457 7.54442108,19.6160683 7.72087001,18.8147162 L6.33642819,18.8147162 C6.18035676,19.6228608 6.0989214,20.4445632 6.0989214,21.2799049 C6.0989214,25.2051621 7.89732573,28.8927428 10.8968897,31.296833 C11.1615665,30.9300855 11.4601719,30.5905284 11.7723148,30.2645699 C9.07135612,28.1049893 7.45619322,24.8045064 7.45619322,21.2799049 L7.45619322,21.2799049 Z'
                      id='Path'
                    ></path>
                    <path
                      d='M30.2651577,18.814723 C30.4416067,19.616075 30.5298345,20.44457 30.5298345,21.2799117 C30.5298345,24.8045132 28.9146784,28.0981968 26.2205123,30.2645766 C26.5326619,30.5905352 26.824468,30.9300923 27.0959373,31.2968398 C30.0955013,28.8859503 31.8871064,25.2051689 31.8871064,21.2799117 C31.8871064,20.4445768 31.805671,19.6228743 31.6495996,18.814723 L30.2651577,18.814723 Z'
                      id='Path'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InvestmentApplicationTeamIcon;
