import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'src/components/atoms/FormInput';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import FormSelect from 'src/components/atoms/FormSelect';
import { TrueFalseRadioOptions } from 'src/constants/common';
import { ICountry } from 'src/interfaces/common';
import { IFinancialTaxDetailsForm, ITaxNoGIINReason } from 'src/modules/applications/type';
import { useGetCountries } from 'src/modules/common/hooks';
import { ITaxDetailsProps } from '..';

interface IEntityCountryOfTaxResidencyProps extends ITaxDetailsProps {
  taxNoTINReasons?: ITaxNoGIINReason[];
}

const EntityCountryOfTaxResidency: React.FC<IEntityCountryOfTaxResidencyProps> = ({
  taxNoTINReasons,
  isViewMode,
  isDisabledEdit,
  isExistingInvestmentEntity,
}) => {
  const { watch } = useFormContext<IFinancialTaxDetailsForm>();
  const { data: countries = [] } = useGetCountries();

  const watchHasEntiyTaxOtherThanAustralia = watch(
    'entityCountryOfTaxResidency.hasEntityTaxOtherThanAustralia',
  );
  const watchHasTIN = watch('entityCountryOfTaxResidency.hasTIN');

  const ReasonOptions = React.useMemo(() => {
    return (taxNoTINReasons || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [taxNoTINReasons]);

  const countryNumbersFormat = countries.map((country: ICountry) => {
    return {
      name: country.name,
      value: country.code,
      label: (
        <Box className='flex flex-row gap-2'>
          <span className={`fi fi-${country.code.toLowerCase()}`} />
          {country.name}
        </Box>
      ),
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormRadioGroup
          label='Is the entity a tax resident of a country other than Australia?'
          name='entityCountryOfTaxResidency.hasEntityTaxOtherThanAustralia'
          options={TrueFalseRadioOptions}
          isBooleanValue
          disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
        />
      </Grid>
      {watchHasEntiyTaxOtherThanAustralia && (
        <>
          <Grid item xs={12}>
            <FormRadioGroup
              label='Do you have Tax Identification Number (TIN)?'
              name='entityCountryOfTaxResidency.hasTIN'
              options={TrueFalseRadioOptions}
              isBooleanValue
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Grid>
          {watchHasTIN ? (
            <Grid item xs={12}>
              <FormInput
                label='Tax identification number (TIN)'
                name='entityCountryOfTaxResidency.tin'
                placeholder='Enter tax identification number'
                disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FormSelect
                placeholder='Select a reason'
                label='Select a reason'
                name='entityCountryOfTaxResidency.reasonNotHavingTIN'
                options={ReasonOptions}
                disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormSelect
              placeholder='Select Country of Tax Residency'
              label='Country of Tax Residency'
              name='entityCountryOfTaxResidency.countryOfTax'
              options={countryNumbersFormat}
              disabled={isViewMode || isDisabledEdit || isExistingInvestmentEntity}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EntityCountryOfTaxResidency;
