import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { PDFIcon } from 'src/assets/icons/PDFIcon';

import { useFormContext } from 'react-hook-form';
import { DownloadIcon } from 'src/assets/icons/DownloadIcon';
import FormDatePicker from 'src/components/atoms/FormDatePicker';
import FormRadioGroup from 'src/components/atoms/FormRadioGroup';
import { InvestorDetailsProps } from '.';

interface IGenerateStatementProps extends InvestorDetailsProps {
  previewInfo?: { url: string; fileSize: number };
}

const GenerateStatement: FC<IGenerateStatementProps> = ({ previewInfo }) => {
  const form = useFormContext();
  const isPreview = form.watch('isPreview');
  const option = form.watch('option');

  const StatementOptions = [
    {
      label: 'Annual',
      value: 0,
    },
    {
      label: 'Current Holding',
      value: 1,
    },
  ];

  const PublishOptions = [
    {
      label: 'Publish',
      value: 0,
    },
    {
      label: 'Email & Publish',
      value: 1,
    },
    {
      label: 'No Action',
      value: 2,
    },
  ];
  const fileSizeInMB = useMemo(() => {
    const size = previewInfo?.fileSize || 0;
    return (size / (1024 * 1024)).toFixed(1);
  }, [previewInfo?.fileSize]);

  return (
    <Box>
      <Grid container columnSpacing={3} rowSpacing='18px'>
        <Grid item xs={12}>
          {isPreview ? (
            <Box
              className='flex items-center justify-between p-[23px] rounded-[10px]'
              bgcolor='neutral.ne100'
            >
              <Box className='flex items-center '>
                <PDFIcon />
                <Box className='pl-[16px]'>
                  <Typography variant='body2' color='base.black'>
                    {option ? 'Current_Holding_Statement.pdf' : 'Annual_Holding_Statement.pdf'}
                  </Typography>
                  <Typography color='neutral.ne800' variant='body3'>
                    {`${fileSizeInMB}MB`}
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={() => window.open(previewInfo?.url, '_blank')}>
                <DownloadIcon />
              </IconButton>
            </Box>
          ) : (
            <FormDatePicker name='transactionDate' label='Statement Date' disableFuture />
          )}
        </Grid>
        <Grid item xs={12}>
          {isPreview ? (
            <FormRadioGroup
              key='publishOption'
              name='publishOption'
              label='Option'
              row
              options={PublishOptions}
              isEnumValue
            />
          ) : (
            <FormRadioGroup
              key='option'
              name='option'
              label='Select statement type'
              row
              options={StatementOptions}
              isEnumValue
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenerateStatement;
