import { DEFAULT_COUNTRY } from './common';

export const contactLawyerDefaultValue = {
  name: '',
  email: '',
  contactNumber: '',
  subject: '',
  message: '',
  countryFlagCode: DEFAULT_COUNTRY,
};
