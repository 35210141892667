/* eslint-disable no-empty-pattern */
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PlusPrimaryIcon } from 'src/assets/icons/PlusPrimaryIcon';
import { IBasicModalElement } from 'src/components/atoms/BasicModal';
import { ConfirmModal } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import { CustomDrawer, ICustomDrawerElement } from 'src/components/atoms/CustomDrawer';
import CustomHelperText from 'src/components/atoms/CustomHelperText';
import { CustomMenuItem, CustomSelect } from 'src/components/atoms/CustomSelect';
import UpdateDetailsButton from 'src/components/molecules/UpdateDetailsButton';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import {
  MAX_TEAM_COMMUNICATION_LIST,
  MAX_TEAM_CONTACT,
  MAX_TEAM_PRIMARY_CONTACT,
  TeamType,
} from 'src/constants/applications';
import { ActionType, REQUIRE_MESSAGE } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import {
  useDeleteTeamContact,
  useGetApplicationById,
  useGetContactListByTeamType,
  useGetTeamContact,
  useUpsertTeamContact,
} from 'src/modules/applications/hooks';
import {
  ITeamContact,
  ITeamError,
  IUpsertInvestmentApplicationForm,
} from 'src/modules/applications/type';
import { handleErrorFromServer } from 'src/utils/common';
import TeamSelectionBox from './TeamSelectionBox';
import TeamUpsertContact, { TeamUpsertContactHandler } from './TeamUpsertContact';

interface IInvestmentApplicationTeamProps extends IUpsertInvestmentApplicationForm {
  errors: ITeamError;
  setTeamErrors?: (fielName: string, value: string) => void;
}

const InvestmentApplicationTeam: FC<IInvestmentApplicationTeamProps> = ({
  id = '',
  errors,
  isViewMode,
  isEditMode,
  isExistingInvestmentEntity,
  investmentEntityId,
  currentStep,
  isDisabledEdit,
  isReviewing,
  isDraft,
  setTeamErrors,
  isFundApplication,
}) => {
  const { isInvestor, isFunds } = detectPortalType();
  const isCreateMode = !isViewMode && !isEditMode;
  const createUserRef = useRef<TeamUpsertContactHandler>(null);
  const upsertDrawerRef = useRef<ICustomDrawerElement>(null);
  const modalConfirmRef = useRef<IBasicModalElement>(null);
  const cacheFromTeamTypeRef = useRef<{
    fromTeamType: number | null;
  }>({
    fromTeamType: null,
  });
  const [selectedUser, setSelectedUser] = useState<ITeamContact | null>();
  const [actionType, setActionType] = useState<ActionType>('create');
  const [existingValue, setExistingValue] = useState<string | undefined>('');

  const { data: existingContacts } = useGetTeamContact(
    id,
    currentStep === INVESTMENT_APPLICATION_STEP.TEAM,
  );
  const { mutate: upsertTeamContact } = useUpsertTeamContact();
  const { mutate: removeTeamContact } = useDeleteTeamContact();
  const { data: applicationDetail } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.TEAM,
  });

  const form = useFormContext();
  const { append, update, remove } = useFieldArray({
    control: form?.control,
    name: 'teammates',
  });

  const selectedUsers = form.watch('teammates');
  const { primaryContacts, teams, communicationList } = useGetContactListByTeamType(
    selectedUsers,
    // Allow only active team users for existing application in IP or Fund application created from IP
    (isExistingInvestmentEntity && isInvestor) || (!isFundApplication && isFunds),
  );

  const handleCreateContact = () => {
    createUserRef.current?.onCreate(handleAddUser);
  };

  useEffect(() => {
    if (!isEmpty(applicationDetail?.team)) {
      form.reset({
        teammates: applicationDetail?.team,
      });
    }
  }, [applicationDetail]);

  // Default current user shoule be added to 3 teams when creating
  useEffect(() => {
    if (
      !isExistingInvestmentEntity &&
      (isCreateMode || isDraft) &&
      isEmpty(selectedUsers) &&
      isEmpty(applicationDetail?.team)
    ) {
      const currentUser = (existingContacts || []).find(
        (f: ITeamContact) => f.isCurrentUser && f.userType !== null,
      );
      !isEmpty(currentUser) &&
        append({
          ...currentUser,
          teamTypes: [TeamType.PrimaryContact, TeamType.CommunicationList, TeamType.Team],
        });
    }
  }, [existingContacts, applicationDetail]);

  const handleAddUser = (data: ITeamContact) => {
    upsertTeamContact(
      { id, data },
      {
        onSuccess: (id) => {
          const existingItemIndex = selectedUsers.findIndex((f: ITeamContact) => f.id === data.id);
          if (existingItemIndex !== -1) {
            update(existingItemIndex, data);
          } else {
            append({ ...data, id, isNew: actionType === 'create' });
          }
          errors.errorMessageFromServer && setTeamErrors?.('errorMessageFromServer', '');
        },
        onError: handleErrorFromServer,
      },
    );
    upsertDrawerRef.current?.close();
  };

  const handleRemoveContact = (user: ITeamContact, fromTeamType: number) => {
    cacheFromTeamTypeRef.current.fromTeamType = fromTeamType;
    setSelectedUser(user);
    modalConfirmRef.current?.open();
  };
  const handleEditContact = (user: ITeamContact, actionType?: ActionType) => {
    setExistingValue(user.id);
    setActionType(actionType || 'edit');
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };
  const handleViewContact = (user: ITeamContact) => {
    setActionType('view');
    setSelectedUser(user);
    upsertDrawerRef.current?.open();
  };

  const confirmRemoveContact = () => {
    if (selectedUser?.isNew && selectedUser?.id && selectedUser.teamTypes?.length === 1) {
      removeTeamContact(selectedUser?.id, {
        onSuccess: () => handleRemoveContactFromList(),
        onError: handleErrorFromServer,
      });
    } else {
      handleRemoveContactFromList();
    }
  };

  const handleRemoveContactFromList = () => {
    const newSelectedUser = [...selectedUsers];
    const userIndex = newSelectedUser.findIndex((f) => f.id === selectedUser?.id);
    if (userIndex !== -1) {
      newSelectedUser[userIndex].teamTypes = (newSelectedUser[userIndex].teamTypes || []).filter(
        (x: number) => x !== cacheFromTeamTypeRef.current.fromTeamType,
      );
      isEmpty(newSelectedUser[userIndex].teamTypes)
        ? remove(userIndex)
        : update(userIndex, newSelectedUser[userIndex]);
    }

    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    modalConfirmRef.current?.close();
    setSelectedUser(null);
  };

  const handleCloseUpsertModal = () => {
    upsertDrawerRef.current?.close();
    setExistingValue('');
    setSelectedUser(null);
  };

  const existingContactsOptions = useMemo(() => {
    return (existingContacts || []).filter(
      (x: ITeamContact) => selectedUsers.findIndex((f: ITeamContact) => f.id === x.id) === -1,
    );
  }, [selectedUsers, existingContacts]);

  const getUpsertContactModalTitle = useMemo(() => {
    switch (actionType) {
      case 'existing':
        return 'Existing';
      case 'edit':
        return 'Edit';
      case 'view':
        return 'View';
      default:
        return 'Create New';
    }
  }, [actionType]);

  return (
    <Box>
      {!isExistingInvestmentEntity ? (
        <Box className='mt-4 py-4 px-6 rounded-[10px]' bgcolor='neutral.ne100'>
          <Box>
            <Typography variant='body2'>Select Existing</Typography>
            <CustomSelect
              className='w-full'
              onChange={(e: SelectChangeEvent<unknown>) => {
                const findUser = existingContactsOptions?.find(
                  (f: ITeamContact) => f.id === e.target.value,
                );
                handleEditContact(findUser, 'existing');
              }}
              value={existingValue}
              renderValue={(value: unknown) => {
                const findContent = existingContactsOptions?.find(
                  (f: ITeamContact) => f.id === value,
                );
                return (
                  <Typography
                    color={!isEmpty(findContent) ? 'text.primary' : 'neutral.ne500'}
                    variant='body2'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    {!isEmpty(findContent)
                      ? `${findContent.firstName} ${findContent.lastName}`
                      : 'Select existing'}
                  </Typography>
                );
              }}
              displayEmpty
              fullWidth
              disabled={isViewMode || isDisabledEdit}
            >
              {(existingContactsOptions || []).map((contact: ITeamContact) => (
                <CustomMenuItem key={contact.id} value={contact.id}>
                  {`${contact.firstName} ${contact.lastName}`}
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </Box>
          <CustomButton
            className='p-0 mt-[14px]'
            variant='text'
            startIcon={<PlusPrimaryIcon />}
            onClick={() => {
              setActionType('create');
              upsertDrawerRef.current?.open();
            }}
            disabled={isViewMode || isDisabledEdit}
          >
            <Typography variant='body2' color='primary.main'>
              Create New
            </Typography>
          </CustomButton>
        </Box>
      ) : (
        !isReviewing && (
          <Box mb={4} textAlign='right'>
            <UpdateDetailsButton
              entityId={investmentEntityId}
              disabled={isViewMode || isDisabledEdit}
            />
          </Box>
        )
      )}

      <TeamSelectionBox
        title='Primary Contact'
        numOfSelected={`${primaryContacts.length}/${MAX_TEAM_PRIMARY_CONTACT} selected`}
        users={primaryContacts}
        mt='32px'
        onRemove={
          isExistingInvestmentEntity
            ? undefined
            : (user) => handleRemoveContact(user, TeamType.PrimaryContact)
        }
        onEdit={isExistingInvestmentEntity ? undefined : handleEditContact}
        onView={handleViewContact}
        disabled={isViewMode || isDisabledEdit}
      />
      {errors.isPrimaryContactError && (
        <CustomHelperText variant='error' message={REQUIRE_MESSAGE} />
      )}
      <TeamSelectionBox
        title='Team'
        numOfSelected={`${teams.length}/${MAX_TEAM_CONTACT} selected`}
        users={teams}
        tooltip='Please create the team who will be assigned to access this account'
        mt='32px'
        onRemove={
          isExistingInvestmentEntity
            ? undefined
            : (user) => handleRemoveContact(user, TeamType.Team)
        }
        onEdit={isExistingInvestmentEntity ? undefined : handleEditContact}
        onView={handleViewContact}
        disabled={isViewMode || isDisabledEdit}
      />
      {(errors.isTeamError || errors?.errorMessageFromServer) && (
        <CustomHelperText
          variant='error'
          message={errors?.errorMessageFromServer || REQUIRE_MESSAGE}
        />
      )}
      <TeamSelectionBox
        title='Communication List'
        numOfSelected={`${communicationList.length}/${MAX_TEAM_COMMUNICATION_LIST} selected`}
        users={communicationList}
        tooltip='Please select the team to be added to the email distribution list to receive statements, news, and updates via email.'
        mt='32px'
        onRemove={
          isExistingInvestmentEntity
            ? undefined
            : (user) => handleRemoveContact(user, TeamType.CommunicationList)
        }
        onEdit={isExistingInvestmentEntity ? undefined : handleEditContact}
        onView={handleViewContact}
        disabled={isViewMode || isDisabledEdit}
      />
      {errors.isCommunicationListError && (
        <CustomHelperText variant='error' message={REQUIRE_MESSAGE} />
      )}
      <CustomDrawer
        ref={upsertDrawerRef}
        title={getUpsertContactModalTitle}
        onClose={handleCloseUpsertModal}
        ButtonComponents={
          <Box className='flex gap-2'>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseUpsertModal}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className='w-[160px]'
              onClick={handleCreateContact}
              disabled={isViewMode || isDisabledEdit || actionType === 'view'}
            >
              Save
            </CustomButton>
          </Box>
        }
      >
        <TeamUpsertContact
          ref={createUserRef}
          user={selectedUser || undefined}
          isEditMode={actionType === 'edit' || actionType === 'existing'}
          isViewMode={actionType === 'view'}
          isPrimaryContactGotLimitation={primaryContacts.length === MAX_TEAM_PRIMARY_CONTACT}
          isTeamGotLimitation={teams.length === MAX_TEAM_CONTACT}
          isCLGotLimitation={communicationList.length === MAX_TEAM_COMMUNICATION_LIST}
        />
      </CustomDrawer>
      <ConfirmModal
        ref={modalConfirmRef}
        title='Remove User?'
        content='Are you sure you want to remove this user? This action cannot be undone.'
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </CustomButton>
            <CustomButton color='error' onClick={confirmRemoveContact}>
              Remove
            </CustomButton>
          </>
        }
      />
    </Box>
  );
};

export default InvestmentApplicationTeam;
