import React from 'react';
import { detectPortalType } from 'src/helpers/common';
import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';
import InvestorDashboard from './InvestorDashboard';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const { isInvestor, isAdmin } = detectPortalType();
  if (isInvestor) return <InvestorDashboard />;
  if (isAdmin) return <AdminDashboard />;

  return <ClientDashboard />;
};

export default Dashboard;
