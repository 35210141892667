import * as React from 'react';

interface IOvalDownloadIconProps {}

const OvalDownloadIcon: React.FunctionComponent<IOvalDownloadIconProps> = (props) => {
  return (
    <svg
      width='23px'
      height='23px'
      viewBox='0 0 23 23'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Fund-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Fundraising_KYC-Back-Office_Documents_00'
          transform='translate(-1277.000000, -451.000000)'
        >
          <g id='Table-document' transform='translate(606.000000, 370.000000)'>
            <g id='Group-14' transform='translate(20.000000, 81.000000)'>
              <g id='Remove-Copy-5' transform='translate(651.000000, 0.000000)'>
                <circle id='Oval' fill='#EDEEEE' cx='11.5' cy='11.5' r='11.5' />
                <g
                  id='01.Design-Token/06.Icons/Fluent/Line/Dismiss'
                  transform='translate(4.312500, 4.312500)'
                  fill='#686D6E'
                  fillRule='nonzero'
                >
                  <g id='Group' transform='translate(2.995833, 1.198333)'>
                    <path
                      d='M7.95445402,11.0815875 C8.19407895,11.0815875 8.38833333,11.2827795 8.38833333,11.5309625 C8.38833333,11.7791455 8.19407895,11.9803375 7.95445402,11.9803375 L0.43387931,11.9827342 C0.194254384,11.9827342 0,11.7815421 0,11.5333592 C0,11.2851762 0.194254384,11.0839842 0.43387931,11.0839842 L7.95445402,11.0815875 Z M4.13515908,0.00419416667 L4.19416667,0 C4.41098239,0 4.5945095,0.16578727 4.62399644,0.38826 L4.62804598,0.449375 L4.62746747,8.64897083 L6.78066586,6.42007083 C6.93249051,6.26278745 7.17256172,6.24419001 7.34528747,6.37633167 L7.39446046,6.42007083 C7.54614044,6.57747249 7.56384985,6.82610694 7.43611287,7.0048575 L7.39388195,7.0551875 L4.50308874,10.0492233 C4.35126409,10.2065067 4.11119288,10.2251042 3.93846713,10.0929625 L3.88929414,10.0492233 L0.995029885,7.05578667 C0.833560063,6.88964646 0.823492179,6.62251695 0.971966358,6.44384592 C1.12044054,6.26517489 1.37698179,6.235703 1.55965149,6.37633167 L1.60824598,6.41947167 L3.75970885,8.64477667 L3.76028736,0.449375 C3.7602965,0.224815855 3.92035782,0.0347342103 4.13515908,0.00419416667 L4.19416667,0 L4.13515908,0.00419416667 L4.13515908,0.00419416667 Z'
                      id='Shape'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default OvalDownloadIcon;
