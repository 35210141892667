export const GREEN_ID = {
  SCRIPT_CONFIG: String(process.env.REACT_APP_GREEN_ID_SCRIPT_CONFIG),
  SCRIPT_UI: String(process.env.REACT_APP_GREEN_ID_SCRIPT_UI),
  STYLE: String(process.env.REACT_APP_GREEN_ID_STYLE),
  MONO_STYLE: String(process.env.REACT_APP_GREEN_ID_STYLE_MONO),
  ENV: String(process.env.REACT_APP_GREEN_ID_ENV),
  ACCOUNT_ID: String(process.env.REACT_APP_GREEN_ID_ACCOUNT_ID),
  API_CODE: String(process.env.REACT_APP_GREEN_ID_API_CODE),
};

export const BIOMETRIC_CAMERA_ACCESS_MESSAGE =
  'Please allow the camera access for biometric verification';
