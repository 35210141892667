import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from 'src/components/atoms/CustomButton';
import { useEnterKeyPress } from 'src/modules/common/hooks';

interface IKYCFormSectionProps {
  title: string;
  children: React.ReactNode;
  showSubmit?: boolean;
  submitLabel?: string;
  isLoading?: boolean;
  isDisableSubmit?: boolean;
  onSubmit?: () => void;
  status?: React.ReactNode | null;
}

const KYCFormSection: React.FC<IKYCFormSectionProps> = ({
  title,
  status,
  children,
  showSubmit,
  submitLabel,
  isLoading,
  isDisableSubmit,
  onSubmit,
}) => {
  onSubmit && useEnterKeyPress(onSubmit);

  return (
    <Box className='p-[24px]' bgcolor='neutral.ne100'>
      <Box className='p-[40px] rounded-[10px]' bgcolor='white'>
        <Box className='flex items-center'>
          <Typography variant='h6'>{title}</Typography>
          {status}
        </Box>
        <Box className='pt-[24px] relative'>{children}</Box>
        {showSubmit && (
          <Grid item xs={12} md={12} className='flex flex-row-reverse mt-10'>
            <CustomButton isLoading={isLoading} onClick={onSubmit} disabled={isDisableSubmit}>
              <Typography variant='body2'>{submitLabel}</Typography>
            </CustomButton>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default KYCFormSection;
