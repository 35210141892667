const PartnershipIcon = () => {
  return (
    <svg
      width='53px'
      height='53px'
      viewBox='0 0 53 53'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='path-1' x='0' y='0' width='53' height='53' rx='10'></rect>
      </defs>
      <g id='Investor-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Investment-Entity_00'
          transform='translate(-1043.000000, -498.000000)'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Main-Content' transform='translate(0.000000, 213.000000)'>
              <g id='Select-Enity-Type' transform='translate(196.000000, 182.000000)'>
                <g id='Type-1-Copy-5' transform='translate(547.000000, 43.000000)'>
                  <g id='Group-2' transform='translate(10.000000, 6.000000)'>
                    <mask id='mask-2' fill='white'>
                      <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Rectangle' fill='#EDEEEE' xlinkHref='#path-1'></use>
                    <g id='trust' mask='url(#mask-2)'>
                      <g transform='translate(5.000000, 7.000000)' id='Group'>
                        <g transform='translate(11.765734, 0.000000)'>
                          <path
                            d='M8.9831989,0.166299636 L1.0443239,3.84638976 C0.369403069,4.15908993 -0.0450094309,4.87225952 0.00390306905,5.63482153 C0.141861402,7.77681769 0.665207236,12.1677677 2.64679057,15.443302 C5.14634474,19.5750306 8.2500489,20.6563336 9.33651557,20.9180423 C9.5986364,20.98076 9.8700739,20.98076 10.1320156,20.9180423 C11.2183031,20.6563336 14.3221864,19.5750306 16.8217406,15.443302 C18.8035031,12.1679454 19.3266697,7.77664002 19.4646281,5.63482153 C19.5135406,4.87225952 19.0987697,4.15908993 18.4242072,3.84638976 L10.4853322,0.166299636 C10.0076739,-0.0554332119 9.46067807,-0.0554332119 8.9831989,0.166299636 Z'
                            id='Path'
                            fill='#F6B940'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M5.6377989,17.8873381 C5.54714057,17.8873381 5.45648224,17.8535807 5.3867864,17.7857105 C4.6004239,17.0203058 3.88536974,16.1090335 3.26133224,15.077656 C1.86598224,12.7714922 1.23424057,9.8738632 0.949903069,7.84895193 C0.922669736,7.65458035 1.0593739,7.47513309 1.25538224,7.44812717 C1.4517489,7.41934454 1.6323489,7.55650621 1.6597614,7.75105546 C1.9349614,9.71022863 2.54269474,12.5081845 3.8758739,14.7118323 C4.4687364,15.6918631 5.1459864,16.5553419 5.8888114,17.2782834 C6.0301739,17.4158004 6.03214474,17.6407313 5.89346974,17.7809134 C5.82341557,17.851804 5.73060724,17.8873381 5.6377989,17.8873381 L5.6377989,17.8873381 Z M1.1405364,6.74295275 C0.957428069,6.74295275 0.801194736,6.60454739 0.784173902,6.42012536 L0.773782236,6.30552786 C0.756761402,6.10991258 0.902961402,5.93792749 1.10004474,5.92104878 C1.2958739,5.90150502 1.47074057,6.04897158 1.4877614,6.24440919 L1.49779474,6.35509794 C1.51589057,6.55053555 1.37076557,6.72340899 1.17368224,6.74135372 C1.1625739,6.74241974 1.15146557,6.74295275 1.1405364,6.74295275 L1.1405364,6.74295275 Z'
                            id='Shape'
                            fill='#FFC91D'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M18.4242072,3.84638976 L15.3360906,2.41489813 C15.7085781,2.78196549 15.9165906,3.30662663 15.8812947,3.85811602 C15.7431572,5.99993451 15.2201697,10.3912398 13.2384072,13.6665965 C10.7388531,17.7983251 7.6351489,18.8796281 6.54868224,19.1413368 C6.32705307,19.1944603 6.0989739,19.1997904 5.87501557,19.1630126 C7.36980307,20.3285314 8.69975724,20.7647126 9.33651557,20.9180423 C9.5986364,20.98076 9.8700739,20.98076 10.1320156,20.9180423 C11.2183031,20.6563336 14.3221864,19.574853 16.8217406,15.443302 C18.8035031,12.1677677 19.3266697,7.77664002 19.4646281,5.63482153 C19.5135406,4.87225952 19.0987697,4.15908993 18.4242072,3.84638976 L18.4242072,3.84638976 Z'
                            id='Path'
                            fill='#F4A93C'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M18.4242072,3.84638976 L16.9949947,3.18385627 C17.4507947,3.55056829 17.7125572,4.13243935 17.6731406,4.74646877 C17.5350031,6.88828726 17.0120156,11.2795926 15.0302531,14.5549492 C12.5306989,18.6866779 9.42699474,19.7679809 8.34052807,20.0296896 C8.0785864,20.0924073 7.8071489,20.0924073 7.54502807,20.0296896 C7.34794474,19.9822515 7.0836739,19.9072746 6.76941557,19.7924994 C7.88634057,20.4958971 8.8305489,20.7961603 9.33669474,20.9180423 C9.59881557,20.98076 9.87025307,20.98076 10.1321947,20.9180423 C11.2184822,20.6563336 14.3223656,19.574853 16.8219197,15.443302 C18.8036822,12.1677677 19.3268489,7.77664002 19.4648072,5.63482153 C19.5135406,4.87225952 19.0987697,4.15908993 18.4242072,3.84638976 Z'
                            id='Path'
                            fill='#F4A93C'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M9.2057239,3.22134476 L3.6178739,5.81160373 C3.1427239,6.03173754 2.85121974,6.53365684 2.88544057,7.07039958 C2.9825489,8.57811188 3.35091557,11.6686911 4.74572807,13.9741442 C6.50514474,16.8822558 8.68954474,17.6433964 9.45422807,17.8276408 C9.63876974,17.8718807 9.8297614,17.8718807 10.0141239,17.8276408 C10.7788072,17.6433964 12.9633864,16.8822558 14.7226239,13.9741442 C16.1174364,11.6686911 16.4856239,8.5779342 16.5829114,7.07039958 C16.6173114,6.53365684 16.3254489,6.03173754 15.8504781,5.81160373 L10.2628072,3.22134476 C9.92669057,3.06552769 9.5416614,3.06552769 9.2057239,3.22134476 L9.2057239,3.22134476 Z'
                            id='Path'
                            fill='#F1F2F2'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M15.8506572,5.81160373 L12.6682989,4.33640514 C12.8961989,4.5906517 13.0226906,4.93320052 12.9995781,5.29369407 C12.9024697,6.80122869 12.5342822,9.8919856 11.1392906,12.1974387 C9.3798739,15.1055503 7.1954739,15.8666909 6.43079057,16.0507576 C6.4198614,16.0534226 6.40875307,16.054311 6.3978239,16.0566207 C7.69033224,17.2866339 8.91941557,17.6986519 9.4540489,17.8274631 C9.63859057,17.8717031 9.82958224,17.8717031 10.0139447,17.8274631 C10.7786281,17.6432187 12.9632072,16.8820781 14.7224447,13.9741442 C16.1172572,11.6686911 16.4854447,8.5779342 16.5827322,7.07039958 C16.6173114,6.53383452 16.3254489,6.03173754 15.8506572,5.81160373 L15.8506572,5.81160373 Z'
                            id='Path'
                            fill='#E6E7E8'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M9.36518224,14.0372172 C9.1428364,14.0372172 8.92747807,13.9551334 8.7617489,13.8053572 L6.1914239,11.482137 C5.82574474,11.1516698 5.79940724,10.5898755 6.1328364,10.2272499 C6.46590724,9.86462434 7.0326114,9.83850676 7.39829057,10.1691517 L9.24406557,11.8374781 L12.3180281,7.81945862 C12.6168781,7.42876108 13.1789239,7.35218507 13.5732697,7.64871722 C13.9672572,7.94524937 14.0444781,8.50260189 13.7454489,8.8934771 L10.0788031,13.6861402 C9.92669057,13.8847759 9.69735724,14.0110997 9.44688224,14.0338415 C9.4196489,14.0359735 9.39241557,14.0372172 9.36518224,14.0372172 L9.36518224,14.0372172 Z'
                            id='Path'
                            fill='#345E80'
                            fillRule='nonzero'
                          ></path>
                        </g>
                        <g transform='translate(0.000000, 16.371631)'>
                          <path
                            d='M25.6486042,12.366581 C25.9535458,12.25607 26.2226542,12.0433983 26.3975208,11.7427797 L27.3906417,10.0369648 C27.4400917,9.95203825 27.4107083,9.84330387 27.3250667,9.7942668 L20.0156042,5.60941464 C19.15865,5.11886625 18.0628667,5.40989061 17.5681875,6.25968885 L16.921575,7.3701298 L25.6486042,12.366581 Z'
                            id='Path'
                            fill='#FCC4AE'
                            fillRule='nonzero'
                          ></path>
                          <g
                            transform='translate(16.894879, 0.000000)'
                            fillRule='nonzero'
                            id='Path'
                          >
                            <path
                              d='M13.8413417,4.70329483 L0,12.6279344 L6.169425,23.2245614 L12.0966167,19.8310539 C13.2420292,19.1752719 14.188925,18.2265112 14.8384042,17.0839118 L19.4965583,14.4170769 L13.8413417,4.70329483 Z'
                              fill='#F2AD97'
                            ></path>
                            <polygon
                              fill='#E59076'
                              transform='translate(16.909558, 15.308013) rotate(-30.000728) translate(-16.909558, -15.308013) '
                              points='14.2239916 14.7874011 19.5914068 14.8068497 19.5951247 15.8286251 14.2277095 15.8091766'
                            ></polygon>
                            <g transform='translate(13.562550, 0.000000)'>
                              <path
                                d='M7.97883777,15.0858288 L12.5425711,12.4730057 L5.28058777,0 L0.716854434,2.61282312 C0.0313627674,3.00529737 -0.203524733,3.87463938 0.192254434,4.55440691 L6.02090443,14.5656094 C6.4166836,15.245377 7.2933461,15.4783031 7.97883777,15.0858288 L7.97883777,15.0858288 Z'
                                fill='#3C6D91'
                              ></path>
                              <path
                                d='M0.716854434,2.61282312 L0.811275267,2.55881128 L5.8609086,11.2321546 C6.14488777,11.7198602 6.77376277,11.8868705 7.26557527,11.6054404 L10.8444294,9.55636593 L12.5425711,12.4730057 L7.97883777,15.0858288 C7.2933461,15.4783031 6.4166836,15.245377 6.02090443,14.5656094 L0.192254434,4.55440691 C-0.203524733,3.87463938 0.0313627674,3.00529737 0.716854434,2.61282312 L0.716854434,2.61282312 Z'
                                fill='#345E80'
                              ></path>
                            </g>
                            <path
                              d='M14.3245542,16.2007115 L14.8387625,17.0837342 C14.1892833,18.2263335 13.2423875,19.1750942 12.096975,19.8308762 L6.16978333,23.2243838 L5.655575,22.3413611 L11.5827667,18.9478536 C12.7281792,18.2920716 13.675075,17.3433108 14.3245542,16.2007115 Z'
                              fill='#E59076'
                            ></path>
                          </g>
                          <g fillRule='nonzero' id='Path'>
                            <path
                              d='M33.0619833,16.6107752 L12.2637792,4.70329483 L6.60838333,14.4167215 L11.2665375,17.0835565 C11.9160167,18.2261558 12.8629125,19.1749166 14.008325,19.8306986 L18.7668125,22.5550988 L23.775775,25.4228792 C24.5134042,25.8452021 25.4567167,25.5946866 25.882775,24.8630392 C26.3086542,24.1315696 26.0560292,23.1961341 25.3182208,22.7738112 L27.9895958,24.3031993 C28.727225,24.7255222 29.6705375,24.4750068 30.0964167,23.7433594 C30.5222958,23.0118898 30.2696708,22.0764543 29.5318625,21.6541314 L30.86755,22.4188255 C31.6051792,22.8411484 32.5484917,22.5906329 32.9743708,21.8589856 C33.40025,21.1273382 33.1474458,20.1920805 32.4098167,19.7695799 L31.5193583,19.2598431 C32.2569875,19.682166 33.2003,19.4314728 33.6261792,18.7000032 C34.0524167,17.9685335 33.7996125,17.0332757 33.0619833,16.6107752 L33.0619833,16.6107752 Z'
                              fill='#FCC4AE'
                            ></path>
                            <g>
                              <path
                                d='M4.56373333,15.0858288 L0,12.4730057 L7.26198333,0 L11.8257167,2.61282312 C12.5112083,3.00529737 12.7460958,3.87463938 12.3503167,4.55440691 L6.52166667,14.5656094 C6.1258875,15.245377 5.249225,15.4783031 4.56373333,15.0858288 Z'
                                fill='#3C6D91'
                              ></path>
                              <path
                                d='M11.8257167,2.61282312 L11.7312958,2.55881128 L6.6816625,11.2321546 C6.39768333,11.7198602 5.76880833,11.8868705 5.27699583,11.6054404 L1.69814167,9.55636593 L0,12.4730057 L4.56373333,15.0858288 C5.249225,15.4783031 6.1258875,15.245377 6.52166667,14.5656094 L12.3503167,4.55440691 C12.7460958,3.87463938 12.5112083,3.00529737 11.8257167,2.61282312 Z'
                                fill='#345E80'
                              ></path>
                            </g>
                            <g transform='translate(6.607523, 13.515274)' fill='#F2AD97'>
                              <path d='M23.6696729,9.71621671 C23.1873562,10.110823 22.4929062,10.1930845 21.9195729,9.86492696 L16.020152,6.48740978 L15.482652,7.41058597 L21.3820729,10.7881031 C22.119702,11.210426 23.0630145,10.9599106 23.4890729,10.2282632 C23.583852,10.0651617 23.6401104,9.89157754 23.6696729,9.71621671 L23.6696729,9.71621671 Z'></path>
                              <path d='M26.5478062,7.83184284 C26.0654895,8.22644914 25.3710395,8.3087106 24.7977062,7.9805531 L17.5625979,3.8381642 L17.0250979,4.76134038 L24.2602062,8.90372928 C24.9978354,9.32605218 25.9411479,9.0755367 26.367027,8.34388937 C26.4619854,8.18078781 26.5180645,8.00720368 26.5478062,7.83184284 L26.5478062,7.83184284 Z'></path>
                              <path d='M27.1996145,4.67286045 C26.7172979,5.06746674 26.0228479,5.14972821 25.4495145,4.8215707 L19.1048645,1.18909628 L18.5673645,2.11227246 L24.9120145,5.74474688 C25.6496437,6.16706978 26.5929562,5.91637663 27.0188354,5.18490697 C27.1137937,5.02180541 27.170052,4.84822128 27.1996145,4.67286045 L27.1996145,4.67286045 Z'></path>
                              <g>
                                <polygon
                                  transform='translate(2.586064, 1.793673) rotate(-59.999272) translate(-2.586064, -1.793673) '
                                  points='2.06545152 -0.891893809 3.08722698 -0.8881759 3.10667555 4.4792393 2.08490009 4.47552139'
                                ></polygon>
                                <path d='M17.705752,10.9846068 L7.91501036,5.43257972 C6.76959786,4.77679772 5.82270202,3.82803698 5.17322286,2.68543766 L4.65901452,3.5684603 C5.30849369,4.71105961 6.25538952,5.65982036 7.40080202,6.31560236 L17.168252,11.907783 C17.9058812,12.3301059 18.8491937,12.0794127 19.275252,11.347943 C19.3702104,11.1850192 19.4262895,11.0112574 19.455852,10.8358965 C18.9735354,11.2305028 18.2789062,11.3127643 17.705752,10.9846068 L17.705752,10.9846068 Z'></path>
                              </g>
                            </g>
                          </g>
                          <g
                            transform='translate(9.166779, 14.047774)'
                            fillRule='nonzero'
                            id='Path'
                          >
                            <path
                              d='M10.0574456,11.3752825 L12.3950331,10.0370679 C13.1326623,9.61474497 13.3854665,8.67930951 12.9595873,7.94783986 C12.5337081,7.2163702 11.5903956,6.96567705 10.8527665,7.38799995 L8.51517896,8.72621454 C7.7775498,9.14853744 7.52474563,10.0839729 7.9506248,10.8154425 C8.37650396,11.5469122 9.31981646,11.7976054 10.0574456,11.3752825 L10.0574456,11.3752825 Z'
                              fill='#F2AD97'
                            ></path>
                            <path
                              d='M11.8808248,9.15404523 L9.5432373,10.4922598 C8.96255813,10.8246814 8.2580748,10.7349578 7.77522063,10.3272039 C7.80621646,10.4942142 7.8597873,10.6599808 7.95044563,10.8156202 C8.3763248,11.5470899 9.3196373,11.797783 10.0572665,11.3754601 L12.394854,10.0372455 C13.1324831,9.61492264 13.3852873,8.67948718 12.9594081,7.94801753 C12.8687498,7.79237812 12.7510373,7.6633893 12.620604,7.55341123 C12.7354498,8.17152708 12.4616831,8.82144595 11.8808248,9.15404523 L11.8808248,9.15404523 Z'
                              fill='#E59076'
                            ></path>
                            <path
                              d='M5.8436248,10.2556026 L10.4073581,7.64277952 C11.1449873,7.22045662 11.3977915,6.28502117 10.9719123,5.55355151 C10.5460331,4.82208185 9.60272063,4.5713887 8.86509146,4.9937116 L4.30135813,7.60653473 C3.56372896,8.02885763 3.3109248,8.96429308 3.73680396,9.69576274 C4.16268313,10.4272324 5.10599563,10.6779255 5.8436248,10.2556026 L5.8436248,10.2556026 Z'
                              fill='#F2AD97'
                            ></path>
                            <path
                              d='M9.89332896,6.75975688 L5.32959563,9.37258 C4.74891646,9.70500161 4.04443313,9.61527798 3.56157896,9.20752406 C3.5925748,9.37453438 3.64614563,9.540301 3.73680396,9.69594041 C4.16268313,10.4274101 5.10599563,10.6781032 5.8436248,10.2557803 L10.4073581,7.64295719 C11.1449873,7.22063429 11.3977915,6.28519884 10.9719123,5.55372918 C10.881254,5.39808978 10.7635415,5.26910096 10.6331081,5.15912289 C10.747954,5.77723873 10.4740081,6.42715761 9.89332896,6.75975688 L9.89332896,6.75975688 Z'
                              fill='#E59076'
                            ></path>
                            <path
                              d='M2.96549146,8.37122878 L8.41968313,5.24849117 C9.1573123,4.82616827 9.41011646,3.89073282 8.9842373,3.15926316 C8.55835813,2.4277935 7.61504563,2.17710036 6.87741646,2.59942326 L1.4232248,5.72216086 C0.68559563,6.14448376 0.432791463,7.07991921 0.85867063,7.81138887 C1.2845498,8.54285853 2.2278623,8.79355168 2.96549146,8.37122878 L2.96549146,8.37122878 Z'
                              fill='#F2AD97'
                            ></path>
                            <path
                              d='M7.90565396,4.36546853 L2.4514623,7.48820614 C1.87078313,7.82062774 1.1662998,7.73090411 0.68344563,7.32297253 C0.714441463,7.48998285 0.768012296,7.65574947 0.85867063,7.81138887 C1.2845498,8.54285853 2.2278623,8.79355168 2.96549146,8.37122878 L8.41968313,5.24849117 C9.1573123,4.82616827 9.41011646,3.89073282 8.9842373,3.15926316 C8.89357896,3.00362376 8.77586646,2.87463494 8.64543313,2.76465687 C8.76027896,3.38312806 8.48633313,4.03304693 7.90565396,4.36546853 Z'
                              fill='#E59076'
                            ></path>
                            <path
                              d='M2.31368313,5.21224638 L6.4321873,2.85420283 C7.16981646,2.43187993 7.42262063,1.49644448 6.99674146,0.764974817 C6.5708623,0.0335051586 5.6275498,-0.217187989 4.88992063,0.205134911 L0.771416463,2.56317846 C0.0337872964,2.98550136 -0.21901687,3.92093682 0.206862296,4.65240647 C0.632741463,5.38387613 1.5758748,5.63456928 2.31368313,5.21224638 Z'
                              fill='#F2AD97'
                            ></path>
                            <path
                              d='M5.91815813,1.97118019 L1.79965396,4.32904607 C1.2189748,4.66146767 0.514491463,4.57174404 0.0316372964,4.16399013 C0.0626331298,4.33100045 0.116203963,4.49676707 0.206862296,4.65240647 C0.632741463,5.38387613 1.57605396,5.63456928 2.31368313,5.21224638 L6.4321873,2.8543805 C7.16981646,2.4320576 7.42262063,1.49662215 6.99674146,0.764974817 C6.90608313,0.609335415 6.78837063,0.480346594 6.6579373,0.370368523 C6.77260396,0.988839712 6.4988373,1.63875859 5.91815813,1.97118019 L5.91815813,1.97118019 Z'
                              fill='#E59076'
                            ></path>
                            <g transform='translate(4.721457, 0.461910)' fill='#FFD2C0'>
                              <path d='M1.20745075,2.09185169 L1.56829242,1.88522084 C1.99667992,1.63985781 2.14359658,1.09654126 1.89616742,0.671730977 L1.76376325,0.444312672 C1.51633408,0.0195023842 0.968442417,-0.126187468 0.540054917,0.119175563 L0.17921325,0.325806414 C0.00775074994,0.423880558 -0.0508367501,0.641171642 0.0480632499,0.81120236 L0.717967417,1.96179685 C0.816867417,2.1316499 1.03598825,2.18992584 1.20745075,2.09185169 L1.20745075,2.09185169 Z'></path>
                              <path d='M3.19512575,4.48596237 L3.55596742,4.27933152 C3.98435492,4.03396849 4.13127158,3.49065194 3.88384242,3.06584165 L3.75143825,2.83842335 C3.50400908,2.41361306 2.95611742,2.26792321 2.52772992,2.51328624 L2.00707158,2.81141742 C1.92393825,2.85903313 1.89545075,2.96439177 1.94346742,3.0468309 L2.70564242,4.35590752 C2.80454242,4.52593824 3.02366325,4.58421418 3.19512575,4.48596237 L3.19512575,4.48596237 Z'></path>
                              <path d='M5.18262158,6.88025071 L5.54346325,6.67361986 C5.97185075,6.42825683 6.11876742,5.88494029 5.87133825,5.46013 L5.73893408,5.23271169 C5.49150492,4.80790141 4.94361325,4.66221155 4.51522575,4.90757458 L4.15438408,5.11420544 C3.98292158,5.21227958 3.92433408,5.42957066 4.02323408,5.59960138 L4.69313825,6.75019587 C4.79203825,6.92004892 5.01133825,6.97832486 5.18262158,6.88025071 L5.18262158,6.88025071 Z'></path>
                              <path d='M7.17029658,9.27453906 L7.53113825,9.06790821 C7.95952575,8.82254518 8.10644242,8.27922863 7.85901325,7.85441834 L7.72660908,7.62700004 C7.47917992,7.20218975 6.93128825,7.0564999 6.50290075,7.30186293 L6.14205908,7.50849378 C5.97059658,7.60656793 5.91200908,7.82385901 6.01090908,7.99388973 L6.68081325,9.14448422 C6.77971325,9.31433726 6.99883408,9.3726132 7.17029658,9.27453906 L7.17029658,9.27453906 Z'></path>
                            </g>
                          </g>
                          <path
                            d='M19.9568375,11.4634816 L26.3351708,7.81164112 L25.4316333,6.25968885 C24.9369542,5.40989061 23.8411708,5.11868858 22.9842167,5.60941464 L15.6747542,9.7942668 C15.5891125,9.84330387 15.5597292,9.95203825 15.6091792,10.0369648 L15.79605,10.3580155 C16.6372375,11.8026547 18.5000333,12.2976449 19.9568375,11.4634816 L19.9568375,11.4634816 Z'
                            id='Path'
                            fill='#F2AD97'
                            fillRule='nonzero'
                          ></path>
                          <path
                            d='M18.213725,10.0453153 L19.0316208,9.57697571 C19.2774375,9.43626064 19.361825,9.12444882 19.2197458,8.88050715 L18.7324125,8.04350119 L16.4100542,9.37318759 L16.5428167,9.60131658 C16.880725,10.1814109 17.6287458,10.3802243 18.213725,10.0453153 L18.213725,10.0453153 Z'
                            id='Path'
                            fill='#FFD2C0'
                            fillRule='nonzero'
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default PartnershipIcon;
