import { FC } from 'react';

interface IDocumentIconProps {}

const DocumentIcon: FC<IDocumentIconProps> = (props) => {
  return (
    <svg
      width='13.3333333px'
      height='16.6641667px'
      viewBox='0 0 13.3333333 16.6641667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Investor-Portal' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g
          id='00_Investor-Portal_Application_Details_00'
          transform='translate(-788.333333, -222.666667)'
          fill='#003E4E'
          fillRule='nonzero'
        >
          <g id='Pop-up' transform='translate(290.000000, 54.000000)'>
            <g id='Group-3' transform='translate(495.000000, 165.000000)'>
              <g
                id='01.Design-Token/06.Icons/Fluent/Line/Document-One-Page--'
                transform='translate(0.000000, 2.000000)'
              >
                <g id='Group' transform='translate(3.333333, 1.666667)'>
                  <path
                    d='M11.4583333,0 C12.4441479,0 13.2616931,0.763163909 13.3291667,1.74666667 L13.3333333,1.87416667 L13.3333333,14.7891667 C13.3333333,15.7749812 12.5701694,16.5925265 11.5866667,16.66 L11.4583333,16.6641667 L1.875,16.6641667 C0.889185446,16.6641667 0.0716401892,15.9010028 0.00416666667,14.9175 L0,14.7908333 L0,1.875 C0,0.889185446 0.763163909,0.0716401892 1.74666667,0.00416666667 L1.875,0 L11.4583333,0 Z M11.4583333,1.25 L1.875,1.25 C1.56267852,1.25001317 1.29830905,1.48058035 1.25583333,1.79 L1.25,1.875 L1.25,14.79 C1.25,15.1066667 1.485,15.3683333 1.79,15.4091667 L1.875,15.415 L11.4583333,15.415 C11.7706548,15.415 12.0350243,15.1844197 12.0775,14.875 L12.0833333,14.79 L12.0833333,1.875 C12.0833333,1.56267852 11.852753,1.29830905 11.5433333,1.25583333 L11.4583333,1.24916667 L11.4583333,1.25 Z'
                    id='Shape'
                  />
                  <path
                    d='M2.5,11.4575 C2.5,11.112322 2.77982203,10.8325 3.125,10.8325 L10.2083333,10.8325 C10.5535113,10.8325 10.8333333,11.112322 10.8333333,11.4575 C10.8333333,11.802678 10.5535113,12.0825 10.2083333,12.0825 L3.125,12.0825 C2.77982203,12.0825 2.5,11.802678 2.5,11.4575 L2.5,11.4575 Z M2.5,4.79083333 C2.5,4.44565536 2.77982203,4.16583333 3.125,4.16583333 L10.2083333,4.16583333 C10.5535113,4.16583333 10.8333333,4.44565536 10.8333333,4.79083333 C10.8333333,5.1360113 10.5535113,5.41583333 10.2083333,5.41583333 L3.125,5.41583333 C2.77982203,5.41583333 2.5,5.1360113 2.5,4.79083333 L2.5,4.79083333 Z M2.5,8.12416667 C2.5,7.7789887 2.77982203,7.49916667 3.125,7.49916667 L10.2083333,7.49916667 C10.4316243,7.49916667 10.6379537,7.61829098 10.7495992,7.81166666 C10.8612447,8.00504234 10.8612447,8.243291 10.7495992,8.43666667 C10.6379537,8.63004235 10.4316243,8.74916667 10.2083333,8.74916667 L3.125,8.74916667 C2.77982203,8.74916667 2.5,8.46934464 2.5,8.12416667 L2.5,8.12416667 Z'
                    id='Shape'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DocumentIcon;
