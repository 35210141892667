/* eslint-disable autofix/no-unused-vars */
import { Box, BoxProps, Grid, IconButton, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import * as React from 'react';
import ContactIcon from 'src/assets/icons/ContactIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DocumentOnePageIcon from 'src/assets/icons/DocumentOnePageIcon';
import { EditOutlinedIcon } from 'src/assets/icons/EditOutlinedIcon';
import { FlagIcon } from 'src/assets/icons/FlagIcon';
import { InfoIcon } from 'src/assets/icons/InfoIcon';
import CustomAlert from 'src/components/atoms/CustomAlert';
import { CustomTooltip } from 'src/components/atoms/CustomTooltip';
import { INVESTMENT_ENTITY_ROLE, INVESTMENT_ENTITY_ROLE_TEXT } from 'src/constants/applications';
import { ITeamContact } from 'src/modules/applications/type';

interface ITeamSelectionBoxProps extends BoxProps {
  title: string;
  numOfSelected?: string;
  users: ITeamContact[];
  tooltip?: string;
  disabled?: boolean;
  alertInfo?: string;
  onView?: (user: ITeamContact) => void;
  onEdit?: (user: ITeamContact) => void;
  onRemove?: (user: ITeamContact) => void;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: theme.palette.neutral.ne200,
  marginLeft: '12px',
}));

const TeamSelectionBox: React.FC<ITeamSelectionBoxProps> = ({
  users,
  numOfSelected,
  title,
  tooltip,
  disabled,
  onView,
  onRemove,
  onEdit,
  alertInfo,
  ...props
}) => {
  const renderTooltip = () => {
    if (tooltip) {
      return (
        <CustomTooltip title={tooltip} placement='right-end'>
          <span className='cursor-pointer ml-[6px]'>
            <InfoIcon />
          </span>
        </CustomTooltip>
      );
    }
  };

  const renderEmpty = () => {
    return (
      <Box className='text-center py-2'>
        <Typography variant='body3' color='neutral.ne800'>
          No data to display
        </Typography>
      </Box>
    );
  };

  const renderAlert = () => {
    if (!alertInfo) return;
    return (
      <CustomAlert
        severity='info'
        sx={{
          my: 2,
        }}
      >
        {alertInfo}
      </CustomAlert>
    );
  };

  return (
    <Box {...props}>
      <Box className='flex items-center justify-between'>
        <Box className='flex items-center'>
          <Typography fontWeight={500} variant='body2'>
            {title}
          </Typography>
          {renderTooltip()}
        </Box>
        <Typography variant='body3' color='neutral.ne800'>
          {numOfSelected}
        </Typography>
      </Box>
      {renderAlert()}
      <Box
        className='mt-4 py-4 px-6 rounded-[10px] max-h-[380px] overflow-y-auto'
        bgcolor='neutral.ne100'
      >
        {!isEmpty(users) ? (
          <Grid container spacing={2}>
            {users.map((user: ITeamContact, index: number) => {
              const userTypeName =
                user?.userType === INVESTMENT_ENTITY_ROLE.Investor
                  ? INVESTMENT_ENTITY_ROLE_TEXT.INVESTOR
                  : user?.userType === INVESTMENT_ENTITY_ROLE.Delegate
                  ? INVESTMENT_ENTITY_ROLE_TEXT.DELEGATE
                  : '';
              return (
                <React.Fragment key={`approved-signatoty-${index}`}>
                  <Grid item xs={1} className='flex items-center justify-center'>
                    <Box
                      className='w-[32px] h-[32px] flex items-center justify-center rounded-[16px]'
                      bgcolor='neutral.ne200'
                    >
                      <ContactIcon color='#9DA0A1' height='16px' />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      className={clsx('flex items-center ', {
                        'h-full': !userTypeName,
                      })}
                    >
                      <Typography variant='body3' mr='4px'>
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                      {user.isNew && <FlagIcon />}
                    </Box>
                    {userTypeName && (
                      <Typography variant='body3' color='neutral.ne800'>
                        {userTypeName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} className='flex items-center justify-end'>
                    <CustomIconButton onClick={() => onView?.(user)}>
                      <DocumentOnePageIcon />
                    </CustomIconButton>
                    {typeof onEdit === 'function' && (
                      <CustomIconButton disabled={disabled} onClick={() => onEdit?.(user)}>
                        <EditOutlinedIcon />
                      </CustomIconButton>
                    )}
                    {typeof onRemove === 'function' && (
                      <CustomIconButton disabled={disabled} onClick={() => onRemove?.(user)}>
                        <DeleteIcon />
                      </CustomIconButton>
                    )}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          renderEmpty()
        )}
      </Box>
    </Box>
  );
};

export default TeamSelectionBox;
