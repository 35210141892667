import { Box, Grid } from '@mui/material';
import { FC, useRef } from 'react';
import ArrowCounterClockWisePrimary from 'src/assets/icons/ArrowCounterClockWisePrimary';
import { ConfirmModal, IConfirmModalElement } from 'src/components/atoms/ConfirmModal';
import CustomButton from 'src/components/atoms/CustomButton';
import FormInput from 'src/components/atoms/FormInput';
import FormPhoneCountryInput from 'src/components/atoms/FormPhoneCountryInput';
import { DEFAULT_COUNTRY } from 'src/constants/common';
import { detectPortalType } from 'src/helpers/common';
import { useResetAuthenticator } from 'src/modules/auth/hooks';
import { forceLogout } from 'src/modules/auth/services';
import { IProfileResponse } from 'src/modules/auth/type';
import { useCountry, useEnterKeyPress } from 'src/modules/common/hooks';
import { handleErrorFromServer } from 'src/utils/common';

interface IProfileProps {
  userInfo: IProfileResponse | undefined;
  onSubmit: () => void;
}

const Profile: FC<IProfileProps> = ({ userInfo, onSubmit }) => {
  const confirmModalRef = useRef<IConfirmModalElement>(null);
  const { isAdmin: isGlobalAdminPortal } = detectPortalType();
  const { mutate: resetAuthenticatorMutate, isLoading } = useResetAuthenticator();
  const { parsePhoneNumber } = useCountry();

  // Use this hook to submit the profile form inside the drawer
  useEnterKeyPress(onSubmit);

  const handleOpenConfirmModal = () => {
    confirmModalRef.current?.open();
  };

  const handleCloseConfirmModal = () => {
    confirmModalRef.current?.close();
  };

  const getContactNumber = () => {
    const { contactNumber = '' } = parsePhoneNumber(userInfo?.contactNumber || '');

    return contactNumber || userInfo?.contactNumber;
  };

  const getCountryFlagCode = () => {
    const { countryFlagCode } = parsePhoneNumber(userInfo?.contactNumber || '');

    return userInfo?.countryFlagCode || countryFlagCode || DEFAULT_COUNTRY;
  };

  const handleResetAuthenticator = () => {
    resetAuthenticatorMutate(
      {},
      {
        onSuccess: forceLogout,
        onError: handleErrorFromServer,
      },
    );
  };

  return (
    <>
      <Box className='pt-12' component='form'>
        <Grid container rowSpacing={4} columnSpacing={3}>
          <Grid item xs={6}>
            <FormInput name='firstName' label='First Name' defaultValue={userInfo?.firstName} />
          </Grid>
          <Grid item xs={6}>
            <FormInput name='lastName' label='Last Name' defaultValue={userInfo?.lastName} />
          </Grid>
          <Grid item xs={6}>
            <FormInput name='email' label='Email Address' defaultValue={userInfo?.email} disabled />
          </Grid>
          {isGlobalAdminPortal && (
            <>
              <Grid item xs={6}>
                <FormInput name='role' label='Role' defaultValue={userInfo?.role} disabled />
              </Grid>
              <Grid item xs={6}>
                <FormInput name='position' label='Position' defaultValue={userInfo?.position} />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <FormPhoneCountryInput
              name='contactNumber'
              label='Contact Number'
              size='small'
              className='w-full'
              defaultValue={getContactNumber()}
              countryFlagCode={getCountryFlagCode()}
            />
          </Grid>
        </Grid>
        <Box className='pt-10 flex justify-end'>
          <CustomButton
            startIcon={<ArrowCounterClockWisePrimary />}
            variant='outlined'
            sx={{ px: '12px' }}
            onClick={handleOpenConfirmModal}
          >
            Reset 2FA
          </CustomButton>
        </Box>
      </Box>
      <ConfirmModal
        title='Reset 2FA?'
        content={`You will be prompted to log out to reset 2FA. <br/>
        Are you sure you want to continue?`}
        ref={confirmModalRef}
        ButtonsComponent={
          <>
            <CustomButton
              sx={{ color: 'neutral.ne800' }}
              variant='text'
              onClick={handleCloseConfirmModal}
              disabled={isLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton onClick={handleResetAuthenticator} isLoading={isLoading}>
              Reset
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default Profile;
