import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export type TooltipPlacement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.neutral.ne200,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral.ne200,
    color: theme.palette.neutral.ne800,
    padding: '16px 12px',
    borderRadius: 8,
    fontSize: 12,
    maxWidth: 400,
  },
}));
