/* eslint-disable react/self-closing-comp */
import { FC, SVGProps } from 'react';

export const PersonSelectedIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width='19.2px'
      height='19.2px'
      viewBox='0 0 19.2 19.2'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Fund-Portal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='00_Fund-Portal_Administration_Approved-Signatory-List_04'
          transform='translate(-649.400000, -494.400000)'
          fill='#33B27F'
          fillRule='nonzero'
        >
          <g id='Pop-up-Copy' transform='translate(290.000000, 70.000000)'>
            <g id='Back-Button-+-Title-+-Content-Mask' transform='translate(0.000000, 186.000000)'>
              <g id='Review-people' transform='translate(333.000000, 212.000000)'>
                <g id='User' transform='translate(20.000000, 16.000000)'>
                  <g id='Group' transform='translate(0.000000, 4.000000)'>
                    <g
                      id='01.Design-Token/06.Icons/Fluent/Line/People'
                      transform='translate(6.400000, 6.400000)'
                    >
                      <g id='Group' transform='translate(1.600000, 1.600000)'>
                        <rect
                          id='Rectangle'
                          opacity='0'
                          x='0'
                          y='0'
                          width='16.0000001'
                          height='16.0000001'
                        ></rect>
                        <path
                          d='M14.4400001,2.83333335 C14.1637356,2.59068594 13.7431719,2.61753043 13.5000001,2.89333335 L12.2533334,4.32666669 L11.8333334,3.85333335 C11.5884871,3.57719099 11.1661424,3.55182046 10.8900001,3.7966667 C10.6138577,4.04151293 10.5884872,4.46385765 10.8333334,4.74000002 L11.7600001,5.78000003 C11.8875927,5.92138401 12.0695605,6.00144984 12.2600001,6.00001938 C12.4491554,5.99785829 12.628495,5.91545899 12.7533334,5.77333336 L14.4933334,3.77333335 C14.7376632,3.49885174 14.7138087,3.07841703 14.4400001,2.83333335 Z'
                          id='Path'
                        ></path>
                        <path
                          d='M6.6666667,7.33333337 C8.13942604,7.33333337 9.33333338,6.13942603 9.33333338,4.66666669 C9.33333338,3.19390735 8.13942604,2.00000001 6.6666667,2.00000001 C5.19390736,2.00000001 4.00000002,3.19390735 4.00000002,4.66666669 C4.00000002,6.13942603 5.19390736,7.33333337 6.6666667,7.33333337 Z M6.6666667,3.33333335 C7.40304637,3.33333335 8.00000004,3.93028702 8.00000004,4.66666669 C8.00000004,5.40304636 7.40304637,6.00000003 6.6666667,6.00000003 C5.93028703,6.00000003 5.33333336,5.40304636 5.33333336,4.66666669 C5.33333336,3.93028702 5.93028703,3.33333335 6.6666667,3.33333335 Z'
                          id='Shape'
                        ></path>
                        <path
                          d='M6.6666667,8.66666671 C4.08933785,8.66666671 2.00000001,10.7560046 2.00000001,13.3333334 C2.00000001,13.7015232 2.29847684,14.0000001 2.66666668,14.0000001 C3.03485652,14.0000001 3.33333335,13.7015232 3.33333335,13.3333334 C3.33333335,11.4923842 4.82571752,10 6.6666667,10 C8.50761588,10 10,11.4923842 10,13.3333334 C10,13.7015232 10.2984769,14.0000001 10.6666667,14.0000001 C11.0348566,14.0000001 11.3333334,13.7015232 11.3333334,13.3333334 C11.3333334,10.7560046 9.24399555,8.66666671 6.6666667,8.66666671 L6.6666667,8.66666671 Z'
                          id='Path'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
