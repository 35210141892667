/* eslint-disable autofix/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports*/
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Typography } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import CloseIcon from 'src/assets/icons/CloseIcon';
import { BasicModal, IBasicModalElement } from 'src/components/atoms/BasicModal';
import { CustomStepper, ICustomStepperElement, IStep } from 'src/components/atoms/CustomStepper';
import { OptionType } from 'src/components/atoms/FormAutocomplete';
import ConfirmationAlert from 'src/components/molecules/ConfirmationAlert';
import FormSection from 'src/components/molecules/FormSection';
import ContactDetailsForm from 'src/components/pages/client-management/components/ContactDetailsForm';
import OnboardingContactForm from 'src/components/pages/client-management/components/OnboardingContactForm';
import OrganisationForm from 'src/components/pages/client-management/components/OrganisationForm';
import {
  CLIENT_STATUS,
  contactDetailsDefaultValues,
  onboardingContactDefaultValues,
  organisationDetailsDefaultValues,
  subscriptionBillingDefaultValues,
} from 'src/constants/client-management';
import { CLIENT_USER_ROLE, DEFAULT_COUNTRY, OTHER_LABEL } from 'src/constants/common';
import { ROUTES_PATH } from 'src/constants/routesPath';
import { showToast } from 'src/helpers/toast';
import { useNavigateWithParams } from 'src/hooks/useNavigateWithParams';
import { IErrorResponse } from 'src/interfaces/common';
import { useGetUserInfo } from 'src/modules/auth/hooks';
import {
  useCreateDraftClient,
  useGetClientDetail,
  useSubmitClient,
  useUpdateClient,
} from 'src/modules/client/hooks';
import { SUBMIT_FORM_ACTIONS } from 'src/modules/common/consts';
import { useCountry } from 'src/modules/common/hooks';
import { IAlertInfo } from 'src/modules/common/type';
import {
  getErrorMessageFromServer,
  handleErrorFromServer,
  removeSpacesInString,
  swapArrayElements,
} from 'src/utils/common';
import { formatPhoneNumber } from 'src/utils/phone';
import {
  administratorsValidationSchema,
  onboardingContactValidationSchema,
  organisationDetailsValidationSchema,
} from 'src/validations/client-management';
import SubscriptionAndBilling from '../SubscriptionAndBilling';

interface ICreateUpdateClientFormProps {
  handleOpenCreateClientModal: (isOpen: boolean) => void;
  id?: string;
  isEditMode: boolean;
  isViewMode: boolean;
  isCreateMode: boolean;
  isDraft: boolean;
}

type CreateClientProcessName = 'Detail' | 'Admin' | 'Contact' | string;

export interface IModalInfo {
  title: string;
  description: string;
}

export const SUBMIT_MODE = {
  Draft: 'draft',
  Submit: 'submit',
};

enum ADMIN_UPSERT_CLIENT_STEP {
  ORGANISATION_DETAILS,
  CONTACT_DETAILS,
  ONBOARDING_CONTACT,
  SUBSCRIPTION_BILLING,
  AMLPOLICY,
}

type FormKey = Exclude<ADMIN_UPSERT_CLIENT_STEP, ADMIN_UPSERT_CLIENT_STEP.AMLPOLICY>;

type IFormsState = {
  [key in FormKey]: {
    form: UseFormReturn<any>;
    isDirty?: boolean;
    isValid?: boolean;
    submit: (mode: string) => void;
  };
};

const CreateUpdateClientForm: FC<ICreateUpdateClientFormProps> = ({
  handleOpenCreateClientModal,
  id = '',
  isEditMode,
  isViewMode,
  isCreateMode,
  isDraft,
}) => {
  const navigate = useNavigateWithParams();
  const stepperRef = useRef<ICustomStepperElement>(null);
  const modalAlertRef = useRef<IBasicModalElement>(null);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const [alertInfo, setAlertInfo] = useState<IAlertInfo>({
    title: '',
    description: '',
  });
  const [clientId, setClientId] = useState(id);

  const { mutate: updateClientMutate, isLoading: isUpdating } = useUpdateClient();
  const { mutate: createClientMutate, isLoading: isSubmitLoading } = useSubmitClient();
  const { mutate: createDraftMutate, isLoading: isCreateDraftLoading } = useCreateDraftClient();
  const { data: clientDetails } = useGetClientDetail(clientId);
  const { getPhoneCodeByCountryCode, parsePhoneNumber } = useCountry();
  const { data: userInfo } = useGetUserInfo();

  const isLoading = isUpdating || isSubmitLoading || isCreateDraftLoading;

  const organisationDetailsForm = useForm({
    defaultValues: organisationDetailsDefaultValues,
    resolver: yupResolver(organisationDetailsValidationSchema),
  });

  const contactDetailsForm = useForm({
    defaultValues: contactDetailsDefaultValues,
    resolver: yupResolver(administratorsValidationSchema),
  });

  const onboardingContactForm = useForm({
    defaultValues: onboardingContactDefaultValues,
    resolver: yupResolver(onboardingContactValidationSchema),
  });

  const subscriptionAndBillingForm = useForm({
    defaultValues: subscriptionBillingDefaultValues,
  });

  const watchClientName = organisationDetailsForm.watch('clientName');

  const { clientStatus } = useMemo(() => {
    return {
      clientStatus: clientDetails?.status,
    };
  }, [clientDetails]);

  useEffect(() => {
    if ((isEditMode || isViewMode) && clientDetails) handleFillData();
  }, [clientDetails]);

  const handleFillData = () => {
    const organisationDetailsData = {
      legalName: clientDetails?.legalName || '',
      abn: clientDetails?.abn || '',
      physicalAddress: {
        country: {
          value: clientDetails?.countryId || '',
          label: clientDetails?.countryName || '',
          id: clientDetails?.countryId || '',
        },
        state: {
          value: clientDetails?.stateId || '',
          label: clientDetails?.stateName || '',
          id: clientDetails?.stateId || '',
        },
        city: clientDetails?.manualEntry
          ? clientDetails?.cityName
          : {
              value: clientDetails?.cityId || '',
              label: clientDetails?.cityName || '',
              id: clientDetails?.cityId || '',
            },
        manualEntry: clientDetails?.manualEntry,
        postcode: clientDetails?.postcode || '',
        streetAddress: clientDetails?.streetAddress || '',
      },
      postalAddress: {
        country: {
          value: clientDetails?.postalCountryId || '',
          label: clientDetails?.postalCountryName || '',
          id: clientDetails?.postalCountryId || '',
        },
        state: {
          value: clientDetails?.postalStateId || '',
          label: clientDetails?.postalStateName || '',
          id: clientDetails?.postalStateId || '',
        },
        city: clientDetails?.postalManualEntry
          ? clientDetails?.postalCityName
          : {
              value: clientDetails?.postalCityId || '',
              label: clientDetails?.postalCityName || '',
              id: clientDetails?.postalCityId || '',
            },
        manualEntry: clientDetails?.postalManualEntry,
        postcode: clientDetails?.postalPostcode || '',
        streetAddress: clientDetails?.postalStreetAddress || '',
      },
      capacityIds: clientDetails?.capacities?.map((item: any) => item?.id) || [],
      otherCapacity: clientDetails?.otherCapacity || '',
      clientName: clientDetails?.clientName || '',
      tenantId: clientDetails?.tenantId || '',
      hasOtherCapacity: (clientDetails?.capacities || []).some(
        (it: any) => it?.capacityName === OTHER_LABEL,
      ),
    };

    const onboardingContactData = {
      assignees: (clientDetails?.assignees || []).map((item: any) => ({
        id: item?.id,
        label: `${item?.firstName} ${item?.lastName}`,
        value: item?.id,
      })),
    };

    const subscriptionFeatureIds: Record<string, boolean> = {};
    clientDetails?.subscriptionFeatureIds?.forEach((it: any) => {
      subscriptionFeatureIds[it] = true;
    });
    const subscriptionBillingData = {
      subscriptionFeatureIds,
      billings: clientDetails?.billings,
    };

    if (!isEmpty(clientDetails?.administrators)) {
      const primaryIndex = clientDetails?.administrators?.findIndex((item: any) => item?.isPrimary);
      let clientAdministrators = clientDetails?.administrators?.map((administrator: any) => {
        const { contactNumber, countryFlagCode } = parsePhoneNumber(
          administrator?.phoneNumber || '',
        );
        return {
          ...administrator,
          phoneNumber: contactNumber || administrator?.phoneNumber,
          countryFlagCode,
        };
      });
      if (primaryIndex > 0) {
        clientAdministrators = swapArrayElements(clientAdministrators, primaryIndex, 0);
      }
      contactDetailsForm.reset({
        clientAdministrators,
      });
    }
    onboardingContactForm.reset(onboardingContactData);
    organisationDetailsForm.reset(organisationDetailsData);
    subscriptionAndBillingForm.reset(subscriptionBillingData);
  };

  const getContactDetailsBody = (data: any) => {
    const primaryIndex = data.clientAdministrators.findIndex((item: any) => item?.isPrimary);
    let clientAdministrators = data.clientAdministrators.map(
      ({ isAddNew, countryFlagCode = DEFAULT_COUNTRY, ...administrator }: any) => {
        const phoneNumberFormat = formatPhoneNumber(
          getPhoneCodeByCountryCode(countryFlagCode),
          administrator?.phoneNumber,
        );

        return {
          ...administrator,
          phoneNumber: administrator?.phoneNumber ? phoneNumberFormat : '',
          countryFlagCode,
        };
      },
    );

    if (primaryIndex > 0) {
      clientAdministrators = swapArrayElements(clientAdministrators, primaryIndex, 0);
    }

    return {
      clientAdministrators,
    };
  };

  const getOnboardingContactBody = (data: any) => {
    const onboardingContactBody: any = {
      assigneeIds: data.assignees?.map((item: OptionType) => item.id),
    };

    if (!isEmpty(onboardingContactBody.assigneeIds)) {
      onboardingContactBody.isSendEmailForAssignee = data.isSendEmailForAssignee;
    }

    return onboardingContactBody;
  };

  const getSubscriptionBillingBody = (data: any) => {
    const subscriptionFeatureIds = Object.keys(data.subscriptionFeatureIds).filter(
      (key) => data.subscriptionFeatureIds[key],
    );
    return {
      subscriptionFeatureIds,
      ...(!clientId || clientStatus === CLIENT_STATUS.Draft.id ? { billings: data.billings } : {}),
    };
  };

  const generateClientBody = () => {
    const organisationDetailsData = organisationDetailsForm.getValues();
    const contactDetailsData = contactDetailsForm.getValues();
    const onboardingContactData = onboardingContactForm.getValues();
    const subscriptionAndBillingData = subscriptionAndBillingForm.getValues();

    const cityName =
      typeof organisationDetailsData.physicalAddress.city === 'string'
        ? organisationDetailsData.physicalAddress.city
        : String(get(organisationDetailsData, 'physicalAddress.city.label', ''));

    const postalCityName =
      typeof organisationDetailsData.postalAddress.city === 'string'
        ? organisationDetailsData.postalAddress.city
        : String(get(organisationDetailsData, 'postalAddress.city.label', ''));
    const cityId = String(get(organisationDetailsData, 'physicalAddress.city.id', ''));
    const postalCityId = String(get(organisationDetailsData, 'postalAddress.city.id', ''));

    const organisationDetailsBody: any = {
      client: {
        legalName: organisationDetailsData.legalName,
        abn: removeSpacesInString(organisationDetailsData.abn),
        countryName: get(organisationDetailsData, 'physicalAddress.country.label', ''),
        countryId: String(get(organisationDetailsData, 'physicalAddress.country.id', '')),
        stateName: get(organisationDetailsData, 'physicalAddress.state.label', ''),
        stateId: String(get(organisationDetailsData, 'physicalAddress.state.id', '')),
        cityName,
        postcode: organisationDetailsData.physicalAddress?.postcode,
        streetAddress: organisationDetailsData.physicalAddress?.streetAddress,
        otherCapacity: organisationDetailsData.otherCapacity,
        postalCountryName: get(organisationDetailsData, 'postalAddress.country.label', ''),
        postalCountryId: String(get(organisationDetailsData, 'postalAddress.country.id', '')),
        postalStateName: get(organisationDetailsData, 'postalAddress.state.label', ''),
        postalStateId: String(get(organisationDetailsData, 'postalAddress.state.id', '')),
        postalCityName,
        postalPostcode: organisationDetailsData.postalAddress.postcode,
        postalStreetAddress: organisationDetailsData.postalAddress.streetAddress,
        capacityIds: organisationDetailsData.capacityIds,
        clientName: organisationDetailsData.clientName,
        tenantId: organisationDetailsData.tenantId?.toLowerCase(),
        manualEntry: get(organisationDetailsData, 'physicalAddress.manualEntry'),
        postalManualEntry: get(organisationDetailsData, 'postalAddress.manualEntry'),
      },
    };

    if (cityId) organisationDetailsBody.client.cityId = cityId;
    if (postalCityId) organisationDetailsBody.client.postalCityId = postalCityId;

    const onboardingContactBody = getOnboardingContactBody(onboardingContactData);
    const contactDetailsBody = getContactDetailsBody(contactDetailsData);
    const subscriptionAndBillingBody = getSubscriptionBillingBody(subscriptionAndBillingData);

    return {
      isSaveDraft: true,
      client: {
        ...organisationDetailsBody.client,
        ...onboardingContactBody,
      },
      ...contactDetailsBody,
      ...subscriptionAndBillingBody,
      id: clientId,
    };
  };

  const handleShowModal = (alertInfo: IAlertInfo) => {
    setAlertInfo(alertInfo);
    modalAlertRef.current?.open();
  };

  const handleGoToNextStep = () => {
    stepperRef.current?.next();
  };

  const handleSubmitSectionSuccess = (mode: string) => {
    switch (mode) {
      case SUBMIT_MODE.Draft:
        handleShowModal({
          title: 'Draft saved!',
          description: 'You can come back and resume at any time.',
          isError: false,
        });
        break;
      default:
        handleGoToNextStep();
    }
  };

  const handleSaveSectionDraft = (mode: string) => {
    const clientBody = generateClientBody();

    createDraftMutate(clientBody, {
      onSuccess: (id: string) => {
        if (!clientId) {
          setClientId(id);
        }
        handleSubmitSectionSuccess(mode);
      },
      onError: handleErrorFromServer,
    });
  };

  const handleSubmitSubscriptionBilling = (mode: string) => {
    const clientBody = generateClientBody();

    if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
      handleSaveSectionDraft(mode);
      return;
    }

    if (!clientBody.billings && (!clientId || clientStatus === CLIENT_STATUS.Draft.id)) {
      handleShowModal({
        title: 'Oops',
        description: 'Billings must not be empty',
        isError: true,
      });
      subscriptionAndBillingForm.setError('billings', {
        message: 'Billings must not be empty',
      });
      return;
    }

    if (isEditMode && clientStatus !== CLIENT_STATUS.Draft.id) {
      handleUpdateClient(clientBody);
    } else {
      handleCreateClient(clientBody);
    }
  };

  const handleUpdateClient = (clientBody: any) => {
    updateClientMutate(
      { clientId, data: clientBody },
      {
        onSuccess: () => {
          handleShowModal({
            title: 'You did it !',
            description: `Details have been changed.`,
            isError: false,
          });
        },
        onError: (error: unknown | IErrorResponse) => {
          const errorResponse = error as IErrorResponse;
          const processName = errorResponse.data?.ProcessName || '';
          const errorMessage = getErrorMessageFromServer(errorResponse);

          handleCreateEditClientError(processName, errorMessage);
        },
      },
    );
  };

  const handleSaveSubscriptionBillingDraft = (mode: string) => {
    const clientBody = generateClientBody();

    if (mode === SUBMIT_MODE.Submit) {
      return;
    } else {
      createDraftMutate(clientBody, {
        onSuccess: (id: string) => {
          if (!clientId) navigate(ROUTES_PATH.CLIENT_MANAGEMENT, { id });
          handleSubmitSectionSuccess(SUBMIT_MODE.Draft);
        },
        onError: handleErrorFromServer,
      });
    }
  };

  const handleCreateEditClientError = (
    processName: CreateClientProcessName,
    errorMessage: string,
  ) => {
    if (!processName) return;

    switch (processName) {
      case 'Detail':
        stepperRef.current?.setCurrentStep(0);
        organisationDetailsForm.trigger();
        break;
      case 'Admin':
        stepperRef.current?.setCurrentStep(1);
        contactDetailsForm.trigger();
        break;
      case 'Contact':
        stepperRef.current?.setCurrentStep(2);
        onboardingContactForm.trigger();
        break;
      case 'SubscriptionBilling':
        stepperRef.current?.setCurrentStep(3);
        subscriptionAndBillingForm.trigger();
        break;
      default:
        break;
    }

    showToast(errorMessage, 'error');
  };

  const formsState: IFormsState = {
    [ADMIN_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS]: {
      isDirty: organisationDetailsForm.formState.isDirty,
      isValid: organisationDetailsForm.formState.isValid,
      form: organisationDetailsForm,
      submit: handleSaveSectionDraft,
    },
    [ADMIN_UPSERT_CLIENT_STEP.CONTACT_DETAILS]: {
      isDirty: contactDetailsForm.formState.isDirty,
      isValid: contactDetailsForm.formState.isValid,
      form: contactDetailsForm,
      submit: handleSaveSectionDraft,
    },
    [ADMIN_UPSERT_CLIENT_STEP.ONBOARDING_CONTACT]: {
      isDirty: onboardingContactForm.formState.isDirty,
      isValid: onboardingContactForm.formState.isValid,
      form: onboardingContactForm,
      submit: handleSaveSectionDraft,
    },
    [ADMIN_UPSERT_CLIENT_STEP.SUBSCRIPTION_BILLING]: {
      isDirty: subscriptionAndBillingForm.formState.isDirty,
      isValid: subscriptionAndBillingForm.formState.isValid,
      form: subscriptionAndBillingForm,
      submit: handleSubmitSubscriptionBilling,
    },
  };

  const handleCreateClient = (clientBody: any) => {
    createClientMutate(clientBody, {
      onSuccess: () => {
        handleShowModal({
          title: 'You did it !',
          description: `Client ${watchClientName} has been created.`,
          isError: false,
        });
      },
      onError: (error: unknown | IErrorResponse) => {
        const errorResponse = error as IErrorResponse;
        const processName = errorResponse.data?.ProcessName || '';
        const errorMessage = getErrorMessageFromServer(errorResponse);

        handleCreateEditClientError(processName, errorMessage);
        handleErrorFromServer(error);
      },
    });
  };

  const handleSubmitSection = (sectionKey: FormKey, mode: SUBMIT_FORM_ACTIONS) => {
    const clientBody = generateClientBody();
    /**
     * Action for secondary button
     * Save: in the creating mode or draft client => save draft action
     * Next: in the editing mode and not draft client =>
     */
    if (isViewMode) return handleGoToNextStep();
    if (isEditMode && !isDraft) {
      if (mode === SUBMIT_FORM_ACTIONS.DRAFT) {
        handleGoToNextStep();
      } else {
        handleUpdateClient(clientBody);
      }
      return;
    }
    const { submit, form } = formsState[sectionKey];
    form.setValue('isSaveDraft', mode === SUBMIT_FORM_ACTIONS.DRAFT);
    form.handleSubmit(() => submit(mode))();
  };

  const handleCloseModal = () => {
    handleOpenCreateClientModal(false);
  };

  const handleSetErrorSteps = (step: number) => {
    const errorSteps: number[] = [];
    Object.entries(formsState).forEach(([key, { isValid, form }]) => {
      if (!isValid) {
        errorSteps.push(Number(key));
        if (step !== Number(key)) {
          form?.trigger();
        }
      } else if (step === Number(key)) {
        form?.trigger();
      }
    });

    setErrorSteps(errorSteps);
  };

  const STEPS: IStep[] = [
    {
      key: ADMIN_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
      label: 'Organisation Details',
      content: (
        <FormProvider {...organisationDetailsForm}>
          <FormSection
            {...{
              title: 'Organisation Details',
              isLoading,
              isEditMode,
              isViewMode,
              isDraft,
              onSubmit: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
                  SUBMIT_FORM_ACTIONS.SUBMIT,
                ),
              onSave: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.ORGANISATION_DETAILS,
                  SUBMIT_FORM_ACTIONS.DRAFT,
                ),
            }}
          >
            <OrganisationForm
              {...{
                clientStatus,
                isEditMode,
                isViewMode,
                clientDetails,
                clientId,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: ADMIN_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
      label: 'Contact Details',
      content: (
        <FormProvider {...contactDetailsForm}>
          <FormSection
            {...{
              title: 'Contact Details',
              isDraft,
              isEditMode,
              isLoading,
              isViewMode,
              onSubmit: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
                  SUBMIT_FORM_ACTIONS.SUBMIT,
                ),
              onSave: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.CONTACT_DETAILS,
                  SUBMIT_FORM_ACTIONS.DRAFT,
                ),
            }}
          >
            <ContactDetailsForm
              {...{
                clientStatus,
                isEditMode,
                isViewMode,
                clientId,
                clientDetails,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: ADMIN_UPSERT_CLIENT_STEP.ONBOARDING_CONTACT,
      label: 'Onboarding Contact',
      content: (
        <FormProvider {...onboardingContactForm}>
          <FormSection
            {...{
              title: 'Onboarding Contact',
              isDraft,
              isEditMode,
              isLoading,
              isViewMode,
              onSubmit: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.ONBOARDING_CONTACT,
                  SUBMIT_FORM_ACTIONS.SUBMIT,
                ),
              onSave: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.ONBOARDING_CONTACT,
                  SUBMIT_FORM_ACTIONS.DRAFT,
                ),
            }}
          >
            <OnboardingContactForm
              {...{
                clientStatus,
                isViewMode,
                clientId,
                clientDetails,
              }}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: ADMIN_UPSERT_CLIENT_STEP.SUBSCRIPTION_BILLING,
      label: 'Subscription & Billing',
      content: (
        <FormProvider {...subscriptionAndBillingForm}>
          <FormSection
            {...{
              title: 'Subscription & Billing',
              isDraft,
              isEditMode,
              isLoading,
              isLastStep: true,
              isViewMode,
              onSubmit: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.SUBSCRIPTION_BILLING,
                  SUBMIT_FORM_ACTIONS.SUBMIT,
                ),
              onSave: () =>
                handleSubmitSection(
                  ADMIN_UPSERT_CLIENT_STEP.SUBSCRIPTION_BILLING,
                  SUBMIT_FORM_ACTIONS.DRAFT,
                ),
            }}
          >
            <SubscriptionAndBilling
              clientId={clientId}
              isCreatedClient={!!clientId && clientStatus !== CLIENT_STATUS.Draft.id}
              isViewMode={isViewMode}
              isDraft={clientStatus === CLIENT_STATUS.Draft.id}
              isSuperAdmin={userInfo?.role === CLIENT_USER_ROLE.SUPER_ADMIN}
              isEditingClient={isEditMode}
            />
          </FormSection>
        </FormProvider>
      ),
    },
    {
      key: ADMIN_UPSERT_CLIENT_STEP.AMLPOLICY,
      label: 'AML/CTF Policy',
      content: <Box />,
    },
  ];

  return (
    <Box className='w-[1120px]'>
      <Box className='flex flex-col'>
        <Box className='relative'>
          <Typography variant='h5' align='center' sx={{ pt: 5, pb: 4 }}>
            {isViewMode ? 'View Client' : isEditMode ? 'Edit Client' : 'Create New Client'}
          </Typography>
          <Box className='absolute right-10 top-10'>
            <IconButton sx={{ p: 0 }} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className='flex-1'>
          <CustomStepper
            ref={stepperRef}
            steps={STEPS}
            StepperStyles={{ paddingX: 5 }}
            onClickStep={handleSetErrorSteps}
            errorSteps={errorSteps}
            enableClickStep={!!clientId && clientStatus !== CLIENT_STATUS.Draft.id}
          />
        </Box>
      </Box>
      <BasicModal ref={modalAlertRef}>
        <ConfirmationAlert
          isError={alertInfo.isError}
          title={alertInfo.title}
          description={alertInfo.description}
          buttonAction={{
            label: 'OK',
            onAction: () => {
              modalAlertRef?.current?.close();
              !alertInfo.isError && handleCloseModal();
            },
          }}
        />
      </BasicModal>
    </Box>
  );
};

export default CreateUpdateClientForm;
