import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';
import WarningIcon from 'src/assets/icons/WarningIcon';
import CustomButton from 'src/components/atoms/CustomButton';
import { INVESTMENT_APPLICATION_STEP } from 'src/components/pages/applications/components/InvestmentApplicationForm';
import { FUNDRAISING_APPLICATON_TAB } from 'src/components/pages/applications/fundraising';
import {
  APPLICATION_APPROVE_STEP,
  FUNDRAISING_OVERVIEW_STEP,
  RejectApplicationType,
} from 'src/constants/applications';
import { UnitClassOfferType } from 'src/constants/unit-class';
import {
  useGetApplicationById,
  useGetApplicationOfferUnitClassDetail,
  useGetApplicationOverview,
} from 'src/modules/applications/hooks';
import { IFundraisingApplicationOverviewProps } from '.';

interface IActionRequiredProps extends IFundraisingApplicationOverviewProps {
  scrollDownToKYCSection: () => void;
}

interface IActionRequired {
  id: number;
  title: string;
  handleReview: () => void;
  isHidden?: boolean;
}

const ActionRequired: FC<IActionRequiredProps> = (props) => {
  const { id = '', scrollDownToKYCSection, onSetApplicationTab } = props;
  const { data: { detail: applicationDetail } = {} } = useGetApplicationById(id, {
    step: INVESTMENT_APPLICATION_STEP.DETAILS,
  });
  const { data: overviewActionsRequired } = useGetApplicationOverview(
    id,
    FUNDRAISING_OVERVIEW_STEP.ApplicationSummary,
  );
  const { data: overviewKYCReview } = useGetApplicationOverview(
    id,
    FUNDRAISING_OVERVIEW_STEP.KYCReview,
  );
  const { data: unitClassDetailData } = useGetApplicationOfferUnitClassDetail(
    overviewActionsRequired?.offerId || '',
    overviewActionsRequired?.unitClassId || '',
  );

  const ACTIONS_REQUIRED = [
    {
      id: APPLICATION_APPROVE_STEP.InvestorTypeApproved,
      title: 'Investor type requires approval',
      handleReview: () =>
        onSetApplicationTab(FUNDRAISING_APPLICATON_TAB.Review, INVESTMENT_APPLICATION_STEP.DETAILS),
    },
    {
      id: APPLICATION_APPROVE_STEP.InvestmentAmountApproved,

      title: 'Investment amount is below minimum investment amount',
      handleReview: () =>
        onSetApplicationTab(FUNDRAISING_APPLICATON_TAB.Review, INVESTMENT_APPLICATION_STEP.DETAILS),
    },
    {
      id: APPLICATION_APPROVE_STEP.KycApproved,
      title: 'Review and approve AML/KYC assessment',
      handleReview: () => scrollDownToKYCSection(),
    },
    {
      id: APPLICATION_APPROVE_STEP.ManualSigned,
      title: 'Manual signatories are pending',
      handleReview: () => {
        onSetApplicationTab(
          FUNDRAISING_APPLICATON_TAB.Review,
          INVESTMENT_APPLICATION_STEP.ACCEPTANCE,
        );
      },
    },
  ];

  const actionsRequired: IActionRequired[] = useMemo(() => {
    const { offerType } = unitClassDetailData || {};
    const { investmentAmount = 0 } = overviewActionsRequired || {};
    const {
      minimumInvestmentAmount = 0,
      acceptInvestmentAmountUnderMinimum,
      hardFloor = 0,
    } = unitClassDetailData || {};

    const isShowInvestmentAmountAction =
      !applicationDetail?.isExistingInvestorInFund &&
      investmentAmount < minimumInvestmentAmount &&
      acceptInvestmentAmountUnderMinimum &&
      investmentAmount >= hardFloor;

    const isRejectedInvestorType =
      applicationDetail?.rejectedType === RejectApplicationType.InvestorType;

    const isRejectedInvestmentAmount =
      applicationDetail?.rejectedType === RejectApplicationType.InvestmentAmount;

    let newActionsRequired: IActionRequired[] = [];

    // Filter from summary array
    if (isEmpty(overviewActionsRequired?.summary)) {
      newActionsRequired = ACTIONS_REQUIRED;
    } else {
      newActionsRequired = ACTIONS_REQUIRED.filter(
        (it) => !(overviewActionsRequired?.summary || []).map((it) => it.id).includes(it.id),
      );
    }

    return newActionsRequired.map((it) => ({
      ...it,
      isHidden:
        ((offerType !== UnitClassOfferType.Wholesale || isRejectedInvestorType) &&
          it.id === APPLICATION_APPROVE_STEP.InvestorTypeApproved) ||
        ((!isShowInvestmentAmountAction || isRejectedInvestmentAmount) &&
          it.id === APPLICATION_APPROVE_STEP.InvestmentAmountApproved) ||
        (isEmpty(overviewKYCReview?.kycReview) && it.id === APPLICATION_APPROVE_STEP.KycApproved),
    }));
  }, [overviewActionsRequired, overviewKYCReview, unitClassDetailData, applicationDetail]);

  return (
    <Box>
      <Grid container rowSpacing='18px'>
        {(actionsRequired || [])
          .filter((it) => !it.isHidden)
          .map((item, index) => (
            <Grid key={index} item xs={12}>
              <Box
                className='border rounded-lg px-4 py-3 flex justify-between items-center'
                borderColor='neutral.ne300'
              >
                <Box className='flex items-center'>
                  <WarningIcon />
                  <Typography variant='body3' color='error' ml={1}>
                    {item.title}
                  </Typography>
                </Box>
                <CustomButton
                  variant='text'
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    p: 0,
                    px: 1,
                  })}
                  onClick={item.handleReview}
                >
                  Review
                </CustomButton>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default ActionRequired;
